import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sales-vendor',
  templateUrl: './sales-vendor.component.html',
  styleUrls: ['./sales-vendor.component.scss'],
  providers: [DatePipe, MessageService],
})
export class SalesVendorComponent implements OnInit {

  vendorForm: FormGroup;
  cols = [
    { field: 'name', header: 'Company', width: '10%' },
    { field: 'webSite', header: 'Corporate Website', width: '10%' },
    { field: 'country', header: 'Country', width: '10%' },
    { field: 'region', header: 'Region', width: '10%' },
    { field: '', header: 'Action', width: '5%' },
  ];
  gender1 = [
    { name: 'Male', code: 'M' },
    { name: 'Female', code: 'F' },
  ];
  accId: any;
  page: any = 1;
  rows: any = 10;
  loading: boolean;
  totalrecods: any;
  records: boolean;
  table: boolean;
  first: any;
  pageCount: any;
  vendorAssociatedStaffingMode: any;
  vendorAssociatedCustomertableData: any;
  statusDD: any;
  getStatusTable = {};
  industry: any;
  getTableIndustry = {};
  state: any;
  statusLabel: any;
  rowData: any;
  items: any = [];
  regionOptions: any;
  type: any;
  typeTable = {};
  regionTable = {};
  countrytable = {};
  registrationForm: FormGroup;
  display: any;
  vendorName: string;
  loader: boolean;
  countryData: any = [];
  ofcMailValidation: string;
  ofcEmailExten: any;
  accountExistCheck: string;
  errMsg: string;
  regRes: any;
  name: any;
  officenum: any;
  displayNumber: boolean;
  officenummsg: string;
  EmployeeData: any;
  stateDataArray: any;
  displayModal: boolean;
  source: any = [];
  id: string;
  country: any;
  datta: any;
  ofcnameValidation: string;
  accountname: any;
  Accid: any;
  role: any;
  acctType: string;
  rowid: string;
  empflag: any;
  contflag: any;
  contaccid: any;
  actIDS: any;
  accountId: any;
  salesvendoruserId: any;
  spinnerHide: boolean;
  checkHide: boolean;
  phSpinnerHide: boolean;
  phCheckHide: boolean;
  mailSpinnerHide: boolean;
  mailCheckHide: boolean;
  acctype: any;
  website: any;
  countrycodedropdown: any = [];

  // tslint:disable-next-line:max-line-length
  constructor(private route: ActivatedRoute, private breadcrumbService: BreadcrumbService, private messageService: MessageService, private fb: FormBuilder, private router: Router, private apisService: ApisService) {
    this.breadcrumbService.setItems([
      { label: 'Vendors', routerLink: '/vendor' }
    ]);
  }

  ngOnInit(): void {
    this.accountId = JSON.parse(this.apisService.decodeData());
    this.salesvendoruserId = this.accountId.userId;
    const userdetails = JSON.parse(this.apisService.decodeData());
    this.accId = userdetails.accId;
    this.rowid = sessionStorage.getItem('Act_type');
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.empflag = routeParams.params.empflag;
    this.contflag = routeParams.params.contflag;
    this.contaccid = routeParams.params.contaccid;
    this.vendorForm = this.fb.group({
      region: [''],
      vendorCountry: [''],
      vendorState: [''],

    });
    this.registrationForm = this.fb.group({
      customerName: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z0-9]*$'), Validators.maxLength(60)]],
      websiteURL: ['', [Validators.required, Validators.maxLength(60)]],
      NumOfEmployees: [''],
      firstName: ['', [Validators.required, Validators.maxLength(25)]],
      middleName: ['', [Validators.maxLength(15)]],
      lastName: ['', [Validators.required, Validators.maxLength(25)]],
      officePhone: ['', [Validators.required, Validators.maxLength(15)]],
      mobileDrp: [''],
      // tslint:disable-next-line:max-line-length
      officeEmail: ['', [Validators.required, Validators.pattern('^[[a-zA-Z0-9.+-_]+@[a-zA-Z0-9.+-_]+.[a-zA-Z]{2,5}]*$'), Validators.maxLength(60)], ],
      address1: ['', [Validators.maxLength(50)]],
      address2: ['', [Validators.maxLength(50)]],
      city: ['', [Validators.maxLength(20)]],
      country: ['', Validators.required],
      state: [''],
      gender: [''],
      mailbox: ['', [Validators.maxLength(10)]],
      zipcode: ['', [Validators.maxLength(10), Validators.pattern('^[0-9-]*$')]],
      source: ['', Validators.required]
    });
    this.searchData();
    this.getCountryDropdown();
    this.regionDropdown();
    this.getdrop();
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.searchData();
  }
  countryids(event) {
  }
  getdrop() {
    this.countrycodedropdown = this.apisService.getdropdwon();
  }
  searchData() {
    // tslint:disable-next-line:max-line-length
    const accountCountry = this.vendorForm.controls.vendorCountry.value === null || this.vendorForm.controls.vendorCountry.value === undefined || this.vendorForm.controls.vendorCountry.value === '' ? '' : this.vendorForm.controls.vendorCountry.value.code;
    // tslint:disable-next-line:max-line-length
    const accountState = this.vendorForm.controls.vendorState.value === '' || this.vendorForm.controls.vendorState.value === null || this.vendorForm.controls.vendorState.value === undefined ? '' : this.vendorForm.controls.vendorState.value.code;
    // tslint:disable-next-line:max-line-length
    const region = this.vendorForm.controls.region.value === null || this.vendorForm.controls.region.value === undefined || this.vendorForm.controls.region.value === '' ? '' : this.vendorForm.controls.region.value.code;
    // tslint:disable-next-line:semicolon
    const data = this.page + '/' + this.rows + '/' + this.salesvendoruserId + '?country=' + accountCountry + '&state=' + accountState + '&accType=' + 'V' + '&region=' + region
    this.loader = true;
    this.apisService.getApi(environment.salescontacts + data).subscribe((res: any) => {
      this.loader = false;
      this.vendorAssociatedCustomertableData = res.data.records;
      this.totalrecods = res.data.count;
      if (this.totalrecods === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  copyInputMessage(data) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (data));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
  reset() {
    this.vendorForm.reset();
    this.records = false;
    this.searchData();
    this.getCountryDropdown();
    this.getStatusData();
    this.getIndustryDropdown();
  }

  getCountryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'adlc').subscribe(res => {
      this.country = res.data;
      this.country.forEach(element => {
        this.countrytable[element.code] = element.label;
      });
    });
  }
  getStateByUrl(country2) {
    this.vendorForm.controls.vendorState.setValue('');
    if (country2 === null || '' || undefined) { }
    else {
      this.apisService.getApi(environment.states + country2).subscribe(res => {
        this.state = res.data;
      });
    }
  }

  getIndustryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'ALIT').subscribe(res => {
      this.industry = res.data;
      this.industry.forEach(element => {
        this.getTableIndustry[element.code] = element.label;
      });
    });
  }

  goToLink(url: string) {
    const addressLink = url.includes('http') ? url : `http://${url}`;
    window.open(addressLink, '_blank');
  }
  getStatusData() {
    this.apisService.getApi(environment.lookupsData + 'RLS').subscribe(res => {
      this.statusDD = res.data;
      this.statusDD.forEach(element => {
        this.getStatusTable[element.code] = element.label;
      });
    }
    );
  }
  emailToast(){
    if (this.registrationForm.controls.officeEmail.invalid && this.registrationForm.controls.officeEmail.errors.pattern) {
    }
    // tslint:disable-next-line: max-line-length
    else if (this.registrationForm.controls.officeEmail.value === '' || this.registrationForm.controls.officeEmail.value === null || this.registrationForm.controls.officeEmail.value === undefined) {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please enter email.' });
    }
    else {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
    }
  }
  toggle(event, data) {
    this.acctype = data.lkAccountType;
    sessionStorage.setItem('salesacctype', this.acctype);
    this.rowData = data;
    this.actIDS = this.rowData.accId;
    sessionStorage.setItem('salesAccid', this.rowData.accId);
    sessionStorage.setItem('Act_type', this.rowData.lkAccountType);
    if (data.lkStatus !== 'R') {
      this.items = [
        { label: 'Contacts', icon: 'pi pi-user', command: () => { this.contacts(data); }, },
      ];
    }
    else {
      this.items = [];
    }
  }
  addlocation() {
    this.displayModal = true;
    this.registrationForm.reset();
    this.registrationForm.controls.mobileDrp.patchValue({
      code: '+1',
      flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png',
      id: '2',
      label: 'UNITED STATES',
    });
    this.registrationForm.controls.gender.patchValue({
      name: 'Male',
      code: 'M',
    });
    this.getState('USA');
    this.countryList();
    this.employeeDD();
    this.getIndustryDropdown1();
  }
  regionDropdown() {
    this.apisService.getApi(environment.lookupsData + 'ALR').subscribe((res) => {
        this.regionOptions = res.data;
        this.regionOptions.forEach((element) => {
          this.regionTable[element.code] = element.label;
        });
      });
  }
  employeeDD() {
    this.apisService.getApi(environment.lookupsData + 'EMP').subscribe((res: any) => {
        this.EmployeeData = res.data;
      });
  }
  getState(data) {
    if (data === null || '' || undefined) { }
    else {
      this.apisService.getApi(environment.states + data).subscribe((res) => {
        this.stateDataArray = res.data;
      });
    }
  }
  officeEmailExtensionCheck() {
    const email = this.registrationForm.controls.officeEmail.value;
    if (email === '' || email === null || email === undefined) {
    } else {
      if (this.registrationForm.controls.officeEmail.invalid && this.registrationForm.controls.officeEmail.errors.pattern) {
      }
      else {
        this.mailSpinnerHide = true;
        this.apisService.getApi(environment.emailVaildation + email).subscribe((res) => {
            this.ofcEmailExten = res;
            if (this.ofcEmailExten.validation) {
              this.mailSpinnerHide = false;
              this.ofcMailValidation = 'Email already exist';
              this.registrationForm.get('officeEmail').reset();
              setTimeout(() => {
                this.ofcMailValidation = '';
              }, 3000);
            } else {
              this.mailSpinnerHide = false;
              this.mailCheckHide = true;
              setTimeout(() => {
                this.mailCheckHide = false;
              }, 3000);
              this.ofcMailValidation = '';
            }
          });
      }
    }
  }
  countryList() {
    this.apisService.getApi(environment.lookupsData + 'adlc').subscribe((res) => {
      this.countryData = res.data;
      this.registrationForm.controls.country.patchValue(this.countryData.filter((x) => x.code === 'USA')[0]);
    });
  }
  reset1() {
    this.registrationForm.reset();
    this.registrationForm.controls.mobileDrp.patchValue({
      code: '+1',
      flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png',
      id: '2',
      label: 'UNITED STATES',
    });
    this.registrationForm.controls.country.patchValue({
      code: 'USA',
      id: 'ADLC',
      label: 'UNITED STATES',
    });
    this.registrationForm.controls.gender.patchValue({
      name: 'Male',
      code: 'M',
    });
    this.registrationForm.controls.country.patchValue(this.countryData.filter((x) => x.code === 'USA')[0]);
    this.accountExistCheck = '';
    this.errMsg = '';
  }
  register() {
    if (this.registrationForm.invalid) {
      const controls = this.registrationForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      const body = {
        accId: 0,
        accName: this.registrationForm.controls.customerName.value,
        accType: this.display || 'V',
        address1: this.registrationForm.controls.address1.value,
        address2: this.registrationForm.controls.address2.value,
        city: this.registrationForm.controls.city.value,
        country: this.registrationForm.controls.country.value.code,
        firstName: this.registrationForm.controls.firstName.value,
        gender: this.registrationForm.controls.gender.value.code,
        lastName: this.registrationForm.controls.lastName.value,
        mailBox: this.registrationForm.controls.mailbox.value,
        middleName: this.registrationForm.controls.middleName.value,
        // tslint:disable-next-line:max-line-length
        noOfEmployees: this.registrationForm.controls.NumOfEmployees.value === null || this.registrationForm.controls.NumOfEmployees.value === undefined || this.registrationForm.controls.NumOfEmployees.value === '' ? '' : this.registrationForm.controls.NumOfEmployees.value.code,
        officeMail: this.registrationForm.controls.officeEmail.value,
        officePhone: this.registrationForm.controls.officePhone.value,
        // tslint:disable-next-line:max-line-length
        state: this.registrationForm.controls.state.value === null || this.registrationForm.controls.state.value === undefined || this.registrationForm.controls.state.value === '' ? '' : this.registrationForm.controls.state.value.code,
        webSite: this.registrationForm.controls.websiteURL.value,
        zip: this.registrationForm.controls.zipcode.value,
        source: this.registrationForm.controls.source.value.code,
        // tslint:disable-next-line:max-line-length
        occode: this.registrationForm.controls.mobileDrp.value === null || '' || this.registrationForm.controls.mobileDrp.value.id === undefined ? '' : this.registrationForm.controls.mobileDrp.value.id.toString(),
      };
      this.loader = true;
      this.displayModal = false;
      this.apisService.postApi(environment.acctreg, body).subscribe((res) => {
        this.regRes = res;
        this.loader = false;
        if (this.regRes.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '',  life: 3000,  detail: this.regRes.message });
          this.reset();
        } else if (this.regRes.hasOwnProperty('success') === false) {
          this.messageService.add({ key: 'br',  severity: '', life: 3000,  detail: this.regRes.message });
        } else {
          this.messageService.add({  key: 'br',  severity: '',  life: 3000, detail: this.regRes.error });
        }
      });
    }
  }
  goto() {
    this.router.navigate(['/login']);
  }
  getPhon() {
    this.displayNumber = false;
    const num = this.registrationForm.controls.officePhone.value;
    if (num === '' || num === null || num === undefined || num === '(___) ___-____') {
    }
    else {
      this.phSpinnerHide = true;
      this.apisService.getApi(environment.phonerequired + num).subscribe(res => {
        this.officenum = res;
        this.officenum = res.data.isNumberExisted;
        if (this.officenum === true) {
          this.phSpinnerHide = false;
          this.officenummsg = 'office number already exist';
          setTimeout(() => {
            this.officenummsg = '';
          }, 5000);
          this.registrationForm.get('officePhone').reset();
        }
        else {
          this.phSpinnerHide = false;
          this.phCheckHide = true;
          setTimeout(() => {
            this.phCheckHide = false;
          }, 3000);
        }
      });
    }
  }
  contacts(data) {
    this.Accid = data.accId;
    this.router.navigate(['/vendor-contact/', this.actIDS], {
      queryParams: {
        vendoraccname: data.accName,
        conname: 'contactname'
      }
    });
    sessionStorage.setItem('salesAccId', this.actIDS);
  }

  getIndustryDropdown1() {
    this.apisService.getApi(environment.lookupsData + 'SOF').subscribe(res => {
      this.source = res.data;
      this.source.forEach(element => {
        this.getTableIndustry[element.code] = element.label;
      });
    });
  }

  nameExtensionCheck() {
    const name = this.registrationForm.controls.customerName.value;
    if (name) {
      this.spinnerHide = true;
      this.apisService.getApi(environment.NameValidation + '?accName=' + name).subscribe((res) => {
          this.accountname = res;
          if (this.accountname.validation) {
            this.spinnerHide = false;
            this.messageService.add({
              key: 'br',
              severity: '',
              life: 3000,
              detail: 'Account name already exists.',
            });
            this.registrationForm.get('customerName').reset();
            setTimeout(() => {
              this.ofcnameValidation = '';
            }, 3000);
          } else {
            this.spinnerHide = false;
            this.checkHide = true;
            setTimeout(() => {
              this.checkHide = false;
            }, 3000);
            this.ofcnameValidation = '';
          }
        });
    }
  }

  urlvalidation() {
    this.website = this.registrationForm.controls.websiteURL.value;
    if (true) {
      this.website = this.website.replace('http://www.', '');
      this.website = this.website.replace('http://', '');
      this.website = this.website.replace('http:', '');
    }
    if (true) {
      this.website = this.website.replace('https://www.', '');
      this.website = this.website.replace('https://', '');
      this.website = this.website.replace('https:', '');
    }
    if (true) {
      this.website = this.website.replace('www.', '');
    }
    this.registrationForm.controls.websiteURL.patchValue(this.website);
  }
}
