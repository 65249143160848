import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sales-information',
  templateUrl: './sales-information.component.html',
  styleUrls: ['./sales-information.component.scss'],
  providers: [DatePipe, MessageService],
})
export class SalesInformationComponent implements OnInit {
  salesinfoForm: FormGroup;
  page: any = 1;
  rows: any = 10;
  cols = [
    { field: 'name', header: 'Name', width: '10%' },
    { field: 'email', header: 'Email', width: '10%' },
    { field: 'officePhone', header: 'Phone Number', width: '10%' },
    { field: 'status', header: 'Status', width: '5%' },
    { field: '', header: 'Action', width: '3%' },
  ];
  statusData = [
    { label: 'Active', value: 'A' },
    { label: 'Inactive', value: 'I' },
  ];

  first: any;
  pageCount: any;
  rowTID: any;
  gridres: any;
  loading = false;
  totalrecods: any;
  records: boolean;
  table: boolean;
  displayModal: boolean;
  saleAddForm: FormGroup;
  saleEditForm: FormGroup;
  displayModal1: boolean;
  name: void;
  items: { label: string; icon: string; command: () => void; }[];
  data: any;
  accId: any;
  saleName: any;
  subbtn: boolean;
  gridId: any;
  hide: boolean;
  salesuserName: any;
  salesuser: any;
  salesaccname: any;
  contactnamedsb: boolean;
  accID: any;
  status: any = [];
  statuTable: any = {};
  statusd: any;
  custName: string;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private apisService: ApisService, private breadcrumbService: BreadcrumbService,
    // tslint:disable-next-line:align
    private messageService: MessageService) {
    this.breadcrumbService.setItems([
      { label: 'Accounts', routerLink: '/accounts' },
      { label: 'Sales Info', routerLink: '/sales-information' },
    ]);
  }

  ngOnInit(): void {
    this.custName = this.route.snapshot.queryParamMap.get('salesname');
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.salesuser = routeParams.params.salesname;
    this.salesaccname = routeParams.params.salesaccname;
    const userDetails = JSON.parse(this.apisService.decodeData());
    this.accID = userDetails.accId;
    if (this.salesaccname === 'salesaccname '){
      this.contactnamedsb = true;
    }
    const userdetails = JSON.parse(this.apisService.decodeData());
    this.accId = userdetails.accId;
    this.salesinfoForm = this.fb.group({
      name: [''],
      mail: [''],
    });

    this.saleAddForm = this.fb.group({
      addnNameForm: ['', [Validators.required]]
    });
    this.saleEditForm = this.fb.group({
      editNameForm: [''],
      editEmailForm: [''],
      editPhoneForm: [''],
      editStatusForm: ['']
    });
    this.rowTID = JSON.parse(sessionStorage.getItem('saleInfoId'));
    this.gridData();
    this.salesNameDropdown();
    this.statusDD();
  }
  statusDD() {
    this.apisService.getApi(environment.actstatus).subscribe((res: any) => {
      this.status = res.data;
      this.status.forEach((element) => {
        this.statuTable[element.code] = element.label;
      });
    });
  }
  gridData() {
    this.loading = true;
    const pgNo = this.page;
    const recordPage = this.rows;

    // tslint:disable-next-line:max-line-length
    const name = this.salesinfoForm.controls.name.value === undefined ? '' : this.salesinfoForm.controls.name.value === null ? '' : this.salesinfoForm.controls.name.value === '' ? '' : this.salesinfoForm.controls.name.value;
    // tslint:disable-next-line:max-line-length
    const email = this.salesinfoForm.controls.mail.value === undefined ? '' : this.salesinfoForm.controls.mail.value === null ? '' : this.salesinfoForm.controls.mail.value === '' ? '' : this.salesinfoForm.controls.mail.value;
    // tslint:disable-next-line:max-line-length
    this.apisService.getApi(environment.getsales  + pgNo + '/' + recordPage + '/' + this.rowTID + '?email=' + email + '&name=' + name).subscribe((res: any) => {
      this.gridres = res.records;
      this.loading = false;
      this.totalrecods = res.count;
      if (this.totalrecods === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  addSaleInfo() {
    this.displayModal = true;
    this.saleAddForm.reset();
    this.salesNameDropdown();
  }
  addSaledata() {
    if (this.saleAddForm.invalid) {
      const controls = this.saleAddForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    } else {
      this.subbtn = true;
      this.hide = true;
      const data = {
        userId: this.saleAddForm.controls.addnNameForm.value === undefined ? '' : this.saleAddForm.controls.addnNameForm.value
          === null ? '' : this.saleAddForm.controls.addnNameForm.value === '' ? '' : this.saleAddForm.controls.addnNameForm.value.id,
        accId: this.rowTID
      };
      this.apisService.postApi(environment.addSales, data).subscribe((res: any) => {
        this.loading = false;
        this.hide = false;
        this.subbtn = false;
        if (res.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br',  severity: '', life: 3000, detail: res.message });
          this.displayModal = false;
          this.gridData();
        } else if (res.hasOwnProperty('success') === false) {
          this.messageService.add({  key: 'br', severity: '',  life: 3000, detail: res.message });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error  });
        }
      });
    }
  }
  salesNameDropdown() {
    this.apisService.getApi(environment.salesName + this.accID + '/' + this.rowTID).subscribe(res => {
      this.saleName = res.data;
    });
  }
    // It is used to copy an email from grid data to clipboard
    copyInputMessage(item) {
      document.addEventListener('copy', (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', (item));
        e.preventDefault();
        document.removeEventListener('copy', null);
      });
      // tslint:disable-next-line: deprecation
      document.execCommand('copy');
    }
  editaleInfo() {
    this.displayModal1 = true;
    this.saleEditForm.reset();
    this.saleEditForm.controls.editNameForm.patchValue(this.data.name);
    this.saleEditForm.controls.editPhoneForm.patchValue(this.data.officePhone);
    this.saleEditForm.controls.editEmailForm.patchValue(this.data.email);
    this.saleEditForm.get('editStatusForm').patchValue(this.statusData.filter((x) => x.value === this.data.status)[0]);
  }
  updatesales() {
    this.loading = true;
    this.subbtn = true;
    this.statusd = this.saleEditForm.controls.editStatusForm.value === undefined ? '' : this.saleEditForm.controls.editStatusForm.value
      === null ? '' : this.saleEditForm.controls.editStatusForm.value === '' ? '' : this.saleEditForm.controls.editStatusForm.value.value;
    const reqbody = {
      id: this.data.id,
      status: this.statusd
    };
    this.apisService.putApi(environment.updateSales , reqbody).subscribe((res: any) => {
      this.loading = false;
      this.subbtn = false;
      this.gridData();
      if (res.hasOwnProperty('success') === true) {
        this.messageService.add({
          key: 'br',
          severity: '',
          life: 3000,
          detail: res.message,
        });
        this.displayModal1 = false;
        this.gridData();
      } else if (res.hasOwnProperty('success') === false) {
        this.messageService.add({
          key: 'br',
          severity: '',
          life: 3000,
          detail: res.message,
        });
      } else {
        this.messageService.add({
          key: 'br',
          severity: '',
          life: 3000,
          detail: res.error,
        });
      }
    });
  }

  reset() {
    this.salesinfoForm.reset();
    this.gridData();
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridData();
  }
  emailToast(){
    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
  }
  toggle(event, data) {
    this.data = data;
    this.items = [
      {
        label: ' Edit',
        icon: 'pi pi-pencil',
        command: () => {
          this.editaleInfo();
        }
      },
    ];
  }

}
