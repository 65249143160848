import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { LogincComponent } from './components/loginc/loginc.component';
import { AuthGuard } from './services/auth.guard';
import { AddRequirementsComponent } from './components/projectManager/add-requirements/add-requirements.component';
import { EditRequirementsComponent } from './components/projectManager/edit-requirements/edit-requirements.component';
import { TeamComponent } from './components/purchasing/team/team.component';
import { DepartmentsComponent } from './components/purchasing/departments/departments.component';
import { JobTitleComponent } from './components/purchasing/job-title/job-title.component';
import { JobCategoryComponent } from './components/purchasing/job-category/job-category.component';
import { WorkLocationComponent } from './components/purchasing/work-location/work-location.component';
import { ProjectRequirementsComponent } from './components/projectManager/project-requirements/project-requirements.component';
import { VendorAsstComponent } from './components/purchasing/vendor-asst/vendor-asst.component';
import { VendorSearchComponent } from './components/purchasing/vendor-search/vendor-search.component';
import { VendorAccountComponent } from './components/purchasing/vendor-account/vendor-account.component';
import { TiersComponent } from './components/purchasing/tiers/tiers.component';
import { VendorDefinationComponent } from './components/purchasing/vendor-defination/vendor-defination.component';
import { PurchasRequirementComponent } from './components/purchasing/purchas-requirement/purchas-requirement.component';
import { CopyRequirementComponent } from './components/projectManager/copy-requirement/copy-requirement.component';
import { SubmitedProfilesComponent } from './components/projectManager/submited-profiles/submited-profiles.component';
import { TaskComponent } from './components/purchasing/task/task.component';
import { CustomerComponent } from './components/recruiter/customer/customer.component';
import { ConsultantSearchComponent } from './components/recruiter/consultant-search/consultant-search.component';
import { EditConsultantSearchComponent } from './components/recruiter/edit-consultant-search/edit-consultant-search.component';
import { AddConsultantComponent } from './components/recruiter/add-consultant/add-consultant.component';
import { OpenPositionsComponent } from './components/recruiter/open-positions/open-positions.component';
import { OpenPositionsEditComponent } from './components/recruiter/open-positions-edit/open-positions-edit.component';
import { SubcontractorComponent } from './components/recruiter/vendor/subcontractor/subcontractor.component';
import { AvailablesubcontractorComponent } from './components/recruiter/vendor/availablesubcontractor/availablesubcontractor.component';
import { ProfileComponent } from './components/registrationpages/profile/profile.component';
import { ResetuserpasswordComponent } from './components/registrationpages/resetuserpassword/resetuserpassword.component';
import { CustomerregistrationComponent } from './components/registrationpages/customerregistration/customerregistration.component';
import { ConsultantVendorregistrationComponent } from './components/registrationpages/consultant-vendorregistration/consultant-vendorregistration.component';
import { ChangepasswordComponent } from './components/registrationpages/changepassword/changepassword.component';
import { AvailableopenpositionsComponent } from './components/recruiter/availableopenpositions/availableopenpositions.component';
import { AddvendorrequirementComponent } from './components/recruiter/addvendorrequirement/addvendorrequirement.component';
// tslint:disable-next-line:max-line-length
import { ConsultantResumeComponentComponent } from './components/employee/consultant-resume-component/consultant-resume-component.component';
import { EmployeereviewsComponent } from './components/employee/employeereviews/employeereviews.component';
import { EmployeeInterviewComponent } from './components/employee/employee-interview/employee-interview.component';
import { EmployeeSkillsComponent } from './components/employee/employee-skills/employee-skills.component';
import { SubmissionrequestComponent } from './components/employee/submissionrequest/submissionrequest.component';
import { CertificationsComponent } from './components/employee/certifications/certifications.component';
import { AcademicComponent } from './components/employee/academic/academic.component';
import { RecruiterComponent } from './components/employee/recruiter/recruiter.component';
import { PreferencesComponent } from './components/employee/preferences/preferences.component';
import { LicenseComponent } from './components/employee/license/license.component';
import { UserpreferenceComponent } from './components/registrationpages/userpreference/userpreference.component';
import { TechEvalutionComponent } from './components/project-lead/tech-evalution/tech-evalution.component';
import { AddTechreviewsComponent } from './components/project-lead/add-techreviews/add-techreviews.component';
import { NetworkComponent } from './components/employee/network/network.component';
import { AccountSearchComponent } from './components/servicesbay-admin/account-search/account-search.component';
import { CustomerAccountDetailsComponent } from './components/customer-admin/customer-account-details/customer-account-details.component';
import { VendorTierDefinitionComponent } from './components/recruiter/vendor-tier-definition/vendor-tier-definition.component';
import { VendotTierComponent } from './components/recruiter/vendot-tier/vendot-tier.component';
import { VendorRequirementComponent } from './components/recruiter/vendor-requirement/vendor-requirement.component';
import { VendorRequirementEditComponent } from './components/recruiter/vendor-requirement-edit/vendor-requirement-edit.component';
import { ScheduleInterviewComponent } from './components/projectManager/schedule-interview/schedule-interview.component';
import { AddContactComponent } from './components/servicesbay-admin/add-contact/add-contact.component';
import { EditContactComponent } from './components/servicesbay-admin/edit-contact/edit-contact.component';
import { AdminMenuComponent } from './components/servicesbay-admin/admin-menu/admin-menu.component';
import { VendorDetailsComponent } from './components/servicesbay-admin/vendor-details/vendor-details.component';
import { VendorContactComponent } from './components/servicesbay-admin/vendor-contact/vendor-contact.component';
import { VendorAttachmentComponent } from './components/servicesbay-admin/vendor-attachment/vendor-attachment.component';
import { VendorLocationsComponent } from './components/servicesbay-admin/vendor-locations/vendor-locations.component';
import { AddCustomerComponent } from './components/recruiter/add-customer/add-customer.component';
import { TechReviewsComponent } from './components/projectManager/tech-reviews/tech-reviews.component';
import { CustomerLocationsComponent } from './components/customer-admin/customer-locations/customer-locations.component';
import { CustomerContactsComponent } from './components/customer-admin/customer-contacts/customer-contacts.component';
import { CustomerAddContactsComponent } from './components/customer-admin/customer-add-contacts/customer-add-contacts.component';
import { CustomerAttachmentsComponent } from './components/customer-admin/customer-attachments/customer-attachments.component';
import { TechEvalutionTechreviewsComponent } from './components/tech-evalution/tech-evalution-techreviews/tech-evalution-techreviews.component';
import { TechEvalutionAddTechreviewsComponent } from './components/tech-evalution/tech-evalution-add-techreviews/tech-evalution-add-techreviews.component';
import { CustomerEditContactsComponent } from './components/customer-admin/customer-edit-contacts/customer-edit-contacts.component';
import { VendorAccountDetailsComponent } from './components/vendor-admin/vendor-account-details/vendor-account-details.component';
import { EmployerComponent } from './components/employee/employer/employer.component';
import { RecruitingManagerOpenpositionComponent } from './components/recruitingManager/recruiting-manager-openposition/recruiting-manager-openposition.component';
import { RecruitingManagerOpenpositionEditComponent } from './components/recruitingManager/recruiting-manager-openposition-edit/recruiting-manager-openposition-edit.component';
import { RecrutingManagerRequirementsComponent } from './components/recruitingManager/recruting-manager-requirements/recruting-manager-requirements.component';
import { RecrutingManagerRequirementsEditComponent } from './components/recruitingManager/recruting-manager-requirements-edit/recruting-manager-requirements-edit.component';
import { EmployeeRecommendationsComponent } from './components/employee/employee-recommendations/employee-recommendations.component';
import { PurchaRequirementeditComponent } from './components/purchasing/purcha-requirementedit/purcha-requirementedit.component';
import { RecuiterDashboardComponent } from './components/recruiter/recuiter-dashboard/recuiter-dashboard.component';
import { RequirementPreferenceComponent } from './components/purchasing/requirement-preference/requirement-preference.component';
import { EmployerCalenderComponent } from './components/employee/employer-calender/employer-calender.component';
import { EditSubcontractorComponent } from './components/recruiter/edit-subcontractor/edit-subcontractor.component';
import { RegErrorComponent } from './components/recruiter/reg-error/reg-error.component';
import { ProjectvendorComponent } from './projectvendor/projectvendor.component';
import { SalesOpenPositionsComponent } from './components/sales/sales-open-positions/sales-open-positions.component';
import { SalesopenpositionEditComponent } from './components/sales/sales-open-positions/salesopenposition-edit/salesopenposition-edit.component';
import { AuthenticatePasswordComponent } from './components/authenticate-password/authenticate-password.component';
import { SalesInfoComponent } from './components/projectManager/sales-info/sales-info.component';
import { RecSubmittedComponent } from './components/recruiter/rec-submitted/rec-submitted.component';
import { RecReviewsComponent } from './components/recruiter/rec-reviews/rec-reviews.component';
import { RecInterviewsComponent } from './components/recruiter/rec-interviews/rec-interviews.component';
import { RecSelectedComponent } from './components/recruiter/rec-selected/rec-selected.component';
import { VendorBankComponent } from './components/vendor-admin/vendor-bank/vendor-bank.component';
import { SalesCustomerComponent } from './components/salesRole/sales-customer/sales-customer.component';
import { SalesVendorComponent } from './components/salesRole/sales-vendor/sales-vendor.component';
import { SalesInformationComponent } from './components/salesRole/sales-information/sales-information.component';
import { VerifyEmailComponent } from './components/registrationpages/verify-email/verify-email.component';
import { SharecalenderComponent } from './components/registrationpages/sharecalender/sharecalender.component';
import { CalendereventsComponent } from './components/registrationpages/calenderevents/calenderevents.component';
import { SkillsComponent } from './components/servicesbay-admin/skills/skills.component';
import { AppTopBarComponent } from './app.topbar.component';
import { ServicesbayadminmenuComponent } from './components/servicesbayadmin-accountdetails/servicesbayadminmenu/servicesbayadminmenu.component';
import { AccountdetailsComponent } from './components/servicesbayadmin-accountdetails/accountdetails/accountdetails.component';
import { ServicesbaycontactsComponent } from './components/servicesbayadmin-accountdetails/servicesbaycontacts/servicesbaycontacts.component';
import { ServicesbayaddcontactsComponent } from './components/servicesbayadmin-accountdetails/servicesbayaddcontacts/servicesbayaddcontacts.component';
import { ServicesbayeditcontactsComponent } from './components/servicesbayadmin-accountdetails/servicesbayeditcontacts/servicesbayeditcontacts.component';
import { ServicesbayattachmentsComponent } from './components/servicesbayadmin-accountdetails/servicesbayattachments/servicesbayattachments.component';
import { ServicesbaylocationsComponent } from './components/servicesbayadmin-accountdetails/servicesbaylocations/servicesbaylocations.component';
// tslint:disable-next-line:max-line-length
import { ConsultantOpenPositionsComponent } from './components/consultantRole/consultant-open-positions/consultant-open-positions.component';
import { ConsultantOpenPositionEditComponent } from './components/consultantRole/consultant-open-position-edit/consultant-open-position-edit.component';
import { AssociationRequestComponent } from './components/recruiter/association-request/association-request.component';
import { AppliedpositionsComponent } from './components/consultantRole/appliedpositions/appliedpositions.component';
import { AddcustomertobankComponent } from './components/vendor-admin/addcustomertobank/addcustomertobank.component';
import { AccountregistrationpageComponent } from './components/registrationpages/accountregistrationpage/accountregistrationpage.component';
import { GroupsComponent } from './components/customer-admin/groups/groups.component';
import { MembersComponent } from './components/customer-admin/members/members.component';
import { ProjectsComponent } from './components/projectManager/projects/projects.component';
import { TimeSheetsComponent } from './components/employee/time-sheets/time-sheets.component';
import { AddTimesheetComponent } from './components/employee/add-timesheet/add-timesheet.component';
import { ProjectListComponent } from './components/employee/project-list/project-list.component';
import { ResourceComponent } from './components/projectManager/resource/resource.component';
import { UserHistoryComponent } from './components/employee/user-history/user-history.component';
import { SelectCandidatesComponent } from './components/projectManager/select-candidates/select-candidates.component';
import { AccountsactivityComponent } from './components/servicesbay-admin/accountsactivity/accountsactivity.component';
import { VendorSubcontractorComponent } from './components/projectManager/vendor-subcontractor/vendor-subcontractor.component';
import { ResetPasswodComponent } from './components/servicesbay-admin/reset-passwod/reset-passwod.component';
import { DeleteAccountComponent } from './components/delete-account/delete-account.component';
import { AccountsBackupComponent } from './components/servicesbay-admin/accounts-backup/accounts-backup.component';
@NgModule({
  imports: [
    RouterModule.forRoot([
      { path: '', component: LogincComponent, pathMatch: 'full' },
      { path: 'login', component: LogincComponent, pathMatch: 'full' },
      { path: 'consultantRegistration', component: CustomerregistrationComponent },
      { path: 'register', component: AccountregistrationpageComponent },
      { path: 'registration', component: ConsultantVendorregistrationComponent },
      { path: 'reg_success', component: RegErrorComponent },
      { path: 'verify', component: AuthenticatePasswordComponent },
      { path: 'emailVerify', component: VerifyEmailComponent },
      { path: 'deleteAccount', component: DeleteAccountComponent },
      {
        path: '', component: AppMainComponent,
        children: [
          { path: '', component: ProjectRequirementsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
          { path: 'add-requiremenent', component: AddRequirementsComponent },
          { path: 'project-edit-requirement', component: EditRequirementsComponent },
          { path: 'team', component: TeamComponent },
          { path: 'departments', component: DepartmentsComponent },
          { path: 'jobcategories', component: JobCategoryComponent },
          { path: 'location', component: WorkLocationComponent },
          { path: 'jobtitles', component: JobTitleComponent },
          { path: 'project-requirements', component: ProjectRequirementsComponent },
          { path: 'vendors', component: VendorAsstComponent },
          { path: 'available-vendors', component: VendorSearchComponent },
          { path: 'accdetails/:id', component: VendorAccountComponent },
          { path: 'verdor-tiers', component: TiersComponent },
          { path: 'tiers', component: VendorDefinationComponent },
          { path: 'purchas-requirement', component: PurchasRequirementComponent },
          { path: 'copy-requirement/:id', component: CopyRequirementComponent },
          { path: 'submitted-profiles', component: SubmitedProfilesComponent },
          { path: 'task', component: TaskComponent },
          { path: 'customer', component: CustomerComponent },
          { path: 'candidates', component: ConsultantSearchComponent },
          { path: 'editcandidate/:id', component: EditConsultantSearchComponent },
          { path: 'addcandidate', component: AddConsultantComponent },
          { path: 'open-positions', component: OpenPositionsComponent },
          { path: 'open-position-edit', component: OpenPositionsEditComponent },
          { path: 'subcontactors', component: SubcontractorComponent },
          { path: 'avaialablesubcontractor', component: AvailablesubcontractorComponent },
          { path: 'profile', component: ProfileComponent },
          { path: 'resetUserPassword', component: ResetuserpasswordComponent },
          { path: 'changepassword', component: ChangepasswordComponent },
          { path: 'availablecandidates', component: AvailableopenpositionsComponent },
          { path: 'addvendorrequirement', component: AddvendorrequirementComponent },
          { path: 'myresume', component: ConsultantResumeComponentComponent },
          { path: 'employee-reviews', component: EmployeereviewsComponent },
          { path: 'emp-interview', component: EmployeeInterviewComponent },
          { path: 'employeskills', component: EmployeeSkillsComponent },
          { path: 'employee-requirements', component: SubmissionrequestComponent },
          { path: 'emp-certificates', component: CertificationsComponent },
          { path: 'employee-academic', component: AcademicComponent },
          { path: 'employee-recruiter', component: RecruiterComponent },
          { path: 'my-preferences', component: PreferencesComponent },
          { path: 'emp-license', component: LicenseComponent },
          { path: 'userpreference', component: UserpreferenceComponent },
          { path: 'tech-evalution', component: TechEvalutionComponent },
          { path: 'add-techreviews/:id', component: AddTechreviewsComponent },
          { path: 'network', component: NetworkComponent },
          { path: 'accounts', component: AccountSearchComponent },
          { path: 'account-details', component: CustomerAccountDetailsComponent },
          { path: 'vendor-tier-definition', component: VendorTierDefinitionComponent },
          { path: 'vendot-tier', component: VendotTierComponent },
          { path: 'rec-requirements', component: VendorRequirementComponent },
          { path: 'rec-requirement-edit', component: VendorRequirementEditComponent },
          { path: 'schedule-interview', component: ScheduleInterviewComponent },
          { path: 'edit-contact/:id', component: EditContactComponent },
          { path: 'add-contact', component: AddContactComponent },
          { path: 'customerdetails', component: AddCustomerComponent },
          { path: 'tech-reviews', component: TechReviewsComponent },
          { path: 'account-details', component: CustomerAccountDetailsComponent },
          { path: 'locations/:id', component: CustomerLocationsComponent },
          { path: 'contacts/:id', component: CustomerContactsComponent },
          { path: 'contact-add', component: CustomerAddContactsComponent },
          { path: 'attachments/:id', component: CustomerAttachmentsComponent },
          { path: 'tech-evaltion-tech-reviews', component: TechEvalutionTechreviewsComponent },
          { path: 'add-tech-review/:id', component: TechEvalutionAddTechreviewsComponent },
          { path: 'contact-edit/:id', component: CustomerEditContactsComponent },
          { path: 'accountdetails', component: VendorAccountDetailsComponent },
          { path: 'employer', component: EmployerComponent },
          { path: 'openpositions', component: RecruitingManagerOpenpositionComponent },
          { path: 'openpositionedit', component: RecruitingManagerOpenpositionEditComponent },
          { path: 'requirements', component: RecrutingManagerRequirementsComponent },
          { path: 'requirementedit', component: RecrutingManagerRequirementsEditComponent },
          { path: 'employee-recommendations', component: EmployeeRecommendationsComponent },
          { path: 'purcha-requirementedit', component: PurchaRequirementeditComponent },
          { path: 'recuiter-dashboard', component: RecuiterDashboardComponent },
          { path: 'requirementpreference', component: RequirementPreferenceComponent },
          { path: 'employer-calender', component: EmployerCalenderComponent },
          { path: 'edit-subcontractor/:id', component: EditSubcontractorComponent },
          { path: 'projectvendor', component: ProjectvendorComponent },
          { path: 'salesOpenPositions', component: SalesOpenPositionsComponent },
          { path: 'salesOpenPositions-edit', component: SalesopenpositionEditComponent },
          { path: 'salesinfo', component: SalesInfoComponent },
          { path: 'rec-submitted', component: RecSubmittedComponent },
          { path: 'rec-reviews', component: RecReviewsComponent },
          { path: 'rec-interviews', component: RecInterviewsComponent },
          { path: 'rec-selected', component: RecSelectedComponent },
          { path: 'rec-onProjects', component: RecSelectedComponent },
          { path: 'bank-accounts', component: VendorBankComponent },
          { path: 'customers', component: SalesCustomerComponent },
          { path: 'vendor', component: SalesVendorComponent },
          { path: 'sales-information', component: SalesInformationComponent },
          { path: 'vendor-contact/:id', component: VendorContactComponent },
          { path: 'sharedcalender', component: SharecalenderComponent },
          { path: 'calendar-events', component: CalendereventsComponent },
          { path: 'skills', component: SkillsComponent },
          { path: 'topbarrout', component: AppTopBarComponent },
          { path: 'acc-contact-add', component: ServicesbayaddcontactsComponent },
          { path: 'consultantopenposition', component: ConsultantOpenPositionsComponent },
          { path: 'consultantopenpositionedit', component: ConsultantOpenPositionEditComponent },
          { path: 'acc-contact-edit/:id', component: ServicesbayeditcontactsComponent },
          { path: 'associationrequest', component: AssociationRequestComponent },
          { path: 'appliedpositions', component: AppliedpositionsComponent },
          { path: 'add-customer-to-bank', component: AddcustomertobankComponent },
          { path: 'groups', component: GroupsComponent },
          { path: 'members', component: MembersComponent },
          { path: 'projects', component: ProjectsComponent },
          { path: 'time-sheets', component: TimeSheetsComponent },
          { path: 'add-timesheet', component: AddTimesheetComponent },
          { path: 'project-history', component: ProjectListComponent },
          { path: 'resource', component: ResourceComponent },
          { path: 'user-history', component: UserHistoryComponent },
          { path: 'selectedcandidates', component: SelectCandidatesComponent },
          { path: 'accounts-activity', component: AccountsactivityComponent },
          {path: 'vendor-details', component: VendorSubcontractorComponent},
          {path: 'subcontractor-details', component: VendorSubcontractorComponent},
          {path: 'reset-passwod', component: ResetPasswodComponent},
          { path: 'accountsBackup', component: AccountsBackupComponent },
          {
            path: 'admin-menu', component: AdminMenuComponent,
            children: [
              { path: 'account-details/:id', component: VendorDetailsComponent },
              { path: 'account-contacts/:id', component: VendorContactComponent },
              { path: 'account-attachments/:id', component: VendorAttachmentComponent },
              { path: 'account-locations/:id', component: VendorLocationsComponent },
            ],
          },
          {
            path: 'acc-adminmenu', component: ServicesbayadminmenuComponent,
            children: [
              { path: 'acctdetails/:id', component: AccountdetailsComponent },
              { path: 'acc-details/:id', component: AccountdetailsComponent },
              { path: 'acc-contact/:id', component: ServicesbaycontactsComponent },
              { path: 'acc-attachment/:id', component: ServicesbayattachmentsComponent },
              { path: 'acc-locations/:id', component: ServicesbaylocationsComponent },
            ],
          },
        ]
      },
    ], { scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
