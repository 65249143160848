import { Component, OnInit, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApisService } from 'src/app/services/apis.service';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-job-category',
  templateUrl: './job-category.component.html',
  styleUrls: ['./job-category.component.scss'],
  providers: [DatePipe, MessageService],
})
export class JobCategoryComponent implements OnInit {
  // It is used for displaying table header data
  cols = [
    { field: 'jobCategory', header: 'Job Category', width: '20%' },
    { field: 'minRate', header: 'Min Rate', width: '15%' },
    { field: 'maxRate', header: ' Max Rate', width: '15%' },
    { field: 'status', header: 'Status', width: '15%' },
    { field: 'description', header: 'Description', width: '20%' },
    { field: '', header: 'Action', width: '7%' },
  ];
  // It is used for displaying status
  jobStatus = [
    { name: 'Active', id: 'A' },
    { name: 'Inactive', id: 'I' },
  ];
  showUploadView: boolean;
  displayModal: boolean;
  categoryForm: FormGroup;
  categoryAddForm: FormGroup;
  loader: boolean;
  gridData: any;
  totalRecords: any;
  // tslint:disable-next-line:no-inferrable-types
  table: boolean = true;
  records: boolean;
  rows: any = 10;
  page: any = 1;
  hide: boolean;
  getData: any;
  id: any;
  displayModal1: boolean;
  categoryUpdateForm: FormGroup;
  status: any;
  fileOver: boolean;
  isUploading: boolean;
  savbtn: boolean;
  rowData: any;
  items: { label: string; icon: string; command: () => void; }[];
  acctdetails: any;
  currencycode: any[];
  preferencList: any = [];
  currencyValue: any;
  currencysymbl: any;
  statuslist = {};
  items1: { label: string; icon: string; command: () => void; }[];
  first: any;
  pageCount: any;
  resetdisable: boolean;
  downloadData: any;
  constructor(
    private breadcrumbService: BreadcrumbService,
    private fb: FormBuilder,
    private apisService: ApisService,
    private messageService: MessageService,
  ) {
    this.breadcrumbService.setItems([
      { label: 'Job Categories', routerLink: '/jobcategories' },
    ]);
    this.items1 = [
      {label: 'Download Sample', icon: 'pi pi-download', command: () => { this.downloadFileapi();
      }},
      {label: 'Import Data', icon:  'pi pi-chevron-down', command: () => {
        this.toggleUploadView();
      }
    }];
  }
  ngOnInit(): void {

    this.acctdetails = JSON.parse(this.apisService.decodeData());
    this.preferencList = this.acctdetails.preferencesList;
    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }
    this.categoryForm = this.fb.group({
      jobCategory: [''],
      min: [''],
      max: [''],
    });
    this.categoryAddForm = this.fb.group({
      addCategory: ['', [Validators.required, Validators.maxLength(25), Validators.pattern('^[\\sa-zA-z]*$')]],
      addMax: ['', [Validators.required]],
      addMin: ['', [Validators.required]],
      addDes: ['', Validators.maxLength(255)],
      });
    this.categoryUpdateForm = this.fb.group({
      editCategory: ['', [Validators.required, Validators.maxLength(25), Validators.pattern('^[\\sa-zA-z]*$')]],
      editMax: ['', [Validators.required]],
      editMin: ['', [Validators.required]],
      editDes: ['', Validators.maxLength(255)],
      editStatus: ['', [Validators.required]],
      });
    this.gridTable();
    this.gridstatus();
  }
  toggleUploadView() {
    this.showUploadView = !this.showUploadView;
  }
  // It is used to show add pop up
  addDepartment() {
    this.categoryAddForm.reset();
    this.displayModal = true;
  }
  // It is used to reset the search fields
  reset() {
    this.categoryForm.reset();
    this.records = false;
    this.gridTable();
  }
  // It is used to close the add pop up
  addCancel() {
    this.displayModal = false;
    this.categoryAddForm.reset();
  }
// It is used to add job category
  SaveDailog1() {
    this.hide = true;
    if (this.categoryAddForm.invalid) {
      const controls = this.categoryAddForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    } else {
      const data = {
        description: this.categoryAddForm.controls.addDes.value,
        jobCategory: this.categoryAddForm.controls.addCategory.value,
        maxRate: this.categoryAddForm.controls.addMax.value,
        minRate: this.categoryAddForm.controls.addMin.value,
      };
      this.savbtn = true;
      this.apisService.postApi(environment.addCategory, data).subscribe((res: any) => {
          this.getData = res;
          this.hide = false;
          this.savbtn = false;
          this.displayModal = false;
          this.gridTable();
          if (this.getData.hasOwnProperty('success') === true) {
            this.messageService.add({
              key: 'br',
            severity: '',
            life: 3000,
              detail: this.getData.message,
            });
            this.gridTable();
          } else if (this.getData.hasOwnProperty('failed') === true) {
            this.messageService.add({
              key: 'br',
              severity: '',
              life: 3000,
              detail: this.getData.failed,
            });
          }
          else {
            this.messageService.add({
              key: 'br',
              severity: '',
              life: 3000,
              detail: this.getData.error,
            });
          }
        });
    }
  }
  // It is used to show table data
  gridTable() {
    this.loader = true;
    const pgNo = this.page;
    const recordPage = this.rows;
    const searchcategory = this.categoryForm.controls.jobCategory.value === null  ? ''  : this.categoryForm.controls.jobCategory.value;
    const searchmin =  this.categoryForm.controls.min.value === null  ? '' : this.categoryForm.controls.min.value;
    const searchmax = this.categoryForm.controls.max.value === null  ? ''  : this.categoryForm.controls.max.value;
    const search = '?category=' + searchcategory + '&min=' + searchmin + '&max=' + searchmax;
    this.apisService.getApi(environment.getTable + '/' + pgNo + '/' + recordPage + search).subscribe((res: any) => {
        this.loader = false;
        this.gridData = res.data;
        this.totalRecords = res.count;
        if (this.totalRecords === 0) {
          this.records = true;
          this.table = false;
        } else {
          this.records = false;
          this.table = true;
        }
        this.loader = false;
        this.gridData = res.data;
        this.totalRecords = res.count;
      });
  }
  // It is used to get job category details and to bind
  updateData(data) {
    this.categoryUpdateForm.reset();
    this.hide = true;
    this.id = data.id;
    this.displayModal1 = true;
    this.categoryUpdateForm.controls.editCategory.patchValue(data.jobCategory);
    this.categoryUpdateForm.controls.editMax.patchValue(data.maxRate);
    this.categoryUpdateForm.controls.editMin.patchValue(data.minRate);
    this.categoryUpdateForm.controls.editDes.patchValue(data.description);
    this.categoryUpdateForm.controls.editStatus.patchValue(data.status);
  }
  // It is used to close the edit pop up
  editCancel() {
    this.displayModal1 = false;
    this.categoryUpdateForm.reset();
  }
  // It is used to update job category
  editSave() {
    this.hide = true;
    this.hide = true;
    if (this.categoryUpdateForm.invalid) {
      const controls = this.categoryUpdateForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    } else {
      const valueid = this.id;
      const data = {
        description: this.categoryUpdateForm.controls.editDes.value,
        jobCategory: this.categoryUpdateForm.controls.editCategory.value,
        maxRate: this.categoryUpdateForm.controls.editMax.value,
        minRate: this.categoryUpdateForm.controls.editMin.value,
        status: this.categoryUpdateForm.controls.editStatus.value,
        id: valueid
      };
      this.savbtn = true;
      this.apisService.putApi(environment.editCategory, data).subscribe((res: any) => {
          this.hide = false;
          this.getData = res;
          this.loader = false;
          if (this.getData.hasOwnProperty('success') === true) {
            this.messageService.add({
              key: 'br',
            severity: '',
            life: 3000,
              detail: this.getData.message,
            });
            this.savbtn = false;
            this.displayModal1 = false;
            this.gridTable();

          } else if (this.getData.hasOwnProperty('failed') === true) {
            this.messageService.add({
              key: 'br',
            severity: '',
            life: 3000,
              detail: this.getData.failed,
            });
          } else {
            this.messageService.add({
              key: 'br',
            severity: '',
            life: 3000,
              detail: this.getData.error,
            });
          }
        });
    }
  }
  // Drag over listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drag leave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.isUploading = true;
      this.onUpload(files);
    }
  }
  // Window keydown listener
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.gridTable();
    }
  }
  uploadedFiles(arg0: string, uploadedFiles: any) {
    throw new Error('Method not implemented.');
  }
  // It is used to upload files
  onUpload(files) {
    this.isUploading = true;
    const formData: FormData = new FormData();
    formData.append('file', files[0]);
    this.apisService.postApi(environment.fileuploadJobCategory, formData).subscribe((res: any) => {
        if (res.success === true) {
          this.gridTable();
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
          this.isUploading = false;
          this.showUploadView = false;
        } else {
          setTimeout(() => {
            this.isUploading = false;
          }, 2000);
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
          this.gridTable();
        }
      });
  }
  // It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridTable();
  }
  // It is used to show action pop up
  toggle(event, data, rowData) {
    this.rowData = rowData;
    this.items = [
      { label: ' Edit',  icon: 'pi pi-pencil', command: () => { this.updateData(this.rowData); } },
    ];
  }
  gridstatus() {
    // tslint:disable-next-line:no-unused-expression
    this.jobStatus.forEach(element => {
      this.statuslist[element.id] = [element.name];
    });
  }
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
  downloadFileapi() {
    this.apisService.getApi(environment.downloadres + 3).subscribe((res: any) => {
      this.downloadData = res;
      window.location.assign(this.downloadData.downloadUrl);
    });
  }
}
