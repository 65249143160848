import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
  providers: [DatePipe, MessageService],
})
export class MembersComponent implements OnInit {
  cols = [
    { field: 'name', header: 'Member Name' },
    { field: 'member_type', header: 'Member Type' },
    { field: 'lkStatus', header: 'Status' },
    { field: '', header: 'Action' }
  ];
  type = [
    { name: 'Member', id: 'M' },
    { name: 'Group', id: 'G' },
  ];
  status = [
    { name: 'Active', id: 'A' },
    { name: 'Inactive', id: 'I' },
  ];
  loading: boolean;
  Id: any;
  MembersData: any;
  addmemberform: FormGroup;
  display: boolean;
  accId: any;
  groups: any = [];
  empList = {};
  disable: boolean;
  addres: any;
  editform: FormGroup;
  items: { label: string; icon: string; command: () => void; }[];
  display1: boolean;
  statuslist = {};
  groupname: any;
  table: boolean;
  records: boolean;
  rows: any = 10;
  page: any = 1;
  totalrecords: any;
  rowid: any;
  updateres: any;
  memberlist = {};
  first: any;
  pageCount: any;
  group: boolean;
  member = true;
  userId: any;
  members: any = [];
  RoleId: any;
  adminrole: boolean;
  nonadminrole: boolean;
  rescols: { field: string; header: string; }[];
  editstatus: any;
  dname: any;
  allmembers: any;
  allgroups: any;
  usrroleid: any;
  // tslint:disable-next-line: max-line-length
  constructor(private apisService: ApisService, private route: ActivatedRoute, private fb: FormBuilder, private breadcrumbService: BreadcrumbService, private messageService: MessageService, private router: Router) {
    this.breadcrumbService.setItems([
      { label: 'Groups', routerLink: '/groups' },
      { label: 'Members', routerLink: '/members' },
    ]);
  }
  ngOnInit(): void {
    const userdetails = JSON.parse(this.apisService.decodeData());
    this.accId = userdetails.accId;
    this.userId = userdetails.userId;
    this.Id = this.route.snapshot.queryParams.gridId;
    this.groupname = this.route.snapshot.queryParams.groupname;
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === ''){
      this.RoleId = userdetails.roles[0].id;
    }else{
      this.RoleId = this.usrroleid;
    }
    if (this.RoleId === 3 || this.RoleId === 6 || this.RoleId === 1 || this.RoleId === 14) {
      this.adminrole = true;
      this.nonadminrole = false;
    }
    else {
      this.adminrole = false;
      this.nonadminrole = true;
    }
    this.addmemberform = this.fb.group({
      membername: ['', Validators.required],
      membertype: ['', Validators.required],
    });
    this.editform = this.fb.group({
      emembername: ['', Validators.required],
      emembertype: ['', Validators.required],
      Status: ['', Validators.required],
    });
    this.gridData();
    this.gridstatus();
    this.gridmember();
  }
  gridstatus() {
    // tslint:disable-next-line:no-unused-expression
    this.status.forEach(element => {
      this.statuslist[element.id] = [element.name];
    });
  }
  // it is used to filter gridmember
  gridmember() {
    // tslint:disable-next-line:no-unused-expression
    this.type.forEach(element => {
      this.memberlist[element.id] = [element.name];
    });
  }
  // it is used to display table grid
  gridData() {
    this.loading = true;
    this.apisService.getApi(environment.membersgrid + this.page + '/' + this.rows + '/' + this.Id).subscribe((res) => {
      this.MembersData = res.data.records;
      this.totalrecords = res.count;
      if (this.totalrecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.loading = false;
    });
  }
  // it is used for the dropdown data of groups
  groupDD() {
    this.apisService.getApi(environment.groupDD + this.Id + '/' + this.userId).subscribe((res) => {
      this.groups = res.data;
      this.groups.forEach((element) => {
        this.empList[element.id] = element.name;
      });
    });
  }
  // it is used for the dropdown data of groups /look-up/available-users-for-group/38/4
  memberDD() {
    this.apisService.getApi(environment.memberDD + this.accId + '/' + this.Id).subscribe((res) => {
      this.members = res.data;
    });
  }
  // it is used for the dropdown data of all group members
  assignedtoDD() {
    this.apisService.getApi(environment.assignedtodropdown + this.accId).subscribe((res) => {
      this.allmembers = res.data;
      this.editform.controls.emembername.patchValue(this.allmembers.filter((x) => x.id === this.dname)[0]);
      this.allmembers.forEach((element) => {
        this.empList[element.id] = element.name;
      });
    });
  }
  TotalgroupsDD() {
    this.apisService.getApi(environment.groupormembernames + this.userId + '/B').subscribe((res) => {
      this.allgroups = res.data;
      this.editform.controls.emembername.patchValue(this.allgroups.filter((x) => x.id === this.dname)[0]);
    });
  }
  // it is used for add member pop-up
  addData() {
    this.addmemberform.reset();
    this.display = true;
  }
  // it is used for navigating back to groups
  goto() {
    this.router.navigateByUrl('groups');
  }
  // it is used for add member
  addmember() {
    if (this.addmemberform.invalid) {
      const controls = this.addmemberform.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      this.disable = true;
      this.loading = true;
      const data = {
        groupId: this.Id,
        memberType: this.addmemberform.controls.membertype.value.id,
        memberId: this.addmemberform.controls.membername.value.id,
      };
      this.apisService.postApi(environment.membersgrid, data).subscribe((res: any) => {
        this.addres = res;
        if (this.addres.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.addres.message });
        } else if (this.addres.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.addres.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.addres.error });
        }
        this.disable = false;
        this.display = false;
        this.loading = false;
        this.gridData();
      });
    }
  }
  // it is used to edit pop-up data
  editdata(data) {
    this.editstatus = data.member_type;
    this.dname = data.memberId;
    this.editform.reset();
    this.display1 = true;
    this.editform.controls.emembertype.patchValue(this.type.filter((x) => x.id === data.member_type)[0]);
    this.editform.controls.Status.patchValue(this.status.filter((x) => x.id === data.lkStatus)[0]);
    this.rowid = data.id;
    if (this.editstatus === 'G') {
      this.group = true;
      this.member = false;
      this.editform.controls.emembername.patchValue(data.name);
      this.TotalgroupsDD();
    }
    else {
      this.group = false;
      this.member = true;
      this.editform.controls.emembername.patchValue(data.name);
      this.assignedtoDD();
    }
  }
  // it is used to update member
  updatemember() {
    if (this.editform.invalid) {
      const controls = this.editform.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      this.disable = true;
      const data1 = {
        id: this.rowid,
        memberType: this.editform.controls.emembertype.value.id,
        memberId: this.editform.controls.emembername.value.id,
        // tslint:disable-next-line: radix
        groupId: parseInt(this.Id),
        lkStatus: this.editform.controls.Status.value.id
      };
      this.apisService.putApi(environment.membersgrid, data1).subscribe((res: any) => {
        this.updateres = res;
        if (this.updateres.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateres.message });
        } else if (this.updateres.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateres.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateres.error });
        }
        this.disable = false;
        this.display1 = false;
        this.gridData();
      });
    }
  }
  // it is used to display toogle items
  toggle(event, data, rowData) {
    if (this.adminrole === true) {
      this.items = [{ label: 'Edit', icon: 'pi pi-pencil', command: () => { this.editdata(data); } },
      ];
    }
    else {
      this.items = [
        { label: 'Leave', icon: 'fa fa-sign-out', command: () => { } },
      ];
    }
  }
  // It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridData();
  }
  membertype(id) {
    if (id === 'G') {
      this.groupDD();
      this.group = true;
      this.member = false;
    }
    else {
      this.memberDD();
      this.group = false;
      this.member = true;
    }
  }
}
