<p-toast position="bottom-right" key="br"></p-toast>
<nav class="navbar navbar-expand-lg navbar-dark sticky-top nav">
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
        <a class="navbar-brand">
            <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Servicesbay Logo SVG.svg" class="innerimg">
        </a>
        <button class="pi pi-arrow-left pointer addhBtn" id="backToStyle" pButton type="button" style="width: auto;
      top: 14px;" label="&nbsp;Back to Login" (click)="goto()"></button>
    </div>
</nav><br>
<div *ngIf=display>
    <div class="p-grid">
        <p-card class="hello">
            <div class="p-grid p-justify-center">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <div><br>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                                <h4><label class="inputfields">Hi &nbsp;<strong><a>{{names}}</a></strong></label>
                                </h4>
                                <em class="pi pi-info-circle" pTooltip="your password can't be too similar to your other personal information
                                your password must contain at least 8 alphabets
                                your password can;t be a commonly used password
                                your password can't be entirely numeric" tooltipPosition="right"></em>
                            </div>
                        </div><br>
                        <div class="p-grid ">
                            <div class="p-col-5 p-lg-6 p-fluid">
                                <label class="inputfields verifypswd">Email</label>
                            </div>
                            <div class="p-col-5 p-lg-6 verifypaswd descrip" tooltipPosition="right"
                                pTooltip="{{emailId}}">
                                {{emailId}}
                            </div>
                        </div><br>
                        <form [formGroup]="resetUsrPwdForm">
                            <div class="p-grid p-fluid">
                                <div class="p-col-5 p-lg-5">
                                    <label class="inputfields">New Password<label
                                            class="mandatoryColor">*</label></label>
                                </div>
                                <div class="p-col-7 p-lg-7">
                                    <input pPassword autocomplete="off" type="password" autocomplete="off" maxlength=21
                                        pInputText placeholder="" class="inputtext" [type]="hide1 ? 'password' : 'text'"
                                        (input)="onSearchChange($event.target.value)" formControlName="password">
                                    <span class="addOnCla"><em class="pi pi-eye-slash eyestyle" (click)="myFunction(2)"
                                            *ngIf="hideNewPWDIcon"></em>
                                        <em class="pi pi-eye eyestyle" (click)="myFunction1(2)"
                                            *ngIf="showNewPWDIcon"></em></span><br>
                                    <span class="validationtest"
                                        *ngIf="resetUsrPwdForm.controls.password.touched && resetUsrPwdForm.controls.password.invalid && resetUsrPwdForm.controls.password.errors.required">
                                        Please enter new password
                                    </span>
                                    <span class="validationtest"
                                        *ngIf="this.resetUsrPwdForm.get('password').hasError('maxlength')">
                                        Please enter 20 alphabets only
                                    </span>
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="p-col-5 p-lg-5">
                                    <label class="inputfields">Confirm New Password<label
                                            class="mandatoryColor">*</label></label>
                                </div>
                                <div class="p-col-7 p-lg-7">
                                    <input pPassword autocomplete="off" type="password" autocomplete="off" maxlength=21
                                        pInputText placeholder="" class="inputtext" [type]="hide2? 'password' : 'text'"
                                        formControlName="conformPassword">
                                    <span class="addOnCla"><em class="pi pi-eye-slash eyestyle" (click)="myFunction(3)"
                                            *ngIf="hideCfmPWDIcon"></em>
                                        <em class="pi pi-eye eyestyle" (click)="myFunction1(3)"
                                            *ngIf="showCfmPWDIcon"></em>
                                    </span><br>
                                    <span class="validationtest"
                                        *ngIf="resetUsrPwdForm.controls.conformPassword.touched && resetUsrPwdForm.controls.conformPassword.invalid && resetUsrPwdForm.controls.conformPassword.errors.required">
                                        Please enter password to confirm
                                    </span>
                                    <span class="validationtest"
                                        *ngIf="this.resetUsrPwdForm.get('conformPassword').hasError('maxlength')">
                                        Please enter 20 alphabets only
                                    </span>
                                </div>
                            </div>
                        </form>
                        <br>
                        <div class="p-grid p-justify-center">
                            <div class="p-col-5 p-fluid"></div>
                            <button pButton type="button" class="btnsty" label="Change your password" icon="pi pi-key"
                                (click)="resetPasswords()" [disabled]="disable"></button>
                        </div>
                    </div>
                </div>
            </div>
        </p-card>
    </div>
</div>
<div *ngIf=!display>
    <div class="p-grid1">
        <p-card>
            <br><br>
            <h3 class="aligncenter">
                <label>Token expired !</label><br><br>
                <em class="pi pi-times-circle fntsize"></em>
                <br>
            </h3>
            <br>
            <div class="p-grid p-justify-center">
                <span class="pointer" (click)="forgotPassword()">Please generate new token &nbsp;<a>Click
                        here</a></span>
            </div>
        </p-card>
    </div>
</div>
<p-dialog class="dialogBox" header="Forgot Your password ?" [(visible)]="displayForgotDialog" [modal]=true
    [draggable]="false">
    <form [formGroup]="forgotpwdForm">
        <p>Confirm your email and we'll send the instructions</p>
        <div class="p-grid-12"></div>
        <div class="p-grid p-fluid p-justify-center">
            <div class="p-col-12 p-md-12 p-sm-12 p-fluid">
                <input autocomplete="off" type="text" pInputText size="30" placeholder="Email"
                    formControlName="emailId" />
                <div>
                    <span class="mandatoryColor"
                        *ngIf="forgotpwdForm.controls.emailId.touched && forgotpwdForm.controls.emailId.invalid && forgotpwdForm.controls.emailId.errors.required">Email
                        can't be blank</span>
                    <span class="mandatoryColor"
                        *ngIf="forgotpwdForm.controls.emailId.touched && forgotpwdForm.controls.emailId.invalid && forgotpwdForm.controls.emailId.errors.pattern">Email
                        is not valid</span>
                </div>
            </div>
        </div>
        <div class="p-grid-12"></div>
        <br>
        <div div class="p-grid p-justify-center">
            <button pButton type="button" icon='pi pi-send' label="Send Link" (click)="password()" class="addhBtn1"
                [disabled]="loader"></button>&nbsp;
        </div>
    </form>
</p-dialog>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>