import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { element } from 'protractor';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-add-timesheet',
  templateUrl: './add-timesheet.component.html',
  styleUrls: ['./add-timesheet.component.scss'],
  providers: [DatePipe, MessageService]
})
export class AddTimesheetComponent implements OnInit {
  cols = [
    { field: 'userName', header: 'Category' },
    { field: 'companyName', header: 'Sunday' },
    { field: 'phoneNumber', header: 'Monday' },
    { field: 'email', header: 'Tuesday' },
    { field: 'lkStatus', header: 'Wednesday' },
    { field: 'lkStatus', header: 'Thursday' },
    { field: 'lkStatus', header: 'Friday' },
    { field: 'lkStatus', header: 'saturday' },
  ];

  gender: any = 'https://d2r8nxfsv3v6y3.cloudfront.net/images/male.png';
  projectsdata: FormGroup;
  actId: any;
  projects: any;
  project1: any;
  loader: boolean;
  project2: any;
  project4: any;
  project3: any;
  project5: any;
  getdata: any;
  startdate: any;
  secondday: any;
  enddate: any;
  timesheetNote: FormGroup;
  project1Id: any;
  project2Id: any;
  project3Id: any;
  project4Id: any;
  project5Id: any;
  dates: any;
  weekdates: any = [];
  headers: string;
  totalhours: any;
  firstday: any;
  lastday: any;
  add: any;
  edit: any;
  id: any;
  editTimesheet: boolean;
  addTimesheet: boolean;
  editres: any;
  submittedDate: any;
  approveTimesheet: boolean;
  approve: any;
  approveRejectPopup: boolean;
  rejectResonForm: FormGroup;
  roles: any;
  hide: boolean;
  accusername: any;
  currentDate: Date;
  profileimage: string;
  submittotalhours: any;
  addTimeSheetLkStatus: any;
  drafttimeSheetLkStatus: any;
  submitTimeSheetLkStatus: any;
  submitUserId: number;
  showdialog: boolean;
  display: boolean;
  sundayHours: any;
  mondayHours: any;
  tuesdayHours: any;
  wednesdayHours: any;
  thursdayHours: any;
  fridayHours: any;
  saturdayHours: any;
  timelines: any = [];
  week1: any;
  week2: any;
  week3: any;
  week4: any;
  week5: any;
  week6: any;
  week7: any;
  submittedform: boolean;
  rejectedTimeSheet: boolean;
  timesheetId: any;
  objectOneId: any;
  objectTwoId: any;
  objectThreeId: any;
  objectFourId: any;
  objectFiveId: any;
  objectSixId: any;
  objectSevenId: any;
  updatedisplay: boolean;
  date: Date;
  patchSubmittedDate: any;
  displayModal: boolean;
  histroy: any;
  flag: any;
  hierarchytotalhours: any;
  submittedbyname: any;
  pmrejected: boolean;
  downloadimageid: any;
  dwnimageid: any;
  usrID: any;
  timeSheetId: any;
  previousId: any;
  nextId: any;
  timeSheetgetId: any;
  dsbbtn: boolean;
  fileName: any;
  files: any;
  name: any;
  fileType: any;
  filedata: FormData;
  filesdatass: boolean;
  isUploading1: boolean;
  downloadData: any;
  downloadflag: any;
  downloadId: any;
  fileRes: any;
  fileData: any;
  filesuccess: any;
  filePath: any;
  fileSize: any;
  disable: boolean;
  file: number;
  classinput: boolean;
  classinput1: boolean;
  dwnshow: any;
  project1Sun: number;
  project2Sun: number;
  project3Sun: number;
  project4Sun: number;
  project5Sun: number;
  projectiSun: number;
  project1mon: number;
  project2mon: number;
  project3mon: number;
  project4mon: number;
  project5mon: number;
  projectimon: number;
  // tues
  project1tues: number;
  project2tues: number;
  project3tues: number;
  project4tues: number;
  project5tues: number;
  projectitues: number;
  // wed
  project1wed: number;
  project2wed: number;
  project3wed: number;
  project4wed: number;
  project5wed: number;
  projectiwed: number;
  // thurs
  project1thurs: number;
  project2thurs: number;
  project3thurs: number;
  project4thurs: number;
  project5thurs: number;
  projectithurs: number;
  // fri
  project1fri: number;
  project2fri: number;
  project3fri: number;
  project4fri: number;
  project5fri: number;
  projectifri: number;
  // sat
  project1sat: number;
  project2sat: number;
  project3sat: number;
  project4sat: number;
  project5sat: number;
  projectisat: number;
  editFilePath: any;
  previousRecordId: any;
  nextRecordId: any;
  previousbtn: boolean;
  nextbtn: boolean;
  gridtimesheetId: any;
  proejctmanagerreject: boolean;
  savebtnweek: any;
  // weekEndFridayDate: number;
  currentdate:  Date;
  savenbtn: boolean;
  idLsit: string;
  idListArray: any = [];
  nextIndexValue: any;
  timeSheetnpId: number;
  weekEndFridayDate: Date;
  usrroleid: any;
  // tslint:disable-next-line: max-line-length
  constructor(private breadcrumbService: BreadcrumbService, private messageService: MessageService, private router: Router, private datepipe: DatePipe, private route: ActivatedRoute, private fb: FormBuilder, private apiservice: ApisService) {
  }
  ngOnInit(): void {
    this.date = new Date();
    this.actId = JSON.parse(this.apiservice.decodeData());
    this.apiservice.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === ''){
      this.roles = this.actId.roles[0].id;
    }else{
      this.roles = this.usrroleid;
    }
    if (this.roles === 5 && JSON.parse(sessionStorage.getItem('idlist')) !== undefined) {
      this.idLsit = JSON.parse(sessionStorage.getItem('idlist'));
    }
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.startdate = routeParams.params.startdate;
    this.enddate = routeParams.params.enddate;
    this.previousRecordId = routeParams.params.previousId;
    this.nextRecordId = routeParams.params.nextId;
    this.add = routeParams.params.add;
    this.edit = routeParams.params.edit;
    this.id = routeParams.params.id;
    this.approve = routeParams.params.approve;
    this.accusername = this.actId.name;
    this.currentDate = new Date();
    this.firstday = this.datepipe.transform(this.startdate, 'yyyy-MM-dd');
    this.lastday = this.datepipe.transform(this.enddate, 'yyyy-MM-dd');
    this.enableSubmitOnWeekEnd();
    // From here to
    this.idListArray.push(this.id);
    if (this.idListArray.includes(this.id)) {
      this.nextIndexValue = this.idListArray.indexOf(this.id);
      this.timeSheetnpId = this.idListArray[this.nextIndexValue];
    }
    // upto here line of code is used to get list of values from timesheet grid of project manager for previous next button functionality
    this.projectsdata = this.fb.group({
      tsun: [''],
      tmon: [''],
      ttues: [''],
      twed: [''],
      tthurs: [''],
      tfri: [''],
      tsat: [''],
      p1sun: [''],
      p1mon: [''],
      p1tues: [''],
      p1wed: [''],
      p1thurs: [''],
      p1fri: [''],
      p1sat: [''],
      p2sun: [''],
      p2mon: [''],
      p2tues: [''],
      p2wed: [''],
      p2thurs: [''],
      p2fri: [''],
      p2sat: [''],
      p3sun: [''],
      p3mon: [''],
      p3tues: [''],
      p3wed: [''],
      p3thurs: [''],
      p3fri: [''],
      p3sat: [''],
      p4sun: [''],
      p4mon: [''],
      p4tues: [''],
      p4wed: [''],
      p4thurs: [''],
      p4fri: [''],
      p4sat: [''],
      p5sun: [''],
      p5mon: [''],
      p5tues: [''],
      p5wed: [''],
      p5thurs: [''],
      p5fri: [''],
      p5sat: [''],
      isun: [''],
      imon: [''],
      itues: [''],
      iwed: [''],
      ithurs: [''],
      ifri: [''],
      isat: [''],
      notes: ['']
    });
    this.timesheetNote = this.fb.group({
      notes: ['', [Validators.maxLength(250)]],
      rejectReason: [''],
      addResumeForm: ['']

    });
    this.rejectResonForm = this.fb.group({
      rejectreason: ['', [Validators.required, Validators.maxLength(250)]]
    });
    // If roleId is equal to 11(Employee) enters into if condition
    if (this.roles === 11) {
      // It will be enabled when roleid 11 and key is add
      if (this.add === 'add') {
        this.breadcrumbService.setItems([
          { label: 'Timesheets', routerLink: '/time-sheets' },
          { label: 'Timesheet Add', routerLink: '/add-timesheet' },
        ]);
        this.classinput = true;
        this.addTimesheet = true;
        this.editTimesheet = false;
        this.approveTimesheet = false;
        this.submittedform = false;
        this.rejectedTimeSheet = false;
        this.getprojects();
        this.getid();
        this.downloadImage();
      }
      // It will be enabled when roleid 11 and key is D(Draft)
      else if (this.edit === 'D') {
        this.breadcrumbService.setItems([
          { label: 'Timesheets', routerLink: '/time-sheets' },
          { label: 'Timesheet Edit', routerLink: '/add-timesheet' },
        ]);
        this.editTimesheet = true;
        this.addTimesheet = false;
        this.approveTimesheet = false;
        this.submittedform = false;
        this.rejectedTimeSheet = false;
        this.editData();
        this.downloadImage();
      }
     // It will be enabled when roleid 11 and key is S(Submitted)
      else if (this.edit === 'S') {
        this.breadcrumbService.setItems([
          { label: 'Timesheets', routerLink: '/time-sheets' },
          { label: 'Timesheet', routerLink: '/add-timesheet' },
        ]);
        this.submittedform = true;
        this.editTimesheet = false;
        this.addTimesheet = false;
        this.approveTimesheet = false;
        this.rejectedTimeSheet = false;
        this.editData();
        this.downloadImage();
      }
     // It will be enabled when roleid 11 and key is A(Approved)
      else if (this.edit === 'A') {
        this.breadcrumbService.setItems([
          { label: 'Timesheets', routerLink: '/time-sheets' },
          { label: 'Timesheet', routerLink: '/add-timesheet' },
        ]);
        this.submittedform = true;
        this.editTimesheet = false;
        this.addTimesheet = false;
        this.approveTimesheet = false;
        this.rejectedTimeSheet = false;
        this.editData();
        this.downloadImage();
      }
           // It will be enabled when roleid 11 and key is R(Rejected)
      else if (this.edit === 'R') {
        this.breadcrumbService.setItems([
          { label: 'Timesheets', routerLink: '/time-sheets' },
          { label: 'Timesheet', routerLink: '/add-timesheet' },
        ]);
        this.classinput = true;
        this.submittedform = false;
        this.editTimesheet = false;
        this.addTimesheet = false;
        this.approveTimesheet = false;
        this.rejectedTimeSheet = true;
        this.editData();
        this.downloadImage();
      }
    }
    // if roleId is equal to 9(Recruiter)
    else if (this.roles === 9) {
      this.breadcrumbService.setItems([
        { label: 'Timesheets', routerLink: '/time-sheets' },
        { label: 'Timesheet', routerLink: '/add-timesheet' },
      ]);
      this.pmrejected = true;
      this.approveTimesheet = false;
      this.editTimesheet = false;
      this.addTimesheet = false;
      this.proejctmanagerreject = false;
      this.editData();
      this.downloadImage();
    }
    // if roleId is 5(Project manager)
    else {
      // roleId 5 and key S(Submitted and approve)
      if ((this.approve === 'approve' && this.edit === 'S')) {
        this.breadcrumbService.setItems([
          { label: 'Timesheets', routerLink: '/time-sheets' },
          { label: 'Timesheet', routerLink: '/add-timesheet' },
        ]);
        this.approveTimesheet = true;
        this.editTimesheet = false;
        this.addTimesheet = false;
        this.proejctmanagerreject = false;
        this.editData();
      }
      // roleId 5 and key R(Rejected and approve)
      else if (this.approve === 'approve' && this.edit === 'R') {
        this.breadcrumbService.setItems([
          { label: 'Timesheets', routerLink: '/time-sheets' },
          { label: 'Timesheet', routerLink: '/add-timesheet' },
        ]);
        this.approveTimesheet = false;
        this.proejctmanagerreject = true;
        this.editTimesheet = false;
        this.addTimesheet = false;
        this.editData();
      }
      else {
        this.breadcrumbService.setItems([
          { label: 'Timesheets', routerLink: '/time-sheets' },
          { label: 'Timesheet', routerLink: '/add-timesheet' },
        ]);
        this.pmrejected = true;
        this.approveTimesheet = false;
        this.editTimesheet = false;
        this.addTimesheet = false;
        this.proejctmanagerreject = false;
        this.editData();
      }

    }

  }
  // method to generate one unique id that will be given to add API
  getid() {
    this.loader = true;
    this.apiservice.getApi(environment.getid + this.actId.userId).subscribe((res) => {
      this.getdata = res;
      this.loader = false;
    });
  }
// This method is used to get project names and week dates
  getprojects() {
    this.loader = true;
    this.apiservice.getApi(environment.getprojects + this.actId.userId + '?weekStartDate=' + this.firstday).subscribe((res) => {
      this.projects = [res.dateMap];
      this.flag = res.flag;
      this.loader = false;
      this.week1 = this.projects[0]?.weekDate1;
      this.week2 = this.projects[0]?.weekDate2;
      this.week3 = this.projects[0]?.weekDate3;
      this.week4 = this.projects[0]?.weekDate4;
      this.week5 = this.projects[0]?.weekDate5;
      this.week6 = this.projects[0]?.weekDate6;
      this.savebtnweek = this.projects[0]?.weekDate6;
      this.week7 = this.projects[0]?.weekDate7;
      this.project1 = this.projects[0]?.projectName1;
      this.project1Id = this.projects[0]?.projectId1;
      this.project2 = this.projects[0]?.projectName2;
      this.project2Id = this.projects[0]?.projectId2;
      this.project3 = this.projects[0]?.projectName3;
      this.project3Id = this.projects[0]?.projectId3;
      this.project4 = this.projects[0]?.projectName4;
      this.project4Id = this.projects[0]?.projectId4;
      this.project5 = this.projects[0]?.projectName5;
      this.project5Id = this.projects[0]?.projectId5;
      if (this.flag === 1) {
        this.showdialog = true;
        this.display = true;
      } else {
        this.showdialog = false;
      }
    });

  }
  // Method to save timesheet as draft
  saveAsDraft(event) {
    this.drafttimeSheetLkStatus = event;
    this.addtimesheet(undefined);
  }
  // Method to add timesheet
  addtimesheet(event) {
    this.addTimeSheetLkStatus = event;
    if (this.drafttimeSheetLkStatus === undefined) {
      this.submitTimeSheetLkStatus = this.addTimeSheetLkStatus;
      this.submitUserId = 0;
    } else {
      this.submitTimeSheetLkStatus = this.drafttimeSheetLkStatus;
      this.submitUserId = this.actId.userId;
    }
    this.hide = true;

    this.disable = true;
    const actId = JSON.parse(this.apiservice.decodeData());
    if (this.filedata !== undefined) {
      this.apiservice.postApi(environment.profileUpload + actId.userId + '/T', this.filedata).subscribe((res: any) => {
        this.fileRes = res;
        this.fileData = res.data;
        this.filesuccess = this.fileRes.success;
        this.filePath = res.data.path;
        this.fileSize = res.data.fileSize;
        if (this.fileRes.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.fileRes.message });
          this.disable = false;
          this.file = this.fileRes?.success === true ? 1 : 0;
          const data = {
            poId: 0,
            invoiceId: 0,
            timesheetId: this.getdata,
            lkStatus: this.submitTimeSheetLkStatus,
            startDate: this.firstday,
            endDate: this.lastday,
            totalHrs: this.totalhours,
            statusChangedDate: this.datepipe.transform(this.currentDate, 'yyyy-MM-dd HH:mm:ss'),
            // tslint:disable-next-line:max-line-length
            notes: this.timesheetNote.controls.notes.value === null || this.timesheetNote.controls.notes.value === '' || this.timesheetNote.controls.notes.value === undefined ? '' : this.timesheetNote.controls.notes.value,
            resourceType: 'E',
            filePath: this.filedata !== undefined ? this.filePath : '',
            fileFlag: this.file,
            draftedBy: this.submitUserId,
            firstPrjId: this.project1Id,
            // tslint:disable-next-line: max-line-length
            draftedDate: this.drafttimeSheetLkStatus === 'D' ? this.datepipe.transform(this.currentDate, 'yyyy-MM-dd HH:mm:ss') : '0000:00:00',
            // tslint:disable-next-line: max-line-length
            submittedDate: this.addTimeSheetLkStatus === 'S' ? this.datepipe.transform(this.currentDate, 'yyyy-MM-dd HH:mm:ss') : '0000:00:00',
            timesheetLines: [
              {
                workDate: this.week1,
                // tslint:disable-next-line:max-line-length
                prj1Hrs: this.projectsdata.controls.p1sun.value === null || this.projectsdata.controls.p1sun.value === '' || this.projectsdata.controls.p1sun.value === undefined ? '0' : this.projectsdata.controls.p1sun.value,
                // tslint:disable-next-line:max-line-length
                prj2Hrs: this.projectsdata.controls.p2sun.value === null || this.projectsdata.controls.p2sun.value === '' || this.projectsdata.controls.p2sun.value === undefined ? '0' : this.projectsdata.controls.p2sun.value,
                // tslint:disable-next-line:max-line-length
                internalHrs: this.projectsdata.controls.isun.value === null || this.projectsdata.controls.isun.value === '' || this.projectsdata.controls.isun.value === undefined ? '0' : this.projectsdata.controls.isun.value,
                // tslint:disable-next-line:max-line-length
                prj3Hrs: this.projectsdata.controls.p3sun.value === null || this.projectsdata.controls.p3sun.value === '' || this.projectsdata.controls.p3sun.value === undefined ? '0' : this.projectsdata.controls.p3sun.value,
                // tslint:disable-next-line:max-line-length
                prj4Hrs: this.projectsdata.controls.p4sun.value === null || this.projectsdata.controls.p4sun.value === '' || this.projectsdata.controls.p4sun.value === undefined ? '0' : this.projectsdata.controls.p4sun.value,
                // tslint:disable-next-line:max-line-length
                prj5Hrs: this.projectsdata.controls.p5sun.value === null || this.projectsdata.controls.p5sun.value === '' || this.projectsdata.controls.p5sun.value === undefined ? '0' : this.projectsdata.controls.p5sun.value,
                prjId1: this.project1Id === null || this.project1Id === undefined || this.project1Id === '' ? '0' : this.project1Id,
                prjId2: this.project2Id === null || this.project2Id === undefined || this.project2Id === '' ? '0' : this.project2Id,
                prjId3: this.project3Id === null || this.project3Id === undefined || this.project3Id === '' ? '0' : this.project3Id,
                prjId4: this.project4Id === null || this.project4Id === undefined || this.project4Id === '' ? '0' : this.project4Id,
                prjId5: this.project5Id === null || this.project5Id === undefined || this.project5Id === '' ? '0' : this.project5Id,
              },
              {
                workDate: this.week2,
                // tslint:disable-next-line:max-line-length
                prj1Hrs: this.projectsdata.controls.p1mon.value === null || this.projectsdata.controls.p1mon.value === '' || this.projectsdata.controls.p1mon.value === undefined ? '0' : this.projectsdata.controls.p1mon.value,
                // tslint:disable-next-line:max-line-length
                prj2Hrs: this.projectsdata.controls.p2mon.value === null || this.projectsdata.controls.p2mon.value === '' || this.projectsdata.controls.p2mon.value === undefined ? '0' : this.projectsdata.controls.p2mon.value,
                // tslint:disable-next-line:max-line-length
                internalHrs: this.projectsdata.controls.imon.value === null || this.projectsdata.controls.imon.value === '' || this.projectsdata.controls.imon.value === undefined ? '0' : this.projectsdata.controls.imon.value,
                // tslint:disable-next-line:max-line-length
                prj3Hrs: this.projectsdata.controls.p3mon.value === null || this.projectsdata.controls.p3mon.value === '' || this.projectsdata.controls.p3mon.value === undefined ? '0' : this.projectsdata.controls.p3mon.value,
                // tslint:disable-next-line:max-line-length
                prj4Hrs: this.projectsdata.controls.p4mon.value === null || this.projectsdata.controls.p4mon.value === '' || this.projectsdata.controls.p4mon.value === undefined ? '0' : this.projectsdata.controls.p4mon.value,
                // tslint:disable-next-line:max-line-length
                prj5Hrs: this.projectsdata.controls.p5mon.value === null || this.projectsdata.controls.p5mon.value === '' || this.projectsdata.controls.p5mon.value === undefined ? '0' : this.projectsdata.controls.p5mon.value,
                prjId1: this.project1Id === null || this.project1Id === undefined || this.project1Id === '' ? '0' : this.project1Id,
                prjId2: this.project2Id === null || this.project2Id === undefined || this.project2Id === '' ? '0' : this.project2Id,
                prjId3: this.project3Id === null || this.project3Id === undefined || this.project3Id === '' ? '0' : this.project3Id,
                prjId4: this.project4Id === null || this.project4Id === undefined || this.project4Id === '' ? '0' : this.project4Id,
                prjId5: this.project5Id === null || this.project5Id === undefined || this.project5Id === '' ? '0' : this.project5Id,
              },
              {
                workDate: this.week3,
                // tslint:disable-next-line:max-line-length
                prj1Hrs: this.projectsdata.controls.p1tues.value === null || this.projectsdata.controls.p1tues.value === '' || this.projectsdata.controls.p1tues.value === undefined ? '0' : this.projectsdata.controls.p1tues.value,
                // tslint:disable-next-line:max-line-length
                prj2Hrs: this.projectsdata.controls.p2tues.value === null || this.projectsdata.controls.p2tues.value === '' || this.projectsdata.controls.p2tues.value === undefined ? '0' : this.projectsdata.controls.p2tues.value,
                // tslint:disable-next-line:max-line-length
                internalHrs: this.projectsdata.controls.itues.value === null || this.projectsdata.controls.itues.value === '' || this.projectsdata.controls.itues.value === undefined ? '0' : this.projectsdata.controls.itues.value,
                // tslint:disable-next-line:max-line-length
                prj3Hrs: this.projectsdata.controls.p3tues.value === null || this.projectsdata.controls.p3tues.value === '' || this.projectsdata.controls.p3tues.value === undefined ? '0' : this.projectsdata.controls.p3tues.value,
                // tslint:disable-next-line:max-line-length
                prj4Hrs: this.projectsdata.controls.p4tues.value === null || this.projectsdata.controls.p4tues.value === '' || this.projectsdata.controls.p4tues.value === undefined ? '0' : this.projectsdata.controls.p4tues.value,
                // tslint:disable-next-line:max-line-length
                prj5Hrs: this.projectsdata.controls.p5tues.value === null || this.projectsdata.controls.p5tues.value === '' || this.projectsdata.controls.p5tues.value === undefined ? '0' : this.projectsdata.controls.p5tues.value,
                prjId1: this.project1Id === null || this.project1Id === undefined || this.project1Id === '' ? '0' : this.project1Id,
                prjId2: this.project2Id === null || this.project2Id === undefined || this.project2Id === '' ? '0' : this.project2Id,
                prjId3: this.project3Id === null || this.project3Id === undefined || this.project3Id === '' ? '0' : this.project3Id,
                prjId4: this.project4Id === null || this.project4Id === undefined || this.project4Id === '' ? '0' : this.project4Id,
                prjId5: this.project5Id === null || this.project5Id === undefined || this.project5Id === '' ? '0' : this.project5Id,
              },
              {
                workDate: this.week4,
                // tslint:disable-next-line:max-line-length
                prj1Hrs: this.projectsdata.controls.p1wed.value === null || this.projectsdata.controls.p1wed.value === '' || this.projectsdata.controls.p1wed.value === undefined ? '0' : this.projectsdata.controls.p1wed.value,
                // tslint:disable-next-line:max-line-length
                prj2Hrs: this.projectsdata.controls.p2wed.value === null || this.projectsdata.controls.p2wed.value === '' || this.projectsdata.controls.p2wed.value === undefined ? '0' : this.projectsdata.controls.p2wed.value,
                // tslint:disable-next-line:max-line-length
                internalHrs: this.projectsdata.controls.iwed.value === null || this.projectsdata.controls.iwed.value === '' || this.projectsdata.controls.iwed.value === undefined ? '0' : this.projectsdata.controls.iwed.value,
                // tslint:disable-next-line:max-line-length
                prj3Hrs: this.projectsdata.controls.p3wed.value === null || this.projectsdata.controls.p3wed.value === '' || this.projectsdata.controls.p3wed.value === undefined ? '0' : this.projectsdata.controls.p3wed.value,
                // tslint:disable-next-line:max-line-length
                prj4Hrs: this.projectsdata.controls.p4wed.value === null || this.projectsdata.controls.p4wed.value === '' || this.projectsdata.controls.p4wed.value === undefined ? '0' : this.projectsdata.controls.p4wed.value,
                // tslint:disable-next-line:max-line-length
                prj5Hrs: this.projectsdata.controls.p5wed.value === null || this.projectsdata.controls.p5wed.value === '' || this.projectsdata.controls.p5wed.value === undefined ? '0' : this.projectsdata.controls.p5wed.value,
                prjId1: this.project1Id === null || this.project1Id === undefined || this.project1Id === '' ? '0' : this.project1Id,
                prjId2: this.project2Id === null || this.project2Id === undefined || this.project2Id === '' ? '0' : this.project2Id,
                prjId3: this.project3Id === null || this.project3Id === undefined || this.project3Id === '' ? '0' : this.project3Id,
                prjId4: this.project4Id === null || this.project4Id === undefined || this.project4Id === '' ? '0' : this.project4Id,
                prjId5: this.project5Id === null || this.project5Id === undefined || this.project5Id === '' ? '0' : this.project5Id,
              },
              {
                workDate: this.week5,
                // tslint:disable-next-line:max-line-length
                prj1Hrs: this.projectsdata.controls.p1thurs.value === null || this.projectsdata.controls.p1thurs.value === '' || this.projectsdata.controls.p1thurs.value === undefined ? '0' : this.projectsdata.controls.p1thurs.value,
                // tslint:disable-next-line:max-line-length
                prj2Hrs: this.projectsdata.controls.p2thurs.value === null || this.projectsdata.controls.p2thurs.value === '' || this.projectsdata.controls.p2thurs.value === undefined ? '0' : this.projectsdata.controls.p2thurs.value,
                // tslint:disable-next-line:max-line-length
                internalHrs: this.projectsdata.controls.ithurs.value === null || this.projectsdata.controls.ithurs.value === '' || this.projectsdata.controls.ithurs.value === undefined ? '0' : this.projectsdata.controls.ithurs.value,
                // tslint:disable-next-line:max-line-length
                prj3Hrs: this.projectsdata.controls.p3thurs.value === null || this.projectsdata.controls.p3thurs.value === '' || this.projectsdata.controls.p3thurs.value === undefined ? '0' : this.projectsdata.controls.p3thurs.value,
                // tslint:disable-next-line:max-line-length
                prj4Hrs: this.projectsdata.controls.p4thurs.value === null || this.projectsdata.controls.p4thurs.value === '' || this.projectsdata.controls.p4thurs.value === undefined ? '0' : this.projectsdata.controls.p4thurs.value,
                // tslint:disable-next-line:max-line-length
                prj5Hrs: this.projectsdata.controls.p5thurs.value === null || this.projectsdata.controls.p5thurs.value === '' || this.projectsdata.controls.p5thurs.value === undefined ? '0' : this.projectsdata.controls.p5thurs.value,
                prjId1: this.project1Id === null || this.project1Id === undefined || this.project1Id === '' ? '0' : this.project1Id,
                prjId2: this.project2Id === null || this.project2Id === undefined || this.project2Id === '' ? '0' : this.project2Id,
                prjId3: this.project3Id === null || this.project3Id === undefined || this.project3Id === '' ? '0' : this.project3Id,
                prjId4: this.project4Id === null || this.project4Id === undefined || this.project4Id === '' ? '0' : this.project4Id,
                prjId5: this.project5Id === null || this.project5Id === undefined || this.project5Id === '' ? '0' : this.project5Id,
              },
              {
                workDate: this.week6,
                // tslint:disable-next-line:max-line-length
                prj1Hrs: this.projectsdata.controls.p1fri.value === null || this.projectsdata.controls.p1fri.value === '' || this.projectsdata.controls.p1fri.value === undefined ? '0' : this.projectsdata.controls.p1fri.value,
                // tslint:disable-next-line:max-line-length
                prj2Hrs: this.projectsdata.controls.p2fri.value === null || this.projectsdata.controls.p2fri.value === '' || this.projectsdata.controls.p2fri.value === undefined ? '0' : this.projectsdata.controls.p2fri.value,
                // tslint:disable-next-line:max-line-length
                internalHrs: this.projectsdata.controls.ifri.value === null || this.projectsdata.controls.ifri.value === '' || this.projectsdata.controls.ifri.value === undefined ? '0' : this.projectsdata.controls.ifri.value,
                // tslint:disable-next-line:max-line-length
                prj3Hrs: this.projectsdata.controls.p3fri.value === null || this.projectsdata.controls.p3fri.value === '' || this.projectsdata.controls.p3fri.value === undefined ? '0' : this.projectsdata.controls.p3fri.value,
                // tslint:disable-next-line:max-line-length
                prj4Hrs: this.projectsdata.controls.p4fri.value === null || this.projectsdata.controls.p4fri.value === '' || this.projectsdata.controls.p4fri.value === undefined ? '0' : this.projectsdata.controls.p4fri.value,
                // tslint:disable-next-line:max-line-length
                prj5Hrs: this.projectsdata.controls.p5fri.value === null || this.projectsdata.controls.p5fri.value === '' || this.projectsdata.controls.p5fri.value === undefined ? '0' : this.projectsdata.controls.p5fri.value,
                prjId1: this.project1Id === null || this.project1Id === undefined || this.project1Id === '' ? '0' : this.project1Id,
                prjId2: this.project2Id === null || this.project2Id === undefined || this.project2Id === '' ? '0' : this.project2Id,
                prjId3: this.project3Id === null || this.project3Id === undefined || this.project3Id === '' ? '0' : this.project3Id,
                prjId4: this.project4Id === null || this.project4Id === undefined || this.project4Id === '' ? '0' : this.project4Id,
                prjId5: this.project5Id === null || this.project5Id === undefined || this.project5Id === '' ? '0' : this.project5Id,
              },
              {
                workDate: this.week7,
                // tslint:disable-next-line: max-line-length
                prj1Hrs: this.projectsdata.controls.p1sat.value === null || this.projectsdata.controls.p1sat.value === '' || this.projectsdata.controls.p1sat.value === undefined ? '0' : this.projectsdata.controls.p1sat.value,
                // tslint:disable-next-line: max-line-length
                prj2Hrs: this.projectsdata.controls.p2sat.value === null || this.projectsdata.controls.p2sat.value === '' || this.projectsdata.controls.p2sat.value === undefined ? '0' : this.projectsdata.controls.p2sat.value,
                // tslint:disable-next-line: max-line-length
                internalHrs: this.projectsdata.controls.isat.value === null || this.projectsdata.controls.isat.value === '' || this.projectsdata.controls.isat.value === undefined ? '0' : this.projectsdata.controls.isat.value,
                // tslint:disable-next-line: max-line-length
                prj3Hrs: this.projectsdata.controls.p3sat.value === null || this.projectsdata.controls.p3sat.value === '' || this.projectsdata.controls.p3sat.value === undefined ? '0' : this.projectsdata.controls.p3sat.value,
                // tslint:disable-next-line: max-line-length
                prj4Hrs: this.projectsdata.controls.p4sat.value === null || this.projectsdata.controls.p4sat.value === '' || this.projectsdata.controls.p4sat.value === undefined ? '0' : this.projectsdata.controls.p4sat.value,
                // tslint:disable-next-line: max-line-length
                prj5Hrs: this.projectsdata.controls.p5sat.value === null || this.projectsdata.controls.p5sat.value === '' || this.projectsdata.controls.p5sat.value === undefined ? '0' : this.projectsdata.controls.p5sat.value,
                prjId1: this.project1Id === null || this.project1Id === undefined || this.project1Id === '' ? '0' : this.project1Id,
                prjId2: this.project2Id === null || this.project2Id === undefined || this.project2Id === '' ? '0' : this.project2Id,
                prjId3: this.project3Id === null || this.project3Id === undefined || this.project3Id === '' ? '0' : this.project3Id,
                prjId4: this.project4Id === null || this.project4Id === undefined || this.project4Id === '' ? '0' : this.project4Id,
                prjId5: this.project5Id === null || this.project5Id === undefined || this.project5Id === '' ? '0' : this.project5Id,
              }

            ]
          };
          this.loader = true;
          this.apiservice.postApi(environment.addtimesheetgrid, data).subscribe((res: any) => {
            this.loader = false;
            if (res.hasOwnProperty('success') === true) {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
              setTimeout(() => {
                this.loader = false;
                this.router.navigate(['/time-sheets']
                );
              }, 2000);
            } else if (res.hasOwnProperty('failed') === true) {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
            } else {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
            }
          });

        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.fileRes.error });
        }
      });
    }
    else {
      this.file = this.fileRes?.success === true ? 1 : 0;
      const data = {
        poId: 0,
        invoiceId: 0,
        timesheetId: this.getdata,
        lkStatus: this.submitTimeSheetLkStatus,
        startDate: this.firstday,
        endDate: this.lastday,
        totalHrs: this.totalhours,
        firstPrjId: this.project1Id,
        statusChangedDate: this.datepipe.transform(this.currentDate, 'yyyy-MM-dd HH:mm:ss'),
        // tslint:disable-next-line:max-line-length
        notes: this.timesheetNote.controls.notes.value === null || this.timesheetNote.controls.notes.value === '' || this.timesheetNote.controls.notes.value === undefined ? '' : this.timesheetNote.controls.notes.value,
        resourceType: 'E',
        filePath: this.filedata !== undefined ? this.filePath : '',
        fileFlag: this.file,
        draftedBy: this.submitUserId,
        // tslint:disable-next-line: max-line-length
        draftedDate: this.drafttimeSheetLkStatus === 'D' ? this.datepipe.transform(this.currentDate, 'yyyy-MM-dd HH:mm:ss') : '0000:00:00',
        // tslint:disable-next-line: max-line-length
        submittedDate: this.addTimeSheetLkStatus === 'S' ? this.datepipe.transform(this.currentDate, 'yyyy-MM-dd HH:mm:ss') : '0000:00:00',
        timesheetLines: [
          {
            workDate: this.week1,
            // tslint:disable-next-line:max-line-length
            prj1Hrs: this.projectsdata.controls.p1sun.value === null || this.projectsdata.controls.p1sun.value === '' || this.projectsdata.controls.p1sun.value === undefined ? '0' : this.projectsdata.controls.p1sun.value,
            // tslint:disable-next-line:max-line-length
            prj2Hrs: this.projectsdata.controls.p2sun.value === null || this.projectsdata.controls.p2sun.value === '' || this.projectsdata.controls.p2sun.value === undefined ? '0' : this.projectsdata.controls.p2sun.value,
            // tslint:disable-next-line:max-line-length
            internalHrs: this.projectsdata.controls.isun.value === null || this.projectsdata.controls.isun.value === '' || this.projectsdata.controls.isun.value === undefined ? '0' : this.projectsdata.controls.isun.value,
            // tslint:disable-next-line:max-line-length
            prj3Hrs: this.projectsdata.controls.p3sun.value === null || this.projectsdata.controls.p3sun.value === '' || this.projectsdata.controls.p3sun.value === undefined ? '0' : this.projectsdata.controls.p3sun.value,
            // tslint:disable-next-line:max-line-length
            prj4Hrs: this.projectsdata.controls.p4sun.value === null || this.projectsdata.controls.p4sun.value === '' || this.projectsdata.controls.p4sun.value === undefined ? '0' : this.projectsdata.controls.p4sun.value,
            // tslint:disable-next-line:max-line-length
            prj5Hrs: this.projectsdata.controls.p5sun.value === null || this.projectsdata.controls.p5sun.value === '' || this.projectsdata.controls.p5sun.value === undefined ? '0' : this.projectsdata.controls.p5sun.value,
            prjId1: this.project1Id === null || this.project1Id === undefined || this.project1Id === '' ? '0' : this.project1Id,
            prjId2: this.project2Id === null || this.project2Id === undefined || this.project2Id === '' ? '0' : this.project2Id,
            prjId3: this.project3Id === null || this.project3Id === undefined || this.project3Id === '' ? '0' : this.project3Id,
            prjId4: this.project4Id === null || this.project4Id === undefined || this.project4Id === '' ? '0' : this.project4Id,
            prjId5: this.project5Id === null || this.project5Id === undefined || this.project5Id === '' ? '0' : this.project5Id,
          },
          {
            workDate: this.week2,
            // tslint:disable-next-line:max-line-length
            prj1Hrs: this.projectsdata.controls.p1mon.value === null || this.projectsdata.controls.p1mon.value === '' || this.projectsdata.controls.p1mon.value === undefined ? '0' : this.projectsdata.controls.p1mon.value,
            // tslint:disable-next-line:max-line-length
            prj2Hrs: this.projectsdata.controls.p2mon.value === null || this.projectsdata.controls.p2mon.value === '' || this.projectsdata.controls.p2mon.value === undefined ? '0' : this.projectsdata.controls.p2mon.value,
            // tslint:disable-next-line:max-line-length
            internalHrs: this.projectsdata.controls.imon.value === null || this.projectsdata.controls.imon.value === '' || this.projectsdata.controls.imon.value === undefined ? '0' : this.projectsdata.controls.imon.value,
            // tslint:disable-next-line:max-line-length
            prj3Hrs: this.projectsdata.controls.p3mon.value === null || this.projectsdata.controls.p3mon.value === '' || this.projectsdata.controls.p3mon.value === undefined ? '0' : this.projectsdata.controls.p3mon.value,
            // tslint:disable-next-line:max-line-length
            prj4Hrs: this.projectsdata.controls.p4mon.value === null || this.projectsdata.controls.p4mon.value === '' || this.projectsdata.controls.p4mon.value === undefined ? '0' : this.projectsdata.controls.p4mon.value,
            // tslint:disable-next-line:max-line-length
            prj5Hrs: this.projectsdata.controls.p5mon.value === null || this.projectsdata.controls.p5mon.value === '' || this.projectsdata.controls.p5mon.value === undefined ? '0' : this.projectsdata.controls.p5mon.value,
            prjId1: this.project1Id === null || this.project1Id === undefined || this.project1Id === '' ? '0' : this.project1Id,
            prjId2: this.project2Id === null || this.project2Id === undefined || this.project2Id === '' ? '0' : this.project2Id,
            prjId3: this.project3Id === null || this.project3Id === undefined || this.project3Id === '' ? '0' : this.project3Id,
            prjId4: this.project4Id === null || this.project4Id === undefined || this.project4Id === '' ? '0' : this.project4Id,
            prjId5: this.project5Id === null || this.project5Id === undefined || this.project5Id === '' ? '0' : this.project5Id,
          },
          {
            workDate: this.week3,
            // tslint:disable-next-line:max-line-length
            prj1Hrs: this.projectsdata.controls.p1tues.value === null || this.projectsdata.controls.p1tues.value === '' || this.projectsdata.controls.p1tues.value === undefined ? '0' : this.projectsdata.controls.p1tues.value,
            // tslint:disable-next-line:max-line-length
            prj2Hrs: this.projectsdata.controls.p2tues.value === null || this.projectsdata.controls.p2tues.value === '' || this.projectsdata.controls.p2tues.value === undefined ? '0' : this.projectsdata.controls.p2tues.value,
            // tslint:disable-next-line:max-line-length
            internalHrs: this.projectsdata.controls.itues.value === null || this.projectsdata.controls.itues.value === '' || this.projectsdata.controls.itues.value === undefined ? '0' : this.projectsdata.controls.itues.value,
            // tslint:disable-next-line:max-line-length
            prj3Hrs: this.projectsdata.controls.p3tues.value === null || this.projectsdata.controls.p3tues.value === '' || this.projectsdata.controls.p3tues.value === undefined ? '0' : this.projectsdata.controls.p3tues.value,
            // tslint:disable-next-line:max-line-length
            prj4Hrs: this.projectsdata.controls.p4tues.value === null || this.projectsdata.controls.p4tues.value === '' || this.projectsdata.controls.p4tues.value === undefined ? '0' : this.projectsdata.controls.p4tues.value,
            // tslint:disable-next-line:max-line-length
            prj5Hrs: this.projectsdata.controls.p5tues.value === null || this.projectsdata.controls.p5tues.value === '' || this.projectsdata.controls.p5tues.value === undefined ? '0' : this.projectsdata.controls.p5tues.value,
            prjId1: this.project1Id === null || this.project1Id === undefined || this.project1Id === '' ? '0' : this.project1Id,
            prjId2: this.project2Id === null || this.project2Id === undefined || this.project2Id === '' ? '0' : this.project2Id,
            prjId3: this.project3Id === null || this.project3Id === undefined || this.project3Id === '' ? '0' : this.project3Id,
            prjId4: this.project4Id === null || this.project4Id === undefined || this.project4Id === '' ? '0' : this.project4Id,
            prjId5: this.project5Id === null || this.project5Id === undefined || this.project5Id === '' ? '0' : this.project5Id,
          },
          {
            workDate: this.week4,
            // tslint:disable-next-line:max-line-length
            prj1Hrs: this.projectsdata.controls.p1wed.value === null || this.projectsdata.controls.p1wed.value === '' || this.projectsdata.controls.p1wed.value === undefined ? '0' : this.projectsdata.controls.p1wed.value,
            // tslint:disable-next-line:max-line-length
            prj2Hrs: this.projectsdata.controls.p2wed.value === null || this.projectsdata.controls.p2wed.value === '' || this.projectsdata.controls.p2wed.value === undefined ? '0' : this.projectsdata.controls.p2wed.value,
            // tslint:disable-next-line:max-line-length
            internalHrs: this.projectsdata.controls.iwed.value === null || this.projectsdata.controls.iwed.value === '' || this.projectsdata.controls.iwed.value === undefined ? '0' : this.projectsdata.controls.iwed.value,
            // tslint:disable-next-line:max-line-length
            prj3Hrs: this.projectsdata.controls.p3wed.value === null || this.projectsdata.controls.p3wed.value === '' || this.projectsdata.controls.p3wed.value === undefined ? '0' : this.projectsdata.controls.p3wed.value,
            // tslint:disable-next-line:max-line-length
            prj4Hrs: this.projectsdata.controls.p4wed.value === null || this.projectsdata.controls.p4wed.value === '' || this.projectsdata.controls.p4wed.value === undefined ? '0' : this.projectsdata.controls.p4wed.value,
            // tslint:disable-next-line:max-line-length
            prj5Hrs: this.projectsdata.controls.p5wed.value === null || this.projectsdata.controls.p5wed.value === '' || this.projectsdata.controls.p5wed.value === undefined ? '0' : this.projectsdata.controls.p5wed.value,
            prjId1: this.project1Id === null || this.project1Id === undefined || this.project1Id === '' ? '0' : this.project1Id,
            prjId2: this.project2Id === null || this.project2Id === undefined || this.project2Id === '' ? '0' : this.project2Id,
            prjId3: this.project3Id === null || this.project3Id === undefined || this.project3Id === '' ? '0' : this.project3Id,
            prjId4: this.project4Id === null || this.project4Id === undefined || this.project4Id === '' ? '0' : this.project4Id,
            prjId5: this.project5Id === null || this.project5Id === undefined || this.project5Id === '' ? '0' : this.project5Id,
          },
          {
            workDate: this.week5,
            // tslint:disable-next-line:max-line-length
            prj1Hrs: this.projectsdata.controls.p1thurs.value === null || this.projectsdata.controls.p1thurs.value === '' || this.projectsdata.controls.p1thurs.value === undefined ? '0' : this.projectsdata.controls.p1thurs.value,
            // tslint:disable-next-line:max-line-length
            prj2Hrs: this.projectsdata.controls.p2thurs.value === null || this.projectsdata.controls.p2thurs.value === '' || this.projectsdata.controls.p2thurs.value === undefined ? '0' : this.projectsdata.controls.p2thurs.value,
            // tslint:disable-next-line:max-line-length
            internalHrs: this.projectsdata.controls.ithurs.value === null || this.projectsdata.controls.ithurs.value === '' || this.projectsdata.controls.ithurs.value === undefined ? '0' : this.projectsdata.controls.ithurs.value,
            // tslint:disable-next-line:max-line-length
            prj3Hrs: this.projectsdata.controls.p3thurs.value === null || this.projectsdata.controls.p3thurs.value === '' || this.projectsdata.controls.p3thurs.value === undefined ? '0' : this.projectsdata.controls.p3thurs.value,
            // tslint:disable-next-line:max-line-length
            prj4Hrs: this.projectsdata.controls.p4thurs.value === null || this.projectsdata.controls.p4thurs.value === '' || this.projectsdata.controls.p4thurs.value === undefined ? '0' : this.projectsdata.controls.p4thurs.value,
            // tslint:disable-next-line:max-line-length
            prj5Hrs: this.projectsdata.controls.p5thurs.value === null || this.projectsdata.controls.p5thurs.value === '' || this.projectsdata.controls.p5thurs.value === undefined ? '0' : this.projectsdata.controls.p5thurs.value,
            prjId1: this.project1Id === null || this.project1Id === undefined || this.project1Id === '' ? '0' : this.project1Id,
            prjId2: this.project2Id === null || this.project2Id === undefined || this.project2Id === '' ? '0' : this.project2Id,
            prjId3: this.project3Id === null || this.project3Id === undefined || this.project3Id === '' ? '0' : this.project3Id,
            prjId4: this.project4Id === null || this.project4Id === undefined || this.project4Id === '' ? '0' : this.project4Id,
            prjId5: this.project5Id === null || this.project5Id === undefined || this.project5Id === '' ? '0' : this.project5Id,
          },
          {
            workDate: this.week6,
            // tslint:disable-next-line:max-line-length
            prj1Hrs: this.projectsdata.controls.p1fri.value === null || this.projectsdata.controls.p1fri.value === '' || this.projectsdata.controls.p1fri.value === undefined ? '0' : this.projectsdata.controls.p1fri.value,
            // tslint:disable-next-line:max-line-length
            prj2Hrs: this.projectsdata.controls.p2fri.value === null || this.projectsdata.controls.p2fri.value === '' || this.projectsdata.controls.p2fri.value === undefined ? '0' : this.projectsdata.controls.p2fri.value,
            // tslint:disable-next-line:max-line-length
            internalHrs: this.projectsdata.controls.ifri.value === null || this.projectsdata.controls.ifri.value === '' || this.projectsdata.controls.ifri.value === undefined ? '0' : this.projectsdata.controls.ifri.value,
            // tslint:disable-next-line:max-line-length
            prj3Hrs: this.projectsdata.controls.p3fri.value === null || this.projectsdata.controls.p3fri.value === '' || this.projectsdata.controls.p3fri.value === undefined ? '0' : this.projectsdata.controls.p3fri.value,
            // tslint:disable-next-line:max-line-length
            prj4Hrs: this.projectsdata.controls.p4fri.value === null || this.projectsdata.controls.p4fri.value === '' || this.projectsdata.controls.p4fri.value === undefined ? '0' : this.projectsdata.controls.p4fri.value,
            // tslint:disable-next-line:max-line-length
            prj5Hrs: this.projectsdata.controls.p5fri.value === null || this.projectsdata.controls.p5fri.value === '' || this.projectsdata.controls.p5fri.value === undefined ? '0' : this.projectsdata.controls.p5fri.value,
            prjId1: this.project1Id === null || this.project1Id === undefined || this.project1Id === '' ? '0' : this.project1Id,
            prjId2: this.project2Id === null || this.project2Id === undefined || this.project2Id === '' ? '0' : this.project2Id,
            prjId3: this.project3Id === null || this.project3Id === undefined || this.project3Id === '' ? '0' : this.project3Id,
            prjId4: this.project4Id === null || this.project4Id === undefined || this.project4Id === '' ? '0' : this.project4Id,
            prjId5: this.project5Id === null || this.project5Id === undefined || this.project5Id === '' ? '0' : this.project5Id,
          },
          {
            workDate: this.week7,
            // tslint:disable-next-line: max-line-length
            prj1Hrs: this.projectsdata.controls.p1sat.value === null || this.projectsdata.controls.p1sat.value === '' || this.projectsdata.controls.p1sat.value === undefined ? '0' : this.projectsdata.controls.p1sat.value,
            // tslint:disable-next-line: max-line-length
            prj2Hrs: this.projectsdata.controls.p2sat.value === null || this.projectsdata.controls.p2sat.value === '' || this.projectsdata.controls.p2sat.value === undefined ? '0' : this.projectsdata.controls.p2sat.value,
            // tslint:disable-next-line: max-line-length
            internalHrs: this.projectsdata.controls.isat.value === null || this.projectsdata.controls.isat.value === '' || this.projectsdata.controls.isat.value === undefined ? '0' : this.projectsdata.controls.isat.value,
            // tslint:disable-next-line: max-line-length
            prj3Hrs: this.projectsdata.controls.p3sat.value === null || this.projectsdata.controls.p3sat.value === '' || this.projectsdata.controls.p3sat.value === undefined ? '0' : this.projectsdata.controls.p3sat.value,
            // tslint:disable-next-line: max-line-length
            prj4Hrs: this.projectsdata.controls.p4sat.value === null || this.projectsdata.controls.p4sat.value === '' || this.projectsdata.controls.p4sat.value === undefined ? '0' : this.projectsdata.controls.p4sat.value,
            // tslint:disable-next-line: max-line-length
            prj5Hrs: this.projectsdata.controls.p5sat.value === null || this.projectsdata.controls.p5sat.value === '' || this.projectsdata.controls.p5sat.value === undefined ? '0' : this.projectsdata.controls.p5sat.value,
            prjId1: this.project1Id === null || this.project1Id === undefined || this.project1Id === '' ? '0' : this.project1Id,
            prjId2: this.project2Id === null || this.project2Id === undefined || this.project2Id === '' ? '0' : this.project2Id,
            prjId3: this.project3Id === null || this.project3Id === undefined || this.project3Id === '' ? '0' : this.project3Id,
            prjId4: this.project4Id === null || this.project4Id === undefined || this.project4Id === '' ? '0' : this.project4Id,
            prjId5: this.project5Id === null || this.project5Id === undefined || this.project5Id === '' ? '0' : this.project5Id,
          }

        ]
      };
      this.loader = true;
      this.apiservice.postApi(environment.addtimesheetgrid, data).subscribe((res: any) => {
        this.loader = false;
        if (res.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
          setTimeout(() => {
            this.loader = false;
            this.router.navigate(['/time-sheets']
            );
          }, 2000);
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
        }
      });
    }
  }
  // Method to calculate total hours
  calculateTotalHours() {
    if (this.projectsdata.get('p1sun').value === '') {
      this.project1Sun = 0;
    } else {
      this.project1Sun = this.projectsdata.get('p1sun').value;
    }
    if (this.projectsdata.get('p2sun').value === '') {
      this.project2Sun = 0;
    } else {
      this.project2Sun = this.projectsdata.get('p2sun').value;
    }
    if (this.projectsdata.get('p3sun').value === '') {
      this.project3Sun = 0;
    } else {
      this.project3Sun = this.projectsdata.get('p3sun').value;
    }
    if (this.projectsdata.get('p4sun').value === '') {
      this.project4Sun = 0;
    } else {
      this.project4Sun = this.projectsdata.get('p4sun').value;
    }
    if (this.projectsdata.get('p5sun').value === '') {
      this.project5Sun = 0;
    } else {
      this.project5Sun = this.projectsdata.get('p5sun').value;
    }
    if (this.projectsdata.get('isun').value === '') {
      this.projectiSun = 0;
    } else {
      this.projectiSun = this.projectsdata.get('isun').value;
    }
    this.sundayHours = this.project1Sun + this.project2Sun + this.project3Sun + this.project4Sun + this.project5Sun + this.projectiSun;
    if (this.sundayHours >= 25) {
      Swal.fire({
        text: 'You cannot enter more than 24 hours',
        confirmButtonColor: '#0D416B',
        iconColor: '#1dbb99',
        confirmButtonText: 'I Understand',
        reverseButtons: false,
      });
      this.projectsdata.controls.p1sun.reset();
      this.projectsdata.controls.p2sun.reset();
      this.projectsdata.controls.p3sun.reset();
      this.projectsdata.controls.p4sun.reset();
      this.projectsdata.controls.p5sun.reset();
      this.projectsdata.controls.isun.reset();
      this.projectsdata.controls.tsun.reset();
    } else if (this.sundayHours === '00000') {
      this.projectsdata.get('tsun').patchValue(0);
    }
    else {
      // tslint:disable-next-line:radix
      this.projectsdata.get('tsun').patchValue(parseInt(this.sundayHours));
    }
    if (this.projectsdata.get('p1mon').value === '') {
      this.project1mon = 0;
    } else {
      this.project1mon = this.projectsdata.get('p1mon').value;
    }
    if (this.projectsdata.get('p2mon').value === '') {
      this.project2mon = 0;
    } else {
      this.project2mon = this.projectsdata.get('p2mon').value;
    }
    if (this.projectsdata.get('p3mon').value === '') {
      this.project3mon = 0;
    } else {
      this.project3mon = this.projectsdata.get('p3mon').value;
    }
    if (this.projectsdata.get('p4mon').value === '') {
      this.project4mon = 0;
    } else {
      this.project4mon = this.projectsdata.get('p4mon').value;
    }
    if (this.projectsdata.get('p5mon').value === '') {
      this.project5mon = 0;
    } else {
      this.project5mon = this.projectsdata.get('p5mon').value;
    }
    if (this.projectsdata.get('imon').value === '') {
      this.projectimon = 0;
    } else {
      this.projectimon = this.projectsdata.get('imon').value;
    }
    // tslint:disable-next-line:max-line-length
    this.mondayHours = this.project1mon + this.project2mon + this.project3mon + this.project4mon + this.project5mon + this.projectimon;
    if (this.mondayHours >= 25) {
      Swal.fire({
        text: 'You cannot enter more than 24 hours',
        confirmButtonColor: '#0D416B',
        iconColor: '#1dbb99',
        confirmButtonText: 'I Understand',
        reverseButtons: false,
      });
      this.projectsdata.controls.p1mon.reset();
      this.projectsdata.controls.p2mon.reset();
      this.projectsdata.controls.p3mon.reset();
      this.projectsdata.controls.p4mon.reset();
      this.projectsdata.controls.p5mon.reset();
      this.projectsdata.controls.imon.reset();
      this.projectsdata.controls.tmon.reset();
    } else if (this.mondayHours === '00000') {
      this.projectsdata.get('tmon').patchValue(0);
    }
    else {
      this.projectsdata.get('tmon').patchValue(this.mondayHours);
    }
    if (this.projectsdata.get('p1tues').value === '') {
      this.project1tues = 0;
    } else {
      this.project1tues = this.projectsdata.get('p1tues').value;
    }
    if (this.projectsdata.get('p2tues').value === '') {
      this.project2tues = 0;
    } else {
      this.project2tues = this.projectsdata.get('p2tues').value;
    }
    if (this.projectsdata.get('p3tues').value === '') {
      this.project3tues = 0;
    } else {
      this.project3tues = this.projectsdata.get('p3tues').value;
    }
    if (this.projectsdata.get('p4tues').value === '') {
      this.project4tues = 0;
    } else {
      this.project4tues = this.projectsdata.get('p4tues').value;
    }
    if (this.projectsdata.get('p5tues').value === '') {
      this.project5tues = 0;
    } else {
      this.project5tues = this.projectsdata.get('p5tues').value;
    }
    if (this.projectsdata.get('itues').value === '') {
      this.projectitues = 0;
    } else {
      this.projectitues = this.projectsdata.get('itues').value;
    }
    // tslint:disable-next-line:max-line-length
    this.tuesdayHours = this.project1tues + this.project2tues + this.project3tues + this.project4tues + this.project5tues + this.projectitues;
    if (this.tuesdayHours >= 25) {
      Swal.fire({
        text: 'You cannot enter more than 24 hours',
        confirmButtonColor: '#0D416B',
        iconColor: '#1dbb99',
        confirmButtonText: 'I Understand',
        reverseButtons: false,
      });
      this.projectsdata.controls.p1tues.reset();
      this.projectsdata.controls.p2tues.reset();
      this.projectsdata.controls.p3tues.reset();
      this.projectsdata.controls.p4tues.reset();
      this.projectsdata.controls.p5tues.reset();
      this.projectsdata.controls.itues.reset();
      this.projectsdata.controls.ttues.reset();
    } else if (this.tuesdayHours === '00000') {
      this.projectsdata.get('ttues').patchValue(0);
    }
    else {
      this.projectsdata.get('ttues').patchValue(this.tuesdayHours);
    }
    if (this.projectsdata.get('p1wed').value === '') {
      this.project1wed = 0;
    } else {
      this.project1wed = this.projectsdata.get('p1wed').value;
    }
    if (this.projectsdata.get('p2wed').value === '') {
      this.project2wed = 0;
    } else {
      this.project2wed = this.projectsdata.get('p2wed').value;
    }
    if (this.projectsdata.get('p3wed').value === '') {
      this.project3wed = 0;
    } else {
      this.project3wed = this.projectsdata.get('p3wed').value;
    }
    if (this.projectsdata.get('p4wed').value === '') {
      this.project4wed = 0;
    } else {
      this.project4wed = this.projectsdata.get('p4wed').value;
    }
    if (this.projectsdata.get('p5wed').value === '') {
      this.project5wed = 0;
    } else {
      this.project5wed = this.projectsdata.get('p5wed').value;
    }
    if (this.projectsdata.get('iwed').value === '') {
      this.projectiwed = 0;
    } else {
      this.projectiwed = this.projectsdata.get('iwed').value;
    }
    // tslint:disable-next-line:max-line-length
    this.wednesdayHours = this.project1wed + this.project2wed + this.project3wed + this.project4wed + this.project5wed + this.projectiwed;
    if (this.wednesdayHours >= 25) {
      Swal.fire({
        text: 'You cannot enter more than 24 hours',
        confirmButtonColor: '#0D416B',
        iconColor: '#1dbb99',
        confirmButtonText: 'I Understand',
        reverseButtons: false,
      });
      this.projectsdata.controls.p1wed.reset();
      this.projectsdata.controls.p2wed.reset();
      this.projectsdata.controls.p3wed.reset();
      this.projectsdata.controls.p4wed.reset();
      this.projectsdata.controls.p5wed.reset();
      this.projectsdata.controls.iwed.reset();
      this.projectsdata.controls.twed.reset();
    } else if (this.wednesdayHours === '00000') {
      this.projectsdata.get('twed').patchValue(0);
    }
    else {
      this.projectsdata.get('twed').patchValue(this.wednesdayHours);
    }
    if (this.projectsdata.get('p1thurs').value === '') {
      this.project1thurs = 0;
    } else {
      this.project1thurs = this.projectsdata.get('p1thurs').value;
    }
    if (this.projectsdata.get('p2thurs').value === '') {
      this.project2thurs = 0;
    } else {
      this.project2thurs = this.projectsdata.get('p2thurs').value;
    }
    if (this.projectsdata.get('p3thurs').value === '') {
      this.project3thurs = 0;
    } else {
      this.project3thurs = this.projectsdata.get('p3thurs').value;
    }
    if (this.projectsdata.get('p4thurs').value === '') {
      this.project4thurs = 0;
    } else {
      this.project4thurs = this.projectsdata.get('p4thurs').value;
    }
    if (this.projectsdata.get('p5thurs').value === '') {
      this.project5thurs = 0;
    } else {
      this.project5thurs = this.projectsdata.get('p5thurs').value;
    }
    if (this.projectsdata.get('ithurs').value === '') {
      this.projectithurs = 0;
    } else {
      this.projectithurs = this.projectsdata.get('ithurs').value;
    }
    // tslint:disable-next-line:max-line-length
    this.thursdayHours = this.project1thurs + this.project2thurs + this.project3thurs + this.project4thurs + this.project5thurs + this.projectithurs;
    if (this.thursdayHours >= 25) {
      Swal.fire({
        text: 'You cannot enter more than 24 hours',
        confirmButtonColor: '#0D416B',
        iconColor: '#1dbb99',
        confirmButtonText: 'I Understand',
        reverseButtons: false,
      });
      this.projectsdata.controls.p1thurs.reset();
      this.projectsdata.controls.p2thurs.reset();
      this.projectsdata.controls.p3thurs.reset();
      this.projectsdata.controls.p4thurs.reset();
      this.projectsdata.controls.p5thurs.reset();
      this.projectsdata.controls.ithurs.reset();
      this.projectsdata.controls.tthurs.reset();
    } else if (this.thursdayHours === '00000') {
      this.projectsdata.get('tthurs').patchValue(0);
    }
    else {
      this.projectsdata.get('tthurs').patchValue(this.thursdayHours);
    }
    if (this.projectsdata.get('p1fri').value === '') {
      this.project1fri = 0;
    } else {
      this.project1fri = this.projectsdata.get('p1fri').value;
    }
    if (this.projectsdata.get('p2fri').value === '') {
      this.project2fri = 0;
    } else {
      this.project2fri = this.projectsdata.get('p2fri').value;
    }
    if (this.projectsdata.get('p3fri').value === '') {
      this.project3fri = 0;
    } else {
      this.project3fri = this.projectsdata.get('p3fri').value;
    }
    if (this.projectsdata.get('p4fri').value === '') {
      this.project4fri = 0;
    } else {
      this.project4fri = this.projectsdata.get('p4fri').value;
    }
    if (this.projectsdata.get('p5fri').value === '') {
      this.project5fri = 0;
    } else {
      this.project5fri = this.projectsdata.get('p5fri').value;
    }
    if (this.projectsdata.get('ifri').value === '') {
      this.projectifri = 0;
    } else {
      this.projectifri = this.projectsdata.get('ifri').value;
    }
    // tslint:disable-next-line:max-line-length
    this.fridayHours = this.project1fri + this.project2fri + this.project3fri + this.project4fri + this.project5fri + this.projectifri;
    if (this.fridayHours >= 25) {
      Swal.fire({
        text: 'You cannot enter more than 24 hours',
        confirmButtonColor: '#0D416B',
        iconColor: '#1dbb99',
        confirmButtonText: 'I Understand',
        reverseButtons: false,
      });
      this.projectsdata.controls.p1fri.reset();
      this.projectsdata.controls.p2fri.reset();
      this.projectsdata.controls.p3fri.reset();
      this.projectsdata.controls.p4fri.reset();
      this.projectsdata.controls.p5fri.reset();
      this.projectsdata.controls.ifri.reset();
      this.projectsdata.controls.tfri.reset();
    } else if (this.fridayHours === '00000') {
      this.projectsdata.get('tfri').patchValue(0);
    }
    else {
      this.projectsdata.get('tfri').patchValue(this.fridayHours);
    }
    if (this.projectsdata.get('p1sat').value === '') {
      this.project1sat = 0;
    } else {
      this.project1sat = this.projectsdata.get('p1sat').value;
    }
    if (this.projectsdata.get('p2sat').value === '') {
      this.project2sat = 0;
    } else {
      this.project2sat = this.projectsdata.get('p2sat').value;
    }
    if (this.projectsdata.get('p3sat').value === '') {
      this.project3sat = 0;
    } else {
      this.project3sat = this.projectsdata.get('p3sat').value;
    }
    if (this.projectsdata.get('p4sat').value === '') {
      this.project4sat = 0;
    } else {
      this.project4sat = this.projectsdata.get('p4sat').value;
    }
    if (this.projectsdata.get('p5sat').value === '') {
      this.project5sat = 0;
    } else {
      this.project5sat = this.projectsdata.get('p5sat').value;
    }
    if (this.projectsdata.get('isat').value === '') {
      this.projectisat = 0;
    } else {
      this.projectisat = this.projectsdata.get('isat').value;
    }
    // tslint:disable-next-line:max-line-length
    this.saturdayHours = this.project1sat + this.project2sat + this.project3sat + this.project4sat + this.project5sat + this.projectisat;
    if (this.saturdayHours >= 25) {
      Swal.fire({
        text: 'You cannot enter more than 24 hours',
        confirmButtonColor: '#0D416B',
        iconColor: '#1dbb99',
        confirmButtonText: 'I Understand',
        reverseButtons: false,
      });
      this.projectsdata.controls.p1sat.reset();
      this.projectsdata.controls.p2sat.reset();
      this.projectsdata.controls.p3sat.reset();
      this.projectsdata.controls.p4sat.reset();
      this.projectsdata.controls.p5sat.reset();
      this.projectsdata.controls.isat.reset();
      this.projectsdata.controls.tsat.reset();
    } else if (this.saturdayHours === '00000') {
      this.projectsdata.get('tsat').patchValue(0);
    }
    else {
      this.projectsdata.get('tsat').patchValue(this.saturdayHours);
    }
    // tslint:disable-next-line:max-line-length
    this.totalhours = parseInt(this.sundayHours) + parseInt(this.mondayHours) + parseInt(this.tuesdayHours) + parseInt(this.wednesdayHours) + parseInt(this.thursdayHours) + parseInt(this.fridayHours) + parseInt(this.saturdayHours);

  }
  // Method to submit timesheet
  submitTimesheet(event) {
    if (this.totalhours === undefined) {
      this.submittotalhours = 0;
    } else {
      this.submittotalhours = this.totalhours;
    }
    Swal.fire({
      text: 'Arey you sure you want to submit the timesheet for the week with ' + this.submittotalhours + ' hours',
      icon: 'warning', showCancelButton: true, confirmButtonColor: '#0D416B', cancelButtonText: 'Cancel', confirmButtonText: 'Submit',
    }).then((result) => {
      if (result.value) {
        return this.addtimesheet('S');
      }
    });
  }
  // Method to navigate to previous screen
  goBack() {
    this.router.navigate(['/time-sheets']);
  }
  // Method to download user image
  downloadImage() {
    if (this.roles === 11) {
      this.dwnimageid = this.actId.imageId;
    } else {
      this.dwnimageid = this.downloadimageid;
    }
    this.apiservice.getApi(environment.downloadres + this.dwnimageid).subscribe((result) => {
      this.profileimage = result.downloadUrl;
      if (this.profileimage === '' || this.profileimage === null || this.profileimage === undefined) {
        if (this.actId.gender === 'M') {
          this.profileimage = 'https://d2r8nxfsv3v6y3.cloudfront.net/images/male.png';
        }
        else {
          this.profileimage = 'https://d2r8nxfsv3v6y3.cloudfront.net/images/female.png';
        }
      }
    });
  }
  // Method to get the data of submitted timesheet
  editData() {
    this.loader = true;
    this.apiservice.getApi(environment.rowEdit + this.id).subscribe(res => {
      this.loader = false;
      this.projects = res.data;
      this.downloadflag = this.projects[0].fileFlag;
      this.downloadId = this.projects[0].id;
      this.dwnshow = this.downloadflag === 0 ? false : true;
      this.usrID = this.projects[0].usrId;
      this.timeSheetId = this.projects[0].timesheetId;
      this.submittedDate = this.projects[0].submittedDate;
      this.submittedbyname = this.projects[0].submittedByname;
      this.hierarchytotalhours = this.projects[0].totalHrs;
      this.downloadimageid = this.projects[0].imageId;
      this.editFilePath = this.projects[0].filePath;
      this.downloadImage();
      this.gridtimesheetId = this.projects[0].timesheetId;
      if (this.roles === 5) {
        // tslint:disable-next-line:radix
        if (parseInt(this.nextRecordId) === this.downloadId && parseInt(this.previousRecordId) === this.downloadId) {
          this.previousbtn = true;
          this.nextbtn = true;
        }
        // tslint:disable-next-line:radix
        else if (parseInt(this.nextRecordId) === this.downloadId) {
          this.previousbtn = false;
          this.nextbtn = true;
          // tslint:disable-next-line:radix
        } else if (parseInt(this.previousRecordId) === this.downloadId) {
          this.previousbtn = true;
          this.nextbtn = false;
          // tslint:disable-next-line:radix
        } else if (parseInt(this.nextRecordId) !== this.downloadId && parseInt(this.previousRecordId) !== this.downloadId) {
          this.previousbtn = false;
          this.nextbtn = false;
          // tslint:disable-next-line:radix
        } else if (parseInt(this.nextRecordId) !== this.downloadId && parseInt(this.previousRecordId) === this.downloadId) {
          this.previousbtn = true;
          this.nextbtn = false;
          // tslint:disable-next-line:radix
        } else if (parseInt(this.nextRecordId) === this.downloadId && parseInt(this.previousRecordId) !== this.downloadId) {
          this.previousbtn = false;
          this.nextbtn = true;
        } else {
          this.previousbtn = false;
          this.nextbtn = false;
        }
      } else {
        // tslint:disable-next-line:radix
        if (parseInt(this.nextRecordId) === this.projects[0].id && parseInt(this.previousRecordId) === this.projects[0].id) {
          this.previousbtn = true;
          this.nextbtn = true;
        }
        // tslint:disable-next-line:radix
        else if (parseInt(this.nextRecordId) === this.projects[0].id) {
          this.previousbtn = false;
          this.nextbtn = true;
          // tslint:disable-next-line:radix
        } else if (parseInt(this.previousRecordId) === this.projects[0].id) {
          this.previousbtn = true;
          this.nextbtn = false;
          // tslint:disable-next-line:radix
        } else if (parseInt(this.nextRecordId) !== this.projects[0].id && parseInt(this.previousRecordId) !== this.projects[0].id) {
          this.previousbtn = false;
          this.nextbtn = false;
          // tslint:disable-next-line:radix
        } else if (parseInt(this.nextRecordId) !== this.projects[0].id && parseInt(this.previousRecordId) === this.projects[0].id) {
          this.previousbtn = true;
          this.nextbtn = false;
          // tslint:disable-next-line:radix
        } else if (parseInt(this.nextRecordId) === this.projects[0].id && parseInt(this.previousRecordId) !== this.projects[0].id) {
          this.previousbtn = false;
          this.nextbtn = true;
        } else {
          this.previousbtn = false;
          this.nextbtn = false;
        }
      }

      if (this.submittedDate === '0000-00-00') {
        this.patchSubmittedDate = '--';
      } else {
        this.patchSubmittedDate = this.submittedDate;
      }
      this.timelines = this.projects[0].timesheetLinses;
      this.startdate = this.projects[0].dateStart;
      this.timesheetId = this.projects[0].id;
      this.objectOneId = this.timelines[0]?.id;
      this.objectTwoId = this.timelines[1]?.id;
      this.objectThreeId = this.timelines[2]?.id;
      this.objectFourId = this.timelines[3]?.id;
      this.objectFiveId = this.timelines[4]?.id;
      this.objectSixId = this.timelines[5]?.id;
      this.objectSevenId = this.timelines[6]?.id;
      this.week1 = this.timelines[0]?.workDate;
      this.week2 = this.timelines[1]?.workDate;
      this.week3 = this.timelines[2]?.workDate;
      this.week4 = this.timelines[3]?.workDate;
      this.week5 = this.timelines[4]?.workDate;
      this.week6 = this.timelines[5]?.workDate;
      this.week7 = this.timelines[6]?.workDate;
      this.project1 = res.data[0]?.prjName1;
      this.project1Id = this.timelines[0]?.prjId1;
      this.project2 = res.data[0]?.prjName2;
      this.project2Id = this.timelines[0]?.prjId2;
      this.project3 = res.data[0]?.prjName3;
      this.project3Id = this.timelines[0]?.prjId3;
      this.project4 = res.data[0]?.prjName4;
      this.project4Id = this.timelines[0]?.prjId4;
      this.project5 = res.data[0]?.prjName5;
      this.project5Id = this.timelines[0]?.prjId5;
      this.calculateTotalHours();
      this.projectsdata.patchValue({
        p1sun: this.timelines[0]?.prj1Hrs,
        p1mon: this.timelines[1]?.prj1Hrs,
        p1tues: this.timelines[2]?.prj1Hrs,
        p1wed: this.timelines[3]?.prj1Hrs,
        p1thurs: this.timelines[4]?.prj1Hrs,
        p1fri: this.timelines[5]?.prj1Hrs,
        p1sat: this.timelines[6]?.prj1Hrs,
        // second project
        p2sun: this.timelines[0]?.prj2Hrs,
        p2mon: this.timelines[1]?.prj2Hrs,
        p2tues: this.timelines[2]?.prj2Hrs,
        p2wed: this.timelines[3]?.prj2Hrs,
        p2thurs: this.timelines[4]?.prj2Hrs,
        p2fri: this.timelines[5]?.prj2Hrs,
        p2sat: this.timelines[6]?.prj2Hrs,
        // Third Project
        p3sun: this.timelines[0]?.prj3Hrs,
        p3mon: this.timelines[1]?.prj3Hrs,
        p3tues: this.timelines[2]?.prj3Hrs,
        p3wed: this.timelines[3]?.prj3Hrs,
        p3thurs: this.timelines[4]?.prj3Hrs,
        p3fri: this.timelines[5]?.prj3Hrs,
        p3sat: this.timelines[6]?.prj3Hrs,
        // fourth project
        p4sun: this.timelines[0]?.prj4Hrs,
        p4mon: this.timelines[1]?.prj4Hrs,
        p4tues: this.timelines[2]?.prj4Hrs,
        p4wed: this.timelines[3]?.prj4Hrs,
        p4thurs: this.timelines[4]?.prj4Hrs,
        p4fri: this.timelines[5]?.prj4Hrs,
        p4sat: this.timelines[6]?.prj4Hrs,
        // fifth project
        p5sun: this.timelines[0]?.prj5Hrs,
        p5mon: this.timelines[1]?.prj5Hrs,
        p5tues: this.timelines[2]?.prj5Hrs,
        p5wed: this.timelines[3]?.prj5Hrs,
        p5thurs: this.timelines[4]?.prj5Hrs,
        p5fri: this.timelines[5]?.prj5Hrs,
        p5sat: this.timelines[6]?.prj5Hrs,
        // internal hours
        isun: this.timelines[0]?.internalHrs,
        imon: this.timelines[1]?.internalHrs,
        itues: this.timelines[2]?.internalHrs,
        iwed: this.timelines[3]?.internalHrs,
        ithurs: this.timelines[4]?.internalHrs,
        ifri: this.timelines[5]?.internalHrs,
        isat: this.timelines[6]?.internalHrs,
        // total hours
        // tslint:disable-next-line: max-line-length
        tsun: this.timelines[0]?.prj1Hrs + this.timelines[0]?.prj2Hrs + this.timelines[0]?.prj3Hrs + this.timelines[0]?.prj4Hrs + this.timelines[0]?.prj5Hrs + this.timelines[0]?.internalHrs,
        // tslint:disable-next-line:max-line-length
        tmon: this.timelines[1]?.prj1Hrs + this.timelines[1]?.prj2Hrs + this.timelines[1]?.prj3Hrs + this.timelines[1]?.prj4Hrs + this.timelines[1]?.prj5Hrs + this.timelines[1]?.internalHrs,
        // tslint:disable-next-line:max-line-length
        ttues: this.timelines[2]?.prj1Hrs + this.timelines[2]?.prj2Hrs + this.timelines[2]?.prj3Hrs + this.timelines[2]?.prj4Hrs + this.timelines[2]?.prj5Hrs + this.timelines[2]?.internalHrs,
        // tslint:disable-next-line:max-line-length
        twed: this.timelines[3]?.prj1Hrs + this.timelines[3]?.prj2Hrs + this.timelines[3]?.prj3Hrs + this.timelines[3]?.prj4Hrs + this.timelines[3]?.prj5Hrs + this.timelines[3]?.internalHrs,
        // tslint:disable-next-line:max-line-length
        tthurs: this.timelines[4]?.prj1Hrs + this.timelines[4]?.prj2Hrs + this.timelines[4]?.prj3Hrs + this.timelines[4]?.prj4Hrs + this.timelines[4]?.prj5Hrs + this.timelines[4]?.internalHrs,
        // tslint:disable-next-line:max-line-length
        tfri: this.timelines[5]?.prj1Hrs + this.timelines[5]?.prj2Hrs + this.timelines[5]?.prj3Hrs + this.timelines[5]?.prj4Hrs + this.timelines[5]?.prj5Hrs + this.timelines[5]?.internalHrs,
        // tslint:disable-next-line:max-line-length
        tsat: this.timelines[6]?.prj1Hrs + this.timelines[6]?.prj2Hrs + this.timelines[6]?.prj3Hrs + this.timelines[6]?.prj4Hrs + this.timelines[6]?.prj5Hrs + this.timelines[6]?.internalHrs,
      });
      this.timesheetNote.patchValue({
        notes: this.projects[0].notes,
        rejectReason: this.projects[0].comments,
      });
    });

  }
  // Method to enable reject timesheet popuop from project manager role
  reject() {
    this.rejectResonForm.reset();
    this.approveRejectPopup = true;
  }

  close() {
    this.approveRejectPopup = false;
    this.rejectResonForm.reset();
  }
  // Mthod to hit reject timesheet API from project manager role
  rejectReason() {
    this.hide = true;
    if (this.rejectResonForm.invalid) {
      const controls = this.rejectResonForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      const data = {
        lkStatus: 'R',
        // tslint:disable-next-line: max-line-length
        comments: this.rejectResonForm.controls.rejectreason.value === null || this.rejectResonForm.controls.rejectreason.value === undefined || this.rejectResonForm.controls.rejectreason.value === '' ? '' : this.rejectResonForm.controls.rejectreason.value,
        id: this.id
      };
      this.approveRejectPopup = false;
      this.loader = true;
      this.apiservice.putApi(environment.rejectreasons, data).subscribe(res => {
        this.loader = false;
        this.hide = false;
        if (res.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
          setTimeout(() => {
            this.loader = false;
            this.router.navigate(['/time-sheets']
            );
          }, 2000);
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        }
      });
    }
  }
// Method to approve timesheet from project manager role
  approvetimesheet() {
    const data = {
      lkStatus: 'A',
      // tslint:disable-next-line: max-line-length
      comments: this.rejectResonForm.controls.rejectreason.value === null || this.rejectResonForm.controls.rejectreason.value === undefined || this.rejectResonForm.controls.rejectreason.value === '' ? '' : this.rejectResonForm.controls.rejectreason.value,
      id: this.id
    };
    Swal.fire({
      text: 'Are you sure want to approve ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0D416B',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.hide = true;
        this.loader = true;
        this.apiservice.putApi(environment.rejectreasons, data).subscribe(res => {
          this.loader = false;
          if (res.hasOwnProperty('success') === true) {
            Swal.fire({ text: res.message, icon: 'success', iconColor: '#1dbb99', confirmButtonColor: '#0D416B', confirmButtonText: 'Ok' });
            setTimeout(() => {
              this.loader = false;
              this.router.navigate(['/time-sheets']
              );
            }, 2000);
          } else if (res.hasOwnProperty('failed') === true) {
            Swal.fire({ text: res.message, icon: 'success', iconColor: '#1dbb99', confirmButtonColor: '#0D416B', confirmButtonText: 'Ok' });
          } else {
            Swal.fire({ text: res.message, icon: 'success', iconColor: '#1dbb99', confirmButtonColor: '#0D416B', confirmButtonText: 'Ok' });
          }
        });
        this.hide = false;
      }
    });
  }
  callPopUpTimesheet() {
    this.calculateTotalHours();
    this.updatedisplay = true;
  }
  cancelPopUp() {
    this.updatedisplay = false;
  }
  // Method to update timesheet which is in draft state
  updateTimesheet() {
    if (this.filedata !== undefined) {
      this.updatedisplay = false;
      const actId = JSON.parse(this.apiservice.decodeData());
      this.apiservice.postApi(environment.profileUpload + actId.userId + '/T', this.filedata).subscribe((res: any) => {
        this.fileRes = res;
        this.fileData = res.data;
        this.filesuccess = this.fileRes.success;
        this.filePath = res.data.path;
        this.fileSize = res.data.fileSize;
        if (this.fileRes.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.fileRes.message });
          this.disable = false;
          const updatedata = {
            id: this.timesheetId,
            lkStatus: 'S',
            statusChangedDate: this.datepipe.transform(this.currentDate, 'yyyy-MM-dd HH:mm:ss'),
            submittedDate: this.datepipe.transform(this.currentDate, 'yyyy-MM-dd HH:mm:ss'),
            totalHrs: this.totalhours,
            filePath: this.filePath,
            firstPrjId: this.project1Id,
            // tslint:disable-next-line:max-line-length
            notes: this.timesheetNote.controls.notes.value === null || this.timesheetNote.controls.notes.value === '' || this.timesheetNote.controls.notes.value === undefined ? '' : this.timesheetNote.controls.notes.value,
            timesheetLines: [
              {
                id: this.objectOneId,
                // tslint:disable-next-line:max-line-length
                prj1Hrs: this.projectsdata.controls.p1sun.value === null || this.projectsdata.controls.p1sun.value === '' || this.projectsdata.controls.p1sun.value === undefined ? '0' : this.projectsdata.controls.p1sun.value,
                // tslint:disable-next-line:max-line-length
                prj2Hrs: this.projectsdata.controls.p2sun.value === null || this.projectsdata.controls.p2sun.value === '' || this.projectsdata.controls.p2sun.value === undefined ? '0' : this.projectsdata.controls.p2sun.value,
                // tslint:disable-next-line:max-line-length
                internalHrs: this.projectsdata.controls.isun.value === null || this.projectsdata.controls.isun.value === '' || this.projectsdata.controls.isun.value === undefined ? '0' : this.projectsdata.controls.isun.value,
                // tslint:disable-next-line:max-line-length
                prj3Hrs: this.projectsdata.controls.p3sun.value === null || this.projectsdata.controls.p3sun.value === '' || this.projectsdata.controls.p3sun.value === undefined ? '0' : this.projectsdata.controls.p3sun.value,
                // tslint:disable-next-line:max-line-length
                prj4Hrs: this.projectsdata.controls.p4sun.value === null || this.projectsdata.controls.p4sun.value === '' || this.projectsdata.controls.p4sun.value === undefined ? '0' : this.projectsdata.controls.p4sun.value,
                // tslint:disable-next-line:max-line-length
                prj5Hrs: this.projectsdata.controls.p5sun.value === null || this.projectsdata.controls.p5sun.value === '' || this.projectsdata.controls.p5sun.value === undefined ? '0' : this.projectsdata.controls.p5sun.value,
                prjId1: this.project1Id,
                prjId2: this.project2Id,
                prjId3: this.project3Id,
                prjId4: this.project4Id,
                prjId5: this.project5Id,
              },
              {
                id: this.objectTwoId,
                // tslint:disable-next-line:max-line-length
                prj1Hrs: this.projectsdata.controls.p1mon.value === null || this.projectsdata.controls.p1mon.value === '' || this.projectsdata.controls.p1mon.value === undefined ? '0' : this.projectsdata.controls.p1mon.value,
                // tslint:disable-next-line:max-line-length
                prj2Hrs: this.projectsdata.controls.p2mon.value === null || this.projectsdata.controls.p2mon.value === '' || this.projectsdata.controls.p2mon.value === undefined ? '0' : this.projectsdata.controls.p2mon.value,
                // tslint:disable-next-line:max-line-length
                internalHrs: this.projectsdata.controls.imon.value === null || this.projectsdata.controls.imon.value === '' || this.projectsdata.controls.imon.value === undefined ? '0' : this.projectsdata.controls.imon.value,
                // tslint:disable-next-line:max-line-length
                prj3Hrs: this.projectsdata.controls.p3mon.value === null || this.projectsdata.controls.p3mon.value === '' || this.projectsdata.controls.p3mon.value === undefined ? '0' : this.projectsdata.controls.p3mon.value,
                // tslint:disable-next-line:max-line-length
                prj4Hrs: this.projectsdata.controls.p4mon.value === null || this.projectsdata.controls.p4mon.value === '' || this.projectsdata.controls.p4mon.value === undefined ? '0' : this.projectsdata.controls.p4mon.value,
                // tslint:disable-next-line:max-line-length
                prj5Hrs: this.projectsdata.controls.p5mon.value === null || this.projectsdata.controls.p5mon.value === '' || this.projectsdata.controls.p5mon.value === undefined ? '0' : this.projectsdata.controls.p5mon.value,
                prjId1: this.project1Id,
                prjId2: this.project2Id,
                prjId3: this.project3Id,
                prjId4: this.project4Id,
                prjId5: this.project5Id,
              },
              {
                id: this.objectThreeId,
                // tslint:disable-next-line:max-line-length
                prj1Hrs: this.projectsdata.controls.p1tues.value === null || this.projectsdata.controls.p1tues.value === '' || this.projectsdata.controls.p1tues.value === undefined ? '0' : this.projectsdata.controls.p1tues.value,
                // tslint:disable-next-line:max-line-length
                prj2Hrs: this.projectsdata.controls.p2tues.value === null || this.projectsdata.controls.p2tues.value === '' || this.projectsdata.controls.p2tues.value === undefined ? '0' : this.projectsdata.controls.p2tues.value,
                // tslint:disable-next-line:max-line-length
                internalHrs: this.projectsdata.controls.itues.value === null || this.projectsdata.controls.itues.value === '' || this.projectsdata.controls.itues.value === undefined ? '0' : this.projectsdata.controls.itues.value,
                // tslint:disable-next-line:max-line-length
                prj3Hrs: this.projectsdata.controls.p3tues.value === null || this.projectsdata.controls.p3tues.value === '' || this.projectsdata.controls.p3tues.value === undefined ? '0' : this.projectsdata.controls.p3tues.value,
                // tslint:disable-next-line:max-line-length
                prj4Hrs: this.projectsdata.controls.p4tues.value === null || this.projectsdata.controls.p4tues.value === '' || this.projectsdata.controls.p4tues.value === undefined ? '0' : this.projectsdata.controls.p4tues.value,
                // tslint:disable-next-line:max-line-length
                prj5Hrs: this.projectsdata.controls.p5tues.value === null || this.projectsdata.controls.p5tues.value === '' || this.projectsdata.controls.p5tues.value === undefined ? '0' : this.projectsdata.controls.p5tues.value,
                prjId1: this.project1Id,
                prjId2: this.project2Id,
                prjId3: this.project3Id,
                prjId4: this.project4Id,
                prjId5: this.project5Id,
              },
              {
                id: this.objectFourId,
                // tslint:disable-next-line:max-line-length
                prj1Hrs: this.projectsdata.controls.p1wed.value === null || this.projectsdata.controls.p1wed.value === '' || this.projectsdata.controls.p1wed.value === undefined ? '0' : this.projectsdata.controls.p1wed.value,
                // tslint:disable-next-line:max-line-length
                prj2Hrs: this.projectsdata.controls.p2wed.value === null || this.projectsdata.controls.p2wed.value === '' || this.projectsdata.controls.p2wed.value === undefined ? '0' : this.projectsdata.controls.p2wed.value,
                // tslint:disable-next-line:max-line-length
                internalHrs: this.projectsdata.controls.iwed.value === null || this.projectsdata.controls.iwed.value === '' || this.projectsdata.controls.iwed.value === undefined ? '0' : this.projectsdata.controls.iwed.value,
                // tslint:disable-next-line:max-line-length
                prj3Hrs: this.projectsdata.controls.p3wed.value === null || this.projectsdata.controls.p3wed.value === '' || this.projectsdata.controls.p3wed.value === undefined ? '0' : this.projectsdata.controls.p3wed.value,
                // tslint:disable-next-line:max-line-length
                prj4Hrs: this.projectsdata.controls.p4wed.value === null || this.projectsdata.controls.p4wed.value === '' || this.projectsdata.controls.p4wed.value === undefined ? '0' : this.projectsdata.controls.p4wed.value,
                // tslint:disable-next-line:max-line-length
                prj5Hrs: this.projectsdata.controls.p5wed.value === null || this.projectsdata.controls.p5wed.value === '' || this.projectsdata.controls.p5wed.value === undefined ? '0' : this.projectsdata.controls.p5wed.value,
                prjId1: this.project1Id,
                prjId2: this.project2Id,
                prjId3: this.project3Id,
                prjId4: this.project4Id,
                prjId5: this.project5Id,
              },
              {
                id: this.objectFiveId,
                // tslint:disable-next-line:max-line-length
                prj1Hrs: this.projectsdata.controls.p1thurs.value === null || this.projectsdata.controls.p1thurs.value === '' || this.projectsdata.controls.p1thurs.value === undefined ? '0' : this.projectsdata.controls.p1thurs.value,
                // tslint:disable-next-line:max-line-length
                prj2Hrs: this.projectsdata.controls.p2thurs.value === null || this.projectsdata.controls.p2thurs.value === '' || this.projectsdata.controls.p2thurs.value === undefined ? '0' : this.projectsdata.controls.p2thurs.value,
                // tslint:disable-next-line:max-line-length
                internalHrs: this.projectsdata.controls.ithurs.value === null || this.projectsdata.controls.ithurs.value === '' || this.projectsdata.controls.ithurs.value === undefined ? '0' : this.projectsdata.controls.ithurs.value,
                // tslint:disable-next-line:max-line-length
                prj3Hrs: this.projectsdata.controls.p3thurs.value === null || this.projectsdata.controls.p3thurs.value === '' || this.projectsdata.controls.p3thurs.value === undefined ? '0' : this.projectsdata.controls.p3thurs.value,
                // tslint:disable-next-line:max-line-length
                prj4Hrs: this.projectsdata.controls.p4thurs.value === null || this.projectsdata.controls.p4thurs.value === '' || this.projectsdata.controls.p4thurs.value === undefined ? '0' : this.projectsdata.controls.p4thurs.value,
                // tslint:disable-next-line:max-line-length
                prj5Hrs: this.projectsdata.controls.p5thurs.value === null || this.projectsdata.controls.p5thurs.value === '' || this.projectsdata.controls.p5thurs.value === undefined ? '0' : this.projectsdata.controls.p5thurs.value,
                prjId1: this.project1Id,
                prjId2: this.project2Id,
                prjId3: this.project3Id,
                prjId4: this.project4Id,
                prjId5: this.project5Id,
              },
              {
                id: this.objectSixId,
                // tslint:disable-next-line:max-line-length
                prj1Hrs: this.projectsdata.controls.p1fri.value === null || this.projectsdata.controls.p1fri.value === '' || this.projectsdata.controls.p1fri.value === undefined ? '0' : this.projectsdata.controls.p1fri.value,
                // tslint:disable-next-line:max-line-length
                prj2Hrs: this.projectsdata.controls.p2fri.value === null || this.projectsdata.controls.p2fri.value === '' || this.projectsdata.controls.p2fri.value === undefined ? '0' : this.projectsdata.controls.p2fri.value,
                // tslint:disable-next-line:max-line-length
                internalHrs: this.projectsdata.controls.ifri.value === null || this.projectsdata.controls.ifri.value === '' || this.projectsdata.controls.ifri.value === undefined ? '0' : this.projectsdata.controls.ifri.value,
                // tslint:disable-next-line:max-line-length
                prj3Hrs: this.projectsdata.controls.p3fri.value === null || this.projectsdata.controls.p3fri.value === '' || this.projectsdata.controls.p3fri.value === undefined ? '0' : this.projectsdata.controls.p3fri.value,
                // tslint:disable-next-line:max-line-length
                prj4Hrs: this.projectsdata.controls.p4fri.value === null || this.projectsdata.controls.p4fri.value === '' || this.projectsdata.controls.p4fri.value === undefined ? '0' : this.projectsdata.controls.p4fri.value,
                // tslint:disable-next-line:max-line-length
                prj5Hrs: this.projectsdata.controls.p5fri.value === null || this.projectsdata.controls.p5fri.value === '' || this.projectsdata.controls.p5fri.value === undefined ? '0' : this.projectsdata.controls.p5fri.value,
                prjId1: this.project1Id,
                prjId2: this.project2Id,
                prjId3: this.project3Id,
                prjId4: this.project4Id,
                prjId5: this.project5Id,
              },
              {
                id: this.objectSevenId,
                // tslint:disable-next-line: max-line-length
                prj1Hrs: this.projectsdata.controls.p1sat.value === null || this.projectsdata.controls.p1sat.value === '' || this.projectsdata.controls.p1sat.value === undefined ? '0' : this.projectsdata.controls.p1sat.value,
                // tslint:disable-next-line: max-line-length
                prj2Hrs: this.projectsdata.controls.p2sat.value === null || this.projectsdata.controls.p2sat.value === '' || this.projectsdata.controls.p2sat.value === undefined ? '0' : this.projectsdata.controls.p2sat.value,
                // tslint:disable-next-line: max-line-length
                internalHrs: this.projectsdata.controls.isat.value === null || this.projectsdata.controls.isat.value === '' || this.projectsdata.controls.isat.value === undefined ? '0' : this.projectsdata.controls.isat.value,
                // tslint:disable-next-line: max-line-length
                prj3Hrs: this.projectsdata.controls.p3sat.value === null || this.projectsdata.controls.p3sat.value === '' || this.projectsdata.controls.p3sat.value === undefined ? '0' : this.projectsdata.controls.p3sat.value,
                // tslint:disable-next-line: max-line-length
                prj4Hrs: this.projectsdata.controls.p4sat.value === null || this.projectsdata.controls.p4sat.value === '' || this.projectsdata.controls.p4sat.value === undefined ? '0' : this.projectsdata.controls.p4sat.value,
                // tslint:disable-next-line: max-line-length
                prj5Hrs: this.projectsdata.controls.p5sat.value === null || this.projectsdata.controls.p5sat.value === '' || this.projectsdata.controls.p5sat.value === undefined ? '0' : this.projectsdata.controls.p5sat.value,
                prjId1: this.project1Id,
                prjId2: this.project2Id,
                prjId3: this.project3Id,
                prjId4: this.project4Id,
                prjId5: this.project5Id,
              }

            ]
          };
          this.loader = true;
          this.apiservice.putApi(environment.updateTimesheet, updatedata).subscribe((updateres): any => {
            this.loader = false;
            if (updateres.hasOwnProperty('success') === true) {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: updateres.message });
              setTimeout(() => {
                this.loader = false;
                this.router.navigate(['/time-sheets']
                );
              }, 2000);
            } else if (updateres.hasOwnProperty('failed') === true) {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: updateres.failed });
            } else {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: updateres.error });
            }
          });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.fileRes.error });
        }


      });
    }
    else {
      const updatedata = {
        id: this.timesheetId,
        lkStatus: 'S',
        statusChangedDate: this.datepipe.transform(this.currentDate, 'yyyy-MM-dd HH:mm:ss'),
        submittedDate: this.datepipe.transform(this.currentDate, 'yyyy-MM-dd HH:mm:ss'),
        totalHrs: this.totalhours,
        filePath: this.editFilePath,
        firstPrjId: this.project1Id,
        // tslint:disable-next-line:max-line-length
        notes: this.timesheetNote.controls.notes.value === null || this.timesheetNote.controls.notes.value === '' || this.timesheetNote.controls.notes.value === undefined ? '' : this.timesheetNote.controls.notes.value,
        timesheetLines: [
          {
            id: this.objectOneId,
            // tslint:disable-next-line:max-line-length
            prj1Hrs: this.projectsdata.controls.p1sun.value === null || this.projectsdata.controls.p1sun.value === '' || this.projectsdata.controls.p1sun.value === undefined ? '0' : this.projectsdata.controls.p1sun.value,
            // tslint:disable-next-line:max-line-length
            prj2Hrs: this.projectsdata.controls.p2sun.value === null || this.projectsdata.controls.p2sun.value === '' || this.projectsdata.controls.p2sun.value === undefined ? '0' : this.projectsdata.controls.p2sun.value,
            // tslint:disable-next-line:max-line-length
            internalHrs: this.projectsdata.controls.isun.value === null || this.projectsdata.controls.isun.value === '' || this.projectsdata.controls.isun.value === undefined ? '0' : this.projectsdata.controls.isun.value,
            // tslint:disable-next-line:max-line-length
            prj3Hrs: this.projectsdata.controls.p3sun.value === null || this.projectsdata.controls.p3sun.value === '' || this.projectsdata.controls.p3sun.value === undefined ? '0' : this.projectsdata.controls.p3sun.value,
            // tslint:disable-next-line:max-line-length
            prj4Hrs: this.projectsdata.controls.p4sun.value === null || this.projectsdata.controls.p4sun.value === '' || this.projectsdata.controls.p4sun.value === undefined ? '0' : this.projectsdata.controls.p4sun.value,
            // tslint:disable-next-line:max-line-length
            prj5Hrs: this.projectsdata.controls.p5sun.value === null || this.projectsdata.controls.p5sun.value === '' || this.projectsdata.controls.p5sun.value === undefined ? '0' : this.projectsdata.controls.p5sun.value,
            prjId1: this.project1Id,
            prjId2: this.project2Id,
            prjId3: this.project3Id,
            prjId4: this.project4Id,
            prjId5: this.project5Id,
            // filePath
          },
          {
            id: this.objectTwoId,
            // tslint:disable-next-line:max-line-length
            prj1Hrs: this.projectsdata.controls.p1mon.value === null || this.projectsdata.controls.p1mon.value === '' || this.projectsdata.controls.p1mon.value === undefined ? '0' : this.projectsdata.controls.p1mon.value,
            // tslint:disable-next-line:max-line-length
            prj2Hrs: this.projectsdata.controls.p2mon.value === null || this.projectsdata.controls.p2mon.value === '' || this.projectsdata.controls.p2mon.value === undefined ? '0' : this.projectsdata.controls.p2mon.value,
            // tslint:disable-next-line:max-line-length
            internalHrs: this.projectsdata.controls.imon.value === null || this.projectsdata.controls.imon.value === '' || this.projectsdata.controls.imon.value === undefined ? '0' : this.projectsdata.controls.imon.value,
            // tslint:disable-next-line:max-line-length
            prj3Hrs: this.projectsdata.controls.p3mon.value === null || this.projectsdata.controls.p3mon.value === '' || this.projectsdata.controls.p3mon.value === undefined ? '0' : this.projectsdata.controls.p3mon.value,
            // tslint:disable-next-line:max-line-length
            prj4Hrs: this.projectsdata.controls.p4mon.value === null || this.projectsdata.controls.p4mon.value === '' || this.projectsdata.controls.p4mon.value === undefined ? '0' : this.projectsdata.controls.p4mon.value,
            // tslint:disable-next-line:max-line-length
            prj5Hrs: this.projectsdata.controls.p5mon.value === null || this.projectsdata.controls.p5mon.value === '' || this.projectsdata.controls.p5mon.value === undefined ? '0' : this.projectsdata.controls.p5mon.value,
            prjId1: this.project1Id,
            prjId2: this.project2Id,
            prjId3: this.project3Id,
            prjId4: this.project4Id,
            prjId5: this.project5Id,
          },
          {
            id: this.objectThreeId,
            // tslint:disable-next-line:max-line-length
            prj1Hrs: this.projectsdata.controls.p1tues.value === null || this.projectsdata.controls.p1tues.value === '' || this.projectsdata.controls.p1tues.value === undefined ? '0' : this.projectsdata.controls.p1tues.value,
            // tslint:disable-next-line:max-line-length
            prj2Hrs: this.projectsdata.controls.p2tues.value === null || this.projectsdata.controls.p2tues.value === '' || this.projectsdata.controls.p2tues.value === undefined ? '0' : this.projectsdata.controls.p2tues.value,
            // tslint:disable-next-line:max-line-length
            internalHrs: this.projectsdata.controls.itues.value === null || this.projectsdata.controls.itues.value === '' || this.projectsdata.controls.itues.value === undefined ? '0' : this.projectsdata.controls.itues.value,
            // tslint:disable-next-line:max-line-length
            prj3Hrs: this.projectsdata.controls.p3tues.value === null || this.projectsdata.controls.p3tues.value === '' || this.projectsdata.controls.p3tues.value === undefined ? '0' : this.projectsdata.controls.p3tues.value,
            // tslint:disable-next-line:max-line-length
            prj4Hrs: this.projectsdata.controls.p4tues.value === null || this.projectsdata.controls.p4tues.value === '' || this.projectsdata.controls.p4tues.value === undefined ? '0' : this.projectsdata.controls.p4tues.value,
            // tslint:disable-next-line:max-line-length
            prj5Hrs: this.projectsdata.controls.p5tues.value === null || this.projectsdata.controls.p5tues.value === '' || this.projectsdata.controls.p5tues.value === undefined ? '0' : this.projectsdata.controls.p5tues.value,
            prjId1: this.project1Id,
            prjId2: this.project2Id,
            prjId3: this.project3Id,
            prjId4: this.project4Id,
            prjId5: this.project5Id,
          },
          {
            id: this.objectFourId,
            // tslint:disable-next-line:max-line-length
            prj1Hrs: this.projectsdata.controls.p1wed.value === null || this.projectsdata.controls.p1wed.value === '' || this.projectsdata.controls.p1wed.value === undefined ? '0' : this.projectsdata.controls.p1wed.value,
            // tslint:disable-next-line:max-line-length
            prj2Hrs: this.projectsdata.controls.p2wed.value === null || this.projectsdata.controls.p2wed.value === '' || this.projectsdata.controls.p2wed.value === undefined ? '0' : this.projectsdata.controls.p2wed.value,
            // tslint:disable-next-line:max-line-length
            internalHrs: this.projectsdata.controls.iwed.value === null || this.projectsdata.controls.iwed.value === '' || this.projectsdata.controls.iwed.value === undefined ? '0' : this.projectsdata.controls.iwed.value,
            // tslint:disable-next-line:max-line-length
            prj3Hrs: this.projectsdata.controls.p3wed.value === null || this.projectsdata.controls.p3wed.value === '' || this.projectsdata.controls.p3wed.value === undefined ? '0' : this.projectsdata.controls.p3wed.value,
            // tslint:disable-next-line:max-line-length
            prj4Hrs: this.projectsdata.controls.p4wed.value === null || this.projectsdata.controls.p4wed.value === '' || this.projectsdata.controls.p4wed.value === undefined ? '0' : this.projectsdata.controls.p4wed.value,
            // tslint:disable-next-line:max-line-length
            prj5Hrs: this.projectsdata.controls.p5wed.value === null || this.projectsdata.controls.p5wed.value === '' || this.projectsdata.controls.p5wed.value === undefined ? '0' : this.projectsdata.controls.p5wed.value,
            prjId1: this.project1Id,
            prjId2: this.project2Id,
            prjId3: this.project3Id,
            prjId4: this.project4Id,
            prjId5: this.project5Id,
          },
          {
            id: this.objectFiveId,
            // tslint:disable-next-line:max-line-length
            prj1Hrs: this.projectsdata.controls.p1thurs.value === null || this.projectsdata.controls.p1thurs.value === '' || this.projectsdata.controls.p1thurs.value === undefined ? '0' : this.projectsdata.controls.p1thurs.value,
            // tslint:disable-next-line:max-line-length
            prj2Hrs: this.projectsdata.controls.p2thurs.value === null || this.projectsdata.controls.p2thurs.value === '' || this.projectsdata.controls.p2thurs.value === undefined ? '0' : this.projectsdata.controls.p2thurs.value,
            // tslint:disable-next-line:max-line-length
            internalHrs: this.projectsdata.controls.ithurs.value === null || this.projectsdata.controls.ithurs.value === '' || this.projectsdata.controls.ithurs.value === undefined ? '0' : this.projectsdata.controls.ithurs.value,
            // tslint:disable-next-line:max-line-length
            prj3Hrs: this.projectsdata.controls.p3thurs.value === null || this.projectsdata.controls.p3thurs.value === '' || this.projectsdata.controls.p3thurs.value === undefined ? '0' : this.projectsdata.controls.p3thurs.value,
            // tslint:disable-next-line:max-line-length
            prj4Hrs: this.projectsdata.controls.p4thurs.value === null || this.projectsdata.controls.p4thurs.value === '' || this.projectsdata.controls.p4thurs.value === undefined ? '0' : this.projectsdata.controls.p4thurs.value,
            // tslint:disable-next-line:max-line-length
            prj5Hrs: this.projectsdata.controls.p5thurs.value === null || this.projectsdata.controls.p5thurs.value === '' || this.projectsdata.controls.p5thurs.value === undefined ? '0' : this.projectsdata.controls.p5thurs.value,
            prjId1: this.project1Id,
            prjId2: this.project2Id,
            prjId3: this.project3Id,
            prjId4: this.project4Id,
            prjId5: this.project5Id,
          },
          {
            id: this.objectSixId,
            // tslint:disable-next-line:max-line-length
            prj1Hrs: this.projectsdata.controls.p1fri.value === null || this.projectsdata.controls.p1fri.value === '' || this.projectsdata.controls.p1fri.value === undefined ? '0' : this.projectsdata.controls.p1fri.value,
            // tslint:disable-next-line:max-line-length
            prj2Hrs: this.projectsdata.controls.p2fri.value === null || this.projectsdata.controls.p2fri.value === '' || this.projectsdata.controls.p2fri.value === undefined ? '0' : this.projectsdata.controls.p2fri.value,
            // tslint:disable-next-line:max-line-length
            internalHrs: this.projectsdata.controls.ifri.value === null || this.projectsdata.controls.ifri.value === '' || this.projectsdata.controls.ifri.value === undefined ? '0' : this.projectsdata.controls.ifri.value,
            // tslint:disable-next-line:max-line-length
            prj3Hrs: this.projectsdata.controls.p3fri.value === null || this.projectsdata.controls.p3fri.value === '' || this.projectsdata.controls.p3fri.value === undefined ? '0' : this.projectsdata.controls.p3fri.value,
            // tslint:disable-next-line:max-line-length
            prj4Hrs: this.projectsdata.controls.p4fri.value === null || this.projectsdata.controls.p4fri.value === '' || this.projectsdata.controls.p4fri.value === undefined ? '0' : this.projectsdata.controls.p4fri.value,
            // tslint:disable-next-line:max-line-length
            prj5Hrs: this.projectsdata.controls.p5fri.value === null || this.projectsdata.controls.p5fri.value === '' || this.projectsdata.controls.p5fri.value === undefined ? '0' : this.projectsdata.controls.p5fri.value,
            prjId1: this.project1Id,
            prjId2: this.project2Id,
            prjId3: this.project3Id,
            prjId4: this.project4Id,
            prjId5: this.project5Id,
          },
          {
            id: this.objectSevenId,
            // tslint:disable-next-line: max-line-length
            prj1Hrs: this.projectsdata.controls.p1sat.value === null || this.projectsdata.controls.p1sat.value === '' || this.projectsdata.controls.p1sat.value === undefined ? '0' : this.projectsdata.controls.p1sat.value,
            // tslint:disable-next-line: max-line-length
            prj2Hrs: this.projectsdata.controls.p2sat.value === null || this.projectsdata.controls.p2sat.value === '' || this.projectsdata.controls.p2sat.value === undefined ? '0' : this.projectsdata.controls.p2sat.value,
            // tslint:disable-next-line: max-line-length
            internalHrs: this.projectsdata.controls.isat.value === null || this.projectsdata.controls.isat.value === '' || this.projectsdata.controls.isat.value === undefined ? '0' : this.projectsdata.controls.isat.value,
            // tslint:disable-next-line: max-line-length
            prj3Hrs: this.projectsdata.controls.p3sat.value === null || this.projectsdata.controls.p3sat.value === '' || this.projectsdata.controls.p3sat.value === undefined ? '0' : this.projectsdata.controls.p3sat.value,
            // tslint:disable-next-line: max-line-length
            prj4Hrs: this.projectsdata.controls.p4sat.value === null || this.projectsdata.controls.p4sat.value === '' || this.projectsdata.controls.p4sat.value === undefined ? '0' : this.projectsdata.controls.p4sat.value,
            // tslint:disable-next-line: max-line-length
            prj5Hrs: this.projectsdata.controls.p5sat.value === null || this.projectsdata.controls.p5sat.value === '' || this.projectsdata.controls.p5sat.value === undefined ? '0' : this.projectsdata.controls.p5sat.value,
            prjId1: this.project1Id,
            prjId2: this.project2Id,
            prjId3: this.project3Id,
            prjId4: this.project4Id,
            prjId5: this.project5Id,
          }

        ]
      };
      this.updatedisplay = false;
      this.loader = true;
      this.apiservice.putApi(environment.updateTimesheet, updatedata).subscribe((updateres): any => {
        this.loader = false;
        if (updateres.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: updateres.message });
          setTimeout(() => {
            this.loader = false;
            this.router.navigate(['/time-sheets']
            );
          }, 2000);
        } else if (updateres.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: updateres.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: updateres.error });
        }
      });

    }
    this.calculateTotalHours();
  }
  // Method to get  the timesheet history
  viewHistory() {
    this.displayModal = true;
    this.apiservice.getApi(environment.timesheetHistory + this.id).subscribe(res => {
      this.histroy = res.data;
    });
  }
  handleFileInput(event) {
    this.files = event.target.files[0].name;
    this.fileName = event.target.files[0];
    if (this.fileName.name.length > 25) {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'File name should be 25 alphabets.' });
      this.cancelfiles();
    }
    if (this.fileName.size > 10485760) {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please upload below 10MB.' });
      this.cancelfiles();
    }
    else {
      this.name = this.fileName.name;
      this.fileType = this.fileName.name.split('.').pop();
      const formData: FormData = new FormData();
      formData.append('file', this.fileName);
      this.filedata = formData;
      // tslint:disable-next-line:no-conditional-assignment
      if (this.filedata = formData) {
        this.filesdatass = true;
      } else {
        this.filesdatass = false;
      }
    }
  }
  // Method to call previous timesheet in both employee and project manager role
  previousTimesheet(event) {
    if (this.roles === 11) {
      this.previousId = event;
      this.nextTimesheet(undefined);
    } else {
      this.previousId = event;
      this.nextTimesheet(undefined);
    }
  }
  // Method to call next timesheet in both employee and project manager role
  nextTimesheet(event) {
    if (this.roles === 11) {
      this.nextId = event;
      if (this.previousId === undefined) {
        this.timeSheetgetId = this.timeSheetId - 1;
        this.timeSheetId = this.timeSheetId - 1;
      } else {
        this.timeSheetgetId = this.timeSheetId + 1;
        this.timeSheetId = this.timeSheetId + 1;
      }
      if (this.timeSheetgetId === 0) {
        this.dsbbtn = true;
      } else {
        this.dsbbtn = false;
      }
      this.loader = true;
      this.apiservice.getApi(environment.timesheetnexytprevious + this.usrID + '/' + this.timeSheetgetId).subscribe(res => {
        this.loader = false;
        this.projects = res.data;
        this.submittedDate = this.projects[0].submittedDate;
        this.submittedbyname = this.projects[0].submittedByname;
        this.hierarchytotalhours = this.projects[0].totalHrs;
        this.downloadimageid = this.projects[0].imageId;
        // tslint:disable-next-line:radix
        if (parseInt(this.nextRecordId) === this.projects[0].id) {
          this.previousbtn = false;
          this.nextbtn = true;
          // tslint:disable-next-line:radix
        } else if (parseInt(this.previousRecordId) === this.projects[0].id) {
          this.previousbtn = true;
          this.nextbtn = false;
          // tslint:disable-next-line:radix
        } else if (parseInt(this.nextRecordId) !== this.projects[0].id && parseInt(this.previousRecordId) !== this.projects[0].id) {
          this.previousbtn = false;
          this.nextbtn = false;
          // tslint:disable-next-line:radix
        } else if (parseInt(this.nextRecordId) !== this.projects[0].id && parseInt(this.previousRecordId) === this.projects[0].id) {
          this.previousbtn = true;
          this.nextbtn = false;
          // tslint:disable-next-line:radix
        } else if (parseInt(this.nextRecordId) === this.projects[0].id && parseInt(this.previousRecordId) !== this.projects[0].id) {
          this.previousbtn = false;
          this.nextbtn = true;
        } else if (parseInt(this.nextRecordId) === this.projects[0].id && parseInt(this.previousRecordId) === this.projects[0].id) {
          this.previousbtn = true;
          this.nextbtn = true;
        } else {
          this.previousbtn = false;
          this.nextbtn = false;
        }
        this.downloadImage();
        if (this.submittedDate === '0000-00-00') {
          this.patchSubmittedDate = '--';
        } else {
          this.patchSubmittedDate = this.submittedDate;
        }
        this.timelines = this.projects[0].timesheetLinses;
        this.startdate = this.projects[0].dateStart;
        this.timesheetId = this.projects[0].id;
        this.objectOneId = this.timelines[0]?.id;
        this.objectTwoId = this.timelines[1]?.id;
        this.objectThreeId = this.timelines[2]?.id;
        this.objectFourId = this.timelines[3]?.id;
        this.objectFiveId = this.timelines[4]?.id;
        this.objectSixId = this.timelines[5]?.id;
        this.objectSevenId = this.timelines[6]?.id;
        this.week1 = this.timelines[0]?.workDate;
        this.week2 = this.timelines[1]?.workDate;
        this.week3 = this.timelines[2]?.workDate;
        this.week4 = this.timelines[3]?.workDate;
        this.week5 = this.timelines[4]?.workDate;
        this.week6 = this.timelines[5]?.workDate;
        this.week7 = this.timelines[6]?.workDate;
        this.project1 = res.data[0]?.prjName1;
        this.project1Id = this.timelines[0]?.prjId1;
        this.project2 = res.data[0]?.prjName2;
        this.project2Id = this.timelines[0]?.prjId2;
        this.project3 = res.data[0]?.prjName3;
        this.project3Id = this.timelines[0]?.prjId3;
        this.project4 = res.data[0]?.prjName4;
        this.project4Id = this.timelines[0]?.prjId4;
        this.project5 = res.data[0]?.prjName5;
        this.project5Id = this.timelines[0]?.prjId5;
        this.calculateTotalHours();
        this.projectsdata.patchValue({
          p1sun: this.timelines[0]?.prj1Hrs,
          p1mon: this.timelines[1]?.prj1Hrs,
          p1tues: this.timelines[2]?.prj1Hrs,
          p1wed: this.timelines[3]?.prj1Hrs,
          p1thurs: this.timelines[4]?.prj1Hrs,
          p1fri: this.timelines[5]?.prj1Hrs,
          p1sat: this.timelines[6]?.prj1Hrs,
          // second project
          p2sun: this.timelines[0]?.prj2Hrs,
          p2mon: this.timelines[1]?.prj2Hrs,
          p2tues: this.timelines[2]?.prj2Hrs,
          p2wed: this.timelines[3]?.prj2Hrs,
          p2thurs: this.timelines[4]?.prj2Hrs,
          p2fri: this.timelines[5]?.prj2Hrs,
          p2sat: this.timelines[6]?.prj2Hrs,
          // Third Project
          p3sun: this.timelines[0]?.prj3Hrs,
          p3mon: this.timelines[1]?.prj3Hrs,
          p3tues: this.timelines[2]?.prj3Hrs,
          p3wed: this.timelines[3]?.prj3Hrs,
          p3thurs: this.timelines[4]?.prj3Hrs,
          p3fri: this.timelines[5]?.prj3Hrs,
          p3sat: this.timelines[6]?.prj3Hrs,
          // fourth project
          p4sun: this.timelines[0]?.prj4Hrs,
          p4mon: this.timelines[1]?.prj4Hrs,
          p4tues: this.timelines[2]?.prj4Hrs,
          p4wed: this.timelines[3]?.prj4Hrs,
          p4thurs: this.timelines[4]?.prj4Hrs,
          p4fri: this.timelines[5]?.prj4Hrs,
          p4sat: this.timelines[6]?.prj4Hrs,
          // fifth project
          p5sun: this.timelines[0]?.prj5Hrs,
          p5mon: this.timelines[1]?.prj5Hrs,
          p5tues: this.timelines[2]?.prj5Hrs,
          p5wed: this.timelines[3]?.prj5Hrs,
          p5thurs: this.timelines[4]?.prj5Hrs,
          p5fri: this.timelines[5]?.prj5Hrs,
          p5sat: this.timelines[6]?.prj5Hrs,
          // internal hours
          isun: this.timelines[0]?.internalHrs,
          imon: this.timelines[1]?.internalHrs,
          itues: this.timelines[2]?.internalHrs,
          iwed: this.timelines[3]?.internalHrs,
          ithurs: this.timelines[4]?.internalHrs,
          ifri: this.timelines[5]?.internalHrs,
          isat: this.timelines[6]?.internalHrs,
          // total hours
          // tslint:disable-next-line: max-line-length
          tsun: this.timelines[0]?.prj1Hrs + this.timelines[0]?.prj2Hrs + this.timelines[0]?.prj3Hrs + this.timelines[0]?.prj4Hrs + this.timelines[0]?.prj5Hrs + this.timelines[0]?.internalHrs,
          // tslint:disable-next-line:max-line-length
          tmon: this.timelines[1]?.prj1Hrs + this.timelines[1]?.prj2Hrs + this.timelines[1]?.prj3Hrs + this.timelines[1]?.prj4Hrs + this.timelines[1]?.prj5Hrs + this.timelines[1]?.internalHrs,
          // tslint:disable-next-line:max-line-length
          ttues: this.timelines[2]?.prj1Hrs + this.timelines[2]?.prj2Hrs + this.timelines[2]?.prj3Hrs + this.timelines[2]?.prj4Hrs + this.timelines[2]?.prj5Hrs + this.timelines[2]?.internalHrs,
          // tslint:disable-next-line:max-line-length
          twed: this.timelines[3]?.prj1Hrs + this.timelines[3]?.prj2Hrs + this.timelines[3]?.prj3Hrs + this.timelines[3]?.prj4Hrs + this.timelines[3]?.prj5Hrs + this.timelines[3]?.internalHrs,
          // tslint:disable-next-line:max-line-length
          tthurs: this.timelines[4]?.prj1Hrs + this.timelines[4]?.prj2Hrs + this.timelines[4]?.prj3Hrs + this.timelines[4]?.prj4Hrs + this.timelines[4]?.prj5Hrs + this.timelines[4]?.internalHrs,
          // tslint:disable-next-line:max-line-length
          tfri: this.timelines[5]?.prj1Hrs + this.timelines[5]?.prj2Hrs + this.timelines[5]?.prj3Hrs + this.timelines[5]?.prj4Hrs + this.timelines[5]?.prj5Hrs + this.timelines[5]?.internalHrs,
          // tslint:disable-next-line:max-line-length
          tsat: this.timelines[6]?.prj1Hrs + this.timelines[6]?.prj2Hrs + this.timelines[6]?.prj3Hrs + this.timelines[6]?.prj4Hrs + this.timelines[6]?.prj5Hrs + this.timelines[6]?.internalHrs,
        });
        this.timesheetNote.patchValue({
          notes: this.projects[0].notes,
          rejectReason: this.projects[0].comments,
        });
      });
    } else {
      if (this.previousId === undefined) {

        this.timeSheetgetId = this.timeSheetnpId - 1;
        this.timeSheetnpId = this.timeSheetnpId - 1;
      } else {
        this.timeSheetgetId = this.timeSheetnpId + 1;
        this.timeSheetnpId = this.timeSheetnpId + 1;
      }
      this.loader = true;
      this.apiservice.getApi(environment.rowEdit + this.timeSheetgetId).subscribe(res => {
        this.loader = false;
        this.projects = res.data;
        this.downloadflag = this.projects[0].fileFlag;
        this.downloadId = this.projects[0].id;
        this.dwnshow = this.downloadflag === 0 ? false : true;
        this.usrID = this.projects[0].usrId;
        this.timeSheetId = this.projects[0].timesheetId;
        this.submittedDate = this.projects[0].submittedDate;
        this.submittedbyname = this.projects[0].submittedByname;
        this.hierarchytotalhours = this.projects[0].totalHrs;
        this.downloadimageid = this.projects[0].imageId;
        this.editFilePath = this.projects[0].filePath;
        this.downloadImage();
        this.gridtimesheetId = this.projects[0].timesheetId;
        // tslint:disable-next-line:radix
        if (parseInt(this.nextRecordId) === this.downloadId && parseInt(this.previousRecordId) === this.downloadId) {
          this.previousbtn = true;
          this.nextbtn = true;
        }
        // tslint:disable-next-line:radix
        else if (parseInt(this.nextRecordId) === this.downloadId) {
          this.previousbtn = false;
          this.nextbtn = true;
          // tslint:disable-next-line:radix
        } else if (parseInt(this.previousRecordId) === this.downloadId) {
          this.previousbtn = true;
          this.nextbtn = false;
          // tslint:disable-next-line:radix
        } else if (parseInt(this.nextRecordId) !== this.downloadId && parseInt(this.previousRecordId) !== this.downloadId) {
          this.previousbtn = false;
          this.nextbtn = false;
          // tslint:disable-next-line:radix
        } else if (parseInt(this.nextRecordId) !== this.downloadId && parseInt(this.previousRecordId) === this.downloadId) {
          this.previousbtn = true;
          this.nextbtn = false;
          // tslint:disable-next-line:radix
        } else if (parseInt(this.nextRecordId) === this.downloadId && parseInt(this.previousRecordId) !== this.downloadId) {
          this.previousbtn = false;
          this.nextbtn = true;
        } else {
          this.previousbtn = false;
          this.nextbtn = false;
        }


        if (this.submittedDate === '0000-00-00') {
          this.patchSubmittedDate = '--';
        } else {
          this.patchSubmittedDate = this.submittedDate;
        }
        this.timelines = this.projects[0].timesheetLinses;
        this.startdate = this.projects[0].dateStart;
        this.timesheetId = this.projects[0].id;
        this.objectOneId = this.timelines[0]?.id;
        this.objectTwoId = this.timelines[1]?.id;
        this.objectThreeId = this.timelines[2]?.id;
        this.objectFourId = this.timelines[3]?.id;
        this.objectFiveId = this.timelines[4]?.id;
        this.objectSixId = this.timelines[5]?.id;
        this.objectSevenId = this.timelines[6]?.id;
        // tslint:disable-next-line:max-line-length
        this.week1 = this.timelines[0]?.workDate;
        this.week2 = this.timelines[1]?.workDate;
        this.week3 = this.timelines[2]?.workDate;
        this.week4 = this.timelines[3]?.workDate;
        this.week5 = this.timelines[4]?.workDate;
        this.week6 = this.timelines[5]?.workDate;
        this.week7 = this.timelines[6]?.workDate;
        this.project1 = res.data[0]?.prjName1;
        this.project1Id = this.timelines[0]?.prjId1;
        this.project2 = res.data[0]?.prjName2;
        this.project2Id = this.timelines[0]?.prjId2;
        this.project3 = res.data[0]?.prjName3;
        this.project3Id = this.timelines[0]?.prjId3;
        this.project4 = res.data[0]?.prjName4;
        this.project4Id = this.timelines[0]?.prjId4;
        this.project5 = res.data[0]?.prjName5;
        this.project5Id = this.timelines[0]?.prjId5;
        this.calculateTotalHours();
        this.projectsdata.patchValue({
          p1sun: this.timelines[0]?.prj1Hrs,
          p1mon: this.timelines[1]?.prj1Hrs,
          p1tues: this.timelines[2]?.prj1Hrs,
          p1wed: this.timelines[3]?.prj1Hrs,
          p1thurs: this.timelines[4]?.prj1Hrs,
          p1fri: this.timelines[5]?.prj1Hrs,
          p1sat: this.timelines[6]?.prj1Hrs,
          // second project
          p2sun: this.timelines[0]?.prj2Hrs,
          p2mon: this.timelines[1]?.prj2Hrs,
          p2tues: this.timelines[2]?.prj2Hrs,
          p2wed: this.timelines[3]?.prj2Hrs,
          p2thurs: this.timelines[4]?.prj2Hrs,
          p2fri: this.timelines[5]?.prj2Hrs,
          p2sat: this.timelines[6]?.prj2Hrs,
          // Third Project
          p3sun: this.timelines[0]?.prj3Hrs,
          p3mon: this.timelines[1]?.prj3Hrs,
          p3tues: this.timelines[2]?.prj3Hrs,
          p3wed: this.timelines[3]?.prj3Hrs,
          p3thurs: this.timelines[4]?.prj3Hrs,
          p3fri: this.timelines[5]?.prj3Hrs,
          p3sat: this.timelines[6]?.prj3Hrs,
          // fourth project
          p4sun: this.timelines[0]?.prj4Hrs,
          p4mon: this.timelines[1]?.prj4Hrs,
          p4tues: this.timelines[2]?.prj4Hrs,
          p4wed: this.timelines[3]?.prj4Hrs,
          p4thurs: this.timelines[4]?.prj4Hrs,
          p4fri: this.timelines[5]?.prj4Hrs,
          p4sat: this.timelines[6]?.prj4Hrs,
          // fifth project
          p5sun: this.timelines[0]?.prj5Hrs,
          p5mon: this.timelines[1]?.prj5Hrs,
          p5tues: this.timelines[2]?.prj5Hrs,
          p5wed: this.timelines[3]?.prj5Hrs,
          p5thurs: this.timelines[4]?.prj5Hrs,
          p5fri: this.timelines[5]?.prj5Hrs,
          p5sat: this.timelines[6]?.prj5Hrs,
          // internal hours
          isun: this.timelines[0]?.internalHrs,
          imon: this.timelines[1]?.internalHrs,
          itues: this.timelines[2]?.internalHrs,
          iwed: this.timelines[3]?.internalHrs,
          ithurs: this.timelines[4]?.internalHrs,
          ifri: this.timelines[5]?.internalHrs,
          isat: this.timelines[6]?.internalHrs,
          // total hours
          // tslint:disable-next-line: max-line-length
          tsun: this.timelines[0]?.prj1Hrs + this.timelines[0]?.prj2Hrs + this.timelines[0]?.prj3Hrs + this.timelines[0]?.prj4Hrs + this.timelines[0]?.prj5Hrs + this.timelines[0]?.internalHrs,
          // tslint:disable-next-line:max-line-length
          tmon: this.timelines[1]?.prj1Hrs + this.timelines[1]?.prj2Hrs + this.timelines[1]?.prj3Hrs + this.timelines[1]?.prj4Hrs + this.timelines[1]?.prj5Hrs + this.timelines[1]?.internalHrs,
          // tslint:disable-next-line:max-line-length
          ttues: this.timelines[2]?.prj1Hrs + this.timelines[2]?.prj2Hrs + this.timelines[2]?.prj3Hrs + this.timelines[2]?.prj4Hrs + this.timelines[2]?.prj5Hrs + this.timelines[2]?.internalHrs,
          // tslint:disable-next-line:max-line-length
          twed: this.timelines[3]?.prj1Hrs + this.timelines[3]?.prj2Hrs + this.timelines[3]?.prj3Hrs + this.timelines[3]?.prj4Hrs + this.timelines[3]?.prj5Hrs + this.timelines[3]?.internalHrs,
          // tslint:disable-next-line:max-line-length
          tthurs: this.timelines[4]?.prj1Hrs + this.timelines[4]?.prj2Hrs + this.timelines[4]?.prj3Hrs + this.timelines[4]?.prj4Hrs + this.timelines[4]?.prj5Hrs + this.timelines[4]?.internalHrs,
          // tslint:disable-next-line:max-line-length
          tfri: this.timelines[5]?.prj1Hrs + this.timelines[5]?.prj2Hrs + this.timelines[5]?.prj3Hrs + this.timelines[5]?.prj4Hrs + this.timelines[5]?.prj5Hrs + this.timelines[5]?.internalHrs,
          // tslint:disable-next-line:max-line-length
          tsat: this.timelines[6]?.prj1Hrs + this.timelines[6]?.prj2Hrs + this.timelines[6]?.prj3Hrs + this.timelines[6]?.prj4Hrs + this.timelines[6]?.prj5Hrs + this.timelines[6]?.internalHrs,
        });
        this.timesheetNote.patchValue({
          notes: this.projects[0].notes,
          rejectReason: this.projects[0].comments,
        });
      });

    }
  }
  // It is used to download files
  downloadFileapi() {
    this.isUploading1 = true;
    if (this.downloadflag !== 0 && this.downloadflag !== null || this.downloadflag !== undefined) {
      this.apiservice.getApi(environment.timesheetattachmentdownload + this.downloadId).subscribe((res: any) => {
        this.downloadData = res;
        window.location.assign(this.downloadData.downloadUrl);
        this.isUploading1 = false;
      });
    }
  }
  cancelfiles() {
    this.timesheetNote.controls.addResumeForm.reset();
    this.filesdatass = false;
    this.classinput = false;
    this.classinput1 = true;
  }
  // This method allows you to enable submit current week timesheet on firday and remaining weeks can be submited at any date.
  enableSubmitOnWeekEnd() {
    this.currentdate = new Date();
    this.weekEndFridayDate = new Date(this.enddate);
    this.weekEndFridayDate.setDate(this.weekEndFridayDate.getDate() - 1);
    if (this.weekEndFridayDate > this.currentdate) {
      this.savenbtn = true;
    } else if (this.weekEndFridayDate < this.currentdate) {
      this.savenbtn = false;
    } else {
      this.savenbtn = false;
    }
  }
}


