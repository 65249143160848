import { Component, OnInit, HostListener } from '@angular/core';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { MessageService } from 'primeng/api';
import * as XLSX from 'xlsx';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import { isEmpty } from 'rxjs/operators';
@Component({
  selector: 'app-project-requirements',
  templateUrl: './project-requirements.component.html',
  styleUrls: ['./project-requirements.component.scss'],
  providers: [DatePipe, MessageService],
})
export class ProjectRequirementsComponent implements OnInit {
  iconToggle: any = [];
  cols = [
    { field: 'reqId', header: 'Job Id', sno: 1 },
    { field: 'reqName', header: 'Position Title', sno: 2 },
    { field: 'noOfPositions', header: 'Positions', sno: 3 },
    { field: 'requiredSkills', header: ' Skills', sno: 4 },
    { field: 'requiredSkills', header: ' Skills', sno: 5 },
    { field: 'postedDate', header: 'Posted Date', sno: 6 },
    { field: 'reqStatus', header: 'Status', sno: 7 },
    { field: 'submissions', header: 'Submissions', sno: 8 },
    { field: '', header: 'Action', sno: 9 }
  ];
  cols3 = [
    { dataKey: 'reqId', header: 'Job Id' },
    { dataKey: 'reqName', header: 'Position Title' },
    { dataKey: 'noOfPositions', header: 'Positions' },
    { dataKey: 'skills', header: ' Skills' },
    { dataKey: 'postedDate', header: 'Posted Date' },
    { dataKey: 'status', header: 'Status' },
    { dataKey: 'submissions', header: 'Submissions' },
  ];
  requirementsForm: FormGroup;
  statusList: any = [];
  userDetails: any;
  userAccId: any;
  first: any;
  rows: any = 10;
  page: any = 1;
  pageCount: any;
  userRoleId: any;
  listOfRequirements: any;
  reqCount: any;
  totalRecords: any;
  skillsList: any;
  tableStatus: any;
  display: boolean;
  reqSkillsValue: any[];
  reqStartDate: string;
  reqEndDate: string;
  table = true;
  records: boolean;
  histroy1: any;
  items: ({
    label: string; icon: string;
    // tslint:disable-next-line:no-shadowed-variable
    command: (event: any, i: any) => void;
  } | {
    label: string; icon: string;
    // tslint:disable-next-line:no-shadowed-variable
    command?: undefined;
  })[];
  downloadOptions: { label: string; icon: string; command: (event: any, i: any) => void; }[];
  reqIdData: any;
  securityReleaseResponse: any;
  loader: boolean;
  rowData: any;
  vendorNames: any;
  releaseDialog: boolean;
  releaseForm: FormGroup;
  actId: any;
  userdata: number;
  requirementsData: any;
  dbQuery: any;
  requirementsGridData: any;
  acctdetails: any;
  roleId: any;
  reqcount: any;
  displayModal: boolean;
  histroy: any;
  date: Date;
  disable = false;
  displayreason = false;
  reason: any;
  array: any = [];
  usrroleid: any;
  // tslint:disable-next-line:max-line-length
  constructor(private breadcrumbService: BreadcrumbService, private messageService: MessageService, private apiService: ApisService, private datepipe: DatePipe, private router: Router, private fb: FormBuilder) {
    this.breadcrumbService.setItems([
      { label: 'Requirements', routerLink: '/project-requirements' },
    ]);
  }
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.search();
    }
  }
  ngOnInit(): void {
    this.requirementsForm = this.fb.group({
      jobId: [''],
      jobTitle: [''],
      status: [''],
      startDate: [''],
      endDate: ['']
    });
    this.releaseForm = this.fb.group({
      vendorNameControl: [''],
      comments: ['']
    });
    this.apiService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    this.userDetails = JSON.parse(this.apiService.decodeData());
    this.userAccId = this.userDetails.accId;

    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
      this.userRoleId = this.userDetails.roles[0].id;
    } else {
      this.userRoleId = this.usrroleid;
    }
    this.getStatus();
    this.reqSkillSet();
    this.getRequirementsList();
  }
  getStatus() {
    this.apiService.getApi(environment.lookupsData + 'RELS').subscribe((res) => {
      this.statusList = res.data;
    });
  }
  reqSkillSet() {
    this.apiService.getApi(environment.lookupsData + 'RELRS').subscribe((res) => {
      this.skillsList = res.data;
    });
  }
  getRequirementsList() {
    this.loader = true;
    const pgNo = this.page;
    const recordPage = this.rows;
    const data = '/' + pgNo + '/' + recordPage + '?' + 'accId=' + this.userAccId + '&' + 'primaryRole=' + this.userRoleId + '&isAssigned=' + '';
    this.apiService.getApi(environment.requirementGrid + data).subscribe((res) => {
      this.loader = false;
      this.listOfRequirements = res.data.records;
      this.reqCount = res.data.count;
      this.loader = false;
      if (this.reqCount !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  search() {
    this.loader = true;
    // tslint:disable-next-line:max-line-length
    if (this.requirementsForm.controls.startDate.value === '' || this.requirementsForm.controls.startDate.value === null || this.requirementsForm.controls.startDate.value === undefined) {
      this.reqStartDate = '';
    } else {
      const startdate = this.requirementsForm.controls.startDate.value;
      const reqstartDate = new Date(startdate);
      this.reqStartDate = this.datepipe.transform(reqstartDate, 'MM-dd-yyyy');
    }
    // tslint:disable-next-line:max-line-length
    if (this.requirementsForm.controls.endDate.value === '' || this.requirementsForm.controls.endDate.value === null || this.requirementsForm.controls.endDate.value === undefined) {
      this.reqEndDate = '';
    } else {
      const endDate = this.requirementsForm.controls.endDate.value;
      const reqendtDate = new Date(endDate);
      this.reqEndDate = this.datepipe.transform(reqendtDate, 'MM-dd-yyyy');
    }
    const jobId = this.requirementsForm.controls.jobId.value === null ? '' : this.requirementsForm.controls.jobId.value;
    const jobTitle = this.requirementsForm.controls.jobTitle.value === null ? '' : this.requirementsForm.controls.jobTitle.value;
    // tslint:disable-next-line:max-line-length
    const jodStatus = this.requirementsForm.controls.status.value === null ? '' : this.requirementsForm.controls.status.value === '' ? '' : this.requirementsForm.controls.status.value;
    const pgNo = this.page;
    const recordPage = this.rows;
    const createBy = 0;
    const data = '/' + pgNo + '/' + recordPage + '?accId=' + this.userAccId + '&reqId=' + jobId + '&createdBy=' + createBy + '&customerName=' + '' + '&reqName=' + jobTitle + '&startDate=' + this.reqStartDate + '&endDate=' + this.reqEndDate + '&reqStatus=' + jodStatus + '&primaryRole=' + this.userRoleId + '&isAssigned=' + '' + '&recruiterId=' + '';
    this.apiService.getApi(environment.requirementGrid + data).subscribe((res) => {
      this.listOfRequirements = res.data.records;
      this.reqCount = res.data.count;
      this.loader = false;
      if (this.reqCount !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.getRequirementsList();
  }
  getSkillSetTable(data) {
    this.array = data;
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.skillsList?.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      if (data === null || data === undefined || data === '' || data === isEmpty()) { } else {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.array.length; j++) {
          if (Number(this.skillsList[i].code) === this.array[j]) {
            selectedPrePrimarySkills3.push(
              this.skillsList[i].label
            );
          } else {
          }
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  toggle(menu, event, rowData) {
    this.rowData = rowData;
    if (this.rowData.reqStatus === 'A') {
      // tslint:disable-next-line:no-shadowed-variable
      this.items = [
      // tslint:disable-next-line: no-shadowed-variable
      { label: 'Copy', icon: 'pi pi-clone', command: (event, i) => { this.copyReq(rowData); } },
      // tslint:disable-next-line:no-shadowed-variable
      { label: 'Release', icon: 'pi pi-arrow-circle-right', command: (event, i) => { this.showReleaseDialog(); } },
      { label: 'Requirement Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); } },
        //   { label: 'Vendors', icon: 'fa fa-users', command: (event, i) => { this.router.navigate(['/vendor-details'],{
        //     queryParams:{
        //       vendor:'vendor',
        //       reqId:this.rowData.reqId
        //     }
        //   }) }
        //  },
      ];
    }
    else if (this.rowData.reqStatus === 'R') {
      this.items = [
        // tslint:disable-next-line: no-shadowed-variable
        { label: 'Copy', icon: 'pi pi-clone', command: (event, i) => { this.copyReq(rowData); } },
        {
          // tslint:disable-next-line:no-shadowed-variable
          label: 'Vendors', icon: 'fa fa-users', command: (event, i) => {
            this.router.navigate(['/vendor-details'], {
              queryParams: {
                vendor: 'vendor',
                reqId: this.rowData.reqId,
                role: 'projectManager'
              }
            });
          }
        },
        { label: 'Requirement Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); } },
      ];
    }
    else if (this.rowData.reqStatus === 'J') {
      this.items = [
        // tslint:disable-next-line:no-shadowed-variable
        { label: 'Rejection Reason', icon: 'pi pi-comments', command: (event, i) => { this.showReasonDialog(rowData); } },
        { label: 'Requirement Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); } },
        //   { label: 'Vendors', icon: 'fa fa-users', command: (event, i) => { this.router.navigate(['/vendor-details'],{
        //     queryParams:{
        //       vendor:'vendor',
        //       reqId:this.rowData.reqId
        //     }
        //   }) }
        // }
      ];
    } else {
      this.items = [
        // tslint:disable-next-line: no-shadowed-variable
        { label: 'Copy', icon: 'pi pi-clone', command: (event, i) => { this.copyReq(rowData); } },
        { label: 'Requirement Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); } },
        // { label: 'Vendors', icon: 'fa fa-users', command: (event, i) => { this.router.navigate(['/vendor-details'],{
        //   queryParams:{
        //     vendor:'vendor',
        //     reqId:this.rowData.reqId
        //   }
        // }) } },
      ];
    }
  }
  showReasonDialog(rowData) {
    this.reason = rowData.approverComments;
    this.displayreason = true;
  }
  // It is used for the displaying the data of the grid
  getRequirementsData() {
    this.loader = true;
    const pgNo = this.page;
    const recordPage = this.rows;
    this.userdata = 1;
    this.loader = true;
    const statusVal = this.requirementsForm.controls.status.value;
    // tslint:disable-next-line:max-line-length
    const assingId = statusVal === '' || statusVal === null || statusVal === undefined ? 1 : this.requirementsForm.controls.status.value.code;
    const data = '/' + pgNo + '/' + recordPage + '?' + 'accId=' + this.userDetails.accId + '&' + 'primaryRole=' + this.userRoleId + '&isAssigned=' + assingId;
    this.apiService.getApi(environment.requirementGrid + data).subscribe((res) => {
      this.requirementsData = res.data.records;
      this.reqcount = res.data.count;
      if (this.reqcount !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.dbQuery = this.requirementsData.reqDownloadQueryString;
      this.totalRecords = this.requirementsData.reqCount;
      this.loader = false;
      this.requirementsGridData =
        this.requirementsData.loginUserRequirementsList;
    });
  }
  showReleaseDialog() {
    this.releaseForm.reset();
    this.apiService.getApi(environment.getVendorNames + '?accId=' + this.rowData.createdByAccId).subscribe((res: any) => {
      this.vendorNames = res.data;
    });
    this.releaseDialog = true;
  }
  // It is used for when click on the release column based on the status open the dialog box
  releasedDialogBox() {
    this.disable = true;
    const data = this.rowData;
    this.reqIdData = data.reqId;
    if (data.reqStatus === 'A') {
      this.depositPolicy();
    }
    this.disable = false;
    this.releaseDialog = false;
  }
  depositPolicy() {
    // tslint:disable-next-line:max-line-length
    Swal.fire({
      // tslint:disable-next-line:max-line-length
      text: 'Do you want to release requirement?', icon: 'warning', showCancelButton: true, confirmButtonColor: '#0D416B', confirmButtonText: 'Yes', cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        return this.releasedYes();
      }
    });
  }
  releasedYes() {
    // tslint:disable-next-line:max-line-length
    this.actId = this.releaseForm.controls.vendorNameControl.value === null || this.releaseForm.controls.vendorNameControl.value === undefined || this.releaseForm.controls.vendorNameControl.value === '' ? 0 : this.releaseForm.controls.vendorNameControl.value.accId;
    this.apiService.getApi(environment.getRealeseReq + this.reqIdData + '?venId=' + this.actId + '&flag=' + 'N').subscribe((res: any) => {
      if (res.hasOwnProperty('success') === true) {
        // tslint:disable-next-line: max-line-length
        Swal.fire({ text: res.message, icon: 'success', iconColor: '#1dbb99', confirmButtonColor: '#0D416B', confirmButtonText: 'Ok', reverseButtons: false });
        this.disable = false;
        this.releaseDialog = false;
        this.getRequirementsData();
        this.getRequirementsList();
      } else if (
        res.hasOwnProperty('success') === false
      ) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
      } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
      }
    });
    this.reset();
  }
  addReq() {
    this.router.navigate(['/add-requiremenent']);
  }
  copyReq(data) {
    this.router.navigate(['/copy-requirement', data.reqId]);
  }
  reset() {
    this.requirementsForm.reset();
    this.getRequirementsList();
  }
  getStatusTable(data) {
    const item = data === null || data === undefined ? '' : this.statusList.find((element) => element.code === data);
    this.tableStatus = item?.label;
    return this.tableStatus;
  }
  reqSkillsPopUp(data) {
    this.display = true;
    this.reqSkillsValue = data;
  }
  downLoadsToggle() {
    this.downloadOptions = [
      { label: 'Download XLSX', icon: 'pi pi-download', command: (event, i) => { this.exportExcel(); } },
      { label: 'Download PDF', icon: 'pi pi-download', command: (event, i) => { this.exportPdf(); } },
    ];
  }
  reqEditRoute(data) {
    this.loader = true;
    sessionStorage.setItem('requirementData', JSON.stringify(data));
    this.router.navigate(['/project-edit-requirement'], {
    queryParams: {
      createdbyaccID: data.createdByAccId,
    },
  });
}
  add() {
    this.router.navigate(['/add-requiremenent']);
  }
  add1() {
    this.router.navigate(['/project-edit-requirement']);
  }
  profilehistroy() {
    this.displayModal = true;
    this.date = new Date();
    const actId = JSON.parse(this.apiService.decodeData());
    this.apiService.getApi(environment.getreqActivity + this.rowData.reqId).subscribe((res: any) => {
      this.histroy = res.data;
      this.histroy1 = this.rowData.reqId;
    });
  }
  exportExcel() {
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    // tslint:disable-next-line:max-line-length
    ws['!cols'] = [ {width : 10}, {width : 30}  , {width : 10} , {width : 30}, {width : 30}, {width : 20}, {width : 10}, {width : 14}, {hidden : true} ];
    ws['!rows'] = [{hpt : 30}, {hpt : 30}, ];
    ws['!cols'][3] = { hidden: true };
    ws['!cols'][8] = { hidden: true };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ExcelSheet.xlsx');
  }
  exportPdf() {
    // tslint:disable-next-line: no-shadowed-variable
    this.listOfRequirements.forEach((element) => {
      element.status = this.getStatusTable(element.reqStatus);
      element.skills = this.getSkillSetTable(element.requiredSkills);
    });
    const doc = new jsPDF('p', 'pt');
    const element = document.getElementById('my-table');
    autoTable(doc, {
      headStyles: { fillColor: '#2bb9d5', cellPadding: 5 , cellWidth: 85 },
      margin: { left: 3},
      columns: this.cols3,
      body: this.listOfRequirements,
      didDrawPage: (dataArg) => {
        doc.text('Requirements', dataArg.settings.margin.left, 30);
      }
    });
    doc.save('Requirements.pdf');
  }
}
