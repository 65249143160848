<p-toast position="bottom-right" key="br"></p-toast>
<form [formGroup]="accountSearchForm">
  <p-card>
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Name</label>
        <input id="input" type="text" pInputText placeholder="Account Name" autocomplete="off"
          (keyup.enter)="searchData()" formControlName="accountName" maxlength="60">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Corporate Website</label>
        <input id="input" type="text" pInputText placeholder="www.servicesbay.com" autocomplete="off"
          (keyup.enter)="searchData()" formControlName="accountUrl" maxlength="60">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Zip</label>
        <input id="input" type="text" pInputText placeholder="Zip" pKeyFilter="num" autocomplete="off"
          (keyup.enter)="searchData()" formControlName="accountZip" maxlength="10">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Industry</label>
        <p-dropdown [options]="industry" optionLabel="label" placeholder="Select Industry"
          formControlName="accountIndustry" (keyup.enter)="searchData()" [showClear]="true"></p-dropdown>
      </div>

      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Status</label>
        <p-dropdown [options]="status" placeholder="Select Status" (keyup.enter)="searchData()" optionLabel="label"
          formControlName="accountStatus" [showClear]="true"></p-dropdown>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Country</label>
        <p-dropdown [options]="country" placeholder="Select Country" optionLabel="label"
          formControlName="accountCountry" (keyup.enter)="searchData()" (onChange)="getStateByUrl($event.value.code)"
          [showClear]="true">
        </p-dropdown>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">State</label>
        <p-dropdown [options]="state" placeholder="Select State" optionLabel="label" formControlName="accountState"
          (keyup.enter)="searchData()" [showClear]="true"></p-dropdown>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3" *ngIf="roleID === 1">
        <label class="inputfields">Type</label>
        <p-dropdown [options]="type" placeholder="Select Account Type" optionLabel="label" (keyup.enter)="searchData()"
          formControlName="accountType" [showClear]="true"></p-dropdown>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Region</label>
        <p-dropdown [options]="regionOptions" placeholder="Select Region" optionLabel="label" formControlName="region"
          (keyup.enter)="searchData()" (onChange)="getStateByUrl($event.value.code)" [showClear]="true">
        </p-dropdown>

      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Sales Associated</label>
        <p-dropdown [options]="Sales" placeholder="Select Sales Associated" optionLabel="label"
          formControlName="salesAssociated" (keyup.enter)="searchData()" [showClear]="true">
        </p-dropdown>

      </div>
    </div>
    <div class="btnsCSS1 btnalign">
      <button pButton type="button" *ngIf="roleID === 0" icon='pi pi-plus' label="Add" (click)="addData()"
        class="addhBtn"></button>
      <p-splitButton label="Import" [model]="items1" class="addhBtn"></p-splitButton>
      <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" class="addhBtn"></button>
      <button pButton type="button" icon='pi pi-search' label="Search" (click)="searchData()" class="addhBtn"></button>
    </div><br>
    <div *ngIf="isUploading" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
      <p-progressBar mode="indeterminate"></p-progressBar>
    </div>
    <div *ngIf="showUploadView" class="dropzone p-col-12 p-md-12 p-sm-12 p-lg-3">
      <em class="pi pi-upload uploadIcon Iconsize" aria-hidden="true"></em>
      <input accept=".xlsm,.xlsx,application/msexcel" type="file" class="fileInput" #fileDropRef id="fileDropRef"
        multiple (change)="onUpload($event.target.files)" style="cursor: pointer;" />
      <h3>Drag and drop file here</h3>
      <h3 class="importstylin">or</h3>
      <label id="fileUpload" for="fileDropRef">Browse for files</label>
    </div>
  </p-card><br>
  <p-card class="pgpos">
    <div *ngIf="table">
      <p-table [columns]="cols" [value]="accounSearchGridData" class="tablescroll" [columns]="cols" dataKey="edit"
        selectionMode="single" [responsive]="true" sortMode="multiple">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th id="gridHeader" *ngFor="let col of columns let i=index" (click)="iconToggle[i] = !iconToggle[i]"
              [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
              {{col.header}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data let-columns="columns" let-rowIndex="rowIndex">
          <tr>
            <td class="textCenter"><span class="p-column-title">#</span>{{+this.page === 1 ? rowIndex +1 : (+(this.page
              -1)*this.rows) + (rowIndex +1)}}</td>
            <td class="textCenter pointer skill-desc" tooltipPosition="top" pTooltip="{{data.accName}}" (click)="navigateTo(data)"><span
                class="p-column-title">Name</span>
              {{data.accName}}</td>
            <td class="textCenter skill-desc" tooltipPosition="top" pTooltip="{{data.website}}"><span class="p-column-title">Corporate Website</span><a
                (click)="goToLink(data.website)">{{data.website}}</a></td>
            <td class="textCenter"><span class="p-column-title">Account Type</span>{{ typeTable[data.lkAccountType]}}
            </td>
            <td class="textCenter"><span class="p-column-title">country</span><a
                (click)="navigateTo(data)"></a>{{contryTable[data.country]}}</td>
            <td class="textCenter"><span class="p-column-title">Region</span>{{regiontype[data.lkRegion]}}
            </td>
            <td>
              <p-badge [value]="statuTable[data.lkStatus]"
                [ngClass]="data.lkStatus==='A'?'green': data.lkStatus === 'I'?'red': 'blue'">
              </p-badge>
            </td>

            <td class="textCenter"><span class="ui-column-title"></span><a>
                <em (click)="data.lkStatus !== 'R' ? actionpopup.toggle($event) : ''" (click)="toggle($event, data)"
                  class="pi pi-ellipsis-v"></em></a></td>
          </tr>
        </ng-template>
        <!-- <ng-template pTemplate="emptymessage">
          <tr *ngIf="accounSearchGridData?.length==0" class="aligncenter">
            <td colspan="6" class="mandatoryColor">
              No records
            </td>
          </tr>
        </ng-template> -->
      </p-table>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
          <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
          <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
            (onPageChange)="paginate($event)"></p-paginator>
        </div>
      </div>
    </div>
    <p-card *ngIf="accounSearchGridData?.length==0" >
      <div class="norecordImgsize">
        <div *ngIf="records" class="aligncenter">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
          <br><em>Sorry, we are not able to find any accounts</em><br>
        </div>
      </div>
    </p-card>

  </p-card>
</form>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center laoderheight">
        <div class="laodersize">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
            class="loaderimg-height">
        </div>
      </div>
    </div>
  </div>
</ng-template>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>