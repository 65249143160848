<p-toast position="bottom-right" key="br"></p-toast>
<form>
    <p-card>
        <p-panel header="My Network">
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                    <p-megaMenu [model]="items" orientation="vertical"></p-megaMenu>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                    <div *ngIf="selectedCategoryList === 'Invitations'">
                        <div class="p-col-12">
                            <p-dataView #dv [value]="networkData" [paginator]="false" [rows]="9" filterBy="name">
                                <p-header>
                                    Invitations
                                </p-header>
                                <ng-template let-data pTemplate="listItem">
                                    <div class="p-col-12">
                                        <div class="product-list-item">
                                            <div class="product-list-detail">
                                                <div class="p-grid p-fluid"
                                                    style="align-items: center;margin-top: 2px;">
                                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3"
                                                        style="text-align: center">
                                                        <img alt=""
                                                            [src]="data.downloadUrl ? data.downloadUrl : (data.gender === 'M'? 'https://d2r8nxfsv3v6y3.cloudfront.net/images/male.png' :  '../../https://d2r8nxfsv3v6y3.cloudfront.net/images/female.png')"
                                                            style="width: 25%;height: 80%; border-radius: 10%;" />
                                                    </div>
                                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                                                    </div>
                                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-5"
                                                        (click)="invitationInfo(data)">
                                                        <div><strong>{{data.name}}</strong></div>
                                                        <div><strong>{{data.designation}}</strong> at
                                                            {{data.companyName}}</div>
                                                        <div>...</div>
                                                        <p>{{durationCal(data.daysAgo,data.createdDate)}}</p>
                                                    </div>
                                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                                                    </div>
                                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                                                        <div>
                                                            <button pButton type="button" icon="pi pi-times"
                                                                tooltipPosition="top" pTooltip="Ignore"
                                                                (click)="confirmIgnore(data)"
                                                                class="ui-button-rounded  ui-button-danger"></button>&nbsp;&nbsp;&nbsp;
                                                            <button pButton type="button" icon="pi pi-check"
                                                                tooltipPosition="top"
                                                                (click)="actionpopup.toggle($event); toggle($event, data)"
                                                                pTooltip="Accept" class="ui-button-rounded"></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-dataView>
                        </div>
                    </div>
                    <div class="p-grid ui-fluid ">
                        <div *ngIf="totalRecords !== 0 && selectedCategoryList === 'Invitations'"
                            class="p-col-12 p-md-12 p-sm-12 p-lg-2 inv">
                            <h3 class="norecorddata">Total records : {{totalRecords}}</h3>
                        </div>
                        <div *ngIf="totalRecords !== 0 && selectedCategoryList === 'Invitations'"
                            class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                            <p-paginator (onPageChange)="paginate($event,'invitations')" [rows]="10"
                                [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"></p-paginator>
                        </div>
                    </div>
                    <div *ngIf="selectedCategoryList === 'Connections'">
                        <div class=" inboxContainer">
                            <div class="p-col-12" *ngIf="!show">
                                <p-dataView #dv [value]="networkData" [paginator]="false" [rows]="9" filterBy="name">
                                    <ng-template pTemplate="header">
                                        <p style="font-size: 18px;">{{totalRecords}} Connection(s)</p><br>

                                        <div class="p-grid p-fluid">
                                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                                <p-dropdown (onChange)="getConnectionsData()"
                                                    [(ngModel)]="relationshipType" name="relationshipType"
                                                    [options]="connectionTypes" optionLabel="label" [showClear]="false">
                                                </p-dropdown>
                                            </div>
                                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                                <p-dropdown (onChange)="getConnectionsData()" [(ngModel)]="scopesType"
                                                    name="scopesType" [options]="scopesTypes" optionLabel="label"
                                                    [showClear]="false"></p-dropdown>
                                            </div>
                                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2"></div>
                                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                                <span class="p-input-icon-left p-mb-2 p-mb-md-0">
                                                    <em class="pi pi-search searchstyle"></em>
                                                    <input formControlName="connectionsearch" type="search" pInputText
                                                        name="searchTerm" [(ngModel)]="searchTerm"
                                                        placeholder="Search by Name">
                                                </span>
                                            </div>
                                        </div>
                                    </ng-template><br>
                                    <ng-template let-data pTemplate="listItem">
                                        <div class="p-col-12">
                                            <div class="product-list-item">
                                                <div class="product-list-detail">
                                                    <div class="p-grid p-fluid"
                                                        style="align-items: center;margin-top: 2px;">
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3"
                                                            style="text-align: center">
                                                            <img alt=""
                                                                [src]="data.downloadUrl ? data.downloadUrl : (data.gender === 'M'? 'https://d2r8nxfsv3v6y3.cloudfront.net/images/male.png' :  'https://d2r8nxfsv3v6y3.cloudfront.net/images/male.png')"
                                                                style="width: 25%;height: 86%; border-radius: 10%;" />
                                                        </div>
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-5">
                                                            <div><strong>{{data.name}}</strong></div>
                                                            <div><strong>{{data.designation}}</strong> at
                                                                {{data.companyName}}
                                                            </div>
                                                            <div>...</div>
                                                            <div>{{durationCal(data.daysAgo,data.connectedDate)}}</div>
                                                            <div>
                                                                {{lastConversationCal(data.lastConversationDate,data.connectedDate)}}
                                                            </div>
                                                        </div>
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                                                        </div>
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                                            <div style="margin-top: 10px;">

                                                                <em style="width: 50%;"
                                                                    (click)="downloadpopup.toggle($event); toggle($event,data)"
                                                                    class="pi pi-ellipsis-v pointer"></em>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-dataView>
                            </div>
                            <div class="p-col-12" *ngIf="show">
                                <p-dataView #dv [value]="networkData1" [paginator]="false" [rows]="9" filterBy="name">
                                    <ng-template pTemplate="header">
                                        <em class="pi pi-arrow-left"
                                            style="font-size: 15px; float: right;cursor: pointer;"
                                            (click)="homeroute()"></em>
                                        <p>Show Details</p>
                                    </ng-template><br>
                                    <ng-template let-data pTemplate="listItem">
                                        <div class="p-col-12">
                                            <div class="product-list-item">
                                                <div class="product-list-detail">
                                                    <div class="p-grid p-fluid"
                                                        style="align-items: center;margin-top: 2px;">
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3"
                                                            style="text-align: center">
                                                            <img alt=""
                                                                [src]="data.data.downloadUrl ? data.data.downloadUrl : (data.data.gender === 'M'? 'https://d2r8nxfsv3v6y3.cloudfront.net/images/male.png' :  'https://d2r8nxfsv3v6y3.cloudfront.net/images/male.png')"
                                                                style="width: 38%;height: 86%; border-radius: 10%;" />
                                                        </div>
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-5">
                                                            <div><strong>{{data.data.userName}}</strong></div>
                                                            <div><strong>{{data.data.title}}</strong> at
                                                                {{data.data.companyName}}
                                                            </div>
                                                            <div>...</div>
                                                            <div>
                                                                {{durationCal(data.data.daysAgo,data.data.connectedDate)}}
                                                            </div>
                                                            <div>
                                                                {{lastConversationCal(data.data.lastConversationDate,data.data.connectedDate)}}
                                                            </div>
                                                        </div>
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                                                        </div>
                                                    </div>
                                                    <p-panel [toggleable]="false">
                                                        <p-header>Academics</p-header>
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                                                            <div *ngFor="let data of data.academics; let i=index">
                                                                <strong>{{data.degree}}</strong> in
                                                                <strong>{{data.branchName}}</strong>
                                                                from
                                                                <strong>{{data.institutionName}}
                                                                    ({{data.universityName}})</strong>
                                                                <hr>
                                                            </div>
                                                        </div>
                                                    </p-panel>
                                                    <p-panel [toggleable]="false">
                                                        <p-header>
                                                            Certifications
                                                        </p-header>
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                                                            <div *ngFor="let data of data.certification; let i=index">
                                                                <strong>{{data.cName}}</strong> from
                                                                <strong>{{data.institutionName}}</strong>
                                                                <hr>
                                                            </div>
                                                        </div>
                                                    </p-panel>
                                                    <p-panel [toggleable]="false">
                                                        <p-header> Licence </p-header>
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                                                            <div *ngFor="let data of data?.license; let i=index">
                                                                <strong>{{data.cName}}</strong> from
                                                                <strong>{{data.institutionName}}</strong>
                                                                <hr>
                                                            </div>
                                                        </div>
                                                    </p-panel>
                                                    <p-panel [toggleable]="false">
                                                        <p-header> Skills
                                                        </p-header>
                                                        <div class="p-grid p-fluid">
                                                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                                                <label>Primary skills</label>
                                                                <hr>
                                                                <div
                                                                    *ngFor="let data of normalprimaryskill; index as  i">
                                                                    <strong>{{primaryTable[data]}}</strong>
                                                                    <strong><em class="pi pi-plus"
                                                                            (click)="showDialog2(data)"
                                                                            style="font-size: 10px; float: right; cursor: pointer;padding: 0em 1em;"></em></strong>
                                                                    <hr>

                                                                </div>
                                                                <div
                                                                    *ngFor="let edata of endorsedprimaryskill; index as  j">
                                                                    <strong>{{primaryTable[edata]}}</strong>
                                                                    <strong><em class="pi pi-check-circle"
                                                                            (click)="showDialog2(edata)"
                                                                            style="font-size: 10px; float: right; cursor: pointer;padding: 0em 1em; color: green;"></em></strong>
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                                                <label>Secondary skills</label>
                                                                <hr>
                                                                <div
                                                                    *ngFor="let data of normalsecondaryskill; index as  i">
                                                                    <strong>{{primaryTable[data]}}</strong>
                                                                    <strong><em class="pi pi-plus"
                                                                            (click)="showDialog2(data)"
                                                                            style="font-size: 10px; float: right; cursor: pointer;padding: 0em 1em;"></em></strong>
                                                                    <hr>
                                                                </div>
                                                                <div
                                                                    *ngFor="let edata of endorsedsecondaryskill; index as  j">
                                                                    <strong>{{primaryTable[edata]}}</strong>
                                                                    <strong><em class="pi pi-check-circle"
                                                                            (click)="showDialog2(edata)"
                                                                            style="font-size: 10px; float: right; cursor: pointer;padding: 0em 1em; color: green;"></em></strong>
                                                                    <hr>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </p-panel>
                                                    <p-dialog class="dialogBox" header="Endorsed Skill"
                                                        [(visible)]="display2" [draggable]="false" [modal]="true"
                                                        [resizable]="false" [style]="{width: '25%' }" [baseZIndex]="1">
                                                        <form [formGroup]="endorsementForm">
                                                            <div class="p-grid p-fluid">
                                                                <div class="p-col-12 p-md-12 p-sm-12 p-fluid"
                                                                    style="text-align: center;">
                                                                    How good is <strong>{{data.data.userName}}</strong>
                                                                    at
                                                                    {{this.skillTitle}}
                                                                    ?
                                                                    <br><br>
                                                                    <div>
                                                                        <p-selectButton [options]="connectionList"
                                                                            optionLabel="name"
                                                                            formControlName="endorsebtn">

                                                                        </p-selectButton>
                                                                        <span class="validationtest"
                                                                            *ngIf="this.endorsementForm.get('endorsebtn').touched && this.endorsementForm.get('endorsebtn').invalid && this.endorsementForm.get('endorsebtn').errors.required">
                                                                            Please select
                                                                        </span>
                                                                    </div>
                                                                    <br>
                                                                    <div>
                                                                        <textarea autocomplete="off" [rows]="3"
                                                                            [cols]="5" placeholder="Description"
                                                                            pInputTextarea maxlength="31"
                                                                            formControlName="comment"></textarea>
                                                                        <span class="validationtest"
                                                                            *ngIf="this.endorsementForm.get('comment').hasError('maxlength')">
                                                                            Please enter 30 characters only
                                                                        </span><br><br>
                                                                    </div>
                                                                    <div>
                                                                        <button pButton type="button" label="Submit"
                                                                            [disabled]="disable" class="addhBtn"
                                                                            (click)="saveEndorserment()"></button>&nbsp;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </p-dialog>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-dataView>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectedCategoryList === 'Network'" class="pgpos">
                        <div>
                            <div class="p-col-12 inboxContainer1">
                                <p-dataView #dv [value]="networkData" [paginator]="false" [rows]="9" filterBy="name">
                                    <ng-template pTemplate="header">
                                        <form [formGroup]="networksearch">
                                            <div class="p-grid p-fluid">
                                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">Network</div>
                                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                                    <span class="p-input-icon-left p-mb-2 p-mb-md-0">
                                                        <em class="pi pi-search searchstyle"></em>
                                                        <input formControlName="invisearch" type="text"
                                                            name="searchTerm" (input)="getNetworkList('search')"
                                                            (keydown.enter)="getNetworkList('no search')"
                                                            [(ngModel)]="searchNetoworkTerm" pInputText
                                                            placeholder="Search by name">
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                    </ng-template><br>
                                    <ng-template let-data pTemplate="listItem">
                                        <div class="p-col-12">
                                            <div class="product-list-item">
                                                <div class="product-list-detail">
                                                    <div class="p-grid p-fluid"
                                                        style="align-items: cener;margin-top: 2px;">
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3"
                                                            style="text-align: center;">
                                                            <img alt=""
                                                                [src]="data.downloadUrl ? data.downloadUrl : (data.gender === 'M'? 'https://d2r8nxfsv3v6y3.cloudfront.net/images/male.png' :  '../../https://d2r8nxfsv3v6y3.cloudfront.net/images/female.png')"
                                                                style="width: 25%;height: 86%; border-radius: 10%;" />
                                                        </div>
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-5 textalign ">
                                                            <div><strong>{{data.name}}</strong></div>
                                                            <div><strong>{{data.designation}}</strong> at
                                                                {{data.companyName}}
                                                            </div>
                                                        </div>
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 textalign">
                                                        </div>
                                                        <div *ngIf="!data.connectionCheck && this.loginData.userId !== data.id"
                                                            class="p-col-12 p-md-12 p-sm-12 p-lg-2 iconalign">
                                                            <div style="margin-top: 40px;">
                                                                <button pButton tooltipPosition="top"
                                                                    (click)="confirmRequest(data)" type="button"
                                                                    icon="fa fa-user-plus" pTooltip="Request"
                                                                    class="ui-button-rounded ui-button-secondary"></button>&nbsp;&nbsp;&nbsp;
                                                            </div>
                                                        </div>
                                                        <div *ngIf="data.connectionCheck && this.loginData.userId !== data.id"
                                                            class="p-col-12 p-md-12 p-sm-12 p-lg-2 iconalign">
                                                            <div>
                                                                <button icon="pi pi-send" pButton type="button"
                                                                    tooltipPosition="top" pTooltip="Message"
                                                                    class="ui-button-rounded  ui-button-info"></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-dataView>
                            </div>
                        </div>
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <h3 style="margin-top: 16px;" class="norecorddata" *ngIf="totalRecords !== 0">Total
                                    records : {{this.totalRecords}}</h3>
                            </div>
                            <div *ngIf="totalRecords !== 0" class="p-col-12 p-md-12 p-sm-12 p-lg-7 poss">
                                <p-paginator (onPageChange)="paginate($event,'network')" [rows]="10" class="paginate"
                                    [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"></p-paginator>
                            </div>
                        </div>
                    </div>
                    <div class="card " style="border:none" *ngIf="selectedCategoryList === 'Manage'">
                        <p-tabView>
                            <p-tabPanel header="Requested" leftIcon="pi pi-calendar" tooltip="Requested">
                                <div>
                                    <div class="p-col-12">
                                        <p-dataView #dv [value]="networkData" [paginator]="false" [rows]="9"
                                            filterBy="name">
                                            <ng-template let-data pTemplate="listItem">
                                                <div class="p-col-12">
                                                    <div class="product-list-item">
                                                        <div class="product-list-detail">
                                                            <div class="p-grid p-fluid"
                                                                style="align-items: center;margin-top: 2px;">
                                                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3"
                                                                    style="text-align: center">
                                                                    <img alt=""
                                                                        [src]="data.downloadUrl ? data.downloadUrl : (data.gender === 'M'? 'https://d2r8nxfsv3v6y3.cloudfront.net/images/male.png' :  '../../https://d2r8nxfsv3v6y3.cloudfront.net/images/female.png')"
                                                                        style="width: 25%;height: 86%; border-radius: 10%;" />
                                                                </div>
                                                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                                                                </div>
                                                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-5">
                                                                    <div><strong>{{data.name}}</strong></div>
                                                                    <div><strong>{{data.designation}}</strong> at
                                                                        {{data.companyName}}</div>
                                                                    <div>...</div>
                                                                    <p>{{durationCal(data.daysAgo,data.createdDate)}}
                                                                    </p>
                                                                </div>
                                                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                                                                </div>
                                                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                                                                    <div>
                                                                        <button pButton type="button" icon="pi pi-times"
                                                                            tooltipPosition="top" pTooltip="Cancel"
                                                                            (click)="ignorerequest(data)"
                                                                            class="ui-button-rounded  ui-button-danger"></button>&nbsp;&nbsp;&nbsp;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-dataView>
                                    </div>
                                </div>
                            </p-tabPanel>
                            <p-tabPanel header="Accepted" rightIcon="pi pi-inbox" tooltip="Accepted">
                                <p>Accepted</p>
                            </p-tabPanel>
                        </p-tabView>
                    </div>
                    <div class="p-grid p-fluid" *ngIf="selectedCategoryList === 'Manage'">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                            <h3 style="margin-top: 16px;" class="norecorddata" *ngIf="totalRecords !== 0">Total records
                                :
                                {{totalRecords}}</h3>
                        </div>
                        <div *ngIf="totalRecords !== 0" class="p-col-12 p-md-12 p-sm-12 p-lg-8">
                            <p-paginator (onPageChange)="paginate($event,'manage')" [rows]="10"
                                [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"></p-paginator>
                        </div>
                    </div>
                </div>
                <p-dialog [(visible)]="display" [showHeader]="false" [resizable]="false" [modal]="true"
                    [responsive]="true" [style]="{width: '350px', minWidth: '180px'}" [minY]="70">
                    <h5 style="margin-top: 20px">Are you sure to remove?</h5>
                    <p-footer>
                        <button type="button" pButton icon="pi pi-times" (click)="showConfirmation('no')" label="No"
                            class="ui-button-secondary"></button>
                        <button type="button" pButton icon="pi pi-check" (click)="showConfirmation('yes')"
                            label="Yes"></button>
                    </p-footer>
                </p-dialog>
                <p-dialog [showHeader]="false" [(visible)]="confirmingRequest" [resizable]="false" [modal]="true"
                    [responsive]="true" [style]="{width: '350px', minWidth: '180px'}" [minY]="70">
                    <h5 style="margin-top: 20px">Are you sure to send request?</h5>
                    <p-footer>
                        <button type="button" pButton icon="pi pi-times" (click)="saveNetwork('no')" label="No"
                            class="ui-button-secondary"></button>
                        <button type="button" pButton icon="pi pi-check" (click)="saveNetwork('yes')"
                            label="Yes"></button>
                    </p-footer>
                </p-dialog>
                <p-dialog [(visible)]="displayConnectionConfirmation" [showHeader]="false" [resizable]="false"
                    [modal]="true" [responsive]="true" [style]="{width: '350px', minWidth: '180px'}" [minY]="70">
                    <h5 style="margin-top: 20px">You are about to make this connection to
                        <strong>{{connectionSelected}}</strong>?
                    </h5>
                    <p-footer>
                        <button type="button" pButton icon="pi pi-times" (click)="showConnectionsConfirmation('no')"
                            label="No" class="ui-button-secondary"></button>
                        <button type="button" pButton icon="pi pi-check" (click)="showConnectionsConfirmation('yes')"
                            label="Yes"></button>
                    </p-footer>
                </p-dialog>
                <p-dialog [showHeader]="false" [(visible)]="confirmingIgnore" [resizable]="false" [modal]="true"
                    [responsive]="true" [style]="{width: '350px', minWidth: '180px'}" [minY]="70">
                    <h5 style="margin-top: 20px">Are you sure to ignore?</h5>
                    <p-footer>
                        <button type="button" pButton icon="pi pi-times" (click)="ignoreInvitation('no')" label="No"
                            class="ui-button-secondary"></button>
                        <button type="button" pButton icon="pi pi-check" (click)="ignoreInvitation('yes')"
                            label="Yes"></button>
                    </p-footer>
                </p-dialog>
                <p-dialog [(visible)]="showInfo" header=" " [resizable]="false" [modal]="true" [responsive]="true"
                    [style]="{width: '500px', minWidth: '180px'}" [minY]="90">
                    <div class="p-grid ui-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-12" style="text-align: center;">
                            <img alt=""
                                [src]="invitationsData?.downloadUrl ? invitationsData?.downloadUrl : (invitationsData?.gender === 'M'? 'https://d2r8nxfsv3v6y3.cloudfront.net/images/male.png' :  '../../https://d2r8nxfsv3v6y3.cloudfront.net/images/female.png')"
                                style="width: 27%; height: 70%;  border-radius: 52%; border: 1px solid;" />
                        </div>
                    </div>
                    <div class="p-grid ui-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-12" style="text-align: center;">
                            <div style="text-align: center;font-size: 18px;"><strong>{{invitationsData?.name}}</strong>
                            </div>
                            <div style="text-align: center;color: grey;">{{invitationsData?.designation}}</div>
                            <div style="text-align: center;color:grey;">{{invitationsData?.cName}}</div>
                        </div>
                    </div>
                    <div class="p-grid ui-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2"></div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-8" style="text-align: center;">
                            <button style="width: 42%;" (click)="updateInvitation(invitationsData?.id)" pButton
                                [disabled]="disable" tooltipPosition="top" type="button" label="Ignore"
                                pTooltip="Ignore" class="ui-button-rounded ui-button-secondary"></button>
                            &nbsp; &nbsp; &nbsp; &nbsp;
                            <button style="width: 42%;" label="Accept" pButton type="button"
                                (click)="actionpopup.toggle($event); toggle($event,invitationsData)"
                                tooltipPosition="top" pTooltip="Accept"
                                class="ui-button-rounded  ui-button-info"></button>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2"></div>
                    </div>
                    <hr>
                    <h4 style="text-align: center;"><u>Contact Details</u></h4>
                    <p-panel [toggleable]="false">
                        <p-header>Academics</p-header>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                            <div *ngFor="let data of acadamics; let i=index">
                                <strong>{{data.degree}}</strong> in
                                <strong>{{data.branchName}}</strong>
                                from
                                <strong>{{data.institutionName}}
                                    ({{data.universityName}})</strong>
                                <hr>
                            </div>
                        </div>
                    </p-panel>
                    <p-panel [toggleable]="false">
                        <p-header>
                            Certifications
                        </p-header>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                            <div *ngFor="let data of certification; let i=index">
                                <strong>{{data.cName}}</strong> from
                                <strong>{{data.institutionName}}</strong>
                                <hr>
                            </div>
                        </div>
                    </p-panel>
                    <p-panel [toggleable]="false">
                        <p-header> Licence </p-header>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                            <div *ngFor="let data of license; let i=index">
                                <strong>{{data.cName}}</strong> from
                                <strong>{{data.institutionName}}</strong>
                                <hr>
                            </div>
                        </div>
                    </p-panel>
                    <p-panel [toggleable]="false">
                        <p-header> Skills
                        </p-header>
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <label>Primary skills</label>
                                <hr>
                                <div *ngFor="let data of primaryskil; let i=index">
                                    <strong>{{primaryTable[data]}}</strong>
                                    <hr>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <label>Secondary skills</label>
                                <hr>
                                <div *ngFor="let data of secondaryskil; let i=index">
                                    <strong>{{primaryTable[data]}}</strong>
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </p-panel>
                </p-dialog>
            </div>
        </p-panel>
    </p-card>
</form>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center" style="height:520px">
                <div style="position: absolute;left:50%;top:50%;transform:translate(-50%,-50%)">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        style="height:8em">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="connectionItems"></p-menu>
<p-menu class="menuclass" #downloadpopup [popup]="true" [model]="connectionsItems"></p-menu>