<p-toast position="bottom-right" key="br"></p-toast>
<form [formGroup]="association">
	<p-card>
				<div class="p-grid p-fluid">
					<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
						<label class="inputfields">Name</label>
						<input id="input" type="text" autocomplete="off" pInputText placeholder="Name" size="30" formControlName="name"
							(keyup.enter)="gridData()">
					</div>
					<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
						<label class="inputfields"> Email</label>
						<input id="input" type="text" autocomplete="off" pInputText placeholder="Email" size="30" formControlName="email"
							(keyup.enter)="gridData()">
					</div>
				</div>
				<div class="btnsCSS1 buttonalignement">
					<button pButton type="button" icon='pi pi-refresh' label="Reset" class="addhBtn" 
						(click)="reset()"></button>
					<button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn"
						(click)="gridData()"></button>
				</div>
	</p-card>
</form><br>
<p-card class="pgpos">
	<div *ngIf="table">
		<p-table [columns]="cols" [value]="associate" [rows]="10" selectionMode="single" dataKey="edit"
			[responsive]="true" sortMode="multiple" [autoLayout]="true">
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
						[ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
						<span>{{col.header}}</span>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
				<tr>
					<td class="textCenter"><span class="ui-column-title"></span>{{data.userName}}</td>
					<td class="textCenter"  pTooltip="{{data.email}}" tooltipPosition="top"><span class="ui-column-title"></span><a class="pointer"
						(click)="emailToast()" (click)="copyInputMessage(data.email)">{{data.email}}</a></td>
					<td class="textCenter"><span class="ui-column-title"></span>{{data.createdDate}}</td>
					<td>
						<p-badge [value]="gettatusData[data.lkStatus]" [ngClass]="data.lkStatus ==='A'?'green': data.lkStatus === 'R'?'red': 'blue'">
						</p-badge>
					   </td>
					<td class="textCenter"><span class="ui-column-title"></span>{{data.approveRrejectDate}}</td>
					<td> <a class="pi pi-ellipsis-v pointer" 
							(click)="actionpopup.toggle($event); toggle($event, data,rowData)"></a></td>
				</tr>
			</ng-template>
		</p-table>
		<div class="p-grid p-fluid">
			<div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
				<h3 class="norecorddata">Total records : {{this.totalrecords}}</h3>
			</div>
			<div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
				<p-paginator [rows]="10" [totalRecords]="totalrecords" [rowsPerPageOptions]="[10,20,30]"
					(onPageChange)="paginate($event)"></p-paginator>
			</div>
		</div>
	</div>
	<p-card *ngIf="records">
		<div class="norecordImgsize">
			<div *ngIf="records" class="aligncenter">
				<img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
				<br><em>Sorry, we are not able to find any association request</em><br>
			</div>
		</div>
	</p-card>
</p-card>
<p-dialog [(visible)]="approveOrReject" header="Association Request" [resizable]="false" [modal]="true"
	[responsive]="true" [style]="{width: '25%', minWidth: '180px'}" [minY]="70">
	<form [formGroup]="approveOrRejectForm">
		<div class="p-grid p-fluid">
			<div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
				Do you want to approve association request ?
			</div>
		</div>
		<div class="p-grid p-fluid ">
			<div class="p-col-6 p-md-6 p-sm-12">
				<p-radioButton name="approveOrReject" value="Approved" label="Yes" formControlName="approveOrReject" 
					inputId="opt1" (click)="approveRequirement()">
				</p-radioButton>
				<p-radioButton name="approveOrReject" value="Rejected" label="No" formControlName="approveOrReject" class="reqApp"
				class="noradio"	inputId="opt2" (click)="rejectRequirement()">
				</p-radioButton>
			</div>
		</div>
		<div class="p-grid p-fluid">
			<div class="p-col-8 p-md-12 p-sm-12" *ngIf="rejectForComments">
				<label class="inputfields">Reason for Rejection<span class="mandatoryColor">*</span></label>
				<textarea autocomplete="off" pInputTextarea [rows]="2" [cols]="30" placeholder="Comments"
					formControlName="comments" maxlength=201></textarea>
				<span class="validationtest" *ngIf="approveOrRejectForm.get('comments').invalid && approveOrRejectForm.get('comments').touched &&
          this.approveOrRejectForm.get('comments').errors.required">Please enter reason for rejection</span>
		  <span class="validationtest" *ngIf="this.approveOrRejectForm.get('comments').hasError('maxlength')">
			Please enter 200 alphabets only
		</span>
			</div>
		</div>
		<br>
		<div class="btnsCSS1">
			<button pButton type="button" icon='pi pi-times' label="Cancel"	class="addhBtn"
				(click)="approveOrReject = false"></button>
			<button pButton type="button" icon='pi pi-check-circle' label="Submit"  [disabled]="disable"	class="addhBtn"
				(click)="approveOrRejectYes()"></button><br><br>
		</div>
	</form>
</p-dialog>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
	<div class="loading-bar">
		<div>
			<div class="image-holder" class="col-sm-12 text-center laoderheight " >
				<div class="laodersize" >
					<img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif" class="loaderimg-height">
				</div>
			</div>
		</div>
	</div>
</ng-template>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>