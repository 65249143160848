import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-requirement-preference',
  templateUrl: './requirement-preference.component.html',
  styleUrls: ['./requirement-preference.component.scss'],
  providers: [DatePipe, MessageService],
})
export class RequirementPreferenceComponent implements OnInit {
  titleForms: FormGroup;
  loader: boolean;
  gridData: any;
  totalRecords: any;
  records: boolean;
  table: boolean;
  actId: string;
  userId: string;
  titleAddForm: FormGroup;
  displayModal: boolean;
  jobTitle: any = [];
  hireTypeList: any = [];
  billingList: any = [];
  gridRes: any;

  getData: any;
  hide: boolean;
  endDisplay: boolean;
  acctdetails: any;
  accId: any;
  hiretypeTable = {};
  updateForm: FormGroup;
  rowData: any;
  items: any = [];
  displayModal1: boolean;
  id: any;
  billingtypeTable = {};
  first: any;
  rows: any = 10;
  page: any = 1;
  pageCount: any;
  addjobTitle: any;
  jobTitleTable = {};
  jobTable: any;
  categoryname: any = [];
  jobTitle1: any = [];
  data3: any;
  data4: any;
  disable = false;
  resetbuttons: boolean;

  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private breadcrumbService: BreadcrumbService, private apisService: ApisService, private messageService: MessageService) {
    this.breadcrumbService.setItems([
      { label: 'Requirement Preferences', routerLink: '/requirementpreference' },
    ]);
  }
  // It is used for displaying table header data
  cols = [
    { field: 'catogoryName', header: 'Job Category', width: '12%' },
    { field: 'lkHireType', header: 'Hire Type', width: '12%' },
    { field: 'lkBillingType', header: 'Billing Type', width: '10%' },
    { field: '', header: 'Action', width: '5%' },
  ];
  // Window keydown listener
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.search();
    }
  }
  ngOnInit(): void {
    this.titleForms = this.fb.group({
      jobTitle: [''],
    });
    this.titleAddForm = this.fb.group({
      addjobTitle: ['', [Validators.required]],
      hireType: ['', [Validators.required]],
      billingType: ['', [Validators.required]],
    });
    this.updateForm = this.fb.group({
      editjobTitle: [''],
      hireTypes: ['', [Validators.required]],
      billingTypes: ['', [Validators.required]],
    });
    this.jobCategoryDropD1();
    this.getHireTypeData();
    this.getBillingDropdown();
    this.gridTable();
  }
  // It is used to get jobcategory dropdown values
  jobCategoryDropD1() {
    this.apisService.getApi(environment.jobCategoryDD  + '/' + this.page + '/' + this.rows ).subscribe((res: any) => {
      this.jobTitle1 = res.data;
    });
  }
  // It is used to get hiretype dropdown values
  getHireTypeData() {
    this.apisService.getApi(environment.lookupsData + 'RELHT').subscribe((res) => {
      this.hireTypeList = res.data;
      this.hireTypeList.forEach((element) => {
        this.hiretypeTable[element.code] = element.label;
      });
    });
  }
    // It is used to get billing type dropdown values
  getBillingDropdown() {
    this.apisService.getApi(environment.lookupsData + 'RELBT').subscribe((res) => {
      this.billingList = res.data;
      this.billingList.forEach((element) => {
        this.billingtypeTable[element.code] = element.label;
      });
    });
  }
  // It is used to show table data
  gridTable() {
    this.loader = true;
    const actId = JSON.parse(this.apisService.decodeData());
    // tslint:disable-next-line:max-line-length
    this.apisService.getApi(environment.requirementpreference + '/' + this.page + '/' + this.rows + '/' + actId.accId).subscribe((res: any) => {
      this.gridData = res.data.records;
      this.loader = false;
      this.totalRecords = res.count;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  // It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridTable();
  }
// It is used to show add pop up
  addDepartment() {
    const actId = JSON.parse(this.apisService.decodeData());
    this.apisService.getApi(environment.jobCategory + '/' + actId.accId).subscribe((res: any) => {
      this.jobTitle = res.data;
    });
    this.displayModal = true;
    this.titleAddForm.reset();
  }
  // It is used to reset search fields
  reset() {
    this.titleForms.reset();
    this.records = false;
    this.gridTable();
  }
// It is used to close add pop up
  addCancel() {
    this.displayModal = false;
    this.titleAddForm.reset();
  }
  // It is used to search the grid data
  search() {
    this.loader = true;
    const actId = JSON.parse(this.apisService.decodeData());
    // tslint:disable-next-line:max-line-length
    const jobcategory =  this.titleForms.controls.jobTitle.value === null || ''  ? ''  : this.titleForms.controls.jobTitle.value.id === undefined  ? ''  : this.titleForms.controls.jobTitle.value.id;
    const data1 = '?jobCategoryId=' + jobcategory;
    // tslint:disable-next-line:max-line-length
    this.apisService.getApi(environment.requirementpreference  + '/' + this.page + '/' + this.rows + '/' + actId.accId + data1).subscribe((res: any) => {
        this.loader = false;
        this.gridData = res.data.records;
        this.totalRecords = res.count;
        if (this.totalRecords === 0) {
          this.records = true;
          this.table = false;
        } else {
          this.records = false;
          this.table = true;
        }
      });
  }
  // It is used to add requirement preferece
  SaveDailog1() {
    if (this.titleAddForm.invalid) {
      const controls = this.titleAddForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      this.disable = true;
      const data2 = {
        jobCategoryId: this.titleAddForm.controls.addjobTitle.value,
        lkHireType: this.titleAddForm.controls.hireType.value,
        lkBillingType: this.titleAddForm.controls.billingType.value,
      };
      this.apisService.postApi(environment.reqpreference, data2).subscribe((res: any) => {
          this.gridData = res;
          this.hide = false;
          this.disable = false;
          this.displayModal = false;
          this.gridTable();
          if (res.hasOwnProperty('success') === true) {
            this.messageService.add({
              key: 'br',
              severity: '',
              life: 3000,
              detail: res.message,
            });
            this.gridTable();
          } else if (res.hasOwnProperty('failed') === true) {
            this.messageService.add({
              key: 'br',
              severity: '',
              life: 3000,
              detail: res.failed,
            });
          } else {
            this.messageService.add({
              key: 'br',
              severity: '',
              life: 3000,
              detail: res.error,
            });
          }

        });
    }
  }
  // It is used to show action pop up
  toggle(event, data) {
    this.rowData = data;
    this.categoryname = this.rowData.catogoryName;
    this.items = [
      {
        label: 'Edit',
        icon: 'pi pi-fw pi-pencil',
        command: () => {
          this.update(this.rowData);
        },
      },

    ];
  }
// It is used to bind the table data
  update(data) {
    this.updateForm.reset();
    this.hide = true;
    this.displayModal1 = true;
    this.id = data.id;
    this.updateForm.controls.editjobTitle.patchValue(data.catogoryName);
    this.updateForm.controls.hireTypes.patchValue(data.lkHireType);
    this.updateForm.controls.billingTypes.patchValue(data.lkBillingType);

  }
  // It is used to update requirement preference
  editSave() {
    if (this.updateForm.invalid) {
      const controls = this.updateForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      this.disable = true;
      const data4 = {
        id: this.updateForm.controls.editjobTitle.value === null ? -1 : this.rowData.id,
        lkHireType: this.updateForm.controls.hireTypes.value,
        lkBillingType: this.updateForm.controls.billingTypes.value,
      };
      this.apisService.putApi(environment.reqpreference, data4).subscribe((res: any) => {
          this.hide = false;
          this.getData = res;
          this.loader = false;
          this.disable = false;
          this.displayModal1 = false;
          this.gridTable();
          if (res.hasOwnProperty('success') === true) {
            this.messageService.add({
              key: 'br',
              severity: '',
              life: 3000,
              detail: res.message,
            });
            this.gridTable();
          } else if (res.hasOwnProperty('failed') === true) {
            this.messageService.add({
              key: 'br',
              severity: '',
              life: 3000,
              detail: res.failed,
            });
          } else {
            this.messageService.add({
              key: 'br',
              severity: '',
              life: 3000,
              detail: res.error,
            });
          }
        });
    }
  }
  // It is used to close edit pop up
  editCancel() {
    this.displayModal1 = false;
    this.updateForm.reset();
  }

}
