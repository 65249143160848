<p-card>
            <form [formGroup]="categoryForm">
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Job Category</label><br>
                        <input id="input" type="text" autocomplete="off" pInputText placeholder="Job Category" 
                            maxlength="30" formControlName="jobCategory">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Min Rate</label><br>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                            <input autocomplete="off" pInputText maxlength=5 placeholder="Min Rate" type="number" 
                                pKeyFilter="num" formControlName="min">
                            <span class="p-inputgroup-addon">/Hr</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Max Rate </label><br>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                            <input autocomplete="off" pInputText maxlength=5 placeholder="Max Rate" pKeyFilter="num" 
                                formControlName="max" type="number">
                            <span class="p-inputgroup-addon">/Hr</span>
                        </div>
                    </div>
                </div>
                <br>
                <div class="btnsCSS1">
                    <p-splitButton label="Import" [model]="items1" class="addhBtn"></p-splitButton>
                    <button pButton type="button" icon='pi pi-plus' label="Add" (click)="addDepartment()"
                        class="addhBtn"></button>
                    <button pButton type="button" icon='pi pi-refresh' label="Reset" class="addhBtn" 
                        (click)="reset()"></button>
                    <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn" 
                        (click)="gridTable()"></button>
                </div><br><br>
                <div *ngIf="isUploading" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <p-progressBar mode="indeterminate"></p-progressBar>
                </div>
                <br>
                <div *ngIf="showUploadView" class="dropzone p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <em class="pi pi-upload uploadIcon Iconsize" aria-hidden="true"></em>
                    <input accept=".xlsm,.xlsx,application/msexcel" type="file" class="fileInput" #fileDropRef
                        id="fileDropRef" multiple (change)="onUpload($event.target.files)" style="cursor: pointer;"/>
                    <h3>Drag and drop file here</h3>
                    <h3  class="importstylin">or</h3>
                    <label id="fileUpload" for="fileDropRef">Browse for files</label>
                </div>
            </form>
</p-card><br>
<p-card class="pgpos">
    <div *ngIf="table">
        <!--It is used for displaying the job category table-->
        <p-table [columns]="cols" [value]="gridData" selectionMode="single" dataKey="edit" [responsive]="true"
            sortMode="multiple">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
                <tr>
                    <td pTooltip="{{data.jobCategory}}" tooltipPosition="top"><span class="p-column-title">
                            Job Category</span>{{data.jobCategory}}
                    </td>
                    <td class="textCenter"><span class="p-column-title">
                            Min Rate</span>
                        {{data.minRate | currency: currencyValue : true}}

                    </td>
                    <td class="textCenter"><span class="p-column-title">
                            Max Rate</span>
                        {{data.maxRate | currency: currencyValue : true}}

                    </td>
                    <td><span class="ui-column-title"></span>
                        <p-badge [value]="this.statuslist[data.status]" [ngClass]="data.status==='A'?'green': 'red'">
                        </p-badge>
                    </td>

                    <td class="textCenter descrip" tooltipPosition="top"pTooltip="{{data.description}}"><span
                            class="p-column-title">
                            Description</span>{{data.description}}
                    </td>

                    <td class="textCenter"><span class="p-column-title">
                            Action</span><a (click)="downloadActionPopup.toggle($event); toggle($event,'data', data)"
                            class="pi pi-ellipsis-v pointer"></a>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any job categories</em><br>
            </div>
        </div>
    </p-card>
</p-card>
<form [formGroup]="categoryAddForm">
    <!-- It is used for add job category pop up -->
    <p-dialog class="dialogBox" header="Add Job Category " [(visible)]="displayModal" [modal]="true" [baseZIndex]="1" [style]="{width: '30%'}"
        [draggable]="false" [resizable]="false" class="dialogwidth">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Job Category<span class="mandatoryColor">*</span></label>
                <input id="input" type="text" pInputText placeholder="Job Category" maxlength="26"
                    formControlName="addCategory" autocomplete="off">
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="categoryAddForm.get('addCategory').touched && categoryAddForm.get('addCategory').invalid && this.categoryAddForm.get('addCategory').errors.required">
                    <p>Please enter job category</p>
                </span>
                <span class="validationtest" *ngIf="this.categoryAddForm.get('addCategory').hasError('maxlength')">
                    Please enter 25 alphabets only
                </span>
                <span class="validationtest"
                    *ngIf="this.categoryAddForm.get('addCategory').invalid && this.categoryAddForm.get('addCategory').errors.pattern">
                    Please enter alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Min Rate<span class="mandatoryColor">*</span></label><br>
                <div class="p-inputgroup"><span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue :
                        true)}} </span>
                    <input autocomplete="off" pInputText placeholder="Min Rate" pKeyFilter="num"
                        formControlName="addMin" type="number">
                    <span class="p-inputgroup-addon">/Hr</span>
                </div>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="categoryAddForm.get('addMin').touched && categoryAddForm.get('addMin').invalid && this.categoryAddForm.get('addMin').errors.required">
                    <p>Please enter min rate</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Max Rate <span class="mandatoryColor">*</span></label><br>
                <div class="p-inputgroup"><span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue :
                        true)}} </span>
                    <input autocomplete="off" pInputText placeholder="Max Rate" type="number" pKeyFilter="num"
                        formControlName="addMax">
                    <span class="p-inputgroup-addon">/Hr</span>
                </div>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="categoryAddForm.get('addMax').touched && categoryAddForm.get('addMax').invalid && this.categoryAddForm.get('addMax').errors.required">
                    <p>Please enter max rate</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Description</label><br>
                <textarea type="text" pInputText placeholder="Description" autocomplete="off" rows="5"
                    formControlName="addDes" maxlength="256"></textarea>

                <span class="validationtest" *ngIf="this.categoryAddForm.get('addDes').hasError('maxlength')">
                    Please enter 255 alphabets only
                </span>
            </div>
        </div><br>
        <div class="btnsCSS1">
            <button pButton type="button" icon='pi pi-times' label="Cancel" class="addhBtn"
                (click)="addCancel()"></button>
            <button pButton type="button" icon='pi pi-save' label="Save" class="addhBtn" (click)="SaveDailog1()"
                [disabled]="savbtn"></button>
        </div><br><br>
    </p-dialog>
    <p-toast position="bottom-right" key="br"></p-toast>
</form>
<form [formGroup]="categoryUpdateForm">
    <!-- It is used for edit job category pop up -->
    <p-dialog class="dialogBox" header="Edit Job Category " [(visible)]="displayModal1" [modal]="true" [baseZIndex]="1" [style]="{width: '30%'}"
        [draggable]="false" [resizable]="false" class="dialogwidth">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Job Category<span class="mandatoryColor">*</span></label>
                <input id="input" type="text" pInputText placeholder="Job Category" autocomplete="off"
                    formControlName="editCategory" maxlength="26">
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="categoryUpdateForm.get('editCategory').touched && categoryUpdateForm.get('editCategory').invalid && this.categoryUpdateForm.get('editCategory').errors.required">
                    <p>Please enter job category</p>
                </span>
                <span class="validationtest" *ngIf="this.categoryUpdateForm.get('editCategory').hasError('maxlength')">
                    Please enter 25 alphabets only
                </span>
                <span class="validationtest"
                    *ngIf="this.categoryUpdateForm.get('editCategory').invalid && this.categoryUpdateForm.get('editCategory').errors.pattern">
                    Please enter alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Status<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="jobStatus" placeholder="Select Status" optionLabel="name" optionValue="id"
                    autocomplete="off" formControlName="editStatus" [showClear]="true">
                </p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="categoryUpdateForm.get('editStatus').touched && categoryUpdateForm.get('editStatus').invalid && this.categoryUpdateForm.get('editStatus').errors.required">
                    <p>Please select status</p>
                </span>

            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Min Rate<span class="mandatoryColor">*</span></label>
                <div class="p-inputgroup"><span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue :
                        true)}} </span>
                    <input autocomplete="off" pInputText formControlName="editMin" type="number" placeholder="Min Rate"
                        pKeyFilter="num">
                    <span class="p-inputgroup-addon">/Hr</span>
                </div>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="categoryUpdateForm.get('editMin').touched && categoryUpdateForm.get('editMin').invalid && this.categoryUpdateForm.get('editMin').errors.required">
                    <p>Please enter min rate</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Max Rate<span class="mandatoryColor">*</span></label>
                <div class="p-inputgroup"><span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue :
                        true)}} </span>
                    <input autocomplete="off" pInputText formControlName="editMax" type="number" placeholder="Max Rate"
                        pKeyFilter="num">
                    <span class="p-inputgroup-addon">/Hr</span>
                </div>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="categoryUpdateForm.get('editMax').touched && categoryUpdateForm.get('editMax').invalid && this.categoryUpdateForm.get('editMax').errors.required">
                    <p>Please enter max rate</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Description</label>
                <textarea id="input" type="text" pInputText placeholder="Description" autocomplete="off"
                    formControlName="editDes" rows="5" cols="30" maxlength="256"></textarea>

                <span class="validationtest" *ngIf="this.categoryUpdateForm.get('editDes').hasError('maxlength')">
                    Please enter 255 alphabets only
                </span>
            </div>
        </div><br>
        <div class="btnsCSS1">
            <button pButton type="button" icon='pi pi-times' label="Cancel" (click)="editCancel()"
                class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-save' label="Update" (click)="editSave()" class="addhBtn"
                [disabled]="savbtn"></button>
        </div><br><br>
    </p-dialog>
    <p-toast position="bottom-right" key="br"></p-toast>
</form>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="items"></p-menu>