<p-card>
            <form [formGroup]="titleForm">
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Job Category</label><br>
                        <p-dropdown placeholder="Select Job Category" [options]="jobTitle" optionLabel="jobCategory"
                            autocomplete="off" formControlName="jobTitle" [showClear]="true">
                        </p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Title</label><br>
                        <input id="input" pInputText placeholder="Title" autocomplete="off" formControlName="title"
                            maxlength="30">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Min Rate</label><br>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                            <input autocomplete="off" pInputText formControlName="min" placeholder="Min Rate"
                                pKeyFilter="num" type="number">
                            <span class="p-inputgroup-addon">/Hr</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Max Rate</label><br>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                            <input autocomplete="off" type="text" pInputText formControlName="max" type="number" 
                                placeholder="Max Rate" pKeyFilter="num">
                            <span class="p-inputgroup-addon">/Hr</span>
                        </div>
                    </div>
                </div>
                <br>
                <div class="btnsCSS1">
                    <p-splitButton label="Import" [model]="items1" class="addhBtn"></p-splitButton>
                    <button pButton type="button" icon='pi pi-plus' label="Add" (click)="addDepartment()"
                        class="addhBtn"></button>
                    <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" 
                        class="addhBtn"></button>
                    <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn" 
                        (click)="gridTable()"></button>
                </div>
                <br><br>
                <div *ngIf="isUploading" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <p-progressBar mode="indeterminate"></p-progressBar>
                </div>
                <br>
                <div *ngIf="showUploadView" class="dropzone p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <em class="pi pi-upload uploadIcon Iconsize" aria-hidden="true"></em>
                    <input accept=".xlsm,.xlsx,application/msexcel" type="file" class="fileInput" #fileDropRef
                        id="fileDropRef" multiple (change)="onUpload($event.target.files)" style="cursor: pointer;"/>
                    <h3>Drag and drop file here</h3>
                    <h3  class="importstylin">or</h3>
                    <label id="fileUpload" for="fileDropRef">Browse for files</label>
                </div>

            </form>
</p-card>
<br>
<p-card class="pgpos">
    <div *ngIf="table">
         <!--It is used for displaying the Job title table-->
        <p-table [columns]="cols" [value]="gridData" selectionMode="single" dataKey="edit" [responsive]="true"
            sortMode="single">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                        <span>
                            {{col.header}}
                        </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
                <tr>
                    <td class="textCenter"><span class="p-column-title">
                            Job Category</span>{{data.jobCategory}}</td>
                    <td pTooltip="{{data.title}}" tooltipPosition="top"><span class="p-column-title">
                            Title</span>{{data.title}}</td>
                    <td class="textCenter"><span class="p-column-title">
                            Min Rate</span>
                        {{data.minRate | currency: currencyValue : true}}
                    </td>
                    <td class="textCenter"><span class="p-column-title">
                            Max Rate</span>
                        {{data.maxRate | currency: currencyValue : true}}
                    </td>
                    <td><span class="ui-column-title"></span>
                        <p-badge [value]="this.statuslist[data.status]" [ngClass]="data.status==='A'?'green': 'red'">
                        </p-badge>
                    </td>
                    <td class="textCenter descrip" pTooltip="{{data.description}}" tooltipPosition="top"><span
                            class="p-column-title">
                            Description</span>{{data.description}}</td>
                    <td class="textCenter"><span class="p-column-title">
                            Action</span><a (click)="downloadActionPopup.toggle($event); toggle($event,'data', data)"
                            class="pi pi-ellipsis-v pointer"></a>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any job titles</em><br>
            </div>
        </div>
    </p-card>
</p-card>
<form [formGroup]="titleAddForm">
    <!-- It is used for add job title pop up -->
    <p-dialog class="dialogBox" header=" Add Title " [(visible)]="displayModal" [modal]="true" [baseZIndex]="1"  [style]="{width: '30%'}"
        [draggable]="false" [resizable]="false" class="dialogwidths">

        <div class="p-fluid p-grid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Job Category<span class="mandatoryColor">*</span></label>
                <p-dropdown formControlName="addjobTitle" [options]="jobTitle" optionLabel="jobCategory"
                    placeholder="Select Job Category" [showClear]="true" (onChange)="getMinrate($event.value)">
                </p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="titleAddForm.get('addjobTitle').touched && titleAddForm.get('addjobTitle').invalid && this.titleAddForm.get('addjobTitle').errors.required">
                    <p>Please select job category</p>
                </span>

            </div>

            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Title<span class="mandatoryColor">*</span></label>
                <input id="input" type="text" pInputText placeholder="Title" autocomplete="off"
                    formControlName="addTitle" maxlength="31">
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="titleAddForm.get('addTitle').touched && titleAddForm.get('addTitle').invalid && this.titleAddForm.get('addTitle').errors.required">
                    <p>Please enter title</p>
                </span>
                <span class="validationtest" *ngIf="this.titleAddForm.get('addTitle').hasError('maxlength')">
                    Please enter 30 alphabets only
                </span>
                <span class="validationtest"
                    *ngIf="this.titleAddForm.get('addTitle').invalid && this.titleAddForm.get('addTitle').errors.pattern">
                    Please enter alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Min Rate<span class="mandatoryColor">*</span></label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                    <input autocomplete="off" pInputText formControlName="addMin" type="number" placeholder="Min Rate"
                        pKeyFilter="num">
                    <span class="p-inputgroup-addon">/Hr</span>
                </div>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="titleAddForm.get('addMin').touched && titleAddForm.get('addMin').invalid && this.titleAddForm.get('addMin').errors.required">
                    <p>Please enter min rate</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Max Rate<span class="mandatoryColor">*</span></label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                    <input autocomplete="off" pInputText formControlName="addMax" type="number" placeholder="Max Rate"
                        pKeyFilter="num">
                    <span class="p-inputgroup-addon">/Hr</span>
                </div>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="titleAddForm.get('addMax').touched && titleAddForm.get('addMax').invalid && this.titleAddForm.get('addMax').errors.required">
                    <p>Please enter max rate</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Description</label><br>
                <textarea type="text" pInputText placeholder="Description" formControlName="addDes"
                    autoResize="autoResize" rows="5" maxlength="256"></textarea>
                <span class="validationtest" *ngIf="this.titleAddForm.get('addDes').hasError('maxlength')">
                    Please enter 255 alphabets only
                </span>
            </div>
        </div>
        <br>
        <div class="btnsCSS1">
            <button pButton type="button" icon='pi pi-times' label="Cancel" class="addhBtn"
                (click)="addCancel()"></button>
            <button pButton type="button" icon='pi pi-save' label="Save" class="addhBtn" (click)="SaveDailog1()"
                [disabled]="savbtn"></button>
        </div><br><br>
    </p-dialog>
    <p-toast position="bottom-right" key="br"></p-toast>
</form>
<form [formGroup]="titleUpdateForm">
    <!-- It is used for edit job title pop up -->
    <p-dialog class="dialogBox" header="Edit Title " [(visible)]="displayModal1" [modal]="true" [baseZIndex]="1" [style]="{width: '30%'}"
        [draggable]="false" [resizable]="false" class="dialogwidths">
        <div class="p-fluid p-grid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Title<span class="mandatoryColor">*</span></label>
                <input id="input" type="text" pInputText placeholder="Title" autocomplete="off"
                    formControlName="editTitle" maxlength="31">
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="titleUpdateForm.get('editTitle').touched && titleUpdateForm.get('editTitle').invalid && this.titleUpdateForm.get('editTitle').errors.required">
                    <p>Please enter title</p>
                </span>
                <span class="validationtest" *ngIf="this.titleUpdateForm.get('editTitle').hasError('maxlength')">
                    Please enter 30 alphabets only
                </span>
                <span class="validationtest"
                    *ngIf="this.titleUpdateForm.get('editTitle').invalid && this.titleUpdateForm.get('editTitle').errors.pattern">
                    Please enter alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Job Category<span class="mandatoryColor">*</span></label>
                <p-dropdown formControlName="editjobTitle" [options]="jobTitle" optionLabel="jobCategory"
                    placeholder="Select Job Category" [showClear]="true"></p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="titleUpdateForm.get('editjobTitle').touched && titleUpdateForm.get('editjobTitle').invalid && this.titleUpdateForm.get('editjobTitle').errors.required">
                    <p>Please select job category</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Status<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="status" placeholder="Select Status" optionLabel="name" autocomplete="off"
                    formControlName="editStatus" [showClear]="true">
                </p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="titleUpdateForm.get('editStatus').touched && titleUpdateForm.get('editStatus').invalid && this.titleUpdateForm.get('editStatus').errors.required">
                    <p>Please select status</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Min Rate<span class="mandatoryColor">*</span></label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                    <input autocomplete="off" pInputText formControlName="editMin" type="number" placeholder="Min Rate"
                        pKeyFilter="num">
                    <span class="p-inputgroup-addon">/Hr</span>
                </div>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="titleUpdateForm.get('editMin').touched && titleUpdateForm.get('editMin').invalid && this.titleUpdateForm.get('editMin').errors.required">
                    <p>Please enter min rate</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Max Rate<span class="mandatoryColor">*</span></label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                    <input autocomplete="off" pInputText formControlName="editMax" type="number">
                    <span class="p-inputgroup-addon">/Hr</span>
                </div>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="titleUpdateForm.get('editMax').touched && titleUpdateForm.get('editMax').invalid && this.titleUpdateForm.get('editMax').errors.required">
                    <p>Please enter max rate</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Description</label><br>
                <textarea type="text" pInputText placeholder="Description" formControlName="editDes"
                    autoResize="autoResize" rows="5" maxlength="256"></textarea>
                <span class="validationtest" *ngIf="this.titleUpdateForm.get('editDes').hasError('maxlength')">
                    Please enter 255 alphabets only
                </span>
            </div>
        </div>
        <br>
        <div class="btnsCSS1">
            <button pButton type="button" icon='pi pi-times' label="Cancel" class="addhBtn"
                (click)="editCancel()"></button>
            <button pButton type="button" icon='pi pi-save' label="Update" class="addhBtn" (click)="editSave()"
                [disabled]="updbtn"></button>
        </div><br><br>
    </p-dialog>
    <p-toast position="bottom-right" key="br"></p-toast>
</form>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="items"></p-menu>