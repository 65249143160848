import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss'],
  providers: [MessageService, DatePipe],
})
export class LicenseComponent implements OnInit {
  licenseForm: any;
  showUploadView: boolean;
  loader: boolean;
  rows: any = 10;
  page: any = 1;
  searchdata: any;
  totalRecords: any;
  records: boolean;
  table: boolean;
  // It is used for displaying table header data
  cols = [
    { field: 'title', header: 'Name', width: '10%' },
    { field: 'organization', header: 'Organization', width: '10%' },
    { field: 'issuedDate', header: 'Issue Date', width: '6%' },
    { field: 'expiryDate', header: 'Expired Date', width: '6%' },
    { field: 'rankMarks', header: 'Rank/Mark', width: '5%' },
    { field: 'action', header: 'Action', width: '3%' },
  ];
  certificateGrid: any;
  showDialog1: boolean;
  licenseAddForm: any;
  show: boolean;
  userId: any;
  rowData: any;
  items: { label: string; icon: string; command: () => void; }[];
  isUploading1: boolean;
  filedata: any;
  downloadData: any;
  showDialog: boolean;
  hide: boolean;
  showEdit: boolean;
  licenseEditForm: any;
  id: any;
  isUploading: boolean;
  fileName: any;
  name: any;
  fileType: any;
  fileRes: any;
  fileData: any;
  filePath: any;
  fileSize: any;
  getData: any;
  first: any;
  pageCount: any;
  disable = false;
  fileOver: boolean;
  showExPiredDate: boolean;
  filesdatass: boolean;
  attachmentfile: boolean;
  profileupload: boolean;
  // tslint:disable-next-line: max-line-length
  constructor(private breadcrumbService: BreadcrumbService, private fb: FormBuilder, private datepipe: DatePipe, private apisService: ApisService, private messageService: MessageService,) {
    this.breadcrumbService.setItems([
      { label: 'Licenses', routerLink: ['/emp-license'] },
    ]);
  }
  // Window keydown listener
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchData();
    }
  }
  ngOnInit(): void {
    this.attachmentfile = true;
    this.licenseForm = this.fb.group({
      title: [''],
      expdate: [''],
      issuedate: [''],
    });
    this.licenseAddForm = this.fb.group({
      resume: ['', [Validators.required]],
      Addtitle: ['', [Validators.maxLength(15), Validators.pattern('^[\\sa-zA-z]*$'), Validators.required]],
      addIssuedate: [''],
      addExpdate: [''],
      addrank: ['', [Validators.maxLength(9), Validators.pattern('^[0-9]+$')]],
      addDes: ['', Validators.maxLength(200)],
      organisation: ['', [Validators.maxLength(50), Validators.pattern('^[\\sa-zA-z]*$'), Validators.required]],
      dateCheck: ['']
    });
    this.licenseEditForm = this.fb.group({
      title: ['', [Validators.maxLength(15), Validators.pattern('^[\\sa-zA-z]*$'), Validators.required]],
      editexpdate: [''],
      editissuedate: [''],
      comments: ['', Validators.maxLength(200)],
      editrank: ['', [Validators.maxLength(9), Validators.pattern('^[0-9]+$')]],
      editOrganisation: ['', [Validators.maxLength(50), Validators.pattern('^[\\sa-zA-z]*$'), Validators.required]],
      dateCheck: [false]
    });
    this.searchData();
  }
  toggleUploadView() {
    this.showUploadView = !this.showUploadView;
  }
  // It is used to search table data
  searchData() {
    this.loader = true;
    const title = this.licenseForm.controls.title.value === null ? '' : this.licenseForm.controls.title.value;
    // tslint:disable-next-line:max-line-length
    const expDate = this.licenseForm.controls.expdate.value === null || this.licenseForm.controls.expdate.value === undefined || this.licenseForm.controls.expdate.value === '' ? '' : this.datepipe.transform(this.licenseForm.controls.expdate.value, 'MM-dd-yyyy');
    // tslint:disable-next-line:max-line-length
    const issueDate = this.licenseForm.controls.issuedate.value === null || this.licenseForm.controls.issuedate.value === undefined || this.licenseForm.controls.issuedate.value === '' ? '' : this.datepipe.transform(this.licenseForm.controls.issuedate.value, 'MM-dd-yyyy');
    const actId = JSON.parse(this.apisService.decodeData());
    // tslint:disable-next-line:max-line-length
    const data = this.page + '/' + this.rows + '/' + actId.userId + '?title=' + title + '&issuedDate=' + issueDate + '&expiredDate=' + expDate + '&objectType=L';
    this.apisService.getApi(environment.getLicense + data).subscribe((res) => {
      this.loader = false;
      this.searchdata = res.data;
      this.totalRecords = res.count;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
      this.loader = false;
      this.searchdata = res.data;
    });
  }
  // It is used to reset search fields
  reset() {
    this.licenseForm.reset();
    this.loader = true;
    this.searchData();
  }
  // It is used to show add license pop up
  addlocation() {
    this.licenseAddForm.reset();
    this.showDialog1 = true;
    this.filesdatass = false;
    this.showExPiredDate = true;
  }
  // It is used to disable the check box
  disableAddCheckboxValue(data) {
    if (data.checked === true) {
      this.showExPiredDate = false;
      this.show = true;
    }
    else {
      this.showExPiredDate = true;
      this.show = false;
    }
  }
  disableCheckboxValue() {
    this.licenseAddForm.controls.addExpdate.enable();
    // this.licenseEditForm.controls.editexpdate.enable();
  }
  // It is used to show action pop up
  toggle(event, data, rowData) {
    this.rowData = rowData;
    this.items = [
      { label: ' Edit', icon: 'pi pi-pencil', command: () => { this.updateAction(); } },
      { label: 'Download', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
    ];
  }
  // It is used to download the files on table data
  downloadFileapi() {
    this.isUploading1 = true;
    this.filedata = this.rowData.id;
    this.apisService.getApi(environment.downloaddocument + '/' + this.filedata).subscribe((res: any) => {
      this.downloadData = res;
      this.isUploading1 = false;
      window.location.assign(this.downloadData.downloadUrl);
    });
  }
  // It is used to bind the license table data
  updateAction() {
    this.licenseEditForm.reset();
    this.showDialog = true;
    this.hide = true;
    this.id = this.rowData.id;
    this.licenseEditForm.controls.title.patchValue(this.rowData.title);
    this.licenseEditForm.controls.comments.patchValue(this.rowData.description);
    this.licenseEditForm.controls.editOrganisation.patchValue(this.rowData.organization);
    this.licenseEditForm.controls.editrank.patchValue(this.rowData.rankMarks);
    this.licenseEditForm.controls.editissuedate.patchValue(this.rowData.issuedDate ? new Date(this.rowData.issuedDate) : '');
    if (this.rowData.expiryDate === null || this.rowData.expiryDate === '') {
      this.showEdit = true;
      this.licenseEditForm.controls.dateCheck.patchValue(true);
      this.licenseEditForm.controls.editexpdate.patchValue(new Date());
    } else {
      this.licenseEditForm.controls.editexpdate.patchValue(this.rowData.expiryDate ? new Date(this.rowData.expiryDate) : '');
    }
  }
  disableEditCheckboxValue(data) {
    if (data.checked === true) {
      this.licenseEditForm.controls.editexpdate.disable();
      this.showEdit = true;
    }
    else {
      this.showEdit = false;
      this.licenseEditForm.controls.editexpdate.enable();
    }
  }
  // It is used to upload files on table data
  onUpload(files) {
    const actId = JSON.parse(this.apisService.decodeData());
    this.isUploading = true;
    const formData: FormData = new FormData();
    formData.append('file', files[0]);
    this.apisService.postApi(environment.fileuploadCertificates + 'L' + '/' + actId.userId + '?file=', formData).subscribe((res: any) => {
      if (res.success === true) {
        this.searchData();
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Inserted Successfully.' });
        this.isUploading = false;
        this.showUploadView = false;
      } else {
        setTimeout(() => {
          this.isUploading = false;
        }, 2000);
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
        this.searchData();
      }
    });
  }
  // Drag over listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drag Leave Listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drop Listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.isUploading = true;
      this.onUpload(files);
    }
  }
  // It is used to add attachment on table
  handleFileInput(event) {
    this.fileName = event.target.files[0];
    this.name = this.fileName.name;
    if (this.fileName.name.length > 25) {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'File name should be 25 alphabets.' });
      this.cancelfiles();
    }
    if (this.fileName.size > 10485760) {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please upload below 10MB.' });
      this.cancelfiles();
    }
    else {
      this.fileType = this.fileName.name.split('.').pop();
      const formData: FormData = new FormData();
      formData.append('file', this.fileName);
      this.filedata = formData;
      // tslint:disable-next-line: no-conditional-assignment
      if (this.filedata = formData) {
        this.filesdatass = true;
      } else {
        this.filesdatass = false;
      }
    }
  }
  // It is used to upload a attachment
  uploadAttachment() {
    if (this.licenseAddForm.invalid) {
      const controls = this.licenseAddForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      this.disable = true;
      const acctdetails = JSON.parse(this.apisService.decodeData());
      this.userId = acctdetails.userId;
      if (this.fileName === '' || this.fileName === null || this.fileName === undefined) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please attach the file.' });
      }
      this.loader = true;
      this.apisService.postApi(environment.profileUpload + this.userId + '/' + 'L', this.filedata).subscribe((res: any) => {
        this.fileRes = res;
        this.fileData = res.data;
        this.filePath = res.data.path;
        this.fileSize = res.data.fileSize;
        this.addLicense();
        this.loader = false;
        if (this.fileRes.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.fileRes.message });
          this.searchData();
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.fileRes.error });
        }
      });
    }
  }
  // It is used to show add licence pop up
  addLicense() {
    if (this.licenseAddForm.invalid) {
      const controls = this.licenseAddForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
        controls[key].updateValueAndValidity();
      });
    }
    else {
      const issuedate = this.licenseAddForm.controls.addIssuedate.value;
      const expdate = this.licenseAddForm.controls.addExpdate.value;
      const addData = {
        accId: 1,
        usrId: this.userId,
        title: this.licenseAddForm.controls.Addtitle.value,
        institutionName: this.licenseAddForm.controls.organisation.value ? this.licenseAddForm.controls.organisation.value : '',
        description: this.licenseAddForm.controls.addDes.value,
        lkObjectType: 'L',
        // tslint:disable-next-line: max-line-length
        issuedDate: issuedate === undefined || issuedate === '' || issuedate === null ? '' : this.datepipe.transform(issuedate, 'MM-dd-yyyy'),
        filePath: this.filePath,
        // tslint:disable-next-line:max-line-length
        rankMarks: this.licenseAddForm.controls.addrank.value === undefined || this.licenseAddForm.controls.addrank.value === null || this.licenseAddForm.controls.addrank.value === '' ? '' : this.licenseAddForm.controls.addrank.value,
        // tslint:disable-next-line: max-line-length
        expiryDate: expdate === undefined || expdate === '' || expdate === null || this.show === true ? '' : this.datepipe.transform(expdate, 'MM-dd-yyyy'),
      };
      this.apisService.postApi(environment.addAcademic, addData).subscribe((res: any) => {
        this.getData = res;
        this.disable = false;
        this.showDialog1 = false;
        if (this.getData.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message, });
          this.searchData();
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error });
        }
      });
    }
  }
  // It is used to update licence pop up
  submitAction() {
    if (this.licenseEditForm.invalid) {
      const controls = this.licenseEditForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
        controls[key].updateValueAndValidity();
      });
    }
    else {
      this.disable = true;
      const issdate = this.licenseEditForm.controls.editissuedate.value ? this.licenseEditForm.controls.editissuedate.value : '';
      const exDate = this.licenseEditForm.controls.editexpdate.value ? this.licenseEditForm.controls.editexpdate.value : '';
      this.loader = true;
      this.hide = true;
      const data = {
        lkObjectType: 'L',
        title: this.licenseEditForm.controls.title.value,
        institutionName: this.licenseEditForm.controls.editOrganisation.value ? this.licenseEditForm.controls.editOrganisation.value : '',
        description: this.licenseEditForm.controls.comments.value,
        issuedDate: issdate === undefined || issdate === '' || issdate === null ? '' : this.datepipe.transform(issdate, 'MM-dd-yyyy'),
        rankMarks: this.licenseEditForm.controls.editrank.value,
        // tslint:disable-next-line: max-line-length
        expiryDate: exDate === undefined || exDate === '' || exDate === null || this.showEdit === true ? '' : this.datepipe.transform(exDate, 'MM-dd-yyyy'),
        id: this.rowData.id
      };
      this.apisService.putApi(environment.updateLicense, data).subscribe((res: any) => {
        this.hide = false;
        this.disable = false;
        this.showDialog = false;
        this.loader = false;
        if (res.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
          this.searchData();
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error });
        }
      });
    }
  }
  // It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.searchData();
  }
  cancelfiles() {
    this.licenseAddForm.controls.resume.reset();
    this.filesdatass = false;
    this.attachmentfile = false;
    this.profileupload = true;

  }
}
