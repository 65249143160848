<p-toast position="bottom-right" key="br"></p-toast>
<form [formGroup]="academicForm">
    <p-card>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">University</label>
                <p-dropdown placeholder="Select University" [showClear]="true" formControlName="universityForm" optionLabel="name"
                    [options]="universiRes" filter="true">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Start Year</label>
                <p-dropdown placeholder="Select Start Year" [showClear]="true" formControlName="strtYearForm" [options]="yearList"
                    optionLabel="id" filter="true">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">End Year</label>
                <p-dropdown placeholder="Select End Year" [showClear]="true" formControlName="endYearForm" [options]="yearList "
                    optionLabel="id" filter="true">
                </p-dropdown>
            </div>
        </div>
        <br>
        <div class="btnsCSS1">
            <button pButton type="button" [icon]="showUploadView ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                label="Import" (click)="toggleUploadView()" class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-plus' label="Add" (click)="addAcademic()"
                class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-search' label="Search" (click)="gridData()"
                class="addhBtn"></button>
        </div><br><br>
        <div *ngIf="isUploading" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <p-progressBar mode="indeterminate"></p-progressBar>
        </div><br>
        <div *ngIf="showUploadView" class="dropzone p-col-12 p-md-12 p-sm-12 p-lg-3">
            <em class="pi pi-upload uploadIcon Iconsize" aria-hidden="true"></em>
            <input accept=".doc,.docx,application/msword,application/pdf " type="file" class="fileInput" #fileDropRef
                id="fileDropRef" multiple (change)="onUpload($event.target.files)" style="cursor: pointer;"/>
            <h3>Drag and drop file here</h3>
            <h3 class="importstylin">or</h3>
            <label id="fileUpload" for="fileDropRef">Browse for files</label>
        </div>
    </p-card><br>
</form>
<p-card class="pgpos">
    <div *ngIf="isUploading1" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <p-progressBar mode="indeterminate"></p-progressBar>
    </div>
    <div *ngIf="table">
        <p-table [columns]="cols" [value]="gridres" selectionMode="single" dataKey="edit" [rows]="10"
            [responsive]="true" [rowsPerPageOptions]="[10,20,30]" sortMode="multiple" [autoLayout]="true">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i=index"
                        (click)="iconToggle[i] = !iconToggle[i]" [ngStyle]="{'width': col.width }"
                        [pSortableColumn]="col.field">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                <tr>
                    <td class="textCenter skill-desc" pTooltip="{{unverRes[rowData.universityId]}}" tooltipPosition="top">
                        <span class="p-column-title"> University</span>{{unverRes[rowData.universityId]}}
                    </td>
                    <td class="textCenter"><span class="p-column-title">Degree</span>{{degreeRes[rowData.degree]}} </td>
                    <td class="textCenter skill-desc" pTooltip="{{rowData.branch}}" tooltipPosition="top"><span
                            class="p-column-title"> Branch</span>{{rowData.branch}}</td>
                    <td class="textCenter"><span class="p-column-title">Start Year</span>{{rowData.startYear}}</td>
                    <td class="textCenter"><span class="p-column-title">End Year</span>{{rowData.endYear}}</td>
                    <td class="textCenter"><span class="p-column-title"> Action</span><a><em
                                (click)="downloadActionPopup.toggle($event); toggle($event,data, rowData)"
                                class="pi pi-ellipsis-v pointer"></em> </a></td>
                </tr>
            </ng-template>
        </p-table>
        <ng-template pTemplate="emptymessage">
            <tr *ngIf="certificateGrid?.length==0" class="aligncenter">
                <td colspan="4" class="mandatoryColor"> No records</td>
            </tr>
        </ng-template>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" (onPageChange)="paginate($event)"
                    [rowsPerPageOptions]="[10,20,30]">
                </p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png"
                    class="norecordimg">
                <br><em>Sorry, we are not able to find any academics</em><br>
            </div>
        </div>
    </p-card>
</p-card>
<form [formGroup]="addAcademicForm">
    <p-dialog class="dialogBox" header="Add Academic" [(visible)]="showDialog1" [modal]="true" [style]="{width: '30%'}"
        [baseZIndex]="1" [draggable]="false" [resizable]="false">
        <div>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Country<label class="mandatoryColor">*</label></label>
                    <p-dropdown placeholder="Select Country" [options]="livingCountry" [showClear]="true"
                        (onChange)="getStateByUrl($event.value.code)" formControlName="addCtryForm" optionLabel="label">
                    </p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.addAcademicForm.get('addCtryForm').touched && this.addAcademicForm.get('addCtryForm').invalid && this.addAcademicForm.get('addCtryForm').errors.required">Please
                            select country
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">State<label class="mandatoryColor">*</label></label>
                    <p-dropdown placeholder="Select State" [showClear]="true" formControlName="addStateForm" [options]="stateOptions"
                        (onChange)="universityDropdown()" optionLabel="label">
                    </p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.addAcademicForm.get('addStateForm').touched && this.addAcademicForm.get('addStateForm').invalid && this.addAcademicForm.get('addStateForm').errors.required">
                            Please select state
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">College Name</label>
                    <input id="input" type="text" pInputText formControlName="addClgNameForm" placeholder="College Name"
                        autocomplete="off" maxlength="51">
                    <div>
                        <span class="validationtest"
                            *ngIf="this.addAcademicForm.get('addClgNameForm').hasError('maxlength')">Please enter 50
                            alphabets only</span>
                        <span class="validationtest"
                            *ngIf="this.addAcademicForm.controls.addClgNameForm.invalid && this.addAcademicForm.controls.addClgNameForm.errors.pattern">Please enter
                            alphabets only</span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">University<label class="mandatoryColor">*</label></label>
                    <p-dropdown placeholder="Select University" [showClear]="true" formControlName="addUnivercityForm"
                        [options]="universityRes" optionLabel="name" filter="true">
                    </p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.addAcademicForm.get('addUnivercityForm').touched && this.addAcademicForm.get('addUnivercityForm').invalid && this.addAcademicForm.get('addUnivercityForm').errors.required">
                            Please select university
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Degree<label class="mandatoryColor">*</label></label>
                    <p-dropdown appendTo="body" [showClear]="true" placeholder="Select Degree" filter="true" [options]="courseRes"
                        formControlName="addDegreeForm" optionLabel="courseName"
                        (onChange)="branchDropdown($event.value.id)">
                    </p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.addAcademicForm.get('addDegreeForm').touched && this.addAcademicForm.get('addDegreeForm').invalid && this.addAcademicForm.get('addDegreeForm').errors.required">
                            Please select degree
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Branch</label>
                    <p-dropdown appendTo="body" placeholder="Select Branch" formControlName="addBranchForm"
                        [options]="branch" optionLabel="specializationName" [showClear]="true" filter="true">
                    </p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Start Year</label>
                    <p-dropdown appendTo="body" placeholder="Select Start Year" formControlName="addStrtYearForm" [showClear]="true"
                        (onChange)="onlyendYear()" [options]="yearList" optionLabel="id" filter="true">
                    </p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">End Year</label>
                    <p-dropdown appendTo="body" placeholder="Select End Year" formControlName="addEndYearForm" [showClear]="true"
                        [options]="yearList1" optionLabel="id" filter="true">
                    </p-dropdown>
                </div>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <label class="inputfields">Add Attachment</label><span class="mandatoryColor">*</span><br>
                    <input *ngIf="classinput" maxFileSize="10485760" type="file" id="attachment"
                        accept=".doc,.docx,application/msword,application/pdf" (change)="handleFileInput($event)"
                        formControlName='addResumeForm' class="pointer">
                    <input *ngIf="profileupload" maxFileSize="10485760" type="file" id="attachment"
                        accept=".doc,.docx,application/msword,application/pdf" (change)="handleFileInput($event)"
                        formControlName='addResumeForm' class="pointer">
                    <em *ngIf="filesdatass" class="pi pi-times"  style="cursor: pointer;" (click)="cancelfiles()"></em>
                    <div class="validationtest"
                        *ngIf="this.addAcademicForm.get('addResumeForm').touched && this.addAcademicForm.get('addResumeForm').invalid && this.addAcademicForm.get('addResumeForm').errors.required">
                         Please upload attachment
                    </div>
                </div>
            </div><br>
            <div class="btnsCSS1 rightalign">
                <button class="addhBtn" pButton type="submit" [disabled]="disable" icon='pi pi-save' label="Save"
                    (click)="uploadAttachment()"></button>
            </div>
        </div>
    </p-dialog>
</form>
<form [formGroup]="editAcademicForm">
    <p-dialog class="dialogBox" header="Edit Academic" [(visible)]="showDialog" [modal]="true" [style]="{width: '30%'}"
        [baseZIndex]="1" [draggable]="false" [resizable]="false">
        <div>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Country<label class="mandatoryColor">*</label></label>
                    <p-dropdown placeholder="Select Country" [options]="livingCountry" [showClear]="true"
                        (onChange)="getStateByUrl($event.value.code)" formControlName="editCtryForm"
                        optionLabel="label">
                    </p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.editAcademicForm.get('editCtryForm').touched && this.editAcademicForm.get('editCtryForm').invalid && this.editAcademicForm.get('editCtryForm').errors.required">
                            Please select country
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">State<label class="mandatoryColor">*</label></label>
                    <p-dropdown placeholder="Select State" formControlName="editStateForm" [options]="stateOptions" [showClear]="true"
                        (onChange)="edituniversityDropdown()" optionLabel="label">
                    </p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.editAcademicForm.get('editStateForm').touched && this.editAcademicForm.get('editStateForm').invalid && this.editAcademicForm.get('editStateForm').errors.required">
                            Please select state
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">College Name</label>
                    <input id="input" type="text" pInputText formControlName="editClgNameForm"
                        placeholder="College Name" autocomplete="off" maxlength="51">
                    <div>
                        <span class="validationtest"
                            *ngIf="this.editAcademicForm.get('editClgNameForm').hasError('maxlength')">
                            Please enter 50 alphabets only
                        </span>
                        <span class="validationtest"
                            *ngIf="this.editAcademicForm.controls.editClgNameForm.invalid && this.editAcademicForm.controls.editClgNameForm.errors.pattern">Please enter
                            alphabets only</span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">University<label class="mandatoryColor">*</label></label>
                    <p-dropdown appendTo="body" placeholder="Select University" formControlName="editUnivercityForm" [showClear]="true"
                        [options]="edituniversityRes" optionLabel="name" filter="true">
                    </p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.editAcademicForm.get('editUnivercityForm').touched && this.editAcademicForm.get('editUnivercityForm').invalid && this.editAcademicForm.get('editUnivercityForm').errors.required">
                            Please select university
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Degree<label class="mandatoryColor">*</label></label>
                    <p-dropdown appendTo="body" placeholder="Select Degree" filter="true" [options]="courseRes" [showClear]="true"
                        formControlName="editDegreeForm" optionLabel="courseName"
                        (onChange)="branchDropdown($event.value.id)">
                    </p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.editAcademicForm.get('editDegreeForm').touched && this.editAcademicForm.get('editDegreeForm').invalid && this.editAcademicForm.get('editDegreeForm').errors.required">
                            Please select degree
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Branch</label>
                    <p-dropdown appendTo="body" placeholder="Select Branch" formControlName="editBranchForm" [showClear]="true"
                        [options]="branch" optionLabel="specializationName" filter="true">
                    </p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Start Year</label>
                    <p-dropdown appendTo="body" placeholder="Select Start Year" formControlName="editStrtYearForm" [showClear]="true"
                        [options]="yearList" (onChange)="onlyendYear1()" optionLabel="id" filter="true">
                    </p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">End Year</label>
                    <p-dropdown appendTo="body" placeholder="Select End Year" formControlName="editEndYearForm" [showClear]="true"
                        [options]="endYearList" optionLabel="id" filter="true">
                    </p-dropdown>
                </div>
            </div> <br>
            <div class="btnsCSS1 rightalign">
                <button class="addhBtn" pButton type="submit" icon='pi pi-save' [disabled]="disable" label="Update"
                    (click)="updateBranch()"></button>
            </div>
        </div>
    </p-dialog>
</form>
<p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="items"></p-menu>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>