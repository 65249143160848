// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// while moving int o production we need to enable these two baseurl,baseUrl2

// const baseUrl = 'http://34.227.250.147/v1';
// tslint:disable-next-line:max-line-length
// For testing in local we need to use Line 9 if for portal need to test line 8 ; in portal if web socket not working their is permission issue in aws
// const baseUrl = 'https://portal-api.servicesbay.com/v1'; // PROD URL
// const baseUrl2 = 'https://portal-api.servicesbay.com/v1/ws'; // web socket API PROD URL
// const baseUrl2 = 'http://172.17.12.175:9090/ngServicesBay/ws'; // web socket local

// const baseUrl = 'https://uat-portal-api.servicesbay.com/v1'; // UAT URL
// const baseUrl2 = 'https://uat-portal-api.servicesbay.com/v1/ws'; // web socket API UAT URL
const baseUrl = 'https://portal-api.servicesbay.com/v1'; // PROD URL
const baseUrl2 = 'https://portal-api.servicesbay.com/v1/ws'; // web socket API PROD URL

export const environment = {
  production: false,
  socketendpoint: baseUrl2,
  logInData: baseUrl + '/login',
  lookupsData: baseUrl + '/look-up/',
  smsUser: baseUrl + '/user/mobile-number/',
  requirementGrid: baseUrl + '/requirements',
  jobTitle: baseUrl + '/job-title/',
  departments: baseUrl + '/department',
  location: baseUrl + '/location',
  addReq: baseUrl + '/requirement',
  requirementEdit: baseUrl + '/requirement',
  avaliableSubmitedPro: baseUrl + '/requirement/submitted-profiles/',
  getRealeseReq: baseUrl + '/requirement/release/',
  submitedProfiles: baseUrl + '/requirements/submitted-profiles/',
  getDepartment: baseUrl + '/departments', // no need to change
  addDepartment: baseUrl + '/department/',
  editTable: baseUrl + '/department',
  fileuploadDepartments: baseUrl + '/department-xlsx?file=',
  vendorAccsocitegrid: baseUrl + '/vendors/associated/',
  states: baseUrl + '/look-up/states/',
  activeVendorGrid: baseUrl + '/vendors/A/',
  gettitleTable: baseUrl + '/job-titles',
  addTitle: baseUrl + '/job-title',
  updateTitle: baseUrl + '/job-title',
  fileuploadJobTitle: baseUrl + '/job-title-xlsx?file',
  fileuploadJobCategory: baseUrl + '/job-category-xlsx?file=',
  getLocation: baseUrl + '/locations/',
  getLocations: baseUrl + '/locations/',
  fileuploadWorkLocation: baseUrl + '/work-locations-xlsx?file=',
  locationEdit: baseUrl + '/location',
  vendorTier: baseUrl + '/tiers/',
  tiers: baseUrl + '/tiers',
  getTiresWithVendor: baseUrl + '/tier/vendors/',
  getUpdateVendorTier: baseUrl + '/tier/vendor',
  accountget: baseUrl + '/account/',
  taskstateDD: baseUrl + '/look-up/TLTS',
  assignedTo: baseUrl + '/user/',
  addTask: baseUrl + '/task',
  getCustomerList: baseUrl + '/customers/',
  consultantTable: baseUrl + '/candidates',
  state: baseUrl + '/look-up/states',
  addConsultant: baseUrl + '/candidate',
  getResumes: baseUrl + '/attachment/resumes/',
  emailVaildation: baseUrl + '/validate/email?email=',
  attachment: baseUrl + '/attachment/resume',
  openPositions: baseUrl + '/requirements',
  skills: baseUrl + '/look-up/RELRS',
  status: baseUrl + '/look-up/RELS',
  profile: baseUrl + '/user/profile/',
  acctreg: baseUrl + '/account',
  maxRate: baseUrl + '/job-title/',
  experience: baseUrl + '/look-up/RELYE',
  hireType: baseUrl + '/look-up/RELHT',
  billingType: baseUrl + '/look-up/RELBT',
  removeRequirement: baseUrl + '/requirement/profile/',
  downloadAttach: baseUrl + '/attachment/download',
  requireupdateaction: baseUrl + '/attachment/resumes',
  vendorMarkUp: baseUrl + '/vendor/markup/max-submission/',
  avaliableConsultant: baseUrl + '/requirement/consultants/',
  submitedConsultant: baseUrl + '/requirement/consultants/',
  resume: baseUrl + '/attachment/resumes/',
  resumDowload: baseUrl + '/attachment/download',
  profileUpload: baseUrl + '/attachment/upload/',
  attachmentUpdate: baseUrl + '/attachment/details',
  addProfile: baseUrl + '/attachment',
  attachmentPDF: baseUrl + '/attachment-pdf-docs/',
  employeeRatings: baseUrl + '/ratings/',
  getReviewType: baseUrl + '/review/',
  reviewGridData: baseUrl + '/reviews/user/',
  intervieApprove: baseUrl + '/interview/',
  getInterviewGrid: baseUrl + '/interviews/',
  interviewgrid: baseUrl + '/interviews/consultant/',
  reqProfileId: baseUrl + '/requirement/profile/approval-request',
  submissionStatusDD: baseUrl + '/look-up/ATESL',
  submissionsApprove: baseUrl + '/activity/submission-approval',
  getSkillGrid: baseUrl + '/employee/skills/',
  getSkillList: baseUrl + '/available-skills/',
  getAddSkill: baseUrl + '/employee/skills',
  getRatings: baseUrl + '/look-up/ELRT',
  certificationGrid: baseUrl + '/certifications/',
  addCertificate: baseUrl + '/upload-document',
  fileuploadCertificates: baseUrl + '/upload-document-pdf-docs/',
  editCertificate: baseUrl + '/document',
  universities: baseUrl + '/look-up/universities/',
  eduCourse: baseUrl + '/look-up/course/',
  branch: baseUrl + '/look-up/specialization/',
  universitiesList: baseUrl + '/look-up/universities',
  academics: baseUrl + '/academics/',
  insertAcademics: baseUrl + '/academic', // new change
  recruiter: baseUrl + '/vendor/recruiter',
  preCurrencyDropDown: baseUrl + '/look-up/UPDC',
  preNotfDropDown: baseUrl + '/look-up/UPNP',
  preCountryDropdown: baseUrl + '/look-up/ADLC',
  getPreferences: baseUrl + '/user/preferences/', // get
  getLicense: baseUrl + '/licences/',
  addAcademic: baseUrl + '/upload-document',
  updateLicense: baseUrl + '/document',
  preferenceSave: baseUrl + '/user/preferences', // post
  getInterviewType: baseUrl + '/look-up/RPLIT',
  interviewStatus: baseUrl + '/look-up/ERRS',
  techReviews: baseUrl + '/reviews/tech-evalutaion/',
  reviewPatching: baseUrl + '/review',
  addReviwes: baseUrl + '/review',
  networkData: baseUrl + '/invitations/',
  connectionsData: baseUrl + '/networks/',
  updateNetwork: baseUrl + '/network',
  networkList: baseUrl + '/networks-list/',
  saveNetwork: baseUrl + '/network',
  connectionRequestData: baseUrl + '/connection-request/',
  branchUpdate: baseUrl + '/academic', // new change
  actstatus: baseUrl + '/look-up/ULS',
  fileuploadAcc: baseUrl + '/account/excel-registration',
  getAccounData: baseUrl + '/accounts',
  vendorNames: baseUrl + '/vendor/markup/max-submission',
  addVendorTier: baseUrl + '/vendor/associate-request',
  addTiers: baseUrl + '/tier',
  checkTiers: baseUrl + '/tier/is-existed/',
  listVindorTiers: baseUrl + '/tier/vendor',
  tiersLookup: baseUrl + '/tiers/lookup',
  getVendorNames: baseUrl + '/tier/vendor/not-in-1',
  getReceivedTable: baseUrl + '/requirement/received-profiles/',
  vedorReq: baseUrl + '/requirements/vendor/',
  reqArea: baseUrl + '/look-up/RELRC',
  updateaction: baseUrl + '/attachment/resume',
  requirementSubmit: baseUrl + '/requirement/profile-submit/',
  getZoneType: baseUrl + '/look-up/RPLZ',
  getLocationList: baseUrl + '/location/',
  reviewer: baseUrl + '/look-up/reviewer/',
  scheduleData: baseUrl + '/interview',
  getReviewTable: baseUrl + '/reviews/consultant/',
  reviewGrid: baseUrl + '/reviews/',
  Accountget: baseUrl + '/account/',
  roleDD: baseUrl + '/look-up/roles/',
  fileuploadContacts: baseUrl + '/users/excel-registration',
  getTabledata: baseUrl + '/users/',
  loginSend: baseUrl + '/user/activate/',
  addContact: baseUrl + '/user/',
  emailVerification: baseUrl + '/user/is-email-exist/',
  getAttachment: baseUrl + '/attachments',
  requestApproval: baseUrl + '/activity/submission-request',
  updateBillRate: baseUrl + '/bill-rate',
  ratecards: baseUrl + '/ratecards/',
  positionUpdate: baseUrl + '/ratecard',
  editContactpatch: baseUrl + '/user/',
  changepassword: baseUrl + '/change-password',
  connectionShowDetails: baseUrl + '/user-details/',
  addRequirementradio: baseUrl + '/employee-aproval-flag/',
  skillEndorsement: baseUrl + '/endorsements',
  getemployement: baseUrl + '/employements',
  saveemployment: baseUrl + '/employement',
  updateStatus: baseUrl + '/look-up/recruiters',
  activerec: baseUrl + '/look-up/active-recruiters/',
  reqApprove: baseUrl + '/requirement/status',
  requirmentRecruiter: baseUrl + '/requirements/vendor/',
  skillsEndorsement: baseUrl + '/employee/skill-endorsement/',
  accountnames: baseUrl + '/look-up/account-names',
  selectedCandidate: baseUrl + '/selected-by-cutomer/',
  rmUpdateStatus: baseUrl + '/requirement/assign/',
  recommendation: baseUrl + '/recommendations',
  changeSkill: baseUrl + '/skill/change-type/',
  barChart: baseUrl + '/dashboard/recuriters',
  pieChart: baseUrl + '/pi-chart-dashboard',
  ratecardtable: baseUrl + '/ratecards',
  updateemployment: baseUrl + '/employement',
  requirementpreference: baseUrl + '/requirement/preferences',
  reqpreference: baseUrl + '/requirement/preference',
  submiteProfileToReq: baseUrl + '/requirement/associate-consultant',
  calender: baseUrl + '/employee/calender/',
  myconnectedpeople: baseUrl + '/my-connected-people',
  saverecommendation: baseUrl + '/recommendation',
  jobcategoryaction: baseUrl + '/acc-ratecard',
  associatedRequirement: baseUrl + '/associate-to-original-requirement/',
  positioncategory: baseUrl + '/requirement/preference/',
  getProfileActivity: baseUrl + '/activity/profile/',
  phonerequired: baseUrl + '/user/is-office-number-exist/',
  getreqActivity: baseUrl + '/activity/requirement/',
  resetpassword: baseUrl + '/verify-email/',
  activeresume: baseUrl + '/attachment/active-resume/',
  profileImageUpload: baseUrl + '/attachment/image/',
  updateProfileData: baseUrl + '/user/profile',
  salesinfogrid: baseUrl + '/profile-sales/',
  salessearch: baseUrl + '/vendor/sales/',
  markup: baseUrl + '/markup',
  patchmarkup: baseUrl + '/markup/',
  getlocation: baseUrl + '/interview/location/',
  getIntreviewActivity: baseUrl + '/activity/intreview/',
  getSubmissionActivity: baseUrl + '/activity/submission/',
  forgetPassword: baseUrl + '/forget-password',
  tierUpdate: baseUrl + '/tier',
  recsubmitGet: baseUrl + '/requirement/candidate-profile/',
  getBankTable: baseUrl + '/account/banks',
  saveBank: baseUrl + '/account/bank',
  salesName: baseUrl + '/sales/',
  getsales: baseUrl + '/sales/',
  addSales: baseUrl + '/sales',
  updateSales: baseUrl + '/sales/',
  backlogin: baseUrl + '/passcode/',
  resendit: baseUrl + '/resend-passcode/',
  verify: baseUrl + '/verify-passcode/',
  verifyLogin: baseUrl + '/accesslog',
  NameValidation: baseUrl + '/account/is-name-exist',
  salescontacts: baseUrl + '/sales/accounts/',
  emailverify: baseUrl + '/verfiy-email?sessionId=',
  downloadedattachment: baseUrl + '/attachment/download',
  downloaddocument: baseUrl + '/download-doc',
  deleteimage: baseUrl + '/attachment',
  updateaccesslog: baseUrl + '/accesslog',
  reject: baseUrl + '/reject-by-customer',
  adminskills: baseUrl + '/skill/1',
  candidateresume: baseUrl + '/attachment/candidate/',
  downloadres: baseUrl + '/attachment/download/',
  updateMvault: baseUrl + '/attachment/mvault',
  numberValidation: baseUrl + '/user/validate-number/',
  accinactive: baseUrl + '/inactive-vendor-from-tier/',
  accountactiveornot: baseUrl + '/account/is-active',
  consultant: baseUrl + '/consultant',
  consultantOpenPos: baseUrl + '/consultant/open-position/',
  consultantsubmitreq: baseUrl + '/requirement/submit-consultant-profile',
  consultantProfile: baseUrl + '/consultant/',
  updateConsultantProfile: baseUrl + '/consultant/',
  selectmode: baseUrl + '/auth-type/',
  accountnamesdropdown: baseUrl + '/look-up/account-names',
  updateConsultantprofile: baseUrl + '/user/consultant/profile', // new change
  getConsultantProfileActivity: baseUrl + '/activity/consultant-profile',
  removecandidate: baseUrl + '/requirement/sub-profile',
  association: baseUrl + '/association',
  appliedpositions: baseUrl + '/consultant/applied-position/',
  associationreceived: baseUrl + '/association/received',
  associateddrop: baseUrl + '/look-up',
  associationrequest: baseUrl + '/association',
  consultantRecruiterGrid: baseUrl + '/association/requested/',
  accountbankdetails: baseUrl + '/account/bank-details',
  accountbank: baseUrl + '/account/bank-details',
  getBankCust: baseUrl + '/account/bank/associated-customers/',
  insertBankCust: baseUrl + '/account/bank/associate-customer',
  customerName: baseUrl + '/account/bank/not-associated-customers/',
  gettask: baseUrl + '/tasks/',
  edittask: baseUrl + '/task/',
  assignedtodropdown: baseUrl + '/look-up/assignedto/',
  addtask: baseUrl + '/tasks',
  updatetask: baseUrl + '/task',
  saveattachment: baseUrl + '/task/attachment',
  calendaravailability: baseUrl + '/calendar/availability',
  isavailable: baseUrl + '/is-available/',
  updateapicalender: baseUrl + '/available-to-and-from/',
  groupdata: baseUrl + '/groups/',
  groupadd: baseUrl + '/group',
  membersgrid: baseUrl + '/group/member/',
  groupormembernames: baseUrl + '/look-up/group/names/',
  vendorreceivedprofile: baseUrl + '/requirement/vendor-received-profiles/',
  Ignore: baseUrl + '/relationship/',
  positionCat: baseUrl + '/job-category', // based on accid
  jobCategoryDD: baseUrl + '/job-categories',
  getTable: baseUrl + '/job-categories',
  addCategory: baseUrl + '/job-category',
  editCategory: baseUrl + '/job-category',
  jobCategory: baseUrl + '/job-category/prefenrence',
  groupDD: baseUrl + '/groups/names/',
  memberDD: baseUrl + '/look-up/available-users-for-group/',
  assignTask: baseUrl + '/task/status',
  taskNotify: baseUrl + '/task/notification',
  getTeamsData: baseUrl + '/users/',
  updateAttachment: baseUrl + '/attachment/update',
  taskDownload: baseUrl + '/task/attachment/download/',
  vendornames: baseUrl + '/look-up/vendor-names',
  locationphone: baseUrl + '/location/phone/',
  rejectpublicprofile: baseUrl + '/reject-public-profile/',
  selectVendorProfile: baseUrl + '/selected-public-profile/',
  project: baseUrl + '/project',
  projects: baseUrl + '/projects/',
  projecthistory: baseUrl + '/user/project/history/',
  projectResources: baseUrl + '/project/resources/',
  addProjectResources: baseUrl + '/project/resource',
  updateProjetResource: baseUrl + '/project/resource',
  updateproject: baseUrl + '/project',
  statuslookup: baseUrl + '/look-up/PLS',
  projecttype: baseUrl + '/look-up/PLPT',
  userList: baseUrl + '/user/organization/history/',
  importCandidate: baseUrl + '/candidate-xlsx',
  selectedbycustomer: baseUrl + '/candidates/selected-by-customer/',
  projectreshistory: baseUrl + '/resource/project/',
  timesheetgrid: baseUrl + '/timesheets',
  addtimesheetgrid: baseUrl + '/timesheet',
  getprojects: baseUrl + '/timesheet/add-timesheet-data/',
  getid: baseUrl + '/timesheet/lastId/',
  rowEdit: baseUrl + '/timesheet/',
  rejectreasons: baseUrl + '/timesheet/status',
  updateTimesheet: baseUrl + '/timesheet',
  timesheetHistory: baseUrl + '/activity/timesheet/',
  timesheetnexytprevious : baseUrl + '/timesheet/next-previous/',
  timesheetattachmentdownload : baseUrl + '/attachment/download/timesheet/',
  changeAccType: baseUrl + '/account/acc-type',
  accountsActivity: baseUrl + '/activity/',
  vendorsubcontractor: baseUrl + '/requirement/released/accounts/',
  openposviewedornot: baseUrl + '/open-position/viewed',
  vendorname: baseUrl + '/look-up/requirement/released/accounts/',
  skillsnotExisted : baseUrl + '/skills/notExisted',
  notExistedSkillAdded : baseUrl + '/notExistedSkillAdded',
  getOtherDetails: baseUrl + '/candidate/other-details/',
  updateMail: baseUrl + '/reset-password',
  attachmentActive: baseUrl + '/attachment/active/resumes/',
  existEmailValidation: baseUrl + '/user/is-email-exist-update/',
  loginSendCandidate: baseUrl + '/candidate/activate/',
  deleteConfirmation: baseUrl + '/admin/verify-default-email/',
  removeUserAccount: baseUrl + '/user/delete/user-account/',
  accountsBackup: baseUrl + '/user/deleted-accounts'
};
