<p-card>
    <form [formGroup]="reviewSearchForm">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Candidate Name</label>
                <input (keyup.enter)="searchData()" id="input" type="text" pInputText placeholder="Candidate Name"
                    autocomplete="off" formControlName="name" maxlength="30">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Interview Mode</label>
                <p-dropdown (keyup.enter)="searchData()" [options]="interviewTypeList"
                    placeholder="Select Interview Mode" optionLabel="label" autocomplete="off"
                    [showClear]="true"    formControlName="interviewType">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Assign Date</label>
                <p-calendar (keyup.enter)="searchData()" id="popup" placeholder="MM-DD-YYYY" dateFormat="mm-dd-yy"
                    [showIcon]="true" [showButtonBar]="true" formControlName="startDate">
                </p-calendar>
            </div>

            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Review Date</label>
                <p-calendar (keyup.enter)="searchData()" id="popup" placeholder="MM-DD-YYYY" dateFormat="mm-dd-yy"
                    [showIcon]="true" [showButtonBar]="true" formControlName="endDate">
                </p-calendar>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Status</label>
                <p-dropdown (keyup.enter)="searchData()" [options]="statusList" placeholder="Select Status"
                [showClear]="true" optionLabel="label" autocomplete="off" formControlName="status">
                </p-dropdown>
            </div>
        </div>
        <div class="btnsCSS1 btnalign">
            <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-search' (click)="searchData()" label="Search"
                class="addhBtn"></button>
        </div>
    </form>
</p-card>
<br>
<p-card class="pgpos">
    <div *ngIf="isUploading" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <p-progressBar mode="indeterminate"></p-progressBar>
    </div>
    <div *ngIf="table">
        <p-table [columns]="cols" [value]="gridData" [autoLayout]="true" selectionMode="single" dataKey="edit"
            [responsive]="true" sortMode="multiple">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns let i=index" (click)="iconToggle[i] = !iconToggle[i]"
                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
                <tr>
                    <td pTooltip={{data.jobTitle}} tooltipPosition="top"><span class="p-column-title">
                            Job Title</span>{{data.jobTitle}}</td>
                    <td pTooltip={{data.username}} tooltipPosition="top"><span class="p-column-title">
                            Candidate Name</span>{{data.username}}</td>
                    <td><span class="p-column-title">
                            Interview Mode</span>{{interviewTypeLabel[data.attributes]}}</td>
                    <td><span class="p-column-title">
                            Interview Date&Time</span>{{data.interviewDate}}</td>
                    <td><span class="p-column-title">
                            Assign Date</span>{{data.assignDate}}</td>
                    <td><span class="p-column-title">
                            Review Date</span>{{data.reviewDate}}</td>
                    <td><span class="ui-column-title"></span>
                        <p-badge [value]="statusTable[data.status]" [ngClass]="data.status ==='A'?'green': 'evalution'">
                        </p-badge>
                    </td>
                    <td class="textCenter"><span class="p-column-title">Action</span><a>
                            <em (click)="actionpopup.toggle($event); toggle(items, $event, data)"
                                class="pi pi-ellipsis-v pointer"></em>

                        </a></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr *ngIf="gridData?.length==0" class="aligncenter">
                    <td colspan="6" class="mandatoryColor">
                        No Records
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator (onPageChange)="paginate($event)" [rows]="10" [totalRecords]="totalRecords"
                    [rowsPerPageOptions]="[10,20,30]"></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any tech reviews</em><br>
            </div>
        </div>
    </p-card>
</p-card>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>