import { Component, OnInit, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ApisService } from 'src/app/services/apis.service';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss'],
  providers: [DatePipe, MessageService],
})
export class DepartmentsComponent implements OnInit {
  iconToggle: any = [];
  // It is used for displaying table header data
  cols = [
    { field: 'name', header: 'Dept.Name', width: '15%' },
    { field: 'deptNo', header: 'Dept.No', width: '15%' },
    { field: 'craetedBy', header: 'Created By ', width: '15%' },
    { field: 'status', header: 'Status ', width: '12%' },
    { field: '', header: 'Action', width: '5%' },
  ];
  departmentsForm: FormGroup;
  displayModal: boolean;
  departmentAddForm: FormGroup;
  showUploadView: boolean;
  isUploading: boolean;
  gridData: any;
  accId: any;
  loader: boolean;
  // tslint:disable-next-line:no-inferrable-types
  table: boolean = true;
  totalRecords: any;
  records: boolean;
  rows: any = 10;
  page: any = 1;
  hide: boolean;
  getData: any;
  editDepartments: FormGroup;
  displayModal1: boolean;
   // It is used for displaying status
  status = [
    { name: 'Active', id: 'A' },
    { name: 'Inactive', id: 'I' },
  ];
  id: any;
  first: any;
  pageCount: any;
  subbtn: boolean;
  fileOver: boolean;
  data: any;
  statuslist = {};
  items: { label: string; icon: string; command: () => void; }[];
  items1: { label: string; icon: string; command: () => void; }[];
  disable: boolean;
  downloadData: any;
  // tslint:disable-next-line:max-line-length
  constructor(private messageService: MessageService, private breadcrumbService: BreadcrumbService, private fb: FormBuilder, private apisService: ApisService) {
    this.breadcrumbService.setItems([
      { label: 'Departments', routerLink: '/departments' }
    ]);
    this.items1 = [
      {label: 'Download Sample', icon: 'pi pi-download', command: () => { this.downloadFileapi();
      }},
      {label: 'Import Data', icon: 'pi pi-chevron-down', command: () => {
        this.toggleUploadView();
      }
    }];
  }
  // // It is used to show add pop up
  addDepartment() {
    this.displayModal = true;
    this.departmentAddForm.reset();
  }
  toggleUploadView() {
    this.showUploadView = !this.showUploadView;
  }
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.gridTable();
    }
  }
  ngOnInit(): void {
    const userdetails = JSON.parse(this.apisService.decodeData());
    this.accId = userdetails.accId;
    this.departmentsForm = this.fb.group({
      departmentNumber: [''],
      departmentName: ['']
    });
    this.departmentAddForm = this.fb.group({
      addDepartmentName: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z0-9]*$'), Validators.maxLength(30)]],
      addDepartmentNumber: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.maxLength(12)]]
    });
    this.editDepartments = this.fb.group({
      editDepartmentName: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z0-9]*$'), Validators.maxLength(30)]],
      editDepartmentNum: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.maxLength(12)]],
      editDepartmentStatus: ['', Validators.required]
    });
    this.gridTable();
    this.gridstatus();
  }
  // It is Used to upload files
  onUpload(files) {
    this.isUploading = true;
    const formData: FormData = new FormData();
    formData.append('file', files[0]);
    this.apisService.postApi(environment.fileuploadDepartments, formData).subscribe((res: any) => {
        if (res.success === true) {
          this.gridTable();
          this.messageService.add({  key: 'br', severity: '', life: 3000, detail: res.message, });
          this.isUploading = false;
          this.showUploadView = false;
        } else {
          setTimeout(() => {
            this.isUploading = false;
          }, 2000);
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
          this.gridTable();
        }
      });
  }
  // Drag Over Listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drag Leave Listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
   // Drop Listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.isUploading = true;
      this.onUpload(files);
    }
  }
  uploadedFiles(arg0: string, uploadedFiles: any) {
    throw new Error('Method not implemented.');
  }
   // It is used to add a department
  saveDailog1() {
    this.hide = true;
    if (this.departmentAddForm.invalid) {
      const controls = this.departmentAddForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    } else {
      const data = {
        name: this.departmentAddForm.controls.addDepartmentName.value,
        departmentNo: this.departmentAddForm.controls.addDepartmentNumber.value,
      };
      this.subbtn = true;
      this.apisService.postApi(environment.addDepartment, data).subscribe((res: any) => {
          this.getData = res;
          this.hide = false;
          this.subbtn = false;
          this.gridTable();
          if (this.getData.hasOwnProperty('success') === true) {
            this.messageService.add({  key: 'br',  severity: '',  life: 3000, detail: this.getData.message });
            this.displayModal = false;
            this.gridTable();
          } else if (this.getData.hasOwnProperty('success') === false) {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message,  });
          } else {
            this.messageService.add({  key: 'br', severity: '', life: 3000, detail: this.getData.error  });
          }
        }
        );
    }
  }
// It is used to show table data
  gridTable() {
    this.loader = true;
    const pgNo = this.page;
    const recordPage = this.rows;
    // tslint:disable-next-line:max-line-length
    const departNum = this.departmentsForm.controls.departmentNumber.value === null ? '' : this.departmentsForm.controls.departmentNumber.value;
    // tslint:disable-next-line:max-line-length
    const depatName =  this.departmentsForm.controls.departmentName.value === null  ? ''  : this.departmentsForm.controls.departmentName.value;
    const search = '?depNo=' + departNum + '&depName=' + depatName;
    this.apisService.getApi(environment.getDepartment + '/' + pgNo + '/' + recordPage + '/' + this.accId + search).subscribe((res: any) => {
        this.loader = false;
        this.gridData = res.data;
        this.totalRecords = res.count;
        if (this.totalRecords === 0) {
          this.records = true;
          this.table = false;
        } else {
          this.records = false;
          this.table = true;
        }
        this.loader = false;
      });
  }
  // It is used to reset search fields
  reset() {
    this.departmentsForm.reset();
    this.records = false;
    this.gridTable();
  }
  // It is used to close add pop up
  addCancel() {
    this.displayModal = false;
    this.departmentAddForm.reset();
  }
  // It is used to bind the table data
  updateData(data) {
    this.hide = true;
    this.id = data.id;
    this.displayModal1 = true;
    this.editDepartments.controls.editDepartmentNum.patchValue(data.deptNo);
    this.editDepartments.controls.editDepartmentName.patchValue(data.name);
    this.editDepartments.controls.editDepartmentStatus.patchValue(
      this.status.filter((x) => x.id === data.status)[0]
    );
  }
  // It is used to close edit pop up
  editCancel() {
    this.displayModal1 = false;
    this.editDepartments.reset();
  }
  // It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridTable();
  }
  // It is used to update department
  editSave() {
    if (this.editDepartments.invalid) {
      const controls = this.editDepartments.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });

    } else {
      this.hide = false;
      const valueid =  this.id;
      const data = {
        name: this.editDepartments.controls.editDepartmentName.value,
        departmentNo: this.editDepartments.controls.editDepartmentNum.value,
        status: this.editDepartments.controls.editDepartmentStatus.value.id,
        id: valueid
      };
      this.subbtn = true;
      this.apisService.putApi(environment.editTable, data).subscribe((res: any) => {
          this.hide = false;
          this.getData = res;
          this.loader = false;
          if (this.getData.hasOwnProperty('success') === true) {
            this.messageService.add({  key: 'br', severity: '', life: 3000, detail: this.getData.message,  });
            this.subbtn = false;
            this.displayModal1 = false;
            this.gridTable();
          } else if (this.getData.hasOwnProperty('failed') === true) {
            this.messageService.add({ key: 'br', severity: '', life: 3000,  detail: this.getData.failed, });
          } else {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error });
          }
        });
    }
  }
  // It is used to patch the status dropdown value
  gridstatus() {
    // tslint:disable-next-line:no-unused-expression
    this.status.forEach(element => {
      this.statuslist[element.id] = [element.name];
    });
  }
  // It is used to show action pop up
  toggle(event, data, rowData) {
    this.data = rowData;
    this.items = [{  label: ' Edit', icon: 'pi pi-pencil',  command: () => { this.updateData(this.data);  }
      },
    ];
  }
  downloadFileapi() {
    this.apisService.getApi(environment.downloadres + 2).subscribe((res: any) => {
      this.downloadData = res;
      window.location.assign(this.downloadData.downloadUrl);
    });
  }
}
