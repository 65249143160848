import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-employee-interview',
  templateUrl: './employee-interview.component.html',
  styleUrls: ['./employee-interview.component.scss'],
  providers: [DatePipe, MessageService],
})
export class EmployeeInterviewComponent implements OnInit {
  interviewsForm: FormGroup;
  approveOrRejectForm: FormGroup;
  iconToggle = [];
  status: any[];
  totalRecords: any;
  getData: any;
  loader = false;
  pageCount: any;
  first: any;
  typeTable = {};
  industryList: any = [];
  loading: boolean;
  AcctRowID: any;
  customerId: any;
  rows: any = 10;
  page: any = 1;
  records = true;
  table: boolean;
  statusConversion = {};
  interview = {};
  options: any = [];
  overlays: any[];
  // It is used for displaying table header data
  cols = [
    { field: 'reqId', header: 'Job Id' },
    { field: 'reviewerName', header: 'Reviewer Name' },
    { field: 'reviewedBy', header: 'Reviewed By' },
    { field: 'activityAttributes', header: 'Interview Mode' },
    { field: 'interviewDate', header: 'Date & Time' },
    { field: 'lkActivityStatus', header: 'Status' },
    { field: 'action', header: 'Action' },
  ];
  customerName: string;
  interviewgrid: any;
  interviewGrid: any;
  tablestatusdata: any;
  interviewMode: any;
  mode: string;
  status1: string;
  rowData: any;
  items: { label: string; icon: string; command: () => void; }[];
  filedata: any;
  resumeRes: any;
  approveOrRejectResponse: any;
  approveOrReject: boolean;
  rejectForComments: boolean;
  display: boolean;
  isUploading1: boolean;
  disable: boolean;
  interviewtype: any;
  loationForm: FormGroup;
  showDialog1: boolean;
  stateOptions: any = [];
  livingCountry: any = [];
  rowId: any;
  locdata: any;
  locationname: any;
  addressline1: any;
  adddressline2: any;
  zip: any;
  city: any;
  locationid: any;
  country: any;
  state: any;
  review: any;
  displayModal: boolean;
  empId: any;
  histroy: any;
  jobID: any;
  date: Date;
  reqid: any;
  dattaa: any;
  statuskey: string;
  comment: any;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private messageService: MessageService, private route: ActivatedRoute, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Interviews', routerLink: '/rec-interviews' },
    ]);
  }
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchData();
    }
  }
  ngOnInit() {
    this.interviewsForm = this.fb.group({
      name: ['', Validators.pattern('^[\\sa-zA-z]*$')],
      status: [''],
      interviewMode: ['']
    });
    this.approveOrRejectForm = this.fb.group({
      approveOrReject: [''],
      comments: ['', [Validators.required, Validators.maxLength(200)]],
    });
    this.status1 = this.route.snapshot.queryParamMap.get('lkActivityStatus');
    this.mode = this.route.snapshot.queryParamMap.get('interviewMode');
    this.getInterviewStatus();
    this.gridData();
    this.getInterviewMode();
    this.CountryDropdown();
  }
  // It is used to get interview mode dropdown values
  getInterviewMode() {
    this.apisService.getApi(environment.lookupsData + 'RPLIT').subscribe((res: any) => {
      this.interviewMode = res.data;
      this.interviewMode.forEach(element => {
        this.interview[element.code] = element.label;
      });
    });
  }
  // It is used to get status dropdown values
  getInterviewStatus() {
    this.apisService.getApi(environment.lookupsData + 'ATESL').subscribe((res: any) => {
      this.status = res.data;
      this.status.forEach(element => {
        this.statusConversion[element.code] = element.label;
      });
    });
  }
  // It is used to get grid data
  gridData() {
    this.loader = true;
    const pageNo = this.page;
    const pageCount = this.rows;
    const data = pageNo + '/' + pageCount;
    const actId = JSON.parse(this.apisService.decodeData());
    this.apisService.getApi(environment.interviewgrid + data + '/' + actId.userId).subscribe((res: any) => {
      this.interviewGrid = res.data.records;
      this.interviewGrid.forEach(element => {
        this.jobID = element.reqId;
      });
      this.totalRecords = res.total;
      this.totalRecords = res.data.count;
      this.review = this.interviewGrid.reviewId;
      this.loader = false;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  // It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridData();
  }
  // It is used for the searching the data based on the grid data
  searchData() {
    this.loader = true;
    const pageNo = this.page;
    const pageCount = this.rows;
    const data1 = pageNo + '/' + pageCount;
    const actId = JSON.parse(this.apisService.decodeData());
    const customerName = this.interviewsForm.controls.name.value === null ? '' : this.interviewsForm.controls.name.value;
    // tslint:disable-next-line:max-line-length
    const industry = this.interviewsForm.controls.interviewMode.value === null || this.interviewsForm.controls.interviewMode.value === '' || this.interviewsForm.controls.interviewMode.value === undefined ? '' : this.interviewsForm.controls.interviewMode.value.code;
    // tslint:disable-next-line:max-line-length
    const status = this.interviewsForm.controls.status.value === null || this.interviewsForm.controls.status.value === '' || this.interviewsForm.controls.status.value === undefined ? '' : this.interviewsForm.controls.status.value.code;
    const data = data1 + '/' + actId.userId + '?lkActivityStatus=' + status + '&intreview=' + industry + '&accountName=' + customerName;
    this.apisService.getApi(environment.interviewgrid + data).subscribe((res) => {
      this.loader = false;
      this.interviewGrid = res.data.records;
      this.totalRecords = res.data.count;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  // It is used to show action pop up
  toggle(event, data) {
    this.rowData = data;
    this.locationid = data.locationId;
    this.interviewtype = this.rowData.activityAttributes;
    if (this.interviewtype === 'f') {
      this.items = [
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        { label: 'Approve/Reject', icon: 'pi pi-fw pi-check-circle', command: () => { this.approveOrRejectRequirement(); }, },
        { label: 'Activity', icon: 'pi pi-fw pi-clock', command: () => { this.getInterviewActivity(); } },
        { label: 'Location', icon: 'pi pi-map-marker', command: () => { this.location(); }, },
      ];
    }
    else {
      this.items = [
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        { label: 'Approve/Reject', icon: 'pi pi-fw pi-check-circle', command: () => { this.approveOrRejectRequirement(); }, },
        { label: 'Activity', icon: 'pi pi-fw pi-clock', command: () => { this.getInterviewActivity(); } },
      ];
    }
  }
  // It is used for download the profile
  downloadFileapi() {
    this.filedata = this.rowData.resumeId;
    this.isUploading1 = true;
    this.apisService.getApi(environment.downloadedattachment + '/' + this.filedata).subscribe((res) => {
      this.resumeRes = res;
      this.isUploading1 = false;
      window.location.assign(this.resumeRes.downloadUrl);
    });
  }
  submitForApproveOrReject() {
    if (this.approveOrRejectForm.invalid) {
      this.approveOrRejectForm.get('comments').markAsTouched();
    } else {
    this.disable = true;
    this.approveOrRejectYes();
    }
  }
  approveOrRejectRequirement() {
    this.rejectForComments = false;
    this.filedata = this.rowData;
    if (this.filedata.lkActivityStatus === 'C') {
      this.approveOrReject = true;
      this.approveOrRejectForm.get('comments').disable();
      this.approveOrRejectForm.get('approveOrReject').patchValue('Approved');
    }
    else {
      Swal.fire({
        // tslint:disable-next-line:max-line-length
        text: 'Request already ' + this.statusConversion[this.filedata.lkActivityStatus], icon: 'success', confirmButtonColor: '#0D416B', iconColor: '#1dbb99', confirmButtonText: 'Ok'
      });
    }
  }
  approveOrRejectYes() {
    this.statuskey = this.approveOrRejectForm.controls.approveOrReject.value === 'Approved' ? 'A' : 'R';
    this.comment = this.approveOrRejectForm.get('comments').value === null ? '' : this.approveOrRejectForm.get('comments').value;
    const reqbody = {
      id: this.rowData.activityId,
      lkStatus: this.statuskey,
      comments: this.comment,
      reviewId: this.rowData.reviewId,
      profileId: this.rowData.reqProfileId
    };
    // tslint:disable-next-line:max-line-length
    this.apisService.putApi(environment.intervieApprove, reqbody).subscribe((res) => {
      this.approveOrRejectResponse = res;
      if (this.approveOrRejectResponse.hasOwnProperty('success') === true) {
        this.approveOrReject = false;
        this.gridData();
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.approveOrRejectResponse.message, });
        this.disable = false;
      } else if (
        this.approveOrRejectResponse.hasOwnProperty('success') === false
      ) {
        this.messageService.add({
          key: 'br', severity: '', life: 3000, detail: this.approveOrRejectResponse.message,
        });
        this.disable = false;
      } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.approveOrRejectResponse.error, });
      }
    });
  }
  // It is used to approve the request
  approveRequirement() {
    this.approveOrRejectForm.get('comments').reset();
    this.rejectForComments = false;
    this.approveOrRejectForm.get('comments').disable();
  }
  // It is used to reject the request
  rejectRequirement() {
    this.rejectForComments = true;
    this.approveOrRejectForm.get('comments').enable();
  }
  // It is used for reset the form
  reset() {
    this.interviewsForm.reset();
    this.loader = true;
    this.gridData();
  }
  yes() {
    this.display = false;
  }
  no() {
    this.display = false;
  }
  // It is used to get the location
  location() {
    this.showDialog1 = true;
    this.apisService.getApi(environment.getlocation + this.locationid).subscribe((res) => {
      this.locdata = res.data[0];
      this.locationname = this.locdata.name;
      this.addressline1 = this.locdata.address1;
      this.adddressline2 = this.locdata.address2;
      this.city = this.locdata.city;
      this.zip = this.locdata.zip;
      this.country = this.livingCountry.filter(x => x.code === this.locdata.country)[0].label;
      // tslint:disable-next-line: max-line-length
      this.apisService.getApi(environment.state + '/' + this.livingCountry.filter(x => x.code === this.locdata.country)[0].code)
        // tslint:disable-next-line:no-shadowed-variable
        .subscribe((result) => {
          this.stateOptions = result.data;
          this.state = this.stateOptions.filter(x => x.code === this.locdata.state)[0].label;
        });
    });
  }
  // It is used to get the country dropdown
  CountryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'ADLC').subscribe((res) => {
      this.livingCountry = res.data;
    });
  }
  // It is used to display the interview activity
  getInterviewActivity() {
    this.displayModal = true;
    this.date = new Date();
    this.dattaa = this.rowData.reqProfileId;
    this.empId = this.rowData.reqId;
    this.apisService.getApi(environment.getIntreviewActivity + this.dattaa).subscribe((res) => {
      this.histroy = res.data;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  // It is used to display the map
  goToLink(url) {
    const webdata = 'https://www.google.com/maps/search/' + this.locationname + ',' + this.city;
    const addressLink = `${webdata}`;
    window.open(addressLink, '_blank');
  }
}
