<p-card>
    <!-- <p-panel> -->
        <p-accordion>
            <p-accordionTab [selected]="this.reqStatusacc === 'R' ? false : true">
        <p-header>
            <strong>{{headerName}} Id : </strong><span class="panelLabel">{{this.reqId}}</span>
            <strong class="positionheader">Position Title : </strong><span class="panelLabel"> {{this.reqName}}</span><strong class="positionheader">Max Rate :
            </strong><span class="panelLabel">{{this.maxrate |
            currency: 'USD' : true}}</span>
            <em class="pi pi-arrow-left panelBackRoute" (click)="backNavigate()" ></em>
        </p-header>
        <form [formGroup]="editRequirementsForm">
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Position Title</label>
                    <br>
                    <input readonly="true" autocomplete="off" id="input" type="text" pInputText
                        placeholder="Position Title" maxlength="30" formControlName='reqName'>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                    <label class="inputfields">Position Category</label>
                    <br>
                    <p-dropdown readonly="true"  optionLabel="jobCategory" [options]="positionData"
                    [showClear]="true" placeholder="Select Position Category" (onChange)="getPositionCategoryBindDataValues($event.value)"
                    formControlName="positionCategory" >
                </p-dropdown>
                    <div>
                        <span
                            *ngIf="this.editRequirementsForm.controls.positionCategory.touched && this.editRequirementsForm.controls.positionCategory.invalid && this.editRequirementsForm.controls.positionCategory.errors.required">
                            Please select position category
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                    <label class="inputfields">Job Role</label>
                    <p-dropdown [options]="minMaxRate" placeholder="Select Job Role " 
                    [showClear]="true" (onChange)="getMinrate($event.value)" optionLabel="title" 
                            formControlName="jobRole"></p-dropdown>
                </div>
               
                <div class="p-col-3 p-md-3 p-sm-12">
                    <label class="inputfields">Department </label>
                    <p-dropdown readonly="true" formControlName="department" optionLabel="name"
                    [showClear]="true"  [options]="departmentList" placeholder="Select Department"></p-dropdown>
                </div>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                    <label class="inputfields">Min Rate</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}}  </span>
                        <input readonly="true" autocomplete="off" type="text" pInputText placeholder="Enter Min Rate"
                            maxlength="9" formControlName="requirementMinRate" disabled="true" readonly="true">
                        <span class="p-inputgroup-addon">/Hrs</span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                    <label class="inputfields">Max Rate </label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}}  </span>
                        <input readonly="true" autocomplete="off" type="text" pInputText maxlength="9"
                            formControlName="requirementMaxRate" placeholder="Enter Max Rate">
                        <span class="p-inputgroup-addon">/Hrs</span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 "></div>
               </div>
               <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                    <label class="inputfields">Number of Positions<span class="mandatoryColor">*</span></label>
                    <input readonly="true" autocomplete="off" id="input" type="text" pInputText
                        placeholder="Number of Positions" maxlength="10"
                        formControlName="requirementNoofPositions" maxlength=4 pKeyFilter="num">
                    <div>
                        <span class="validationtest"
                            *ngIf="this.editRequirementsForm.controls.requirementNoofPositions.touched && this.editRequirementsForm.controls.requirementNoofPositions.invalid && this.editRequirementsForm.controls.requirementNoofPositions.errors.required">
                            Please enter number of positions
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory"  pTooltip="{{this.patchlocationString}}" tooltipPosition="top">
                    <label class="inputfields">Work Location<span class="mandatoryColor">*</span></label>
                    <p-dropdown [options]="locationList" optionLabel="name" placeholder="Select Work Location "
                    [showClear]="true"  formControlName="requirementWorkCenterLocation" appendTo="body" (onChange)="saveLocation($event)">
                 
                    <ng-template let-data pTemplate="item">
                        <div pTooltip="{{data.name + '\n' + data.address1+',' + data.city + ',' + data.state + ',' + data.country}}"
                            tooltipPosition="right">
                            {{ data.name }}
                        </div>
                    </ng-template>
                </p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.editRequirementsForm.controls.requirementWorkCenterLocation.touched && this.editRequirementsForm.controls.requirementWorkCenterLocation.invalid && this.editRequirementsForm.controls.requirementWorkCenterLocation.errors.required">
                            Please select work location
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                    <label class="inputfields">Required Experience<span class="mandatoryColor">*</span></label>
                    <p-dropdown [options]="exprienceList" placeholder="Select Required Experience" 
                    [showClear]="true"  optionLabel="label"  formControlName="experience">
                    </p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.editRequirementsForm.get('experience').touched && this.editRequirementsForm.get('experience').invalid && this.editRequirementsForm.get('experience').errors.required">
                            Please select experience
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 "></div>
</div>
<div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                    <label class="inputfields">Hire Type<span class="mandatoryColor">*</span></label>
                    <p-dropdown readonly="true"
                    [showClear]="true" placeholder="Select Hire Type" optionLabel="label"
                        [options]="hireTypeList" optionValue="code" formControlName='requirementType'>
                    </p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.editRequirementsForm.controls.requirementType.touched && this.editRequirementsForm.controls.requirementType.invalid && this.editRequirementsForm.controls.requirementType.errors?.required">
                            Please select type
                        </span>
                    </div>
                 
                   
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                    <label class="inputfields">Desired Start Date<span class="mandatoryColor">*</span></label>
                    <p-calendar readonly="true" id="popup" placeholder="MM-DD-YYYY" dateFormat="mm-dd-yy"
                        [showIcon]="true" [showButtonBar]="true" formControlName="requirementStartDate">
                    </p-calendar>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.editRequirementsForm.get('requirementStartDate').touched && this.editRequirementsForm.get('requirementStartDate').invalid && this.editRequirementsForm.get('requirementStartDate').errors.required">
                            Please select desired start date
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory" *ngIf="enddata">
                    <label class="inputfields">Expected End Date<span class="mandatoryColor">*</span></label>
                    <p-calendar readonly="true" id="popup" placeholder="MM-DD-YYYY" dateFormat="mm-dd-yy"
                        [showIcon]="true" [showButtonBar]="true" formControlName="requirementEndDate" disabled="true">
                    </p-calendar>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.editRequirementsForm.get('requirementEndDate').touched && this.editRequirementsForm.get('requirementEndDate').invalid &&  this.editRequirementsForm.get('requirementEndDate').errors.required">
                            Please select expected end date
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                    <label class="inputfields">Status<span class="mandatoryColor">*</span></label>
                    <p-dropdown readonly="true" placeholder="Select Status" optionLabel="label" [options]="statusList"
                        optionValue="code" formControlName="requirementStatus" [showClear]="true">
                    </p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.editRequirementsForm.controls.requirementStatus.touched && this.editRequirementsForm.controls.requirementStatus.invalid && this.editRequirementsForm.controls.requirementStatus.errors.required">
                            Please select status
                        </span>
                    </div>
                </div>
</div>
<div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 invalidMandatory">
                    <label>Employee approval required?</label>&nbsp;
                    <p-radioButton name="approval" value="Y" label="Yes" formControlName="approval" inputId="Yes">
                    </p-radioButton>
                    <p-radioButton class="radiopos" name="approval" value="N" label="No" formControlName="approval" inputId="No">
                    </p-radioButton>
                  </div>
</div>
<div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory container">
                    <label class="inputfields">Billing Type<span class="mandatoryColor">*</span></label>
                    <p-dropdown readonly="true" formControlName="billingtype" optionLabel="label" [showClear]="true"
                        [options]="billingList" optionValue="code" placeholder="Select Billing Type"></p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.editRequirementsForm.get('billingtype').touched && this.editRequirementsForm.get('billingtype').invalid && this.editRequirementsForm.get('requirementsExp').errors.required">
                            Please select billing type
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 container">
                    <label class="inputfields">Duration</label>
                    <div class="p-inputgroup invalidMandatory">
                        <input readonly="true" autocomplete="off" id="input" type="text" pInputText
                            placeholder="Enter Duration" formControlName="duration">
                        <span class="p-inputgroup-addon">/Hrs</span>

                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 container">
                    <label class="inputfields">Per Week</label>
                    <div class="p-inputgroup invalidMandatory">
                        <input readonly="true" autocomplete="off" id="input" type="text" pInputText
                            placeholder="Enter Per Week" formControlName="hrsperWeek">
                        <span class="p-inputgroup-addon" >Hrs</span>

                    </div>
                </div>
</div>
                <!-- <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory skills">
                    <label class="inputfields">Skill Set<span class="mandatoryColor">*</span></label>
                    <p-multiSelect readonly="true" optionLabel="label"  placeholder="Select Skill Set"
                        optionValue="code" [options]="specializationSkillsData" maxSelectedLabels="15"
                        [virtualScroll]="true" [itemSize]="34" formControlName="requirementSkillSet">
                    </p-multiSelect>
                    <div class="skillstyle">
                        <label class="inputfields"><strong>Skill Set : </strong>{{primarySkills.toString()}} </label> 
                
                    </div>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.editRequirementsForm.controls.requirementSkillSet.touched && this.editRequirementsForm.controls.requirementSkillSet.invalid && this.editRequirementsForm.controls.requirementSkillSet.errors.required">
                            Please select skill set
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory skills">
                    <label class="inputfields">Preferred Skill Set</label>
                    <p-multiSelect readonly="true" optionLabel="label" placeholder="Select Preferred Skill Set" 
                        optionValue="code" [options]="specializationSkillsData" maxSelectedLabels="15"
                        [virtualScroll]="true" [itemSize]="34"
                        formControlName="preferredSkills">
                    </p-multiSelect>
                    <div class="skillstyle">
                        <label class="inputfields"><strong>Preferred Skill Set : </strong>{{preferredSkillsData.toString()}} </label> 
                     
                   </div>
                </div> -->
                <div class="p-grid p-fluid">
                <div class="p-field p-col-6 p-md-6 p-sm-12 p-lg-6">
                    <label class="first">Skill Set<span class="mandatoryColor">*</span></label>
                    <p-multiSelect [options]="skillsList" [showToggleAll]="true" formControlName="requirementSkillSet"
                      placeholder="Select Skill Set" maxSelectedLabels="15" optionLabel="label"
                    
                      ></p-multiSelect>
                    <div class="skillstyle">
                      <label><strong>Skill Set: </strong></label><span>{{primarySkills.toString()}}</span>
                    </div>
                    <div>
                      <span class="validationtest"
                        *ngIf="this.editRequirementsForm.get('requirementSkillSet').touched && this.editRequirementsForm.get('requirementSkillSet').invalid && this.editRequirementsForm.get('requirementSkillSet').errors.required">
                        Please select skill set
                      </span>
                    </div>
                  </div>
                  <div class="p-field p-col-6 p-md-6 p-sm-12 p-lg-6">
                    <label class="first">Preferred Skill Set<span class="mandatoryColor">*</span></label>
                    <p-multiSelect [options]="skillsList" [showToggleAll]="true" formControlName="preferredSkills"
                      placeholder="Select Preferred Skill Set" maxSelectedLabels="15" optionLabel="label"></p-multiSelect>
                    <div class="skillstyle">
                      <label><strong>Preferred Skill Set: </strong> </label>
                     <span>{{skillsList12.toString()}}</span>
                    </div>
                    <div>
                      <span class="validationtest"
                        *ngIf="this.editRequirementsForm.get('preferredSkills').touched && this.editRequirementsForm.get('preferredSkills').invalid && this.editRequirementsForm.get('preferredSkills').errors.required">
                        Please select skill set
                      </span>
                    </div>
                  </div>
                </div>
                  <div class="p-fluid  p-grid" *ngIf="otherskills">
                    <div class="p-field p-col-12 p-md-6 p-sm-12 p-lg-6">
                        <label class="inputfields">Others Skills<span class="mandatoryColor">*</span></label>
                        <textarea rows="5" maxlength="301" cols="30" placeholder="Enter Others Skills (Ex: Angular, Java)"
                            formControlName="otherskills" pInputTextarea></textarea>
                           
                        </div>
                        </div>
                        <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                    <label class="inputfields">Job Description<span class="mandatoryColor">*</span></label>
                    <textarea readonly="true" autocomplete="off" pInputTextarea rows="8" cols="30"
                        placeholder="Job Description" formControlName="requirementDescription"></textarea>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.editRequirementsForm.controls.requirementDescription.touched && this.editRequirementsForm.controls.requirementDescription.invalid && this.editRequirementsForm.controls.requirementDescription.errors.required">
                            Please enter description
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                    <label class="inputfields">Responsibilities<span class="mandatoryColor">*</span></label>
                    <textarea readonly="true" autocomplete="off" pInputTextarea rows="8" cols="30"
                        placeholder="Responsibilities" formControlName="requirementResponsibilities"></textarea>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.editRequirementsForm.controls.requirementResponsibilities.touched && this.editRequirementsForm.controls.requirementResponsibilities.invalid && this.editRequirementsForm.controls.requirementResponsibilities.errors.required">
                            Please enter responsibilities
                        </span>
                    </div>
                </div>
                        </div>
                        <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Qualifications<span class="mandatoryColor">*</span></label>
                    <textarea readonly="true" autocomplete="off" pInputTextarea rows="8" cols="30"
                        placeholder="Qualifications" formControlName="requirementQualification"></textarea>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.editRequirementsForm.controls.requirementQualification.touched && this.editRequirementsForm.controls.requirementQualification.invalid && this.editRequirementsForm.controls.requirementQualification.errors.required">
                            Please enter qualifications
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                    <label class="inputfields">Comments</label>
                    <textarea readonly="true" autocomplete="off" pInputTextarea rows="8" cols="30"
                        placeholder="Comments" formControlName="requirementComments"></textarea>
                </div>
            </div>
            <br>
            <div>
                <div class="btnsCSS1">
                    <button *ngIf="updatebutton" class="addhBtn" pButton type="submit" icon='pi pi-save' label="Submit"
                        (click)="requirementEditUpdate()"></button>
                </div><br><br>
            </div>
        </form>
    <!-- </p-panel> -->
            </p-accordionTab>
        </p-accordion>
</p-card>
<br>
<div *ngIf="recived">
    <p-card>
      <p-panel>
        <p-header>
          <strong>Received Candidates</strong>
        </p-header>
  
        <form [formGroup]="submittedProfileForm">
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
              <label class="inputfields"> First Name </label>
              <input id="input" type="text" autocomplete="off" pInputText placeholder="First Name" maxlength="30"
                formControlName="firstName" (keyup.enter)="gridTable()">
            </div>
  
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
              <label class="inputfields"> Last Name </label>
              <input id="input" type="text" autocomplete="off" pInputText placeholder="Last Name" maxlength="30"
                formControlName="lastName" (keyup.enter)="gridTable()">
            </div>
  
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
              <label class="inputfields"> Email</label>
              <input id="input" type="text" autocomplete="off" pInputText placeholder="Email" maxlength="60"
                formControlName="emailId" (keyup.enter)="gridTable()">
  
              <span class="validationtest"
                *ngIf="this.submittedProfileForm.controls.emailId.invalid && this.submittedProfileForm.controls.emailId.errors?.pattern">Please
                enter
                email upto extension only</span>
  
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
              <label class="inputfields">Status </label>
              <p-dropdown [options]="consStatusData" placeholder="Select Status" optionLabel="label" [showClear]="true"
                formControlName="status" (keyup.enter)="gridTable()">
              </p-dropdown>
            </div>
          </div>
          <br>
          <div class="btnsCSS1">
            <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn"
              (click)="gridTable()"></button>
          </div>
          <br>
          <br>
          <p-dialog class="dialogBox" [contentStyle]="{'overflow': 'visible'}" [(visible)]="statusDailog"
            header="{{commentsHeader}}" [modal]="true" [style]="{width: '30%'}" [baseZIndex]="100000" [draggable]="false"
            [resizable]="false">
            <br>
            <div class="p-grid p-fluid">
              <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 ">
                <label class="inputfields">Comments<span class="mandatoryColor">*</span></label>
                <textarea autocomplete="off" [rows]="2" [cols]="30" pInputTextarea formControlName="comments1"
                  placeholder="comments" maxlength=300></textarea>
                <span class="mandatoryColor" *ngIf="submittedProfileForm.controls.comments1.touched && submittedProfileForm.controls.comments1.invalid
              && submittedProfileForm.controls.comments1.errors.required">
                  Please enter comments</span>
                <p class="mandatoryColor"></p>
              </div>
            </div>
            <div class="btnsCSS1" *ngIf="disableButton">
              <button pButton type="button" icon='pi pi-upload' label="OK" (click)="save()" class="addhBtn"></button>
            </div><br><br>
          </p-dialog>
        </form>
      </p-panel>
    </p-card>
    <br>
    <p-card class="pgpos">
      <div *ngIf="isUploading1" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <p-progressBar mode="indeterminate"></p-progressBar>
      </div>
      <div *ngIf="table">
        <p-table [columns]="cols11" id="excel-table" [autoLayout]="true" dataKey="consultantName"
          [value]="submittedProfilesGridData" selectionMode="single" [rows]="7" [rowsPerPageOptions]="[10,20,30]"
          sortMode="multiple">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th id="gridHeader" *ngFor="let col of columns let i=index" (click)="iconToggle[i] = !iconToggle[i]"
                [pSortableColumn]="col.field" [hidden]="col.sno == 5">
                {{col.header}}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-Data let-expanded="expanded">
            <tr>
              <td class="textCenter">
                {{Data.vendorName}}
              </td>
              <td class="textCenter">
                {{Data.consultantName}}
              </td>
              <td class="textCenter">{{Data.submittedDate}}</td>
              <td class="textCenter">
                <a (click)="op.toggle($event)" (click)="reqSkills1(getSkillSetTable(Data.skills))"
                  *ngFor="let data1 of getSkillSetTable(Data.skills); index as i;">
                  <p-badge class="badgespace newsty" *ngIf='i<1' [value]="data1"></p-badge>
                  <p-badge *ngIf='i===1' [value]="'+'+(Data.skills.length-1)+' more'"></p-badge>
                </a>
              </td>
              <td class="pointer" [hidden]="true">
                <a (click)="op.toggle($event)" (click)="reqSkills1(getSkillSetTable(Data.skills))"
                  *ngFor="let data1 of getSkillSetTable(Data.skills); index as i;">
                  <p-badge [value]="data1+','"></p-badge>
                </a>
              </td>
              <td class="textCenter">{{statusList[Data.profileState === 'S' ? 'B' : Data.profileState] }}</td>
              <td class="textCenter">
                {{Data.billRate | currency: currencyValue : true}}
              </td>
              <td class="textCenter"><a>
                  <em (click)="actionpopup.toggle($event); toggle(items, $event, Data)"
                    class="pi pi-ellipsis-v pointer"></em>
                </a></td>
            </tr>
          </ng-template>
        </p-table>
        <div class="p-grid ui-fluid">
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 totalrec">
            <h3 class="norecorddata ">Total records : {{this.subProRecords}}</h3>
            <p class="downpos">
              <span class="ui-column-title pointer" (click)="downloadActionPopup.toggle($event); downLoadsToggle()">
                <em class="pi pi-download downloadspace"></em>
                <strong>Download</strong></span>
            </p>
          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
            <p-paginator [rows]="10" [totalRecords]="subProRecords" [rowsPerPageOptions]="[10,20,30]"
              (onPageChange)="paginate($event)" #p></p-paginator>
          </div>
        </div>
  
  
      </div>
      <p-card *ngIf="records">
        <div class="norecordImgsize">
          <div *ngIf="records" class="aligncenter">
            <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
            <br><em>Sorry, we are not able to find any candidates</em><br>
          </div>
        </div>
      </p-card>
      <p-overlayPanel #op [showCloseIcon]="true" header="Skill Details" [style]="{width: '25vw'}">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-col-12 bordersty">
  
            <p class="skpos">
  
              <p-badge class="badgespace badgesp" *ngFor="let data2 of reqSkillsValue; index as i" [value]="data2"
                [ngClass]="i%2===0? 'newsty' : ''">
              </p-badge>
  
  
            </p>
  
          </div>
        </div>
      </p-overlayPanel>
    </p-card>
  </div>
  <p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="downloadOptions"></p-menu>
  <div *ngIf="!loader; else showLoader"></div>
  <ng-template #showLoader>
    <div class="loading-bar">
      <div>
        <div class="image-holder" class="col-sm-12 text-center laoderheight ">
          <div class="laodersize">
            <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
              class="loaderimg-height">
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>