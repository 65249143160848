<p-toast position="bottom-right" key="br"></p-toast>
<form [formGroup]="vendorTiersForm">
  <p-card class="pgpos">
    <p-panel [toggleable]="false">
      <p-header>
        <strong>
          {{tireName}} {{this.tierName}}
        </strong>
        <em class="pi pi-arrow-left panelBackRoute" (click)="goto()"></em>
      </p-header>
      <div *ngIf="table">
        <!--It is used for displaying the tiers table-->
        <p-table [columns]="tableHeader" [value]="vendorSaveData" selectionMode="single" dataKey="edit"
          [responsive]="true" sortMode="multiple" [autoLayout]="true">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                {{col.header}}

              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-data>
            <tr>

              <td class="textCenter"><span class="p-column-title">
                  Vendor Name</span>{{data.vName}}</td>
              <td class="textCenter"><span class="p-column-title">
                  Tier</span>{{data.tierName}}
              </td>
              <td class="textCenter"><span class="p-column-title">
                  Discount</span>{{data.discount}}</td>
              <td class="textCenter"><span class="p-column-title">
                  Days Delay</span>{{data.daysDelay}}</td>
              <td><span class="ui-column-title"></span>
                <p-badge [value]="getStatusTable(data.status)" [ngClass]="data.status ==='A'?'green': 'red'">
                </p-badge>
              </td>
              <td class="textCenter"><span class="p-column-title">
                  Action</span><a (click)="downloadActionPopup.toggle($event); toggle($event,'data', data)"
                  class="pi pi-ellipsis-v pointer"></a>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <div class="p-grid ui-fluid">

          <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
            <h3 class="norecorddata">Total records : {{this.totalrecords}}</h3>
          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
            <p-paginator [rows]="10" [totalRecords]="totalrecords" [rowsPerPageOptions]="[10,20,30]"
            (onPageChange)="paginate($event)"></p-paginator>
          </div>
        </div>
      </div>
      <p-card *ngIf="records">
        <div class="norecordImgsize">
          <div *ngIf="records" class="aligncenter">
            <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
            <br><em>Sorry, we are unable to find any vendors</em><br>
          </div>
        </div>
      </p-card>
    </p-panel>
       <!-- It is used for edit tiers  pop up -->
    <p-dialog class="dialogBox" header="Edit Vendor Tier" [(visible)]="display2" [modal]="true" [style]="{width: '25%'}" [baseZIndex]="1"
      [draggable]="false" [resizable]="false">
      <form [formGroup]='vendorEditForm'>
        <p class="mandatoryColor">{{this.message}}</p>
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <label class="inputfields">Vendor Name</label>
            <input id="input" type="text" pInputText placeholder="Enter First Name" maxlength="40" disabled="true"
              autocomplete="off" formControlName="vendorEditName" readonly>
          </div>
     
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <label class="inputfields">Type Of Tier </label>
            <p-dropdown [options]="gridData" placeholder="Select Type Of Tier" (onChange)='getVendorTier($event.value)'
              formControlName='vendorEditTier' optionLabel="tierName" [showClear]="true">
            </p-dropdown>
            <span *ngIf='tierdata' class="mandatoryColor">This tier Inactive</span>
          </div>
        
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <label class="inputfields">Status<span class="mandatoryColor">*</span></label>
            <p-dropdown [options]="stausData" formControlName="vendorEditsatutsTier"
              (onChange)="editactive($event.value)" placeholder="Select Status" optionLabel="label" [showClear]="true">
            </p-dropdown>
            <span class="mandatoryColor"
              *ngIf="vendorEditForm.controls.vendorEditsatutsTier.touched && vendorEditForm.controls.vendorEditsatutsTier.invalid && vendorEditForm.controls.vendorEditsatutsTier.errors.required">
              Please select status</span>
          </div>
       
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <label class="inputfields">Days Delay</label>
            <input autocomplete="off" id="input" type="text" disabled="true" size="30" pInputText
              placeholder="No.Of Days" readonly formControlName="editDays">
          </div>
     
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <label class="inputfields">Discount</label>
            <input autocomplete="off" id="input" type="text" size="30" pInputText disabled="true"
              placeholder="Discount" readonly formControlName="editDiscount">
          </div>
        </div><br>
        <div class="btnsCSS1">
          <button pButton type="button" icon='pi pi-times' label="Cancel" class="addhBtn"
            (click)="addCancel()"></button>
          <button pButton type="button" class="addhBtn" [disabled]="disable1" icon='pi pi-save' label="Update"
            (click)="Update()"></button>
        </div><br><br>
      </form>
    </p-dialog>
  </p-card>
</form>
<div *ngIf="!loading; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center laoderheight">
        <div class="laodersize">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif" class="loaderimg-height">
        </div>
      </div>
    </div>
  </div>
</ng-template>
<p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="items"></p-menu>