import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
@Component({
  selector: 'app-time-sheets',
  templateUrl: './time-sheets.component.html',
  styleUrls: ['./time-sheets.component.scss'],
  providers: [DatePipe, MessageService],
})
export class TimeSheetsComponent implements OnInit {
  months = [
    { month: 'All', id: '' },
    { month: 'January', id: 1 },
    { month: 'February', id: 2 },
    { month: 'March', id: 3 },
    { month: 'April', id: 4 },
    { month: 'May', id: 5 },
    { month: 'June', id: 6 },
    { month: 'July', id: 7 },
    { month: 'August', id: 8 },
    { month: 'September', id: 9 },
    { month: 'October', id: 10 },
    { month: 'November', id: 11 },
    { month: 'December', id: 12 },
  ];
  status = [
    { label: 'All', code: '' },
    { label: 'Approved', code: 'A' },
    { label: 'Submitted', code: 'S' },
    { label: 'Rejected', code: 'R' },
    { label: 'Draft', code: 'D' }
  ];
  pmstatus = [
    { label: 'All', code: '' },
    { label: 'Approved', code: 'A' },
    { label: 'Submitted', code: 'S' },
    { label: 'Rejected', code: 'R' },
  ];
  serachtimesheet: FormGroup;
  display: boolean;
  calender: FormGroup;
  curr: any;
  first: number;
  last: any;
  firstday: Date;
  lastday: Date;
  rows: any = 10;
  page: any = 1;
  actId: any;
  timesheetgrid: any;
  loader: boolean;
  totalRecords: any;
  records: boolean;
  table: boolean;
  pageCount: any;
  userId: any;
  statuslist = {};
  roles: any;
  employee: boolean;
  addBtn: boolean;
  cols: { field: string; header: string; }[];
  approve: boolean;
  years: any;
  year: any;
  obj = { id: 0, name: 'All' };
  weekstodiaplay: { id: string; name: string; }[];
  searchyearvalue: string;
  searchmonthvalue: string;
  searchstatusvalue: any;
  visibleStatus: any = [];
  disable: boolean;
  weekendday: any = [];
  searchweekvalue: string;
  btndisable = true;
  nextId: any;
  previousId: any;
  idList: any;
  accID: any;
  candidatesnames: any = [];
  customernames: any = [];
  usrroleid: any;
  // tslint:disable-next-line: max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private breadcrumbService: BreadcrumbService, private router: Router) {
    this.breadcrumbService.setItems([
      { label: 'Timesheets', routerLink: '/time-sheets' },
    ]);
    this.yearDD();
    this.actId = JSON.parse(this.apisService.decodeData());
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === ''){
      this.roles = this.actId.roles[0].id;
    }else{
      this.roles = this.usrroleid;
    }
    if (this.roles === 11) {
      this.visibleStatus = this.status;
    }
    else {
      this.visibleStatus = this.pmstatus;
    }
    if (this.roles === 11) {
      this.cols = [
        { field: 'userName', header: 'Start Date' },
        { field: 'companyName', header: 'End Date' },
        { field: 'phoneNumber', header: 'Hours' },
        { field: 'email', header: 'Submitted Date' },
        { field: 'lkStatus', header: 'Status' },
        { field: 'lkStatus', header: 'Approved Date' },
      ];
      this.employee = true;
      this.addBtn = true;
      this.approve = false;
    }else if (this.roles === 9){
      this.cols = [
        { field: 'phoneNumber', header: 'Customer Name' },
        { field: 'phoneNumber', header: 'Candidate Name' },
        { field: 'userName', header: 'Start date' },
        { field: 'companyName', header: 'End date' },
        { field: 'phoneNumber', header: 'Hours' },
        { field: 'lkStatus', header: 'Status' },
      ];
    }
    else {
      this.cols = [
        { field: 'submittedby', header: 'Submitted By' },
        { field: 'startdate', header: 'Start Date' },
        { field: 'enddate', header: 'End Date' },
        { field: 'submitteddate', header: 'Submitted Date' },
        { field: 'hrs', header: 'Billable Hrs' },
        { field: 'lkStatus', header: 'Status' },
      ];
      this.addBtn = false;
      this.employee = false;
      this.approve = true;
    }
  }
  dateTime = new Date();
  ngOnInit(): void {
    this.weekendday.push(this.obj);
    this.status.forEach(element => {
      this.statuslist[element.code] = [element.label];
    });
    this.userId = this.actId.userId;
    this.accID = this.actId.accId;
    // this.getdate();
    this.serachtimesheet = this.fb.group({
      searchyear: [''],
      searchmonth: [''],
      searchweek: [''],
      searchstatus: [''],
      candidateName: [''],
      customerName: ['']
    });
    this.calender = this.fb.group({
      selecteddate: [''],
    });
    this.serachtimesheet.controls.searchmonth.patchValue(this.months.filter((x) => x.id === '')[0]);
    this.serachtimesheet.controls.searchstatus.patchValue(this.visibleStatus.filter((x) => x.code === '')[0]);
    this.griddata();
    this.patchweek();
    if (this.roles === 11) {
    }
    else {
      this.gridcandidateNames();
      this.gridcustomerNames();
    }

  }
  patchweek() {
    this.serachtimesheet.controls.searchweek.patchValue(this.weekendday.filter((x) => x.id === 0)[0]);
  }
  yearDD() {
    this.apisService.getApi(environment.lookupsData + 'YEAR').subscribe(res => {
      this.years = res.data;
      this.serachtimesheet.controls.searchyear.patchValue(this.years.filter((x) => x.code === '1')[0]);
    });
  }
  addtimesheet() {
    this.display = true;
    this.getdate();
  }
  entertimesheet() {
    this.router.navigate(['/add-timesheet'], {
      queryParams: { startdate: this.firstday, enddate: this.lastday,  add: 'add' }
    });
  }
  getweekdate() {
    this.weekendday = [];
    // tslint:disable-next-line:prefer-const
    let year = this.serachtimesheet.controls.searchyear.value;
    const month = this.serachtimesheet.controls.searchmonth.value;
    // tslint:disable-next-line:prefer-const
    let selectedyear = year.label;
    if (selectedyear === undefined || selectedyear === null || selectedyear === '' || selectedyear === 'All') {
      const d = new Date();
      // tslint:disable-next-line: prefer-const
      let year1 = d.getFullYear();
      selectedyear = year1;
    }
    if (month.month !== 'All') {
      this.btndisable = false;
    }
    // tslint:disable-next-line:prefer-const
    let selectedmonth = (month.id) - 1;
    let count = 1;
    this.obj = { id: 0,  name: 'All' };
    this.weekendday.push(this.obj);
    // tslint:disable-next-line:no-var-keyword
    for (var i = 1; i <= new Date(selectedyear, selectedmonth, 0).getDate(); i++) {
      const date = new Date(selectedyear, selectedmonth, i);
      if (date.getDay() === 0) {
        this.obj = {
          id: date.getDate(),
          name: 'Week of ' + date.getDate(),
        };
        this.weekendday.push(this.obj);
        count = count + 1;
      }
    }
  }
  gridcandidateNames() {
    this.actId = JSON.parse(this.apisService.decodeData());
    this.accID = this.actId.accId;
    this.apisService.getApi(environment.lookupsData + 'candidate-name' + '/' + this.accID).subscribe((res: any) => {
      this.candidatesnames = res.data;
    }
    );
  }
  gridcustomerNames() {
    this.actId = JSON.parse(this.apisService.decodeData());
    this.accID = this.actId.accId;
    this.apisService.getApi(environment.lookupsData + 'customer-name' + '/' + this.accID).subscribe((res: any) => {
      this.customernames = res.data;
    }
    );
  }
  getdate() {
    this.curr = new Date(); // get current date
    this.first = this.curr.getDate() - this.curr.getDay(); // First day is the day of the month - the day of the week
    this.last = this.first + 6; // last day is the first day + 6
    this.firstday = moment().startOf('week').toDate(); // 06-Jul-2014
    this.lastday = moment().endOf('week').toDate(); // 12-Jul-2014
  }
  fetchdate() {
    this.curr = this.calender.controls.selecteddate.value; // get current date
    this.first = this.curr.getDate() - this.curr.getDay(); // First day is the day of the month - the day of the week
    this.last = this.first + 6; // last day is the first day + 6
    this.firstday = new Date(this.curr.setDate(this.first)); // 06-Jul-2014
    this.lastday = new Date(this.curr.setDate((this.firstday.getDate() + 6))); // 12-Jul-2014
  }
  resetTimeSheet() {
    this.serachtimesheet.reset();
    this.serachtimesheet.controls.searchyear.patchValue(this.years.filter((x) => x.code === '1')[0]);
    this.serachtimesheet.controls.searchmonth.patchValue(this.months.filter((x) => x.id === '')[0]);
    this.serachtimesheet.controls.searchstatus.patchValue(this.visibleStatus.filter((x) => x.code === '')[0]);
    this.patchweek();
    this.disable = true;
    this.griddata();
  }
  griddata() {
    if (this.serachtimesheet.controls.searchyear.value?.label === 'All') {
      this.searchyearvalue = '';
    } else {
      // tslint:disable-next-line:max-line-length
      this.searchyearvalue = this.serachtimesheet.controls.searchyear.value === null || this.serachtimesheet.controls.searchyear.value === undefined || this.serachtimesheet.controls.searchyear.value === '' ? '' : this.serachtimesheet.controls.searchyear.value.label;
    }
    if (this.serachtimesheet.controls.searchmonth.value?.month === 'All') {
      this.searchmonthvalue = '';
    } else {
      // tslint:disable-next-line:max-line-length
      this.searchmonthvalue = this.serachtimesheet.controls.searchmonth.value === null || this.serachtimesheet.controls.searchmonth.value === undefined || this.serachtimesheet.controls.searchmonth.value === '' ? '' : this.serachtimesheet.controls.searchmonth.value.month;
    }
    if (this.serachtimesheet.controls.searchstatus.value?.code === 'All') {
      this.searchstatusvalue = '';
    } else {
      // tslint:disable-next-line:max-line-length
      this.searchstatusvalue = this.serachtimesheet.controls.searchstatus.value === null || this.serachtimesheet.controls.searchstatus.value === '' || this.serachtimesheet.controls.searchstatus.value === undefined ? '' : this.serachtimesheet.controls.searchstatus.value.code;
    }
    if (this.serachtimesheet.controls.searchweek.value?.name === 'All') {
      this.searchweekvalue = '';
    } else {
      // tslint:disable-next-line:max-line-length
      this.searchweekvalue = this.serachtimesheet.controls.searchweek.value === null || this.serachtimesheet.controls.searchweek.value === undefined || this.serachtimesheet.controls.searchweek.value === '' ? '' : this.serachtimesheet.controls.searchweek.value.id;
    }
    // tslint:disable-next-line: max-line-length
    const statusId = this.searchstatusvalue;
    // tslint:disable-next-line:max-line-length
    const searchyear = this.searchyearvalue;
    // tslint:disable-next-line:max-line-length
    const searchmonth = this.searchmonthvalue;
    const searchweek = this.searchweekvalue;
    this.loader = true;
    // tslint:disable-next-line:max-line-length
    const canName =  this.serachtimesheet.controls.candidateName.value === null || this.serachtimesheet.controls.candidateName.value === undefined || this.serachtimesheet.controls.candidateName.value === '' ? '' : this.serachtimesheet.controls.candidateName.value.id;
    // tslint:disable-next-line:max-line-length
    const custName =  this.serachtimesheet.controls.customerName.value === null || this.serachtimesheet.controls.customerName.value === undefined || this.serachtimesheet.controls.customerName.value === '' ? '' : this.serachtimesheet.controls.customerName.value.id;
    // tslint:disable-next-line: max-line-length
    const data = this.roles === 11 ? '/' + this.page + '/' + this.rows + '/' + this.userId + '?status=' + statusId + '&year=' + searchyear + '&monthName=' + searchmonth + '&week=' + searchweek : '/my-hierarchy/' + this.page + '/' + this.rows + '?status=' + statusId + '&year=' + searchyear + '&monthName=' + searchmonth + '&week=' + searchweek;
    // my-hierarchy/+this.page + '/' + this.rows +?status=+statusId
    // tslint:disable-next-line:max-line-length
    const recrole = this.roles === 9 ? '/recruiters/' + this.page + '/' + this.rows + '/' +  this.accID + '/' + this.userId + '?monthName=' + searchmonth + '&year=' + searchyear + '&candidateName=' + canName + '&customerName=' + custName   : data;
    // tslint:disable-next-line: max-line-length
    this.loader = true;
    this.apisService.getApi(environment.timesheetgrid + recrole).subscribe((res: any) => {
      if (this.roles === 5) {
        this.idList = res.idList;
        sessionStorage.setItem('idlist', JSON.stringify(this.idList));
      }
      this.timesheetgrid = res.data;
      this.totalRecords = res.Count;
      this.nextId = res.nextId;
      this.previousId = res.previousId;
      this.loader = false;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  // It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.griddata();
  }
 rowedit(data) {
    if (this.roles === 11) {
      this.router.navigate(['/add-timesheet']
        , {
          queryParams: {
            id: data.id,
            edit: data.lkStatus,
            previousId: this.previousId,
            nextId: this.nextId
          }
        });
    }
    else {
      this.router.navigate(['/add-timesheet']
        , {
          queryParams: {
            id: data.id,
            edit: data.lkStatus,
            approve: 'approve',
            previousId: this.previousId,
            nextId: this.nextId
          }
        });
    }
  }
  yearsData(data) {
    this.year = data.value.label;
  }
}

