<p-card>
    <!-- <p-panel> -->
        <!-- <p-header>
            <strong>Open Position Id : </strong><span class="panelLabel">{{reqid}}</span><strong
                class="positionheader">Position
                Title
                : </strong><span class="panelLabel"> {{jobtite}} </span><strong class="positionheader">Max
                Rate
                : </strong><span class="panelLabel">
                {{this.maxRatevalue |
                currency: currencyValue : true}}</span>
            <div class="pi pi-arrow-left panelBackRoute" (click)="backNavigate()">
            </div>
        </p-header> -->
        <form [formGroup]="consultantAndSubmittedForm">
            <!-- <p-tabView>
                <p-tabPanel header="Available Candidates"> -->
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields" class="inputfields">Resource Type</label>
                            <p-dropdown [disabled]="true" [options]="resourceTypeDropDown" optionLabel="name"
                                [showClear]="true" placeholder="Select Resource Type" formControlName="resourceType">
                            </p-dropdown>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields">Email</label>
                            <input id="input" autocomplete="off" pInputText type="text" [size]="30" placeholder="Email"
                                formControlName="consultantEmailId" [minLength]="3" (completeMethod)="getEmails()">
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields"> Skills</label>
                            <p-multiSelect [options]="specializationSkills" [showToggleAll]="false"
                                [maxSelectedLabels]="15" formControlName="skills" placeholder="Select Skills"
                                optionLabel="label" [filter]='false'>
                            </p-multiSelect>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields">Available From</label>
                            <p-calendar id="popup" formControlName="startDate" dateFormat="mm-dd-yy"
                                placeholder="MM-DD-YYYY" [showIcon]="true" [showButtonBar]="true">
                            </p-calendar>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields">Rate From</label>
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}}
                                </span>
                                <input id="input" type="text" autocomplete="off" pInputText placeholder="Rate From"
                                    formControlName="fromRate" maxlength="8" pKeyFilter="num">
                                <span class="p-inputgroup-addon">/Hrs</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields">Rate To</label>
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}}
                                </span>
                                <input id="input" type="text" autocomplete="off" pInputText placeholder="Rate To"
                                    formControlName="toRate" maxlength="8" pKeyFilter="num">
                                <span class="p-inputgroup-addon">/Hrs</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3"></div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        </div>
                    </div>
                    <div class="btnsCSS1 btnalignment">
                        <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="resetConsultant()"
                            class="addhBtn"></button>
                        <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn"
                            (click)="searchConsultantVend()"></button>
                    </div>
                <!-- </p-tabPanel>
            </p-tabView> -->
        </form>
    <!-- </p-panel> -->
</p-card>
<br>
<p-card class="pgpos">
    <div *ngIf="isUploading1" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <p-progressBar mode="indeterminate"></p-progressBar>
    </div>
    <div *ngIf="table">
        <p-table #dt [columns]="cols" [value]="subData" selectionMode="single" dataKey="name" [paginator]="false"
            [rows]="10" [responsive]="true" [expandedRowKeys]="subData" [rowExpandMode]="single"
            [rowsPerPageOptions]="[10,20,30]" sortMode="multiple" [autoLayout]="true">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns let i=index" (click)="iconToggle[i] = !iconToggle[i]"
                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                        <span> {{col.header}} </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product let-expanded="expanded" let-i="rowIndex">
                <tr>
                    <td pTooltip="{{product.name}}" tooltipPosition="top">{{product.name}}</td>
                    <td class="textCenter pointer" pTooltip="{{product.email}}" tooltipPosition="top"><span
                            class="p-column-title">Email</span><a (click)="emailToast()"
                            (click)="copyInputMessage(product.email)">{{product.email}}</a>
                    </td>
                    <td>{{this.expLabel[product.lkExperience]}}</td>
                    <td class="skillpopup">
                        <a (click)="op.toggle($event)" (click)="skillSetPopup(product.requiredSkills)"
                            *ngFor="let data1 of getSkilssData(product.requiredSkills); index as i;">
                            <p-badge class="badgespace newsty" *ngIf='i<1' [value]="data1"></p-badge>
                            <p-badge *ngIf='i===1' [value]="'+'+(product.requiredSkills.length-1)+' more'"></p-badge>
                        </a>
                    </td>
                    <td>{{product.availableFrom}}</td>
                    <td> {{product.expMinRate | currency: currencyValue : true}} </td>
                    <td> {{product.expMaxRate | currency: currencyValue : true}} </td>
                    <td>{{product.resumeCount}}</td>
                    <td>
                        <div *ngIf="product.conCount">
                            <button type="button" pButton pRipple [pRowToggler]="product"
                                class="p-button-text p-button-rounded p-button-plain"
                                [disabled]="product.conCount === 0? false: true"
                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-left'"></button>
                        </div>
                        <div *ngIf="!product.conCount">
                            <button type="button" style="width: 50%;  color: #2bb9d5;" pButton pRipple
                                [pRowToggler]="product" class="p-button-text p-button-rounded p-button-plain"
                                [disabled]="product.conCount === 0? false: true"
                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-left'"></button>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-product>
                <tr>
                    <td colspan="9">
                        <div class="p-p-3">
                            <p-table [columns]="cols2" [value]="product.resumeList" selectionMode="single"
                                [autoLayout]="true" dataKey="edit" *ngIf="product.resumeList.length>0"
                                [paginator]="false" [rows]="10" [responsive]="true" [rowsPerPageOptions]="[10,20,30]"
                                sortMode="multiple">
                                <ng-template pTemplate="header" let-columns [autoLayout]="true">
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns let i=index" (click)="iconToggle[i] = !iconToggle[i]"
                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order let-i="rowIndex">
                <tr>
                    <td>{{i+1}}</td>
                    <td pTooltip="{{order.fileName}}" tooltipPosition="top" class="tooltip">
                        {{order.fileName}}</td>
                    <td class="tooltip" pTooltip="{{order.title}}" tooltipPosition="top">{{order.title}}</td>
                    <td>{{getStatusTable(order.lkStatus)}}</td>
                    <td>
                        <em (click)="downloadFileapi(order)" class="pi pi-download pointer" aria-hidden="true"
                            pTooltip="download" tooltipPosition="top"></em>
                    </td>
                    <td>{{order.comments}} </td>
                    <td (click)="openPopup(product,order)"><em class="fa fa-paper-plane pointer"
                            aria-hidden="true"></em></td>
                    <!-- <td>
                        <p class="messegeIcon"
                            [ngStyle]="{'background-color': order.status === 'Created' ? '#039CD3' : order.status === 'Delivered' ? '#689F38' : '#FBC02D'}">
                            {{order.status}}</p>
                    </td> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" class="aligncenter">
                <tr *ngIf="(product.resumeList?.length === 0) || (product.resumeList === null)">
                    <td colspan="7" class="aligncenter mandatoryColor">
                        No Records
                    </td>
                </tr>
            </ng-template>



        </p-table>

    </div>
    </td>
    </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" class="aligncenter">
        <tr *ngIf="(subData?.length === 0) || (subData === null)">
            <td colspan="10" class="aligncenter mandatoryColor">
                No Records
            </td>
        </tr>
    </ng-template>


    </p-table>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
            <h3 class="norecorddata">Total records : {{this.totalRecordsData}}</h3>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-10 pppos">
            <p-paginator [rows]="10" [totalRecords]="totalRecordsData" [rowsPerPageOptions]="[10,20,30]"
                (onPageChange)="paginate($event)"></p-paginator>
        </div>
    </div>
    </div>

    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any candidates</em><br>
            </div>
        </div>
    </p-card>
</p-card>

<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '25%' }">
    <label class="inputfields">Skill Details</label>
    <ng-template pTemplate>
        <form [formGroup]="dialogForm">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-12 bordersty">

                    <p class="skpos">

                        <p-badge class="badgespace badgesp" *ngFor="let data2 of reqSkillsValue; index as i"
                            [value]="data2" [ngClass]="i%2===0? 'newsty' : ''">
                        </p-badge>


                    </p>

                </div>
            </div>
        </form>
    </ng-template>
</p-overlayPanel>
<form [formGroup]="consultantAndSubmittedForm">
    <p-dialog class="dialogBox" [(visible)]="consultantRequirementDisplay" header="Associate Candidate to Open Positions"
        [modal]="true" [style]="{width: '40%'}" [baseZIndex]="100000" [draggable]="false" [resizable]="false">
        <div class="p-grid ui-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <strong>Position Title : </strong> <span class="panelLabel">{{jobtite}}</span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <strong>Candidate Name : </strong>
                <span class="panelLabel"> {{candidateName}}</span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <strong>Markup Percentage : </strong><span class="panelLabel">{{this.markUpData}}%</span>
            </div>
        </div>
        <div class="p-grid ui-fluid">
            <div class="p-col-5 p-md-5 p-sm-5 p-lg-5">
                <p-table [value]="payRatesTable" [responsive]="true">
                    <ng-template pTemplate="caption" class="billrate">
                        Bill Rate Calculation
                    </ng-template>
                    <ng-template pTemplate="body" let-pro>
                        <tr class="aligncenter ">
                            <td style="text-align: center;padding: 5px; cursor: pointer;"
                                (click)="payrate(((payRateValue * pro.value) / 100 )+ payRateValue)">{{pro.key}} =
                                {{((payRateValue
                                * pro.value) / 100 )+ payRateValue | currency: currencyValue : true}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="p-col-1 p-md-1 p-sm-1 p-lg-1"></div>
            <div class="p-col-6 p-md-6 p-sm-6 p-lg-6 billratealign">
                <div class="p-grid ui-fluid ">
                    <div class="p-col-12 p-md-6 p-sm-6 p-lg-6">
                        <label>Pay Rate</label>
                    </div>
                    <div class="p-col-12 p-md-6 p-sm-6 p-lg-6">
                        : <strong>{{payRateValue | currency: currencyValue : true
                            }}</strong>
                    </div>
                    <div class="p-col-12 p-md-6 p-sm-6 p-lg-6">
                        <label>Max Rate for Position </label>
                    </div>
                    <div class="p-col-12 p-md-6 p-sm-6 p-lg-6">
                        <label>: {{maxRatevalue |currency: currencyValue : true}}</label>
                    </div>
                    <div class="p-col-12 p-md-6 p-sm-6 p-lg-6">
                        <label class="inputfields">Bill Rate Requested </label>
                    </div>
                    <div class="p-col-12 p-md-6 p-sm-6 p-lg-4">
                        <div class="p-inputgroup">
                            <p class="duration">:</p>
                            <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                            <input class="aligncenter" style="width: 68px;" autocomplete="off" id="input" type="text"
                                size="10" maxlength="22" formControlName="billRateControl" pInputText
                                placeholder="Bill Rate">
                            <span class="p-inputgroup-addon">/Hr</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                        <div style="float: right;">
                            <span *ngIf="errorMsg" class="mandatoryColor"> Please enter bill rate less than max
                                rate </span>
                            <span class="validationtest"
                                *ngIf="
                                consultantAndSubmittedForm.get('billRateControl').touched &&
                                consultantAndSubmittedForm.get('billRateControl').errors?.required && consultantAndSubmittedForm.get('billRateControl').invalid">
                                Please enter request bill rate
                            </span>
                            <!-- <span class="mandatoryColor"
                            *ngIf="this.consultantAndSubmittedForm.controls.billRateControl.touched && this.consultantAndSubmittedForm.controls.billRateControl.invalid && this.consultantAndSubmittedForm.controls.billRateControl.errors.required || this.consultantAndSubmittedForm.controls.billRateControl.errors?.min">
                                Please enter bill rate requested
                            </span> -->
                            <span class="validationtest"
                                *ngIf="this.consultantAndSubmittedForm.controls.billRateControl.invalid && this.consultantAndSubmittedForm.controls.billRateControl.errors?.pattern">Please
                                enter Numbers only</span>
                            <span *ngIf="alertError" class="validrate">Please enter valid bill rate
                            </span>
                        </div>

                    </div>
                    <div class="p-col-6 p-md-6 p-sm-6 p-lg-6"></div>
                    <div class="p-col-6 p-md-6 p-sm-6 p-lg-6">
                        <div>
                            <button pButton type="button" icon="pi pi-check-circle" label="Associate" class="addhBtn"
                                (click)="submitConsultant()" [disabled]="disable"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </p-dialog>
</form>
<p-toast position="bottom-right" key="br"></p-toast>