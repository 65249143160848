import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-academic',
  templateUrl: './academic.component.html',
  styleUrls: ['./academic.component.scss'],
  providers: [MessageService, DatePipe]
})
export class AcademicComponent implements OnInit {
  items: MenuItem[];
  academicForm: FormGroup;
  addAcademicForm: FormGroup;
  iconToggle = [];
  totalRecords: any;
  loader = false;
  pageCount: any;
  first: any;
  rows: any = 10;
  page: any = 1;
  records: boolean;
  table: boolean;
  uploadedFiles: any = [];
  showUploadView = false;
  isUploading = false;
  fileOver: boolean;
  hide: boolean;
  showDialog: boolean;
  // It is used for displaying status dropdown values
  statusData = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
  ];
    // It is used for displaying table header data
  cols = [
    { field: 'universityId', header: 'University'},
    { field: 'degree', header: 'Degree'},
    { field: 'branch', header: 'Branch'},
    { field: 'startYear', header: 'Start Year' },
    { field: 'endYear', header: 'End Year'},
    { field: 'action', header: 'Action'},
  ];
  title: string;
  status1: string;
  attachmentType1: string;
  filedata: any;
  rowData: any;
  downloadData: any;
  fileName: any;
  fileType: any;
  showDialog1: boolean;
  userId: any;
  fileRes: any;
  fileData: any;
  filePath: any;
  fileSize: any;
  name: any;
  isUploading1 = false;
  range: any = [];
  livingCountry: any = [];
  stateOptions: any = [];
  degree: any;
  courseRes: any;
  universityRes: any = [];
  yearList: any = [];
  yearList1: any = [];
  gridres: any;
  actId: any;
  unverRes = {};
  universiRes: any = [];
  degreeRes = {};
  branch: any = [];
  branchRes = {};
  rowId: any;
  yearList2: any = [];
  disable = false;
  endYearList: any = [];
  idds: any;
  editAcademicForm: FormGroup;
  edituniversityRes: any = [];
  filesdatass: boolean;
  classinput: boolean;
  profileupload: boolean;
  data: any = [];
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private messageService: MessageService, private route: ActivatedRoute, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Academics', routerLink: ['/employee-academic'] },
    ]);
  }
  // Window keydown listener
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.gridData();
    }
  }
  ngOnInit() {
    this.classinput = true;
    this.actId = JSON.parse(this.apisService.decodeData());
    this.academicForm = this.fb.group({
      universityForm: [''],
      strtYearForm: [''],
      endYearForm: [''],
    });
    this.addAcademicForm = this.fb.group({
      addStateForm: ['', [Validators.required]],
      addUnivercityForm: ['', [Validators.required]],
      addClgNameForm: ['', [Validators.maxLength(50), Validators.pattern('^[\\sa-zA-z]*$')]],
      addDegreeForm: ['', [Validators.required]],
      addBranchForm: [''],
      addCtryForm: ['', [Validators.required]],
      addStrtYearForm: [''],
      addEndYearForm: [''],
      addResumeForm: ['', [Validators.required]],
    });
    this.editAcademicForm = this.fb.group({
      editCtryForm: ['', Validators.required],
      editStateForm: ['', Validators.required],
      editUnivercityForm: ['', Validators.required],
      editClgNameForm: ['', [Validators.maxLength(50), Validators.pattern('^[\\sa-zA-z]*$')]],
      editDegreeForm: ['', Validators.required],
      editBranchForm: [''],
      editStrtYearForm: [''],
      editEndYearForm: ['']
    });
    this.title = this.route.snapshot.queryParamMap.get('title');
    this.status1 = this.route.snapshot.queryParamMap.get('status');
    this.attachmentType1 = this.route.snapshot.queryParamMap.get('attachmentType');
    this.CountryDropdown();
    this.universitiesList();
    this.eduCourse();
    this.gridData();
    this.onlyYear();
  }
  // It is used for displaying country dropdown values
  CountryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'ADLC').subscribe((res) => {
      this.livingCountry = res.data;
    });
  }
  // It is used for displaying state dropdown values
  getStateByUrl(code) {
    if (code === null || code === '' || code === undefined) {
      this.stateOptions = [];
    }
    else {
      this.apisService.getApi(environment.state + '/' + code).subscribe((res) => {
        this.stateOptions = res.data;
        this.editAcademicForm.controls.editStateForm.patchValue(this.stateOptions.filter(x => x.code === this.rowId.stateCode)[0]);
      });
    }
  }
  // It is used for displaying search field unviersity dropdown values
  universitiesList() {
    this.apisService.getApi(environment.universitiesList).subscribe((res) => {
      this.universiRes = res.data;
      this.universiRes.forEach(element => {
        this.unverRes[element.id] = element.name;
      });
    });
  }
  // It is used for displaying add unviersity dropdown values
  universityDropdown() {
    const stt = this.addAcademicForm.controls.addStateForm.value.code;
    const cnt = this.addAcademicForm.controls.addCtryForm.value.code;
    this.apisService.getApi(environment.universities + cnt + '/' + stt).subscribe((res) => {
      this.universityRes = res.data;
    });
  }
  // It is used for displaying edit unviersity dropdown values
  edituniversityDropdown() {
    const stt = this.editAcademicForm.controls.editStateForm.value.code;
    const cnt = this.editAcademicForm.controls.editCtryForm.value.code;
    this.apisService.getApi(environment.universities + cnt + '/' + stt).subscribe((res) => {
      this.edituniversityRes = res.data;
    });
  }
  // It is used for displaying branch dropdown values
  branchDropdown(data) {
    this.apisService.getApi(environment.branch + data).subscribe((res) => {
      this.branch = res.data;
      this.editAcademicForm.controls.editBranchForm.patchValue(this.branch.filter(x => x.id === Number(this.rowId.branchId))[0]);
      this.branch.forEach(element => {
        this.branchRes[element.id] = element.educationName;
      });
    });
  }
  // It is used for displaying degree dropdown values
  eduCourse() {
    this.apisService.getApi(environment.eduCourse).subscribe((res) => {
      this.courseRes = res.data;
      this.courseRes.forEach(element => {
        this.degreeRes[element.id] = element.courseName;
      });
    });
  }
  // It is used to reset search fields
  reset() {
    this.academicForm.reset();
    this.addAcademicForm.reset();
    this.gridData();
  }
  onlyYear() {
    const data = {
      availableYears: []
    };
    const year = new Date().getFullYear();
    for (let years = year; years >= 1950; years-- ){
      this.data.push(years);
    }
    this.range = [];
    this.range.push(year);
    for (let i = this.data.length; i >= 0; i--) {
      data.availableYears.push({ id: year - i });
    }
    this.yearList = data.availableYears;
  }
  onlyendYear() {
    const startyear = this.addAcademicForm.controls.addStrtYearForm.value.id;
    const data = {
      availableYears: []
    };
    const year1 = new Date().getFullYear();
    for (let i = startyear; i <= year1; i++) {
      data.availableYears.push({ id: i });
    }
    this.yearList1 = data.availableYears;
  }
  // It is used to show action pop up
  toggle(event, data, rowData) {
    this.rowData = rowData;
    this.items = [
      { label: ' Edit', icon: 'pi pi-pencil', command: () => { this.editAcademic(rowData); } },
      { label: 'Download', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
    ];
  }
  // It is used to download files
  downloadFileapi() {
    this.isUploading1 = true;
    this.filedata = this.rowData.id;
    this.apisService.getApi(environment.downloaddocument + '/' + this.filedata).subscribe((res: any) => {
      this.downloadData = res;
      window.location.assign(this.downloadData.downloadUrl);
      this.isUploading1 = false;
    });
  }
  handleFileInput(event) {

    this.fileName = event.target.files[0];
    if (this.fileName.name.length > 25){
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'File name should be 25 alphabets.' });
      this.cancelfiles();
    }
    if (this.fileName.size > 10485760){
    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please upload below 10MB.' });
    this.cancelfiles();
   }
   else{
    this.name = this.fileName.name;
    this.fileType = this.fileName.name.split('.').pop();
    const formData: FormData = new FormData();
    formData.append('file', this.fileName);
    this.filedata = formData;
    // tslint:disable-next-line:no-conditional-assignment
    if (this.filedata = formData){
      this.filesdatass = true;
    }else{
      this.filesdatass = false;
    }
   }

  }
  // It is used to upload attchments
  uploadAttachment() {
    if (this.addAcademicForm.invalid) {
      const controls = this.addAcademicForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      this.disable = true;
      this.userId = this.actId.userId;
      this.apisService.postApi(environment.profileUpload + this.userId + '/' + 'A', this.filedata).subscribe((res: any) => {
        this.fileRes = res;
        this.fileData = res.data;
        this.filePath = res.data.path;
        this.fileSize = res.data.fileSize;
        this.addAcademicData();
        if (this.fileRes.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.fileRes.message });
          this.loader = false;
          this.showDialog1 = false;
          this.gridData();
        } else if (this.fileRes.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.fileRes.failed });
          this.loader = false;
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.fileRes.error });
          this.loader = false;
        }
      });
    }
  }
 // It is used to show table data
  gridData() {
    this.loader = true;
    // tslint:disable-next-line:max-line-length
    const univers = this.academicForm.controls.universityForm.value === null || '' || this.academicForm.controls.universityForm.value.id === undefined ? '' : this.academicForm.controls.universityForm.value.id; // tslint:disable-next-line:max-line-length
    const strtYear = this.academicForm.controls.strtYearForm.value === null || '' || this.academicForm.controls.strtYearForm.value.id === undefined ? '' : this.academicForm.controls.strtYearForm.value.id;
    // tslint:disable-next-line:max-line-length
    const endYear = this.academicForm.controls.endYearForm.value === undefined || this.academicForm.controls.endYearForm.value === null || this.academicForm.controls.endYearForm.value === '' ? '' : this.academicForm.controls.endYearForm.value.id;
    // tslint:disable-next-line:max-line-length
    this.apisService.getApi(environment.academics + this.page + '/' + this.rows + '/' + this.actId.userId + '/' + 'A' + '?universityId=' + univers + '&startYear=' + strtYear + '&endYear=' + endYear).subscribe((res: any) => {
      this.gridres = res.data;
      this.totalRecords = res.count;
      this.loader = false;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  // It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridData();
  }
  // It is used to add academics
  addAcademicData() {
    const data = {
      usrId: this.actId.userId,
      title: '',
      description: '',
      lkObjectType: 'A',
      filePath: this.filePath,
      // tslint:disable-next-line:max-line-length
      startYear: this.addAcademicForm.controls.addStrtYearForm.value === null || '' || this.addAcademicForm.controls.addStrtYearForm.value.id === undefined ? '' : this.addAcademicForm.controls.addStrtYearForm.value.id,
      rankMarks: '',
      // tslint:disable-next-line:max-line-length
      endYear: this.addAcademicForm.controls.addEndYearForm.value === null || '' || this.addAcademicForm.controls.addEndYearForm.value.id === undefined ? '' : this.addAcademicForm.controls.addEndYearForm.value.id,
      // tslint:disable-next-line:max-line-length
      universitieId: this.addAcademicForm.controls.addUnivercityForm.value === null || '' || this.addAcademicForm.controls.addUnivercityForm.value.id === undefined ? 0 : this.addAcademicForm.controls.addUnivercityForm.value.id,
      institutionName: this.addAcademicForm.controls.addClgNameForm.value,
      degree: this.addAcademicForm.controls.addDegreeForm.value.id,
      branch: this.addAcademicForm.controls.addBranchForm.value.id
    };
    this.apisService.postApi(environment.insertAcademics, data).subscribe((res: any) => {
      this.disable = false;
      if (res.hasOwnProperty('success') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        this.gridData();
      } else if (res.hasOwnProperty('failed') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
        this.loader = false;
      } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
        this.loader = false;
      }
    });
  }
  // It is used to upload files
  onUpload(files) {
    this.isUploading = true;
    const formData: FormData = new FormData();
    formData.append('file', files[0]);
    // tslint:disable-next-line:max-line-length
    this.apisService.postApi(environment.fileuploadCertificates + 'A' + '/' + this.actId.userId + '?file=', formData).subscribe((res: any) => {
      this.showUploadView = false;
      if (res.hasOwnProperty('success') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        this.isUploading = false;
        this.showUploadView = false;
        this.gridData();
      } else if (res.hasOwnProperty('failed') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
      } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
      }
    });
  }
  toggleUploadView() {
    this.showUploadView = !this.showUploadView;
  }
  uploadFile() {
    const formData: FormData = new FormData();
    this.uploadedFiles.forEach((file) => {
      formData.append('file', file);
    });
    this.apisService.postApi(environment.fileuploadDepartments, formData).subscribe((res: any) => {
    });
  }
  // Drag over listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drag Leave Listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drop Listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.isUploading = true;
      this.onUpload(files);
      this.gridData();
    }
  }
  // It is used to show add academic pop up
  addAcademic() {
    this.showDialog1 = true;
    this.addAcademicForm.reset();
    this.CountryDropdown();
    this.filesdatass = false;
  }
  // It is used bind the table data
  editAcademic(rowData) {
    this.rowId = rowData;
    this.addAcademicForm.reset();
    this.editAcademicForm.reset();
    this.showDialog = true;
    this.branchDropdown(rowData.degree);
    this.editAcademicForm.controls.editClgNameForm.patchValue(rowData.institutionName);
    this.editAcademicForm.controls.editCtryForm.patchValue(this.livingCountry.filter(x => x.code === rowData.countryCode)[0]);
    this.editAcademicForm.controls.editDegreeForm.patchValue(this.courseRes.filter(x => x.id === Number(rowData.degree))[0]);
    this.getStateByUrl(rowData.countryCode);
    const stt = rowData.stateCode;
    const cnt = rowData.countryCode;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.universities + cnt + '/' + stt).subscribe((res) => {
      this.edituniversityRes = res.data;
      // tslint:disable-next-line: max-line-length
      this.editAcademicForm.controls.editUnivercityForm.patchValue(this.edituniversityRes.filter(x => x.id === this.rowId.universityId)[0]);
    });
    this.editAcademicForm.controls.editStrtYearForm.patchValue(this.yearList.filter(x => x.id === Number(rowData.startYear))[0]);
    this.onlyendYear1();
  }
  onlyendYear1() {
    const editStartyear = this.editAcademicForm.controls.editStrtYearForm.value.id;
    const data = {
      availableYears: []
    };
    const Edityear = new Date().getFullYear();
    for (let i = editStartyear; i <= Edityear; i++) {
      data.availableYears.push({ id: i });
    }
    this.endYearList = data.availableYears;
    this.editAcademicForm.controls.editEndYearForm.patchValue(this.endYearList.filter(x => x.id === Number(this.rowId.endYear))[0]);
  }
  // It is used to update academics
  updateBranch() {
    if (this.editAcademicForm.invalid) {
      const controls = this.editAcademicForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      this.disable = true;
      const data = {
        id: this.rowId.id,
        title: '',
        description: '',
        // tslint:disable-next-line:max-line-length
        startYear: this.editAcademicForm.controls.editStrtYearForm.value === null || this.editAcademicForm.controls.editStrtYearForm.value === '' || this.editAcademicForm.controls.editStrtYearForm.value === undefined ? '' : this.editAcademicForm.controls.editStrtYearForm.value.id,
        rankMarks: 1,
        // tslint:disable-next-line:max-line-length
        endYear: this.editAcademicForm.controls.editEndYearForm.value === null || this.editAcademicForm.controls.editEndYearForm.value === '' || this.editAcademicForm.controls.editEndYearForm.value === undefined ? '' : this.editAcademicForm.controls.editEndYearForm.value.id,
        // tslint:disable-next-line:max-line-length
        universitieId: this.editAcademicForm.controls.editUnivercityForm.value === null || this.editAcademicForm.controls.editUnivercityForm.value === '' || this.editAcademicForm.controls.editUnivercityForm.value === undefined ? 0 : this.editAcademicForm.controls.editUnivercityForm.value.id,
        institutionName: this.editAcademicForm.controls.editClgNameForm.value,
        // tslint:disable-next-line:max-line-length
        degree: this.editAcademicForm.controls.editDegreeForm.value === null || this.editAcademicForm.controls.editDegreeForm.value === '' || this.editAcademicForm.controls.editDegreeForm.value === undefined ? '' : this.editAcademicForm.controls.editDegreeForm.value.id,
        // tslint:disable-next-line:max-line-length
        branch: this.editAcademicForm.controls.editBranchForm.value === null || this.editAcademicForm.controls.editBranchForm.value === '' || this.editAcademicForm.controls.editBranchForm.value === undefined ? '' : this.editAcademicForm.controls.editBranchForm.value.id,
      };
      this.apisService.putApi(environment.branchUpdate, data).subscribe((res: any) => {
        if (res.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
          this.disable = false;
          this.showDialog = false;
          this.gridData();
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
          this.loader = false;
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error, });
          this.loader = false;
        }
      });
    }
  }
  cancelfiles(){
    this.addAcademicForm.controls.addResumeForm.reset();
    this.filesdatass = false;
    this.classinput = false;
    this.profileupload = true;
  }
}
