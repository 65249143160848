import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-association-request',
  templateUrl: './association-request.component.html',
  styleUrls: ['./association-request.component.scss'],
  providers: [DatePipe, MessageService],
})
export class AssociationRequestComponent implements OnInit {
  association: FormGroup;
  // It is used to show table header of association request screen
  cols = [
    { field: 'userName', header: 'Name' },
    { field: 'email', header: 'Email' },
    { field: 'createdDate', header: 'Requested Date' },
    { field: 'lkStatus', header: 'Status' },
    { field: 'approveRrejectDate', header: 'Approve/Reject Date' },
    { field: 'action', header: 'Action' },
  ];
  // tslint:disable-next-line:no-inferrable-types
  loader: boolean = false;
  rows: any = 10;
  page: any = 1;
  associate: any;
  totalRecords: any;
  table: boolean;
  records: boolean;
  accid: any;
  associationedit: FormGroup;
  dropdown: any = [];
  statustable: {};
  getStatusData: {};
  first: any;
  pageCount: any;
  totalrecords: any;
  items: { label: string; icon: string; command: () => void; }[];
  rowData: any;
  approveOrRejectForm: FormGroup;
  filedata: any;
  rejectForComments: boolean;
  approveOrReject: boolean;
  gettatusData = {};
  display: boolean;
  disable: boolean;
  approveOrRejectResponse: any;
  id: any;
  statusdata: string;
  idnumber: any;
  email: any;
  usrId: any;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private breadcrumbService: BreadcrumbService, private apisService: ApisService, private messageService: MessageService) {
    this.breadcrumbService.setItems([
      { label: 'Association Request', routerLink: '/association' }
    ]);
  }
  ngOnInit(): void {
    this.association = this.fb.group({
      name: [''],
      email: [''],
    });
    this.approveOrRejectForm = this.fb.group({
      approveOrReject: [''],
      comments: ['', [Validators.required, Validators.maxLength(200)]],
    });
    this.gridData();
    this.statusdrop();
  }
  // Method to get griddata of associatio request
  gridData() {
    this.loader = true;
    const pageNo = this.page;
    const pageCount = this.rows;
    const orgId = pageNo + '/' + pageCount;
    const actId = JSON.parse(this.apisService.decodeData());
    this.accid = actId.accId;
    // tslint:disable-next-line:max-line-length
    const name = this.association.controls.name.value === undefined ? '' : this.association.controls.name.value === null ? '' : this.association.controls.name.value === '' ? '' : this.association.controls.name.value; // tslint:disable-next-line:max-line-length
    const email = this.association.controls.email.value === undefined ? '' : this.association.controls.email.value === null ? '' : this.association.controls.email.value === '' ? '' : this.association.controls.email.value;
    const gridres = orgId + '/' + this.accid + '?name=' + name + '&email=' + email;
    this.apisService.getApi(environment.associationreceived + '/' + gridres).subscribe((res: any) => {
      this.associate = res.data.records;
      this.totalrecords = res.data.count;
      this.loader = false;
      if (this.totalrecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  // Method to show actions
  toggle(event, data, rowData) {
    this.rowData = data;
    this.id = data.id;
    this.items = [
      { label: 'Approve/Reject', icon: 'pi pi-fw pi-check-circle', command: () => { this.approveOrRejectRequirement(); } },
    ];
  }
  emailToast() {
    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
  }
  // pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridData();
  }
  // It is used to show status dropdown values
  statusdrop() {
    this.apisService.getApi(environment.associateddrop + '/' + 'ATESL').subscribe((res) => {
      this.dropdown = res.data;
      this.dropdown.forEach(element => {
        this.gettatusData[element.code] = element.label;
      });
    });
  }
  // It is used to copy email t clipboard
  copyInputMessage(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
  // It is used to approve or reject request
  approveOrRejectRequirement() {
    this.rejectForComments = false;
    this.filedata = this.rowData;
    if (this.filedata.lkStatus === 'C') {
      this.approveOrReject = true;
      this.approveOrRejectForm.get('comments').disable();
      this.approveOrRejectForm.get('approveOrReject').patchValue('Approved');
    }
    else {
      // tslint:disable-next-line:max-line-length
      Swal.fire({ text: 'Request already ' + this.gettatusData[this.filedata.lkStatus], icon: 'success', iconColor: '#1dbb99', confirmButtonColor: '#0D416B', confirmButtonText: 'Ok' });
    }
  }
  // Approve radio button method
  approveRequirement() {
    this.approveOrRejectForm.get('comments').reset();
    this.rejectForComments = false;
    this.approveOrRejectForm.get('comments').disable();
  }
  // reject radio button method
  rejectRequirement() {
    this.rejectForComments = true;
    this.approveOrRejectForm.get('comments').enable();
  }
  reset() {
    this.association.reset();
    this.loader = true;
    this.gridData();
  }
  submitForApproveOrReject() {
    this.disable = true;
    if (this.approveOrRejectForm.invalid) {
      this.approveOrRejectForm.get('comments').markAsTouched();
    } else {
      this.disable = true;
      this.approveOrRejectYes();
    }
  }
  approveOrRejectYes() {
    this.idnumber = this.rowData.id;
    this.usrId = this.rowData.usrId;
    this.email = this.rowData.email;
    const emaildata = this.email.split('@', 2);
    if (this.approveOrRejectForm.invalid) {
      const controls = this.approveOrRejectForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      const datas = {
        emailExtension: emaildata[1],
        lkStatus: this.approveOrRejectForm.controls.approveOrReject.value === 'Approved' ? 'A' : 'R',
        // tslint:disable-next-line:max-line-length
        comment: this.approveOrRejectForm.controls.comments.value === null || this.approveOrRejectForm.controls.comments.value === undefined || this.approveOrRejectForm.controls.comments.value === '' ? '' : this.approveOrRejectForm.controls.comments.value,
        id: this.idnumber,
        usrId: this.usrId,
      };
      this.disable = true;
      this.apisService.putApi(environment.associationrequest, datas).subscribe((res) => {
        this.approveOrRejectResponse = res;
        if (this.approveOrRejectResponse.hasOwnProperty('success') === true) {
          this.approveOrReject = false;
          this.gridData();
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.approveOrRejectResponse.message });
          this.disable = false;
        } else {
        }
      });
    }
  }
}
