import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { MessageService } from 'primeng/api';
import { isEmpty } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-recruiting-manager-openposition',
  templateUrl: './recruiting-manager-openposition.component.html',
  styleUrls: ['./recruiting-manager-openposition.component.scss'],
  providers: [MessageService, DatePipe],
})
export class RecruitingManagerOpenpositionComponent implements OnInit {
  openPositionSearchForm: FormGroup;
  cols3 = [
    { dataKey: 'reqId', header: 'Job Id' },
    { dataKey: 'accName', header: 'Customer' },
    { dataKey: 'reqName', header: 'Position Title' },
    { dataKey: 'skills', header: 'Skills' },
    { dataKey: 'postedDate', header: 'Posted Date' },
    { dataKey: 'plannedEndDate', header: 'Target Date' },
    { dataKey: 'status', header: 'Status' },
    { dataKey: 'submissions', header: 'Submissions' },
    { dataKey: 'consName', header: 'Assigned' },
    ];
    cols = [
      { field: 'reqId', header: 'Job Id', width: '4%', sno: 1 },
      { field: 'accName', header: 'Customer', width: '7%' , sno: 2},
      { field: 'reqName', header: 'Position Title', width: '7%' , sno: 3},
      { field: 'requiredSkills', header: 'Skills', width: '10%', sno: 4 },
      { field: 'requiredSkills', header: 'Skills', width: '10%', sno: 5 },
      { field: 'postedDate', header: 'Posted Date', width: '6%' , sno: 6},
      { field: 'plannedEndDate', header: 'Target Date', width: '6%', sno: 7 },
      { field: 'status', header: 'Status', width: '5%', sno: 8 },
      { field: 'submissions', header: 'Submissions', width: '5%' , sno: 9},
      { field: 'consName', header: 'Assigned', width: '8%', sno: 10},
      { field: 'Action', header: 'Action', width: '3%' , sno: 11},
    ];

  assignstatusDD = [
    { code: 1, label: 'Assigned' },
    { code: 0, label: 'Not assigned' },
  ];
  loading: boolean;
  acctDetails: any;
  roleId: any;
  array: any;
  specializationSkillsData: any = [];
  statusDD: any;
  tablestatusdata: any;
  display: boolean;
  reqSkillsValue: any;
  skillSet: any[];
  loader: boolean;
  requirementsStartDate: string;
  requirementsEndDate: string;
  requirementsData: any;
  reqcount: any;
  totalRecords: any;
  requirementsGridData: any;
  dbQuery: any;
  table: boolean;
  records: boolean;
  rows: any = 10;
  page: any = 1;
  name: string;
  gridStatus: any;
  reqID: any;
  header: string;
  first: any;
  pageCount: any;
  // tslint:disable-next-line:max-line-length
  items: ({ label: string; icon: string; disable: boolean; command: (event: any, i: any) => void; } | { label: string; icon: string; command: (event: any, i: any) => void; disable?: undefined; })[];
  downloadOptions: { label: string; icon: string; command: (event: any, i: any) => void; }[];
  rowData: any;
  assignedList: any;
  recruiterForm: FormGroup;
  dialogForCandidates: boolean;
  enableAssignedField: boolean;
  recruiterFormdata: any;
  alertError: boolean;
  accountId: any;
  rowId: any;
  TaxTermDailog: boolean;
  getStatusData: any = [];
  usrroleid: any;
  // tslint:disable-next-line: max-line-length
  constructor( private fb: FormBuilder, private apisService: ApisService, private datepipe: DatePipe, private breadcrumbService: BreadcrumbService, private route: Router, private messageService: MessageService ) {
    this.breadcrumbService.setItems([
      { label: 'Open Positions', routerLink: '/openpositions' },
    ]);
  }
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchData();
    }
  }
  ngOnInit(): void {
    this.openPositionSearchForm = this.fb.group({
      requirementsJobId: [''],
      requirementsCustomerName: [''],
      requirementsJobTitle: [''],
      requirementsassignCandidates: [''],
      requirementsassignStatus: [''],
      requirementsStartDate: [''],
      requirementsEndDate: [''],
    });
    this.recruiterForm = this.fb.group({
      recruiterControl: [''],
    });
    this.openPositionSearchForm.controls.requirementsassignStatus.patchValue({
      code: 0,
      label: 'Not assigned',
    });
    this.acctDetails = JSON.parse(this.apisService.decodeData());
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === ''){
      this.roleId = this.acctDetails.roles[0].id;
    }else{
      this.roleId = this.usrroleid;
    }
    this.gridData();
    this.getStatus();
    this.reqSkillSet();
    this.getAssign();
  }
  getToolTipContent(tootipdata) {
    return tootipdata;
  }
  gridData() {
    const statusVal = this.openPositionSearchForm.controls.requirementsassignStatus.value;
    // tslint:disable-next-line:max-line-length
    const assingId = statusVal === '' || statusVal === null || statusVal === undefined ? 0 : this.openPositionSearchForm.controls.requirementsassignStatus.value.code;
    this.loading = true;
    const data =  '/' + this.page + '/' + this.rows +  '?' +  'accId=' +  this.acctDetails.accId + '&' + 'primaryRole=' + this.roleId + '&isAssigned=' + assingId;
    this.apisService.getApi(environment.openPositions + data).subscribe((res) => {
        this.requirementsData = res.data.records;
        this.loading = false;
        this.totalRecords = res.data.count;
        if (this.totalRecords === 0) {
          this.records = true;
          this.table = false;
        } else {
          this.records = false;
          this.table = true;
        }
      });
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridData();
  }
  searchData() {
    if (
      this.openPositionSearchForm.controls.requirementsStartDate.value === '' ||
      this.openPositionSearchForm.controls.requirementsStartDate.value === null ||
      this.openPositionSearchForm.controls.requirementsStartDate.value === undefined
    ) {
      this.requirementsStartDate = '';
    } else {
      const startdate = this.openPositionSearchForm.controls.requirementsStartDate.value;
      const reqstartDate = new Date(startdate);
      this.requirementsStartDate = this.datepipe.transform( reqstartDate, 'MM-dd-yyyy');
    }
    if (
      this.openPositionSearchForm.controls.requirementsEndDate.value === '' ||
      this.openPositionSearchForm.controls.requirementsEndDate.value === null ||
      this.openPositionSearchForm.controls.requirementsEndDate.value === undefined
    ) {
      this.requirementsEndDate = '';
    } else {
      const enddate = this.openPositionSearchForm.controls.requirementsEndDate.value;
      const reqendDate = new Date(enddate);
      this.requirementsEndDate = this.datepipe.transform(
        reqendDate,
        'MM-dd-yyyy'
      );
    }
    const custNmae = this.openPositionSearchForm.controls.requirementsCustomerName.value;
    const pgFlag = this.page;
    const pgNo = this.rows;
    // tslint:disable-next-line:max-line-length
    const reqId = this.openPositionSearchForm.controls.requirementsJobId.value === null ? '' : this.openPositionSearchForm.controls.requirementsJobId.value;
    // tslint:disable-next-line:max-line-length
    const jobTitle = this.openPositionSearchForm.controls.requirementsJobTitle.value === null  ? ''  : this.openPositionSearchForm.controls.requirementsJobTitle.value;
    // tslint:disable-next-line:max-line-length
    const custName =  this.openPositionSearchForm.controls.requirementsCustomerName.value === null  ? '' : this.openPositionSearchForm.controls.requirementsCustomerName.value;
    const createBy = '';
    // tslint:disable-next-line:max-line-length
    const assignedCandidate = this.openPositionSearchForm.controls.requirementsassignCandidates.value === ''  ? '' : this.openPositionSearchForm.controls.requirementsassignCandidates.value ===  null ? '' : this.openPositionSearchForm.controls.requirementsassignCandidates.value === undefined ? '' : this.openPositionSearchForm.controls.requirementsassignCandidates.value.id;
    this.loader = true;
    // tslint:disable-next-line:max-line-length
    const assingId = this.openPositionSearchForm.controls.requirementsassignStatus.value === null ? 1  : this.openPositionSearchForm.controls.requirementsassignStatus.value === ''  ? 1 : this.openPositionSearchForm.controls.requirementsassignStatus.value === undefined ? 1 : this.openPositionSearchForm.controls.requirementsassignStatus.value.code;
    // tslint:disable-next-line:max-line-length
    const statusCheck =  '';
    // tslint:disable-next-line:max-line-length
    // tslint:disable-next-line:max-line-length
    const data = '/' +  pgFlag +  '/' + pgNo + '?reqId=' + reqId + '&createdBy=' + createBy + '&customerName=' +  custName + '&reqName=' + jobTitle + '&startDate=' +  this.requirementsStartDate + '&endDate=' + this.requirementsEndDate +  '&reqStatus=' +  statusCheck + '&isAssigned=' + assingId + '&' + 'primaryRole=' + this.roleId + '&recruiterId=' +  assignedCandidate;
    this.loading = true;
    this.apisService.getApi(environment.requirementGrid + data).subscribe((res) => {
        this.requirementsData = res.data.records;
        this.totalRecords = res.data.count;
        this.loading = false;
        if (this.totalRecords !== 0) {
          this.table = true;
          this.records = false;
        } else {
          this.table = false;
          this.records = true;
        }
      });
  }
  reqSkillSet() {
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.specializationSkillsData = res.data;
     });
  }
  getSkillSetTable(data) {
    this.array = data;
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.specializationSkillsData.length; i++) {
      // tslint:disable-next-line:prefer-for-of
   if (data === null || data === undefined || data === '' || data === isEmpty()){} else{
    // tslint:disable-next-line:prefer-for-of
    for (let j = 0; j < this.array.length; j++) {
      if (Number(this.specializationSkillsData[i].code) === this.array[j]) {
        selectedPrePrimarySkills3.push(
          this.specializationSkillsData[i].label
        );
      } else {
      }
    }
   }
    }
    return selectedPrePrimarySkills3;
  }
  getStatus() {
    this.apisService.getApi(environment.status).subscribe((res) => {
      this.statusDD = res.data;
      this.statusDD.forEach(element => {
        this.getStatusData[element.code] = element.label;
      });
    });
  }
  reqSkills1(data) {
    this.display = true;
    this.reqSkillsValue = data;
  }
  getAssign() {
    this.apisService.getApi(environment.activerec + this.acctDetails.accId).subscribe((res) => {
        this.assignedList = res.data;
      });
  }
  getAssignedList(event, type) {
    if (this.rowData.consName === null || this.rowData.consName === '  ') {
      this.recruiterForm.reset();
      type === 'menu'  ? (this.dialogForCandidates = true) : (this.dialogForCandidates = false);
      if (event.value.code === 0) {
        this.apisService.getApi(environment.updateStatus + '/' + this.acctDetails.accId).subscribe((res) => {
            this.assignedList = res.data;
          });
      }
    }
    else {
      Swal.fire({
        text: 'Requirement already assigned',
        icon: 'success',
        confirmButtonColor: '#0D416B',
        iconColor: '#1dbb99',
        confirmButtonText: 'Ok',
        reverseButtons: false,
      });
    }
  }
  requirementRoute(data) {
    this.name = 'requirementsSearch';
    this.gridStatus = data.reqStatus;
    this.reqID = data.reqId;
    // tslint:disable-next-line:no-string-literal
    data['from'] = 'open';
      // tslint:disable-next-line:no-string-literal
    data['name'] = 'requirementsSearch';
    sessionStorage.setItem('reqData', JSON.stringify(data));
    this.route.navigate(['/openpositionedit'], {
      queryParams: {
        submittedreqId: this.reqID,
        gStatus: this.gridStatus,
        name: this.name,
        reqId: data.reqId,
        tdate: data.plannedEndDate,
        from: 'open',
        rvId: data.rvId,
        viewed: data.isViewed,
        ownerId: data.ownerId
      },
    });
  }
  toggle(menu, event, rowData) {
    this.accountId = this.acctDetails.accId;
    this.rowData = rowData;
    this.apisService.getApi(environment.updateStatus + '/' + this.accountId).subscribe((res) => {
      res.data.forEach((x) => {
        const data = {
          rowId: x.id,
        };
      });
    });
    this.items = [
        {
          label: 'Assign',
          icon: 'fa fa-check-circle-o',
          // tslint:disable-next-line:no-shadowed-variable
          command: (event, i) => {
            const json = {
              value: { code: 1 },
            };
            this.getAssignedList(json, 'menu');
          },
        },
      ];
  }
  sweetAlertForTaxTerm() {
    const data = this.rowData;
    if (this.dialogForCandidates === true) {
      this.recruiterFormdata =
        this.recruiterForm.controls.recruiterControl.value;
      if (
        this.recruiterFormdata === undefined ||
        this.recruiterFormdata === null ||
        this.recruiterFormdata === ''
      ) {
        this.alertError = true;
        setTimeout(() => {
          this.alertError = false;
        }, 10000);

        return;
      }
    }
    if (data.consName !== null ) {
      Swal.fire({
        text: 'Requirement already assigned',
        icon: 'success',
        confirmButtonColor: '#0D416B',
        iconColor: '#1dbb99',
        confirmButtonText: 'Ok',
        reverseButtons: false,
      });
    } else {
      this.rowId = data.reqId;
      this.TaxTermDailog = false;
      this.dialogForCandidates = false;
      Swal.fire({
        text: 'Are you sure want to assign?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0D416B',
        cancelButtonColor: '#D0D0D0',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: false,
      }).then((result) => {
        if (result.value) {
         return this.updateStatus();
        }
      });
    }
  }
  updateStatus() {
    // tslint:disable-next-line:max-line-length
    const id = this.recruiterForm.controls.recruiterControl.value.id === undefined ? '' : this.recruiterForm.controls.recruiterControl.value.id === null ? '' : this.recruiterForm.controls.recruiterControl.value.id === '' ? '' : this.recruiterForm.controls.recruiterControl.value.id;
    this.apisService.getApi(environment.rmUpdateStatus + this.rowId + '/' + id).subscribe((res) => {

        if (res.hasOwnProperty('success') === true) {
          this.dialogForCandidates = false;
          this.gridData();
          this.messageService.add({  key: 'br',  severity: '', life: 3000, detail: res.message });
        } else if (res.hasOwnProperty('success') === false) {
          this.dialogForCandidates = false;
          this.messageService.add({  key: 'br',  severity: '', life: 3000, detail: res.message });
        } else {
          this.dialogForCandidates = false;
          this.messageService.add({ key: 'br', severity: '',   life: 3000,  detail: res.error });
        }
      });
  }
  downLoadsToggle() {
    this.downloadOptions = [
      { label: 'Download XLSX', icon: 'pi pi-download', command: (event, i) => { this.exportExcel(); }, },
      { label: 'Download PDF', icon: 'pi pi-download', command: (event, i) => {  this.exportPdf();  }, },
    ];
  }
  addVendor() {
    const data = this.rowData;
    sessionStorage.setItem('reqData', JSON.stringify(this.rowData));
    const addroute = true;
    this.route.navigate(['/addvendorrequirement'], {
      queryParams: {
        requirementId: true,
        reqId: data.reqId,
        from: 'open',
      },
    });
  }
  reset() {
    this.openPositionSearchForm.reset();
    this.openPositionSearchForm.controls.requirementsassignStatus.patchValue({
      code: 0,
      label: 'Not assigned',
    });
    this.gridData();
  }
  exportExcel() {
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    // tslint:disable-next-line:max-line-length
    ws['!cols'] = [ {width : 10}, {width : 20}  , {width : 30} , {width : 30}, {width : 20}, {width : 20}, {width : 20}, {width : 20}, {width : 20}, {width : 20}, {hidden : true} ];
    ws['!rows'] = [{hpt : 30}, {hpt : 30}, ];
    ws['!cols'][3] = {hidden: true};
    ws['!cols'][10] = {hidden: true};
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ExcelSheet.xlsx');
  }
exportPdf() {
// tslint:disable-next-line:no-shadowed-variable
this.requirementsData.forEach((element) => {
  element.status = this.getStatusData[element.reqStatus];
  element.skills = this.getSkillSetTable(element.requiredSkills);
});
const doc = new jsPDF('p', 'pt');
// tslint:disable-next-line:prefer-const
let element = document.getElementById('my-table');
autoTable(doc, {
  headStyles: { fillColor: '#2bb9d5',  cellPadding: 1, cellWidth: 64},
      margin: { left: 10},
  columns: this.cols3,
  body: this.requirementsData,
  didDrawPage: (dataArg) => {
    doc.text('Open Positions', dataArg.settings.margin.left, 30);
  }
});
doc.save('Open Positions.pdf');
}
}

