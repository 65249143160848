<p-toast position="bottom-right" key="br"></p-toast>
<form [formGroup]="licenseForm">
    <p-card>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Name</label>
                <input id="input" type="text" pInputText placeholder="Name" autocomplete="off" formControlName="title"
                    maxlength="30">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Issued Date</label>
                <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="issuedate" placeholder="MM-DD-YYYY"
                    [showIcon]="true" [showButtonBar]="true">
                </p-calendar>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Expired Date</label>
                <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="expdate" placeholder="MM-DD-YYYY"
                    [showIcon]="true" [showButtonBar]="true">
                </p-calendar>
            </div>

        </div>
        <br>
        <div class="btnsCSS1">
            <button pButton type="button" (click)="toggleUploadView()"
                [icon]="showUploadView ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" label="Import"
                class="addhBtn"></button>
            <button pButton (click)="addlocation()" type="button" icon='pi pi-plus' label="Add"
                class="addhBtn"></button>
            <button pButton (click)="reset()" type="button" icon='pi pi-refresh' label="Reset" class="addhBtn"></button>
            <button pButton (click)="searchData()" type="button" icon='pi pi-search' label="Search"
                class="addhBtn"></button>
        </div><br><br>
        <div *ngIf="isUploading" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
        <br>
        <div *ngIf="showUploadView" class="dropzone p-col-12 p-md-12 p-sm-12 p-lg-3">
            <em class="pi pi-upload Iconsize" aria-hidden="true"></em>
            <input accept=".xlsm,.xlsx,application/msexcel" type="file" class="fileInput" #fileDropRef id="fileDropRef"
                multiple (change)="onUpload($event.target.files)" style="cursor: pointer;"/>
            <h3>Drag and drop file here</h3>
            <h3 class="importstylin">or</h3>
            <label id="fileUpload" for="fileDropRef">Browse for files</label>
        </div>
    </p-card>
</form>
<br>
<p-card class="pgpos">
    <div *ngIf="isUploading1" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <p-progressBar mode="indeterminate"></p-progressBar>
    </div>
    <div *ngIf="table">
        <p-table [columns]="cols" [value]="searchdata" selectionMode="single" dataKey="edit" [rows]="10"
            [responsive]="true" [rowsPerPageOptions]="[10,20,30]" sortMode="multiple">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i=index"
                        (click)="iconToggle[i] = !iconToggle[i]" [ngStyle]="{'width': col.width }"
                        [pSortableColumn]="col.field"> {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                <tr>
                    <td class="textCenter skill-desc" pTooltip="{{rowData.title}}" tooltipPosition="top"> <span class="p-column-title"> Name</span>{{rowData.title}}</td>
                    <td class="textCenter skill-desc" pTooltip="{{rowData.organization}}" tooltipPosition="top" ><span class="p-column-title"> Organization</span>{{rowData.organization}}
                    </td>
                    <td class="textCenter"><span class="p-column-title"> Issue Date</span>{{rowData.issuedDate === null
                        ||
                        rowData.issuedDate === ''?'--':rowData.issuedDate | date:'MM-dd-yyyy'}}</td>
                    <td class="textCenter"> <span class="p-column-title"> Expired Date</span>{{rowData.expiryDate ==
                        null || rowData.expiryDate === ''?'--':rowData.expiryDate | date:'MM-dd-yyyy'}}</td>
                    <td class="textCenter"><span class="p-column-title"> Rank/Mark</span>{{rowData.rankMarks == 0 ? '' :
                        rowData.rankMarks}}</td>
                    <td class="textCenter"><span class="p-column-title"> Action</span> <a>
                            <em (click)="downloadActionPopup.toggle($event); toggle($event,'data', rowData)"
                                class="pi pi-ellipsis-v pointer"></em>
                        </a></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr *ngIf="certificateGrid?.length==0" class="aligncenter">
                    <td colspan="4" class="mandatoryColor">
                        No Records
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                <h3 class="norecorddata">Total records : {{totalRecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png"
                    class="norecordimg">
                <br><em>Sorry, we are not able to find any license details</em><br>
            </div>
        </div>
    </p-card>
    <p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="items"></p-menu>
</p-card>
<p-dialog [contentStyle]="{'overflow': 'visible'}" class="dialogBox" header="Add License" [(visible)]="showDialog1"
    [modal]="true" [style]="{width: '30%'}" [baseZIndex]="1" [draggable]="false" [resizable]="false">
    <form [formGroup]="licenseAddForm">
        <div>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12">
                    <label class="inputfields">Name<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText placeholder="Name" autocomplete="off"
                       maxlength="16" formControlName="Addtitle">
                    <span class="validationtest"
                        *ngIf="this.licenseAddForm.controls.Addtitle.touched && this.licenseAddForm.controls.Addtitle.invalid && this.licenseAddForm.controls.Addtitle.errors.required">
                        Please enter name</span>
                    <span class="validationtest"
                        *ngIf="this.licenseAddForm.get('Addtitle').invalid && this.licenseAddForm.get('Addtitle').errors?.pattern">
                        Please enter alphabets only</span>
                    <span class="validationtest" *ngIf="this.licenseAddForm.get('Addtitle').hasError('maxlength')">
                        Please enter 15 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12">
                    <label class="inputfields">Issuing Organization<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText placeholder="Issuing Organization" autocomplete="off"
                       maxlength="51" formControlName="organisation">
                    <span class="validationtest"
                        *ngIf="this.licenseAddForm.controls.organisation.touched && this.licenseAddForm.controls.organisation.invalid && this.licenseAddForm.controls.organisation.errors.required">
                        Please enter organization</span>
                    <span class="validationtest"
                        *ngIf="this.licenseAddForm.get('organisation').invalid && this.licenseAddForm.get('organisation').errors?.pattern">
                        Please enter alphabets only</span>
                    <span class="validationtest" *ngIf="this.licenseAddForm.get('organisation').hasError('maxlength')">
                        Please enter 50 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12">
                    <p-checkbox name="dateCheck" value="{show}" (onChange)="disableAddCheckboxValue($event)" binay=true
                        label="This certificate does not expire" formControlName="dateCheck">
                    </p-checkbox>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Issue Date</label>
                    <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="addIssuedate" placeholder="MM-DD-YYYY"
                        [showIcon]="true" [showButtonBar]="true">
                    </p-calendar>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6" *ngIf="showExPiredDate">
                    <label class="inputfields">Expired Date</label>
                    <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="addExpdate" placeholder="MM-DD-YYYY"
                        [showIcon]="true" [showButtonBar]="true">
                    </p-calendar>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6" *ngIf="show">
                    <label class="inputfields">Expired Date</label>
                    <input id="input" type="text" pInputText placeholder="No expiration date" readonly="true"
                        (onChange)="disableCheckboxValue($event)" autocomplete="off" maxlength="30">
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 ui-fluid ">
                    <label class="inputfields">Rank/Mark</label>
                    <input id="input" type="text" pInputText placeholder="Rank/Mark" autocomplete="off"
                        formControlName="addrank" maxlength="10">
                    <span class="validationtest" *ngIf="this.licenseAddForm.get('addrank').hasError('maxlength')">
                        Please enter 9 numbers only
                    </span>
                    <span class="validationtest"
                        *ngIf="this.licenseAddForm.controls.addrank.invalid && this.licenseAddForm.controls.addrank.errors.pattern">Please enter
                        numbers only</span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 ui-fluid ">
                    <label class="inputfields">Description</label>
                    <textarea autocomplete="off" [rows]="3" [cols]="30" placeholder="Description" pInputTextarea
                        formControlName="addDes" maxlength="201"></textarea>
                    <span class="validationtest" *ngIf="this.licenseAddForm.get('addDes').hasError('maxlength')">
                        Please enter 200 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <label class="inputfields">Add Attachment</label><span class="mandatoryColor">*</span><br>
                    <input *ngIf="attachmentfile" type="file" id="attachment" accept=".doc,.docx,application/msword,application/pdf" 
                        (change)="handleFileInput($event)" formControlName='resume'>
                        <input type="file" id="attachment" accept=".doc,.docx,application/msword,application/pdf" *ngIf="profileupload"
                        (change)="handleFileInput($event)" formControlName='resume'>
                        <em *ngIf="filesdatass" class="pi pi-times" style="cursor: pointer;" (click)="cancelfiles()"></em>
                    <div class="validationtest"
                        *ngIf="this.licenseAddForm.get('resume').touched && this.licenseAddForm.get('resume').invalid && this.licenseAddForm.get('resume').errors.required">
                         Please upload attachment
                </div>
                </div>
            </div>
            <div class="btnsCSS1 rightalign">
                <button class="addhBtn" pButton type="submit" [disabled]="disable" icon='pi pi-save' label="Save"
                    (click)="uploadAttachment()"></button>
            </div><br>
        </div>
    </form>
</p-dialog>
<p-dialog class="dialogBox" header="Edit License" [(visible)]="showDialog" [modal]="true" [style]="{width: '30%'}"
    [baseZIndex]="1" [draggable]="false" [resizable]="false" [contentStyle]="{'overflow': 'visible'}">
    <form [formGroup]="licenseEditForm">
        <div>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 ui-fluid ">
                    <label class="inputfields">Name<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText placeholder="Name" autocomplete="off"
                      maxlength="16"  formControlName="title">
                    <span class="validationtest"
                        *ngIf="this.licenseEditForm.controls.title.touched && this.licenseEditForm.controls.title.invalid && this.licenseEditForm.controls.title.errors.required">
                        Please enter name</span>
                    <span class="validationtest"
                        *ngIf="this.licenseEditForm.get('title').invalid && this.licenseEditForm.get('title').errors?.pattern">
                        Please enter alphabets only</span>
                    <span class="validationtest" *ngIf="this.licenseEditForm.get('title').hasError('maxlength')">
                        Please enter 15 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-fluid ">
                    <label class="inputfields">Issuing Organization<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText placeholder="Issuing Organization" autocomplete="off"
                      maxlength="51"  formControlName="editOrganisation">
                    <span class="validationtest"
                        *ngIf="this.licenseEditForm.controls.editOrganisation.touched && this.licenseEditForm.controls.editOrganisation.invalid && this.licenseEditForm.controls.editOrganisation.errors.required">
                        Please enter organization</span>
                    <span class="validationtest"
                        *ngIf="this.licenseEditForm.get('editOrganisation').invalid && this.licenseEditForm.get('editOrganisation').errors?.pattern">
                        Please enter alphabets only</span>
                    <span class="validationtest"
                        *ngIf="this.licenseEditForm.get('editOrganisation').hasError('maxlength')">
                        Please enter 50 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-fluid ">
                    <p-checkbox name="groupname" formControlName="dateCheck"
                        (onChange)="disableEditCheckboxValue($event)" binary="true"
                        label="This license does not expire">
                    </p-checkbox>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Issue Date</label>
                    <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="editissuedate"
                        placeholder="MM-DD-YYYY" [showIcon]="true" [showButtonBar]="true">
                    </p-calendar>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6" *ngIf="!showEdit">
                    <label class="inputfields">Expired Date</label>
                    <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="editexpdate" placeholder="MM-DD-YYYY"
                        [showIcon]="true" [showButtonBar]="true">
                    </p-calendar>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6" *ngIf="showEdit">
                    <label class="inputfields">Expired Date</label>
                    <input id="input" type="text" pInputText placeholder="No expiration date" readonly="true"
                        disabled="true" (onChange)="disableEditboxValue($event)" autocomplete="off" maxlength="30">
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-fluid ">
                    <label class="inputfields">Rank/Mark</label>
                    <input id="input" type="text" pInputText placeholder="Rank/Mark" autocomplete="off"
                        formControlName="editrank" maxlength="10">
                    <span class="validationtest" *ngIf="this.licenseEditForm.get('editrank').hasError('maxlength')">
                        Please enter 9 numbers only
                    </span>
                    <span class="validationtest"
                        *ngIf="this.licenseEditForm.controls.editrank.invalid && this.licenseEditForm.controls.editrank.errors.pattern">Please enter
                        numbers only</span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-fluid ">
                    <label class="inputfields">Description</label>
                    <textarea autocomplete="off" [rows]="3" [cols]="30" placeholder="Description" pInputTextarea
                        formControlName="comments" maxlength="201"></textarea>
                    <span class="validationtest" *ngIf="this.licenseEditForm.get('comments').hasError('maxlength')">
                        Please enter 200 alphabets only
                    </span>
                </div>
            </div><br>
            <div class="btnsCSS1 urightalign">
                <button class="addhBtn" pButton type="submit" [disabled]="disable" icon='pi pi-save' label="Update"
                    (click)="submitAction()"></button>
            </div><br><br>
        </div>
    </form>
</p-dialog>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>