<p-card>
	<p-panel><p-header>
		<strong >Sales Info : </strong><a class="headstyle">{{this.names}}</a>
	</p-header>
			<form [formGroup]="salesinfoForm">
				<div class="p-fluid p-grid">
					<div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
						<label class="inputfields">Name</label>
						<input id="input" type="text" pInputText placeholder="Name" (keyup.enter)="search()"
							formControlName="name" maxlength="50" autocomplete="off">
					</div>
					<div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
						<label class="inputfields">Email</label>
						<input id="input" type="text" pInputText placeholder="Email" (keyup.enter)="search()"
							formControlName="mail" maxlength="60" autocomplete="off">
					</div>
				</div>
				<div class="btnsCSS1 btnalign">
					<button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()"
						class="addhBtn"></button>
					<button pButton type="button" icon='pi pi-search' label="Search" (click)="search()"
						class="addhBtn"></button>
				</div> 
			</form>
		</p-panel>
</p-card>
<br>
<p-card class="pgpos">
	<div *ngIf="table">
		<p-table [value]="gridres" [columns]="cols" selectionMode="single" dataKey="edit" [responsive]="true"
		[autoLayout]="true"	sortMode="multiple">
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
						[ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
						<span>
							{{col.header}}

						</span>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-data>
				<tr>
					<td class="textCenter"><span class="p-column-title">Name</span>{{data.names}}</td>
					<td class="textCenter" (click)="emailToast()" (click)="copyInputMessage(data.email)"><span class="p-column-title">
						Email</span><a style="cursor: pointer;">{{data.email}}</a></td>
					<td class="textCenter"><span class="p-column-title">Office Number</span>{{data.officePhone}}</td>
					<td class="textCenter"><span class="p-column-title">Action</span><a><em
								(click)="editActionPopup.toggle($event); toggle($event,data, rowData)"
								class="pi pi-ellipsis-v pointer"></em> </a></td>
				</tr>
			</ng-template>
		</p-table>
		<div class="p-grid ui-fluid">
			<div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
				<h3 class="norecorddata">Total records : {{this.totalrecods}}</h3>
			</div>
			<div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
				<p-paginator [rows]="10" [totalRecords]="totalrecods" [rowsPerPageOptions]="[10,20,30]"
					(onPageChange)="paginate($event)"></p-paginator>
			</div>
		</div>
		<ng-template pTemplate="emptymessage">
			<tr class="aligncenter">
				<td colspan="6" class="mandatoryColor">No Records</td>
			</tr>
		</ng-template>
	</div>
	<p-card *ngIf="records">
		<div class="norecordImgsize">
			<div *ngIf="records" class="aligncenter">
				<img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
				<br><em>Sorry, we are not able to find any sales info</em><br>
			</div>
		</div>
	</p-card>
</p-card>
<div *ngIf="!loading; else showLoader"></div>
<ng-template #showLoader>
	<div class="loading-bar">
		<div>
			<div class="image-holder" class="col-sm-12 text-center laoderheight " >
                <div class="laodersize" >
					<img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif" class="loaderimg-height">
				</div>
			</div>
		</div>
	</div>
</ng-template>
<p-toast position="bottom-right" key="br"></p-toast>