<div class="layout-topbar">
  <a class="layout-topbar-logo">
    <img id="layout-topbar-logo" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Servicesbay Logo SVG.svg" alt="" />
  </a>
  <a class="layout-menu-button" (click)="appMain.onMenuButtonClick($event)">
    <em class="pi pi-bars" style="cursor: pointer;"></em>
  </a>
  <a class="layout-menu-button" style="position: absolute; width: 90%;">
    <strong style="font-size: x-large"> {{ acctname }}</strong>
  </a>
  <a id="topbar-menu-button" (click)="appMain.onTopbarMenuButtonClick($event)">
    <em class="pi pi-ellipsis-v"></em>
  </a>
  <ul class="topbar-menu fadeInDown" [ngClass]="{ 'topbar-menu-visible': appMain.topbarMenuActive }">
    <li #notifications *ngIf="app.profileMode === 'popup' || appMain.isHorizontal()"
      [ngClass]="{ 'active-topmenuitem': appMain.activeTopbarItem === notifications }">
      <a (click)="appMain.onTopbarItemClick($event, notifications)">
        <img id="avatar" alt="" [src]="(this.topbarimage?this.topbarimage:this.profileimage)">
        <span class="topbar-item-name">Arlene Welch</span>
      </a>
      <ul class="fadeInDown" style="cursor: pointer;">
        <li role="menuitem">
          <a (click)="ViewProfile()">
            <em style="padding-right: 4%;" class="pi pi-user"></em>
            <span>Profile</span>
          </a>
        </li>
        <li role="menuitem">
          <a (click)="changePassword()">
            <em style="padding-right: 4%;" class="pi pi-key"></em>
            <span>Change Password</span>
          </a>
        </li>
        <li role="menuitem">
          <a (click)="userPrefernces()">
            <em style="padding-right: 4%;" class="fa fa-cogs"></em>
            <span>Change Preferences</span>
          </a>
        </li>
        <li role="menuitem">
          <a>
            <em style="padding-right: 4%;" class="fa fa-users"></em>
            <span>Roles</span>

          </a>
        </li>
        <p *ngFor="let data of usrroles;let i=index " style="margin-left: 15%;">
          <p-radioButton [inputId]="data.id" name="category" [value]="data.id" [(ngModel)]="selectedRole"
            (click)="rolechange(data);getrole(data)">
          </p-radioButton>&nbsp;&nbsp;&nbsp;<label style="color: #727272;">{{data.name}}</label>
        </p>
        <li role="menuitem">
          <a (click)="logout()">
            <em style="padding-right: 4%;" class="pi pi-sign-out"></em>
            <span>Sign Out</span>
          </a>
        </li>
      </ul>
    </li>
    <li #profile class="user-profile" *ngIf="app.profileMode === 'popup' || appMain.isHorizontal()"
      [ngClass]="{ 'active-topmenuitem': appMain.activeTopbarItem === profile }">
      <div>
        <p style="margin-top: 2%; margin-bottom: 0em; color: #0D416B; font-weight: 600; text-align: center"
          (click)="appMain.onTopbarItemClick($event, profile)">{{ fullName }}</p>
        <p style="margin-top: 3px; color: #0D416B; text-align: center; border-radius: 7px; box-shadow: 0px 0px 3px 3px #ffffff00"
          (click)="appMain.onTopbarItemClick($event, profile)">
          &nbsp;&nbsp;{{ loginRoleName }}
        </p>
      </div>
    </li>
  </ul>
</div>