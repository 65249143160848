import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-user-history',
  templateUrl: './user-history.component.html',
  styleUrls: ['./user-history.component.scss'],
  providers: [DatePipe, MessageService],
})
export class UserHistoryComponent implements OnInit {
  loginData: any;
  products: any;
  loader: boolean;
  table: boolean;
  records: boolean;
  constructor(private breadcrumbService: BreadcrumbService, private apisService: ApisService, ) {
    this.breadcrumbService.setItems([
      { label: 'User History', routerLink: '/user-history' }
    ]);
    this.loginData = JSON.parse(this.apisService.decodeData());
   }
  ngOnInit(): void {
    this.projectHistory();
  }
projectHistory(){
  this.loader = true;
  this.apisService.getApi(environment.userList + this.loginData.userId).subscribe(res => {
    this.loader = false;
    this.products = res.data;
  });
}
}
