<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
  <form [formGroup]="openPositionSearchForm">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ui-field">
        <label class="inputfields">Job Id</label>
        <input id="first" type="text" maxlength="5" pKeyFilter="int" formControlName="requirementsJobId"
          autocomplete="off" pInputText placeholder="Job Id">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Customer Name</label>
        <p-dropdown placeholder="Select Customer Name" [showClear]="true" (keyup.enter)="searchData()"
          formControlName="requirementsCustomerName" filter="true" [options]="accountnamesres"
          optionLabel="name">
        </p-dropdown>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Position Title</label>
        <input id="input" type="text" maxlength=30 formControlName="requirementsJobTitle" pInputText
          placeholder="Position Title" autocomplete="off">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Assign Status</label>
        <p-dropdown [options]="assignstatusDD" formControlName="requirementsassignStatus" placeholder="Select Status"
          [showClear]="true" optionLabel="label" (onChange)="getAssignedList($event, 'field')">

        </p-dropdown>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Start Date </label>
        <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="requirementsStartDate" placeholder="MM-DD-YYYY"
          [showIcon]="true" [showButtonBar]="true">
        </p-calendar>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">End Date </label>
        <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="requirementsEndDate" placeholder="MM-DD-YYYY"
          [showIcon]="true" [showButtonBar]="true">
        </p-calendar>
      </div>
    </div>
    <div class="btnsCSS1 btnalignment">
      <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset($event)" class="addhBtn"></button>
      <button pButton type="submit" icon='pi pi-search' label="Search" (click)="searchData()" class="addhBtn"></button>
    </div>
  </form>
</p-card>
<br>
<p-card class="pgpos">
  <div *ngIf="table">
    <p-table [columns]="cols" [value]="gridData" selectionMode="single" dataKey="edit" [responsive]="true"
      id="excel-table" sortMode="multiple" [autoLayout]="true">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th  id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
            [ngStyle]="{'width': col.width }" [hidden]="col.sno == 5" [pSortableColumn]="col.field">
            {{col.header}}

          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr class="pointer">
          <td class="textCenter" (click)="requirementRoute(data)">{{data.reqId}}</td>
          <td class="textCenter" pTooltip="{{data.accName}}" tooltipPosition="top" (click)="requirementRoute(data)">
            {{data.accName}}
          </td>
          <td class="textCenter" pTooltip="{{data.reqName}}" tooltipPosition="top" (click)="requirementRoute(data)">
            {{data.reqName}}</td>
          <td class="textCenter skill-desc">
            <a (click)="op.toggle($event)" (click)="reqSkills1(getSkillSetTable(data.requiredSkills))"
              *ngFor="let data1 of getSkillSetTable(data.requiredSkills); index as i;">
              <p-badge class="badgespace newsty" *ngIf='i<1' [value]="data1"></p-badge>
              <p-badge *ngIf='i===1' [value]="'+'+(data.requiredSkills.length-1)+' more'"></p-badge>
            </a>
          </td>
          <td class="textCenter skill-desc" [hidden]="true">
            <a (click)="op.toggle($event)" (click)="reqSkills1(getSkillSetTable(data.requiredSkills))"
            *ngFor="let data1 of getSkillSetTable(data.requiredSkills); index as i;">   
            <p-badge  [value]="data1+','"></p-badge>
            </a>
          </td>
          <td class="textCenter" (click)="requirementRoute(data)">{{data.postedDate }}</td>
          <td class="textCenter" (click)="requirementRoute(data)">{{data.plannedEndDate}}</td>
          <td class="textCenter" (click)="reqEditRoute(data)"><span class="ui-column-title"></span>
            <p-badge [value]="getStatusData[data.reqStatus]"
              [ngClass]="data.reqStatus ==='C'?'blue': data.reqStatus === 'R' ?'green':data.reqStatus === 'J'? 'red':data.reqStatus === 'P'?'evalution':'green'">
            </p-badge>
          </td>
          <td class="aligncenter" (click)="requirementRoute(data)">{{data.submissions}}</td>
          <td class="textCenter"><a>

              <em (click)="actionpopup.toggle($event); toggle1(items, $event, data)" class="pi pi-ellipsis-v"></em>
            </a></td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-grid ui-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 totalrec">
        <h3 class="norecorddata ">Total records : {{this.totalRecords}}</h3>
        <p class="downpos">
          <span class="ui-column-title pointer" (click)="downloadActionPopup.toggle($event); downLoadsToggle()"
            (keyup)="exportExcel()">
            <em class="pi pi-download downloadspac"></em>
            <strong>Download</strong></span>
        </p>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
        <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
          (onPageChange)="paginate($event)" #p></p-paginator>
      </div>
    </div>


  </div>
  <p-card *ngIf="records">
    <div class="norecordImgsize">
      <div *ngIf="records" class="aligncenter">
        <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
        <br><em>Sorry, we are not able to find any open positions</em><br>
      </div>
    </div>
  </p-card>

  <p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="downloadOptions"></p-menu>
</p-card>

<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '25%' }">
  <label class="inputfields">Skill Details</label>
  <ng-template pTemplate>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-12 bordersty">

        <p class="skpos">

          <p-badge class="badgespace badgesp" *ngFor="let data2 of reqSkillsValue; index as i" [value]="data2"
            [ngClass]="i%2===0? 'newsty' : ''">
          </p-badge>


        </p>

      </div>
    </div>

  </ng-template>
</p-overlayPanel>

<!---Dialog box  for Candidate Details---->
<p-dialog class="dialogBox" header="Available Recruiters" [(visible)]="dialogForCandidates"
  [style]="{width: '30vw', height: '20vw'}" [draggable]="false" [modal]="true" [resizable]="false">
  <br>
  <label *ngIf="alertError" class="mandatoryColor posi">Please select Recruiter</label>
  <div class="p-grid ui-fluid">
    <div class="p-col-12 p-md-12 p-sm-12 ui-fluid aligncenter">
      <form [formGroup]="recruiterForm">
        <p-dropdown [options]="assignedList" filter="true" formControlName="recruiterControl"
          placeholder="Select Available Recruiters" optionLabel="name" [showClear]="true">
        </p-dropdown>
      </form>
    </div>
  </div>
  <br>
  <br>
  <div class="p-grid ui-fluid">
    <div class="p-col-12 p-md-12 p-sm-12 ui-fluid aligncenter">
      <button pButton type="button" icon='pi pi-check' label="Assign" (click)="sweetAlertForTaxTerm()"></button><br><br>
    </div>
  </div>
</p-dialog>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
<div *ngIf="!loading; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center laoderheight ">
        <div class="laodersize">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
            class="loaderimg-height">
        </div>
      </div>
    </div>
  </div>
</ng-template>