import { Component, OnInit, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ApisService } from 'src/app/services/apis.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-job-title',
  templateUrl: './job-title.component.html',
  styleUrls: ['./job-title.component.scss'],
  providers: [DatePipe, MessageService],
})
export class JobTitleComponent implements OnInit {

  titleForm: FormGroup;
  titleAddForm: FormGroup;
  titleUpdateForm: FormGroup;
  showUploadView: boolean;
  isUploading: boolean;
  displayModal: boolean;
  displayModal1: boolean;
  accId: any;
  rows: any = 10;
  page: any = 1;
  // It is used for displaying status
  status = [
    { name: 'Active', id: 'A' },
    { name: 'Inactive', id: 'I' },
  ];
  loader: boolean;
  gridData: any;
  totalRecords: any;
  records: boolean;
  table: boolean;
  hide: boolean;
  id: any;
  jobTitle: any;

  getData: any;
  updbtn: boolean;
  savbtn: boolean;
  fileOver: boolean;
  maxRateId: any;
  rowData: any;
  items: { label: string; icon: string; command: () => void; }[];
  acctdetails: any;
  currencycode: any[];
  preferencList: any = [];
  currencyValue: any;
  currencysymbl: any;
  statuslist = {};
  items1: { label: string; icon: string; command: () => void; }[];
  first: any;
  pageCount: any;
  buttonreset: boolean;
  downloadData: any;
  // tslint:disable-next-line:max-line-length
  constructor(private breadcrumbService: BreadcrumbService, private fb: FormBuilder, private messageService: MessageService, private apisService: ApisService) {
    this.breadcrumbService.setItems([
      { label: 'Job Titles', routerLink: '/jobtitles' },
    ]);
    this.items1 = [
      {label: 'Download Sample', icon: 'pi pi-download', command: () => { this.downloadFileapi();
      }},
      {
        label: 'Import Data', icon: 'pi pi-chevron-down', command: () => {
          this.toggleUploadView();
        }
      }];
  }
    // It is used for displaying table header data
  cols = [
    { field: 'jobCategory', header: 'Job Category', width: '15%' },
    { field: 'title', header: 'Title', width: '20%' },
    { field: 'minRate', header: 'Min Rate', width: '' },
    { field: 'maxRate', header: 'Max Rate', width: '' },
    { field: 'maxRate', header: 'Status', width: '' },
    { field: 'description', header: 'Description', width: '20%' },
    { field: '', header: 'Action', width: '7%' },
  ];
  ngOnInit(): void {
    this.acctdetails = JSON.parse(this.apisService.decodeData());
    this.preferencList = this.acctdetails.preferencesList;
    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }
    this.titleForm = this.fb.group({
      title: [''],
      min: [''],
      max: [''],
      jobTitle: ['']
    });
    this.gridTable();
    this.gridstatus();
    this.jobCategoryDropD();
    const userdetails = JSON.parse(this.apisService.decodeData());
    this.accId = userdetails.accId;
    setTimeout(() => {
      this.isUploading = false;
    }, 2000);
    this.titleAddForm = this.fb.group({
      addTitle: ['', [Validators.required, Validators.maxLength(30), Validators.pattern('^[\\sa-zA-z]*$')]],
      addMax: ['', [Validators.required]],
      addMin: ['', [Validators.required]],
      addDes: ['', Validators.maxLength(255)],
      addjobTitle: ['', [Validators.required]]
    });
    this.titleUpdateForm = this.fb.group({
      editTitle: ['', [Validators.required, Validators.maxLength(30), Validators.pattern('^[\\sa-zA-z]*$')]],
      editMax: ['', [Validators.required]],
      editMin: ['', [Validators.required]],
      editDes: ['', Validators.maxLength(255)],
      editjobTitle: ['', [Validators.required]],
      editStatus: ['', [Validators.required]]
    });
  }
  toggleUploadView() {
    this.showUploadView = !this.showUploadView;
  }
   // It is used to show add job title pop up
  addDepartment() {
    this.displayModal = true;
    this.titleAddForm.reset();
  }
 // It is used to reset search fields
  reset() {
    this.titleForm.reset();
    this.records = false;
    this.gridTable();
  }
  // It is used to display job category dropdown value
  jobCategoryDropD() {
    this.apisService.getApi(environment.jobCategoryDD + '/' + this.page + '/' + this.rows).subscribe((res: any) => {
      this.jobTitle = res.data;
    });
  }
   // It is used to get job title details and to bind
  updateData(data) {
    this.titleUpdateForm.reset();
    this.hide = true;
    this.id = data.id;
    this.displayModal1 = true;
    this.titleUpdateForm.controls.editMax.patchValue(data.maxRate);
    this.titleUpdateForm.controls.editMin.patchValue(data.minRate);
    this.titleUpdateForm.controls.editDes.patchValue(data.description);
    this.titleUpdateForm.controls.editTitle.patchValue(data.title);
    this.titleUpdateForm.controls.editjobTitle.patchValue(this.jobTitle.filter((x) => x.id === data.jobCatId)[0]);
    this.titleUpdateForm.controls.editStatus.patchValue(this.status.filter((x) => x.id === data.status)[0]);
  }
  // It is used to close edit pop up
  editCancel() {
    this.displayModal1 = false;
    this.titleUpdateForm.reset();
  }
  // It is used to close add pop up
  addCancel() {
    this.displayModal = false;
    this.titleAddForm.reset();
  }
  // It used to change minrate based on job category dropdown value
  getMinrate(data) {
    this.maxRateId = data.id;
    this.titleAddForm.get('addMax').patchValue(data.maxRate);
    this.titleAddForm.get('addMin').patchValue(data.minRate);
  }
  // It is used to add job title
  SaveDailog1() {
    this.hide = true;
    if (this.titleAddForm.invalid) {
      const controls = this.titleAddForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    } else {
      const data = {
        description: this.titleAddForm.controls.addDes.value,
        categoryId: this.titleAddForm.controls.addjobTitle.value === null ? -1 : this.titleAddForm.controls.addjobTitle.value.id,
        maxRate: +this.titleAddForm.controls.addMax.value,
        minRate: +this.titleAddForm.controls.addMin.value,
        title: this.titleAddForm.controls.addTitle.value,
      };
      this.savbtn = true;
      this.apisService.postApi(environment.addTitle, data).subscribe((res: any) => {
        this.getData = res;
        this.hide = false;
        this.savbtn = false;
        this.displayModal = false;
        this.gridTable();
        if (this.getData.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message  });
          this.gridTable();
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br',  severity: '', life: 3000, detail: this.getData.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000,  detail: this.getData.error  });
        }
      });
    }
  }
  // It is used to update job title
  editSave() {
    this.hide = true;
    this.hide = true;
    if (this.titleUpdateForm.invalid) {
      const controls = this.titleUpdateForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    } else {
      const valueid = '/' + this.id;
      const data = {
        description: this.titleUpdateForm.controls.editDes.value,
        categoryId: this.titleUpdateForm.controls.editjobTitle.value === null ? -1 : this.titleUpdateForm.controls.editjobTitle.value.id,
        maxRate: +this.titleUpdateForm.controls.editMax.value,
        minRate: +this.titleUpdateForm.controls.editMin.value,
        title: this.titleUpdateForm.controls.editTitle.value,
        status: this.titleUpdateForm.controls.editStatus.value.id,
      };
      this.updbtn = true;
      this.apisService.putApi(environment.updateTitle + valueid, data).subscribe((res: any) => {
        this.hide = false;
        this.getData = res;
        this.loader = false;
        this.gridTable();
        if (this.getData.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message });
          this.updbtn = false;
          this.displayModal1 = false;
          this.gridTable();
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.failed });
        } else {
          this.messageService.add({  key: 'br', severity: '', life: 3000, detail: this.getData.error });
        }
      });
    }
  }
  // It is used to show job title table data
  gridTable() {
    // tslint:disable-next-line:max-line-length
    const pgNo = this.page;
    const recordPage = this.rows;
    // tslint:disable-next-line:max-line-length
    const searchcategory = this.titleForm.controls.jobTitle.value === null || '' ? '' : this.titleForm.controls.jobTitle.value.id === undefined ? '' : this.titleForm.controls.jobTitle.value.id;
    const searchmin = this.titleForm.controls.min.value === null ? '' : this.titleForm.controls.min.value;
    const searchmax = this.titleForm.controls.max.value === null ? '' : this.titleForm.controls.max.value;
    const searchtitle = this.titleForm.controls.title.value === null ? '' : this.titleForm.controls.title.value;
    const search = 'categoryId=' + searchcategory + '&min=' + searchmin + '&max=' + searchmax + '&title=' + searchtitle;
    this.loader = true;
    this.apisService.getApi(environment.gettitleTable + '/' + pgNo + '/' + recordPage + '?' + 'venId=&' + search).subscribe((res: any) => {
      this.loader = false;
      this.gridData = res.data;
      this.totalRecords = res.count;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
      this.loader = false;
    });
  }
  // Drag over listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  // Drag leave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drop Listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.isUploading = true;
      this.onUpload(files);
    }
  }
// Window keydown listener
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.gridTable();
    }
  }
  // It is used to upload files
  onUpload(files) {
    this.isUploading = true;
    const formData: FormData = new FormData();
    formData.append('file', files[0]);
    this.apisService.postApi(environment.fileuploadJobTitle, formData).subscribe((res: any) => {
      if (res.success === true) {
        this.gridTable();
        this.messageService.add({
          key: 'br',
          severity: '',
          life: 3000,
          detail: res.message,
        });
        this.isUploading = false;
        this.showUploadView = false;
      }
        else {
        setTimeout(() => {
          this.isUploading = false;
        }, 2000);
        this.messageService.add({
          key: 'br',
          severity: '',
          life: 3000,
          detail: res.message,
        });
        this.gridTable();
      }
    });
  }
  // It is used for displaying server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridTable();
  }
  gridstatus() {
    // tslint:disable-next-line:no-unused-expression
    this.status.forEach(element => {
      this.statuslist[element.id] = [element.name];
    });
  }
  uploadedFiles(arg0: string, uploadedFiles: any) {
    throw new Error('Method not implemented.');
  }
  // It is used to display action pop up
  toggle(event, data, rowData) {
    this.rowData = rowData;
    this.items = [
      {
        label: ' Edit',
        icon: 'pi pi-pencil',
        command: () => {
          this.updateData(this.rowData);
        }
      },
    ];
  }
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
  downloadFileapi() {
    this.apisService.getApi(environment.downloadres + 4).subscribe((res: any) => {
      this.downloadData = res;
      window.location.assign(this.downloadData.downloadUrl);
    });
  }
}
