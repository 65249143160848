<div class="scroll">
    <p-toast position="bottom-right" key="br"></p-toast>
    <nav class="navbar navbar-expand-lg navbar-dark sticky-top nav">
        <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
            <a class="navbar-brand">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Servicesbay Logo SVG.svg"
                    class="innerimg">
            </a>
            <button class="pi pi-arrow-left pointer addhBtn" id="backToStyle" pButton type="button" style="width: auto;
      top: 14px;" label="Back
        to Registration" (click)="goto()"></button>
        </div>
    </nav><br>
    <form [formGroup]="consultantRegForm">
        <div class="p-grid p-justify-center">
            <div class="p-col-8 p-md-8 p-sm-12">
                <p-card class="cardCss">
                    <p-panel [toggleable]="false" [style]="{'margin-bottom':'20px'}">
                        <p-header>
                            <div>
                                <label>Consultant Registration</label>
                            </div>
                        </p-header>
                        <h5 class="panelLabel h3tag">Consultant Information</h5>
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                                <label class="inputfields">First Name<label class="mandatoryColor">*</label></label>
                                <input autocomplete="off" id="input" type="text" pInputText placeholder="First Name"
                                    maxlength="26" formControlName="firstName">
                                <span class="validationtest"
                                    *ngIf="consultantRegForm.get('firstName').touched && consultantRegForm.get('firstName').invalid && consultantRegForm.get('firstName').errors.required">
                                    Please enter first name</span>
                                <span class="validationtest"
                                    *ngIf="this.consultantRegForm.get('firstName').hasError('maxlength')">
                                    Please enter 25 alphabets only
                                </span>
                                <span class="validationtest"
                                    *ngIf="this.consultantRegForm.controls.firstName.invalid && this.consultantRegForm.controls.firstName.errors.pattern">Please enter alphabets only</span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                                <label class="inputfields">Last Name<label class="mandatoryColor">*</label></label>
                                <input autocomplete="off" id="input" type="text" pInputText placeholder="Last Name"
                                    maxlength="26" formControlName="lastName">
                                <span class="validationtest"
                                    *ngIf="consultantRegForm.get('lastName').touched && consultantRegForm.get('lastName').invalid && consultantRegForm.get('lastName').errors.required">
                                    Please enter last name</span>
                                <span class="validationtest"
                                    *ngIf="this.consultantRegForm.get('lastName').hasError('maxlength')">
                                    Please enter 25 alphabets only
                                </span>
                                <span class="validationtest"
                                *ngIf="this.consultantRegForm.controls.lastName.invalid && this.consultantRegForm.controls.lastName.errors.pattern">Please
                                enter alphabets only</span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory ">
                                <label class="inputfields">Mobile Number<label class="mandatoryColor">*</label></label>
                                <div class="p-col-12 p-md-12 p-sm-12 phone">
                                    <div class="p-inputgroup" class="p-col-4 p-md-4 ph-no">
                                        <span class="p-inputgroup-addon my-component">
                                            <p-dropdown [options]="countrycodedropdown" class="phonecodestyle newcode"
                                                (onChange)="countryids($event)" 
                                                optionLabel="label"  formControlName="mobileDrp">
                                                <ng-template let-items pTemplate="selectedItem">
                                                    <div class="flex-row">
                                                        <img class="lang-change-img" src={{items.flag}} alt="" />
                                                        <div class="lang-change-option-text selected-lang center">
                                                            {{items.code}}
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template let-country pTemplate="item">
                                                    <div class="flex-row" id="dropdown1">
                                                        <img class="lang-change-img" src={{country.flag}} alt="" />
                                                        <div class="lang-change-option-text center">{{country.label}}
                                                            {{country.code}}</div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </span>
                                    </div>
                                    <div class="spinner">
                                        <span class="p-input-icon-right"><em *ngIf="phSpinnerHide"
                                                class="pi pi-spin pi-spinner spinnerstyles"></em>
                                            <em *ngIf="phCheckHide" class="pi pi-check-circle tickmarkstyle"></em>
                                            <p-inputMask autocomplete="off" mask="(999) 999-9999"
                                                formControlName="mobileNumber" (onBlur)='checkPhone()'
                                                placeholder="Mobile Number">
                                            </p-inputMask>
                                        </span>
                                    </div>
                                </div>
                                <span class="validationtest"
                                    *ngIf="consultantRegForm.get('mobileNumber').touched && consultantRegForm.get('mobileNumber').invalid && consultantRegForm.get('mobileNumber').errors.required">
                                    Please enter mobile number</span>
                                <p class="mandatoryColor">{{this.officenummsg}}</p>
                                <span *ngIf="displayNumber" class="mandatoryColor">Mobile number already exist</span>
                                <span class="validationtest"
                                    *ngIf="this.consultantRegForm.get('mobileNumber').hasError('maxlength')">
                                    Please enter 15 alphabets only
                                </span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                                <label class="inputfields">Email<label class="mandatoryColor">*</label></label>
                                <span class="p-input-icon-right"><em *ngIf="mailSpinnerHide"
                                        class="pi pi-spin pi-spinner spinnerstyles"></em>
                                    <em *ngIf="mailCheckHide" class="pi pi-check-circle tickmarkstyle"></em>
                                    <input autocomplete="off" id="input" type="text" pInputText placeholder="Email"
                                        (blur)='checkEmail()' maxlength="61" formControlName="emailId">
                                </span>
                                <span class="validationtest"
                                    *ngIf="consultantRegForm.get('emailId').touched && consultantRegForm.get('emailId').invalid && consultantRegForm.get('emailId').errors.required">
                                    Please enter email</span>

                                <span class="validationtest"
                                    *ngIf="consultantRegForm.controls.emailId.invalid && consultantRegForm.controls.emailId.errors.pattern">
                                    Must be valid corp email
                                </span>
                                <span class="validationtest"
                                    *ngIf="this.consultantRegForm.get('emailId').hasError('maxlength')">
                                    Please enter 60 alphabets only
                                </span>
                                <p class="mandatoryColor">{{ofcMailValidation}}</p>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory ">
                                <label class="inputfields">Gender<label class="mandatoryColor">*</label></label>
                                <p-dropdown [options]="gender" formControlName="gender" placeholder="Select Gender"
                                    [showClear]="true" optionLabel="name">
                                </p-dropdown>
                                <span class="validationtest"
                                    *ngIf="consultantRegForm.get('gender').touched && consultantRegForm.get('gender').invalid && consultantRegForm.get('gender').errors.required">
                                    Please select gender</span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                                <label class="inputfields">Qualification</label>
                                <p-dropdown class="countryDD" placeholder="Select Qualification" [showClear]="true"
                                    [options]="qualificationList" optionLabel="label" formControlName="qualification">
                                </p-dropdown>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                                <label class="inputfields">Experience</label>
                                <p-dropdown class="countryDD" placeholder="Select Experience" [options]="exprienceList"
                                    [showClear]="true" optionLabel="label" formControlName="experience">
                                </p-dropdown>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory ">
                                <label class="inputfields">Country<span class="mandatoryColor">*</span></label>
                                <p-dropdown class="countryDD" [filter]='true' [options]="country"
                                    formControlName="country" [showClear]="true" placeholder="Select Country"
                                    optionLabel="label"></p-dropdown>
                                <span class="validationtest"
                                    *ngIf="consultantRegForm.get('country').touched && consultantRegForm.get('country').invalid && consultantRegForm.get('country').errors.required">
                                    Please enter country</span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <label class="inputfields">Upload Resume</label>
                                <br>
                                <input *ngIf="classinput" type="file" id="attachment" maxFileSize="1000000"
                                    accept=".doc,.docx,application/msword,application/pdf"
                                    (change)="handleFileInput($event)" formControlName='fileData'>
                                <input *ngIf="classinput1" type="file" id="attachment"
                                    accept=".doc,.docx,application/msword,application/pdf"
                                    (change)="handleFileInput($event)" formControlName='fileData'>
                                <em *ngIf="filesdatass" class="pi pi-times" style="cursor: pointer;"
                                    (click)="cancelfiles()"></em>
                                <span class="validationtest" *ngIf="consultantRegForm.get('fileData').touched && consultantRegForm.get('fileData').invalid
                                 && consultantRegForm.get('fileData').errors.required">
                                    Please upload resume</span>

                            </div>
                        </div>
                        <div class="btnsCSS1 btnalign">
                            <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="resetRegForm()"
                                class="addhBtn"></button>
                            <button pButton [disabled]="disable" type="button" icon='pi pi-save' label="Register"
                                (click)="register()" class="addhBtn"></button>
                        </div>
                    </p-panel>
                </p-card>
            </div>
        </div>
    </form>
    <p-dialog class="dialogBox" [(visible)]="displayBasic" [style]="{width: '30vw'}" [draggable]="false" [modal]="true"
        [resizable]="false" [closable]="false">
        <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/miracleemail.svg" class="verify">
        <h5 class="h5msg">Please check your email and click on verify</h5>
    </p-dialog>
    <div *ngIf="!loader; else showLoader"></div>
    <ng-template #showLoader>
        <div class="loading-bar">
            <div>
                <div class="image-holder" class="col-sm-12 text-center laoderheight">
                    <div class="laodersize">
                        <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                            class="loaderimg-height">
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>