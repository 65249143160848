<div class="card">
    <p-dataView #dv [value]="products" filterBy="name" layout="grid">
        <ng-template pTemplate="header" class="cardheader">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
            </div>
        </ng-template>
        <ng-template let-product pTemplate="listItem">
            <div class="p-col-12">
                <div class="product-list-item">
                    <i class="pi pi-sitemap product-category-icon prjlistIcon" ></i>
                    <div class="product-list-detail">
                        <div class="product-name">{{product.projectName}}</div><br>
                        <div class="role" style="margin-bottom: -32px;">
                            <div class="product-category"><span class="cmpnyName">Company  -  </span>{{product.companyName}}</div>
                            <div class="product-category"><span class="cmpnyName">Start Date  -  </span>{{product.startDate}}</div>
                            <div class="product-category endDate"><span class="cmpnyName">End Date  -  </span>{{product.endDate}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template let-product pTemplate="gridItem">
            <div class="p-col-12 p-md-4">
                <div class="product-grid-item card">
                    <div class="product-grid-item-content">
                        <div class="product-list-item">
                           <i class="pi pi-sitemap product-category-icon prjIcon" ></i>
                            <div class="product-list-detail">
                                <div class="product-name prjName">{{product.projectName}}</div><br>
                               <div class="product-category"><span class="cmpnyName">Company  -  </span>{{product.companyName}}</div>
                                <div class="product-category"><span class="cmpnyName">Start Date  -  </span>{{product.startDate}}</div>
                                <div class="product-category endDate"><span class="cmpnyName">End Date  -  </span>{{product.endDate}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-dataView>
</div>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight " >
                <div class="laodersize" >
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif" class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>