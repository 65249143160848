<p-card>
      <form [formGroup]="requirementsForm">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-3">
            <label class="inputfields">Job Id</label>
            <input id="input" type="number" maxlength="5" pKeyFilter="int" autocomplete="off" pInputText
              formControlName="jobId" placeholder="Job Id">
          </div>
          <div class="p-field p-col-12 p-md-3">
            <label class="inputfields">Position Title</label>
            <input id="input" type="text" maxlength="30" autocomplete="off" pInputText formControlName="jobTitle"
              (keyup.enter)="search()" placeholder="Position Title">
          </div>
          <div class="p-field p-col-12 p-md-3">
            <label class="inputfields">Status</label>
            <p-dropdown [options]="statusList" placeholder="Select Status" optionValue="code" 
            [showClear]="true"  optionLabel="label" (keyup.enter)="search()" formControlName="status"></p-dropdown>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <label class="inputfields">Start Date</label>
            <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="startDate" placeholder="MM-DD-YYYY"
              [showIcon]="true" (keyup.enter)="search()" [showButtonBar]="true">
            </p-calendar>
          </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-3">
            <label class="inputfields">End Date</label>
            <p-calendar (keyup.enter)="search()" [showIcon]="true" inputId="icon" formControlName="endDate"
              placeholder="MM-DD-YYYY" dateFormat="mm-dd-yy"></p-calendar>
          </div>
        </div>
        <div class="btnsCSS1 btnalign">
          <button pButton class="addhBtn" type="button" icon='pi pi-plus' (click)="addReq()" label="Add" ></button>
          <button pButton class="addhBtn" type="button" icon='pi pi-refresh' (click)="reset()" 
            label="Reset"></button>
          <button pButton class="addhBtn" type="button" icon='pi pi-search' (click)="search()"
            label="Search"></button>
        </div>
      </form>
</p-card>
<br>
<p-card class="pgpos">
  <div *ngIf="table">
    <p-table [autoLayout]="true" [columns]="cols" [value]="listOfRequirements" [resizableColumns]="true" selectionMode="single"
      dataKey="edit" [responsive]="true" id='excel-table' sortMode="multiple" responsiveLayout="scroll">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
            [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field"  [hidden]="col.sno == 5" >
            <span>
              {{col.header}}
            </span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr class="pointer">
          <td class="textCenter" (click)="reqEditRoute(data)" ><span class="p-column-title">
            </span> {{data.reqId}}</td>
          <td class="textCenter" (click)="reqEditRoute(data)" ><span class="p-column-title">
            </span>{{data.reqName}}</td>
          <td (click)="reqEditRoute(data)" > <span class="p-column-title"> </span>
            {{data.noOfPositions}} </td>
            <td class="textCenter ">
                <a (click)="op.toggle($event)" (click)="reqSkillsPopUp(getSkillSetTable(data.requiredSkills))"
                *ngFor="let data1 of getSkillSetTable(data.requiredSkills); index as i;">
                <p-badge class="badgespace newsty"  *ngIf='i<1' [value]="data1"></p-badge>
                <p-badge  *ngIf='i===1' [value]="'+'+(data.requiredSkills.length-1)+' more'"></p-badge>
              </a>
            </td>
            <td class="pointer" [hidden]="true">
              <a (click)="op.toggle($event)" (click)="reqSkillsPopUp(getSkillSetTable(data.requiredSkills))"
                *ngFor="let data1 of getSkillSetTable(data.requiredSkills); index as i;">
              <p-badge  [value]="data1+','"></p-badge>
            </a>
          </td>
          <td class="textCenter" (click)="reqEditRoute(data)" > <span class="p-column-title">
            </span> {{data.postedDate }}</td>
            <td class="textCenter" (click)="reqEditRoute(data)" ><span class="ui-column-title"></span>
              <p-badge [value]="getStatusTable(data.reqStatus)" [ngClass]="data.reqStatus ==='C'?'blue': data.reqStatus === 'R' ?'green':data.reqStatus === 'J'? 'red':data.reqStatus === 'P'?'evalution':'green'">
              </p-badge>
          </td>
          <td (click)="reqEditRoute(data)" ><span class="p-column-title">
            </span>{{data.submissions}}</td>
          <td class="textCenter"><span class="p-column-title">
            </span><a>
              <em (click)="actionpopup.toggle($event); toggle(items, $event, data)"
                class="pi pi-ellipsis-v"></em>
            </a></td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-grid ui-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 totalrec">
        <h3 class="norecorddata ">Total records : {{this.reqCount}}</h3>
        <p class="downpos">
          <span  class="ui-column-title pointer"
          (click)="downloadActionPopup.toggle($event); downLoadsToggle()">
          <em class="pi pi-download download-spac"></em>
          <strong>Download</strong></span>
        </p>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
          <p-paginator [rows]="10" [totalRecords]="reqCount" [rowsPerPageOptions]="[10,20,30]"
              (onPageChange)="paginate($event)" #p></p-paginator>
      </div>
  </div>
  </div>
  <p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="downloadOptions"></p-menu>
  <p-card *ngIf="records">
    <div class="norecordImgsize">
      <div *ngIf="records" class="aligncenter">
        <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
        <br><em>Sorry, we are not able to find any requirements</em><br>
      </div>
    </div>
  </p-card>
</p-card>
<p-dialog header="Rejection Reason" class="dialogBox" [(visible)]="displayreason" [style]="{width: '25vw'}"
  [draggable]="false" [modal]="true" [resizable]="true">
  <textarea autocomplete="off" pInputTextarea rows="3" cols="5" [style]="{width: '100%'}" readonly="true"
    [value]='reason'></textarea>
</p-dialog>
<form [formGroup]="releaseForm">
  <p-dialog class="dialogBox" header="Release Requirement" [(visible)]="releaseDialog"
    [contentStyle]="{'overflow': 'visible'}" [draggable]="false" [modal]="true" [resizable]="false"
    [style]="{width: '24%' }" [baseZIndex]="1">
    <div class="p-grid p-fluid header">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <label class="inputfields">Vendor Names</label>
        <p-dropdown [options]="vendorNames" formControlName="vendorNameControl" [filter]="true" class="addhBtn"
          placeholder="Select Vendor Name" optionLabel="name" [showClear]="true">
        </p-dropdown>
      </div>
    </div><br>
      <div class="btnsCSS1 buttonalign">
        <button pButton type="button" icon='pi pi-arrow-circle-right' label="Release" [disabled]="disable"
          (click)="releasedDialogBox()"></button><br><br>
      </div>
  </p-dialog>
  <p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '25%' }">
    <label class="inputfields">Skill Details</label>
    <ng-template pTemplate>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12 bordersty">
          <p class="skpos">
            <p-badge class="badgespace badgesp" *ngFor="let data2 of reqSkillsValue; index as i" [value]="data2" [ngClass]="i%2===0? 'newsty' : ''">
            </p-badge>
          </p>
        </div>
      </div>
    </ng-template>
  </p-overlayPanel>
</form>
<p-dialog class="dialogBox" [(visible)]="displayModal" [draggable]="false" [modal]="true"
  [style]="{width: '30%',height:'60%'}">
  <p-header>Requirement Activity
    <span class="Activity">Job Id : <a>{{this.histroy1}}</a></span>
  </p-header>
  <div class="Activitypos Activitycolor" > Please find the below information</div><br>
  <div class="p-grid p-fluid">
    <div class="p-col-12 p-md-12 p-sm-12">
      <p-timeline [value]="histroy" align="alternate">
        <ng-template pTemplate="content" let-event>
          <p-card>
            <p class="aligncenter"> {{event.activity}}
              <br>
              <span class="Activitycolor">By {{event.activityBy}}</span>
            </p>
          </p-card>
          <br>
        </ng-template>
        <ng-template pTemplate="opposite" let-event><span class="Activitycolor">{{event.activityDate | date:'MM-dd-yyyy'}}</span>
        </ng-template>
      </p-timeline>
    </div>
  </div>
</p-dialog>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center laoderheight ">
        <div class="laodersize" >
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif" class="loaderimg-height">
        </div>
      </div>
    </div>
  </div>
</ng-template>