import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-add-requirements',
  templateUrl: './add-requirements.component.html',
  styleUrls: ['./add-requirements.component.scss'],
  providers: [DatePipe, MessageService],
})
export class AddRequirementsComponent implements OnInit {
  addRequirementsForm: FormGroup;
  userDetails: any;
  userAccId: any;
  positionData: any = [];
  jobTitleData: any;
  departmentList: any = [];
  locationList: any = [];
  exprienceList: any = [];
  hireTypeList: any = [];
  billingList: any = [];
  skillsList: any;
  addRequirementsFromDate: string;
  addRequirementsEndDate: string;
  addRequirementData: any;
  endDisplay: boolean;
  loader: boolean;
  maxDate: any;
  maxEnddate: string;
  radiodata: any;
  radioData: boolean;
  positiondata: any;
  list = {};
  hirelist = {};
  preferencList: any = [];
  currencycode: any[];
  currencyValue: any;
  currencysymbl: any;
  startdate: string;
  disable: boolean;
  hcm: string;
  otherskills = false;
  otherskilldata: boolean;
  shwmsg: boolean;
  showmsg: string;
  yesterday: number;

  minDate: Date;
  minDates: Date;
  startDate: any;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private apiService: ApisService, private route: ActivatedRoute, private router: Router, private messageService: MessageService, private breadcrumbService: BreadcrumbService, private datepipe: DatePipe) {
    this.hcm = this.route.snapshot.queryParamMap.get('Hcm');
    if (this.hcm === 'HCM') {
      this.breadcrumbService.setItems([
        { label: 'Requirements', routerLink: '/rec-requirements' },
        { label: 'Requirement Add', routerLink: '/add-requiremenent' },
      ]);
    } else {
      this.breadcrumbService.setItems([
        { label: 'Requirements', routerLink: '/project-requirements' },
        { label: 'Requirement Add', routerLink: '/add-requiremenent' },
      ]);
    }
  }
  ngOnInit(): void {
    const today = new Date();
    const month = today.getMonth();
    const prevMonth = month === 0 ? 11 : month - 0;
    this.minDate = new Date();
    this.minDates = new Date();
    this.minDate.setMonth(prevMonth);
    this.minDates.setMonth(prevMonth);
    // this.minDate.setFullYear(prevYear);

    // yesterday
    this.addRequirementsForm = this.fb.group({
      positionTitle: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]+'), Validators.maxLength(50)]],
      positionCategory: ['', [Validators.required]],
      jobRole: ['', [Validators.required]],
      department: ['', [Validators.required]],
      minRate: ['', [Validators.pattern('^[0-9]*$')]],
      maxRate: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      positions: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(11)]],
      workLocation: ['', [Validators.required]],
      experience: ['', [Validators.required]],
      hireType: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      billingType: ['', [Validators.required]],
      duration: ['', [Validators.pattern('^[0-9]*$')]],
      perWeek: ['', [Validators.pattern('^[0-9]*$'), Validators.maxLength(11)]],
      preferredSkills: ['', [Validators.required]],
      requirementsSkillSet: ['', [Validators.required]],
      description: ['', [Validators.required, Validators.maxLength(2000)]],
      responsibilities: ['', [Validators.required, Validators.maxLength(2000)]],
      qualification: ['', [Validators.required, Validators.maxLength(500)]],
      comments: ['', [Validators.maxLength(500)]],
      approval: [''],
      otherskills: ['']
    });
    this.userDetails = JSON.parse(this.apiService.decodeData());
    this.preferencList = this.userDetails.preferencesList;
    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }
    this.userAccId = this.userDetails.accId;
    this.getPositionCatagory();
    this.getDepartments();
    this.getWorkLocation();
    this.getExperience();
    this.getHireTypeData();
    this.getBillingDropdown();
    this.reqSkillSet();
    this.radiovalue();
    this.addRequirementsForm.get('endDate').disable();
    this.datevalidation();
  }
  goto() {
    if (this.hcm === 'HCM') {
      this.router.navigate(['/rec-requirements']);
    }
    else {
      this.router.navigate(['/project-requirements']);
    }
  }
  getPositionCatagory() {
    this.apiService.getApi(environment.positionCat + '/' + this.userAccId).subscribe((res) => {
      this.positionData = res.data;
    });
  }
  getRolesBasedonCategory(data) {
    this.apiService.getApi(environment.jobTitle + this.userAccId + '/' + data.id).subscribe((res) => {
      this.jobTitleData = res.data;
    });
    this.apiService.getApi(environment.positioncategory + data.id).subscribe(res => {
      this.positiondata = res.data.records[0];
      if (this.positiondata.lkHireType === 'C' || this.positiondata.lkHireType === 'H') {
        this.endDisplay = true;
        this.addRequirementsForm.get('endDate').enable();
        const today = new Date();
        const month = today.getMonth();
        const year = today.getFullYear();
        const nextMonth = month === 11 ? 0 : month + 6;
        this.maxDate = new Date();
        this.maxDate.setMonth(nextMonth);
        this.maxEnddate = this.datepipe.transform(this.maxDate, 'MM-dd-yyyy');
        this.addRequirementsForm.controls.endDate.patchValue(
          this.maxEnddate
        );
      } else {
        this.endDisplay = false;
      }
      this.addRequirementsForm.patchValue({
        billingType: this.positiondata.lkBillingType,
        hireType: this.positiondata.lkHireType,
      });
    });
  }
  getMinrate(data) {
    this.addRequirementsForm.controls.minRate.patchValue(data.minrate);
    this.addRequirementsForm.controls.maxRate.patchValue(data.maxrate);
  }
  getDepartments() {
    this.apiService.getApi(environment.departments + '/' + this.userAccId).subscribe((res) => {
      this.departmentList = res.data;
      this.addRequirementsForm.controls.department.patchValue(
        this.departmentList[0]
      );
    });
  }
  getWorkLocation() {
    this.apiService.getApi(environment.location + '/' + this.userAccId).subscribe((res) => {
      this.locationList = res.data;
      this.addRequirementsForm.controls.workLocation.patchValue(this.locationList[0].locId);
    });
  }
  getExperience() {
    this.apiService.getApi(environment.lookupsData + 'RELYE').subscribe((res: any) => {
      this.exprienceList = res.data;
    });
  }
  getHireTypeData() {
    this.apiService.getApi(environment.lookupsData + 'RELHT').subscribe((res) => {
      this.hireTypeList = res.data;
      this.addRequirementsForm.controls.hireType.patchValue(this.hireTypeList[0].code);

      this.endDisplay = true;
      this.addRequirementsForm.get('endDate').enable();
      const today = new Date();
      const month = today.getMonth();
      const year = today.getFullYear();
      const nextMonth = month === 11 ? 0 : month + 6;
      this.maxDate = new Date();
      this.maxDate.setMonth(nextMonth);
      const todays = new Date(this.startdate);
      const days = today.getDate() + 16;
      const prvdats = days === 0 ? 11 : days;

      const months = todays.getMonth();
      const prevMonth = months === 0 ? 11 : months - 0;
      this.minDate = new Date();
      this.minDates.setMonth(prevMonth);
      this.minDates.setDate(prvdats);
      this.maxEnddate = this.datepipe.transform(this.maxDate, 'MM-dd-yyyy');
      this.addRequirementsForm.controls.endDate.patchValue(
        this.maxEnddate
      );
    });
  }
  getBillingDropdown() {
    this.apiService.getApi(environment.lookupsData + 'RELBT').subscribe((res) => {
      this.billingList = res.data;
      this.addRequirementsForm.patchValue({
        experience: '2',
        duration: 24,
        positions: 1,
        perWeek: 40,
        billingType: 'M',
      });
    });
  }
  reqSkillSet() {
    this.apiService.getApi(environment.lookupsData + 'RELRS').subscribe((res) => {
      this.skillsList = res.data;
    });
  }
  radiovalue() {
    this.apiService.getApi(environment.addRequirementradio + this.userDetails.accId).subscribe(res => {
      this.radiodata = res.data[0];
      this.addRequirementsForm.controls.approval.patchValue(this.radiodata.empApprovalReqFlag);
    });
  }
  typeData(event, data) {
    if ((data.originalEvent.isTrusted === true && event === 'C') || (data.originalEvent.isTrusted === true && event === 'H')) {
      this.endDisplay = true;
      this.addRequirementsForm.get('endDate').enable();
      const today = this.startDate;
      const month = today.getMonth();
      const year = today.getFullYear();
      const nextMonth = month === 11 ? 0 : month + 6;
      this.maxDate = new Date();
      this.maxDate.setMonth(nextMonth);
      const todays = new Date(this.startdate);
      const days = today.getDate();
      const prvdats = days === 0 ? 11 : days;
      const months = todays.getMonth();
      const prevMonth = months === 0 ? 11 : months - 0;
      this.minDate = new Date();
      this.minDates.setMonth(prevMonth);
      this.minDates.setDate(prvdats);
      // let months = new Date(this.startdate).getDate();
      // let prevMonth = months-1;
      // this.minDates = new Date();
      // this.minDates.setMonth(prevMonth) ;

      // let months = new Date(this.startdate);
      // let prevMonth = months-1;
      // this.minDates = new Date();
      // this.minDates.setMonth(prevMonth) ;
      this.maxDate = this.datepipe.transform(this.maxDate, 'MM-dd-yyyy');
      this.addRequirementsForm.controls.endDate.patchValue(
        this.maxDate
      );
    }
    else if (event === 'C' || event === 'H') {
      this.endDisplay = true;
      this.addRequirementsForm.get('endDate').enable();
      const today = new Date();
      const month = today.getMonth();
      const year = today.getFullYear();
      const nextMonth = month === 11 ? 0 : month + 6;
      this.maxDate = new Date();
      this.maxDate.setMonth(nextMonth);
      const todays = new Date(this.startdate);
      const days = today.getDate() + 16;
      const prvdats = days === 0 ? 11 : days;

      const months = todays.getMonth();
      const prevMonth = months === 0 ? 11 : months - 0;
      this.minDate = new Date();
      this.minDates.setMonth(prevMonth);
      this.minDates.setDate(prvdats);
      // let months = new Date(this.startdate).getDate();
      // let prevMonth = months-1;
      // this.minDates = new Date();
      // this.minDates.setMonth(prevMonth) ;

      // let months = new Date(this.startdate);
      // let prevMonth = months-1;
      // this.minDates = new Date();
      // this.minDates.setMonth(prevMonth) ;
      this.maxEnddate = this.datepipe.transform(this.maxDate, 'MM-dd-yyyy');
      this.addRequirementsForm.controls.endDate.patchValue(
        this.maxEnddate
      );


    }

    else {
      this.endDisplay = false;
      this.addRequirementsForm.get('endDate').reset();
      this.addRequirementsForm.get('endDate').disable();
    }
  }
  addReq() {
    if (this.addRequirementsForm.invalid) {
      const controls = this.addRequirementsForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      this.loader = true;
      const addreqfrmDate =
        this.addRequirementsForm.controls.startDate.value;
      const addReqFrmDate = new Date(Date.parse(addreqfrmDate));
      this.addRequirementsFromDate = this.datepipe.transform(addReqFrmDate, 'MM-dd-yyyy');
      const addreqEndDate = this.addRequirementsForm.controls.endDate.value;
      if (addreqEndDate) {
        const addReqEndDate = new Date(Date.parse(addreqEndDate));
        this.addRequirementsEndDate = this.datepipe.transform(addReqEndDate, 'MM-dd-yyyy');
      } else {
        this.addRequirementsEndDate = '';
      }
      const payload = {
        // tslint:disable-next-line:max-line-length
        accDeptId: this.addRequirementsForm.controls.department.value === null || this.addRequirementsForm.controls.department.value.id === undefined || this.addRequirementsForm.controls.department.value === '' ? '' : this.addRequirementsForm.controls.department.value.id,
        accId: 1,
        billingType: this.addRequirementsForm.controls.billingType.value,
        comment: this.addRequirementsForm.controls.comments.value,
        createdBy: 1,
        createdByAccId: 1,
        hireType: this.addRequirementsForm.controls.hireType.value,
        hrsPerWeek: Number(this.addRequirementsForm.controls.perWeek.value),
        locationId:
          this.addRequirementsForm.controls.workLocation.value,
        modifiedBy: Number(this.userDetails.userId),
        ownerId: this.userAccId,
        preSkillSet: this.addRequirementsForm.controls.preferredSkills.value,
        reqCategory: 0,
        reqComments: 'string',
        reqCreatedBy: Number(this.userDetails.userId),
        reqDuration: Number(this.addRequirementsForm.controls.duration.value),
        reqEndDate: this.addRequirementsEndDate,
        reqJobdesc: this.addRequirementsForm.controls.description.value,
        jobTitle: this.addRequirementsForm.controls.jobRole.value.id,
        reqMaxRate: this.addRequirementsForm.controls.maxRate.value,
        reqMinRate: this.addRequirementsForm.controls.minRate.value,
        reqName: this.addRequirementsForm.controls.positionTitle.value,
        reqNoOfResources: Number(this.addRequirementsForm.controls.positions.value),
        reqQualification: this.addRequirementsForm.controls.qualification.value,
        reqResponsibility: this.addRequirementsForm.controls.responsibilities.value,
        reqSkillSet: this.addRequirementsForm.controls.requirementsSkillSet.value,
        reqStartDate: this.addRequirementsFromDate,
        positionCategory: this.addRequirementsForm.controls.positionCategory.value.id,
        yearExperience: this.addRequirementsForm.controls.experience.value,
        empApprovalReqFlag: this.addRequirementsForm.controls.approval.value,
        lkReqType: 'R',
        parentReqId: 0,
        otherSkills: this.addRequirementsForm.controls.otherskills.value === null || this.addRequirementsForm.controls.otherskills.value === '' || this.addRequirementsForm.controls.otherskills.value === undefined ? '' : this.addRequirementsForm.controls.otherskills.value,
      };
      this.disable = true;
      this.loader = true;
      this.apiService.postApi(environment.addReq, payload).subscribe((res) => {
        this.addRequirementData = res;
        if (res.success === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
          setTimeout(() => {
            if (this.hcm === 'HCM') {
              this.router.navigate(['/rec-requirements']);
            }
            else {
              this.router.navigate(['/project-requirements']);
            }
          }, 2000);
          this.addRequirementsForm.reset();
          this.loader = false;
          this.disable = false;
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        }
        this.loader = false;
      });
    }
  }
  reset() {
   
    this.addRequirementsForm.controls.positionTitle.reset();
    this.addRequirementsForm.controls.positionCategory.reset();
    this.addRequirementsForm.controls.jobRole.reset();
    this.addRequirementsForm.controls.minRate.reset();
    this.addRequirementsForm.controls.maxRate.reset();
    this.addRequirementsForm.controls.preferredSkills.reset();
    this.addRequirementsForm.controls.requirementsSkillSet.reset();
    this.addRequirementsForm.controls.description.reset();
    this.addRequirementsForm.controls.responsibilities.reset();
    this.addRequirementsForm.controls.qualification.reset();
    this.addRequirementsForm.controls.comments.reset();
    this.addRequirementsForm.controls.approval.reset();
    this.addRequirementsForm.controls.otherskills.reset();
    this.addRequirementsForm.controls.approval.patchValue(this.radiodata.empApprovalReqFlag);
  }
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
  datevalidation() {
    const d = new Date();
    // tslint:disable-next-line:prefer-const
    let day = d.getDay();
    d.setDate(d.getDate() + 15);
    this.startdate = this.datepipe.transform(d, 'MM-dd-yyyy');
    this.addRequirementsForm.controls.startDate.patchValue(
      this.startdate
    );
  }
  skillsetOthers(data) {
    this.addRequirementsForm.controls.otherskills.reset();
    this.addRequirementsForm.controls.otherskills.clearValidators();
    // tslint:disable-next-line:prefer-for-of
    this.otherskilldata = false;
    if (data) {
      this.otherskilldata = false;
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < data.length; i++) {
      if (data[i] === '25') {
        this.addRequirementsForm.controls.otherskills.setValidators([Validators.required, Validators.maxLength(300)]);
        this.otherskilldata = true;
      }
      if (this.otherskilldata === false) {
        this.addRequirementsForm.controls.otherskills.reset();
        this.addRequirementsForm.controls.otherskills.clearValidators();

      }
      // else {

      //   this.editRequirementsForm.controls.otherskills.clearValidators();
      //   this.otherskilldata = false;
      // }
    }
  }
  getduration() {
    if (this.addRequirementsForm.controls.duration.value > 24) {
      this.shwmsg = true;
      this.addRequirementsForm.controls.duration.reset();
      this.showmsg = 'Duration should be less than 24 months';
      setTimeout(() => {
        this.showmsg = '';
        this.shwmsg = false;
        this.addRequirementsForm.controls.duration.patchValue(24);
      }, 3000);
    } else {
    }
  }
  setRequired() {
    return [Validators.required, Validators.maxLength(300)];
  }
  onBlur(data): void {
    if (data === '') {
      this.addRequirementsForm.controls.otherskills.setValidators(this.setRequired());
    }
  }
  endDateValidation(data) {
    this.startDate = data;
    const todays = new Date(data);
    const days = todays.getDate();
    const prvdats = days === 0 ? 11 : days;
    const months = todays.getMonth();
    const prevMonth = months === 0 ? 11 : months - 0;
    this.minDate = new Date();
    this.minDates.setMonth(prevMonth);
    this.minDates.setDate(prvdats);

  }
}
