import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import { isEmpty } from 'rxjs/operators';
@Component({
  selector: 'app-edit-consultant-search',
  templateUrl: './edit-consultant-search.component.html',
  styleUrls: ['./edit-consultant-search.component.scss'],
  providers: [MessageService, DatePipe],
})
export class EditConsultantSearchComponent implements OnInit {
  disabled: boolean;
  usrstatus: any;
  userStatus: any;
  // status dropdown values
  editStatus = [
    { label: 'Active', code: 'A' },
    { label: 'Inactive', code: 'I' },
  ];
  // It is used to show gender dropdown values
  gender1 = [
    { name: 'Male', code: 'M' },
    { name: 'Female', code: 'F' },
  ];
  // It is used to show availability dropdown values
  availability = [
    { name: 'Yes', code: 'Y' },
    { name: 'No', code: 'N' },
  ];
  // It is used to show relocation dropdown values
  relocation = [
    { name: 'Yes', code: 'Y' },
    { name: 'No', code: 'N' },
  ];
  // It is used to show status dropdown values
  Status = [
    { name: 'All', value: '-1' },
    { name: 'Active', value: 'A' },
    { name: 'Inactive', value: 'I' },
  ];
  cols = [
    { field: 'fileName', header: 'Attachment Name' },
    { field: 'title', header: 'Attachment Title' },
    { field: 'object_type', header: 'Attachment Type ' },
    { field: 'opp_created_date', header: 'Date of Attachment' },
    { field: 'lkStatus', header: 'Status' },
    { field: 'action', header: 'Action' }
  ];
  display: boolean;
  mvaultForm: FormGroup;
  bas2: any;
  dispalyattachmenttitle: boolean;
  displayattatype: boolean;
  displayattailename: boolean;
  userId: any;
  file2: any;
  userIdValue: any;
  files1: any;
  base64textString: string;
  resumes: any;
  editConsultantForm: FormGroup;
  country: any;
  state: any;
  livingCountry: any;
  permanentStates: any = [];
  experienceData: any;
  currencyData: any;
  accId: any;
  disableButtons: boolean;
  statusValue: any;
  statusTable = {};
  totalRecords: any;
  prefCountry: boolean;
  regField: boolean;
  nonUsStateField: boolean;
  usStateField: boolean;
  consultant: any;
  secondaryFilteredData: any;
  selectedsecondarySkillsId: any;
  othSecSkillsField: boolean;
  filteredData: any;
  othPriSkillsField: boolean;
  selectedPrimarySkillsId: any;
  dateOfBirthValidation: string;
  showIcon: boolean;
  MailingStates: any;
  loader: boolean;
  consultantRowData: any;
  rowID: any;
  updateResponse: any;
  filterId: any;
  dobDate: any;
  availDate1: any;
  actId: any;
  refffDate: any;
  refferedDate1: any;
  permanentStates1: any = [];

  hide = true;
  showIcon1: boolean;
  hideIcon = true;
  currencysymbl: any;
  acctdetails: any;
  currencycode: any[];
  preferencList: any = [];
  currencyValue: any;
  skillsCategory: any = [];
  preferredSkills: any = [];
  statusList: any = [];
  selectedPrimaryskills: any = [];
  primarySkills: any[];
  secondarySkills: any = [];
  secondarySkill: any = [];
  primarySkill: any = [];
  firstName1: string;
  type: string;
  status2: any;
  attachemnts: any;
  lkattachementtype: any = {};
  protocol: any = [];
  items: { label: string; icon: string; command: () => void; }[];
  editDisplay: boolean;
  editAttachmentForm: FormGroup;
  isUploading: boolean;
  filedata: any;
  rowData: any;
  downloadData: any;
  rowdata: any;
  disable: boolean;
  fileRes: any;
  fileData: any;
  filePath: any;
  fileSize: any;
  fileName: any;
  name: any;
  fileType: any;
  getData: any;
  dcountry: any;
  displayemail1: boolean;
  altEmailSpinnerHide: boolean;
  altEmailId: any;
  altEmailCheckHide: boolean;
  acctid: any;
  acctId: any;
  numId: string;
  phSpinnerHide: boolean;
  officenum: any;
  officenummsg: string;
  phCheckHide: boolean;
  phSpinnerHide1: boolean;
  mobinum: any;
  mobinummsg: string;
  phCheckHide1: boolean;
  countrycodedropdown: any = [];
  rows: any = 10;
  page: any = 1;
  buttondisable: boolean;
  records: boolean;
  table: boolean;
  attachmentfile: boolean;
  crossicon: boolean;
  profileupload: boolean;
  first: any;
  pageCount: any;
  filesdatass: boolean;
  registerdStatus: boolean;
  loginActiveData: String;
  email1: any;
  emailIdData: any;
  displayActive: boolean;
  disabled1: boolean;
  saveReg: any;
  emailDisable: boolean;
  customerFlag: string;
  // tslint:disable-next-line:max-line-length
  constructor(private datepipe: DatePipe, private messageService: MessageService, private router: Router, private breadcrumbService: BreadcrumbService, private route: ActivatedRoute, private fb: FormBuilder, private apisService: ApisService) {

  }

  ngOnInit(): void {
    this.attachmentfile = true;
    this.acctId = JSON.parse(this.apisService.decodeData()).userId;
    this.numId = sessionStorage.getItem('editconIdttt');
    this.type = this.route.snapshot.queryParamMap.get('type');
    this.customerFlag = this.route.snapshot.queryParamMap.get('flag');
    if (this.customerFlag === "C") {
      this.breadcrumbService.setItems([
        { label: 'Selected Candidates', routerLink: '/selectedcandidates' },
        { label: 'Candidate Edit ', routerLink: '/editcandidate' }
      ]);
    } else {
      if (this.type === 'open') {
        this.breadcrumbService.setItems([
          { label: 'Open Positions', routerLink: '/open-positions' },
          { label: 'Open Position Edit', routerLink: '/open-position-edit' },
          { label: 'Candidate Edit ', routerLink: '/editcandidate' }
        ]);
      }
      else {
        this.breadcrumbService.setItems([
          { label: 'Candidates', routerLink: '/candidates' },
          { label: 'Candidate Edit', routerLink: '/editcandidate' }
        ]);
      }
    }
    this.acctdetails = JSON.parse(this.apisService.decodeData());
    this.preferencList = this.acctdetails.preferencesList;

    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }
    this.userId = this.route.snapshot.paramMap.get('id');

    this.editConsultantForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(25)]],
      middleName: ['', [Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(15)]],
      lastName: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(25)]],
      dateOfBirth: [''],
      mobileNo: [''],
      homePhone: [''],
      emailId: ['', [Validators.pattern('^[[a-zA-Z0-9.+-_]+@[a-zA-Z0-9.+-_]+.[a-zA-Z]{2,5}]*$'),]],
      alternateEmailId: ['', [Validators.email, Validators.pattern('^[[a-zA-Z0-9.+-_]+@[a-zA-Z0-9.+-_]+.[a-zA-Z]{2,5}]*$'),, Validators.maxLength(60)]],
      availability: [''],
      availableDate: [''],
      Status: [''],
      ssnNo: [''],
      livingCountry: [''],
      linkedIn: ['', [Validators.maxLength(120)]],
      facebook: ['', [Validators.maxLength(120)]],
      twitter: ['', [Validators.maxLength(120)]],
      permanentAddressLine1: ['', [Validators.maxLength(50)]],
      permanentAddressLine2: ['', [Validators.maxLength(50)]],
      permanentCity: ['', [Validators.maxLength(20)]],
      permanentCountry: [''],
      permanentState: [''],
      permanentZip: ['', [Validators.pattern('^[0-9]*$'), Validators.maxLength(10)]],
      mailingAdressCheckbox: [''],
      mailingAddressLine1: ['', [Validators.maxLength(50)]],
      mailingAddressline2: ['', [Validators.maxLength(50)]],
      mailingCity: ['', [Validators.maxLength(20)]],
      mailingCountry: [''],
      mailingState: [''],
      mailingZip: ['', [Validators.pattern('^[0-9]*$'), Validators.maxLength(10)]],
      education: ['', [Validators.maxLength(10)]],
      primarySkills: ['', [Validators.required]],
      secondarySkills: ['', [Validators.required]],
      otherPrimarySkills: [''],
      otherSecondarySkills: [''],
      jobTitle: ['', [Validators.required, Validators.maxLength(30), Validators.pattern('^[\\sa-zA-z]*$')]],
      experience: [''],
      workingCountry: [''],
      mobileDrp: [''],
      homeNumDrp: [''],
      workingState: [''],
      city: ['', [Validators.maxLength(20)]],
      ratesalary: ['', Validators.pattern('^[\\s0-9.]*$')],
      referredBy: [''],
      consultStatus: [''],
      relocation: [''],
      preferredCountry: [''],
      preferredRegion: [''],
      preferredUsaState: [''],
      preferredNonUsaState: [''],
      comments: ['', Validators.maxLength(500)],
      gender: [''],
      consultantworkstatus: ['', Validators.required],
      industryvertical: [''],
      certifications: [''],
      mailbox2: ['', [Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
      mailbox1: ['', [Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
      currentRate: ['', Validators.pattern('^[0-9]*$')],
      currentCTC: ['', Validators.pattern('^[0-9]*$')],
      expMinRate: ['', Validators.pattern('^[0-9]*$')],
      expMaxRate: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      expMinCTC: ['', Validators.pattern('^[0-9]*$')],
      expMaxCTC: ['', Validators.pattern('^[0-9]*$')],
      refDate: [''],
      currency: [''],
      consultantworkstatusComments: ['', Validators.maxLength(500)],
    });
    this.editAttachmentForm = this.fb.group({
      attachmentName: ['', [Validators.maxLength(40)]],
      attachmentTitle: ['', [Validators.maxLength(40), Validators.pattern('^[\\sa-zA-z]*$')]],
      attachmentDate: [''],
      attachmentType: [''],
      attachmentStatus: [''],
      attachmentComments: ['', [Validators.maxLength(200)]]
    });
    this.mvaultForm = this.fb.group({
      dataAttachements: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$')]],
      attachmentTitle: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(40)]],
      attachmentform: ['', Validators.required]
    });
    this.loader = true;
    setTimeout(() => {
      this.loader = false;
      this.getConsultantEditData();
    }, 1000);
    this.userstatus();
    this.getCountryDropdown();
    this.getExperiencedropDownData();
    this.currencyDropdown();
    this.getSkillsCategory();
    this.getStatus();
    this.ConsultantDropdown();
    this.getdrop();
  }
  // Country code onchange method for further enhancement
  countryids(event) {
  }
  // It is used to copy email to clipboard
  copyInputMessage(data) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (data));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
  // It is used to get country code dropdown values
  getdrop() {
    this.countrycodedropdown = this.apisService.getdropdwon();
  }
  // It is used to show status dropdown values
  userstatus() {
    this.apisService.getApi(environment.actstatus).subscribe((res) => {
      this.usrstatus = res.data;
      this.usrstatus.forEach((element) => {
        this.statusTable[element.code] = element.label;
      });
    });
  }
  // It is used to call APIs when ever they are required based on tab change
  handleChange(e) {
    if (e.index === 1) {
      this.attachmenttype();
      this.getCandidatesResumes(this.userId);
    }
  }
  // It is used to show Mvault add popup
  showDialog() {
    this.display = true;
    this.filesdatass = false;
    this.mvaultForm.reset();
  }
  _handleReaderLoaded1(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    this.bas2 = 'data:text/html;base64,' + this.base64textString;

  }
  // country dropdwon
  getCountryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'adlc').subscribe(res => {
      this.livingCountry = res.data;
    });
  }
  // states dropdown
  getStateByUrl(country) {
    this.apisService.getApi(environment.states + country).subscribe(res => {
      this.permanentStates = res.data;
      // tslint:disable-next-line:max-line-length
      this.editConsultantForm.controls.permanentState.patchValue(this.permanentStates.filter((x) => x.code === this.consultantRowData.address[0].state)[0]);
    });
  }
  getStateByUrl1(country) {
    this.apisService.getApi(environment.states + country).subscribe(res => {
      this.permanentStates1 = res.data;
      // tslint:disable-next-line:max-line-length
      this.editConsultantForm.controls.mailingState.patchValue(this.permanentStates1.filter((x) => x.code === this.consultantRowData.address[0].state)[0]);
    });
  }
  // experience dropdwon
  getExperiencedropDownData() {
    this.apisService
      .getApi(environment.lookupsData + 'RELYE')
      .subscribe((res: any) => {
        this.experienceData = res.data;

      });
  }
  attachmenttype() {
    this.apisService.getApi(environment.lookupsData + 'ATAT').subscribe((res) => {
      this.attachemnts = res.data;
      this.attachemnts.forEach(element => {
        this.lkattachementtype[element.code] = element.label;
        if (element.code !== 'B' || element.code !== 'A' || element.code !== 'C') {
          this.protocol.push(element);
        }
        else {
          this.protocol = '';
        }
      });

    });
  }
  // currency dropdwon
  currencyDropdown() {
    this.apisService.getApi(environment.lookupsData + 'UPDC').subscribe((res: any) => {
      this.currencyData = res.data;
    });
  }

  // skills dropdown
  getSkillsCategory() {
    this.apisService
      .getApi(environment.lookupsData + 'RELRS')
      .subscribe((res) => {
        this.skillsCategory = res.data;
        // const data = { skillName: 'Others', id: '' };
        // this.skillsCategory.push(data);
      });
  }
  // resume grid Api
  getCandidatesResumes(userId) {
    this.loader = true;
    const pageNo = this.page;
    const pageCount = this.rows;
    const data = pageNo + '/' + pageCount;
    this.apisService.getApi(environment.candidateresume + data + '/' + userId).subscribe((res) => {
      this.resumes = res.data;
      this.totalRecords = res.count;
      this.loader = false;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.getCandidatesResumes(this.userId);
  }
  getStatus() {
    this.apisService
      .getApi(environment.lookupsData + 'RELRS')
      .subscribe((res) => {
        this.statusList = res.data;
        // const data = { skillName: 'Others', id: '' };
        // this.statusList.push(data);
      });
    this.Status.forEach((element) => {
      this.statusTable[element.value] = element.name;
    });
  }
  // consultant drop down
  ConsultantDropdown() {
    this.apisService
      .getApi(environment.lookupsData + 'UPDVS')
      .subscribe((res) => {
        this.consultant = res.data;
      });
  }
  relocDropDown(data) {
    if (data === 'Yes') {
      this.prefCountry = true;
      this.regField = false;
      this.nonUsStateField = false;
      this.usStateField = false;
      this.editConsultantForm.get('preferredCountry').enable();
      this.editConsultantForm.get('preferredCountry').reset();
    } else {
      this.prefCountry = false;
      this.regField = false;
      this.nonUsStateField = false;
      this.usStateField = false;
      this.editConsultantForm.get('preferredCountry').disable();
      this.editConsultantForm.get('preferredRegion').disable();
      this.editConsultantForm.get('preferredNonUsaState').disable();
      this.editConsultantForm.get('preferredUsaState').disable();
    }
  }

  // Method for primary skills data to handle other primary skills
  primarySkillsData(event) {
    const skills = event.value;
    this.filteredData = skills.map((x) => x.code);
    this.selectedPrimarySkillsId = skills.map((x) => x.code).map(Number);
    if (this.filteredData.includes('Others')) {
      this.othPriSkillsField = true;
      this.editConsultantForm.get('otherPrimarySkills').enable();
    } else {
      this.othPriSkillsField = false;
      this.editConsultantForm.get('otherPrimarySkills').disable();
    }
  }

  // Method for secondary skills data to handle other secondary skills
  secondarySkillData(data) {
    const skills = data.value;
    this.secondaryFilteredData = skills.map((x) => x.code);
    this.selectedsecondarySkillsId = skills.map((x) => x.code).map(Number);
    if (this.secondaryFilteredData.includes('Others')) {
      this.othSecSkillsField = true;
      this.editConsultantForm.get('otherSecondarySkills').enable();
    } else {
      this.othSecSkillsField = false;
      this.editConsultantForm.get('otherSecondarySkills').disable();
    }
  }

  // It is used for calculating  Date of Birth ,consultant is eligibile or not
  getAge(event) {
    const today = new Date();

    const birthDate = new Date(
      this.editConsultantForm.get('dateOfBirth').value
    );
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      isNaN(age--);
    }
    if (age < 18) {
      this.editConsultantForm.get('dateOfBirth').reset();
      this.dateOfBirthValidation = 'Age Should Be Atleast 18';
    } else {
      this.dateOfBirthValidation = '';
    }
  }

  // handiling avilable date based on Avilability
  avilableDateModification(event) {
    if (event === 'Yes') {
      this.editConsultantForm.controls.availableDate.enable();
      this.showIcon = true;
    } else {
      this.editConsultantForm.controls.availableDate.reset();
      this.editConsultantForm.controls.availableDate.disable();
      this.showIcon = false;
    }
  }

  disableMailAd(event) {
    this.getStateByUrl1(this.editConsultantForm.controls.permanentCountry.value);
    {
      this.editConsultantForm.controls.mailingAddressLine1.setValue(
        this.editConsultantForm.controls.permanentAddressLine1.value
      );
      this.editConsultantForm.controls.mailingAddressline2.setValue(
        this.editConsultantForm.controls.permanentAddressLine2.value
      );
      this.editConsultantForm.controls.mailingCity.setValue(
        this.editConsultantForm.controls.permanentCity.value
      );
      this.editConsultantForm.controls.mailingCountry.patchValue(this.livingCountry.filter(
        (x) => x.code === this.editConsultantForm.controls.permanentCountry.value
      )[0]);
      this.editConsultantForm.controls.mailingState.setValue(
        this.editConsultantForm.controls.permanentState.value
      );
      this.editConsultantForm.controls.mailingZip.setValue(
        this.editConsultantForm.controls.permanentZip.value
      );
      this.editConsultantForm.controls.mailbox2.setValue(
        this.editConsultantForm.controls.mailbox1.value
      );
      if (event.checked === true) {
        this.editConsultantForm.controls.mailingAddressLine1.disable();
        this.editConsultantForm.controls.mailingAddressline2.disable();
        this.editConsultantForm.controls.mailingCity.disable();
        this.editConsultantForm.controls.mailingCountry.disable();
        this.editConsultantForm.controls.mailingState.disable();
        this.editConsultantForm.controls.mailingZip.disable();
        this.editConsultantForm.controls.mailbox2.disable();
      } else {
        this.editConsultantForm.controls.mailingAddressLine1.enable();
        this.editConsultantForm.controls.mailingAddressline2.enable();
        this.editConsultantForm.controls.mailingCity.enable();
        this.editConsultantForm.controls.mailingCountry.enable();
        this.editConsultantForm.controls.mailingState.enable();
        this.editConsultantForm.controls.mailingZip.enable();
        this.editConsultantForm.controls.mailbox2.enable();
      }
    }
  }
  disableCheckboxValue() {
    this.editConsultantForm.controls.mailingAdressCheckbox.setValue(false);
    this.editConsultantForm.controls.mailingAddressLine1.enable();
    this.editConsultantForm.controls.mailingAddressline2.enable();
    this.editConsultantForm.controls.mailingCity.enable();
    this.editConsultantForm.controls.mailingCountry.enable();
    this.editConsultantForm.controls.mailingState.enable();
    this.editConsultantForm.controls.mailingZip.enable();
  }

  getConsultantEditData() {
    const consultId = this.userId;
    this.loader = true;
    this.apisService.getApi(environment.addConsultant + '/' + consultId).subscribe((res: any) => {
      this.consultantRowData = res.data;
      this.rowID = this.consultantRowData.id;
      this.loader = false;
      if (this.consultantRowData.address.length === 0) {
      } else {
        this.editConsultantForm.patchValue({
          permanentAddressLine1: this.consultantRowData.address[0].address1,
          permanentAddressLine2: this.consultantRowData.address[0].address2,
          mailbox1: this.consultantRowData.address[0].mailbox,
          permanentCity: this.consultantRowData.address[0].city,
          permanentZip: this.consultantRowData.address[0].zip,
          mailingAddressLine1: this.consultantRowData.address[1].address1,
          mailingAddressline2: this.consultantRowData.address[1].address2,
          mailbox2: this.consultantRowData.address[1].mailbox,
          mailingCity: this.consultantRowData.address[1].city,
          mailingZip: this.consultantRowData.address[1].zip,
          mailingCountry: this.consultantRowData.address[1].country,
        });
        this.editConsultantForm.controls.permanentCountry.patchValue(
          this.consultantRowData.address[0].country
        );
        this.getStateByUrl(this.consultantRowData.address[0].country);
        this.getStateByUrl1(this.consultantRowData.address[1].country);
        this.editConsultantForm.controls.mailingCountry.patchValue(
          this.livingCountry.filter(
            (x) => x.code === this.consultantRowData.address[1].country
          )[0]
        );
      }
      this.editConsultantForm.controls.workingCountry.patchValue(
        this.livingCountry.filter(
          (x) => x.code === this.consultantRowData.workingCountry
        )[0]
      );
      this.editConsultantForm.controls.currency.patchValue(
        this.currencyData.filter(
          (x) => x.code === this.consultantRowData.currency
        )[0]
      );
      this.editConsultantForm.controls.availability.patchValue(
        this.availability.filter(
          (x) => x.code === this.consultantRowData.available
        )[0]
      );
      this.editConsultantForm.controls.livingCountry.patchValue(
        this.livingCountry.filter(
          (x) => x.code === this.consultantRowData.livingCountry
        )[0]
      );
      this.editConsultantForm.controls.relocation.patchValue(
        this.relocation.filter(
          (x) => x.code === this.consultantRowData.relocation
        )[0]
      );
      this.editConsultantForm.controls.consultantworkstatus.patchValue(
        this.consultantRowData.workStatus
      );
      this.editConsultantForm.patchValue({
        firstName: this.consultantRowData.firstName,
        middleName: this.consultantRowData.middlName,
        lastName: this.consultantRowData.lastName,
        dateOfBirth: this.consultantRowData.birthDate,
        mobileNo: this.consultantRowData.mobilPhone,
        homePhone: this.consultantRowData.homePhone,
        emailId: this.consultantRowData.email1,
        alternateEmailId: this.consultantRowData.email2,
        ssnNo: this.consultantRowData.ssn,
        linkedIn: this.consultantRowData.linkedin,
        facebook: this.consultantRowData.facebook,
        twitter: this.consultantRowData.twitter,
        jobTitle: this.consultantRowData.jobTitle,
        currentRate: this.consultantRowData.curRate,
        currentCTC: this.consultantRowData.cur_ctc,
        expMinRate: this.consultantRowData.exp_rate_min,
        expMaxRate: this.consultantRowData.exp_rate_max,
        expMinCTC: this.consultantRowData.exp_ctc_min,
        expMaxCTC: this.consultantRowData.exp_ctc_max,
        education: this.consultantRowData.education,
        referredBy: this.consultantRowData.referredBy,
        refDate: this.consultantRowData.referredDate,
        availableDate: this.consultantRowData.availableDate,
        consultantworkstatusComments:
          this.consultantRowData.visaStatusComments,
        comments: this.consultantRowData.comments,
        consultantworkstatus: this.consultantRowData.workStatus,
      });
      // this.editConsultantForm.controls.alternateEmailId.disable();
      // if (
      //   this.consultantRowData.email2 === '' ||
      //   this.consultantRowData.email2 === null ||
      //   this.consultantRowData.email2 === undefined
      // ) {
      //   this.editConsultantForm.controls.emailId.disable();
      // } else {
      //   this.editConsultantForm.controls.emailId.enable();
      //   this.emailverification();
      // }
      if(this.consultantRowData.flag === 'Y') {
          this.editConsultantForm.controls.emailId.disable();
      } else if(this.consultantRowData.flag === 'N') {
        this.editConsultantForm.controls.emailId.enable();
      }
      if (this.consultantRowData.available === 'Y') {
        this.editConsultantForm.controls.availableDate.enable();
        this.editConsultantForm.controls.availableDate.patchValue(this.consultantRowData.availableDate);
      } else {
        this.editConsultantForm.controls.availableDate.disable();
      }
      // tslint:disable-next-line:max-line-length
      this.editConsultantForm.controls.mobileDrp.patchValue(this.countrycodedropdown.filter((x) => x.id === this.consultantRowData.mobileCcode)[0]);
      // tslint:disable-next-line:max-line-length
      this.editConsultantForm.controls.homeNumDrp.patchValue(this.countrycodedropdown.filter((x) => x.id === this.consultantRowData.officeCcode)[0]);
      this.editConsultantForm.controls.dateOfBirth.patchValue(
        this.consultantRowData.birthDate
      );
      this.editConsultantForm.controls.gender.patchValue(
        this.consultantRowData.gender
      );
      this.editConsultantForm.controls.Status.patchValue(
        this.consultantRowData.status
      );
      if (this.consultantRowData.status === 'R') {
        this.status2 = this.consultantRowData.status;
        this.disabled = true;
      }

      const selectedPrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.skillsCategory.length; i++) {
        // tslint:disable-next-line: prefer-for-of
        for (
          let j = 0;
          j <= this.consultantRowData.primarySkills.length;
          j++
        ) {
          if (
            Number(this.skillsCategory[i].code) ===
            this.consultantRowData.primarySkills[j]
          ) {
            selectedPrimarySkills.push(this.skillsCategory[i]);
          }
        }
      }
      this.editConsultantForm.controls.primarySkills.patchValue(
        selectedPrimarySkills
      );
      const selectedSecondarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.skillsCategory.length; i++) {
        // tslint:disable-next-line: prefer-for-of
        for (
          let j = 0;
          j <= this.consultantRowData.secondarySkills.length;
          j++
        ) {
          if (
            Number(this.skillsCategory[i].code) ===
            this.consultantRowData.secondarySkills[j]
          ) {
            selectedSecondarySkills.push(this.skillsCategory[i]);
          }
        }
      }
      this.editConsultantForm.controls.secondarySkills.patchValue(
        selectedSecondarySkills
      );
      this.primarySkill = [];
      this.secondarySkill = [];
      selectedPrimarySkills.forEach((element) => {
        this.primarySkill.push(element.label);
      });
      selectedSecondarySkills.forEach((element) => {
        this.secondarySkill.push(element.label);
      });
      this.editConsultantForm.controls.experience.patchValue(
        this.consultantRowData.experience
      );
    });
  }
  consultantUpdateForm() {
    this.actId = JSON.parse(this.apisService.decodeData());
    if (this.editConsultantForm.invalid) {
      const controls = this.editConsultantForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      if (
        this.editConsultantForm.controls.firstName.value === '' ||
        this.editConsultantForm.controls.firstName.value === null ||
        this.editConsultantForm.controls.firstName.value === undefined
      ) {
        this.firstName1 = '';
      } else {
        const firstName = this.editConsultantForm.controls.firstName.value;
        const firstName1 = new Date(Date.parse(firstName));
      }
      if (
        this.editConsultantForm.controls.dateOfBirth.value === '' ||
        this.editConsultantForm.controls.dateOfBirth.value === null ||
        this.editConsultantForm.controls.dateOfBirth.value === undefined
      ) {
        this.dobDate = '';
      } else {
        const dob = this.editConsultantForm.controls.dateOfBirth.value;
        const dobDate1 = new Date(Date.parse(dob));
        this.dobDate = this.datepipe.transform(dobDate1, 'MM-dd-yyyy');
      }
      if (
        this.editConsultantForm.controls.refDate.value === '' ||
        this.editConsultantForm.controls.refDate.value === null ||
        this.editConsultantForm.controls.refDate.value === undefined
      ) {
        this.dobDate = '';
      } else {
        const refferedDate = this.editConsultantForm.controls.refDate.value;
        const dobDate1 = new Date(Date.parse(refferedDate));
        this.refferedDate1 = this.datepipe.transform(dobDate1, 'MM-dd-yyyy');
      }
      if (
        this.editConsultantForm.controls.availableDate.value === '' ||
        this.editConsultantForm.controls.availableDate.value === null ||
        this.editConsultantForm.controls.availableDate.value === undefined
      ) {
        this.dobDate = '';
      } else {
        const avaliDate = this.editConsultantForm.controls.availableDate.value;
        const avail = new Date(Date.parse(avaliDate));
        this.availDate1 = this.datepipe.transform(avail, 'MM-dd-yyyy');
      }
      if (
        this.editConsultantForm.controls.Status.value === '' ||
        this.editConsultantForm.controls.Status.value === null ||
        this.editConsultantForm.controls.Status.value === undefined
      ) {
        this.userStatus = '';
      } else {
        this.userStatus = this.editConsultantForm.controls.Status.value;
      }
      if (
        this.editConsultantForm.controls.dateOfBirth.value === '' ||
        this.editConsultantForm.controls.dateOfBirth.value === null ||
        this.editConsultantForm.controls.dateOfBirth.value === undefined
      ) {
        this.dobDate = '';
      } else {
        const dob = this.editConsultantForm.controls.dateOfBirth.value;
        const dobDate1 = new Date(Date.parse(dob));
        this.dobDate = this.datepipe.transform(dobDate1, 'MM-dd-yyyy');
      }
      if (
        this.editConsultantForm.controls.availableDate.value === '' ||
        this.editConsultantForm.controls.availableDate.value === null ||
        this.editConsultantForm.controls.availableDate.value === undefined
      ) {
        this.availDate1 = '';
      } else {
        const avaliDate = this.editConsultantForm.controls.availableDate.value;
        const avail = new Date(Date.parse(avaliDate));
        this.availDate1 = this.datepipe.transform(avail, 'MM-dd-yyyy');
      }
      if (
        this.editConsultantForm.controls.availableDate.value === '' ||
        this.editConsultantForm.controls.availableDate.value === undefined ||
        this.editConsultantForm.controls.availableDate.value === null
      ) {
        this.refffDate = '';
      } else {
        const refDate = this.editConsultantForm.controls.refDate.value;
        this.refffDate = this.datepipe.transform(refDate, 'MM-dd-yyyy');
      }
      // if (
      //   this.editConsultantForm.controls.alternateEmailId.value === '' ||
      //   this.editConsultantForm.controls.alternateEmailId.value === undefined ||
      //   this.editConsultantForm.controls.alternateEmailId.value === null
      // ) {
        const skill = this.editConsultantForm.get('primarySkills').value;
        this.filterId = skill.map((x) => x.code).map(Number);
        const skills = this.editConsultantForm.get('secondarySkills').value;
        this.selectedsecondarySkillsId = skills.map((x) => x.code).map(Number);
        const data = {
          accId: this.actId.accId,
          altEmail: this.editConsultantForm.controls.alternateEmailId.value,
          // tslint:disable-next-line:max-line-length
          availability: this.editConsultantForm.controls.availability.value === undefined || this.editConsultantForm.controls.availability.value === '' ? '' : this.editConsultantForm.controls.availability.value.code,
          availableDate: this.availDate1 === undefined || this.availDate1 === '' ? '' : this.availDate1,
          status: this.userStatus === undefined || this.userStatus === '' ? '' : this.userStatus,
          comment: this.editConsultantForm.controls.comments.value === '' ? null : this.editConsultantForm.controls.comments.value,
          currCtc: this.editConsultantForm.controls.currentCTC.value === '' ? 0 : this.editConsultantForm.controls.currentCTC.value,
          currRate: this.editConsultantForm.controls.currentRate.value === '' ? 0 : this.editConsultantForm.controls.currentRate.value,
          dob: this.dobDate === undefined ? null : this.dobDate,
          education: this.editConsultantForm.controls.education.value === '' ? null : this.editConsultantForm.controls.education.value,
          email: this.editConsultantForm.controls.emailId.value,
          expMaxCtc: this.editConsultantForm.controls.expMaxCTC.value === '' ? 0 : this.editConsultantForm.controls.expMaxCTC.value,
          expMaxRate: this.editConsultantForm.controls.expMaxRate.value === '' ? 0 : this.editConsultantForm.controls.expMaxRate.value,
          expMinCtc: this.editConsultantForm.controls.expMinCTC.value === '' ? 0 : this.editConsultantForm.controls.expMinCTC.value,
          expMinRate: this.editConsultantForm.controls.expMinRate.value === '' ? 0 : this.editConsultantForm.controls.expMinRate.value,
          // tslint:disable-next-line:max-line-length
          experiance: this.editConsultantForm.controls.experience.value === '' ? '' : this.editConsultantForm.controls.experience.value === undefined ? '' : this.editConsultantForm.controls.experience.value,
          facebook: this.editConsultantForm.controls.facebook.value === '' ? null : this.editConsultantForm.controls.facebook.value,
          fname: this.editConsultantForm.controls.firstName.value,
          // tslint:disable-next-line:max-line-length
          occode: this.editConsultantForm.controls.homeNumDrp.value === undefined || this.editConsultantForm.controls.homeNumDrp.value === null ||
            this.editConsultantForm.controls.homeNumDrp.value === '' ? '' : this.editConsultantForm.controls.homeNumDrp.value.id,
          // tslint:disable-next-line:max-line-length
          mccode: this.editConsultantForm.controls.mobileDrp.value === undefined || this.editConsultantForm.controls.mobileDrp.value === null ||
            this.editConsultantForm.controls.mobileDrp.value === '' ? '' : this.editConsultantForm.controls.mobileDrp.value.id,
          // tslint:disable-next-line:max-line-length
          gender: this.editConsultantForm.controls.gender.value === undefined || this.editConsultantForm.controls.gender.value === '' ? '' : this.editConsultantForm.controls.gender.value,
          // tslint:disable-next-line:max-line-length
          homePhone: this.editConsultantForm.controls.homePhone.value === '' ? '' : this.editConsultantForm.controls.homePhone.value === null ? '' : this.editConsultantForm.controls.homePhone.value,
          jobTitle: this.editConsultantForm.controls.jobTitle.value === '' ? null : this.editConsultantForm.controls.jobTitle.value,
          linkedIn: this.editConsultantForm.controls.linkedIn.value === '' ? null : this.editConsultantForm.controls.linkedIn.value,
          // tslint:disable-next-line:max-line-length
          livingCountry: this.editConsultantForm.controls.livingCountry.value === undefined || this.editConsultantForm.controls.livingCountry.value === null || this.editConsultantForm.controls.livingCountry.value === '' ? '' : this.editConsultantForm.controls.livingCountry.value.code,
          // tslint:disable-next-line:max-line-length
          lkCurrency: this.editConsultantForm.controls.currency.value === undefined || this.editConsultantForm.controls.currency.value === null ? '' : this.editConsultantForm.controls.currency.value.code,
          // tslint:disable-next-line:max-line-length
          lkRelocation: this.editConsultantForm.controls.relocation.value === '' || this.editConsultantForm.controls.relocation.value === undefined || this.editConsultantForm.controls.relocation.value === null ? '' : this.editConsultantForm.controls.relocation.value.code,
          // tslint:disable-next-line:max-line-length
          lkVisaComment: this.editConsultantForm.controls.consultantworkstatusComments.value === '' || this.editConsultantForm.controls.consultantworkstatusComments.value === undefined || this.editConsultantForm.controls.consultantworkstatusComments.value === null ? '' : this.editConsultantForm.controls.consultantworkstatusComments.value,
          // tslint:disable-next-line:max-line-length
          lkVisaStatus: this.editConsultantForm.controls.consultantworkstatus.value === undefined || this.editConsultantForm.controls.consultantworkstatus.value === '' ? 0 : this.editConsultantForm.controls.consultantworkstatus.value,
          lname: this.editConsultantForm.controls.lastName.value,
          maddType: '',
          // tslint:disable-next-line:max-line-length
          maddress1: this.editConsultantForm.controls.mailingAddressLine1.value === '' ? null : this.editConsultantForm.controls.mailingAddressLine1.value,
          // tslint:disable-next-line:max-line-length
          maddress2: this.editConsultantForm.controls.mailingAddressline2.value === '' ? null : this.editConsultantForm.controls.mailingAddressline2.value,
          mcity: this.editConsultantForm.controls.mailingCity.value === '' ? null : this.editConsultantForm.controls.mailingCity.value,
          // tslint:disable-next-line:max-line-length
          mcountry: this.editConsultantForm.controls.mailingCountry.value === undefined || this.editConsultantForm.controls.mailingCountry.value === null || this.editConsultantForm.controls.mailingCountry.value === '' ? '' : this.editConsultantForm.controls.mailingCountry.value.code,
          mdistrict: '',
          mmailBox: this.editConsultantForm.controls.mailbox2.value,
          mname: this.editConsultantForm.controls.middleName.value,
          mobilePhone: this.editConsultantForm.controls.mobileNo.value,
          mphone: '',
          // tslint:disable-next-line:max-line-length
          mstate: this.editConsultantForm.controls.mailingState.value === undefined || this.editConsultantForm.controls.mailingState.value === null ? '' : this.editConsultantForm.controls.mailingState.value.code,
          mzip: this.editConsultantForm.controls.mailingZip.value === '' ? null : this.editConsultantForm.controls.mailingZip.value,
          paddType: '',
          // tslint:disable-next-line:max-line-length
          paddress1: this.editConsultantForm.controls.permanentAddressLine1.value === '' ? null : this.editConsultantForm.controls.permanentAddressLine1.value,
          // tslint:disable-next-line:max-line-length
          paddress2: this.editConsultantForm.controls.permanentAddressLine2.value === '' ? null : this.editConsultantForm.controls.permanentAddressLine2.value,
          pcity: this.editConsultantForm.controls.permanentCity.value === '' ? null : this.editConsultantForm.controls.permanentCity.value,
          // tslint:disable-next-line:max-line-length
          pcountry: this.editConsultantForm.controls.permanentCountry.value === undefined || this.editConsultantForm.controls.permanentCountry.value === '' ? '' : this.editConsultantForm.controls.permanentCountry.value,
          pdistrict: '',
          pmailBox: this.editConsultantForm.controls.mailbox1.value,
          pphone: '',
          // tslint:disable-next-line:max-line-length
          pstate: this.editConsultantForm.controls.permanentState.value === undefined || this.editConsultantForm.controls.permanentState.value === '' ? '' : this.editConsultantForm.controls.permanentState.value.code,
          pzip: this.editConsultantForm.controls.permanentZip.value === '' ? null : this.editConsultantForm.controls.permanentZip.value,
          referedBy: this.editConsultantForm.controls.referredBy.value,
          referedDate: this.refffDate,
          ssnNumber: this.editConsultantForm.controls.ssnNo.value === '' ? null : this.editConsultantForm.controls.ssnNo.value,
          twitter: this.editConsultantForm.controls.twitter.value === '' ? null : this.editConsultantForm.controls.twitter.value,
          // tslint:disable-next-line:max-line-length
          wokringCountry: this.editConsultantForm.controls.workingCountry.value === undefined || this.editConsultantForm.controls.workingCountry.value === null || this.editConsultantForm.controls.workingCountry.value === '' ? '' : this.editConsultantForm.controls.workingCountry.value.code,
          workLocationId: 0,
          hireType: '',
          startDate: '',
          endDate: '',
          primarySkills: this.filterId === undefined ? 0 : this.filterId,
          secondarySkills: this.selectedsecondarySkillsId === undefined ? 0 : this.selectedsecondarySkillsId,
          id: this.rowID
        };
        this.loader = true;
        this.buttondisable = true;
        this.apisService.putApi(environment.addConsultant, data).subscribe((res) => {
          this.updateResponse = res;
          this.loader = false;
          if (this.updateResponse.hasOwnProperty('success') === true) {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateResponse.message });
            setTimeout(() => {
              if(this.customerFlag === "C") {
                this.router.navigate(['/selectedcandidates']);
              } else {
                this.router.navigate(['/candidates']);
              }
            }, 2000);
            this.editConsultantForm.reset();
          }
        });
      // } 
      // else {
      //   this.displayActive = true;
      //   this.registerdStatus = true;
      //   this.loginActiveData = 'Do you want to send login credentials to email?';
      //   this.email1 = this.editConsultantForm.controls.emailId.value;
      // }
    }
  }

  cancel() {
    this.displayActive = false;
  }

  saveUserContacts() {
    const skill = this.editConsultantForm.get('primarySkills').value;
    this.filterId = skill.map((x) => x.code).map(Number);
    const skills = this.editConsultantForm.get('secondarySkills').value;
    this.selectedsecondarySkillsId = skills.map((x) => x.code).map(Number);
    const data = {
      accId: this.actId.accId,
      altEmail: this.editConsultantForm.controls.alternateEmailId.value,
      // tslint:disable-next-line:max-line-length
      availability: this.editConsultantForm.controls.availability.value === undefined || this.editConsultantForm.controls.availability.value === '' ? '' : this.editConsultantForm.controls.availability.value.code,
      availableDate: this.availDate1 === undefined || this.availDate1 === '' ? '' : this.availDate1,
      status: this.userStatus === undefined || this.userStatus === '' ? '' : this.userStatus,
      comment: this.editConsultantForm.controls.comments.value === '' ? null : this.editConsultantForm.controls.comments.value,
      currCtc: this.editConsultantForm.controls.currentCTC.value === '' ? 0 : this.editConsultantForm.controls.currentCTC.value,
      currRate: this.editConsultantForm.controls.currentRate.value === '' ? 0 : this.editConsultantForm.controls.currentRate.value,
      dob: this.dobDate === undefined ? null : this.dobDate,
      education: this.editConsultantForm.controls.education.value === '' ? null : this.editConsultantForm.controls.education.value,
      email: this.editConsultantForm.controls.emailId.value,
      expMaxCtc: this.editConsultantForm.controls.expMaxCTC.value === '' ? 0 : this.editConsultantForm.controls.expMaxCTC.value,
      expMaxRate: this.editConsultantForm.controls.expMaxRate.value === '' ? 0 : this.editConsultantForm.controls.expMaxRate.value,
      expMinCtc: this.editConsultantForm.controls.expMinCTC.value === '' ? 0 : this.editConsultantForm.controls.expMinCTC.value,
      expMinRate: this.editConsultantForm.controls.expMinRate.value === '' ? 0 : this.editConsultantForm.controls.expMinRate.value,
      // tslint:disable-next-line:max-line-length
      experiance: this.editConsultantForm.controls.experience.value === '' ? '' : this.editConsultantForm.controls.experience.value === undefined ? '' : this.editConsultantForm.controls.experience.value,
      facebook: this.editConsultantForm.controls.facebook.value === '' ? null : this.editConsultantForm.controls.facebook.value,
      fname: this.editConsultantForm.controls.firstName.value,
      // tslint:disable-next-line:max-line-length
      occode: this.editConsultantForm.controls.homeNumDrp.value === undefined || this.editConsultantForm.controls.homeNumDrp.value === null ||
        this.editConsultantForm.controls.homeNumDrp.value === '' ? '' : this.editConsultantForm.controls.homeNumDrp.value.id,
      // tslint:disable-next-line:max-line-length
      mccode: this.editConsultantForm.controls.mobileDrp.value === undefined || this.editConsultantForm.controls.mobileDrp.value === null ||
        this.editConsultantForm.controls.mobileDrp.value === '' ? '' : this.editConsultantForm.controls.mobileDrp.value.id,
      // tslint:disable-next-line:max-line-length
      gender: this.editConsultantForm.controls.gender.value === undefined || this.editConsultantForm.controls.gender.value === '' ? '' : this.editConsultantForm.controls.gender.value,
      // tslint:disable-next-line:max-line-length
      homePhone: this.editConsultantForm.controls.homePhone.value === '' ? '' : this.editConsultantForm.controls.homePhone.value === null ? '' : this.editConsultantForm.controls.homePhone.value,
      jobTitle: this.editConsultantForm.controls.jobTitle.value === '' ? null : this.editConsultantForm.controls.jobTitle.value,
      linkedIn: this.editConsultantForm.controls.linkedIn.value === '' ? null : this.editConsultantForm.controls.linkedIn.value,
      // tslint:disable-next-line:max-line-length
      livingCountry: this.editConsultantForm.controls.livingCountry.value === undefined || this.editConsultantForm.controls.livingCountry.value === null || this.editConsultantForm.controls.livingCountry.value === '' ? '' : this.editConsultantForm.controls.livingCountry.value.code,
      // tslint:disable-next-line:max-line-length
      lkCurrency: this.editConsultantForm.controls.currency.value === undefined || this.editConsultantForm.controls.currency.value === null ? '' : this.editConsultantForm.controls.currency.value.code,
      // tslint:disable-next-line:max-line-length
      lkRelocation: this.editConsultantForm.controls.relocation.value === '' || this.editConsultantForm.controls.relocation.value === undefined || this.editConsultantForm.controls.relocation.value === null ? '' : this.editConsultantForm.controls.relocation.value.code,
      // tslint:disable-next-line:max-line-length
      lkVisaComment: this.editConsultantForm.controls.consultantworkstatusComments.value === '' || this.editConsultantForm.controls.consultantworkstatusComments.value === undefined || this.editConsultantForm.controls.consultantworkstatusComments.value === null ? '' : this.editConsultantForm.controls.consultantworkstatusComments.value,
      // tslint:disable-next-line:max-line-length
      lkVisaStatus: this.editConsultantForm.controls.consultantworkstatus.value === undefined || this.editConsultantForm.controls.consultantworkstatus.value === '' ? 0 : this.editConsultantForm.controls.consultantworkstatus.value,
      lname: this.editConsultantForm.controls.lastName.value,
      maddType: '',
      // tslint:disable-next-line:max-line-length
      maddress1: this.editConsultantForm.controls.mailingAddressLine1.value === '' ? null : this.editConsultantForm.controls.mailingAddressLine1.value,
      // tslint:disable-next-line:max-line-length
      maddress2: this.editConsultantForm.controls.mailingAddressline2.value === '' ? null : this.editConsultantForm.controls.mailingAddressline2.value,
      mcity: this.editConsultantForm.controls.mailingCity.value === '' ? null : this.editConsultantForm.controls.mailingCity.value,
      // tslint:disable-next-line:max-line-length
      mcountry: this.editConsultantForm.controls.mailingCountry.value === undefined || this.editConsultantForm.controls.mailingCountry.value === null || this.editConsultantForm.controls.mailingCountry.value === '' ? '' : this.editConsultantForm.controls.mailingCountry.value.code,
      mdistrict: '',
      mmailBox: this.editConsultantForm.controls.mailbox2.value,
      mname: this.editConsultantForm.controls.middleName.value,
      mobilePhone: this.editConsultantForm.controls.mobileNo.value,
      mphone: '',
      // tslint:disable-next-line:max-line-length
      mstate: this.editConsultantForm.controls.mailingState.value === undefined || this.editConsultantForm.controls.mailingState.value === null ? '' : this.editConsultantForm.controls.mailingState.value.code,
      mzip: this.editConsultantForm.controls.mailingZip.value === '' ? null : this.editConsultantForm.controls.mailingZip.value,
      paddType: '',
      // tslint:disable-next-line:max-line-length
      paddress1: this.editConsultantForm.controls.permanentAddressLine1.value === '' ? null : this.editConsultantForm.controls.permanentAddressLine1.value,
      // tslint:disable-next-line:max-line-length
      paddress2: this.editConsultantForm.controls.permanentAddressLine2.value === '' ? null : this.editConsultantForm.controls.permanentAddressLine2.value,
      pcity: this.editConsultantForm.controls.permanentCity.value === '' ? null : this.editConsultantForm.controls.permanentCity.value,
      // tslint:disable-next-line:max-line-length
      pcountry: this.editConsultantForm.controls.permanentCountry.value === undefined || this.editConsultantForm.controls.permanentCountry.value === '' ? '' : this.editConsultantForm.controls.permanentCountry.value,
      pdistrict: '',
      pmailBox: this.editConsultantForm.controls.mailbox1.value,
      pphone: '',
      // tslint:disable-next-line:max-line-length
      pstate: this.editConsultantForm.controls.permanentState.value === undefined || this.editConsultantForm.controls.permanentState.value === '' ? '' : this.editConsultantForm.controls.permanentState.value.code,
      pzip: this.editConsultantForm.controls.permanentZip.value === '' ? null : this.editConsultantForm.controls.permanentZip.value,
      referedBy: this.editConsultantForm.controls.referredBy.value,
      referedDate: this.refffDate,
      ssnNumber: this.editConsultantForm.controls.ssnNo.value === '' ? null : this.editConsultantForm.controls.ssnNo.value,
      twitter: this.editConsultantForm.controls.twitter.value === '' ? null : this.editConsultantForm.controls.twitter.value,
      // tslint:disable-next-line:max-line-length
      wokringCountry: this.editConsultantForm.controls.workingCountry.value === undefined || this.editConsultantForm.controls.workingCountry.value === null || this.editConsultantForm.controls.workingCountry.value === '' ? '' : this.editConsultantForm.controls.workingCountry.value.code,
      workLocationId: 0,
      hireType: '',
      startDate: '',
      endDate: '',
      primarySkills: this.filterId === undefined ? 0 : this.filterId,
      secondarySkills: this.selectedsecondarySkillsId === undefined ? 0 : this.selectedsecondarySkillsId,
      id: this.rowID
    };
    this.loader = true;
    this.buttondisable = true;
    this.apisService.putApi(environment.addConsultant, data).subscribe((res) => {
      this.updateResponse = res;
      this.loader = false;
      if (this.updateResponse.hasOwnProperty('success') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateResponse.message });
        setTimeout(() => {
          if(this.customerFlag === "C") {
            this.router.navigate(['/selectedcandidates']);
          } else {
            this.router.navigate(['/candidates']);
          }
        }, 2000);
        this.editConsultantForm.reset();
      }
    });
  }



  goto() {
    if (this.customerFlag === "C") {
      this.router.navigate(['/selectedcandidates']);
    } else {
      if (this.type === 'open') {
        this.router.navigate(['/open-position-edit']);
      } else {
        this.router.navigate(['/candidates']);
      }
    }
  }
  myFunction() {
    this.hide = !this.hide;
    this.hideIcon = false;
    this.showIcon1 = true;
  }
  myFunction1() {
    this.hide = !this.hide;
    this.hideIcon = true;
    this.showIcon1 = false;
  }
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
  emailToast() {
    if (this.editConsultantForm.controls.emailId.invalid && this.editConsultantForm.controls.emailId.errors.pattern) {
    }
    // tslint:disable-next-line: max-line-length
    else if (this.editConsultantForm.controls.emailId.value === '' || this.editConsultantForm.controls.emailId.value === null || this.editConsultantForm.controls.emailId.value === undefined) {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please enter email.' });
    }
    else {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
    }
  }
  altEmailToast() {
    if (this.editConsultantForm.controls.alternateEmailId.invalid && this.editConsultantForm.controls.alternateEmailId.errors.pattern) {
    }
    else if (this.editConsultantForm.controls.alternateEmailId.value === '') {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please enter alternate email.' });
    }
    else {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
    }
  }
  toggle(event, data, rowData) {
    this.rowdata = data.id;
    this.items = [
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => {
          this.editAttachment(data);
        },
      },
      {
        label: 'Download',
        icon: 'pi pi-download',
        command: () => {
          this.downloadFileapi();
        },
      }
    ];
  }

  editAttachment(data) {
    this.editDisplay = true;
    this.editAttachmentForm.controls.attachmentStatus.patchValue(this.editStatus.filter((x) => x.code === data.lkStatus)[0]);
    this.editAttachmentForm.controls.attachmentType.patchValue(this.protocol.filter((x) => x.code === data.lkAttachmentType)[0]);
    this.editAttachmentForm.controls.attachmentName.patchValue(data.fileName);
    this.editAttachmentForm.controls.attachmentTitle.patchValue(data.title);
    this.editAttachmentForm.controls.attachmentDate.patchValue(data.dateOfAttachment);
    this.editAttachmentForm.controls.attachmentComments.patchValue(data.comments);
  }
  downloadFileapi() {
    this.isUploading = true;
    this.filedata = this.rowdata;
    this.apisService.getApi(environment.downloadres + this.filedata).subscribe((res: any) => {
      this.downloadData = res;
      window.location.assign(this.downloadData.downloadUrl);
      this.isUploading = false;
    });
  }
  updateMvault() {
    this.disable = true;
    const reqbody = {
      // tslint:disable-next-line:max-line-length
      attType: this.editAttachmentForm.controls.attachmentType.value === undefined || this.editAttachmentForm.controls.attachmentType.value === null || this.editAttachmentForm.controls.attachmentType.value === '' ? '' : this.editAttachmentForm.controls.attachmentType.value.code,
      // tslint:disable-next-line:max-line-length
      title: this.editAttachmentForm.controls.attachmentTitle.value === undefined || this.editAttachmentForm.controls.attachmentTitle.value === null || this.editAttachmentForm.controls.attachmentTitle.value === '' ? '' : this.editAttachmentForm.controls.attachmentTitle.value,
      // tslint:disable-next-line:max-line-length
      comments: this.editAttachmentForm.controls.attachmentComments.value === undefined || this.editAttachmentForm.controls.attachmentComments.value === null || this.editAttachmentForm.controls.attachmentComments.value === '' ? '' : this.editAttachmentForm.controls.attachmentComments.value,
      // tslint:disable-next-line:max-line-length
      status: this.editAttachmentForm.controls.attachmentStatus.value === undefined || this.editAttachmentForm.controls.attachmentStatus.value === null || this.editAttachmentForm.controls.attachmentStatus.value === '' ? '' : this.editAttachmentForm.controls.attachmentStatus.value.code,
      id: this.rowdata
    };
    this.editDisplay = false;
    this.loader = true;
    this.apisService.putApi(environment.updateMvault, reqbody).subscribe((res: any) => {
      this.loader = false;
      this.getCandidatesResumes(this.userId);
      if (res.hasOwnProperty('success') === true) {

        this.messageService.add({
          key: 'br',
          severity: '',
          life: 3000,
          detail: res.message,
        });
        this.disable = false;
      } else {
        this.messageService.add({
          key: 'br',
          severity: '',
          life: 3000,
          detail: res.error,
        });
      }
    });

  }
  handleFileInput(event) {
    this.fileName = event.target.files[0];
    this.name = this.fileName.name;
    if (this.fileName.name.length > 25) {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'File name should be 25 alphabets.' });
      this.cancelfiles();
    }
    if (this.fileName.size > 10485760) {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please upload below 10MB.' });
      this.cancelfiles();
    }
    else {
      this.fileType = this.fileName.name.split('.').pop();
      const formData: FormData = new FormData();
      formData.append('file', this.fileName);
      this.filedata = formData;
      // tslint:disable-next-line: no-conditional-assignment
      if (this.filedata = formData) {
        this.filesdatass = true;
      } else {
        this.filesdatass = false;
      }
    }
  }
  uploadAttachment() {
    if (this.mvaultForm.invalid) {
      const controls = this.mvaultForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    }
    else {
      this.hide = true;
      this.disable = true;
      this.display = false;
      this.loader = true;
      this.apisService.postApi(environment.profileUpload + this.userId + '/' + 'U', this.filedata).subscribe((res: any) => {
        this.loader = false;
        this.fileRes = res;
        this.fileData = res.data;
        this.filePath = res.data.path;
        this.fileSize = res.data.fileSize;
        this.hide = false;
        this.addProfile();
      });
    }
    this.disable = false;
  }
  addProfile() {
    if (this.mvaultForm.invalid) {
      const controls = this.mvaultForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      const addData = {
        objectId: this.userId,
        objectType: 'U',
        title: this.mvaultForm.controls.attachmentTitle.value,
        attPath: this.filePath,
        fileName: this.fileName.name,
        size: this.fileSize,
        extension: this.fileType,
        comments: ' ',
        verifiedBy: 0,
        // tslint:disable-next-line:max-line-length
        attType: this.mvaultForm.controls.dataAttachements.value === null || this.mvaultForm.controls.dataAttachements.value === undefined || this.mvaultForm.controls.dataAttachements.value === '' ? '' : this.mvaultForm.controls.dataAttachements.value.code
      };
      this.loader = true;
      this.apisService.postApi(environment.addProfile, addData).subscribe((res: any) => {
        this.loader = false;
        this.getData = res;
        this.disable = false;
        if (this.getData.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message });
          this.getCandidatesResumes(this.userId);
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error });
        }
      });
    }
  }
  emailverification() {
    this.displayemail1 = false;
    const email = this.editConsultantForm.controls.emailId.value;
    if (email === '' || email === null || email === undefined) {
    } else {
      if (this.editConsultantForm.controls.emailId.invalid && this.editConsultantForm.controls.emailId.errors.pattern) {
      }
      else {
        this.altEmailSpinnerHide = true;
        const emailCheck = this.consultantRowData.id + "/" + email;
        this.apisService.getApi(environment.existEmailValidation + emailCheck).subscribe((res) => {
          this.emailIdData = res;
          if (this.emailIdData.isEmailExisted === true) {
            this.altEmailSpinnerHide = false;
            this.displayemail1 = true;
            setTimeout(() => {
              this.displayemail1 = false;
            }, 1000);
            this.editConsultantForm.controls.emailId.reset();
          } else {
            this.altEmailSpinnerHide = false;
            this.altEmailCheckHide = true;
            setTimeout(() => {
              this.altEmailCheckHide = false;
            }, 3000);
          }
        });
      }
    }
  }

  AlternateEmail() {
    // this.displayemail1 = false;
    // const email = this.editConsultantForm.controls.alternateEmailId.value;
    // if (email === '' || email === null || email === undefined) {
    // } else {
    //   if (this.editConsultantForm.controls.alternateEmailId.invalid && this.editConsultantForm.controls.alternateEmailId.errors.pattern) {
    //   }
    //   else {
    //     this.altEmailSpinnerHide = true;
    //     this.apisService.getApi(environment.emailVaildation + email).subscribe((res) => {
    //       this.altEmailId = res;
    //       if (this.altEmailId.validation === true) {
    //         this.altEmailSpinnerHide = false;
    //         this.displayemail1 = true;
    //         setTimeout(() => {
    //           this.displayemail1 = false;
    //         }, 1000);
    //         this.editConsultantForm.controls.alternateEmailId.reset();
    //       } else {
    //         this.altEmailSpinnerHide = false;
    //         this.altEmailCheckHide = true;
    //         setTimeout(() => {
    //           this.altEmailCheckHide = false;
    //         }, 3000);
    //       }
    //     });
    //   }
    // }
  }
  getPhon() {
    const num = this.editConsultantForm.controls.mobileNo.value;
    if (num === '' || num === null || num === undefined || num === '(___) ___-____') {
    }
    else {
      this.phSpinnerHide = true;
      this.apisService.getApi(environment.numberValidation + this.numId + '/' + true + '?' + 'phoneNumber=' + num).subscribe(res => {
        this.officenum = res;
        this.officenum = res.success;
        if (this.officenum === false) {
          this.phSpinnerHide = false;
          this.officenummsg = 'mobile number already exist';
          setTimeout(() => {
            this.officenummsg = '';
          }, 5000);
          this.editConsultantForm.get('mobileNo').reset();
        }
        else {
          this.phSpinnerHide = false;
          this.phCheckHide = true;
          setTimeout(() => {
            this.phCheckHide = false;
          }, 3000);
        }
      });
    }
  }
  getmobi() {
    const mob = this.editConsultantForm.controls.homePhone.value;
    if (mob === '' || mob === null || mob === undefined || mob === '(___) ___-____') {
    }
    else {
      const text = mob;
      const result = text.replace(' ', '-');
      this.phSpinnerHide1 = true;
      this.apisService.getApi(environment.numberValidation + this.numId + '/' + false + '?' + 'phoneNumber=' + mob).subscribe(res => {
        this.mobinum = res;
        this.mobinum = res.success;
        if (this.mobinum === false) {
          this.phSpinnerHide1 = false;
          this.mobinummsg = 'home number already exist';
          setTimeout(() => {
            this.mobinummsg = '';
          }, 5000);
          this.editConsultantForm.get('homePhone').reset();
        }
        else {
          this.phSpinnerHide1 = false;
          this.phCheckHide1 = true;
          setTimeout(() => {
            this.phCheckHide1 = false;
          }, 3000);
        }
      });
    }

  }
  attachfile() {
    this.mvaultForm.controls.attachmentform.reset();
    this.crossicon = false;
    this.attachmentfile = false;
    this.profileupload = true;
  }
  cancelfiles() {
    this.mvaultForm.controls.attachmentform.reset();
    this.filesdatass = false;
    this.attachmentfile = false;
    this.profileupload = true;
  }
}
