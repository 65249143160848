import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-servicesbayeditcontacts',
  templateUrl: './servicesbayeditcontacts.component.html',
  styleUrls: ['./servicesbayeditcontacts.component.scss'],
  providers: [DatePipe, MessageService],
})
export class ServicesbayeditcontactsComponent implements OnInit {
  // Dropdwon values for gender
  genderDrop = [
    { id: 'M', name: 'Male' },
    { id: 'F', name: 'Female' },
  ];
  // Drop down values for status if account is active
  status2D = [
    { code: 'A', id: 'ULS', label: 'Active' },
    { code: 'I', id: 'ULS', label: 'Inactive' },
  ];
  EditContactsForm: FormGroup;
  location = [{ acc_add_id: -1, location_name: 'Select Work Location' }];
  country: any;
  stateData: any = [];
  rolesData: any = [];
  editData: any;
  accountType: string;
  value: string;
  display: boolean;
  display1: boolean;
  status: any;
  workLocation: any;
  data: any;
  getData: any;
  role: any = [];
  exp: any = [];
  specializationSkills: any;
  id: any;
  fileName: any;
  userIdValue: any;
  url: any;
  imageSuccess: boolean;
  idValue: any;
  accountTypeValue: any;
  reqUpdData: any;
  skillData = [];
  skillDataId = [];
  accountid: any;
  loader: boolean;
  profileRespons: any;
  url1: any;
  actId: any;
  accID: any;
  addIDDD: any;
  addID: any;
  userID: any;
  statusDisable = false;
  rowid: string;
  displayNumber: boolean;
  officenum: any;
  officenummsg: string;
  disable: boolean;
  usid: any;
  empflag: any;
  conflag: any;
  acctid: any;
  conaccid: any;
  editsalesaccid: any;
  loginData: any;
  roleid: any;
  conteditid: any;
  phSpinnerHide: boolean;
  phCheckHide: boolean;
  logoimage: any;
  phSpinnerHide1: boolean;
  mobinum: any;
  mobinummsg: string;
  phCheckHide1: boolean;
  numId: string;
  ProfileImg: string;
  x: any;
  workLoc: any=[];
  status1: any = [];
  countrycodedropdown: any = [];
  usrroleid: any;
  getdata: any;
  getaccid: any;
  patchlocationString: string;
  editLocationdata: any;
  constructor(
    private fb: FormBuilder,
    private apisService: ApisService,
    private router: Router,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private sanitizer: DomSanitizer,
  ) {
    this.usid = JSON.parse(apisService.decodeData()).userId;
    this.editsalesaccid = JSON.parse(sessionStorage.getItem('editsalesaccid'));
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.conaccid = routeParams.params.conaccid;
    this.empflag = routeParams.params.empflag;
    this.conflag = routeParams.params.conflag;
    this.conteditid = routeParams.params.coneditid;
    this.loginData = JSON.parse(this.apisService.decodeData());
    this.getdata = routeParams.params.getdata;
    this.getaccid = routeParams.params.accid;
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
      this.roleid = this.loginData.roles[0].id;
    } else {
      this.roleid = this.usrroleid;
    }
    if (this.getdata === 'AccAct') {
      this.breadcrumbService.setItems([
        { label: 'Accounts Activity', routerLink: '/accounts-activity' },
        {
          label: 'Account Details', routerLink: ['/acc-adminmenu/acc-details', this.getaccid, {
            getdata: 'AccAct'
          }]
        },
        {
          label: 'Contacts', routerLink: ['/acc-adminmenu/acc-contact/', this.getaccid, {
            getdata: 'AccAct'
          }],
        },
        { label: 'Contact Edit', routerLink: '#' },
      ]);
      this.apisService.accActivitycondition.next('AccAct');
    } else {
      this.breadcrumbService.setItems([
        { label: 'Account Details', routerLink: ['/acc-adminmenu/acc-details', this.roleid] },
        { label: 'Contacts', routerLink: ['/acc-adminmenu/acc-contact/', this.roleid], },
        { label: 'Contact Edit', routerLink: '#' },
      ]);
    }

    this.numId = sessionStorage.getItem('numid');
    if (JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 8) {
      this.accountTypeValue = JSON.parse(
        sessionStorage.getItem('loginData')
      ).primaryrole;
    } else {
      sessionStorage.getItem('account_Type');
      this.accountTypeValue = sessionStorage.getItem('account_Type');
    }
    if (
      JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 0 ||
      JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 1
    ) {
      this.accountid = Number(sessionStorage.getItem('account_id'));
    } else {
      this.accountid = JSON.parse(sessionStorage.getItem('loginData')).orgId;
    }
    this.getRoleDD();
    // this.getlocation();
    this.getCountryDropD();
  }
  ngOnInit() {
    this.acctid = JSON.parse(this.apisService.decodeData()).userId;

    this.EditContactsForm = this.fb.group({
      FirstName: ['', [Validators.required, Validators.maxLength(25), Validators.pattern('^[\\sa-zA-z]*$')],],
      MidleName: ['', [Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(15)]],
      LastName: ['', [Validators.required, Validators.maxLength(25), Validators.pattern('^[\\sa-zA-z]*$')],],
      Email: ['', [Validators.required, Validators.maxLength(60), Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),],],
      Gender: [''],
      Title: ['', [Validators.required, Validators.maxLength(25), Validators.pattern('^[\\sa-zA-z]*$')]],
      OfficePh: ['', Validators.required],
      Mobphone: [''],
      worklocation: [''],
      Experience: [''],
      SSNnum: ['', Validators.maxLength(25)],
      Role: [''],
      Skills: [''],
      Status: [''],
      Education: [''],
      addressLine1: ['', Validators.maxLength(50)],
      addressLine2: ['', Validators.maxLength(50)],
      mailboxNumber: ['', [Validators.maxLength(10)]],
      city: ['', [Validators.pattern('^[\\sa-zA-z0-9]*$'), Validators.maxLength(20)]],
      country: [''],
      state: [''],
      zip: ['', [Validators.pattern('^[0-9-]+$'),Validators.maxLength(10)]],
      mobileDrp: [''],
      mobileDrppp: [''],
    });
    this.statusDD();
    this.edit();
    this.getdrop();
  }
  emailToast() {
    if (this.EditContactsForm.controls.Email.invalid && this.EditContactsForm.controls.Email.errors.pattern) {
    }
    // tslint:disable-next-line: max-line-length
    else if (this.EditContactsForm.controls.Email.value === '' || this.EditContactsForm.controls.Email.value === null || this.EditContactsForm.controls.Email.value === undefined) {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please enter email.' });
    }
    else {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
    }
  }
  // On change method for phone number country codes dropdwon for future enhancement
  countryids(event) {
  }
  // It is used for phone number country codes dropdwon values
  getdrop() {
    this.countrycodedropdown = this.apisService.getdropdwon();
  }
  copyInputMessage(data) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (data));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
  // It is used for status drop down values if account is registered
  statusDD() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'ULS').subscribe((res: any) => {
      this.status = res.data;
    });
  }
  // It is used to get roles dropdwon values
  getRoleDD() {
    const rolevalue = sessionStorage.getItem('Act_type');
    const getroles = rolevalue === 'B' ? 'V' : rolevalue;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.roleDD + getroles).subscribe((res: any) => {
      this.role = res.data;
    });
  }
  // It is used for the displaying the country Dropdown Values
  getCountryDropD() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.preCountryDropdown).subscribe((res) => {
      this.country = res.data;
    });
  }
  // It is used for the displaying the StateB Dropdown based on country Values
  getStateByUrl(code) {
    if (code === null || code === '' || code === undefined) {
      this.stateData = [];
    }
    else { // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.state + '/' + code).subscribe((res) => {
        this.stateData = res.data;
        this.EditContactsForm.controls.state.patchValue(
          this.stateData.filter((x) => x.code === this.data.state)[0]
        );
      });
    }
  }
  // It is used for the displaying the location Dropdown Values
  getlocation() {
    this.actId = JSON.parse(this.apisService.decodeData());
    this.rowid = sessionStorage.getItem('acct_rowid');
    this.id = this.actId.accId;
    const id = this.actId.roles[0].id === 1 ? this.rowid : this.actId.accId;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.location + '/' + this.id).subscribe((res: any) => {
      this.workLoc = res.data;
      this.EditContactsForm.controls.worklocation.patchValue(this.workLoc.filter((x) => x.locId === this.data.workLocationId)[0]);
      this.editLocationdata = this.workLoc.filter((x) => x.locId === this.data.workLocationId)[0]
      this.patchlocationString = this.editLocationdata?.name  + '\n' + this.editLocationdata?.address1 + ',' + this.editLocationdata?.city + ',' + this.editLocationdata?.state + ',' + this.editLocationdata?.country
    });
  }
  // It is used for the get the data and binding it
  edit() {
    this.statusDisable = false;
    const contactI = Number(this.route.snapshot.paramMap.get('id'));
    this.loader = true;
    this.apisService.getApi(environment.addContact + contactI).subscribe((res: any) => {
      this.data = res.data;
      this.loader = false;
      this.logoimage = this.data.logo;
      this.statusDisable = this.data.status === 'R' ? true : false;
      this.status1 = this.data.status === 'R' ? this.status : this.status2D;
      this.accID = res.data.accId;
      this.addID = res.data.addId;
      this.userID = res.data.userId;
      if (this.data.gender === 'M') {
        this.ProfileImg = 'https://d2r8nxfsv3v6y3.cloudfront.net/images/male.png';
      }
      else {
        this.ProfileImg = 'https://d2r8nxfsv3v6y3.cloudfront.net/images/female.png';
      }
      this.getStateByUrl(this.data.country);
      const Rolelist = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.role.length; i++) {
        // tslint:disable-next-line: prefer-for-of
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.data.roleList.length; j++) {
          if (this.role[i].id === this.data.roleList[j].id) {
            Rolelist.push(this.role[i]);
          } else {
          }
        }
      }

      this.loader = false;
      this.getlocation();
      const imgPath = this.data.profileGENERALIMAGE;
      this.EditContactsForm.controls.country.patchValue(this.country.filter((x) => x.code === this.data.country)[0]);
      this.EditContactsForm.controls.Gender.patchValue(this.genderDrop.filter((x) => x.id === this.data.gender)[0]);
      this.EditContactsForm.controls.Role.patchValue(Rolelist);
      this.EditContactsForm.controls.FirstName.patchValue(this.data.firstName);
      this.EditContactsForm.controls.MidleName.patchValue(this.data.middleName);
      this.EditContactsForm.controls.LastName.patchValue(this.data.lastName);
      this.EditContactsForm.controls.Email.patchValue(this.data.email);
      this.EditContactsForm.controls.Title.patchValue(this.data.designation);
      this.EditContactsForm.controls.OfficePh.patchValue(this.data.officePhone);
      this.EditContactsForm.controls.Mobphone.patchValue(this.data.mobilePhone);
      this.EditContactsForm.controls.addressLine1.patchValue(this.data.address1);
      this.EditContactsForm.controls.addressLine2.patchValue(this.data.address2);
      this.EditContactsForm.controls.mailboxNumber.patchValue(this.data.mailbox);
      this.EditContactsForm.controls.city.patchValue(this.data.city);
      this.EditContactsForm.controls.zip.patchValue(this.data.zip);
      this.EditContactsForm.controls.Status.patchValue(this.status.filter((x) => x.code === this.data?.status)[0]);
      this.EditContactsForm.controls.SSNnum.patchValue(this.data.ssnNumber);
      this.EditContactsForm.controls.Education.patchValue(this.data.education);
      this.EditContactsForm.controls.Skills.patchValue(this.skillData);
      this.EditContactsForm.controls.Gender.patchValue(this.genderDrop.filter((x) => x.id === this.data.gender)[0]);
      this.EditContactsForm.controls.country.patchValue(this.country.filter((x) => x.code === this.data.country)[0]);
      this.EditContactsForm.controls.Experience.patchValue(this.exp.filter((x) => x.id === Number(this.data.experience))[0]);
      this.EditContactsForm.controls.mobileDrppp.patchValue(this.countrycodedropdown.filter((x) => x.id === this.data.mobileCcode)[0]);
      this.EditContactsForm.controls.mobileDrp.patchValue(this.countrycodedropdown.filter((x) => x.id === this.data.officeCcode)[0]);
    });
  }
  // It is used for the updating the Edit Contact Details and keep the validations
  profileUpdate() {
    if (this.EditContactsForm.invalid) {
      const controls = this.EditContactsForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      const roleValue = this.EditContactsForm.controls.Role.value;
      this.rolesData = roleValue.map((x) => x.id);
      this.disable = true;
      const data1 = {
        accId: this.accID,
        addId: this.addID,
        address1: this.EditContactsForm.controls.addressLine1.value,
        address2: this.EditContactsForm.controls.addressLine2.value,
        city: this.EditContactsForm.controls.city.value,
        // tslint:disable-next-line:max-line-length
        country: this.EditContactsForm.controls.country.value === null || this.EditContactsForm.controls.country.value === undefined || this.EditContactsForm.controls.country.value === '' ? 'USA' : this.EditContactsForm.controls.country.value.code,
        email: this.EditContactsForm.controls.Email.value,
        fname: this.EditContactsForm.controls.FirstName.value,
        gender: this.EditContactsForm.controls.Gender.value == null || undefined ? 'M' : this.EditContactsForm.controls.Gender.value.id,
        id: this.userID,
        lname: this.EditContactsForm.controls.LastName.value,
        mailBox: this.EditContactsForm.controls.mailboxNumber.value,
        mname: this.EditContactsForm.controls.MidleName.value,
        officePhone: this.EditContactsForm.controls.OfficePh.value,
        phone: this.EditContactsForm.controls.Mobphone.value,
        roleList: this.rolesData,
        ssnNumber: this.EditContactsForm.controls.SSNnum.value,
        status: this.EditContactsForm.controls.Status.value == null || undefined ? 'A' : this.EditContactsForm.controls.Status.value.code,
        // tslint:disable-next-line:max-line-length
        state: this.EditContactsForm.controls.state.value === null || this.EditContactsForm.controls.state.value === undefined || this.EditContactsForm.controls.state.value === '' ? 'A' : this.EditContactsForm.controls.state.value.code,
        title: this.EditContactsForm.controls.Title.value,
        // tslint:disable-next-line:max-line-length
        workLocation: this.EditContactsForm.controls.worklocation.value == null || undefined ? '' : this.EditContactsForm.controls.worklocation.value.locId,
        zip: this.EditContactsForm.controls.zip.value,
        // tslint:disable-next-line:max-line-length
        mccode: this.EditContactsForm.controls.mobileDrppp.value === null || this.EditContactsForm.controls.mobileDrppp.value === '' || this.EditContactsForm.controls.mobileDrppp.value === undefined || this.EditContactsForm.controls.mobileDrppp.value === '' ? '' : this.EditContactsForm.controls.mobileDrppp.value.id,
        // tslint:disable-next-line:max-line-length
        occode: this.EditContactsForm.controls.mobileDrp.value === null || this.EditContactsForm.controls.mobileDrp.value === '' || this.EditContactsForm.controls.mobileDrp.value === undefined || this.EditContactsForm.controls.mobileDrp.value === '' ? '' : this.EditContactsForm.controls.mobileDrp.value.id
      };
      this.loader = true;
      this.apisService.putApi(environment.addContact, data1).subscribe((res) => {
        if (res.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
          setTimeout(() => {
            this.loader = false;
            // this.router.navigate(['/acc-adminmenu/acc-contact', this.roleid]
            // );
            if (this.getdata === 'AccAct') {
              this.router.navigate(['/acc-adminmenu/acc-contact/', this.getaccid], {
                queryParams: {
                  getdata: 'AccAct',
                }
              }
              );
              this.apisService.accActivitycondition.next('AccAct');
            } else {
              this.router.navigate(['/acc-adminmenu/acc-contact', this.roleid]
              );
            }
            this.disable = false;
          },
            600);
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
          this.disable = false;
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
          this.disable = false;
        }
      });
    }
    this.loader = false;
  }
  // It is used for routing the  vendor contact page based on role id
  homeroute() {
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.conteditid = routeParams.params.coneditid;
    if (this.getdata === 'AccAct') {
      this.router.navigate(['/acc-adminmenu/acc-contact/', this.getaccid], {
        queryParams: {
          getdata: 'AccAct',
        }
      }
      );
      this.apisService.accActivitycondition.next('AccAct');
    } else {
      this.router.navigate(['/acc-adminmenu/acc-contact', this.roleid]
      );
    }

    // if (
    //   JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 0 ||
    //   JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 1
    // ) {
    //   this.id = sessionStorage.getItem('account_id');
    // } else if (JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 6) {
    //   this.router.navigate(['/admin-menu/account-contacts', this.roleid], {
    //     queryParams: {
    //       contflag: 'conflag',
    //     }
    //   });
    // }
    // else {
    //   this.id = JSON.parse(sessionStorage.getItem('loginData')).orgId;
    //   this.router.navigate(['/admin-menu/account-contacts', this.id], {
    //     queryParams: {
    //       contflag: 'conflag',
    //     }
    //   });
    // }
  }
  // It is used to check office number already exists or not
  getPhon() {
    const num = this.EditContactsForm.controls.OfficePh.value;
    if (num === '' || num === null || num === undefined || num === '(___) ___-____') {
    }
    else {
      this.phSpinnerHide = true;
      // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.numberValidation + this.numId + '/' + true + '?' + 'phoneNumber=' + num).subscribe(res => {
        this.officenum = res;
        this.officenum = res.success;
        if (this.officenum === false) {
          this.phSpinnerHide = false;
          this.officenummsg = 'office number already exist';
          setTimeout(() => {
            this.officenummsg = '';
          }, 5000);
          this.EditContactsForm.get('OfficePh').reset();
        }
        else {
          this.phSpinnerHide = false;
          this.phCheckHide = true;
          setTimeout(() => {
            this.phCheckHide = false;
          }, 3000);

        }
      });
    }
  }
  // It is used to check mobile number already exist or not
  getmobi() {
    const mob = this.EditContactsForm.controls.Mobphone.value;
    if (mob === '' || mob === null || mob === undefined || mob === '(___) ___-____') {
    }
    else {
      const text = mob;
      const result = text.replace(' ', '-');
      this.phSpinnerHide1 = true;
      // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.numberValidation + this.numId + '/' + false + '?' + 'phoneNumber=' + mob).subscribe(res => {
        this.mobinum = res;
        this.mobinum = res.success;
        if (this.mobinum === false) {
          this.phSpinnerHide1 = false;
          this.mobinummsg = 'mobile number already exist';
          setTimeout(() => {
            this.mobinummsg = '';
          }, 5000);
          this.EditContactsForm.get('Mobphone').reset();
        }
        else {
          this.phSpinnerHide1 = false;
          this.phCheckHide1 = true;
          setTimeout(() => {
            this.phCheckHide1 = false;
          }, 3000);
        }
      });
    }
  }
  saveLocation(ev) {
    const data = ev.value
    this.patchlocationString = data.name  + '\n' + data.address1 + ',' + data.city + ',' + data.state + ',' + data.country
  }
}
