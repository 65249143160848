import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [MessageService, DatePipe]

})
export class ProfileComponent implements OnInit {

  gender = [
    { name: 'Male', code: 'M' },
    { name: 'Female', code: 'F' },
  ];
  Relocation = [
    { name: 'Yes', code: 'Y' },
    { name: 'No', code: 'N' },
  ];
  profileForm: FormGroup;
  userIdValue: any;
  countryData: any = [];
  StateData: any = [];
  actId: any;
  id: string;
  workLocations: any = [];
  responsedata: any;
  profileData: any;
  updateResponse: any;
  loader: boolean;
  fileName: any;
  imageSuccess: boolean;
  base64: any;
  remove: boolean;
  url1: any;
  url: any;
  officenum: any;
  officenummsg: string;
  addressdata: any;
  displayModal = false;
  showImg = false;
  showImg1 = false;
  displayModal1: boolean;
  profileImage: any;
  image: string;
  displayModal2: boolean;
  fileType: any;
  filedata: FormData;
  getData: any;
  disable: boolean;
  display: boolean;
  fileSize: any;
  data: any;
  fileData: any;
  filePath: any;
  event: any;
  fileNames: any;
  pathExtension: any;
  name: any;
  imageevent: any;
  iamgedownloadurl: any;
  delflag: any;
  phSpinnerHide: boolean;
  phCheckHide: boolean;
  userId: any;
  phSpinnerHide1: boolean;
  mobinum: any;
  mobinummsg: string;
  phCheckHide1: boolean;
  genderValue: string;
  male: boolean;
  female: boolean;
  topimageid: string;
  profilegender: string;
  roleId: any;
  addConsultantForm: FormGroup;
  preferencList: any;
  currencyValue: string;
  currencycode: any[];
  currencysymbl: any;
  lvcountry: any[];
  experienceData: any[];
  consultant: any[];
  currencyData: any;
  consultantProfile: any;
  updateId: any;
  updateProfile: any;
  displaypopup: boolean;
  dialogstatus: number;
  company: any;
  ConsultantprofileForm: FormGroup;
  ConsultantProfileData: any;
  Caddressdata: any;
  CprofileImage: any;
  Cdelflag: any;
  Ciamgedownloadurl: any;
  Cprofilegender: string;
  Cimage: string;
  CphSpinnerHide: boolean;
  Cofficenum: any;
  Cofficenummsg: string;
  CphCheckHide: boolean;
  CphSpinnerHide1: boolean;
  Cmobinum: any;
  Cmobinummsg: string;
  CphCheckHide1: boolean;
  StateData1: any = [];
  accname: any;
  AssociateForm: FormGroup;
  associateres: any;
  items: any[];
  oCountryCodeDropdown: any = [];
  profileassociation: any;
  usrroleid: any;
  editLocationdata: any;
  patchlocationString: string;
  patchlocationString1: string;
  // tslint:disable-next-line: max-line-length
  constructor(private datepipe: DatePipe, private apisService: ApisService, private breadcrumbService: BreadcrumbService, private fb: FormBuilder, private messageService: MessageService, private sanitizer: DomSanitizer, private router: Router) {
    if (sessionStorage.getItem('loginRole') === 'Consultant') {
      this.router.navigate([
        '/consultant-update-profile',
        JSON.parse(sessionStorage.getItem('userId'))
      ]);
    }
    this.breadcrumbService.setItems([
      { label: 'Profile', routerLink: '/profile' },
    ]);
  }

  ngOnInit(): void {
    this.topimageid = sessionStorage.getItem('topimageid');
    this.userIdValue = sessionStorage.getItem('userId');
    this.genderValue = sessionStorage.getItem('genderValue');
    const userDetails = JSON.parse(this.apisService.decodeData());
    this.userId = userDetails.userId;
    this.accname = userDetails.name;
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === ''){
      this.roleId = userDetails.roles[0].id;
    }else{
      this.roleId = this.usrroleid;
    }
    this.profileForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(25)]],
      middileName: ['', [Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(15)]],
      lastName: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(25)]],
      emailId: [''],
      gender: [''],
      title: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(25)]],
      officePhone: ['', [Validators.required]],
      mobileNumber: [''],
      workLocation: [''],
      addressLine1: ['', Validators.maxLength(50)],
      addressLine2: ['', Validators.maxLength(50)],
      mailboxNumber: ['', Validators.maxLength(10)],
      city: ['', Validators.maxLength(20)],
      country: [''],
      state: [''],
      zip: ['', Validators.maxLength(10)],
      conMobileDrp: [''],
      ConoffMobileDrp: [''],
    });
    // consultant formgroup
    this.ConsultantprofileForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(25)]],
      middileName: ['', [Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(15)]],
      lastName: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(25)]],
      emailId: [''],
      gender: [''],
      title: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(25)]],
      officePhone: ['', [Validators.required]],
      mobileNumber: [''],
      workLocation: [''],
      permanentAddressLine1: ['', Validators.maxLength(50)],
      permanentAddressLine2: ['', Validators.maxLength(50)],
      permanentCity: ['', Validators.maxLength(20)],
      permanentCountry: [''],
      permanentState: [''],
      permanentZip: ['', Validators.maxLength(10)],
      mailingAdressCheckbox: [''],
      mailingAddressLine1: ['', Validators.maxLength(50)],
      mailingAddressLine2: ['', Validators.maxLength(50)],
      mailingCity: ['', Validators.maxLength(20)],
      mailingCountry: [''],
      mailingState: [''],
      mailingZip: ['', Validators.maxLength(10)],
      mailbox2: ['', Validators.maxLength(10)],
      mailbox1: ['', Validators.maxLength(10)],
      oMobileDrp: [''],
      mMobileDrp: [''],
    });
    // associate form
    this.AssociateForm = this.fb.group({
      companyname: ['', Validators.required]
    });
    // other details
    this.addConsultantForm = this.fb.group({
      currentRate: ['', Validators.pattern('^[0-9].*$')],
      currentCTC: ['', Validators.pattern('^[0-9].*$')],
      expMinRate: ['', Validators.pattern('^[0-9].*$')],
      expMaxRate: ['', Validators.pattern('^[0-9].*$')],
      expMinCTC: ['', Validators.pattern('^[0-9].*$')],
      expMaxCTC: ['', Validators.pattern('^[0-9].*$')],
      education: ['', Validators.maxLength(10)],
      workingCountry: ['', [Validators.required]],
      currency: ['', [Validators.required]],
      relocation: [''],
      experience: [''],
      livingCountry: [''],
      consultantworkstatus: ['', Validators.required],
      consultantworkstatusComments: ['', Validators.maxLength(200)],
      comments: ['', Validators.maxLength(200)],
    });
    this.getCountryDropdown();
    this.getlocation();
    this.getdrop();
    // for role 15 only

    if (this.roleId === 15) {
      this.preferencList = userDetails.preferencesList;
      if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
        this.currencyValue = 'USD';
      }
      else {
        this.currencycode = [];
        this.preferencList.forEach(element => {
          this.currencycode[element.key] = [element.value];
        });
        // tslint:disable-next-line:no-string-literal
        this.currencyValue = this.currencycode['currency'].toString();
      }
      this.getConsultantProfileData();
    }
    else {
      this.getProfileData();

    }
  }
  countryids(event) {
  }
  getdrop() {
    this.oCountryCodeDropdown = this.apisService.getdropdwon();
  }
  copyInputMessage(data) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (data));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
  toggle() {
    this.items = [];
  }
  emailToast() {
    if (this.ConsultantprofileForm.controls.emailId.invalid && this.ConsultantprofileForm.controls.emailId.errors.pattern) {
    }
    // tslint:disable-next-line: max-line-length
    else if (this.ConsultantprofileForm.controls.emailId.value === '' || this.ConsultantprofileForm.controls.emailId.value === null || this.ConsultantprofileForm.controls.emailId.value === undefined) {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please enter email.' });
    }
    else {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
    }
    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
  }
  associate() {
    if (this.AssociateForm.invalid) {
      const controls = this.AssociateForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      this.disable = true;
      this.loader = true;
      const data = {
        accId: this.AssociateForm.controls.companyname.value.id,
        usrId: this.userId
      };
      this.apisService.postApi(environment.association, data).subscribe((res => {
        this.associateres = res;
        if (this.associateres.success === true) {
          this.dialogstatus = 2;
        }
        else {
          if (this.associateres.hasOwnProperty('failed') === true) {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.associateres.failed, });
          } else {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.associateres.error, });
          }
        }
        this.loader = false;
        this.disable = false;
      }));
    }
  }
  // popup methods
  showDialog() {
    this.displaypopup = true;
    this.dialogstatus = 1;
  }
  noDialog() {
    this.dialogstatus = 2;
  }
  yesDialog() {
    this.dialogstatus = 3;
    this.apisService.getApi(environment.vendornames).subscribe((res) => {
      this.company = res.data;
    });
  }
  closeDialog() {
    this.displaypopup = false;
  }
  backDialog() {
    this.dialogstatus = 1;
  }
  handleChange(e) {
    const index = e.index;
    if (index === 1) {
      this.getExperienceDropDownData();
      this.currencyDropdown();
      this.getCountryDropdown();
      this.ConsultantDropdown();
      this.getCountryDropdown();
      this.consultantProfileData();
    }
  }
  getCountryDropdown() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'adlc').subscribe(res => {
      this.countryData = res.data;
      this.lvcountry = res.data;
    });
  }
  getStateByUrl(country) {
    if (country === null || '' || undefined) { }
    else { // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.states + country).subscribe(res => {
        this.StateData = res.data;
      });
    }
  }
  getlocation() {
    this.actId = JSON.parse(this.apisService.decodeData());
    this.id = '/' + this.actId.accId;
    // tslint:disable-next-line: deprecation
    if (this.actId.accId === null || this.actId.accId === undefined || this.actId.accId === 0) {
    }
    else {
      this.apisService.getApi(environment.location + this.id).subscribe((res: any) => {
        this.workLocations = res.data;
      });
    }
  }
  getProfileData() {
    this.loader = true;
    const actId = JSON.parse(this.apisService.decodeData());
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.profile + actId.userId).subscribe((res: any) => {
      this.profileData = res.data[0];
      this.apisService.username1.next(this.profileData.imageId);
      this.addressdata = this.profileData.address[0];
      this.profileImage = this.profileData.downloadUrl;
      this.delflag = this.profileData.dFlag;
      this.iamgedownloadurl = res.data[0].downloadUrl;
      if (this.iamgedownloadurl === '' || null || undefined) {
        if (this.profileData.gender === 'M') {
          this.profilegender = 'https://d2r8nxfsv3v6y3.cloudfront.net/images/male.png';
        }
        else {
          this.profilegender = 'https://d2r8nxfsv3v6y3.cloudfront.net/images/female.png';
        }
      }
      if (JSON.stringify(this.profileData.imageId) === 'null') {
        this.image = 'https://www.w3schools.com/howto/img_avatar.png';
      } else {
        this.image = this.profileData.downloadUrl;
      }
      this.profileForm.patchValue({
        firstName: this.profileData.firstName,
        middileName: this.profileData.midName,
        lastName: this.profileData.lastName,
        emailId: this.profileData.email,
        gender: this.profileData.gender,
        title: this.profileData.designation,
        officePhone: this.profileData.officePhone,
        mobileNumber: this.profileData.phone,
        mailboxNumber: this.addressdata.mailBoxNumber,
        addressLine1: this.addressdata.address,
        addressLine2: this.addressdata.address2,
        city: this.addressdata.city,
        zip: this.addressdata.zip
      });
      this.loader = false;
      // tslint:disable-next-line:no-unused-expression
      // this.profileForm.controls.workLocation.patchValue(this.workLocations.filter(x => x.locId === this.profileData.locationId)[0]);
      this.profileForm.controls.country.patchValue(this.countryData.filter(x => x.code === this.addressdata.country)[0]);
      // tslint:disable-next-line:no-shadowed-variable
      this.apisService.getApi(environment.states + this.addressdata.country).subscribe(res => {
        this.StateData = res.data;
        this.profileForm.controls.state.patchValue(this.StateData.filter(x => x.code === this.addressdata.state)[0]);
        // tslint:disable-next-line:max-line-length
        this.profileForm.controls.conMobileDrp.patchValue(this.oCountryCodeDropdown.filter((x) => x.id === this.profileData.mobilecCode)[0]);
        // tslint:disable-next-line:max-line-length
        this.profileForm.controls.ConoffMobileDrp.patchValue(this.oCountryCodeDropdown.filter((x) => x.id === this.profileData.officecCode)[0]);
        this.profileForm.controls.workLocation.patchValue(this.workLocations.filter(x => x.locId === this.profileData.locationId)[0]);
        this.editLocationdata = this.workLocations.filter(x => x.locId === this.profileData.locationId)
        this.patchlocationString = this.editLocationdata[0]?.name + '\n' + this.editLocationdata[0]?.address1 + ',' + this.editLocationdata[0]?.city + ',' + this.editLocationdata[0]?.state + ',' + this.editLocationdata[0]?.country
      });
    });
  }
  profileDetails() {
    this.displayModal = true;
  }
  profileDetails1() {
  }
  onSelectFile1() {
    this.displayModal1 = true;
    this.displayModal = false;
    this.displayModal2 = false;
  }
  back() {
    this.displayModal1 = false;
    this.displayModal = true;
    this.displayModal2 = false;
  }
  back1() {
    this.displayModal1 = false;
    this.displayModal = false;
    this.displayModal2 = false;
  }
  onSelectFile(event) {
    this.imageevent = event;
    this.fileName = event.target.files[0];
    this.name = this.fileName.name;
    this.fileType = this.fileName.name.split('.').pop();
    const formData: FormData = new FormData();
    formData.append('file', this.fileName);
    this.filedata = formData;
    const chooseImgPath = event.target.files[0].name;
    this.pathExtension = chooseImgPath.substring(chooseImgPath.indexOf('.') + 1);
    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    const reader = e.target;
    this.url = reader.result;
    this.imageSuccess = true;
  }
  profileImageUploadData() {
    this.loader = true;
    if (this.roleId === 15) {
      this.apisService.postApi(environment.profileImageUpload + 'P/' + this.actId.userId, this.filedata).subscribe((res: any) => {
        this.loader = false;
        this.CprofileImage = res.data;
        if (res.success === true) {
          this.addAttachement();
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        }
        else if (res.success === false) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
        }
      });
    }
    else {
      this.apisService.postApi(environment.profileImageUpload + 'P/' + this.actId.userId, this.filedata).subscribe((res: any) => {
        this.loader = false;
        this.profileImage = res.data;
        if (res.success === true) {
          this.addAttachement();
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        }
        else if (res.success === false) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        }
      });
    }
  }
  addAttachement() {
    const actId = JSON.parse(this.apisService.decodeData());
    if (this.roleId === 15 ? this.ConsultantprofileForm.invalid : this.profileForm.invalid) {
      const controls = this.roleId === 15 ? this.ConsultantprofileForm.controls : this.profileForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      const addData = {
        objectId: actId.userId,
        objectType: 'U',
        title: '',
        attPath: this.roleId === 15 ? this.CprofileImage : this.profileImage,
        fileName: '',
        size: '',
        extension: '',
        comments: '',
        verifiedBy: 0,
        attType: 'P'
      };
      this.loader = true;
      this.apisService.postApi(environment.addProfile, addData).subscribe((res: any) => {
        this.getData = res;
        if (this.getData.hasOwnProperty('success') === true) {
          if (this.roleId === 15) {
            const updateProfile = {
              designation: this.ConsultantprofileForm.controls.title.value === '' ? null : this.ConsultantprofileForm.controls.title.value,
              // tslint:disable-next-line:max-line-length
              firstName: this.ConsultantprofileForm.controls.firstName.value === '' ? null : this.ConsultantprofileForm.controls.firstName.value,
              // tslint:disable-next-line:max-line-length
              lastName: this.ConsultantprofileForm.controls.lastName.value === '' ? null : this.ConsultantprofileForm.controls.lastName.value,
              // tslint:disable-next-line:max-line-length
              midName: this.ConsultantprofileForm.controls.middileName.value === '' ? null : this.ConsultantprofileForm.controls.middileName.value,
              gender: this.ConsultantprofileForm.controls.gender.value === undefined || this.ConsultantprofileForm.controls.gender.value === '' ? '' : this.ConsultantprofileForm.controls.gender.value,
              imageId: this.getData.id,
              officePhone: this.ConsultantprofileForm.controls.officePhone.value,
              phone: this.ConsultantprofileForm.controls.mobileNumber.value,
              // tslint:disable-next-line:max-line-length
              mccode: this.ConsultantprofileForm.controls.mMobileDrp.value === null || this.ConsultantprofileForm.controls.mMobileDrp.value === '' || this.ConsultantprofileForm.controls.mMobileDrp.value === undefined || this.ConsultantprofileForm.controls.mMobileDrp.value === '' ? '' : this.ConsultantprofileForm.controls.mMobileDrp.value.id,
              // tslint:disable-next-line:max-line-length
              occode: this.ConsultantprofileForm.controls.oMobileDrp.value === null || this.ConsultantprofileForm.controls.oMobileDrp.value === '' || this.ConsultantprofileForm.controls.oMobileDrp.value === undefined || this.ConsultantprofileForm.controls.oMobileDrp.value === '' ? '' : this.ConsultantprofileForm.controls.oMobileDrp.value.id,
              // tslint:disable-next-line:max-line-length
              workingLocation: this.ConsultantprofileForm.controls.workLocation.value.locId === undefined || this.ConsultantprofileForm.controls.workLocation.value === '' ? '' : this.ConsultantprofileForm.controls.workLocation.value.locId,
              userId: JSON.parse(this.apisService.decodeData()).userId,
              auId: this.ConsultantProfileData.auId,
              userAddress: [
                {
                  // tslint:disable-next-line:max-line-length
                  address: this.ConsultantprofileForm.controls.permanentAddressLine1.value === '' ? null : this.ConsultantprofileForm.controls.permanentAddressLine1.value,
                  // tslint:disable-next-line:max-line-length
                  address2: this.ConsultantprofileForm.controls.permanentAddressLine2.value === '' ? null : this.ConsultantprofileForm.controls.permanentAddressLine2.value,
                  // tslint:disable-next-line:max-line-length
                  mailBoxNumber: this.ConsultantprofileForm.controls.mailbox1.value === '' ? null : this.ConsultantprofileForm.controls.mailbox1.value,
                  // tslint:disable-next-line:max-line-length
                  country: this.ConsultantprofileForm.controls.permanentCountry.value === undefined || this.ConsultantprofileForm.controls.permanentCountry.value === '' ? '' : this.ConsultantprofileForm.controls.permanentCountry.value.code,
                  // tslint:disable-next-line:max-line-length
                  state: this.ConsultantprofileForm.controls.permanentState.value === null || this.ConsultantprofileForm.controls.permanentState.value === undefined || this.ConsultantprofileForm.controls.permanentState.value === '' ? '' : this.ConsultantprofileForm.controls.permanentState.value.code,
                  // tslint:disable-next-line: max-line-length
                  city: this.ConsultantprofileForm.controls.permanentCity.value === '' ? null : this.ConsultantprofileForm.controls.permanentCity.value,
                  // tslint:disable-next-line:max-line-length
                  zip: this.ConsultantprofileForm.controls.permanentZip.value === '' ? null : this.ConsultantprofileForm.controls.permanentZip.value,
                  lkAddrType: 'P',
                  addId: this.ConsultantProfileData.address[0].addId,
                },
                {
                  // tslint:disable-next-line:max-line-length
                  address: this.ConsultantprofileForm.controls.mailingAddressLine1.value === '' ? null : this.ConsultantprofileForm.controls.mailingAddressLine1.value,
                  // tslint:disable-next-line:max-line-length
                  address2: this.ConsultantprofileForm.controls.mailingAddressLine2.value === '' ? null : this.ConsultantprofileForm.controls.mailingAddressLine2.value,
                  // tslint:disable-next-line:max-line-length
                  mailBoxNumber: this.ConsultantprofileForm.controls.mailbox2.value === '' ? null : this.ConsultantprofileForm.controls.mailbox2.value,
                  // tslint:disable-next-line:max-line-length
                  country: this.ConsultantprofileForm.controls.mailingCountry.value === undefined || this.ConsultantprofileForm.controls.mailingCountry.value === '' ? '' : this.ConsultantprofileForm.controls.mailingCountry.value.code,
                  // tslint:disable-next-line:max-line-length
                  state: this.ConsultantprofileForm.controls.mailingState.value === undefined || this.ConsultantprofileForm.controls.mailingState.value === '' ? '' : this.ConsultantprofileForm.controls.mailingState.value.code,
                  // tslint:disable-next-line:max-line-length
                  city: this.ConsultantprofileForm.controls.mailingCity.value === '' ? null : this.ConsultantprofileForm.controls.mailingCity.value,
                  // tslint:disable-next-line:max-line-length
                  zip: this.ConsultantprofileForm.controls.mailingZip.value === '' ? null : this.ConsultantprofileForm.controls.mailingZip.value,
                  lkAddrType: 'M',
                  addId: this.ConsultantProfileData.address[1].addId,

                },
              ],
            };
            // tslint:disable-next-line:no-shadowed-variable
            this.apisService.putApi(environment.updateConsultantprofile, updateProfile).subscribe((res) => {
              this.updateResponse = res;
              this.apisService.username.next(this.getData.id);
              if (this.updateResponse.hasOwnProperty('success') === true) {
                this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateResponse.message });
              }
              else if (this.updateResponse.hasOwnProperty('failed') === true) {
                this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateResponse.failed });
              } else {
                this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateResponse.error });
              }
              this.loader = false;
            });
          }
          else {
            const data = {
              designation: this.profileForm.controls.title.value,
              firstName: this.profileForm.controls.firstName.value,
              // tslint:disable-next-line: max-line-length
              lastName: this.profileForm.controls.lastName.value === undefined || this.profileForm.controls.lastName.value === null || this.profileForm.controls.lastName.value === '' ? '' : this.profileForm.controls.lastName.value,
              // tslint:disable-next-line:max-line-length
              midName: this.profileForm.controls.middileName.value === undefined || this.profileForm.controls.middileName.value === null || this.profileForm.controls.middileName.value === '' ? '' : this.profileForm.controls.middileName.value,
              // tslint:disable-next-line:max-line-length
              gender: this.profileForm.controls.gender.value === undefined || this.profileForm.controls.gender.value === '' ? '' : this.profileForm.controls.gender.value,
              officePhone: this.profileForm.controls.officePhone.value,
              phone: this.profileForm.controls.mobileNumber.value,
              imageId: this.getData.id,
              address: this.profileForm.controls.addressLine1.value,
              address2: this.profileForm.controls.addressLine2.value,
              // tslint:disable-next-line:max-line-length
              mailBoxNumber: this.profileForm.controls.mailboxNumber.value === undefined || this.profileForm.controls.mailboxNumber.value === '' ? '' : this.profileForm.controls.mailboxNumber.value,
              // tslint:disable-next-line:max-line-length
              countries: this.profileForm.controls.country.value.code === undefined || this.profileForm.controls.country.value === '' ? '' : this.profileForm.controls.country.value.code,
              // tslint:disable-next-line:max-line-length
              states: this.profileForm.controls.state.value === undefined || this.profileForm.controls.state.value === '' ? '' : this.profileForm.controls.state.value.code,
              city: this.profileForm.controls.city.value,
              zip: this.profileForm.controls.zip.value,
              // tslint:disable-next-line:max-line-length
              workingLocation: this.profileForm.controls.workLocation.value === null || this.profileForm.controls.workLocation.value === undefined || this.profileForm.controls.workLocation.value === '' ? '' : this.profileForm.controls.workLocation.value.locId,
              userId: JSON.parse(this.apisService.decodeData()).userId,
              id: this.profileData.id,
              addId: this.addressdata.addId,
              auId: this.profileData.auId,
              // tslint:disable-next-line:max-line-length
              mccode: this.profileForm.controls.conMobileDrp.value === null || this.profileForm.controls.conMobileDrp.value === '' || this.profileForm.controls.conMobileDrp.value === undefined || this.profileForm.controls.conMobileDrp.value === '' ? '' : this.profileForm.controls.conMobileDrp.value.id,
              // tslint:disable-next-line:max-line-length
              occode: this.profileForm.controls.ConoffMobileDrp.value === null || this.profileForm.controls.ConoffMobileDrp.value === '' || this.profileForm.controls.ConoffMobileDrp.value === undefined || this.profileForm.controls.ConoffMobileDrp.value === '' ? '' : this.profileForm.controls.ConoffMobileDrp.value.id
            };
            // tslint:disable-next-line:no-shadowed-variable
            this.apisService.postApi(environment.updateProfileData, data).subscribe((res) => {
              this.apisService.username.next(this.getData.id);
              this.updateResponse = res;
              if (this.updateResponse.hasOwnProperty('success') === true) {
                this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateResponse.message });
              }
              else if (this.updateResponse.hasOwnProperty('failed') === true) {
                this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateResponse.failed });
                this.loader = false;
              } else {
                this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateResponse.error });
              }
              this.loader = false;
            });
          }
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error });
        }
      });
    }
  }
  logout() {
    sessionStorage.clear();
    this.router.navigateByUrl('login');
  }
  deleteAttachment() {
    if (this.roleId === 15) {
      const delimageid = '?attachmentId=' + this.ConsultantProfileData.imageId;
      this.loader = true;
      this.apisService.deleteApi(environment.deleteimage + delimageid).subscribe((res: any) => {
        this.loader = false;
        this.profileImageUploadData();
      });
    }
    else {
      const delimageid = '?attachmentId=' + this.profileData.imageId;
      this.loader = true;
      this.apisService.deleteApi(environment.deleteimage + delimageid).subscribe((res: any) => {
        this.loader = false;
        this.profileImageUploadData();
      });
    }
  }
  profileUpdate() {
    if (this.profileForm.invalid) {
      const controls = this.profileForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      if (this.delflag > 0 && this.imageevent !== undefined) {
        this.deleteAttachment();
      }
      if (this.profileData.imageId === null || this.profileData.imageId === 0 || this.profileData.imageId > 0 || this.delflag === 0) {
        // tslint:disable-next-line:max-line-length
        if (this.profileData.imageId === 0 && this.imageevent !== undefined && this.delflag === 0 || this.profileData.imageId >= 0 && this.imageevent !== undefined && this.delflag === 0) {
          this.profileImageUploadData();
        }
        // tslint:disable-next-line:max-line-length
        else if (this.profileData.imageId === 0 || this.profileData.imageId === null || this.profileData.imageId > 0 && this.imageevent === undefined) {
          const data = {
            designation: this.profileForm.controls.title.value,
            firstName: this.profileForm.controls.firstName.value,
            // tslint:disable-next-line: max-line-length
            lastName: this.profileForm.controls.lastName.value === undefined || this.profileForm.controls.lastName.value === null || this.profileForm.controls.lastName.value === '' ? '' : this.profileForm.controls.lastName.value,
            // tslint:disable-next-line:max-line-length
            midName: this.profileForm.controls.middileName.value === undefined || this.profileForm.controls.middileName.value === null || this.profileForm.controls.middileName.value === '' ? '' : this.profileForm.controls.middileName.value,
            // tslint:disable-next-line:max-line-length
            gender: this.profileForm.controls.gender.value === undefined || this.profileForm.controls.gender.value === '' ? '' : this.profileForm.controls.gender.value,
            officePhone: this.profileForm.controls.officePhone.value,
            phone: this.profileForm.controls.mobileNumber.value,
            imageId: this.profileData.imageId,
            address: this.profileForm.controls.addressLine1.value,
            address2: this.profileForm.controls.addressLine2.value,
            mailBoxNumber: this.profileForm.controls.mailboxNumber.value === undefined || this.profileForm.controls.mailboxNumber.value === '' ? '' : this.profileForm.controls.mailboxNumber.value,
            // tslint:disable-next-line:max-line-length
            countries: this.profileForm.controls.country.value === null || this.profileForm.controls.country.value === undefined || this.profileForm.controls.country.value === '' ? '' : this.profileForm.controls.country.value.code,
            // tslint:disable-next-line:max-line-length
            states: this.profileForm.controls.state.value === null || this.profileForm.controls.state.value === undefined || this.profileForm.controls.state.value === '' ? '' : this.profileForm.controls.state.value.code,
            city: this.profileForm.controls.city.value,
            zip: this.profileForm.controls.zip.value,
            workingLocation: this.profileForm.controls.workLocation.value === undefined || this.profileForm.controls.workLocation.value === '' ? '' : this.profileForm.controls.workLocation.value.locId,
            userId: JSON.parse(this.apisService.decodeData()).userId,
            id: this.profileData.id,
            addId: this.addressdata.addId,
            auId: this.profileData.auId,
            // tslint:disable-next-line:max-line-length
            mccode: this.profileForm.controls.conMobileDrp.value === null || this.profileForm.controls.conMobileDrp.value === '' || this.profileForm.controls.conMobileDrp.value === undefined || this.profileForm.controls.conMobileDrp.value === '' ? '' : this.profileForm.controls.conMobileDrp.value.id,
            // tslint:disable-next-line:max-line-length
            occode: this.profileForm.controls.ConoffMobileDrp.value === null || this.profileForm.controls.ConoffMobileDrp.value === '' || this.profileForm.controls.ConoffMobileDrp.value === undefined || this.profileForm.controls.ConoffMobileDrp.value === '' ? '' : this.profileForm.controls.ConoffMobileDrp.value.id

          };
          this.loader = true;
          this.apisService.postApi(environment.updateProfileData, data).subscribe((res) => {
            this.loader = false;
            this.updateResponse = res;
            if (this.updateResponse.hasOwnProperty('success') === true) {
              this.getProfileData();
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateResponse.message });
            }
            else if (this.updateResponse.hasOwnProperty('failed') === true) {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateResponse.failed });
              this.loader = false;
            } else {
              this.messageService.add({
                key: 'br', severity: '', life: 3000, detail: this.updateResponse.error
              });
              // tslint:disable-next-line:align
            } this.loader = false;
          });

        }
      }
    }

  }
  getPhon() {
    const num = this.profileForm.controls.officePhone.value;
    if (num === '' || num === null || num === undefined || num === '(___) ___-____') {
    }
    else {
      this.disable = true;
      this.phSpinnerHide = true;
      this.apisService.getApi(environment.numberValidation + this.userId + '/' + true + '?' + 'phoneNumber=' + num).subscribe(res => {
        this.disable = false;
        this.officenum = res;
        this.officenum = res.success;
        if (this.officenum === false) {
          this.phSpinnerHide = false;
          this.officenummsg = 'office number already exist';
          setTimeout(() => {
            this.officenummsg = '';
          }, 5000);
          this.profileForm.get('officePhone').reset();
        }
        else {
          this.phSpinnerHide = false;
          this.phCheckHide = true;
          setTimeout(() => {
            this.phCheckHide = false;
          }, 3000);
        }
      });
    }

  }
  getmobi() {
    const mob = this.profileForm.controls.mobileNumber.value;
    if (mob === '' || mob === null || mob === undefined || mob === '(___) ___-____') {
    }
    else {
      const text = mob;
      const result = text.replace(' ', '-');
      this.phSpinnerHide1 = true;
      this.apisService.getApi(environment.numberValidation + this.userId + '/' + false + '?' + 'phoneNumber=' + mob).subscribe(res => {
        this.mobinum = res;
        this.mobinum = res.success;
        if (this.mobinum === false) {
          this.phSpinnerHide1 = false;
          this.mobinummsg = 'mobile number already exist';
          setTimeout(() => {
            this.mobinummsg = '';
          }, 5000);
          this.profileForm.get('mobileNumber').reset();
        }
        else {
          this.phSpinnerHide1 = false;
          this.phCheckHide1 = true;
          setTimeout(() => {
            this.phCheckHide1 = false;
          }, 3000);
        }
      });
    }

  }
  removeProfile() {
    this.displayModal2 = true;
    this.displayModal1 = false;
    this.displayModal = false;
  }
  // role 15 methods..
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
  getExperienceDropDownData() {
    this.apisService.getApi(environment.lookupsData + 'RELYE').subscribe((res: any) => {
      this.experienceData = res.data;
    });
  }
  currencyDropdown() {
    this.apisService.getApi(environment.lookupsData + 'UPDC').subscribe((res: any) => {
      this.currencyData = res.data;
    });
  }
  ConsultantDropdown() {
    this.apisService.getApi(environment.lookupsData + 'UPDVS').subscribe((res) => {
      this.consultant = res.data;
    });
  }
  consultantProfileData() {
    this.loader = true;
    this.apisService.getApi(environment.consultantProfile + this.userId).subscribe(res => {
      this.consultantProfile = res.data[0];
      this.loader = false;
      this.updateId = this.consultantProfile.usrId;
      this.addConsultantForm.patchValue({
        currentRate: this.consultantProfile.curRate,
        currentCTC: this.consultantProfile.curCtc,
        expMinRate: this.consultantProfile.expRateMin,
        expMaxRate: this.consultantProfile.expRateMax,
        expMinCTC: this.consultantProfile.expCtcMn,
        expMaxCTC: this.consultantProfile.expCtcMax,
        education: this.consultantProfile.education,
        consultantworkstatusComments:
          this.consultantProfile.visaStatusComments,
        comments: this.consultantProfile.comments,


      });
      this.addConsultantForm.controls.experience.patchValue(
        this.experienceData.filter(
          (x) => x.code === this.consultantProfile.lkExperience
        )[0]
      );
      this.addConsultantForm.controls.workingCountry.patchValue(
        this.lvcountry.filter(
          (x) => x.code === this.consultantProfile.lkWorkingCountry
        )[0]
      );
      this.addConsultantForm.controls.currency.patchValue(
        this.currencyData.filter(
          (x) => x.code === this.consultantProfile.lkCurrency
        )[0]
      );
      this.addConsultantForm.controls.relocation.patchValue(
        this.Relocation.filter(
          (x) => x.code === this.consultantProfile.lkRelocation
        )[0]
      );
      this.addConsultantForm.controls.consultantworkstatus.patchValue(
        this.consultant.filter(
          (x) => x.code === this.consultantProfile.lkVisaStatus
        )[0]
      );
      this.addConsultantForm.controls.livingCountry.patchValue(
        this.lvcountry.filter(
          (x) => x.code === this.consultantProfile.lkLivingCountry
        )[0]
      );
    });

  }

  updateConsultantProfile() {
    this.loader = false;
    if (this.addConsultantForm.invalid) {
      const controls = this.addConsultantForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      const updateProfile = {
        id: this.updateId,
        lkCurrency: this.addConsultantForm.controls.currency.value === undefined ? '' : this.addConsultantForm.controls.currency.value.code,
        // tslint:disable-next-line:max-line-length
        lkRelocation: this.addConsultantForm.controls.relocation.value === undefined || this.addConsultantForm.controls.relocation.value.code === '' ? '' : this.addConsultantForm.controls.relocation.value.code,
        expMaxCtc: this.addConsultantForm.controls.expMaxCTC.value === '' ? 0 : this.addConsultantForm.controls.expMaxCTC.value,
        expMaxRate: this.addConsultantForm.controls.expMaxRate.value === '' ? 0 : this.addConsultantForm.controls.expMaxRate.value,
        expMinCtc: this.addConsultantForm.controls.expMinCTC.value === '' ? 0 : this.addConsultantForm.controls.expMinCTC.value,
        expMinRate: this.addConsultantForm.controls.expMinRate.value === '' ? 0 : this.addConsultantForm.controls.expMinRate.value,
        // tslint:disable-next-line:max-line-length
        experiance: this.addConsultantForm.controls.experience.value === undefined ? 0 : this.addConsultantForm.controls.experience.value.code,
        currRate: this.addConsultantForm.controls.currentRate.value === '' ? 0 : this.addConsultantForm.controls.currentRate.value,
        currCtc: this.addConsultantForm.controls.currentCTC.value === '' ? 0 : this.addConsultantForm.controls.currentCTC.value,
        // tslint:disable-next-line:max-line-length
        lkVisaComment: this.addConsultantForm.controls.consultantworkstatusComments.value === '' ? '' : this.addConsultantForm.controls.consultantworkstatusComments.value,
        // tslint:disable-next-line:max-line-length
        lkVisaStatus: this.addConsultantForm.controls.consultantworkstatus.value === undefined || this.addConsultantForm.controls.consultantworkstatus.value === '' ? 0 : this.addConsultantForm.controls.consultantworkstatus.value.code,
        comment: this.addConsultantForm.controls.comments.value === '' ? null : this.addConsultantForm.controls.comments.value,
        education: this.addConsultantForm.controls.education.value === '' ? null : this.addConsultantForm.controls.education.value,
        // tslint:disable-next-line:max-line-length
        livingCountry: this.addConsultantForm.controls.livingCountry.value === undefined ? '' : this.addConsultantForm.controls.livingCountry.value.code,
        // tslint:disable-next-line:max-line-length
        wokringCountry: this.addConsultantForm.controls.workingCountry.value === undefined ? '' : this.addConsultantForm.controls.workingCountry.value.code,

      };
      this.apisService.putApi(environment.updateConsultantProfile, updateProfile).subscribe((res) => {
        this.updateProfile = res;
        this.loader = false;
        if (this.updateProfile.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateProfile.message });
        }
        else if (this.updateProfile.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateProfile.failed });
          this.loader = false;
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateProfile.error });
        }
      });
      this.consultantProfileData();
    }
  }
  // consultant original profile
  getStateByUrl1(country) {
    if (country === null || '' || undefined) { }
    else { // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.states + country).subscribe(res => {
        this.StateData1 = res.data;
      });
    }
  }
  getConsultantProfileData() {
    this.loader = true;
    const actId = JSON.parse(this.apisService.decodeData());
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.profile + actId.userId).subscribe((res: any) => {
      this.ConsultantProfileData = res.data[0];
      this.apisService.username1.next(this.ConsultantProfileData.imageId);
      this.Caddressdata = this.ConsultantProfileData.address[0];
      this.CprofileImage = this.ConsultantProfileData.downloadUrl;
      this.Cdelflag = this.ConsultantProfileData.dFlag;
      this.Ciamgedownloadurl = res.data[0].downloadUrl;
      this.profileassociation = res.data[0].isAssociated;
      if (this.profileassociation === 'N') {
        this.showDialog();
      }
      if (this.Ciamgedownloadurl === '' || null || undefined) {
        if (this.ConsultantProfileData.gender === 'M') {
          this.Cprofilegender = 'https://d2r8nxfsv3v6y3.cloudfront.net/images/male.png';
        }
        else {
          this.Cprofilegender = 'https://d2r8nxfsv3v6y3.cloudfront.net/images/female.png';
        }
      }
      if (JSON.stringify(this.ConsultantProfileData.imageId) === 'null') {
        this.Cimage = 'https://www.w3schools.com/howto/img_avatar.png';
      } else {
        this.Cimage = this.ConsultantProfileData.downloadUrl;
      }
      this.ConsultantprofileForm.patchValue({
        firstName: this.ConsultantProfileData.firstName,
        middileName: this.ConsultantProfileData.midName,
        lastName: this.ConsultantProfileData.lastName,
        emailId: this.ConsultantProfileData.email,
        gender: this.ConsultantProfileData.gender,
        title: this.ConsultantProfileData.designation,
        officePhone: this.ConsultantProfileData.officePhone,
        mobileNumber: this.ConsultantProfileData.phone,
        permanentAddressLine1: this.ConsultantProfileData?.address[0].address,
        permanentAddressLine2: this.ConsultantProfileData?.address[0].address2,
        permanentCity: this.ConsultantProfileData?.address[0].city,
        permanentZip: this.ConsultantProfileData?.address[0].zip,
        mailingAddressLine1: this.ConsultantProfileData?.address[1]?.address,
        mailingAddressLine2: this.ConsultantProfileData?.address[1]?.address2,
        mailingCity: this.ConsultantProfileData?.address[1]?.city,
        mailingZip: this.ConsultantProfileData?.address[1]?.zip,
        mailbox1: this.ConsultantProfileData?.address[0]?.mailBoxNumber,
        mailbox2: this.ConsultantProfileData?.address[1].mailBoxNumber,

      });
      this.loader = false;
      this.ConsultantprofileForm.controls.permanentCountry.patchValue(
        this.countryData.filter(
          (x) => x.code === this.ConsultantProfileData.address[0].country
        )[0]
      );
      // tslint:disable-next-line:no-shadowed-variable
      this.apisService.getApi(environment.states + this.ConsultantProfileData.address[0].country).subscribe(res => {
        this.StateData = res.data;
        // tslint:disable-next-line:max-line-length
        this.ConsultantprofileForm.controls.permanentState.patchValue(this.StateData.filter(x => x.code === this.ConsultantProfileData.address[0].state)[0]);
      });
      this.ConsultantprofileForm.controls.mailingCountry.patchValue(
        this.countryData.filter(
          (x) => x.code === this.ConsultantProfileData?.address[1].country
        )[0]
      );
      // tslint:disable-next-line:no-shadowed-variable
      this.apisService.getApi(environment.states + this.ConsultantProfileData.address[1].country).subscribe(res => {
        this.StateData1 = res.data;
        // tslint:disable-next-line:max-line-length
        this.ConsultantprofileForm.controls.mailingState.patchValue(this.StateData1.filter(x => x.code === this.ConsultantProfileData.address[1].state)[0]);
      });
      this.ConsultantprofileForm.controls.workLocation.patchValue(this.workLocations.filter(x => x.locId === this.ConsultantProfileData.locationId)[0]);
      this.editLocationdata = this.workLocations.filter(x => x.locId === this.profileData.locationId)
      this.patchlocationString1 = this.editLocationdata[0]?.name + '\n' + this.editLocationdata[0]?.address1 + ',' + this.editLocationdata[0]?.city + ',' + this.editLocationdata[0]?.state + ',' + this.editLocationdata[0]?.country
      // tslint:disable-next-line:max-line-length
      this.ConsultantprofileForm.controls.mMobileDrp.patchValue(this.oCountryCodeDropdown.filter((x) => x.id === this.ConsultantProfileData.mobilecCode)[0]);
      // tslint:disable-next-line:max-line-length
      this.ConsultantprofileForm.controls.oMobileDrp.patchValue(this.oCountryCodeDropdown.filter((x) => x.id === this.ConsultantProfileData.officecCode)[0]);

    });
  }
  disableCheckboxValue() {
    this.ConsultantprofileForm.controls.mailingAdressCheckbox.setValue(false);
    this.ConsultantprofileForm.controls.mailingAddressLine1.enable();
    this.ConsultantprofileForm.controls.mailingAddressLine2.enable();
    this.ConsultantprofileForm.controls.mailingCity.enable();
    this.ConsultantprofileForm.controls.mailingCountry.enable();
    this.ConsultantprofileForm.controls.mailingState.enable();
    this.ConsultantprofileForm.controls.mailingZip.enable();
  }
  disableMailAd(event) {
    this.getStateByUrl1(this.ConsultantprofileForm.controls.permanentCountry.value.code);
    {
      this.ConsultantprofileForm.controls.mailingAddressLine1.setValue(
        this.ConsultantprofileForm.controls.permanentAddressLine1.value
      );
      this.ConsultantprofileForm.controls.mailingAddressLine2.setValue(
        this.ConsultantprofileForm.controls.permanentAddressLine2.value
      );
      this.ConsultantprofileForm.controls.mailingCity.setValue(
        this.ConsultantprofileForm.controls.permanentCity.value
      );


      this.ConsultantprofileForm.controls.mailingCountry.patchValue(this.ConsultantprofileForm.controls.permanentCountry.value);
      this.ConsultantprofileForm.controls.mailingState.patchValue(this.ConsultantprofileForm.controls.permanentState.value);
      this.ConsultantprofileForm.controls.mailingZip.setValue(
        this.ConsultantprofileForm.controls.permanentZip.value
      );
      this.ConsultantprofileForm.controls.mailbox2.setValue(
        this.ConsultantprofileForm.controls.mailbox1.value
      );

      if (event.checked === true) {

        this.ConsultantprofileForm.controls.mailingAddressLine1.disable();
        this.ConsultantprofileForm.controls.mailingAddressLine2.disable();
        this.ConsultantprofileForm.controls.mailingCity.disable();
        this.ConsultantprofileForm.controls.mailingCountry.disable();
        this.ConsultantprofileForm.controls.mailingState.disable();
        this.ConsultantprofileForm.controls.mailingZip.disable();
        this.ConsultantprofileForm.controls.mailbox2.disable();
      } else {
        this.ConsultantprofileForm.controls.mailingAddressLine1.enable();
        this.ConsultantprofileForm.controls.mailingAddressLine2.enable();
        this.ConsultantprofileForm.controls.mailingCity.enable();
        this.ConsultantprofileForm.controls.mailingCountry.enable();
        this.ConsultantprofileForm.controls.mailingState.enable();
        this.ConsultantprofileForm.controls.mailingZip.enable();
        this.ConsultantprofileForm.controls.mailbox2.enable();
      }
    }
  }
  CgetPhon() {
    const Cnum = this.ConsultantprofileForm.controls.officePhone.value;
    if (Cnum) {
      this.CphSpinnerHide = true;
      this.apisService.getApi(environment.numberValidation + this.userId + '/' + true + '?' + 'phoneNumber=' + Cnum).subscribe(res => {
        this.Cofficenum = res;
        this.Cofficenum = res.success;
        if (this.Cofficenum === false) {
          this.CphSpinnerHide = false;
          this.Cofficenummsg = 'office number already exist';
          setTimeout(() => {
            this.Cofficenummsg = '';
          }, 5000);
          this.ConsultantprofileForm.get('officePhone').reset();
        }
        else {
          this.CphSpinnerHide = false;
          this.CphCheckHide = true;
          setTimeout(() => {
            this.CphCheckHide = false;
          }, 3000);

        }
      });
    }


  }
  Cgetmobi() {
    const Cmob = this.ConsultantprofileForm.controls.mobileNumber.value;
    if (Cmob) {
      const text = Cmob;
      const result = text.replace(' ', '-');
      this.CphSpinnerHide1 = true;
      this.apisService.getApi(environment.numberValidation + this.userId + '/' + false + '?' + 'phoneNumber=' + Cmob).subscribe(res => {
        this.Cmobinum = res;
        this.Cmobinum = res.success;
        if (this.Cmobinum === false) {
          this.CphSpinnerHide1 = false;
          this.Cmobinummsg = 'mobile number already exist';
          setTimeout(() => {
            this.Cmobinummsg = '';
          }, 5000);
          this.ConsultantprofileForm.get('mobileNumber').reset();
        }
        else {
          this.CphSpinnerHide1 = false;
          this.CphCheckHide1 = true;
          setTimeout(() => {
            this.CphCheckHide1 = false;
          }, 3000);
        }
      });
    }

  }

  ConsultantprofileUpdate() {
    if (this.ConsultantprofileForm.invalid) {
      const controls = this.ConsultantprofileForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      this.loader = true;
      if (this.Cdelflag > 0 && this.imageevent !== undefined) {
        this.deleteAttachment();
      }
      // tslint:disable-next-line:max-line-length
      if (this.ConsultantProfileData.imageId === null || this.ConsultantProfileData.imageId === 0 || this.ConsultantProfileData.imageId > 0 || this.Cdelflag === 0) {
        // tslint:disable-next-line:max-line-length
        if (this.ConsultantProfileData.imageId === 0 && this.imageevent !== undefined && this.Cdelflag === 0 || this.ConsultantProfileData.imageId >= 0 && this.imageevent !== undefined && this.Cdelflag === 0) {
          this.profileImageUploadData();
        }
        // tslint:disable-next-line:max-line-length
        else if (this.ConsultantProfileData.imageId === 0 || this.ConsultantProfileData.imageId === null || this.ConsultantProfileData.imageId > 0 && this.imageevent === undefined) {
          const updateProfile = {
            designation: this.ConsultantprofileForm.controls.title.value === '' ? null : this.ConsultantprofileForm.controls.title.value,
            // tslint:disable-next-line:max-line-length
            firstName: this.ConsultantprofileForm.controls.firstName.value === '' ? null : this.ConsultantprofileForm.controls.firstName.value,
            lastName: this.ConsultantprofileForm.controls.lastName.value === '' ? null : this.ConsultantprofileForm.controls.lastName.value,
            // tslint:disable-next-line:max-line-length
            midName: this.ConsultantprofileForm.controls.middileName.value === '' ? null : this.ConsultantprofileForm.controls.middileName.value,
            gender: this.ConsultantprofileForm.controls.gender.value === undefined || this.ConsultantprofileForm.controls.gender.value === '' ? '' : this.ConsultantprofileForm.controls.gender.value,
            imageId: this.ConsultantProfileData.imageId,
            officePhone: this.ConsultantprofileForm.controls.officePhone.value,
            phone: this.ConsultantprofileForm.controls.mobileNumber.value,
            // tslint:disable-next-line:max-line-length
            workingLocation: this.ConsultantprofileForm.controls.workLocation.value.locId === undefined || this.ConsultantprofileForm.controls.workLocation.value === '' ? '' : this.ConsultantprofileForm.controls.workLocation.value.locId,
            userId: JSON.parse(this.apisService.decodeData()).userId,
            // tslint:disable-next-line:max-line-length
            mccode: this.ConsultantprofileForm.controls.mMobileDrp.value === null || this.ConsultantprofileForm.controls.mMobileDrp.value === '' || this.ConsultantprofileForm.controls.mMobileDrp.value === undefined || this.ConsultantprofileForm.controls.mMobileDrp.value === '' ? '' : this.ConsultantprofileForm.controls.mMobileDrp.value.id,
            // tslint:disable-next-line:max-line-length
            occode: this.ConsultantprofileForm.controls.oMobileDrp.value === null || this.ConsultantprofileForm.controls.oMobileDrp.value === '' || this.ConsultantprofileForm.controls.oMobileDrp.value === undefined || this.ConsultantprofileForm.controls.oMobileDrp.value === '' ? '' : this.ConsultantprofileForm.controls.oMobileDrp.value.id,

            auId: this.ConsultantProfileData.auId,
            userAddress: [
              {
                // tslint:disable-next-line:max-line-length
                address: this.ConsultantprofileForm.controls.permanentAddressLine1.value === '' ? null : this.ConsultantprofileForm.controls.permanentAddressLine1.value,
                // tslint:disable-next-line:max-line-length
                address2: this.ConsultantprofileForm.controls.permanentAddressLine2.value === '' ? null : this.ConsultantprofileForm.controls.permanentAddressLine2.value,
                // tslint:disable-next-line:max-line-length
                mailBoxNumber: this.ConsultantprofileForm.controls.mailbox1.value === '' ? null : this.ConsultantprofileForm.controls.mailbox1.value,
                // tslint:disable-next-line:max-line-length
                country: this.ConsultantprofileForm.controls.permanentCountry.value === undefined || this.ConsultantprofileForm.controls.permanentCountry.value === '' ? '' : this.ConsultantprofileForm.controls.permanentCountry.value.code,
                // tslint:disable-next-line:max-line-length
                state: this.ConsultantprofileForm.controls.permanentState.value === null || this.ConsultantprofileForm.controls.permanentState.value === undefined || this.ConsultantprofileForm.controls.permanentState.value === '' ? '' : this.ConsultantprofileForm.controls.permanentState.value.code,
                // tslint:disable-next-line:max-line-length
                city: this.ConsultantprofileForm.controls.permanentCity.value === '' ? null : this.ConsultantprofileForm.controls.permanentCity.value,
                // tslint:disable-next-line:max-line-length
                zip: this.ConsultantprofileForm.controls.permanentZip.value === '' ? null : this.ConsultantprofileForm.controls.permanentZip.value,
                lkAddrType: 'P',
                addId: this.ConsultantProfileData.address[0].addId,
              },
              {
                // tslint:disable-next-line:max-line-length
                address: this.ConsultantprofileForm.controls.mailingAddressLine1.value === '' ? null : this.ConsultantprofileForm.controls.mailingAddressLine1.value,
                // tslint:disable-next-line:max-line-length
                address2: this.ConsultantprofileForm.controls.mailingAddressLine2.value === '' ? null : this.ConsultantprofileForm.controls.mailingAddressLine2.value,
                // tslint:disable-next-line:max-line-length
                mailBoxNumber: this.ConsultantprofileForm.controls.mailbox2.value === '' ? null : this.ConsultantprofileForm.controls.mailbox2.value,
                // tslint:disable-next-line:max-line-length
                country: this.ConsultantprofileForm.controls.mailingCountry.value === undefined || this.ConsultantprofileForm.controls.mailingCountry.value === '' ? '' : this.ConsultantprofileForm.controls.mailingCountry.value.code,
                // tslint:disable-next-line:max-line-length
                state: this.ConsultantprofileForm.controls.mailingState.value === undefined || this.ConsultantprofileForm.controls.mailingState.value === '' ? '' : this.ConsultantprofileForm.controls.mailingState.value.code,
                // tslint:disable-next-line:max-line-length
                city: this.ConsultantprofileForm.controls.mailingCity.value === '' ? null : this.ConsultantprofileForm.controls.mailingCity.value,
                // tslint:disable-next-line:max-line-length
                zip: this.ConsultantprofileForm.controls.mailingZip.value === '' ? null : this.ConsultantprofileForm.controls.mailingZip.value,
                lkAddrType: 'M',
                addId: this.ConsultantProfileData.address[1].addId,
              },
            ],

          };

          this.apisService.putApi(environment.updateConsultantprofile, updateProfile).subscribe((res) => {
            this.updateResponse = res;
            if (this.updateResponse.hasOwnProperty('success') === true) {
              this.getConsultantProfileData();
              this.messageService.add({
                key: 'br',
                severity: '',
                life: 3000,
                detail: this.updateResponse.message,
              });
            }
            else if (this.updateResponse.hasOwnProperty('failed') === true) {
              this.messageService.add({
                key: 'br',
                severity: '',
                life: 3000,
                detail: this.updateResponse.failed,
              });
            } else {
              this.messageService.add({
                key: 'br',
                severity: '',
                life: 3000,
                detail: this.updateResponse.error,
              });
            }
            this.loader = false;

          });
        }
      }
    }

  }
}
