import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { MessageService } from 'primeng/api';
import { isEmpty } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-vendor-requirement-edit',
  templateUrl: './vendor-requirement-edit.component.html',
  styleUrls: ['./vendor-requirement-edit.component.scss'],
  providers: [MessageService, DatePipe],
})
export class VendorRequirementEditComponent implements OnInit {
  loader = false;
  skillsList: any = [];
  iconToggle = [];
  updateactioncols = [
    { field: 'name', header: 'Title', width: '8%' },
    { field: 'title', header: 'Name', width: '8%' },
    { field: '', header: 'Download', width: '8%' },
    { field: '', header: '', width: '8%' },
  ];
  // It is used for the  displaying the table header for vendor tab
  cols = [
    { field: 'account_name', header: 'Vendor', width: '8%' },
    { field: 'vendor_type', header: 'Vendor Tier', width: '6%' },
    { field: 'noOfSubmissions', header: 'No.Of.Submissions', width: '7%' },
    { field: 'averageRate', header: 'Avg.Rate', width: '5%' },
    { field: 'req_access_time', header: 'Access Time', width: '7%' },
    { field: 'status', header: 'Status', width: '5%' },
    { field: 'RatingAndReviews', header: 'Rating and Reviews', width: '8%' },
  ];
  payRatesTable = [
    { key: 'Pay Rate +  25%', value: 25 },
    { key: 'Pay Rate +  30%', value: 30 },
    { key: 'Pay Rate +  35%', value: 35 },
    { key: 'Pay Rate +  40%', value: 40 },
    { key: 'Pay Rate +  45%', value: 45 },
    { key: 'Pay Rate +  50%', value: 50 },
  ];
  // It is used for the displaying the vendor Status dropdown values
  vendorStatus = [
    { code: 'Active', name: 'Active' },
    { code: 'Inactive', name: 'Inactive' },
  ];
  // It is used for the  displaying the table header for all roles except csr role in submitted profile tab
  cols11 = [
    { field: 'name', header: 'Candidate Name', sno: 1 },
    { field: 'postedDate', header: 'Submitted Date', sno: 2 },
    { field: 'consultant_skills', header: 'Skill Set', sno: 3 },
    { field: 'experience', header: 'Experience', sno: 4 },
    { field: 'csr_status', header: 'Status', sno: 5 },
    { field: 'rate_salary', header: 'PayRate', sno: 6 },
    { field: '', header: 'Action', sno: 7 },
  ];
  // It is used for the displaying the Type dropdown values
  Type = [
    { name: 'Contract', code: 'CO' },
    { name: 'Contract to hire', code: 'C2C' },
    { name: 'Full time', code: 'PE' },
  ];
  // It is used for the displaying the work location dropdown values
  workLocation = [
    { name: 'Onsite', code: 'ON' },
    { name: 'Offsite', code: 'OF' },
    { name: 'Offshore', code: 'OS' },
  ];
  // It is used for the displaying the Status dropdown values
  statusValue = [
    { code: '-1', name: 'All' },
    { code: 'Active', name: 'Active' },
    { code: 'Inactive', name: 'Inactive' },
  ];
  first: any;
  rows: any = 10;
  page: any = 1;
  pageCount: any;
  tierTypes: any;
  editRequirementsForm: FormGroup;
  vendorForm: FormGroup;
  vendorAddForm: FormGroup;
  submittedProfileForm: FormGroup;
  status: any;
  getexperienceOptions = [];
  getreqCategoryUrl: any;
  requestData: any;
  filteredData: any;
  filterId: [];
  preFilteredData: any;
  preFilterId: [];
  filterIdString: any;
  preFilterIdString: any;
  EditRequirementsFromDate: string;
  EditRequirementsEndDate: string;
  reqId: any;
  reqUpdateData: any;
  Data: any;
  loginRole: any;
  vendorGrid: any;
  display: boolean;
  display1 = false;
  tireData: any;
  vendorNameData: any;
  vendorAccessTime: any;
  addedRes: any;
  submitProfileTableData: any[] = [];
  searchData: any;
  csrStatus: any;
  minDate: Date;
  minMaxRate: any = [];
  differenceInDays: any;
  typeOfTierOptions: any;
  disableVendorName = true;
  vendorGridSearchRes: any;
  disableVendorTier: boolean;
  reqCategoryOptions: any;
  skilldetailspopup: boolean;
  VendorEditAssociationDispaly: boolean;
  vendorAccountName: any;
  vendorEditForm: FormGroup;
  validStatus = false;
  TaxTermRes: any;
  TaxTermDailog: boolean;
  headerName: string;
  proceedGoButtton: boolean;
  primaryRolevalue: any;
  typeOfusr: any;
  taxTermDropDown: { label: string; value: string }[];
  resumeResponse: string;
  gridRecord: any;
  statusDailog = false;
  disableButton = false;
  XLSFile: Blob;
  dbQuery: any;
  pdfFile: Blob;
  filename = 'requirementFile.pdf';
  filename1 = 'requiremetFile.xlsx';
  submited: string;
  submited1: string;
  submittedProfileStatus: any;
  roleId: any;
  statusDD: any = [];
  downloadbutton: boolean;
  commentsHeader: string;
  tabchangeVar: boolean;
  tabchangeVar1: boolean;
  tabchangeVar2: boolean;
  tabChangeValue: any;
  indexval: any;
  name1: any;
  updt: boolean;
  primaryRole: any;
  userGroupId: any;
  totalRecords: any;
  totalRecords1: number;
  hireTypeData: any = [];
  billingData: any = [];
  expLabel = {};
  durataion = [
    { lable: 'Operations', value: 1 },
    { lable: 'Sales', value: 2 },
    { lable: 'Recruitment', value: 3 },
    { lable: 'Marketing', value: 4 },
    { lable: 'Executive', value: 5 },
  ];
  locationList: any = [];
  acctdetails: any;
  departmentList: any = [];
  acctDetails: any;
  enddata: boolean;
  positionData: any;
  MinRateIds: any;
  jobTitleId: any;
  updBtn: boolean;
  titel: any;
  postCat: void;
  reqName: any;
  reqPosi: any;
  jobCat: any;
  maxrate: any;
  updatebutton = true;
  submittedProfilesGridData: any;
  submitedtotalrecord: any;
  subProRecords: any;
  table = true;
  records: boolean;
  specializationSkillsData: any = [];
  array: any;
  consStatusData: any = [];
  resumeRes: any;
  popup: boolean;
  filedata: any;
  rowID: any;
  userId: string;
  idToDelete: any;
  formRoute: any;
  rowData: any;
  skillset: any = [];
  disabledDropdown: boolean;
  profileID: any;
  updateactionBtn: boolean;
  rowdataid: any;
  updateactionGrid: any = [];
  updateaction: boolean;
  userid: any;
  resumID: any;
  loaderForDownload: boolean;
  UserId: any;
  requiremntId: any;
  acctID: any;
  items: any = [];
  states: any;
  gridStatus: any;
  Candidatestatus: string;
  requId: string;
  rolenot5HIde: boolean;
  newTable: boolean;
  statusList = {};
  routeParams: any;
  ownerId: any;
  recived: boolean;
  reqStatus: string;
  reqSkillsValue: any = [];
  experienceData: any = [];
  isUploading1: boolean;
  selectedPrimaryskills: any = [];
  currencycode: any[];
  preferencList: any = [];
  currencyValue: any;
  currencysymbl: any;
  data: any;
  primarySkills: any = [];
  preferredSkills: any = [];
  skillset1 = {};
  downloadOptions: { label: string; icon: string; command: (event: any, i: any) => void; }[];
  skillsList12: any = [];
  exppatch: any;
  vendorId: any;
  rejectpopup: boolean;
  rejectform: FormGroup;
  rejectopention: any = [];
  rejectValue: any;
  conid: any;
  displayModal: boolean;
  reqProfileId: any;
  histroy: any;
  jobId: any;
  updateBilForm: FormGroup;
  payRate: any;
  canname: any;
  subconName: any;
  consultantRequirementDisplay: boolean;
  alertError: boolean;
  errorMsg: boolean;
  payrates: any;
  receivedCandidateForm: FormGroup;
  selected: boolean;
  startCurDate: Date;
  profilestatus: any;
  candidatename: any;
  hide: boolean;
  btndisable: boolean;
  disable: boolean;
  cols3: { dataKey: string; header: string; }[];
  parentReqId: any;
  usrroleid: any;
  parentreqId: any;
  getdropdown: any;
  createdbyaccID: any;
  getstatus: any;
  // otherskills = false;
  otherskilldata: boolean;
  patchlocationString: string;
  editLocationdata: any;
  statusDisable = true;
  shwmsg: boolean;
  showmsg: string;
  getotherDetails: FormGroup;
  displayOtherDetails: boolean;
  getotherdetailsdata: any;
  // tslint:disable-next-line:max-line-length
  constructor(private apisService: ApisService, private fb: FormBuilder, private datepipe: DatePipe, private routeParam: ActivatedRoute, private messageService: MessageService, private router: Router, private route: ActivatedRoute, private breadcrumbService: BreadcrumbService) {
    this.requestData = JSON.parse(sessionStorage.getItem('requestData'));
    this.requId = this.requestData.reqId;
    this.reqName = this.requestData.reqName;
    this.jobCat = this.requestData.jobCategory;
    this.maxrate = this.requestData.maxRate;
    this.ownerId = this.requestData.ownerId;
    this.reqStatus = this.requestData.reqStatus;
    // parentreqId
    this.breadcrumbService.setItems([
      { label: 'Requirements', routerLink: '/rec-requirements' },
      { label: 'Requirements Edit', routerLink: '/rec-requirement-edit' },
    ]);
    this.acctDetails = JSON.parse(this.apisService.decodeData());
    this.roleId = this.acctDetails.roles[0].id;
    this.cols11 = [
      { field: 'vendorName', header: 'Subcontractor Name', sno: 1 },
      { field: 'consultantName', header: 'Consultant Name', sno: 2 },
      { field: 'submittedDate', header: 'Received Date', sno: 3 },
      { field: 'skills', header: 'Skills', sno: 4 },
      { field: 'skills', header: 'Skills', sno: 5 },
      { field: 'profileState', header: 'Status', sno: 6 },
      { field: 'billRate', header: 'Bill Rate', sno: 7 },
      { field: 'Action', header: 'Action', sno: 8 },
    ];
    this.cols3 = [
      { dataKey: 'vendorName', header: 'Subcontractor Name' },
      { dataKey: 'consultantName', header: 'Consultant Name' },
      { dataKey: 'submittedDate', header: 'Received Date' },
      { dataKey: 'skills', header: 'Skills' },
      { dataKey: 'status', header: 'Status' },
      { dataKey: 'billRate', header: 'Bill Rate' },
    ];
  }
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.vendorGridSearch();
    }
  }
  ngOnInit() {
    this.minDate = new Date();
    this.minDate.setMonth(new Date().getMonth());
    this.minDate.setFullYear(new Date().getFullYear());
    this.startCurDate = this.minDate;
    this.receivedCandidateForm = this.fb.group({
      onboardDate1: ['', [Validators.required]],
    });
    this.acctdetails = JSON.parse(this.apisService.decodeData());
    this.preferencList = this.acctdetails.preferencesList;
    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    } else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.name1 = routeParams.params.name;
    this.titel = routeParams.params.title;
    this.parentreqId = routeParams.params.parentreqId;
    this.createdbyaccID = routeParams.params.createdbyaccID;
    this.formRoute = routeParams.params.from;
    this.getstatus = routeParams.params.reqStatus;
    this.acctDetails = JSON.parse(this.apisService.decodeData());
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
      this.roleId = this.acctDetails.roles[0].id;
    } else {
      this.roleId = this.usrroleid;
    }
    this.acctID = this.acctDetails.accId;
    if (this.reqStatus === 'C') {
      this.updatebutton = true;
    }
    else {
      this.updatebutton = false;
    }
    this.editRequirementsForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      requirementTitle: [''],
      requirementType: ['', Validators.required],
      requirementStartDate: ['', Validators.required],
      requirementEndDate: ['', Validators.required],
      requirementNoofPositions: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(11)]],
      requirementReqExp: ['', Validators.required],
      requirementWorkCenterLocation: ['', Validators.required],
      requirementStatus: ['', Validators.required],
      requirementMinRate: [''],
      requirementMaxRate: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      requirementCategory: [''],
      requirementDescription: ['', [Validators.required, Validators.maxLength(2000)]],
      requirementResponsibilities: ['', [Validators.required, Validators.maxLength(2000)]],
      requirementQualification: ['', [Validators.required, Validators.maxLength(500)]],
      requirementsSkillSet: ['', Validators.required],
      preferredSkills: ['', Validators.required],
      requirementComments: ['', Validators.maxLength(500)],
      positionCategory: [''],
      billingtype: ['', Validators.required],
      hrsperWeek: ['', Validators.pattern('^[0-9]*$')],
      duration: ['', Validators.pattern('^[0-9]*$')],
      department: [''],
      approval: [''],
      otherskills: ['']
    });
    this.getotherDetails = this.fb.group({
      addressControl: [''],
      addressFormControl: [''],
      Country: [''],
      State: [''],
      cityForm: [''],
      Phone: [''],
      zipForm: [''],
      faxForm: [''],
      mailbox1: [''],
      workSatus: ['']
    });
    this.getotherDetails.disable();
    if (this.getstatus === 'C') {
      // this.editRequirementsForm.disable();
    } else {
      this.editRequirementsForm.disable();
      this.editRequirementsForm.controls.positionCategory.disable();
      this.editRequirementsForm.controls.requirementCategory.disable();
    }
    this.updateBilForm = this.fb.group({
      billRateControl: ['', [Validators.required, Validators.min(1), Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
    });
    this.submittedProfileForm = this.fb.group({
      vendorName: ['', Validators.pattern('^[a-zA-Z]*$')],
      firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z]*$')]],
      lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z]*$')]],
      ssnNumber: [''],
      skillSet: [''],
      emailId: ['', [Validators.required, Validators.pattern('^[a-zA-Z]*$')]],
      csrStatus: [''],
      skillDetails: [''],
      comments1: ['', Validators.required],
      status: [''],
    });
    this.rejectform = this.fb.group({
      reject: ['', Validators.required]
    });
    if (this.roleId === 9 && this.reqStatus === 'R') {
      this.recived = true;
      this.submitProfileGrid();
    }
    else {
      this.recived = false;
    }
    this.reqEditVendorGrid();
    this.rolenot5HIde = true;
    this.newTable = false;
    this.getDepartments();
    this.getWorkLocation();
    this.getexperience();
    this.getdSkillset();
    // this.getExperienceDropDownData();
    this.getConStatus();
    this.reqSkillSet();
    this.reqCategoryDropdown();
    this.getBillingDropdown();
    this.getHireTypeData();
    this.getStatus();
    this.gridTable();
    // this.getcategory();
    this.onTabChange(0);
    this.reqdetils();
  }
  getDepartments() {
    this.getdropdown = this.parentreqId === '0' ? this.createdbyaccID : this.ownerId;
    this.apisService.getApi(environment.departments + '/' + this.getdropdown).subscribe((res) => {
      this.departmentList = res.data;
      this.editRequirementsForm.controls.department.patchValue(
        this.departmentList.filter(
          (x) => x.id === this.requestData.departmentId
        )[0]
      );
    });
  }
  getWorkLocation() {
    this.acctdetails = JSON.parse(this.apisService.decodeData());
    this.getdropdown = this.parentreqId === '0' ? this.createdbyaccID : this.ownerId;
    this.apisService.getApi(environment.location + '/' + this.getdropdown).subscribe((res) => {
      this.locationList = res.data;
    });
  }
  getexperience() {
    this.apisService.getApi(environment.lookupsData + 'RELYE').subscribe((res: any) => {
      this.getexperienceOptions = res.data;
      this.getexperienceOptions.forEach((element) => {
        this.expLabel[element.label] = [element.code];
      });
    });
  }
  getdSkillset() {
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.skillsList = res.data;
      this.skillsList.forEach((element) => {
        this.skillset1[element.code] = element.label;
      });
    });
  }
  getConStatus() {
    this.apisService
      .getApi(environment.lookupsData + 'RPLPS')
      .subscribe((res) => {
        this.consStatusData = res.data;
        this.consStatusData.forEach((element) => {
          this.statusList[element.code] = element.label;
        });
      });
  }
  reqSkillSet() {
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.specializationSkillsData = res.data;
    });
  }
  reqCategoryDropdown() {
    this.apisService.getApi(environment.reqArea).subscribe((res) => {
      this.reqCategoryOptions = res.data;
      const val = {
        catname: 'Select Req Category ',
        grpcategory: -1,
      };
    });
  }
  getBillingDropdown() {
    this.apisService.getApi(environment.billingType).subscribe((res) => {
      this.billingData = res.data;
    });
  }
  getHireTypeData() {
    this.apisService.getApi(environment.hireType).subscribe((res) => {
      this.hireTypeData = res.data;
    });
  }
  getStatus() {
    this.apisService.getApi(environment.lookupsData + 'RELS').subscribe((res) => {
      this.statusDD = res.data;
    });
  }
  gridTable() {
    this.loader = true;
    const searchstatus =
      // tslint:disable-next-line:max-line-length
      this.submittedProfileForm.controls.status.value === null || this.submittedProfileForm.controls.status.value === '' ? '' : this.submittedProfileForm.controls.status.value === undefined ? '' : this.submittedProfileForm.controls.status.value.code;
    // tslint:disable-next-line:max-line-length
    const searchEmail = this.submittedProfileForm.controls.emailId.value === null || this.submittedProfileForm.controls.emailId.value === '' ? '' : this.submittedProfileForm.controls.emailId.value === undefined ? '' : this.submittedProfileForm.controls.emailId.value;
    const firstName = this.submittedProfileForm.controls.firstName.value === null || this.submittedProfileForm.controls.firstName.value === '' ? '' : this.submittedProfileForm.controls.firstName.value === undefined ? '' : this.submittedProfileForm.controls.firstName.value;
    // tslint:disable-next-line:max-line-length
    const lastname = this.submittedProfileForm.controls.lastName.value === null || this.submittedProfileForm.controls.lastName.value === '' ? '' : this.submittedProfileForm.controls.lastName.value === undefined ? '' : this.submittedProfileForm.controls.lastName.value;
    const search1 = this.page + '/' + this.rows + '/' + this.roleId + '?reqId=' + this.requestData.reqId;
    const email = '&consEmail=' + searchEmail;
    const status = '&status=' + searchstatus;
    const fname = '&fName=' + firstName;
    const lname = '&lName=' + lastname;
    this.apisService.getApi(
      environment.vendorreceivedprofile + search1 + email + fname + lname + status)
      .subscribe((res: any) => {
        this.submittedProfilesGridData = res.data.records;
        this.loader = false;
        this.subProRecords = res.data.count;
        if (this.subProRecords !== 0) {
          this.table = true;
          this.records = false;
        } else {
          this.table = false;
          this.records = true;
        }
      });
  }
   // It is used for get particular row data based on the id and binding these values into the form
   reqdetils() {
    this.loader = true;
    const requirementId = '/' + this.requId;
    this.loader = true;
    this.apisService.getApi(environment.requirementEdit + requirementId).subscribe((res) => {
      this.requestData = res.data;
      this.getDepartments();
      if (this.parentreqId === '0') {
      } else if (this.requestData.status === 'C') {
        this.editRequirementsForm.disable();
        this.editRequirementsForm.controls.name.enable();
        this.editRequirementsForm.controls.requirementMaxRate.enable();
      }else {
        this.editRequirementsForm.disable();
      }
      this.getdropdown = this.parentreqId === '0' ? this.createdbyaccID : this.ownerId;
      // tslint:disable-next-line:no-shadowed-variable
      this.apisService.getApi(environment.positionCat + '/' + this.getdropdown).subscribe((res) => {
        this.positionData = res.data;
        this.editRequirementsForm.controls.name.patchValue(this.requestData.name);
        // tslint:disable-next-line:max-line-length
        this.editRequirementsForm.controls.positionCategory.patchValue(this.positionData.filter((x) => x.id === this.requestData.category)[0]);
      });
      this.jobTitleId = this.requestData.jobTitle.id;
      this.getPositionCategoryBindDataValues(this.requestData);
      this.typeData(this.requestData.hireType);
      if (this.requestData.status === 'C') {
        this.validStatus = false;
      } else {
        this.validStatus = true;
      }
      this.statusDisable = true;
      this.editRequirementsForm.patchValue({
        requirementStartDate: this.requestData.plannedStartDate,
        requirementEndDate: this.requestData.plannedEndDate,
        requirementNoofPositions: this.requestData.noOfPositions,
        requirementMinRate: this.requestData.minRate,
        requirementMaxRate: this.requestData.maxRate,
        requirementDescription: this.requestData.description,
        requirementResponsibilities: this.requestData.responsibilities,
        requirementQualification: this.requestData.qualification,
        requirementComments: this.requestData.comments,
        hrsperWeek: this.requestData.hrsPerWeek,
        duration: this.requestData.duration,
        approval: this.requestData.empApprovalReqFlag,
        // otherskills: this.requestData.otherSkills

      });


      const selectedPrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.skillsList.length; i++) {
        const array = this.requestData.prefferedSkills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.skillsList[i].code) === array[j]) {
            selectedPrimarySkills.push(this.skillsList[i]);
          }
        }
      }
      const preferredSkills = selectedPrimarySkills;
      preferredSkills.forEach((element) => {
        this.skillsList12.push(element.label);
      });
      const selectedPrePrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.skillsList.length; i++) {
        const array = this.requestData.skills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.skillsList[i].code) === array[j]) {
            selectedPrePrimarySkills.push(this.skillsList[i]);
          }
        }
      }
      const primarySkills = selectedPrePrimarySkills;
      primarySkills.forEach((element) => {
        this.primarySkills.push(element.label);
      });
      this.editRequirementsForm.controls.requirementsSkillSet.patchValue(selectedPrePrimarySkills);
      this.editRequirementsForm.controls.preferredSkills.patchValue(preferredSkills);
      selectedPrePrimarySkills.forEach((element) => {
        this.selectedPrimaryskills.push(element.label);
      });
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < selectedPrePrimarySkills.length; i++) {
        if (selectedPrePrimarySkills[i].code === '25') {
          this.otherskilldata = true;
          this.editRequirementsForm.controls.otherskills.patchValue(this.requestData.otherSkills);

        }
        else {
          this.onBlur('');
        }
      }
      // tslint:disable-next-line: max-line-length
      this.editRequirementsForm.controls.requirementReqExp.patchValue(this.getexperienceOptions.filter((x) => x.code === this.requestData.experience)[0]);
      this.editRequirementsForm.controls.requirementType.patchValue(
        this.hireTypeData.filter((x) => x.code === this.requestData.hireType)[0]);
      this.editRequirementsForm.controls.billingtype.patchValue(
        this.billingData.filter((x) => x.code === this.requestData.billingType)[0]);
      this.editRequirementsForm.controls.requirementWorkCenterLocation.patchValue(
        this.locationList.filter((x) => x.locId === this.requestData?.location)[0]);
      this.editLocationdata = this.locationList.filter((x) => x.locId === this.requestData?.location)[0];
      this.patchlocationString = this.editLocationdata?.name + '\n' +
        this.editLocationdata?.address1 + ',' + this.editLocationdata?.city + ',' + this.editLocationdata?.state +
        ',' + this.editLocationdata?.country;
      this.editRequirementsForm.controls.requirementStatus.patchValue(
        this.statusDD.filter((x) => x.code === this.requestData.status)[0]
      );
      this.loader = false;
    });
  }
  // getExperienceDropDownData() {
  //   this.apisService
  //     .getApi(environment.lookupsData + 'RELYE')
  //     .subscribe((res: any) => {
  //       this.experienceData = res.data;
  //       this.experienceData.forEach((element) => {
  //         this.expLabel[element.code] = [element.label];
  //       });
  //     });
  // }
  deleteRow() {
    Swal.fire({
      text: 'Are You Sure To Remove ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0D416B',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.apisService
          .deleteApi(environment.removeRequirement + this.idToDelete)
          .subscribe((res: any) => {
            this.submitProfileGrid();
            Swal.fire({
              text: res.message, icon: 'success', confirmButtonColor: '#0D416B', confirmButtonText: 'Ok', reverseButtons: false,
            });
          });
      }
    });
  }
  toggle(menu, event, rowData) {
    this.parentReqId = rowData.parentReqId;
    this.profilestatus = rowData.profileState;
    this.conid = rowData.conId;
    this.userId = rowData.userId;
    this.jobId = rowData.reqId;
    this.vendorId = rowData.vendorId;
    this.payRate = rowData.billRate;
    this.canname = rowData.consultantName;
    this.subconName = rowData.vendorName;
    this.rowData = rowData;
    this.idToDelete = rowData.profileId;
    if (this.rowData.profileState === 'A' && this.parentreqId !== '0') {
      // tslint:disable-next-line:no-shadowed-variable
      this.items = [{ label: 'Remove', icon: 'pi pi-fw pi-trash', command: (event, i) => { this.deleteRow(); }, },
      { label: 'Reject', icon: 'pi pi-fw pi-times-circle', command: () => { this.reject(); }, },
      { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
      { label: 'Update Profile', icon: 'pi pi-fw pi-refresh', command: () => { this.updateAction(); }, },
      { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); }, },
      { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
      { label: 'Submit Candidate', icon: 'pi pi-fw pi-check-circle', command: () => { this.submitCust(); }, },
      { label: 'Select Candidate', icon: 'pi pi-user ', command: () => { this.selectcandidate(); }, },
      { label: 'Associate To Original Requirement', icon: 'pi pi-fw pi-check-circle', command: () => { this.submittoCustomer(); }, },
      { label: 'Profile Activity', icon: 'pi piw pi-clock', command: () => { this.profilehistroy(); }, },
      ];
    } else if (this.rowData.profileState === 'A' && this.parentreqId === '0') {
      // tslint:disable-next-line: no-shadowed-variable
      this.items = [{ label: 'Remove', icon: 'pi pi-fw pi-trash', command: (event, i) => { this.deleteRow(); }, },
      { label: 'Reject', icon: 'pi pi-fw pi-times-circle', command: () => { this.reject(); }, },
      { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
      { label: 'Update Profile', icon: 'pi pi-fw pi-refresh', command: () => { this.updateAction(); }, },
      { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); }, },
      { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
      { label: 'Submit Candidate', icon: 'pi pi-fw pi-check-circle', command: () => { this.submitCust(); }, },
      { label: 'Select Candidate', icon: 'pi pi-user ', command: () => { this.selectcandidate(); }, },
      { label: 'Profile Activity', icon: 'pi piw pi-clock', command: () => { this.profilehistroy(); }, },
      { label: 'Other Details', icon: 'pi pi-info-circle', command: () => { this.getOtherDetails(); }, },
      ];
    }
    else if (this.rowData.profileState === 'R') {
      this.items = [
        { label: 'Reject reason', icon: 'pi pi-fw pi-times-circle', command: () => { }, },
        { label: 'Show reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
        // tslint:disable-next-line:max-line-length
        { label: 'Candidate details', icon: 'pi pi-fw pi-user', command: () => { this.router.navigate(['/editcandidate', this.conid]); }, },
        { label: 'Other Details', icon: 'pi pi-info-circle', command: () => { this.getOtherDetails(); }, },
      ];
    }
    else if (this.rowData.profileState === 'S' && this.parentreqId !== '0') {
      this.items = [
        { label: 'Reject', icon: 'pi pi-fw pi-times-circle', command: () => { this.reject(); }, },
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); }, },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
        { label: 'Select Candidate', icon: 'pi pi-user ', command: () => { this.selectcandidate(); }, },
        { label: 'Associate To Original Requirement', icon: 'pi pi-fw pi-check-circle', command: () => { this.submittoCustomer(); }, },
        { label: 'Profile Activity', icon: 'pi piw pi-clock', command: () => { this.profilehistroy(); }, },
        { label: 'Other Details', icon: 'pi pi-info-circle', command: () => { this.getOtherDetails(); }, },
      ];
    }
    else if (this.rowData.profileState === 'S' && this.parentreqId === '0') {
      this.items = [
        { label: 'Reject', icon: 'pi pi-fw pi-times-circle', command: () => { this.reject(); }, },
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); }, },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
        { label: 'Select Candidate', icon: 'pi pi-user ', command: () => { this.selectcandidate(); }, },
        // { label: 'Associate To Original Requirement', icon: 'pi pi-fw pi-check-circle', command: () => { this.submittoCustomer(); }, },
        { label: 'Profile Activity', icon: 'pi piw pi-clock', command: () => { this.profilehistroy(); }, },
        { label: 'Other Details', icon: 'pi pi-info-circle', command: () => { this.getOtherDetails(); }, },
      ];
    }
    else if (this.rowData.profileState === 'I' && this.parentreqId !== '0') {
      this.items = [
        { label: 'Reject', icon: 'pi pi-fw pi-times-circle', command: () => { this.reject(); }, },
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); }, },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
        { label: 'Select Candidate', icon: 'pi pi-user ', command: () => { this.selectcandidate(); }, },
        { label: 'Associate To Original Requirement', icon: 'pi pi-fw pi-check-circle', command: () => { this.submittoCustomer(); }, },
        { label: 'Profile Activity', icon: 'pi piw pi-clock', command: () => { this.profilehistroy(); }, },
        { label: 'Other Details', icon: 'pi pi-info-circle', command: () => { this.getOtherDetails(); }, },
      ];
    }
    else if (this.rowData.profileState === 'I' && this.parentreqId === '0') {
      this.items = [
        { label: 'Reject', icon: 'pi pi-fw pi-times-circle', command: () => { this.reject(); }, },
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); }, },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
        { label: 'Select Candidate', icon: 'pi pi-user ', command: () => { this.selectcandidate(); }, },
        // { label: 'Associate To Original Requirement', icon: 'pi pi-fw pi-check-circle', command: () => { this.submittoCustomer(); }, },
        { label: 'Profile Activity', icon: 'pi piw pi-clock', command: () => { this.profilehistroy(); }, },
        { label: 'Other Details', icon: 'pi pi-info-circle', command: () => { this.getOtherDetails(); }, },
      ];
    }
    else if (this.rowData.profileState === 'Z' && this.parentreqId !== '0') {
      this.items = [
        { label: 'Reject', icon: 'pi pi-fw pi-times-circle', command: () => { this.reject(); }, },
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); }, },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
        { label: 'Associate To Original Requirement', icon: 'pi pi-fw pi-check-circle', command: () => { this.submittoCustomer(); }, },
        { label: 'Profile Activity', icon: 'pi piw pi-clock', command: () => { this.profilehistroy(); }, },
        { label: 'Other Details', icon: 'pi pi-info-circle', command: () => { this.getOtherDetails(); }, },
      ];
    }
    else if (this.rowData.profileState === 'Z' && this.parentreqId === '0') {
      this.items = [
        { label: 'Reject', icon: 'pi pi-fw pi-times-circle', command: () => { this.reject(); }, },
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); }, },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
        // { label: 'Associate To Original Requirement', icon: 'pi pi-fw pi-check-circle', command: () => { this.submittoCustomer(); }, },
        { label: 'Profile Activity', icon: 'pi piw pi-clock', command: () => { this.profilehistroy(); }, },
        { label: 'Other Details', icon: 'pi pi-info-circle', command: () => { this.getOtherDetails(); }, },
      ];
    }
    else if (this.rowData.profileState === 'U' && this.parentreqId !== '0') {
      this.items = [
        { label: 'Reject', icon: 'pi pi-fw pi-times-circle', command: () => { this.reject(); }, },
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); }, },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
        { label: 'Select Candidate', icon: 'pi pi-user ', command: () => { this.selectcandidate(); }, },
        { label: 'Associate To Original Requirement', icon: 'pi pi-fw pi-check-circle', command: () => { this.submittoCustomer(); }, },
        { label: 'Profile Activity', icon: 'pi piw pi-clock', command: () => { this.profilehistroy(); }, },
        { label: 'Other Details', icon: 'pi pi-info-circle', command: () => { this.getOtherDetails(); }, },
      ];
    }
    else if (this.rowData.profileState === 'U' && this.parentreqId === '0') {
      this.items = [
        { label: 'Reject', icon: 'pi pi-fw pi-times-circle', command: () => { this.reject(); }, },
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); }, },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
        { label: 'Select Candidate', icon: 'pi pi-user ', command: () => { this.selectcandidate(); }, },
        // { label: 'Associate To Original Requirement', icon: 'pi pi-fw pi-check-circle', command: () => { this.submittoCustomer(); }, },
        { label: 'Profile Activity', icon: 'pi piw pi-clock', command: () => { this.profilehistroy(); }, },
        { label: 'Other Details', icon: 'pi pi-info-circle', command: () => { this.getOtherDetails(); }, },
      ];
    }
    else if (this.rowData.profileState === 'B' && this.parentreqId !== '0') {
      this.items = [
        { label: 'Reject', icon: 'pi pi-fw pi-times-circle', command: () => { this.reject(); }, },
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); }, },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
        { label: 'Select Candidate', icon: 'pi pi-user ', command: () => { this.selectcandidate(); }, },
        { label: 'Associate To Original Requirement', icon: 'pi pi-fw pi-check-circle', command: () => { this.submittoCustomer(); }, },
        { label: 'Profile Activity', icon: 'pi piw pi-clock', command: () => { this.profilehistroy(); }, },
        { label: 'Other Details', icon: 'pi pi-info-circle', command: () => { this.getOtherDetails(); }, },
      ];
    }
    else if (this.rowData.profileState === 'B' && this.parentreqId === '0') {
      this.items = [
        { label: 'Reject', icon: 'pi pi-fw pi-times-circle', command: () => { this.reject(); }, },
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); }, },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
        { label: 'Select Candidate', icon: 'pi pi-user ', command: () => { this.selectcandidate(); }, },
        // { label: 'Associate To Original Requirement', icon: 'pi pi-fw pi-check-circle', command: () => { this.submittoCustomer(); }, },
        { label: 'Profile Activity', icon: 'pi piw pi-clock', command: () => { this.profilehistroy(); }, },
        { label: 'Other Details', icon: 'pi pi-info-circle', command: () => { this.getOtherDetails(); }, },
      ];
    }
    // profileState
    else if (this.rowData.profileState === 'L') {
      this.items = [
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); }, },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
        { label: 'Select Candidate', icon: 'pi pi-user ', command: () => { this.selectcandidate(); }, },
        { label: 'Profile Activity', icon: 'pi piw pi-clock', command: () => { this.profilehistroy(); }, },
        { label: 'Other Details', icon: 'pi pi-info-circle', command: () => { this.getOtherDetails(); }, },
      ];
    }
    else if (this.rowData.profileState === 'C') {
      this.items = [
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
        { label: 'Profile Activity', icon: 'pi piw pi-clock', command: () => { this.profilehistroy(); }, },
        { label: 'Other Details', icon: 'pi pi-info-circle', command: () => { this.getOtherDetails(); }, },
      ];
    }
  }
  getPState(state) {
    this.states = state;
  }
  // It is used for displaying the experience dropdown values
  selectcandidate() {
    if (this.profilestatus === 'C') {
      // tslint:disable-next-line:max-line-length
      Swal.fire({ text: 'Candidate already selected', icon: 'success', iconColor: '#1dbb99', confirmButtonColor: '#0D416B', confirmButtonText: 'Ok' });
    }
    else {
      this.selected = true;
      this.candidatename = this.rowData.consultantName;
      this.receivedCandidateForm.reset();
    }
  }
  // method to select candidate
  canidateselection() {
    if (this.receivedCandidateForm.invalid) {
      const controls = this.receivedCandidateForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      const date = this.receivedCandidateForm.controls.onboardDate1.value;
      const onboarddate = new Date(Date.parse(date));
      this.EditRequirementsFromDate = this.datepipe.transform(onboarddate, 'MM-dd-yyyy');
      // tslint:disable-next-line:max-line-length
      this.disable = true;
      if (this.vendorId === 2) {
        // tslint:disable-next-line:max-line-length
        this.apisService.getApi(environment.selectVendorProfile + this.idToDelete + '?plannedStartDate=' + this.EditRequirementsFromDate).subscribe((res: any) => {
          this.submitProfileGrid();
          if (res.hasOwnProperty('success') === true) {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
            this.loader = false;
            this.disable = false;
            this.selected = false;
          } else if (res.hasOwnProperty('success') === false) {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed, });
            this.loader = false;
            this.disable = false;
            this.selected = false;
          } else {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
            this.disable = false;
            this.selected = false;
          }
          this.reset();
        });
      } else {
        // tslint:disable-next-line:max-line-length
        this.apisService.getApi(environment.selectedCandidate + this.idToDelete + '?plannedStartDate=' + this.EditRequirementsFromDate).subscribe((res: any) => {
          this.submitProfileGrid();
          if (res.hasOwnProperty('success') === true) {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
            this.loader = false;
            this.disable = false;
            this.selected = false;
          } else if (res.hasOwnProperty('success') === false) {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed, });
            this.loader = false;
            this.disable = false;
            this.selected = false;
          } else {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
            this.disable = false;
            this.selected = false;
          }
          this.reset();
        });
      }

    }
  }
  profilehistroy() {
    this.displayModal = true;
    if (this.idToDelete === null || this.idToDelete === undefined) {
    } else {
      this.apisService.getApi(environment.getConsultantProfileActivity + '/' + this.idToDelete).subscribe((res: any) => {
        this.histroy = res.data;
        this.loader = false;
      });
    }
  }
  // // It is used for displaying the Reqcategory dropdown values
  // getcategory() {
  // }
  reject() {
    this.rejectpopup = true;
    this.apisService.getApi(environment.lookupsData + 'RPLR').subscribe((res) => {
      this.rejectopention = res.data;
    });
  }
  getRejectOption(value) {
    this.rejectValue = value;
  }
  rejectcandidate() {
    this.loader = true;
    if (this.vendorId === 2) {
      this.apisService.getApi(environment.rejectpublicprofile + this.idToDelete + '/' + this.rejectValue).subscribe((res: any) => {
        this.gridTable();
        this.rejectpopup = false;
        this.rejectform.reset();
        this.submitProfileGrid();
        this.loader = false;
        if (res.hasOwnProperty('success') === true) {
          this.gridTable();
          this.submitProfileGrid();
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
        }
      });
    } else {
      this.apisService.getApi(environment.reject + '/' + this.idToDelete + '/' + this.rejectValue).subscribe((res: any) => {
        this.gridTable();
        this.rejectpopup = false;
        this.rejectform.reset();
        this.submitProfileGrid();
        this.loader = false;
        if (res.hasOwnProperty('success') === true) {
          this.gridTable();
          this.submitProfileGrid();
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
        }
      });
    }
  }
  downLoadsToggle() {
    this.downloadOptions = [
      { label: 'Download XLSX', icon: 'pi pi-download', command: (event, i) => { this.exportExcel(); }, },
      { label: 'Download PDF', icon: 'pi pi-download', command: (event, i) => { this.exportPdf(); }, },
    ];
  }
  exportExcel() {
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    ws['!cols'] = [ {width : 10}, {width : 20}  , {width : 20} , {width : 10}, {width : 10}, {width : 10}, {width : 10}, {hidden : true} ];
    ws['!rows'] = [{hpt : 30}, {hpt : 30}, ];
    ws['!cols'][3] = { hidden: true };
    ws['!cols'][7] = { hidden: true };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ExcelSheet.xlsx');
  }
  // It is used for displaying the Position Category dropdown values and patch the value for min rate and max rate
  getPositionCategoryBindDataValues(data) {
    // const acctId = this.ownerId;
    this.getdropdown = this.parentreqId === '0' ? this.createdbyaccID : this.ownerId;

    const posnCat = data.category;
    this.requiremntId = data.reqId;
    this.apisService
      .getApi(environment.maxRate + this.getdropdown + '/' + posnCat)
      .subscribe((res) => {
        this.minMaxRate = res.data;
        this.editRequirementsForm.controls.requirementTitle.patchValue(
          this.minMaxRate.filter((x) => x.title === data.jobTitle.title)[0]
        );
      });
  }
  getTite(data) {
  }
  // It is used for the updating the existing data
  requirementEditUpdate() {
    if (this.editRequirementsForm.invalid) {
      const controls = this.editRequirementsForm.controls;
      this.editRequirementsForm.controls.requirementEndDate.markAsUntouched();
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      this.loader = true;
      const skill = this.editRequirementsForm.get('requirementsSkillSet').value;
      this.filteredData = skill.map((x) => x.label);
      this.filterId = skill.map((x) => x.code);
      this.filterIdString = this.filterId;
      const preSkills = this.editRequirementsForm.get('preferredSkills').value;
      this.preFilteredData = preSkills.map((x) => x.label);
      this.preFilterId = preSkills.map((x) => x.code);
      this.preFilterIdString = this.preFilterId;
      if (this.editRequirementsForm.controls.requirementStartDate.value) {
        const addreqfrmDate = this.editRequirementsForm.controls.requirementStartDate.value;
        const addReqFrmDate = new Date(Date.parse(addreqfrmDate));
        this.EditRequirementsFromDate = this.datepipe.transform(addReqFrmDate, 'MM-dd-yyyy');
      }
      const reqEndDate = this.editRequirementsForm.controls.requirementEndDate.value;
      if (this.editRequirementsForm.controls.requirementEndDate.value) {
        if (this.requestData.hireType === 'F' || (this.requestData.hireType === 'O' && reqEndDate === '')) {
          this.EditRequirementsEndDate = '';
        } else {
          const editreqEndDate = this.editRequirementsForm.controls.requirementEndDate.value;
          const addReqEndDate = new Date(Date.parse(editreqEndDate));
          this.EditRequirementsEndDate = this.datepipe.transform(addReqEndDate, 'MM-dd-yyyy');
        }
      }
      this.acctDetails = JSON.parse(this.apisService.decodeData());
      const parms = {
        id: Number(this.requId),
        accDeptId: this.editRequirementsForm.controls.department.value.id,
        accId: this.acctDetails.accId,
        billingType: this.editRequirementsForm.controls.billingtype.value.code,
        comment: this.editRequirementsForm.controls.requirementComments.value,
        createdBy: this.acctDetails.userId,
        createdByAccId: 1,
        hireType: this.editRequirementsForm.controls.requirementType.value.code,
        hrsPerWeek: this.editRequirementsForm.controls.hrsperWeek.value,
        locationId: this.editRequirementsForm.controls.requirementWorkCenterLocation.value.locId,
        modifiedBy: 1,
        preSkillSet: this.preFilterIdString,
        reqCreatedBy: 1,
        reqDuration: Number(this.editRequirementsForm.controls.duration.value),
        // tslint:disable-next-line: max-line-length
        reqEndDate: this.editRequirementsForm.controls.requirementEndDate.value === null || this.editRequirementsForm.controls.requirementEndDate.value === '' || this.editRequirementsForm.controls.requirementEndDate.value === undefined ? '' : this.datepipe.transform(this.editRequirementsForm.controls.requirementEndDate.value, 'MM-dd-yyyy'),
        reqJobdesc: this.editRequirementsForm.controls.requirementDescription.value,
        reqMaxRate: Number(this.editRequirementsForm.controls.requirementMaxRate.value),
        reqMinRate: Number(this.editRequirementsForm.controls.requirementMinRate.value),
        reqName: this.editRequirementsForm.controls.name.value,
        reqNoOfResources: this.editRequirementsForm.controls.requirementNoofPositions.value,
        reqQualification: this.editRequirementsForm.controls.requirementQualification.value,
        reqResponsibility: this.editRequirementsForm.controls.requirementResponsibilities.value,
        reqSkillSet: this.filterIdString,
        reqStartDate: this.EditRequirementsFromDate,
        reqType: 'F',
        jobTitle: this.jobTitleId,
        positionCategory: this.editRequirementsForm.controls.positionCategory.value.id,
        yearExperience: this.editRequirementsForm.controls.requirementReqExp.value.code,
        reqStatus: this.validStatus === false ? 'P' : this.editRequirementsForm.controls.requirementStatus.value.code,
        empApprovalReqFlag: this.editRequirementsForm.controls.approval.value,
        otherSkills: this.otherskilldata === true ? this.editRequirementsForm.controls.otherskills.value : '',
      };
      this.apisService.putApi(environment.requirementEdit, parms).subscribe((res: any) => {
        this.reqUpdateData = res;
        this.loader = false;
        this.loader = false;
        if (this.reqUpdateData.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.reqUpdateData.message, });
          this.reqdetils();
          this.loader = false;
          this.router.navigate(['/rec-requirements']);
        } else if (this.reqUpdateData.hasOwnProperty('success') === false) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.reqUpdateData.failed, });
          this.loader = false;
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.reqUpdateData.error, });
        }
        this.loader = false;
      });

    }
  }
  // It is used for the displaying the GridData for vendor Tab
  reqEditVendorGrid() {
    const id = {
      requirementId: this.requId,
      tireType: '-1',
      requirementStatus: 'Active',
    };
  }
  // It is used for the searching the the data based on the griddata for vendor Tab
  vendorGridSearch() {
    const status = this.vendorForm.get('vendorStatus').value;
    const type = this.vendorForm.get('vendorTire').value;
    const searchReq = {
      requirementId: this.requestData.requirement_id,
      tireType: type === undefined || type === null || type === '' ? '-1' : this.vendorForm.get('vendorTire').value.id,
      requirementStatus: status === undefined || status === null || status === '' ? '-1' : this.vendorForm.get('vendorStatus').value.code,
    };
  }
  // It is used for add dialog box for Add Association
  addDilog() {
    this.vendorAddForm.reset();
    this.submitProfileGrid();
    this.display1 = true;
  }
  // It is used for the displaying the vendor name Dropdown Values
  vendorSelect() {
    const body = {
      tireType: this.vendorAddForm.get('vendorAddTier').value.id,
      orgId: JSON.parse(sessionStorage.getItem('loginData')).orgId,
      reqId: this.requestData.requirement_id,
    };
    this.disableVendorTier = true;
  }
  // It is used for the adding the Add Association, converting date format and keep the validations
  saveVendorDetails() {
    if (this.vendorAddForm.invalid) {
      const controls = this.vendorAddForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      const vendorMultipleSelect = [];
      this.vendorAddForm.get('vendorAddNames').value.forEach((element) => {
        vendorMultipleSelect.push(element.vendor_id);
      });
      if (this.vendorAddForm.get('vendorAddaccessTime').value) {
        const accessTime = this.vendorAddForm.get('vendorAddaccessTime').value;
        const conAccessTime = new Date(Date.parse(accessTime));
        this.vendorAccessTime = this.datepipe.transform(conAccessTime, 'MM-dd-yyyy');
      }
      const reqBody = {
        vendorList: vendorMultipleSelect === ('' || undefined) ? null : vendorMultipleSelect.toString(),
        reqId: JSON.parse(this.requId),
        accessTime: this.vendorAccessTime + ' ' + new Date().getHours() + ':' + new Date().getMinutes(),
        userId: JSON.parse(sessionStorage.getItem('userId')),
      };
    }
  }
  // It is used for the reset the submitted profile tab form values
  reset() {
    this.submittedProfileForm.reset();
    this.gridTable();
  }
  // It is used for end date validation
  endDateValidation() {
    if (
      new Date(
        this.editRequirementsForm.get('requirementStartDate').value
      ).getTime() <
      new Date(
        this.editRequirementsForm.get('requirementEndDate').value
      ).getTime()
    ) {
      const startDate = this.editRequirementsForm.get(
        'requirementStartDate'
      ).value;
    } else {
      this.editRequirementsForm.get('requirementEndDate').reset();
      const startDate = this.editRequirementsForm.get(
        'requirementStartDate'
      ).value;
    }
  }
  // It is used for the reset the vendor tab form values
  resetVendor() {
    this.vendorForm.reset();
    this.reqEditVendorGrid();
  }
  typeData(event) {
    if (event === 'C' || event === 'H') {
      this.enddata = true;
      this.editRequirementsForm.get('requirementEndDate').markAsTouched();
      this.editRequirementsForm.get('requirementEndDate').enable();
    } else {
      this.editRequirementsForm.get('requirementEndDate').disable();
      this.enddata = false;
    }
  }
  // It is used for the reset the  Add Association  form values in vendor tab
  resetVendorAssociation() {
    this.vendorAddForm.reset();
  }
  // tslint:disable-next-line: max-line-length
  // It is used to navigate the req tech reviews details page and also pass the data through query params and set the profile details to loca storage
  navigateTo(data) {
    const profileDetails = {
      reqId: data.requirement_id,
      consultantId: data.consultant_id,
      candidateName: data.name,
    };
    this.submited1 = 'requirements-edit';
    sessionStorage.setItem('profileDetails', JSON.stringify(profileDetails));
    this.router.navigate(['/req-techReviewDetails'], {
      queryParams: {
        reqId: data.requirement_id,
        consultantId: data.consultant_id,
        techName: this.submited1,
        status: data.status,
        name1: this.name1,
      },
    });
  }
  downloadFileapi() {
    this.filedata = this.rowData.resumeId;
    this.isUploading1 = true;
    this.apisService.getApi(environment.downloadedattachment + '/' + this.filedata).subscribe((res) => {
      this.resumeRes = res;
      this.isUploading1 = false;
      window.location.assign(this.resumeRes.downloadUrl);
    });
  }// It used to open the  dialog box based on the role id and also binding the data based on the status
  openDailogBox(getData) {
    this.submittedProfileForm.get('comments1').reset();
    this.gridRecord = getData;
    getData.status === 'Processing' ? (this.commentsHeader = 'Rejection') : (this.commentsHeader = 'Comments');
    if (getData.status === 'Processing') {
      this.statusDailog = false;
    } else {
      this.statusDailog = true;
      this.disableButton = false;
      this.submittedProfileForm.get('comments').markAsUntouched();
      this.submittedProfileForm.get('comments').patchValue(getData.customercomments);
    }
  }
  // It is used for the downloading the pdf file in submitted profile tab
  downloadPDFFile() {
  }
  // It is used for the downloading the XL file in submitted profile tab
  downloadXLSSheet() {
  }
  // It is used for navigate the consultant detail page and also passing the data with query params
  goTo(Data) {
    this.rowID = Data.consultant_id;
    this.submited = 'requirements-edit';
    this.router.navigate(['/consultantDetails_BackGroundCheck'], {
      queryParams: {
        consultant_id: Data.consultant_id,
        name: this.submited,
        reqId: Data.requirement_id,
        name2: this.name1,
      },
    });
  }
  // It is used for the save  the data on comments  status pop-up in submitted profiles tab grid, and also keep the validations
  save() {
    const data = {
      createdByOrgId: JSON.parse(sessionStorage.getItem('loginData')).orgId,
      consultantId: this.gridRecord.consultant_id,
      reqID: this.gridRecord.requirement_id,
      customerComment: this.submittedProfileForm.get('comments1').value,
    };
    if (this.submittedProfileForm.invalid) {
      if (this.gridRecord.status === 'Processing') {
        this.submittedProfileForm.get('comments1').markAsTouched();
      }
    } else {
    }
  }
  // It is used for navigate the rating and reviewa page and also passing the data through query params
  navigateToRatingsPage(data) {
    this.router.navigate(['/tech-reviews'], {
      queryParams: {
        vendorOrgId: data.ven_id,
        accountName: data.account_name,
        name: this.name1,
      },
    });
  }
  // tslint:disable-next-line: max-line-length
  // It is used for back routing .It will be route based on the quire parms data .If you get tech reviews it route to tech reviews page otherwise it will route to requirements page
  backNavigate() {
    this.router.navigate(['/rec-requirements']);
  }
  updatedownload(data) {
    this.filedata = data.resumeId;
    this.loaderForDownload = true;
    this.apisService.getApi(environment.downloadedattachment + '/' + this.filedata).subscribe((res) => {
      this.resumeRes = res;
      window.location.assign(this.resumeRes.downloadUrl);
      this.loaderForDownload = false;
    });
  }
  onTabChange(event) {
    this.indexval = event;
    this.routeParams = this.route.snapshot.queryParamMap;
    this.tabChangeValue = this.routeParams.params.navigate;
    // tslint:disable-next-line:max-line-length
    this.tabChangeValue === 'true' ? (this.indexval = 1) : this.tabChangeValue === 'consultant' || this.tabChangeValue === 'reviews' ? (this.indexval = 2) : (this.indexval = 0);
    if (this.indexval === 0) {
      this.tabchangeVar = true;
    } else if (this.indexval === 1) {
      this.tabchangeVar1 = true;
    } else {
      this.tabchangeVar2 = true;
    }
  }
  submitProfileGrid() {
    const row = this.rows;
    const page = this.page;
    this.loader = true;
    this.apisService.getApi(environment.vendorreceivedprofile + page + '/' + row + '/' + this.roleId + '?reqId=' + this.requId + '&consEmail=&fName=&lName=&exp=').subscribe((res: any) => {
      this.loader = false;
      this.submittedProfilesGridData = res.data.records;
      this.data = this.submittedProfilesGridData[0].profileState;
      if (this.data === 'S') {
        this.data = 'B';
      }
      this.subProRecords = res.data.count;
      if (this.subProRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }

  reqSkills1(data) {
    this.display = true;
    this.reqSkillsValue = data;
  }
  conStatusTable(data) {
    const conStatus = this.consStatusData.find((x) => x.code === data).label;
    return conStatus;
  }
  getSkillSetTable(data) {
    this.array = data;
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.specializationSkillsData.length; i++) {
      // tslint:disable-next-line: prefer-for-of
      // tslint:disable-next-line:prefer-for-of
      if (data === null || data === undefined || data === '' || data === isEmpty()) { } else {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.array.length; j++) {
          if (Number(this.specializationSkillsData[i].code) === this.array[j]) {
            selectedPrePrimarySkills3.push(
              this.specializationSkillsData[i].label
            );
          } else {
          }
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridTable();
  }
  add() {
    this.router.navigate(['/consultant&submittedprofiles']);
  }
  ratings() {
    this.filedata = this.rowData;
    this.profileID = this.filedata.profileId;
    this.router.navigate(['/tech-reviews'], {
      // tslint:disable-next-line: max-line-length
      queryParams: {
        proId: this.profileID,
        reqId: this.requiremntId,
        conId: this.filedata.conId,
        reqname: this.reqName,
        techflag: 'T',
        vendorID: this.vendorId
      },
    });
  }
  addReq() {
    this.filedata = this.rowData;
    this.profileID = this.filedata.profileId;
    const queryParams = {
      proId: this.profileID, reqId: this.requiremntId, jobTitle: this.reqName, conId: this.rowData.conId,
      consultantName: this.filedata.consultantName, from: 'reqEdit', createdbyaccID: this.rowData.createdByAccId,
    };
    this.router.navigate(['/schedule-interview'], {
      queryParams: {
        proId: this.profileID, reqId: this.requiremntId, parentreqId: this.parentReqId,
        conId: this.rowData.conId, jobTitle: this.reqName,
        consultantName: this.filedata.consultantName, createdbyaccID: this.rowData.createdByAccId,
        from: 'reqEdit'
      },
    });
    sessionStorage.setItem('reqEdit', JSON.stringify(queryParams));
  }
  editSearchconsultant() {
    this.userid = this.rowData.profileId;
    this.router.navigate(['/editcandidate', this.userid]);
  }
  updateAction() {
    // update profile action method radiobutton
    this.updateaction = true;
    this.updateactionBtn = false;
    this.filedata = this.rowData;
    this.userid = this.rowData.userId;
    this.resumID = this.rowData.resumeId;
    this.apisService.getApi(environment.requireupdateaction + `/${this.userid}`).subscribe((res: any) => {
      this.updateactionGrid = res.data;
    });
  }
  onSelect(event) {
    // Radio button select event
    this.updateactionBtn = true;
    this.filedata = this.rowData;
    this.profileID = this.filedata.profileId;
    this.rowdataid = event.data.id;
  }
  onUnselect(event) {
    // Radio button unselect event
    this.updateactionBtn = false;
    const val1 = event.data.id;
  }
  submitAction() {
    // Update button method in update profile
    const data = {
      id: this.profileID,
      resumeId: this.rowdataid,
    };
    this.apisService.putApi(environment.updateaction, data).subscribe((res: any) => {
      if (res.hasOwnProperty('success') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        this.submitProfileGrid();
        this.updateaction = false;
      } else if (res.hasOwnProperty('success') === false) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
      } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error, });
      }
    });
  }
  submitCust() {
    this.filedata = this.rowData;
    this.userId = this.filedata.userId;
    const subID = this.acctID + '/' + this.ownerId + '/' + this.requestData.reqId + '/' + this.userId + '/' + this.requestData.profileId;
    Swal.fire({
      text: 'Are you sure want to submit?', icon: 'warning', showCancelButton: true, confirmButtonColor: '#0D416B', cancelButtonColor: '#D0D0D0', confirmButtonText: 'Yes', cancelButtonText: 'No', reverseButtons: false,
    }).then((result) => {
      if (result.value) {
        this.apisService.getApi(environment.requirementSubmit + subID).subscribe((res: any) => {
          if (res.hasOwnProperty('success') === true) {
            this.submitProfileGrid();
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
          } else if (res.hasOwnProperty('success') === false) {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
          } else {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error, });
          }
        });
      }
    });
  }
  submittoCustomer() {
    this.updateBilForm.reset();
    this.consultantRequirementDisplay = true;
  }
  associatereq() {
    this.filedata = this.rowData;
    this.userId = this.filedata.userId;
    // tslint:disable-next-line:max-line-length
    const billrateval = this.updateBilForm.controls.billRateControl.value === null || this.updateBilForm.controls.billRateControl.value === undefined || this.updateBilForm.controls.billRateControl.value === '' ? '' : this.updateBilForm.controls.billRateControl.value;
    if (this.updateBilForm.invalid) {
      const controls = this.updateBilForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      if (this.updateBilForm.controls.billRateControl.value > this.maxrate) {
        this.updateBilForm.controls.billRateControl.reset();
        this.errorMsg = true;
        setTimeout(() => {
          this.errorMsg = false;
        }, 3000);
      }
      else {
        this.btndisable = true;
        this.apisService
          .getApi(environment.associatedRequirement + this.filedata.profileId + '/' + billrateval)
          .subscribe((res: any) => {
            this.consultantRequirementDisplay = false;
            if (res.hasOwnProperty('success') === true) {
              this.submitProfileGrid();
              this.btndisable = false;
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
            } else if (res.hasOwnProperty('success') === false) {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
            } else {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error, });
            }
          });
      }
    }
  }
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
  exportPdf() {
    this.submittedProfilesGridData.forEach((element) => {
      element.status = this.statusList[element.profileState];
      element.skills = this.getSkillSetTable(element.skills);
    });
    const doc = new jsPDF('p', 'pt');
    autoTable(doc, {
      headStyles: { fillColor: '#2bb9d5', cellPadding: 5   },
      margin: { left: 17},
      columns: this.cols3,
      body: this.submittedProfilesGridData,
      didDrawPage: (dataArg) => {
        doc.text('Received Candidates', dataArg.settings.margin.left, 30);
      }
    });
    doc.save('Received-Candidates.pdf');
  }
  payrate(data) {
    this.payrates = data;
    if (this.payrates <= this.maxrate) {
      this.updateBilForm.controls.billRateControl.patchValue(this.payrates);
      this.alertError = false;
    } else if (this.payrates >= this.maxrate) {
      this.updateBilForm.controls.billRateControl.patchValue(this.payrates);
      this.alertError = true;
      setTimeout(() => {
        this.alertError = false;
        this.updateBilForm.controls.billRateControl.reset();
      }, 3000);
    }
  }
  billRate() {
    this.alertError = false;
    if (this.updateBilForm.controls.billRateControl.value > this.maxrate) {
      this.updateBilForm.controls.billRateControl.reset();
      this.errorMsg = true;
      setTimeout(() => {
        this.errorMsg = false;
      }, 3000);
    } else if (
      this.updateBilForm.controls.billRateControl.value === '' ||
      this.updateBilForm.controls.billRateControl.value === null ||
      this.updateBilForm.controls.billRateControl.value === undefined
    ) {
      this.alertError = true;
      setTimeout(() => {
        this.alertError = false;
      }, 3000);
    } else {
      this.errorMsg = false;
    }
  }
  // skillsetOthers(data, event) {
  //   // console.log("datadatadata",data);

  //   // console.log("skillsetOthersevents", event);
  //   // if (event.length === 0) {
  //   //   this.primarySkills = [];
  //   // }
  //   // this.editRequirementsForm.controls.otherskills.reset();
  //   // this.editRequirementsForm.controls.otherskills.clearValidators();
  //   // tslint:disable-next-line:prefer-for-of
  //   // this.otherskilldata = false;
  //   if (data) {
  //     this.otherskilldata = false;
  //   }
  //   // tslint:disable-next-line:prefer-for-of
  //   for (let i = 0; i < data.length; i++) {
  //     if (data[i].code === '25') {
  //       console.log("ifffff");

  //       this.otherskilldata = true;
  //     }
  //   }

  //   if (this.otherskilldata === true) {
  //     this.editRequirementsForm.controls.otherskills.setValidators([Validators.required, Validators.maxLength(300)]);
  //   }

  //   if (this.otherskilldata === false) {
  //     this.editRequirementsForm.controls.otherskills.reset();
  //     this.editRequirementsForm.controls.otherskills.clearValidators();
  //   }

  // }
  skillsetOthers(data) {
    this.editRequirementsForm.controls.otherskills.reset();
    this.editRequirementsForm.controls.otherskills.clearValidators();
    // tslint:disable-next-line:prefer-for-of
    this.otherskilldata = false;
    if (data) {
      this.otherskilldata = false;
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < data.length; i++) {
      if (data[i].code === '25') {
        this.otherskilldata = true;
      }
    }

    if (this.otherskilldata === true) {
      this.editRequirementsForm.controls.otherskills.setValidators([Validators.required, Validators.maxLength(300)]);
    }

    if (this.otherskilldata === false) {
      this.editRequirementsForm.controls.otherskills.reset();
      this.editRequirementsForm.controls.otherskills.clearValidators();
    }
  }
  saveLocation(ev) {
    const data = ev.value;
    this.patchlocationString = data.name + '\n' + data.address1 + ',' + data.city + ',' + data.state + ',' + data.country;
  }
  setRequired() {
    return [Validators.required, Validators.maxLength(300)];
  }
  onBlur(data): void {
    if (data === '') {
      this.editRequirementsForm.controls.otherskills.setValidators(this.setRequired());
    }
  }
  getduration() {
    if (this.editRequirementsForm.controls.duration.value > 24) {
      this.shwmsg = true;
      this.editRequirementsForm.controls.duration.reset();
      this.showmsg = 'Duration should be less than 24 months';
      setTimeout(() => {
        this.showmsg = '';
        this.shwmsg = false;
        this.editRequirementsForm.controls.duration.patchValue(24);
      }, 3000);
    } else {
    }
  }
  resetskills(data, event) {
    if (event.length === 0) {
      this.skillsList12 = [];
    }

  }
  getOtherDetails() {
    this.displayOtherDetails = true;
    this.apisService.getApi(environment.getOtherDetails + this.rowData.conId).subscribe((res: any) => {
      this.getotherdetailsdata = res.data[0];
      this.getotherDetails.patchValue({
        addressControl: this.getotherdetailsdata.address1,
        addressFormControl: this.getotherdetailsdata.address2,
        mailbox1: this.getotherdetailsdata.mailbox,
        Country: this.getotherdetailsdata.country,
        State: this.getotherdetailsdata.state,
        cityForm: this.getotherdetailsdata.city,
        zipForm: this.getotherdetailsdata.zip,
        workSatus: this.getotherdetailsdata.visaStatus,
      });
    });
  }
}
