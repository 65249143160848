import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-userpreference',
  templateUrl: './userpreference.component.html',
  styleUrls: ['./userpreference.component.scss'],
  providers: [DatePipe, MessageService],
})
export class UserpreferenceComponent implements OnInit {
  userPrefernces: FormGroup;
  data: any;
  eventvalue: string;
  statelist: any;
  loader: boolean;
  preCountry: any;
  prestate: any;
  preNotifications: any;
  item: any;
  items: any;
  preCurrency: any;
  currency: boolean;
  notification: boolean;
  button: boolean;
  locations: boolean;
  id: any;
  preferenceValues = [
    { name: 'Currency', code: 'currency' },
    { name: 'Locations', code: 'location' },
    { name: 'Notifications', code: 'notification' },
  ];
  disable: boolean;
  // tslint:disable-next-line: max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private breadcrumbService: BreadcrumbService, private router: Router) {
    this.breadcrumbService.setItems([
      { label: 'Preference', routerLink: '/userpreference' },
    ]);
  }
  ngOnInit(): void {
    this.userPrefernces = this.fb.group({
      preference: [''],
      precurrency: [''],
      precountry: [''],
      prestate: [''],
      prenotifications: [''],
      preCity: ['']
    });
    this.id = JSON.parse(sessionStorage.getItem('loginData')).userId;
    this.preCurrencyDD();
    this.preNotificationsDD();
    this.preCountryDD();
  }
  getpreference(event) {
    this.eventvalue = event.value.code;
    this.userPrefernces.reset();
    this.userPrefernces.patchValue({
      preference : event.value
    }); 
    if (this.eventvalue === 'notification') {
      this.notification = true;
      this.currency = false;
      this.locations = false;
      this.button = true;
    }
    else if (this.eventvalue === 'currency') {
      this.currency = true;
      this.notification = false;
      this.locations = false;
      this.button = true;
    }
    else if (this.eventvalue === 'location') {
      this.locations = true;
      this.notification = false;
      this.currency = false;
      this.button = true;
    }
  }
  preCurrencyDD() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.preCurrencyDropDown).subscribe((res: any) => {
      this.preCurrency = res.data;
      this.item = [];
      this.preCurrency.forEach(element => {
        this.item[element.label] = [element.code];
      });
      this.items = [];
      this.items.push({
        code: 'USD', id: 'UPDC', label: 'United States Dollar' + '/' + this.item
      });
    });
  }
  preNotificationsDD() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.preNotfDropDown).subscribe((res: any) => {
      this.preNotifications = res.data;
    });
  }
  preCountryDD() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.preCountryDropdown).subscribe((res: any) => {
      this.preCountry = res.data;
    });
  }
  getStateByUrl(country) {
    if (country === null || '' || undefined){}
    else{ // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.states + country).subscribe((res) => {
        this.prestate = res.data;
      }); }

  }
  preferenceSave() {
    this.disable = true;
    const acctdetails = JSON.parse(this.apisService.decodeData());
    if (this.eventvalue === 'location') {
      this.statelist = [];
      this.userPrefernces.controls.prestate.value.forEach((element) => {
        this.statelist.push(element.code);
      });
      this.data = {
        usrId: acctdetails.userId,
        key: 'locations',
        value: '',
        // tslint:disable-next-line:max-line-length
        country: this.userPrefernces.controls.precountry.value === null || this.userPrefernces.controls.precountry.value === '' || this.userPrefernces.controls.precountry.value === undefined ? '' : this.userPrefernces.controls.precountry.value.code,
        state: this.statelist.toString(),
        city: '',
      };
    }
    else if (this.eventvalue === 'notification') {
      this.data = {
        usrId: acctdetails.userId,
        key: 'notification',
        // tslint:disable-next-line:max-line-length
        value: this.userPrefernces.controls.prenotifications.value === null || this.userPrefernces.controls.prenotifications.value === '' || this.userPrefernces.controls.prenotifications.value === undefined ? '' : this.userPrefernces.controls.prenotifications.value.code,
        country: '',
        state: '',
        city: '',
      };
    }
    else if (this.eventvalue === 'currency') {
      this.data = {
        usrId: acctdetails.userId,
        key: 'currency',
        // tslint:disable-next-line:max-line-length
        value: this.userPrefernces.controls.precurrency.value === null || this.userPrefernces.controls.precurrency.value === '' || this.userPrefernces.controls.precurrency.value === undefined ? '' : this.userPrefernces.controls.precurrency.value.code,
        country: '',
        state: '',
        city: '',
      };
    }
    // tslint:disable-next-line: deprecation
    this.apisService.postApi(environment.preferenceSave, this.data).subscribe((res: any) => {
      this.disable = false;
      this.logout();
      this.userPrefernces.reset();
    });
  }
  logout() {
    sessionStorage.clear();
    this.router.navigateByUrl('login');
  }

}
