<div *ngIf="selected">
<form [formGroup]="submittedProfileForm">

	<p-card>
		<div class="p-grid p-fluid">
			<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
				<label class="inputfields">Job Id</label>
				<input id="input" type="text" autocomplete="off" pInputText placeholder="Job Id" maxlength="30"
					formControlName="jobId">
			</div>
			<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
				<label class="inputfields"> First Name </label>
				<input id="input" type="text" autocomplete="off" pInputText placeholder="First Name" maxlength="30"
					formControlName="firstName">
			</div>

			<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
				<label class="inputfields"> Last Name </label>
				<input id="input" type="text" autocomplete="off" pInputText placeholder="Last Name" maxlength="30"
					formControlName="lastName">
			</div>
			<div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
				<label class="inputfields"> Email</label>
				<input id="input" type="text" autocomplete="off" pInputText placeholder="Email" maxlength="60"
					formControlName="emailId">


			</div>
			<div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
				<label class="inputfields"> Experience</label>
				<p-dropdown [options]="exprienceList" optionLabel="label" placeholder="Select Experience" [showClear]="true" formControlName="experience">
				</p-dropdown>
			</div>

		</div>

		<div class="btnsCSS1 btnalignment">
			<button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" class="addhBtn"></button>
			<button pButton type="button" icon='pi pi-search' label="Search" (click)="tableGet()"
				class="addhBtn"></button>
		</div>
	</p-card>
	<br>
	<p-card class="pgpos">
		<div *ngIf="table">
			<p-table [columns]="cols11" dataKey="consultantName" [value]="gridData" selectionMode="single" [rows]="7"
				[responsive]="true" [rowsPerPageOptions]="[10,20,30]" sortMode="multiple" id="excel-table"
				[autoLayout]="true">
				<ng-template pTemplate="header" let-columns>
					<tr>
						<th id="gridHeader" *ngFor="let col of columns let i=index" [ngStyle]="{'width': col.width }"
							[pSortableColumn]="col.field">
							{{col.header}}

						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-Data let-expanded="expanded">
					<tr>
						<td>
							{{Data.reqId}}</td>
						<td [pRowToggler]="Data" class="textCenter pointer">
							<a>
								{{Data.consultantName}}
							</a>
						</td>
						<td class="textCenter">{{Data.submittedDate}}</td>

						<td class="textCenter pointer">
							<a (click)="op.toggle($event)" (click)="reqSkills(getSkillSetTable(Data.requiredSkills))"
								*ngFor="let data1 of getSkillSetTable(Data.requiredSkills); index as i;">
								<p-badge class="badgespace newsty" *ngIf='i<1' [value]="data1"></p-badge>
								<p-badge *ngIf='i===1' [value]="'+'+(Data.requiredSkills.length-1)+' more'"></p-badge>
							</a>
						</td>
						<td class="textCenter">{{this.expLabel[Data.experience]}}
						</td>
						<td>{{Data.payRate | currency: currencyValue : true}}</td>
						<td>

							{{Data.billRate | currency: currencyValue : true}}
						</td>
						<td class="textCenter pointer"><a>
								<em (click)="actionpopup.toggle($event); toggle(items, $event, Data)"
									class="pi pi-ellipsis-v"></em>
							</a></td>
					</tr>

				</ng-template>
				<ng-template pTemplate="rowexpansion" let-product>
					<tr>
						<td colspan="7">
							<div class="p-p-3">
								<p-card>
									<div class="p-grid p-fluid">
										<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
											<label class="inputfields">Email :</label>
											{{product.email}}
										</div>
										<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
											<label class="inputfields">Phone Number :</label>
											{{product.mobilPhone}}
										</div>
										<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
											<label class="inputfields">Experience :</label>
											{{this.expLabel[product.experience]}}
										</div>
									</div>
									<div class="p-grid p-fluid">
										<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
											<label class="inputfields">Office Number :</label>
											{{product.office_phone }}
										</div>
										<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
											<label class="inputfields">Pay Rate :</label>
											{{product.payRate | currency: currencyValue : true}}

										</div>
										<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">

											<label class="inputfields">Bill Rate :</label>
											{{product.billRate | currency: currencyValue : true}}

										</div>
									</div>
									<div class="p-grid p-fluid">
										<div class="p-col-12 p-md-12 p-sm-12 p-lg-12 ">
											<label class="inputfields">Skills : </label>
											{{getSkillSetTable(product.requiredSkills)}}
										</div>
									</div>
								</p-card>
							</div>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr *ngIf="gridData?.length==0">
						<td colspan="9" class="mandatoryColor aligncenter">
							No records
						</td>
					</tr>
				</ng-template>
			</p-table>
			<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>

			<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '25%' }">
				<label class="inputfields">Skill Details</label>
				<ng-template pTemplate>

					<div class="p-fluid p-formgrid p-grid">
						<div class="p-col-12 bordersty">

							<p class="skpos">

								<p-badge class="badgespace badgesp" *ngFor="let data2 of reqSkillsValue; index as i"
									[value]="data2" [ngClass]="i%2===0? 'newsty' : ''">
								</p-badge>


							</p>

						</div>
					</div>


				</ng-template>
			</p-overlayPanel>
			<div class="p-grid p-fluid" *ngIf="subProRecords>0">
				<div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
					<h3 class="norecorddata">Total records : {{this.subProRecords}}</h3>
				</div>
				<div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
					<p-paginator [rows]="10" [totalRecords]="subProRecords" [rowsPerPageOptions]="[10,20,30]"
						(onPageChange)="paginate($event)" #p></p-paginator>
				</div>
			</div>
		</div>
		<p-card *ngIf="records">
			<div class="norecordImgsize">
				<div *ngIf="records" class="aligncenter">
					<img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
					<br><em>Sorry, we are not able to find any selected</em><br>
				</div>
			</div>
		</p-card>
	</p-card>
</form>
</div>
<div *ngIf="onprojects">
	<form [formGroup]="submittedProfileForm">

		<p-card>
			<div class="p-grid p-fluid">
				<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
					<label class="inputfields">Job Id</label>
					<input id="input" type="text" autocomplete="off" pInputText placeholder="Job Id" maxlength="30"
						formControlName="jobId">
				</div>
				<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
					<label class="inputfields"> First Name </label>
					<input id="input" type="text" autocomplete="off" pInputText placeholder="First Name" maxlength="30"
						formControlName="firstName">
				</div>
	
				<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
					<label class="inputfields"> Last Name </label>
					<input id="input" type="text" autocomplete="off" pInputText placeholder="Last Name" maxlength="30"
						formControlName="lastName">
				</div>
				<div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
					<label class="inputfields"> Email</label>
					<input id="input" type="text" autocomplete="off" pInputText placeholder="Email" maxlength="60"
						formControlName="emailId">
	
	
				</div>
				<div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
					<label class="inputfields"> Experience</label>
					<p-dropdown [options]="exprienceList" optionLabel="label" placeholder="Select Experience" [showClear]="true" formControlName="experience">
					</p-dropdown>
				</div>
	
			</div>
	
			<div class="btnsCSS1 btnalignment">
				<button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" class="addhBtn"></button>
				<button pButton type="button" icon='pi pi-search' label="Search" (click)="tableGet()"
					class="addhBtn"></button>
			</div>
		</p-card>
		<br>
		<p-card class="pgpos">
			<div *ngIf="table">
				<p-table [columns]="cols11" dataKey="consultantName" [value]="gridData" selectionMode="single" [rows]="7"
					[responsive]="true" [rowsPerPageOptions]="[10,20,30]" sortMode="multiple" id="excel-table"
					[autoLayout]="true">
					<ng-template pTemplate="header" let-columns>
						<tr>
							<th id="gridHeader" *ngFor="let col of columns let i=index" [ngStyle]="{'width': col.width }"
								[pSortableColumn]="col.field">
								{{col.header}}
	
							</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-Data let-expanded="expanded">
						<tr>
							<td>
								{{Data.reqId}}</td>
							<td [pRowToggler]="Data" class="textCenter pointer">
								<a>
									{{Data.consultantName}}
								</a>
							</td>
							<td class="textCenter">{{Data.submittedDate}}</td>
	
							<td class="textCenter pointer">
								<a (click)="op.toggle($event)" (click)="reqSkills(getSkillSetTable(Data.requiredSkills))"
									*ngFor="let data1 of getSkillSetTable(Data.requiredSkills); index as i;">
									<p-badge class="badgespace newsty" *ngIf='i<1' [value]="data1"></p-badge>
									<p-badge *ngIf='i===1' [value]="'+'+(Data.requiredSkills.length-1)+' more'"></p-badge>
								</a>
							</td>
							<td class="textCenter">{{this.expLabel[Data.experience]}}
							</td>
							<td>{{Data.payRate | currency: currencyValue : true}}</td>
							<td>
	
								{{Data.billRate | currency: currencyValue : true}}
							</td>
							<td class="textCenter pointer"><a>
									<em (click)="actionpopup.toggle($event); toggle(items, $event, Data)"
										class="pi pi-ellipsis-v"></em>
								</a></td>
						</tr>
	
					</ng-template>
					<ng-template pTemplate="rowexpansion" let-product>
						<tr>
							<td colspan="7">
								<div class="p-p-3">
									<p-card>
										<div class="p-grid p-fluid">
											<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
												<label class="inputfields">Email :</label>
												{{product.email}}
											</div>
											<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
												<label class="inputfields">Phone Number :</label>
												{{product.mobilPhone}}
											</div>
											<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
												<label class="inputfields">Experience :</label>
												{{this.expLabel[product.experience]}}
											</div>
										</div>
										<div class="p-grid p-fluid">
											<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
												<label class="inputfields">Office Number :</label>
												{{product.office_phone }}
											</div>
											<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
												<label class="inputfields">Pay Rate :</label>
												{{product.payRate | currency: currencyValue : true}}
	
											</div>
											<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
	
												<label class="inputfields">Bill Rate :</label>
												{{product.billRate | currency: currencyValue : true}}
	
											</div>
										</div>
										<div class="p-grid p-fluid">
											<div class="p-col-12 p-md-12 p-sm-12 p-lg-12 ">
												<label class="inputfields">Skills : </label>
												{{getSkillSetTable(product.requiredSkills)}}
											</div>
										</div>
									</p-card>
								</div>
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage">
						<tr *ngIf="gridData?.length==0">
							<td colspan="9" class="mandatoryColor aligncenter">
								No records
							</td>
						</tr>
					</ng-template>
				</p-table>
				<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
	
				<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '25%' }">
					<label class="inputfields">Skill Details</label>
					<ng-template pTemplate>
	
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-col-12 bordersty">
	
								<p class="skpos">
	
									<p-badge class="badgespace badgesp" *ngFor="let data2 of reqSkillsValue; index as i"
										[value]="data2" [ngClass]="i%2===0? 'newsty' : ''">
									</p-badge>
	
	
								</p>
	
							</div>
						</div>
	
	
					</ng-template>
				</p-overlayPanel>
				<div class="p-grid p-fluid" *ngIf="subProRecords>0">
					<div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
						<h3 class="norecorddata">Total records : {{this.subProRecords}}</h3>
					</div>
					<div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
						<p-paginator [rows]="10" [totalRecords]="subProRecords" [rowsPerPageOptions]="[10,20,30]"
							(onPageChange)="paginate($event)" #p></p-paginator>
					</div>
				</div>
			</div>
			<p-card *ngIf="records">
				<div class="norecordImgsize">
					<div *ngIf="records" class="aligncenter">
						<img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
						<br><em>Sorry, we are not able to find any selected</em><br>
					</div>
				</div>
			</p-card>
		</p-card>
	</form>
</div>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
	<div class="loading-bar">
		<div>
			<div class="image-holder" class="col-sm-12 text-center laoderheight ">
				<div class="laodersize">
					<img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
						class="loaderimg-height">
				</div>
			</div>
		</div>
	</div>
</ng-template>