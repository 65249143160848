<p-toast position="bottom-right" key="br"></p-toast>
<div>
    <div class="account"><strong>Project Name : <span class="accname">{{queryprojectName}}</span></strong></div><br>
</div>
<p-card>
    <form [formGroup]="resourcesForm">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Start Date</label>
                <p-calendar (keyup.enter)="gridTable()" id="popup" dateFormat="mm-dd-yy" formControlName="startDate"
                    placeholder="MM-DD-YYYY" [showIcon]="true" [showButtonBar]="true">
                </p-calendar>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">End Date</label>
                <p-calendar (keyup.enter)="gridTable()" id="popup" dateFormat="mm-dd-yy" formControlName="endDate"
                    placeholder="MM-DD-YYYY" [showIcon]="true" [showButtonBar]="true">
                </p-calendar>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Status</label>
                <p-dropdown (keyup.enter)="gridTable()" [options]="status" formControlName="status"
                    placeholder="Select Status" optionLabel="label" [showClear]="true"></p-dropdown>
            </div>
        </div><br>
        <div class="btnsCSS1 buttonalignement">
            <span pTooltip="You can add resource if project is active." tooltipPosition="top"
                [tooltipDisabled]="!buttondisable">
                <button pButton type="button" icon='pi pi-plus' label="Add" (click)="addResource()" class="addhBtn"
                    [disabled]='buttondisable'></button>
            </span>
            <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="resetResources()"
                class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-search' label="Search" (click)="gridTable()"
                class="addhBtn"></button>
        </div><br>
    </form>
</p-card>
<br>
<p-card class="pgpos">
    <div *ngIf="table">
        <p-table [value]="gridData" [columns]="cols" selectionMode="single" dataKey="edit" [responsive]="true"
            sortMode="multiple" [rows]="10">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                        <span>
                            {{col.header}}

                        </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
                <tr>
                    <td class="skill-desc" pTooltip="{{data.name}}" tooltipPosition="top"><span class="p-column-title">
                        </span>{{data.name}}</td>
                    <td class="textCenter"><span class="p-column-title">
                        </span>{{ data.startDate === null || data.startDate === '' ? '--' : data.startDate |
                        date:'MM-dd-yyyy' }}</td>
                    <td class="textCenter"><span class="p-column-title">
                        </span>{{ data.endDate === null || data.endDate === '' ? '--' : data.endDate |
                        date:'MM-dd-yyyy'}}</td>
                    <td><span class="p-column-title">
                        </span>{{data.lkUtilization}}<em class="pi pi-percentage" style="position: relative;
                        left: 8px;"></em></td>
                    <td><span class="ui-column-title"></span>
                        <p-badge [value]="this.statuslist[data.lkStatus]"
                            [ngClass]="data.lkStatus==='A'?'green': 'red'">
                        </p-badge>
                    </td>
                    <td class="textCenter"><span class="p-column-title">
                        </span><a [ngClass]="buttondisable ? 'grey' : 'blue'"
                            (click)="buttondisable ? '':actionpopup.toggle($event); toggle(items, $event, data);"
                            class="pi pi-ellipsis-v pointer"></a>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10 ">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any resources</em><br>
            </div>
        </div>
    </p-card>
</p-card>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-dialog class="dialogBox" header="Add Resource" [(visible)]="displayAddResource" [modal]="true"
    [style]="{width: '30%'}" [contentStyle]="{'overflow': 'visible'}" [baseZIndex]="1" [draggable]="false"
    [resizable]="false">
    <form [formGroup]="addResourceForm">
        <strong class="prjname">Project Name : <span><a>{{queryprojectName}}</a></span></strong><br>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Resource Name<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="selectedbycustomer" [filter]="true" filterBy="name"
                    formControlName="addResourceName" placeholder="Select Resource Name" optionLabel="name"
                    [showClear]="true"></p-dropdown>
                <span class="validationtest"
                    *ngIf="this.addResourceForm.controls.addResourceName.touched && this.addResourceForm.controls.addResourceName.invalid && this.addResourceForm.controls.addResourceName.errors.required">
                    Please select resource name
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Status<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="status" formControlName="addResourceStatus" placeholder="Select Status"
                    optionLabel="label" [disabled]="true" [showClear]="true"></p-dropdown>
                <span class="validationtest"
                    *ngIf="this.addResourceForm.controls.addResourceStatus.touched && this.addResourceForm.controls.addResourceStatus.invalid && this.addResourceForm.controls.addResourceStatus.errors.required">
                    Please select status
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Utilization<span class="mandatoryColor">*</span></label>
                <div class="p-inputgroup">
                    <input pInputText placeholder="Utilization" autocomplete="off"
                        formControlName="addResourceUtilization" maxlength="4" (input)="getUtilization()">
                    <span class="p-inputgroup-addon"><em class="pi pi-percentage"></em></span>
                </div>
                <span class="validationtest"
                    *ngIf="this.addResourceForm.controls.addResourceUtilization.touched && this.addResourceForm.controls.addResourceUtilization.invalid && this.addResourceForm.controls.addResourceUtilization.errors.required">
                    Please enter utilization
                </span>
                <span class="validationtest"
                    *ngIf="this.addResourceForm.get('addResourceUtilization').hasError('maxlength')">
                    Please enter 3 numbers only
                </span>
                <span class="validationtest"
                    *ngIf="this.addResourceForm.get('addResourceUtilization').invalid && this.addResourceForm.get('addResourceUtilization').errors.pattern">
                    Please enter numbers only
                </span>
                <p class="validationtest">{{utilozationmsg}}</p>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Start Date<span class="mandatoryColor">*</span></label>
                <p-calendar id="popup" placeholder="MM-DD-YYYY" dateFormat="mm-dd-yy" [showIcon]="true"
                    [showButtonBar]="true" [minDate]='startCurDate' (onSelect)='endDateValidation()'
                    formControlName="addResourceStartDate">
                </p-calendar>
                <span class="validationtest"
                    *ngIf="this.addResourceForm.controls.addResourceStartDate.touched && this.addResourceForm.controls.addResourceStartDate.invalid && this.addResourceForm.controls.addResourceStartDate.errors.required">
                    Please select start date
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">End Date</label>
                <p-calendar id="popup" placeholder="MM-DD-YYYY" dateFormat="mm-dd-yy" [showIcon]="true"
                    [showButtonBar]="true" [disabled]="true" [minDate]='startCurDate'
                    formControlName="addResourceEndDate">
                </p-calendar>
            </div>
        </div><br>
        <div class="btnsCSS1">
            <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="resetaddResources()"
                class="addhBtn"></button>
            <button [disabled]="btnhide" pButton type="button" icon='pi pi-save' label="Save" (click)="saveRsource()"
                class="addhBtn"></button>
        </div><br>
    </form>
</p-dialog>
<p-dialog class="dialogBox" header="Edit Resource" [(visible)]="displayEditResource" [modal]="true"
    [style]="{width: '30%'}" [baseZIndex]="1" [contentStyle]="{'overflow': 'visible'}" [draggable]="false"
    [resizable]="false">
    <form [formGroup]="editResourceForm">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Resource Name</label>
                <input id="input" disabled="true" pInputText placeholder="Select Resource Name" autocomplete="off"
                    formControlName="editResourceName" maxlength="30">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Status<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="status" formControlName="editResourceStatus" optionValue="code"
                    (onChange)="statusData()" placeholder="Select Status" optionLabel="label" [showClear]="true">
                </p-dropdown>
                <span class="validationtest"
                    *ngIf="this.editResourceForm.controls.editResourceStatus.touched && this.editResourceForm.controls.editResourceStatus.invalid && this.editResourceForm.controls.editResourceStatus.errors.required">
                    Please select status
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Utilization<span class="mandatoryColor">*</span></label>
                <div class="p-inputgroup">
                    <input id="input" pInputText placeholder="Utilization" autocomplete="off"
                        formControlName="editResourceUtilization" maxlength="4" (input)="getUtilization()">
                    <span class="p-inputgroup-addon"> <em class="pi pi-percentage"></em>
                    </span>
                </div>
                <span class="validationtest"
                    *ngIf="this.editResourceForm.controls.editResourceUtilization.touched && this.editResourceForm.controls.editResourceUtilization.invalid && this.editResourceForm.controls.editResourceUtilization.errors.required">
                    Please enter utilization
                </span>
                <span class="validationtest"
                    *ngIf="this.editResourceForm.get('editResourceUtilization').hasError('maxlength')">
                    Please enter 3 numbers only
                </span>
                <span class="validationtest"
                    *ngIf="this.editResourceForm.get('editResourceUtilization').invalid && this.editResourceForm.get('editResourceUtilization').errors.pattern">
                    Please enter numbers only
                </span>
                <p class="validationtest">{{utilozationmsg}}</p>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Start Date<span class="mandatoryColor">*</span></label>
                <p-calendar id="popup" dateFormat="mm-dd-yy" disabled="true" formControlName="editResourceStartDate"
                    placeholder="MM-DD-YYYY" [showIcon]="true" [minDate]='startCurDate'
                    (onSelect)='editendDateValidation()' [showButtonBar]="true">
                </p-calendar>
                <span class="validationtest"
                    *ngIf="this.editResourceForm.controls.editResourceStartDate.touched && this.editResourceForm.controls.editResourceStartDate.invalid && this.editResourceForm.controls.editResourceStartDate.errors.required">
                    Please select start date
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">End Date<span *ngIf="endStaus" class="mandatoryColor">*</span></label>
                <p-calendar id="popup" dateFormat="mm-dd-yy" (click)="projectEndDateValidation()"
                    formControlName="editResourceEndDate" placeholder="MM-DD-YYYY" [minDate]='startDate'
                    [showIcon]="true" [showButtonBar]="true">
                </p-calendar>
                <span class="validationtest" *ngIf="endStauserr">
                    Please select end date
                </span>
                <span class="validationtest">
                    {{ this.msg}} </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Skills Set</label><br>
                <textarea type="text" pInputText placeholder="Skills Set" formControlName="skills"
                    autoResize="autoResize" rows="5" maxlength="26"></textarea>
                <span class="validationtest" *ngIf="this.editResourceForm.get('skills').hasError('maxlength')">
                    Please enter 25 alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Comments</label><br>
                <textarea type="text" pInputText placeholder="Comments" formControlName="comments"
                    autoResize="autoResize" rows="5" maxlength="256"></textarea>
                <span class="validationtest" *ngIf="this.editResourceForm.get('comments').hasError('maxlength')">
                    Please enter 255 alphabets only
                </span>
            </div>
        </div><br>
        <div class="btnsCSS1">
            <button pButton type="button" icon='pi pi-times' label="Cancel" (click)="closeEdit()"
                class="addhBtn"></button>
            <button pButton [disabled]="btnhide" type="button" icon='pi pi-save' label="Update"
                (click)="updateResource()" class="addhBtn"></button>
        </div><br>
    </form>
</p-dialog>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>