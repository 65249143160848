import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-submissionrequest',
  templateUrl: './submissionrequest.component.html',
  styleUrls: ['./submissionrequest.component.scss'],
  providers: [DatePipe, MessageService],
})
export class SubmissionrequestComponent implements OnInit {
  submissionRequestForm: FormGroup;
  // It is used for displaying table header data
  cols = [
    { field: 'reqId', header: 'Job Id' },
    { field: 'jobTitle', header: 'Job Title' },
    { field: 'requiredSkills', header: 'Skills' },
    { field: 'vendorName', header: 'Vendor Name' },
    { field: 'accountName', header: 'Customer Name' },
    { field: 'activityStatus', header: 'Status' },
    { field: '', header: 'Action' }
  ];
  first: any;
  rows: any = 10;
  page: any = 1;
  pageCount: any;
  records: boolean;
  userId: any;
  loader: boolean;
  gridRes: any;
  totalRecords: any;
  approveOrRejectForm: FormGroup;
  dialogForm: FormGroup;
  status: any = [];
  statuTable = {};
  specializationSkillsData: any = [];
  array: any;
  display: boolean;
  reqSkillsValue: any;
  rowData: any;
  items: any = [];
  filedata: any;
  resumeRes: any;
  rejectForComments: boolean;
  approveOrReject: boolean;
  approveOrRejectResponse: any;
  isUploading1: boolean;
  table: boolean;
  accId: string;
  pageNo: any;
  displayModal: boolean;
  date: Date;
  histroy: any;
  disable: boolean;
  jobID: any;
  reqId: any;
  empId: any;
  dattaa: any;
  // tslint:disable-next-line:max-line-length
  constructor(private breadcrumbService: BreadcrumbService, private messageService: MessageService, private apisService: ApisService, private fb: FormBuilder) {
    this.breadcrumbService.setItems([
      { label: 'Submission Requests', routerLink: '/submissionrequest' },
    ]);
  }
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.search();
    }
  }
  ngOnInit(): void {
    this.submissionRequestForm = this.fb.group({
      jobId: [''],
      jobRole: [''],
      statusDropdown: [''],
    });
    // tslint:disable-next-line:align
    this.approveOrRejectForm = this.fb.group({
      approveOrReject: [''],
      comments: ['', [Validators.required, Validators.maxLength(200)]],
    });
    this.gridData();
    this.statusDD();
    this.reqSkillSet();
  }
  // It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.search();
  }
  // It is used to get status dropdown values
  statusDD() {
    this.apisService.getApi(environment.submissionStatusDD).subscribe((res: any) => {
      this.status = res.data;
      this.status.forEach((element) => {
        this.statuTable[element.code] = element.label;
      });
    });
  }
  // It is used to get grid data
  gridData() {
    this.pageNo = this.page;
    this.pageCount = this.rows;
    const acctdetails = JSON.parse(this.apisService.decodeData());
    this.userId = acctdetails.userId;
    this.loader = true;
    // tslint:disable-next-line:max-line-length
    this.apisService.getApi(environment.reqProfileId + '/' + this.pageNo + '/' + this.pageCount + '/' + this.userId).subscribe((res: any) => {
      this.gridRes = res.data.records;
      this.gridRes.forEach(element => {
        this.jobID = element.reqId;
      });
      this.totalRecords = res.data.count;
      this.loader = false;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  // It is used for the reset the form values
  reset() {
    this.submissionRequestForm.reset();
    this.records = false;
    this.gridData();
  }
  // It is used for the displaying the skills Dropdown Values
  reqSkillSet() {
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.specializationSkillsData = res.data;
    });
  }
  getSkillSetTable(data) {
    this.array = data;
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.specializationSkillsData.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < this.array.length; j++) {
        if (Number(this.specializationSkillsData[i].code) === this.array[j]) {
          selectedPrePrimarySkills3.push(
            this.specializationSkillsData[i].label
          );
        } else {
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  reqSkills1(data) {
    this.display = true;
    this.reqSkillsValue = data;
  }
  // It is used to show action pop up
  toggle(event, data) {
    this.rowData = data;
    this.items = [
      { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
      { label: 'Approve/Reject', icon: 'pi pi-fw pi-check-circle', command: () => { this.approveOrRejectRequirement(); }, },
      { label: 'Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); }, },
    ];
  }
  // It is used for dowload the profile
  downloadFileapi() {
    this.filedata = this.rowData.resumeId;
    this.isUploading1 = true;
    this.apisService.getApi(environment.downloadedattachment + '/' + this.filedata).subscribe((res) => {
      this.resumeRes = res;
      this.isUploading1 = false;
      window.location.assign(this.resumeRes.downloadUrl);
    });
  }
  // It is used for reject the request
  rejectRequirement() {
    this.rejectForComments = true;
    this.approveOrRejectForm.get('comments').enable();
  }
  // It is used for approve the request
  approveRequirement() {
    this.approveOrRejectForm.get('comments').reset();
    this.rejectForComments = false;
    this.approveOrRejectForm.get('comments').disable();
  }
  submitForApproveOrReject() {
    if (this.approveOrRejectForm.invalid) {
      this.approveOrRejectForm.get('comments').markAsTouched();
    } else {
      this.disable = true;
      this.approveOrRejectYes();
    }
  }
  // It is used to submit the approve/reject the request
  approveOrRejectRequirement() {
    this.rejectForComments = false;
    this.filedata = this.rowData;
    if (this.filedata.activityStatus === 'C') {
      this.approveOrReject = true;
      this.approveOrRejectForm.get('comments').disable();
      this.approveOrRejectForm.get('approveOrReject').patchValue('Approved');
    }
    else {
      Swal.fire({
        text: 'Request already ' + this.statuTable[this.filedata.activityStatus],
        icon: 'success',
        confirmButtonColor: '#0D416B',
        iconColor: '#1dbb99',
        confirmButtonText: 'Ok'
      });
    }
  }
  approveOrRejectYes() {
    this.disable = true;
    const submitReq = {
      lkActivityStatus: this.approveOrRejectForm.controls.approveOrReject.value === 'Approved' ? 'A' : 'R',
      comments: this.approveOrRejectForm.get('comments').value === null ? '' : this.approveOrRejectForm.get('comments').value,
      id: this.filedata.activityId,
      objectId: this.filedata.reqProfileId,
    };
    this.apisService.putApi(environment.submissionsApprove, submitReq).subscribe((res) => {
      this.approveOrRejectResponse = res;
      if (this.approveOrRejectResponse.hasOwnProperty('success') === true) {
        this.approveOrReject = false;
        this.gridData();
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.approveOrRejectResponse.message });
      } else if (
        this.approveOrRejectResponse.hasOwnProperty('success') === false
      ) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.approveOrRejectResponse.message });
      } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.approveOrRejectResponse.error });
      }
      this.disable = false;
    });
  }
  // It is used for the searching the the data based on the grid data
  search() {
    this.loader = true;
    const jobId = this.submissionRequestForm.controls.jobId.value === null ? '' : this.submissionRequestForm.controls.jobId.value;
    // tslint:disable-next-line:max-line-length
    const status = this.submissionRequestForm.controls.statusDropdown.value === null ? '' : this.submissionRequestForm.controls.statusDropdown.value;
    const search = '?jobId=' + jobId + '&lkStatus=' + status;
    // tslint:disable-next-line:max-line-length
    this.apisService.getApi(environment.reqProfileId + '/' + this.pageNo + '/' + this.pageCount + '/' + this.userId + search).subscribe((res: any) => {
      this.loader = false;
      this.gridRes = res.data.records;
      this.totalRecords = res.data.count;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  // It is used to submission sctivity
  profilehistroy() {
    this.displayModal = true;
    this.date = new Date();
    const actId = JSON.parse(this.apisService.decodeData());
    this.empId = this.rowData.reqId;
    this.dattaa = this.rowData.reqProfileId;
    this.apisService.getApi(environment.getSubmissionActivity + this.dattaa).subscribe((res: any) => {
      this.histroy = res.data;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
}
