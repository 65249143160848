<p-panel [toggleable]="false">
  <p-header>
    <label>
     <strong> Tech Reviews </strong><strong class="positionheader">Job Id : </strong><span class="panelLabel">{{jobId}}</span>
      <strong class="positionheader">Position
        Title : </strong> <span class="panelLabel">{{reqName}}</span>
    </label>
    <em class="pi pi-arrow-left panelBackRoute" (click)="goto()"></em>
  </p-header>
  <form [formGroup]="addtechreviewForm">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Candidate Name </label>
        <input autocomplete="off" type="text" pInputText formControlName="candidateName" maxlength=40 disabled="true"
          placeholder="Candidate Name">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Reviewer Name </label>
        <input autocomplete="off" type="text" disabled=true pInputText formControlName="reviewName" maxlength=40
          placeholder="Reviewer Name">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Review Status </label>
        <p-dropdown optionLabel="label" [options]="reviewerStatus1" placeholder="Reviewer Status"
          formControlName="reviewStatus" [showClear]="true"></p-dropdown>
      </div>
    </div>
    <h3>Ratings</h3>
    <div class="p-grid p-fluid">
      <div class="p-col-3 p-md-3 p-sm-12 p-lg-3">
        <label class="inputfields">Architecture </label>
        <p-rating *ngIf="display" [cancel]="false" formControlName="architecture"></p-rating>
        <p-rating *ngIf="!display" disabled=true [cancel]="false" formControlName="architecture"></p-rating>
      </div>
      <div class="p-col-3 p-md-3 p-sm-12 p-lg-3">
        <label class="inputfields">Coding </label>
        <p-rating *ngIf="display" [cancel]="false" formControlName="coding"></p-rating>
        <p-rating *ngIf="!display" disabled=true [cancel]="false" formControlName="coding"></p-rating>
      </div>
      <div class="p-col-3 p-md-3 p-sm-12 p-lg-3">
        <label class="inputfields">Testing </label>
        <p-rating *ngIf="display" [cancel]="false" formControlName="testing"></p-rating>
        <p-rating *ngIf="!display" disabled=true [cancel]="false" formControlName="testing"></p-rating>
      </div>
      <div class="p-col-3 p-md-3 p-sm-12 p-lg-3">
        <label class="inputfields">Business </label>
        <p-rating *ngIf="display" [cancel]="false" formControlName="business"></p-rating>
        <p-rating *ngIf="!display" disabled=true [cancel]="false" formControlName="business"></p-rating>
      </div>
    </div>
    <div class="p-grid p-fluid">
      <div class="p-col-3 p-md-3 p-sm-12 p-lg-3">
        <label class="inputfields">Ownership </label>
        <p-rating *ngIf="display" [cancel]="false" formControlName="ownership"></p-rating>
        <p-rating *ngIf="!display" disabled=true [cancel]="false" formControlName="ownership"></p-rating>
      </div>
      <div class="p-col-3 p-md-3 p-sm-12 p-lg-3">
        <label class="inputfields">Team Management </label>
        <p-rating *ngIf="display" [cancel]="false" formControlName="management"></p-rating>
        <p-rating *ngIf="!display" disabled=true [cancel]="false" formControlName="management"></p-rating>
      </div>
      <div class="p-col-3 p-md-3 p-sm-12 p-lg-3">
        <label class="inputfields">Analytical </label>
        <p-rating *ngIf="display" [cancel]="false" formControlName="analytical"></p-rating>
        <p-rating *ngIf="!display" disabled=true [cancel]="false" formControlName="analytical"></p-rating>
      </div>
      <div class="p-col-3 p-md-3 p-sm-12 p-lg-3">
        <label class="inputfields">Communication </label>
        <p-rating *ngIf="display" [cancel]="false" formControlName="communication"></p-rating>
        <p-rating *ngIf="!display" disabled=true [cancel]="false" formControlName="communication"></p-rating>
      </div>
    </div>
    <div class="p-grid p-fluid">
      <div class="p-col-3 p-md-3 p-sm-12 p-lg-3">
        <label class="inputfields">Documentation </label>
        <p-rating *ngIf="display" [cancel]="false" formControlName="documentation"></p-rating>
        <p-rating *ngIf="!display" disabled=true [cancel]="false" formControlName="documentation"></p-rating>
      </div>
      <div class="p-col-3 p-md-3 p-sm-12 p-lg-3">
        <label class="inputfields">Position </label>
        <p-rating *ngIf="display" [cancel]="false" formControlName="position"></p-rating>
        <p-rating *ngIf="!display" disabled=true [cancel]="false" formControlName="position"></p-rating>
      </div>
    </div>
    <div class="p-col-3 p-md-3 p-sm-12 p-lg-3">
      <label class="inputfields headercolor">Average Rating </label>
      <p-rating [cancel]="false" formControlName="average" disabled=true></p-rating>

    </div>
    <div class="p-grid p-fluid">
      <div class="p-col-12 ">
        <label class="inputfields" id='csslabel'>Comments</label>
        <textarea *ngIf="display" autocomplete="off" formControlName="requirementsComments" placeholder="Comments"
          pInputTextarea rows="2" cols="30" maxlength=300></textarea>
        <textarea *ngIf="!display" disabled=true autocomplete="off" formControlName="requirementsComments"
          placeholder="Comments" pInputTextarea rows="2" cols="30" maxlength=300></textarea>
      </div>
    </div>
  </form>
  <div class="btnsCSS1" *ngIf="display">
    <button pButton type="submit" icon='pi pi-save' label="Save" [disabled]="disable" class="addhBtn"
      (click)="addReqData()"></button>
  </div><br><br>
</p-panel>
<p-toast position="bottom-right" key="br"></p-toast>