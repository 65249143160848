import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';

@Component({
  selector: 'app-resetuserpassword',
  templateUrl: './resetuserpassword.component.html',
  styleUrls: ['./resetuserpassword.component.scss'],
  providers: [DatePipe, MessageService],
})
export class ResetuserpasswordComponent implements OnInit {

  resetUserPassword: FormGroup;
  loginUserId: any;
  checkPasswordRes: any;
  passwordUpdateRes: any;


  constructor(
    private fb: FormBuilder,
    private apiService: ApisService,
    private messageService: MessageService,
    private breadcrumbservice: BreadcrumbService
  ) {
    this.breadcrumbservice.setItems([
      {label: 'Reset User Password', routerLink: '/resetUserPassword'}
    ]);
   }

  ngOnInit() {
    this.loginUserId = JSON.parse(sessionStorage.getItem('loginData')).userId;
    this.resetUserPassword = this.fb.group({
      password: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[[a-zA-Z0-9]+[.]?[a-z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]{2,4}]*$')]],
      conformPassword: ['', Validators.required]
    });
  }
  resetPassword() {
    if (this.resetUserPassword.invalid) {
      const controls = this.resetUserPassword.controls;
      Object.keys(controls).forEach(key => {
        controls[key].markAsTouched();
      });
    } else {
      const email = this.resetUserPassword.controls.email.value;
      const password = this.resetUserPassword.controls.password.value;
      const conformPassword = this.resetUserPassword.controls.conformPassword.value;
      if (password === conformPassword) {
        const data = {
          emailId: this.resetUserPassword.controls.email.value,
          password: this.resetUserPassword.controls.password.value
        };
       } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000 ,  detail: 'Password & Confirm Password must be same.' });
      }
    }
  }
  reset() {
    this.resetUserPassword.reset();
  }
}
