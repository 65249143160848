<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
    <p-accordion (onClose)="onTabClose($event)" (onOpen)="onTabOpen($event)">
        <p-accordionTab [selected]="!show">
            <p-header>
                <strong>{{headerName}} Id : </strong><span class="panelLabel">{{this.reqId}}</span>&nbsp;&nbsp;
                <strong>Position Title :&nbsp;</strong> <span
                    class="panelLabel">{{this.reqName}}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Max Rate :
                </strong><span class="panelLabel">
                    {{this.maxrate | currency: currencyValue : true}}

                </span>
                <div class="pi pi-arrow-left backRoute" (click)="backNavigate()">
                </div>
            </p-header>
            <form [formGroup]="editRequirementsForm">
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Position Title</label>
                        <br>
                        <input readonly="true" autocomplete="off" id="input" type="text" pInputText placeholder="Name"
                            maxlength="30" formControlName='reqName'>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                        <label class="inputfields">Position Category</label>
                        <br>
                        <p-dropdown readonly="true" optionLabel="jobCategory" [options]="positionData" optionValue="id"
                            [showClear]="true" placeholder="Select Position Category"
                            (onChange)="getRolesBasedonCategory($event.value)" formControlName="positionCategory">
                        </p-dropdown>
                        <div>
                            <span
                                *ngIf="this.editRequirementsForm.controls.positionCategory.touched && this.editRequirementsForm.controls.positionCategory.invalid && this.editRequirementsForm.controls.positionCategory.errors.required">
                                Please select position category
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                        <label class="inputfields">Job Role</label>
                        <p-dropdown readonly="true" [options]="minMaxRate" (onChange)="getMinrate($event.value)"
                            [showClear]="true" optionLabel="title" placeholder="Select Job Role"
                            formControlName="requirementTitle">
                        </p-dropdown>
                    </div>

                    <div class="p-col-3 p-md-3 p-sm-12">
                        <label class="inputfields">Department </label>
                        <p-dropdown readonly="true" formControlName="department" optionLabel="name" [showClear]="true"
                            [options]="departmentList" optionValue="id" placeholder="Select Department"></p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                        <label class="inputfields">Min Rate</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                            <input readonly="true" autocomplete="off" type="text" pInputText placeholder="Min Rate"
                                placeholder="Min Rate" maxlength="9" formControlName="requirementMinRate"
                                disabled="true" readonly="true">
                            <span class="p-inputgroup-addon">/Hr</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                        <label class="inputfields">Max Rate </label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                            <input readonly="true" autocomplete="off" type="text" pInputText maxlength="9"
                                formControlName="requirementMaxRate" placeholder="Max Rate">
                            <span class="p-inputgroup-addon">/Hr</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6"></div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                        <label class="inputfields">Number of Positions<span class="mandatoryColor">*</span></label>
                        <input readonly="true" autocomplete="off" id="input" type="text" pInputText
                            placeholder="Number of Positions" maxlength="10" formControlName="requirementNoofPositions"
                            maxlength=4 pKeyFilter="num">
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.requirementNoofPositions.touched && this.editRequirementsForm.controls.requirementNoofPositions.invalid && this.editRequirementsForm.controls.requirementNoofPositions.errors.required">
                                Please enter number of positions
                            </span>
                        </div>
                    </div>
                    <!-- <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                        <label class="inputfields">Work Location<span class="mandatoryColor">*</span></label>
                        <p-dropdown readonly="true" [options]="locationList1" optionLabel="name" [showClear]="true"
                            placeholder="Select Work Location " formControlName="requirementWorkCenterLocation">
                        </p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.requirementWorkCenterLocation.touched && this.editRequirementsForm.controls.requirementWorkCenterLocation.invalid && this.editRequirementsForm.controls.requirementWorkCenterLocation.errors.required">
                                Please select work location
                            </span>
                        </div>
                    </div> -->
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory"
                    pTooltip="{{this.patchlocationString}}" tooltipPosition="top">
                    <label class="inputfields">Work Location<span class="mandatoryColor">*</span></label>
                    <p-dropdown [options]="locationList" placeholder="Select Work Location" optionLabel="name"
                        [showClear]="true" formControlName="requirementWorkCenterLocation" appendTo="body"
                        (onChange)="saveLocation($event)">
                        <ng-template let-data pTemplate="item">
                            <div pTooltip="{{data.name + '\n' + data.address1+',' + data.city + ',' + data.state + ',' + data.country}}"
                                tooltipPosition="right">
                                {{ data.name }}
                            </div>
                        </ng-template>

                    </p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.editRequirementsForm.controls.requirementWorkCenterLocation.touched && this.editRequirementsForm.controls.requirementWorkCenterLocation.invalid && this.editRequirementsForm.controls.requirementWorkCenterLocation.errors.required">
                            Please select work location
                        </span>
                    </div>
                </div>
                    <!-- <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory"  pTooltip="{{this.patchlocationString}}" tooltipPosition="top">
                        <label class="inputfields">Work Location<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="locationList" placeholder="Select Work Location" optionLabel="name"
                          [showClear]="true" formControlName="requirementWorkCenterLocation" appendTo="body" (onChange)="saveLocation($event)">
                          <ng-template let-data pTemplate="item">
                            <div pTooltip="{{data.name + '\n' + data.address1+',' + data.city + ',' + data.state + ',' + data.country}}"
                                tooltipPosition="right">
                                {{ data.name }}
                            </div>
                        </ng-template>
                        
                        </p-dropdown>
                        <div>
                          <span class="validationtest"
                            *ngIf="this.editRequirementsForm.controls.requirementWorkCenterLocation.touched && this.editRequirementsForm.controls.requirementWorkCenterLocation.invalid && this.editRequirementsForm.controls.requirementWorkCenterLocation.errors.required">
                            Please select work location
                          </span>
                        </div>
                      </div> -->
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory" *ngIf="enddata">
                        <label class="inputfields">Required Experience<span class="mandatoryColor">*</span></label>
                        <p-dropdown readonly="true" optionLabel="label" placeholder="Select Experience"
                            [showClear]="true" [options]="exprienceList" optionValue="code"
                            formControlName="requirementReqExp">
                        </p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.requirementReqExp.touched && this.editRequirementsForm.controls.requirementReqExp.invalid && this.editRequirementsForm.controls.requirementReqExp.errors.required">
                                Please select required experience
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3" *ngIf="enddata"></div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6" *ngIf="!enddata"></div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                        <label class="inputfields">Hire Type<span class="mandatoryColor">*</span></label>
                        <p-dropdown readonly="true" placeholder="Select Hire Type" optionLabel="label"
                            [showClear]="true" [options]="hireTypeList" optionValue="code"
                            formControlName='requirementType'>
                        </p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.requirementType.touched && this.editRequirementsForm.controls.requirementType.invalid && this.editRequirementsForm.controls.requirementType.errors?.required">
                                Please select type
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                        <label class="inputfields">Planned Start Date<span class="mandatoryColor">*</span></label>
                        <p-calendar readonly="true" id="popup" placeholder="MM-DD-YYYY" dateFormat="mm-dd-yy"
                            [showIcon]="true" [showButtonBar]="true" formControlName="requirementStartDate">
                        </p-calendar>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('requirementStartDate').touched && this.editRequirementsForm.get('requirementStartDate').invalid && this.editRequirementsForm.get('requirementStartDate').errors.required">
                                Please select planned start date
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory" *ngIf="endDisplay">
                        <label class="inputfields">Planned End Date<span class="mandatoryColor">*</span></label>
                        <p-calendar readonly="true" id="popup" placeholder="MM-DD-YYYY" dateFormat="mm-dd-yy"
                            [showIcon]="true" [showButtonBar]="true" formControlName="requirementEndDate"
                            disabled="true">
                        </p-calendar>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('requirementEndDate').touched && this.editRequirementsForm.get('requirementEndDate').invalid &&  this.editRequirementsForm.get('requirementEndDate').errors.required">
                                Please select planned end date
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                        <label class="inputfields">Status<span class="mandatoryColor">*</span></label>
                        <p-dropdown readonly="true" placeholder="Select Status" optionLabel="label" [showClear]="true"
                            [options]="statusList" formControlName="requirementStatus">
                        </p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.requirementStatus.touched && this.editRequirementsForm.controls.requirementStatus.invalid && this.editRequirementsForm.controls.requirementStatus.errors.required">
                                Please select status
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 invalidMandatory">
                        <label>Employee approval required? </label>
                        <p-radioButton class="yes" name="approval" value="Y" label="Yes" formControlName="approval"
                            inputId="Yes">
                        </p-radioButton>&nbsp;&nbsp;
                        <p-radioButton class="no" name="approval" value="N" label="No" formControlName="approval"
                            inputId="No">
                        </p-radioButton>
                    </div>

                    <div class="p-col-3 p-md-3 p-sm-12 invalidMandatory container">
                        <label class="inputfields">Billing Type<span class="mandatoryColor">*</span></label>
                        <p-dropdown readonly="true" formControlName="billingtype" optionLabel="label" [showClear]="true"
                            [options]="billingList" optionValue="code" placeholder="Select Billing Type"></p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('billingtype').touched && this.editRequirementsForm.get('billingtype').invalid && this.editRequirementsForm.get('requirementsExp').errors.required">
                                Please Select Billing Type
                            </span>
                        </div>
                    </div>
                    <div class="p-col-3 p-md-3 p-sm-12 container">
                        <label class="inputfields">Duration</label>
                        <div class="p-inputgroup">
                            <!-- <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span> -->
                            <input readonly="true" autocomplete="off" id="input" type="text" pInputText
                                placeholder="Enter Duration" formControlName="duration">
                            <span class="p-inputgroup-addon">Months</span>

                        </div>
                    </div>
                    <div class="p-col-3 p-md-3 p-sm-12 container">
                        <label class="inputfields">Per Week</label>
                        <div class="p-inputgroup">
                            <!-- <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span> -->
                            <input readonly="true" autocomplete="off" id="input" type="text" pInputText
                                placeholder="Enter Duration" formControlName="hrsperWeek">
                            <span class="p-inputgroup-addon">Hrs</span>

                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3"></div>

                    <!-- <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory skills">
                        <label class="inputfields">Skill Set<span class="mandatoryColor">*</span></label>
                        <p-multiSelect [options]="PreferredSkills" optionLabel="label" [virtualScroll]="true"
                            [itemSize]="34" placeholder="Select Skill Set" formControlName="requirementSkillSet"
                            (onChange)="skillsetOthers($event,$event.value)"
                            >
                        </p-multiSelect>
                        <div class="skillset">
                            <label><strong>Skill Set: </strong></label>
                            <span > {{selectedPrimaryskills.toString()}}</span>
                           
                        </div>

                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.requirementSkillSet.touched && this.editRequirementsForm.controls.requirementSkillSet.invalid && this.editRequirementsForm.controls.requirementSkillSet.errors.required">
                                Please select skill set
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory skills">
                        <label class="inputfields">Preferred Skill Set</label>
                        <p-multiSelect [options]="PreferredSkills" optionLabel="label" [virtualScroll]="true"
                        (onChange)="resetskills($event,$event.value)" [itemSize]="34" placeholder="Select Preferred Skill Set"
                            formControlName="requirementPreferredSkillSet">
                        </p-multiSelect>
                        <div class="skillset">
                            <label><strong>Preferred Skill Set: </strong> </label>
                            <span >{{preferredSkills.toString()}}</span>
                        </div>
                    </div> -->
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory skills">
                        <label class="inputfields">Skill Set<span class="mandatoryColor">*</span></label>
                        <p-multiSelect [options]="PreferredSkills" optionLabel="label" [virtualScroll]="true"
                            [itemSize]="34" placeholder="Select Skill Set" formControlName="requirementSkillSet"
                            (onChange)="skillsetOthers($event,$event.value)">
                        </p-multiSelect>
                        <div class="skillset">
                            <label><strong>Skill Set: </strong></label>
                            <span> {{selectedPrimaryskills.toString()}}</span>

                        </div>

                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.requirementSkillSet.touched && this.editRequirementsForm.controls.requirementSkillSet.invalid && this.editRequirementsForm.controls.requirementSkillSet.errors.required">
                                Please select skill set
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory skills">
                        <label class="inputfields">Preferred Skill Set</label>
                        <p-multiSelect [options]="PreferredSkills" optionLabel="label" [virtualScroll]="true"
                            (onChange)="resetskills($event,$event.value)" [itemSize]="34"
                            placeholder="Select Preferred Skill Set" formControlName="requirementPreferredSkillSet">
                        </p-multiSelect>
                        <div class="skillset">
                            <label><strong>Preferred Skill Set: </strong> </label>
                            <span>{{preferredSkills.toString()}}</span>
                        </div>
                    </div>
                </div>
                <br>
                <div class="p-fluid  p-grid" *ngIf="otherskilldata">
                    <div class="p-field p-col-12 p-md-6 p-sm-12 p-lg-6">
                        <label class="inputfields">Others Skills<span class="mandatoryColor">*</span></label>
                        <textarea rows="5" maxlength="301" cols="30"
                            placeholder="Enter Others Skills (Ex: Angular, Java)" formControlName="otherskills"
                            (blur)=onBlur($event.target.value) pInputTextarea></textarea>
                        <span class="validationtest" *ngIf="this.editRequirementsForm.get('otherskills').touched &&
                             this.editRequirementsForm.get('otherskills').invalid &&
                              this.editRequirementsForm.get('otherskills').errors.required">
                            Please enter others skills
                        </span>
                        <span class="validationtest"
                            *ngIf=" this.editRequirementsForm.get('otherskills').hasError('maxlength')"> Please enter
                            300
                            alphabets only </span>
                    </div>
                </div>

                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                        <label class="inputfields">Job Description<span class="mandatoryColor">*</span></label>
                        <textarea readonly="true" autocomplete="off" pInputTextarea rows="8" cols="30"
                            placeholder="Job Description" formControlName="requirementDescription"></textarea>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.requirementDescription.touched && this.editRequirementsForm.controls.requirementDescription.invalid && this.editRequirementsForm.controls.requirementDescription.errors.required">
                                Please enter description
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                        <label class="inputfields">Responsibilities<span class="mandatoryColor">*</span></label>
                        <textarea readonly="true" autocomplete="off" pInputTextarea rows="8" cols="30"
                            placeholder="Responsibilities" formControlName="requirementResponsibilities"></textarea>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.requirementResponsibilities.touched && this.editRequirementsForm.controls.requirementResponsibilities.invalid && this.editRequirementsForm.controls.requirementResponsibilities.errors.required">
                                Please enter responsibilities
                            </span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                        <label class="inputfields">Qualifications<span class="mandatoryColor">*</span></label>
                        <textarea readonly="true" autocomplete="off" pInputTextarea rows="8" cols="30"
                            placeholder="Qualifications" formControlName="requirementQualification"></textarea>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.requirementQualification.touched && this.editRequirementsForm.controls.requirementQualification.invalid && this.editRequirementsForm.controls.requirementQualification.errors.required">
                                Please enter qualifications
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                        <label class="inputfields">Comments</label>
                        <textarea readonly="true" autocomplete="off" pInputTextarea rows="8" cols="30"
                            placeholder="Comments" formControlName="requirementComments"></textarea>
                    </div>
                </div>
            </form>
        </p-accordionTab>
        <br *ngIf="show">
        <p-accordionTab [selected]="true" *ngIf="show">
            <p-header>
                <strong>Available Candidates</strong>
                <!-- <div class="pi pi-arrow-left backRoute" (click)="backNavigate()">
                </div> -->
            </p-header>
            <app-availableopenpositions></app-availableopenpositions>
        </p-accordionTab>
        <br *ngIf="show">
        <p-accordionTab [selected]="false" *ngIf="show">
            <p-header>
                <strong>Submitted Candidates</strong>
                <!-- <div class="pi pi-arrow-left backRoute" (click)="backNavigate()">
                </div> -->
            </p-header>
            <form [formGroup]="submittedProfileForm">
                <div>
                    <p-card>
                        <!-- <p-panel>
                            <p-header>
                                <strong>Submitted Candidates</strong>
                            </p-header> -->
                            <div class="p-grid p-fluid">
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                                    <label class="inputfields"> First Name </label>
                                    <input id="input" type="text" autocomplete="off" pInputText placeholder="First Name"
                                        maxlength="30" formControlName="firstName">
                                </div>

                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                                    <label class="inputfields"> Last Name </label>
                                    <input id="input" type="text" autocomplete="off" pInputText placeholder="Last Name"
                                        maxlength="30" formControlName="lastName">
                                </div>
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                    <label class="inputfields"> Email</label>
                                    <input id="input" type="text" autocomplete="off" pInputText placeholder="Email"
                                        maxlength="60" formControlName="emailId">
                                </div>
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                    <label class="inputfields"> Experience</label>
                                    <p-dropdown [options]="exprienceList" optionLabel="label"
                                        placeholder="Select Experience" [showClear]="true" formControlName="experience">
                                    </p-dropdown>
                                </div>
                            </div>
                            <br>
                            <div class="p-grid p-fluid">
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                                    <div class="btnsCSS1">
                                        <!-- <button pButton type="button" icon='pi pi-plus' label="Add" (click)="add()"
                                            class="addhBtn"></button> -->
                                        <button pButton type="button" icon='pi pi-refresh' label="Reset"
                                            (click)="reset($event)" class="addhBtn"></button>
                                        <button pButton type="button" icon='pi pi-search' label="Search"
                                            (click)="search()" class="addhBtn"></button>
                                    </div>
                                </div>
                            </div>
                        <!-- </p-panel> -->
                    </p-card>
                    <br>
                    <p-card class="pgpos">
                        <div *ngIf="isUploading" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                            <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
                        </div>
                        <div *ngIf="table">
                            <p-table [columns]="cols11" [autoLayout]="true" dataKey="consultantName"
                                [value]="submittedProfilesGridData" selectionMode="single" [rows]="7"
                                [responsive]="true" [rowsPerPageOptions]="[10,20,30]" sortMode="multiple"
                                id="excel-table">
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th id="gridHeader" *ngFor="let col of columns let i=index"
                                            [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field"
                                            [hidden]="col.sno == 4">
                                            {{col.header}}

                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-Data let-expanded="expanded">
                                    <tr>
                                        <td [pRowToggler]="Data" class="textCenter">
                                            <a style="cursor: pointer;">
                                                {{Data.consultantName}}
                                            </a>
                                        </td>
                                        <td class="textCenter">{{Data.submittedDate}}</td>
                                        <td class="textCenter">
                                            <a (click)="op.toggle($event)"
                                                (click)="reqSkills(getSkillSetTable(Data.requiredSkills))"
                                                *ngFor="let data1 of getSkillSetTable(Data.requiredSkills); index as i;">
                                                <p-badge class="badgespace newsty" *ngIf='i<1'
                                                    [value]="data1"></p-badge>
                                                <p-badge *ngIf='i===1'
                                                    [value]="'+'+(Data.requiredSkills.length-1)+' more'">
                                                </p-badge>
                                            </a>

                                        </td>
                                        <td class="pointer" [hidden]="true">
                                            <a (click)="op.toggle($event)"
                                                (click)="reqSkills(getSkillSetTable(Data.requiredSkills))"
                                                *ngFor="let data1 of getSkillSetTable(Data.requiredSkills); index as i;">
                                                <p-badge [value]="data1+','"></p-badge>
                                            </a>
                                        </td>
                                        <td class="textCenter">{{this.expLabel[Data.experience]}}
                                        </td>
                                        <td>{{statusdata[Data.profileState]}}</td>
                                        <td>
                                            {{Data.billRate | currency: currencyValue : true}}
                                        </td>
                                        <td class="textCenter"><a>
                                                <em style="width: 50%;cursor: pointer;"
                                                    (click)="actionpopup.toggle($event); toggle(items, $event, Data)"
                                                    class="pi pi-ellipsis-v"></em>
                                            </a></td>
                                    </tr>

                                </ng-template>
                                <ng-template pTemplate="rowexpansion" let-product>
                                    <tr>
                                        <td colspan="7">
                                            <div class="p-p-3">
                                                <p-card>
                                                    <div class="p-grid p-fluid">
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                                                            <label class="inputfields">Email : </label>
                                                            <a class="pointer" (click)="emailToast()"
                                                                (click)="copyInputMessage(product.email)">
                                                                {{product.email}}</a>
                                                        </div>
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                                                            <label class="inputfields">Phone Number :</label>
                                                            {{product.mobilPhone}}
                                                        </div>
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                                                            <label class="inputfields">Experience :</label>
                                                            {{this.expLabel[product.experience]}}
                                                        </div>
                                                    </div>
                                                    <div class="p-grid p-fluid">
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                                                            <label class="inputfields">Office Number :</label>
                                                            {{product.office_phone }}
                                                        </div>
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                                                            <label class="inputfields">Pay Rate :</label>
                                                            {{product.payRate | currency: currencyValue : true}}

                                                        </div>
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">

                                                            <label class="inputfields">Bill Rate :</label>
                                                            {{product.billRate | currency: currencyValue : true}}

                                                        </div>
                                                    </div>
                                                    <div class="p-grid p-fluid">
                                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                                                            <label class="inputfields">Skills :</label>
                                                            {{getSkillSetTable(product.requiredSkills )}}
                                                        </div>
                                                    </div>
                                                </p-card>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>

                            <div class="p-grid ui-fluid">
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 totalrec">
                                    <h3 class="norecorddata ">Total records : {{this.subProRecords}}</h3>
                                    <p class="downpos">
                                        <span class="ui-column-title pointer"
                                            (click)="downloadActionPopup.toggle($event); downLoadsToggle()"
                                            (keyup)="exportExcel()">
                                            <em class="pi pi-download downloadspace"></em>
                                            <strong>Download</strong></span>
                                    </p>
                                </div>
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
                                    <p-paginator [rows]="10" [totalRecords]="subProRecords"
                                        [rowsPerPageOptions]="[10,20,30]" (onPageChange)="paginate($event)"
                                        #p></p-paginator>
                                </div>
                            </div>

                        </div>
                        <p-card *ngIf="records">
                            <div style="height: 340px;">
                                <div *ngIf="records" style="text-align: center;">
                                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png"
                                        class="norecordimg">
                                    <br><em>Sorry, we are not able to find any submitted candidates</em><br>
                                </div>
                            </div>
                        </p-card>
                        <p-menu class="menuclass" #downloadActionPopup [popup]="true"
                            [model]="downloadOptions"></p-menu>
                    </p-card>
                </div>
            </form>
        </p-accordionTab>
    </p-accordion>
</p-card>
<br>
<!-- <form [formGroup]="submittedProfileForm">
    <div *ngIf="show">
        <p-card>
            <p-panel>
                <p-header>
                    <strong>Submitted Candidates</strong>
                </p-header>
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                        <label class="inputfields"> First Name </label>
                        <input id="input" type="text" autocomplete="off" pInputText placeholder="First Name"
                            maxlength="30" formControlName="firstName">
                    </div>

                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                        <label class="inputfields"> Last Name </label>
                        <input id="input" type="text" autocomplete="off" pInputText placeholder="Last Name"
                            maxlength="30" formControlName="lastName">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields"> Email</label>
                        <input id="input" type="text" autocomplete="off" pInputText placeholder="Email" maxlength="60"
                            formControlName="emailId">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields"> Experience</label>
                        <p-dropdown [options]="exprienceList" optionLabel="label" placeholder="Select Experience"
                            [showClear]="true" formControlName="experience">
                        </p-dropdown>
                    </div>
                </div>
                <br>
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                        <div class="btnsCSS1">
                            <button pButton type="button" icon='pi pi-plus' label="Add" (click)="add()"
                                class="addhBtn"></button>
                            <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset($event)"
                                class="addhBtn"></button>
                            <button pButton type="button" icon='pi pi-search' label="Search" (click)="search()"
                                class="addhBtn"></button>
                        </div>
                    </div>
                </div>
            </p-panel>
        </p-card>
        <br>
        <p-card class="pgpos">
            <div *ngIf="isUploading" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
            </div>
            <div *ngIf="table">
                <p-table [columns]="cols11" [autoLayout]="true" dataKey="consultantName"
                    [value]="submittedProfilesGridData" selectionMode="single" [rows]="7" [responsive]="true"
                    [rowsPerPageOptions]="[10,20,30]" sortMode="multiple" id="excel-table">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th id="gridHeader" *ngFor="let col of columns let i=index"
                                [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field" [hidden]="col.sno == 4">
                                {{col.header}}

                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-Data let-expanded="expanded">
                        <tr>
                            <td [pRowToggler]="Data" class="textCenter">
                                <a style="cursor: pointer;">
                                    {{Data.consultantName}}
                                </a>
                            </td>
                            <td class="textCenter">{{Data.submittedDate}}</td>
                            <td class="textCenter">
                                <a (click)="op.toggle($event)"
                                    (click)="reqSkills(getSkillSetTable(Data.requiredSkills))"
                                    *ngFor="let data1 of getSkillSetTable(Data.requiredSkills); index as i;">
                                    <p-badge class="badgespace newsty" *ngIf='i<1' [value]="data1"></p-badge>
                                    <p-badge *ngIf='i===1' [value]="'+'+(Data.requiredSkills.length-1)+' more'">
                                    </p-badge>
                                </a>

                            </td>
                            <td class="pointer" [hidden]="true">
                                <a (click)="op.toggle($event)"
                                    (click)="reqSkills(getSkillSetTable(Data.requiredSkills))"
                                    *ngFor="let data1 of getSkillSetTable(Data.requiredSkills); index as i;">
                                    <p-badge [value]="data1+','"></p-badge>
                                </a>
                            </td>
                            <td class="textCenter">{{this.expLabel[Data.experience]}}
                            </td>
                            <td>{{statusdata[Data.profileState]}}</td>
                            <td>
                                {{Data.billRate | currency: currencyValue : true}}
                            </td>
                            <td class="textCenter"><a>
                                    <em style="width: 50%;cursor: pointer;"
                                        (click)="actionpopup.toggle($event); toggle(items, $event, Data)"
                                        class="pi pi-ellipsis-v"></em>
                                </a></td>
                        </tr>

                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-product>
                        <tr>
                            <td colspan="7">
                                <div class="p-p-3">
                                    <p-card>
                                        <div class="p-grid p-fluid">
                                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                                                <label class="inputfields">Email : </label>
                                                <a class="pointer" (click)="emailToast()"
                                                    (click)="copyInputMessage(product.email)"> {{product.email}}</a>
                                            </div>
                                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                                                <label class="inputfields">Phone Number :</label>
                                                {{product.mobilPhone}}
                                            </div>
                                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                                                <label class="inputfields">Experience :</label>
                                                {{this.expLabel[product.experience]}}
                                            </div>
                                        </div>
                                        <div class="p-grid p-fluid">
                                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                                                <label class="inputfields">Office Number :</label>
                                                {{product.office_phone }}
                                            </div>
                                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                                                <label class="inputfields">Pay Rate :</label>
                                                {{product.payRate | currency: currencyValue : true}}

                                            </div>
                                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">

                                                <label class="inputfields">Bill Rate :</label>
                                                {{product.billRate | currency: currencyValue : true}}

                                            </div>
                                        </div>
                                        <div class="p-grid p-fluid">
                                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                                                <label class="inputfields">Skills :</label>
                                                {{getSkillSetTable(product.requiredSkills )}}
                                            </div>
                                        </div>
                                    </p-card>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>

                <div class="p-grid ui-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 totalrec">
                        <h3 class="norecorddata ">Total records : {{this.subProRecords}}</h3>
                        <p class="downpos">
                            <span class="ui-column-title pointer"
                                (click)="downloadActionPopup.toggle($event); downLoadsToggle()" (keyup)="exportExcel()">
                                <em class="pi pi-download downloadspace"></em>
                                <strong>Download</strong></span>
                        </p>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
                        <p-paginator [rows]="10" [totalRecords]="subProRecords" [rowsPerPageOptions]="[10,20,30]"
                            (onPageChange)="paginate($event)" #p></p-paginator>
                    </div>
                </div>

            </div>
            <p-card *ngIf="records">
                <div style="height: 340px;">
                    <div *ngIf="records" style="text-align: center;">
                        <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png"
                            class="norecordimg">
                        <br><em>Sorry, we are not able to find any submitted candidates</em><br>
                    </div>
                </div>
            </p-card>




            <p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="downloadOptions"></p-menu>
        </p-card>
    </div>
</form> -->
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center" style="height:520px">
                <div style="position: absolute;left:50%;top:50%;transform:translate(-50%,-50%)">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        style="height:8em">
                </div>
            </div>
        </div>
    </div>
</ng-template>

<p-dialog class="dialogBox" header="Update Profile" [(visible)]="updateaction" [modal]="true" [style]="{width: '40%'}"
    [baseZIndex]="1" [draggable]="false" [resizable]="false">
    <form [formGroup]="updateprofileForm">
        <div *ngIf="loaderForDownload">
            <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
        </div>
        <p-table [columns]="updateactioncols" [autoLayout]="true" [value]="updateactionGrid" dataKey="id"
            [responsive]="true" selectionMode="single" (onRowSelect)="onSelect($event)"
            (onRowUnselect)="onUnselect($event)" [(selection)]="selectedRowForResume">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns let i=index" (click)="iconToggle[i] = !iconToggle[i]"
                        [ngStyle]="{'width': col.width }">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-data>
                <tr>
                    <td class="textCenter">{{rowData.title}}</td>
                    <td class="textCenter">{{rowData.fileName}}</td>
                    <td class="textCenter"><a (click)="updatedownload(rowData)">
                            <em style="width: 50%;" class="pi pi-download"></em></a></td>
                    <td>
                        <p-tableRadioButton [value]="rowData" name="tableapproval"></p-tableRadioButton>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <br>
        <div class="btnsCSS1" *ngIf="updateactionBtn">
            <button class="addhBtn" pButton type="submit" icon='pi pi-save' label="Update"
                (click)="submitAction()"></button>
        </div><br><br>
    </form>
</p-dialog>
<form [formGroup]="updateBilForm">
    <p-dialog class="dialogBox pgpos" [(visible)]="consultantRequirementDisplay" header="Update Profile Bill Rate"
        [modal]="true" [style]="{width: '40%'}" [baseZIndex]="100000" [draggable]="false" [resizable]="false">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <strong>Position Title : </strong> <span class="panelLabel"> {{this.reqName}}</span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <strong>Candidate Name : </strong>
                <span class="panelLabel"> {{candidateName}}</span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <strong>Markup Percentage : </strong><span class="panelLabel">{{this.markUpData}}%</span>
            </div>
        </div>
        <div class="p-grid ui-fluid">
            <div class="p-col-5 p-md-5 p-sm-5 p-lg-5">
                <p-table [value]="payRatesTable" [responsive]="true">
                    <ng-template pTemplate="caption" style=" padding-bottom: 6px;
        padding-top: 4px;">
                        Bill Rate Calculation
                    </ng-template>
                    <ng-template pTemplate="body" let-pro>
                        <tr style="text-align: center;">
                            <td style="text-align: center;padding: 5px; cursor: pointer;"
                                (click)="payrate(((payRate * pro.value) / 100 )+ payRate)">{{pro.key}} = {{((payRate
                                * pro.value) / 100 )+ payRate | currency: currencyValue : true}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="p-col-1 p-md-1 p-sm-1 p-lg-1"></div>
            <div class="p-col-6 p-md-6 p-sm-6 p-lg-6 billratealign">
                <div class="p-grid ui-fluid ">
                    <div class="p-col-12 p-md-6 p-sm-6 p-lg-6">
                        <label>Pay Rate</label>
                    </div>
                    <div class="p-col-12 p-md-6 p-sm-6 p-lg-6">
                        :<strong> {{this.payRate | currency: 'USD' : true
                            }}</strong>
                    </div>
                    <div class="p-col-12 p-md-6 p-sm-6 p-lg-6">
                        <label>Max Rate for Position </label>
                    </div>
                    <div class="p-col-12 p-md-6 p-sm-6 p-lg-6">
                        <label>: {{this.maxrate | currency: currencyValue : true}}
                        </label>
                    </div>
                    <div class="p-col-12 p-md-6 p-sm-6 p-lg-6">
                        <label class="inputfields">Bill Rate Requested </label>
                    </div>
                    <div class="p-col-12 p-md-6 p-sm-6 p-lg-4">
                        <div class="p-inputgroup">
                            <p class="duration">:</p>&nbsp;
                            <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                            <input style="width:68px;text-align: center;" autocomplete="off" id="input" type="text"
                                size="10" maxlength="22" formControlName="billRateControl" pInputText
                                placeholder="Bill Rate">
                            <span class="p-inputgroup-addon">/Hr</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                        <div style="float: right;">
                            <span *ngIf="errorMsg" style="color: red;margin-bottom: 15px;"> Please enter bill rate less
                                than max rate </span>
                            <span class="validationtest"
                                *ngIf="this.updateBilForm.controls.billRateControl.invalid && this.updateBilForm.controls.billRateControl.errors?.pattern">Please
                                Enter Numbers only</span>
                            <span *ngIf="alertError" style="color: red;margin-bottom: 10px;">Please enter valid bill
                                rate
                            </span>
                            <span class="validationtest"
                                *ngIf="
                            updateBilForm.get('billRateControl').touched &&
                            updateBilForm.get('billRateControl').errors?.required && updateBilForm.get('billRateControl').invalid">
                                Please enter request bill rate
                            </span>
                        </div>
                    </div>
                    <div class="p-col-6 p-md-6 p-sm-6 p-lg-6"></div>
                    <div class="p-col-6 p-md-6 p-sm-6 p-lg-6">
                        <div class="btnAlgn">
                            <button pButton type="button" icon="pi pi-check-circle" label="Update" [disabled]="disable"
                                (click)="submitConsultant()" class="addhBtn"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </p-dialog>
    <p-dialog class="dialogBox" [(visible)]="rejectSubmission" header="Candidate reject submission request"
        [modal]="true" [style]="{width: '40%'}" [baseZIndex]="100000" [draggable]="false" [resizable]="false">
        <div class="p-grid ui-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 ">
                <label class="inputfields">Comments<span class="mandatoryColor">*</span></label>
                <textarea autocomplete="off" [rows]="2" [cols]="30" pInputTextarea formControlName="rejComment"
                    placeholder="comments" maxlength=300 autoResize="autoResize"></textarea>
                <span class="mandatoryColor" *ngIf="updateBilForm.controls.rejComment.touched && updateBilForm.controls.rejComment.invalid
                   && updateBilForm.controls.rejComment.errors.required">
                    Please enter comments</span>
                <p class="mandatoryColor"></p>
            </div>
        </div>
    </p-dialog>
    <p-dialog class="dialogBox" header="Send Request Approval to Candidate" [(visible)]="approvalRequest" [modal]="true"
        [style]="{width: '25%'}" [baseZIndex]="1" [draggable]="false" [resizable]="false"
        [contentStyle]="{'overflow': 'visible'}">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label><strong>Candidate Name : </strong> <span class="headcol"> {{this.candidatename}}</span></label>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Target Date<span class="mandatoryColor">*</span></label>
                <p-calendar appendTo="body" [minDate]="startCurDate" [inputStyle]="{'width':'95%'}" id="popup"
                    dateFormat="mm-dd-yy" formControlName="requestDate" dateFormat="mm-dd-yy" placeholder="MM-DD-YYYY"
                    [showIcon]="true" [showButtonBar]="true">
                </p-calendar>
                <span *ngIf="dateErr" style="color: red;margin-bottom: 15px;"> Please select target date </span>
            </div>
        </div><br>
        <div class="btnsCSS1 btnAlign">
            <button pButton type="button" icon="pi pi-check-circle" label="Submit" (click)="saveRequest()"
                class="addhBtn" [disabled]="submitApproval"></button>
        </div>
    </p-dialog>
    <p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '25%' }">
        <label class="inputfields">Skill Details</label>
        <ng-template pTemplate>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-12 bordersty">
                    <p class="skpos">
                        <p-badge class="badgespace badgesp" *ngFor="let data2 of reqSkillsValue; index as i"
                            [value]="data2" [ngClass]="i%2===0? 'newsty' : ''">
                        </p-badge>
                    </p>
                </div>
            </div>
        </ng-template>
    </p-overlayPanel>
    <p-dialog class="dialogBox" [(visible)]="displayModal" header="" [draggable]="false" [modal]="true"
        [style]="{width: '30%',height:'60%'}">
        <p-header>Profile Activity<br>
            <span class="Activity">Candidate Name : <a>{{this.consultantName}}</a></span>
        </p-header>
        <p style="color: #8b91a0;">Please find the below information</p>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12">
                <p-timeline [value]="histroy" align="alternate">
                    <ng-template pTemplate="content" let-event>
                        <p-card>
                            <p style="text-align: center;"> {{event.activity}}
                                <br>
                                <span style="color: #8b91a0;">By {{event.activityBy}}</span>
                            </p>
                        </p-card>
                        <br>
                    </ng-template>

                    <ng-template pTemplate="opposite" let-event><span style="color: #8b91a0;">{{event.activityDate |
                            date:'MM-dd-yyyy'}}</span>

                    </ng-template>
                </p-timeline>
            </div>
        </div>
    </p-dialog>
</form>




<p-dialog class="dialogBox" [contentStyle]="{'overflow': 'visible'}" header="Candidate Reject Reason"
    [(visible)]="candidaterejectpopup" [style]="{width: '20%'}" [baseZIndex]="1" [draggable]="false" [modal]="true"
    [resizable]="true">
    <div class="p-grid p-fluid ">
        <form [formGroup]="candidateRejectFrom">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 ">
                <label class="inputfields"> Reject Reason </label>
                <p-dropdown [disabled]="true" [options]="rejectReasonData" placeholder="Select Reject Reason"
                    [showClear]="true" optionLabel="label" formControlName="candidaeRejectReason"></p-dropdown>
            </div>
        </form>
    </div>
</p-dialog>