<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
  <form [formGroup]="AttachmentsForm">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Name</label>
        <input id="input" type="text" (keyup.enter)="DataSearch()" pInputText placeholder="Name" autocomplete="off"
          formControlName="Name" maxlength="30">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Valid From <label class="mandatoryColor"></label></label>
        <p-calendar id="popup" placeholder="MM-DD-YYYY" (onSelect)='endDateValidation()' dateFormat="mm-dd-yy"
          (keyup.enter)="DataSearch()" [showIcon]="true" [showButtonBar]="true" formControlName="ValidFromDate">
        </p-calendar>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Valid To <label class="mandatoryColor"></label></label>
        <p-calendar id="popup" placeholder="MM-DD-YYYY" dateFormat="mm-dd-yy" [showIcon]="true"
          (keyup.enter)="DataSearch()" [showButtonBar]="true" formControlName="VAlidToDate">
        </p-calendar>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Document Type</label>
        <p-dropdown [options]="documenttype" placeholder="Select Document Type" optionLabel="label"
          (keyup.enter)="DataSearch()" formControlName="DocumentType" [showClear]="true"></p-dropdown>
      </div>
    </div>
    <br>
    <div class="btnsCSS1">
      <button pButton type="button" icon='pi pi-plus' label="Add" (click)="showDailog()" class="addhBtn"></button>
      <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="resetData()" class="addhBtn"></button>
      <button pButton type="button" icon='pi pi-search' label="Search" (click)="DataSearch()" class="addhBtn"></button>
    </div><br><br>
  </form>
</p-card>
<br>
<p-card class="pgpos">
  <div *ngIf="isUploading1" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
    <p-progressBar mode="indeterminate"></p-progressBar>
  </div>
  <div *ngIf="table">
    <p-table [columns]="cols" [value]="attachmentsGriddata" selectionMode="single" dataKey="edit" [rows]="10"
      [responsive]="true" sortMode="multiple">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th id="gridHeader" *ngFor="let col of columns; let i=index" (click)="iconToggle[i] = !iconToggle[i]"
            [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td class="textCenter skill-desc" tooltipPosition="top" pTooltip="{{data.title}}"><span class="ui-column-title"></span>
            {{data.title}}</td>
          <td class="textCenter"><span class="ui-column-title"></span>{{
            docTable[data.lkAttachmentType]}}</td>
          <td class='textCenter'><span class="ui-column-title">
            </span>{{data.attachmentUploadedDate | date:'MM-dd-yyyy'}}</td>
          <td class='textCenter'><span class="ui-column-title"></span> {{data.attachmentExpiry ===
            "1999-01-01T00:00:00.000+0000"||data.attachmentExpiry === ''||data.attachmentExpiry ===
            null?"--":data.attachmentExpiry |date:'MM-dd-yyyy'}}
          </td>
          <td class="textCenter"><span class="ui-column-title"></span>{{data.attachmentUploadedBy}}</td>
          <td class="textCenter actionclr"><span class="ui-column-title"></span><em type='button'
              (click)="actionpopup.toggle($event); toggle($event, data)" class="pi pi-ellipsis-v pointer"></em>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr *ngIf="attachmentsGriddata?.length==0">
          <td colspan="6" class="mandatoryColor" aria-colcount="aligncenter">
            No Records
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
        <h3 class="norecorddata">Total records : {{this.totalrecords}}</h3>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
        <p-paginator [rows]="10" [totalRecords]="totalrecords" [rowsPerPageOptions]="[10,20,30]"
          (onPageChange)="paginate($event)"></p-paginator>
      </div>
    </div>
  </div>
  <p-card *ngIf="records">
    <div class="norecordImgsize">
      <div *ngIf="records" class="aligncenter">
        <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
        <br><em>Sorry, we are not able to find any attachments</em><br>
      </div>
    </div>
  </p-card>
</p-card>
<!-- It is used to show add attachment pop up -->
<p-dialog class="dialogBox" header="Add Attachment" [(visible)]="display" [modal]="true" [style]="{width: '25%'}"
  [baseZIndex]="1" [draggable]="false" [resizable]="false" [contentStyle]="{'overflow': 'visible'}">
  <form [formGroup]="vendorAttachmentsDailogForm">
    <div class="p-grid p-fluid">
      <div class="p-col-8 p-md-8 p-sm-12">
        <p class="mandatoryColor">{{errorMsg}}</p>
      </div>
    </div>
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <label class="inputfields">Name<span class="mandatoryColor">*</span></label>
        <input id="input" type="text" autocomplete="off" pInputText placeholder="Name"
          formControlName="attachmentNameDailog" maxlength="41">
        <div>
          <span class="validationtest"
            *ngIf="this.vendorAttachmentsDailogForm.controls.attachmentNameDailog.invalid && this.vendorAttachmentsDailogForm.controls.attachmentNameDailog.errors?.pattern">Please enter
            alphabets only</span>
          <span class="validationtest"
            *ngIf="this.vendorAttachmentsDailogForm.get('attachmentNameDailog').touched && this.vendorAttachmentsDailogForm.get('attachmentNameDailog').invalid && this.vendorAttachmentsDailogForm.get('attachmentNameDailog').errors.required">
            Please enter name
          </span>
          <span class="validationtest"
            *ngIf="this.vendorAttachmentsDailogForm.get('attachmentNameDailog').hasError('maxlength')">
            Please enter 40 alphabets only
          </span>
        </div>
      </div>

      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 invalidMandatory">
        <label class="inputfields">Document Type<span class="mandatoryColor">*</span></label>
        <p-dropdown [options]="documenttype" formControlName="attachmentDocTypeDailog" optionLabel="label"
          placeholder="Select Document Type" [showClear]="true">
        </p-dropdown>
        <div>
          <span class="validationtest"
            *ngIf="this.vendorAttachmentsDailogForm.get('attachmentDocTypeDailog').touched && this.vendorAttachmentsDailogForm.get('attachmentDocTypeDailog').invalid && this.vendorAttachmentsDailogForm.get('attachmentDocTypeDailog').errors.required">
            Please select document type
          </span>
        </div>
      </div>

      <div class="p-col-12 p-md-12 p-sm-12  p-lg-12">
        <p-checkbox name="dateCheck" (onChange)="disableAddCheckboxValue($event)" binay=true
          label="This attachment does not expire" formControlName="dateCheck">
        </p-checkbox>
      </div>

      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 invalidMandatory" *ngIf="validityshow">
        <label class="inputfields">Validity</label>
        <p-calendar id="popup" [minDate]="startCurDate" placeholder="MM-DD-YYYY" dateFormat="mm-dd-yy" [showIcon]="true"
          [showButtonBar]="true" [minDate]="startCurDate" formControlName="attachmentValidityDailog">
        </p-calendar>
        <span *ngIf="valid" class="mandatoryColor">Please select date</span>
        <div>
          <span class="validationtest"
            *ngIf="this.vendorAttachmentsDailogForm.get('attachmentValidityDailog').touched && this.vendorAttachmentsDailogForm.get('attachmentValidityDailog').invalid && this.vendorAttachmentsDailogForm.get('attachmentValidityDailog').errors.required">
            Please select validity date</span>
        </div>
      </div>

      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12" *ngIf="show">
        <label class="inputfields">Expired Date</label>
        <input id="input" type="text" pInputText placeholder="No expiration date" disabled="true"
          (onChange)="disableCheckboxValue($event)" autocomplete="off" formControlName="organisationText"
          maxlength="30">
      </div>

      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <label class="inputfields">Comments</label>
        <textarea [rows]="2" [cols]="30" placeholder="Comments " pInputTextarea
          formControlName="attachmentCommentsDailog" autocomplete="off" maxlength="301"></textarea>
        <span class="validationtest"
          *ngIf="this.vendorAttachmentsDailogForm.get('attachmentCommentsDailog').hasError('maxlength')">
          Please enter 300 alphabets only
        </span>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <label class="inputfields">Upload Attachment<span class="mandatoryColor">*</span></label><br>
        <input *ngIf="classinput" id="attachment" type="file" (change)="onFileChange($event)" #file
          formControlName="uploadAttachment">
        <input *ngIf="classinput1" type="file" (change)="onFileChange($event)" id="attachment" #file
          formControlName="uploadAttachment">
        <em *ngIf="filesdatass" class="pi pi-times" style="cursor: pointer;" (click)="cancelfiles()"></em>
        <div class="validationtest"
          *ngIf="vendorAttachmentsDailogForm.get('uploadAttachment').touched && vendorAttachmentsDailogForm.get('uploadAttachment').invalid && vendorAttachmentsDailogForm.get('uploadAttachment').errors.required">
          Please upload attachment</div>
      </div>
    </div>
    <br>
    <div class="btnsCSS1 buttonalign">
      <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="resetDailog()" class="addhBtn"></button>
      <button pButton [disabled]="hide1" type="button" icon='pi pi-save' label="Save" (click)="createQP()"
        class="addhBtn"></button>
    </div><br><br>
  </form>
</p-dialog>
<!-- It is used to show edit attachment popup -->
<form [formGroup]="vendorAttachmentsEditDailogForm">
  <p-dialog class="dialogBox" header="Edit Attachment" [(visible)]="displayDailog" [modal]="true"
    [style]="{width: '25%'}" [baseZIndex]="1" [draggable]="false" [resizable]="false"
    [contentStyle]="{'overflow': 'visible'}">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12">
        <label class="inputfields">Name<label class="mandatoryColor">*</label></label>
        <input id="input" type="text" autocomplete="off" pInputText placeholder="Name" maxlength="41"
          formControlName="attachmentEditNameDailog">
        <div>
          <span class="validationtest" *ngIf="this.vendorAttachmentsEditDailogForm.get('attachmentEditNameDailog').touched &&
            this.vendorAttachmentsEditDailogForm.get('attachmentEditNameDailog').invalid &&
            this.vendorAttachmentsEditDailogForm.get('attachmentEditNameDailog').errors.required">
            Please enter name
          </span>
          <span class="validationtest"
          *ngIf="this.vendorAttachmentsEditDailogForm.controls.attachmentEditNameDailog.invalid && this.vendorAttachmentsEditDailogForm.controls.attachmentEditNameDailog.errors?.pattern">Please enter
          alphabets only</span>
          <span class="validationtest"
            *ngIf="this.vendorAttachmentsEditDailogForm.get('attachmentEditNameDailog').hasError('maxlength')">
            Please enter 40 alphabets only
          </span>
        </div>
      </div>

      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <p-checkbox name="dateCheck" formControlName="dateCheck" (onChange)="disableEditCheckboxValue($event)"
          binary="true" label="This attachment does not expire">
        </p-checkbox>
      </div>

      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 invalidMandatory" *ngIf="!show1">
        <label class="inputfields">Validity<label class="mandatoryColor">*</label></label>
        <p-calendar id="popup" placeholder="MM-DD-YYYY" [minDate]="startCurDate" dateFormat="mm-dd-yy" [showIcon]="true"
          [showButtonBar]="true" formControlName="attachmentEditValidityDailog">
        </p-calendar>
        <div>
          <span class="validationtest"
            *ngIf="this.vendorAttachmentsEditDailogForm.get('attachmentEditValidityDailog').touched && this.vendorAttachmentsEditDailogForm.get('attachmentEditValidityDailog').invalid && this.vendorAttachmentsEditDailogForm.get('attachmentEditValidityDailog').errors.required">
            Please select validity date
          </span>
          <span *ngIf="displayvalid" class="mandatoryColor">Please select date</span>
        </div>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12" *ngIf="show1">
        <label class="inputfields">Expired Date</label>
        <input id="input" type="text" pInputText placeholder="No expiration date" disabled="true"
          (onChange)="disableCheckboxValue1($event)" autocomplete="off" formControlName="organisationText"
          maxlength="30">
      </div>

      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <label class="inputfields">Comments</label>
        <textarea [rows]="5" [cols]="30" placeholder="Comments " pInputTextarea
          formControlName="attachmentEditCommentsDailog" autocomplete="off" [rows]="2" [cols]="30"
          maxlength="301"></textarea>
          <span class="validationtest"
            *ngIf="this.vendorAttachmentsEditDailogForm.get('attachmentEditCommentsDailog').hasError('maxlength')">
            Please enter 300 alphabets only
          </span>
      </div>
    </div>
    <div class="btnsCSS1 buttonralign">
      <button pButton [disabled]="hide" type="button" icon='pi pi-save' label="Update" (click)="updataeDailog()"
        class="addhBtn"></button>&nbsp;
    </div><br><br>
  </p-dialog>
</form>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center laoderheight">
        <div class="laodersize">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
            class="loaderimg-height">
        </div>
      </div>
    </div>
  </div>
</ng-template>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>