<p-toast position="bottom-right" key="br"></p-toast>
<form [formGroup]="customerScreen">
	<p-card>
		<div class="p-grid p-fluid">

			<div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
				<label class="inputfields">Country</label>
				<p-dropdown [options]="countryOptions" formControlName="country" placeholder="Select Country"
					optionLabel="label" [showClear]="true" (onChange)="getStateByUrl($event.value.code)">
				</p-dropdown>
			</div>
			<div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
				<label class="inputfields">Region</label>
				<p-dropdown [options]="regionOptions" placeholder="Select Region" optionLabel="label"
					formControlName='region' [showClear]="true"></p-dropdown>
			</div>
			<div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
				<label class="inputfields">State</label>
				<p-dropdown [options]="stateoptions" formControlName="state" placeholder="Select State"
					optionLabel="label" [showClear]="true"></p-dropdown>
			</div>
		</div>
		<br>
		<div class="btnsCSS1">
			<button pButton type="button" icon='pi pi-plus' label="Add" (click)="addlocation()" class="btnstyling"
				class="addhBtn"></button>
			<button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" class="btnstyling"
				class="addhBtn"></button>
			<button pButton type="button" icon='pi pi-search' label="Search" (click)="searchData()"
				class="addhBtn"></button>
		</div>
		<br><br>
	</p-card>
</form>
<br>
<p-card class="pgpos">
	<div *ngIf="table">
		<p-table [columns]="cols" [value]="customerGrid" [rows]="10" selectionMode="single" dataKey="edit"
			[responsive]="true" sortMode="multiple">
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
						(click)="iconToggle[i] = !iconToggle[i]" [ngStyle]="{'width': col.width }"
						[pSortableColumn]="col.field">
						<span>
							{{col.header}}

						</span>
					</th>
				</tr>
			</ng-template>

			<ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
				<tr>
					<td class="textCenter"><span class="ui-column-title"></span>{{+this.page === 1 ? rowIndex +1 :
						(+(this.page -1)*this.rows) + (rowIndex +1)}}</td>
					<td>
						{{data.accName}}
					</td>
					<td><a class="pointer" (click)="goToLink(data.website)">{{data.website}}</a></td>
					<td>{{contryTable[data.country]}}</td>
					<td>{{getregionTable[data.lkRegion]}}</td>
					<td> <a class="pi pi-ellipsis-v pointer"
							(click)="data.lkStatus !== 'R' ? actionpopup.toggle($event) : ''"
							(click)="toggle(items, $event, data)"
							[ngClass]="(data.lkStatus !== 'R' )?'my-class2':'my-class'">
						</a>
					</td>
				</tr>
			</ng-template>
		</p-table>
		<div class="p-grid ui-fluid">
			<div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
				<h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
			</div>
			<div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
				<p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
					(onPageChange)="paginate($event)"></p-paginator>
			</div>
		</div>
	</div>
	<p-dialog [contentStyle]="{'overflow': 'visible'}" class="dialogBox" header="Add Customer"
		[(visible)]="displayModal" [modal]="true" [style]="{width: '55%'}" [baseZIndex]="1" [draggable]="false"
		[resizable]="false">
		<form [formGroup]="registrationForm">
			<div class="p-grid p-fluid ">
				<div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
					<h5 class="panelLabel">Customer Information</h5>
					<div class="p-grid p-fluid ippos">
						<div class="p-col-12 p-md-12 p-sm-12 p-lg-4 ">
							<label class="inputfields">Company<label class="mandatoryColor">*</label></label>
							<span class="p-input-icon-right"><em *ngIf="spinnerHide"
									class="pi pi-spin pi-spinner spinnerstyles"></em>
								<em *ngIf="checkHide" class="pi pi-check-circle tickmarkstyle"></em>
								<input autocomplete="off" id="input" type="text" pInputText placeholder="Company Name"
									maxlength="61" formControlName="customerName" (blur)="officeCheck()"></span>
							<span class="validationtest"
								*ngIf="registrationForm.get('customerName').touched && registrationForm.get('customerName').invalid && registrationForm.get('customerName').errors.required">
								Please enter customer name </span>
							<span class="validationtest"
								*ngIf="this.registrationForm.get('customerName').hasError('maxlength')">
								Please enter 60 alphabets only
							</span>
							<span class="validationtest"
								*ngIf="this.registrationForm.get('customerName').hasError('pattern')">
								Please enter alphabets only
							</span>
						</div>
						<div class="p-col-12 p-md-12 p-sm-12 p-lg-4 ">
							<label class="inputfields">Corporate Website<label class="mandatoryColor">*</label></label>
							<input autocomplete="off" id="input" type="text" pInputText placeholder="Corporate Website"
								(blur)="urlvalidation()" maxlength="61" formControlName="websiteURL">
							<span class="validationtest"
								*ngIf="registrationForm.get('websiteURL').touched && registrationForm.get('websiteURL').invalid && registrationForm.get('websiteURL').errors.required">
								Please enter corporate website</span>
							<div>
								<span class="validationtest"
									*ngIf="this.registrationForm.get('websiteURL').hasError('maxlength')">
									Please enter 60 alphabets only
								</span>
							</div>
						</div>
						<div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
							<label class="inputfields">No.Of Employees</label>
							<p-dropdown [options]="EmployeeData" formControlName="NumOfEmployees" [showClear]="true"
								placeholder="Select No.Of Employees" optionLabel="label"></p-dropdown>

						</div>
					</div>
					<h5 class="panelLabel">Customer Admin Details</h5>
					<div class="p-grid p-fluid ippos">
						<div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
							<label class="inputfields">First Name<label class="mandatoryColor">*</label></label>
							<input autocomplete="off" id="input" type="text" pInputText placeholder="First Name"
								maxlength="26" formControlName="firstName">
							<span class="validationtest"
								*ngIf="registrationForm.get('firstName').touched && registrationForm.get('firstName').invalid && registrationForm.get('firstName').errors.required">
								Please enter first name</span>
							<span class="validationtest"
								*ngIf="this.registrationForm.get('firstName').hasError('maxlength')">
								Please enter 25 alphabets only
							</span>
						</div>
						<div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
							<label class="inputfields">Middle Name </label>
							<input autocomplete="off" id="input" type="text" pInputText placeholder="Middle Name"
								maxlength="16" formControlName="middleName">
							<span class="validationtest"
								*ngIf="this.registrationForm.get('middleName').hasError('maxlength')">
								Please enter 15 alphabets only
							</span>
						</div>
						<div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
							<label class="inputfields">Last Name<label class="mandatoryColor">*</label></label>
							<input autocomplete="off" id="input" type="text" pInputText placeholder="Last Name"
								maxlength="26" formControlName="lastName">
							<span class="validationtest"
								*ngIf="registrationForm.get('lastName').touched && registrationForm.get('lastName').invalid && registrationForm.get('lastName').errors.required">
								Please enter last name</span>
							<span class="validationtest"
								*ngIf="this.registrationForm.get('lastName').hasError('maxlength')">
								Please enter 25 alphabets only
							</span>
						</div>

						<div class="p-col-12 p-md-12 p-sm-12 p-lg-4 invalidMandatory">
							<label class="inputfields">Office Phone<label class="mandatoryColor">*</label></label>
							<div class="p-inputgroup">
								<span class="p-inputgroup-addon">
									<p-dropdown class="phonecodestyle newcode" [options]="countrycodedropdown"
										(onChange)="countryids($event)" filterPlaceholder='Search Results'
										optionLabel="label" formControlName="mobileDrp">
										<ng-template let-items pTemplate="selectedItem">
											<div class="flex-row">
												<img class="lang-change-img" src={{items.flag}} alt="" />
												<div class="lang-change-option-text selected-lang center">{{items.code}}
												</div>
											</div>
										</ng-template>
										<ng-template let-country pTemplate="item">
											<div class="flex-row" id="dropdown1">
												<img class="lang-change-img" src={{country.flag}} alt="" />
												<div class="lang-change-option-text center">{{country.label}}
													{{country.code}}</div>
											</div>
										</ng-template>
									</p-dropdown>
								</span>
								<div class="spinner">
									<span class="p-input-icon-right"><em *ngIf="phSpinnerHide"
											class="pi pi-spin pi-spinner spinnerstyles"></em>
										<em *ngIf="phCheckHide" class="pi pi-check-circle tickmarkstyle"></em>
										<p-inputMask autocomplete="off" mask="(999) 999-9999" (onBlur)="getPhon()"
											maxlength="16" formControlName="officePhone" placeholder="Office Phone">
										</p-inputMask>
									</span>
								</div>
							</div>

							<span class="validationtest"
								*ngIf="registrationForm.get('officePhone').touched && registrationForm.get('officePhone').invalid && registrationForm.get('officePhone').errors.required">
								Please enter office phone</span>
							<p class="mandatoryColor">{{this.officenummsg}}</p>
						</div>
						<div class="p-col-12 p-md-12 p-sm-12 p-lg-4 invalidMandatory">
							<label class="inputfields">Office Email<label class="mandatoryColor">*</label></label>
							<span class="p-input-icon-right"><em *ngIf="mailSpinnerHide"
									class="pi pi-spin pi-spinner mail"></em>
								<em *ngIf="mailCheckHide" class="pi pi-check-circle spcolor"></em>
							</span>
							<div class="p-inputgroup">
								<input autocomplete="off" id="input" type="text" maxlength="61" pInputText
									placeholder="Office Email" formControlName="officeEmail"
									(blur)="officeEmailExtensionCheck()" #userInput>

								<span class="p-inputgroup-addon"> <em class="pi pi-copy pointer" (click)="emailToast()"
										(click)="copyInputMessage(userInput.value)"></em>
								</span>
							</div>
							<span class="validationtest"
								*ngIf="registrationForm.get('officeEmail').touched && registrationForm.get('officeEmail').invalid && registrationForm.get('officeEmail').errors.required">
								Please enter office email</span>
							<span class="validationtest"
								*ngIf="registrationForm.controls.officeEmail.invalid && registrationForm.controls.officeEmail.errors.pattern">
								Must be valid corp email
							</span>
							<span class="validationtest"
								*ngIf="this.registrationForm.get('officeEmail').hasError('maxlength')">
								Please enter 60 alphabets only
							</span>
							<p class="mandatoryColor">{{ofcMailValidation}}</p>
						</div>
						<div class="p-col-12 p-md-12 p-sm-12 p-lg-4  ui-fluid invalidMandatory">
							<label class="inputfields">Gender</label>
							<p-dropdown [options]="gender1" formControlName="gender" optionLabel="name"
								[showClear]="true" placeholder="Select Gender">
							</p-dropdown>
						</div>


						<div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
							<label class="inputfields">Address Line 1</label>
							<input autocomplete="off" id="input" type="text" pInputText placeholder="Address Line 1"
								maxlength="51" formControlName="address1">
							<span class="validationtest"
								*ngIf="this.registrationForm.get('address1').hasError('maxlength')">
								Please enter 50 alphabets only
							</span>
						</div>
						<div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
							<label class="inputfields">Address Line 2</label>
							<input autocomplete="off" id="input" type="text" pInputText placeholder="Address Line 2"
								maxlength="51" formControlName="address2">
							<span class="validationtest"
								*ngIf="this.registrationForm.get('address2').hasError('maxlength')">
								Please enter 50 alphabets only
							</span>
						</div>

						<div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
							<label class="inputfields">Mailbox</label>
							<input type="text" autocomplete="off" maxlength="11" pInputText placeholder="Mailbox"
								formControlName="mailbox">
							<span class="validationtest"
								*ngIf="this.registrationForm.get('mailbox').hasError('maxlength')">
								Please enter 10 alphabets only
							</span>
							<span class="validationtest"
								*ngIf="this.registrationForm.get('mailbox').invalid && this.registrationForm.get('mailbox').errors.pattern">
								Please enter numbers only
							</span>
						</div>
						<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
							<label class="inputfields">Country<label class="mandatoryColor">*</label></label>
							<p-dropdown [options]="countryOptions" formControlName="rcountry" [showClear]="true"
								placeholder="Select Country" optionLabel="label"
								(onChange)='getStateByUrl($event.value.code)'>
							</p-dropdown>
							<span class="validationtest"
								*ngIf="registrationForm.get('rcountry').touched && registrationForm.get('rcountry').invalid && registrationForm.get('rcountry').errors.required">
								Please select country</span>
						</div>
						<div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
							<label class="inputfields">State</label>
							<p-dropdown [options]="stateoptions" formControlName="rstate" placeholder="Select State"
								[showClear]="true" optionLabel="label"></p-dropdown>
						</div>

						<div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
							<label class="inputfields">City</label>
							<input autocomplete="off" id="input" type="text" pInputText placeholder="City"
								maxlength="21" formControlName="city">
							<span class="validationtest"
								*ngIf="this.registrationForm.get('city').hasError('maxlength')">
								Please enter 20 alphabets only
							</span>
						</div>
					</div>
					<div class="p-grid p-fluid ">
						<div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
							<label class="inputfields">Zip</label>
							<input autocomplete="off" id="input" type="text" pInputText placeholder="Zip"
								formControlName="zipcode" maxlength="11">
							<span class="validationtest"
								*ngIf="this.registrationForm.get('zipcode').hasError('maxlength')">
								Please enter 10 alphabets only
							</span>
							<span class="validationtest"
								*ngIf="this.registrationForm.get('zipcode').invalid && this.registrationForm.get('zipcode').errors.pattern">
								Please enter numbers only
							</span>
						</div>
						<div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
							<label class="inputfields">Source<label class="mandatoryColor">*</label></label>
							<p-dropdown [options]="sourcedata" formControlName="source" placeholder="Select Source"
								[showClear]="true" optionLabel="label">
							</p-dropdown>
							<span class="validationtest"
								*ngIf="this.registrationForm.get('source').touched && registrationForm.get('source').invalid && registrationForm.get('source').errors.required">
								Please select source
							</span>
						</div>
					</div>

				</div>
			</div>
			<div class="btnsCSS1 btnstyle">
				<button pButton type="button" icon='pi pi-refresh' label="Reset" class="addhBtn btnstyling"
					(click)="reset1()"></button>
				<button pButton type="button" icon='pi pi-save' [disabled]="disable" label="Save" class="addhBtn"
					(click)="register()"></button>
			</div>
		</form>
	</p-dialog>

	<p-card *ngIf="totalRecords === 0">
		<div class="norecordImgsize">
			<div class="aligncenter">
				<img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
				<br><em>Sorry, we are not able to find any customers</em><br>
			</div>
		</div>
	</p-card>
</p-card>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
	<div class="loading-bar">
		<div>
			<div class="image-holder" class="col-sm-12 text-center laoderheight">
				<div class="laodersize">
					<img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
						class="loaderimg-height">
				</div>
			</div>
		</div>
	</div>
</ng-template>