import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApisService } from 'src/app/services/apis.service';

@Component({
    selector: 'app-admin-menu',
    templateUrl: './admin-menu.component.html',
    styleUrls: ['./admin-menu.component.scss'],
    providers: [DatePipe, MessageService],
})
export class AdminMenuComponent implements OnInit {

    selected: any = 'Vendor-details';

    accountid = sessionStorage.getItem('account_id');
    rowId = sessionStorage.getItem('acct_rowid');
    dropDownValue: any;

    accountId: string;
    accName: string;
    empflag: any;
    acctid: any;
    acctdetails: any;
    salesflag: any;
    constructor(private router: Router, private route: ActivatedRoute, private apisService: ApisService) {
        const routeParams: any = this.route.snapshot.queryParamMap;
        this.empflag = routeParams.params.empflag;
        this.salesflag = routeParams.params.flag;
        this.acctid = JSON.parse(apisService.decodeData()).userId;
        this.acctdetails = JSON.parse(this.apisService.decodeData()).accName;
        this.accName = sessionStorage.getItem('account_Name');
        if (
            JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 2 ||
            JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 8 ||
            JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 13
        ) {
            this.accountid = JSON.parse(sessionStorage.getItem('loginData')).orgId;
        } else {
            this.accountid = this.rowId;
        }

    }
    ngOnInit() {
        this.dropDownValue = [
            {
                label: 'Account Details',
                command: () => {
                        this.router.navigate(['/admin-menu/account-details', this.accountid]); }

            },
            {
                label: 'Contacts',
                command: () => {
                        this.router.navigate(['/admin-menu/account-contacts', this.accountid]);

                }
            },
            {
                label: 'Attachments',
                command: () => {
                        this.router.navigate(['/admin-menu/account-attachments', this.accountid]);
                }
            },
            {
                label: 'Locations',
                command: () => {

                        this.router.navigate(['/admin-menu/account-locations', this.accountid]);
                }
            },
        ];
    }
}
