<form [formGroup]="dashboardForm">
    <p-card>
        <!-- <p-accordion>
            <p-accordionTab header="Dashboard" [selected]="false"> -->
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">From Date</label>
                        <p-calendar id="popup" placeholder="Select Date" [showIcon]="true" [showButtonBar]="true"
                            formControlName="fromDateForm">
                        </p-calendar>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">To Date</label>
                        <p-calendar id="popup" placeholder="Select Date" [showIcon]="true" [showButtonBar]="true"
                            formControlName="toDateForm">
                        </p-calendar>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <div class="btnsCSS1">
                            <button pButton type="button" icon='pi pi-search' label="Search" (click)="pieChartRes()"
                                class="alignsearch"></button>
                        </div>
                    </div>
                    <br><br>
                </div>
            <!-- </p-accordionTab>
        </p-accordion> -->
    </p-card>
</form>
<br>
<div class="p-grid p-fluid">
    <!-- <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
    <p-card>
    <p-chart type="bar" [data]="barData"></p-chart>
    </p-card>
    </div> -->
    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
        <p-card>
            <span></span>
            <p-chart type="bar" [data]="barData" [options]="chartOptions"></p-chart>
        </p-card>
    </div>
</div>

<!-- <div class="p-col-12 p-md-12 p-sm-12 p-lg-12"></div>
   <br>
   
   <div class="p-grid p-justify-center">
    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ui-fluid">
    <p-card>
    <p-chart type="pie" [data]="data1"></p-chart>
    </p-card>
    </div>
   </div>
   <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
    <span class="ui-column-title download">
    <em class="pi pi-download dwnldspac"></em>
    <strong>Download</strong></span>
   </div>
   <p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="downloadOptions"></p-menu> -->