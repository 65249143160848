<form [formGroup]="customerScreen">
    <p-card>
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                        <label class="inputfields"> Name</label>
                        <input id="input" autocomplete="off" type="text" (keyup.enter)="searchData()" pInputText placeholder="Name"
                            size="30" maxlength="50" formControlName="customerName">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                        <label class="inputfields"> Industry</label>
                        <p-dropdown [options]="industryOptions" (keyup.enter)="searchData()"
                        [showClear]="true" placeholder="Select Industry" optionValue="code" optionLabel="label" size="30"
                            formControlName="customerIndustry"></p-dropdown>
                    </div>
                </div>
                <div class="btnsCSS1">
                    <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()"
                        class="addhBtn"></button>
                    <button pButton type="button" icon='pi pi-search' label="Search" (click)="searchData()"
                        class="addhBtn"></button>
                </div>
    </p-card>
</form>
<br>
<p-card class="pgpos">
    <div *ngIf="table">
        <p-table [columns]="cols" [value]="customerGrid" [rows]="10" selectionMode="single" dataKey="edit"
           [autoLayout]="true" [responsive]="true" sortMode="multiple">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        (click)="iconToggle[i] = !iconToggle[i]" [ngStyle]="{'width': col.width }"
                        [pSortableColumn]="col.field" [autoLayout]="true">
                        <span>
                            {{col.header}}

                        </span>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
                <tr>
                    <td class="textCenter"><span class="ui-column-title"></span>{{+this.page === 1 ? rowIndex +1 :
                        (+(this.page -1)*this.rows) + (rowIndex +1)}}</td>
                    <td>
                        {{data.cusName}}
                    </td>
                    <td><a class="pointer" (click)="goToLink(data.website)">{{data.website}}</a></td>
                    <td>{{getIndustryTable[data.lkIndustryType]}}</td>
                    <td> <a class="pi pi-ellipsis-v pointer" 
                        (click)="actionpopup.toggle($event); toggle(items, $event, data)"
                       ></a></td>
                </tr>
            </ng-template>


        </p-table>

        <div class="p-grid ui-fluid">

            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>

<p-card *ngIf="totalRecords === 0">
    <div class="norecordImgsize">
        <div class="aligncenter">
            <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
            <br><em>Sorry, we are not able to find any customers</em><br>
        </div>
    </div>
</p-card>
</p-card>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight " >
                <div class="laodersize" >
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif" class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>