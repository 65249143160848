<p-card>
    <form [formGroup]="departmentsForm">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-3 p-lg-3">
                <label class="inputfields">Department Number</label>
                <p-inputNumber mode="decimal" formControlName="departmentNumber" inputId="withoutgrouping" autocomplete="off"
                    [useGrouping]="false" (keyup.enter)="gridTable()" placeholder="Department Number" maxlength="11">
                </p-inputNumber>
            </div>
            <div class="p-field p-col-12 p-md-3 p-lg-3">
                <label class="inputfields">Department Name</label>
                <input id="input" type="text" pInputText placeholder="Department Name" autocomplete="off"
                    (keyup.enter)="gridTable()" formControlName="departmentName" maxlength="30">
            </div>
        </div>
        <div class="btnsCSS1 buttonsalign">
            <p-splitButton label="Import" [model]="items1" class="addhBtn "></p-splitButton>
            <button pButton type="button" icon='pi pi-plus' (click)="addDepartment()" label="Add"
                class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-search' label="Search" (click)="gridTable()"
                class="addhBtn"></button>
        </div>
        <div *ngIf="isUploading" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <p-progressBar mode="indeterminate"></p-progressBar>
        </div><br>
        <div *ngIf="showUploadView" class="dropzone p-col-12 p-md-12 p-sm-12 p-lg-3">
            <em class="pi pi-upload uploadIcon Iconsize" aria-hidden="true"></em>
            <input accept=".xlsm,.xlsx,application/msexcel" type="file" class="fileInput" #fileDropRef id="fileDropRef"
                multiple (change)="onUpload($event.target.files)" style="cursor: pointer;" />
            <h3>Drag and drop file here</h3>
            <h3 class="importstylin">or</h3>
            <label id="fileUpload" for="fileDropRef">Browse for files</label>
        </div>
    </form>
</p-card>
<br>
<p-card class="pgpos">
    <div *ngIf="table">
        <!--It is used for displaying the department table-->
        <p-table [value]="gridData" [columns]="cols" selectionMode="single" dataKey="edit" [responsive]="true"
            sortMode="multiple" [rows]="10">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                        <span>
                            {{col.header}}

                        </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
                <tr>
                    <td class="skill-desc" pTooltip="{{data.name}}" tooltipPosition="top"><span class="p-column-title">
                            Dept.Name</span>{{data.name}}</td>
                    <td class="textCenter"><span class="p-column-title">
                            Dept.No</span>{{data.deptNo}}</td>
                    <td class="textCenter"><span class="p-column-title">
                            Created By</span>{{data.createdBy}}</td>
                    <td><span class="ui-column-title"></span>
                        <p-badge [value]="this.statuslist[data.status]" [ngClass]="data.status==='A'?'green': 'red'">
                        </p-badge>
                    </td>
                    <td class="textCenter"><span class="p-column-title">
                            Action</span><a (click)="downloadActionPopup.toggle($event); toggle($event,'data', data)"
                            class="pi pi-ellipsis-v pointer"></a>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10 ">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any departments</em><br>
            </div>
        </div>
    </p-card>
</p-card>
<form [formGroup]="departmentAddForm">
    <!-- It is used for add department pop up -->
    <p-dialog header="Add Department" [(visible)]="displayModal" [modal]="true" [draggable]="false" [resizable]="false" [style]="{width: '25%'}">
        <div class="p-fluid p-grid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Department Name<span class="mandatoryColor">*</span></label>
                <input id="input" type="text" pInputText placeholder="Department Name" autocomplete="off"
                    formControlName="addDepartmentName" maxlength="31">
                <div>
                    <span class="validationtest"
                        *ngIf="this.departmentAddForm.get('addDepartmentName').touched && this.departmentAddForm.get('addDepartmentName').invalid && this.departmentAddForm.get('addDepartmentName').errors.required">
                        Please enter department name
                    </span>
                    <span class="validationtest"
                        *ngIf="this.departmentAddForm.get('addDepartmentName').hasError('maxlength')">
                        Please enter 30 alphabets only
                    </span>
                    <span class="validationtest"
                        *ngIf="this.departmentAddForm.get('addDepartmentName').invalid && this.departmentAddForm.get('addDepartmentName').errors.pattern">
                        Please enter alphabets only
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Department Number<span class="mandatoryColor">*</span></label>
                <input id="input" autocomplete="off" pKeyFilter="num" size="30" formControlName="addDepartmentNumber" pInputText
                placeholder="Department Number" maxlength="13">
                    <span class="validationtest"
                        *ngIf="this.departmentAddForm.get('addDepartmentNumber').touched && this.departmentAddForm.get('addDepartmentNumber').invalid && this.departmentAddForm.get('addDepartmentNumber').errors.required">
                        Please enter department number
                    </span>
                    <span class="validationtest"
                    *ngIf="this.departmentAddForm.get('addDepartmentNumber').invalid && this.departmentAddForm.get('addDepartmentNumber').errors.pattern">
                    Please enter numbers only
                </span>
                    <span class="validationtest"
                    *ngIf="this.departmentAddForm.get('addDepartmentNumber').hasError('maxlength')">
                    Please enter 12 numbers only
                </span>
            </div>
        </div>
        <br>
        <div class="btnsCSS1 buttonalignment">
            <button pButton type="button" icon='pi pi-times' label="Cancel" (click)="addCancel()"
                class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-save' label="Save" class="addhBtn " (click)="saveDailog1()"
                [disabled]="subbtn"></button>
        </div><br><br>
    </p-dialog>
</form>
<form [formGroup]="editDepartments">
    <!-- It is used edit department pop up -->
    <p-dialog class="dialogBox" header="Edit Department" [(visible)]="displayModal1" [modal]="true" [baseZIndex]="1" [style]="{width: '25%'}"
        [draggable]="false" [resizable]="false">
        <div class="p-fluid p-grid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Department Name<span class="mandatoryColor">*</span></label>
                <input id="input" type="text" pInputText placeholder="Department Name" autocomplete="off"
                    formControlName="editDepartmentName" maxlength="31">
                <div>
                    <span class="validationtest"
                        *ngIf="this.editDepartments.get('editDepartmentName').touched && this.editDepartments.get('editDepartmentName').invalid && this.editDepartments.get('editDepartmentName').errors.required">
                        Please enter department name
                    </span>
                    <span class="validationtest"
                        *ngIf="this.editDepartments.get('editDepartmentName').hasError('maxlength')">
                        Please enter 30 alphabets only
                    </span>
                    <span class="validationtest"
                        *ngIf="this.editDepartments.get('editDepartmentName').invalid && this.editDepartments.get('editDepartmentName').errors.pattern">
                        Please enter alphabets only
                    </span>
                </div>
            </div> 
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Department Number<span class="mandatoryColor">*</span></label>
                <input id="input" autocomplete="off" pKeyFilter="num" size="30" formControlName="editDepartmentNum" pInputText
                placeholder="Department Number" maxlength="13">
                    <span class="validationtest"
                        *ngIf="this.editDepartments.get('editDepartmentNum').touched && this.editDepartments.get('editDepartmentNum').invalid && this.editDepartments.get('editDepartmentNum').errors.required">
                        Please enter department number
                    </span>
                    <span class="validationtest"
                    *ngIf="this.editDepartments.get('editDepartmentNum').invalid && this.editDepartments.get('editDepartmentNum').errors.pattern">
                    Please enter numbers only
                </span>
                    <span class="validationtest"
                        *ngIf="this.editDepartments.get('editDepartmentNum').hasError('maxlength')">
                        Please enter 12 numbers only
                    </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Status<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="status" placeholder="Select Status" optionLabel="name" autocomplete="off"
                    formControlName="editDepartmentStatus" [showClear]="true">
                </p-dropdown>
                <div>
                    <span class="validationtest"
                        *ngIf="this.editDepartments.get('editDepartmentStatus').touched && this.editDepartments.get('editDepartmentStatus').invalid && this.editDepartments.get('editDepartmentStatus').errors.required">
                        Please select status
                    </span>
                </div>
            </div>
        </div><br>
        <div class="btnsCSS1 buttonaligns">
            <button pButton type="button" icon='pi pi-times' label="Cancel" (click)="editCancel()"
                class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-save' label="Update" (click)="editSave()" [disabled]="subbtn"
                class="addhBtn"></button>
        </div><br><br>
    </p-dialog>
</form>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="items"></p-menu>
<p-toast position="bottom-right" key="br"></p-toast>