<form [formGroup]="titleForm">
    <p-card>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Title</label>
                <input id="input" type="text" pInputText placeholder="Title" autocomplete="off" formControlName="title"
                    maxlength="30">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Assigned To</label>
                <p-dropdown [options]="employeeList" placeholder="Select Assigned To" optionLabel="name"
                    [showClear]="true" autocomplete="off" formControlName="assignedTo">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Assigned Date</label>
                <p-calendar id="popup" placeholder="MM-DD-YYYY" dateFormat="mm-dd-yy" [showIcon]="true"
                    [showButtonBar]="true" formControlName="assignedDate">
                </p-calendar>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Group Name</label>
                <p-dropdown [options]="groupsName" placeholder="Select Group Name" optionLabel="name" autocomplete="off"
                    [showClear]="true" formControlName="groupsname">
                </p-dropdown>
            </div>
        </div>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Duration</label>
                <p-dropdown [options]="durationData" placeholder="Select Duration" optionLabel="label"
                    [showClear]="true" autocomplete="off" formControlName="searchDuration">
                </p-dropdown>
            </div>
        </div>
        <div class="btnsCSS1 taskBtn">
            <button pButton type="button" icon='pi pi-plus' label="Add" (click)="addTask('A')" class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-refresh' label="Reset" class="addhBtn" (click)="reset()"></button>
            <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn"
                (click)="gridTable()"></button>
        </div>
    </p-card><br>
</form>
<p-card class="pgpos">
    <div *ngIf="isUploading1" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <p-progressBar mode="indeterminate"></p-progressBar>
    </div>
    <div *ngIf="table">
        <p-table [value]="gridData" [columns]="cols" selectionMode="single" dataKey="edit" [responsive]="true"
            sortMode="multiple">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                        <span>
                            {{col.header}}

                        </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-ro1wIndex="rowIndex" let-data>
                <tr>
                    <td class="skill-desc" tooltipPosition="top" pTooltip="{{data.title}}"
                        (dblclick)="updateData($event, data)" style="cursor: pointer;"><span class="p-column-title">
                        </span>{{data.title}}</td>
                    <td (dblclick)="updateData($event, data)" style="cursor: pointer;" class="textCenter"><span
                            class="p-column-title">
                        </span>{{data.assignedByName}}</td>
                    <td (dblclick)="updateData($event, data)" style="cursor: pointer;" class="textCenter"
                        [ngClass]="data.groupId === 0 ? '':'blue'"><span class="p-column-title">
                        </span>{{data.assignedToName}}</td>
                    <td (dblclick)="updateData($event, data)" style="cursor: pointer;" class="textCenter"><span
                            class="p-column-title">
                        </span>{{data.assignedDate | date:'MM-dd-yyyy'}}</td>
                    <td (dblclick)="updateData($event, data)" style="cursor: pointer;" class="textCenter"><span
                            class="p-column-title">
                        </span>{{data.targetDate | date:'MM-dd-yyyy'}}</td>
                    <td (dblclick)="updateData($event, data)" style="cursor: pointer;" class="textCenter"><span
                            class="ui-column-title"></span>
                        <p-badge [value]=" statustable[data.status]"
                            [ngClass]="data.status ==='C'?'green': data.status === 'P' ? 'red' : data.status === 'A' ? 'blue' :'evalution'">
                        </p-badge>
                    </td>
                    <td class="textCenter"><a (click)="actionpopup.toggle($event);toggle($event, data)"
                            class="pi pi-ellipsis-v pointer">
                        </a></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr *ngIf="gridData1?.length==0" class="aligncenter">
                    <td colspan="6" class="mandatoryColor">
                        No Records
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any tasks</em><br>
            </div>
        </div>
    </p-card>
</p-card>
<form [formGroup]="titleAddForm">
    <p-dialog class="dialogBox" header="{{this.headername}} " [(visible)]="displayModal" [modal]="true"
        [style]="{width: '50%'}" [contentStyle]="{'overflow': 'visible'}" [baseZIndex]="1" [draggable]="false"
        [resizable]="false">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Title<span class="mandatoryColor">*</span></label>
                <textarea type="text" pInputText placeholder="Title" formControlName="addTitle" autoResize="autoResize"
                    rows="2" maxlength="31"></textarea>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="titleAddForm.get('addTitle').touched && titleAddForm.get('addTitle').invalid && this.titleAddForm.get('addTitle').errors.required">
                    <p>Please enter title</p>
                </span>
                <span class="validationtest" *ngIf="this.titleAddForm.get('addTitle').hasError('maxlength')">
                    Please enter 30 alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">Task Category<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="taskCategory" placeholder="Select Task Category" optionLabel="label"
                    [showClear]="true" autocomplete="off" (onChange)="taskcategory($event)"
                    formControlName="taskCategory">
                </p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="titleAddForm.get('taskCategory').touched && titleAddForm.get('taskCategory').invalid">
                    <p>Please select task category</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">Task Source<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="tasksource" placeholder="Select Task Source" optionLabel="label"
                    [showClear]="true" autocomplete="off" formControlName="tasksource">
                </p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="titleAddForm.get('tasksource').touched && titleAddForm.get('tasksource').invalid">
                    <p>Please select task source</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">Task Type<span class="mandatoryColor">*</span></label>
                <p-dropdown placeholder="Select Task Type" [options]="tasktype" placeholder="Select Task Type"
                    [showClear]="true" optionLabel="label" autocomplete="off" formControlName="taskType">
                </p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="this.titleAddForm.get('taskType').touched && this.titleAddForm.get('taskType').invalid">
                    <p>Please select task type</p>
                </span>
            </div>
            <div *ngIf="this.groupOrIndiv" class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">Assign Type<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="groupOrIndividual" (onChange)="assigntod($event)" [showClear]="true"
                    placeholder="Select Assign Type" optionLabel="label" autocomplete="off"
                    formControlName="groupOrIndividual">
                </p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="titleAddForm.get('taskCategory').touched && titleAddForm.get('taskCategory').invalid">
                    <p>Please select assign type</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">Assigned To<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="employeeList" (onChange)="assigntomethod($event)" [showClear]="true"
                    placeholder="Select Assigned To" optionLabel="name" autocomplete="off" formControlName="assignedBy">
                </p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="titleAddForm.get('assignedBy').touched && titleAddForm.get('assignedBy').invalid">
                    <p>Please select assigned to</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4" *ngIf="addstatus">
                <label class="inputfields">Status<span class="mandatoryColor">*</span></label>
                <p-dropdown placeholder="Select status" [options]="assignto" placeholder="Select Task Status"
                    [showClear]="true" optionLabel="label" (onChange)="statusmethod($event)" autocomplete="off"
                    formControlName="assignStatus">
                </p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="this.titleAddForm.get('assignedBy').touched && this.titleAddForm.get('assignedBy').invalid">
                    <p>Please select status</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4" *ngIf="!addstatus">
                <label class="inputfields">Status<span class="mandatoryColor">*</span></label>
                <p-dropdown placeholder="Select status" [options]="taskstatus" placeholder="Select Task Status"
                    [showClear]="true" optionLabel="label" (onChange)="statusmethod($event)" autocomplete="off"
                    formControlName="assignStatus">
                </p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="this.titleAddForm.get('assignedBy').touched && this.titleAddForm.get('assignedBy').invalid">
                    <p>Please select status</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">Task Duration<span class="mandatoryColor">*</span></label>
                <p-dropdown placeholder="Select Task Duration" [options]="durationData" optionLabel="label"
                    [showClear]="true" autocomplete="off" formControlName="duration">
                </p-dropdown>
                <span class="validationtest"
                    *ngIf="this.titleAddForm.get('duration').touched && this.titleAddForm.get('duration').invalid && this.titleAddForm.get('duration').errors.required">
                    Please select task duration
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">Target Date</label>
                <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="targetDate" placeholder="MM-DD-YYYY"
                    [showIcon]="true" [showButtonBar]="true"></p-calendar>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">Remainder</label>
                <p-dropdown [options]="remainderData" placeholder="Select Remainder" optionLabel="label"
                    [showClear]="true" autocomplete="off" formControlName="remainder">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields"> Recurring Task</label>
                <p-dropdown placeholder="Select Recurring Task" [options]="recuringData" optionLabel="label"
                    [showClear]="true" (onChange)="recurring($event)" autocomplete="off" formControlName="recuringTask">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4" *ngIf="recuringDD">
                <label class="inputfields"> Recurring Duration </label>
                <p-dropdown placeholder="Select Recurring Duration" [options]="recuringDuration" optionLabel="label"
                    [showClear]="true" autocomplete="off" formControlName="recuringduration">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Description </label>
                <textarea type="text" pInputText placeholder="Description" formControlName="description"
                    autoResize="autoResize" rows="3" maxlength="201"></textarea>
                <span class="validationtest" *ngIf="this.titleAddForm.get('description').hasError('maxlength')">
                    Please enter 200 alphabets only
                </span>
            </div>
            <br>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Upload Attachment</label><br><input id="attachment" *ngIf="classinput"
                    type="file" accept=".doc,.docx,application/msword,application/pdf"
                    (change)="handleFileInput($event)" #file formControlName="addResumeForm">
                <input id="attachment" *ngIf="taskfiles" type="file"
                    accept=".doc,.docx,application/msword,application/pdf" (change)="handleFileInput($event)" #file
                    formControlName="addResumeForm">
                <em *ngIf="filesdatass" class="pi pi-times" style="cursor: pointer;" (click)="cancelfiles()"></em>
            </div>
        </div><br>
        <div class="btnsCSS1 btnAlgn">
            <button pButton type="button" icon='pi pi-times' label="Cancel" (click)="addCancel()"
                class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-save' label="Save" [disabled]="btndsb" (click)="SaveDailog1()"
                class="addhBtn"></button>&nbsp;
        </div><br><br>
    </p-dialog>
</form>
<form [formGroup]="titleUpdateForm">
    <p-dialog class="dialogBox" header="Edit Task " [(visible)]="displayModal1" [modal]="true" [style]="{width: '50%'}"
        [contentStyle]="{'overflow': 'visible'}" [baseZIndex]="1" [draggable]="false" [resizable]="false">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Title<span class="mandatoryColor">*</span></label>
                <textarea type="text" pInputText placeholder="Title" formControlName="editTitle" autoResize="autoResize"
                    rows="2" maxlength="31"></textarea>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="titleUpdateForm.get('editTitle').touched && titleUpdateForm.get('editTitle').invalid && this.titleUpdateForm.get('editTitle').errors.required">
                    <p>Please enter title</p>
                </span>
                <span class="validationtest" *ngIf="this.titleUpdateForm.get('editTitle').hasError('maxlength')">
                    Please enter 30 alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">Task Category<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="taskCategory" placeholder="Select Task Category" optionLabel="label"
                    [showClear]="true" autocomplete="off" (onChange)="taskcategory($event)"
                    formControlName="edittaskCategory">
                </p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="titleUpdateForm.get('edittaskCategory').touched && titleUpdateForm.get('edittaskCategory').invalid">
                    <p>Please select task category</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">Task Source<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="tasksource" placeholder="Select Task Source" optionLabel="label"
                    [showClear]="true" autocomplete="off" formControlName="edittasksource">
                </p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="titleUpdateForm.get('edittasksource').touched && titleUpdateForm.get('edittasksource').invalid">
                    <p>Please select task source</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">Task Type<span class="mandatoryColor">*</span></label>
                <p-dropdown placeholder="Select Task Type" [options]="tasktype" placeholder="Select Task Type"
                    [showClear]="true" optionLabel="label" autocomplete="off" formControlName="edittaskType">
                </p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="this.titleUpdateForm.get('edittaskType').touched && this.titleUpdateForm.get('edittaskType').invalid">
                    <p>Please select task type</p>
                </span>
            </div>
            <div *ngIf="this.groupOrIndiv" class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">Assign Type<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="groupOrIndividual" (onChange)="assigntod($event)" [showClear]="true"
                    placeholder="Select Assign Type" optionLabel="label" autocomplete="off"
                    formControlName="editgroupOrIndividual">
                </p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="titleUpdateForm.get('editgroupOrIndividual').touched && titleUpdateForm.get('editgroupOrIndividual').invalid">
                    <p>Please select assign type</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">Assigned To<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="employeeList" (onChange)="assigntomethod($event)" [showClear]="true"
                    placeholder="Select Assigned To" optionLabel="name" autocomplete="off"
                    formControlName="editassignedBy">
                </p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="titleUpdateForm.get('editassignedBy').touched && titleUpdateForm.get('editassignedBy').invalid">
                    <p>Please select assigned to</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">Status<span class="mandatoryColor">*</span></label>
                <p-dropdown placeholder="Select status" [options]="editstatus" placeholder="Select Task Status"
                    [showClear]="true" optionLabel="label" (onChange)="statusmethod($event)" autocomplete="off"
                    formControlName="editassignStatus">
                </p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="this.titleUpdateForm.get('editassignStatus').touched && this.titleUpdateForm.get('editassignStatus').invalid">
                    <p>Please select status</p>
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Remainder<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="remainderData" placeholder="Select Remainder" optionLabel="label"
                    [showClear]="true" autocomplete="off" formControlName="editremainder">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Task Duration<span class="mandatoryColor">*</span></label>
                <p-dropdown placeholder="Select Task Duration" [options]="durationData" optionLabel="label"
                    [showClear]="true" autocomplete="off" formControlName="editduration">
                </p-dropdown>
                <span class="validationtest"
                *ngIf="this.titleUpdateForm.get('editduration').touched && this.titleUpdateForm.get('editduration').invalid && this.titleUpdateForm.get('editduration').errors.required">
                Please select task duration
            </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">Target Date</label>
                <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="edittargetDate" placeholder="MM-DD-YYYY"
                    [showIcon]="true" [showButtonBar]="true"></p-calendar>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4" *ngIf="ClosedDate">
                <label class="inputfields">Closed Date</label>
                <p-calendar id="popup" dateFormat="mm-dd-yy" [minDate]="startCurDate" formControlName="editclosedDate"
                    placeholder="MM-DD-YYYY" [showIcon]="true" [showButtonBar]="true"></p-calendar>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields"> Recurring Task </label>
                <p-dropdown placeholder="Select Recurring Task" [options]="recuringData" optionLabel="label"
                    [showClear]="true" (onChange)="recurring($event)" autocomplete="off"
                    formControlName="editrecuringTask">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4" *ngIf="recuringDD">
                <label class="inputfields"> Recurring Duration<span class="mandatoryColor">*</span></label>
                <p-dropdown placeholder="Select Recurring Duration" [options]="recuringDuration" optionLabel="label"
                    [showClear]="true" autocomplete="off" formControlName="editrecuringDuration">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Description </label>
                <textarea type="text" pInputText placeholder="Description" formControlName="editdescription"
                    autoResize="autoResize" rows="3" maxlength="201"></textarea>
                <span class="validationtest" *ngIf="this.titleUpdateForm.get('editdescription').hasError('maxlength')">
                    Please enter 200 alphabets only
                </span>
            </div>
            <br>
        </div><br>
        <div class="btnsCSS1 btnAlgn" *ngIf="updateBtn">
            <button pButton type="button" icon='pi pi-times' label="Cancel" (click)="addCancel()"
                class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-save' label="Update" [disabled]="btndsb" (click)="update()"
                class="addhBtn"></button>&nbsp;
        </div><br><br>
    </p-dialog>
</form>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-toast position="bottom-right" key="br"></p-toast>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>