import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-subcontractor',
  templateUrl: './edit-subcontractor.component.html',
  styleUrls: ['./edit-subcontractor.component.scss'],
  providers: [DatePipe, MessageService],
})
export class EditSubcontractorComponent implements OnInit {

  name: string;
  url: any;
  cols = [
    { field: 'no', header: '#', width: '10%' },
    { field: 'title', header: 'Title', width: '10%' },
    { field: 'jobCategory', header: 'Job Category', width: '10%' },
    { field: 'minRate', header: 'Min Rate($)', width: '10%' },
    { field: 'maxRate', header: 'Max Rate($)', width: '10%' },

    { field: 'description', header: 'Comments', width: '10%' },
    { field: '', header: 'Action', width: '10%' },

  ];
  cols1 = [
    { field: 'sno', header: '#', width: '10%' },
    { field: 'category', header: 'Job Category', width: '10%' },
    { field: 'title', header: 'Job Title', width: '10%' },

    { field: 'min', header: 'Min Rate($)', width: '10%' },
    { field: 'max', header: 'Max Rate($)', width: '10%' },

    { field: 'description', header: 'Comments', width: '10%' },
    { field: 'Status', header: 'Status', width: '10%' },

    { field: '', header: 'Action', width: '10%' },

  ];
  accountDetailsForm: FormGroup;
  titleForm: FormGroup;
  loader: boolean;
  id: string;
  gridData: any;
  totalRecords: any;
  records: boolean;
  table: boolean;
  accId: any;
  categoryForm: FormGroup;
  rategridData: any;
  first: any;
  rows: any = 10;
  pageCount: any;
  page: any = 1;
  loading: boolean;
  diaplay1: any;
  display1: boolean;
  titleActionForm: FormGroup;
  rowdata: any;
  status: any;
  stateoptions: any;
  accountDetails: any;
  country: any;
  industryOptions: any;
  regionOptions: any;
  businessType: any;
  diversity = [
    { label: 'None', value: 'None' },
    { label: 'Womens Association Business Enterprise (WBE)', value: 'Womens Association Business Enterprise (WBE)' },
    { label: 'Minority Business Enterprise (MBE)', value: 'Minority Business Enterprise (MBE)' },
    { label: 'Other', value: 'Other' },
  ];
  vendorName: string;
  type: string;

  tabOption: boolean;
  vendorId: string;
  vendorData: any;
  pathExtension: any;
  fileName: any;
  imageSuccess: boolean;
  jobTitle: any = [];
  totalRecords1: any;
  records1 = false;
  table1: boolean;
  accIDD: any;
  userdetails: any;
  currencycode: any[];
  preferencList: any = [];
  currencyValue: any;
  acctdetails: any;
  currencysymbl: any;
  subcontractorid: string;
  subContractorId: string;
  imageevent: any;
  fileType: any;
  filedata: FormData;
  countrycodedropdown: any = [];
  vlogo: any;
  subname: any;
  bankdetailsForm: FormGroup;
  bankdetails: any;
  detailsLength: any;
  // tslint:disable-next-line:max-line-length
  constructor(private apisService: ApisService, private messageService: MessageService, private fb: FormBuilder, private router: Router, private breadcrumbService: BreadcrumbService, private route: ActivatedRoute) {
    this.type = this.route.snapshot.queryParamMap.get('type');

    if (this.type === '3') {
      this.breadcrumbService.setItems([
        { label: 'Subcontractor', routerLink: '/subcontactors' },
        { label: 'Account Details', routerLink: '/edit-subcontractor' },

      ]);
    } else {
      this.breadcrumbService.setItems([
        { label: 'Subcontractor', routerLink: '/subcontactors' },
        { label: 'Avaliable Subcontractor', routerLink: '/avaialablesubcontractor' },
        { label: 'Account Details', routerLink: '/edit-subcontractor' },
      ]);
    }
  }

  ngOnInit(): void {
    this.userdetails = JSON.parse(this.apisService.decodeData());

    this.preferencList = this.userdetails.preferencesList;

    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }

    if (this.type === '2') {
      this.tabOption = false;
    } else {
      this.tabOption = true;
    }
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.name = routeParams.params.name;
    this.subname = routeParams.params.sName;
    this.vendorName = sessionStorage.getItem('subContractorName');
    this.vendorId = sessionStorage.getItem('subContractorId');
    this.subContractorId = sessionStorage.getItem('vendorId');
    const userdetails = JSON.parse(this.apisService.decodeData());
    this.accId = userdetails.accId;
    this.accountDetailsForm = this.fb.group({
      accountName: [''],
      accountURL: [''],
      status: ['', Validators.required],
      mailExtension: [''],
      address1: [''],
      address2: [''],
      city: ['', Validators.pattern('^[\\sa-zA-z0-9]*$')],
      zip: [''],
      country: [''],
      state: [''],
      phone: [''],
      fax: [''],
      industry: [''],
      region: [''],
      noOfEmployees: [''],
      phNumDrp: [''],
      taxId: [''],
      stockSymbol: [''],
      revenue: [''],
      specializedIn: [''],
      dunAndBradStreetNo: [''],
      businessType: [''],
      diversity: [''],
      ownership: [''],
      staffingMode: [''],
      securityDeposit: [''],
      customerNotes: [''],
      mailbox: ['']
    });
    this.titleForm = this.fb.group({
      title: [''],
      min: [''],
      max: [''],
      jobTitle: ['']
    });
    this.categoryForm = this.fb.group({
      jobCategory: [''],
      min: [''],
      max: [''],

    });
    this.titleActionForm = this.fb.group({
      actionTitle: [''],
      actionMax: ['',[Validators.required, Validators.pattern('^[0-9]*$')]],
      actionMin: ['', Validators.pattern('^[0-9]*$')],
      actionDes: [''],
      actionjobTitle: [''],
    });
    this.bankdetailsForm = this.fb.group({
      bankAccountName: [''],
      bankAccountNumber: [''],
      ifsccode: [''],
      beneficiaryno: [''],
      beneficiaryname: [''],
      address: [''],
      city: [''],
      country: [''],
      state: [''],
      zip: [''],
    });
    this.vendorAccountDetails();

    this.statusList();
    this.getCountryDropdown();
    this.industryDropdown();
    this.regionDropdown();
    this.businesTypeList();
    // this.gridTable();
    this.getdrop();
  }
  onTabChange(event) {
    if (event.index === 0) {
      this.vendorAccountDetails();
    }
    if (event.index === 1) {
      this.bankingdetails();
      // this.gridTable();
    }
    else {
      // this.rateCardGrid();
    }
  }
  rateCradReset() {
    this.categoryForm.reset();
    this.titleForm.reset();
  }
  countryids(event) {
  }
  getdrop() {
    this.countrycodedropdown = this.apisService.getdropdwon();
  }
  // It is used to bind the bank details data
  bankingdetails() {
    this.loader = true;
    this.apisService.getApi(environment.accountbank + '/' + this.accId + '/' + this.vendorId).subscribe((res: any) => {
      this.bankdetails = res.data;
      this.loader = false;
      this.detailsLength = res.data.length;
      this.bankdetailsForm.get('bankAccountName').patchValue(this.bankdetails[0].accName);
      this.bankdetailsForm.get('bankAccountNumber').patchValue(this.bankdetails[0].swiftCode);
      this.bankdetailsForm.get('ifsccode').patchValue(this.bankdetails[0].ifscCode);
      this.bankdetailsForm.get('beneficiaryno').patchValue(this.bankdetails[0].bankAccountNo);
      this.bankdetailsForm.get('beneficiaryname').patchValue(this.bankdetails[0].bankBeneficiaryName);
      this.bankdetailsForm.get('address').patchValue(this.bankdetails[0].bankAddress);
      this.bankdetailsForm.get('city').patchValue(this.bankdetails[0].bankCity);
      this.bankdetailsForm.get('zip').patchValue(this.bankdetails[0].bankZip);
      this.bankdetailsForm.get('country').patchValue(this.country.filter((x) => x.code === this.bankdetails[0].bankLkCountry)[0]);
      this.getStateByUrl(this.bankdetails[0].bankLkCountry);
    });
  }
  gridTable() {
    this.loader = true;
    this.apisService.getApi(environment.gettitleTable + '/' + this.page + '/' + this.rows + '?' + this.accId).subscribe((res: any) => {
      this.gridData = res.data;
      this.loader = false;
      this.totalRecords = this.gridData.length;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }

  // It is used for select the file on account details
  onSelectFile(event) {
    this.imageevent = event;
    this.fileName = event.target.files[0];
    this.name = this.fileName.name;
    this.fileType = this.fileName.name.split('.').pop();
    const formData: FormData = new FormData();
    formData.append('file', this.fileName);
    this.filedata = formData;
    const chooseImgPath = event.target.files[0].name;
    this.pathExtension = chooseImgPath.substring(chooseImgPath.indexOf('.') + 1);
    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    const reader = e.target;
    this.url = reader.result;
    this.imageSuccess = true;
  }
  rateCardGrid() {
    this.loader = true;
    const actId = JSON.parse(this.apisService.decodeData());
    this.loading = true;
    this.apisService.getApi(environment.ratecardtable + '/' + '/' + actId.accId + '/' + this.vendorId).subscribe((res: any) => {
      this.loader = false;
      this.rategridData = res.data;
      this.loading = false;
      this.totalRecords1 = this.rategridData.length;
      if (this.totalRecords1 === 0) {
        this.records1 = true;
        this.table1 = false;
      } else {
        this.records1 = false;
        this.table1 = true;
      }
    });
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
  }
  sweetAlertForTaxTerm(data) {
    this.rowdata = data;
    if (data.rateCardExistcount === 0) {
      this.display1 = true;
      this.titleActionForm.controls.actionMax.patchValue(this.rowdata.maxRate);
      this.titleActionForm.controls.actionMin.patchValue(this.rowdata.minRate);
      this.titleActionForm.controls.actionDes.patchValue(this.rowdata.description);
      this.titleActionForm.controls.actionTitle.patchValue(this.rowdata.title);
      this.titleActionForm.controls.actionjobTitle.patchValue(this.rowdata.jobCategory);
    }
  }
  actioCancel() {
    this.display1 = false;
    this.titleActionForm.reset();
    this.gridTable();
  }
  statusList() {
    this.apisService.getApi(environment.lookupsData + 'ULS').subscribe((res: any) => {
      this.status = res.data;
    });
  }
  jobCategoryDropD() {
    const pgNo = this.page;
    const recordPage = this.rows;
    this.apisService.getApi(environment.jobCategoryDD + '/' + pgNo + '/' + recordPage).subscribe((res: any) => {
      this.jobTitle = res.data;
    });
  }

  getCountryDropdown() {

    this.apisService.getApi(environment.lookupsData + 'adlc').subscribe(res => {
      this.country = res.data;
    });
  }
  getStateByUrl(country) {
    if (country === null || '' || undefined) { }
    else {
      this.apisService.getApi(environment.states + country).subscribe(res => {
        this.stateoptions = res.data;
      });
    }

  }
  industryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'ALIT').subscribe((res: any) => {
      this.industryOptions = res.data;
    });
  }
  regionDropdown() {
    this.apisService.getApi(environment.lookupsData + 'ALR').subscribe(res => {
      this.regionOptions = res.data;
    });
  }
  businesTypeList() {
    this.apisService.getApi(environment.lookupsData + 'ALBE').subscribe((res: any) => {
      this.businessType = res.data;
    });
  }
  goto() {
    if (this.type === '3') {
      this.router.navigate(['/subcontactors']);
    } else {
      this.loader = true;
      this.router.navigate(['/avaialablesubcontractor']);
      this.loader = false;
    }
  }
  vendorAccountDetails() {
    this.loader = true;
    this.apisService
      .getApi(environment.accountget + this.vendorId)
      .subscribe((res: any) => {
        this.vendorData = res.data[0];
        this.vlogo = this.vendorData.logo;
        this.accIDD = res.data[0].accId;
        this.getStateByUrl(this.vendorData.country);
        this.loader = false;
        this.accountDetailsForm.patchValue({
          accountName: this.vendorData.accName,
          accountURL: this.vendorData.website,
          status: this.vendorData.status,
          mailExtension: this.vendorData.website,
          address1: this.vendorData.address1,
          address2: this.vendorData.address2,
          city: this.vendorData.city,
          zip: this.vendorData.zip,
          country: this.vendorData.country,
          state: this.vendorData.state,
          phone: this.vendorData.phone,
          fax: this.vendorData.fax,
          // industry: this.vendorData.lkIndustryType,
          region: this.vendorData.region,
          noOfEmployees: this.vendorData.noOfEmployees,
          taxId: this.vendorData.taxId,
          stockSymbol: this.vendorData.stockSymbol,
          revenue: this.vendorData.revenue,
          specializedIn: [''],
          dunAndBradStreetNo: this.vendorData.dunsNo,
          businessType: this.vendorData.lkBusinessEntity,
          diversity: this.vendorData.diversity,
          ownership: [''],
          staffingMode: this.vendorData.noOfEmployees,
          securityDeposit: this.vendorData.noOfEmployees,
          customerNotes: this.vendorData.description,
          mailbox: this.vendorData.mailbox
        });
        this.accountDetailsForm.get('industry').patchValue(
          this.industryOptions.filter((x) => x.code === this.vendorData.lkIndustryType)[0]);
        // tslint:disable-next-line:max-line-length
        this.accountDetailsForm.controls.phNumDrp.patchValue(this.countrycodedropdown.filter((x) => x.id === this.vendorData.mobileCcode)[0]);
      });

  }
  actionSave() {
    const data = {
      venId: this.accIDD,
      accCatId: this.rowdata.jobCatId,
      accTitleId: this.rowdata.id,
      status: 'S',
      minRate: this.titleActionForm.controls.actionMin.value,
      maxRate: this.titleActionForm.controls.actionMax.value,
      comments: this.titleActionForm.controls.actionDes.value,
    };
    this.loader = true;
    this.apisService
      .postApi(environment.jobcategoryaction, data)
      .subscribe((res: any) => {
        if (res.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
          this.loader = false;
          this.display1 = false;
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
        }
        this.loader = false;
        this.display1 = false;
      });
  }
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
}
