<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
  <p-accordion>
    <p-accordionTab [selected]="this.reqStatus === 'R' ? false : true">

      <p-header>
        <strong>Job Id: </strong><span class="panelLabel">{{this.requId}}</span>
        <strong class="positionheader">Position Title : </strong><span class="panelLabel">{{this.reqName}}</span>
        <strong class="positionheader">Max Rate : </strong><span class="panelLabel"> {{this.maxrate |
          currency:
          currencyValue : true}}
        </span>
        <div class="pi pi-arrow-left backRoute" (click)="backNavigate()">
        </div>
      </p-header>

      <form [formGroup]="editRequirementsForm" *ngIf='loginRole === ("1") || ("3") || ("7") || ("13")'>
        <p-card>
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
              <label class="inputfields">Position Title<span class="mandatoryColor">*</span></label>
              <div class="ui-inputgroup">
                <input autocomplete="off" type="text" pInputText placeholder="Enter Name(Ex:Project Developer)"
                  formControlName="name" maxlength=51>
              </div>
              <div>
                <span class="validationtest"
                  *ngIf="this.editRequirementsForm.controls.name.touched && this.editRequirementsForm.controls.name.invalid && this.editRequirementsForm.controls.name.errors.required">
                  please enter name
                </span>
                <span class="validationtest" *ngIf="this.editRequirementsForm.controls.name.hasError('maxlength')">
                  Please enter 50 alphabets only
                </span>
              </div>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
              <label class="inputfields">Position Category</label>
              <p-dropdown [options]="positionData" optionLabel="jobCategory" placeholder="Select Position Category"
                [showClear]="true" formControlName="positionCategory"
                (onChange)="getPositionCategoryBindDataValues($event.id)">
              </p-dropdown>
              <div>
                <span
                  *ngIf="this.editRequirementsForm.controls.positionCategory.touched && this.editRequirementsForm.controls.positionCategory.invalid && this.editRequirementsForm.controls.positionCategory.errors.required">
                  Please select position category
                </span>
              </div>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
              <label class="inputfields">Job Role</label>
              <p-dropdown [options]="minMaxRate" placeholder="Select Job Role" optionLabel="title" [showClear]="true"
                formControlName="requirementTitle" (onChange)="getTite($event.id)">
              </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
              <label class="inputfields">Department </label>
              <p-dropdown [options]="departmentList" formControlName="department" optionLabel="name"
                placeholder="Select Department" [showClear]="true"></p-dropdown>

            </div>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
              <label class="inputfields">Min Rate</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                <input autocomplete="off" type="text" pInputText placeholder="Min Rate" placeholder="Min Rate"
                  maxlength="9" formControlName="requirementMinRate">
                <span class="p-inputgroup-addon">/Hr</span>
              </div>
            </div>
            <div class="p-col-3 p-md-3 p-sm-3 container">
              <label class="inputfields">Max Rate <span class="mandatoryColor">*</span></label><br>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                <input autocomplete="off" type="text" pInputText maxlength="9" formControlName="requirementMaxRate"
                  placeholder="Max Rate">
                <span class="p-inputgroup-addon">/Hr</span>
              </div>
              <span class="validationtest"
                            *ngIf="this.editRequirementsForm.get('requirementMaxRate').touched && this.editRequirementsForm.get('requirementMaxRate').invalid && this.editRequirementsForm.get('requirementMaxRate').errors.required">
                            Please enter max rate
                        </span>
              <span class="validationtest"
              *ngIf="this.editRequirementsForm.get('requirementMaxRate').invalid && this.editRequirementsForm.get('requirementMaxRate').errors.pattern">
              Please enter numbers only
            </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6"></div>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
              <label class="inputfields">Number of Positions<span class="mandatoryColor">*</span></label>
              <input autocomplete="off" id="input" type="text" pInputText placeholder="Number of Positions"
                maxlength="10" formControlName="requirementNoofPositions" maxlength=12 pKeyFilter="num">
              <div>
                <span class="validationtest"
                  *ngIf="this.editRequirementsForm.controls.requirementNoofPositions.touched && this.editRequirementsForm.controls.requirementNoofPositions.invalid && this.editRequirementsForm.controls.requirementNoofPositions.errors.required">
                  Please enter number of positions
                </span>
                <span class="validationtest"
                  *ngIf=" this.editRequirementsForm.controls.requirementNoofPositions.hasError('maxlength')">
                  Please enter
                  11
                  numbers only </span>

                <span class="validationtest"
                  *ngIf="this.editRequirementsForm.controls.requirementNoofPositions.invalid && this.editRequirementsForm.controls.requirementNoofPositions.errors.pattern">
                  Please enter numbers only
                </span>


              </div>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory" pTooltip="{{this.patchlocationString}}"
              tooltipPosition="top">
              <label class="inputfields">Work Location<span class="mandatoryColor">*</span></label>
              <p-dropdown [options]="locationList" placeholder="Select Work Location" optionLabel="name"
                [showClear]="true" formControlName="requirementWorkCenterLocation" appendTo="body"
                (onChange)="saveLocation($event)">
                <ng-template let-data pTemplate="item">
                  <div
                    pTooltip="{{data.name + '\n' + data.address1+',' + data.city + ',' + data.state + ',' + data.country}}"
                    tooltipPosition="right">
                    {{ data.name }}
                  </div>
                </ng-template>

              </p-dropdown>
              <div>
                <span class="validationtest"
                  *ngIf="this.editRequirementsForm.controls.requirementWorkCenterLocation.touched && this.editRequirementsForm.controls.requirementWorkCenterLocation.invalid && this.editRequirementsForm.controls.requirementWorkCenterLocation.errors.required">
                  Please select work location
                </span>
              </div>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
              <label class="inputfields">Required Experience<span class="mandatoryColor">*</span></label>
              <p-dropdown [options]="getexperienceOptions" optionLabel="label" [showClear]="true" placeholder="Select Experience
              " formControlName="requirementReqExp">
              </p-dropdown>
              <div>
                <span class="validationtest"
                  *ngIf="this.editRequirementsForm.controls.requirementReqExp.touched && this.editRequirementsForm.controls.requirementReqExp.invalid && this.editRequirementsForm.controls.requirementReqExp.errors.required">
                  Please select required experience
                </span>
              </div>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3"></div>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
              <label class="inputfields">Hire Type<span class="mandatoryColor">*</span></label>
              <p-dropdown [options]="hireTypeData" (onChange)="typeData($event.value.code)"
                placeholder="Select Hire Type" optionLabel="label" [showClear]="true" formControlName='requirementType'>
              </p-dropdown>
              <div>
                <span class="validationtest"
                  *ngIf="this.editRequirementsForm.controls.requirementType.touched && this.editRequirementsForm.controls.requirementType.invalid && this.editRequirementsForm.controls.requirementType.errors?.required">
                  Please select type
                </span>
              </div>

            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
              <label class="inputfields">Planned Start Date<span class="mandatoryColor">*</span></label>
              <p-calendar id="popup" placeholder="MM-DD-YYYY" dateFormat="mm-dd-yy" [showIcon]="true"
                [showButtonBar]="true" (onSelect)='endDateValidation()' formControlName="requirementStartDate">
              </p-calendar>
              <div>
                <span class="validationtest"
                  *ngIf="this.editRequirementsForm.get('requirementStartDate').touched && this.editRequirementsForm.get('requirementStartDate').invalid && this.editRequirementsForm.get('requirementStartDate').errors.required">
                  Please select Planned Start Date
                </span>
              </div>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory" *ngIf='enddata'>
              <label class="inputfields">Planned End Date<span class="mandatoryColor">*</span></label>
              <p-calendar id="popup" placeholder="MM-DD-YYYY" dateFormat="mm-dd-yy" [showIcon]="true"
                [showButtonBar]="true" [minDate]='minDate' formControlName="requirementEndDate">
              </p-calendar>
              <div>
                <span class="validationtest"
                  *ngIf="this.editRequirementsForm.get('requirementEndDate').touched && this.editRequirementsForm.get('requirementEndDate').invalid &&  this.editRequirementsForm.get('requirementEndDate').errors.required">
                  Please select Planned End Date
                </span>
              </div>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
              <label class="inputfields">Status<span class="mandatoryColor">*</span></label>
              <p-dropdown [options]="statusDD" placeholder="Select Status" optionLabel="label"
                [disabled]="statusDisable" [showClear]="true" formControlName="requirementStatus">
              </p-dropdown>
              <div>
                <span class="validationtest"
                  *ngIf="this.editRequirementsForm.controls.requirementStatus.touched && this.editRequirementsForm.controls.requirementStatus.invalid && this.editRequirementsForm.controls.requirementStatus.errors.required">
                  Please select status
                </span>
              </div>
            </div>
          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-12" style="margin-left: -1vh;">
            <label>Employee approval required? &nbsp;</label>
            <p-radioButton name="approval" value="Y" label="Yes" formControlName="approval" inputId="Yes" [disabled]="true">
            </p-radioButton>&nbsp;&nbsp;
            <p-radioButton name="approval" value="N" label="No" formControlName="approval" inputId="No" [disabled]="true">
            </p-radioButton>
          </div>

          <div class="p-grid p-fluid">
            <div class="p-col-3 p-md-3 p-sm-12 invalidMandatory container">
              <label class="inputfields">Billing Type<span class="mandatoryColor">*</span></label>
              <p-dropdown [options]="billingData" formControlName="billingtype" optionLabel="label"
                placeholder="Select Billing Type" [showClear]="true"></p-dropdown>
              <div>
                <span class="validationtest"
                  *ngIf="this.editRequirementsForm.get('billingtype').touched && this.editRequirementsForm.get('billingtype').invalid && this.editRequirementsForm.get('requirementsExp').errors.required">
                  Please Select Billing Type
                </span>
              </div>
            </div>
            <div class="p-col-3 p-md-3 p-sm-12 container">
              <label class="inputfields">Duration</label>
              <div class="p-inputgroup">
                <!-- <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span> -->
                <input id="input" type="text" autocomplete="off" formControlName="duration" (blur)="getduration()"
                  pInputText placeholder="Enter Duration" maxlength="30">
                <span class="p-inputgroup-addon">Months</span>
              </div>
              <span class="validationtest" *ngIf="this.shwmsg">{{this.showmsg}}</span>
              <span class="validationtest"
                *ngIf="this.editRequirementsForm.get('duration').invalid && this.editRequirementsForm.get('duration').errors.pattern">
                Please enter numbers only
              </span>
            </div>
            <div class="p-col-3 p-md-3 p-sm-12 container">
              <label class="inputfields">Per Week</label>
              <div class="p-inputgroup">
                <!-- <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span> -->
                <input id="input" type="text" placeholder="Enter Hrs Per Week" autocomplete="off"
                  formControlName="hrsperWeek" pInputText maxlength="30">
                <span class="p-inputgroup-addon">Hrs</span>
              </div>
              <span class="validationtest"
              *ngIf="this.editRequirementsForm.get('hrsperWeek').invalid && this.editRequirementsForm.get('hrsperWeek').errors.pattern">
              Please enter numbers only
            </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3"></div>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-field p-col-6 p-md-6 p-sm-12 p-lg-6">
              <label class="first">Skill Set<span class="mandatoryColor">*</span></label>
              <p-multiSelect [options]="skillsList" [showToggleAll]="true" formControlName="requirementsSkillSet"
                placeholder="Select Skill Set" maxSelectedLabels="15" optionLabel="label"
                (onChange)="skillsetOthers($event.value)"></p-multiSelect>
              <div class="skillstyle">
                <label><strong>Skill Set: </strong></label>
                <span>{{primarySkills.toString()}}</span>
                <!-- <p-card class="skillspatch">{{primarySkills.toString()}}</p-card> -->
              </div>
              <div>
                <span class="validationtest"
                  *ngIf="this.editRequirementsForm.get('requirementsSkillSet').touched && this.editRequirementsForm.get('requirementsSkillSet').invalid && this.editRequirementsForm.get('requirementsSkillSet').errors.required">
                  Please select skill set
                </span>
              </div>
            </div>
            <div class="p-field p-col-6 p-md-6 p-sm-12 p-lg-6">
              <label class="first">Preferred Skill Set<span class="mandatoryColor">*</span></label>
              <p-multiSelect [options]="skillsList" [showToggleAll]="true" formControlName="preferredSkills"
              (onChange)="resetskills($event,$event.value)"  placeholder="Select Preferred Skill Set" maxSelectedLabels="15" optionLabel="label"></p-multiSelect>
              <div class="skillstyle">
                <label><strong>Preferred Skill Set: </strong> </label>
                <span>{{skillsList12.toString()}}</span>
              </div>
              <div>
                <span class="validationtest"
                  *ngIf="this.editRequirementsForm.get('preferredSkills').touched && this.editRequirementsForm.get('preferredSkills').invalid && this.editRequirementsForm.get('preferredSkills').errors.required">
                  Please select skill set
                </span>
              </div>
            </div>
          </div>
          <div class="p-fluid  p-grid" *ngIf="otherskilldata">
            <div class="p-field p-col-12 p-md-6 p-sm-12 p-lg-6">
              <label class="inputfields">Others Skills<span class="mandatoryColor">*</span></label>
              <textarea rows="5" maxlength="301" cols="30" placeholder="Enter Others Skills (Ex: Angular, Java)"
                formControlName="otherskills" (blur)=onBlur($event.target.value) pInputTextarea></textarea>
              <span class="validationtest" *ngIf="this.editRequirementsForm.get('otherskills').touched &&
                       this.editRequirementsForm.get('otherskills').invalid &&
                        this.editRequirementsForm.get('otherskills').errors.required">
                Please enter others skills
              </span>
              <span class="validationtest" *ngIf=" this.editRequirementsForm.get('otherskills').hasError('maxlength')">
                Please enter
                300
                alphabets only </span>
            </div>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
              <label class="inputfields">Job Description<span class="mandatoryColor">*</span></label>
              <textarea autocomplete="off" pInputTextarea rows="8" cols="30" placeholder="Job Description"
                formControlName="requirementDescription" maxlength=2001 autoResize="false"></textarea>
              <div>
                <span class="validationtest"
                  *ngIf="this.editRequirementsForm.controls.requirementDescription.touched && this.editRequirementsForm.controls.requirementDescription.invalid && this.editRequirementsForm.controls.requirementDescription.errors.required">
                  Please enter description
                </span>
                <span class="validationtest"
                  *ngIf="this.editRequirementsForm.get('requirementDescription').hasError('maxlength')">
                  please enter 2000 alphabets only
                </span>
              </div>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
              <label class="inputfields">Responsibilities<span class="mandatoryColor">*</span></label>
              <textarea autocomplete="off" pInputTextarea rows="8" cols="30" placeholder="Responsibilities"
                formControlName="requirementResponsibilities" maxlength=2001 autoResize="false"></textarea>
              <div>
                <span class="validationtest"
                  *ngIf="this.editRequirementsForm.controls.requirementResponsibilities.touched && this.editRequirementsForm.controls.requirementResponsibilities.invalid && this.editRequirementsForm.controls.requirementResponsibilities.errors.required">
                  Please enter responsibilities
                </span>
                <span class="validationtest"
                  *ngIf="this.editRequirementsForm.get('requirementResponsibilities').hasError('maxlength')">
                  please enter 2000 alphabets only
                </span>
              </div>
            </div>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
              <label class="inputfields">Qualifications<span class="mandatoryColor">*</span></label>
              <textarea autocomplete="off" pInputTextarea rows="8" cols="30" placeholder="Qualifications"
                formControlName="requirementQualification" maxlength=501 autoResize="false"></textarea>
              <div>
                <span class="validationtest"
                  *ngIf="this.editRequirementsForm.controls.requirementQualification.touched && this.editRequirementsForm.controls.requirementQualification.invalid && this.editRequirementsForm.controls.requirementQualification.errors.required">
                  Please enter qualifications
                </span>
                <span class="validationtest"
                  *ngIf="this.editRequirementsForm.get('requirementQualification').hasError('maxlength')">
                  please enter 500 alphabets only
                </span>
              </div>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
              <label class="inputfields">Comments</label>
              <textarea autocomplete="off" pInputTextarea rows="8" cols="30" placeholder="Comments"
                formControlName="requirementComments" maxlength=501 autoResize="false"></textarea>
              <div>
                <span class="validationtest"
                  *ngIf="this.editRequirementsForm.get('requirementComments').hasError('maxlength')">
                  please enter 500 alphabets only
                </span>
              </div>
            </div>
          </div>
          <br>
          <div>
            <div class="btnsCSS1">
              <button *ngIf="updatebutton" class="addhBtn" pButton type="submit" icon='pi pi-check-circle'
                label="Submit" (click)="requirementEditUpdate()"></button>
            </div><br><br>
          </div>

        </p-card>
      </form>
    </p-accordionTab>
  </p-accordion>
</p-card>
<br>
<div *ngIf="recived">
  <p-card>
    <p-panel>
      <p-header>
        <strong>Received Candidates</strong>
      </p-header>

      <form [formGroup]="submittedProfileForm">
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
            <label class="inputfields"> First Name </label>
            <input id="input" type="text" autocomplete="off" pInputText placeholder="First Name" maxlength="30"
              formControlName="firstName" (keyup.enter)="gridTable()">
          </div>

          <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
            <label class="inputfields"> Last Name </label>
            <input id="input" type="text" autocomplete="off" pInputText placeholder="Last Name" maxlength="30"
              formControlName="lastName" (keyup.enter)="gridTable()">
          </div>

          <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
            <label class="inputfields"> Email</label>
            <input id="input" type="text" autocomplete="off" pInputText placeholder="Email" maxlength="60"
              formControlName="emailId" (keyup.enter)="gridTable()">

            <span class="validationtest"
              *ngIf="this.submittedProfileForm.controls.emailId.invalid && this.submittedProfileForm.controls.emailId.errors?.pattern">Please
              enter
              email upto extension only</span>

          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
            <label class="inputfields">Status </label>
            <p-dropdown [options]="consStatusData" placeholder="Select Status" optionLabel="label" [showClear]="true"
              formControlName="status" (keyup.enter)="gridTable()">
            </p-dropdown>
          </div>
        </div>
        <br>
        <div class="btnsCSS1">
          <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" class="addhBtn"></button>
          <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn"
            (click)="gridTable()"></button>
        </div>
        <br>
        <br>
        <p-dialog class="dialogBox" [contentStyle]="{'overflow': 'visible'}" [(visible)]="statusDailog"
          header="{{commentsHeader}}" [modal]="true" [style]="{width: '30%'}" [baseZIndex]="100000" [draggable]="false"
          [resizable]="false">
          <br>
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 ">
              <label class="inputfields">Comments<span class="mandatoryColor">*</span></label>
              <textarea autocomplete="off" [rows]="2" [cols]="30" pInputTextarea formControlName="comments1"
                placeholder="comments" maxlength=300></textarea>
              <span class="mandatoryColor" *ngIf="submittedProfileForm.controls.comments1.touched && submittedProfileForm.controls.comments1.invalid
            && submittedProfileForm.controls.comments1.errors.required">
                Please enter comments</span>
              <p class="mandatoryColor"></p>
            </div>
          </div>
          <div class="btnsCSS1" *ngIf="disableButton">
            <button pButton type="button" icon='pi pi-upload' label="OK" (click)="save()" class="addhBtn"></button>
          </div><br><br>
        </p-dialog>
      </form>
    </p-panel>
  </p-card>
  <br>
  <p-card class="pgpos">
    <div *ngIf="isUploading1" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
      <p-progressBar mode="indeterminate"></p-progressBar>
    </div>
    <div *ngIf="table">
      <p-table [columns]="cols11" id="excel-table" [autoLayout]="true" dataKey="consultantName"
        [value]="submittedProfilesGridData" selectionMode="single" [rows]="7" [rowsPerPageOptions]="[10,20,30]"
        sortMode="multiple">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th id="gridHeader" *ngFor="let col of columns let i=index" (click)="iconToggle[i] = !iconToggle[i]"
              [pSortableColumn]="col.field" [hidden]="col.sno == 5">
              {{col.header}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-Data let-expanded="expanded">
          <tr>
            <td class="textCenter">
              {{Data.vendorName}}
            </td>
            <td class="textCenter">
              {{Data.consultantName}}
            </td>
            <td class="textCenter">{{Data.submittedDate}}</td>
            <td class="textCenter">
              <a (click)="op.toggle($event)" (click)="reqSkills1(getSkillSetTable(Data.skills))"
                *ngFor="let data1 of getSkillSetTable(Data.skills); index as i;">
                <p-badge class="badgespace newsty" *ngIf='i<1' [value]="data1"></p-badge>
                <p-badge *ngIf='i===1' [value]="'+'+(Data.skills.length-1)+' more'"></p-badge>
              </a>
            </td>
            <td class="pointer" [hidden]="true">
              <a (click)="op.toggle($event)" (click)="reqSkills1(getSkillSetTable(Data.skills))"
                *ngFor="let data1 of getSkillSetTable(Data.skills); index as i;">
                <p-badge [value]="data1+','"></p-badge>
              </a>
            </td>
            <td class="textCenter">{{statusList[Data.profileState === 'S' ? 'B' : Data.profileState] }}</td>
            <td class="textCenter">
              {{Data.billRate | currency: currencyValue : true}}
            </td>
            <td class="textCenter"><a>
                <em (click)="actionpopup.toggle($event); toggle(items, $event, Data)"
                  class="pi pi-ellipsis-v pointer"></em>
              </a></td>
          </tr>
        </ng-template>
      </p-table>
      <div class="p-grid ui-fluid">
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 totalrec">
          <h3 class="norecorddata ">Total records : {{this.subProRecords}}</h3>
          <p class="downpos">
            <span class="ui-column-title pointer" (click)="downloadActionPopup.toggle($event); downLoadsToggle()">
              <em class="pi pi-download downloadspace"></em>
              <strong>Download</strong></span>
          </p>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
          <p-paginator [rows]="10" [totalRecords]="subProRecords" [rowsPerPageOptions]="[10,20,30]"
            (onPageChange)="paginate($event)" #p></p-paginator>
        </div>
      </div>


    </div>
    <p-card *ngIf="records">
      <div class="norecordImgsize">
        <div *ngIf="records" class="aligncenter">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
          <br><em>Sorry, we are not able to find any candidates</em><br>
        </div>
      </div>
    </p-card>
    <p-overlayPanel #op [showCloseIcon]="true" header="Skill Details" [style]="{width: '25vw'}">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12 bordersty">

          <p class="skpos">

            <p-badge class="badgespace badgesp" *ngFor="let data2 of reqSkillsValue; index as i" [value]="data2"
              [ngClass]="i%2===0? 'newsty' : ''">
            </p-badge>


          </p>

        </div>
      </div>
    </p-overlayPanel>
  </p-card>
</div>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center laoderheight ">
        <div class="laodersize">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
            class="loaderimg-height">
        </div>
      </div>
    </div>
  </div>
</ng-template>

<p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="downloadOptions"></p-menu>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>


<p-dialog [contentStyle]="{'overflow': 'visible'}" class="dialogBox" header="Reject Candidate" [(visible)]="rejectpopup"
  [style]="{width: '20%'}" [baseZIndex]="1" [draggable]="false" [modal]="true" [resizable]="true">
  <form [formGroup]="rejectform">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <label class="inputfields">Reject Reason<span class="mandatoryColor">*</span> </label>
        <p-dropdown [options]="rejectopention" placeholder="Select Reject Reason" optionValue="code" [showClear]="true"
          (onChange)="getRejectOption($event.value)" optionLabel="label" formControlName="reject">
        </p-dropdown>
        <span class="validationtest"
          *ngIf="this.rejectform.controls.reject.touched && this.rejectform.controls.reject.invalid && this.rejectform.controls.reject.errors.required">
          Please select reject reason
        </span>
      </div>
    </div>
  </form><br>
  <div class="aligncenter">
    <button pButton type="button" disable="btndisable" icon="pi pi-check-circle" label="Submit" class="addhBtn"
      (click)="rejectcandidate()"></button>
  </div>
</p-dialog>
<p-dialog class="dialogBox" [(visible)]="displayModal" header=" " [draggable]="false" [modal]="true"
  [style]="{width: '30%',height:'60%'}">
  <p-header>
    <span class="Activityheader">Activity</span>
    <div class="Activity">Job Id : <a>{{this.jobId}}</a></div>
  </p-header>
  <p class="Activitycolor">Please find the below information</p>
  <div class="p-grid p-fluid">
    <div class="p-col-12 p-md-12 p-sm-12">
      <p-timeline [value]="histroy" align="alternate">
        <ng-template pTemplate="content" let-event>
          <p-card>
            <p class="aligncenter"> {{event.activity}}
              <br>
              <span class="Activitycolor">By {{event.activityBy}}</span>
            </p>
          </p-card>
          <br>
        </ng-template>

        <ng-template pTemplate="opposite" let-event><span class="Activitycolor">{{event.activityDate |
            date:'MM-dd-yyyy'}}</span>

        </ng-template>
      </p-timeline>
    </div>
  </div>
</p-dialog>
<form [formGroup]="updateBilForm">
  <p-dialog class="dialogBox pgpos" [(visible)]="consultantRequirementDisplay"
    header="Associate To Original Requirement" [modal]="true" [style]="{width: '45%'}" [baseZIndex]="100000"
    [draggable]="false" [resizable]="false">

    <div class="p-grid p-fluid">

      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
        <strong>Position Title : </strong> <span class="panelLabel">{{this.reqName}}</span>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
        <strong>Candidate Name : </strong>
        <span class="panelLabel"> {{this.canname}}</span>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
        <strong>Subcontactor Name : </strong><span class="panelLabel">{{this.subconName}}
        </span>
      </div>
    </div>
    <div class="p-grid p-fluid">

      <div class="p-col-5 p-md-5 p-sm-5 p-lg-5">
        <p-table [value]="payRatesTable" [responsive]="true">
          <ng-template pTemplate="caption" class="billrate">
            Bill Rate Calculation
          </ng-template>
          <ng-template pTemplate="body" let-pro>
            <tr class="aligncenter">
              <td style="text-align: center;padding: 5px;" (click)="payrate(((payRate * pro.value) / 100 )+ payRate)">
                {{pro.key}} = {{((payRate
                * pro.value) / 100 )+ payRate | currency: currencyValue : true}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="p-col-1 p-md-1 p-sm-1 p-lg-1"></div>
      <div class="p-col-6 p-md-6 p-sm-6 p-lg-6 billratealign">
        <div class="p-grid ui-fluid ">
          <div class="p-col-12 p-md-6 p-sm-6 p-lg-6">
            <label>Pay Rate</label>
          </div>
          <div class="p-col-12 p-md-6 p-sm-6 p-lg-6">
            : <strong> {{this.payRate | currency: 'USD' : true
              }}</strong>
          </div>
          <div class="p-col-12 p-md-6 p-sm-6 p-lg-6">
            <label>Max Rate for Position </label>
          </div>
          <div class="p-col-12 p-md-6 p-sm-6 p-lg-6">
            <label>: {{this.maxrate | currency: currencyValue : true}}
            </label>
          </div>
          <div class="p-col-12 p-md-6 p-sm-6 p-lg-6">
            <label class="inputfields">Bill Rate Requested </label>
          </div>
          <div class="p-col-12 p-md-6 p-sm-6 p-lg-4">
            <div class="p-inputgroup">
              <p class="duration">:</p>
              <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
              <input class="aligncenter" style="width: 68px;" autocomplete="off" id="input" type="text" size="10"
                maxlength="22" formControlName="billRateControl" pInputText placeholder="Bill Rate">
              <span class="p-inputgroup-addon">/Hrs</span>
            </div>
          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <div style="float: right;">
              <span *ngIf="errorMsg" class="lessbillrate mandatoryColor"> Please enter bill rate less than
                max
                rate </span>
              <span class="lessbillrate mandatoryColor"
                *ngIf="this.updateBilForm.controls.billRateControl.touched && this.updateBilForm.controls.billRateControl.invalid && this.updateBilForm.controls.billRateControl.errors.required">
                Please enter bill rate requested
              </span>
              <span class="validationtest"
                *ngIf="this.updateBilForm.controls.billRateControl.invalid && this.updateBilForm.controls.billRateControl.errors?.pattern">Please
                Enter Numbers only</span>
              <span *ngIf="alertError || this.updateBilForm.controls.billRateControl.errors?.min"
                class="validrate">Please enter valid bill rate
              </span>
            </div>

          </div>
          <div class="p-col-6 p-md-6 p-sm-6 p-lg-6"></div>
          <div class="p-col-6 p-md-6 p-sm-6 p-lg-6">
            <div>
              <button pButton type="button" icon="pi pi-check-circle" label="Associate" [disabled]="btndisable"
                class="addhBtns" (click)="associatereq()"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>
</form>
<p-dialog class="dialogBox" [contentStyle]="{'overflow': 'visible'}" [(visible)]="selected" header="Select Candidate"
  [modal]="true" [style]="{width: '20%'}" [baseZIndex]="1" [draggable]="false" [resizable]="false">
  <form [formGroup]="receivedCandidateForm">
    <div class="p-grid p-fluid p-justify-center">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <p><strong>Candidate Name :</strong> {{this.candidatename}}
      </div>
    </div>
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 invalidMandatory">
        <label class="inputfields">Onboard date<span class="mandatoryColor">*</span></label>
        <p-calendar id="popup" placeholder="MM-DD-YYYY" dateFormat="mm-dd-yy" [showIcon]="true" [showButtonBar]="true"
          formControlName="onboardDate1" [minDate]="startCurDate">
        </p-calendar>
        <span class="validationtest"
          *ngIf="this.receivedCandidateForm.controls.onboardDate1.touched && this.receivedCandidateForm.controls.onboardDate1.invalid && this.receivedCandidateForm.controls.onboardDate1.errors.required">
          Please select date
        </span>
      </div>
    </div>
    <br>
    <div class="btnleft">
      <button pButton [disabled]="disable" type="button" icon="pi pi-check-circle" label="Submit"
        (click)="canidateselection()"></button>
    </div>
  </form>
</p-dialog>

<p-dialog [contentStyle]="{'overflow': 'visible'}" class="dialogBox" header="Other Details" [(visible)]="displayOtherDetails"
  [modal]="true" [style]="{width: '30%'}" [baseZIndex]="1" [draggable]="false" [resizable]="false">
  <form [formGroup]="getotherDetails">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <label class="inputfields">Address Line 1</label>
        <input id="input" type="text" autocomplete="off" pInputText placeholder="Address Line 1"
          formControlName="addressControl" maxlength="51">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
        <label class="inputfields">Address Line 2</label>
        <input id="input" type="text" autocomplete="off" pInputText placeholder="Address Line 2"
          formControlName="addressFormControl" maxlength="51">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
        <label class="inputfields" id='csslabel'>Mailbox</label>
        <input type="text" autocomplete="off" maxlength="11" pInputText placeholder="Mailbox"
          formControlName="mailbox1">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
        <label class="inputfields">Country</label>
        <input autocomplete="off" id="input" type="text" pInputText pKeyFilter="num" maxlength="11" pInputText
        placeholder="Country" formControlName="Country">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
        <label class="inputfields">State</label>
          <input autocomplete="off" id="input" type="text" pInputText pKeyFilter="num" maxlength="11" pInputText
          placeholder="State" formControlName="State">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
        <label class="inputfields">City</label>
        <input autocomplete="off" id="input" type="text" pInputText placeholder="City" formControlName="cityForm"
          maxlength="21">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
        <label class="inputfields">Zip</label>
        <input autocomplete="off" id="input" type="text" pInputText pKeyFilter="num" maxlength="11" pInputText
          placeholder="Zip" formControlName="zipForm">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
        <label class="inputfields">Work Satus</label>
        <input autocomplete="off" id="input" type="text"  maxlength="11" pInputText
          placeholder="Work Satus" formControlName="workSatus">
      </div>
    </div>
    <br>
  </form>
</p-dialog>