import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApisService } from 'src/app/services/apis.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-vendor-account',
  templateUrl: './vendor-account.component.html',
  styleUrls: ['./vendor-account.component.scss'],
  providers: [DatePipe, MessageService],
})
export class VendorAccountComponent implements OnInit {

  name: string;
  url: any;
  // It is used for displaying table header data
  cols = [
    { field: 'no', header: '#', width: '10%' },
    { field: 'title', header: 'Title', width: '10%' },
    { field: 'jobCategory', header: 'Job Category', width: '10%' },
    { field: 'minRate', header: 'Min Rate', width: '10%' },
    { field: 'maxRate', header: 'Max Rate', width: '10%' },
    { field: 'description', header: 'Comments', width: '10%' },
    { field: '', header: 'Action', width: '7%' },

  ];
  cols1 = [
    { field: 'sno', header: '#', width: '10%' },
    { field: 'category', header: 'Job Category', width: '10%' },
    { field: 'title', header: 'Job Title', width: '10%' },

    { field: 'min', header: 'Min Rate', width: '10%' },
    { field: 'max', header: 'Max Rate', width: '10%' },

    { field: 'description', header: 'Comments', width: '10%' },
    { field: 'Status', header: 'Status', width: '10%' },

    { field: '', header: 'Action', width: '10%' },

  ];
  accountDetailsForm: FormGroup;
  titleForm: FormGroup;
  loader: boolean;
  id: string;
  gridData: any;
  totalRecords: any;
  records: boolean;
  table: boolean;
  accId: any;
  categoryForm: FormGroup;
  rategridData: any;
  rows: any = 10;
  page: any = 1;
  loading: boolean;
  diaplay1: any;
  display1: boolean;
  titleActionForm: FormGroup;
  rowdata: any;
  status: any;
  stateoptions: any;
  accountDetails: any;
  country: any;
  industryOptions: any = [];
  regionOptions: any;
  businessType: any;
  diversity = [
    { label: 'None', value: 'None' },
    { label: 'Womens Association Business Enterprise (WBE)', value: 'Womens Association Business Enterprise (WBE)' },
    { label: 'Minority Business Enterprise (MBE)', value: 'Minority Business Enterprise (MBE)' },
    { label: 'Other', value: 'Other' },
  ];
  vendorName: string;
  type: string;

  tabOption: boolean;
  vendorId: string;
  vendorData: any;
  pathExtension: any;
  fileName: any;
  imageSuccess: boolean;
  jobTitle: any;
  totalRecords1: any;
  records1 = false;
  table1: boolean;
  acctdetails: any;
  currencycode: any[];
  preferencList: any = [];
  currencyValue: any;
  currencysymbl: any;
  accimage: any;
  bankdetailsForm: FormGroup;
  bankdetails: any;
  first: any;
  pageCount: any;
  bankDetailData: boolean;
  detailsLength: any;
  // tslint:disable-next-line:max-line-length
  constructor(private apisService: ApisService, private fb: FormBuilder, private router: Router, private breadcrumbService: BreadcrumbService, private route: ActivatedRoute) {
    this.type = this.route.snapshot.queryParamMap.get('type');
    if (this.type === '3') {
      this.breadcrumbService.setItems([
        { label: 'Vendors', routerLink: '/vendors' },
        { label: 'Account Details', routerLink: '/vendoraccount' },

      ]);
    } else {
      this.breadcrumbService.setItems([
        { label: 'Vendors', routerLink: '/vendors' },
        { label: 'Available Vendors', routerLink: '/available-vendors' },
        { label: 'Account Details', routerLink: '/vendoraccount' },

      ]);
    }
  }

  ngOnInit(): void {
    this.acctdetails = JSON.parse(this.apisService.decodeData());
    this.preferencList = this.acctdetails.preferencesList;
    this.accId = this.acctdetails.accId;
    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }
    if (this.type === '2') {
      this.bankDetailData = false;
    } else {
      this.bankDetailData = true;
    }
    if (this.type === '3') {
      this.tabOption = true;
    } else {
      this.tabOption = false;
    }
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.name = routeParams.params.name;
    this.vendorName = sessionStorage.getItem('vendorname');
    this.vendorId = sessionStorage.getItem('vendorId');
    const userdetails = JSON.parse(this.apisService.decodeData());
    this.accId = userdetails.accId;

    this.accountDetailsForm = this.fb.group({
      accountName: [''],
      accountURL: [''],
      status: ['', Validators.required],
      mailExtension: [''],
      address1: [''],
      address2: [''],
      city: ['', Validators.pattern('^[\\sa-zA-z0-9]*$')],
      zip: [''],
      country: [''],
      state: [''],
      phone: [''],
      fax: [''],
      industry: [''],
      region: [''],
      noOfEmployees: [''],
      taxId: [''],
      stockSymbol: [''],
      revenue: [''],
      specializedIn: [''],
      dunAndBradStreetNo: [''],
      businessType: [''],
      diversity: [''],
      ownership: [''],
      staffingMode: [''],
      securityDeposit: [''],
      customerNotes: [''],
      mailbox: ['']
    });
    this.bankdetailsForm = this.fb.group({
      bankAccountName: [''],
      bankAccountNumber: [''],
      ifsccode: [''],
      beneficiaryno: [''],
      beneficiaryname: [''],
      address: [''],
      city: [''],
      country: [''],
      state: [''],
      zip: [''],
    });
    this.titleForm = this.fb.group({
      title: [''],
      min: [''],
      max: [''],
      jobTitle: ['']
    });
    this.categoryForm = this.fb.group({
      jobCategory: [''],
      min: [''],
      max: [''],

    });
    this.titleActionForm = this.fb.group({
      actionTitle: [''],
      actionMax: ['',Validators.required],
      actionMin: [''],
      actionDes: [''],
      actionjobTitle: [''],
    });
    this.vendorAccountDetails();
    this.statusList();
    this.getCountryDropdown();
    this.industryDropdown();
    this.regionDropdown();
    this.businesTypeList();
    // this.gridTable();
  }
  // it is used to navigate from one tab to another
  onTabChange(event) {
    if (event.index === 0) {
      this.vendorAccountDetails();
    }
    if (event.index === 1) {
      this.bankingdetails();
    }
    if (event.index === 2) {
      this.jobCategoryDropD();
    }
    if (event.index === 3) {
      this.rateCardGrid();
    }
    else {
    }
  }
  // It is used to bind the bank details data
  bankingdetails() {
    this.loading = true;
    this.apisService.getApi(environment.accountbank + '/' + this.accId + '/' + this.vendorId).subscribe((res: any) => {
      this.bankdetails = res.data;
      this.detailsLength = res.data.length;
      this.loading = false;
      this.bankdetailsForm.get('bankAccountName').patchValue(this.bankdetails[0].accName);
      this.bankdetailsForm.get('bankAccountNumber').patchValue(this.bankdetails[0].swiftCode);
      this.bankdetailsForm.get('ifsccode').patchValue(this.bankdetails[0].ifscCode);
      this.bankdetailsForm.get('beneficiaryno').patchValue(this.bankdetails[0].bankAccountNo);
      this.bankdetailsForm.get('beneficiaryname').patchValue(this.bankdetails[0].bankBeneficiaryName);
      this.bankdetailsForm.get('address').patchValue(this.bankdetails[0].bankAddress);
      this.bankdetailsForm.get('city').patchValue(this.bankdetails[0].bankCity);
      this.bankdetailsForm.get('zip').patchValue(this.bankdetails[0].bankZip);
      this.bankdetailsForm.get('country').patchValue(this.country.filter((x) => x.code === this.bankdetails[0]?.bankLkCountry)[0]);
      this.getStateByUrl(this.bankdetails[0].bankLkCountry);
    });
  }
// It is used for show account details data
  gridTable() {
    this.loading = true;
    const pgNo = this.page;
    const recordPage = this.rows;
    this.apisService.getApi(environment.gettitleTable + '/' + pgNo + '/' + recordPage + '?' + this.accId).subscribe((res: any) => {
      this.gridData = res.data;
      this.accimage = this.gridData.logo;
      this.loading = false;
    });
  }
  // It is used for select the file on account details
  onSelectFile(event) {
    const chooseImgPath = event.target.files[0].name;
    this.pathExtension = chooseImgPath.substring(
      chooseImgPath.indexOf('.') + 1
    );
    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    this.fileName = file.name;
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    const reader = e.target;
    this.url = reader.result;
    this.url = true;
    this.imageSuccess = true;
  }
  // It is used for getting rate card table data
  rateCardGrid() {
    const actId = JSON.parse(this.apisService.decodeData());
    this.loading = true;
    this.apisService.getApi(environment.ratecardtable + '/' + actId.accId + '/' + this.vendorId).subscribe((res: any) => {
      this.rategridData = res.data;
      this.loading = false;
      this.table = true;
      this.totalRecords1 = this.rategridData.length;
      if (this.totalRecords1 === 0) {
        this.records1 = true;
        this.table1 = false;
      } else {
        this.records1 = false;
        this.table1 = true;
      }
    });
  }
  // It is used to bind the data
  sweetAlertForTaxTerm(data) {
    this.rowdata = data;
    if (data.rateCardExistcount === 0) {
      this.display1 = true;
      this.titleActionForm.controls.actionMax.patchValue(this.rowdata.maxRate);
      this.titleActionForm.controls.actionMin.patchValue(this.rowdata.minRate);
      this.titleActionForm.controls.actionDes.patchValue(this.rowdata.description);
      this.titleActionForm.controls.actionTitle.patchValue(this.rowdata.title);
      this.titleActionForm.controls.actionjobTitle.patchValue(this.rowdata.jobCategory);
    }
  }
  // It is used to close submit rate card to vendor pop up
  actioCancel() {
    this.display1 = false;
    this.titleActionForm.reset();
    // this.gridTable();
  }
  // It is used for displaying status dropdown list
  statusList() {
    this.apisService.getApi(environment.lookupsData + 'ULS').subscribe((res: any) => {
      this.status = res.data;
    });
  }
// It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.jobCategoryDropD();
  }
  // It is used for displaying job category dropdown values
  jobCategoryDropD() {
    this.loading = true;
    const pgNo = this.page;
    const recordPage = this.rows;
    this.apisService.getApi(environment.jobCategoryDD + '/' + pgNo + '/' + recordPage).subscribe((res: any) => {
      this.jobTitle = res.data;

      this.totalRecords = res.data.length;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
      this.loading = false;
    });
  }
  // It is used for displaying country dropdown values
  getCountryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'adlc').subscribe(res => {
      this.country = res.data;
    });
  }
  // It is used for displaying state dropdown values
  getStateByUrl(country) {
    if (country === null || '' || undefined) { }
    else {
      this.apisService.getApi(environment.states + country).subscribe(res => {
        this.stateoptions = res.data;
        this.bankdetailsForm.get('state').patchValue(this.stateoptions.filter((x) => x.code === this.bankdetails[0]?.bankLkState)[0]);
      });
    }
  }
  // It is used for industry dropdown values
  industryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'ALIT').subscribe((res: any) => {
      this.industryOptions = res.data;
    });
  }
  // It is used for displaying region dropdown values
  regionDropdown() {
    this.apisService.getApi(environment.lookupsData + 'ALR').subscribe(res => {
      this.regionOptions = res.data;
    });
  }
  // It is used for displaying business type values
  businesTypeList() {
    this.apisService.getApi(environment.lookupsData + 'ALBE').subscribe((res: any) => {
      this.businessType = res.data;
    });
  }
  // It is used to navigate vendors screen
  goto() {
    if (this.type === '3') {
      this.router.navigate(['/vendors']);
    } else {
      this.router.navigate(['/available-vendors']);
    }
  }
  // It is used to show account details data
  vendorAccountDetails() {
    this.loading = true;
    this.apisService.getApi(environment.accountget + this.vendorId).subscribe((res: any) => {
      this.vendorData = res.data[0];
      this.getStateByUrl(this.vendorData.country);
      this.accountDetailsForm.patchValue({
        accountName: this.vendorData.accName,
        accountURL: this.vendorData.website,
        status: this.vendorData.status,
        mailExtension: this.vendorData.website,
        address1: this.vendorData.address1,
        address2: this.vendorData.address2,
        city: this.vendorData.city,
        zip: this.vendorData.zip,
        country: this.vendorData.country,
        state: this.vendorData.state,
        phone: this.vendorData.phone,
        fax: this.vendorData.fax,
        // industry: this.vendorData.industry,
        region: this.vendorData.region,
        noOfEmployees: this.vendorData.noOfEmployees,
        taxId: this.vendorData.taxId,
        stockSymbol: this.vendorData.stockSymbol,
        revenue: this.vendorData.revenue,
        specializedIn: [''],
        dunAndBradStreetNo: this.vendorData.dunsNo,
        // businessType: this.vendorData.businessType,
        diversity: this.vendorData.diversity,
        ownership: [''],
        staffingMode: this.vendorData.noOfEmployees,
        securityDeposit: this.vendorData.noOfEmployees,
        customerNotes: this.vendorData.description,
        mailbox: this.vendorData.mailbox
      });
      this.accountDetailsForm.get('businessType').patchValue(
        this.businessType.filter((x) => x.code === this.vendorData.lkBusinessEntity));
      this.accountDetailsForm.get('industry').patchValue(
        this.industryOptions.filter((x) => x.code === this.vendorData.lkIndustryType));
             this.loading = false;
    });
  }
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
  resetbtn(){
    this.loading = true ;
    this.categoryForm.reset();
    this.rateCardGrid();
 }
 
}