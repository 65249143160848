<p-toast position="bottom-right" key="br"></p-toast>
<p-dialog class="dialogBox" header="Contact Login Credentials" [(visible)]="displayActive" [style]="{width: '25%'}"
  [draggable]="false" [modal]="true" [resizable]="false">
  <p><strong>{{loginActiveData}}</strong></p>
  <a class="aligncenter pointer">({{email1}})</a>
  <br><br>
  <div *ngIf="registerdStatus" class="btnsCSS1">
    <button pButton type="button" icon='pi pi-times' label="Cancel" class="approvebtn" (click)="cancel()"></button>
    <button pButton type="button" icon='pi pi-send' label="Send" (click)="saveUserContacts()" class='buttonsize'
      [disabled]="disable"></button>
  </div>
</p-dialog>
<p-card>
    <p-panel>
        <p-header>
            <strong> Candidate Edit </strong>
            <em class="pi pi-arrow-left panelBackRoute" (click)="goto()"></em>
        </p-header>

        <p-tabView (onChange)="handleChange($event)">
            <p-tabPanel header="Candidate Details">
                <p-card>
                    <form [formGroup]="editConsultantForm">
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">First Name<label class="mandatoryColor">*</label></label>
                                <input id="input" type="text" autocomplete="off" pInputText placeholder="First Name"
                                    maxlength="26" formControlName="firstName">
                                <div>
                                    <span class="validationtest"
                                        *ngIf="this.editConsultantForm.controls.firstName.errors && this.editConsultantForm.controls.firstName.errors?.pattern">Please
                                        enter
                                        alphabets only</span>
                                    <span class="validationtest"
                                        *ngIf="this.editConsultantForm.get('firstName').hasError('maxlength')">
                                        Please enter 25 alphabets only
                                    </span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Middle Name</label>
                                <input id="input" type="text" autocomplete="off" pInputText placeholder="Middle Name"
                                    maxlength="16" formControlName="middleName">
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.controls.middleName.invalid && this.editConsultantForm.controls.middleName.errors?.pattern">Please
                                    enter
                                    alphabets only</span>
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.get('middleName').hasError('maxlength')">
                                    Please enter 15 alphabets only
                                </span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Last Name<label class="mandatoryColor">*</label></label>
                                <input id="input" type="text" autocomplete="off" pInputText placeholder="Last Name"
                                    maxlength="26" formControlName="lastName">
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.get('lastName').touched && this.editConsultantForm.get('lastName').invalid && this.editConsultantForm.get('lastName').errors.required">
                                    Please enter last name</span>
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.controls.lastName.invalid && this.editConsultantForm.controls.lastName.errors?.pattern">Please
                                    enter
                                    alphabets only</span>
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.get('lastName').hasError('maxlength')">
                                    Please enter 25 alphabets only
                                </span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3  invalidMandatory">
                                <label class="inputfields">Date of Birth </label>
                                <p-calendar id="popup" dateFormat="yy-mm-dd" placeholder="MM-DD-YYYY" [showIcon]="true"
                                    [showButtonBar]="true" formControlName="dateOfBirth"
                                    (onClose)='getAge($event.element.ownerDocument.lastModified)'></p-calendar>
                                <p class="mandatoryColor">{{dateOfBirthValidation}}</p>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                                <label class="inputfields">Mobile Number</label>
                                <div class="p-col-12 p-md-12 p-sm-12 mobstyle">
                                    <div class="p-inputgroup" class="p-col-4 p-md-4 mobinput">
                                        <span class="p-inputgroup-addon my-component">
                                            <p-dropdown [options]="countrycodedropdown" class="phonecodestyle newcode"
                                                (onChange)="countryids($event)" filterPlaceholder='Search Results'
                                                optionLabel="label" formControlName="mobileDrp">
                                                <ng-template let-items pTemplate="selectedItem">
                                                    <div class="flex-row">
                                                        <img class="lang-change-img" src={{items.flag}} alt="" />
                                                        <div class="lang-change-option-text selected-lang center">
                                                            {{items.code}}
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template let-country pTemplate="item">
                                                    <div class="flex-row" id="dropdown1">
                                                        <img class="lang-change-img" src={{country.flag}} alt="" />
                                                        <div class="lang-change-option-text center">{{country.label}}
                                                            {{country.code}}</div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </span>
                                    </div>
                                    <div class="p-col-8 p-md-8 phnostyle">
                                        <span class="p-input-icon-right"><em *ngIf="phSpinnerHide"
                                                class="pi pi-spin pi-spinner spinnerstyles"></em>
                                            <em *ngIf="phCheckHide" class="pi pi-check-circle tickmarkstyle"></em>
                                            <p-inputMask autocomplete="off" (onBlur)="getPhon()" mask="(999) 999-9999"
                                                formControlName="mobileNo" placeholder="Mobile Number">
                                            </p-inputMask>
                                        </span>
                                        <p class="validationtest">{{this.officenummsg}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                                <label class="inputfields">Home Number</label>
                                <div class="p-col-12 p-md-12 p-sm-12 mobstyle">
                                    <div class="p-inputgroup" class="p-col-4 p-md-4 mobinput">
                                        <span class="p-inputgroup-addon my-component">
                                            <p-dropdown [options]="countrycodedropdown" class="phonecodestyle newcode"
                                                (onChange)="countryids($event)" filterPlaceholder='Search Results'
                                                optionLabel="label" formControlName="homeNumDrp">
                                                <ng-template let-items pTemplate="selectedItem">
                                                    <div class="flex-row">
                                                        <img class="lang-change-img" src={{items.flag}} alt="" />
                                                        <div class="lang-change-option-text selected-lang center">
                                                            {{items.code}}
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template let-country pTemplate="item">
                                                    <div class="flex-row" id="dropdown1">
                                                        <img class="lang-change-img" src={{country.flag}} alt="" />
                                                        <div class="lang-change-option-text center">{{country.label}}
                                                            {{country.code}}</div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </span>
                                    </div>
                                    <div class="p-col-8 p-md-8 phnostyle">

                                        <span class="p-input-icon-right"><em *ngIf="phSpinnerHide1"
                                                class="pi pi-spin pi-spinner spinnerstyles"></em>
                                            <em *ngIf="phCheckHide1" class="pi pi-check-circle tickmarkstyle"></em>
                                            <p-inputMask autocomplete="off" (onBlur)="getmobi()" mask="(999) 999-9999"
                                                formControlName="homePhone" placeholder="Home Number">
                                            </p-inputMask>
                                        </span>
                                        <p class="validationtest">{{this.mobinummsg}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Email
                                    <!-- <span class="mandatoryColor">*</span>  -->
                                </label>
                                <span class="p-input-icon-right"><em *ngIf="altEmailSpinnerHide"
                                    class="pi pi-spin pi-spinner mail"></em>
                                <em *ngIf="altEmailCheckHide" class="pi pi-check-circle mailcol"></em>
                            </span>
                                <div class="p-inputgroup">
                                    <!-- (blur)="emailverification()" -->
                                    <input id="input" type="text" autocomplete="off" pInputText placeholder="Email" 
                                        formControlName="emailId" maxlength="60" #userInput>

                                    <span class="p-inputgroup-addon"> <em class="pi pi-copy pointer"
                                            (click)="emailToast()" (click)="copyInputMessage(userInput.value)"></em>
                                    </span>
                                </div>
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.controls.emailId.touched && this.editConsultantForm.controls.emailId.invalid && this.editConsultantForm.controls.emailId.errors.required">
                                    Please enter email id</span>
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.controls.emailId.invalid && this.editConsultantForm.controls.emailId.errors?.pattern">Please
                                    enter
                                    email id upto extension only</span>
                                <span *ngIf="displayemail1" class="mandatoryColor">Email already exist</span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Alternate Email</label>
                                <!-- <span class="p-input-icon-right"><em *ngIf="altEmailSpinnerHide"
                                        class="pi pi-spin pi-spinner mail"></em>
                                    <em *ngIf="altEmailCheckHide" class="pi pi-check-circle mailcol"></em>
                                </span> -->
                                <div class="p-inputgroup">
                                    <input id="input" type="text" autocomplete="off" (blur)="AlternateEmail()"
                                        pInputText placeholder="Email" formControlName="alternateEmailId" maxlength="61"
                                        #userInputs>

                                    <span class="p-inputgroup-addon"> <em class="pi pi-copy pointer"
                                            (click)="altEmailToast()" (click)="copyInputMessage(userInputs.value)"></em>
                                    </span>
                                </div>

                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.controls.alternateEmailId.invalid && this.editConsultantForm.controls.alternateEmailId.errors?.pattern">Please
                                    enter
                                    valid email id</span>
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.controls.alternateEmailId.hasError('maxlength')">
                                    Please enter 60 alphabets only
                                </span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Gender </label>
                                <p-dropdown [options]="gender1" formControlName="gender" placeholder="Select Gender"
                                    [showClear]="true" optionLabel="name" optionValue="code">
                                </p-dropdown>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Availability</label>
                                <p-dropdown [options]=" availability" autocomplete="off"
                                    (onChange)='avilableDateModification($event.value.name)' placeholder="Availability"
                                    [showClear]="true" optionLabel="name" formControlName="availability">
                                </p-dropdown>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Available Date <label class="mandatoryColor"
                                        *ngIf="showIcon"></label>
                                </label>
                                <p-calendar [showIcon]="true" [showButtonBar]="true"
                                dateFormat="mm-dd-yy" placeholder="MM-DD-YYYY" formControlName="availableDate">
                                </p-calendar>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                                <label class="inputfields">SSN No</label>
                                <div class="p-col-12 p-md-12 p-sm-12 mobstyle">
                                    <span class="p-input-icon-right">
                                        <p-inputMask autocomplete="off" mask="999-99-9999" formControlName="ssnNo"
                                            maxlength="60" placeholder="SSN Number" [type]="hide ? 'password' : 'text'">
                                        </p-inputMask>
                                    </span>
                                    <span class="p-inputgroup-addon">
                                        <em class='pi pi pi-eye-slash showEye ssnicon' (click)="myFunction()"
                                            *ngIf="hideIcon"></em>
                                        <em class='pi pi-eye showEye ssnicon' (click)="myFunction1()"
                                            *ngIf="showIcon1"></em>
                                    </span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Status</label>
                                <p-dropdown [options]="status2 ==='R'? usrstatus: editStatus" placeholder="Status"
                                    [showClear]="true" optionValue="code" [disabled]="disabled" optionLabel="label"
                                    formControlName="Status">
                                </p-dropdown>
                            </div>
                        </div>
                        <h5 class="header-col">Social Media Profile</h5>
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <label class="inputfields">LinkedIn <em class="fa fa-linkedin-square"
                                        aria-hidden="true"></em></label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon media">linkedin.com/
                                    </span>
                                    <input id="input" autocomplete="off" type="text" pInputText
                                        placeholder='Enter User Name' maxlength=121 formControlName="linkedIn">
                                </div>
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.get('linkedIn').hasError('maxlength')">
                                    Please enter 120 alphabets only
                                </span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <label class="inputfields">Facebook <em class="pi pi-facebook"
                                        aria-hidden="true"></em></label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon media">facebook.com/
                                    </span>
                                    <input id="input" autocomplete="off" type="text" pInputText
                                        placeholder='Enter User Name' maxlength=121 formControlName="facebook">
                                </div>
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.get('facebook').hasError('maxlength')">
                                    Please enter 120 alphabets only
                                </span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <label class="inputfields">Twitter <em class="pi pi-twitter"
                                        aria-hidden="true"></em></label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon media">twitter.com/
                                    </span>
                                    <input id="input" autocomplete="off" type="text" pInputText
                                        placeholder='Enter User Name' maxlength=121 formControlName="twitter">
                                </div>
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.get('twitter').hasError('maxlength')">
                                    Please enter 120 alphabets only
                                </span>
                            </div>
                        </div>
                        <br>
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <div id="cssdiv">Permanent Address </div><br>
                                <div class="p-grid p-fluid">
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                                        <label class="inputfields">Address Line 1 </label>
                                        <input type="text" autocomplete="off" pInputText placeholder="Address Line 1"
                                            maxlength="51" (click)="disableCheckboxValue()"
                                            formControlName="permanentAddressLine1">
                                        <div>
                                            <span class="validationtest"
                                                *ngIf="this.editConsultantForm.get('permanentAddressLine1').hasError('maxlength')">
                                                Please enter 50 alphabets only
                                            </span>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
                                        <label class="inputfields">Address Line 2</label>
                                        <input type="text" autocomplete="off" pInputText placeholder="Address Line 2"
                                            maxlength="51" (click)="disableCheckboxValue()"
                                            formControlName="permanentAddressLine2">
                                        <div>
                                            <span class="validationtest"
                                                *ngIf="this.editConsultantForm.get('permanentAddressLine2').hasError('maxlength')">
                                                Please enter 50 alphabets only
                                            </span>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                        <label class="inputfields">Mailbox</label>
                                        <input type="text" autocomplete="off" maxlength="11" pInputText
                                            placeholder="Mailbox" formControlName="mailbox1">
                                        <div>
                                            <span class="validationtest"
                                                *ngIf="this.editConsultantForm.get('mailbox1').hasError('maxlength')">
                                                Please enter 10 alphabets only
                                            </span>
                                            <span class="validationtest"
                                                *ngIf="this.editConsultantForm.get('mailbox1').invalid && this.editConsultantForm.get('mailbox1').errors.pattern">
                                                Please enter numbers only
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-grid p-fluid">
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                        <label class="inputfields">Country </label>
                                        <p-dropdown [options]="livingCountry" placeholder="Country" optionValue="code"
                                            (click)="disableCheckboxValue()" optionLabel="label" [showClear]="true"
                                            (onChange)="getStateByUrl($event.value)" formControlName="permanentCountry">
                                        </p-dropdown>
                                        <div>

                                        </div>
                                    </div>
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
                                        <label class="inputfields">State </label>
                                        <p-dropdown [options]="permanentStates" placeholder="State"
                                            (click)="disableCheckboxValue()" optionLabel="label" [showClear]="true"
                                            formControlName="permanentState">
                                        </p-dropdown>
                                        <div>

                                        </div>
                                    </div>
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
                                        <label class="inputfields">City </label>
                                        <input type="text" autocomplete="off" pInputText placeholder="City"
                                            maxlength="21" (click)="disableCheckboxValue()"
                                            formControlName="permanentCity">
                                        <div>
                                            <span class="validationtest"
                                                *ngIf="this.editConsultantForm.get('permanentCity').hasError('maxlength')">
                                                Please enter 20 alphabets only
                                            </span>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
                                        <label class="inputfields">Zip</label>
                                        <input type="text" autocomplete="off" pInputText pKeyFilter="num"
                                            placeholder="Zip" maxlength="11" (click)="disableCheckboxValue()"
                                            formControlName="permanentZip">
                                        <div>
                                            <span class="validationtest"
                                                *ngIf="this.editConsultantForm.get('permanentZip').hasError('maxlength')">
                                                Please enter 10 alphabets only
                                            </span>
                                            <span class="validationtest"
                                                *ngIf="this.editConsultantForm.get('permanentZip').invalid && this.editConsultantForm.get('permanentZip').errors.pattern">
                                                Please enter numbers only
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <div id="cssdiv"><span>Mailing Address </span>
                                    <p-checkbox name="mailingAdressCheckbox" [value]="true"
                                        (onChange)="disableMailAd($event)" label="Same as Permanent Address"
                                        formControlName="mailingAdressCheckbox">
                                    </p-checkbox>
                                </div>
                                <br>
                                <div class="p-grid p-fluid">
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                                        <label class="inputfields">Address Line 1 </label>
                                        <input type="text" autocomplete="off" maxlength="51" pInputText
                                            placeholder="Address Line 1" formControlName="mailingAddressLine1">
                                        <div>
                                            <span class="validationtest"
                                                *ngIf="this.editConsultantForm.get('mailingAddressLine1').hasError('maxlength')">
                                                Please enter 50 alphabets only
                                            </span>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
                                        <label class="inputfields">Address Line 2</label>
                                        <input type="text" autocomplete="off" maxlength="51" pInputText
                                            placeholder="Address Line 2" formControlName="mailingAddressline2">
                                        <div>
                                            <span class="validationtest"
                                                *ngIf="this.editConsultantForm.get('mailingAddressline2').hasError('maxlength')">
                                                Please enter 50 alphabets only
                                            </span>
                                        </div>

                                    </div>
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                        <label class="inputfields">Mailbox</label>
                                        <input type="text" autocomplete="off" maxlength="11" pInputText
                                            placeholder="Mailbox" formControlName="mailbox2">
                                        <div>
                                            <span class="validationtest"
                                                *ngIf="this.editConsultantForm.get('mailbox2').hasError('maxlength')">
                                                Please enter 10 alphabets only
                                            </span>
                                            <span class="validationtest"
                                                *ngIf="this.editConsultantForm.get('mailbox2').invalid && this.editConsultantForm.get('mailbox2').errors.pattern">
                                                Please enter numbers only
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-grid p-fluid">
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                                        <label class="inputfields">Country<label
                                                class="mandatoryColor">*</label></label>
                                        <p-dropdown [options]="livingCountry" placeholder="Country"
                                            formControlName="mailingCountry" [showClear]="true"
                                            (onChange)="getStateByUrl1($event.value.code)" optionLabel="label">
                                        </p-dropdown>
                                        <div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
                                        <label class="inputfields">State </label>
                                        <p-dropdown [options]="permanentStates1" placeholder="State" [showClear]="true"
                                            formControlName="mailingState" optionLabel="label">
                                        </p-dropdown>
                                        <div>

                                        </div>
                                    </div>
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                        <label class="inputfields">City </label>
                                        <input type="text" autocomplete="off" maxlength="21" pInputText
                                            placeholder="City" formControlName="mailingCity">
                                        <div>
                                            <span class="validationtest"
                                                *ngIf="this.editConsultantForm.get('mailingCity').hasError('maxlength')">
                                                Please enter 20 alphabets only
                                            </span>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                        <label class="inputfields">Zip </label>
                                        <input type="text" autocomplete="off" pInputText pKeyFilter="num"
                                            placeholder="Zip" formControlName="mailingZip" maxlength="11">
                                        <div>
                                            <span class="validationtest"
                                                *ngIf="this.editConsultantForm.get('mailingZip').hasError('maxlength')">
                                                Please enter 10 alphabets only
                                            </span>
                                            <span class="validationtest"
                                                *ngIf="this.editConsultantForm.get('mailingZip').invalid && this.editConsultantForm.get('mailingZip').errors.pattern">
                                                Please enter numbers only
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory skills">
                                <label class="inputfields">Primary Skill Set<span
                                        class="mandatoryColor">*</span></label>
                                <p-multiSelect [options]="skillsCategory" optionLabel="label"
                                    placeholder="Select Primary Skill Set" (onChange)="primarySkillsData($event)"
                                    formControlName="primarySkills">
                                </p-multiSelect>
                                <div class="skillstyle">
                                    <label><strong>Primary Skill Set: </strong></label>
                                    <span >{{primarySkill.toString()}}</span>
                                </div>
                                <div>
                                    <span class="validationtest"
                                        *ngIf="this.editConsultantForm.get('primarySkills').touched && this.editConsultantForm.get('primarySkills').invalid && this.editConsultantForm.get('primarySkills').errors.required">
                                        Please select primary skill set
                                    </span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <label class="inputfields">Secondary Skill Set<label
                                        class="mandatoryColor">*</label></label>
                                <p-multiSelect [options]="skillsCategory" optionLabel="label"
                                    placeholder="Select Secondary Skill Set" (onChange)="secondarySkillData($event)"
                                    formControlName="secondarySkills">
                                </p-multiSelect>
                                <div>
                                    <div class="skillstyle">
                                        <label><strong>Secondary Skill Set: </strong></label>
                                        <span>  {{secondarySkill.toString()}}</span>
                                    </div>
                                    <span class="validationtest"
                                        *ngIf="this.editConsultantForm.get('secondarySkills').touched && this.editConsultantForm.get('secondarySkills').invalid && this.editConsultantForm.get('secondarySkills').errors.required">
                                        Please select secondary skill set
                                    </span>
                                </div>

                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6" *ngIf=othPriSkillsField>
                                <label class="inputfields">Other Primary Skills<label class="mandatoryColor">*</label>
                                </label>
                                <input id="input" type="text" autocomplete="off" pInputText maxlength="100"
                                    placeholder="Other Primary Skills" formControlName="otherPrimarySkills">
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.get('otherPrimarySkills').touched && this.editConsultantForm.get('otherPrimarySkills').invalid && this.editConsultantForm.get('otherPrimarySkills').errors.required">
                                    Please select other primary skills
                                </span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6" *ngIf=othSecSkillsField>
                                <label class="inputfields">Other Secondary Skills<label
                                        class="mandatoryColor">*</label></label>
                                <input id="input" type="text" autocomplete="off" pInputText maxlength="100"
                                    placeholder="Other Secondary Skills" formControlName="otherSecondarySkills">
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.get('otherSecondarySkills').touched && this.editConsultantForm.get('otherSecondarySkills').invalid && this.editConsultantForm.get('otherSecondarySkills').errors.required">
                                    Please enter other secondary skills
                                </span>
                            </div>
                        </div>

                        <br>
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Job Title<label class="mandatoryColor">*</label></label>
                                <input id="input" autocomplete="off" type="text" pInputText placeholder="Job Title"
                                    maxlength="31" formControlName="jobTitle">
                                <div>
                                    <span class="validationtest"
                                        *ngIf="this.editConsultantForm.get('jobTitle').touched && this.editConsultantForm.get('jobTitle').invalid && this.editConsultantForm.get('jobTitle').errors.required">
                                        Please enter job title
                                    </span>
                                    <span class="validationtest"
                                        *ngIf="this.editConsultantForm.get('jobTitle').hasError('maxlength')">
                                        Please enter 30 alphabets only
                                    </span>
                                    <span class="validationtest"
                                        *ngIf="this.editConsultantForm.controls.jobTitle.invalid && this.editConsultantForm.controls.jobTitle.errors.pattern">Please
                                        enter
                                        alphabets only</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3  invalidMandatory">
                                <label class="inputfields">Experience </label>

                                <p-dropdown [options]="experienceData" placeholder="Experience" [showClear]="true"
                                    formControlName="experience" optionLabel="label" optionValue="code">
                                </p-dropdown>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Current Rate</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}}
                                    </span>
                                    <input id="input" autocomplete="off" type="text" pInputText
                                        placeholder="Current Rate" formControlName="currentRate" maxlength="30">
                                    <span class="p-inputgroup-addon">/Hrs</span>
                                </div>
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.get('currentRate').invalid && this.editConsultantForm.get('currentRate').errors.pattern">
                                    Please enter numbers only
                                </span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Current CTC</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}}
                                    </span>
                                    <input id="input" autocomplete="off" type="text" pInputText
                                        placeholder="Current CTC" formControlName="currentCTC" maxlength="30">
                                    <span class="p-inputgroup-addon">/Yr</span>
                                </div>
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.get('currentCTC').invalid && this.editConsultantForm.get('currentCTC').errors.pattern">
                                    Please enter numbers only
                                </span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Expected MinRate</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}}
                                    </span>
                                    <input id="input" autocomplete="off" type="text" pInputText
                                        placeholder="Expected MinRate" formControlName="expMinRate" maxlength="30">
                                    <span class="p-inputgroup-addon">/Hrs</span>
                                </div>
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.get('expMinRate').invalid && this.editConsultantForm.get('expMinRate').errors.pattern">
                                    Please enter numbers only
                                </span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Expected MaxRate<label
                                        class="mandatoryColor">*</label></label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}}
                                    </span>
                                    <input id="input" autocomplete="off" type="text" pInputText
                                        placeholder="Expected MaxRate" formControlName="expMaxRate" maxlength="30">
                                    <span class="p-inputgroup-addon">/Hrs</span>
                                </div>
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.get('expMaxRate').touched && this.editConsultantForm.get('expMaxRate').invalid && this.editConsultantForm.get('expMaxRate').errors.required">
                                    Please enter expected maxrate
                                </span>
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.get('expMaxRate').invalid && this.editConsultantForm.get('expMaxRate').errors.pattern">
                                    Please enter numbers only
                                </span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Expected MinCTC</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}}
                                    </span>
                                    <input id="input" autocomplete="off" type="text" pInputText
                                        placeholder="Expected MinCTC" formControlName="expMinCTC" maxlength="30">
                                    <span class="p-inputgroup-addon">/Yr</span>
                                </div>
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.get('expMinCTC').invalid && this.editConsultantForm.get('expMinCTC').errors.pattern">
                                    Please enter numbers only
                                </span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Expected MaxCTC</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}}
                                    </span>
                                    <input id="input" autocomplete="off" type="text" pInputText
                                        placeholder="Expected MaxCTC" formControlName="expMaxCTC" maxlength="30">
                                    <span class="p-inputgroup-addon">/Yr</span>
                                </div>
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.get('expMaxCTC').invalid && this.editConsultantForm.get('expMaxCTC').errors.pattern">
                                    Please enter numbers only
                                </span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                                <label class="inputfields">Education</label>
                                <textarea autocomplete="off" placeholder="Education" pInputTextarea rows="2" cols="30"
                                    formControlName="education" maxlength="11"></textarea>
                                <div>
                                    <span class="validationtest"
                                        *ngIf="this.editConsultantForm.get('education').hasError('maxlength')">
                                        Please enter 10 alphabets only
                                    </span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Referred By</label>
                                <input id="input" autocomplete="off" type="text" pKeyFilter="alpha" pInputText
                                    placeholder="Referred by" maxlength="30" formControlName="referredBy">
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Referred Date </label>
                                <p-calendar id="popup" dateFormat="yy-mm-dd" placeholder="MM-DD-YYYY" [showIcon]="true"
                                    [showButtonBar]="true" formControlName="refDate"
                                    (onClose)='getAge($event.element.ownerDocument.lastModified)'></p-calendar>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6"></div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3  invalidMandatory">
                                <label class="inputfields">Working Country </label>
                                <p-dropdown [options]="livingCountry" placeholder="Working Country" [showClear]="true"
                                    (onChange)="getStateByUrl($event.value.code)" optionLabel="label"
                                    formControlName="workingCountry">
                                </p-dropdown>
                                <div>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Currency</label>
                                <p-dropdown [options]="currencyData" placeholder="Currency" [showClear]="true"
                                    (onChange)='relocDropDown($event)' optionLabel="label" formControlName="currency">
                                </p-dropdown>
                            </div>

                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Relocation</label>
                                <p-dropdown [options]="relocation" placeholder="Relocation" [showClear]="true"
                                    (onChange)='relocDropDown($event.value.code)' optionLabel="name"
                                    formControlName="relocation">
                                </p-dropdown>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3"></div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Living Country</label>
                                <p-dropdown [options]="livingCountry" placeholder="Living Country" optionLabel="label"
                                    [showClear]="true" formControlName="livingCountry">
                                </p-dropdown>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Consultant Work Status<label
                                        class="mandatoryColor">*</label></label>
                                <p-dropdown [options]="consultant" placeholder="Consultant Work Status"
                                    [showClear]="true" formControlName="consultantworkstatus" optionLabel="label"
                                    optionValue="code">
                                </p-dropdown>
                                <span class="validationtest"
                                    *ngIf="editConsultantForm.get('consultantworkstatus').touched && editConsultantForm.get('consultantworkstatus').invalid && editConsultantForm.get('consultantworkstatus').errors.required">
                                    Please select Consultant Work Status</span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6"></div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory " *ngIf=prefCountry>
                                <label class="inputfields">Preferred Country </label>
                                <p-dropdown [options]="livingCountry" placeholder="Preferred Country" [showClear]="true"
                                    (onChange)="getStateForRelocation($event.value.code)" optionLabel="label"
                                    formControlName="preferredCountry">
                                </p-dropdown>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory" *ngIf=regField>
                                <label class="inputfields">preferred Region </label>
                                <p-dropdown [options]="regionList" placeholder="Preferred Region" [showClear]="true"
                                    (onChange)="getRegionBasedStates($event.value.name)" optionLabel="region_id"
                                    formControlName="preferredRegion">
                                </p-dropdown>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory" *ngIf=usStateField>
                                <label class="inputfields">Preferred State </label>
                                <p-multiSelect [options]="regionBasedStates" [showToggleAll]="false"
                                    formControlName="preferredUsaState" placeholder="Preferred States"
                                    [panelStyle]="{minWidth:'12em'}" optionLabel="name" [filter]='true'>
                                </p-multiSelect>
                            </div>
                            <div class="p-col-3 p-md-3 p-sm-12 p-lg-3 invalidMandatory" *ngIf=nonUsStateField>
                                <label class="inputfields">Preferred State </label>
                                <p-multiSelect [options]="nonUsStateList" [showToggleAll]="false"
                                    formControlName="preferredNonUsaState" placeholder="Preferred States"
                                    [panelStyle]="{minWidth:'12em'}" optionLabel="name" [filter]='true'>
                                </p-multiSelect>
                            </div>

                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <label class="inputfields">Consultant Work Status Comments</label>
                                <textarea autocomplete="off" placeholder="Consultant Work Status Comments"
                                    pInputTextarea maxlength="501" formControlName="consultantworkstatusComments"
                                    rows="8" cols="30"></textarea>
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.get('consultantworkstatusComments').hasError('maxlength')">
                                    please enter 500 alphabets only
                                </span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <label class="inputfields">Comments</label>
                                <textarea placeholder="Comments" autocomplete="off" pInputTextarea
                                    formControlName="comments" maxlength="501" rows="8" cols="30"></textarea>
                                <span class="validationtest"
                                    *ngIf="this.editConsultantForm.get('comments').hasError('maxlength')">
                                    please enter 500 alphabets only
                                </span>
                            </div>

                        </div>
                        <div class="btnsCSS1">
                            <button class="addhBtn" [disabled]="buttondisable" pButton type="submit" icon='pi pi-save'
                                (click)="consultantUpdateForm()" label="Update"></button>
                        </div><br><br>
                    </form>
                </p-card>
            </p-tabPanel>
            <p-tabPanel header="M Vault ">
                <p-dialog class="dialogBox" header="Upload Attachment" [(visible)]="display" [modal]="true"
                    [style]="{width: '30%'}" [contentStyle]="{'overflow': 'visible'}" [draggable]="false"
                    [resizable]="false">
                    <form [formGroup]="mvaultForm">
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                                <label class="inputfields">Attachment Title<label
                                        class="mandatoryColor">*</label></label>
                                <input id="input" type="text" autocomplete="off" pInputText
                                    placeholder="Attachment Title" maxlength="41" formControlName="attachmentTitle">
                                <div>
                                    <span class="validationtest"
                                        *ngIf="this.mvaultForm.get('attachmentTitle').touched && this.mvaultForm.get('attachmentTitle').invalid && this.mvaultForm.get('attachmentTitle').errors.required">
                                        Please enter attachment title
                                    </span>
                                    <span class="validationtest"
                                        *ngIf="this.mvaultForm.controls.attachmentTitle.invalid && this.mvaultForm.controls.attachmentTitle.errors?.pattern">Please
                                        enter
                                        alphabets only</span>
                                    <span class="validationtest"
                                        *ngIf="this.mvaultForm.get('attachmentTitle').hasError('maxlength')">
                                        Please enter 40 alphabets only
                                    </span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                                <label class="inputfields">Attachment Type </label>
                                <p-dropdown [options]="protocol" placeholder="Attachment Type" optionLabel="label"
                                    [showClear]="true" formControlName="dataAttachements">

                                </p-dropdown>
                                <span *ngIf="displayattatype" class="mandatoryColor">Select the attachment type </span>

                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                                <label class="inputfields">Attach File<label
                                        class="mandatoryColor">*</label></label><br>
                                <!-- *ngIf="attachfiles" -->
                                <input *ngIf="attachmentfile" formControlName="attachmentform" type="file"
                                    id="attachment" accept=".doc,.docx,application/msword,application/pdf"
                                    (change)="handleFileInput($event)">

                                <input formControlName="attachmentform" type="file" id="attachment"
                                    *ngIf="profileupload" accept=".doc,.docx,application/msword,application/pdf"
                                    (change)="handleFileInput($event)">
                                <span class="p-input-icon-right">
                                    <div class="p-sm-12 p-lg-2 iconsalignments">
                                        <em *ngIf="filesdatass" class="pi pi-times" style="cursor: pointer;"
                                            (click)="cancelfiles()"></em>
                                    </div>
                                </span>
                                <div class="validationtest" style="margin-top: -9px; margin-bottom: 9px;"
                                    *ngIf="this.mvaultForm.get('attachmentform').touched && this. mvaultForm.get('attachmentform').invalid && this. mvaultForm.get('attachmentform').errors.required">
                                    Please upload attachment
                                </div>
                                <span class="p-input-icon-right">
                                    <div class="p-sm-12 p-lg-12 alignmenticon">
                                        <i *ngIf="crossicon" class="pi pi-times" (click)="attachfile()"></i>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="btnsCSS1 ">
                            <button class="addhBtn" pButton type="submit" icon="pi pi-upload" label="Upload"
                                [disabled]="disable" style="float: right;" (click)="uploadAttachment()"></button>
                        </div><br><br>
                    </form>
                </p-dialog>
                <p-card class="pgpos">
                    <div *ngIf="isUploading" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                        <p-progressBar mode="indeterminate"></p-progressBar>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                        <div class="btnsCSS1 pos">
                            <button type="button" (click)="showDialog()" pButton icon="pi pi-plus" class="addhBtn"
                                label="Add"></button>
                        </div>
                        <br>
                        <br>
                    </div>
                    <div *ngIf="table">
                        <p-table [rows]="6" [columns]="cols" [value]="resumes" selectionMode="single" dataKey="edit"
                            [autoLayout]="true" [responsive]="true" sortMode="multiple" [autoLayout]="true">
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                                        <span>
                                            {{col.header}}

                                        </span>
                                    </th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
                                <tr>
                                    <td class="textCenter pointer"><span class="ui-column-title"> </span><a
                                            (click)="checkResumeExistOrNot(data.acc_attachment_id, data)">
                                            {{data.fileName}}</a>
                                    </td>
                                    <td class="textCenter"><span class="ui-column-title"> </span>{{data.title}}</td>
                                    <td class="textCenter"><span class="ui-column-title"></span>
                                        {{lkattachementtype[data.lkAttachmentType]}}
                                    </td>
                                    <td class="textCenter"><span class="ui-column-title"></span>
                                        {{data.dateOfAttachment}}
                                    </td>

                                    <td><span class="ui-column-title"></span>
                                        <p-badge [value]="statusTable[data.lkStatus]"
                                            [ngClass]="data.lkStatus ==='A'?'green': 'red'">
                                        </p-badge>
                                    </td>
                                    <td class="aligncenter"><span class="p-column-title"></span><a class="pointer">
                                            <em (click)="editActionPopup.toggle($event); toggle($event,data, rowData)"
                                                class="pi pi-ellipsis-v alignleft"></em>
                                        </a> </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="p-grid ui-fluid">

                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                                <h1></h1>
                                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                                    (onPageChange)="paginate($event)"></p-paginator>
                            </div>
                        </div>
                    </div>

                    <p-card *ngIf="totalRecords === 0">
                        <div class="norecordImgsize">
                            <div class="aligncenter">
                                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png"
                                    class="norecordimg">
                                <br><em>Sorry, we are not able to find any customer</em><br>
                            </div>
                        </div>
                    </p-card>

                </p-card>
            </p-tabPanel>
        </p-tabView>
    </p-panel>
</p-card>



<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-menu class="menuclass" #editActionPopup [popup]="true" [model]="items"></p-menu>
<p-dialog class="dialogBox" header="Edit Attachment" [(visible)]="editDisplay" [modal]="true" [style]="{width: '30%'}"
    [contentStyle]="{'overflow': 'visible'}" [draggable]="false" [resizable]="false">
    <form [formGroup]="editAttachmentForm">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Attachment Name</label>
                <input id="input" disabled='true' type="text" autocomplete="off" pInputText
                    placeholder="Attachment Name" maxlength="41" formControlName="attachmentName">
                <span class="validationtest"
                    *ngIf="this.editAttachmentForm.get('attachmentName').hasError('maxlength')">
                    Please enter 40 alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Attachment Title</label>
                <input id="input" type="text" autocomplete="off" pInputText placeholder="Attachment Title"
                    maxlength="41" formControlName="attachmentTitle">
                <div>
                    <span class="validationtest"
                        *ngIf="this.editAttachmentForm.get('attachmentTitle').hasError('maxlength')">
                        Please enter 40 alphabets only
                    </span>
                    <span class="validationtest"
                        *ngIf="this.editAttachmentForm.controls.attachmentTitle.invalid && this.editAttachmentForm.controls.attachmentTitle.errors?.pattern">Please
                        enter
                        alphabets only</span>
                </div>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Attachment Type</label>
                <p-dropdown [options]="protocol" formControlName="attachmentType" placeholder="Select Attachment Type"
                    [showClear]="true" optionLabel="label">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Status</label>
                <p-dropdown [options]="editStatus" formControlName="attachmentStatus" [showClear]="true"
                    placeholder="Select Attachment Type" optionLabel="label">
                </p-dropdown>

            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Comments</label>
                <textarea [rows]="5" [cols]="30" placeholder="Comments " pInputTextarea
                    formControlName="attachmentComments" autocomplete="off" [rows]="2" [cols]="30"
                    maxlength="201"></textarea>
                <div>
                    <span class="validationtest"
                        *ngIf="this.editAttachmentForm.get('attachmentComments').hasError('maxlength')">
                        Please enter 200 alphabets only
                    </span>
                </div>
            </div>
        </div><br>
        <div class="btnsCSS1">
            <button class="addhBtn" pButton type="submit" [disabled]="disable" icon="pi pi-save" label="Update"
                (click)="updateMvault()"></button>
        </div>
    </form>
</p-dialog>