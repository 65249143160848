import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-employer',
  templateUrl: './employer.component.html',
  styleUrls: ['./employer.component.scss'],
  providers: [DatePipe, MessageService],
})
export class EmployerComponent implements OnInit {
  employerScreen: FormGroup;
  // It is used for displaying table header data
  cols = [
    { field: 'employerName', header: 'Employer Name', width: '10%' },
    { field: 'title', header: ' Designation', width: '10%' },
    { field: 'startDate', header: 'Start Date', width: '10%' },
    { field: 'endDate', header: 'End Date', width: '10%' },
    { field: 'action', header: 'Action', width: '5%' },
  ];
  loader: boolean;
  id: any;
  subbtn = false;
  page: any = 1;
  rows: any = 10;
  res: any;
  data: any;
  gridData: any;
  loginData: any;
  usrId: any;
  totalRecords: number;
  length: number;
  displayModal: boolean;
  editModal: boolean;
  addEmployerScreen: FormGroup;
  getData: any;
  hide: boolean;
  data1: any;
  show1 = false;
  show = false;
  organisationName: string;
  employerNameList: any = [];
  employerName1: any;
  fromdate: any;
  fromDatevalue: any;
  endDatevalue: string;
  endDate: string;
  fromDate: string;
  workLocationId: any;
  workLoc: any;
  totalrecords: any;
  records: boolean;
  table: boolean;
  first: any;
  pageCount: any;
  new: any;
  editEmployerScreen: FormGroup;
  items: { label: string; icon: string; command: () => void; }[];
  disable = false;
  endDate1: any;
  year: string;
  date: boolean;
  endDateChecker: boolean;
  patchlocationString: string;
  editLocationdata: any;
  // tslint:disable-next-line:max-line-length
  constructor(private router: Router, private formBuilder: FormBuilder, private messageService: MessageService, private datepipe: DatePipe, private breadcrumbService: BreadcrumbService, private apisService: ApisService) {
    this.breadcrumbService.setItems([
      { label: 'Employers', routerLink: '/employee-employer' }
    ]);
  }
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.search();
    }
  }
  ngOnInit(): void {
    this.employerScreen = this.formBuilder.group({
      employerName: [''],
      designation: [''],
      startdate: [''],
      enddate: [''],
    });
    this.addEmployerScreen = this.formBuilder.group({
      addTitle: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(15)]],
      organisationName: ['', Validators.required],
      dateCheck: [''],
      organisationText: [''],
      project: [''],
      fromDate: ['', Validators.required],
      toDate: [''],
      comment: ['', Validators.maxLength(200)],
      workLocName: [''],
    });
    this.editEmployerScreen = this.formBuilder.group({
      addTitle: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(15)]],
      organisationName: ['', Validators.required],
      dateCheck: [''],
      organisationText: [''],
      project: [''],
      fromDate: ['', Validators.required],
      toDate: [''],
      comment: ['', Validators.maxLength(200)],
      workLocName: [''],
    });
    this.gridTable();
    this.getorganisationDD();
  }
  // It is used for reset the form
  reset() {
    this.employerScreen.reset();
    this.gridTable();
  }
  // It is used to get the grid data
  gridTable() {
    const actId = JSON.parse(this.apisService.decodeData());
    this.loader = true;
    this.apisService.getApi(environment.getemployement + '/' + 1 + '/' + 10 + '/' + actId.userId + '?').subscribe((res: any) => {
      this.gridData = res.data;
      this.totalRecords = res.Count;
      this.loader = false;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
   // It is used to show action pop up
  toggle(event, data) {
    this.items = [{ label: 'Edit Details', icon: 'pi pi-fw pi-pencil', command: () => { this.edit(data); }, }];
  }
  // It is used for display add pop up
  add() {
    this.show = false;
    this.addEmployerScreen.controls.toDate.enable();
    this.displayModal = true;
    this.addEmployerScreen.reset();
  }
  // It is used for  To Date disable/enable based on check box
  disableAddCheckboxValue(data) {
    if (data.checked === true) {
      this.addEmployerScreen.controls.toDate.disable();
      this.show = true;
    }
    else {
      this.show = false;
      this.disableCheckboxValue();
    }
  }
  disableCheckboxValue() {
    this.addEmployerScreen.controls.toDate.enable();
  }
   // It is used for To Date enable
  disableCheckboxValue1() {
    this.addEmployerScreen.controls.toDate.enable();
  }
  // It is used to get the employer name dropdown
  getorganisationDD() {
    this.apisService.getApi(environment.accountnames).subscribe((res) => {
      this.employerNameList = res.data;
    });
  }
  // It is used to get the location dropdown
  getworkLocByUrl(id) {
    this.apisService.getApi(environment.getLocationList + id).subscribe(res => {
      this.workLoc = res.data;
    });
  }
  // It is used for the searching the the data based on the grid data
  search() {
    this.loader = true;
    // tslint:disable-next-line:max-line-length
    const employerName = this.employerScreen.controls.employerName.value === undefined || this.employerScreen.controls.employerName.value === '' || this.employerScreen.controls.employerName.value === null ? '' : this.employerScreen.controls.employerName.value.id;
    // tslint:disable-next-line:max-line-length
    const title = this.employerScreen.controls.designation.value === undefined || this.employerScreen.controls.designation.value === '' || this.employerScreen.controls.designation.value === null ? '' : this.employerScreen.controls.designation.value;
    // tslint:disable-next-line:max-line-length
    const startDate = this.employerScreen.controls.startdate.value === undefined || this.employerScreen.controls.startdate.value === null || this.employerScreen.controls.startdate.value === '' ? '' : this.datepipe.transform(this.employerScreen.controls.startdate.value, 'MM-dd-yyyy');
    // tslint:disable-next-line:max-line-length
    const endDate = this.employerScreen.controls.enddate.value === undefined || this.employerScreen.controls.enddate.value === null || this.employerScreen.controls.enddate.value === '' ? '' : this.datepipe.transform(this.employerScreen.controls.enddate.value, 'MM-dd-yyyy');
    const actId = JSON.parse(this.apisService.decodeData());
    // tslint:disable-next-line:max-line-length
    const data = this.page + '/' + this.rows + '/' + actId.userId + '?employerId=' + employerName + '&title=' + title + '&startDate=' + startDate + '&endDate=' + endDate;
    this.apisService.getApi(environment.getemployement + '/' + data).subscribe((res: any) => {
      this.loader = false;
      this.gridData = res.data;
      this.totalRecords = res.Count;
      this.loader = false;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
   // It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.search();
  }
  // It is used to insert the data
  addemp() {
    const actId = JSON.parse(this.apisService.decodeData());
    if (this.addEmployerScreen.invalid) {
      const controls = this.addEmployerScreen.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    }
    else {
      this.hide = true;
      this.disable = true;
      if (
        // tslint:disable-next-line:max-line-length
        this.addEmployerScreen.controls.fromDate.value === '' || this.addEmployerScreen.controls.fromDate.value === null || this.addEmployerScreen.controls.fromDate.value === undefined
      ) {
        this.fromDate = '';
      } else {
        const startdate =
          this.addEmployerScreen.controls.fromDate.value;
        const reqstartDate = new Date(startdate);
        this.fromDatevalue = this.datepipe.transform(reqstartDate, 'MM-dd-yyy');
      }
      // tslint:disable-next-line:max-line-length
      if (this.addEmployerScreen.controls.toDate.value === '' || this.addEmployerScreen.controls.toDate.value === null || this.addEmployerScreen.controls.toDate.value === undefined
      ) {
        this.endDate = '';
      } else {
        const enddate = this.addEmployerScreen.controls.toDate.value;
        const reqendDate = new Date(enddate);
        this.endDatevalue = this.datepipe.transform(reqendDate, 'MM-dd-yyyy');
      }
      const data1 = {
        title: this.addEmployerScreen.controls.addTitle.value,
        employerId: this.addEmployerScreen.controls.organisationName.value.id,
        projectId: 1,
        startDate: this.fromDatevalue,
        endDate: this.endDatevalue,
        description: this.addEmployerScreen.controls.comment.value,
        // tslint:disable-next-line:max-line-length
        workLocationId: this.addEmployerScreen.controls.workLocName.value === null || this.addEmployerScreen.controls.workLocName.value ===
          // tslint:disable-next-line:max-line-length
          undefined || this.addEmployerScreen.controls.workLocName.value === '' ? '' : this.addEmployerScreen.controls.workLocName.value.locId,
        lkWorkType: 'A',
        employeeId: 1,
        customerId: 1,
        lkEmploymentType: 'A',
        usrId: actId.userId,
      };
      this.subbtn = true;
      this.apisService.postApi(environment.saveemployment, data1).subscribe((res: any) => {
        this.getData = res;
        this.hide = false;
        this.displayModal = false;
        this.gridTable();
        this.addEmployerScreen.reset();
        if (this.getData.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message });
          this.disable = false;
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error });
        }
      });
    }
  }
  // It is used to patch the values
  edit(data) {
    this.editEmployerScreen.controls.dateCheck.reset();
    this.editModal = true;
    this.new = data.id;
    this.editEmployerScreen.controls.addTitle.patchValue(data.title);
    this.editEmployerScreen.controls.organisationName.patchValue(this.employerNameList.filter(x => x.id === data.employerId)[0]);
    this.getworkLocByUrl(data.employerId);
    this.apisService.getApi(environment.getLocationList + data.employerId).subscribe(res => {
      this.workLoc = res.data;
      this.editEmployerScreen.controls.workLocName.patchValue(this.workLoc.filter(x => x.locId === data.workLocationId)[0]);
      this.editLocationdata = this.workLoc.filter(x => x.locId === data.workLocationId)[0]
      this.patchlocationString = this.editLocationdata.name + '\n' + this.editLocationdata.address1 + ',' + this.editLocationdata.city + ',' + this.editLocationdata.state + ',' + this.editLocationdata.country
    });
    this.endDate1 = data?.endDate?.toString();
    if (this.endDate1 === '' || this.endDate1 === undefined) {
      this.editEmployerScreen.controls.dateCheck.patchValue(true);
      this.show = true;
    } else {
      this.year = '1970-01-05';
      if (this.endDate1.search(this.year) === -1) {
        this.date = true;
        this.show = false;
      }
      else {
        this.editEmployerScreen.controls.dateCheck.patchValue(true);
        this.show = true;
      }
      this.editEmployerScreen.controls.toDate.patchValue(new Date(data.endDate));
    }
    this.editEmployerScreen.controls.fromDate.patchValue(new Date(data.startDate));
    this.editEmployerScreen.controls.comment.patchValue(data.description);
  }
  dateChecker() {
    if (this.editEmployerScreen.controls.dateCheck.value === true) {
      this.endDateChecker = true;
    }
    else {
      this.endDateChecker = false;
    }
  }
  // It is used to update the data
  editemp(data) {
    if (this.editEmployerScreen.invalid) {
      const controls = this.editEmployerScreen.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    }
    else {
      this.hide = true;
      this.disable = true;
      // tslint:disable-next-line:max-line-length
      if (this.editEmployerScreen.controls.fromDate.value === '' || this.editEmployerScreen.controls.fromDate.value === null || this.editEmployerScreen.controls.fromDate.value === undefined
      ) {
        this.fromDate = '';
      } else {
        const startdate = this.editEmployerScreen.controls.fromDate.value;
        const reqstartDate = new Date(startdate);
        this.fromDatevalue = this.datepipe.transform(reqstartDate, 'MM-dd-yyyy');
      }
      // tslint:disable-next-line:max-line-length
      if (this.editEmployerScreen.controls.toDate.value === '' || this.editEmployerScreen.controls.toDate.value === null || this.editEmployerScreen.controls.toDate.value === undefined
      ) {
        this.endDate = '';
      } else {
        const enddate = this.editEmployerScreen.controls.toDate.value;
        const reqendDate = new Date(enddate);
        this.endDatevalue = this.datepipe.transform(reqendDate, 'MM-dd-yyyy');
      }
      const data1 = {
        // tslint:disable-next-line:max-line-length
        title: this.editEmployerScreen.controls.addTitle.value === undefined || this.editEmployerScreen.controls.addTitle.value === '' ? '' : this.editEmployerScreen.controls.addTitle.value === null ? '' : this.editEmployerScreen.controls.addTitle.value,
        // tslint:disable-next-line:max-line-length
        employerId: this.editEmployerScreen.controls.organisationName.value.id === undefined || this.editEmployerScreen.controls.organisationName.value.id === '' ? '' : this.editEmployerScreen.controls.organisationName.value.id === null ? '' : this.editEmployerScreen.controls.organisationName.value.id,
        projectId: 1,
        description: this.editEmployerScreen.controls.comment.value === undefined || this.editEmployerScreen.controls.comment.value === '' ? '' : this.editEmployerScreen.controls.comment.value === null ? '' : this.editEmployerScreen.controls.comment.value,
        // tslint:disable-next-line:max-line-length
        workLocationId: this.editEmployerScreen.controls.workLocName.value === undefined ? '' : this.editEmployerScreen.controls.workLocName.value === '' ? '' : this.editEmployerScreen.controls.workLocName.value === null ? '' : this.editEmployerScreen.controls.workLocName.value.locId,
        startDate: this.fromDatevalue,
        endDate: this.endDateChecker ? '01-05-1970' : this.endDatevalue,
        lkWorkType: 'A',
        employeeId: 1,
        customerId: 1,
        lkEmploymentType: 'A',
        id: this.new,
      };
      this.subbtn = true;
      this.apisService.putApi(environment.updateemployment, data1).subscribe(res => {
        this.getData = res;
        this.hide = false;
        this.editModal = false;
        this.editEmployerScreen.reset();
        this.gridTable();
        if (this.getData.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message });
          this.disable = false;
          this.gridTable();
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error });
        }
      });
    }
  }
  saveLocation(ev) {
    const data = ev.value
    this.patchlocationString = data.name + '\n' + data.address1 + ',' + data.city + ',' + data.state + ',' + data.country
   }
}
