<p-toast position="bottom-right" key="br"></p-toast>
<!-- <p-dialog class="dialogBox" header="Contact Login Credentials" [(visible)]="displayActive" [style]="{width: '25%'}"
  [draggable]="false" [modal]="true" [resizable]="false">
  <p><strong>{{loginActiveData}}</strong></p>
  <a class="aligncenter pointer">({{email1}})</a>
  <br><br>
  <div *ngIf="registerdStatus" class="btnsCSS1">
    <button pButton type="button" icon='pi pi-times' label="Cancel" class="approvebtn" (click)="cancel()"></button>
    <button pButton type="button" icon='pi pi-send' label="Send" (click)="saveUserContacts()" class='buttonsize'
      [disabled]="disable"></button>
  </div>
</p-dialog> -->
<form [formGroup]="addConsultantForm">
    <p-card>
        <!-- Panel for Add Consultant form -->
        <p-panel [toggleable]="false">
            <p-header>
                <strong> Candidate Add </strong>
                <em class="pi pi-arrow-left panelBackRoute pointer" (click)="goto()"></em>
            </p-header>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Upload Resume</label><span class="mandatoryColor">*</span>
                    <input *ngIf="attachmentfile" type="file" id="attachment"
                        accept=".doc,.docx,application/msword,application/pdf" (change)="handleFileInput($event)"
                        formControlName='resume'>
                    <input *ngIf="profileupload" type="file" id="attachment"
                        accept=".doc,.docx,application/msword,application/pdf" (change)="handleFileInput($event)"
                        formControlName='resume'>
                    <span class="p-input-icon-right">
                        <div class="p-sm-12 p-lg-2 iconsalignments">
                            <em *ngIf="filesdatass" class="pi pi-times" style="cursor: pointer;"
                                (click)="cancelfiles()"></em>
                        </div>
                    </span>
                </div>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">First Name<label class="mandatoryColor">*</label></label>
                    <input type="text" pInputText placeholder="First Name" autocomplete="off"
                        formControlName="firstName" maxlength="26">
                    <div>
                        <span class="validationtest"
                            *ngIf="this.addConsultantForm.controls.firstName.touched && this.addConsultantForm.controls.firstName.invalid && this.addConsultantForm.controls.firstName.errors.required">
                            Please enter first name
                        </span>
                        <span class="validationtest"
                            *ngIf="this.addConsultantForm.controls.firstName.invalid && this.addConsultantForm.controls.firstName.errors?.pattern">Please
                            enter
                            alphabets only</span>

                        <span class="validationtest"
                            *ngIf="this.addConsultantForm.get('firstName').hasError('maxlength')">
                            Please enter 25 alphabets only
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Middle Name</label>
                    <input type="text" pInputText placeholder="Middle Name" autocomplete="off"
                        formControlName="middleName" maxlength="16">
                    <span class="validationtest"
                        *ngIf="this.addConsultantForm.controls.middleName.invalid && this.addConsultantForm.controls.middleName.errors?.pattern">Please
                        enter
                        alphabets only</span>
                    <span class="validationtest" *ngIf="this.addConsultantForm.get('middleName').hasError('maxlength')">
                        Please enter 15 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Last Name<label class="mandatoryColor">*</label></label>
                    <input autocomplete="off" type="text" pInputText formControlName="lastName" maxlength="26"
                        placeholder="Last Name">
                    <div>
                        <span class="validationtest"
                            *ngIf="this.addConsultantForm.controls.lastName.touched && this.addConsultantForm.controls.lastName.invalid && this.addConsultantForm.controls.lastName.errors.required">
                            Please enter last name</span>
                        <span class="validationtest"
                            *ngIf="this.addConsultantForm.controls.lastName.invalid && this.addConsultantForm.controls.lastName.errors?.pattern">Please
                            enter
                            alphabets only</span>
                        <span class="validationtest"
                            *ngIf="this.addConsultantForm.get('lastName').hasError('maxlength')">
                            Please enter 25 alphabets only
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Date of Birth</label>
                    <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="1980:2025"
                        placeholder="MM-DD-YYYY" formControlName="dateOfBirth" inputId="navigators"></p-calendar>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.addConsultantForm.controls.dateOfBirth.touched && this.addConsultantForm.controls.dateOfBirth.invalid && this.addConsultantForm.controls.dateOfBirth.errors.required">
                            Please select date of birth</span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                    <label class="inputfields">Mobile Number</label>
                    <div class="p-col-12 p-md-12 p-sm-12 mobstyle">
                        <div class="p-inputgroup" class="p-col-4 p-md-4 mobinput">
                            <span class="p-inputgroup-addon my-component">
                                <p-dropdown [options]="countrycodedropdown" class="phonecodestyle newcode"
                                    (onChange)="countryids($event)" filterPlaceholder='Search Results'
                                    optionLabel="label" formControlName="mobileDrp">
                                    <ng-template let-items pTemplate="selectedItem">
                                        <div class="flex-row">
                                            <img class="lang-change-img" src={{items.flag}} alt="" />
                                            <div class="lang-change-option-text selected-lang center">{{items.code}}
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template let-country pTemplate="item">
                                        <div class="flex-row" id="dropdown1">
                                            <img class="lang-change-img" src={{country.flag}} alt="" />
                                            <div class="lang-change-option-text center">{{country.label}}
                                                {{country.code}}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </span>
                        </div>
                        <div class="p-col-8 p-md-8 phnostyle">
                            <div class="p-input-icon-right"><em *ngIf="phSpinnerHide"
                                    class="pi pi-spin pi-spinner spinnerstyles"></em>
                                <em *ngIf="phCheckHide" class="pi pi-check-circle tickmarkstyle"></em>
                                <p-inputMask autocomplete="off" mask="(999) 999-9999" formControlName="mobileNo"
                                    (onBlur)="getPhon()" placeholder="Mobile Number">
                                </p-inputMask>
                            </div>
                            <p class="validationtest">{{this.officenummsg}}</p>

                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                    <label class="inputfields">Home Number</label>
                    <div class="p-col-12 p-md-12 p-sm-12 mobstyle">
                        <div class="p-inputgroup" class="p-col-4 p-md-4 mobinput">
                            <span class="p-inputgroup-addon my-component">
                                <p-dropdown [options]="countrycodedropdown" class="phonecodestyle newcode"
                                    (onChange)="countryids($event)" filterPlaceholder='Search Results'
                                    optionLabel="label" formControlName="homeNumDrp">
                                    <ng-template let-items pTemplate="selectedItem">
                                        <div class="flex-row">
                                            <img class="lang-change-img" src={{items.flag}} alt="" />
                                            <div class="lang-change-option-text selected-lang center">{{items.code}}
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template let-country pTemplate="item">
                                        <div class="flex-row" id="dropdown1">
                                            <img class="lang-change-img" src={{country.flag}} alt="" />
                                            <div class="lang-change-option-text center">{{country.label}}
                                                {{country.code}}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </span>
                        </div>
                        <div class="p-col-8 p-md-8 phnostyle">
                            <span class="p-input-icon-right"><em *ngIf="phSpinnerHide1"
                                    class="pi pi-spin pi-spinner spinnerstyles"></em>
                                <em *ngIf="phCheckHide1" class="pi pi-check-circle tickmarkstyle"></em>
                                <p-inputMask autocomplete="off" mask="(999) 999-9999" formControlName="homePhone"
                                    (onBlur)="getMobPhon()" placeholder="Home Number">
                                </p-inputMask>
                            </span>
                            <p class="validationtest">{{this.mobnummsg}}</p>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Email
                        <!-- <label class="mandatoryColor">*</label> -->
                    </label>
                    <span class="p-input-icon-right"><em *ngIf="phSpinnerHide2" class="pi pi-spin pi-spinner mail"></em>
                        <em *ngIf="phCheckHide2" class="pi pi-check-circle mailcol"></em>
                    </span>
                    <div class="p-inputgroup">
                        <input id="input" type="text" autocomplete="off" (blur)="emailverification()" pInputText
                            placeholder="Email" formControlName="emailId" maxlength="61" #userInputs>

                        <span class="p-inputgroup-addon"> <em class="pi pi-copy pointer" (click)="emailToast()"
                                (click)="copyInputMessage(userInputs.value)"></em>
                        </span>
                    </div>
                    <div>
                        <div>
                            <span class="mandatoryColor"
                                *ngIf="addConsultantForm.controls.emailId.touched && addConsultantForm.controls.emailId.invalid && addConsultantForm.controls.emailId.errors.required">
                                Please enter email
                            </span>
                            <span class="mandatoryColor"
                                *ngIf="addConsultantForm.controls.emailId.invalid && addConsultantForm.controls.emailId.errors.pattern">
                                Invalid email address
                            </span>
                            <span class="validationtest"
                                *ngIf="this.addConsultantForm.get('emailId').hasError('maxlength')">
                                Please enter 60 alphabets only
                            </span>
                        </div>
                        <span *ngIf="displayemail" class="mandatoryColor">Email already exist</span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Alternate Email</label>
                    <span class="p-input-icon-right"><em *ngIf="altEmailSpinnerHide"
                            class="pi pi-spin pi-spinner mail"></em>
                        <em *ngIf="altEmailCheckHide" class="pi pi-check-circle mailcol"></em>
                    </span>
                    <div class="p-inputgroup">
                        <input id="input" type="text" autocomplete="off" (blur)="AlternateEmail()" pInputText
                            placeholder="Alternate Email" formControlName="alternateEmailId" maxlength="61" #userInput>
                        <span class="p-inputgroup-addon"> <em class="pi pi-copy pointer" (click)="altEmailToast()"
                                (click)="copyInputMessage(userInput.value)"></em>
                        </span>
                    </div>
                    <div>
                        <span class="mandatoryColor" *ngIf="
                    addConsultantForm.controls.alternateEmailId.invalid &&
                    addConsultantForm.controls.alternateEmailId.errors.pattern">
                            Invalid email address
                        </span>
                        <span class="validationtest"
                            *ngIf="this.addConsultantForm.get('alternateEmailId').hasError('maxlength')">
                            Please enter 60 alphabets only
                        </span>
                    </div>
                    <span *ngIf="displayemail1" class="mandatoryColor">Email already exist</span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Gender</label>
                    <p-dropdown [options]="gender1" placeholder="Select Gender" formControlName="gender"
                        [showClear]="true" optionLabel="name">
                    </p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Availability</label>
                    <p-dropdown optionLabel="name" [options]="availability" autocomplete="off" [showClear]="true"
                        (onChange)='avilableDateModification($event.value.name)' placeholder="Select Availability"
                        formControlName="availability">
                    </p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Available Date</label>
                    <p-calendar [showIcon]="true" [showButtonBar]="true" dateFormat="mm-dd-yy" placeholder="MM-DD-YYYY"
                        formControlName="availableDate">
                    </p-calendar>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">SSN Number</label>
                    <div class="p-col-12 p-md-12 p-sm-12 ssnumberstyle">
                        <span class="p-input-icon-right">
                            <p-inputMask autocomplete="off" mask="999-99-9999" maxlength="26" formControlName="ssnNo"
                                placeholder="SSN Number" [type]="hide ? 'password' : 'text'">
                            </p-inputMask>
                        </span>
                        <span class="p-inputgroup-addon">
                            <em class='pi pi-eye-slash ssnicon' (click)="myFunction()" *ngIf="hideIcon"></em>
                            <em class='pi pi-eye ssnicon' (click)="myFunction1()" *ngIf="showIcon1"></em>
                        </span>
                        <span class="validationtest" *ngIf="this.addConsultantForm.get('ssnNo').hasError('maxlength')">
                            Please enter 25 alphabets only
                        </span>
                    </div>
                </div>
            </div>
            <h5 class="header-col">Social Media Profile</h5>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">LinkedIn <em class="fa fa-linkedin-square"
                            aria-hidden="true"></em></label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon media">linkedin.com/
                        </span>
                        <input autocomplete="off" type="text" pInputText placeholder='Username' maxlength=121
                            formControlName="linkedIn">
                    </div>
                    <span class="validationtest" *ngIf="this.addConsultantForm.get('linkedIn').hasError('maxlength')">
                        Please enter 120 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Facebook <em class="pi pi-facebook" aria-hidden="true"></em></label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon media">facebook.com/
                        </span>
                        <input autocomplete="off" type="text" pInputText placeholder='Username' maxlength=121
                            formControlName="facebook">
                    </div>
                    <span class="validationtest" *ngIf="this.addConsultantForm.get('facebook').hasError('maxlength')">
                        Please enter 120 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Twitter <em class="pi pi-twitter" aria-hidden="true"></em></label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon media">twitter.com/
                        </span>
                        <input autocomplete="off" type="text" pInputText placeholder='Username' maxlength=121
                            formControlName="twitter">
                    </div>
                    <span class="validationtest" *ngIf="this.addConsultantForm.get('twitter').hasError('maxlength')">
                        Please enter 120 alphabets only
                    </span>
                </div>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <div id="cssdiv">Permanent Address</div>
                    <br>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                            <label class="inputfields">Address Line 1
                                <!-- <label class="mandatoryColor">*</label> -->
                            </label>
                            <input type="text" autocomplete="off" pInputText placeholder="Address Line 1" maxlength=51
                                (click)="disableCheckboxValue()" formControlName="permanentAddressLine1">
                            <div>
                                <span class="validationtest"
                                    *ngIf="this.addConsultantForm.controls.permanentAddressLine1.touched && this.addConsultantForm.controls.permanentAddressLine1.invalid && this.addConsultantForm.controls.permanentAddressLine1.errors.required">
                                    Please enter address line 1
                                </span>
                                <span class="validationtest"
                                    *ngIf="this.addConsultantForm.controls.permanentAddressLine1.invalid && this.addConsultantForm.controls.permanentAddressLine1.errors?.pattern">Please
                                    enter
                                    alphabets only</span>
                                <span class="validationtest"
                                    *ngIf="this.addConsultantForm.get('permanentAddressLine1').hasError('maxlength')">
                                    Please enter 50 alphabets only
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
                            <label class="inputfields">Address Line 2</label>
                            <input type="text" autocomplete="off" pInputText placeholder="Address Line 2" maxlength=51
                                (click)="disableCheckboxValue()" formControlName="permanentAddressLine2">
                            <div>
                                <span class="validationtest"
                                    *ngIf="this.addConsultantForm.get('permanentAddressLine2').hasError('maxlength')">
                                    Please enter 50 alphabets only
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                            <label class="inputfields">Mailbox</label>
                            <input type="text" autocomplete="off" maxlength="11" pInputText pKeyFilter="num"
                                placeholder="Mailbox" formControlName="mailbox1">
                            <div>
                                <span class="validationtest"
                                    *ngIf="this.addConsultantForm.get('mailbox1').hasError('maxlength')">
                                    Please enter 10 alphabets only
                                </span>
                                <span class="validationtest"
                                    *ngIf="this.addConsultantForm.get('mailbox1').invalid && this.addConsultantForm.get('mailbox1').errors.pattern">
                                    Please enter numbers only
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                            <label class="inputfields">Country </label>
                            <p-dropdown [options]="livingCountry" (click)="disableCheckboxValue()" optionLabel="label"
                                [showClear]="true" (onChange)="getStateByUrl($event.value.code)"
                                placeholder="Select Country" formControlName="permanentCountry">
                            </p-dropdown>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                            <label class="inputfields">State </label>
                            <p-dropdown [options]="stateOptions" placeholder="Select State" [showClear]="true"
                                (click)="disableCheckboxValue()" optionLabel="label" formControlName="permanentState">
                            </p-dropdown>
                            <span class="validationtest"
                                *ngIf="this.addConsultantForm.get('permanentState').touched && this.addConsultantForm.get('permanentState').invalid &&this.addConsultantForm.get('permanentState').errors.required">
                                Plese select state
                            </span>
                        </div>
                    </div>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                            <label class="inputfields">City</label>
                            <input type="text" autocomplete="off" pInputText placeholder="City" maxlength="21"
                                (click)="disableCheckboxValue()" formControlName="permanentCity">
                            <div>
                                <span class="validationtest"
                                    *ngIf="this.addConsultantForm.get('permanentCity').hasError('maxlength')">
                                    Please enter 20 alphabets only
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                            <label class="inputfields">Zip </label>
                            <input type="text" autocomplete="off" pKeyFilter="num" pInputText placeholder="Zip"
                                (click)="disableCheckboxValue()" maxlength="11" formControlName="permanentZip">
                            <span class="validationtest"
                                *ngIf="this.addConsultantForm.get('permanentZip').touched && this.addConsultantForm.get('permanentZip').invalid &&this.addConsultantForm.get('permanentZip').errors.required">
                                Please enter zip
                            </span>
                            <span class="validationtest"
                                *ngIf="this.addConsultantForm.get('permanentZip').hasError('maxlength')">
                                Please enter 10 alphabets only
                            </span>
                            <span class="validationtest"
                                *ngIf="this.addConsultantForm.get('permanentZip').invalid && this.addConsultantForm.get('permanentZip').errors.pattern">
                                Please enter numbers only
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <div id="cssdiv"><span>Mailing Address </span>
                        <p-checkbox name="groupname" [value]="true" (onChange)="disableMailAd($event)"
                            span="Same as Permanent Address" formControlName="mailingAdressCheckbox">
                        </p-checkbox> Same as Permanent Address
                    </div>
                    <br>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                            <label class="inputfields">Address Line 1 </label>
                            <input type="text" autocomplete="off" [disabled]="disableMailAddress" pInputText
                                placeholder="Address Line 1" maxlength=51 formControlName="mailingAddressLine1">
                            <div>
                                <span class="validationtest"
                                    *ngIf="this.addConsultantForm.get('mailingAddressLine1').hasError('maxlength')">
                                    Please enter 50 alphabets only
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
                            <label class="inputfields">Address Line 2</label>
                            <input type="text" autocomplete="off" [disabled]="disableMailAddress" pInputText
                                placeholder="Address Line 2" maxlength=51 formControlName="mailingAddressline2">
                            <div>
                                <span class="validationtest"
                                    *ngIf="this.addConsultantForm.get('mailingAddressline2').hasError('maxlength')">
                                    Please enter 50 alphabets only
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                            <label class="inputfields">Mailbox</label>
                            <input type="text" autocomplete="off" maxlength="11" pInputText pKeyFilter="num"
                                placeholder="Mailbox" formControlName="mailbox2">
                            <div>
                                <span class="validationtest"
                                    *ngIf="this.addConsultantForm.get('mailbox2').hasError('maxlength')">
                                    Please enter 10 alphabets only
                                </span>
                                <span class="validationtest"
                                    *ngIf="this.addConsultantForm.get('mailbox2').invalid && this.addConsultantForm.get('mailbox2').errors.pattern">
                                    Please enter numbers only
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                            <label class="inputfields">Country </label>
                            <p-dropdown [options]="livingCountry" [disabled]="disableMailAddress" [showClear]="true"
                                formControlName="mailingCountry" placeholder="Select Country"
                                (onChange)="getStateByUrl1($event.value.code)" optionLabel="label">
                            </p-dropdown>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                            <label class="inputfields">State </label>
                            <p-dropdown [options]="stateOptions1" [disabled]="disableMailAddress" [showClear]="true"
                                placeholder="Select State" formControlName="mailingState" optionLabel="label">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                            <label class="inputfields">City </label>
                            <input type="text" autocomplete="off" pInputText placeholder="City"
                                [disabled]="disableMailAddress" maxlength="21" formControlName="mailingCity">
                            <div>
                                <span class="validationtest"
                                    *ngIf="this.addConsultantForm.get('mailingCity').hasError('maxlength')">
                                    Please enter 20 alphabets only
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                            <label class="inputfields">Zip </label>
                            <input type="text" autocomplete="off" pInputText pKeyFilter="num"
                                [disabled]="disableMailAddress" placeholder="Zip" maxlength="11"
                                formControlName="mailingZip">
                            <div>
                                <span class="validationtest"
                                    *ngIf="this.addConsultantForm.get('mailingZip').hasError('maxlength')">
                                    Please enter 10 alphabets only
                                </span>
                                <span class="validationtest"
                                    *ngIf="this.addConsultantForm.get('mailingZip').invalid && this.addConsultantForm.get('mailingZip').errors.pattern">
                                    Please enter numbers only
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Primary Skills<label class="mandatoryColor">*</label></label>
                    <p-multiSelect [options]="skillsCategory" optionLabel="label" (onChange)="primarySkillData()"
                        placeholder="Select Primary Skills" formControlName="primarySkills">
                    </p-multiSelect>
                    <span class="validationtest"
                        *ngIf="this.addConsultantForm.get('primarySkills').touched && this.addConsultantForm.get('primarySkills').invalid && this.addConsultantForm.get('primarySkills').errors.required">
                        Please select primary skills
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Secondary Skills<label class="mandatoryColor">*</label></label>
                    <p-multiSelect [options]="skillsCategory" placeholder="Select Secondary Skills" optionLabel="label"
                        (onChange)="secondarySkillData()" formControlName="secondarySkills">
                    </p-multiSelect>
                    <span class="validationtest"
                        *ngIf="this.addConsultantForm.get('secondarySkills').touched && this.addConsultantForm.get('secondarySkills').invalid && this.addConsultantForm.get('secondarySkills').errors.required">
                        Please select secondary skills
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Job Title<label class="mandatoryColor">*</label></label>
                    <input id="input" autocomplete="off" type="text" pInputText placeholder="Job Title"
                        formControlName="jobTitle" maxlength="31">
                    <span class="validationtest" *ngIf="this.addConsultantForm.get('jobTitle').hasError('maxlength')">
                        Please enter 30 alphabets only
                    </span>
                    <span class="validationtest"
                        *ngIf="this.addConsultantForm.get('jobTitle').touched && this.addConsultantForm.get('jobTitle').invalid && this.addConsultantForm.get('jobTitle').errors.required">
                        Please enter job title
                    </span>
                    <span class="validationtest"
                        *ngIf="this.addConsultantForm.controls.jobTitle.invalid && this.addConsultantForm.controls.jobTitle.errors.pattern">Please
                        enter
                        alphabets only</span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                    <label class="inputfields">Experience </label>
                    <p-dropdown placeholder="Select Experience" [options]="experienceData" optionLabel="label"
                        [showClear]="true" formControlName="experience">
                    </p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Current Rate</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                        <input id="input" autocomplete="off" type="text" pInputText placeholder="Current Rate"
                            formControlName="currentRate" maxlength="30">
                        <span class="p-inputgroup-addon">/Hrs</span>
                    </div>
                    <span class="validationtest"
                        *ngIf="this.addConsultantForm.controls.currentRate.invalid && this.addConsultantForm.controls.currentRate.errors?.pattern">Please
                        enter
                        numbers only</span>

                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Current CTC </label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                        <input id="input" autocomplete="off" type="text" pInputText placeholder="Current CTC"
                            formControlName="currentCTC" maxlength="30">
                        <span class="p-inputgroup-addon">/Yr</span>
                    </div>
                    <span class="validationtest"
                        *ngIf="this.addConsultantForm.controls.currentCTC.invalid && this.addConsultantForm.controls.currentCTC.errors?.pattern">Please
                        enter
                        numbers only</span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Expected MinRate </label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                        <input id="input" autocomplete="off" type="text" pInputText placeholder="Expected MinRate"
                            formControlName="expMinRate" maxlength="30">
                        <span class="p-inputgroup-addon">/Hrs</span>
                    </div>
                    <span class="validationtest"
                        *ngIf="this.addConsultantForm.controls.expMinRate.invalid && this.addConsultantForm.controls.expMinRate.errors?.pattern">Please
                        enter
                        numbers only</span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Expected MaxRate<label class="mandatoryColor">*</label></label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                        <input id="input" autocomplete="off" type="text" pInputText placeholder="Expected MaxRate"
                            formControlName="expMaxRate" maxlength="30">
                        <span class="p-inputgroup-addon">/Hrs</span>
                    </div>
                    <span class="validationtest"
                        *ngIf="this.addConsultantForm.controls.expMaxRate.touched && this.addConsultantForm.controls.expMaxRate.invalid && this.addConsultantForm.controls.expMaxRate.errors.required">
                        Please enter expected maxrate
                    </span>
                    <span class="validationtest"
                        *ngIf="this.addConsultantForm.controls.expMaxRate.invalid && this.addConsultantForm.controls.expMaxRate.errors?.pattern">Please
                        enter
                        numbers only</span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Expected MinCTC </label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                        <input id="input" autocomplete="off" type="text" pInputText placeholder="Expected MinCTC"
                            formControlName="expMinCTC" maxlength="30">
                        <span class="p-inputgroup-addon">/Yr</span>
                    </div>
                    <span class="validationtest"
                        *ngIf="this.addConsultantForm.controls.expMinCTC.invalid && this.addConsultantForm.controls.expMinCTC.errors?.pattern">Please
                        enter
                        numbers only</span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Expected MaxCTC </label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                        <input id="input" autocomplete="off" type="text" pInputText placeholder="Expected MaxCTC"
                            formControlName="expMaxCTC" maxlength="30">
                        <span class="p-inputgroup-addon">/Yr</span>
                    </div>
                    <span class="validationtest"
                        *ngIf="this.addConsultantForm.controls.expMaxCTC.invalid && this.addConsultantForm.controls.expMaxCTC.errors?.pattern">Please
                        enter
                        numbers only</span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <label class="inputfields">Education</label>
                    <textarea autocomplete="off" placeholder="Education" pInputTextarea rows="2" cols="30"
                        formControlName="education" maxlength="11"></textarea>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.addConsultantForm.get('education').hasError('maxlength')">
                            Please enter 10 alphabets only
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Referred By</label>
                    <input id="input" autocomplete="off" type="text" pKeyFilter="alpha" pInputText
                        placeholder="Referred by" formControlName="referredBy" maxlength="31">
                    <div>
                        <span class="validationtest"
                            *ngIf="this.addConsultantForm.get('referredBy').hasError('maxlength')">
                            Please enter 30 alphabets only
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Referred Date </label>
                    <p-calendar id="popup" dateFormat="mm-dd-yy" placeholder="MM-DD-YYYY"
                        (onClose)='getAge($event.element.ownerDocument.lastModified)' [showIcon]="true"
                        [showButtonBar]="true" formControlName="refDate"></p-calendar>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6"></div>

                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Working Country<label class="mandatoryColor">*</label> </label>
                    <p-dropdown placeholder="Select Working Country" [options]="livingCountry" optionLabel="label"
                        [showClear]="true" formControlName="workingCountry">
                    </p-dropdown>
                    <span class="validationtest"
                        *ngIf="addConsultantForm.get('workingCountry').touched && addConsultantForm.get('workingCountry').invalid && addConsultantForm.get('workingCountry').errors.required">
                        Please select working country</span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Currency<label class="mandatoryColor">*</label></label>
                    <p-dropdown [options]="currencyData" placeholder="Select Currency" [showClear]="true"
                        (onChange)='relocDropDown($event)' optionLabel="label" formControlName="currency">
                    </p-dropdown>
                    <span class="validationtest"
                        *ngIf="addConsultantForm.get('currency').touched && addConsultantForm.get('currency').invalid && addConsultantForm.get('currency').errors.required">
                        Please select currency</span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Relocation</label>
                    <p-dropdown [options]="Relocation" placeholder="Select Relocation" [showClear]="true"
                        (onChange)='relocDropDown($event.value.code)' optionLabel="name" formControlName="relocation">
                    </p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3"></div>

                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Living Country</label>
                    <p-dropdown optionLabel="label" [options]="livingCountry" formControlName="livingCountry"
                        [showClear]="true" placeholder="Select Living Country">
                    </p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Candidate Work Status<label class="mandatoryColor">*</label></label>
                    <p-dropdown placeholder="Candidate Work Status" [options]="consultant" optionLabel="label"
                        [showClear]="true" formControlName="consultantworkstatus">
                    </p-dropdown>
                    <span class="validationtest"
                        *ngIf="addConsultantForm.get('consultantworkstatus').touched && addConsultantForm.get('consultantworkstatus').invalid && addConsultantForm.get('consultantworkstatus').errors.required">
                        Please select Candidate work status</span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6"></div>

                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Candidate Work Status Comments</label>
                    <textarea autocomplete="off" placeholder="Candidate Work Status Comments" pInputTextarea
                        formControlName="consultantworkstatusComments" maxlength="501" rows="8" cols="30"></textarea>
                    <span class="validationtest"
                        *ngIf="this.addConsultantForm.get('consultantworkstatusComments').hasError('maxlength')">
                        please enter 500 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Comments</label>
                    <textarea autocomplete="off" maxlength="501" placeholder="Comments" pInputTextarea
                        formControlName="comments" rows="8" cols="30"></textarea>
                        <span class="validationtest"
                        *ngIf="this.addConsultantForm.get('comments').hasError('maxlength')">
                        please enter 500 alphabets only
                    </span>
                </div>
            </div>
            <div class="btnsCSS1">
                <button class="addhBtn" pButton type="button" icon='pi pi-refresh' label="Reset"
                    (click)="reset()"></button>
                <button class="addhBtn" [disabled]="btndisable" pButton type="button" icon='pi pi-save' label="Save"
                    (click)="addConsultantData()"></button>
            </div><br><br>
        </p-panel>
    </p-card>
</form>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>