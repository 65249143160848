<p-toast position="bottom-right" key="br"></p-toast>
<form [formGroup]="CertificatesForm">
    <p-card>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Name</label>
                <input id="input" type="text" pInputText placeholder="Name" autocomplete="off" formControlName="title"
                    maxlength="30">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Attachment Type</label>
                <p-dropdown [options]="attachment" formControlName="attachment" placeholder="Select Attachment Type"
                    optionLabel="label" [showClear]="true"></p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Issued Date</label>
                <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="issuedate" placeholder="MM-DD-YYYY"
                    [showIcon]="true" [showButtonBar]="true">
                </p-calendar>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Expired Date</label>
                <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="expdate" placeholder="MM-DD-YYYY"
                    [showIcon]="true" [showButtonBar]="true">
                </p-calendar>
            </div>
        </div>
        <br>
        <div class="btnsCSS1">
            <button pButton type="button" [icon]="showUploadView ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                label="Import" (click)="toggleUploadView()" class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-plus' label="Add" (click)="addlocation()"
                class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-search' label="Search" (click)="searchData()"
                class="addhBtn"></button>
        </div><br><br>
        <div *ngIf="isUploading1" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <p-progressBar mode="indeterminate"></p-progressBar>
        </div><br>
        <div *ngIf="showUploadView" class="dropzone p-col-12 p-md-12 p-sm-12 p-lg-3">
            <em class="pi pi-upload uploadIcon Iconsize" aria-hidden="true"></em>
            <input accept=".xlsm,.xlsx,application/msexcel" type="file" class="fileInput" #fileDropRef id="fileDropRef"
                multiple (change)="onUpload($event.target.files)" style="cursor: pointer;"/>
            <h3>Drag and drop file here</h3>
            <h3 class="importstylin">or</h3>
            <label id="fileUpload" for="fileDropRef">Browse for files</label>
        </div>
    </p-card><br>
    <p-card class="pgpos">
        <div *ngIf="isUploading" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
        <div *ngIf="table">
            <p-table [columns]="cols" [value]="certificateGrid" selectionMode="single" dataKey="edit" [rows]="10"
                [responsive]="true" [rowsPerPageOptions]="[10,20,30]" sortMode="multiple" [autoLayout]="true">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th id="gridHeader" *ngFor="let col of columns; let i=index"
                            (click)="iconToggle[i] = !iconToggle[i]" [ngStyle]="{'width': col.width }"
                            [pSortableColumn]="col.field">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                    <tr>
                        <td class="textCenter"><span class="p-column-title"> Name</span>{{rowData.title}}</td>
                        <td class="textCenter"><span class="p-column-title"> Organization</span>
                            {{rowData.organization}}</td>
                        <td class="textCenter"><span class="p-column-title">Attatchment
                                Type</span>{{typeTable[rowData.lkObjectType]}}</td>
                        <td class="textCenter"><span class="p-column-title"> Issued Date</span>{{rowData.issuedDate ===
                            null||rowData.issuedDate ===''?'--':rowData.issuedDate | date:'MM-dd-yyyy'}}</td>
                        <td class="textCenter"><span class="p-column-title"> Expired Date</span>{{rowData.expiryDate ===
                            null||rowData.expiryDate ===''?'--':rowData.expiryDate | date:'MM-dd-yyyy'}}</td>
                        <td class="textCenter"><span class="p-column-title">Rank/Marks</span>{{rowData.rankMarks}}</td>
                        <td class="textCenter"><span class="p-column-title">Action</span><a><em
                                    (click)="downloadActionPopup.toggle($event); toggle($event,'data', rowData)"
                                    class="pi pi-ellipsis-v pointer"></em> </a></td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr *ngIf="certificateGrid?.length==0" class="aligncenter">
                        <td colspan="4" class="mandatoryColor">
                            No Records
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                    <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                    <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                        (onPageChange)="paginate($event)"></p-paginator>
                </div>
            </div>
            <p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="items"></p-menu>
        </div>
        <p-card *ngIf="records">
            <div class="norecordImgsize">
                <div *ngIf="records" class="aligncenter">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                    <br><em>Sorry, we are not able to find any certificate details</em><br>
                </div>
            </div>
        </p-card>
    </p-card>
</form>
<p-dialog class="dialogBox" header="Add Certificates" [contentStyle]="{'overflow': 'visible'}"
    [(visible)]="showDialogAdd" [modal]="true" [style]="{width: '30%'}" [baseZIndex]="1" [draggable]="false"
    [resizable]="false">
    <form [formGroup]="CertificateAddForm">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Name</label><span class="mandatoryColor">*</span>
                <input id="input" type="text" pInputText placeholder="Name" autocomplete="off" formControlName="title" maxlength="16">
                <span class="validationtest"
                    *ngIf="this.CertificateAddForm.get('title').touched && this.CertificateAddForm.get('title').invalid && this.CertificateAddForm.get('title').errors.required">
                    Please enter name
                </span>
                <span class="validationtest"
                    *ngIf="this.CertificateAddForm.get('title').invalid && this.CertificateAddForm.get('title').errors?.pattern">
                    Please enter alphabets only</span>
                <span class="validationtest" *ngIf="this.CertificateAddForm.get('title').hasError('maxlength')">
                    Please enter 15 alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Issuing Organization</label><span class="mandatoryColor">*</span>
                <input id="input" type="text" pInputText placeholder="Issuing Organization" autocomplete="off" maxlength="51"
                    formControlName="organisation">
                <span class="validationtest"
                    *ngIf="this.CertificateAddForm.get('organisation').touched && this.CertificateAddForm.get('organisation').invalid && this.CertificateAddForm.get('organisation').errors.required">
                    Please enter organization
                </span>
                <span class="validationtest"
                    *ngIf="this.CertificateAddForm.get('organisation').invalid && this.CertificateAddForm.get('organisation').errors?.pattern">
                    Please enter alphabets only</span>
                <span class="validationtest" *ngIf="this.CertificateAddForm.get('organisation').hasError('maxlength')">
                    Please enter 50 alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Attachment Type</label><span class="mandatoryColor">*</span>
                <p-dropdown [options]="attachment" formControlName="attachment" placeholder="Select Attachment Type"
                    optionLabel="label" [showClear]="true"></p-dropdown>
                <span class="validationtest"
                    *ngIf="this.CertificateAddForm.get('attachment').touched && this.CertificateAddForm.get('attachment').invalid && this.CertificateAddForm.get('attachment').errors.required">
                     Please upload attachment type
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <p-checkbox name="groupname" (onChange)="disableAddCheckboxValue($event)" binay=true
                    label="This certificate does not expire" formControlName="dateCheck">
                </p-checkbox>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Issued Date</label>
                <p-calendar appendTo="body" [inputStyle]="{'width':'95%'}" id="popup" dateFormat="mm-dd-yy"
                    formControlName="issuedate" placeholder="MM-DD-YYYY" [showIcon]="true" (onChange)="onlyExpiryDate()"
                    [showButtonBar]="true">
                </p-calendar>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6" *ngIf="showExPiredDate">
                <label class="inputfields">Expired Date</label>
                <p-calendar id="popup" appendTo="body" [inputStyle]="{'width':'95%'}" dateFormat="mm-dd-yy"
                    formControlName="expdate" placeholder="MM-DD-YYYY" [showIcon]="true" [showButtonBar]="true">
                </p-calendar>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6" *ngIf="show">
                <label class="inputfields">Expired Date</label>
                <input id="input" type="text" pInputText placeholder="No expiration date" disabled="true"
                    (onChange)="disableCheckboxValue($event)" autocomplete="off" formControlName="organisationText"
                    maxlength="30">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Rank/Marks</label>
                <input id="input" type="text" pInputText placeholder="Rank/Marks" autocomplete="off"
                    formControlName="rank" maxlength="10">
                <span class="validationtest" *ngIf="this.CertificateAddForm.get('rank').hasError('maxlength')">
                    Please enter 9 numbers only
                </span>
                <span class="validationtest"
                    *ngIf="this.CertificateAddForm.controls.rank.invalid && this.CertificateAddForm.controls.rank.errors.pattern">Please
                    enter
                    numbers only</span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Description</label>
                <textarea autocomplete="off" [rows]="3" [cols]="30" placeholder="Description" pInputTextarea
                    formControlName="comments" maxlength="201"></textarea>
                <span class="validationtest" *ngIf="this.CertificateAddForm.get('comments').hasError('maxlength')">
                    Please enter 200 alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Add Attachment</label><span class="mandatoryColor">*</span><br>
                <input type="file" id="attachment"
                    accept=".doc,.docx,application/msword,application/pdf" formControlName="fileControl"
                    (change)="handleFileInput($event)" *ngIf="classinput" class="pointer">
                <input type="file" id="attachment" accept=".doc,.docx,application/msword,application/pdf"
                    formControlName="fileControl" (change)="handleFileInput($event)" *ngIf="profileupload"
                    class="pointer">
                <em *ngIf="filesdatass" class="pi pi-times" style="cursor: pointer;" (click)="cancelfiles()"></em>
                <div class="validationtest"
                    *ngIf="this.CertificateAddForm.get('fileControl').touched && this.CertificateAddForm.get('fileControl').invalid && this.CertificateAddForm.get('fileControl').errors.required">
                     Please upload attachment
                </div>
            </div>
        </div>
        <div class="btnsCSS1 rightalign">
            <button class="addhBtn" pButton type="submit" [disabled]="disable" icon='pi pi-save' label="Save"
                (click)="uploadAttachment()"></button>
        </div>
    </form>
</p-dialog>
<p-dialog class="dialogBox" header="Edit Certificates" [(visible)]="showDialog" [modal]="true" [style]="{width: '30%'}"
    [baseZIndex]="1" [draggable]="false" [resizable]="false">
    <div>
        <form [formGroup]="CertificateEditForm">
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <label class="inputfields">Name</label><span class="mandatoryColor">*</span>
                    <input id="input" type="text" pInputText placeholder="Name" autocomplete="off" maxlength="16"
                        formControlName="title">
                    <span class="validationtest"
                        *ngIf="this.CertificateEditForm.get('title').touched && this.CertificateEditForm.get('title').invalid && this.CertificateEditForm.get('title').errors.required">
                        Please enter name
                    </span>
                    <span class="validationtest"
                        *ngIf="this.CertificateEditForm.get('title').invalid && this.CertificateEditForm.get('title').errors?.pattern">
                        Please enter alphabets only</span>
                    <span class="validationtest" *ngIf="this.CertificateEditForm.get('title').hasError('maxlength')">
                        Please enter 15 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <label class="inputfields">Issuing Organization</label><span class="mandatoryColor">*</span>
                    <input id="input" type="text" maxlength="51" pInputText placeholder="Issuing Organization" autocomplete="off"
                        formControlName="organisation">
                    <span class="validationtest"
                        *ngIf="this.CertificateEditForm.get('organisation').touched && this.CertificateEditForm.get('organisation').invalid && this.CertificateEditForm.get('organisation').errors.required">
                        Please enter organization
                    </span>
                    <span class="validationtest"
                        *ngIf="this.CertificateEditForm.get('organisation').invalid && this.CertificateEditForm.get('organisation').errors?.pattern">
                        Please enter alphabets only</span>
                    <span class="validationtest"
                        *ngIf="this.CertificateEditForm.get('organisation').hasError('maxlength')">
                        Please enter 50 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <label class="inputfields">Attachment Type</label><span class="mandatoryColor">*</span>
                    <p-dropdown [options]="attachment" formControlName="attachment" placeholder="Select Attachment Type"
                        optionLabel="label" [showClear]="true"></p-dropdown>
                    <span class="validationtest"
                        *ngIf="this.CertificateEditForm.get('attachment').touched && this.CertificateEditForm.get('attachment').invalid && this.CertificateEditForm.get('attachment').errors.required">
                         Please upload attachment type
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <p-checkbox name="groupname" formControlName="dateCheck"
                        (onChange)="disableEditCheckboxValue($event)" binary="true"
                        label="This certificate does not expire">
                    </p-checkbox>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Issued Date</label>
                    <p-calendar appendTo="body" [inputStyle]="{'width':'95%'}" id="popup" dateFormat="mm-dd-yy"
                        formControlName="issuedate" placeholder="MM-DD-YYYY" [showIcon]="true" [showButtonBar]="true">
                    </p-calendar>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6" *ngIf="!show1">
                    <label class="inputfields">Expired Date</label>
                    <p-calendar appendTo="body" [inputStyle]="{'width':'95%'}" id="popup" dateFormat="mm-dd-yy"
                        formControlName="expdate" placeholder="MM-DD-YYYY" [showIcon]="true" [showButtonBar]="true">
                    </p-calendar>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6" *ngIf="show1">
                    <label class="inputfields">Expired Date</label>
                    <input id="input" type="text" pInputText placeholder="No expiration date" disabled="true"
                        (onChange)="disableCheckboxValue1($event)" autocomplete="off" formControlName="organisationText"
                        maxlength="30">
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <label class="inputfields">Rank/Marks</label>
                    <input id="input" type="text" pInputText placeholder="Rank/Marks" autocomplete="off"
                        formControlName="rank" maxlength="10">
                    <span class="validationtest" *ngIf="this.CertificateEditForm.get('rank').hasError('maxlength')">
                        Please enter 9 numbers only
                    </span>
                    <span class="validationtest"
                        *ngIf="this.CertificateEditForm.controls.rank.invalid && this.CertificateEditForm.controls.rank.errors.pattern">Please
                        enter
                        numbers only</span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <label class="inputfields">Description</label>
                    <textarea autocomplete="off" [rows]="3" [cols]="30" placeholder="Description" pInputTextarea
                        formControlName="comments" maxlength="201"></textarea>
                        <span class="validationtest" *ngIf="this.CertificateEditForm.get('comments').hasError('maxlength')">
                            Please enter 200 alphabets only
                        </span>
                </div>
            </div><br>
            <div class="btnsCSS1 rightalign">
                <button class="addhBtn" pButton type="submit" [disabled]="disable" icon='pi pi-save' label="Update"
                    (click)="submitAction()"></button>
            </div>
        </form>
    </div>
    <br>
</p-dialog>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>