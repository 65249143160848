import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-account-search',
  templateUrl: './account-search.component.html',
  styleUrls: ['./account-search.component.scss'],
  providers: [MessageService, DatePipe]
})
export class AccountSearchComponent implements OnInit {
  accountSearchForm: FormGroup;
  statuTable = {};
  typeTable = {};
  country: any;
  state: any;
  type: any;
  industry: any;
  status: any;
  diversity: any;
  staffingmode: any;
  staffingMode: any[];
  GridData: any;
  accounSearchGridData: any;
  AccountDate: string;
  searchAccountData: any;
  searcAccountRes: any;
  orgIdValue: any;
  userIdValue: any;
  accountId: any;
  idValue: any;
  totalRecords: any;
  first: any;
  rows: any = 10;
  page: any = 1;
  pageCount: any;
  data: any;
  loading: boolean;
  iconToggle = [];
  roleId: any;
  loader = false;
  accountSearchName: string;
  roleID: any;
  AcctRowID: any;
  rowID: any;
  table = true;
  records: boolean;
  uploadedFiles: any[] = [];
  fileOver: boolean;
  isUploading = false;
  showUploadView = false;
  contryTable = {};
  cols: { field: string; header: string; width: string; }[];
  items: { label: string; icon: string; command: () => void; }[];
  regionOptions: any = [];
  regiontype = {};
  rowData: any;
  Sales = [
    { id: 'all', label: 'All' },
    { id: 'Y', label: 'Yes' },
    { id: 'N', label: 'No' },
  ];
  items1: { label: string; icon: string; command: () => void; }[];
  downloadData: any;
  usrroleid: any;
  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private apisService: ApisService,
    private datepipe: DatePipe,
    private breadcrumbService: BreadcrumbService
  ) {
    this.breadcrumbService.setItems([
      { label: 'Accounts', routerLink: '/accounts' },
    ]);
    this.items1 = [
      {
        label: 'Download Sample', icon: 'pi pi-download', command: () => {
          this.downloadFileapi();
        }
      },
      {
        label: 'Import Data', icon: 'pi pi-chevron-down', command: () => {
          this.toggleUploadView();

        }
      }];
  }

  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchData();
    }
  }
  // Drag Over Listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drag Leave Listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drop Listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.isUploading = true;
      this.onUpload(files);
    }
  }
  ngOnInit() {
    const actId = JSON.parse(this.apisService.decodeData());
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
      this.roleID = actId.roles[0].id;
    } else {
      this.roleID = this.usrroleid;
    }
    this.cols = [
      { field: 'page', header: '#', width: '2%' },
      { field: 'accName', header: 'Name', width: '9%' },
      { field: 'website', header: 'Corporate Website', width: '9%' },
      { field: 'lkAccountType', header: 'Account Type', width: '6%' },
      { field: 'lkAccountType', header: 'Country', width: '6%' },
      { field: 'lkStatus', header: 'Region', width: '3%' },
      { field: 'lkStatus', header: 'Status', width: '3%' },
      { field: 'lkStatus', header: 'Action', width: '3%' },
    ];
    this.accountSearchForm = this.fb.group({
      accountName: [''],
      accountUrl: ['', Validators.pattern('^((ftp|http|https)://)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(.[a-zA-Z]+)+((/)[w#]+)*(/w+?[a-zA-Z0-9_]+=w+(&[a-zA-Z0-9_]+=w+)*)?$')],
      accountZip: [''],
      accountDate: [''],
      accountCountry: [''],
      accountState: [''],
      accountType: [''],
      accountIndustry: [''],
      accountStatus: [''],
      accountDiversity: [''],
      accountStaffingMode: [''],
      region: [''],
      salesAssociated: ['']
    });
    this.orgIdValue = JSON.parse(sessionStorage.getItem('orgId'));
    this.userIdValue = JSON.parse(sessionStorage.getItem('userId'));
    this.roleId = JSON.parse(sessionStorage.getItem('loginData')).primaryrole;
    this.getCountryDropdown();
    this.getIndustryDropdown();
    this.regionDropdown();
    this.getTypeDropDown();
    this.getStatusDropDown();
    this.gridData();
  }
  downloadFileapi() {
    this.apisService.getApi(environment.downloadres + 1).subscribe((res: any) => {
      this.downloadData = res;
      window.location.assign(this.downloadData.downloadUrl);

    });
  }
  // It is used for the displaying the country Dropdown Values
  getCountryDropdown1() {
    this.apisService.getApi(environment.preCountryDropdown).subscribe((res) => {
      this.country = res.data;
      this.getStateByUrl('');
    });
  }
  // It is used for the displaying the State Dropdown based on country Values
  getStateByUrl1(code) {
    if (code === null || code === '' || code === undefined) {
      this.state = [];
    }
    else {
      this.apisService.getApi(environment.state + '/' + code).subscribe((res) => {
        this.state = res.data;
      });
    }

  }

  // It is used for the displaying the Country dropdown values
  getCountryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'adlc').subscribe((res) => {
      this.country = res.data;
      this.country.forEach((element) => {
        this.contryTable[element.code] = element.label;
      });
    });
  }
  // It is used for the displaying the State dropdown values
  getStateByUrl(country) {
    this.accountSearchForm.controls.accountState.setValue('');
    if (country === null || '' || undefined) { }
    else {
      this.apisService.getApi(environment.states + country).subscribe((res) => {
        this.state = res.data;
      });
    }

  }
  // It is used for the displaying the Industry Dropdown Values
  getIndustryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'ALIT').subscribe((res: any) => {
      this.industry = res.data;
      const val = { code: '', label: 'All' };
      this.industry.unshift(val);
      this.accountSearchForm.controls.accountIndustry.patchValue(
        this.industry.filter((x) => x.code === '')[0]
      );
    });
  }
  regionDropdown() {
    this.apisService.getApi(environment.lookupsData + 'ALR').subscribe((res) => {
      this.regionOptions = res.data;
      this.regionOptions.forEach(element => {
        this.regiontype[element.code] = element.label;
      });
    });
  }
  // It is used for the displaying the  Type Dropdown Values
  getTypeDropDown() {
    this.apisService.getApi(environment.lookupsData + 'ALAT').subscribe((res) => {
      this.type = res.data;
      this.type.forEach((element) => {
        this.typeTable[element.code] = element.label;
      });
    });
  }
  // It is used for the displaying the Status Dropdown Values
  getStatusDropDown() {
    this.apisService.getApi(environment.actstatus).subscribe((res: any) => {
      this.status = res.data;
      const val = { code: '', label: 'All' };
      this.status.unshift(val);
      this.status.forEach((element) => {
        this.statuTable[element.code] = element.label;
      });
      this.accountSearchForm.controls.accountStatus.patchValue(
        this.status.filter((x) => x.code === '')[0]
      );
    });
  }
  // it is used to toggle the upload file view
  toggleUploadView() {
    this.showUploadView = !this.showUploadView;
  }
  // It is Used to upload files
  onUpload(files) {
    this.isUploading = true;
    const formData: FormData = new FormData();
    formData.append('file', files[0]);
    this.apisService.postApi(environment.fileuploadAcc, formData).subscribe((res: any) => {
      if (res.success === true) {
        this.gridData();
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        this.isUploading = false;
        this.showUploadView = false;
      } else {
        setTimeout(() => {
          this.isUploading = false;
        }, 2000);
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
      }
    });
  }
  // It is used for the displaying the GridData
  gridData() {
    this.loader = true;
    const pageNo = this.page;
    const pageCount = this.rows;
    const orgId = '/' + pageNo + '/' + pageCount;
    this.apisService.getApi(environment.getAccounData + orgId).subscribe((res) => {
      this.accounSearchGridData = res.data.records;
      this.AcctRowID = this.accounSearchGridData.accId;
      this.totalRecords = res.data.count;
      this.totalRecords !== 0 ? this.table = true : this.table = false;
      this.totalRecords !== 0 ? this.records = false : this.records = true;
      this.loader = false;
    });
  }
  // It is used  for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.searchData();
  }
  // It is used for the searching the the data based on the griddata
  searchData() {
    this.loader = true;
    // tslint:disable-next-line:max-line-length
    const accountName = this.accountSearchForm.controls.accountName.value === null ? '' : this.accountSearchForm.controls.accountName.value;
    const accountUrl = this.accountSearchForm.controls.accountUrl.value === null ? '' : this.accountSearchForm.controls.accountUrl.value;
    const accountZip = this.accountSearchForm.controls.accountZip.value === null ? '' : this.accountSearchForm.controls.accountZip.value;
    // tslint:disable-next-line:max-line-length
    const accountIndustry = this.accountSearchForm.controls.accountIndustry.value === null || this.accountSearchForm.controls.accountIndustry.value === '' || this.accountSearchForm.controls.accountIndustry.value === undefined ? '' : this.accountSearchForm.controls.accountIndustry.value.code;
    // tslint:disable-next-line:max-line-length
    const accountCountry = this.accountSearchForm.controls.accountCountry.value === null || this.accountSearchForm.controls.accountCountry.value === '' || this.accountSearchForm.controls.accountCountry.value === undefined ? '' : this.accountSearchForm.controls.accountCountry.value.code;
    // tslint:disable-next-line:max-line-length
    const accountState = this.accountSearchForm.controls.accountState.value === null || this.accountSearchForm.controls.accountState.value === '' || this.accountSearchForm.controls.accountState.value === undefined ? '' : this.accountSearchForm.controls.accountState.value.code;
    // tslint:disable-next-line:max-line-length
    const accountStatus = this.accountSearchForm.controls.accountStatus.value === null || this.accountSearchForm.controls.accountStatus.value === '' || this.accountSearchForm.controls.accountStatus.value === undefined ? '' : this.accountSearchForm.controls.accountStatus.value.code;
    // tslint:disable-next-line:max-line-length
    const accountType = this.accountSearchForm.controls.accountType.value === null || this.accountSearchForm.controls.accountType.value === '' || this.accountSearchForm.controls.accountType.value === undefined ? '' : this.accountSearchForm.controls.accountType.value.code;
    // tslint:disable-next-line:max-line-length
    const region = this.accountSearchForm.controls.region.value === null || this.accountSearchForm.controls.region.value === '' || this.accountSearchForm.controls.region.value === undefined ? '' : this.accountSearchForm.controls.region.value.code;
    // tslint:disable-next-line:max-line-length
    const salessst = this.accountSearchForm.controls.salesAssociated.value === null || this.accountSearchForm.controls.salesAssociated.value === undefined || this.accountSearchForm.controls.salesAssociated.value === '' ? '' : this.accountSearchForm.controls.salesAssociated.value.id;
    // tslint:disable-next-line:max-line-length
    const data = '/' + this.page + '/' + this.rows + '?accName=' + accountName + '&accUrl=' + accountUrl + '&zip=' + accountZip + '&industry=' + accountIndustry + '&status=' +
      // tslint:disable-next-line:max-line-length
      (accountStatus === '' || accountStatus === null || accountStatus === undefined ? '' : accountStatus) + '&country=' + accountCountry + '&state=' + accountState + '&accType=' + accountType + '&region=' + region + '&isAssocatied=' + salessst;
    this.apisService.getApi(environment.getAccounData + data).subscribe((res) => {
      this.loader = false;
      this.accounSearchGridData = res.data.records;
      this.totalRecords = res.data.count;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  // It is used for navigate to view the account details of particular account based on account type
  navigateTo(data) {
    this.rowID = data.accId;
    sessionStorage.setItem('acctpe', data.lkAccountType);
    sessionStorage.setItem('acct_rowid', JSON.stringify(this.rowID));
    sessionStorage.setItem('account_Type', data.accountType);
    sessionStorage.setItem('account_Name', data.accName);
    sessionStorage.setItem('csrVendorAccountId', data.account_name);
    if (data.accountType === 'Customer') {
      this.router.navigate(
        ['/account-menu/account-details', this.accountId],
        {
          queryParams: {
            acctName: this.accountSearchName,
          },
        }
      );
    } else {
      sessionStorage.setItem('account_id', data.account_id);
      this.accountId = sessionStorage.getItem('account_id');
      this.router.navigate([
        '/admin-menu/account-details',
        this.rowID,
      ], {
        queryParams: {
          empflag: 'acctemp'
        },
      });
    }
  }
  // It is used for navigate the add account
  addData() {
    this.router.navigate(['/add-account']);
  }
  // It is used for the reset the form values
  reset() {
    this.accountSearchForm.reset();
    this.accountSearchForm.controls.accountCountry.patchValue(
      this.country.filter((x) => x.id === 3)[0]
    );
    this.loader = true;
    this.gridData();
  }
  // It is used for open Urls automatical
  goToLink(url: string) {
    const addressLink = url.includes('http') ? url : `http://${url}`;
    window.open(addressLink, '_blank');
  }
  // used to show actions in screen
  toggle(event, data) {
    this.rowData = data;
    sessionStorage.setItem('saleInfoId', this.rowData.accId);
    sessionStorage.setItem('salesuserName', this.rowData.accName);
    if (this.rowData.lkAccountType === 'V') {
      this.items = [
        {
          label: 'Make account as customer and vendor',
          icon: 'pi pi-info-circle',
          command: () => {
            this.showsweetAlert();
          },
        },
        {
          label: 'Sales Info',
          icon: 'pi pi-info-circle',
          command: () => {
            this.router.navigate(['/sales-information'], {
              queryParams: {
                salesId: this.rowData.accId,
                salesname: this.rowData.accName,
                salesaccname: 'salesaccname'
              }
            });
          },
        }

      ];
    } else if (this.rowData.lkAccountType === 'B') {
      this.items = [
        {
          label: 'Make account as vendor',
          icon: 'pi pi-info-circle',
          command: () => {
            this.showsweetAlert();
          },
        },
        {
          label: 'Sales Info',
          icon: 'pi pi-info-circle',
          command: () => {
            this.router.navigate(['/sales-information'], {
              queryParams: {
                salesId: this.rowData.accId,
                salesname: this.rowData.accName,
                salesaccname: 'salesaccname'
              }
            });
          },
        }

      ];
    }
    else {
      this.items = [
        {
          label: 'Sales Info',
          icon: 'pi pi-info-circle',
          command: () => {
            this.router.navigate(['/sales-information'], {
              queryParams: {
                salesId: this.rowData.accId,
                salesname: this.rowData.accName,
                salesaccname: 'salesaccname'
              }
            });
          },
        }
      ];
    }

  }
  showsweetAlert() {
    if (this.rowData.lkAccountType === 'V') {
      Swal.fire({
        // tslint:disable-next-line:max-line-length
        text: 'Are you sure want to make account both Customer and Vendor', icon: 'warning', showCancelButton: true, confirmButtonColor: '#0D416B', cancelButtonColor: '#D0D0D0', confirmButtonText: 'Yes', cancelButtonText: 'No', reverseButtons: false,
      }).then((result) => {
        if (result.value) {
          const reqbody = {
            id: this.rowData.accId,
            accountType: 'B'
          };
          this.apisService.putApi(environment.changeAccType, reqbody).subscribe((res: any) => {
            if (res.success === true) {
              // tslint:disable-next-line: max-line-length
              Swal.fire({ text: 'Account changed to Customer and Vendor', icon: 'success', confirmButtonColor: '#0D416B', iconColor: '#1dbb99', confirmButtonText: 'Ok', reverseButtons: false });
              this.gridData();
            }
            else if (res.hasOwnProperty('success') === false) {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
            } else {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
            }
          });
        }
      });
    } else {
      Swal.fire({
        // tslint:disable-next-line:max-line-length
        text: 'Are you sure want to make account  Vendor', icon: 'warning', showCancelButton: true, confirmButtonColor: '#0D416B', cancelButtonColor: '#D0D0D0', confirmButtonText: 'Yes', cancelButtonText: 'No', reverseButtons: false,
      }).then((result) => {
        if (result.value) {
          const reqbody = {
            id: this.rowData.accId,
            accountType: 'V'
          };
          this.apisService.putApi(environment.changeAccType, reqbody).subscribe((res: any) => {
            if (res.success === true) {
              // tslint:disable-next-line: max-line-length
              Swal.fire({ text: 'Account changed to Vendor', icon: 'success', confirmButtonColor: '#0D416B', iconColor: '#1dbb99', confirmButtonText: 'Ok', reverseButtons: false });
              this.gridData();
            }
            else if (res.hasOwnProperty('success') === false) {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
            } else {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
            }
          });
        }
      });
    }

  }
}
