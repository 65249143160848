import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-appliedpositions',
  templateUrl: './appliedpositions.component.html',
  styleUrls: ['./appliedpositions.component.scss'],
  providers: [DatePipe, MessageService],
})
export class AppliedpositionsComponent implements OnInit {
  accountnamesres: any;
  appliedpositions: FormGroup;
  first: any;
  page: any = 1;
  rows: any = 10;
  pageCount: any;
  loader: boolean;
  griddata: any;
  totalRecords: any;
  table: boolean;
  downloadOptions: { label: string; icon: string; command: (event: any, i: any) => void; }[];
  records: boolean;
  // It is used for displaying table header data
  cols = [
    { field: 'jobId', header: 'Job Id', width: '4%' },
    { field: 'customerName', header: 'Company', width: '7%' },
    { field: 'jobTitle', header: 'Position Title', width: '7%' },
    { field: 'profileState', header: 'Status', width: '7%' },
    { field: 'Action', header: 'Action', width: '3%' },
  ];
  cols3 = [
    { dataKey: 'jobId', header: 'Job Id' },
    { dataKey: 'customerName', header: 'Company' },
    { dataKey: 'jobTitle', header: 'Position Title' },
    { dataKey: 'status', header: 'Status' },
  ];
  items: {}[];
  statusList1: any = [];
  statusdata = {};
  displayModal: boolean;
  histroy: any;
  subProRecords: number;
  rowData: any;
  profileid: any;
  logindata: any;
  consultantName: any;
  jobid: any;
  // tslint:disable-next-line: max-line-length
  constructor(private apiservice: ApisService, private fb: FormBuilder, private breadcrumbservice: BreadcrumbService) {
    this.breadcrumbservice.setItems([
      { label: 'Applied Positions', routerLink: '/appliedpositions' }]
    );
  }
  ngOnInit(): void {
    this.logindata = JSON.parse(this.apiservice.decodeData());
    this.consultantName = this.logindata.name;
    this.appliedpositions = this.fb.group({
      jobId: [''],
      companyName: [''],
      jobTitle: [''],
    });
    this.getConStatus();
    this.accountNames();
    this.appliedopenpositiongrid();
  }
  // It is used for displaying status dropdown values
  getConStatus() {
    this.apiservice.getApi(environment.lookupsData + 'RPLPS').subscribe((res) => {
      this.statusList1 = res.data;
      this.statusList1.forEach((element) => {
        this.statusdata[element.code] = element.label;
      });
    });
  }
  // Window keydown listener
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.appliedopenpositiongrid();
    }
  }
  // It is used for displaying  company dropdown values
  accountNames() {
    this.apiservice.getApi(environment.accountnamesdropdown).subscribe((res: any) => {
      this.accountnamesres = res.data;
    });
  }
  // It is used to reset search fields
  reset() {
    this.appliedpositions.reset();
    this.appliedopenpositiongrid();
  }
  // It is used to show applied position table data
  appliedopenpositiongrid() {
    // tslint:disable-next-line: max-line-length
    const jobid = this.appliedpositions.controls.jobId.value === null || this.appliedpositions.controls.jobId.value === undefined || this.appliedpositions.controls.jobId.value === '' ? '' : this.appliedpositions.controls.jobId.value;
    // tslint:disable-next-line: max-line-length
    const compName = this.appliedpositions.controls.companyName.value === null || this.appliedpositions.controls.companyName.value === undefined || this.appliedpositions.controls.companyName.value === '' ? '' : this.appliedpositions.controls.companyName.value.employerName;
    // tslint:disable-next-line: max-line-length
    const jobtitle = this.appliedpositions.controls.jobTitle.value === null || this.appliedpositions.controls.jobTitle.value === undefined || this.appliedpositions.controls.jobTitle.value === '' ? '' : this.appliedpositions.controls.jobTitle.value;
    const data = '?jobId=' + jobid + '&companyName=' + compName + '&jobTitle=' + jobtitle;
    this.loader = true;
    this.apiservice.getApi(environment.appliedpositions + this.page + '/' + this.rows + data).subscribe((res: any) => {
      this.loader = false;
      this.griddata = res.data;
      this.totalRecords = res.count;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  // It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.appliedopenpositiongrid();
  }
  // It is used to show action pop up data
  toggle1(event, data) {
    this.rowData = data;
    this.jobid = this.rowData.jobId;
    this.profileid = this.rowData.reqProfileId;
    this.items = [
    ];
  }
  // It is used to show download dropdown data
  downLoadsToggle() {
    this.downloadOptions = this.downloadOptions = [
    { label: 'Download XLSX', icon: 'pi pi-download', command: (event, i) => { this.exportExcel(); }, },
    {label: 'Download PDF',  icon: 'pi pi-download', command: (event, i) => {this.exportPdf(); }, },
    ];
    }
  // It is used to show profile activity pop up
  profilehistroy() {
    this.displayModal = true;
    this.apiservice.getApi(environment.getProfileActivity + this.profileid).subscribe((res: any) => {
      this.histroy = res.data;
      this.loader = false;
    });
  }
  exportExcel() {
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols3'] = [];
    ws['!cols'] = [ {width : 10}, {width : 20}  , {width : 20} , {width : 10}, {width : 10}, {width : 10}, {width : 10}, {hidden : true} ];
    ws['!rows'] = [{hpt : 30}, {hpt : 30}, ];
    ws['!cols3'][3] = { hidden: true };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ExcelSheet.xlsx');
  }
  exportPdf() {
    this.griddata.forEach((element) => {
    element.status = this.statusdata[element.profileState];
  });
    const doc = new jsPDF('p', 'pt');
    autoTable(doc, {
      headStyles: { fillColor: '#2bb9d5', cellPadding: 5   },
      margin: { left: 17},
    columns: this.cols3,
    body: this.griddata,
    didDrawPage: (dataArg) => {
      doc.text('Applied Open Positions', dataArg.settings.margin.left, 30);
    }
  });
    doc.save('Applied Open Positions.pdf');
}
}
