<p-toast position="bottom-right" key="br" ></p-toast>
<p-card>
            <form [formGroup]="titleForms">
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Job Category</label>
                        <p-dropdown [options]="jobTitle1" placeholder="Select Job Category" optionLabel="jobCategory" 
                            autocomplete="off" formControlName="jobTitle" [showClear]="true">
                        </p-dropdown>
                    </div>
                </div>
                <div class="btnsCSS1 buttonalign">
                    <button pButton type="button" icon='pi pi-plus' label="Add" (click)="addDepartment()" 
                        class="addhBtn"></button>
                    <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" 
                        class="addhBtn"></button>
                    <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn"  (click)="search()"></button>
                </div>
            </form>
</p-card><br>
<p-card class="pgpos">
    <div *ngIf="table">
         <!--It is used for displaying the requirement preference table-->
        <p-table [columns]="cols" [value]="gridData" selectionMode="single"  [autoLayout]="true" dataKey="edit" [responsive]="true"
        sortMode="multiple">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        [ngStyle]="{'width': col.width }"  [pSortableColumn]="col.field">
                        <span>
                            {{col.header}}
                        </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
                <tr>
                    <td>
                        {{data.catogoryName}}
                    </td>
                    <td>
                        {{hiretypeTable[data.lkHireType]}}
                    </td>
                    <td>
                    
                        {{billingtypeTable[data.lkBillingType]}}
                    </td>

                    <td class="textCenter"><a (click)="actionpopup.toggle($event); toggle($event, data)" 
                            class="pi pi-ellipsis-v pointer"></a>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10 " >
                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]" (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any requirement preferences</em><br>
            </div>
        </div>
    </p-card>
</p-card>

<form [formGroup]="titleAddForm">
    <!-- It is used for add requirement preference pop up -->
    <p-dialog class="dialogBox" header="Add Requirement Preference" [(visible)]="displayModal" [modal]="true" [contentStyle]="{'overflow': 'visible'}"
        [baseZIndex]="1" [draggable]="false" [resizable]="false" [style]="{'width': '400px'}">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Job Category<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="jobTitle" formControlName="addjobTitle" optionLabel="jobCategory" optionValue="id"
                    placeholder="Select Job Category" [showClear]="true"></p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.titleAddForm.get('addjobTitle').touched && this.titleAddForm.get('addjobTitle').invalid && this.titleAddForm.get('addjobTitle').errors.required">
                            Please select job category
                        </span>
                    </div>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Hire Type<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="hireTypeList"  placeholder="Select Hire Type"  optionLabel="label"
                [showClear]="true"  optionValue="code" formControlName="hireType"></p-dropdown>
                <div>
                    <span class="validationtest"
                        *ngIf="this.titleAddForm.get('hireType').touched && this.titleAddForm.get('hireType').invalid && this.titleAddForm.get('hireType').errors.required">
                        Please select hire type
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Billing Type<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="billingList" placeholder="Select Billing Type" 
                [showClear]="true"  optionLabel="label" optionValue="code" formControlName="billingType"></p-dropdown>
                <div>
                    <span class="validationtest"
                        *ngIf="this.titleAddForm.get('billingType').touched && this.titleAddForm.get('billingType').invalid && this.titleAddForm.get('billingType').errors.required">
                        Please select billing type
                    </span>
                </div>
            </div>
        </div> <br>
        <div class="btnsCSS1">
            <button pButton type="button" icon='pi pi-times' label="Cancel" class="addhBtn" 
                (click)="addCancel()"></button>
            <button pButton type="button" icon='pi pi-save' label="Save" class="addhBtn"  [disabled]="disable" (click)="SaveDailog1()"></button>
        </div><br><br>
    </p-dialog>
    <p-toast position="bottom-right" key="br" ></p-toast>
</form>
<form [formGroup]="updateForm">
<!-- It is used for edit requirement preference pop up -->
    <p-dialog class="dialogBox" header="Edit Requirement Preference" [(visible)]="displayModal1" [modal]="true"  [contentStyle]="{'overflow': 'visible'}"
      [baseZIndex]="1" [draggable]="false" [resizable]="false" [style]="{'width': '400px'}" >
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <label class="inputfields">Job Category</label>
                        <input id="input" type="text" pInputText  autocomplete="off" 
                      formControlName="editjobTitle" disabled="true" maxlength="30">
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <label class="inputfields">Hire Type<span class="mandatoryColor">*</span></label>
            <p-dropdown [options]="hireTypeList"  placeholder="Select Hire Type"  optionLabel="label"
            [showClear]="true"   optionValue="code" formControlName="hireTypes"></p-dropdown>
            <div>
                <span class="validationtest"
                    *ngIf="this.updateForm.get('hireTypes').touched && this.updateForm.get('hireTypes').invalid && this.updateForm.get('hireTypes').errors.required">
                    Please select hire Type
                </span>
            </div>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <label class="inputfields">Billing Type<span class="mandatoryColor">*</span></label>
            <p-dropdown [options]="billingList" placeholder="Select Billing Type" [showClear]="true"
                optionLabel="label" optionValue="code" formControlName="billingTypes"></p-dropdown>
            <div>
                <span class="validationtest"
                    *ngIf="this.updateForm.get('billingTypes').touched && this.updateForm.get('billingTypes').invalid && this.updateForm.get('billingTypes').errors.required">
                    Please select billing type
                </span>
            </div>
        </div>
    </div>        
    <br>
    <div class="btnsCSS1">
        <button pButton type="button" icon='pi pi-times' label="Cancel" class="addhBtn" (click)="editCancel()" 
            ></button>
        <button pButton type="button" icon='pi pi-save' label="Update" [disabled]="disable" class="addhBtn" (click)="editSave()"></button>
    </div><br><br>
    </p-dialog>
    <p-toast position="bottom-right" key="br" ></p-toast>
  </form>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight" >
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif" class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>