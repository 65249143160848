import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { BnNgIdleService } from 'bn-ng-idle';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApisService } from './services/apis.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    // tslint:disable-next-line: max-line-length
    constructor(private primengConfig: PrimeNGConfig, public router: Router, private bnIdle: BnNgIdleService, private apiService: ApisService) { }
    response: any;
    layoutMode = 'static';
    darkMenu = true;
    profileMode = 'popup';
    inputStyle = 'outlined';
    ripple: boolean;
    handleMessage(arg0: string) {
        throw new Error('Method not implemented.');
    }
    ngOnInit() {
        // tslint:disable-next-line: deprecation
        this.bnIdle.startWatching(900).subscribe((isTimedOut: boolean) => {
            if (isTimedOut) {
                this.tokenexpery();
                sessionStorage.clear();
                this.router.navigateByUrl('login');
            }
        });

        this.primengConfig.ripple = true;
    }
    tokenexpery() {
        const dataatoken = sessionStorage.getItem('autoID');
        const reqbody = {
            id: dataatoken
          };
        if (dataatoken === null) {
            this.response = '';

        } else {


            // tslint:disable-next-line: deprecation
            this.apiService.putApi(environment.updateaccesslog , reqbody).subscribe((res) => {
                this.response = res;
            });
        }
    }
}
