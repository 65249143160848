import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-recruiter',
  templateUrl: './recruiter.component.html',
  styleUrls: ['./recruiter.component.scss'],
  providers: [DatePipe, MessageService],
})
export class RecruiterComponent implements OnInit {
   // It is used for displaying table header data
   cols = [
    { field: 'userName', header: 'Recruiter Name'},
    { field: 'companyName', header: 'Company'},
    { field: 'phoneNumber', header: 'Phone Number'},
    { field: 'email', header: 'Email'},
    { field: 'lkStatus', header: 'Status'},
  ];
  cols1 = [
    { field: 'companyName', header: 'Company'},
    { field: 'lkStatus', header: 'Status'},
    { field: 'createdDate', header: 'Created Date'},
    { field: '', header: 'Action'}
  ];
  recruiterFormGroup: any;
  userId: any;
  loader: boolean;
  gridRes: any;
  totalRecords: number;
  table: boolean;
  records: boolean;
  page: any = 1;
  rows: any = 10;
  roleId: any;
  conTotalRecords: number;
  conTable: boolean;
  conRecords: boolean;
  ConGridRes: any;
  first: any;
  pageCount: any;
  rowData: any;
  items: {
    label: string; icon: string;
    // tslint:disable-next-line:no-shadowed-variable
    command: (event: any, i: any) => void;
  }[];
  candidaterejectpopup: boolean;
  RejectData: any;
  splits: any;
  mail: string;
  dropdown: any = [];
  gettatusData = {};
  // Window keydown listener
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.search();
    }
  }
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private messageService: MessageService, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Recruiters', routerLink: '/recuiters' },
    ]);
  }
  ngOnInit(): void {
    this.recruiterFormGroup = this.fb.group({
      reqNameForm: [''],
      urlForm: [''],
      phForm: [''],
      rejectReason: [''],
    });
    this.statusdrop();
    const userDetails = JSON.parse(this.apisService.decodeData());
    this.roleId = userDetails.roles[0].id;
    if (this.roleId === 15) {
      this.consultantGridData();
    } else {
      this.gridData();
    }
  }
  // It is used to reset the search fields
  reset() {
    this.recruiterFormGroup.reset();
    if (this.roleId === 15) {
      this.consultantGridData();
    } else {
      this.gridData();
    }
  }
  emailToast(){
    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
  }
  // It is used to show table data
  gridData() {
    const pageNo = this.page;
    const pageCount = this.rows;
    const orgId = '/' + pageNo + '/' + pageCount;
    const acctdetails = JSON.parse(this.apisService.decodeData());
    this.userId = acctdetails.userId;
    this.loader = true;
    // tslint:disable-next-line:max-line-length
    const recName = this.recruiterFormGroup.controls.reqNameForm.value === undefined ? '' : this.recruiterFormGroup.controls.reqNameForm.value
      === null ? '' : this.recruiterFormGroup.controls.reqNameForm.value;
    const recUrl = this.recruiterFormGroup.controls.urlForm.value === undefined ? '' : this.recruiterFormGroup.controls.urlForm.value
      === null ? '' : this.recruiterFormGroup.controls.urlForm.value;
    // tslint:disable-next-line:max-line-length
    const recPh = this.recruiterFormGroup.controls.phForm.value === undefined ? '' : this.recruiterFormGroup.controls.phForm.value === null ? '' : this.recruiterFormGroup.controls.phForm.value;
    // tslint:disable-next-line:max-line-length
    this.apisService.getApi(environment.recruiter + orgId + '/' + this.userId + '?recruiterName=' + recName + '&url=' + recUrl + '&phone=' + recPh).subscribe((res) => {
      this.gridRes = res.data.records;
      this.totalRecords = res.data.count;
      this.loader = false;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  toggle( event, gridData) {
    this.rowData = gridData;
  }
  rejectToggle( event, RejectData) {
    this.RejectData = RejectData;
    this.candidaterejectpopup = true;
    this.recruiterFormGroup.controls.rejectReason.disable();
    this.recruiterFormGroup.controls.rejectReason.patchValue(this.RejectData.comment);
  }
  search(){
    if (this.roleId === 15) {
      this.consultantGridData();
    } else {
      this.gridData();
    }
  }
  // It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.search();
  }
  // It is used to show consultant recruiter table data
  consultantGridData() {
    this.loader = true;
    const pageNo = this.page;
    const pageCount = this.rows;
    const orgId =  pageNo + '/' + pageCount;
    // tslint:disable-next-line:max-line-length
    const recName = this.recruiterFormGroup.controls.reqNameForm.value === undefined ? '' : this.recruiterFormGroup.controls.reqNameForm.value
    === null ? '' : this.recruiterFormGroup.controls.reqNameForm.value;
    const recUrl = this.recruiterFormGroup.controls.urlForm.value === undefined ? '' : this.recruiterFormGroup.controls.urlForm.value
    === null ? '' : this.recruiterFormGroup.controls.urlForm.value;
  // tslint:disable-next-line:max-line-length
    const recPh = this.recruiterFormGroup.controls.phForm.value === undefined ? '' : this.recruiterFormGroup.controls.phForm.value === null ? '' : this.recruiterFormGroup.controls.phForm.value;
    const acctdetails = JSON.parse(this.apisService.decodeData());
    this.userId = acctdetails.userId;
    // tslint:disable-next-line:max-line-length
    this.apisService.getApi(environment.consultantRecruiterGrid + orgId + '/'  + this.userId + '?name=' + recName + '&url=' + recUrl + '&number=' + recPh ).subscribe((res) => {
      this.loader = false;
      this.ConGridRes = res.data.records;
      this.conTotalRecords = res.data.count;
      if (this.conTotalRecords !== 0) {
      this.conTable = true;
      this.conRecords = false;
    } else {
      this.conTable = false;
      this.conRecords = true;
    }
  });
  }
  // It is used to copy email
  copyInputMessage(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
  // It is used for displaying status dropdown
  statusdrop() {
    this.apisService.getApi(environment.associateddrop + '/' + 'ATESL').subscribe((res) => {
      this.dropdown = res.data;
      this.dropdown.forEach(element => {
        this.gettatusData[element.code] = element.label;
      });
    });
  }
  getToolTipContent(tootipdata) {
    return tootipdata;
  }
  // It is used to navigate the website
  goToLink(url: string) {
    const addressLink = url.includes('http') ? url : `http://${url}`;
    window.open(addressLink, '_blank');
  }
}
