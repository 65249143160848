import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-customer-add-contacts',
  templateUrl: './customer-add-contacts.component.html',
  styleUrls: ['./customer-add-contacts.component.scss'],
  providers: [DatePipe, MessageService],
})
export class CustomerAddContactsComponent implements OnInit {
  AddContactsForm: FormGroup;
  drop: any = [];
  state: any = [];
  getData: any;
  workLocation: any = [];
  accountid: string;
  userId: any;
  loader = false;
  id: any;
  emailId: any;
  displayemail: boolean;
  roleData: any = [];
  orgIdValue: any;
  // It is used for the displaying the  gender Dropdown Values
  genderDrop = [
    { id: 'M', name: 'Male' },
    { id: 'F', name: 'Female' },
  ];
  actId: any;
  mailExtensionValue: string;
  rowid: string;
  rowID: any;
  roles: any = [];
  officenummsg: string;
  officenum: boolean;
  displayNumber: boolean;
  roleid: any;
  loginData: any;
  actID: any;
  salescustAccId: any;
  phSpinnerHide: boolean;
  phCheckHide: boolean;
  acctype: string;
  useraccId: any;
  vacctype: string;
  sacctype: string;
  customerId: string;
  custAccId: string;
  displaymNumber: boolean;
  phSpinnerHide1: boolean;
  mobilenum: any;
  mobilenummsg: string;
  phCheckHide1: boolean;
  salescustAccid: string;
  oCountryCodeDropdown: any = [];
  mCountryCodeDropdown: any = [];
  mailSpinnerHide: boolean;
  mailCheckHide: boolean;
  items: any[];
  disablebtn: boolean;
  salescustaccname: any;
  usrroleid: any;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private apisService: ApisService, private messageService: MessageService, private router: Router, private breadCrumbService: BreadcrumbService) {
    this.loginData = JSON.parse(this.apisService.decodeData());
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
      this.roleid = this.loginData.roles[0].id;
    } else {
      this.roleid = this.usrroleid;
    }
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.actID = routeParams.params.accID;
    this.salescustAccId = JSON.parse(sessionStorage.getItem('salescustAccId'));
    this.salescustaccname = routeParams.params.salescustomeraacname;
    // tslint:disable-next-line:max-line-length
    if (JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 2 || JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 8 || JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 13) {
      this.id = JSON.parse(sessionStorage.getItem('loginData')).orgId;
    } else {
      this.id = JSON.parse(this.apisService.decodeData()).accId;
    }
    if (this.roleid === 14) {
      this.breadCrumbService.setItems([
        { label: 'Customers', routerLink: '/customers' },
        { label: 'Contacts', routerLink: ['/contacts', this.salescustAccId, { queryParams: this.salescustaccname, From: 'customer' }] },
        { label: 'Contact Add', routerLink: ['/contact-add'] },
      ]);
    } else if (this.roleid === 6) {
      this.breadCrumbService.setItems([
        { label: 'Account Details', routerLink: '/accountdetails' },
        { label: 'Contacts', routerLink: ['/contacts', this.id], },
        { label: 'Contact Add', routerLink: ['/contact-add'], },
      ]);
    }
    else {
      this.breadCrumbService.setItems([
        { label: 'Account Details', routerLink: '/account-details' },
        { label: 'Contacts', routerLink: ['/contacts', this.id], },
        { label: 'Contact Add', routerLink: ['/contact-add'], },
      ]);
    }
    // tslint:disable-next-line:max-line-length
    if (JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 0 || JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 1) {
      this.accountid = sessionStorage.getItem('account_id');
    } else {
      this.accountid = JSON.parse(sessionStorage.getItem('loginData')).orgId;
    }
  }
  ngOnInit() {
    this.actId = JSON.parse(this.apisService.decodeData());
    this.rowid = sessionStorage.getItem('acct_rowid');
    this.salescustAccid = sessionStorage.getItem('salescustaccid');
    this.acctype = sessionStorage.getItem('custacctype');
    this.vacctype = sessionStorage.getItem('Act_type');
    this.sacctype = sessionStorage.getItem('SAct_type');
    this.rowID = this.actId.roles[0].id === 1 ? this.rowid : this.actId.accId;
    this.AddContactsForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(25)]],
      middleName: ['', [Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(15)]],
      lastName: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(25)]],
      Email: ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/), Validators.maxLength(60)]],
      gender: [{ id: 'M', name: 'Male' }],
      Title: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(25)]],
      offphone: ['', Validators.required],
      Mobilephone: [''],
      oMobileDrp: [''],
      mMobileDrp: [''],
      worklocation: [''],
      addressLine1: ['', Validators.maxLength(50)],
      addressLine2: ['', Validators.maxLength(50)],
      mailboxNumber: ['', [Validators.maxLength(10)]],
      city: ['', [Validators.pattern('^[\\sa-zA-z0-9]*$'), Validators.maxLength(20)]],
      country: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', [Validators.maxLength(10), Validators.pattern('^[0-9-]+$')]],
      role: ['', Validators.required],
    });
    this.getRoleDD();
    this.getCountrydrop();
    this.getlocation();
    this.getdrop();
    this.orgIdValue = JSON.parse(sessionStorage.getItem('loginData')).orgId;
    this.userId = JSON.parse(sessionStorage.getItem('loginData')).userId;
    this.custAccId = sessionStorage.getItem('custaccid');
    this.AddContactsForm.controls.gender.patchValue({ id: 'M', name: 'Male' });
  }
  countryids(event) {
  }
  emailToast() {
    if (this.AddContactsForm.controls.Email.invalid && this.AddContactsForm.controls.Email.errors.pattern) {
    }
    // tslint:disable-next-line: max-line-length
    else if (this.AddContactsForm.controls.Email.value === '' || this.AddContactsForm.controls.Email.value === null || this.AddContactsForm.controls.Email.value === undefined) {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please enter email.' });
    }
    else {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
    }
  }
  copyInputMessage(data) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (data));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
  getdrop() {
    this.mCountryCodeDropdown = this.apisService.getdropdwon();
    // tslint:disable-next-line: max-line-length
    this.AddContactsForm.controls.mMobileDrp.patchValue({ code: '+1', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png', id: '2', label: 'UNITED STATES', });
    // tslint:disable-next-line: max-line-length
    this.AddContactsForm.controls.oMobileDrp.patchValue({ code: '+1', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png', id: '2', label: 'UNITED STATES' });
  }
  // It is used for the displaying the country Dropdown Values
  getRoleDD() {
    const rolevalue = sessionStorage.getItem('Act_type');
    const getroles = rolevalue === 'B' ? 'V' : rolevalue;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.roleDD + getroles).subscribe((res: any) => {
      this.roleData = res.data;
    });
  }
  getCountrydrop() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'ADLC').subscribe((res) => {
      this.drop = res.data;
      this.AddContactsForm.controls.country.patchValue(this.drop.filter((x) => x.code === 'USA')[0]);
      this.getState('USA');
    });
  }
  // It is used for the displaying the get location Dropdown Values
  getlocation() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.locationEdit + '/' + this.actId.accId).subscribe((res: any) => {
      this.workLocation = res.data;
    });
  }
  // It is used for the displaying the states Dropdown Values based on country
  getState(code) {
    if (code === null || code === '' || code === undefined) {
      this.state = [];
    }
    else {
      // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.state + '/' + code).subscribe((res) => {
        this.state = res.data;
      });
    }
  }
  // It is used for the adding the contact Details, converting date format and keep the validations
  contactAdd() {
    if (this.AddContactsForm.invalid) {
      const controls = this.AddContactsForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      const roleValue = this.AddContactsForm.controls.role.value;
      const addAccId = this.roleid === 3 ? this.acctype : this.vacctype;
      const saId = this.roleid === 14 ? this.sacctype : addAccId;
      const addconId = this.roleid === 14 ? this.salescustAccid : this.rowID;
      this.roles = roleValue.map((x) => x.id);
      const data = {
        accId: addconId,
        address1: this.AddContactsForm.controls.addressLine1.value,
        address2: this.AddContactsForm.controls.addressLine2.value,
        city: this.AddContactsForm.controls.city.value,
        // tslint:disable-next-line:max-line-length
        country: this.AddContactsForm.controls.country.value === undefined || this.AddContactsForm.controls.country.value === null || this.AddContactsForm.controls.country.value === '' ? -1 : this.AddContactsForm.controls.country.value.code,
        email: this.AddContactsForm.controls.Email.value,
        fname: this.AddContactsForm.controls.firstName.value,
        gender: this.AddContactsForm.controls.gender.value.id || '',
        lname: this.AddContactsForm.controls.lastName.value,
        mailBox: this.AddContactsForm.controls.mailboxNumber.value,
        mname: this.AddContactsForm.controls.middleName.value,
        officePhone: this.AddContactsForm.controls.offphone.value,
        phone: this.AddContactsForm.controls.Mobilephone.value,
        // tslint:disable-next-line:max-line-length
        state: this.AddContactsForm.controls.state.value === undefined || this.AddContactsForm.controls.state.value === null || this.AddContactsForm.controls.state.value === '' ? -1 : this.AddContactsForm.controls.state.value.code,
        title: this.AddContactsForm.controls.Title.value,
        workLocation: this.AddContactsForm.controls.worklocation.value.locId || '',
        zip: this.AddContactsForm.controls.zip.value,
        roleList: this.roles,
        typeOfUser: saId,
        // tslint:disable-next-line:max-line-length
        occode: this.AddContactsForm.controls.oMobileDrp.value === undefined || this.AddContactsForm.controls.oMobileDrp.value === null || this.AddContactsForm.controls.oMobileDrp.value === '' ? '' : this.AddContactsForm.controls.oMobileDrp.value.id,
        // tslint:disable-next-line:max-line-length
        mccode: this.AddContactsForm.controls.mMobileDrp.value === undefined || this.AddContactsForm.controls.mMobileDrp.value === null || this.AddContactsForm.controls.mMobileDrp.value === '' ? '' : this.AddContactsForm.controls.mMobileDrp.value.id
      };
      this.disablebtn = true;
      this.loader = true;
      // tslint:disable-next-line: deprecation
      this.apisService.postApi(environment.addContact, data).subscribe((res: any) => {
        this.getData = res;
        this.loader = false;
        if (this.getData.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message, });
          this.AddContactsForm.reset();
          if (this.roleid === 14) {
            setTimeout(() => {
              this.router.navigate(['/contacts/:id']);
            }, 2000);
            this.router.navigate(['/contacts', this.salescustAccId], {
              queryParams: {
                salescustomeraacname: this.salescustaccname
              }
            });
          } else if (this.roleid === 6) {
            setTimeout(() => {
              this.router.navigate(['/contacts', this.id]);
            }, 2000);
          }
          else {
            setTimeout(() => {
              this.router.navigate(['/contacts', this.id]);
            }, 2000);
          }
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.failed, });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error });
        }
      });
    }
  }
  // It is used for the reset the form values
  reset() {
    this.loader = true;
    this.AddContactsForm.reset();
    this.loader = false;
    this.AddContactsForm.controls.gender.patchValue({ id: 'M', name: 'Male' });
    this.AddContactsForm.controls.country.patchValue(this.drop.filter((x) => x.code === 'USA')[0]);
    this.getState('USA');
    // tslint:disable-next-line: max-line-length
    this.AddContactsForm.controls.mMobileDrp.patchValue({ code: '+1', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png', id: '2', label: 'UNITED STATES', });
    // tslint:disable-next-line: max-line-length
    this.AddContactsForm.controls.oMobileDrp.patchValue({ code: '+1', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png', id: '2', label: 'UNITED STATES' });
  }
  // It is used for navigate the vendor contacts page
  homeroute() {
    // tslint:disable-next-line:max-line-length
    if (JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 2 || JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 8 || JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 13) {
      this.id = JSON.parse(sessionStorage.getItem('loginData')).orgId;
    } else {
      this.id = JSON.parse(this.apisService.decodeData()).accId;
    }
    this.router.navigate(['/contacts', this.id], {
      queryParams: {
        salescustomeraacname: this.salescustaccname
      }
    });
  }
  // It is used for verifying the already email exist or not
  emailverification() {
    this.displayemail = false;
    const email = this.AddContactsForm.controls.Email.value;
    if (email === null || email === '' || email === undefined) {
    } else {
      if (this.AddContactsForm.controls.Email.invalid && this.AddContactsForm.controls.Email.errors.pattern) {
      }
      else {
        // tslint:disable-next-line: deprecation
        this.mailSpinnerHide = true;
        this.apisService.getApi(environment.emailVerification + email).subscribe((res) => {
          this.emailId = res.data.isEmailExisted;
          if (this.emailId === true) {
            this.mailSpinnerHide = false;
            this.emailId = 'Email already exist';
            this.mailExtensionValue = '';
            setTimeout(() => {
              this.emailId = '';
            }, 3000);
            this.AddContactsForm.controls.Email.reset();
          } else {
            this.mailSpinnerHide = false;
            this.mailCheckHide = true;
            this.emailId = '';
            setTimeout(() => {
              this.mailCheckHide = false;
            }, 3000);
          }
        });
      }
    }
  }
  getPhon() {
    this.displayNumber = false;
    const num = this.AddContactsForm.controls.offphone.value;
    if (num === '' || num === null || num === undefined || num === '(___) ___-____') {
    }
    else {
      this.phSpinnerHide = true;
      // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.phonerequired + num).subscribe(res => {
        this.officenum = res;
        this.officenum = res.data.isNumberExisted;
        if (this.officenum === true) {
          this.phSpinnerHide = false;
          this.officenummsg = 'Office number already exist';
          setTimeout(() => {
            this.officenummsg = '';
          }, 5000);
          this.AddContactsForm.get('offphone').reset();
        }
        else {
          this.phSpinnerHide = false;
          this.phCheckHide = true;
          setTimeout(() => {
            this.phCheckHide = false;
          }, 3000);
        }
      });
    }
  }
  getMobilePhon() {
    this.displaymNumber = false;
    const num = this.AddContactsForm.controls.Mobilephone.value;
    if (num === '' || num === null || num === undefined || num === '(___) ___-____') {
    }
    else {
      this.phSpinnerHide1 = true;
      // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.phonerequired + num).subscribe(res => {
        this.mobilenum = res;
        this.mobilenum = res.data.isNumberExisted;
        if (this.mobilenum === true) {
          this.phSpinnerHide1 = false;
          this.mobilenummsg = 'Mobile number already exist';
          setTimeout(() => {
            this.mobilenummsg = '';
          }, 5000);
          this.AddContactsForm.get('Mobilephone').reset();
        }
        else {
          this.phSpinnerHide1 = false;
          this.phCheckHide1 = true;
          setTimeout(() => {
            this.phCheckHide1 = false;
          }, 3000);
        }
      });
    }
  }
}
