import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-vendor-subcontractor',
  templateUrl: './vendor-subcontractor.component.html',
  styleUrls: ['./vendor-subcontractor.component.scss'],
  providers: [DatePipe],
})
export class VendorSubcontractorComponent implements OnInit {
  vendor: any;
  subcontractor: any;
  searchName: string;
  vendorsForm: FormGroup;
  rows: any = 10;
  page: any = 1;
  cols: { field: string; header: string; }[];
  first: any;
  pageCount: any;
  records: boolean;
  table: boolean;
  totalRecords: any;
  reqId: any;
  customerGrid: any;
  loader: boolean;
  nameList: any;
  role: any;
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.getTableData();
    }
  }
  constructor(private route: ActivatedRoute, private datepipe: DatePipe, private breadcrumbService: BreadcrumbService,
    private fb: FormBuilder, private apiservice: ApisService) {
  }
  ngOnInit(): void {
    this.vendorsForm = this.fb.group({
      name: ['']
    });
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.vendor = routeParams.params.vendor;
    this.subcontractor = routeParams.params.subcontractor;
    this.reqId = routeParams.params.reqId;
    this.role = routeParams.params.role;
    if (this.vendor === 'vendor') {
      this.searchName = 'Vendor';
      this.cols = [
        { field: 'cusName', header: 'Vendor Name' },
        { field: 'lkIndustryType', header: 'Release Date' },
        { field: 'website', header: 'Viewed' },
        { field: 'action', header: 'Action' },
      ];
      if(this.role === 'purchasManager') {
        this.breadcrumbService.setItems([
          { label: 'Requirements', routerLink: '/purchas-requirement' },
          { label: 'Vendor List', routerLink: '/vendor-details' }
        ]);
      } else if(this.role === 'projectManager') {
        this.breadcrumbService.setItems([
          { label: 'Requirements', routerLink: '/project-requirements' },
          { label: 'Vendor List', routerLink: '/vendor-details' }
        ]);
      }
    }
    else {
      this.searchName = 'Subcontractor';
      this.cols = [
        { field: 'cusName', header: 'Subcontractor Name' },
        { field: 'lkIndustryType', header: 'Release Date' },
        { field: 'website', header: 'Viewed' },
        { field: 'action', header: 'Action' },
      ];
      if(this.role === 'vendorManager') {
        this.breadcrumbService.setItems([
          { label: 'Requirements', routerLink: '/requirements' },
          { label: 'Subcontractor List', routerLink: '/subcontractor-details' },
        ]);
      } else if(this.role === 'HCM') {
        this.breadcrumbService.setItems([
          { label: 'Requirements', routerLink: '/rec-requirements' },
          { label: 'Subcontractor List', routerLink: '/subcontractor-details' },
        ]);
      }
    }
    this.vendorNameDD();
    this.getTableData();
  }
  vendorNameDD() {
    this.apiservice.getApi(environment.vendorname + this.reqId).subscribe(res => {
      this.nameList = res.data;
    });
  }
  getTableData() {
    this.loader = true;
    const pageNo = this.page;
    const pageCount = this.rows;
    // tslint:disable-next-line: max-line-length
    const name = this.vendorsForm.controls.name.value === null || this.vendorsForm.controls.name.value === undefined || this.vendorsForm.controls.name.value === '' ? 0 : this.vendorsForm.controls.name.value;
    const data = pageNo + '/' + pageCount + '/' + this.reqId + '?venId=' + name;
    this.apiservice.getApi(environment.vendorsubcontractor + data).subscribe(res => {
      this.customerGrid = res.data.records;
      this.totalRecords = res.data.count;
      this.loader = false;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  // pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.getTableData();
  }
  reset() {
    this.loader = true;
    this.vendorsForm.reset();
    this.getTableData();
  }
}
