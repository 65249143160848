<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
    <p-accordion>
        <p-accordionTab [selected]="true">
            <p-header>
                <strong>Open Position Id : </strong>
                <span class="panelLabel">{{this.reqId}}</span>
                <strong class="positionheader">Position Title : </strong> <span
                    class="panelLabel">{{this.positiontitle}}</span>
                <div class="pi pi-arrow-left backRoute" (click)="backNavigate()">
                </div>
            </p-header>
            <form [formGroup]="openPositionEditForm">
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Position Title</label>
                        <input autocomplete="off" id="input" type="text" pInputText
                            placeholder="Enter Position Title(Ex:Project Developer)" formControlName="positionTitle">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                        <label class="inputfields">Position Category</label>
                        <p-dropdown placeholder="Select Position Category" [options]="positionData"
                            optionLabel="jobCategory" [showClear]="true" formControlName="positionCategory"
                            (onChange)="getPositionCategoryBindDataValues($event.id)">
                        </p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                        <label class="inputfields">Job Role</label>
                        <p-dropdown [options]="minMaxRate" placeholder="Select Job Role" optionLabel="title"
                            [showClear]="true" formControlName="jobRole">
                        </p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory"
                        pTooltip="{{this.patchlocationString}}" tooltipPosition="top">
                        <label class="inputfields">Work Location</label>
                        <p-dropdown [options]="locationList" optionLabel="name" placeholder="Select Work Location"
                            [showClear]="true" formControlName="workLocation" appendTo="body"
                            (onChange)="saveLocation($event)">
                            <ng-template let-data pTemplate="item">
                                <div pTooltip="{{data.name + '\n' + data.address1+',' + data.city + ',' + data.state + ',' + data.country}}"
                                    tooltipPosition="right">
                                    {{ data.name }}
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                        <label class="inputfields">Required Experience</label>
                        <p-dropdown [options]="getexperienceOptions" optionLabel="label"
                            placeholder="Select Required Experience" [showClear]="true"
                            formControlName="requiredExperience">
                        </p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                        <label class="inputfields">Hire Type</label>
                        <p-dropdown [options]="hireTypeData" optionLabel="label" placeholder="Select Hire Type"
                            [showClear]="true" formControlName="hireType">
                        </p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                        <label class="inputfields">Planned Start Date</label>
                        <p-calendar id="popup" placeholder="MM-DD-YYYY" dateFormat="mm-dd-yy" [showIcon]="true"
                            [showButtonBar]="true" formControlName="plannedStartDate">
                        </p-calendar>
                    </div>

                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3"></div>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                        <label class="inputfields">Billing Type</label>
                        <p-dropdown [options]="billingData" optionLabel="label" placeholder="Select Billing Type"
                            [showClear]="true" formControlName="billingtype">
                        </p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 container">
                        <label class="inputfields">Duration</label>
                        <div class="p-inputgroup">
                            <!-- <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span> -->
                            <input id="input" type="text" autocomplete="off" formControlName="duration" pInputText
                                placeholder="Enter Duration" maxlength="30">
                            <span class="p-inputgroup-addon">/Hrs</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 container">
                        <label class="inputfields">Per Week</label>
                        <div class="p-inputgroup">
                            <!-- <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span> -->
                            <input id="input" type="text" placeholder="Enter Hrs Per Week" autocomplete="off"
                                formControlName="hrsperWeek" pInputText maxlength="30">
                            <span class="p-inputgroup-addon">/Hrs</span>
                        </div>
                    </div>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-field p-col-6 p-md-6 p-sm-12 p-lg-6">
                        <label class="first">Skill Set</label>
                        <p-multiSelect [showToggleAll]="true" formControlName="skillSet" [options]="skillsList"
                            optionLabel="label" placeholder="Select Skill Set" maxSelectedLabels="15"></p-multiSelect>
                        <div class="skillstyle">
                            <strong>Skill Set : </strong>{{primarySkills.toString()}}
                        </div>
                    </div>
                    <div class="p-field p-col-6 p-md-6 p-sm-12 p-lg-6">
                        <label class="first">Preferred Skill Set </label>
                        <p-multiSelect [options]="skillsList" optionLabel="label" [showToggleAll]="true"
                            formControlName="preferredSkills" placeholder="Select Preferred Skill Set"
                            maxSelectedLabels="15">
                        </p-multiSelect>
                        <div class="skillstyle">
                            <strong>Preferred Skill Set : </strong>{{skillsList12.toString()}}
                        </div>
                    </div>
                </div>
                <div class="p-fluid p-grid" *ngIf="otherskills">
                    <div class="p-field p-col-12 p-md-6 p-sm-12 p-lg-6">
                        <label class="inputfields">Others Skills</label>
                        <textarea rows="5" maxlength="301" cols="30"
                            placeholder="Enter Others Skills (Ex: Angular, Java)" formControlName="otherskills"
                            pInputTextarea></textarea>

                    </div>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                        <label class="inputfields">Job Description </label>
                        <textarea autocomplete="off" pInputTextarea rows="8" cols="30" placeholder="Job Description"
                            formControlName="jobDescription" maxlength=300 autoResize="false"></textarea>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                        <label class="inputfields">Responsibilities </label>
                        <textarea autocomplete="off" pInputTextarea rows="8" cols="30" placeholder="Responsibilities"
                            formControlName="responsibilities" maxlength=300 autoResize="false"></textarea>
                    </div>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                        <label class="inputfields">Qualifications </label>
                        <textarea autocomplete="off" pInputTextarea rows="8" cols="30" placeholder="Qualifications"
                            formControlName="qualification" maxlength=300 autoResize="false"></textarea>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                        <label class="inputfields">Comments</label>
                        <textarea autocomplete="off" pInputTextarea rows="8" cols="30" placeholder="Comments"
                            formControlName="comments" maxlength=300 autoResize="false"></textarea>
                    </div>
                </div>
                <br>
                <div>
                    <div class="btnsCSS1">
                        <button class="addhBtn" *ngIf="btndisable" (click)="submittedProfileForm()" pButton
                            disable="btndis" type="submit" icon='pi pi-check-circle' label="Apply"></button>
                    </div><br><br>
                </div>
            </form>
        </p-accordionTab>
    </p-accordion>
</p-card>
<p-dialog class="dialogBox" header="Select profile to submit" [(visible)]="display" [modal]="true"
    [style]="{width: '35%'}" [baseZIndex]="1" [draggable]="false" [resizable]="false">
    <form [formGroup]="updateprofileForm">
        <div *ngIf="loaderForDownload">
            <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
        <div class="popupsize">
            <p-table [columns]="updateactioncols" [autoLayout]="true" [value]="resumegrid" dataKey="id"
                [responsive]="true" selectionMode="single" (onRowSelect)="onSelect($event)"
                (onRowUnselect)="onUnselect($event)" [(selection)]="selectedRowForResume">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th id="gridHeader" *ngFor="let col of columns let i=index"
                            (click)="iconToggle[i] = !iconToggle[i]" [ngStyle]="{'width': col.width }">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-data>
                    <tr>
                        <td class="textCenter">{{data.title}}</td>
                        <td class="textCenter">{{data.fileName}}</td>
                        <td class="textCenter" (click)="updatedownload(data) "><a> <em
                                    class="pi pi-download pointer"></em></a></td>
                        <td>
                            <p-tableRadioButton [value]="rowData" name="tableapproval"></p-tableRadioButton>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <br>
        <div class="btnsCSS1 rightalign">
            <button class="addhBtn" pButton type="submit" icon=' pi pi-arrow-right' *ngIf="updateactionBtn"
                [disabled]="radiobtn" (click)="updateresume()" label="Next"></button>
        </div><br><br>
    </form>
</p-dialog>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-dialog class="dialogBox" [(visible)]="displaybill" header="Apply for position" [modal]="true"
    [style]="{width: '35%'}" [baseZIndex]="1" [draggable]="false" [resizable]="false">
    <form [formGroup]="bllrategroup">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                <strong>Job Id : </strong> <span class="panelLabel">{{this.reqId}}</span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <strong>Job Title : </strong> <span class="panelLabel">{{this.positiontitle}}</span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <strong>Profile Title : </strong> <span class="panelLabel">{{this.restitle}}</span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <strong>Profile Name : </strong> <span class="panelLabel skill-desc"
                    pTooltip="{{this.prname}}">{{this.prname}}</span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-5 billrate">
                <label class="inputfields">Request Bill Rate<span class="mandatoryColor">*</span></label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                    <input class="aligncenter" autocomplete="off" id="input" type="text" size="10"
                        formControlName="billRateControl" pInputText placeholder="Request Bill Rate">
                    <span class="p-inputgroup-addon">/Hrs</span>
                </div>
                <span class="validationtest" *ngIf="
                bllrategroup.get('billRateControl').touched &&
                bllrategroup.get('billRateControl').errors?.required && bllrategroup.get('billRateControl').invalid">
                    Please enter request bill rate
                </span>
            </div>
        </div>
        <div class="btnsCSS1">
            <button class="addhBtn" pButton type="submit" icon='pi pi-arrow-left' label="Back"
                (click)="backtopreviouspopup()"></button>
            <button class="addhBtn" pButton type="submit" icon='pi pi-check-circle' *ngIf="updateactionBtn"
                [disabled]="btndis" (click)="update()" label="Submit"></button>
        </div>
    </form>
</p-dialog>