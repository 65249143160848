import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { isEmpty } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-availableopenpositions',
  templateUrl: './availableopenpositions.component.html',
  styleUrls: ['./availableopenpositions.component.scss'],
  providers: [DatePipe, MessageService],
})
export class AvailableopenpositionsComponent implements OnInit {
  @ViewChild('dt') dt: Table;
  consultantAndSubmittedForm: FormGroup;
  // It is used for the displaying the resource Type DropDown Values
  resourceTypeDropDown = [
    { value: 'CA', name: 'Candidates' },
    { value: 'VC', name: 'Employee' },
  ];
  specializationSkills: any;
  cols = [
    { field: 'name', header: 'Candidate Name' },
    { field: 'email', header: 'Email' },
    { field: 'lkExperience', header: 'Experience' },
    { field: 'requiredSkills', header: 'Skills' },
    { field: 'availableFrom', header: 'Available Form' },
    { field: 'expMinRate', header: 'Min Rate' },
    { field: 'expMaxRate', header: 'Max Rate' },
    { field: 'resumeCount', header: 'No. of Profiles' },
    { field: '', header: 'Action', width: '8%' },
  ];
  cols2 = [
    { field: 'edit', header: '#' },
    { field: 'fullName', header: 'File Name' },
    { field: 'email1', header: 'Title' },
    { field: 'experience', header: 'Status' },
    { field: 'consultantSkills', header: 'Resume' },
    { field: 'availableFrom', header: 'Comments' },
    { field: 'submit', header: 'Action' },
  ];
  payRatesTable = [
    { key: 'Pay Rate + 25%', value: 25 },
    { key: 'Pay Rate + 30%', value: 30 },
    { key: 'Pay Rate + 35%', value: 35 },
    { key: 'Pay Rate + 40%', value: 40 },
    { key: 'Pay Rate + 45%', value: 45 },
    { key: 'Pay Rate + 50%', value: 50 },
  ];
  markUpData: any;
  subData: any;
  userData: any;
  reqid: any;
  jobtite: string;
  reqGridData: any;
  rows: any = 10;
  page: any = 1;
  totalRecordsData: any;
  reqiD: string;
  credAcct: string;
  reqdata: any;
  loader: boolean;
  roleId: any;
  reqData: any;
  createdBy: any;
  reqId: string;
  first: any;
  pageCount: any;
  display: any = '';
  startDate: string;
  skillSet: any = [];
  creadedByid: any;
  skilldetailspopup: boolean;
  reqSkillsValue: any = [];
  maxRatevalue: any;
  totalrecords: number;
  consultantskills: any = [];
  table = true;
  records: boolean;
  experienceData: any;
  expLabel: any = [];
  statusData: any;
  isUploading1: boolean;
  filedata: any;
  rowData: any;
  resumeRes: any;
  consultantRequirementDisplay: boolean;
  alertError: boolean;
  consultId: any;
  resumeId: any;     
  payRateValue: any;
  dataService: any;
  display1: boolean;
  availCounsultRes: any;
  popup: boolean;
  candidateName: any;
  payrates: any;
  errorMsg: boolean;
  show: any;
  showd: boolean;
  acctdetails: any;
  currencycode: any[];
  preferencList: any = [];
  currencysymbl: any;
  currencyValue: any;
  disable: boolean;
  reqidd: any;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private messageService: MessageService, private apisService: ApisService, private datepipe: DatePipe, private breadcrumbService: BreadcrumbService, private router: Router) {
    this.reqGridData = JSON.parse(sessionStorage.getItem('reqData'));
    this.roleId = JSON.parse(this.apisService.decodeData()).roles[0].id;
    this.breadcrumbService.setItems([
      { label: 'Open Positions', routerLink: '/open-positions' },
      { label: 'Open Position Edit', routerLink: '/open-position-edit' },
      // { label: 'Available Candidates', routerLink: '/open-position-add-consultant' },
    ]);
  }
  ngOnInit(): void {
    this.acctdetails = JSON.parse(this.apisService.decodeData());
    this.preferencList = this.acctdetails.preferencesList;
    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }
    this.showd = true;
    this.consultantAndSubmittedForm = this.fb.group({
      resourceType: [''],
      consultantEmailId: [''],
      skills: [''],
      startDate: [''],
      fromRate: [''],
      toRate: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      ssnNumber: [''],
      submittedEmailId: ['', Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      skillSet: [''],
      Experience: [''],
      skillDetails: [''],
      comments: ['', Validators.required],
      profileSubmissionCost: [''],
      profileSelectedCost: [''],
      profileRejectionCost: [''],
      fakeCandidateFine: [''],
      securityComments: [''],
      securityDeposit: [''],
      taxTermProcess: [''],
      commentsForStatus: ['', Validators.required],
      billRateControl: ['', [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$'), Validators.min(1)]],
      maxRatePosition: [''],
    });
    this.consultantAndSubmittedForm.controls.resourceType.patchValue(this.resourceTypeDropDown.filter((x) => x.value === 'CA')[0]);
    this.apisService.availableRefresh.subscribe((res)=>{
      if(res == true){
        this.submittedPro();
      } else {
        this.submittedPro();
      }
    })
    this.reqSkillSet();
    this.getExperienceDropDownData();
    this.getStatus();
    const userRequirement = JSON.parse(sessionStorage.getItem('reqData'));
    this.createdBy = userRequirement.createdByAccId;
    this.jobtite = this.reqdata.reqName;
    this.maxRatevalue = this.reqGridData.maxRate;
  }
  // It is used to show skills dropdown values
  reqSkillSet() {
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.specializationSkills = res.data;
    });
  }
  emailToast() {
    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
  }
  getEmails() {
    const resType = this.consultantAndSubmittedForm.get('resourceType').value;
    const email = this.consultantAndSubmittedForm.controls.consultantEmailId.value;
    const body = {
      resourceType: resType === null || resType === '' || resType === undefined ? -1 : resType.value,
      email: email === null || email === '' || email === undefined ? '' : email,
      venId: JSON.parse(sessionStorage.getItem('loginData')).orgId,
    };
  }
  // It is used to show available candidates grid
  submittedPro() {

    this.loader = true;
    this.userData = JSON.parse(this.apisService.decodeData());
    this.reqid = this.reqGridData.reqId;
    const page = this.page;
    const row = this.rows;
    this.reqdata = JSON.parse(sessionStorage.getItem('reqData'));
    this.credAcct = this.reqdata.createdByAccId;
    this.reqId = this.reqdata.reqId;
    // tslint:disable-next-line:max-line-length
    this.apisService.getApi(environment.submitedConsultant + page + '/' + row + '/' + this.userData.accId + '/' + this.credAcct + '/' + this.reqId + '?resourceType=' + '&email=' + '&skills=' + '&availableFrom=' + '&rateFrom=' + '&rateTo=').subscribe((res) => {
      this.subData = res.data.records;
      this.subData.forEach((element) => {
        element.resumeList.forEach((ele, i) => {
          // tslint:disable-next-line:no-string-literal
          ele['conCount'] = element.conCount;
        });
      });
      this.totalRecordsData = res.data.count;
      this.loader = false;
      if (this.totalRecordsData !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }

    });
  }
  // Method to show skills in in table
  getSkilssData(data) {
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.specializationSkills.length; i++) {
      if (data === null || data === undefined || data === '' || data === isEmpty()) {
      } else {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < data.length; j++) {
          if (Number(this.specializationSkills[i].code) === data[j]) {
            selectedPrePrimarySkills3.push(this.specializationSkills[i].label);
          } else {
          }
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  // It is used to copy email to clipboard
  copyInputMessage(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
  // It is used to show status dropdown values
  getStatus() {
    this.apisService.getApi(environment.lookupsData + 'RLS').subscribe((res) => {
      this.statusData = res.data;
    });
  }
  getStatusTable(data) {
    const status = this.statusData.find((x) => x.code === data).label;
    return status;
  }
  // It is used to show experience dropdown values
  getExperienceDropDownData() {
    this.apisService.getApi(environment.lookupsData + 'RELYE').subscribe((res: any) => {
      this.experienceData = res.data;
      this.experienceData.forEach((element) => {
        this.expLabel[element.code] = [element.label];
      });
    });
  }
  reqSkills1(data) {
    this.display = true;
    this.reqSkillsValue = data;
  }
  // It is used to search available candidates
  searchConsultantVend() {
    if (this.consultantAndSubmittedForm.controls.startDate.value) {
      const startdate = this.consultantAndSubmittedForm.controls.startDate.value;
      const consultantStartDate = new Date(Date.parse(startdate));
      this.startDate = this.datepipe.transform(consultantStartDate, 'MM-dd-yyyy');
    }
    this.consultantskills = this.consultantAndSubmittedForm.controls.skills.value;
    this.skillSet = [];
    if (this.consultantAndSubmittedForm.controls.skills.value === null) {
    } else {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.consultantskills.length; i++) {
        this.skillSet.push(this.consultantskills[i].code);
      }
    }
    const email = this.consultantAndSubmittedForm.controls.consultantEmailId.value;
    this.reqid = this.reqGridData.reqId;
    this.creadedByid = this.reqGridData.createdByAccId;
    const page = this.page;
    const row = this.rows;
    const email1 = email === null || email === undefined ? '' : email;
    const skillValues = this.consultantAndSubmittedForm.controls.skills.value === ('' || [] || null) ? '' : this.skillSet.toString();
    const availableFroms = this.startDate === undefined || this.startDate === null ? '' : this.startDate;
    // tslint:disable-next-line:max-line-length
    const rateFroms = this.consultantAndSubmittedForm.get('fromRate').value === null ? '' : this.consultantAndSubmittedForm.get('fromRate').value;
    const rateTos = this.consultantAndSubmittedForm.get('toRate').value === null ? '' : this.consultantAndSubmittedForm.get('toRate').value;
    const searchData = page + '/' + row + '/' + this.userData.accId + '/' + this.creadedByid + '/' + this.reqid + '?resourceType=' + '&email=' + email1 + '&skills=' + skillValues + '&availableFrom=' + availableFroms + '&rateFrom=' + rateFroms + '&rateTo=' + rateTos;
    this.loader = true;
    this.apisService.getApi(environment.avaliableConsultant + searchData).subscribe((res) => {
      this.loader = false;
      this.subData = res.data.records;
      this.totalRecordsData = res.data.count;
      if (this.totalRecordsData !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  // It is used to show skills in overlay panel pop up
  skillSetPopup(data) {
    this.skilldetailspopup = true;
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.specializationSkills.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < data.length; j++) {
        if (Number(this.specializationSkills[i].code) === data[j]) {
          selectedPrePrimarySkills3.push(this.specializationSkills[i].label);
        } else {
        }
      }
    }
    this.reqSkillsValue = selectedPrePrimarySkills3;
  }
  // Pagination method
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.submittedPro();
  }
  // search panel reset method
  resetConsultant() {
    this.consultantAndSubmittedForm.reset();
  
    this.consultantAndSubmittedForm.controls.resourceType.patchValue(this.resourceTypeDropDown.filter((x) => x.value === 'CA')[0]);
    // this.consultantAndSubmittedForm
    //   .get('resourceType')
    //   .patchValue({ value: 'IC', name: 'Consultant' });
    this.submittedPro();
  }
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchConsultantVend();

    }
  }
  // Back navigation to previous screen
  backNavigate() {
    this.router.navigate(['/open-position-edit']);
  }
  // It isused to download uploaded file
  downloadFileapi(data) {
    this.show = true;
    this.showd = false;
    this.isUploading1 = true;
    this.filedata = data;
    this.reqid = this.filedata.id;
    this.apisService.getApi(environment.downloadedattachment + '/' + this.reqid).subscribe((res) => {
      this.resumeRes = res;
      this.isUploading1 = false;
      this.show = false;
      this.showd = true;
      window.location.assign(this.resumeRes.downloadUrl);
    });
  }
  // Associating candidate profile to a particular open position
  submitConsultant() {
    if (this.consultantAndSubmittedForm.controls.billRateControl.value > this.maxRatevalue) {
      this.consultantAndSubmittedForm.controls.billRateControl.reset();
      this.errorMsg = true;
      setTimeout(() => {
        this.errorMsg = false;
      }, 3000);
    } else {
      this.alertError = false;
      if (this.consultantAndSubmittedForm.controls.billRateControl.invalid) {
        const controls1 = this.consultantAndSubmittedForm.controls.billRateControl;
        const controls = this.consultantAndSubmittedForm.controls.billRateControl.value;
        // tslint:disable-next-line:radix
        if (parseInt(controls) <= 0) {
          this.alertError = true;
          this.consultantAndSubmittedForm.controls.billRateControl.reset();
          setTimeout(() => {
            this.alertError = false;
          }, 3000);
        }
        else {
          controls1.markAsTouched();
        }
      } else {
        this.disable = true;
        const data = {
          accId: this.reqGridData.createdByAccId,
          reqId: this.reqGridData.reqId,
          conId: this.consultId,
          resumeId: this.resumeId,
          billRate: this.consultantAndSubmittedForm.controls.billRateControl.value,
          payRate: this.payRateValue,
          status: 'A'
        };
        this.loader = true;
        this.apisService.postApi(environment.submiteProfileToReq, data).subscribe((res: any) => {
          this.display1 = false;
          this.loader = false;
          this.availCounsultRes = res;
          this.disable = false;
          if (this.availCounsultRes.hasOwnProperty('success') === true) {
            Swal.fire({
              text: this.availCounsultRes.message,
              icon: 'success',
              confirmButtonColor: '#0D416B',
              confirmButtonText: 'Ok',
              iconColor: '#1dbb99',
            });
            this.popup = false;
            this.consultantRequirementDisplay = false;
            this.submittedPro();
            this.dt.reset();
            this.searchConsultantVend();
            this.disable = false;
          } else if (
            this.availCounsultRes.hasOwnProperty('failed') === true
          ) {
            Swal.fire('Warning!', this.availCounsultRes.failed, 'warning');
            this.disable = false;
          } else {
            Swal.fire('Error!', this.availCounsultRes.error, 'error');
            this.disable = false;
          }
        });
      }
    }
  }

  // It is used for get Security Deposit Details data in
  openPopup(data, order) {
    this.consultantAndSubmittedForm.get('securityDeposit').reset();
    const createdAccId = JSON.parse(sessionStorage.getItem('reqData')).createdByAccId;
    const userData = JSON.parse(this.apisService.decodeData()).accId;
    this.consultantAndSubmittedForm.controls.billRateControl.reset();
    this.consultantRequirementDisplay = true;
    this.consultId = data.userId;
    this.resumeId = order.id;
    this.candidateName = data.name;
    this.payRateValue = data.expMaxRate;
    this.payRateValue = data.expMaxRate;
    this.payRateValue = data.expMaxRate;
    this.apisService.getApi(environment.vendorMarkUp + createdAccId + '/' + userData).subscribe((x) => {
      this.markUpData = x.data[0].markupPercentage;
      this.consultantAndSubmittedForm.controls.billRateControl.patchValue(
        (this.payRateValue * this.markUpData) / 100 + this.payRateValue ||
        this.payrates
      );
      if (this.consultantAndSubmittedForm.controls.billRateControl.value > this.maxRatevalue) {
        this.errorMsg = true;
        setTimeout(() => {
          this.errorMsg = false;
          this.consultantAndSubmittedForm.controls.billRateControl.reset();
        }, 3000);
      }
    });
  }
  // Method to calculate bill rate
  billRate() {
    this.alertError = false;
    if (this.consultantAndSubmittedForm.controls.billRateControl.value > this.maxRatevalue) {
      this.consultantAndSubmittedForm.controls.billRateControl.reset();
      this.errorMsg = true;
      setTimeout(() => {
        this.errorMsg = false;
      }, 3000);
    } else {
      this.errorMsg = false;
    }
  }
  // Method to calculate payrate
  payrate(data) {
    this.payrates = data;
    if (this.payrates <= this.maxRatevalue) {
      this.consultantAndSubmittedForm.controls.billRateControl.patchValue(this.payrates);
      this.alertError = false;
    } else if (this.payrates >= this.maxRatevalue) {
      this.consultantAndSubmittedForm.controls.billRateControl.patchValue(this.payrates);
      this.alertError = true;
      setTimeout(() => {
        this.alertError = false;
        this.consultantAndSubmittedForm.controls.billRateControl.reset();
      }, 3000);
    }
  }
  // It is used to show currency symbol per hr based on country
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
}
