<p-card>
  <!-- Panel for Requirements form -->
  <form [formGroup]="requirementsForm">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Job Id</label>
        <input id="input" type="text" maxlength="5" pKeyFilter="int" formControlName="requirementsJobId"
          autocomplete="off" pInputText placeholder="Job Id">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Position Title</label>
        <input id="input" type="text" maxlength=30 formControlName="requirementsJobTitle" pInputText
          placeholder="Position Title" autocomplete="off">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Status</label>
        <p-dropdown [options]="statusDD" formControlName="requirementsStatus" placeholder="Select Status"
          optionLabel="label" [showClear]="true" (keyup.enter)="searchData1()">
        </p-dropdown>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Start Date </label>
        <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="requirementsStartDate" placeholder="MM-DD-YYYY"
          [showIcon]="true" [showButtonBar]="true">
        </p-calendar>
      </div>
    </div>
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">End Date </label>
        <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="requirementsEndDate" placeholder="MM-DD-YYYY"
          [showIcon]="true" [showButtonBar]="true"></p-calendar>
      </div>
    </div>
    <div class="btnsCSS1 btnalignment">
      <button pButton type="button" icon='pi pi-plus' label="Add" (click)="routeToRequirements()" class="addhBtn"></button>
      <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" class="addhBtn"></button>
      <button pButton type="submit" icon='pi pi-search' label="Search" (click)="searchData1()" class="addhBtn"></button>
    </div>
  </form>
</p-card><br>
<p-card class="pgpos">
  <div *ngIf="table">
    <p-table [columns]="cols" [autoLayout]="true" [value]="griddata" selectionMode="single" dataKey="edit"
      [paginator]="false" [rows]="15" id="excel-table" [responsive]="true" [rowsPerPageOptions]="[10,20,30]"
      sortMode="multiple">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th id="gridHeader" *ngFor="let col of columns let i=index" (click)="iconToggle[i] = !iconToggle[i]"
            [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field" [hidden]="col.sno == 6">
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr class="pointer">
          <td class="textCenter pointer" (click)="requirementRoute(data)"><span
              class="ui-column-title"></span>{{data.parentReqId}}</td>
          <td class="textCenter pointer" (click)="requirementRoute(data)"><span
              class="ui-column-title"></span>{{data.reqId}}</td>
          <td class="textCenter pointer" (click)="requirementRoute(data)"><span
              class="ui-column-title"></span>{{data.reqName}}</td>
          <td class="textCenter pointer" (click)="requirementRoute(data)">
            <span class="ui-column-title"></span>{{data.noOfPositions}}
          </td>
          <td class="textCenter pointer">
            <span class="ui-column-title pointer"></span>
            <a (click)="op.toggle($event)" (click)="reqSkills1(getSkillSetTable(data.requiredSkills))"
              *ngFor="let data1 of getSkillSetTable(data.requiredSkills); index as i;">
              <p-badge class="badgespace newsty" *ngIf='i<1' [value]="data1"></p-badge>
              <p-badge *ngIf='i===1' [value]="'+'+(data.requiredSkills.length-1)+' more'"></p-badge>
            </a>
          </td>
          <td class="pointer" [hidden]="true">
            <span class="ui-column-title pointer"></span>
            <a (click)="op.toggle($event)" (click)="reqSkills1(getSkillSetTable(data.requiredSkills))"
              *ngFor="let data1 of getSkillSetTable(data.requiredSkills); index as i;">
             <p-badge [value]="data1+','"></p-badge>
            </a>

          </td>
          <td class="textCenter" (click)="requirementRoute(data)"><span class="ui-column-title"></span>{{data.postedDate
            }}</td>
          <td><span class="ui-column-title"></span>
            <p-badge [value]="getStatusData[data.reqStatus]"
              [ngClass]="data.reqStatus ==='C'?'blue': data.reqStatus === 'R' ?'green':data.reqStatus === 'J'? 'red':data.reqStatus === 'P'?'evalution':'green'">
            </p-badge>
          </td>
          <td class="textCenter" (click)="requirementRoute(data)"><span class="ui-column-title">
            </span>{{data.submissions}}</td>
          <td class="textCenter"><span class="ui-column-title"></span><a>
              <em (click)="actionpopup.toggle($event); toggle($event, data)" class="pi pi-ellipsis-v pointer"></em></a>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr *ngIf="griddata?.length==0" class="aligncenter">
          <td colspan="8" class="mandatoryColor">
            No Records
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-grid ui-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 totalrec">
        <h3 class="norecorddata ">Total records : {{this.griddCount}}</h3>
        <p class="downpos">
          <span class="ui-column-title pointer" (click)="downloadActionPopup.toggle($event); downLoadsToggle()">
            <em class="pi pi-download downloadspac"></em>
            <strong>Download</strong></span>
        </p>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
        <p-paginator [rows]="10" [totalRecords]="griddCount" [rowsPerPageOptions]="[10,20,30]"
          (onPageChange)="paginate($event)" #p></p-paginator>
      </div>
    </div>


  </div>
  <p-card *ngIf="records">
    <div class="norecordImgsize">
      <div *ngIf="records" class="aligncenter">
        <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
        <br><em>Sorry, we are not able to find any requirements</em><br>
      </div>
    </div>
  </p-card>
  <p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="downloadOptions"></p-menu>
</p-card>
<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '25%' }">
  <label class="inputfields">Skill Details</label>
  <ng-template pTemplate>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-12 bordersty">

        <p class="skpos">

          <p-badge class="badgespace badgesp" *ngFor="let data2 of reqSkillsValue; index as i" [value]="data2"
            [ngClass]="i%2===0? 'newsty' : ''">
          </p-badge>


        </p>

      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-dialog class="dialogBox" [contentStyle]="{'overflow': 'visible'}" header="Release Requirement"
  [(visible)]="releaseDialog" [draggable]="false" [modal]="true" [resizable]="false" [style]="{width: '25%' }"
  [baseZIndex]="1">
  <form [formGroup]="releaseForm">
    <div class="p-grid ui-fluid p-justify-center">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-8 ">
        <label class="inputfields">Subcontractor Names</label><br>
        <p-dropdown [options]="vendorNames" formControlName="vendorNameControl" [filter]="true"
          [style]="{width: '225px' }" placeholder="Select Subcontractor Name" optionLabel="name" [showClear]="true">
        </p-dropdown>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-8 ">
        <p-checkbox name="checkvalue" value="Y" formControlName="checkvalue"></p-checkbox>
        <label for="binary"> Release To Public</label>
      </div>
    </div>
    <br>
    <div class="p-grid ui-fluid p-justify-center">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <div class="btnsCSS1">
          <button pButton class="releasebtn" type="button" icon='pi pi-arrow-circle-right' label="Release"
            (click)="releasedDialogBox()"></button><br>
        </div>
      </div>
    </div>
  </form>
</p-dialog>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center laoderheight ">
        <div class="laodersize">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
            class="loaderimg-height">
        </div>
      </div>
    </div>
  </div>
</ng-template>
<p-dialog class="dialogBox" [(visible)]="displayModal" [draggable]="false" [modal]="true"
  [style]="{width: '30%',height:'60%'}">
  <p-header>Requirement Activity
    <span class="Activity">Job Id : <a>{{this.histroy1}}</a></span>
  </p-header>
  <div class="Activitycolor"> Please find the below information</div><br>
  <div class="p-grid p-fluid">
    <div class="p-col-12 p-md-12 p-sm-12">
      <p-timeline [value]="histroy" align="alternate">
        <ng-template pTemplate="content" let-event>
          <p-card>
            <p class="aligncenter"> {{event.activity}}
              <br>
              <span class="Activitycolor">By {{event.activityBy}}</span>
            </p>
          </p-card>
          <br>
        </ng-template>

        <ng-template pTemplate="opposite" let-event><span class="Activitycolor">{{event.activityDate |
            date:'MM-dd-yyyy'}}</span>

        </ng-template>
      </p-timeline>
    </div>
  </div>
</p-dialog>
<p-dialog header="Rejection Reason " class="dialogBox" [(visible)]="displayreason" [style]="{width: '25vw'}"
  [draggable]="false" [modal]="true" [resizable]="true">
  <textarea autocomplete="off" pInputTextarea rows="3" cols="5" [style]="{width: '100%'}" readonly="true"
    [value]='reason'></textarea>
</p-dialog>