<nav class="navbar navbar-expand-lg navbar-dark sticky-top" style="background: white; color: #0D416B;height: 58px;">
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
        <a class="navbar-brand">
            <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Servicesbay Logo SVG.svg" style="width: 197px;
    margin-top: 12px;
    height: 43px;
    margin-left: 15px;">
        </a>
            <button class="pi pi-arrow-left pointer addhBtn" id="backToStyle" pButton type="button" style="width: auto;
            top: 14px;" label="Back
              to Login" (click)="goto()"></button>
    </div>
</nav><br>
<div class="body">
    <div style="text-align: center;font-size: medium;">
        <h4 style="font-size: 25px;cursor: pointer;">Thanks for signing up!</h4>
        <em class="pi pi-check-circle"></em>

        <P>Dear ServicesBay User,<br>Thank you for Registering to ServicesBay</P>
        <h1></h1>


        <h1></h1>
        <P>Your account <a style="cursor:pointer;">{{ name1 }}</a> is in a pending status and must now be approved. This
            should happen
            within
            the next few days, in cases of any problem contact <a style="cursor:pointer;">admin@servicesbay.com</a></P>
    </div>

</div>