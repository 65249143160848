import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-addcustomertobank',
  templateUrl: './addcustomertobank.component.html',
  styleUrls: ['./addcustomertobank.component.scss'],
  providers: [DatePipe, MessageService],
})
export class AddcustomertobankComponent implements OnInit {
  bankCustSearch: FormGroup;
  cols = [
    { field: 'name', header: 'Customer Name', width: '8%' },
    { field: 'lkCountry', header: 'Country', width: '7%' },
    { field: 'lkRegion', header: 'Region', width: '7%' },
    { field: 'lkStatus', header: 'Status', width: '7%' },
    { field: 'action', header: 'Action', width: '3%' },
  ];
  custAddForm: FormGroup;
  displayModal: boolean;
  acctDetails: any;
  bankDrp: any;
  countryDrp: { label: string; flag: string; code: string; id: string; }[];
  bankNameee: any;
  bankId: any;
  rows: any = 10;
  page: any = 1;
  totalRecords: any;
  loader: boolean;
  records: boolean;
  table: boolean;
  first: any;
  pageCount: any;
  regionOptions: any;
  gridData: any;
  custnameDrp: any;
  disable: boolean;
  statusDD: any;
  statusLabel = {};
  regionLabel = {};
  ctryLabel = {};
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private route: ActivatedRoute, private breadcrumbService: BreadcrumbService,
              private messageService: MessageService, private router: Router) {
    this.breadcrumbService.setItems([
      { label: 'Bank Accounts', routerLink: '/bank-accounts' },
      { label: 'Customers', routerLink: '/vendor-bank-customer' }
    ]);
  }
  ngOnInit(): void {
    this.bankCustSearch = this.fb.group({
      custname: [''],
      country: [''],
    });
    this.custAddForm = this.fb.group({
      banknameForm: [''],
      custNameForm: ['', Validators.required],
    });
    this.acctDetails = JSON.parse(this.apisService.decodeData());
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.bankNameee = routeParams.params.bankName;
    this.bankId = routeParams.params.id;
    this.countrydrp();
    this.gridData1();
    this.regionDropdown();
    this.getStatusData();
  }
  backNavigate() {
    this.router.navigate(['/bank-accounts']);
  }
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.gridData1();
    }
  }
  add() {
    this.displayModal = true;
    this.customerDrp();
    this.custAddForm.reset();
    this.custAddForm.controls.banknameForm.patchValue(this.bankNameee);
  }
  reset() {
    this.bankCustSearch.reset();
    this.gridData1();
  }
  countrydrp() {
    this.apisService.getApi(environment.preCountryDropdown).subscribe((res) => {
      this.countryDrp = res.data;
      this.countryDrp.forEach(element => {
        this.ctryLabel[element.code] = element.label;
      });
    });
  }
  customerDrp() {
    this.apisService.getApi(environment.customerName + this.acctDetails.accId).subscribe((res: any) => {
      this.custnameDrp = res.data;
    });
  }
  regionDropdown() {
    this.apisService.getApi(environment.lookupsData + 'ALR').subscribe(res => {
      this.regionOptions = res.data;
      this.regionOptions.forEach(element => {
        this.regionLabel[element.code] = element.label;
      });
    });
  }
  getStatusData() {
    this.apisService.getApi(environment.lookupsData + 'RLS').subscribe((res) => {
        this.statusDD = res.data;
        this.statusDD.forEach((element) => {
          this.statusLabel[element.code] = element.label;
        });
      });
  }
  gridData1() {
    const custName = this.bankCustSearch.controls.custname.value === undefined ||
      this.bankCustSearch.controls.custname.value === null || this.bankCustSearch.controls.custname.value === '' ? '' :
        this.bankCustSearch.controls.custname.value;
    // tslint:disable-next-line:max-line-length
    const coutry = this.bankCustSearch.controls.country.value === undefined || this.bankCustSearch.controls.country.value === null || this.bankCustSearch.controls.country.value === '' ? '' : this.bankCustSearch.controls.country.value.code;
    this.loader = true;
    this.apisService.getApi(environment.getBankCust + this.page + '/' + this.rows + '/' + this.bankId + '?' + 'name=' + custName + '&country=' + coutry).subscribe((res: any) => {
      this.gridData = res.data.records;
      this.totalRecords = res.data.count;
      this.loader = false;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridData1();
  }
  saveData() {
    if (this.custAddForm.invalid) {
      const controls = this.custAddForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      const bankName = this.bankId;
      // tslint:disable-next-line: max-line-length
      const custName = this.custAddForm.controls.custNameForm.value === null ? '' : this.custAddForm.controls.custNameForm.value === undefined ? '' : this.custAddForm.controls.custNameForm.value === '' ? '' : this.custAddForm.controls.custNameForm.value.id;
      const data = {
        bankId: bankName,
        accId: custName
      };
      this.loader = true;
      this.disable = true;
      this.apisService.postApi(environment.insertBankCust, data).subscribe((res: any) => {
        this.loader = false;
        if (res.hasOwnProperty('success') === true) {
          this.messageService.add({key: 'br',  severity: '', life: 3000, detail: res.message  });
          this.displayModal = false;
          this.disable = false;
          this.gridData1();
          this.custAddForm.reset();
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
          this.disable = false;
        } else {
          this.messageService.add({  key: 'br', severity: '', life: 3000, detail: res.error });
          this.disable = false;
        }
      });
    }
  }
  cancel() {
    this.displayModal = false;
    this.custAddForm.reset();
    }
}
