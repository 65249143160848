<p-toast position="bottom-right" key="br"></p-toast>
<form [formGroup]="groupform">
    <p-card class="pgpos">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Group Name</label>
                <input id="input" type="text" pInputText placeholder="Group Name" autocomplete="off"
                    formControlName="name" maxlength="30">
            </div>
        </div>
        <div>
            <div class="btnsCSS1 btnspos">
                <button *ngIf="adminrole" pButton type="button" icon='pi pi-plus' label="Add" class="addhBtn"
                    (click)="addData()"></button>
                <button pButton type="button" icon='pi pi-refresh' label="Reset" class="addhBtn"
                    (click)="reset()"></button>
                <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn"
                    (click)="searchData()"></button>
            </div>
        </div>
    </p-card>
    <br>
    <p-card class="pgpos">
        <div *ngIf="table">
            <p-table [responsive]="true" [columns]="cols" [value]="gridData" selectionMode="single" dataKey="edit"
                sortMode="multiple">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                            [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data>
                    <tr>
                        <td class="textCenter"><span class="p-column-title">
                                Name</span>{{data.name}}</td>
                        <td class="textCenter"><span class="p-column-title">
                                Membercount</span>{{data.memberCount}}</td>
                        <td class="textCenter skillDesc" pTooltip="{{data.description}}" tooltipPosition="top"><span
                                class="p-column-title">
                                Description</span>{{data.description}}</td>
                        <td><span class="ui-column-title"></span>
                            <p-badge [value]="this.statuslist[data.lkStatus]"
                                [ngClass]="data.lkStatus ==='A'?'green': 'red'">
                            </p-badge>
                        </td>
                        <td class="textCenter"><span class="p-column-title">
                                Action</span>
                            <a class="pi pi-ellipsis-v pointer"
                                (click)="downloadActionPopup.toggle($event); toggle($event,data)"></a>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="p-grid ui-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                    <h3 class="norecorddata">Total records : {{this.totalrecords}}</h3>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-10 ">
                    <p-paginator [rows]="10" [totalRecords]="totalrecords" [rowsPerPageOptions]="[10,20,30]"
                        (onPageChange)="paginate($event)"></p-paginator>
                </div>
            </div>
        </div>
        <p-card *ngIf="records">
            <div class="norecordImgsize">
                <div *ngIf="records" class="aligncenter">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png"
                        class="norecordimg">
                    <br><em>Sorry, we are not able to find any groups</em><br>
                </div>
            </div>
        </p-card>
    </p-card>
</form>
<div *ngIf="!loading; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="items"></p-menu>
<p-dialog class="dialogBox" header="Add Group" [(visible)]="display" [modal]="true" [baseZIndex]="1" [draggable]="false"
    [style]="{width: '20%'}" [resizable]="false">
    <form [formGroup]='addgroupform'>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12  p-lg-12">
                <label class="inputfields">Group Name<span class="mandatoryColor">*</span></label>
                <input autocomplete="off" id="input" type="text" size="30" pInputText formControlName="addgroupname"
                  maxlength="26"  placeholder="Name">
                <span class="validationtest"
                    *ngIf="this.addgroupform.controls.addgroupname.invalid && this.addgroupform.controls.addgroupname.touched && this.addgroupform.controls.addgroupname.errors.required">Please
                    enter group name</span>
                    <span class="validationtest"
                    *ngIf=" this.addgroupform.get('addgroupname').hasError('maxlength')"> Please enter
                    25 alphabets only </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12  p-lg-12">
                <label class="inputfields">Description</label>
                <textarea autocomplete="off" [rows]="3" [cols]="30" placeholder="Description" pInputTextarea
                    formControlName="description" maxlength="101"></textarea>
                    <span class="validationtest"
                    *ngIf=" this.addgroupform.get('description').hasError('maxlength')"> Please enter
                    100 alphabets only </span>
            </div>
        </div>
        <div class="btnsCSS1">
            <div class="btnsCSS1">
                <button pButton type="button" icon='pi pi-save' class="addhBtn" label="Save" [disabled]="disable"
                    (click)="addgroup()"></button>
            </div>
        </div>
    </form>
</p-dialog>
<p-dialog class="dialogBox" header="Edit Group" [(visible)]="display1" [modal]="true" [baseZIndex]="1"
    [draggable]="false" [style]="{width: '20%'}" [resizable]="false">
    <form [formGroup]='editgroupform'>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12  p-lg-12">
                <label class="inputfields">Group Name<span class="mandatoryColor">*</span></label>
                <input autocomplete="off" id="input" type="text" size="30" pInputText formControlName="editgroupname"
                  maxlength="26"  placeholder="Name">
                <span class="validationtest"
                    *ngIf="this.editgroupform.controls.editgroupname.invalid && this.editgroupform.controls.editgroupname.touched && this.editgroupform.controls.editgroupname.errors.required">Please
                    enter group name</span>
                    <span class="validationtest"
                    *ngIf=" this.editgroupform.get('editgroupname').hasError('maxlength')"> Please enter
                    25 alphabets only </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Status</label>
                <p-dropdown [options]="status" placeholder="Select Status" [showClear]="true" optionLabel="name" formControlName="Status">
                </p-dropdown>
                <span class="validationtest"
                    *ngIf="this.editgroupform.controls.Status.invalid && this.editgroupform.controls.Status.touched">Please
                    select status</span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12  p-lg-12">
                <label class="inputfields">Description</label>
                <textarea autocomplete="off" [rows]="3" [cols]="30" placeholder="Description" pInputTextarea
                    formControlName="edescription" maxlength="101"></textarea>
                    <span class="validationtest"
                    *ngIf=" this.editgroupform.get('edescription').hasError('maxlength')"> Please enter
                    100 alphabets only </span>
            </div>
        </div>
        <div class="btnsCSS1">
            <div class="btnsCSS1">
                <button pButton type="button" icon='pi pi-save' class="addhBtn" label="Update" [disabled]="disable"
                    (click)="updategroup()"></button>
            </div>
        </div>
    </form>
</p-dialog>