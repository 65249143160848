<form [formGroup]="vendorLocationForm">
  <p-card>
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Name</label>
        <input id="input" type="text" autocomplete="off" pInputText placeholder="Name" (keyup.enter)="Searchlocation()"
          formControlName="Name" maxlength="30">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Status</label>
        <p-dropdown [options]="status2" placeholder="Select Status" optionLabel="label" formControlName="Status"
          (keyup.enter)="Searchlocation()" [showClear]="true"></p-dropdown>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Country</label>
        <p-dropdown [options]="country" placeholder="Select Country" optionLabel="label" formControlName="Country"
          (onChange)="getStateByUrl($event.value.code)" (keyup.enter)="Searchlocation()" [showClear]="true">
        </p-dropdown>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">State</label>
        <p-dropdown [options]="stateData" placeholder="Select State" optionLabel="label"
          (keyup.enter)="Searchlocation()" formControlName="State" [showClear]="true"></p-dropdown>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">City</label>
        <input id="input" type="text" autocomplete="off" pInputText placeholder="City" (keyup.enter)="Searchlocation()"
          formControlName="City" maxlength="20">
      </div>
    </div>

    <div class="btnsCSS1 btnalign">
      <!-- <button pButton type="button" [icon]="showUploadView ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        label="Import" (click)="toggleUploadView()" class="addhBtn"></button> -->
      <p-splitButton label="Import" [model]="items1" class="addhBtn"></p-splitButton>
      <button pButton type="button" icon='pi pi-plus' label="Add" (click)="addlocation()" class="addhBtn"></button>
      <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="resetData()" class="addhBtn"></button>
      <button pButton type="button" icon='pi pi-search' label="Search" (click)="Searchlocation()"
        class="addhBtn"></button>
    </div>
    <div *ngIf="isUploading" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
      <p-progressBar mode="indeterminate"></p-progressBar>
    </div>
    <div *ngIf="showUploadView" class="dropzone p-col-12 p-md-12 p-sm-12 p-lg-3">
      <em class="pi pi-upload uploadIcon Iconsize" aria-hidden="true"></em>
      <input accept=".xlsm,.xlsx,application/msexcel" type="file" class="fileInput" #fileDropRef id="fileDropRef"
        multiple (change)="onUpload($event.target.files)" style="cursor: pointer;" />
      <h3>Drag and drop file here</h3>
      <h3 class="importstylin">or</h3>
      <label id="fileUpload" for="fileDropRef">Browse for files</label>
    </div>
  </p-card>
  <br>
  <p-card class="pgpos">
    <div *ngIf="table">
      <p-table [columns]="cols" [value]="gridTableData" selectionMode="single" dataKey="edit" [responsive]="true"
        sortMode="multiple">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th id="gridHeader" *ngFor="let col of columns let i=index" (click)="iconToggle[i] = !iconToggle[i]"
              [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
              {{col.header}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
          <tr>
            <td class="textCenter skill-desc" tooltipPosition="top" pTooltip="{{data.name}}"><span
                class="p-column-title">Location Name</span>
              {{data.name}}
            </td>
            <td class="textCenter skill-desc" tooltipPosition="top" pTooltip="{{data.address1}}"><span
                class="p-column-title">adress</span>{{data.address1}}</td>
            <td class="textCenter skill-desc" tooltipPosition="top" pTooltip="{{data.city}}"><span
                class="p-column-title">City</span>{{data.city}}</td>
            <td class="textCenter"><span class="p-column-title">State</span>{{data.stateName}}</td>
            <td class="textCenter"><span class="p-column-title">Country</span>{{contryTable[data.country]}}</td>
            <td>
              <p-badge [value]="statuTable[data.status]"
                [ngClass]="data.status==='A'?'green': data.status === 'I'?'red': 'blue'">
              </p-badge>
            </td>

            <td class="textCenter"><span class="p-column-title">Action</span><a>
                <em (click)="downloadActionPopup.toggle($event); toggle($event,data, rowData)"
                  class="pi pi-ellipsis-v pointer"></em>
              </a> </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr *ngIf="gridTableData?.length==0" class="aligncenter">
            <td colspan="6" class="mandatoryColor">
              No Records
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
          <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
          <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
            (onPageChange)="paginate($event)"></p-paginator>
        </div>
      </div>
    </div>
    <p-card *ngIf="records">
      <div class="norecordImgsize">
        <div *ngIf="records" class="aligncenter">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
          <br><em>Sorry, we are not able to find any locations</em><br>
        </div>
      </div>
    </p-card>

  </p-card>
</form>
<p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="items"></p-menu>
<!-- It is used for add location pop up -->
<p-dialog [contentStyle]="{'overflow': 'visible'}" class="dialogBox" header="Add Location" [(visible)]="displayModal"
  [modal]="true" [style]="{width: '30%'}" [baseZIndex]="1" [draggable]="false" [resizable]="false">
  <form [formGroup]="vendorLocationPopUpForm">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <label class="inputfields">Location Name<span class="mandatoryColor">*</span></label>
        <input id="input" type="text" (blur)="locatioVerification()" autocomplete="off" pInputText placeholder="Name"
          formControlName="NameControl" maxlength="21">
        <div>
          <span *ngIf="locationDatadisplay" class="mandatoryColor">Location name exist</span>
          <span class="validationtest"
            *ngIf="this.vendorLocationPopUpForm.get('NameControl').touched && this.vendorLocationPopUpForm.get('NameControl').invalid && this.vendorLocationPopUpForm.get('NameControl').errors.required">
            Please enter location name</span>
          <span class="validationtest"
            *ngIf="this.vendorLocationPopUpForm.controls.NameControl.invalid && this.vendorLocationPopUpForm.controls.NameControl.errors?.pattern">Please
            enter
            alphabets only</span>
          <span class="validationtest" *ngIf="this.vendorLocationPopUpForm.get('NameControl').hasError('maxlength')">
            Please enter 20 alphabets only
          </span>
        </div>
      </div>

      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <label class="inputfields">Address Line 1<span class="mandatoryColor">*</span></label>
        <input id="input" type="text" autocomplete="off" pInputText placeholder="Address Line 1"
          formControlName="addressControl" maxlength="51">
        <div>
          <span class="validationtest"
            *ngIf="this.vendorLocationPopUpForm.get('addressControl').touched && this.vendorLocationPopUpForm.get('addressControl').invalid && this.vendorLocationPopUpForm.get('addressControl').errors.required">
            Please enter address line 1</span>
          <span class="validationtest"
            *ngIf="this.vendorLocationPopUpForm.controls.addressControl.invalid && this.vendorLocationPopUpForm.controls.addressControl.errors?.pattern">Please
            enter
            alphabets only</span>
          <span class="validationtest" *ngIf="this.vendorLocationPopUpForm.get('addressControl').hasError('maxlength')">
            Please enter 50 alphabets only
          </span>
        </div>
      </div>

      <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
        <label class="inputfields">Address Line 2</label>
        <input id="input" type="text" autocomplete="off" pInputText placeholder="Address Line 2"
          formControlName="addressFormControl" maxlength="51">
        <span class="validationtest"
          *ngIf="this.vendorLocationPopUpForm.get('addressFormControl').hasError('maxlength')">
          Please enter 50 alphabets only
        </span>
      </div>


      <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
        <label class="inputfields" id='csslabel'>Mailbox</label>
        <input type="text" autocomplete="off" maxlength="11" pInputText placeholder="Mailbox"
          formControlName="mailbox1">
        <span class="validationtest" *ngIf="this.vendorLocationPopUpForm.get('mailbox1').hasError('maxlength')">
          Please enter 10 alphabets only
        </span>
      </div>

      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
        <label class="inputfields">Country<span class="mandatoryColor">*</span></label>
        <p-dropdown [options]="country" placeholder="Select Country" optionLabel="label" formControlName="Country"
          (onChange)="getStateByUrl($event.value.code)" [showClear]="true">
        </p-dropdown>
        <div>
          <span class="validationtest"
            *ngIf="this.vendorLocationPopUpForm.get('Country').touched && this.vendorLocationPopUpForm.get('Country').invalid && this.vendorLocationPopUpForm.get('Country').errors.required">
            Please select country</span>
        </div>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
        <label class="inputfields">State<span class="mandatoryColor">*</span></label>
        <p-dropdown [options]="stateData" placeholder="Select State" optionLabel="label" formControlName="State"
          [showClear]="true"></p-dropdown>
        <div>
          <span class="validationtest"
            *ngIf="this.vendorLocationPopUpForm.get('State').touched && this.vendorLocationPopUpForm.get('State').invalid && this.vendorLocationPopUpForm.get('State').errors.required">
            Please select state</span>
        </div>
      </div>

      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
        <label class="inputfields">City<span class="mandatoryColor">*</span></label>
        <input autocomplete="off" id="input" type="text" pInputText placeholder="City" formControlName="cityForm"
          maxlength="21">
        <div>
          <span class="validationtest"
            *ngIf="this.vendorLocationPopUpForm.get('cityForm').touched && this.vendorLocationPopUpForm.get('cityForm').invalid && this.vendorLocationPopUpForm.get('cityForm').errors.required">
            Please enter city
          </span>
          <span class="validationtest"
            *ngIf="this.vendorLocationPopUpForm.controls.cityForm.invalid && this.vendorLocationPopUpForm.controls.cityForm.errors?.pattern">Please
            enter
            valid city</span>
          <span class="validationtest" *ngIf="this.vendorLocationPopUpForm.get('cityForm').hasError('maxlength')">
            Please enter 20 alphabets only
          </span>
        </div>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
        <label class="inputfields">Zip</label>
        <input autocomplete="off" id="input" type="text" pInputText pKeyFilter="num" maxlength="11" pInputText
          placeholder="Zip" formControlName="zipForm">
        <span class="validationtest" *ngIf="this.vendorLocationPopUpForm.get('zipForm').hasError('maxlength')">
          Please enter 10 alphabets only
        </span>

        <span class="validationtest"
          *ngIf="this.vendorLocationPopUpForm.get('zipForm').invalid && this.vendorLocationPopUpForm.get('zipForm').errors.pattern">
          Please enter numbers only
        </span>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
        <label class="inputfields">Phone</label>
        <div class="p-col-12 p-md-12 p-sm-12 ph-no">
          <div class="p-inputgroup" class="p-col-4 p-md-4 phone">
            <span class="p-inputgroup-addon addon">
              <p-dropdown [options]="countrycodedropdown" class="phonecodestyle newcode"
                filterPlaceholder='Search Results' optionLabel="label" formControlName="phoneDrp">
                <ng-template let-items pTemplate="selectedItem">
                  <div class="flex-row">
                    <img class="lang-change-img" src={{items.flag}} alt="" />
                    <div class="lang-change-option-text selected-lang center">{{items.code}}
                    </div>
                  </div>
                </ng-template>
                <ng-template let-country pTemplate="item">
                  <div class="flex-row" id="dropdown1">
                    <img class="lang-change-img" src={{country.flag}} alt="" />
                    <div class="lang-change-option-text center">{{country.label}}
                      {{country.code}}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </span>
          </div>
          <div class="p-col-8 p-md-8 ccode">
            <span class="p-input-icon-right"><em *ngIf="phSpinnerHide" class="pi pi-spin pi-spinner spinnerstyles"></em>
              <em *ngIf="phCheckHide" class="pi pi-check-circle tickmarkstyle"></em>
              <p-inputMask autocomplete="off" (onBlur)="getPhon()" mask="(999) 999-9999" formControlName="Phone"
                placeholder="Phone">
              </p-inputMask>
            </span>
            <p class="mandatoryColor">{{this.mobinummsg}}</p>
            <span *ngIf="displayNumber" class="mandatoryColor">Phone number already exist</span>
          </div>
        </div>
      </div>
    </div>

    <br>
    <div class="btnsCSS1 buttonalign">
      <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="vendorLocReset()"
        class="addhBtn"></button>
      <button pButton type="button" icon='pi pi-save' label="Save" [disabled]="hide" (click)="vendorLocSave()"
        class="addhBtn"></button>
    </div><br><br>
  </form>
</p-dialog>
<!-- It is used for edit location pop up -->
<p-dialog class="dialogBox" [contentStyle]="{'overflow': 'visible'}" header="Edit Location" [(visible)]="editlocation"
  [style]="{width: '30%'}" [baseZIndex]="1" [modal]="true" [draggable]="false" [resizable]="false">
  <form [formGroup]="vendorEditLocationPopUpForm">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
        <label class="inputfields">Location Name<span class="mandatoryColor">*</span></label>
        <input id="input" autocomplete="off" type="text" pInputText placeholder="Name" formControlName="NameControl"
          maxlength="21">
        <span class="validationtest"
          *ngIf="vendorEditLocationPopUpForm.get('NameControl').touched && vendorEditLocationPopUpForm.get('NameControl').invalid && this.vendorEditLocationPopUpForm.get('NameControl').errors.required">
          Please enter name
        </span>
        <span class="validationtest"
          *ngIf="this.vendorEditLocationPopUpForm.controls.NameControl.invalid && this.vendorEditLocationPopUpForm.controls.NameControl.errors?.pattern">Please
          enter
          alphabets only</span>
        <span class="validationtest" *ngIf="this.vendorEditLocationPopUpForm.get('NameControl').hasError('maxlength')">
          Please enter 20 alphabets only
        </span>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
        <label class="inputfields">Status</label>
        <p-dropdown [options]="status1" placeholder="Select Status" optionLabel="label" formControlName="StatusForm"
          [showClear]="true"></p-dropdown>
      </div>

      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <label class="inputfields">Address Line 1</label>
        <input id="input" autocomplete="off" type="text" pInputText placeholder="Address Line 1"
          formControlName="addressControl" maxlength="51">
        <span class="validationtest"
          *ngIf="this.vendorEditLocationPopUpForm.get('addressControl').hasError('maxlength')">
          Please enter 50 alphabets only
        </span>
      </div>

      <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
        <label class="inputfields">Address Line 2</label>
        <input id="input" type="text" autocomplete="off" pInputText placeholder="Address Line 2"
          formControlName="addressFormControl" maxlength="51">
        <span class="validationtest"
          *ngIf="this.vendorEditLocationPopUpForm.get('addressFormControl').hasError('maxlength')">
          Please enter 50 alphabets only
        </span>
      </div>

      <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
        <label class="inputfields" id='csslabel'>Mailbox</label>
        <input type="text" autocomplete="off" maxlength="11" pInputText placeholder="Mailbox"
          formControlName="mailbox2">
        <span class="validationtest" *ngIf="this.vendorEditLocationPopUpForm.get('mailbox2').hasError('maxlength')">
          Please enter 10 alphabets only
        </span>
      </div>

      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
        <label class="inputfields">Country<span class="mandatoryColor">*</span></label>
        <p-dropdown [options]="country" placeholder="Select Country" optionLabel="label" formControlName="Country"
          (onChange)="getStateByUrl($event.value.code)" [showClear]="true">
        </p-dropdown>
        <span class="validationtest"
          *ngIf="vendorEditLocationPopUpForm.get('Country').touched && vendorEditLocationPopUpForm.get('Country').invalid && this.vendorEditLocationPopUpForm.get('Country').errors.required">
          Please select country
        </span>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
        <label class="inputfields">State<span class="mandatoryColor">*</span></label>
        <p-dropdown [options]="stateData" placeholder="Select State" optionLabel="label" formControlName="State"
          [showClear]="true"></p-dropdown>
        <span class="validationtest"
          *ngIf="vendorEditLocationPopUpForm.get('State').touched && vendorEditLocationPopUpForm.get('State').invalid && this.vendorEditLocationPopUpForm.get('State').errors.required">
          Please select state
        </span>
      </div>

      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
        <label class="inputfields">City<span class="mandatoryColor">*</span></label>
        <input id="input" type="text" autocomplete="off" pInputText placeholder="City" formControlName="cityForm"
          maxlength="21">
        <span class="validationtest"
          *ngIf="vendorEditLocationPopUpForm.get('cityForm').touched && vendorEditLocationPopUpForm.get('cityForm').invalid && this.vendorEditLocationPopUpForm.get('cityForm').errors.required">
          Please select city
        </span>
        <span class="validationtest" *ngIf="this.vendorEditLocationPopUpForm.get('cityForm').hasError('maxlength')">
          Please enter 20 alphabets only
        </span>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
        <label class="inputfields">Zip</label>
        <input id="input" type="text" autocomplete="off" pInputText pKeyFilter="num" maxlength="11" pInputText
          placeholder="Zip" formControlName="zipForm">
        <span class="validationtest" *ngIf="this.vendorEditLocationPopUpForm.get('zipForm').hasError('maxlength')">
          Please enter 10 alphabets only
        </span>
        <span class="validationtest"
          *ngIf="this.vendorEditLocationPopUpForm.get('zipForm').invalid && this.vendorEditLocationPopUpForm.get('zipForm').errors.pattern">
          Please enter numbers only
        </span>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
        <label class="inputfields">Phone</label>
        <div class="p-col-12 p-md-12 p-sm-12 ph-no">
          <div class="p-inputgroup" class="p-col-4 p-md-4 phone">
            <span class="p-inputgroup-addon addon">
              <p-dropdown [options]="countrycodedropdown" class="phonecodestyle newcode"
                filterPlaceholder='Search Results' optionLabel="label" formControlName="editPhoneDrp">
                <ng-template let-items pTemplate="selectedItem">
                  <div class="flex-row">
                    <img class="lang-change-img" src={{items.flag}} alt="" />
                    <div class="lang-change-option-text selected-lang center">{{items.code}}
                    </div>
                  </div>
                </ng-template>
                <ng-template let-country pTemplate="item">
                  <div class="flex-row" id="dropdown1">
                    <img class="lang-change-img" src={{country.flag}} alt="" />
                    <div class="lang-change-option-text center">{{country.label}}
                      {{country.code}}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </span>
          </div>
          <div class="p-col-8 p-md-8 ccode">
            <span class="p-input-icon-right"><em *ngIf="editphSpinnerHide"
                class="pi pi-spin pi-spinner spinnerstyles"></em>
              <em *ngIf="editphCheckHide" class="pi pi-check-circle tickmarkstyle"></em>
              <p-inputMask autocomplete="off" (onBlur)="geteditPhon()" mask="(999) 999-9999" formControlName="Phone"
                placeholder="Phone">
              </p-inputMask>
            </span>
            <p class="mandatoryColor">{{this.mobinummsg1}}</p>
            <span *ngIf="displayNumber" class="mandatoryColor">Phone number already exist</span>
          </div>
        </div>
      </div>
    </div>
  </form>
  <br>
  <div class="btnsCSS1 buttonalign">
    <button pButton [disabled]="hide" type="button" icon='pi pi-save' label="Update" class="addhBtn"
      (click)="vendorLocUpdate()"></button>
  </div><br>
</p-dialog>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center laoderheight">
        <div class="laodersize">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
            class="loaderimg-height">
        </div>
      </div>
    </div>
  </div>
</ng-template>
<p-toast position="bottom-right" key="br"></p-toast>