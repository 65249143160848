import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
  providers: [DatePipe, MessageService],
})
export class PreferencesComponent implements OnInit {
  userPrefernces: FormGroup;
  locationvalue: any = [];
  currencyValue: any = [];
  prefUsStateData: any = [];
  prefFilterUsData: any = [];
  prefUsStateId: any = [];
  prefNonUsStateData: any = [];
  prefNonUsStateId: any = [];
  prefFilterData: any = [];
  prefFilterDataId: any = [];
  statelist: any = [];
  preferenceValues = [
    { name: 'Currency', code: 'currency' },
    { name: 'Locations', code: 'location' },
    { name: 'Notifications', code: 'notification' },
  ];
  eventvalue: any;
  notification: boolean;
  currency: boolean;
  preCurrency: any = [];
  preCountry: any = [];
  prestate: any = [];
  preNotifications: any = [];
  locations: boolean;
  id: any;
  button: boolean;
  stateValue: any = [];
  data = {};
  item = {};
  statuTable: any = [];
  coutryvalue = {};
  statesdata = {};
  loader: boolean;
  actId: any;
  preferencedata: any = [];
  preferencevalue: any = [];
  preferences: any;
  notificationvalue: any;
  statevalue: any = [];
  countryValue: any;
  statevalues: any;
  states: any;
  notification1 = {};
  constructor(private fb: FormBuilder, private apisService: ApisService, private breadcrumbService: BreadcrumbService ) {
    this.breadcrumbService.setItems([
      { label: 'Preferences', routerLink: '/employeepreference' },
    ]);
  }
  ngOnInit() {
    this.actId = JSON.parse(this.apisService.decodeData());
    this.userPrefernces = this.fb.group({
      preference: [''],
      precurrency: [''],
      precountry: [''],
      prestate: [''],
      prenotifications: [''],
      preCity: ['']
    });
    this.id = JSON.parse(sessionStorage.getItem('loginData')).userId;
    this.preCurrencyDD();
    this.preNotificationsDD();
    this.preCountryDD();
    this.getPreference();
  }
  preCurrencyDD() {
    // this.loader = true;
    this.apisService.getApi(environment.preCurrencyDropDown).subscribe((res: any) => {
      this.preCurrency = res.data;
      // this.loader = false;
      this.preCurrency.forEach(element => {
        this.item[element.code] = element.label;
      });
    });
  }
  preNotificationsDD() {
    // this.loader = true;
    this.apisService.getApi(environment.preNotfDropDown).subscribe((res: any) => {
      this.preNotifications = res.data;
      // this.loader = false;
      this.preNotifications.forEach(element => {
        this.notification1[element.code] = element.label;
      });
    });
  }
  preCountryDD() {
    this.loader = true;
    this.apisService.getApi(environment.preCountryDropdown).subscribe((res: any) => {
      this.preCountry = res.data;
      this.loader = false;
      this.preCountry.forEach(element => {
        this.coutryvalue[element.code] = element.label;
      });
    });
  }
  getStateByUrl(country) {
    if (country === null || '' || undefined){}
  else{
    // this.loader = true;
    this.apisService.getApi(environment.states + country).subscribe((res) => {
      this.prestate = res.data;
      // this.loader = false
      this.statevalue = this.locationvalue[0].state.split(/(?:,| )+/);
      this.statuTable = [];
      this.prestate.forEach((element) => {
        this.statevalue.forEach((element1, index) => {
          if (element.code === this.statevalue[index]) {
            this.statuTable.push(' ' + element.label);
          }
        });
      });
    });
  }
  }
  getPreference() {
    
    this.apisService.getApi(environment.getPreferences + this.actId.userId).subscribe((res: any) => {
      this.preferencedata = res.data;
      this.preferencevalue = [];
      this.preferencedata.forEach(element => {
        if (element.key === 'location') {
          this.locationvalue.push({ country: element.country, state: element.state });
        }
        if (element.key !== 'location') {
          this.preferencevalue.push({ key: element.key, value: element.value });
        }
      });
      this.preferencevalue.forEach(element => {
        this.currencyValue[element.key] = [element.value];
      });
      this.preferences = this.currencyValue.currency[0];
      this.notificationvalue = this.currencyValue.notification[0];
      this.statevalue = this.locationvalue[0].state;
      this.countryValue = this.locationvalue[0].country;
      this.getStateByUrl(this.countryValue);
    });
  }
}
