import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-addvendorrequirement',
  templateUrl: './addvendorrequirement.component.html',
  styleUrls: ['./addvendorrequirement.component.scss'],
  providers: [MessageService, DatePipe],
})
export class AddvendorrequirementComponent implements OnInit {
  loader: boolean;
  iconToggle = [];
  updateactioncols = [
    { field: 'name', header: 'Title', width: '8%' },
    { field: 'title', header: 'Name', width: '8%' },
    { field: '', header: 'Download', width: '8%' },
    { field: '', header: '', width: '8%' },
  ];
  // It is used for the  displaying the table header for vendor tab
  cols = [
    { field: 'account_name', header: 'Vendor', width: '8%' },
    { field: 'vendor_type', header: 'Vendor Tier', width: '6%' },
    { field: 'noOfSubmissions', header: 'No.Of.Submissions', width: '7%' },
    { field: 'averageRate', header: 'Avg.Rate', width: '5%' },
    { field: 'req_access_time', header: 'Access Time', width: '7%' },
    { field: 'status', header: 'Status', width: '5%' },
    { field: 'RatingAndReviews', header: 'Rating and Reviews', width: '8%' },
  ];
  items = [
    { label: 'Remove', icon: 'pi pi-fw pi-trash', command: (event, i) => { this.deleteRow(); } },
    { label: 'Reject', icon: 'pi pi-fw pi-times-circle' },
    { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users' },
    { label: 'Update Profile', icon: 'pi pi-fw pi-refresh', command: (event, i) => { this.updateAction(); } },
    { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
    { label: 'Show Rating', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
    { label: 'Show Details of Candidate', icon: 'pi pi-fw pi-user', command: () => { this.showdetails(); } },
  ];
  // It is used for the displaying the vendor Status dropdown values
  vendorStatus = [
    { code: 'Active', name: 'Active' },
    { code: 'Inactive', name: 'Inactive' },
  ];
  // It is used for the  displaying the table header for all roles except csr role in submitted profile tab
  cols11 = [
    { field: 'name', header: 'Candidate Name', width: '20%' },
    { field: 'postedDate', header: 'Submitted Date', width: '15%' },
    { field: 'consultant_skills', header: 'Skill Set', width: '20%' },
    { field: 'experience', header: 'Experience', width: '14%' },
    { field: 'csr_status', header: 'Status', width: '12%' },
    { field: 'rate_salary', header: 'Rate $', width: '8%' },
    { field: '', header: 'Action', width: '5%' },
  ];
  // It is used for the displaying the Type dropdown values
  Type = [
    { name: 'Contract', code: 'CO' },
    { name: 'Contract to hire', code: 'C2C' },
    { name: 'Full time', code: 'PE' },
  ];
  // It is used for the displaying the work location dropdown values
  workLocation = [
    { name: 'Onsite', code: 'ON' },
    { name: 'Offsite', code: 'OF' },
    { name: 'Offshore', code: 'OS' },
  ];
  // It is used for the displaying the Status dropdown values
  statusValue = [
    { code: '-1', name: 'All' },
    { code: 'Active', name: 'Active' },
    { code: 'Inactive', name: 'Inactive' },
  ];
  first: any;
  rows: any = 10;
  page: any = 1;
  pageCount: any;
  editRequirementsForm: FormGroup;
  vendorForm: FormGroup;
  vendorAddForm: FormGroup;
  submittedProfileForm: FormGroup;
  status: any;
  getexperienceOptions: any = [];
  PreferredSkills: any = [];
  requestData: any;
  filteredData: any;
  preFilterId: [];
  filterIdString: any;
  reqId: any;
  Data: any;
  vendorGrid: any;
  display: boolean;
  csrStatus: any;
  minDate: Date;
  minMaxRate: any = [];
  reqCategoryOptions: any;
  vendorEditForm: FormGroup;
  validStatus = false;
  headerName: string;
  submited: string;
  roleId: any;
  tabchangeVar: boolean;
  name1: any;
  updt: boolean;
  primaryRole: any;
  totalRecords: any;
  hireTypeData: any = [];
  billingData: any;
  durataion = [
    { lable: 'Operations', value: 1 },
    { lable: 'Sales', value: 2 },
    { lable: 'Recruitment', value: 3 },
    { lable: 'Marketing', value: 4 },
    { lable: 'Executive', value: 5 },
  ];
  locationList: any = [];
  acctdetails: any;
  departmentList: any = [];
  acctDetails: any;
  enddata: boolean;
  positionData: any = [];
  MinRateIds: any;
  jobTitleId: any;
  updBtn: boolean;
  titel: any;
  reqData: any;
  reqName: any;
  jobCat: any;
  maxrate: any;
  submittedProfilesGridData: any;
  subProRecords: any;
  table = true;
  records: boolean;
  specializationSkillsData: any;
  array: any;
  consStatusData: any;
  resumeRes: any;
  popup: boolean;
  filedata: any;
  userId: string;
  idToDelete: any;
  formRoute: any;
  rowData: any;
  skillset: any = [];
  profileID: any;
  updateactionBtn: boolean;
  rowdataid: any;
  updateactionGrid: any = [];
  updateaction: boolean;
  userid: any;
  resumID: any;
  AddRequirementsEndDate: any;
  maxRateId: any;
  skillSet: any;
  AddRequirementsFromDate: any;
  addRequirementData: any;
  jobid: any;
  requirementSkillSet: any = [];
  requirementPreferredSkillSet: any = [];
  accountId: any;
  currencysymbl: any;
  currencycode: any[];
  preferencList: any = [];
  currencyValue: any;
  disbalebtn: boolean;
  usrroleid: any;
  otherskills = false;
  patchlocationString: string;
  editLocationdata: any;
  otherskilldata: boolean;
  // tslint:disable-next-line:max-line-length
  constructor(private apisService: ApisService, private fb: FormBuilder, private datepipe: DatePipe, private routeParam: ActivatedRoute, private messageService: MessageService, private router: Router, private route: ActivatedRoute, private breadcrumbService: BreadcrumbService) {
    if (this.roleId === 1) {
      this.cols11 = [
        { field: 'created_by_org_id', header: 'Vendor', width: '8%' },
        { field: 'name', header: 'Candidate Name', width: '8%' },
        { field: 'postedDate', header: 'Submitted Date', width: '7%' },
        { field: 'consultant_skills', header: 'Skill Set', width: '5%' },
        { field: 'experience', header: 'Experience', width: '5%' },
        { field: 'csr_status', header: 'Status', width: '5%' },
        { field: 'rate_salary', header: 'Rate', width: '5%' },
        { field: 'download', header: 'Resume', width: '5%' },
        { field: 'reviews', header: 'Reviews', width: '5%' },
      ];
    }
    // tslint:disable-next-line:no-unused-expression
  }
  ngOnInit() {
    this.acctDetails = JSON.parse(this.apisService.decodeData());
    this.preferencList = this.acctDetails.preferencesList;
    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.name1 = routeParams.params.name;
    this.reqId = routeParams.params.reqId;
    this.titel = routeParams.params.title;
    this.formRoute = routeParams.params.from;
    this.headerName = this.formRoute === 'open' ? 'Open Position' : 'Requirement';
    const nameLabel = this.formRoute === 'open' ? 'Add Vendor Requirement' : 'Requirements';
    const routeUrl = this.formRoute === 'open' ? '/open-positions' : '/requirements';
    this.breadcrumbService.setItems([
      { label: this.headerName, routerLink: [routeUrl] },
      { label: `${nameLabel}`, routerLink: '/requirements-edit' },
    ]);
    this.editRequirementsForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      requirementTitle: [''],
      requirementType: ['', Validators.required],
      requirementStartDate: ['', Validators.required],
      requirementEndDate: ['', Validators.required],
      requirementNoofPositions: ['', Validators.required],
      requirementReqExp: ['', Validators.required],
      requirementWorkCenterLocation: ['', Validators.required],
      requirementMinRate: [''],
      requirementMaxRate: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      requirementDescription: ['', Validators.required],
      requirementResponsibilities: ['', Validators.required],
      requirementQualification: ['', Validators.required],
      requirementSkillSet: ['', Validators.required],
      requirementPreferredSkillSet: [''],
      requirementComments: ['', Validators.maxLength(500)],
      positionCategory: [''],
      billingtype: ['', Validators.required],
      hrsperWeek: [''],
      duration: [''],
      department: [''],
      approval: [''],
      otherskills: ['', Validators.maxLength(300)]
    });
    if (this.primaryRole === 3 || this.primaryRole === 1) {
      this.updt = true;
    } else {
      this.updt = false;
    }
    this.vendorForm = this.fb.group({
      vendorTire: [''],
      vendorStatus: [''],
    });
    this.vendorAddForm = this.fb.group({
      vendorAddTier: ['', Validators.required],
      vendorAddaccessTime: ['', Validators.required],
      vendorAddNames: ['', Validators.required],
    });
    this.submittedProfileForm = this.fb.group({
      vendorName: ['', Validators.pattern('^[a-zA-Z]*$')],
      firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z]*$')]],
      lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z]*$')]],
      ssnNumber: [''],
      skillSet: [''],
      experience: [''],
      emailId: ['', [Validators.required, Validators.pattern('^[a-zA-Z]*$')]],
      phone: [''],
      csrStatus: [''],
      skillDetails: [''],
      comments1: ['', Validators.required],
    });
    this.vendorEditForm = this.fb.group({
      typeOfTier: [''],
      status: [''],
    });
    this.getdSkillset();
    this.getexperience();
    this.reqCategoryDropdown();
    this.getBillingDropdown();
    this.getHireTypeData();
    this.getDepartments();
    this.getWorkLocation();
    this.reqSkillSet();
    this.getConStatus();
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
      this.roleId = this.acctDetails.roles[0].id;
    } else {
      this.roleId = this.usrroleid;
    }
    if (this.acctDetails.accType === 'C') {
      this.updBtn = true;
    } else {
      this.updBtn = false;
    }
    this.reqEditVendorGrid();
    this.reqdetils();
  }
  // It i sused to get skills dropdown
  getdSkillset() {
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.PreferredSkills = res.data;
      this.PreferredSkills.forEach((element) => {
        this.skillset[element.code] = element.label;
      });
    });
  }
   // It is used for displaying the experience dropdown values
   getexperience() {
    this.apisService.getApi(environment.experience).subscribe((res: any) => {
      this.getexperienceOptions = res.data;
    });
  }
  // It is used for displaying the category dropdown values
  reqCategoryDropdown() {
    this.apisService.getApi(environment.reqArea).subscribe((res) => {
      this.reqCategoryOptions = res.data;
      const val = { catname: 'Select Req Category ', grpcategory: -1 };
    });
  }
  // It is used for displaying the billing type dropdown values
  getBillingDropdown() {
    this.apisService.getApi(environment.billingType).subscribe((res) => {
      this.billingData = res.data;
    });
  }
  // It is used for displaying the hiretype dropdown values
  getHireTypeData() {
    this.apisService.getApi(environment.hireType).subscribe((res) => {
      this.hireTypeData = res.data;
    });
  }
   // Method to get departments
   getDepartments() {
    this.reqData = JSON.parse(sessionStorage.getItem('reqData'));
    this.accountId = this.reqData.createdByAccId;
    // this.reqData.createdByAccId
    this.apisService.getApi(environment.departments + '/' +  this.reqData.ownerId).subscribe((res) => {
      this.departmentList = res.data;
      // console.log(" this.departmentList ", this.departmentList );
      
    });
  }
  // It is used to show work location dropdown values
  getWorkLocation() {
    this.acctdetails = JSON.parse(this.apisService.decodeData());
    this.reqData = JSON.parse(sessionStorage.getItem('reqData'));
    // this.reqData.createdByAccId
    this.apisService.getApi(environment.location + '/' + this.reqData.ownerId).subscribe((res) => {
      this.locationList = res.data;
    });
  }
  // It is used to show skills dropdown values
  reqSkillSet() {
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.specializationSkillsData = res.data;
    });
  }
  getConStatus() {
    this.apisService.getApi(environment.lookupsData + 'RPLPS').subscribe((res) => {
      this.consStatusData = res.data;
    });
  }
  // It is used to remove profile
  deleteRow() {
    Swal.fire({
      text: 'Are You Sure To Remove ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0D416B',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.apisService.deleteApi(environment.removeRequirement + this.idToDelete).subscribe((res: any) => {
          this.submitProfileGrid();
          Swal.fire({ text: res.message, icon: 'success', iconColor: '#1dbb99', });
        });
      }
    });
  }
  toggle(menu, event, rowData) {
    this.rowData = rowData;
    this.idToDelete = rowData.profileId;
  }
 
  // It is used for displaying the Position Category dropdown values and patch the value for min rate and max rate
  getPositionCategoryBindDataValues(data) {
    const acctId = data.accId;
    const posnCat = data.category;
    // tslint:disable-next-line: no-string-literal
    this.jobid = data.jobTitle['id'];
    // acctId
    this.apisService.getApi(environment.maxRate + this.reqData.ownerId  + '/' + posnCat).subscribe((res) => {
      this.minMaxRate = res.data;
      // console.log("this.minMaxRate ",this.minMaxRate );
      
      this.editRequirementsForm.controls.requirementTitle.patchValue(this.minMaxRate.filter((x) => x.id === data.jobTitle.id)[0]);
    });
  }
  // It is used to patch minrate and maxrate
  getTite(data) {
    this.MinRateIds = data.id;
    this.editRequirementsForm.controls.requirementsMinRate.patchValue(data.maxrate);
    this.editRequirementsForm.controls.requirementsMaxRate.patchValue(data.maxrate);
  }
 
 
  // It is used for the displaying the GridData for vendor Tab
  reqEditVendorGrid() {
    const id = { requirementId: this.reqId, tireType: '-1', requirementStatus: 'Active', };
  }
   // It is used for get particular row data based on the id and binding these values into the form
   reqdetils() {
    this.loader = true;
    this.editRequirementsForm.disable();
    this.editRequirementsForm.controls.name.enable();
    this.editRequirementsForm.controls.requirementMaxRate.enable();
    const requirementId = '/' + this.reqId;
    this.loader = true;
    this.apisService.getApi(environment.requirementEdit + requirementId).subscribe((res) => {
      this.requestData = res.data;
      // console.log("this.requestData",this.requestData);
      
      this.editRequirementsForm.controls.name.patchValue(this.requestData.name);
      this.getDepartments();
      this.getWorkLocation();
      this.getexperience();
      this.getBillingDropdown();
      // tslint:disable-next-line:no-shadowed-variable
      this.apisService.getApi(environment.positionCat + '/' + this.reqData.ownerId).subscribe((res) => {
        this.positionData = res.data;
        // console.log("  this.positionData",  this.positionData);
        
        // tslint:disable-next-line:max-line-length
        this.editRequirementsForm.controls.positionCategory.patchValue(this.positionData.filter((x) => x.id === this.requestData.category)[0]);
      });
      this.jobTitleId = this.requestData.jobTitle.id;
      this.getPositionCategoryBindDataValues(this.requestData);
      this.typeData(this.requestData.hireType);
      if (this.requestData.status === 'P') {
        this.updBtn = false;
      } else {
        this.updBtn = true;
      }
      if (this.requestData.status === 'C') {
        this.validStatus = false;
      } else {
        this.validStatus = true;
      }
      this.editRequirementsForm.patchValue({
        requirementStartDate: this.requestData.plannedStartDate,
        requirementEndDate: this.requestData.plannedEndDate,
        requirementNoofPositions: this.requestData.noOfPositions,
        requirementMinRate: this.requestData.minRate,
        requirementMaxRate: this.requestData.maxRate,
        requirementDescription: this.requestData.description,
        requirementResponsibilities: this.requestData.responsibilities,
        requirementQualification: this.requestData.qualification,
        requirementComments: this.requestData.comments,
        hrsperWeek: this.requestData.hrsPerWeek,
        duration: this.requestData.duration,
        approval: this.requestData.empApprovalReqFlag,
        // otherskills: this.requestData.otherSkills,
      });
      const selectedPrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.PreferredSkills.length; i++) {
        const array = this.requestData.prefferedSkills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.PreferredSkills[i].code) === array[j]) {
            selectedPrimarySkills.push(this.PreferredSkills[i]);
          }
        }
      }
      this.editRequirementsForm.controls.requirementPreferredSkillSet.patchValue(selectedPrimarySkills);
      selectedPrimarySkills.forEach((ele) => {
        this.requirementPreferredSkillSet.push(ele.label);
      });
      const selectedPrePrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.PreferredSkills.length; i++) {
        const array = this.requestData.skills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.PreferredSkills[i].code) === array[j]) {
            selectedPrePrimarySkills.push(this.PreferredSkills[i]);
          }
        }
      }
      this.editRequirementsForm.controls.requirementSkillSet.patchValue(selectedPrePrimarySkills);
      const requirementSkillSet = this.editRequirementsForm.controls.requirementSkillSet.value;
      requirementSkillSet.forEach((ele) => {
        this.requirementSkillSet.push(ele.label);
      });
      for (let i = 0; i < selectedPrePrimarySkills.length; i++) {
        if (selectedPrePrimarySkills[i].code === '25') {
          this.otherskilldata = true;
          this.editRequirementsForm.controls.otherskills.patchValue(this.requestData.otherSkills)

        }
        else {
          this.onBlur('');
        }
      }
      // tslint:disable-next-line:max-line-length
      this.editRequirementsForm.controls.requirementType.patchValue(this.hireTypeData.filter((x) => x.code === this.requestData.hireType)[0]);
      // tslint:disable-next-line:max-line-length
      this.editRequirementsForm.controls.requirementWorkCenterLocation.patchValue(this.locationList.filter((x) => x.locId === this.requestData.location)[0]);
      this.editLocationdata = this.locationList.filter((x) => x.locId === this.requestData.location)[0]
      this.patchlocationString = this.editLocationdata?.name + '\n' + this.editLocationdata?.address1 + ',' + this.editLocationdata?.city + ',' + this.editLocationdata?.state + ',' + this.editLocationdata?.country
      // tslint:disable-next-line:max-line-length
      this.editRequirementsForm.controls.department.patchValue(this.departmentList.filter((x) => x.id === this.requestData.departmentId)[0]);
      // tslint:disable-next-line:max-line-length
      this.editRequirementsForm.controls.requirementReqExp.patchValue(this.getexperienceOptions.filter((x) => x.code === this.requestData.experience)[0]);
      // tslint:disable-next-line:max-line-length
      this.editRequirementsForm.controls.billingtype.patchValue(this.billingData.filter((x) => x.code === this.requestData.billingType)[0]);

      this.loader = false;
    });
    if (this.requestData?.otherSkills !== null || this.requestData?.otherSkills !== '' || this.requestData?.otherSkills !== undefined) {
      this.otherskills = true;
    }
    else {
      this.otherskills = false;
    }
  }
  // It is used for end date validation
  endDateValidation() {
    if (new Date(this.editRequirementsForm.get('requirementStartDate').value).getTime() < new Date(this.editRequirementsForm.get('requirementEndDate').value).getTime()) {
    } else {
      this.editRequirementsForm.get('requirementEndDate').reset();
    }
  }
  // It is used to show end date based on hire type
  typeData(event) {
    if (event === 'C' || event === 'H') {
      this.enddata = true;
      this.editRequirementsForm.get('requirementEndDate').markAsTouched();
      this.editRequirementsForm.get('requirementEndDate').enable();
    } else {
      this.editRequirementsForm.get('requirementEndDate').disable();
      this.enddata = false;
    }
  }
  // method to download file
  downloadFileapi() {
    this.filedata = this.rowData.resumeId;
    this.apisService.getApi(environment.downloadedattachment + '/' + this.filedata).subscribe((res) => {
      this.resumeRes = res;
      window.location.assign(this.resumeRes.downloadUrl);
    });
  }
  // tslint:disable-next-line: max-line-length
  // It is used for back routing .It will be route based on the quire parms data .If you get tech reviews it route to tech reviews page otherwise it will route to requirements page
  backNavigate() {
    if (this.name1 === 'techReview_req') {
      this.router.navigate(['/tech-reviews']);
    } else {
      if (this.headerName === 'Requirements') {
        this.router.navigate(['/requirements']);
      } else {
        this.router.navigate(['/open-positions']);
      }
    }
  }
  submitProfileGrid() {
    const row = this.rows;
    const page = this.page;
    this.loader = true;
    // tslint:disable-next-line:max-line-length
    this.apisService.getApi(environment.avaliableSubmitedPro + page + '/' + row + '/' + this.reqId + '?consEmail=&fName=&lName=&exp=').subscribe((res: any) => {
      this.loader = false;
      this.submittedProfilesGridData = res.data.records;
      this.subProRecords = res.data.count;
      if (this.subProRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  
  conStatusTable(data) {
    const conStatus = this.consStatusData.find((x) => x.code === data).label;
    return conStatus;
  }
  getSkillSetTable(data) {
    this.array = data;
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.specializationSkillsData.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < this.array.length; j++) {
        if (Number(this.specializationSkillsData[i].code) === this.array[j]) {
          selectedPrePrimarySkills3.push(
            this.specializationSkillsData[i].label
          );
        } else {
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  // Pagination method
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
  }

  add() {
    this.router.navigate(['/consultant&submittedprofiles']);
  }
  ratings() {
    this.router.navigate(['/tech-reviews']);
  }
  showdetails() {
    this.userId = this.route.snapshot.paramMap.get('id');
    this.router.navigate(['/editcandidate', this.userId]);
  }
  updateAction() {
    this.updateaction = true;
    this.updateactionBtn = false;
    this.filedata = this.rowData;
    this.userid = this.rowData.userId;
    this.resumID = this.rowData.resumeId;
    this.apisService.getApi(environment.requireupdateaction + `/${this.userid}`).subscribe((res: any) => {
      this.updateactionGrid = res.data;
    });
  }
  onSelect(event) {
    this.updateactionBtn = true;
    this.filedata = this.rowData;
    this.profileID = this.filedata.profileId;
    this.rowdataid = event.data.id;
  }
  onUnselect(event) {
    // Radio button unselect event
    this.updateactionBtn = false;
    const val1 = event.data.id;
  }
  submitAction() {
    // Update button method in update profile
    const data = {
      id: this.profileID,
      resumeId: this.rowdataid,
    };
    this.apisService.putApi(environment.updateaction, data).subscribe((res: any) => {
      if (res.hasOwnProperty('success') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        this.submitProfileGrid();
        this.updateaction = false;
      } else if (res.hasOwnProperty('success') === false) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
      } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
      }
    });
  }
  // Method to get minrate based on job role
  getMinrate(data) {
    this.editRequirementsForm.get('requirementsMinRate').disable();
    this.editRequirementsForm.get('requirementsMaxRate').disable();
    this.maxRateId = data.id;
    this.editRequirementsForm.get('requirementsMinRate').patchValue(data.minrate);
    this.editRequirementsForm.get('requirementsMaxRate').patchValue(data.maxrate);
  }
  // It is used for the updating the existing data
  requirementEditUpdate() {
    if (this.editRequirementsForm.invalid) {
      const controls = this.editRequirementsForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
        }
      }
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      this.loader = true;
      const reqType = this.editRequirementsForm.controls.requirementType.value;
      const addreqfrmDate = this.editRequirementsForm.controls.requirementStartDate.value;
      const addReqFrmDate = new Date(Date.parse(addreqfrmDate));
      this.AddRequirementsFromDate = this.datepipe.transform(addReqFrmDate, 'MM-dd-yyyy');
      const addreqtoDate = this.editRequirementsForm.controls.requirementStartDate.value;
      const addReqToDate = new Date(Date.parse(addreqtoDate));
      this.AddRequirementsEndDate = this.datepipe.transform(addReqToDate, 'MM-dd-yyyy');
      if (reqType.code === 'F' || reqType.code === 'O') {
        this.AddRequirementsEndDate = '';
      }
      const skillSetFormData = this.editRequirementsForm.controls.requirementSkillSet.value;
      this.skillSet = skillSetFormData.map((x) => x.code);
      const PreferredSkillsFormData = this.editRequirementsForm.controls.requirementPreferredSkillSet.value;
      this.PreferredSkills = PreferredSkillsFormData.map((x) => x.code);
      const payload = {
        // tslint:disable-next-line:max-line-length
        accDeptId: this.editRequirementsForm.controls.department.value === null ? '' : this.editRequirementsForm.controls.department.value.id === undefined ? 0 : this.editRequirementsForm.controls.department.value.id, // check,
        accId: 1,
        billingType: this.editRequirementsForm.controls.billingtype.value.code,
        comment: this.editRequirementsForm.controls.requirementComments.value,
        createdBy: 1,
        createdByAccId: 1,
        hireType: this.editRequirementsForm.controls.requirementType.value.code,
        hrsPerWeek: Number(this.editRequirementsForm.controls.hrsperWeek.value),
        locationId: this.editRequirementsForm.controls.requirementWorkCenterLocation.value.locId,
        modifiedBy: Number(this.acctDetails.userId),
        ownerId: this.accountId,
        preSkillSet: this.PreferredSkills,
        reqCategory: 0,
        reqComments: 'string',
        reqCreatedBy: Number(this.acctDetails.userId),
        reqDuration: Number(this.editRequirementsForm.controls.duration.value),
        reqEndDate: this.AddRequirementsEndDate,
        reqJobdesc: this.editRequirementsForm.controls.requirementDescription.value,
        jobTitle: this.jobid,
        reqMaxRate: this.editRequirementsForm.controls.requirementMaxRate.value,
        reqMinRate: this.editRequirementsForm.controls.requirementMinRate.value,
        reqName: this.editRequirementsForm.controls.name.value,
        reqNoOfResources: Number(this.editRequirementsForm.controls.requirementNoofPositions.value),
        reqQualification: this.editRequirementsForm.controls.requirementQualification.value,
        reqResponsibility: this.editRequirementsForm.controls.requirementResponsibilities.value,
        reqSkillSet: this.skillSet,
        reqStartDate: this.AddRequirementsFromDate,
        // tslint:disable-next-line:max-line-length
        positionCategory: this.editRequirementsForm.controls.positionCategory.value ? this.editRequirementsForm.controls.positionCategory.value.id : '',
        yearExperience: this.editRequirementsForm.controls.requirementReqExp.value.code,
        empApprovalReqFlag: this.editRequirementsForm.controls.approval.value,
        lkReqType: 'C',
        parentReqId: this.reqId,
        otherSkills: this.otherskilldata === true ? this.editRequirementsForm.controls.otherskills.value : '',
        // tslint:disable-next-line: max-line-length
        // otherSkills: this.editRequirementsForm.controls.otherskills.value === null ||  this.editRequirementsForm.controls.otherskills.value === '' ||  this.editRequirementsForm.controls.otherskills.value === undefined ? '' :  this.editRequirementsForm.controls.otherskills.value
      };
      this.disbalebtn = true;
      this.apisService.postApi(environment.addReq, payload).subscribe((res) => {
        this.addRequirementData = res;
        this.loader = false;
        if (this.addRequirementData.success === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.addRequirementData.message });
          setTimeout(() => {
            this.router.navigate(['/rec-requirements']);
            this.disbalebtn = false;
          }, 2000);
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.addRequirementData.message });
        }
      });
    }
  }
  // Method to show currency symbol based on duration
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
  skillsetOthers(data) {
    this.editRequirementsForm.controls.otherskills.reset();
    this.editRequirementsForm.controls.otherskills.clearValidators();
    // tslint:disable-next-line:prefer-for-of
    this.otherskilldata = false;
    if (data) {
      this.otherskilldata = false;
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < data.length; i++) {
      if (data[i].code === '25') {
        this.otherskilldata = true;
      }
    }

    if (this.otherskilldata === true) {
      this.editRequirementsForm.controls.otherskills.setValidators([Validators.required, Validators.maxLength(300)]);
    }

    if (this.otherskilldata === false) {
      this.editRequirementsForm.controls.otherskills.reset();
      this.editRequirementsForm.controls.otherskills.clearValidators();
    }
  }
  saveLocation(ev) {
    const data = ev.value;
    this.patchlocationString = data.name + '\n' + data.address1 + ',' + data.city + ',' + data.state + ',' + data.country
  }
  setRequired() {
    return [Validators.required, Validators.maxLength(300)];
  }
  onBlur(data): void {
    if (data === '') {
    }
  }
}
