<p-card>
    <form [formGroup]="techReviews">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields"> Candidate Name</label>
                <input id="input" autocomplete="off" type="text" pInputText (keyup.enter)="searchData()"
                    placeholder="Candidate Name" size="30" formControlName="candidatesName" />
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields"> Interview Mode</label>
                <p-dropdown placeholder="Select Interview Mode" (keyup.enter)="searchData()" [showClear]="true"
                    [options]="interviewTypeList" optionValue="code" optionLabel="label" size="30"
                    formControlName="candidatesMode"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <label class="inputfields">Assign Date</label>
                <p-calendar id="popup" dateFormat="mm-dd-yy" (keyup.enter)="searchData()"
                    formControlName="candidatesAssignDate" placeholder="MM-DD-YYYY" [showIcon]="true"
                    [showButtonBar]="true">
                </p-calendar>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <label class="inputfields">Review Date</label>
                <p-calendar id="popup" dateFormat="mm-dd-yy" (keyup.enter)="searchData()"
                    formControlName="candidatesReviewDate" placeholder="MM-DD-YYYY" [showIcon]="true"
                    [showButtonBar]="true">
                </p-calendar>
            </div>
        </div>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields"> Status</label>
                <p-dropdown placeholder="Select Status" (keyup.enter)="searchData()" [showClear]="true" [options]="statusList"
                    optionValue="code" optionLabel="label" size="30" formControlName="candidatesStatus">
                </p-dropdown>
            </div>
        </div>
    </form>
    <div class="btnsCSS1 btnalign">
        <button pButton type="button" icon="pi pi-refresh" label="Reset" (click)="reset()" class="addhBtn"></button>
        <button pButton type="button" icon="pi pi-search" label="Search" (click)="searchData()" class="addhBtn">
        </button>
    </div>
</p-card>
<br />
<p-card class="pgpos">
    <div *ngIf="isUploading1" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
    </div>
    <div *ngIf="table">
        <p-table [value]="this.gridData" [columns]="cols" selectionMode="single" dataKey="edit" [responsive]="true"
            sortMode="multiple">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        (click)="iconToggle[i] = !iconToggle[i]" [ngStyle]="{ width: col.width }"
                        [pSortableColumn]="col.field">
                        <span>
                            {{ col.header }}
                        </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
                <tr>
                    <td class="textCenter"><span class="ui-column-title"></span>{{ data.jobTitle }} </td>
                    <td class="textCenter"> <span class="ui-column-title"></span>{{ data.username }}</td>
                    <td class="textCenter"> <span class="ui-column-title"></span>{{ interviewTypeLabel[data.attributes] }} </td>
                    <td class="textCenter"> <span class="ui-column-title"></span>{{ data.interviewDate }} </td>
                    <td class="textCenter"> <span class="ui-column-title"></span>{{ data.assignDate }} </td>
                    <td class="textCenter"> <span class="ui-column-title"></span>{{ data.reviewDate }} </td>
                    <td><span class="ui-column-title"></span>
                        <p-badge [value]=" statusTable[data.status]"
                            [ngClass]="data.status ==='A'?'green': 'evalution'">
                        </p-badge>
                    </td>
                    <td class="textCenter"> <span class="ui-column-title"></span><a>
                            <em (click)="actionpopup.toggle($event); toggle(items, $event, data)"
                                class="pi pi-ellipsis-v pointer"></em>
                        </a>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid ui-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{ this.totalRecords }}
                </h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" (onPageChange)="paginate($event)"
                    [rowsPerPageOptions]="[10, 20, 30]">
                </p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg" />
                <br /><em>Sorry, we are not able to find any reviews</em><br />
            </div>
        </div>
    </p-card>
</p-card>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>