
<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
    <form [formGroup]="submissionRequestForm">
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                        <label class="inputfields">Job Id</label>
                        <input id="input" type="text" pInputText placeholder="Job Id" autocomplete="off" maxlength="11"
                            formControlName="jobId">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                        <label class="inputfields"> Status</label>
                        <br>
                        <p-dropdown [options]="status" [showClear]="true" placeholder="Select Status" optionValue="code"
                            optionLabel="label" size="30" formControlName="statusDropdown"></p-dropdown>
                    </div>
                </div>
                <div class="btnsCSS1 btnalign">
                    <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()"
                        class="addhBtn"></button>
                    <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn"
                        (click)="search()"></button>
                </div>
    </form>
</p-card>
<br>
<p-card class="pgpos">
    <div *ngIf="isUploading1" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <p-progressBar mode="indeterminate"></p-progressBar>
    </div>
    <div *ngIf="table">
        <p-table [columns]="cols" [value]="gridRes" selectionMode="single" dataKey="edit" [rows]="10"
            [responsive]="true" [rowsPerPageOptions]="[10,20,30]" sortMode="multiple" [autoLayout]="true">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i=index" [ngStyle]="{'width': col.width }"
                        [pSortableColumn]="col.field">
                        <span>
                            {{col.header}}

                        </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                <tr>
                    <td class="textCenter"><span class="p-column-title">Job Id </span>{{data.reqId}}</td>
                    <td class="textCenter" pTooltip="{{data.jobTitle}}" tooltipPosition="top"><span
                            class="p-column-title skillDesc">Job
                            Title</span>{{data.jobTitle}}</td>
                    <td class='textCenter skillDesc pointer'><span class="p-column-title">Skills</span>
                            <a (click)="op.toggle($event)" (click)="reqSkills1(getSkillSetTable(data.requiredSkills))"
                            *ngFor="let data1 of getSkillSetTable(data.requiredSkills); index as i;">
                            <p-badge class="badgespace newsty"  *ngIf='i<1' [value]="data1"></p-badge>
                            <p-badge  *ngIf='i===1' [value]="'+'+(data.requiredSkills.length-1)+' more'"></p-badge>
                          </a>
                    </td>
                    <td><span class="p-column-title">Vendor Name</span>
                        {{data.vendorName}}
                    </td>
                    <td class='textCenter' pTooltip="{{data.accountName}}" tooltipPosition="top"><span
                            class="p-column-title">Customer Name </span>{{data.accountName}}</td>
                    <td>
                        <p-badge [value]="statuTable[data.activityStatus]"
                            [ngClass]="data.activityStatus==='A'?'green': data.activityStatus === 'R'?'red': 'blue'">
                        </p-badge>
                    </td>
                    <td class="textCenter"><span class="p-column-title">Action</span><a>
                            <em (click)="actionpopup.toggle($event); toggle($event, data)"
                                class="pi pi-ellipsis-v pointer"></em></a></td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any submission requests</em><br>
            </div>
        </div>
    </p-card>
</p-card>
<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '25%' }">
    <label class="inputfields">Skill Details</label>
    <ng-template pTemplate>
        <div class="p-col-12 bordersty">
            <p class="skpos">
              <p-badge class="badgespace badgesp" *ngFor="let data2 of reqSkillsValue; index as i" [value]="data2" [ngClass]="i%2===0? 'newsty' : ''">
              </p-badge>
            </p>
          </div>
    </ng-template>
</p-overlayPanel>
<p-dialog [(visible)]="approveOrReject" header="Submission Request Approve/Reject" [resizable]="false" [modal]="true"
    [responsive]="true" [style]="{width: '25%', minWidth: '220px'}" [minY]="70">
    <form [formGroup]="approveOrRejectForm">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <p>Do you want to approve the submission request?</p>
            </div>
            <div class="p-col-6 p-md-6 p-sm-12">
                <p-radioButton name="approveOrReject" value="Approved" label="Yes" formControlName="approveOrReject"
                    class="radiospace" inputId="opt1" (click)="approveRequirement()">
                </p-radioButton>
                <p-radioButton name="approveOrReject" value="Rejected" label="No" formControlName="approveOrReject"
                    inputId="opt2" (click)="rejectRequirement()">
                </p-radioButton>
            </div>
        </div><br>
        <div class="p-grid p-fluid ">
            <div class="p-col-8 p-md-12 p-sm-12" *ngIf="rejectForComments">
                <label class="first">Reason for Rejection<span class="mandatoryColor">*</span></label>
                <textarea autocomplete="off" pInputTextarea [rows]="2" [cols]="30" placeholder="Comments"
                    formControlName="comments" maxlength=201></textarea>
                <span class="validationtest" *ngIf="approveOrRejectForm.get('comments').invalid && approveOrRejectForm.get('comments').touched &&
          this.approveOrRejectForm.get('comments').errors.required">Please enter reason for rejection</span>
          <span class="validationtest" *ngIf="this.approveOrRejectForm.get('comments').hasError('maxlength')">
            Please enter 200 alphabets only
        </span>
            </div>
        </div>
        <div class="btnsCSS1">
            <button pButton type="button" icon='pi pi-times' label="Cancel" class="approvbtn"
                (click)="approveOrReject = false"></button>
            <button pButton type="button" icon='pi pi-check-circle' label="Submit" class="approvbtn" [disabled]="disable"
                (click)="submitForApproveOrReject()"></button><br>
        </div>
    </form>
</p-dialog>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
<p-dialog class="dialogBox" [(visible)]="displayModal" [draggable]="false" [modal]="true"
    [style]="{width: '30%',height:'60%'}">
    <p-header><span class="subActivity">Submission Activity</span>
        <span class="Activity">Job Id : <a> {{this.empId}}</a></span>
    </p-header>
    <p class="activitycolor">Please find the below information</p>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-sm-12">
            <p-timeline [value]="histroy" align="alternate">
                <ng-template pTemplate="content" let-event>
                    <p-card>
                        <p class="aligncenter"> {{event.activity}}
                            <br>
                            <span class="activitycolor">By {{event.activityBy}}</span>
                        </p>
                    </p-card>
                    <br>
                </ng-template>
                <ng-template pTemplate="opposite" let-event><span class="activitycolor">{{event.activityDate | date:'MM-dd-yyyy'}}</span>
                </ng-template>
            </p-timeline>
        </div>
    </div>
</p-dialog>