import { Component, OnInit, HostListener } from '@angular/core';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApisService } from 'src/app/services/apis.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-submited-profiles',
  templateUrl: './submited-profiles.component.html',
  styleUrls: ['./submited-profiles.component.scss'],
  providers: [DatePipe, MessageService],
})
export class SubmitedProfilesComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  constructor(private breadcrumbService: BreadcrumbService, private fb: FormBuilder, private apisService: ApisService, private datepipe: DatePipe) {
    this.breadcrumbService.setItems([
      { label: 'Submitted Profiles', routerLink: '/submittedprofiles' },
    ]);
  }
  submittedListForm: FormGroup;
  page = 1;
  rows = 10;
  roleId = 5;
  tableData: any;
  scrollableCols: any;
  totalRecords1: any;
  table = true;
  records: boolean;
  loader: boolean;
  workStatusList: any;
  first: any;
  pageCount: any;
  subFromDate: string;
  subEndDate: string;
  workStatusData: any;
  consStatusData: any;
  items: {
    label: string; icon: string;
    // tslint:disable-next-line:no-shadowed-variable
    command: (event: any, i: any) => void;
  }[];
  cols = [
    { field: 'reqId', header: 'Job ID', width: '4px' },
    { field: 'customerName', header: 'Vendor', width: '10px' }, // role id = 3
    { field: 'consultantName', header: 'Candidate Name', width: '10px' },
    { field: 'submittedDate', header: 'Submitted Date', width: '10px' },
    { field: 'email', header: 'Email', width: '10px' },
    { field: 'office_phone', header: 'Phone No', width: '10px' },
    { field: 'workStatus', header: 'Work Status', width: '10px' },
    { field: 'consStatus', header: 'Consultant Status', width: '10px' },
    { field: 'start', header: 'Background Status', width: '10px' },
    { field: 'maxRate', header: 'Rate($/Hr)', width: '10px' },
    { field: '', header: 'Action', width: '5px' },
  ];
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.search();
    }
  }
  ngOnInit(): void {
    this.submittedListForm = this.fb.group({
      customerEmail: [''],
      firstName: [''],
      lastName: [''],
      startDate: [''],
      todate: [''],
      status: ['']
    });
    this.submitTableData();
    this.getWorkStatus();
    this.getConStatus();
  }
  getWorkStatus() {
    this.apisService.getApi(environment.lookupsData + 'UPDVS').subscribe((res) => {
      this.workStatusList = res.data;
    });
  }
  getConStatus() {
    this.apisService.getApi(environment.lookupsData + 'RPLPS').subscribe((res) => {
      this.consStatusData = res.data;
    });
  }
  getConsStatus(data) {
    const ConsultantStatus = this.consStatusData.find(x => x.code === data).label;
    return ConsultantStatus;
  }
  submitTableData() {
    this.loader = true;
    const page = this.page;
    const row = this.rows;
    this.apisService.getApi(environment.submitedProfiles + page + '/' + row + '/' + this.roleId).subscribe(
      (res: any) => {
        this.tableData = res.data.records;
        this.totalRecords1 = res.data.count;
        this.loader = false;
        if (this.totalRecords1 !== 0) {
          this.table = true;
          this.records = false;
        } else {
          this.table = false;
          this.records = true;
        }
        this.loader = false;
      },
      (err) => {
        this.loader = false;
      }
    );
  }
  reset() {
    this.submittedListForm.reset();
    this.submitTableData();
  }
  search() {
    this.loader = true;
    if (
      this.submittedListForm.controls.startDate.value === '' ||
      this.submittedListForm.controls.startDate.value === null
    ) {
      this.subFromDate = '';
    } else {
      const startdate = this.submittedListForm.controls.startDate.value;
      const reqstartDate = new Date(Date.parse(startdate));
      this.subFromDate = this.datepipe.transform(reqstartDate, 'MM-dd-yyyy');
    }
    if (
      this.submittedListForm.controls.todate.value === '' ||
      this.submittedListForm.controls.todate.value === null
    ) {
      this.subEndDate = '';
    } else {
      const endDate = this.submittedListForm.controls.todate.value;
      const reqEndDate = new Date(Date.parse(endDate));
      this.subEndDate = this.datepipe.transform(reqEndDate, 'MM-dd-yyyy');
    }
    const page = this.page;
    const row = this.rows;
    // tslint:disable-next-line:max-line-length
    const consEmail = this.submittedListForm.controls.customerEmail.value === null ? '' : this.submittedListForm.controls.customerEmail.value;
    const fristName = this.submittedListForm.controls.firstName.value === null ? '' : this.submittedListForm.controls.firstName.value;
    const lastName = this.submittedListForm.controls.lastName.value === null ? '' : this.submittedListForm.controls.lastName.value;
    // tslint:disable-next-line:max-line-length
    const status = this.submittedListForm.controls.status.value === null ? '' : this.submittedListForm.controls.status.value.code === '-1' ? '' : this.submittedListForm.controls.status.value.code;
    const submittedFrom = this.subFromDate === '' ? '' : this.subFromDate;
    const submittedTo = this.subEndDate === '' ? '' : this.subEndDate;
    // tslint:disable-next-line:max-line-length
    const searchData = page + '/' + row + '/' + this.roleId + '?custName=' + '&consEmail=' + consEmail + '&fName=' + fristName + '&lName=' + lastName + '&fromDate=' + submittedFrom + '&toDate=' + submittedTo + '&status=' + status;
    this.apisService.getApi(environment.submitedProfiles + searchData).subscribe((res) => {
      this.tableData = res.data.records;
      this.totalRecords1 = res.data.count;
      this.loader = false;
      if (this.totalRecords1 !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  getWorkStatusTable(data) {
    // tslint:disable-next-line:variable-name
    const wok_status = this.workStatusList.find((x) => x.code === data).label;
    return wok_status;
  }
  conStatusTable(data) {
    const conStatus = this.consStatusData.find((x) => x.code === data).label;
    return conStatus;
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.submitTableData();
  }
  toggle(menu, event, rowData) {
    this.items = [
      // tslint:disable-next-line: no-shadowed-variable
      { label: 'Show Profile', icon: 'pi pi-arrow-circle-right', command: (event, i) => { } },
      // tslint:disable-next-line: no-shadowed-variable
      { label: 'Review', icon: 'pi pi-clone', command: (event, i) => { } },
      // tslint:disable-next-line: no-shadowed-variable
      { label: 'Proceed', icon: 'pi pi-clone', command: (event, i) => { } },
    ];
  }
}
