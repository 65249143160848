import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-employee-recommendations',
  templateUrl: './employee-recommendations.component.html',
  styleUrls: ['./employee-recommendations.component.scss'],
  providers: [DatePipe, MessageService],
})
export class EmployeeRecommendationsComponent implements OnInit {
  // It is used for displaying table header data
  received = [
    { field: 'name', header: 'Recommended By ', width: '10%' },
    { field: 'organization', header: 'Organization Name', width: '10%' },
    { field: 'recommendation', header: 'Recommendation', width: '10%' },
    { field: 'recDate', header: ' Recommendated Date', width: '10%' },
    { field: '', header: 'Action', width: '5%' },
  ];
  requested = [
    { field: 'name', header: 'Requested By', width: '10%' },
    { field: 'organization', header: 'Organization Name', width: '10%' },
    { field: 'recommendation', header: 'Recommendation', width: '10%' },
    { field: 'recDate', header: ' Recommendated Date', width: '10%' },
    { field: '', header: 'Action', width: '5%' },
  ];
  given = [
    { field: 'name', header: 'Recommended To ', width: '10%' },
    { field: 'organization', header: 'Organization Name', width: '10%' },
    { field: 'recommendation', header: 'Recommendation', width: '10%' },
    { field: 'recDate', header: ' Recommendated Date', width: '10%' },
    { field: '', header: 'Action', width: '5%' },
  ];
  recommendationForm: FormGroup;
  first: any;
  rows: any = 10;
  pageCount: any;
  page: any = 1;
  totalRecords: any;
  id: any;
  statuTable: any;
  griddCount: number;
  records: boolean;
  table: boolean;
  loader: boolean;
  Modal: boolean;
  data: any;
  // It is used for displaying status dropdown values
  status = [
    { name: 'Received', code: 'R' },
    { name: 'Given', code: 'G' },
    { name: 'Requested', code: 'Q' }
  ];
  userId: any;
  gridRes: any;
  gridData: any;
  jobId: string;
  actId: any;
  rowid: string;
  row: string;
  grid: any;
  totalrecords: number;
  pageNo: string;
  displayModal: boolean;
  request: FormGroup;
  dropdown: any;
  set: string[];
  str: string;
  employeData: string;
  jobStatus: any = [];
  usrAccId: any;
  getData: any;
  items: { label: string; icon: string; command: () => void; }[];
  editrequest: FormGroup;
  editModal: boolean;
  cols: { field: string; header: string; width: string; }[];
  hide: boolean;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private messageService: MessageService, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Recommendations', routerLink: '/recommendations' },
    ]);
  }
  // Window keydown listener
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.search();
    }
  }
  ngOnInit(): void {
    this.recommendationForm = this.fb.group({
      status: [''],
    });
    this.recommendationForm.controls.status.patchValue({ code: 'R', name: 'Received' });
    this.request = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.maxLength(200)],
    });
    this.editrequest = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.maxLength(200)],
    });
    this.dropdownevent('');
    this.gridTable();
    this.dropDown();
  }
  dropdownevent(event) {
    if (event === 'R') {
      this.cols = this.received;
    } else if (event === 'Q') {
      this.cols = this.requested;
    } else if (event === 'G') {
      this.cols = this.given;
    } else {
      this.cols = this.received;
    }
  }
  // It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridTable();
    this.search();
  }
  // It is used to show request pop up
  pop() {
    this.request.reset();
    this.displayModal = true;
  }
  // It is used to copy email
  copyInputMessage(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
  // It is used to show table data
  gridTable() {
    const pageNo = this.page;
    const pageCount = this.rows;
    const acctdetails = JSON.parse(this.apisService.decodeData());
    this.userId = acctdetails.userId;
    this.loader = true;
    // tslint:disable-next-line:max-line-length
    this.apisService.getApi(environment.recommendation + '/' + pageNo + '/' + pageCount + '/' + this.userId + '/' + 'R').subscribe((res: any) => {
      this.gridData = res.data.records;
      this.totalRecords = res.data.count;
      this.loader = false;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  // It is used to show action pop up data
  toggle(event, data) {
    this.items = [
      { label: 'Show Request', icon: 'pi pi-fw pi-pencil', command: () => { this.edit(data); }, }
    ];
  }
  emailToast(){
    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
  }
  dropDown() {
    this.apisService.getApi(environment.myconnectedpeople + '/' + this.userId).subscribe((res: any) => {
      this.data = res.data;
      this.data.forEach((element) => {
        this.employeData = element.name + '(' + element.companyName + ')' + '\n' + element.designation;
        this.jobStatus.push({ accId: element.accId, details: this.employeData, partnerId: element.partnerId });
      });
    });
  }
  // It is used to reset search fields
  reset() {
    this.recommendationForm.reset();
    this.records = false;
    this.gridTable();
  }
  // It is used to search table data
  search() {
    this.loader = true;
    // tslint:disable-next-line:max-line-length
    const status = this.recommendationForm.controls.status.value === null || this.recommendationForm.controls.status.value === undefined || this.recommendationForm.controls.status.value === '' ? 'R' : this.recommendationForm.controls.status.value.code;
    const data = this.page + '/' + this.rows + '/' + this.userId;
    this.apisService.getApi(environment.recommendation + '/' + data + '/' + status).subscribe((res: any) => {
      this.loader = false;
      this.gridRes = res.data.records;
      this.totalRecords = res.data.count;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  // It is used to add employee recommendation
  save() {
    if (this.request.invalid) {
      const controls = this.request.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      this.hide = true;
      this.loader = true;
      const data = {
        usrAccId: this.userId,
        status: 'Q',
        // tslint:disable-next-line:max-line-length
        reviewerId: this.request.controls.name.value.partnerId === undefined || this.request.controls.name.value.partnerId === '' || this.request.controls.name.value.partnerId === null ? '' : this.request.controls.name.value.partnerId,
        // tslint:disable-next-line:max-line-length
        revAccId: this.request.controls.name.value.accId === undefined || this.request.controls.name.value.accId === '' || this.request.controls.name.value.accId === null ? '' : this.request.controls.name.value.accId,
        // tslint:disable-next-line:max-line-length
        recommendation: this.request.controls.description.value === undefined || this.request.controls.description.value === '' || this.request.controls.description.value === null ? '' : this.request.controls.description.value,
      };
      this.apisService.postApi(environment.saverecommendation, data).subscribe(res => {
        this.getData = res;
        this.gridTable();
        if (this.getData.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message });
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error });
        }
        this.hide = false;
        this.displayModal = false;
        this.loader = false;
      });
    }
  }
  // It is used to display show request pop up
  edit(data) {
    this.editrequest.reset();
    this.editModal = true;
    this.editrequest.controls.description.patchValue(data.comment);
    this.editrequest.controls.name.patchValue(this.jobStatus.filter(x => x.partnerId === data.reviewerId)[0]);
  }
}
