import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApisService } from 'src/app/services/apis.service';
@Component({
    selector: 'app-servicesbayadminmenu',
    templateUrl: './servicesbayadminmenu.component.html',
    styleUrls: ['./servicesbayadminmenu.component.scss'],
    providers: [DatePipe, MessageService],
})
export class ServicesbayadminmenuComponent implements OnInit {
    selected: any = 'Vendor-details';
    accountid = sessionStorage.getItem('account_id');
    rowId = sessionStorage.getItem('acct_rowid');
    dropDownValue: any;
    accountId: string;
    accName: string;
    empflag: any;
    acctid: any;
    acctdetails: any;
    accId: any;
    acctname: any;
    flag: any;
    getdata: any;
    accdetails: string;
    getdata1: any;
    fetchdata: any;
    getaccdata: any;
    accountactivityId: string;
    constructor(private router: Router, private route: ActivatedRoute, private apisService: ApisService) {
        const routeParams: any = this.route.snapshot.queryParamMap;
        this.empflag = routeParams.params.empflag;
        this.flag = routeParams.params.flag;
        this.accountactivityId = this.route.snapshot.paramMap.get('getdata');
        this.getdata = routeParams.params.getdata;
        this.getaccdata = routeParams.params.getaaccdataofacc;
        this.accdetails = this.route.snapshot.paramMap.get('getdata');
        this.acctid = JSON.parse(apisService.decodeData()).userId;
        this.acctdetails = JSON.parse(this.apisService.decodeData()).accName;
        this.apisService.accActivity.subscribe((res: any) => {
            this.acctname = res;
        });
        // tslint:disable-next-line: max-line-length
        if (JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 2 || JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 8 || JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 13) {
            this.accountid = JSON.parse(sessionStorage.getItem('loginData')).orgId;
        } else {
            this.accountid = this.rowId;
        }
        this.apisService.accActivitycondition.subscribe((res: any) => {
            this.getdata1 = res;
        })
        if ((this.flag === 'AccActivity' || this.getdata === 'AccAct' || this.router.url.split(';')[1] === 'getdata=AccAct')) {
            this.accName = this.acctname.accountName;
        }
        else if (this.getaccdata === 'accountdata') {
            this.accName = this.acctdetails;

        } else {
            this.accName = this.acctdetails;
        }
    }
    ngOnInit() {
        this.accId = JSON.parse(this.apisService.decodeData()).accId;
        // if (this.empflag) {
        //     this.accName = sessionStorage.getItem('account_Name');
        // } else {
        //     this.accName = this.acctdetails;
        // }
        // tslint:disable-next-line:max-line-length
        if ((this.flag === 'AccActivity' || this.getdata === 'AccAct'|| this.router.url.split(';')[1] === 'getdata=AccAct')) {
            this.dropDownValue = [
                // tslint:disable-next-line:max-line-length
                {
                    label: 'Account Details', command: () => {
                        this.router.navigate(['/acc-adminmenu/acc-details', this.acctname.accId], {
                            queryParams: {
                                getdata: 'AccAct'
                            }
                        });
                    }
                },
                {
                    label: 'Contacts', command: () => {
                        this.router.navigate(['/acc-adminmenu/acc-contact', this.acctname.accId], {
                            queryParams: {
                                getdata: 'AccAct'
                            }
                        });
                    }
                },
                {
                    label: 'Attachments', command: () => {
                        this.router.navigate(['/acc-adminmenu/acc-attachment', this.acctname.accId], {
                            queryParams: {
                                getdata: 'AccAct'
                            }
                        });
                    }
                },
                {
                    label: 'Locations', command: () => {
                        this.router.navigate(['/acc-adminmenu/acc-locations', this.acctname.accId], {
                            queryParams: {
                                getdata: 'AccAct'
                            }
                        });
                    }
                },
            ];
        } else if (this.getaccdata === 'accountdata') {
            this.dropDownValue = [
                { label: 'Account Details', command: () => { this.router.navigate(['/acc-adminmenu/acc-details', this.accId]); } },
                { label: 'Contacts', command: () => { this.router.navigate(['/acc-adminmenu/acc-contact', this.accId]); } },
                { label: 'Attachments', command: () => { this.router.navigate(['/acc-adminmenu/acc-attachment', this.accId]); } },
                { label: 'Locations', command: () => { this.router.navigate(['/acc-adminmenu/acc-locations', this.accId]); } },
            ];
        } else {
            this.dropDownValue = [
                { label: 'Account Details', command: () => { this.router.navigate(['/acc-adminmenu/acc-details', this.accId]); } },
                { label: 'Contacts', command: () => { this.router.navigate(['/acc-adminmenu/acc-contact', this.accId]); } },
                { label: 'Attachments', command: () => { this.router.navigate(['/acc-adminmenu/acc-attachment', this.accId]); } },
                { label: 'Locations', command: () => { this.router.navigate(['/acc-adminmenu/acc-locations', this.accId]); } },
            ];
        }

    }
}
