import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-employeereviews',
    templateUrl: './employeereviews.component.html',
    styleUrls: ['./employeereviews.component.scss'],
    providers: [DatePipe, MessageService],
})
export class EmployeereviewsComponent implements OnInit {
    RatingForm: FormGroup;
    addtechreviewForm: FormGroup;
    items: any = [];
    actId: any;
    loginName: any;
    isUploading: boolean;
    overAllRating: any;
    reviewlist: any;
    value = 0;
    analytical: any;
    testing: any;
    teammanagement: any;
    position: any;
    ownership: any;
    overallRating: any;
    documentation: any;
    communication: any;
    coding: any;
    business: any;
    architecture: any;
    showValue: boolean;
    iconToggle = [];
    status: any[];
    totalRecords: any;
    getData: any;
    loader = false;
    pageCount: any;
    first: any;
    typeTable = {};
    loading: boolean;
    rows: any = 10;
    page: any = 1;
    records = true;
    accordianRec = true;
    accordian: boolean;
    table: boolean;
    gridData: any;
    statusConversion: any = {};
    showDialog: boolean;
    editData: any;
    reviewGrid: any;
    data: any;
    reviewshow: any;
    reviewDate: any;
    reviewerName: any;
    reviewId: number; reviewID: any;
    reviewNames: any;
    reviewDates: any;
    rowData: any;
    percentage = true;
    iconn = false;
    percentage1 = true;
    iconn1 = false;
    percentage2 = true;
    iconn2 = false;
    percentage3 = true;
    iconn3 = false;
    percentage4 = true;
    iconn4 = false;
    percentage5 = true;
    iconn5 = false;
    percentage6 = true;
    iconn6 = false;
    percentage7 = true;
    iconn7 = false;
    percentage8 = true;
    iconn8 = false;
    percentage9 = true;
    iconn9 = false;
    // It is used for displaying table header data
    cols = [
        { field: 'jobId', header: 'Job Id', width: '6%' },
        { field: 'accountName', header: 'Company', width: '14%' },
        { field: 'reviewerName', header: 'Reviewer Name', width: '14%' },
        { field: 'reviewDate', header: 'Review Date', width: '8%' },
        { field: 'action', header: 'Action ', width: '4%' },
    ];
    itemss1: { 'icon:pi pi-fw pi-star-o': any; }[];
    constructor(private fb: FormBuilder, private apisService: ApisService, private breadcrumbService: BreadcrumbService) {
        this.breadcrumbService.setItems([
            { label: 'Tech Reviews', routerLink: '/employee-reviews' },
        ]);
    }
    ngOnInit() {
        this.RatingForm = this.fb.group({
            score: [''],
        });
        this.actId = JSON.parse(this.apisService.decodeData());
        this.loginName = this.actId.name;
        this.addtechreviewForm = this.fb.group({
            candidatetName: [''],
            reviewName: [''],
            reviewStatus: [''],
            resourceName: [''],
            projectType: [''],
            rolePlayed: [''],
            techStartDate: [''],
            techEndDate: [''],
            context: [''],
            scope: [''],
            duration: [''],
            requirementsComments: [''],
            architecture: [''],
            coding: [''],
            testing: [''],
            business: [''],
            ownershsip: [''],
            management: [''],
            analytical: [''],
            communication: [''],
            documentation: [''],
            position: [''],
            average: [''],
        });
        this.getOverallRating();
        this.gridTable();
    }
    getReviewStatus() {
        this.status.forEach((x) => {
            this.statusConversion[x.code] = x.label;
        });
    }
    // It is used to displaying action pop up
    toggle(event, data, rowData) {
        this.rowData = rowData;
        this.items = [{ label: 'Show Rating', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(rowData); }, },
        ];
    }
    reviewTable(data) {
        const data1 = data.reviewId;
        this.apisService.getApi(environment.getReviewType + data1).subscribe((res: any) => {
            this.reviewshow = res.data;
        });
    }
    // It is used to show overall ratings
    getOverallRating() {
        this.actId = JSON.parse(this.apisService.decodeData());
        this.apisService.getApi(environment.employeeRatings + this.actId.userId).subscribe((res: any) => {
            this.reviewlist = res.data[0];
            this.analytical = Math.round((this.reviewlist.analytical / 5) * 100);
            if (this.analytical === 100) {
                this.percentage1 = false;
                this.iconn1 = true;
            }
            this.architecture = Math.round((this.reviewlist.architecture / 5) * 100);
            if (this.architecture === 100) {
                this.percentage = false;
                this.iconn = true;
            }
            this.business = Math.round((this.reviewlist.business / 5) * 100);
            if (this.business === 100) {
                this.percentage2 = false;
                this.iconn2 = true;
            }
            this.coding = Math.round((this.reviewlist.coding / 5) * 100);
            if (this.coding === 100) {
                this.percentage3 = false;
                this.iconn3 = true;
            }
            this.communication = Math.round((this.reviewlist.communication / 5) * 100);
            if (this.communication === 100) {
                this.percentage4 = false;
                this.iconn4 = true;
            }
            this.documentation = Math.round((this.reviewlist.documentation / 5) * 100);
            if (this.documentation === 100) {
                this.percentage5 = false;
                this.iconn5 = true;
            }
            this.ownership = Math.round((this.reviewlist.ownership / 5) * 100);
            if (this.ownership === 100) {
                this.percentage6 = false;
                this.iconn6 = true;
            }
            this.position = Math.round((this.reviewlist.position / 5) * 100);
            if (this.position === 100) {
                this.percentage7 = false;
                this.iconn7 = true;
            }
            this.teammanagement = Math.round((this.reviewlist.teammanagement / 5) * 100);
            if (this.teammanagement === 100) {
                this.percentage8 = false;
                this.iconn8 = true;
            }
            this.testing = Math.round((this.reviewlist.testing / 5) * 100);
            if (this.testing === 100) {
                this.percentage9 = false;
                this.iconn9 = true;
            }
            this.overallRating = this.reviewlist.overallRating;
            this.RatingForm.controls.score.patchValue(this.reviewlist.overallRating);
        });
    }
    // It is used to show table data
    gridTable() {
        this.loader = true;
        const pageNo = this.page;
        const pageCount = this.rows;
        const data = pageNo + '/' + pageCount;
        const actId = JSON.parse(this.apisService.decodeData());
        this.apisService.getApi(environment.reviewGridData + data + '/' + actId.userId).subscribe((res: any) => {
            this.reviewGrid = res.data;
            this.totalRecords = res.count;
            this.loader = false;
            if (this.totalRecords !== 0) {
                this.table = true;
                this.records = false;
                this.accordian = true;
            } else {
                this.table = false;
                this.records = true;
                this.accordian = false;
                this.accordianRec = true;
            }
        });
    }
    // It is used for server side pagination
    paginate(event) {
        let i = 1;
        this.first = event.first;
        this.rows = event.rows;
        this.page = event.page + i++;
        this.pageCount = event.pageCount;
        this.gridTable();
    }
    // It is used to bind the table data
    ratings(rowData) {
        this.showDialog = true;
        this.reviewID = rowData.reviewId;
        this.reviewNames = rowData.reviewerName;
        this.reviewDates = rowData.reviewDate;
        const data1 = this.reviewID;
        this.apisService.getApi(environment.getReviewType + data1).subscribe((res: any) => {
            this.editData = res.data[0];
            this.addtechreviewForm.controls.requirementsComments.patchValue(this.editData.comments);
            this.addtechreviewForm.controls.architecture.patchValue(this.editData.architecture);
            this.addtechreviewForm.controls.coding.patchValue(this.editData.coding);
            this.addtechreviewForm.controls.testing.patchValue(this.editData.testing);
            this.addtechreviewForm.controls.business.patchValue(this.editData.business);
            this.addtechreviewForm.controls.ownershsip.patchValue(this.editData.ownership);
            this.addtechreviewForm.controls.management.patchValue(this.editData.teammanagement);
            this.addtechreviewForm.controls.analytical.patchValue(this.editData.analytical);
            this.addtechreviewForm.controls.communication.patchValue(this.editData.communication);
            this.addtechreviewForm.controls.documentation.patchValue(this.editData.documentation);
            this.addtechreviewForm.controls.position.patchValue(this.editData.POSITION);
            this.addtechreviewForm.controls.average.patchValue(this.editData.averageRating);
        });
    }
    // It is used to navigate to website
    goToLink(url: string) {
        const addressLink = url.includes('http') ? url : `http://${url}`;
        window.open(addressLink, '_blank');
    }
}
