import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { MessageService } from 'primeng/api';
import { ApisService } from 'src/app/services/apis.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-tiers',
  templateUrl: './tiers.component.html',
  styleUrls: ['./tiers.component.scss'],
  providers: [DatePipe, MessageService],

})
export class TiersComponent implements OnInit {

  tireName: any;
  vendorTiersForm: FormGroup;
  tireId: any;
  loading: boolean;
  vendorSaveData: any;
  totalrecords: any;
  table: boolean;
  records: boolean;
  stausData: any = [];
  display2: boolean;
  message: string;
  gridRoeData: any;
  vendorEditForm: any;
  status1: any;
  gridData: any;
  tierdata: boolean;
  buttonDisable: boolean;
  vendorId: any;
  accDetails: any;
  saveData: any;
  disable1 = false;
  nameLabel: { label: any; routerLink: string };
  rowData: any;
  items: { label: string; icon: string; command: () => void; }[];
  accType: any;
  tableHeader: { field: string; header: string; width: string; }[];
  tierName: string;
  gridId: any;
  activedata: any;
  dataaa: any;
  status: any;
  pageCount: any;
  page: any = 1;
  rows: any = 10;
  first: any;

  // tslint:disable-next-line:max-line-length
  constructor(private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private apisService: ApisService, private messageService: MessageService, private breadcrumbService: BreadcrumbService) {
  }
  ngOnInit(): void {
    const userdetails = JSON.parse(this.apisService.decodeData());
    this.accType = userdetails.accType;
    if (this.accType === 'C') {
      this.tierName = 'Vendors';
      this.tableHeader = [
        { field: 'vName', header: 'Vendor Name', width: '14%' },
        { field: 'tierName', header: 'Tier', width: '13%' },
        { field: 'discount', header: 'Discount(%)', width: '14%' },
        { field: 'daysDelay', header: 'Days Delay', width: '13%' },
        { field: 'status', header: 'Status', width: '13%' },
        { field: '', header: 'Action', width: '7%' },
      ];
    }
    else {
      this.tierName = 'Subcontractor';
      // It is used for displaying table header data
      this.tableHeader = [
        { field: 'vName', header: 'Subcontractor', width: '14%' },
        { field: 'tierName', header: 'Tier', width: '13%' },
        { field: 'discount', header: 'Discount(%)', width: '14%' },
        { field: 'daysDelay', header: 'Days Delay', width: '13%' },
        { field: 'status', header: 'Status', width: '13%' },
        { field: '', header: 'Action', width: '7%' },
      ];
    }
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.tireName = routeParams.params.tN;
    this.tireId = routeParams.params.t1;
    this.nameLabel = this.tireName;
    this.breadcrumbService.setItems([
      { label: 'Tiers', routerLink: '/tiers' },
      { label: `${this.nameLabel}`, routerLink: '/verdor-defination' },
    ]);
    this.vendorTiersForm = this.fb.group({
      vendorName: [''],
      tiers: [''],
      status: [''],
      days: [''],
      discount: [''],
    });
    this.status1 = [
      // status dropdown values for updating Tier's
      { name: 'Active', value: 'A' },
      { name: 'Inactive', value: 'I' },
    ];
    this.vendorEditForm = this.fb.group({
      vendorEditName: [''],
      vendorEditTier: [''],
      editDays: [''],
      editDiscount: [''],
      vendorEditsatutsTier: ['', Validators.required],
    });
    this.vendorGrid();
    this.getVendorStatus();
    this.getTiergridData();
  }
  // It is used to back navigate to tiers screen
  goto() {
    this.router.navigate(['/tiers']);
  }
  // It is used to show table data
  vendorGrid() {
    this.loading = true;
    const pgNo = this.page;
    const recordPage = this.rows;
    const pagecount = pgNo + '/' + recordPage;
    this.apisService.getApi(environment.getTiresWithVendor + pagecount + '/' + this.tireId).subscribe((res) => {
      this.vendorSaveData = res.data;
      this.totalrecords = res.data.length;
      if (this.totalrecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.loading = false;
    });
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.vendorGrid();
  }
  // It is used for displaying status dropdown values
  getVendorStatus() {
    const status = 'RLS';
    this.apisService.getApi(environment.lookupsData + status).subscribe((res) => {
      this.stausData = res.data;
    });
  }
  getStatusTable(data) {
    const status =
      data === null ? '' : this.stausData.find((x) => x.code === data)?.label;
    return status;
  }
  // It is used to bind the table data
  getVendorTier(data) {
    this.tierdata = false;
    if (data.status === 'A') {
      this.vendorEditForm.controls.editDiscount.patchValue(data.discount);
      this.vendorEditForm.controls.editDays.patchValue(data.daysDelay);
    } else {
      this.tierdata = true;
      this.vendorEditForm.controls.editDiscount.reset();
      this.vendorEditForm.controls.editDays.reset();
      this.vendorEditForm.controls.vendorEditTier.reset();
    }
  }
  // It is used displaying type of tier dropdown values
  getTiergridData() {
    this.loading = true;
    const pgNo = this.page;
    const recordPage = this.rows;
    const pagecount = pgNo + '/' + recordPage;
    this.accDetails = JSON.parse(this.apisService.decodeData());
    this.apisService.getApi(environment.tiers + '/' + pagecount + '/' + this.accDetails.accId).subscribe((res) => {
      this.gridData = res.data;
      this.loading = false;
    });
  }
  // It is used to show action pop up
  toggle(event, data, rowData) {
    this.rowData = rowData;
    this.items = [
      {
        label: ' Edit',
        icon: 'pi pi-pencil',
        command: () => {
          this.edit(this.rowData);
        }
      },
    ];
  }
  // it is used to update the tiers
  Update() {
    this.loading = true;
    this.disable1 = true;
    if (this.vendorEditForm.invalid) {
      const controls = this.vendorEditForm.controls;
      Object.keys(controls).forEach(key => {
        controls[key].markAsTouched();
      });
    } else {
      const data = {
        id: this.vendorEditForm.controls.vendorEditTier.value.id,
        // tslint:disable-next-line:max-line-length
        status: this.vendorEditForm.controls.vendorEditsatutsTier.value === null || this.vendorEditForm.controls.vendorEditsatutsTier.value === undefined || this.vendorEditForm.controls.vendorEditsatutsTier.value === '' ? 'Active' : this.vendorEditForm.controls.vendorEditsatutsTier.value.code,
        vendorId: this.vendorId,
        daysDelay: this.vendorEditForm.controls.editDays.value,
        discount: this.vendorEditForm.controls.editDiscount.value,
      };
      this.apisService.putApi(environment.getUpdateVendorTier, data).subscribe((res) => {
        this.saveData = res;
        if (this.saveData.hasOwnProperty('success') === true) {
          this.loading = false;
          this.disable1 = false;
          this.messageService.add({
            key: 'br',
            severity: '',
            life: 3000,
            detail: this.saveData.message,
          });
          this.display2 = false;
          this.vendorGrid();
        } else if (this.saveData.hasOwnProperty('failed') === true) {
          this.loading = false;
          this.display2 = true;
          this.messageService.add({
            key: 'br',
            severity: '',
            life: 3000,
            detail: this.saveData.failed,
          });
        } else {
          this.loading = false;
          this.display2 = true;
          this.messageService.add({
            key: 'br',
            severity: '',
            life: 3000,
            detail: this.saveData.error,
          });
        }
        this.vendorSaveData = res.data;
      });
    }
  }
  // It is used to patch the table data
  edit(rowData) {
    this.vendorEditForm.reset();
    this.display2 = true;
    this.message = '';
    this.vendorId = rowData.venId;
    this.gridRoeData = rowData;
    this.vendorEditForm.controls.vendorEditName.patchValue(rowData.vName);
    this.vendorEditForm.controls.editDiscount.patchValue(rowData.discount);
    this.vendorEditForm.controls.editDays.patchValue(rowData.daysDelay);
    this.vendorEditForm.controls.vendorEditTier.patchValue(this.gridData.filter((x) => x.tierName === rowData.tierName)[0]);
    this.vendorEditForm.controls.vendorEditsatutsTier.patchValue(this.stausData.filter((x) => x.code === rowData.status)[0]);
    this.gridId = rowData.venId;
    rowData.status === 'I' ? this.disable1 = true : this.disable1 = false;
  }
  // it is used to close add pop up
  addCancel() {
    this.display2 = false;
    this.vendorEditForm.reset();
  }
  // It is used for status dropdown values
  editactive(event) {
    if (event.value === 'I') {
      this.disable1 = false;
    } else {
      this.loading = true;
      this.disable1 = false;
      this.apisService.getApi(environment.accountactiveornot + '/' + this.gridId).subscribe((res) => {
        this.loading = false;
        this.activedata = res;
        this.dataaa = this.rowData;
        this.status = this.dataaa.status;
        if (this.activedata.success === true) {
          this.messageService.add({
            key: 'br',
            severity: '',
            life: 3000,
            detail: this.activedata.message,
          });
        } else if (this.activedata.success === false) {
          this.display2 = false;
          this.disable1 = true;
          this.messageService.add({
            key: 'br',
            severity: '',
            life: 3000,
            detail: this.activedata.message,
          });
        } else {
          this.display2 = false;
          this.messageService.add({
            key: 'br',
            severity: '',
            life: 3000,
            detail: this.activedata.error,
          });
        }
      });
    }

  }
}
