import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-vendot-tier',
  templateUrl: './vendot-tier.component.html',
  styleUrls: ['./vendot-tier.component.scss'],
  providers: [DatePipe, MessageService],
})
export class VendotTierComponent implements OnInit {
  vendorTiersForm: FormGroup;
  vendorAddForm: FormGroup;
  vendorEditForm: FormGroup;
  display: boolean;
  tiers: any;
  vendor: any;
  custoID: any;
  vendorTierdata: any;
  vendorSaveData: any;
  vendorSearchData1: any;
  vendorName: any;
  vendorId: any[];
  vendorData: any[];
  vendorserachId: any[];
  vendorserachData: any[];
  vendorTierTrueorFlase: any;
  display1: boolean;
  vendorArrayValues: any;
  saveData: any;
  userId: string;
  display2: boolean;
  accountName: any;
  vendorAccountName: any = [];
  status2: { name: string; value: string }[];
  vendorid: any;
  display3: boolean;
  vendorName2: any;
  status1: any;
  iconToggle = [];
  disable = false;
  loading = false;
  tierActiveCheck: any;
  gridRoeData: any;
  message: any;
  buttonDisable: boolean;
  stausData: any;
  totalrecords: any;
  vendorNamelist: any;
  accountDetails: any;
  vendorTierList: any;
  tireId: any;
  tireName: any;
  accDetails: any;
  gridData: any;
  tierdata: boolean;
  records: boolean;
  table: boolean;
  nameLabel: { label: any; routerLink: string };
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private apisService: ApisService,
    private router: Router,
    private messageService: MessageService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.getAddVendorNames();
  }
  // It is used for the displaying the status Dropdown Values
  status = [
    { name: 'Active', value: '-1' },
    { name: 'Inactive', value: 'Inactive' },
  ];
  // It is used for the displaying the Table header values
  tableHeader = [
    { field: '', header: 'Edit', width: '7%' },
    { field: 'accountName', header: 'Vendor Name', width: '14%' },
    { field: 'tierName', header: 'Tier', width: '13%' },
    { field: 'discount', header: 'Discount(%)', width: '14%' },
    { field: 'daysDelay', header: 'Days Delay', width: '13%' },
    { field: 'status', header: 'Status', width: '13%' },
  ];
  ngOnInit() {
    this.status1 = [
      // status dropdown values for updating Tier's

      { name: 'Active', value: 'A' },
      { name: 'Inactive', value: 'I' },
    ];
    this.vendorTiersForm = this.fb.group({
      vendorName: [''],
      tiers: [''],
      status: [''],
      days: [''],
      discount: [''],
    });
    this.vendorAddForm = this.fb.group({
      vendorName1: ['', Validators.required],
      vendorAddTier: ['', Validators.required],
      status: [''],
      days: [''],
      discount: [''],
    });
    this.vendorEditForm = this.fb.group({
      vendorEditName: [''],
      vendorEditTier: [''],
      editDays: [''],
      editDiscount: [''],
      vendorEditsatutsTier: ['', Validators.required],
    });

    const routeParams: any = this.route.snapshot.queryParamMap;
    this.tireId = routeParams.params.t1;
    this.tireName = routeParams.params.tN;
    this.nameLabel = this.tireName;

    this.breadcrumbService.setItems([
      { label: 'Tiering Definition', routerLink: '/VendorDefinition' },

      { label: `${this.nameLabel}`, routerLink: '/VendorDefinition' },
    ]);

    this.custoID = JSON.parse(sessionStorage.getItem('loginData')).orgId;
    this.userId = sessionStorage.getItem('userId');

    this.getVendorNames();
    this.getVendorNameDropdown();
    this.vendorGrid();
    this.getVendorTiers();
    this.getVendorStatus();
    this.getTiergridData();
  }
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchData();
    }
  }
  getVendorTiers() {
    // It is used for the displaying the types of tiers Values
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.tiersLookup).subscribe((res) => {
      this.tiers = res.data;

    });
  }
  getAddVendorNames() {
    this.accountDetails = JSON.parse(this.apisService.decodeData());
    this.apisService
      .getApi(environment.vendorTier + this.accountDetails.accId)
      // tslint:disable-next-line: deprecation
      .subscribe((res) => {
        this.vendorTierList = res.data;
      });
  }
  getVendorNames() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.vendorNames).subscribe((res) => {
      this.vendorNamelist = res.data;
    });
  }
  // It is used for the displaying the Grid Data
  vendorGrid() {
    this.loading = true;
    this.apisService
      .getApi(environment.getTiresWithVendor + this.tireId)
      // tslint:disable-next-line: deprecation
      .subscribe((res) => {
        this.vendorSaveData = res.data;
        this.totalrecords = res.data.length;
        if (this.totalrecords !== 0) {
          this.table = true;
          this.records = false;
        } else {
          this.table = false;
          this.records = true;
        }
        this.loading = false;
        });
  }

  // It is used for the searching the grid Data
  searchData() {
    const tiersId =
      this.vendorTiersForm.controls.tiers.value === '' ||
      this.vendorTiersForm.controls.tiers.value.vendorType === undefined
        ? ''
        : this.vendorTiersForm.controls.tiers.value.vendorType;
    const vendorName =
      this.vendorTiersForm.get('vendorName').value === ''
        ? ''
        : this.vendorTiersForm.get('vendorName').value.name === undefined
        ? ''
        : this.vendorTiersForm.get('vendorName').value.name;
    // tslint:disable-next-line: max-line-length
    const data = '?tierName=' + tiersId + '&vendorName=' + vendorName;
    this.loading = true;
    this.apisService
      .getApi(environment.listVindorTiers + data)
      // tslint:disable-next-line: deprecation
      .subscribe((res) => {
        this.vendorSaveData = res.data;
        this.totalrecords = res.total;
        if (this.totalrecords !== 0) {
          this.table = true;
          this.records = false;
        } else {

          this.table = false;
          this.records = true;
        }
        this.loading = false;
      });
  }
  getVendorStatus() {
    const status = 'RLS';
    this.apisService.getApi(environment.lookupsData + status).subscribe((res) => {
        this.stausData = res.data;
      });
  }
  getStatusTable(data) {
    const status = data === null ? '' : this.stausData.find((x) => x.code === data).label;
    return status;
  }
addProject() {
    this.vendorAddForm.reset();
    this.display = true;
    this.vendorAddForm.controls.status.patchValue(this.status1[0]);
    this.vendorAddForm.controls.status.disable();
  }
  // It is used for the displaying the vendor names Dropdown Values
  getVendorNameDropdown() {
    const data = {
      customerId: this.custoID,
      vendorName: this.vendorAddForm.controls.vendorName1.value,
    };
  }
  // It is used check the selected tier already existed or not in add pop-up
  getVendorTier(data) {
    this.tierdata = false;
    if (data.status === 'A') {
      this.vendorEditForm.controls.editDiscount.patchValue(data.discount);
      this.vendorEditForm.controls.editDays.patchValue(data.daysDelay);
    } else {
      this.tierdata = true;
      this.vendorEditForm.controls.editDiscount.reset();
      this.vendorEditForm.controls.editDays.reset();
      this.vendorEditForm.controls.vendorEditTier.reset();
    }
  }
  // It is used for based on the selected tier get the discount ,no.of.days delay and also binding that data
  vendorSelect1() {
    const data = {
      customerId: this.custoID,
      tiersId: this.vendorEditForm.controls.vendorEditTier.value.id,
    };
  }
  // It is used for the adding the Associate Vendor To Active Vendor, keep the validations
  addSave() {
    this.loading = true;
    if (this.vendorAddForm.invalid) {
      this.vendorAddForm.controls.vendorName1.markAsTouched();
      this.vendorAddForm.controls.vendorAddTier.markAsTouched();
    } else {
      const skill = this.vendorAddForm.get('vendorName1').value;
      this.vendorData = skill.map((x) => x.name);
      this.vendorId = skill.map((x) => x.id);
      const data = {
        ids: this.vendorId,
        tierId: this.vendorAddForm.controls.vendorAddTier.value.id,
        status: 'A',
        discount: this.vendorAddForm.controls.discount.value.toString(),
        daysDelay: this.vendorAddForm.controls.days.value.toString(),
      };
      this.apisService
        .postApi(environment.addVendorTier, data)
        // tslint:disable-next-line: deprecation
        .subscribe((res) => {
          this.saveData = res.data;
          if (this.saveData.hasOwnProperty('success') === true) {
            this.loading = false;
            this.messageService.add({  key: 'br',  severity: '', life: 3000, detail: this.saveData.message});
            this.display = false;
            this.vendorGrid();
          } else if (this.saveData.hasOwnProperty('failed') === true) {
            this.loading = false;
            this.display = true;
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.saveData.failed });
          } else {
            this.loading = false;
            this.display = true;
            this.messageService.add({  key: 'br',  severity: '', life: 3000, detail: this.saveData.error});
          }
          this.vendorSaveData = res.data;
        });
    }
  }
  // In this method is used for open the edit pop-up ,and also binding the data
  edit(data) {
    this.display2 = true;
    this.message = '';
    this.vendorId = data.venId;
    this.gridRoeData = data;
    this.vendorEditForm.controls.vendorEditName.patchValue(data.vName);
    this.vendorEditForm.controls.editDiscount.patchValue(data.discount);
    this.vendorEditForm.controls.editDays.patchValue(data.daysDelay);
    this.vendorEditForm.controls.vendorEditsatutsTier.patchValue(
      this.status1.filter((x) => x.value === data.status)[0]
    );
    this.vendorGrid();
    this.vendorEditForm.controls.vendorEditTier.patchValue(
      this.gridData.filter((x) => x.tierName === data.tierName)[0]
    );
  }
  // It is used check the selected tier already existed or not in update pop-up
  getVendorTierExist(data) {
    this.vendorAddForm.controls.discount.patchValue(data.discount);
    this.vendorAddForm.controls.days.patchValue(data.daysDelay);
  }
  // It is used for based on the selected tier get the discount ,no.of.days delay and also binding that data
  vendorSelect() {
    const data = {
      customerId: this.custoID,
      tiersId: this.vendorAddForm.controls.vendorAddTier.value.id,
    };
  }
  getTiergridData() {
    this.loading = true;
    this.accDetails = JSON.parse(this.apisService.decodeData());
    this.apisService.getApi(environment.tiers + '/' + this.accDetails.accId).subscribe((res) => {
        this.gridData = res.data;
        this.loading = false;
      });
  }
  // It is used for updating the Update Vendor Tiers, keep the validations
  Update() {
    this.loading = true;

    if (this.vendorEditForm.invalid) {
      this.vendorEditForm.controls.vendorEditsatutsTier.markAsTouched();
    } else {
      const data = {
        id: this.vendorEditForm.controls.vendorEditTier.value.id,
        // tslint:disable-next-line: max-line-length
        status: this.vendorEditForm.controls.vendorEditsatutsTier.value === null || this.vendorEditForm.controls.vendorEditsatutsTier.value === '' ? 'Active' : this.vendorEditForm.controls.vendorEditsatutsTier.value.value,
        vendorId: this.vendorId,
        daysDelay: this.vendorEditForm.controls.editDays.value,
        discount: this.vendorEditForm.controls.editDiscount.value,
      };
      this.apisService.putApi(environment.getUpdateVendorTier, data).subscribe((res) => {
          this.saveData = res;
          if (this.saveData.hasOwnProperty('success') === true) {
            this.loading = false;
            this.display2 = false;
            this.messageService.add({  key: 'br', severity: '', life: 3000, detail: this.saveData.message  });
            this.vendorGrid();
          } else if (this.saveData.hasOwnProperty('failed') === true) {
            this.loading = false;
            this.display2 = true;
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.saveData.failed });
          } else {
            this.loading = false;
            this.display2 = true;
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.saveData.error });
          }
          this.vendorSaveData = res.data;
        });
    }
  }
  // It is used for the reset the form values
  resetData() {
    this.vendorTiersForm.reset();
    this.vendorGrid();
  }
  goto() {
    this.router.navigate(['/tiers']);
  }
}
