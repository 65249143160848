<form [formGroup]="serachtimesheet">
    <p-toast position="bottom-right" key="br"></p-toast>
    <p-card>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3" *ngIf="this.roles === 9">
                <label class="inputfields">Candidate Name</label>
                <p-dropdown placeholder="Select Candidate Name"
                [options]="candidatesnames" optionLabel="canName" [showClear]="true" (keyup.enter)="griddata()"
                    formControlName="candidateName">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3" *ngIf="this.roles === 9">
                <label class="inputfields">Customer Name</label>
                <p-dropdown placeholder="Select Customer Name"
                [options]="customernames" optionLabel="cusName" [showClear]="true" (keyup.enter)="griddata()"
                    formControlName="customerName">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Year</label>
                <p-dropdown placeholder="Select Year" (onChange)="getweekdate()"
                [options]="years" optionLabel="label" [showClear]="true" (keyup.enter)="griddata()"
                    formControlName="searchyear">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Month</label>
                <p-dropdown placeholder="Select Month" (onChange)="getweekdate()"
                [options]="months"  optionLabel="month" [showClear]="true" (keyup.enter)="griddata()"
                    formControlName="searchmonth">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3" *ngIf="this.roles !== 9">
                <label class="inputfields">Week</label>
                <p-dropdown placeholder="Select Week" [disabled]="btndisable"  [options]="weekendday" optionLabel="name" [showClear]="true" (keyup.enter)="griddata()"
                    formControlName="searchweek">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3" *ngIf="this.roles !== 9">
                <label class="inputfields">Status</label>
                <p-dropdown placeholder="Select Status" [options]="visibleStatus" optionLabel="label" [showClear]="true" (keyup.enter)="griddata()"
                    formControlName="searchstatus">
                </p-dropdown>
            </div>
        </div>
        <br>
        <div class="btnsCSS1 ">
            <button *ngIf="addBtn" pButton type="button" icon='pi pi-plus' label="Add" class="addhBtn"
                (click)="addtimesheet()"></button>
            <button pButton type="button" icon='pi pi-refresh' label="Reset" class="addhBtn" (click)="resetTimeSheet()"></button>
            <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn" (click)="griddata()"></button>
        </div>
        <br>
        <br>
    </p-card>
    <br>
    <p-card class="pgpos">
        <div *ngIf="table">
            <p-table [columns]="cols" class="tablescroll" [value]="timesheetgrid" [columns]="cols" dataKey="edit"
                [autoLayout]="true" selectionMode="single" [responsive]="true" sortMode="multiple">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th id="gridHeader" *ngFor="let col of columns let i=index"
                            (click)="iconToggle[i] = !iconToggle[i]" [ngStyle]="{'width': col.width }"
                            [pSortableColumn]="col.field">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-columns="columns" let-rowIndex="rowIndex">
                    <tr *ngIf="employee" (click)="rowedit(data)" style="cursor: pointer;">
                        <td class="textCenter"> <span class="p-column-title">
                                Start Date</span>{{data.dateStart }}</td>
                        <td class="textCenter"><span class="p-column-title">
                                End Date</span>{{data.dateEnd }}</td>
                        <td class="textCenter"><span class="p-column-title">
                                Total hours</span>{{data.totalHrs}}</td>
                        <td class="textCenter"><span class="p-column-title">
                                Submitted Date</span>{{data.submittedDate  }}</td>
                        <td>
                            <p-badge   [value]="statuslist[data.lkStatus]"
                                [ngClass]="data.lkStatus ==='A'?'green': data.lkStatus === 'R'?'red': data.lkStatus ==='D' ? 'evalution' : 'blue'">
                            </p-badge>
                        </td>
                        <td class="textCenter"><span class="p-column-title">
                                Approved Date</span>{{data.approvedDate  }}</td>
                    </tr>
                    <tr *ngIf="approve"  (click)="rowedit(data)" style="cursor: pointer;">
                        <td class="textCenter"> <span class="p-column-title">
                            Submitted By</span>{{data.submittedBy }}</td>
                        <td class="textCenter"><span class="p-column-title">
                            Start Date</span>{{data.dateStart }}</td>
                        <td class="textCenter"><span class="p-column-title">
                            End Date</span>{{data.dateEnd}}</td>
                        <td class="textCenter"><span class="p-column-title">
                            Submitted Date</span>{{data.submittedDate  }}</td>
                            <td class="textCenter"><span class="p-column-title">
                                Billable Hrs</span>{{data.totalHrs  }}</td>
                        <td>
                            <p-badge   [value]="statuslist[data.lkStatus]"
                                [ngClass]="data.lkStatus ==='A'?'green': data.lkStatus === 'R'?'red': data.lkStatus ==='D' ? 'evalution' : 'blue'">
                            </p-badge>
                        </td>
                    </tr>
                    <tr *ngIf="this.roles === 9"  (click)="rowedit(data)" style="cursor: pointer;">
                        <td class="textCenter"> <span class="p-column-title">
                            Submitted By</span>{{data.cusName }}</td>
                            <td class="textCenter"> <span class="p-column-title">
                                Submitted By</span>{{data.canName }}</td>
                        <td class="textCenter"><span class="p-column-title">
                            Start Date</span>{{data.dateStart }}</td>
                        <td class="textCenter"><span class="p-column-title">
                            End Date</span>{{data.dateEnd}}</td>
                            <td class="textCenter"><span class="p-column-title">
                                Billable Hrs</span>{{data.totalHrs  }}</td>
                        <td>
                            <p-badge   [value]="statuslist[data.lkStatus]"
                                [ngClass]="data.lkStatus ==='A'?'green': data.lkStatus === 'R'?'red': data.lkStatus ==='D' ? 'evalution' : 'blue'">
                            </p-badge>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                    <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                    <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                        (onPageChange)="paginate($event)"></p-paginator>
                </div>
            </div>
        </div>
        <p-card *ngIf="records">
            <div class="norecordImgsize">
                <div *ngIf="records" class="aligncenter">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                    <br><em>Sorry, we are not able to find any timesheets</em><br>
                </div>
            </div>
        </p-card>
    </p-card>
</form>
<p-dialog style="text-align: center;" class="box" header=" " [(visible)]="display" [modal]="true"
    [contentStyle]="{'overflow': 'visible'}" [formGroup]="calender" [style]="{width: '30%','height':'20%'}">
    <h4>You are about to add time sheet for the week</h4>
    <h5>({{firstday | date:'MMMM d, y'}} - {{lastday | date:'MMMM d, y'}})</h5>
    <h5>if you wish to change the date, click here <p-calendar [inputStyle]="{'width':'0px', 'display':'none'}"
            formControlName="selecteddate" [maxDate]="dateTime" (onSelect)="fetchdate()" [showIcon]="true"
            icon="fa fa-calendar pointer"></p-calendar>
    </h5>
    <button pButton type="button" label="Proceed" icon="pi pi-arrow-right" (click)="entertimesheet()"></button>
</p-dialog>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>