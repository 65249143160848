import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {
  constructor(public router: Router) { }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const currentUser = JSON.parse(sessionStorage.getItem('loginData'));
    if (currentUser !== null) {
      const currentUserToken = JSON.parse(sessionStorage.getItem('loginData'))
        .data.token;
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUserToken}`,
        },
      });
    }
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        sessionStorage.clear();
        this.router.navigateByUrl('login');
      }
      const error = err.error.message || err.statusText;
      return throwError(error);
    }));
  }
}
