<p-toast position="bottom-right" key="br"></p-toast>
<p-panel>
    <p-header>
        <strong>Group-Name : </strong> <span style="color: #0D416B;"> <strong>{{this.groupname}}</strong></span>
        <em class="pi pi-arrow-left panelBackRoute" (click)="goto()"></em>
    </p-header>
    <div *ngIf="adminrole">
        <div class="btnsCSS1 btnspos">
            <button pButton type="button" icon='pi pi-plus' label="Add" class="addhBtn" (click)="addData()"></button>
        </div>
        <br>
        <br>
    </div>
</p-panel>
<br>
<p-card class="pgpos">
    <div *ngIf="table">
        <p-table [responsive]="true" [columns]="cols" [value]="MembersData" selectionMode="single" dataKey="edit"
            [autoLayout]="true" sortMode="multiple">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
                <tr>
                    <td class="textCenter"><span class="p-column-title">
                            Member Name</span>{{data.name}}</td>
                    <td class="textCenter"><span class="p-column-title">
                            Member Type</span>{{this.memberlist[data.member_type]}}</td>
                    <td><span class="ui-column-title"></span>
                        <p-badge [value]="this.statuslist[data.lkStatus]"
                            [ngClass]="data.lkStatus ==='A'?'green': 'red'">
                        </p-badge>
                    </td>
                    <td class="textCenter"><span class="p-column-title">
                            Action</span>
                        <a class="pi pi-ellipsis-v pointer"
                            (click)="downloadActionPopup.toggle($event); toggle($event,data)"></a>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid ui-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalrecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10 ">
                <p-paginator [rows]="10" [totalRecords]="totalrecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png"
                    class="norecordimg">
                <br><em>Sorry, we are not able to find any group members</em><br>
            </div>
        </div>
    </p-card>
</p-card>
<div *ngIf="!loading; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-dialog class="dialogBox" header="Add Member" [(visible)]="display" [contentStyle]="{'overflow': 'visible'}"
    [modal]="true" [baseZIndex]="1" [draggable]="false" [style]="{width: '20%'}" [resizable]="false">
    <form [formGroup]='addmemberform'>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12  p-lg-12">
                <label class="inputfields">Member Type<span class="mandatoryColor">*</span></label>
                <p-dropdown [filter]="true" placeholder="Select Member Type" [options]="type" optionLabel="name" [showClear]="true"
                    (onChange)="membertype($event.value.id)" formControlName="membertype">
                </p-dropdown>
                <span class="validationtest"
                    *ngIf="this.addmemberform.controls.membertype.invalid && this.addmemberform.controls.membertype.touched">Please
                    select member type</span>
            </div>
            <div *ngIf="member" class="p-col-12 p-md-12 p-sm-12  p-lg-12">
                <label class="inputfields">Member Name<span class="mandatoryColor">*</span></label>
                <p-dropdown [filter]="true" placeholder="Select Member Name" optionLabel="name" [options]="members" [showClear]="true"
                    formControlName="membername">
                </p-dropdown>
                <span class="validationtest"
                    *ngIf="this.addmemberform.controls.membername.invalid && this.addmemberform.controls.membername.touched">Please
                    select member name</span>
            </div>
            <div *ngIf="group" class="p-col-12 p-md-12 p-sm-12  p-lg-12">
                <label class="inputfields">Group Name<span class="mandatoryColor">*</span></label>
                <p-dropdown [filter]="true" placeholder="Select Group Name" [options]="groups" optionLabel="name" [showClear]="true"
                    formControlName="membername">
                </p-dropdown>
                <span class="validationtest"
                    *ngIf="this.addmemberform.controls.membername.invalid && this.addmemberform.controls.membername.touched">Please
                    select group name</span>
            </div>
        </div>
        <div class="btnsCSS1">
            <div class="btnsCSS1">
                <button pButton type="button" icon='pi pi-save' class="addhBtn" label="Save" (click)="addmember()"
                    [disabled]="disable"></button>
            </div>
        </div>
    </form>
</p-dialog>
<p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="items"></p-menu>
<p-dialog class="dialogBox" header="Edit Member" [(visible)]="display1" [contentStyle]="{'overflow': 'visible'}"
    [modal]="true" [baseZIndex]="1" [draggable]="false" [style]="{width: '20%'}" [resizable]="false">
    <form [formGroup]='editform'>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12  p-lg-12">
                <label class="inputfields">Member Type</label><span class="mandatoryColor">*</span>
                <p-dropdown placeholder="Select Member Type" [options]="type" optionLabel="name" [disabled]="true"
                    [showClear]="true" (onChange)="membertype($event.value.id)" formControlName="emembertype">
                </p-dropdown>
                <span class="validationtest"
                    *ngIf="this.editform.controls.emembertype.invalid && this.editform.controls.emembertype.touched">Please
                    select member type</span>
            </div>
            <div *ngIf="group" class="p-col-12 p-md-12 p-sm-12  p-lg-12">
                <label class="inputfields">Group Name<span class="mandatoryColor">*</span></label>
                <p-dropdown placeholder="Select Group Name" [options]="allgroups" optionLabel="name" [disabled]="true"
                    [showClear]="true" formControlName="emembername">
                </p-dropdown>
                <span class="validationtest"
                    *ngIf="this.editform.controls.emembername.invalid && this.editform.controls.emembername.touched">Please
                    select group name</span>
            </div>
            <div *ngIf="member" class="p-col-12 p-md-12 p-sm-12  p-lg-12">
                <label class="inputfields">Member Name<span class="mandatoryColor">*</span></label>
                <p-dropdown placeholder="Select Member Name" [options]="allmembers" optionLabel="name" [disabled]="true"
                    [showClear]="true" formControlName="emembername">
                </p-dropdown>
                <span class="validationtest"
                    *ngIf="this.editform.controls.emembername.invalid && this.editform.controls.emembername.touched">Please
                    select member name</span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Status</label><span class="mandatoryColor">*</span>
                <p-dropdown [options]="status" [showClear]="true" placeholder="Select Status" optionLabel="name" formControlName="Status">
                </p-dropdown>
                <span class="validationtest"
                    *ngIf="this.editform.controls.Status.invalid && this.editform.controls.Status.touched">Please
                    select status</span>
            </div>
        </div>
        <div class="btnsCSS1">
            <div class="btnsCSS1">
                <button pButton type="button" icon='pi pi-save' class="addhBtn" label="Update" (click)=" updatemember()"
                    [disabled]="disable"></button>
            </div>
        </div>
    </form>
</p-dialog>