import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ApisService } from 'src/app/services/apis.service';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-copy-requirement',
  templateUrl: './copy-requirement.component.html',
  styleUrls: ['./copy-requirement.component.scss'],
  providers: [DatePipe, MessageService]
})
export class CopyRequirementComponent implements OnInit {
  reqData: any;
  loader = false;
  copyRequirementsForm: FormGroup;
  userDetails: any;
  userAccId: any;
  reqId: any;
  positionData: any;
  jobTitleData: any;
  departmentList: any = [];
  locationList: any;
  statusList: any;
  exprienceList: any;
  hireTypeList: any;
  billingList: any;
  preferredSkills: any = [];
  endDisplay: boolean;
  copyRequirementsEndDate: string;
  copyRequirementsStartDate: string;
  addRequirementData: any;
  selectedPrimaryskills: any = [];
  PreferredSkills: any = [];
  filteredData: any;
  filterId: [];
  preFilteredData: any;
  preFilterId: [];
  filterIdString: any;
  preFilterIdString: any;
  preferencList: any = [];
  currencycode: any[];
  currencyValue: any;
  currencysymbl: any;
  coping: boolean;
  disable: boolean;
  otherskills = false;
  patchlocationString: string;
  editLocationdata: any;
  shwmsg: boolean;
  showmsg: string;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private apiService: ApisService, private route: ActivatedRoute, private messageService: MessageService, private datepipe: DatePipe, private router: Router, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Requirements', routerLink: '/project-requirements' },
      { label: 'Requirement Copy', routerLink: '/copy-requirement/:id' },
    ]);
  }
  ngOnInit(): void {
    this.reqId = this.route.snapshot.paramMap.get('id');
    this.copyRequirementsForm = this.fb.group({
      positionTitle: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]+'), Validators.maxLength(50)]],
      positionCategory: ['', [Validators.required]],
      jobRole: ['', [Validators.required]],
      department: ['', [Validators.required]],
      minRate: [''],
      maxRate: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      positions: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(11)]],
      workLocation: ['', [Validators.required]],
      experience: ['', [Validators.required]],
      hireType: ['', [Validators.required]],
      approval: [''],
      startDate: ['', [Validators.required]],
      endDate: [''],
      billingType: ['', [Validators.required]],
      duration: ['', [Validators.pattern('^[0-9]*$')]],
      perWeek: ['', [Validators.pattern('^[0-9]*$'), Validators.maxLength(11)]],
      requirementSkillSet: ['', [Validators.required]],
      requirementPreferredSkillSet: ['', [Validators.required]],
      description: ['', [Validators.required, Validators.maxLength(2000)]],
      responsibilities: ['', [Validators.required, Validators.maxLength(2000)]],
      qualification: ['', [Validators.required, Validators.maxLength(500)]],
      comments: ['', [Validators.maxLength(500)]],
      otherskills: ['']
    });
    this.userDetails = JSON.parse(this.apiService.decodeData());
    this.preferencList = this.userDetails.preferencesList;
    if (this.preferencList === null || this.preferencList === undefined || this.preferencList === '') {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
    }
    this.userAccId = this.userDetails.accId;
    this.getPositionCatagory();
    this.getDepartments();
    this.getWorkLocation();
    this.getExperience();
    this.getHireTypeData();
    this.getBillingDropdown();
    this.reqSkillSet();
    this.getStatus();
    this.editReqData();
    this.copyRequirementsForm.get('endDate').disable();
  }
  goto() {
    this.router.navigate(['/project-requirements']);
  }
  getPositionCatagory() {
    this.apiService.getApi(environment.positionCat + '/' + this.userAccId).subscribe((res) => {
      this.positionData = res.data;
    });
  }
  getDepartments() {
    this.apiService.getApi(environment.departments + '/' + this.userAccId).subscribe((res) => {
      this.departmentList = res.data;
    });
  }
  getWorkLocation() {
    this.apiService.getApi(environment.location + '/' + this.userAccId).subscribe((res) => {
      this.locationList = res.data;
    });
  }
  getStatus() {
    this.apiService.getApi(environment.lookupsData + 'RELS').subscribe((res) => {
      this.statusList = res.data;
    });
  }
  getExperience() {
    this.apiService.getApi(environment.lookupsData + 'RELYE').subscribe((res: any) => {
      this.exprienceList = res.data;
    });
  }
  getHireTypeData() {
    this.apiService.getApi(environment.lookupsData + 'RELHT').subscribe((res) => {
      this.hireTypeList = res.data;
    });
  }
  getBillingDropdown() {
    this.apiService.getApi(environment.lookupsData + 'RELBT').subscribe((res) => {
      this.billingList = res.data;
    });
  }
  reqSkillSet() {
    this.apiService.getApi(environment.lookupsData + 'RELRS').subscribe((res) => {
      this.PreferredSkills = res.data;
    });
  }
  typeData(event) {
    if (event === 'C' || event === 'H') {
      this.endDisplay = true;
      this.copyRequirementsForm.get('endDate').enable();
    } else {
      this.endDisplay = false;
      this.copyRequirementsForm.get('endDate').disable();
    }
  }
  getRolesBasedonCategory(event) {
    this.apiService.getApi(environment.jobTitle + this.userAccId + '/' + event).subscribe((res) => {
      this.jobTitleData = res.data;
    });
  }
  getMinrate(event) {
    const availbleRate = this.jobTitleData.find(x => x.id === event);
    this.copyRequirementsForm.controls.minRate.patchValue(availbleRate.minrate);
    this.copyRequirementsForm.controls.maxRate.patchValue(availbleRate.maxrate);
  }
  editReqData() {
    this.loader = true;
    this.apiService.getApi(environment.requirementEdit + '/' + this.reqId).subscribe((res) => {
      this.reqData = res.data;
      this.getRolesBasedonCategory(this.reqData.positionCategory);
      this.typeData(this.reqData.hireType);
      this.copyRequirementsForm.patchValue({
        positionTitle: this.reqData.name,
        positionCategory: this.reqData.positionCategory,
        jobRole: this.reqData.jobTitle.id,
        minRate: this.reqData.minRate,
        maxRate: this.reqData.maxRate,
        positions: this.reqData.noOfPositions,
        // workLocation: this.reqData.location,
        experience: this.reqData.experience,
        hireType: this.reqData.hireType,
        startDate: this.reqData.plannedStartDate,
        endDate: this.reqData.plannedEndDate,
        billingType: this.reqData.billingType,
        duration: this.reqData.duration,
        perWeek: this.reqData.hrsPerWeek,
        approval: this.reqData.empApprovalReqFlag,
        requirementPreferredSkillSet: this.reqData.prefferedSkills.toString(),
        requirementSkillSet: this.reqData.skills.toString(),
        description: this.reqData.description,
        responsibilities: this.reqData.responsibilities,
        qualification: this.reqData.qualification,
        comments: this.reqData.comments,
        // otherskills: this.reqData.otherSkills
      });
      this.copyRequirementsForm.controls.department.patchValue(
        this.departmentList.filter((x) => x.id === this.reqData.departmentId)[0]);
      const selectedPrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.PreferredSkills.length; i++) {
        const array = this.reqData.prefferedSkills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.PreferredSkills[i].code) === array[j]) {
            selectedPrimarySkills.push(this.PreferredSkills[i]);
          }
        }
      }
      this.copyRequirementsForm.controls.requirementPreferredSkillSet.patchValue(selectedPrimarySkills);
      const preferredSkills =
        this.copyRequirementsForm.controls.requirementPreferredSkillSet.value;
      preferredSkills.forEach((element) => {
        this.preferredSkills.push(element.label);
      });
      const selectedPrePrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.PreferredSkills.length; i++) {
        const array = this.reqData.skills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.PreferredSkills[i].code) === array[j]) {
            selectedPrePrimarySkills.push(this.PreferredSkills[i]);
          }
        }
      }
      this.copyRequirementsForm.controls.requirementSkillSet.patchValue(
        selectedPrePrimarySkills
      );
      selectedPrePrimarySkills.forEach((element) => {
        this.selectedPrimaryskills.push(element.label);
      });
      this.copyRequirementsForm.controls.workLocation.patchValue(this.locationList.filter((x) => x.locId === this.reqData.location)[0]);
      this.editLocationdata = this.locationList.filter((x) => x.locId === this.reqData.location)[0];
      this.patchlocationString = this.editLocationdata?.name + '\n' +
        this.editLocationdata?.address1 + ',' + this.editLocationdata?.city + ',' +
        this.editLocationdata?.state + ',' + this.editLocationdata?.country;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < selectedPrePrimarySkills.length; i++) {
        if (selectedPrePrimarySkills[i].code === '25') {
          this.otherskills = true;
          this.copyRequirementsForm.controls.otherskills.patchValue(this.reqData.otherSkills);

        }
        else {
          this.onBlur('');
        }
      }



      setTimeout(() => {
        this.loader = false;
      }, 2000);
    });
  }
  copyRequirement() {
    const skill = this.copyRequirementsForm.get('requirementSkillSet').value;
    this.filteredData = skill.map((x) => x.label);
    this.filterId = skill.map((x) => x.code);
    this.filterIdString = this.filterId;
    const preSkills = this.copyRequirementsForm.get('requirementPreferredSkillSet').value;
    this.preFilteredData = preSkills.map((x) => x.label);
    this.preFilterId = preSkills.map((x) => x.code);
    this.preFilterIdString = this.preFilterId;
    if (this.copyRequirementsForm.invalid) {
      const controls = this.copyRequirementsForm.controls;
      for (const name in controls) { if (controls[name].invalid) { } }
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      const addreqfrmDate = this.copyRequirementsForm.controls.startDate.value;
      const addReqFrmDate = new Date(Date.parse(addreqfrmDate));
      this.copyRequirementsStartDate = this.datepipe.transform(addReqFrmDate, 'MM-dd-yyyy');
      const addreqEndDate = this.copyRequirementsForm.controls.endDate.value;
      if (addreqEndDate) {
        const addReqEndDate = new Date(Date.parse(addreqEndDate));
        this.copyRequirementsEndDate = this.datepipe.transform(addReqEndDate, 'MM-dd-yyyy');
      }
      else {
        this.copyRequirementsEndDate = '';
      }
      const payload = {
        // tslint:disable-next-line:max-line-length
        accDeptId: this.copyRequirementsForm.controls.department.value === null || this.copyRequirementsForm.controls.department.value.id === undefined || this.copyRequirementsForm.controls.department.value === '' ? '' : this.copyRequirementsForm.controls.department.value.id,
        accId: 1,
        billingType: this.copyRequirementsForm.controls.billingType.value,
        comment: this.copyRequirementsForm.controls.comments.value,
        createdBy: 1,
        createdByAccId: 1,
        hireType: this.copyRequirementsForm.controls.hireType.value,
        hrsPerWeek: Number(this.copyRequirementsForm.controls.perWeek.value),
        locationId: this.copyRequirementsForm.controls.workLocation.value.locId,
        modifiedBy: Number(this.userDetails.userId),
        ownerId: this.userAccId,
        preSkillSet: this.preFilterIdString,
        reqCategory: 0,
        reqComments: 'string',
        reqCreatedBy: Number(this.userDetails.userId),
        reqDuration: Number(this.copyRequirementsForm.controls.duration.value),
        reqEndDate: this.copyRequirementsEndDate,
        reqJobdesc: this.copyRequirementsForm.controls.description.value,
        jobTitle: this.copyRequirementsForm.controls.jobRole.value,
        reqMaxRate: this.copyRequirementsForm.controls.maxRate.value,
        reqMinRate: this.copyRequirementsForm.controls.minRate.value,
        reqName: this.copyRequirementsForm.controls.positionTitle.value,
        reqNoOfResources: Number(this.copyRequirementsForm.controls.positions.value),
        reqQualification: this.copyRequirementsForm.controls.qualification.value,
        reqResponsibility: this.copyRequirementsForm.controls.responsibilities.value,
        reqSkillSet: this.filterIdString,
        reqStartDate: this.copyRequirementsStartDate,
        positionCategory: this.copyRequirementsForm.controls.positionCategory.value,
        empApprovalReqFlag: this.copyRequirementsForm.controls.approval.value,
        yearExperience: this.copyRequirementsForm.controls.experience.value,
        primaryRole: this.userDetails.roles[0].id,
        // tslint:disable-next-line: max-line-length
        // otherSkills: this.copyRequirementsForm.controls.otherskills.value === null || this.copyRequirementsForm.controls.otherskills.value === '' || this.copyRequirementsForm.controls.otherskills.value === undefined ? '' :  this.copyRequirementsForm.controls.otherskills.value
        otherSkills: this.otherskills === true ? this.copyRequirementsForm.controls.otherskills.value : '',
      };
      this.disable = true;
      this.apiService.postApi(environment.addReq, payload).subscribe((res) => {
        this.addRequirementData = res;
        if (res.success === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
          setTimeout(() => {
            this.router.navigate(['/project-requirements']);
          }, 2000);
          this.disable = false;
          this.copyRequirementsForm.reset();
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        }
      });
    }
  }
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
  // skillsetOthers(data, event) {
  //   console.log("skillsetOthersevents", event);
  //   if(event.length === 0){
  //     this.selectedPrimaryskills=[];
  //   }

  //   this.copyRequirementsForm.controls.otherskills.reset();
  //   this.copyRequirementsForm.controls.otherskills.clearValidators();
  //   // tslint:disable-next-line:prefer-for-of
  //   this.otherskills = false;
  //   if (data) {
  //     this.otherskills = false;
  //   }
  //   // tslint:disable-next-line:prefer-for-of
  //   for (let i = 0; i < data.length; i++) {
  //     if (data[i].code === '25') {
  //       this.otherskills = true;
  //     }
  //   }
  //   if (this.otherskills === true) {
  //     this.copyRequirementsForm.controls.otherskills.setValidators([Validators.required, Validators.maxLength(300)]);
  //   }

  //   if (this.otherskills === false) {
  //     this.copyRequirementsForm.controls.otherskills.reset();
  //     this.copyRequirementsForm.controls.otherskills.clearValidators();
  //   }
  // }
  skillsetOthers(data) {
    this.copyRequirementsForm.controls.otherskills.reset();
    this.copyRequirementsForm.controls.otherskills.clearValidators();
    // tslint:disable-next-line:prefer-for-of
    this.otherskills = false;
    if (data) {
      this.otherskills = false;
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < data.length; i++) {
      if (data[i].code === '25') {
        this.otherskills = true;
      }
    }

    if (this.otherskills === true) {
      this.copyRequirementsForm.controls.otherskills.setValidators([Validators.required, Validators.maxLength(300)]);
    }

    if (this.otherskills === false) {
      this.copyRequirementsForm.controls.otherskills.reset();
      this.copyRequirementsForm.controls.otherskills.clearValidators();
    }
  }
  saveLocation(ev) {
    const data = ev.value;
    this.patchlocationString = data.name + '\n' + data.address1 + ',' + data.city + ',' + data.state + ',' + data.country;
  }
  getduration() {
    if (this.copyRequirementsForm.controls.duration.value > 24) {
      this.shwmsg = true;
      this.copyRequirementsForm.controls.duration.reset();
      this.showmsg = 'Duration should be less than 24 months';
      setTimeout(() => {
        this.showmsg = '';
        this.shwmsg = false;
        this.copyRequirementsForm.controls.duration.patchValue(24);
      }, 3000);
    } else {
    }
  }
  setRequired() {
    return [Validators.required, Validators.maxLength(300)];
  }
  onBlur(data): void {
    if (data === '') {
      this.copyRequirementsForm.controls.otherskills.setValidators(this.setRequired());
    }
  }
  resetreq() {
    this.copyRequirementsForm.reset();
    this.preferredSkills = '';
    this.selectedPrimaryskills = '';
  }
  resetskills(data, event) {
    if (event.length === 0) {
      this.preferredSkills = [];
    }

  }
}
