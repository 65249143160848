import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import Swal from 'sweetalert2';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
@Component({
  selector: 'app-edit-requirements',
  templateUrl: './edit-requirements.component.html',
  styleUrls: ['./edit-requirements.component.scss'],
  providers: [MessageService, DatePipe],
})
export class EditRequirementsComponent implements OnInit {
  editRequirementsForm: FormGroup;
  userDetails: any;
  userAccId: any;
  endDisplay: boolean;
  skillsList: any = [];
  billingList: any;
  hireTypeList: any;
  exprienceList: any;
  locationList: any;
  departmentList: any;
  jobTitleData: any;
  positionData: any;
  reqData: any;
  loader = false;
  statusList: any[];
  status: any[];
  reqId: any;
  rows: any = 10;
  page: any = 1;
  table = true;
  records: boolean;
  pageCount: any;
  editRequirementsFromDate: any;
  editRequirementsEndDate: any;
  editRequirementData: any;
  receivedCandidates: FormGroup;
  receivedCandidateForm: FormGroup;
  calenderForm: FormGroup;
  selectedPrimarySkills: any = [];
  primarySkills: any = [];
  cols = [
    { field: 'vendorName', header: 'Vendor Name', sno: 1 },
    { field: 'consultantName', header: 'Candidate Name', sno: 2 },
    { field: 'submittedDate', header: 'Received Date', sno: 3 },
    { field: 'skills', header: ' Skills', sno: 4 },
    { field: 'skills', header: ' Skills', sno: 5 },
    { field: 'profileState', header: 'Status', sno: 6 },
    { field: 'payRate', header: 'Bill Rate', sno: 7 },
    { field: '', header: 'Action', sno: 8 }
  ];
  cols3 = [
    { dataKey: 'vendorName', header: 'Vendor Name' },
    { dataKey: 'consultantName', header: 'Candidate Name' },
    { dataKey: 'submittedDate', header: 'Received Date' },
    { dataKey: 'skills', header: 'Skills' },
    { dataKey: 'status', header: 'Status' },
    { dataKey: 'billRate', header: 'Bill Rate' },
  ];
  maxrate: any;
  reqName: any;
  recCandidates: boolean;
  recCandidateRecords: any;
  recCandidateGrid: any;
  submitBtn: boolean;
  roleId: any;
  gridData: any;
  subProRecords: any;
  array: any;
  consStatusData: any;
  statusList1 = {};
  rowData: any;
  idToDelete: any;
  conId: any;
  consultantName: any;
  items: ({
    label: string; icon: string;
    command: (event: any, i: any) => void;
  } | {
    label: string; icon: string;
    command?: undefined;
  })[];
  downloadOptions: {
    label: string;
    icon: string;
    command: (event: any, i: any) => void;
  }[];
  isUploading1 = false;
  skillset = {};
  displaySkills = false;
  reqSkillsValue: any;
  filedata: any;
  resumeRes: any;
  profileID: any;
  userId: any;
  reqId1: any;
  filteredData: any;
  filterId: any = [];
  preFilteredData: any;
  preFilterId: any[];
  filterIdString: any;
  preFilterIdString: any;
  acctDetails: any;
  reqUpdateData: any;
  preferredSkills: any = [];
  validStatus = false;
  EditRequirementsFromDate: string;
  EditRequirementsEndDate: string;
  candidatename: any;
  selected: boolean;
  iconToggle: any = [];
  planedendDate: any;
  profileId: any;
  currencycode: any[];
  preferencList: any = [];
  currencyValue: any;
  currencysymbl: any;
  disable: boolean;
  hide: boolean;
  statusList11: any = [];
  acctdetails: any;
  skillset1 = {};
  skillsList12: any = [];
  selectedPrimaryskills: any = [];
  reqid: any;
  rejectpopup: boolean;
  rejectopention: any = [];
  rejectValue: any;
  lkRejectedType: any;
  candidaterejectpopup: boolean;
  rejectReasonData: any = [];
  minDate: Date;
  startCurDate: Date;
  disablebtn: boolean;
  usrroleid: any;
  otherskills = false;
  patchlocationString: string;
  editLocationdata: any;
  shwmsg: boolean;
  showmsg: string;
  displayOtherDetails: boolean;
  getotherDetails: FormGroup;
  getotherdetailsdata: any;
  createdbyaccID: any;
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.gridTable();
    }
  }
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private messageService: MessageService, private apiService: ApisService, private datepipe: DatePipe, private router: Router, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Requirements', routerLink: '/project-requirements' },
      { label: 'Requirement Edit', routerLink: '/project-edit-requirement' },
    ]);
  }
  ngOnInit(): void {
    this.minDate = new Date();
    this.minDate.setMonth(new Date().getMonth());
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.createdbyaccID = routeParams.params.createdbyaccID;
    this.minDate.setFullYear(new Date().getFullYear());
    this.startCurDate = this.minDate;
    this.editRequirementsForm = this.fb.group({
      positionTitle: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]+'), Validators.maxLength(50)]],
      positionCategory: ['', [Validators.required]],
      jobRole: ['', [Validators.required]],
      department: ['', [Validators.required]],
      minRate: ['', [Validators.pattern('^[0-9]*$')]],
      maxRate: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      positions: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(11)]],
      workLocation: ['', [Validators.required]],
      experience: ['', [Validators.required]],
      hireType: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      billingType: ['', [Validators.required]],
      duration: ['', [Validators.pattern('^[0-9]*$')]],
      perWeek: ['', [Validators.pattern('^[0-9]*$')]],
      status: ['', [Validators.required]],
      preferredSkills: ['', [Validators.required]],
      requirementsSkillSet: ['', [Validators.required]],
      description: ['', [Validators.required, Validators.maxLength(2000)]],
      responsibilities: ['', [Validators.required, Validators.maxLength(2000)]],
      qualification: ['', [Validators.required, Validators.maxLength(500)]],
      comments: ['', Validators.maxLength(500)],
      approval: [''],
      otherskills: ['']
    });
    this.getotherDetails = this.fb.group({
      addressControl: [''],
      addressFormControl: [''],
      Country: [''],
      State: [''],
      cityForm: [''],
      Phone: [''],
      zipForm: [''],
      faxForm: [''],
      mailbox1: [''],
      workSatus: ['']
    });
    this.getotherDetails.disable();
    this.receivedCandidates = this.fb.group({
      fristName: [''],
      lastName: [''],
      email: [''],
      skillSet: [''],
      status: [''],
      onboardDate: [''],
      onboardDate1: ['', [Validators.required]],
      comments: [''],
      reject: [''],
      rejectReason: ['']
    });
    this.receivedCandidateForm = this.fb.group({
      onboardDate1: ['', [Validators.required]],
    });
    this.userDetails = JSON.parse(this.apiService.decodeData());
    this.acctdetails = JSON.parse(this.apiService.decodeData());
    this.preferencList = this.acctdetails.preferencesList;
    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }
    this.apiService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
      this.roleId = this.userDetails.roles[0].id;
    } else {
      this.roleId = this.usrroleid;
    }
    this.userAccId = this.userDetails.accId;
    const userRequirement = JSON.parse(sessionStorage.getItem('requirementData'));
    this.reqId = userRequirement.reqId;
    this.reqName = userRequirement.reqName;
    this.maxrate = userRequirement.maxRate;
    if (userRequirement.reqStatus === 'R') {
      this.editRequirementsForm.disable();
      this.recCandidates = true;
      this.gridTable();
    } else if (userRequirement.reqStatus === 'P' || userRequirement.reqStatus === 'A' || userRequirement.reqStatus === 'J') {
      this.editRequirementsForm.disable();
    }

    else {
      this.recCandidates = false;
    }
    // this.editRequirementsForm.disable();
    this.getPositionCatagory();
    this.getDepartments();
    this.getWorkLocation();
    this.getExperience();
    this.getHireTypeData();
    this.getBillingDropdown();
    this.reqSkillSet();
    this.getConStatus();
    this.editReqData();
    this.getStatus();
    this.editRequirementsForm.get('endDate').disable();
    this.editRequirementsForm.get('status').disable();
  }
  reset() {
    this.receivedCandidates.reset();
    this.gridTable();
  }
  getPositionCatagory() {
    this.apiService.getApi(environment.positionCat + '/' + this.userAccId).subscribe((res) => {
      this.positionData = res.data;
    });
  }
  getRolesBasedonCategory(event) {
    this.apiService.getApi(environment.jobTitle + this.userAccId + '/' + event).subscribe((res) => {
      this.jobTitleData = res.data;
    });
  }
  getMinrate(event) {
    const availbleRate = this.jobTitleData.find(x => x.id === event);
    this.editRequirementsForm.controls.minRate.patchValue(availbleRate.minrate);
    this.editRequirementsForm.controls.maxRate.patchValue(availbleRate.maxrate);
  }
  getDepartments() {
    this.apiService.getApi(environment.departments + '/' + this.userAccId).subscribe((res) => {
      this.departmentList = res.data;
    });
  }
  getWorkLocation() {
    this.apiService.getApi(environment.location + '/' + this.userAccId).subscribe((res) => {
      this.locationList = res.data;
    });
  }
  getStatus() {
    this.apiService.getApi(environment.lookupsData + 'RPLPS').subscribe((res) => {
      this.status = res.data;
      this.status.forEach((element) => {
        this.statusList11[element.code] = element.label;
      });
    });
  }
  getConStatus() {
    this.apiService.getApi(environment.lookupsData + 'RELS').subscribe((res) => {
      this.statusList = res.data;
      this.statusList.forEach((element) => {
        this.statusList1[element.code] = element.label;
      });
    });
  }
  getExperience() {
    this.apiService.getApi(environment.lookupsData + 'RELYE').subscribe((res: any) => {
      this.exprienceList = res.data;
    });
  }
  getHireTypeData() {
    this.apiService.getApi(environment.lookupsData + 'RELHT').subscribe((res) => {
      this.hireTypeList = res.data;
    });
  }
  getBillingDropdown() {
    this.apiService.getApi(environment.lookupsData + 'RELBT').subscribe((res) => {
      this.billingList = res.data;
    });
  }
  reqSkillSet() {
    this.apiService.getApi(environment.skills).subscribe((res) => {
      this.skillsList = res.data;
      this.skillsList.forEach((element) => {
        this.skillset1[element.code] = element.label;
      });
    });
  }
  getSkillSetTable(data) {
    this.array = data;
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.skillsList.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < this.array.length; j++) {
        if (Number(this.skillsList[i].code) === this.array[j]) {
          selectedPrePrimarySkills3.push(
            this.skillsList[i].label
          );
        } else {
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  typeData(event) {
    if (event === 'C' || event === 'H') {
      this.endDisplay = true;
      this.editRequirementsForm.get('endDate').enable();
    } else {
      this.endDisplay = false;
      this.editRequirementsForm.get('endDate').disable();
    }
  }
  editReqData() {
    this.loader = true;
    this.apiService.getApi(environment.requirementEdit + '/' + this.reqId).subscribe((res) => {
      this.loader = false;
      this.reqData = res.data;
      this.getRolesBasedonCategory(this.reqData.positionCategory);
      if (this.reqData.status === 'C') {
        this.submitBtn = true;
      } else {
        this.submitBtn = false;
      }
      this.typeData(this.reqData.hireType);
      this.editRequirementsForm.patchValue({
        positionTitle: this.reqData.name,
        positionCategory: this.reqData.positionCategory,
        jobRole: this.reqData.jobTitle.id,
        department: this.reqData.departmentId,
        minRate: this.reqData.minRate,
        maxRate: this.reqData.maxRate,
        positions: this.reqData.noOfPositions,
        // workLocation: this.reqData.location,
        experience: this.reqData.experience,
        hireType: this.reqData.hireType,
        startDate: this.reqData.plannedStartDate,
        endDate: this.reqData.plannedEndDate,
        billingType: this.reqData.billingType,
        duration: this.reqData.duration,
        perWeek: this.reqData.hrsPerWeek,
        preferredSkills: this.reqData.prefferedSkills.toString(),
        requirementsSkillSet: this.reqData.skills.toString(),
        description: this.reqData.description,
        responsibilities: this.reqData.responsibilities,
        qualification: this.reqData.qualification,
        comments: this.reqData.comments,
        approval: this.reqData.empApprovalReqFlag,
        otherskills: this.reqData.otherSkills,
      });
      this.editRequirementsForm.controls.status.patchValue(this.statusList.filter(x => x.code === this.reqData.status)[0]);
      const selectedPrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.skillsList.length; i++) {
        const array = this.reqData.prefferedSkills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.skillsList[i].code) === array[j]) {
            selectedPrimarySkills.push(this.skillsList[i]);
          }
        }
      }
      const preferredSkills = selectedPrimarySkills;
      preferredSkills.forEach((element) => {
        this.skillsList12.push(element.label);
      });
      const selectedPrePrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.skillsList.length; i++) {
        const array = this.reqData.skills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.skillsList[i].code) === array[j]) {
            selectedPrePrimarySkills.push(this.skillsList[i]);
          }
        }
      }
      const primarySkills = selectedPrePrimarySkills;
      primarySkills.forEach((element) => {
        this.primarySkills.push(element.label);
      });
      this.editRequirementsForm.controls.requirementsSkillSet.patchValue(selectedPrePrimarySkills);
      this.editRequirementsForm.controls.preferredSkills.patchValue(preferredSkills);
      selectedPrePrimarySkills.forEach((element) => {
        this.selectedPrimaryskills.push(element.label);
      });
      this.editRequirementsForm.controls.workLocation.patchValue(
        this.locationList.filter((x) => x.locId === this.reqData.location)[0]);
      this.editLocationdata = this.locationList.filter((x) => x.locId === this.reqData?.location)[0];
      this.patchlocationString = this.editLocationdata?.name + '\n' + this.editLocationdata?.address1 + ',' +
        this.editLocationdata?.city + ',' + this.editLocationdata?.state + ',' + this.editLocationdata?.country;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < selectedPrePrimarySkills.length; i++) {
        if (selectedPrePrimarySkills[i].code === '25') {
          this.otherskills = true;
          this.editRequirementsForm.controls.otherskills.patchValue(this.reqData.otherSkills);

        }
        else {
          this.onBlur('');
        }
      }
    });



    // if (this.reqData.otherSkills !== null || this.reqData.otherSkills !== '' || this.reqData.otherSkills !== undefined){

    //   this.editRequirementsForm.controls.otherskills.setValidators([Validators.required, Validators.maxLength(300)]);

    //   this.otherskills = true;
    //   this.editRequirementsForm.controls.otherskills.patchValue(this.reqData.otherSkills);
    //   }
  }
  reqSkills1(data) {
    this.displaySkills = true;
    this.reqSkillsValue = data;
  }
  updateRequirement() {
    const skill = this.editRequirementsForm.get('requirementsSkillSet').value;
    this.filteredData = skill.map((x) => x.label);
    this.filterId = skill.map((x) => x.code);
    this.filterIdString = this.filterId;
    const preSkills = this.editRequirementsForm.get('preferredSkills').value;
    this.preFilteredData = preSkills.map((x) => x.label);
    this.preFilterId = preSkills.map((x) => x.code);
    this.preFilterIdString = this.preFilterId;
    if (this.editRequirementsForm.invalid) {
      const controls = this.editRequirementsForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      const editreqfrmDate =
        this.editRequirementsForm.controls.startDate.value;
      const addReqFrmDate = new Date(Date.parse(editreqfrmDate));
      this.editRequirementsFromDate = this.datepipe.transform(addReqFrmDate, 'MM-dd-yyyy');
      const editreqEndDate = this.editRequirementsForm.controls.endDate.value;
      if (editreqEndDate) {
        const addReqEndDate = new Date(Date.parse(editreqEndDate));
        this.editRequirementsEndDate = this.datepipe.transform(editreqEndDate, 'MM-dd-yyyy');
      } else {
        this.editRequirementsEndDate = '';
      }
      const parms = {
        id: Number(this.reqId),
        // tslint:disable-next-line:max-line-length
        accDeptId: this.editRequirementsForm.controls.department.value === null ? '' : this.editRequirementsForm.controls.department.value === undefined ? 0 : this.editRequirementsForm.controls.department.value,
        accId: this.userAccId,
        billingType: this.editRequirementsForm.controls.billingType.value,
        comment: this.editRequirementsForm.controls.comments.value,
        createdBy: this.userDetails.userId,
        createdByAccId: 1,
        hireType: this.editRequirementsForm.controls.hireType.value,
        hrsPerWeek: Number(this.editRequirementsForm.controls.perWeek.value),
        locationId: this.editRequirementsForm.controls.workLocation.value.locId,
        modifiedBy: 1,
        preSkillSet: this.preFilterIdString,
        reqCreatedBy: 1,
        reqDuration: Number(this.editRequirementsForm.controls.duration.value),
        reqEndDate: this.editRequirementsEndDate,
        reqJobdesc: this.editRequirementsForm.controls.description.value,
        reqMaxRate: this.editRequirementsForm.controls.maxRate.value,
        reqMinRate: this.editRequirementsForm.controls.minRate.value,
        reqName: this.editRequirementsForm.controls.positionTitle.value,
        reqNoOfResources: Number(this.editRequirementsForm.controls.positions.value),
        reqQualification: this.editRequirementsForm.controls.qualification.value,
        reqResponsibility: this.editRequirementsForm.controls.responsibilities.value,
        reqSkillSet: this.filterIdString,
        reqStartDate: this.editRequirementsFromDate,
        reqType: 'F',
        jobTitle: this.editRequirementsForm.controls.jobRole.value,
        positionCategory: this.editRequirementsForm.controls.positionCategory.value,
        yearExperience: this.editRequirementsForm.controls.experience.value,
        reqStatus: 'P',
        empApprovalReqFlag: this.editRequirementsForm.controls.approval.value,
        // tslint:disable-next-line: max-line-length
        // otherSkills: this.editRequirementsForm.controls.otherskills.value === null || this.editRequirementsForm.controls.otherskills.value === '' || this.editRequirementsForm.controls.otherskills.value === undefined ? '' : this.editRequirementsForm.controls.otherskills.value,
        otherSkills: this.otherskills === true ? this.editRequirementsForm.controls.otherskills.value : '',

      };
      this.disablebtn = true;
      this.loader = true;
      this.apiService.putApi(environment.addReq, parms).subscribe((res) => {
        this.editRequirementData = res;
        if (this.editRequirementData.hasOwnProperty('success') === true) {
          this.editReqData();
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.editRequirementData.message });
          this.loader = false;
        } else if (this.editRequirementData.hasOwnProperty('success') === false) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.editRequirementData.failed });
          this.loader = false;
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.editRequirementData.error });
        }
        setTimeout(() => {
          this.router.navigate(['/project-requirements']);
        }, 2000);
        this.loader = false;
      });
    }
  }
  gridTable() {
    this.loader = true;
    // tslint:disable-next-line:max-line-length
    const searchstatus = this.receivedCandidates.controls.status.value === null || this.receivedCandidates.controls.status.value === '' ? '' : this.receivedCandidates.controls.status.value === undefined ? '' : this.receivedCandidates.controls.status.value.code;
    // tslint:disable-next-line:max-line-length
    const searchEmail = this.receivedCandidates.controls.email.value === null || this.receivedCandidates.controls.email.value === '' ? '' : this.receivedCandidates.controls.email.value === undefined ? '' : this.receivedCandidates.controls.email.value;
    const firstName = this.receivedCandidates.controls.fristName.value === null || this.receivedCandidates.controls.fristName.value === '' ? '' : this.receivedCandidates.controls.fristName.value === undefined ? '' : this.receivedCandidates.controls.fristName.value;
    // tslint:disable-next-line:max-line-length
    const lastname = this.receivedCandidates.controls.lastName.value === null || this.receivedCandidates.controls.lastName.value === '' ? '' : this.receivedCandidates.controls.lastName.value === undefined ? '' : this.receivedCandidates.controls.lastName.value;
    const search1 = this.page + '/' + this.rows + '/' + this.roleId + '?reqId=' + this.reqId;
    const email = '&consEmail=' + searchEmail;
    const status = '&status=' + searchstatus;
    const fname = '&fName=' + firstName;
    const lname = '&lName=' + lastname;
    this.apiService.getApi(environment.getReceivedTable + search1 + email + fname + lname + status).subscribe((res: any) => {
      this.gridData = res.data.records;
      this.loader = false;
      this.subProRecords = res.data.count;
      if (this.subProRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  downLoadsToggle() {
    this.downloadOptions = [
      { label: 'Download XLSX', icon: 'pi pi-download', command: (event, i) => { this.exportExcel(); } },
      { label: 'Download PDF', icon: 'pi pi-download', command: (event, i) => { this.exportPdf(); } },
    ];
  }
  exportExcel() {
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    ws['!cols'] = [ {width : 10}, {width : 20}  , {width : 20} , {width : 10}, {width : 10}, {width : 10}, {width : 10}, {hidden : true} ];
    ws['!rows'] = [{hpt : 30}, {hpt : 30}, ];
    ws['!cols'][3] = { hidden: true };
    ws['!cols'][7] = { hidden: true };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ExcelSheet.xlsx');
  }
  exportPdf() {
    this.gridData.forEach((element) => {
      element.status = this.statusList11[element.profileState];
      element.skills = this.getSkillSetTable(element.skills);
    });
    const doc = new jsPDF('p', 'pt');
    autoTable(doc, {
      headStyles: { fillColor: '#2bb9d5', cellPadding: 5   },
      margin: { left: 17},
      columns: this.cols3,
      body: this.gridData,
      didDrawPage: (dataArg) => {
        doc.text('Requirement Edit', dataArg.settings.margin.left, 30);
      }
    });
    doc.save('Requirement Edit.pdf');
  }
  deleteRow() {
    Swal.fire({
      text: 'Are you sure want to remove ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0D416B',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.apiService.deleteApi(environment.removeRequirement + this.idToDelete).subscribe((res: any) => {
          Swal.fire({ text: res.message, icon: 'success', iconColor: '#1dbb99', confirmButtonColor: '#0D416B', confirmButtonText: 'Ok' });
        });
      }
    });
  }
  toggle(menu, event, rowData) {
    this.rowData = rowData;
    this.idToDelete = rowData.profileId;
    this.conId = rowData.userId;
    this.userId = rowData.userId;
    this.profileId = rowData.profileId;
    this.lkRejectedType = rowData.lkRejectedType;
    this.planedendDate = rowData.plannedEndDate;
    this.consultantName = rowData.consultantName;
    if (this.rowData.profileState === 'A') {
      // tslint:disable-next-line: no-shadowed-variable
      this.items = [{ label: 'Remove', icon: 'pi pi-fw pi-trash', command: (event, i) => { this.deleteRow(); } },
      { label: 'Reject', icon: 'pi pi-fw pi-times-circle' },
      { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); } },
      { label: 'Update Profile', icon: 'pi pi-fw pi-refresh', },
      { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); } },
      { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); } },
      { label: 'Candidate Details', icon: 'pi pi-fw pi-user' },
      { label: 'Update Bill Rate', icon: 'pi pi-fw pi-money-bill' },
      { label: 'Submit Candidate', icon: 'pi pi-fw pi-check-circle' },
      { label: 'Request Approval', icon: 'pi pi-fw pi-external-link' },
      { label: 'Other Details', icon: 'pi pi-info-circle', command: () => { this.getOtherDetails(); }, },

      ];
    }
    else if (this.rowData.profileState === 'J') {
      this.items = [{ label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); } },
      { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); } },
      { label: 'Other Details', icon: 'pi pi-info-circle', command: () => { this.getOtherDetails(); }, },

      ];
    }
    else if (this.rowData.profileState === 'S' || this.rowData.profileState === 'I' || this.rowData.profileState === 'U') {
      this.items = [{ label: 'Reject', icon: 'pi pi-fw pi-times-circle', command: () => { this.reject(); } },
      { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); } },
      { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); } },
      { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); } },
      { label: 'Select Candidate', icon: 'pi pi-check', command: () => { this.selectedDialog(); } },
      { label: 'Other Details', icon: 'pi pi-info-circle', command: () => { this.getOtherDetails(); }, },

      ];
    }
    else if (this.rowData.profileState === 'R') {
      this.items = [{ label: 'Reject Reason', icon: 'pi pi-fw pi-times-circle', command: () => { this.rejectReson(); } },
      { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); } },
      { label: 'Other Details', icon: 'pi pi-info-circle', command: () => { this.getOtherDetails(); }, },

      ];
    }
    else if (this.rowData.profileState === 'C') {
      this.items = [{ label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); } },
      { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); } },
      { label: 'Selected', icon: 'pi pi-check', command: () => { this.selectedDialog(); } },
      { label: 'Other Details', icon: 'pi pi-info-circle', command: () => { this.getOtherDetails(); }, },

      ];
    }
  }
  downloadFileapi() {
    this.filedata = this.rowData;
    this.reqid = this.filedata.resumeId;
    this.isUploading1 = true;
    this.apiService.getApi(environment.downloadedattachment + '/' + this.reqid).subscribe((res) => {
      this.resumeRes = res;
      this.isUploading1 = false;
      window.location.assign(this.resumeRes.downloadUrl);
    });
  }
  addReq() {
    this.filedata = this.rowData;
    this.profileID = this.filedata.profileId;
    this.router.navigate(['/schedule-interview'], {
      queryParams: {
        proId: this.profileID,
        reqId: this.filedata.reqId,
        consultantName: this.consultantName,
        jobTitle: this.reqName,
        conId: this.filedata.conId,
        userId: this.userId,
        createdbyaccID: this.rowData.createdByAccId
      },
    });
  }
  selectedDialog() {
    if (this.rowData.profileState === 'C') {
      // tslint:disable-next-line: max-line-length
      Swal.fire({ text: 'Candidate already selected', icon: 'success', iconColor: '#1dbb99', confirmButtonColor: '#0D416B', confirmButtonText: 'Ok' });
    }
    else {
      this.selected = true;
      this.candidatename = this.rowData.consultantName;
      this.receivedCandidates.controls.onboardDate.patchValue(this.rowData.plannedEndDate);
      this.receivedCandidateForm.reset();
    }
  }
  ratings() {
    this.filedata = this.rowData;
    this.profileID = this.filedata.profileId;
    this.reqId1 = this.filedata.reqId;
    this.router.navigate(['/tech-reviews'], {
      queryParams: {
        proId: this.profileID,
        reqId: this.reqId1,
        conId: this.roleId === 5 ? this.filedata.conId : this.conId,
        reqname: this.reqName,
        vendorId: this.filedata.vendorId,
        userId: this.userId,
      },
    });
  }
  backRouting() {
    this.router.navigate(['/project-requirements']);
  }
  submitCandidate() {
    if (this.receivedCandidateForm.invalid) {
      const controls = this.receivedCandidateForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      const date = this.receivedCandidateForm.controls.onboardDate1.value;
      const onboarddate = new Date(Date.parse(date));
      this.EditRequirementsFromDate = this.datepipe.transform(onboarddate, 'MM-dd-yyyy');
      // tslint:disable-next-line:max-line-length
      this.hide = true;
      // tslint:disable-next-line:max-line-length
      this.apiService.getApi(environment.selectedCandidate + this.profileId + '?plannedStartDate=' + this.EditRequirementsFromDate).subscribe((res: any) => {
        if (res.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
          this.loader = false;
          this.hide = false;
          this.selected = false;
        } else if (res.hasOwnProperty('success') === false) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
          this.loader = false;
          this.hide = false;
          this.selected = false;
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
          this.hide = false;
          this.selected = false;
        }
        this.reset();
      });
    }
  }
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
  reject() {
    this.receivedCandidates.controls.reject.reset();
    this.rejectpopup = true;
    // this.hide = true;
    this.apiService.getApi(environment.lookupsData + 'RPLR').subscribe((res) => {
      // this.hide = false;
      this.rejectopention = res.data;
    });
  }
  getRejectOption(value) {
    this.rejectValue = value;
  }
  submiteReject() {
    this.loader = true;
    this.hide = true;
    this.apiService.getApi(environment.reject + '/' + this.profileId + '/' + this.rejectValue).subscribe((res: any) => {
      this.hide = false;
      this.rejectpopup = false;
      this.loader = false;
      this.reset();
    });
  }
  rejectReson() {
    this.candidaterejectpopup = true;
    this.apiService.getApi(environment.lookupsData + 'RPLR').subscribe((res) => {
      this.rejectReasonData = res.data;
      this.receivedCandidates.controls.rejectReason.patchValue(this.rejectReasonData.filter(
        (x) => x.code === this.lkRejectedType)[0]);
    });
  }
  // skillsetOthers(data, event) {
  //   console.log("skillsetOthersevents", event);
  //   if(event.length === 0){
  //     this.primarySkills=[];
  //   }

  //   this.editRequirementsForm.controls.otherskills.reset();
  //   this.editRequirementsForm.controls.otherskills.clearValidators();
  //   // tslint:disable-next-line:prefer-for-of
  //   this.otherskills = false;
  //   if (data) {
  //     this.otherskills = false;
  //   }
  //   // tslint:disable-next-line:prefer-for-of
  //   for (let i = 0; i < data.length; i++) {
  //     if (data[i].code === '25') {
  //       this.otherskills = true;
  //     }
  //   }

  //   if (this.otherskills === true) {
  //     this.editRequirementsForm.controls.otherskills.setValidators([Validators.required, Validators.maxLength(300)]);
  //   }

  //   if (this.otherskills === false) {
  //     this.editRequirementsForm.controls.otherskills.reset();
  //     this.editRequirementsForm.controls.otherskills.clearValidators();
  //   }
  // }
  skillsetOthers(data) {
    this.editRequirementsForm.controls.otherskills.reset();
    this.editRequirementsForm.controls.otherskills.clearValidators();
    // tslint:disable-next-line:prefer-for-of
    this.otherskills = false;
    if (data) {
      this.otherskills = false;
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < data.length; i++) {
      if (data[i].code === '25') {
        this.otherskills = true;
      }
    }

    if (this.otherskills === true) {
      this.editRequirementsForm.controls.otherskills.setValidators([Validators.required, Validators.maxLength(300)]);
    }

    if (this.otherskills === false) {
      this.editRequirementsForm.controls.otherskills.reset();
      this.editRequirementsForm.controls.otherskills.clearValidators();
    }
  }
  saveLocation(ev) {
    const data = ev.value;
    this.patchlocationString = data.name + '\n' + data.address1 + ',' + data.city + ',' + data.state + ',' + data.country;
  }
  setRequired() {
    return [Validators.required, Validators.maxLength(300)];
  }
  onBlur(data): void {
    if (data === '') {
      this.editRequirementsForm.controls.otherskills.setValidators(this.setRequired());
    }
  }
  getduration() {
    if (this.editRequirementsForm.controls.duration.value > 24) {
      this.shwmsg = true;
      this.editRequirementsForm.controls.duration.reset();
      this.showmsg = 'Duration should be less than 24 months';
      setTimeout(() => {
        this.showmsg = '';
        this.shwmsg = false;
        this.editRequirementsForm.controls.duration.patchValue(24);
      }, 3000);
    } else {
    }
  }
  resetskills(data, event) {
    if (event.length === 0) {
      this.skillsList12 = [];
    }
  }
  getOtherDetails() {
    this.displayOtherDetails = true;
    this.apiService.getApi(environment.getOtherDetails + this.rowData.conId).subscribe((res: any) => {
      this.getotherdetailsdata = res.data[0];
      this.getotherDetails.patchValue({
        addressControl: this.getotherdetailsdata.address1,
        addressFormControl: this.getotherdetailsdata.address2,
        mailbox1: this.getotherdetailsdata.mailbox,
        Country: this.getotherdetailsdata.country,
        State: this.getotherdetailsdata.state,
        cityForm: this.getotherdetailsdata.city,
        zipForm: this.getotherdetailsdata.zip,
        workSatus: this.getotherdetailsdata.visaStatus,
      });
    });
  }
}
