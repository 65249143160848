<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
    <p-panel>
        <p-header>
            <strong>
                Available Subcontractors
            </strong>
        </p-header>


    <!-- <p-accordion>
        <p-accordionTab header="Available Sub Contractors" [selected]="false"> -->
            <form [formGroup]="availableSubcontractorForm">
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Subcontractor Name</label>
                        <input (keyup.enter)="searchData()" id="input" type="text" pInputText placeholder="Name"
                            formControlName="subcontratorName" maxlength="50" autocomplete="off">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Corporate Website</label>
                        <input (keyup.enter)="searchData()" id="input" type="text" pInputText
                            placeholder="Corporate Website" formControlName="subcontratorUrl" maxlength="60"
                            autocomplete="off">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Country</label>
                        <p-dropdown [options]="country" placeholder="Select Country" optionLabel="label"
                            formControlName="country" (onChange)="getStateByUrl($event.value.code)"
                            (keyup.enter)="searchData()" [showClear]="true">
                        </p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">State</label>
                        <p-dropdown [options]="state" placeholder="Select State" optionLabel="label"
                            formControlName="vendorState" (keyup.enter)="searchData()" [showClear]="true"></p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Industry</label>
                        <p-dropdown (keyup.enter)="searchData()" [options]="industry" placeholder="Select Industry"
                        [showClear]="true"   optionLabel="label" optionValue="code" formControlName="industry"></p-dropdown>
                    </div>
                </div>
            </form>
            <div class="btnsCSS1 btnalignment">
                <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()"
                    class="addhBtn"></button>
                <button pButton type="button" icon='pi pi-search' label="Search" (click)="searchData()"
                    class="addhBtn"></button>
            </div>
        </p-panel>
</p-card><br>
<p-card class="pgpos">
    <div *ngIf="table">
        <p-table [columns]="cols" [value]="vendorSearchTableData" selectionMode="single" [responsive]="true"
            [autoLayout]="true" sortMode="multiple">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                        <span>
                            {{col.header}}
                        </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
                <tr>
                    <td class="textCenter" pTooltip="{{data.name}}" tooltipPosition="top"><span class="p-column-title">
                        </span>
                        {{data.name}}
                    </td>
                    <td class="textCenter" pTooltip="{{data.webSite}}" tooltipPosition="top"><span
                            class="ui-column-title pointer"> </span><a
                            (click)="goToLink(data.webSite)">{{data.webSite}}</a></td>
                    <td class="textCenter"><span class="ui-column-title"> </span>{{data.phone}}</td>
                    <td class="textCenter"><span class="ui-column-title"></span>{{industryTable[data.industry]}}</td>
                    <td class="aligncenter"><span class="ui-column-title"></span>
                        <a>
                            <em *ngIf="data.id !== this.accountId"
                                (click)="actionpopup.toggle($event); toggle(items, $event, data)"
                                class="pi pi-ellipsis-v pointer"></em>
                            <em *ngIf="data.id === this.accountId" (click)="associateAlert()"
                                class="pi pi-ellipsis-v pointer"></em>
                        </a>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
            </ng-template>
        </p-table>

        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 records-pos">
                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any available subcontractors</em><br>
            </div>
        </div>
    </p-card>
</p-card>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
<p-dialog class="dialogBox" header="Associate Subcontractor" [(visible)]="display" [modal]="true"
    [style]="{width: '25%'}" [baseZIndex]="1" [draggable]="false" [resizable]="false">
    <form [formGroup]='vendorAddForm'>
        <div class="p-fluid p-grid">
            <div class="p-col-12 p-md-12 p-sm-12  p-lg-12">
                <label class="inputfields">Sub Contrator Name<span class="mandatoryColor">*</span></label>
                <input autocomplete="off" id="input" type="text" size="30" maxlength="60" pInputText
                    formControlName="subContractorName1" placeholder="Sub Contrator Name" disabled="true">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12  p-lg-12">
                <label class="inputfields">Type Of Tier<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="vendorTierList" placeholder="Select Type Of Tier" [showClear]="true"
                    (onChange)='getVendorTierExist($event)' optionLabel="tierName" formControlName='vendorAddTier'
                    >
                </p-dropdown>
                <span *ngIf='errormgs' class="mandatoryColor">This tier is Inactive</span>
                <span class="mandatoryColor"
                    *ngIf="vendorAddForm.controls.vendorAddTier.touched && vendorAddForm.controls.vendorAddTier.invalid && vendorAddForm.controls.vendorAddTier.errors.required">
                    Please select type of tier</span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12  p-lg-12">
                <label class="inputfields">Status</label>
                <p-dropdown [options]="status1" placeholder="Select Status" formControlName="status" optionLabel="name" [showClear]="true"
                    >
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12  p-lg-12">
                <label class="inputfields">Days Delay</label>
                <input autocomplete="off" id="input" type="text" size="30" maxlength="5" pInputText
                    formControlName="days" placeholder="No.Of Days" disabled="true">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12  p-lg-12">
                <label class="inputfields">Discount(%)</label>
                <input autocomplete="off" id="input" type="text" size="30" pInputText maxlength="9"
                    formControlName="discount" placeholder="Discount(%)" disabled="true"><br>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Max Submissions<span class="mandatoryColor">*</span></label>
                <input autocomplete="off" id="input" min="0" size="30" pInputText maxlength="5"
                    formControlName="maxSubmission" placeholder="Max submissions">
                <span class="mandatoryColor"
                    *ngIf="vendorAddForm.controls.maxSubmission.touched && vendorAddForm.controls.maxSubmission.invalid && vendorAddForm.controls.maxSubmission.errors.required">
                    Please enter max submissions</span>
                <span class="mandatoryColor" *ngIf="vendorAddForm.controls.maxSubmission.errors?.min">
                    Please enter valid submissions</span>
                    <span class="mandatoryColor" *ngIf="this.vendorAddForm.get('maxSubmission').hasError('maxlength')">
                        Please enter 4 numbers only
                    </span>
            </div>
        </div><br>
        <div class="btnsCSS1">
            <button pButton type="button" icon='fa fa-handshake-o' class="addhBtn" label="Associate"
                [disabled]="disabled" (click)="addSave()"></button>
        </div>
    </form>
</p-dialog>

<div *ngIf="!loading; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>