import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-vendor-search',
  templateUrl: './vendor-search.component.html',
  styleUrls: ['./vendor-search.component.scss'],
  providers: [DatePipe, MessageService],
})
export class VendorSearchComponent implements OnInit {
  // It is used for displaying table header data
  cols = [
    { field: 'name', header: 'Vendor Name', width: '7%' },
    { field: 'webSite', header: 'Corporate Website', width: '7%' },
    { field: 'phone', header: 'Phone Number', width: '7%' },
    { field: 'industry', header: 'Industry', width: '5%' },
    { field: '', header: 'Action', width: '2%' },
  ];
  vendorSearchForm: FormGroup;
  loading: boolean;
  page = 1;
  rows = 10;
  disable = false;
  totalRecords: any;
  table = true;
  records: boolean;
  tableData: any;
  accId: any;
  display: boolean;
  vendorAddForm: FormGroup;
  country: any;
  state: any;
  vendorStaffingMode: any[];
  vendorAssociatedStaffingMode: any[];
  first: 1;
  pageCount: any;
  vendorId: any;
  vendorTierList: any;
  status1: { name: string; value: string; }[];
  errormgs: boolean;
  industry: any = [];
  items: { label: string; icon: string; command: (event: any, i: any) => void; }[];
  rowData: any;
  getIndustryTable = {};
  accountId: any;
  saveData: any;
  resetbutton: boolean;
  // tslint:disable-next-line:max-line-length
  constructor(private route: ActivatedRoute, private messageService: MessageService, private breadcrumbService: BreadcrumbService, private fb: FormBuilder, private router: Router, private apisService: ApisService) {
    this.breadcrumbService.setItems([
      { label: 'Vendors', routerLink: '/vendors' },
      { label: 'Available Vendors', routerLink: '/VendorSearch' }
    ]);
  }

  ngOnInit(): void {
    const userdetails = JSON.parse(this.apisService.decodeData());
    this.accId = userdetails.accId;
// It is used for displaying status dropdown values
    this.status1 = [
      { name: 'Active', value: 'A' },
      { name: 'Inactive', value: 'I' }
    ];
    this.vendorSearchForm = this.fb.group({
      vendorName: [''],
      vendorUrl: [''],
      vendorIndustry: [''],
      vendorCountry: [''],
      vendorState: [''],
      vendorStaffingMode: ['']
    });
    this.vendorAddForm = this.fb.group({
      vendorName1: ['', Validators.required],
      vendorAddTier: ['', Validators.required],
      status: [''],
      days: [''],
      discount: [''],
      maxSubmission: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.maxLength(4)]]
    });
    this.gridData();
    this.getCountryDropdown();
    this.getAddVendorNames();
    this.getIndustryDropDown();
  }
// It is used to show table data

  gridData() {
    const rows = this.rows;
    const page = this.page;
    const data = page + '/' + rows;
    this.loading = true;
    this.apisService.getApi(environment.activeVendorGrid + data).subscribe((res: any) => {
      this.tableData = res.data;
      this.loading = false;
      this.totalRecords = res.total;
      this.table = true;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
// It is used to navigate vendor account details screen
  vendorDetails(data) {
    sessionStorage.setItem('vendorname', data.name);
    sessionStorage.setItem('vendorId', data.id);
    this.router.navigate(['/accdetails', data.id], {
      queryParams: {
        type: 2,
      },
    });

  }
// It is used to display associate pop up
  addDailong(data) {
    this.vendorId = data.id;
    this.vendorAddForm.reset();
    this.display = true;
    this.vendorAddForm.controls.vendorName1.patchValue(data.name);
    this.vendorAddForm.controls.maxSubmission.patchValue(data.maxSubmission);
  }
  // It is used for displaying industry dropdown values
  getIndustryDropDown() {
    this.apisService.getApi(environment.lookupsData + 'ALIT').subscribe(res => {
      this.industry = res.data;
      this.industry.forEach(element => {
        this.getIndustryTable[element.code] = element.label;
      });
    });
  }
  // It is used for displaying country dropdown values
  getCountryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'adlc').subscribe(res => {
      this.country = res.data;
    });
  }
// It is used for displaying state dropdown values
  getStateByUrl(country) {
    this.vendorSearchForm.controls.vendorState.setValue('');
    if (country === null || '' || undefined) { }
    else {
      this.apisService.getApi(environment.states + country).subscribe(res => {
        this.state = res.data;
      });
    }
  }
  // It is used for displaying type of tier dropdown values
  getAddVendorNames() {
    const pgNo = this.page;
    const recordPage = this.rows;
    const pagerow = pgNo + '/'+ recordPage;
    this.apisService.getApi(environment.vendorTier  + pagerow +'/' + this.accId).subscribe(res => {
      this.vendorTierList = res.data;
    });
  }
  // It is used to search table data
  searchData() {
    const associatedStaffingMode = this.vendorSearchForm.controls.vendorStaffingMode.value;
    this.vendorAssociatedStaffingMode = [];
    if (this.vendorSearchForm.controls.vendorStaffingMode.value) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < associatedStaffingMode.length; i++) {
        this.vendorAssociatedStaffingMode.push(associatedStaffingMode[i].value);
      }
    }
    const staffingMode = this.vendorSearchForm.controls.vendorStaffingMode.value;
    this.loading = true;
    const pgNo = this.page;
    const recordPage = this.rows;
    const accountName = this.vendorSearchForm.controls.vendorName.value === null ? '' : this.vendorSearchForm.controls.vendorName.value;
    const accountUrl = this.vendorSearchForm.controls.vendorUrl.value === null ? '' : this.vendorSearchForm.controls.vendorUrl.value;
    // tslint:disable-next-line:max-line-length
    const accCountry = this.vendorSearchForm.controls.vendorCountry.value === null || '' ? '' : this.vendorSearchForm.controls.vendorCountry.value.code === undefined ? '' : this.vendorSearchForm.controls.vendorCountry.value.code;
    // tslint:disable-next-line:max-line-length
    const accState = this.vendorSearchForm.controls.vendorState.value === null || '' ? '' : this.vendorSearchForm.controls.vendorState.value.code === undefined ? '' : this.vendorSearchForm.controls.vendorState.value.code;
    // tslint:disable-next-line:max-line-length
    const accountIndustry = this.vendorSearchForm.controls.vendorIndustry.value === null || '' ? '' : this.vendorSearchForm.controls.vendorIndustry.value.code === undefined ? '' : this.vendorSearchForm.controls.vendorIndustry.value.code;
    // tslint:disable-next-line:max-line-length
    const associatedSearch = pgNo + '/' + recordPage + '?name=' + accountName + '&country=' + accCountry + '&state=' + accState + '&url=' + accountUrl + '&industry=' + accountIndustry;
    this.apisService.getApi(environment.activeVendorGrid + associatedSearch).subscribe(res => {
      this.tableData = res.data;
      this.totalRecords = res.total;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
      this.loading = false;
      this.tableData = res.data;
      this.totalRecords = res.total;
    });
  }
  // it is used for displaying tiers alreday existed or not and bind data
  getVendorTierExist(data) {
    this.errormgs = false;
    if (data.value.status === 'A') {
      this.vendorAddForm.controls.discount.patchValue(
        data.value.discount
      );
      this.vendorAddForm.controls.days.patchValue(
        data.value.daysDelay);
      this.vendorAddForm.controls.status.patchValue(this.status1.filter(x => x.value === data.value.status)[0]);
    } else {
      this.vendorAddForm.controls.vendorAddTier.reset();
      this.vendorAddForm.controls.discount.reset();
      this.vendorAddForm.controls.days.reset();
      this.vendorAddForm.controls.status.reset();
      setTimeout(() => {
        this.errormgs = true;
      }, 500);

    }
  }
  // It is used to reset the search fields
  reset() {
    this.vendorSearchForm.reset();
    this.records = false;
    this.gridData();
  }
  // It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridData();
  }
  // It is used to add vendor associate
  addSave() {
    this.display = true;
    if (this.vendorAddForm.invalid) {
      const controls = this.vendorAddForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      this.disable = true;
      const data = {
        tierId: this.vendorAddForm.controls.vendorAddTier.value.id,
        venId: this.vendorId,
        discount: this.vendorAddForm.controls.discount.value,
        daysDelay: this.vendorAddForm.controls.days.value,
        maxSubmission: this.vendorAddForm.controls.maxSubmission.value
      };
      this.loading = true;
      this.apisService.postApi(environment.addVendorTier, data).subscribe((res: any) => {
          this.saveData = res;
          this.disable = false;
          this.loading = false;
          this.gridData();
          if (this.saveData.success === true) {
            this.loading = false;
            this.messageService.add({
              key: 'br',
              severity: '',
              life: 3000,
              detail: this.saveData.message,
            });
            this.display = false;
            this.vendorAddForm.reset();
            this.gridData();
          } else if (this.saveData.success === false) {
            this.loading = false;
            this.display = true;
            this.messageService.add({
              key: 'br',
              severity: '',
              life: 3000,
              detail: this.saveData.message,
            });
            this.vendorAddForm.reset();
          } else {
            this.loading = false;
            this.display = true;
            this.messageService.add({
              key: 'br',
              severity: '',
              life: 3000,
              detail: this.saveData.error,
            });
          }
          this.loading = false;
          this.gridData();
        });
    }
  }
// It is used to show action pop up
  toggle(menu, event, rowData) {
    this.rowData = rowData;
    if (this.rowData.id === this.accountId) {
    } else {
      this.items = [
        {
          label: 'Associate',
          icon: 'fa fa-handshake-o',
          // tslint:disable-next-line:no-shadowed-variable
          command: (event, i) => {
            this.addDailong(rowData);
          },

        },
        {
          label: 'Details',
          icon: 'pi pi-eye showEye',
          // tslint:disable-next-line:no-shadowed-variable
          command: (event, i) => {
            this.vendorDetails(rowData);
          },

        },
      ];
    }

  }
  // It is used to navigate to email
  goToLink(url: string) {
    const addressLink = url.includes('http') ? url : `http://${url}`;
    window.open(addressLink, '_blank');
  }
}
