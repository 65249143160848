import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-add-techreviews',
  templateUrl: './add-techreviews.component.html',
  styleUrls: ['./add-techreviews.component.scss'],
  providers: [DatePipe, MessageService],
})
export class AddTechreviewsComponent implements OnInit {
  addtechreviewForm: any;
  techrequId: any;
  techuseId: any;
  reqName: any;
  reviewerStatus: any;
  editData: any;
  id: any;
  loader: boolean;
  techData: any;
  acctdetails: any;
  roleId: any;
  rowId: any;
  jobId: any;
  profileid: string;
  reviewerStatus1: any[];
  count = 0;
  display: boolean;
  disable: boolean;
  parentReqId: string;
  usrroleid: any;
  createdbyaccId: string;
  // tslint:disable-next-line:max-line-length
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private apisService: ApisService, private messageService: MessageService, private router: Router, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Tech Reviews', routerLink: '/tech-evalution' },
      { label: 'Tech Reviews Add', routerLink: '/add-tech-reviews' }
    ]);
  }
  ngOnInit(): void {
    this.acctdetails = JSON.parse(this.apisService.decodeData());
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === ''){
      this.roleId = this.acctdetails.roles[0].id;
    }else{
      this.roleId = this.usrroleid;
    }
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.techrequId = this.route.snapshot.queryParamMap.get('reqId');
    this.techuseId = this.route.snapshot.queryParamMap.get('conId');
    this.reqName = this.route.snapshot.queryParamMap.get('positionTitle');
    this.profileid = this.route.snapshot.queryParamMap.get('profileid');
    this.parentReqId = this.route.snapshot.queryParamMap.get('parentReqId');
    this.rowId = routeParams.params.rowId;
    this.jobId = routeParams.params.jobId;
    this.createdbyaccId = this.route.snapshot.queryParamMap.get('createdbyaccId')
    this.addtechreviewForm = this.formBuilder.group({
      candidateName: [''],
      reviewName: [''],
      reviewStatus: [''],
      architecture: [''],
      coding: [''],
      testing: [''],
      business: [''],
      management: [''],
      ownership: [''],
      analytical: [''],
      communication: [''],
      documentation: [''],
      position: [''],
      average: [''],
      requirementsComments: [''],
    });
    this.reviewstatusDD();
    this.patchingData();
  }
  reviewstatusDD() {
    this.apisService.getApi(environment.lookupsData + 'ERRS').subscribe((res) => {
      this.reviewerStatus = res.data;
    });
  }
  patchingData() {
    this.apisService.getApi(environment.reviewPatching + '/' + this.id).subscribe((res: any) => {
      this.editData = res.data[0];
      if (this.editData.lkReviewState === 'E') {
        this.display = false;
      } else {
        this.display = true;
      }
      this.reviewerStatus1 = [];
      this.reviewerStatus.every(element => {
        if (this.count === 2) {
          return false;
        }
        else {
          this.reviewerStatus1.push(element);
          this.count = this.count + 1;
          return true;
        }
      });
      this.addtechreviewForm.controls.candidateName.patchValue(this.editData.candidateName);
      this.addtechreviewForm.controls.reviewName.patchValue(this.editData.reviewerName);
      this.addtechreviewForm.controls.requirementsComments.patchValue(this.editData.comments);
      // tslint:disable-next-line:max-line-length
      this.addtechreviewForm.controls.reviewStatus.patchValue(this.reviewerStatus.filter((x) => x.code === this.editData.lkReviewState)[0]);
      this.addtechreviewForm.controls.architecture.patchValue(this.editData.architecture);
      this.addtechreviewForm.controls.coding.patchValue(this.editData.coding);
      this.addtechreviewForm.controls.testing.patchValue(this.editData.testing);
      this.addtechreviewForm.controls.business.patchValue(this.editData.business);
      this.addtechreviewForm.controls.ownership.patchValue(this.editData.ownership);
      this.addtechreviewForm.controls.management.patchValue(this.editData.teammanagement);
      this.addtechreviewForm.controls.analytical.patchValue(this.editData.analytical);
      this.addtechreviewForm.controls.communication.patchValue(this.editData.communication);
      this.addtechreviewForm.controls.documentation.patchValue(this.editData.documentation);
      this.addtechreviewForm.controls.position.patchValue(this.editData.POSITION);
      this.addtechreviewForm.controls.average.patchValue(this.editData.averageRating);
    });
  }
  addReqData() {
    const data = {
      lkStatus: this.addtechreviewForm.controls.reviewStatus.value.code,
      business: this.addtechreviewForm.controls.business.value,
      coding: this.addtechreviewForm.controls.coding.value,
      architecture: this.addtechreviewForm.controls.architecture.value,
      testing: this.addtechreviewForm.controls.testing.value,
      documentation: this.addtechreviewForm.controls.documentation.value,
      position: this.addtechreviewForm.controls.position.value,
      ownership: this.addtechreviewForm.controls.ownership.value,
      teammanagement: this.addtechreviewForm.controls.management.value,
      analytical: this.addtechreviewForm.controls.analytical.value,
      communication: this.addtechreviewForm.controls.communication.value,
      comments: this.addtechreviewForm.controls.requirementsComments.value,
      id: this.editData.id,
      profileId: +this.profileid,
      reqId: this.jobId,
      parentReqId : this.parentReqId,
      createdByAccId : this.createdbyaccId
    };
    this.loader = true;
    this.disable = true;
    this.apisService.postApi(environment.addReviwes, data).subscribe((res: any) => {
      this.loader = false;
      this.techData = res;
      if (this.techData.hasOwnProperty('success') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.techData.message });
        this.loader = false;
        this.disable = false;
        setTimeout(() => {
          this.router.navigate(['/tech-evalution']);
        }, 2000);
      } else if (this.techData.hasOwnProperty('success') === false) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.techData.message });
        setTimeout(() => {
          this.loader = false;
          this.disable = false;
        }, 1500);
        this.goto();
      } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.techData.error });
        this.loader = false;
        this.disable = false;
      }
      this.loader = true;
    });
  }
  goto() {
    this.router.navigate(['/tech-evalution'], {
      queryParams: {
        reqId: this.techrequId, conId: this.techuseId, reqname: this.reqName
      }
    }
    );
  }
}
