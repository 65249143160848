<p-card>
    <p-panel>
        <p-header>
            <label><strong> Account : </strong><strong class="header-col">{{custName}}</strong></label>
            <em class="pi pi-arrow-left panelBackRoute" (click)="goto()"></em>
        </p-header>
        <p-tabView (onChange)="onTabChange($event)">
            <p-tabPanel header="Customer Details">
                <p-card>
                    <form [formGroup]="accountDetailsForm">
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <label class="inputfields">Account Name</label>
                                <input id="input" autocomplete="off" type="text" size="30" formControlName="accountName"
                                    pInputText placeholder="Account Name" [readonly]="true" maxlength="60">
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <label class="inputfields">Corporate Website</label>
                                <input id="input" autocomplete="off" type="text" size="30" formControlName="accountURL"
                                    pInputText placeholder="Corporate Website" [readonly]="true" maxlength="60">
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                                <h5 class="header-col">Billing Address</h5>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                                <label class="inputfields" id='csslabel'>Address Line 1</label><label
                                    class="mandatoryColor">*</label>
                                <input type="text" autocomplete="off" pInputText placeholder="Address Line 1"
                                    maxlength="100" formControlName="address1" [readonly]="true">
                            </div>
                            <div class="p-col-12">
                                <div class="p-grid p-fluid">
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
                                        <label class="inputfields" id='csslabel'>Address Line 2</label>
                                        <input type="text" autocomplete="off" pInputText placeholder="Address Line 2"
                                            maxlength="100" formControlName="address2" [readonly]="true">
                                    </div>
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                        <label class="inputfields" id='csslabel'>Mailbox</label>
                                        <input type="text" pInputText pKeyFilter="num" autocomplete="off"
                                            maxlength="100" pInputText placeholder="Mailbox" formControlName="mailbox"
                                            [readonly]="true">
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12">
                                <div class="p-grid p-fluid">
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                        <label class="inputfields">Country</label>
                                        <p-dropdown formControlName="country" [options]="countryOptions"
                                            placeholder="Select Country" optionLabel="label"
                                            (onChange)="stateDropdown($event.value.code)" [showClear]="true"
                                            [readonly]="true">
                                        </p-dropdown>
                                    </div>
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                                        <label class="inputfields">State</label>
                                        <p-dropdown formControlName="state" placeholder="Select State"
                                            [options]="stateoptions" optionLabel="label" [showClear]="true"
                                            [readonly]="true"></p-dropdown>
                                    </div>
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                        <label class="inputfields">City</label>
                                        <input id="input" autocomplete="off" type="text" size="30" maxlength="20"
                                            formControlName="city" pInputText placeholder="City" disabled="true">
                                    </div>
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                        <label class="inputfields">Zip</label>
                                        <input id="input" autocomplete="off" pKeyFilter="num" size="30"
                                            formControlName="zip" pInputText placeholder="Zip" maxlength="10"
                                            disabled="true">
                                    </div>
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                        <label class="inputfields">Phone</label>
                                        <div class="p-col-12 p-md-12 p-sm-12 mobstyle">
                                            <div class="p-inputgroup" class="p-col-4 p-md-4 mobinput">
                                                <span class="p-inputgroup-addon addon">
                                                    <p-dropdown [disabled]="true" [options]="countrycodedropdown"
                                                        class="phonecodestyle" (onChange)="countryids($event)"
                                                        filterPlaceholder='Search Results' optionLabel="label"
                                                        formControlName="phDrp">
                                                        <ng-template let-items pTemplate="selectedItem">
                                                            <div class="flex-row">
                                                                <img class="lang-change-img" src={{items.flag}}
                                                                    alt="" />
                                                                <div
                                                                    class="lang-change-option-text selected-lang center">
                                                                    {{items.code}}
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                        <ng-template let-country pTemplate="item">
                                                            <div class="flex-row" id="dropdown1">
                                                                <img class="lang-change-img" src={{country.flag}}
                                                                    alt="" />
                                                                <div class="lang-change-option-text center">
                                                                    {{country.label}}
                                                                    {{country.code}}</div>
                                                            </div>
                                                        </ng-template>
                                                    </p-dropdown>
                                                </span>
                                            </div>
                                            <div class="p-col-8 p-md-8 phnostyle">
                                                <p-inputMask autocomplete="off" id="input" mask="(999) 999-9999"
                                                    size="30" formControlName="phone" placeholder="Phone"
                                                    disabled="true">
                                                </p-inputMask>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                        <label class="inputfields">Fax</label>
                                        <input autocomplete="off" id="input" pKeyFilter="num" size="30"
                                            formControlName="fax" pInputText placeholder="Fax" maxlength="15"
                                            disabled="true">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </p-card>
            </p-tabPanel>
            <p-tabPanel header="Banking Details">
                <p-card *ngIf="detailsLength != 0">
                    <form [formGroup]="bankdetailsForm">
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields" id='csslabel'>Bank Name</label>
                                <input type="text" autocomplete="off" maxlength="100" pInputText placeholder="Bank Name"
                                    formControlName="bankAccountName" [readonly]="true">
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields" id='csslabel'>Swift Code</label>
                                <input type="text" pInputText pKeyFilter="num" autocomplete="off" maxlength="100"
                                    pInputText placeholder="Swift Code" formControlName="bankAccountNumber"
                                    [readonly]="true">
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields" id='csslabel'>IFSC Code</label>
                                <input type="text" autocomplete="off" maxlength="100" pInputText placeholder="IFSC Code"
                                    formControlName="ifsccode" [readonly]="true">
                            </div>

                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <label class="inputfields" id='csslabel'>Beneficiary Account No</label>
                                <input type="text" autocomplete="off" maxlength="100" pInputText
                                    placeholder="Beneficiary Account No" formControlName="beneficiaryno"
                                    [readonly]="true">
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <label class="inputfields" id='csslabel'>Beneficiary Name</label>
                                <input type="text" pInputText pKeyFilter="num" autocomplete="off" maxlength="100"
                                    pInputText placeholder="Beneficiary Name" formControlName="beneficiaryname"
                                    [readonly]="true">
                            </div>

                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <label class="inputfields" id='csslabel'>Address</label>
                                <input type="text" autocomplete="off" maxlength="100" pInputText placeholder="Address"
                                    formControlName="address" [readonly]="true">
                            </div>

                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <label class="inputfields" id='csslabel'>City</label>
                                <input type="text" pInputText pKeyFilter="num" autocomplete="off" maxlength="100"
                                    pInputText placeholder="City" formControlName="city" [readonly]="true">
                            </div>

                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields" id='csslabel'>Country</label>
                                <p-dropdown [options]="countryOptions" placeholder="Select Country" optionLabel="label"
                                    [readonly]="true" (onChange)="getStateByUrl($event.value.code)" [showClear]="true"
                                    formControlName="country"></p-dropdown>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields" id='csslabel'>State</label>
                                <p-dropdown placeholder="Select State" [options]="stateOptions" [readonly]="true"
                                    optionLabel="label" [showClear]="true" formControlName="state"></p-dropdown>
                            </div>

                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields" id='csslabel'>Zip</label>
                                <input type="text" pInputText pKeyFilter="num" autocomplete="off" maxlength="100"
                                    [readonly]="true" pInputText placeholder="Zip" formControlName="zip"
                                    [readonly]="true">
                            </div>
                        </div>
                    </form>
                </p-card>
                <p-card *ngIf="detailsLength === 0">
                    <div class="norecordImgsize">
                        <div class="aligncenter">
                            <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png"
                                class="norecordimg">
                            <br><em>Sorry, we are not able to find any banking details</em><br>
                        </div>
                    </div>
                </p-card>
            </p-tabPanel>
            <!-- <p-tabPanel header="Rate Card">
                <p-card>
                    <form [formGroup]="taskViewForm">
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Job Title</label>
                                <div class="p-inputgroup">
                                    <input autocomplete="off" type="text" pInputText formControlName="rateCardJobTitle"
                                        placeholder="Job Title">
                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Job Category</label>
                                <div class="p-inputgroup">
                                    <input autocomplete="off" type="text" pInputText
                                        formControlName="rateCardJobCategory" placeholder="Job Category">
                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Min Rate</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}}
                                    </span>
                                    <input autocomplete="off" type="text" pInputText formControlName="minRate"
                                        maxlength=5 placeholder="Min Rate" pKeyFilter="num">
                                    <span class="p-inputgroup-addon">/Hrs</span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields">Max Rate</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}}
                                    </span>
                                    <input autocomplete="off" type="text" pInputText formControlName="maxRate"
                                        maxlength=5 placeholder="Max Rate" pKeyFilter="num">
                                    <span class="p-inputgroup-addon">/Hrs</span>
                                </div>
                            </div>
                        </div>
                        <div class="btnsCSS1">
                            <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()"
                                class="addhBtn"></button>
                            <button pButton type="submit" icon='pi pi-search' label="Search"
                                (click)="searchRateCardData()" class="addhBtn"></button>
                        </div>
                        <br>
                        <br>
                        <br>
                    </form>
                </p-card>
                <br>
                <p-card class="pgpos">
                    <div *ngIf !="totalRecords === 0">
                        <p-table [columns]="cols" [rows]="10" [value]="rateGridData" selectionMode="single"
                            dataKey="edit" [responsive]="true" sortMode="multiple">
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                                        <span> {{col.header}}</span>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-data>
                                <tr>
                                    <td class="textCenter"><span class="ui-column-title"></span>{{data.jobcategory}}
                                    </td>
                                    <td class="textCenter"><span class="ui-column-title"></span>{{data.jobTitle}}</td>
                                    <td class="textCenter"><span class="ui-column-title"></span> {{data.minRate |
                                        currency: currencyValue : true}} </td>
                                    <td class="textCenter"><span class="ui-column-title"></span> {{data.maxRate |
                                        currency: currencyValue : true}}</td>
                                    <td class="textCenter"><span class="ui-column-title"></span>{{data.comments}} </td>
                                    <td class="textCenter" (onChange)='statuschange()'><span
                                            class="ui-column-title"></span>{{data.lkStatus}}</td>
                                    <td class="textCenter"><span class="ui-column-title"></span>
                                        <em class="pi pi-ellipsis-v eccli-size" (click)="editData(data)"></em>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                                    (onPageChange)="paginate($event)"></p-paginator>
                            </div>
                        </div>
                    </div>
                    <p-card *ngIf="totalRecords === 0">
                        <div class="norecordImgsize">
                            <div class="aligncenter">
                                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png"
                                    class="norecordimg">
                                <br><em>Sorry, we are not able to find any rate cards</em><br>
                            </div>
                        </div>
                    </p-card>
                </p-card>
                <form [formGroup]="addRateCardForm">
                    <p-dialog class="dialogBox" header="Update rate card to customer" [(visible)]="displayModal"
                        [modal]="true" [style]="{width: '35vw'}" [baseZIndex]="1" [draggable]="false"
                        [resizable]="false">
                        <div class="p-grid p-fluid">
                            <div class="p-col-6 p-md-6 p-sm-12">
                                <label class="inputfields">Position Category</label>
                                <input id="input" type="text" pInputText placeholder="Position Category"
                                    formControlName="jobcategory" autocomplete="off" disabled="true">
                            </div>
                            <div class="p-col-6 p-md-6 p-sm-12">
                                <label class="inputfields">Job Title</label>
                                <input id="input" type="text" pInputText placeholder="Job Title"
                                    formControlName="jobTitle" autocomplete="off" disabled="true">
                            </div>
                        </div>
                        <div class="p-grid p-fluid">
                            <div class="p-col-6 p-md-6 p-sm-12">
                                <label class="inputfields">Min Rate <label class="mandatoryColor">*</label></label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}}
                                    </span>
                                    <input id="input" pKeyFilter="num" pInputText placeholder="Min Rate"
                                        formControlName="minRate" autocomplete="off" maxlength="3">
                                    <span class="mandatoryColor"
                                        *ngIf="addRateCardForm.controls.minRate.touched && addRateCardForm.controls.minRate.invalid && addRateCardForm.controls.minRate.errors.required">
                                        Please enter min rate</span>
                                </div>
                            </div>
                            <div class="p-col-6 p-md-6 p-sm-12">
                                <label class="inputfields">Max Rate <label class="mandatoryColor">*</label></label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}}
                                    </span>
                                    <input id="input" pKeyFilter="num" pInputText placeholder="Max Rate"
                                        formControlName="maxRate" maxlength="3" autocomplete="off">
                                    <span class="mandatoryColor"
                                        *ngIf="addRateCardForm.controls.maxRate.touched && addRateCardForm.controls.maxRate.invalid && addRateCardForm.controls.maxRate.errors.required">
                                        Please enter max rate</span>
                                </div>
                            </div>
                        </div>
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12">
                                <label class="inputfields">Comments</label>
                                <input id="input" type="text" pInputText placeholder="Comments"
                                    formControlName="comments" maxlength="300" autocomplete="off">
                            </div>
                        </div>
                        <div *ngIf="displayAddButton" class="btnsCSS1">
                            <button pButton type="button" icon='pi pi-refresh' class="addhBtn" label="Reset"
                                (click)="addRateCardDataReset()" [disabled]="disableButtons"></button>
                            <button pButton type="button" icon='pi pi-plus' class="addhBtn" label="Add"
                                (click)="addRateCardData()" [disabled]="disableButtons"></button><br>
                        </div>
                        <div *ngIf="displayUpdateButton" class="btnsCSS1">
                            <button pButton type="button" icon='pi pi-save' class="addhBtn savbtn" label="Update"
                                (click)="updateRateCardData()" [disabled]="disableButtons"></button>
                        </div><br><br>
                    </p-dialog>
                </form>
            </p-tabPanel> -->
            <p-tabPanel header="Team Info">
                <p-card>
                    <form [formGroup]="teamsForm">
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields"> First Name</label>
                                <input id="input" type="text" (keyup.enter)="getGirdData()"  pInputText placeholder="First Name" autocomplete="off"
                                    formControlName="firstName" maxlength="30">
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields">Last Name</label>
                                <input id="input" type="text" (keyup.enter)="getGirdData()"  pInputText placeholder="Last Name" autocomplete="off"
                                    formControlName="lastName" maxlength="30">
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields">Email</label>
                                <input id="input" type="text" (keyup.enter)="getGirdData()"  pInputText placeholder="Email" autocomplete="off"
                                    formControlName="emailid" maxlength="60">
                            </div>

                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields">Phone</label>
                                <p-inputMask autocomplete="off" (keyup.enter)="getGirdData()"  mask="(999) 999-9999" formControlName="phone"
                                    placeholder="Phone">
                                </p-inputMask>
                                <p-tabPanel header="Attachments"></p-tabPanel>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields">Status</label>
                                <p-dropdown placeholder="Select Status" (keyup.enter)="getGirdData()"  [options]="status" optionLabel="label"
                                    formControlName="status" [showClear]="true"></p-dropdown>
                            </div>
                        </div>
                        <div class="btnsCSS1 btnalignment">
                            <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="resetteaminfo()" class="addhBtn"></button>
                            <button pButton type="button" icon='pi pi-search' label="Search" (click)="getGirdData()" class="addhBtn"></button>
                        </div>
                    </form>
                </p-card>
                <br>
                <p-card class="pgpos">
                    <div *ngIf="table">
                        <p-table class="tablescroll" [value]="grid" [columns]="cols1" selectionMode="single"
                            dataKey="edit" [responsive]="true" sortMode="multiple">
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th id="gridHeader" *ngFor="let col of columns; let i=index"
                                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                                        {{col.header}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-data>
                                <tr>
                                    <td class="textCenter"><span class="p-column-title">Name</span>{{data.name}} </td>
                                    <td class="textCenter"><span class="p-column-title">Email</span><a class="pointer"
                                            (click)="emailToast()"
                                            (click)="copyInputMessage(data.email)">{{data.email}}</a></td>
                                            <td class="textCenter" pTooltip="{{roletable(data.roleList)}}" tooltipPosition="top"><span
                                                class="p-column-title">Role</span>{{ roletable(data.roleList)}}</td>
                                        <td class="textCenter"><span class="p-column-title">Phone</span>{{data.officePhone}}</td>
                                        <td>
                                            <p-badge [value]="statuTable[data.status]"
                                                [ngClass]="data.status==='A'?'green': data.status === 'I'?'red': 'blue'">
                                            </p-badge>
                                        </td>

                                    <!-- <td class="textCenter"><span class="p-column-title">Action</span><a>
                                            <em (click)="downloadActionPopup.toggle($event); toggle($event,data, rowData)"
                                                class="pi pi-ellipsis-v pointer"></em>
                                        </a> </td> -->
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                                <h3 class="norecorddata">Total records : {{this.totalrecords}}</h3>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                                <p-paginator [rows]="10" [totalRecords]="totalrecords" [rowsPerPageOptions]="[10,20,30]"
                                    (onPageChange)="paginate($event)"></p-paginator>
                            </div>
                        </div>
                    </div>
                    <p-card *ngIf="records">
                        <div class="norecordImgsize">
                            <div class="aligncenter">
                                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png"
                                    class="norecordimg">
                                <br><em>Sorry, we are not able to find any team info</em><br>
                            </div>
                        </div>
                    </p-card>
                </p-card>
            </p-tabPanel>
            <p-tabPanel header="Util">
                <p-card>
                    <form [formGroup]="standardmarkup">
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields" id='csslabel'>Max Submissions</label>
                                <input type="text" pInputText pKeyFilter="num" autocomplete="off" pInputText
                                    formControlName="submissions" placeholder="Max Submissions" disabled="true"
                                    maxlength="3" [readonly]="true">
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <label class="inputfields" id='csslabel'>Standard Mark Up (%)</label>
                                <input type="text" pInputText pKeyFilter="num" autocomplete="off" pInputText
                                    placeholder="Standard Mark Up" formControlName="markup" maxlength="4">
                                    <span class="validationtest"
                                    *ngIf="this.standardmarkup.get('markup').hasError('maxlength')">
                                    Please enter 3 numbers only
                                </span>
                                <span class="validationtest"
                    *ngIf="this.standardmarkup.controls.markup.invalid && this.standardmarkup.controls.markup.errors.pattern">Please
                    enter
                    numbers only</span>
                            </div>
                        </div>
                        <div class="btnsCSS1 btnalignment">
                            <button pButton type="button" icon='pi pi-save' (click)="updateStandardmarkup()"
                                [disabled]="disable" label="Update" class="addhBtn"></button>
                        </div>
                    </form>
                </p-card>
            </p-tabPanel>
        </p-tabView>
    </p-panel>
</p-card>
<p-toast position="bottom-right" key="br"></p-toast>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>