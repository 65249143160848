import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-sales-info',
  templateUrl: './sales-info.component.html',
  styleUrls: ['./sales-info.component.scss'],
  providers: [DatePipe, MessageService],
})
export class SalesInfoComponent implements OnInit {
  salesinfoForm: FormGroup;
  page: any = 1;
  rows: any = 10;
  cols = [
    { field: 'name', header: 'Name' },
    { field: 'webSite', header: 'Email' },
    { field: 'phone', header: 'Office Number' },
    { field: '', header: 'Action' },
  ];
  first: any;
  pageCount: any;
  rowTID: any;
  gridres: any;
  loading = false;
  totalrecods: any;
  records: boolean;
  table: boolean;
  items: any[];
  names: any;
  // tslint:disable-next-line: max-line-length
  constructor(private fb: FormBuilder, private messageService: MessageService, private route: ActivatedRoute, private apisService: ApisService, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Vendors', routerLink: '/projectvendor' },
      { label: 'Sales Info', routerLink: '/salesinfo' }
    ]);
  }
  ngOnInit(): void {
    this.salesinfoForm = this.fb.group({
      name: [''],
      mail: [''],
    });
    this.rowTID = JSON.parse(sessionStorage.getItem('id'));
    this.gridData();
  }
  gridData() {
    this.loading = true;
    const pgNo = this.page;
    const recordPage = this.rows;
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.names = routeParams.params.name;
    this.apisService.getApi(environment.salessearch + pgNo + '/' + recordPage + '/' + this.rowTID).subscribe((res: any) => {
      this.gridres = res.data.records;
      this.loading = false;
      this.totalrecods = res.data.count;
      if (this.totalrecods === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  search() {
    // tslint:disable-next-line:max-line-length
    const Name = this.salesinfoForm.controls.name.value === null ? '' : this.salesinfoForm.controls.name.value === undefined ? '' : this.salesinfoForm.controls.name.value === '' ? '' : this.salesinfoForm.controls.name.value;
    // tslint:disable-next-line:max-line-length
    const email = this.salesinfoForm.controls.mail.value === null ? '' : this.salesinfoForm.controls.mail.value === undefined ? '' : this.salesinfoForm.controls.mail.value === '' ? '' : this.salesinfoForm.controls.mail.value;
    const pgNo = this.page;
    const recordPage = this.rows;
    const Id = this.rowTID;
    const salesSearch = pgNo + '/' + recordPage + '/' + Id + '?name=' + Name + '&email=' + email;
    this.loading = true;
    this.apisService.getApi(environment.salessearch + salesSearch).subscribe(res => {
      this.loading = false;
      this.gridres = res.data.records;
      this.totalrecods = res.data.count;
      if (this.totalrecods === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  reset() {
    this.salesinfoForm.reset();
    this.gridData();
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridData();
  }
  // It is used to show action pop up
  toggle(event, data, rowData) {
    this.items = [];
  }
  emailToast(){
    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
  }
  copyInputMessage(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
}
