<p-toast position="bottom-right" key="br"></p-toast>
<form [formGroup]="resumeForm" (ngSubmit)="saveAttachmentDetails()">
    <p-card>
        <div class="btnsCSS1 alignbtn">
            <button pButton type="button" [icon]="showUploadView ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                label="Import" (click)="toggleUploadView()" class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-plus' label="Add" (click)="showDialog()" class="addhBtn"></button>
        </div>
        <h5></h5>
        <div *ngIf="isUploading" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <br>
            <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
        <br>
        <div *ngIf="showUploadView" class="dropzone p-col-12 p-md-12 p-sm-12 p-lg-3">
            <em class="pi pi-upload uploadIcon Iconsize" aria-hidden="true"></em>
            <input accept=".doc,.docx,application/msword,application/pdf " type="file" class="fileInput" #fileDropRef
                id="fileDropRef" multiple (change)="onUpload($event.target.files)" style="cursor: pointer;"/>
            <h3>Drag and drop file here</h3>
            <h3 class="importstylin">or</h3>
            <label id="fileUpload" for="fileDropRef">Browse for files</label>
        </div>
    </p-card>
    <br>
    <p-card class="pgpos">
        <!--It is used for displaying the My Resumes table-->
        <div *ngIf="isUploading1" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
        <div *ngIf="table">
            <p-table [columns]="cols" [value]="gridRes" [rows]="10" selectionMode="single" dataKey="edit"
                [responsive]="true" sortMode="multiple" >
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                            [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                            <span>{{col.header}} </span>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td class="textCenter"> <span class="p-column-title">File Name</span> {{rowData.fileName}}</td>
                        <td class="textCenter"><span class="p-column-title">Profile Title</span>{{rowData.title}}</td>
                          <td class="textCenter descrip" pTooltip="{{rowData.comments}}" tooltipPosition="top"><span
                                class="p-column-title">
                                Comments</span>{{rowData.comments}}</td>
                        <td><span class="ui-column-title"></span>
                            <p-badge [value]="this.statuslist[rowData.lkStatus]"
                                [ngClass]="rowData.lkStatus ==='A'?'green': 'red'">
                            </p-badge>
                        </td>
                        <td class="textCenter"><span class="p-column-title">Action</span><a>
                                <em (click)="actionpopup.toggle($event); toggle($event,data, rowData)"
                                    class="pi pi-ellipsis-v pointer"></em>
                            </a></td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="paginatorright">
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr *ngIf="gridRes?.length==0" class="aligncenter">
                        <td colspan="6" class="mandatoryColor"> No Records </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="p-grid">
                <div class="p-col-8 p-md-8 p-lg-2 totalrec">
                    <h3 class="norecorddata">Total records : {{this.recordslnt}}</h3>
                </div>
                <div class="p-col-8 p-md-8 p-lg-10">
                    <p-paginator [rows]="10" [totalRecords]="recordslnt" [rowsPerPageOptions]="[10,20,30]"
                        (onPageChange)="paginate($event)"></p-paginator>
                </div>
            </div>
        </div>
        <p-card *ngIf="records">
            <div class="norecordImgsize">
                <div *ngIf="records" class="aligncenter">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png"
                        class="norecordimg">
                    <br><em>Sorry, we are not able to find any Profiles</em><br>
                </div>
            </div>
        </p-card>
    </p-card>
    <br>
    <p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
    <!---Dialog box for Upload Resume Details---->
    <p-dialog class="dialogBox1" header="Upload Profile" [(visible)]="display" [modal]="true" [style]="{width: '25%' }"
        [baseZIndex]="1" [draggable]="false" [resizable]="false">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Profile Title<span class="mandatoryColor">*</span></label>
                <input id="input" type="text" autocomplete="off" pInputText placeholder="Profile Title"
                   maxlength="26" formControlName="attachmentTitle">
                <div>
                    <span class="mandatoryColor" *ngIf="resumeForm.controls.attachmentTitle.touched && 
    resumeForm.controls.attachmentTitle.invalid &&
    resumeForm.controls.attachmentTitle.errors.required">Please enter profile title</span>
                </div>
                <div>
                    <span class="mandatoryColor"
                        *ngIf="this.resumeForm.controls.attachmentTitle.invalid && this.resumeForm.controls.attachmentTitle.errors?.pattern">
                        Please enter alphabets only</span>
                </div>
                <div>
                    <span class="validationtest" *ngIf="this.resumeForm.get('attachmentTitle').hasError('maxlength')">
                        Please enter 25 alphabets only
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Comments</label>
                <textarea placeholder="Comments " pInputTextarea autocomplete="off" rows="4" cols="30"
                    formControlName="comments" maxlength="201"></textarea>
                <span class="validationtest" *ngIf="this.resumeForm.get('comments').hasError('maxlength')">
                    Please enter 200 alphabets only
                </span>
            </div>
       
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <label class="inputfields">Add Attachment<span class="mandatoryColor">*</span></label><br>
            <input class="pointer" *ngIf="classinput" type="file" id="attachment"  (change)="handleFileInput($event)" #file formControlName="fileupload">
            <input class="pointer" *ngIf="profileupload" type="file" id="attachment" (change)="handleFileInput($event)"
                #file formControlName="fileupload">
            <div class="validationtest"
                *ngIf="resumeForm.get('fileupload').touched && resumeForm.get('fileupload').invalid && resumeForm.get('fileupload').errors.required">
                 Please upload attachment</div>
            <em *ngIf="filesdatass" class="pi pi-times" style="cursor: pointer;" (click)="cancelfiles()"></em>
        </div>
    </div>
        <br>
        <div class="btnsCSS1 rightalign">
            <button type="submit" class="addhBtn" pButton icon='pi pi-save' label="Save" [disabled]="disable"
                (click)="profiled()"></button>
        </div>
    </p-dialog>
</form>
<!---Dialog box for Edit Resume ---->
<p-dialog class="dialogBox" header="Edit Profile" [(visible)]="display1" [modal]="true" [style]="{width: '25%' }"
    [baseZIndex]="1" [draggable]="false" [resizable]="false">
    <form [formGroup]="editResumeForm">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Profile Title</label><span class="mandatoryColor">*</span>
                <input id="input" type="text" autocomplete="off" pInputText placeholder="Name of your file"
                   maxlength="26" formControlName="editAttachmentTitle">
                <div>
                    <span class="mandatoryColor"
                        *ngIf="editResumeForm.controls.editAttachmentTitle.touched && editResumeForm.controls.editAttachmentTitle.invalid && editResumeForm.controls.editAttachmentTitle.errors.required">Please
                        enter profile title</span>
                </div>
                <div>
                    <span class="mandatoryColor"
                        *ngIf="this.editResumeForm.controls.editAttachmentTitle.invalid && this.editResumeForm.controls.editAttachmentTitle.errors?.pattern">
                        Please enter alphabets only</span>
                </div>
                <div>
                    <span class="validationtest"
                        *ngIf="this.editResumeForm.get('editAttachmentTitle').hasError('maxlength')">
                        Please enter 25 alphabets only
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Status</label>
                <p-dropdown [options]=" status" placeholder="Select Status" optionLabel="label" [showClear]="true"
                    formControlName="editAttachmentStatus" (onChange)="statusdisable()">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Comments</label>
                <textarea placeholder="Comments " pInputTextarea autocomplete="off" rows="4" cols="30"
                    formControlName="editComments" maxlength="201"></textarea>
                <span class="validationtest" *ngIf="this.editResumeForm.get('editComments').hasError('maxlength')">
                    Please enter 200 alphabets only
                </span>
            </div>
        </div><br>
        <div class="btnsCSS1">
            <button type="button" pButton icon="pi pi-save" [disabled]="disable" label="Update" class="addhBtn"
                (click)="updateProfile()"></button>
        </div>
    </form>
</p-dialog>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>