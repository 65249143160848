import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Message, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-consultant-resume-component',
  templateUrl: './consultant-resume-component.component.html',
  styleUrls: ['./consultant-resume-component.component.scss'],
  providers: [DatePipe, MessageService],
})
export class ConsultantResumeComponentComponent implements OnInit {
  resumeForm: FormGroup;
  msgs: Message[] = [];
  // It is used for displaying the table header data
  cols = [
    { field: 'fileName', header: 'File Name', width: '15%' },
    { field: 'title', header: 'Profile Title', width: '10%' },
    { field: 'comments', header: 'Comments', width: '10%' },
    { field: 'lkStatus', header: 'Status', width: '5%' },
    { field: 'action', header: 'Action', width: '3%' },
  ];
  // It is used for the displaying the status Dropdown Values
  statusdata = [
    { name: 'Active', code: 'Active' },
    { name: 'In-Active', code: 'In-Active' }
  ];
  data: any;
  display: boolean;
  files1: any;
  file2: any;
  base64textString: any;
  bas2: any;
  orgIdValue: any;
  userIdValue: any;
  base = 'data:text/html;base64,';
  consultantId: any;
  addResponse: any;
  cvGridDetails: any;
  display1: boolean;
  titleName: any;
  fileName: any;
  attachmentId: any;
  loader: boolean;
  showSubmitColumn: any;
  ReqId: string;
  reqName: string;
  response: any;
  base64File: any;
  iconToggle = [];
  dispalyattachmenttitle: boolean;
  attatitle: boolean;
  Reslt: string;
  disable = false;
  loading = false;
  actId: any;
  rowid: string;
  userId: any;
  gridRes: any;
  gridData: any;
  downloadData: any;
  title: any;
  resTitle: any;
  status: any;
  comments: any;
  comments1: any;
  patchStatus: any;
  status1: any;
  records: boolean;
  table: boolean;
  rowData: any;
  totalrecords = 1;
  items: ({ label: string; icon: string; command: () => void; } | { label: string; icon: string; command?: undefined; })[];
  filedata: any = {};
  showUploadView = false;
  gridId: any;
  res1: any;
  profileTilte: any;
  rowId: any;
  fileToUpload: File | null = null;
  fileData: any;
  filePath: any;
  fileSize: any;
  getData: any;
  isUploading = false;
  isUploading1 = false;
  fileType: any;
  fileRes: any;
  updateRes: any;
  name: any;
  uploadFile: any;
  recordslnt: any;
  fileOver: boolean;
  first: any;
  pageCount: any;
  hide: boolean;
  disab: any;
  disab1: any;
  editResumeForm: FormGroup;
  i: number;
  count = 0;
  statuss: any;
  gridresponse: any[];
  gridresponseData: any[];
  data1: any;
  success: any;
  event: any;
  activeresume: any;
  lkstatus: any;
  page: any = 1;
  rows: any = 10;
  totalRecords: number;
  uploadfile: any;
  statuslist = {};
  filesdatass: boolean;
  classinput: boolean;
  profileupload: boolean;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private messageService: MessageService, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Profiles', routerLink: '/myresume' },
    ]);
  }
  ngOnInit() {
    this.classinput = true;
    this.orgIdValue = sessionStorage.getItem('orgId');
    this.userIdValue = sessionStorage.getItem('userId');
    this.ReqId = sessionStorage.getItem('requirementId');
    this.reqName = sessionStorage.getItem('requirementName');
    this.resumeForm = this.fb.group({
      attachmentTitle: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(25)]],
      attachmentStatus: [''],
      fileupload: ['', Validators.required],
      editComments: [''],
      comments: ['', Validators.maxLength(200)]
    });
    this.editResumeForm = this.fb.group({
      editAttachmentTitle: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(25)]],
      editAttachmentStatus: [''],
      editComments: ['', Validators.maxLength(200)]
    });
    this.grid();
    this.statusDD();
  }
  // Drag Over Listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drag Leave Listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drop Listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.isUploading = true;
      this.onUpload(files);
    }
  }
  onUpload(files) {
    this.isUploading = true;
    this.apisService.getApi(environment.activeresume + this.userId).subscribe((res: any) => {
      this.uploadfile = res;
      if (this.uploadfile.data === true) {
        const formData: FormData = new FormData();
        formData.append('file', files[0]);
        this.fileType = files[0].name.split('.').pop();
        // tslint:disable-next-line:max-line-length
        this.apisService.postApi(environment.attachmentPDF + this.userId + '/' + 'R' + '/' + this.fileType, formData).subscribe((res1: any) => {
          this.showUploadView = false;
          if (res.hasOwnProperty('success') === true) {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
            this.isUploading = false;
            this.showUploadView = false;
            this.grid();
          } else if (res.hasOwnProperty('failed') === true) {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed, });
          } else {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error, });
          }
        });
      }
      else {
        this.isUploading = false;
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.uploadfile.message });
      }
    });
  }
  toggleUploadView() {
    this.showUploadView = !this.showUploadView;
  }
  toggle(event, data, rowData) {
    this.rowData = rowData;
    this.items = [
      { label: 'Edit Profile', icon: 'pi pi-pencil', command: () => { this.showDialog1(rowData); } },
      { label: 'Download', icon: 'pi pi-fw pi-download', command: () => { this.gridDownLoad(); }, },
    ];
  }
  // It is used for the displaying the GridData
  grid() {
    const acctdetails = JSON.parse(this.apisService.decodeData());
    this.userId = acctdetails.userId;
    this.loader = true;
    const pageNo = this.page;
    const pageCount = this.rows;
    const data = pageNo + '/' + pageCount;
    this.apisService.getApi(environment.resume + data + '/' + this.userId).subscribe((res: any) => {
      this.loader = false;
      this.recordslnt = res.data.count;
      this.res1 = res;
      this.gridRes = res.data.records;
      this.gridresponseData = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.gridRes.length; i++) {
        if (this.gridRes[i].lkStatus === 'A') {
          this.gridresponseData.push(this.gridRes[i]);
        }
      }
      if (this.recordslnt !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.grid();
  }
  gridDownLoad() {
    this.isUploading1 = true;
    this.filedata = this.rowData.id;
    this.apisService.getApi(environment.downloadedattachment + '/' + this.filedata).subscribe((res: any) => {
      this.downloadData = res;
      window.location.assign(this.downloadData.downloadUrl);
      this.isUploading1 = false;
    });
  }
  statusDD() {
    this.apisService.getApi(environment.lookupsData + 'RLS').subscribe((res: any) => {
      this.status = res.data;
      this.status.forEach(element => {
        this.statuslist[element.code] = element.label;
      });
    });
  }
  showDialog() {
    this.resumeForm.reset();
    this.filesdatass = false;
    this.display = true;
    this.disable = false;
  }
  _handleReaderLoaded1(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    this.bas2 = this.base + this.base64textString;
  }
  handleFileInput(event) {
    this.fileName = event.target.files[0];
    this.name = this.fileName.name;
    if (this.fileName.name.length > 25){
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'File name should be 25 alphabets.' });
      this.cancelfiles();
    }
    if (this.fileName.size > 10485760){
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please upload below 10MB.' });
      this.cancelfiles();
     }
   else{
    this.fileType = this.fileName.name.split('.').pop();
    const formData: FormData = new FormData();
    formData.append('file', this.fileName);
    this.filedata = formData;
    // tslint:disable-next-line: no-conditional-assignment
    if (this.filedata = formData) {
      this.filesdatass = true;
    } else {
      this.filesdatass = false;
    }
  }
  }
  uploadAttachment() {
    if (this.resumeForm.invalid) {
      const controls = this.resumeForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    }
    else {
      this.hide = true;
      this.disable = true;
      this.apisService.postApi(environment.profileUpload + this.userId + '/' + 'R', this.filedata).subscribe((res: any) => {
        this.fileRes = res;
        this.fileData = res.data;
        this.filePath = res.data.path;
        this.fileSize = res.data.fileSize;
        this.hide = false;
        this.addProfile(this.data);
        if (this.fileRes.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.fileRes.message });
          this.grid();
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.fileRes.error });
        }
      });
    }
    this.disable = false;
  }
  addProfile(data) {
    if (this.resumeForm.invalid) {
      const controls = this.resumeForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      const addData = {
        objectId: this.userId,
        objectType: 'U',
        // tslint:disable-next-line:max-line-length
        title: this.resumeForm.controls.attachmentTitle.value === undefined || this.resumeForm.controls.attachmentTitle.value === null || this.resumeForm.controls.attachmentTitle.value === '' ? '' : this.resumeForm.controls.attachmentTitle.value,
        attPath: this.filePath,
        fileName: this.fileName.name,
        size: this.fileSize,
        extension: this.fileType,
        // tslint:disable-next-line:max-line-length
        comments: this.resumeForm.controls.comments.value === undefined || this.resumeForm.controls.comments.value === null || this.resumeForm.controls.comments.value === '' ? '' : this.resumeForm.controls.comments.value,
        verifiedBy: 0,
        attType: 'R'
      };
      this.apisService.postApi(environment.addProfile, addData).subscribe((res: any) => {
        this.getData = res;
        this.disable = false;
        this.display = false;
        if (this.getData.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message, });
          this.grid();
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error });
        }
      });
    }
  }
  // It is used for the adding the save Attachment Details, converting date format and keep the validations
  saveAttachmentDetails() {
    if (this.resumeForm.invalid) {
      const controls = this.resumeForm.controls;
      Object.keys(controls).forEach(key => {
        controls[key].markAsTouched();
      });
    } else if (this.bas2 === undefined) {
      this.msgs = [];
    } else if (this.bas2.length > 0) {
      this.base64File = this.bas2.substring(this.bas2.indexOf(',') + 1);
      const body = {
        consId: this.userIdValue,
        attachmentType: 'CV',
        consultAttachmentFileName: this.file2,
        userSessionId: this.userIdValue,
        attachmentTitle: this.resumeForm.controls.attachmentTitle.value, // 'verification1',
        base64: this.base64File
      };
    }
  }
  // It is used for open the edit resume dialog box
  showDialog1(rowData) {
    this.rowId = rowData.id;
    this.display1 = true;
    this.resumeForm.reset();
    this.profileTilte = rowData.title;
    this.editResumeForm.controls.editAttachmentStatus.patchValue(this.status.filter(x => x.code === rowData.lkStatus)[0]);
    this.editResumeForm.get('editComments').patchValue(rowData.comments);
    this.editResumeForm.get('editAttachmentTitle').patchValue(rowData.title);
    this.lkstatus = rowData.lkStatus;
    if (this.lkstatus === 'I') {
      this.disable = true;
    }
    else {
      this.disable = false;
    }
  }
  updateProfile() {
    if (this.editResumeForm.invalid) {
      const controls = this.editResumeForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    }
    else {
      this.hide = true;
      this.disable = true;
      const dataValue1 = {
        id: this.rowId,
        // tslint:disable-next-line:max-line-length
        status: this.editResumeForm.controls.editAttachmentStatus.value === undefined || this.editResumeForm.controls.editAttachmentStatus.value === null || this.editResumeForm.controls.editAttachmentStatus.value === '' ? '' : this.editResumeForm.controls.editAttachmentStatus.value.code,
        comments: this.editResumeForm.controls.editComments.value,
        title: this.editResumeForm.controls.editAttachmentTitle.value,
      };
      this.apisService.putApi(environment.attachmentUpdate, dataValue1).subscribe((res: any) => {
        this.updateRes = res;
        this.display1 = false;
        this.hide = false;
        if (this.updateRes.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateRes.message });
          this.disable = false;
          this.grid();
        } else if (this.updateRes.hasOwnProperty('failed') === true) {
          this.messageService.add({
            key: 'br', severity: '', life: 3000, detail: this.updateRes.failed,
          });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateRes.error });
        }
      });
    }
  }
  statusdisable() {
    if (this.editResumeForm.controls.editAttachmentStatus.value.code === 'A') {
      this.disable = true;
      const acctdetails = JSON.parse(this.apisService.decodeData());
      this.userId = acctdetails.userId;
      this.apisService.getApi(environment.activeresume + this.userId).subscribe((res: any) => {
        this.data1 = res;
        this.activeresume = res.data;
        if (this.data1.data === false) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.data1.message });
          this.display = false;
        }
        else {
          this.uploadAttachment();
        }
      });
    }
  }
  profiled() {
    if (this.resumeForm.invalid) {
      const controls = this.resumeForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      const acctdetails = JSON.parse(this.apisService.decodeData());
      this.disable = true;
      this.userId = acctdetails.userId;
      this.apisService.getApi(environment.activeresume + this.userId).subscribe((res: any) => {
        this.data1 = res;
        this.activeresume = res.data;
        if (this.data1.data === false) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.data1.message });
          this.display = false;
        }
        else {
          this.uploadAttachment();
        }
        this.disable = false;
      });
    }
  }
  cancelfiles() {
    this.resumeForm.controls.fileupload.reset();
    this.filesdatass = false;
    this.classinput = false;
    this.profileupload = true;
  }
}
