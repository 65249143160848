<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
    <p-accordion>
        <p-accordionTab [selected]="!recCandidates">
            <p-header>
                <strong>Requirement Id : </strong><span class="panelLabel">{{this.reqId}}</span>
                <strong class="positionheader">Position Title : </strong><span class="panelLabel">
                    {{this.reqName}}</span><strong class="positionheader">Max Rate :
                </strong><span class="panelLabel">
                    {{this.maxrate | currency: currencyValue : true}}
                </span>
                <em class="pi pi-arrow-left backRoute" (click)="backRouting()">
                </em>
            </p-header>
            <form [formGroup]="editRequirementsForm">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                        <label class="first">Position Title<span class="mandatoryColor">*</span></label>
                        <input id="input" type="text" maxlength="51" pKeyFilter="int" autocomplete="off" pInputText
                            formControlName="positionTitle" placeholder="Enter Title(Ex : Project Developer)">
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('positionTitle').touched && this.editRequirementsForm.get('positionTitle').invalid && this.editRequirementsForm.get('positionTitle').errors.required">
                                Please enter position title
                            </span>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('positionTitle').invalid && this.editRequirementsForm.get('positionTitle').errors.pattern">
                                Please enter alphabets only
                            </span>
                        </div>
                        <div>
                            <span class="validationtest"
                                *ngIf=" this.editRequirementsForm.get('positionTitle').hasError('maxlength')"> Please
                                enter 50
                                alphabets only </span>
                        </div>
                    </div>
                    <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                        <label class="first">Position Category<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="positionData" placeholder="Select Position Category " optionValue="id"
                            [showClear]="true" (onChange)="getRolesBasedonCategory($event.value)"
                            optionLabel="jobCategory" formControlName="positionCategory"></p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('positionCategory').touched && this.editRequirementsForm.get('positionCategory').invalid && this.editRequirementsForm.get('positionCategory').errors.required">
                                Please select Position Category
                            </span>
                        </div>
                    </div>
                    <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                        <label class="first">Job Role<span class="mandatoryColor">*</span> </label>
                        <p-dropdown [options]="jobTitleData" placeholder="Select Job Role " optionValue="id"
                            [showClear]="true" (onChange)="getMinrate($event.value)" optionLabel="title"
                            formControlName="jobRole"></p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('jobRole').touched && this.editRequirementsForm.get('jobRole').invalid && this.editRequirementsForm.get('jobRole').errors.required">
                                Please select Job Role
                            </span>
                        </div>
                    </div>
                    <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                        <label class="first">Department<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="departmentList" placeholder="Select Department" [showClear]="true"
                            optionLabel="name" optionValue="id" formControlName="department"></p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('department').touched && this.editRequirementsForm.get('department').invalid && this.editRequirementsForm.get('department').errors.required">
                                Please select department
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class=" p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                        <label class="first">Min Rate </label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                            <input type="text" pInputText placeholder="Min Rate" disabled="true"
                                formControlName="minRate">
                            <span class="p-inputgroup-addon">/Hr</span>
                        </div>
                        <span class="validationtest"
                            *ngIf="this.editRequirementsForm.get('minRate').invalid && this.editRequirementsForm.get('minRate').errors.pattern">
                            Please enter numbers only
                        </span>
                    </div>
                    <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                        <label class="first">Max Rate <span class="mandatoryColor">*</span></label>
                        <div class="p-inputgroup"> <span class="p-inputgroup-addon">{{durationCal(0 | currency:
                                currencyValue : true)}} </span>
                            <input type="text" pInputText placeholder="Max Rate" formControlName="maxRate">
                            <span class="p-inputgroup-addon">/Hr</span>
                        </div>
                        <span class="validationtest"
                        *ngIf="this.editRequirementsForm.get('maxRate').touched && this.editRequirementsForm.get('maxRate').invalid && this.editRequirementsForm.get('maxRate').errors.required">
                        Please enter max rate
                    </span>
                        <span class="validationtest"
                            *ngIf="this.editRequirementsForm.get('maxRate').invalid && this.editRequirementsForm.get('maxRate').errors.pattern">
                            Please enter numbers only
                        </span>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                        <label class="first">Number of Positions<span class="mandatoryColor">*</span></label>
                        <input id="input" type="text" maxlength="12" pKeyFilter="int" autocomplete="off" pInputText
                            optionValue="code" formControlName="positions" placeholder="Number of Positions ">
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('positions').touched && this.editRequirementsForm.get('positions').invalid && this.editRequirementsForm.get('positions').errors.required">
                                Please enter number of Positions
                            </span>
                            <span class="validationtest"
                                *ngIf=" this.editRequirementsForm.get('positions').hasError('maxlength')"> Please enter
                                11 numbers only </span>
                            <div>
                                <span class="validationtest"
                                    *ngIf="this.editRequirementsForm.get('positions').invalid && this.editRequirementsForm.get('positions').errors.pattern">
                                    Please enter numbers only
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                        <label class="first">Work Location<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="locationList" placeholder="Select Work Location" [showClear]="true"
                            optionLabel="name" optionValue="locId" formControlName="workLocation"></p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('workLocation').touched && this.editRequirementsForm.get('workLocation').invalid && this.editRequirementsForm.get('workLocation').errors.required">
                                Please select Work Location
                            </span>
                        </div>
                    </div> -->
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory"
                        pTooltip="{{this.patchlocationString}}" tooltipPosition="top">
                        <label class="inputfields">Work Location<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="locationList" placeholder="Select Work Location" optionLabel="name"
                            [showClear]="true" formControlName="workLocation" appendTo="body"
                            (onChange)="saveLocation($event)">
                            <ng-template let-data pTemplate="item">
                                <div pTooltip="{{data.name + '\n' + data.address1+',' + data.city + ',' + data.state + ',' + data.country}}"
                                    tooltipPosition="right">
                                    {{ data.name }}
                                </div>
                            </ng-template>

                        </p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.workLocation.touched && this.editRequirementsForm.controls.workLocation.invalid && this.editRequirementsForm.controls.workLocation.errors.required">
                                Please select work location
                            </span>
                        </div>
                    </div>
                    <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                        <label class="first">Required Experience<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="exprienceList" placeholder="Select Required Experience"
                            [showClear]="true" optionLabel="label" optionValue="code" formControlName="experience">
                        </p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('experience').touched && this.editRequirementsForm.get('experience').invalid && this.editRequirementsForm.get('experience').errors.required">
                                Please select experience
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                        <label class="first">Hire Type<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="hireTypeList" (onChange)="typeData($event.value)" [showClear]="true"
                            placeholder="Select Hire Type" optionLabel="label" optionValue="code"
                            formControlName="hireType"></p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('hireType').touched && this.editRequirementsForm.get('hireType').invalid && this.editRequirementsForm.get('hireType').errors.required">
                                Please select hire Type
                            </span>
                        </div>
                    </div>
                    <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                        <label class="first">Planned Start Date<span class="mandatoryColor">*</span></label>
                        <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="startDate"
                            placeholder="MM-DD-YYYY" [showIcon]="true" [showButtonBar]="true">
                        </p-calendar>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('startDate').touched && this.editRequirementsForm.get('startDate').invalid && this.editRequirementsForm.get('startDate').errors.required">
                                Please select planned start date
                            </span>
                        </div>
                    </div>
                    <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3" *ngIf="endDisplay">
                        <label class="first">Planned End Date<span class="mandatoryColor">*</span></label>
                        <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="endDate" placeholder="MM-DD-YYYY"
                            [showIcon]="true" [showButtonBar]="true">
                        </p-calendar>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('endDate').touched && this.editRequirementsForm.get('endDate').invalid && this.editRequirementsForm.get('endDate').errors.required">
                                Please select planned end date
                            </span>
                        </div>
                    </div>
                    <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                        <label class="first">Status<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="statusList" placeholder="Select Status" [showClear]="true"
                            optionLabel="label" formControlName="status"></p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('status').touched && this.editRequirementsForm.get('status').invalid && this.editRequirementsForm.get('status').errors.required">
                                Please select status
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                        <label>Employee approval required? </label>&nbsp;
                        <!-- <p-radioButton name="approval" value="Y" label="Yes" formControlName="approval" inputId="Yes">
                        </p-radioButton>
                        <p-radioButton name="approval" value="N" label="No" formControlName="approval" inputId="No"
                            class="radiopos"> -->

                        <p-radioButton name="approval" value="Y" label="Yes" formControlName="approval" inputId="Yes" [disabled]="true">
                        </p-radioButton>
                        <p-radioButton name="approval" value="N" label="No" formControlName="approval" inputId="No" [disabled]="true"
                            class="radiopos">
                        </p-radioButton>
                    </div>
                </div><br>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                        <label class="first">Billing Type<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="billingList" placeholder="Select Billing Type" [showClear]="true"
                            optionLabel="label" optionValue="code" formControlName="billingType"></p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('billingType').touched && this.editRequirementsForm.get('billingType').invalid && this.editRequirementsForm.get('billingType').errors.required">
                                Please select billing type
                            </span>
                        </div>
                    </div>
                    <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                        <label class="first">Duration</label>
                        <div class="p-inputgroup">
                            <!-- <span class="p-inputgroup-addon">{{durationCal(0 | currency:
                                currencyValue : true)}} </span> -->
                            <input type="text" pInputText placeholder="Duration" formControlName="duration"
                                (blur)="getduration()">
                            <span class="p-inputgroup-addon">Months</span>
                        </div>
                        <span class="validationtest"
                            *ngIf="this.editRequirementsForm.get('duration').invalid && this.editRequirementsForm.get('duration').errors.pattern">
                            Please enter numbers only
                        </span>
                        <span class="validationtest">
                            {{this.showmsg}}
                        </span>
                    </div>
                    <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                        <label class="first">Per Week</label>
                        <div class="p-inputgroup">
                            <!-- <span class="p-inputgroup-addon">{{durationCal(0 | currency:
                                currencyValue : true)}} </span> -->
                            <input type="text" pInputText placeholder="Per Week" formControlName="perWeek">
                            <span class="p-inputgroup-addon">Hrs</span>
                        </div>
                        <span class="validationtest"
                            *ngIf="this.editRequirementsForm.get('perWeek').invalid && this.editRequirementsForm.get('perWeek').errors.pattern">
                            Please enter numbers only
                        </span>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6 p-md-6 p-sm-12 p-lg-6">
                        <label class="first">Skill Set<span class="mandatoryColor">*</span></label>
                        <p-multiSelect [options]="skillsList" [showToggleAll]="true"
                            formControlName="requirementsSkillSet" placeholder="Select Skill Set" maxSelectedLabels="15"
                            optionLabel="label"   (onChange)="skillsetOthers($event.value)"></p-multiSelect>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('requirementsSkillSet').touched && this.editRequirementsForm.get('requirementsSkillSet').invalid && this.editRequirementsForm.get('requirementsSkillSet').errors.required">
                                Please select skill set
                            </span>
                        </div>
                        <div class="skillstyle">
                            <label><strong>Skill Set: </strong></label>
                            <span >{{primarySkills.toString()}}</span>
             
                          </div>
                    </div>
                    <div class="p-field p-col-6 p-md-6 p-sm-12 p-lg-6">
                        <label class="first">Preferred Skills Set<span class="mandatoryColor">*</span></label>
                        <p-multiSelect [options]="skillsList" [showToggleAll]="true" formControlName="preferredSkills"
                        (onChange)="resetskills($event,$event.value)"  placeholder="Select Preferred Skill Set" maxSelectedLabels="15" optionLabel="label">
                        </p-multiSelect>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('preferredSkills').touched && this.editRequirementsForm.get('preferredSkills').invalid && this.editRequirementsForm.get('preferredSkills').errors.required">
                                Please select preferred skill set
                            </span>
                        </div>
                        <div class="skillstyle">
                            <label><strong>Preferred Skill Set: </strong></label>
                            <span >{{skillsList12.toString()}}</span>
             
                            <!-- <strong>Preferred Skill Set: </strong><label>{{skillsList12.toString()}}</label> -->
                        </div>
                        <br>
                    </div>
                </div>

                <div class="p-fluid  p-grid" *ngIf="otherskills">
                    <div class="p-field p-col-12 p-md-6 p-sm-12 p-lg-6">
                        <label class="inputfields">Others Skills<span class="mandatoryColor">*</span></label>
                        <textarea rows="5" maxlength="301" cols="30"
                            placeholder="Enter Others Skills (Ex: Angular, Java)" formControlName="otherskills"
                            (blur)=onBlur($event.target.value) pInputTextarea></textarea>
                        <span class="validationtest" *ngIf="this.editRequirementsForm.get('otherskills').touched &&
                             this.editRequirementsForm.get('otherskills').invalid &&
                              this.editRequirementsForm.get('otherskills').errors.required">
                            Please enter others skills
                        </span>
                        <span class="validationtest"
                            *ngIf=" this.editRequirementsForm.get('otherskills').hasError('maxlength')"> Please enter
                            300
                            alphabets only </span>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6 p-md-6 p-sm-12 p-lg-6">
                        <label class="first">Job Description<span class="mandatoryColor">*</span></label>
                        <textarea rows="5" cols="30" maxlength="2001" placeholder="Job Description"
                            formControlName="description" autoResize="false" pInputTextarea></textarea>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('description').touched && this.editRequirementsForm.get('description').invalid && this.editRequirementsForm.get('description').errors.required">
                                Please enter job description
                            </span>
                        </div>
                        <div>
                            <span class="validationtest"
                                *ngIf=" this.editRequirementsForm.get('description').hasError('maxlength')">
                                Please enter 2000 alphabets only
                            </span>
                        </div>
                    </div>
                    <div class="p-field p-col-6 p-md-6 p-sm-12 p-lg-6">
                        <label class="first">Responsibilities<span class="mandatoryColor">*</span></label>
                        <textarea rows="5" cols="30" maxlength="2001" placeholder="Responsibilities"
                            formControlName="responsibilities" autoResize="false" pInputTextarea></textarea>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('responsibilities').touched && this.editRequirementsForm.get('responsibilities').invalid && this.editRequirementsForm.get('responsibilities').errors.required">
                                Please enter responsibilities
                            </span>
                        </div>
                        <div>
                            <span class="validationtest"
                                *ngIf=" this.editRequirementsForm.get('responsibilities').hasError('maxlength')">
                                Please enter 2000 alphabets only
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6 p-md-6 p-sm-12 p-lg-6">
                        <label class="first">Qualification<span class="mandatoryColor">*</span></label>
                        <textarea rows="5" maxlength="501" cols="30" placeholder="Qualification"
                            formControlName="qualification" autoResize="false" pInputTextarea></textarea>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('qualification').touched && this.editRequirementsForm.get('qualification').invalid && this.editRequirementsForm.get('qualification').errors.required">
                                Please enter qualification
                            </span>
                        </div>
                        <div>
                            <span class="validationtest"
                                *ngIf=" this.editRequirementsForm.get('qualification').hasError('maxlength')">
                                Please enter 500 alphabets only
                            </span>
                        </div>
                    </div>
                    <div class="p-field p-col-6 p-md-6 p-sm-12 p-lg-6">
                        <label class="first">Comments</label>
                        <textarea rows="5" maxlength="501" autoResize="false" cols="30" placeholder="Comments" formControlName="comments"
                            pInputTextarea></textarea>
                        <span class="validationtest"
                            *ngIf=" this.editRequirementsForm.get('comments').hasError('maxlength')">
                            Please enter 500 alphabets only
                        </span>
                    </div>
                </div>
            </form>
            <div class="btnsCSS1">
                <button *ngIf="submitBtn" [disabled]="disablebtn" pButton class="addhBtn" type="button"
                    icon='pi pi-save' (click)="updateRequirement()" label="Submit"></button>
            </div>
            <br><br>
        </p-accordionTab>
    </p-accordion>
</p-card>
<h1></h1>
<form [formGroup]="receivedCandidates">
    <div *ngIf="recCandidates">
        <p-card>
            <p-panel>
                <p-header>
                    <strong>Received Candidates</strong>
                </p-header>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-3 p-lg-3">
                        <label class="first">Frist Name</label>
                        <input id="input" type="text" maxlength="20" autocomplete="off" pInputText
                            formControlName="fristName" placeholder="Frist Name" (keyup.enter)="gridTable()">
                    </div>
                    <div class="p-field p-col-12 p-md-3 p-lg-3">
                        <label class="first">Last Name</label>
                        <input id="input" type="text" maxlength="30" autocomplete="off" pInputText
                            formControlName="lastName" placeholder="Last Name" (keyup.enter)="gridTable()">
                    </div>
                    <div class="p-field p-col-12 p-md-3 p-lg-3">
                        <label class="first">Email</label>
                        <input id="input" type="text" maxlength="30" autocomplete="off" pInputText
                            formControlName="email" placeholder="Email" (keyup.enter)="gridTable()">
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <label class="first">Status</label>
                        <p-dropdown [options]="status" placeholder="Select Status" [showClear]="true"
                            optionLabel="label" formControlName="status" (keyup.enter)="gridTable()"></p-dropdown>
                    </div>
                </div>
                <br><br><br>
                <div class="btnsCSS1 btnalign">
                    <button pButton class="addhBtn" type="button" icon='pi pi-refresh' label="Reset"
                        (click)="reset()"></button>
                    <button pButton class="addhBtn" type="button" icon='pi pi-search' (click)="gridTable()"
                        label="Search"></button>
                </div>
            </p-panel>
        </p-card>
        <h1></h1>
        <p-card class="pgpos">
            <div *ngIf="isUploading1" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <p-progressBar mode="indeterminate" [style]="{'height':'6px'}"></p-progressBar>
            </div>
            <div *ngIf="table">
                <p-table [columns]="cols" id="excel-table" [autoLayout]="true" dataKey="consultantName"
                    [value]="gridData" selectionMode="single" [rows]="7" [rowsPerPageOptions]="[10,20,30]"
                    sortMode="multiple">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th id="gridHeader" *ngFor="let col of columns let i=index"
                                (click)="iconToggle[i] = !iconToggle[i]" [pSortableColumn]="col.field"
                                [hidden]="col.sno == 5">
                                {{col.header}}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-Data let-expanded="expanded">
                        <tr>
                            <td class="textCenter"> {{Data.vendorName}} </td>
                            <td class="textCenter"> {{Data.consultantName}} </td>
                            <td class="textCenter">{{Data.submittedDate}}</td>
                            <td class="textCenter pointer">
                                <a (click)="op.toggle($event)" (click)="reqSkills1(getSkillSetTable(Data.skills))"
                                    *ngFor="let data1 of getSkillSetTable(Data.skills); index as i;">
                                    <p-badge class="badgespace newsty" *ngIf='i<1' [value]="data1"></p-badge>
                                    <p-badge *ngIf='i===1' [value]="'+'+(Data.skills.length-1)+' more'"></p-badge>
                                </a>
                            </td>
                            <td class="pointer" [hidden]="true">
                                <a (click)="op.toggle($event)" (click)="reqSkills1(getSkillSetTable(Data.skills))"
                                    *ngFor="let data1 of getSkillSetTable(Data.skills); index as i;">
                                    <p-badge [value]="data1+','"></p-badge>
                                </a>

                            </td>

                            <td class="textCenter">{{statusList11[Data.profileState]}}</td>
                            <td class="textCenter"> {{Data.billRate | currency: currencyValue : true}} </td>
                            <td class="textCenter"><a>
                                    <em (click)="actionpopup.toggle($event);  toggle(items, $event, Data)"
                                        class="pi pi-ellipsis-v pointer"></em>
                                </a></td>
                        </tr>
                    </ng-template>
                </p-table>
                <div class="p-grid ui-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 totalrec" style="display: flex;">
                        <h3 class="norecorddata ">Total records : {{this.subProRecords}}</h3>
                        <p class="downpos">
                            <span class="ui-column-title pointer"
                                (click)="downloadActionPopup.toggle($event); downLoadsToggle()">
                                <em class="pi pi-download downloadspace"></em>
                                <strong>Download</strong></span>
                        </p>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
                        <p-paginator [rows]="10" [totalRecords]="subProRecords" [rowsPerPageOptions]="[10,20,30]"
                            (onPageChange)="paginate($event)" #p></p-paginator>
                    </div>
                </div>
            </div>
            <p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="downloadOptions"></p-menu>
            <p-card *ngIf="records">
                <div class="norecordImgsize">
                    <div *ngIf="records" class="aligncenter">
                        <img alt="babylon-layout" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png"
                            class="norecordimg">
                        <br><em>Sorry, we are not able to find any candidates</em><br>
                    </div>
                </div>
            </p-card>
        </p-card>
        <p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
    </div>
    <p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '25%' }">
        <label class="inputfields">Skill Details</label>
        <ng-template pTemplate>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-12 bordersty">
                    <p class="skpos">
                        <p-badge class="badgespace badgesp" *ngFor="let data2 of reqSkillsValue; index as i"
                            [value]="data2" [ngClass]="i%2===0? 'newsty' : ''">
                        </p-badge>
                    </p>
                </div>
            </div>
        </ng-template>
    </p-overlayPanel>
    <p-dialog class="dialogBox" [contentStyle]="{'overflow': 'visible'}" [(visible)]="selected"
        header="Select Candidate" [modal]="true" [style]="{width: '20%'}" [baseZIndex]="1" [draggable]="false"
        [resizable]="false">
        <form [formGroup]="receivedCandidateForm">
            <div class="p-grid p-fluid p-justify-center">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <p><strong>Candidate Name :</strong> {{this.candidatename}}
                </div>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <label class="inputfields">Onboard date<span class="mandatoryColor">*</span></label><br>
                    <p-calendar id="popup" placeholder="MM-DD-YYYY" dateFormat="mm-dd-yy" [showIcon]="true"
                        [showButtonBar]="true" formControlName="onboardDate1" [minDate]="startCurDate">
                    </p-calendar>
                    <span class="validationtest"
                        *ngIf="this.receivedCandidateForm.controls.onboardDate1.touched && this.receivedCandidateForm.controls.onboardDate1.invalid && this.receivedCandidateForm.controls.onboardDate1.errors.required">
                        Please select onboard date
                    </span>
                </div>
            </div>
            <br>
            <div class="btnsCSS1">
                <button pButton type="button" icon="pi pi-save" label="Submit" (click)="submitCandidate()"
                    class="addhBtn" [disabled]="hide"></button>
            </div>
        </form>
    </p-dialog>
    <p-dialog class="dialogBox" [contentStyle]="{'overflow': 'visible'}" header="Reject Candidate"
        [(visible)]="rejectpopup" [style]="{width: '20%'}" [baseZIndex]="1" [draggable]="false" [modal]="true"
        [resizable]="true">
        <div class="p-fluid p-grid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields ">Reject Reason<span class="mandatoryColor">*</span> </label>
                <p-dropdown [options]="rejectopention" placeholder="Select Reject Reason" optionValue="code"
                    class="radiopos" [showClear]="true" (onChange)="getRejectOption($event.value)" optionLabel="label"
                    formControlName="reject"></p-dropdown>
            </div>
        </div>
        <br>
        <div class="alignleft">
            <button pButton type="button" icon="pi pi-save" label="Submit" (click)="submiteReject()" [disabled]="hide"
                class="addhBtn"></button>
        </div>
    </p-dialog>
    <p-dialog class="dialogBox" [contentStyle]="{'overflow': 'visible'}" header="Candidate Reject Reason"
        [(visible)]="candidaterejectpopup" [style]="{width: '20%'}" [baseZIndex]="1" [draggable]="false" [modal]="true"
        [resizable]="true">
        <div class="p-fluid p-formgrid p-grid">
            <label class="firsts"> Reject Reason </label>
            <p-dropdown [disabled]="true" [options]="rejectReasonData" placeholder="Select Reject Reason"
                [showClear]="true" optionLabel="label" formControlName="rejectReason"></p-dropdown>
        </div>
    </p-dialog>
</form>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight">
                <div class="laodersize">
                    <img alt="babylon-layout" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>

<p-dialog [contentStyle]="{'overflow': 'visible'}" class="dialogBox" header="Other Details" [(visible)]="displayOtherDetails"
  [modal]="true" [style]="{width: '30%'}" [baseZIndex]="1" [draggable]="false" [resizable]="false">
  <form [formGroup]="getotherDetails">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <label class="inputfields">Address Line 1</label>
        <input id="input" type="text" autocomplete="off" pInputText placeholder="Address Line 1"
          formControlName="addressControl" maxlength="51">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
        <label class="inputfields">Address Line 2</label>
        <input id="input" type="text" autocomplete="off" pInputText placeholder="Address Line 2"
          formControlName="addressFormControl" maxlength="51">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
        <label class="inputfields" id='csslabel'>Mailbox</label>
        <input type="text" autocomplete="off" maxlength="11" pInputText placeholder="Mailbox"
          formControlName="mailbox1">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
        <label class="inputfields">Country</label>
        <input autocomplete="off" id="input" type="text" pInputText pKeyFilter="num" maxlength="11" pInputText
        placeholder="Country" formControlName="Country">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
        <label class="inputfields">State</label>
          <input autocomplete="off" id="input" type="text" pInputText pKeyFilter="num" maxlength="11" pInputText
          placeholder="State" formControlName="State">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
        <label class="inputfields">City</label>
        <input autocomplete="off" id="input" type="text" pInputText placeholder="City" formControlName="cityForm"
          maxlength="21">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
        <label class="inputfields">Zip</label>
        <input autocomplete="off" id="input" type="text" pInputText pKeyFilter="num" maxlength="11" pInputText
          placeholder="Zip" formControlName="zipForm">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
        <label class="inputfields">Work Satus</label>
        <input autocomplete="off" id="input" type="text"  maxlength="11" pInputText
          placeholder="Work Satus" formControlName="workSatus">
      </div>
    </div>
    <br>
  </form>
</p-dialog>