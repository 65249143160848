import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-tech-evalution',
  templateUrl: './tech-evalution.component.html',
  styleUrls: ['./tech-evalution.component.scss'],
  providers: [DatePipe, MessageService],
})
export class TechEvalutionComponent implements OnInit {
  techReviews: FormGroup;
  records: boolean;
  cols = [
    { field: 'jobTitle', header: 'Position Title', width: '10%' },
    { field: 'username', header: 'Canditate Name', width: '12%' },
    { field: 'attributes', header: 'Interview Mode', width: '10%' },
    { field: 'interviewDate', header: 'Interview Date & Time', width: '12%' },
    { field: 'assignDate', header: 'Assign Date', width: '6%' },
    { field: 'reviewDate', header: 'Review Date', width: '6%' },
    { field: 'status', header: 'Status', width: '6%' },
    { field: 'action', header: 'Action', width: '5%' }
  ];
  loader: boolean;
  totalRecords: any;
  table: boolean;
  userData: any;
  rowData: any;
  items: any = [];
  resumeRes: any;
  interviewTypeList = [];
  interviewTypeLabel = {};
  statusList = [];
  statusTable = {};
  first: any;
  pageCount: any;
  rows: any = 10;
  page: any = 1;
  gridData: any;
  isUploading1: boolean;
  resumeReq: any;
  parentReqId: any;
  // tslint:disable-next-line: max-line-length
  constructor(private router: Router, private formBuilder: FormBuilder, private datepipe: DatePipe, private apisService: ApisService, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Tech Reviews', routerLink: '/tech-evalution' },
    ]);
  }
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchData();
    }
  }
  ngOnInit(): void {
    this.userData = JSON.parse(this.apisService.decodeData());
    this.techReviews = this.formBuilder.group({
      candidatesName: [''],
      candidatesMode: [''],
      candidatesAssignDate: [''],
      candidatesReviewDate: [''],
      candidatesStatus: [''],
    });
    this.getInterviewType();
    this.gridTable();
  }
  // For Interview dropdown
  getInterviewType() {
    this.apisService.getApi(environment.getInterviewType).subscribe((res: any) => {
      this.interviewTypeList = res.data;
      this.interviewTypeList.forEach((element) => {
        this.interviewTypeLabel[element.code] = element.label;
      });
      this.getInterviewStatus();
    });
  }
  // For Interview-status dropdown
  getInterviewStatus() {
    this.apisService.getApi(environment.interviewStatus).subscribe((res: any) => {
      this.statusList = res.data;
      this.statusList.forEach((element) => {
        this.statusTable[element.code] = element.label;
      });
    });
  }
  // Table data
  gridTable() {
    this.loader = true;
    const data = this.page + '/' + this.rows + '/' + this.userData.userId;
    this.apisService.getApi(environment.techReviews + data).subscribe((res: any) => {
      this.gridData = res.data;
      this.loader = false;
      this.totalRecords = res.count;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  // table paginator
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridTable();
  }
  // Searching Grid data
  searchData() {
    this.loader = true;
    // tslint:disable-next-line:max-line-length
    const cndName = this.techReviews.controls.candidatesName.value === undefined ? '' : this.techReviews.controls.candidatesName.value === null ? '' : this.techReviews.controls.candidatesName.value === '' ? '' : this.techReviews.controls.candidatesName.value;
    // tslint:disable-next-line:max-line-length
    const inretviewMode = this.techReviews.controls.candidatesMode.value === undefined ? '' : this.techReviews.controls.candidatesMode.value === null ? '' : this.techReviews.controls.candidatesMode.value === '' ? '' : this.techReviews.controls.candidatesMode.value;
    const assignDate = this.techReviews.controls.candidatesAssignDate.value;
    const asgDate = assignDate === undefined || assignDate === null || assignDate === '' ? '' : this.datepipe.transform(assignDate, 'MM-dd-yyyy');
    const reviewdate = this.techReviews.controls.candidatesReviewDate.value;
    const revDate = reviewdate === undefined || reviewdate === '' || reviewdate === null ? '' : this.datepipe.transform(reviewdate, 'MM-dd-yyyy');
    // tslint:disable-next-line:max-line-length
    const status = this.techReviews.controls.candidatesStatus.value === undefined ? '' : this.techReviews.controls.candidatesStatus.value === null ? '' : this.techReviews.controls.candidatesStatus.value === '' ? '' : this.techReviews.controls.candidatesStatus.value;
    const data = this.page + '/' + this.rows + '/' + this.userData.userId;
    this.apisService.getApi(environment.techReviews + data + '?candidateName=' + cndName + '&interviewMode=' + inretviewMode + '&assignedDate=' + asgDate + '&reviewsDate=' + revDate + '&status=' + status).subscribe((res: any) => {
      this.gridData = res.data;
      this.totalRecords = res.count;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.loader = false;
    });
  }
  //  For toggle view
  toggle(menu, event, rowData) {
    this.rowData = rowData;
    this.parentReqId = rowData.parentReqId;
    if (this.rowData.status === 'E') {
      this.items = [
      // tslint:disable-next-line: no-shadowed-variable
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        { label: 'Show Review', icon: 'pi pi-fw pi-star-o', command: (event: any, i: any) => { this.ratings(); }, }
      ];
    } else if (this.rowData.status === 'A') {
      this.items = [
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        // tslint:disable-next-line: no-shadowed-variable
        { label: 'Review', icon: 'pi pi-fw pi-star-o', command: (event: any, i: any) => { this.ratings(); }, },
      ];
    }
  }
  // For showing Ratings
  ratings() {
    const data = this.rowData.id;
    this.router.navigate(['/add-techreviews', this.rowData.id], {
      queryParams: {
        rowId: data,
        jobId: this.rowData.requirementId,
        positionTitle: this.rowData.positionName,
        profileid: this.rowData.reqProfileId,
        parentReqId : this.parentReqId,
        createdbyaccId : this.rowData.createdByAccId
      },
    });
  }
  // Grid reset
  reset() {
    this.loader = true;
    this.techReviews.reset();
    this.gridTable();
  }
  // For download file
  downloadFileapi() {
    this.isUploading1 = true;
    this.resumeReq = this.rowData.resumeId;
    this.apisService.getApi(environment.downloadedattachment + '/' + this.resumeReq).subscribe((res) => {
      this.isUploading1 = false;
      this.resumeRes = res;
      window.location.assign(this.resumeRes.downloadUrl);
    });
  }
}
