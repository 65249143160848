import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-accountregistrationpage',
  templateUrl: './accountregistrationpage.component.html',
  styleUrls: ['./accountregistrationpage.component.scss'],
  providers: [DatePipe, MessageService],
})
export class AccountregistrationpageComponent implements OnInit {
  constructor(private router: Router) { }
  ngOnInit(): void {
  }
  goto() {
    this.router.navigate(['/login']);
  }
  navigateToRegisterForm() {
    this.router.navigate(['/registration'], {
      queryParams: {
        vendorRegistrationForm: 'V'
      }
    });
  }
  navigateToCustomerRegisterForm() {
    this.router.navigate(['/registration']);
  }
  navigateToconsultantRegisterForm() {
    this.router.navigate(['/consultantRegistration']);
  }
}
