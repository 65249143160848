import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { isEmpty } from 'rxjs/internal/operators/isEmpty';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-select-candidates',
  templateUrl: './select-candidates.component.html',
  styleUrls: ['./select-candidates.component.scss'],
  providers: [DatePipe, MessageService],
})
export class SelectCandidatesComponent implements OnInit {
  selectedForm: any;
  status: any = [];
  statuslist = {};
  loader: boolean;
  gridData: any;
  totalRecords: number;
  records: boolean;
  table: boolean;
  rows: any = 10;
  page: any = 1;
  cols = [
    { field: 'name', header: 'Vendor Name', width: '15%' },
    { field: 'deptNo', header: 'Candidate Name', width: '15%' },
    { field: 'deptNo', header: 'Skills', width: '15%' },
    { field: 'craetedBy', header: 'Bill Rate', width: '15%' },
    { field: 'status', header: 'Status ', width: '12%' },
    { field: '', header: 'Action', width: '5%' },
  ];
  first: any;
  pageCount: any;
  actId: any;
  accId: any;
  display: boolean;
  reqSkillsValue: any;
  skillsList: any = [];
  array: any = [];
  acctdetails: any;
  preferencList: any;
  currencyValue: string;
  currencycode: any = [];
  items: { label: string; icon: string; command: () => void; }[];
  displayModal: boolean;
  date: Date;
  histroy: any;
  userId: any;
  candidatename: any;
  newstatus: any = [];
  projecythistoryid: any;
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.gridTable();
    }
  }
  // tslint:disable-next-line:max-line-length
  constructor(private breadcrumbService: BreadcrumbService, private fb: FormBuilder, private apisService: ApisService, private router: Router) {
    this.breadcrumbService.setItems([
      { label: 'Selected Candidates', routerLink: '/selectedcandidates' },
    ]);
  }
  ngOnInit(): void {
    this.acctdetails = JSON.parse(this.apisService.decodeData());
    this.preferencList = this.acctdetails.preferencesList;
    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }
    this.actId = JSON.parse(this.apisService.decodeData());
    this.accId = this.actId.accId;
    this.userId = this.actId.userId;
    this.selectedForm = this.fb.group({
      selectVendorName: [''],
      selectCandidateName: [''],
      selectBillRate: [''],
      selectStatus: ['']
    });
    this.gridTable();
    this.gridstatus();
    this.reqSkillSet();
  }
  resetSelectedForm() {
    this.selectedForm.reset();
    this.gridTable();
  }
  // toggle(event, data, rowData) {
  //   this.projecythistoryid = rowData.conId;
  //   this.items = [{
  //     label: 'Project History', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(rowData); }
  //   },
  //   ];
  // }
  toggle(event, data, rowData) {
    this.projecythistoryid = rowData.conId;  
    this.items = [
      // {
      //   label: 'Edit', icon: 'pi pi-pencil', command: () => {
      //     this.router.navigate(['/editcandidate', rowData.conId], {
      //       queryParams: {
      //         flag: "C"
      //       }
      //     });
      //   },
      // },
      {
      label: 'Project History', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(rowData); }
      },
    ];
  }
  gridstatus() {
    this.apisService.getApi(environment.lookupsData + 'RPLPS').subscribe((res: any) => {
      this.status = res.data;
      this.status.forEach(element => {
        if (element.code === 'C' || element.code === 'J') {
          this.newstatus.push(element);
        }
      });
      this.status.forEach(element => {
        this.statuslist[element.code] = [element.label];
      });
    }
    );
  }
  gridTable() {
    this.loader = true;
    const pgNo = this.page;
    const recordPage = this.rows;
    // tslint:disable-next-line:max-line-length
    const vendorName = this.selectedForm.controls.selectVendorName.value === null || this.selectedForm.controls.selectVendorName.value === undefined || this.selectedForm.controls.selectVendorName.value === '' ? '' : this.selectedForm.controls.selectVendorName.value;
    // tslint:disable-next-line:max-line-length
    const candidateName = this.selectedForm.controls.selectCandidateName.value === null || this.selectedForm.controls.selectCandidateName.value === undefined || this.selectedForm.controls.selectCandidateName.value === '' ? '' : this.selectedForm.controls.selectCandidateName.value;
    // tslint:disable-next-line:max-line-length
    const billRat = this.selectedForm.controls.selectBillRate.value === null || this.selectedForm.controls.selectBillRate.value === undefined || this.selectedForm.controls.selectBillRate.value === '' ? '' : this.selectedForm.controls.selectBillRate.value;
    // tslint:disable-next-line:max-line-length
    const status = this.selectedForm.controls.selectStatus.value === null || this.selectedForm.controls.selectStatus.value === undefined || this.selectedForm.controls.selectStatus.value === '' ? '' : this.selectedForm.controls.selectStatus.value.code;
    // tslint:disable-next-line:max-line-length
    this.apisService.getApi(environment.selectedbycustomer + pgNo + '/' + recordPage + '/' + this.accId + '?name=' + candidateName + '&status=' + status ).subscribe((res: any) => {
      this.loader = false;
      this.gridData = res.data.records;
      this.totalRecords = res.data.count;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
      this.loader = false;
    });
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridTable();
  }
  reqSkillsPopUp(data) {
    this.display = true;
    this.reqSkillsValue = data;
    }
    reqSkillSet() {
      this.apisService.getApi(environment.lookupsData + 'RELRS').subscribe((res) => {
        this.skillsList = res.data;
      });
    }
    getSkillSetTable(data) {
      this.array = data;
      const selectedPrePrimarySkills3 = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.skillsList.length; i++) {
        // tslint:disable-next-line:prefer-for-of
     if (data === null || data === undefined || data === '' || data === isEmpty()){} else{
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < this.array.length; j++) {
        if (Number(this.skillsList[i].code) === this.array[j]) {
          selectedPrePrimarySkills3.push(
            this.skillsList[i].label
          );
        } else {
        }
      }
     }
      }
      return selectedPrePrimarySkills3;
    }
    profilehistroy(rowData) {
      this.candidatename = rowData.consultantName;
      this.displayModal = true;
      this.date = new Date();
      this.apisService.getApi(environment.projectreshistory + this.accId + '/' + this.projecythistoryid ).subscribe((res: any) => {
        this.histroy = res.data;
        this.loader = false;
      });
    }
}
