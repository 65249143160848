<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
    <p-accordion>
        <p-accordionTab [selected]="true">
            <p-header>
                <label>Add Requirement</label>
                <em class="pi pi-arrow-left backRoute" (click)="goto()"></em>
            </p-header>
            <form [formGroup]="addRequirementsForm">
                <div class="p-fluid  p-grid">
                    <div class="p-field p-col-12 p-md-3 p-sm-12 p-lg-3">
                        <label class="inputfields">Position Title<span class="mandatoryColor">*</span></label>
                        <input id="input" type="text" maxlength="51" pKeyFilter="int" autocomplete="off" pInputText
                            formControlName="positionTitle" placeholder="Enter Title(Ex : Project Developer)">
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('positionTitle').touched && this.addRequirementsForm.get('positionTitle').invalid && this.addRequirementsForm.get('positionTitle').errors.required">
                                Please enter position title
                            </span>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('positionTitle').invalid && this.addRequirementsForm.get('positionTitle').errors.pattern">
                                Please enter alphabets only
                            </span>
                        </div>
                        <div>
                            <span class="validationtest"
                                *ngIf=" this.addRequirementsForm.get('positionTitle').hasError('maxlength')"> Please
                                enter 50
                                alphabets only </span>
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-3 p-sm-12 p-lg-3">
                        <label class="inputfields">Position Category<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="positionData" placeholder="Select Position Category " [showClear]="true"
                            (onChange)="getRolesBasedonCategory($event.value)" optionLabel="jobCategory"
                            formControlName="positionCategory"></p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('positionCategory').touched && this.addRequirementsForm.get('positionCategory').invalid && this.addRequirementsForm.get('positionCategory').errors.required">
                                Please select position category
                            </span>
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-3 p-sm-12 p-lg-3">
                        <label class="inputfields">Job Role<span class="mandatoryColor">*</span> </label>
                        <p-dropdown [options]="jobTitleData" placeholder="Select Job Role"
                            (onChange)="getMinrate($event.value)" [showClear]="true" optionLabel="title"
                            formControlName="jobRole"></p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('jobRole').touched && this.addRequirementsForm.get('jobRole').invalid && this.addRequirementsForm.get('jobRole').errors.required">
                                Please select job role
                            </span>
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-3 p-sm-12 p-lg-3">
                        <label class="inputfields">Department<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="departmentList" placeholder="Select Department" [showClear]="true"
                            optionLabel="name" formControlName="department"></p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('department').touched && this.addRequirementsForm.get('department').invalid && this.addRequirementsForm.get('department').errors.required">
                                Please select department
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class=" p-field p-col-12 p-md-3 p-sm-12 p-lg-3">
                        <label class="inputfields">Min Rate</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                            <input type="text" pInputText placeholder="Min Rate" disabled="true"
                                formControlName="minRate">
                            <span class="p-inputgroup-addon">/Hr</span>
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-3 p-sm-12 p-lg-3">
                        <label class="inputfields">Max Rate<span class="mandatoryColor">*</span></label>
                        <div class="p-inputgroup"> <span class="p-inputgroup-addon">{{durationCal(0 | currency:
                                currencyValue : true)}} </span>
                            <input pInputText placeholder="Max Rate" formControlName="maxRate">
                            <span class="p-inputgroup-addon">/Hr</span>
                        </div>
                        <div>
                            <span class="validationtest"
                            *ngIf="this.addRequirementsForm.get('maxRate').touched && this.addRequirementsForm.get('maxRate').invalid && this.addRequirementsForm.get('maxRate').errors.required">
                            Please enter max rate
                        </span>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('maxRate').invalid && this.addRequirementsForm.get('maxRate').errors.pattern">
                                Please enter numbers only
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-fluid  p-grid">
                    <div class="p-field p-col-12 p-md-3 p-sm-12 p-lg-3">
                        <label class="inputfields">Number of Positions<span class="mandatoryColor">*</span></label>
                        <input id="input" maxlength="12" pKeyFilter="int" autocomplete="off" pInputText
                            optionValue="code" formControlName="positions" placeholder="Enter Number of Positions ">
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('positions').touched && this.addRequirementsForm.get('positions').invalid && this.addRequirementsForm.get('positions').errors.required">
                                Please enter number of positions
                            </span>
                        </div>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('positions').invalid && this.addRequirementsForm.get('positions').errors.pattern">
                                Please enter numbers only
                            </span>
                        </div>
                        <div>
                            <span class="validationtest"
                                *ngIf=" this.addRequirementsForm.get('positions').hasError('maxlength')"> Please enter
                                11
                                numbers only </span>
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-3 p-sm-12 p-lg-3">
                        <label class="inputfields">Work Location<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="locationList" placeholder="Select Work Location" [showClear]="true"
                            optionLabel="name" optionValue="locId" formControlName="workLocation">
                            <ng-template let-data pTemplate="item">
                                <div pTooltip="{{data.name + '\n' + data.address1+',' + data.city + ',' + data.state + ',' + data.country}}"
                                    tooltipPosition="right">
                                    {{ data.name }}
                                </div>
                            </ng-template>

                        </p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('workLocation').touched && this.addRequirementsForm.get('workLocation').invalid && this.addRequirementsForm.get('workLocation').errors.required">
                                Please select work location
                            </span>
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-3 p-sm-12 p-lg-3">
                        <label class="inputfields">Required Experience<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="exprienceList" placeholder="Select Required Experience"
                            [showClear]="true" optionLabel="label" optionValue="code" formControlName="experience">
                        </p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('experience').touched && this.addRequirementsForm.get('experience').invalid && this.addRequirementsForm.get('experience').errors.required">
                                Please select required experience
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-fluid  p-grid">
                    <div class="p-field p-col-12 p-md-3 p-sm-12 p-lg-3">
                        <label class="inputfields">Hire Type<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="hireTypeList" (onChange)="typeData($event.value,$event)"
                            placeholder="Select Hire Type" [showClear]="true" optionLabel="label" optionValue="code"
                            formControlName="hireType"></p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('hireType').touched && this.addRequirementsForm.get('hireType').invalid && this.addRequirementsForm.get('hireType').errors.required">
                                Please select hire type
                            </span>
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-3 p-sm-12 p-lg-3">
                        <label class="inputfields">Planned Start Date<span class="mandatoryColor">*</span></label>
                        <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="startDate" [minDate]="minDate"
                            placeholder="MM-DD-YYYY" [showIcon]="true" (onSelect)='endDateValidation($event)' [showButtonBar]="true" >
                        </p-calendar>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('startDate').touched && this.addRequirementsForm.get('startDate').invalid && this.addRequirementsForm.get('startDate').errors.required">
                                Please select planned start date
                            </span>
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-3 p-sm-12 p-lg-3" *ngIf='endDisplay'>
                        <label class="inputfields">Planned End Date<span class="mandatoryColor">*</span></label>
                        <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="endDate" placeholder="MM-DD-YYYY"
                            [showIcon]="true" [minDate]="minDates" [showButtonBar]="true">
                        </p-calendar>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('endDate').touched && this.addRequirementsForm.get('endDate').invalid && this.addRequirementsForm.get('endDate').errors.required">
                                Please select planned end date
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-fluid  p-grid">
                    <!-- <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                        <label>Employee approval required? </label>&nbsp;
                        <p-radioButton name="approval" value="Y" label="Yes" formControlName="approval" inputId="Yes">
                        </p-radioButton>
                        <p-radioButton name="approval" value="N" label="No" formControlName="approval" inputId="No"
                            class="radiospac">
                        </p-radioButton>
                    </div> -->


                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-12" >
                        <label>Employee approval required? </label>&nbsp;
                        <p-radioButton name="approval" value="Y" label="Yes" formControlName="approval" inputId="Yes" [disabled]="true">
                        </p-radioButton>
                        <p-radioButton name="approval" value="N" label="No" formControlName="approval" inputId="No" [disabled]="true"
                            class="radiospac">
                        </p-radioButton>
                    </div>
                </div><br>
                <div class="p-fluid  p-grid">
                    <div class="p-field p-col-12 p-md-3 p-sm-12 p-lg-3">
                        <label class="inputfields">Billing Type<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="billingList" placeholder="Select Billing Type" [showClear]="true"
                            optionLabel="label" optionValue="code" formControlName="billingType"></p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('billingType').touched && this.addRequirementsForm.get('billingType').invalid && this.addRequirementsForm.get('billingType').errors.required">
                                Please select billing type
                            </span>
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-3 p-sm-12 p-lg-3">
                        <label class="inputfields">Duration</label>
                        <div class="p-inputgroup">
                            <!-- <span class="p-inputgroup-addon">{{durationCal(0 | currency:
                                currencyValue : true)}} </span> -->
                            <input pInputText placeholder="Duration" formControlName="duration" (blur)="getduration()">
                            <span class="p-inputgroup-addon">Months</span>
                        </div>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('duration').invalid && this.addRequirementsForm.get('duration').errors.pattern">
                                Please enter numbers only
                            </span>
                            <span class="validationtest" *ngIf="this.shwmsg">{{this.showmsg}}</span>
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-3 p-sm-12 p-lg-3">
                        <label class="inputfields">Per Week</label>
                        <div class="p-inputgroup">
                            <!-- <span class="p-inputgroup-addon">{{durationCal(0 | currency:
                                currencyValue : true)}} </span> -->
                            <input pInputText placeholder="Per Week" formControlName="perWeek" maxlength="12">
                            <span class="p-inputgroup-addon">Hrs</span>
                        </div>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('perWeek').invalid && this.addRequirementsForm.get('perWeek').errors.pattern">
                                Please enter numbers only
                            </span>
                            <span class="validationtest"
                                *ngIf=" this.addRequirementsForm.get('perWeek').hasError('maxlength')"> Please enter 11
                                alphabets only </span>
                        </div>
                    </div>
                </div>
                <div class="p-fluid  p-grid">
                    <div class="p-field p-col-12 p-md-6 p-sm-12 p-lg-6">
                        <label class="inputfields">Skill Set<span class="mandatoryColor">*</span></label>
                        <p-multiSelect [options]="skillsList" [showToggleAll]="true"
                            formControlName="requirementsSkillSet" placeholder="Select Skill Set" maxSelectedLabels="15"
                            optionValue="code" optionLabel="label" (onChange)="skillsetOthers($event.value)">
                        </p-multiSelect>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('requirementsSkillSet').touched && this.addRequirementsForm.get('requirementsSkillSet').invalid && this.addRequirementsForm.get('requirementsSkillSet').errors.required">
                                Please select skill set
                            </span>

                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-6 p-sm-12 p-lg-6">
                        <label class="inputfields">Preferred Skill Set<span class="mandatoryColor">*</span></label>
                        <p-multiSelect [options]="skillsList" [showToggleAll]="true" formControlName="preferredSkills"
                        placeholder="Select Preferred Skill Set" maxSelectedLabels="15" optionValue="label"
                            optionValue="code" optionLabel="label"></p-multiSelect>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('preferredSkills').touched && this.addRequirementsForm.get('preferredSkills').invalid && this.addRequirementsForm.get('preferredSkills').errors.required">
                                Please select preferred skill set
                            </span>
                        </div>
                    </div>
                </div>

                <div class="p-fluid  p-grid" *ngIf="otherskilldata">
                    <div class="p-field p-col-12 p-md-6 p-sm-12 p-lg-6">
                        <label class="inputfields">Others Skills<span class="mandatoryColor">*</span></label>
                        <textarea rows="5" maxlength="301" cols="30" (blur)=onBlur($event.target.value)
                            placeholder="Enter Others Skills (Ex: Angular, Java)" formControlName="otherskills"
                            pInputTextarea></textarea>
                        <span class="validationtest" *ngIf="this.addRequirementsForm.get('otherskills').touched &&
                             this.addRequirementsForm.get('otherskills').invalid &&
                              this.addRequirementsForm.get('otherskills').errors.required">
                            Please enter others skills
                        </span>
                        <span class="validationtest"
                            *ngIf=" this.addRequirementsForm.get('otherskills').hasError('maxlength')"> Please enter
                            300
                            alphabets only </span>
                    </div>
                </div>
                <div class="p-fluid  p-grid">
                    <div class="p-field p-col-12 p-md-6 p-sm-12 p-lg-6">
                        <label class="inputfields">Job Description<span class="mandatoryColor">*</span></label>
                        <textarea rows="5" maxlength="2001" cols="30" placeholder="Enter Description"
                            formControlName="description" autoResize="false" pInputTextarea></textarea>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('description').touched && this.addRequirementsForm.get('description').invalid && this.addRequirementsForm.get('description').errors.required">
                                Please enter job description
                            </span>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('description').hasError('maxlength')">
                                please enter 2000 alphabets only
                            </span>
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-6 p-sm-12 p-lg-6">
                        <label class="inputfields">Responsibilities<span class="mandatoryColor">*</span></label>
                        <textarea rows="5" maxlength="2001" cols="30" placeholder="Enter Responsibilities"
                            formControlName="responsibilities" pInputTextarea autoResize="false"></textarea>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('responsibilities').touched && this.addRequirementsForm.get('responsibilities').invalid && this.addRequirementsForm.get('responsibilities').errors.required">
                                Please enter responsibilities
                            </span>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('responsibilities').hasError('maxlength')">
                                please enter 2000 alphabets only
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-fluid  p-grid">
                    <div class="p-field p-col-12 p-md-6 p-sm-12 p-lg-6">
                        <label class="inputfields">Qualification<span class="mandatoryColor">*</span></label>
                        <textarea rows="5" cols="30" placeholder="Enter Qualification" formControlName="qualification"
                            pInputTextarea maxlength="501" autoResize="false"></textarea>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('qualification').touched && this.addRequirementsForm.get('qualification').invalid && this.addRequirementsForm.get('qualification').errors.required">
                                Please enter qualification
                            </span>
                        </div>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.addRequirementsForm.get('qualification').hasError('maxlength')">
                                Please enter 500 alphabets only
                            </span>
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-6 p-sm-12 p-lg-6">
                        <label class="inputfields"> Comments</label>
                        <textarea rows="5" cols="30" placeholder="Enter Comments" formControlName="comments"
                            pInputTextarea maxlength="501" autoResize="false"></textarea>
                        <div>
                            <span class="validationtest"
                                *ngIf=" this.addRequirementsForm.get('comments').hasError('maxlength')">
                                Please enter 500 alphabets only
                            </span>
                        </div>
                    </div>
                </div>
            </form>
            <div class="btnsCSS1">
                <button pButton class="addhBtn" type="button" icon='pi pi-refresh' (click)="reset()"
                    label="Reset"></button>
                <button pButton class="addhBtn" [disabled]="disable" type="button" icon='pi pi-save' (click)="addReq()"
                    label="Save"></button>
            </div><br><br>
        </p-accordionTab>
    </p-accordion>
</p-card>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>