import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { environment } from 'src/environments/environment';
import { env } from 'process';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-sharecalender',
  templateUrl: './sharecalender.component.html',
  styleUrls: ['./sharecalender.component.scss'],
  providers: [DatePipe, MessageService],
})
export class SharecalenderComponent implements OnInit {
  Time = [
    { name: '12:00AM', code: '1' }, { name: '12:15AM', code: '2' }, { name: '12:30AM', code: '3' }, { name: '12:45AM', code: '4' },
    { name: '1:00AM', code: '5' }, { name: '1:15AM', code: '6' }, { name: '1:30AM', code: '7' }, { name: '1:45AM', code: '8' },
    { name: '2:00AM', code: '9' }, { name: '2:15AM', code: '10' }, { name: '2:30AM', code: '11' }, { name: '2:45AM', code: '12' },
    { name: '3:00AM', code: '13' }, { name: '3:15AM', code: '14' }, { name: '3:30AM', code: '15' }, { name: '3:45AM', code: '16' },
    { name: '4:00AM', code: '17' }, { name: '4:15AM', code: '18' }, { name: '4:30AM', code: '19' }, { name: '4:45AM', code: '20' },
    { name: '5:00AM', code: '21' }, { name: '5:15AM', code: '22' }, { name: '5:30AM', code: '23' }, { name: '5:45AM', code: '24' },
    { name: '6:00AM', code: '25' }, { name: '6:15AM', code: '26' }, { name: '6:30AM', code: '27' }, { name: '6:45AM', code: '28' },
    { name: '7:00AM', code: '29' }, { name: '7:15AM', code: '30' }, { name: '7:30AM', code: '31' }, { name: '7:45AM', code: '32' },
    // tslint:disable-next-line:max-line-length
    { name: '8:00AM', code: '33' }, { name: '8:15AM', code: '34' }, { name: '8:30AM', code: '35' }, { name: '8:45AM', code: '36' },
    { name: '9:00AM', code: '37' }, { name: '9:15AM', code: '38' }, { name: '9:30AM', code: '39' }, { name: '9:45AM', code: '40' },
    { name: '10:00AM', code: '41' }, { name: '10:15AM', code: '42' }, { name: '10:30AM', code: '43' }, { name: '10:45AM', code: '44' },
    // tslint:disable-next-line:max-line-length
    { name: '11:00AM', code: '45' }, { name: '11:15AM', code: '46' }, { name: '11:30AM', code: '47' }, { name: '11:45AM', code: '48' },
    { name: '12:00PM', code: '49' }, { name: '12:15PM', code: '50' }, { name: '12:30PM', code: '51' }, { name: '12:45PM', code: '52' },
    { name: '1:00PM', code: '53' }, { name: '1:15PM', code: '54' }, { name: '1:30PM', code: '55' }, { name: '1:45PM', code: '56' },
    { name: '2:00PM', code: '57' }, { name: '2:15PM', code: '58' }, { name: '2:30PM', code: '59' }, { name: '2:45PM', code: '60' },
    { name: '3:00PM', code: '61' }, { name: '3:15PM', code: '62' }, { name: '3:30PM', code: '63' }, { name: '3:45PM', code: '64' },
    { name: '4:00PM', code: '65' }, { name: '4:15PM', code: '66' }, { name: '4:30PM', code: '67' }, { name: '4:45PM', code: '68' },
    { name: '5:00PM', code: '69' }, { name: '5:15PM', code: '70' }, { name: '5:30PM', code: '71' }, { name: '5:45PM', code: '72' },
    { name: '6:00PM', code: '73' }, { name: '6:15PM', code: '74' }, { name: '6:30PM', code: '75' }, { name: '6:45PM', code: '76' },
    { name: '7:00PM', code: '77' }, { name: '7:15PM', code: '78' }, { name: '7:30PM', code: '79' }, { name: '7:45PM', code: '80' },
    // tslint:disable-next-line:max-line-length
    { name: '8:00pm', code: '81' }, { name: '8:15pm', code: '82' }, { name: '8:30pm', code: '83' }, { name: '8:45pm', code: '84' },
    { name: '9:00pm', code: '85' }, { name: '9:15pm', code: '86' }, { name: '9:30pm', code: '87' }, { name: '9:45pm', code: '88' },
    { name: '10:00pm', code: '89' }, { name: '10:15pm', code: '90' }, { name: '10:30pm', code: '91' }, { name: '10:45pm', code: '92' },
    // tslint:disable-next-line:max-line-length
    { name: '11:00pm', code: '93' }, { name: '11:15pm', code: '94' }, { name: '11:30pm', code: '95' }, { name: '11:45pm', code: '96' },

  ];
  stateOptions = [
    { label: 'List View', value: 'lview' },
    { label: 'Calender View', value: 'cview' }
  ];
  event = [
    { label: 'Upcoming', code: 'u' },
    { label: 'Past', code: 'a' },
    { label: 'Data Range', code: 'd' },

  ];
  cols = [
    { field: 'Event name', header: 'Event name', width: '5%' },
    { field: 'Event type', header: 'Event type', width: '6%' },
    { field: 'Start time', header: 'Start time', width: '5%' },
    { field: 'End time', header: 'End time ', width: '4%' },
    { field: 'Date', header: 'Date', width: '4%' },
    { field: 'location', header: 'Location', width: '3%' },
    { field: '', header: 'Action', width: '2%' },
  ];
  display: boolean;
  events: any[];
  currentDate: Date;
  // tslint:disable-next-line:max-line-length
  options: { plugins: import('@fullcalendar/core/plugin-system').PluginDef[]; defaultDate: Date; header: { left: string; center: string; right: string; }; editable: boolean; };
  acctdetails: any;
  data: any;
  sheduleform: FormGroup;
  showcalender: boolean;
  Createform: FormGroup;
  table: boolean;
  // tslint:disable-next-line:typedef-whitespace
  // tslint:disable-next-line:no-inferrable-types
  records: boolean = true;
  Eventform: FormGroup;
  show: any;
  eventcolor: any;
  datares: any;
  userid: any;
  response: any;
  responseid: any;
  checking: any;
  id: any;
  clenderForm: FormGroup;
  claenderForm: FormGroup;
  value: any;
  calederform: FormGroup;
  calederform1: FormGroup;
  calederform6: FormGroup;
  calederform5: FormGroup;
  calederform4: FormGroup;
  calederform3: FormGroup;
  calederform2: FormGroup;
  monday: any;
  saturday: any;
  friday: any;
  thursday: any;
  wednesday: any;
  tuesday: any;
  id6: any;
  id4: any;
  id5: any;
  id3: any;
  id2: any;
  id1: any;
  availablefrom: any;
  availableTo: any;
  loading: boolean;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, public router: Router, private messageService: MessageService, private route: ActivatedRoute, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Sharedcalender', routerLink: '/sharedcalender' },
    ]);

  }
  // tslint:disable-next-line:no-inferrable-types
  // tslint:disable-next-line:no-inferrable-types
  monoption: boolean = true;
  // tslint:disable-next-line:no-inferrable-types
  tueoption: boolean = true;
  // tslint:disable-next-line:no-inferrable-types
  wedoption: boolean = true;
  // tslint:disable-next-line:no-inferrable-types
  thuoption: boolean = true;
  // tslint:disable-next-line:no-inferrable-types
  frioption: boolean = true;
  // tslint:disable-next-line:no-inferrable-types
  satoption: boolean = true;
  eventtype: string;
  items: FormArray;
  orderForm: FormGroup;
  // tslint:disable-next-line:no-inferrable-types
  value1: string = 'lview';
  // tslint:disable-next-line:no-inferrable-types
  calenderview: boolean = true;
  ngOnInit(): void {
    this.acctdetails = JSON.parse(this.apisService.decodeData());
    this.options = {
      plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
      defaultDate: this.currentDate,
      header: {
        left: 'prev,next',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      editable: true
    };
    this.Createform = this.fb.group({
      eventtype: ['', [Validators.required]],

    });
    this.sheduleform = this.fb.group({
      event: [''],
      fromdate: [''],
      todate: ['']
    });

    this.orderForm = new FormGroup({
      items: new FormArray([])
    });
    this.calederform = this.fb.group({
      fromdates: [''],
      todates: [''],
    });
    this.calederform1 = this.fb.group({
      monday: [''],
      mondays: [''],
    });
    this.calederform2 = this.fb.group({
      tuesday: [''],
      tuesdays: [''],
    });
    this.calederform3 = this.fb.group({
      wednesday: [''],
      wednesdays: [''],
    });
    this.calederform4 = this.fb.group({
      thursday: [''],
      thursdays: [''],
    });
    this.calederform5 = this.fb.group({
      friday: [''],
      fridays: [''],
    });
    this.calederform6 = this.fb.group({
      saturday: [''],
      saturdays: [''],
    });
    this.getcalender();
    this.calender();
    this.listview();
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.show = routeParams.params.card;
    this.eventcolor = routeParams.params.color;
    if (this.show === 'Y') {
      this.addEvent();
    }
  }

  getcalender() {
    this.loading = true;
    this.apisService.getApi(environment.calendaravailability + '/' + this.acctdetails.userId).subscribe((res: any) => {
      this.response = res.data;
      this.loading = false;
      this.availablefrom = this.response[0].availableFrom;
      this.availableTo = this.response[0].availableTo;
      this.id = this.response[0].id;
      this.value = this.response[0].weekDay;
      this.monday = this.response[1].weekDay;
      this.tuesday = this.response[2].weekDay;
      this.wednesday = this.response[3].weekDay;
      this.thursday = this.response[4].weekDay;
      this.friday = this.response[5].weekDay;
      this.saturday = this.response[6].weekDay;
      this.id1 = this.response[1].id;
      this.id2 = this.response[2].id;
      this.id3 = this.response[3].id;
      this.id4 = this.response[4].id;
      this.id5 = this.response[5].id;
      this.id6 = this.response[6].id;
      this.calederform.controls.fromdates.patchValue(
        this.Time.filter((x) => x.code === this.response[0].availableFrom)[0]
      );
      this.calederform.controls.todates.patchValue(
        this.Time.filter((x) => x.code === this.response[0].availableTo)[0]
      );
      this.calederform1.controls.monday.patchValue(
        this.Time.filter((x) => x.code === this.response[1].availableFrom)[0]
      );
      this.calederform1.controls.mondays.patchValue(
        this.Time.filter((x) => x.code === this.response[1].availableTo)[0]
      );
      this.calederform2.controls.tuesday.patchValue(
        this.Time.filter((x) => x.code === this.response[2].availableFrom)[0]
      );
      this.calederform2.controls.tuesdays.patchValue(
        this.Time.filter((x) => x.code === this.response[2].availableTo)[0]
      );
      this.calederform3.controls.wednesday.patchValue(
        this.Time.filter((x) => x.code === this.response[3].availableFrom)[0]
      );
      this.calederform3.controls.wednesdays.patchValue(
        this.Time.filter((x) => x.code === this.response[3].availableTo)[0]
      );
      this.calederform4.controls.thursday.patchValue(
        this.Time.filter((x) => x.code === this.response[4].availableFrom)[0]
      );
      this.calederform4.controls.thursdays.patchValue(
        this.Time.filter((x) => x.code === this.response[4].availableTo)[0]
      );
      this.calederform5.controls.friday.patchValue(
        this.Time.filter((x) => x.code === this.response[5].availableFrom)[0]
      );
      this.calederform5.controls.fridays.patchValue(
        this.Time.filter((x) => x.code === this.response[5].availableTo)[0]
      );
      this.calederform6.controls.saturday.patchValue(
        this.Time.filter((x) => x.code === this.response[6].availableFrom)[0]
      );
      this.calederform6.controls.saturdays.patchValue(
        this.Time.filter((x) => x.code === this.response[6].availableTo)[0]
      );
    });
  }
  showDialog() {
    this.Createform.reset();
    this.display = true;

  }
  navigate() {

    if (this.Createform.invalid) {
      const controls = this.Createform.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      this.router.navigateByUrl('/calendar-events');
    }
  }
  calender() {
    this.apisService.getApi(environment.calender + this.acctdetails.userId).subscribe((res: any) => {
      this.data = res.data.records;
      this.events = this.data;
    });
  }
  selectoption() {
    if (this.value1 === 'cview') {
      this.calenderview = false;
    }
    else {
      this.getcalender();
      this.calenderview = true;
    }

  }
  typeofevent(code) {
    if (code === 'd') {
      this.showcalender = true;
    }
    else {
      this.showcalender = false;
    }
  }
  createevent(): FormGroup {
    return this.fb.group({
      eventname: '',
      date: '',
      time: ''
    });

  }
  addEvent(): void {
    this.items = this.orderForm.get('items') as FormArray;
    this.items.push(this.createevent());
  }

  listview() {
    const data = {
      weekDay: '1',
      availableFrom: '2021-05-10',
      availableTo: '2022-02-21',
      isAvailable: 'Y',
    };
    this.apisService.postApi(environment.calendaravailability, data).subscribe((res: any) => {
      this.datares = res.data;
    });
  }

  checkclaender() {
    const available = {
      id : this.id,
      isAvailable: 'N'
    };
    this.loading = true;
    this.apisService.putApi(environment.isavailable , available).subscribe((res: any) => {
      this.checking = res;
      this.loading = false;
    });
  }
  updateapi() {
    this.loading = true;
    const update = {
      id: this.id,
      availableFrom: this.calederform.controls.fromdates.value.code,
      availableTo: this.calederform.controls.todates.value.code
    };
    this.apisService.putApi(environment.updateapicalender , update).subscribe((res: any) => {
      this.loading = false;
    });
  }
  updatemonday() {
    this.loading = true;
    const update = {
      id: this.id1,
      availableFrom: this.calederform1.controls.monday.value.code,
      availableTo: this.calederform1.controls.mondays.value.code
    };
    this.apisService.putApi(environment.updateapicalender , update).subscribe((res: any) => {
      this.loading = false;
    });
  }
  updatetuesday() {
    this.loading = true;
    const update = {
      id: this.id2,
      availableFrom: this.calederform2.controls.tuesday.value.code,
      availableTo: this.calederform2.controls.tuesdays.value.code
    };
    this.apisService.putApi(environment.updateapicalender , update).subscribe((res: any) => {
      this.loading = false;
    });
  }
  updatewednesday() {
    this.loading = true;
    const update = {
      id: this.id3,
      availableFrom: this.calederform3.controls.wednesday.value.code,
      availableTo: this.calederform3.controls.wednesdays.value.code
    };
    this.apisService.putApi(environment.updateapicalender , update).subscribe((res: any) => {
      this.loading = false;
    });
  }
  updatethurday() {
    this.loading = true;
    const update = {
      id: this.id4,
      availableFrom: this.calederform4.controls.thursday.value.code,
      availableTo: this.calederform4.controls.thursdays.value.code
    };
    this.apisService.putApi(environment.updateapicalender , update).subscribe((res: any) => {
      this.loading = false;
    });
  }
  updatefriday() {
    this.loading = true;
    const update = {
      id: this.id5,
      availableFrom: this.calederform5.controls.friday.value.code,
      availableTo: this.calederform5.controls.fridays.value.code
    };
    this.apisService.putApi(environment.updateapicalender , update).subscribe((res: any) => {
      this.loading = false;
    });
  }
  updatesaturday() {
    this.loading = true;
    const update = {
      id: this.id6,
      availableFrom: this.calederform6.controls.saturday.value.code,
      availableTo: this.calederform6.controls.saturdays.value.code
    };
    this.apisService.putApi(environment.updateapicalender , update).subscribe((res: any) => {
      this.loading = false;
    });
  }
}
