<p-splitButton icon="pi pi pi-bars" [model]="dropDownValue"></p-splitButton>
<p-card>
    <form [formGroup]="accountDetailsForm">
        <p-panel header="Account Details">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 aligncenter">
                    <div class="p-grid p-fluid">
                        <div class="p-col-4 p-md-4 p-sm-12" class="imageDiv">
                            <div class="p-col-4 p-md-4 p-sm-12" class="imageDiv">
                                <label class="hoverable" for="fileInput">
                                    <img alt="" class="img1"
                                        [src]="url ? url : (this.iamgedownloadurl?this.iamgedownloadurl:'https://d2r8nxfsv3v6y3.cloudfront.net/images/Account.png')">
                                    <div class="middle">
                                        <div class="text"><em class="fa fa-camera fa-2x"></em></div>
                                    </div>
                                    <div class="hover-text"> Choose file
                                        <h4 class="success-message" *ngIf="url"> Image added successfully </h4>
                                    </div>
                                    <div class="background"></div>
                                </label>
                                <br />
                                <input autocomplete="off" id="fileInput" type='file' accept=".png, .jpeg, .jpg"
                                    (change)="onSelectFile($event)">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-9" style=" position: relative; top: 0px;">
                    <div class="p-grid p-justify-end ippos">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-9">
                            <div class="p-grid p-justify-end"> </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                            <label class="inputfields">Account Name</label>
                            <input id="input" autocomplete="off" type="text" size="30" formControlName="accountName"
                                disabled="true" pInputText placeholder="Account Name" [readonly]="true" maxlength="60">
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                            <label class="inputfields">Corporate Website</label>
                            <input id="input" autocomplete="off" type="text" size="30" formControlName="accountURL"
                                disabled="true" pInputText placeholder="Corporate Website" maxlength="60">
                        </div>
                    </div><br>
                    <div class="p-grid  p-fluid ippos">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
                            <label class="inputfields">Status<span class="mandatoryColor">*</span></label>
                            <p-dropdown [options]="status" [disabled]="true" formControlName="status"
                                placeholder="Status" optionLabel="label" [showClear]="true"></p-dropdown>
                            <div>
                                <span class="validationtest"
                                    *ngIf="this.accountDetailsForm.get('status').touched && this.accountDetailsForm.get('status').invalid && this.accountDetailsForm.get('status').errors.required">
                                    Please select status</span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
                            <label class="inputfields">Phone</label>
                            <div class="p-col-12 p-md-12 p-sm-12 phone">
                                <div class="p-inputgroup" class="p-col-4 p-md-4 phno">
                                    <span class="p-inputgroup-addon">
                                        <p-dropdown [options]="countrycodedropdown" class="phonecodestyle newcode"
                                            (onChange)="countryids($event)" filterPlaceholder='Search Results'
                                            optionLabel="label" formControlName="phNumDrp">
                                            <ng-template let-items pTemplate="selectedItem">
                                                <div class="flex-row">
                                                    <img class="lang-change-img" src={{items.flag}} alt="" />
                                                    <div class="lang-change-option-text selected-lang center">
                                                        {{items.code}}
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template let-country pTemplate="item">
                                                <div class="flex-row" id="dropdown1">
                                                    <img class="lang-change-img" src={{country.flag}} alt="" />
                                                    <div class="lang-change-option-text center">{{country.label}}
                                                        {{country.code}}</div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </span>
                                </div>
                                <div class="ccode">
                                    <span class="p-input-icon-right"><em *ngIf="phSpinnerHide"
                                            class="pi pi-spin pi-spinner spinnerstyles"></em>
                                        <em *ngIf="phCheckHide" class="pi pi-check-circle tickmarkstyle"></em>
                                        <p-inputMask autocomplete="off" id="input" (onBlur)="getPhon()"
                                            mask="(999) 999-9999" size="30" formControlName="phone" placeholder="Phone">
                                        </p-inputMask>
                                    </span>
                                </div>
                            </div>
                            <p class="mandatoryColor">{{this.phnummsg}}</p>
                            <span *ngIf="displayNumber" class="mandatoryColor">phone number already exist</span>
                        </div>
                    </div>
                </div>
            </div>
            <h5 id="h3tag">Account Address</h5>
            <div class="p-grid p-fluid ippos">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">Address Line 1</label>
                    <input autocomplete="off" id="input" type="text" size="30" formControlName="address1" pInputText
                        placeholder="Address Line 1" maxlength="51">
                    <div>
                        <span class="validationtest"
                            *ngIf=" this.accountDetailsForm.get('address1').hasError('maxlength')"> Please enter 50
                            alphabets only</span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">Address Line 2</label>
                    <input autocomplete="off" id="input" type="text" size="30" formControlName="address2" pInputText
                        placeholder="Address Line 2" maxlength="51">
                    <div>
                        <span class="validationtest"
                            *ngIf=" this.accountDetailsForm.get('address2').hasError('maxlength')"> Please enter 50
                            alphabets only </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields" id='csslabel'>Mailbox</label>
                    <input type="text" autocomplete="off" maxlength="11" pInputText placeholder="Mailbox"
                        formControlName="mailbox">
                    <div>
                        <span class="validationtest"
                            *ngIf=" this.accountDetailsForm.get('mailbox').hasError('maxlength')"> Please enter 10
                            alphabets only </span>
                        <span class="validationtest"
                            *ngIf="this.accountDetailsForm.get('mailbox').invalid && this.accountDetailsForm.get('mailbox').errors.pattern">
                            Please enter numbers only
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Country</label>
                    <p-dropdown [options]="countryOptions" formControlName="country" placeholder="Select Country"
                        optionLabel="label" [showClear]="true" (onChange)="getStateByUrl($event.value.code)">
                    </p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">State</label>
                    <p-dropdown [options]="stateoptions" formControlName="state" placeholder="State" optionLabel="label"
                        [showClear]="true"></p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">City</label>
                    <input id="input" autocomplete="off" type="text" size="30" maxlength="21" formControlName="city"
                        pInputText placeholder="City">
                    <span class="validationtest"
                        *ngIf="this.accountDetailsForm.controls.city.invalid && this.accountDetailsForm.controls.city.errors?.pattern">Please
                        enter
                        alphabets only</span>
                    <div>
                        <span class="validationtest" *ngIf=" this.accountDetailsForm.get('city').hasError('maxlength')">
                            Please enter 20 alphabets only </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Zip</label>
                    <input id="input" autocomplete="off" pKeyFilter="num" size="30" formControlName="zip" pInputText
                        placeholder="Zip" maxlength="11">
                    <span class="validationtest" *ngIf=" this.accountDetailsForm.get('zip').hasError('maxlength')">
                        Please enter 10 numbers only </span>
                    <span class="validationtest"
                        *ngIf="this.accountDetailsForm.get('zip').invalid && this.accountDetailsForm.get('zip').errors.pattern">
                        Please enter numbers only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Fax</label>
                    <input autocomplete="off" id="input" pKeyFilter="num" size="30" formControlName="fax" pInputText
                        placeholder="Fax" maxlength="16">
                    <div>
                        <span class="validationtest" *ngIf=" this.accountDetailsForm.get('fax').hasError('maxlength')">
                            Please enter 15 alphabets only</span>
                        <span class="validationtest"
                            *ngIf="this.accountDetailsForm.get('fax').invalid && this.accountDetailsForm.get('fax').errors.pattern">
                            Please enter numbers only
                        </span>
                    </div>
                </div>
            </div>
            <h5 id="h3tag">Basic Information</h5>
            <div class="p-grid p-fluid ippos">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Industry</label>
                    <p-dropdown [options]="industryOptions" formControlName="industry" placeholder="Industry"
                        optionLabel="label" [showClear]="true"></p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Region</label>
                    <p-dropdown [options]="regionOptions" placeholder="Region" optionLabel="label"
                        formControlName='region' [showClear]="true"></p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">No.Of Employees</label>
                    <p-dropdown [options]="EmployeeData" placeholder="No.Of Employees" optionLabel="label"
                        optionValue="code" formControlName='noOfEmployees' [showClear]="true"></p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Tax ID</label>
                    <input autocomplete="off" id="input" type="text" pInputText placeholder="Tax ID"
                        formControlName="taxId" maxlength="21">
                    <div>
                        <span class="validationtest"
                            *ngIf=" this.accountDetailsForm.get('taxId').hasError('maxlength')"> Please enter 20
                            numbers only </span>
                    </div>
                    <span class="validationtest"
                        *ngIf="this.accountDetailsForm.get('taxId').invalid && this.accountDetailsForm.get('taxId').errors.pattern">
                        Please enter numbers only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Stock Symbol</label>
                    <input autocomplete="off" id="input" type="text" pInputText placeholder="Stock Symbol"
                        maxlength="11" formControlName="stockSymbol">
                    <div>
                        <span class="validationtest"
                            *ngIf=" this.accountDetailsForm.get('stockSymbol').hasError('maxlength')"> Please enter 10
                            alphabets only </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Revenue</label>
                    <input autocomplete="off" id="input" pKeyFilter="num" size="30" autocomplete="off" maxlength="10"
                        formControlName="revenue" pInputText placeholder="Revenue">
                    <div>
                        <span class="validationtest"
                            *ngIf="this.accountDetailsForm.get('revenue').hasError('maxlength')"> Please enter maximum
                            of 9 alphabets </span>
                        <span class="validationtest"
                            *ngIf="this.accountDetailsForm.get('revenue').invalid && this.accountDetailsForm.get('revenue').errors.pattern">
                            Please enter numbers only
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Dun & BradStreet No.</label>
                    <input autocomplete="off" id="input" type="text" size="30" formControlName="dunAndBradStreetNo"
                        pInputText maxlength="13" placeholder="Dun & BradStreet No.">
                    <div>
                        <span class="validationtest"
                            *ngIf=" this.accountDetailsForm.get('dunAndBradStreetNo').hasError('maxlength')"> Please
                            enter 12 alphabets only </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Business Type</label>
                    <p-dropdown [options]="businessType" formControlName="businessType" placeholder="Business Type"
                        optionLabel="label" [showClear]="true"></p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
                    <label>Employee approval required?</label>&nbsp;
                    <!-- <p-radioButton name="Emp" value="Y" label="Yes" formControlName="Emp" inputId="Yes"
                        class="radiobtn"></p-radioButton>
                    <p-radioButton name="Emp" value="N" label="No" formControlName="Emp" inputId="No"> </p-radioButton> -->
                    <p-radioButton name="Emp" value="Y" label="Yes" formControlName="Emp" inputId="Yes"
                        [disabled]="true" class="radiobtn"></p-radioButton>
                    <p-radioButton name="Emp" value="N" label="No" formControlName="Emp" inputId="No" [disabled]="true">
                    </p-radioButton>
                </div>
                <div class="p-col-12">
                    <label class="inputfields">Notes</label>
                    <textarea autocomplete="off" [rows]="3" [cols]="30" placeholder="Notes" pInputTextarea
                        formControlName="customerNotes" maxlength="201"></textarea>
                    <div>
                        <span class="validationtest"
                            *ngIf=" this.accountDetailsForm.get('customerNotes').hasError('maxlength')"> Please enter
                            200 alphabets only </span>
                    </div>
                </div>
            </div>
            <div class="btnsCSS1">
                <button pButton type="submit" icon='pi pi-save' label="Update" (click)="updateAccountDetails()"
                    [disabled]="disable" class="addhBtn"></button>
            </div><br><br>
        </p-panel>
    </form>
</p-card>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center" style="height:520px">
                <div style="position: absolute;left:50%;top:50%;transform:translate(-50%,-50%)">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        style="height:8em">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-toast position="bottom-right" key="br"></p-toast>