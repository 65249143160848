import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { MessageService } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-salesopenposition-edit',
  templateUrl: './salesopenposition-edit.component.html',
  styleUrls: ['./salesopenposition-edit.component.scss'],
  providers: [MessageService, DatePipe],
})
export class SalesopenpositionEditComponent implements OnInit {
  submittedProfileForm: FormGroup;
  editRequirementsForm: FormGroup;
  updateprofileForm: FormGroup;
  UserId: string;
  name1: any;
  reqId: any;
  titel: any;
  formRoute: any;
  gridStatus: any;
  reqData: any;
  reqPosi: any;
  reqName: any;
  jobCat: any;
  maxrate: any;
  headerName: string;
  rows: any = 10;
  page: any = 1;
  pageCount: any;
  minimumDate = new Date();
  submitApproval: boolean;
  dateErr: boolean;
  @ViewChild('p', { static: false }) paginator: Paginator;
  updateactioncols = [
    { field: 'name', header: 'Title', width: '8%' },
    { field: 'title', header: 'Name', width: '8%' },
    { field: '', header: 'Download', width: '8%' },
    { field: '', header: '', width: '8%' },
  ];
  cols3 = [
    { dataKey: 'consultantName', header: 'Candidate Name' },
    { dataKey: 'submittedDate', header: 'Submitted Date' },
    { dataKey: 'skills', header: 'Skills' },
    { dataKey: 'exper', header: 'Exp' },
    { dataKey: 'status', header: 'Status' },
    { dataKey: 'billRate', header: 'Bill Rate' },
  ];
  cols11 = [
    { field: 'consultantName', header: 'Candidate Name', width: '15%', sno: 1 },
    { field: 'submittedDate', header: 'Submitted Date', width: '15%', sno: 2 },
    { field: 'requiredSkills', header: 'Skills', width: '10%', sno: 3 },
    { field: 'requiredSkills', header: 'Skills', width: '10%', sno: 4 },
    { field: 'experience', header: 'Exp', width: '10%', sno: 5 },
    { field: 'profileState', header: 'Status', width: '12%', sno: 6 },
    { field: 'billRate', header: 'Bill Rate', width: '12%', sno: 7 },
    { field: '', header: 'Action', width: '8%', sno: 8 },
  ];
  payRatesTable = [
    { key: 'Pay Rate +  25%', value: 25 },
    { key: 'Pay Rate +  30%', value: 30 },
    { key: 'Pay Rate +  35%', value: 35 },
    { key: 'Pay Rate +  40%', value: 40 },
    { key: 'Pay Rate +  45%', value: 45 },
    { key: 'Pay Rate +  50%', value: 50 },
  ];
  submittedProfilesGridData: any;
  subProRecords: any;
  table: boolean;
  records: boolean;
  loader = false;
  array: any;
  PreferredSkills: any = [];
  experienceData: any;
  expLabel: any = [];
  statusList: any = [];
  statusdata = {};
  first: any;
  positionData: any;
  requiremntId: any;
  minMaxRate: any;
  departmentList: any = [];
  postCat: any;
  jobTitleId: any;
  locationList1: any;
  acctdetails: any;
  locationList: any;
  getexperienceOptions: any;
  statusDD: any;
  billingData: any;
  hireTypeData: any;
  preferredSkills: any = [];
  skillset = {};
  rowData: any;
  idToDelete: any;
  conId: any;
  consultantName: any;
  items: any = [];
  downloadOptions: any = [];
  jobTitleData: any;
  userDetails: any;
  userAccId: any;
  exprienceList: any;
  billingList: any;
  hireTypeList: any;
  display: boolean;
  reqSkillsValue: any;
  filedata: any;
  resumeRes: any;
  updateaction: boolean;
  updateactionBtn: boolean;
  userid: any;
  resumID: any;
  updateactionGrid: any;
  profileID: any;
  reqId1: any;
  consultantRequirementDisplay: boolean;
  billdata: any;
  updateBilForm: any;
  candidateName: any;
  payRate: any;
  markUpData: any;
  errorMsg: boolean;
  payrates: any;
  alertError: boolean;
  approvalRequest: boolean;
  candidatename: any;
  userId: any;
  targetDate: string;
  maximumDate: Date;
  isUploading: boolean;
  loaderForDownload: boolean;
  rowdataid: any;
  iconToggle = {};
  selectedResume: any = [];
  display1: boolean;
  availCounsultRes: any;
  popup: boolean;
  reqCount: any;
  reqStatus: any;
  rejectSubmission: boolean;
  enddata: boolean;
  selectedPrimaryskills: any = [];
  tablerowID: any;
  selectedValue: boolean;
  selectedRowForResume: any;
  currencycode: any[];
  preferencList: any = [];
  currencyValue: any;
  currencysymbl: any;
  assignedDate: string;
  histroy: any;
  date: Date;
  displayModal = false;
  endDisplay: boolean;
  statusList1: any = [];
  otherskills: boolean;
  patchlocationString: string;
  editLocationdata: any;
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.search();
    }
  }
  // tslint:disable-next-line: max-line-length
  constructor(private datepipe: DatePipe, private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private apisService: ApisService, private breadcrumbService: BreadcrumbService, private messageService: MessageService) {
    this.reqData = JSON.parse(sessionStorage.getItem('dataiq'));
    this.reqPosi = this.reqData.noOfPositions;
    this.reqName = this.reqData.reqName;
    this.jobCat = this.reqData.jobCategory;
    this.maxrate = this.reqData.maxRate;
    this.breadcrumbService.setItems([
      { label: 'Open Positions', routerLink: '/salesOpenPositions' },
      { label: 'Open Position Edit', routerLink: '/salesOpenPositions-edit' },
    ]);
  }
  ngOnInit(): void {
    this.userDetails = JSON.parse(this.apisService.decodeData());
    this.preferencList = this.userDetails.preferencesList;
    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }
    this.userAccId = this.userDetails.accId;
    this.UserId = sessionStorage.getItem('UserId');
    const routeParams: any = this.route.snapshot.queryParamMap;
    if (Object.keys(routeParams.params).length !== 0) {
      this.name1 = routeParams.params.name;
      this.reqId = routeParams.params.reqId;
      this.titel = routeParams.params.title;
      this.formRoute = routeParams.params.from;
      this.gridStatus = routeParams.params.gStatus;
    } else {
      (this.name1 = this.reqData.name),
        (this.reqId = this.reqData.reqId),
        (this.titel = this.reqData.jobTitle),
        (this.formRoute = this.reqData.from),
        (this.gridStatus = this.reqData.reqStatus);
      this.editReqData();
    }
    this.headerName = this.formRoute === 'open' ? 'Open Position' : 'Requirement';
    const nameLabel = this.formRoute === 'open' ? 'Open Positions' : 'Requirements';
    const routeUrl = this.formRoute === 'recruiter' ? '/recruiter-requirement' : '/requirements';
    this.updateBilForm = this.fb.group({
      billRateControl: [''],
      requestDate: [''],
      rejComment: ['', Validators.required],
    });
    this.submittedProfileForm = this.fb.group({
      firstName: ['', [Validators.pattern('^[a-zA-Z]*$')]],
      lastName: ['', [Validators.pattern('^[a-zA-Z]*$')]],
      vendorName: ['', Validators.pattern('^[a-zA-Z]*$')],
      emailId: ['', [Validators.pattern('^[a-zA-Z]*$')]],
      skillSet: [''],
      experience: [''],
    });
    this.updateprofileForm = this.fb.group({
      tableapproval: ['']
    });
    this.targetDate = this.route.snapshot.queryParamMap.get('tdate');
    this.editRequirementsForm = this.fb.group({
      requirementSkillSet: ['', Validators.required],
      reqName: ['', Validators.required],
      requirementTitle: [''],
      positionCategory: [''],
      department: [''],
      requirementMinRate: [''],
      requirementMaxRate: [''],
      requirementNoofPositions: ['', Validators.required],
      requirementWorkCenterLocation: ['', Validators.required],
      requirementReqExp: ['', Validators.required],
      requirementType: ['', Validators.required],
      requirementStartDate: ['', Validators.required],
      requirementEndDate: ['', Validators.required],
      requirementStatus: ['', Validators.required],
      billingtype: ['', Validators.required],
      duration: [''],
      hrsperWeek: [''],
      requirementPreferredSkillSet: [''],
      requirementDescription: ['', Validators.required],
      requirementResponsibilities: ['', Validators.required],
      requirementQualification: ['', Validators.required],
      requirementComments: [''],
      approval: [''],
      otherskills: ['']
    });
    this.editRequirementsForm.disable();
    this.submitProfileGrid();
    this.getExperienceDropDownData();
    this.getConStatus();
    this.getDepartments();
    this.getPositionCatagory();
    this.getWorkLocation();
    this.getexperience();
    this.getStatus();
    this.getBillingDropdown();
    this.getHireTypeData();
    this.getdSkillset();
    this.editReqData();
  }
  backNavigate() {
    this.router.navigate(['/salesOpenPositions']);
  }
  submitProfileGrid() {
    this.loader = true;
    const row = this.rows;
    const page = this.page;
    // tslint:disable-next-line:max-line-length
    this.apisService.getApi(environment.avaliableSubmitedPro + page + '/' + row + '/' + this.reqId + '?consEmail=&fName=&lName=&exp=').subscribe((res: any) => {
      this.submittedProfilesGridData = res.data.records;
      this.subProRecords = res.data.count;
      this.loader = false;
      if (this.subProRecords !== 0) {
        this.table = true;
        this.records = false;
        setTimeout(() => {
          this.records = false;
        }, 2000);
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.submitProfileGrid();
  }
  search() {
    // tslint:disable-next-line:max-line-length
    const fName = this.submittedProfileForm.controls.firstName.value === null || undefined || '' ? '' : this.submittedProfileForm.controls.firstName.value;
    // tslint:disable-next-line:max-line-length
    const lName = this.submittedProfileForm.controls.lastName.value === null || undefined || '' ? '' : this.submittedProfileForm.controls.lastName.value;
    // tslint:disable-next-line:max-line-length
    const email = this.submittedProfileForm.controls.emailId.value === null || undefined || '' ? '' : this.submittedProfileForm.controls.emailId.value;
    // tslint:disable-next-line:max-line-length
    const exp = this.submittedProfileForm.controls.experience.value === null || this.submittedProfileForm.controls.experience.value === undefined || this.submittedProfileForm.controls.experience.value === '' ? '' : this.submittedProfileForm.controls.experience.value.code;
    this.loader = true;
    const pgFlag = this.page;
    const pgNo = this.rows;
    // tslint:disable-next-line:max-line-length
    const data = '/' + pgFlag + '/' + pgNo + '/' + this.reqId + '?consEmail=' + email + '&fName=' + fName + '&lName=' + lName + '&exp=' + exp;
    this.apisService.getApi(environment.avaliableSubmitedPro + data).subscribe((res) => {
      this.submittedProfilesGridData = res.data.records;
      this.subProRecords = res.data.count;
      if (this.subProRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.loader = false;
    });
  }
  getExperienceDropDownData() {
    this.apisService.getApi(environment.lookupsData + 'RELYE').subscribe((res: any) => {
      this.experienceData = res.data;
      this.experienceData.forEach((element) => {
        this.expLabel[element.code] = [element.label];
      });
    });
  }
  getConStatus() {
    this.apisService.getApi(environment.lookupsData + 'RPLPS').subscribe((res) => {
      this.statusList1 = res.data;
      this.statusList1.forEach((element) => {
        this.statusdata[element.code] = element.label;
      });
    });
  }
  getDepartments() {
    this.apisService.getApi(environment.departments + '/' + this.reqData.createdByAccId).subscribe((res) => {
      this.departmentList = res.data;
    });
  }
  getPositionCatagory() {
    this.apisService.getApi(environment.positionCat + '/' + this.reqData.createdByAccId).subscribe((res) => {
      this.positionData = res.data;
    });
  }
  getWorkLocation() {
    this.apisService.getApi(environment.location + '/' + this.userAccId).subscribe((res) => {
      this.locationList = res.data;
    });
  }
  getexperience() {
    this.apisService.getApi(environment.lookupsData + 'RELYE').subscribe((res: any) => {
      this.exprienceList = res.data;
    });
  }
  getStatus() {
    this.apisService.getApi(environment.lookupsData + 'RELS').subscribe((res) => {
      this.statusList = res.data;
    });
  }
  getBillingDropdown() {
    this.apisService.getApi(environment.lookupsData + 'RELBT').subscribe((res) => {
      this.billingList = res.data;
    });
  }
  getHireTypeData() {
    this.apisService.getApi(environment.lookupsData + 'RELHT').subscribe((res) => {
      this.hireTypeList = res.data;
    });
  }
  getdSkillset() {
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.PreferredSkills = res.data;
      this.PreferredSkills.forEach((element) => {
        this.skillset[element.code] = element.label;
      });
    });
  }
  getSkillSetTable(data) {
    this.array = data;
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.PreferredSkills.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < this.array.length; j++) {
        if (Number(this.PreferredSkills[i].code) === this.array[j]) {
          selectedPrePrimarySkills3.push(this.PreferredSkills[i].label);
        } else {
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  getRolesBasedonCategory(event) {
    this.apisService.getApi(environment.jobTitle + this.userAccId + '/' + event).subscribe((res) => {
      this.jobTitleData = res.data;
    });
  }
  editReqData() {
    this.apisService.getApi(environment.location + '/' + this.reqData.createdByAccId).subscribe((res1) => {
      this.locationList1 = res1.data;
    });
    this.apisService.getApi(environment.requirementEdit + '/' + this.reqId).subscribe((res) => {
      this.reqData = res.data;
      this.getRolesBasedonCategory(this.reqData.positionCategory);
      this.getPositionCategoryBindDataValues(this.reqData);
      this.typeData(this.reqData.hireType);
      this.editRequirementsForm.patchValue({
        reqName: this.reqData.name,
        positionCategory: this.reqData.positionCategory,
        requirementTitle: this.reqData.jobTitle.id,
        department: this.reqData.departmentId,
        requirementMinRate: this.reqData.minRate,
        requirementMaxRate: this.reqData.maxRate,
        requirementNoofPositions: this.reqData.noOfPositions,
        // requirementWorkCenterLocation: this.reqData.location,
        requirementReqExp: this.reqData.experience,
        requirementType: this.reqData.hireType,
        requirementStartDate: this.reqData.plannedStartDate,
        requirementEndDate: this.reqData.plannedEndDate,
        billingtype: this.reqData.billingType,
        duration: this.reqData.duration,
        hrsperWeek: this.reqData.hrsPerWeek,
        requirementStatus: this.statusList.filter(x => x.code === this.reqData.status)[0],
        requirementSkillSet: this.reqData.prefferedSkills.toString(),
        requirementPreferredSkillSet: this.reqData.skills.toString(),
        requirementDescription: this.reqData.description,
        requirementResponsibilities: this.reqData.responsibilities,
        requirementQualification: this.reqData.qualification,
        requirementComments: this.reqData.comments,
        approval: this.reqData.empApprovalReqFlag,
        // otherskills: this.reqData.otherSkills,
      });
      this.editRequirementsForm.controls.requirementWorkCenterLocation.patchValue(
        this.locationList1.filter((x) => x.locId === this.reqData.location)[0]);
      this.editLocationdata = this.locationList1.filter((x) => x.locId === this.reqData.location)[0];
      this.patchlocationString = this.editLocationdata?.name
        + '\n' + this.editLocationdata?.address1 + ',' + this.editLocationdata?.city + ',' +
        this.editLocationdata?.state + ',' + this.editLocationdata?.country;
      const selectedPrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.PreferredSkills.length; i++) {
        const array = this.reqData.prefferedSkills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.PreferredSkills[i].code) === array[j]) {
            selectedPrimarySkills.push(this.PreferredSkills[i]);
          }
        }
      }
      this.editRequirementsForm.controls.requirementPreferredSkillSet.patchValue(selectedPrimarySkills);
      const preferredSkills = this.editRequirementsForm.controls.requirementPreferredSkillSet.value;
      preferredSkills.forEach((element) => {
        this.preferredSkills.push(element.label);
      });
      const selectedPrePrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.PreferredSkills.length; i++) {
        const array = this.reqData.skills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.PreferredSkills[i].code) === array[j]) {
            selectedPrePrimarySkills.push(this.PreferredSkills[i]);
          }
        }
      }
      this.editRequirementsForm.controls.requirementSkillSet.patchValue(selectedPrePrimarySkills);
      selectedPrePrimarySkills.forEach((element) => {
        this.selectedPrimaryskills.push(element.label);
      });
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < selectedPrePrimarySkills.length; i++)
      {
          if (selectedPrePrimarySkills[i].code === '25')
          {
            this.otherskills = true;
            this.editRequirementsForm.controls.otherskills.patchValue(this.reqData.otherSkills);

          }
      }



    });
    // this.apisService.getApi(environment.location + '/' + this.reqData.createdByAccId).subscribe((res1) => {
    //   this.locationList1 = res1.data;
    //   this.editRequirementsForm.controls.requirementWorkCenterLocation.patchValue(
    //     this.locationList1.filter(
    //       (x) => x.locId === this.reqData.location
    //     )[0]
    //   );
    //   this.editLocationdata =this.locationList1.filter(
    //     (x) => x.locId === this.reqData.location
    //   )[0]
    //   this.patchlocationString = this.editLocationdata?.name
    //    + '\n' + this.editLocationdata?.address1 + ',' + this.editLocationdata?.city + ',' +
    //     this.editLocationdata?.state + ',' + this.editLocationdata?.country
    // });
  }
  getPositionCategoryBindDataValues(data) {
    const acctId = data.accId;
    const posnCat = data.category;
    this.requiremntId = data.reqId;
    this.apisService.getApi(environment.maxRate + acctId + '/' + posnCat).subscribe((res) => {
      this.minMaxRate = res.data;
      this.editRequirementsForm.controls.requirementTitle.patchValue(
        this.minMaxRate.filter(x => x.title === data.jobTitle.title)[0]
      );
    });
  }
  typeData(event) {
    if (event === 'C' || event === 'H') {
      this.endDisplay = true;
      this.editRequirementsForm.get('requirementEndDate').enable();
    } else {
      this.endDisplay = false;
      this.editRequirementsForm.get('requirementEndDate').disable();
    }
  }
  getMinrate(event) {
    const availbleRate = this.jobTitleData.find(x => x.id === event);
    this.editRequirementsForm.controls.requirementMinRate.patchValue(availbleRate.minrate);
    this.editRequirementsForm.controls.requirementMaxRate.patchValue(availbleRate.maxrate);
  }
  toggle(menu, event, rowData) {
    this.rowData = rowData;
    this.idToDelete = rowData.profileId;
    this.conId = rowData.conId;
    this.userId = rowData.userId;
    this.consultantName = rowData.consultantName;
    this.tablerowID = rowData.resumeId;
    this.items = [
      { label: 'Profile Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); } },
    ];
  }
  downLoadsToggle() {
    this.downloadOptions = [
      { label: 'Download XLSX', icon: 'pi pi-download', command: (event, i) => { this.exportExcel(); } },
      { label: 'Download PDF', icon: 'pi pi-download', command: (event, i) => { this.exportPdf(); } },
    ];
  }
  reqSkills(data) {
    this.display = true;
    this.reqSkillsValue = data;
  }
  reset($event) {
    this.loader = true;
    this.submittedProfileForm.reset();
    this.loader = false;
    this.submitProfileGrid();
  }
  deleteRow() {
    // tslint:disable-next-line: max-line-length
    Swal.fire({ text: 'Are you sure want to remove ?', icon: 'warning', showCancelButton: true, confirmButtonColor: '#0D416B', confirmButtonText: 'Yes', cancelButtonText: 'No' }).then((result) => {
      if (result.value) {
        this.apisService.deleteApi(environment.removeRequirement + this.idToDelete).subscribe((res: any) => {
          this.submitProfileGrid();
          Swal.fire({ text: res.message, icon: 'success', confirmButtonColor: '#0D416B', iconColor: '#1dbb99', confirmButtonText: 'Ok' });
        });
      }
    });
  }
  downloadFileapi() {
    this.isUploading = true;
    this.filedata = this.rowData;
    const resumeReqBody = {
      attPath: this.rowData.attachmentPath,
    };
    this.apisService.postApi(environment.downloadAttach, resumeReqBody).subscribe((res) => {
      this.resumeRes = res;
      setTimeout(() => {
        this.isUploading = false;
      }, 2000);
      window.location.assign(this.resumeRes.downloadUrl);
    });
  }
  updateAction() {
    this.updateprofileForm.reset();
    this.updateaction = true;
    this.updateactionBtn = false;
    this.filedata = this.rowData;
    this.userid = this.rowData.userId;
    this.resumID = this.rowData.resumeId;
    this.apisService.getApi(environment.requireupdateaction + `/${this.userid}`).subscribe((res: any) => {
      this.updateactionGrid = res.data;
      res.data.forEach((ele) => {
        if (ele.id === this.resumID) {
          this.selectedRowForResume = ele;
          this.updateactionBtn = true;
        }
      });
    });
    const radioid = this.resumID === this.tablerowID;
    this.selectedValue = radioid;
  }

  selectedResumeCheck(data) {
    if (data.attachmentPath === this.rowData.attachmentPath) {
      this.selectedResume = [];
      this.selectedResume.push(data);
    }
    else {
      return;
    }
  }
  updatedownload(data) {
    const resumeReqBody = {
      attPath: data.attachmentPath,
    };
    this.loaderForDownload = true;
    this.apisService.postApi(environment.downloadAttach, resumeReqBody).subscribe((res) => {
      this.resumeRes = res;
      window.location.assign(this.resumeRes.downloadUrl);
      this.loaderForDownload = false;
    });
  }
  onSelect(event) {
    this.updateactionBtn = true;
    this.filedata = this.rowData;
    this.profileID = this.filedata.profileId;
    this.rowdataid = event.data.id;
  }
  submitCust() {
    this.filedata = this.rowData;
    this.reqCount = this.rowData.requestCount;
    this.reqStatus = this.rowData.submissionRequestStatus;
    this.userId = this.filedata.userId;
    if (this.filedata.empApprovalReqFlag === 'N' || this.reqCount !== 0 && this.reqStatus === 'A') {
      // tslint:disable-next-line: max-line-length
      const subID = this.userAccId + '/' + this.reqData.accId + '/' + this.reqData.reqId + '/' + this.userId + '/' + this.reqData.profileId;
      // tslint:disable-next-line: max-line-length
      Swal.fire({
        text: 'Are you sure want to submit?', icon: 'warning', showCancelButton: true, confirmButtonColor: '#0D416B', cancelButtonColor: '#D0D0D0', confirmButtonText: 'Yes', cancelButtonText: 'No', reverseButtons: false,
      }).then((result) => {
        if (result.value) {
          this.apisService.getApi(environment.requirementSubmit + subID).subscribe((res: any) => {
            if (res.success === true) {
              // tslint:disable-next-line: max-line-length
              Swal.fire({ text: 'Candidate submitted to customer', icon: 'success', confirmButtonColor: '#0D416B', iconColor: '#1dbb99', confirmButtonText: 'Ok', reverseButtons: false });
              this.submitProfileGrid();
            }
            else if (res.hasOwnProperty('success') === false) {
              this.messageService.add({ key: 'br', severity: '', life: 3000, sticky: true, closable: true, detail: res.message });
            } else {
              this.messageService.add({ key: 'br', severity: '', life: 3000, sticky: true, closable: true, detail: res.error });
            }
          });
        }
      });
    }
    else if (this.reqCount !== 0 && this.filedata.empApprovalReqFlag === 'Y') {
      // tslint:disable-next-line: max-line-length
      Swal.fire({ text: 'Candidate approval pending for submission', icon: 'warning', confirmButtonColor: '#0D416B', confirmButtonText: 'Ok' });
    }
    else if (this.reqCount === 0 && this.reqStatus === '') {
      Swal.fire({ text: 'Candidate approval request pending', icon: 'warning', confirmButtonColor: '#0D416B', confirmButtonText: 'Ok' });
    }
    else if (this.reqCount !== 0 && this.reqStatus === 'R') {
      this.rejectSubmission = true;
      this.updateBilForm.get('rejComment').patchValue(this.rowData.comments);
    }
  }
  saveRequest() {
    this.billdata = this.rowData;
    this.submitApproval = true;
    const dateValue = this.updateBilForm.controls.requestDate.value;
    if (dateValue === '' || dateValue === null || dateValue === undefined) {
      this.dateErr = true;
      setTimeout(() => {
        this.submitApproval = false;
        this.dateErr = false;
      }, 2000);
    } else {
      const data = {
        objectId: this.billdata.profileId,
        assignedToId: this.billdata.userId,
        targetDate:
          this.datepipe.transform(dateValue, 'yyyy-MM-dd') + ' 00:00:00',
      };
      this.apisService.postApi(environment.requestApproval, data).subscribe((res: any) => {
        this.submitApproval = false;
        this.approvalRequest = false;
        if (res.success === true) {
          // tslint:disable-next-line: max-line-length
          Swal.fire({ text: res.message, icon: 'success', confirmButtonColor: '#0D416B', iconColor: '#1dbb99', confirmButtonText: 'Ok', reverseButtons: false, });
          this.submitProfileGrid();
        } else {
          // tslint:disable-next-line: max-line-length
          Swal.fire({ text: res.message, icon: 'warning', confirmButtonColor: '#0D416B', confirmButtonText: 'Ok', reverseButtons: false, });
        }
      });
    }
    this.submitProfileGrid();
  }
  onUnselect(event) {
    this.updateactionBtn = false;
    const val1 = event.data.id;
  }
  submitAction() {
    const data = {
      id: this.profileID,
      resumeId: this.rowdataid,
    };
    this.apisService.putApi(environment.updateaction, data).subscribe((res: any) => {
      if (res.hasOwnProperty('success') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
        this.submitProfileGrid();
        this.updateaction = false;
      } else if (res.hasOwnProperty('success') === false) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
      } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error, });
      }
    });
  }
  addReq() {
    this.filedata = this.rowData;
    this.profileID = this.filedata.profileId;
    const queryParams = {
      proId: this.profileID,
      reqId: this.requiremntId,
      consultantName: this.consultantName,
      jobTitle: this.reqName,
      conId: this.userId,
    };
    this.router.navigate(['/schedule-interview'], {
      queryParams: {
        proId: this.profileID,
        reqId: this.requiremntId,
        consultantName: this.consultantName,
        jobTitle: this.reqName,
        conId: this.userId,
        from: 'Oedit'
      },
    });
    sessionStorage.setItem('openpositiondata', JSON.stringify(queryParams));
  }
  ratings() {
    this.filedata = this.rowData;
    this.profileID = this.filedata.profileId;
    this.reqId1 = this.filedata.reqId;
    this.router.navigate(['/tech-reviews'], {
      queryParams: {
        proId: this.profileID,
        reqId: this.reqId1,
        conId: this.filedata.conId,
        reqname: this.reqName,
        vendorId: this.filedata.vendorId,
        userId: this.userId,
        type: 'open'
      },
    });
  }
  editSearchconsultant() {
    this.router.navigate(['/editcandidate', this.userId]);
  }
  updateBillrate() {
    this.consultantRequirementDisplay = true;
    this.billdata = this.rowData;
    const createdAccId = JSON.parse(sessionStorage.getItem('reqData')).createdByAccId;
    const userData = JSON.parse(this.apisService.decodeData()).accId;
    this.updateBilForm.controls.billRateControl.reset();
    this.consultantRequirementDisplay = true;
    this.candidateName = this.billdata.consultantName;
    this.payRate = this.billdata.payRate;
    this.apisService.getApi(environment.vendorMarkUp + createdAccId + '/' + userData).subscribe((x) => {
      this.markUpData = x.data[0].markupPercentage;
      this.updateBilForm.controls.billRateControl.patchValue(this.billdata.billRate);
      if (this.updateBilForm.controls.billRateControl.value > this.maxrate) {
        this.errorMsg = true;
        setTimeout(() => {
          this.errorMsg = false;
          this.updateBilForm.controls.billRateControl.reset();
        }, 3000);
      }
    });
    const data = '?billRate=' + this.billdata.billRate + '&profileId=' + this.billdata.userId;
  }
  billRate() {
    this.alertError = false;
    if (this.updateBilForm.controls.billRateControl.value > this.maxrate) {
      this.updateBilForm.controls.billRateControl.reset();
      this.errorMsg = true;
      setTimeout(() => {
        this.errorMsg = false;
      }, 3000);
      // tslint:disable-next-line: max-line-length
    } else if (this.updateBilForm.controls.billRateControl.value === '' || this.updateBilForm.controls.billRateControl.value === null || this.updateBilForm.controls.billRateControl.value === undefined) {
      this.alertError = true;
      setTimeout(() => {
        this.alertError = false;
      }, 3000);
    } else {
      this.errorMsg = false;
    }
  }
  // It is used for the adding the Security Deposit Details
  submitConsultant() {
    if (this.updateBilForm.controls.billRateControl.value > this.maxrate) {
      this.updateBilForm.controls.billRateControl.reset();
      this.alertError = true;
      setTimeout(() => {
        this.alertError = false;
      }, 3000);
      // tslint:disable-next-line: max-line-length
    } else if (this.updateBilForm.controls.billRateControl.value === '' || this.updateBilForm.controls.billRateControl.value === null || this.updateBilForm.controls.billRateControl.value === undefined
    ) {
      this.alertError = true;
      setTimeout(() => {
        this.alertError = false;
      }, 3000);
    } else {
      this.alertError = false;
      if (this.updateBilForm.controls.billRateControl.invalid) {
        this.alertError = true;
        setTimeout(() => {
          this.alertError = false;
        }, 3000);
      } else {
        const data = '?billRate=' + this.updateBilForm.controls.billRateControl.value + '&profileId=' +
          this.billdata.profileId;
        this.loader = true;
        this.apisService.getApi(environment.updateBillRate + data).subscribe((res: any) => {
          this.display1 = false;
          this.loader = false;
          this.availCounsultRes = res;
          if (this.availCounsultRes.hasOwnProperty('success') === true) {
            Swal.fire('Success!', this.availCounsultRes.message, 'success');
            this.popup = false;
            this.consultantRequirementDisplay = false;
            this.submitProfileGrid();
          } else if (
            this.availCounsultRes.hasOwnProperty('failed') === true
          ) {
            Swal.fire('Warning!', this.availCounsultRes.failed, 'warning');
          } else {
            Swal.fire('Error!', this.availCounsultRes.error, 'error');
          }
        });
      }
    }
  }
  payrate(data) {
    this.payrates = data;
    if (this.payrates <= this.maxrate) {
      this.updateBilForm.controls.billRateControl.patchValue(this.payrates);
      this.alertError = false;
    } else if (this.payrates >= this.maxrate) {
      this.updateBilForm.controls.billRateControl.patchValue(this.payrates);
      this.alertError = true;
      setTimeout(() => {
        this.alertError = false;
        this.updateBilForm.controls.billRateControl.reset();
      }, 3000);
    }
  }
  requestApproval() {
    this.approvalRequest = false;
    this.updateBilForm.controls.requestDate.reset();
    this.reqCount = this.rowData.requestCount;
    this.reqStatus = this.rowData.submissionRequestStatus;
    if (this.reqCount === 0) {
      this.approvalRequest = true;
      this.updateBilForm.controls.requestDate.reset();
      this.filedata = this.rowData;
      this.candidatename = this.filedata.consultantName;
      this.userId = this.filedata.userId;
      const dateVal = new Date(this.targetDate);
      this.maximumDate = dateVal;
    }
    else {
      Swal.fire({ text: 'Request already sent', icon: 'warning', confirmButtonText: 'Ok' });
    }
  }
  add() {
    this.router.navigate(['/availablecandidates']);
  }
  profilehistroy() {
    this.displayModal = true;
    this.date = new Date();
    const actId = JSON.parse(this.apisService.decodeData());
    this.apisService.getApi(environment.getProfileActivity + this.rowData.profileId).subscribe((res: any) => {
      this.histroy = res.data;
    });
  }
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
  exportExcel() {
    // tslint:disable-next-line:prefer-const
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    ws['!cols'] = [ {width : 10}, {width : 20}  , {width : 20} , {width : 10}, {width : 10}, {width : 10}, {width : 10}, {hidden : true} ];
    ws['!rows'] = [{hpt : 30}, {hpt : 30}, ];
    ws['!cols'][2] = { hidden: true };
    ws['!cols'][7] = { hidden: true };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ExcelSheet.xlsx');
  }
  exportPdf() {
    this.submittedProfilesGridData.forEach((element) => {
      element.status = this.statusdata[element.profileState];
      element.skills = this.getSkillSetTable(element.requiredSkills);
      element.exper = this.expLabel[element.experience];
    });
    const doc = new jsPDF('p', 'pt');
    autoTable(doc, {
      headStyles: { fillColor: '#2bb9d5', cellPadding: 5   },
      margin: { left: 17},
      columns: this.cols3,
      body: this.submittedProfilesGridData,
      didDrawPage: (dataArg) => {
        doc.text('Submitted Candidates', dataArg.settings.margin.left, 30);
      }
    });
    doc.save('Submitted-Candidates.pdf');
  }
  saveLocation(ev) {
    const data = ev.value;
    this.patchlocationString = data.name  + '\n' + data.address1 + ',' + data.city + ',' + data.state + ',' + data.country;
  }
}
