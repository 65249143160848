import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-certifications',
    templateUrl: './certifications.component.html',
    styleUrls: ['./certifications.component.scss'],
    providers: [MessageService, DatePipe],
})
export class CertificationsComponent implements OnInit {
    CertificatesForm: FormGroup;
    CertificateEditForm: FormGroup;
    CertificateAddForm: FormGroup;
    iconToggle = [];
    totalRecords: any;
    getData: any;
    loader = false;
    pageCount: any;
    first: any;
    typeTable = {};
    loading: boolean;
    AcctRowID: any;
    customerId: any;
    rows: any = 10;
    page: any = 1;
    records: boolean;
    table: boolean;
    statusConversion: any = {};
    statusConversion1: any;
    uploadedFiles: any[] = [];
    showUploadView = false;
    isUploading = false;
    isUploading1 = false;
    displayModal: boolean;
    fileOver: boolean;
    hide: boolean;
    id: any;
    showDialog: boolean;
    showDialogAdd = false;
    // It is used for displaying status dropdown values
    statusData = [
        { label: 'Active', value: 'Active' },
        { label: 'Inactive', value: 'Inactive' },
    ];
    // It is used for displaying table header data
    cols = [
        { field: 'title', header: 'Name' },
        { field: 'organization', header: 'Organization' },
        { field: 'lkObjectType', header: 'Attachment Type' },
        { field: 'issuedDate', header: 'Issued Date' },
        { field: 'expiryDate', header: 'Expired Date' },
        { field: 'rankMarks', header: 'Rank/Marks' },
        { field: 'action', header: 'Action' },
    ];
    customerName: string;
    interviewgrid: any;
    interviewGrid: any;
    tablestatusdata: any;
    attachment: any;
    certificateGrid: any;
    title: string;
    status1: string;
    attachmentType1: string;
    dropdown: void;
    status: any;
    filedata: any;
    rowData: any;
    resumeRes: any;
    conId: any;
    downloadData: any;
    fileName: any;
    fileType: any;
    reqSkillsValue: any[];
    desc: string;
    expiryDate: string;
    issuedDate: string;
    userIdValue: string;
    userId: string;
    comments: string;
    getFile: any;
    getFileName: any;
    fileSize: any;
    errorMsg: string;
    display: boolean;
    displayModal1: boolean;
    fileRes: any;
    fileData: any;
    filePath: any;
    show = false;
    show1 = false;
    disable = false;
    expDateList1: any = [];
    items: { label: string; icon: string; command: () => void; }[];
    showExPiredDate = true;
    filesdatass: boolean;
    classinput: boolean;
    profileupload: boolean;
    name: any;
    // tslint:disable-next-line:max-line-length
    constructor(private datePipe: DatePipe, private fb: FormBuilder, private messageService: MessageService, private route: ActivatedRoute, private apisService: ApisService, private breadcrumbService: BreadcrumbService) {
        this.breadcrumbService.setItems([{ label: 'Certificates', routerLink: '/emp-certificates' }]);
    }
    // Window keydown listener
    @HostListener('window:keydown', ['$event'])
    keyboardInput(event: any) {
        if (event.key === 'Enter') {
            this.searchData();
        }
    }
    ngOnInit() {
        this.classinput = true;
        this.CertificatesForm = this.fb.group({
            title: [''],
            attachment: [''],
            issuedate: [''],
            expdate: [''],
        });
        this.CertificateEditForm = this.fb.group({
            title: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(15)]],
            dateCheck: [''],
            organisation: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(50)]],
            organisationText: [''],
            attachment: ['', Validators.required],
            issuedate: [''],
            expdate: [''],
            rank: ['', [Validators.maxLength(9), Validators.pattern('^[0-9]+$')]],
            comments: ['', Validators.maxLength(200)],
        });
        this.CertificateAddForm = this.fb.group({
            title: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(15)]],
            dateCheck: [''],
            organisation: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(50)]],
            organisationText: [''],
            attachment: ['', Validators.required],
            issuedate: [''],
            expdate: [''],
            comments: ['', [Validators.maxLength(200)]],
            rank: ['', [Validators.maxLength(9), Validators.pattern('^[0-9]+$')]],
            fileControl: ['', Validators.required],
        });
        this.userIdValue = sessionStorage.getItem('userId');
        this.title = this.route.snapshot.queryParamMap.get('title');
        this.attachment = this.route.snapshot.queryParamMap.get('objectType');
        this.comments = this.route.snapshot.queryParamMap.get('description');
        this.expiryDate = this.route.snapshot.queryParamMap.get('expiryDate');
        this.issuedDate = this.route.snapshot.queryParamMap.get('issuedDate');
        this.getAttachmentType();
        this.gridData();
    }
    onlyExpiryDate() {
        const startyear = this.CertificatesForm.controls.expdate.value;
        const data = {
            availableDate: []
        };
        const date = new Date().getFullYear();
        for (let i = startyear; i <= date; i++) {
            data.availableDate.push({ id: i });
        }
        this.expDateList1 = data.availableDate;
    }
    getType() {
        this.attachment.forEach((element) => {
            this.typeTable[element.code] = element.label;
        });
    }
    getAttachmentType() {
        this.apisService.getApi(environment.lookupsData + 'ATAT').subscribe((res: any) => {
            this.attachment = res.data;
            const id = ['C', 'O'];
            this.attachment = this.attachment.filter((x) => {
                return id.includes(x.code);
            });
            this.getType();
        });
    }
    // It is used to show action pop up
    toggle(event, data, rowData) {
        this.rowData = rowData;
        const newLocal = 'pi pi-fw pi-download';
        this.items = [{ label: ' Edit', icon: 'pi pi-pencil', command: () => { this.updateAction(rowData); }, },
        { label: 'Download', icon: newLocal, command: () => { this.downloadFileapi(); }, },
        ];
    }
    // It is used to download table data
    downloadFileapi() {
        this.isUploading = true;
        this.filedata = this.rowData.id;
        this.apisService.getApi(environment.downloaddocument + '/' + this.filedata).subscribe((res: any) => {
            this.downloadData = res;
            window.location.assign(this.downloadData.downloadUrl);
            this.isUploading = false;
        });
    }
    // It is used to add attachment on table
    handleFileInput(event) {
        this.fileName = event.target.files[0];
        this.name = this.fileName.name;
        if (this.fileName.name.length > 25){
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'File name should be 25 alphabets.' });
            this.cancelfiles();
          }
        if (this.fileName.size > 10485760){
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please upload below 10MB.' });
            this.cancelfiles();
           }
         else{
        this.fileType = this.fileName.name.split('.').pop();
        const formData: FormData = new FormData();
        formData.append('file', this.fileName);
        this.filedata = formData;
        // tslint:disable-next-line: no-conditional-assignment
        if (this.filedata = formData) {
            this.filesdatass = true;
        } else {
            this.filesdatass = false;
        }
    }
    }
    // It is used to bind certification table data
    updateAction(rowData) {
        this.showDialog = true;
        this.CertificateEditForm.reset();
        this.hide = true;
        this.id = rowData.id;
        this.CertificateEditForm.controls.title.patchValue(rowData.title);
        this.CertificateEditForm.controls.organisation.patchValue(rowData.organization);
        this.CertificateEditForm.controls.rank.patchValue(rowData.rankMarks);
        this.CertificateEditForm.controls.issuedate.patchValue(rowData.issuedDate ? new Date(rowData.issuedDate) : '');
        if (rowData.expiryDate !== '' && rowData.expiryDate != null) {
            this.CertificateEditForm.controls.expdate.enable();
            this.CertificateEditForm.controls.dateCheck.patchValue(false);
            this.show1 = false;
            this.disableCheckboxValue1();
            this.CertificateEditForm.controls.expdate.patchValue(new Date(rowData.expiryDate));
        }
        else {
            this.CertificateEditForm.controls.expdate.disable();
            this.show1 = true;
            this.CertificateEditForm.controls.dateCheck.patchValue(true);
            this.CertificateEditForm.controls.expdate.patchValue(new Date());
        }
        this.CertificateEditForm.controls.attachment.patchValue(this.attachment.filter((x) => x.code === rowData.lkObjectType)[0]);
        this.CertificateEditForm.controls.comments.patchValue(rowData.description);
    }
    // It is used to show certification table data
    gridData() {
        this.loader = true;
        const pageNo = this.page;
        const pageCount = this.rows;
        const data = pageNo + '/' + pageCount;
        const actId = JSON.parse(this.apisService.decodeData());
        this.apisService.getApi(environment.certificationGrid + data + '/' + actId.userId).subscribe((res: any) => {
            this.certificateGrid = res.data;
            this.loader = false;
            this.table = true;
            this.totalRecords = res.count;
            if (this.totalRecords === 0) {
                this.records = true;
                this.table = false;
            } else {
                this.records = false;
                this.table = true;
            }
        });
    }
    // It is used to disable the checkbox
    disableAddCheckboxValue(data) {
        if (data.checked === true) {
            this.showExPiredDate = false;
            this.show = true;
        } else {
            this.showExPiredDate = true;
            this.show = false;
        }
    }
    disableCheckboxValue() {
        this.CertificateAddForm.controls.expdate.enable();
    }
    disableEditCheckboxValue(data) {
        if (data.checked === true) {
            this.CertificateEditForm.controls.expdate.disable();
            this.show1 = true;
        } else {
            this.show1 = false;
            this.disableCheckboxValue1();
        }
    }
    disableCheckboxValue1() {
        this.CertificateEditForm.controls.expdate.enable();
    }
    // It is used for server side pagination
    paginate(event) {
        let i = 1;
        this.first = event.first;
        this.rows = event.rows;
        this.page = event.page + i++;
        this.pageCount = event.pageCount;
        this.gridData();
        this.getType();
    }
    // It is used to search table data
    searchData() {
        this.loader = true;
        // tslint:disable-next-line:max-line-length
        const certificatetitle = this.CertificatesForm.controls.title.value === undefined || this.CertificatesForm.controls.title.value === '' || this.CertificatesForm.controls.title.value === null ? '' : this.CertificatesForm.controls.title.value;
        // tslint:disable-next-line: max-line-length
        const certificateAttachmentType = this.CertificatesForm.controls.attachment.value === undefined || this.CertificatesForm.controls.attachment.value === '' || this.CertificatesForm.controls.attachment.value === null ? '' : this.CertificatesForm.controls.attachment.value.code;
        // tslint:disable-next-line: max-line-length
        const certificatexpiryDate = this.CertificatesForm.controls.expdate.value === undefined || this.CertificatesForm.controls.expdate.value === '' || this.CertificatesForm.controls.expdate.value === null ? '' : this.datePipe.transform(this.CertificatesForm.controls.expdate.value, 'MM-dd-yyyy');
        // tslint:disable-next-line: max-line-length
        const certificateIssueDate = this.CertificatesForm.controls.issuedate.value === undefined || this.CertificatesForm.controls.issuedate.value === '' || this.CertificatesForm.controls.issuedate.value === null ? '' : this.datePipe.transform(this.CertificatesForm.controls.issuedate.value, 'MM-dd-yyyy');
        const actId = JSON.parse(this.apisService.decodeData());
        // tslint:disable-next-line: max-line-length
        const data = this.page + '/' + this.rows + '/' + actId.userId + '?title=' + certificatetitle + '&issuedDate=' + certificateIssueDate + '&expiredDate=' + certificatexpiryDate + '&objectType=' + certificateAttachmentType;
        // tslint:disable-next-line: deprecation
        this.apisService.getApi(environment.certificationGrid + data).subscribe((res) => {
            this.loader = false;
            this.certificateGrid = res.data;
            this.totalRecords = res.count;
            if (this.totalRecords === 0) {
                this.records = true;
                this.table = false;
            } else {
                this.records = false;
                this.table = true;
            }
            this.loader = false;
        });
    }
    // It is used to reset search fields
    reset() {
        this.CertificatesForm.reset();
        this.loader = true;
        this.gridData();
        this.getType();
    }
    // It is used to upload files
    onUpload(files) {
        const actId = JSON.parse(this.apisService.decodeData());
        this.isUploading1 = true;
        const formData: FormData = new FormData();
        formData.append('file', files[0]);
        // tslint:disable-next-line: max-line-length
        this.apisService.postApi(environment.fileuploadCertificates + 'O' + '/' + actId.userId + '?file=', formData).subscribe((res: any) => {
            if (res.success === true) {
                this.gridData();
                this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Inserted Successfully.' });
                this.isUploading1 = false;
                this.showUploadView = false;
            } else {
                setTimeout(() => {
                    this.isUploading1 = false;
                }, 2000);
                this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
                this.gridData();
            }
        });
    }
    toggleUploadView() {
        this.showUploadView = !this.showUploadView;
    }
    // It is used to upload files
    uploadFile() {
        const formData: FormData = new FormData();
        this.uploadedFiles.forEach((file) => {
            formData.append('file', file);
        });
        this.apisService.postApi(environment.fileuploadDepartments, formData).subscribe((res: any) => {
        });
    }
    // Drag over listener
    @HostListener('dragover', ['$event']) onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }
    // Drag leave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }
    // Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
        const files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.isUploading1 = true;
            this.onUpload(files);
        }
    }
    // It is used to update certification
    submitAction() {
        this.hide = true;
        if (this.CertificateEditForm.invalid) {
            const controls = this.CertificateEditForm.controls;
            Object.keys(controls).forEach((key) => {
                controls[key].markAsTouched();
            });
            this.hide = false;
        } else {
            this.disable = true;
            const expdate = this.CertificateEditForm.controls.expdate.value;
            const data = {
                id: this.rowData.id,
                lkObjectType: this.CertificateEditForm.controls.attachment.value.code,
                institutionName: this.CertificateEditForm.controls.organisation.value,
                title: this.CertificateEditForm.controls.title.value,
                description: this.CertificateEditForm.controls.comments.value,
                issuedDate: this.datePipe.transform(this.CertificateEditForm.controls.issuedate.value, 'yyyy-MM-dd'),
                // tslint:disable-next-line:max-line-length
                expiryDate: expdate === undefined || expdate === '' || expdate === null || this.show1 === true ? '' : this.datePipe.transform(this.CertificateEditForm.controls.expdate.value, 'yyyy-MM-dd'),
                rankMarks: this.CertificateEditForm.controls.rank.value,
            };
            this.apisService.putApi(environment.editCertificate, data).subscribe((res: any) => {
                this.hide = false;
                this.getData = res;
                this.loader = false;
                this.gridData();
                if (this.getData.hasOwnProperty('success') === true) {
                    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message });
                    this.disable = false;
                    this.showDialog = false;
                    this.gridData();
                    this.searchData();
                } else if (this.getData.hasOwnProperty('failed') === true) {
                    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message });
                    this.loader = false;
                } else {
                    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error });
                    this.loader = false;
                }
                this.CertificateEditForm.reset();
                this.loader = true;
            });
        }
    }
    // It is used to show add pop up
    addlocation() {
        this.filesdatass = false;
        this.showDialogAdd = true;
        this.CertificateAddForm.reset();
        this.show = false;
        this.showExPiredDate = true;
    }
    // It is used to upload attachment
    uploadAttachment() {
        this.hide = true;
        if (this.CertificateAddForm.invalid) {
            const controls = this.CertificateAddForm.controls;
            Object.keys(controls).forEach((key) => {
                controls[key].markAsTouched();
            });
            this.hide = false;
        } else {
            this.disable = true;
            const actId = JSON.parse(this.apisService.decodeData());
            // tslint:disable-next-line:max-line-length
            const data1 = this.CertificateAddForm.controls.attachment.value.code;
            this.apisService.postApi(environment.profileUpload + actId.userId + '/' + data1, this.filedata).subscribe((res: any) => {
                this.fileRes = res;
                this.fileData = res.data;
                this.filePath = res.data.path;
                this.fileSize = res.data.fileSize;
                this.addCertificate();
                if (this.fileRes.hasOwnProperty('success') === true) {
                    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.fileRes.message });
                    this.disable = false;
                    this.showDialogAdd = false;
                    this.gridData();
                    this.searchData();
                } else {
                    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.fileRes.error });
                }
            });
        }
    }
    // It is used to add certificate
    addCertificate() {
        this.hide = true;
        if (this.CertificateAddForm.invalid) {
            const controls = this.CertificateAddForm.controls;
            Object.keys(controls).forEach((key) => {
                controls[key].markAsTouched();
            });
            this.hide = false;
        } else {
            const issuedate = this.CertificateAddForm.controls.issuedate.value;
            const expdate = this.CertificateAddForm.controls.expdate.value;
            const actId = JSON.parse(this.apisService.decodeData());
            const addData = {
                accId: 0,
                usrId: actId.userId,
                title: this.CertificateAddForm.controls.title.value,
                institutionName: this.CertificateAddForm.controls.organisation.value,
                description: this.CertificateAddForm.controls.comments.value,
                lkObjectType: this.CertificateAddForm.controls.attachment.value.code,
                // tslint:disable-next-line:max-line-length
                issuedDate: issuedate === undefined || issuedate === '' || issuedate === null ? '' : this.datePipe.transform(issuedate, 'yyyy-MM-dd'),
                filePath: this.filePath,
                // tslint:disable-next-line:max-line-length
                rankMarks: this.CertificateAddForm.controls.rank.value === undefined || this.CertificateAddForm.controls.rank.value === null || this.CertificateAddForm.controls.rank.value === '' ? '' : parseInt(this.CertificateAddForm.controls.rank.value),
                // tslint:disable-next-line: max-line-length
                expiryDate: expdate === undefined || expdate === '' || expdate === null ? '' : this.datePipe.transform(expdate, 'yyyy-MM-dd'),
            };
            this.apisService.postApi(environment.addCertificate, addData).subscribe((res: any) => {
                this.getData = res;
                this.showDialogAdd = false;
                this.CertificateAddForm.reset();
                if (this.getData.hasOwnProperty('success') === true) {
                    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message });
                    this.searchData();
                } else if (this.getData.hasOwnProperty('failed') === true) {
                    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.failed });
                } else {
                    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error });
                }
            });
        }
    }
    cancelfiles() {
        this.CertificateAddForm.controls.fileControl.reset();
        this.filesdatass = false;
        this.classinput = false;
        this.profileupload = true;
    }
}
