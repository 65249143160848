<p-accordion>
    <p-accordionTab header="Event Details" [selected]="detailspanel">
        <form [formGroup]="eventsForm">
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">Event Name<label class="mandatoryColor">*</label></label>
                    <input type="text" pInputText placeholder="Event Name" autocomplete="off" formControlName="eventname"
                        maxlength="26">
                        <span class="validationtest"
                        *ngIf="this.eventsForm.controls.eventname.touched && this.eventsForm.controls.eventname.invalid && this.eventsForm.controls.eventname.errors.required">
                        Please enter event name
                   </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">Location<label class="mandatoryColor">*</label></label>
                    <p-dropdown optionLabel="name" [options]="location" autocomplete="off" placeholder="Select Location"
                    [showClear]="true"  formControlName="location">
                    </p-dropdown>
                    <span class="validationtest"
                    *ngIf="this.eventsForm.controls.location.touched && this.eventsForm.controls.location.invalid && this.eventsForm.controls.location.errors.required">
                    Please select location
               </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">Event Link<label class="mandatoryColor">*</label></label>
                    <input type="text" pInputText placeholder="Event Link" autocomplete="off" formControlName="link"
                        maxlength="26">
                        <span class="validationtest"
                        *ngIf="this.eventsForm.controls.link.touched && this.eventsForm.controls.link.invalid && this.eventsForm.controls.link.errors.required">
                        Please enter event link
                   </span>
                </div>
            </div>
    
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <label class="inputfields"> Description/Instructions </label>
                    <textarea  autocomplete="off" pInputTextarea rows="8" cols="30"
                        placeholder=" Description" formControlName="description"></textarea>
                </div>
            </div>
    
            <div class="p-grid p-fluid">
                
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                   
                    <label class="inputfields">Event Color<label class="mandatoryColor">*</label></label><br>
                    <span>  <em class="pi pi-circle-on purplecolor"  (click)="eventColor('violet')"><em *ngIf="violet" class="pi pi-check checked"></em></em></span>
                    <span><em class="pi pi-circle-on bluecolor"  (click)="eventColor('blue')"><em *ngIf="blue" class="pi pi-check checked"></em></em></span>
                    <span><em class="pi pi-circle-on greencolor "  (click)="eventColor('green')"><em *ngIf="green" class="pi pi-check checked"></em></em></span>
                   <span><em class="pi pi-circle-on yellowcolor"  (click)="eventColor('yellow')"><em *ngIf="yellow" class="pi pi-check checked"></em></em></span>
                   <span><em class="pi pi-circle-on redcolor"  (click)="eventColor('red')"><em *ngIf="red" class="pi pi-check checked"></em></em></span>
                   <span> <em class="pi pi-circle-on pinkcolor"  (click)="eventColor('pink')"><em *ngIf="pink" class="pi pi-check checked"></em></em></span>
    
                  
                </div>  
               
            </div>
            <div class="btnsCSS1">
                <button pButton type="button" label="Next" (click)="eventdetailsfn()"></button>
            </div>
            <h1></h1>
            <br>
        </form>
    </p-accordionTab>
    <div *ngIf="event2">
    <p-accordionTab header="Event Availability"  [selected]="availabilitypanel">
        <form [formGroup]="availabilityForm">
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">Duration</label>
                    <p-dropdown optionLabel="name" [options]="duration" autocomplete="off" placeholder="Select Duration"
                    [showClear]="true"  formControlName="duration">
                    </p-dropdown>

                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
                    <label class="inputfields">What's the max number of these events that you want per
                        day?</label>
                    <input type="text" pInputText placeholder="Max Events" autocomplete="off"
                        formControlName="maxevents" maxlength="26">

                </div>
            </div>
            <label class="inputfields"><strong>Want to add time before or after your events?</strong></label>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Before Event</label>
                    <p-dropdown optionLabel="name" [options]="duration" autocomplete="off"
                    [showClear]="true"  placeholder="Select Before Event" formControlName="bevent">
                    </p-dropdown>

                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">After Event</label>
                    <p-dropdown optionLabel="name" [options]="duration" autocomplete="off"
                    [showClear]="true"  placeholder="Select After Event" formControlName="aevent">
                    </p-dropdown>
                </div>
            </div>
            <div class="btnsCSS1">
             
                <button pButton type="button" label="Next" (click)="eventavailability()"></button>
            </div>
        </form>
        <h1></h1>
        <br>
    </p-accordionTab>
</div>
<div *ngIf=event3>
        <p-accordionTab header="Booking Experience"  [selected]="booking">
        <strong class="inputfields">When can invitees schedule? </strong>
        <strong class="inputfields">Invitees can schedule...</strong>
        <form [formGroup]="bookingForm">
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 alignmented">
                    <p-radioButton formControlName="typeofInvite" value="days" (onClick)="selectedoption('A')"></p-radioButton>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                    <input type="text" pInputText placeholder="Days" autocomplete="off"
                    formControlName="days" maxlength="26">
                </div>
                <p>Available days into the feature</p>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 alignmented">
                    <p-radioButton formControlName="typeofInvite"  value="range" (onClick)="selectedoption('B')"></p-radioButton>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                  <p>Within a date range</p>
                </div>
             
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3" *ngIf="radiooption">
                        <p-calendar id="popup" placeholder="From date" dateFormat="mm-dd-yy"  [showIcon]="true" (onSelect)="datavalidation()"
                          [showButtonBar]="true" formControlName="fromdate">
                        </p-calendar>
                      </div>
                       <div class="p-col-12 p-md-12 p-sm-12 p-lg-3" *ngIf="radiooption">
                        <p-calendar id="popup" placeholder="To date " dateFormat="mm-dd-yy" [minDate]="minDate" [showIcon]="true"
                          [showButtonBar]="true" formControlName="todate">
                        </p-calendar>
                      </div>
              
               
            </div>  <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 alignmented">
                    <p-radioButton formControlName="typeofInvite" value="full" (onClick)="selectedoption('C')"></p-radioButton>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                    <p>Indefinitely into the future</p>
                  </div>
            </div>
            <br>
            <p><strong>How frequently do you want to show start times?</strong></p>
            <label class="inputfields">Show start times every...</label>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                    <p-dropdown optionLabel="name" [options]="duration" autocomplete="off"
                    [showClear]="true"   placeholder="Select Start Time" formControlName="starttime">
                    </p-dropdown>
                  </div>
            </div>
            <br>
            <p><strong>Scheduling conditions</strong></p>
            <label class="inputfields">Invitees can't schedule within...</label>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                    <input type="text" pInputText placeholder="Time" autocomplete="off"
                    formControlName="conditions" maxlength="26">
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <p-dropdown optionLabel="name" [options]="shedule" autocomplete="off"
                    [showClear]="true"   placeholder="Select Shedule Time" formControlName="shedule">
                    </p-dropdown>
                </div>
                <p>of an event start time</p>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Time Zone</label>
                    <p-dropdown optionLabel="label" [options]="zone" autocomplete="off"
                    [showClear]="true"   placeholder="Select Time Zone" formControlName="zone">
                    </p-dropdown>
                </div>
            </div>
         
           
            <div class="btnsCSS1">
             
                <button pButton type="button" label="Save & close" (click)="bookingexp()"></button>
            </div>
        </form>
<h1>
</h1>
<br>
    </p-accordionTab>
</div>

</p-accordion>