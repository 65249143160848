import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-vendor-bank',
  templateUrl: './vendor-bank.component.html',
  styleUrls: ['./vendor-bank.component.scss'],
  providers: [DatePipe, MessageService],
})
export class VendorBankComponent implements OnInit {
  bankFrom: any;
  displayAddModel: boolean;
  buttonLabel: string;
  headerTitle: string;
  accountId: any;
  rows: any = 10;
  page: any = 1;
  totalRecords: any;
  records: boolean;
  table: boolean;
  bankForm1: FormGroup;
  cols = [
    { field: 'bankName', header: 'Bank Name', width: '7%' },
    { field: 'beneficiaryName', header: 'Beneficiary Name', width: '7%' },
    { field: 'swiftCode', header: 'Swift Code', width: '7%' },
    { field: 'createdBy', header: 'Created By', width: '7%' },
    { field: 'customerCount', header: 'Customer Count', width: '7%' },
    { field: 'action', header: 'Action', width: '4%' },
  ];
  countrtList: any;
  editState: any;
  stateList: any;
  country: any;
  loading: boolean;
  searchData: any;
  first: any;
  pageCount: any;
  bankSearch: FormGroup;
  gridData: any;
  accId: any;
  loader: boolean;
  disable: boolean;
  items: { label: string; icon: string; command: () => void; }[];
  rowData: any;
  displayAddModel1: boolean;
  id: any;
  contryTable: any;
  contryTable1: any;
  stateList1: any = [];
  // tslint:disable-next-line: max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private breadcrumbService: BreadcrumbService, private messageService: MessageService, private router: Router) {
    this.breadcrumbService.setItems([
      { label: 'Bank Accounts', routerLink: '/bank-accounts' },
    ]);
  }
  ngOnInit(): void {
    const userdetails = JSON.parse(this.apisService.decodeData());
    this.accId = userdetails.accId;
    this.bankSearch = this.fb.group({
      bankName1: [''],
      beneficiaryName: [''],
    });
    this.bankForm1 = this.fb.group({
      bankName: ['', [Validators.pattern('^[\\sa-zA-z]*$'), Validators.required, Validators.maxLength(50)]],
      swiftCode: ['', [Validators.required, Validators.maxLength(14)]],
      ifscCode: ['', [Validators.required, Validators.maxLength(14)]],
      accNo: ['', [Validators.required, Validators.maxLength(25)]],
      beneficiaryName: ['', [Validators.required, Validators.maxLength(50)]],
      address: ['', [Validators.required, Validators.maxLength(110)]],
      city: ['', [Validators.required, Validators.maxLength(20)]],
      country: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', [Validators.required, Validators.maxLength(10)]],
    });
    this.gridTable();
    this.getCountryDropdown();
  }
  getCountryDropdown() {
    const country = 'adlc';
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'adlc').subscribe((res) => {
      this.country = res.data;
    });
  }
  getState(country) {
    if (country === null || '' || undefined) { }
    else {    // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.states + country).subscribe((res: any) => {
        this.stateList1 = res.data;
        // this.bankForm1.get('state').patchValue(this.stateList.filter((x) => x.code === this.editState[0].bankState)[0]);
      });
    }
  }
  addNewBank() {
    this.bankForm1.reset();
    this.displayAddModel = true;
    this.buttonLabel = 'Save';
    this.headerTitle = 'Add';
  }
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.gridTable();
    }
  }
  customers(id) {
    this.router.navigate(['/add-customer-to-bank'], {
      queryParams: {
        bankName: id.bankName,
        id: id.id
      },
    });
  }
  reset() {
    this.bankSearch.reset();
    this.gridTable();
  }
  cancelAddBank() {
    this.displayAddModel = false;
  }
  canceleditbank() {
    this.displayAddModel1 = false;
  }
  toggle(event, data) {
    this.rowData = data;
    this.items = [
      { label: 'Edit', icon: 'pi pi-pencil', command: () => { this.editRow(data); } },
      { label: 'Customers', icon: 'fa fa-handshake-o', command: () => { this.customers(data); } },
    ];
  }
  gridTable() {
    this.loader = true;
    // tslint:disable-next-line: max-line-length
    const bank = this.bankSearch.controls.bankName1.value === undefined || this.bankSearch.controls.bankName1.value === null ? '' : this.bankSearch.controls.bankName1.value;
    // tslint:disable-next-line: max-line-length
    const benName = this.bankSearch.controls.beneficiaryName.value === undefined || this.bankSearch.controls.beneficiaryName.value === null ? '' : this.bankSearch.controls.beneficiaryName.value;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.getBankTable + '/' + this.page + '/' + this.rows + '/' + this.accId + '?' + 'bankName=' + bank + '&beneficiaryName=' + benName).subscribe((res: any) => {
      this.gridData = res.data.records;
      this.totalRecords = res.data.count;
      this.loader = false;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  addBank() {
    if (this.bankForm1.invalid) {
      const controls = this.bankForm1.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      const body = {
        // tslint:disable-next-line: max-line-length
        bankName: this.bankForm1.controls.bankName.value === undefined || this.bankForm1.controls.bankName.value === '' ? null : this.bankForm1.controls.bankName.value,
        bankAbaRoutingNo: '9988',
        // tslint:disable-next-line:max-line-length
        swiftCode: this.bankForm1.controls.swiftCode.value === undefined || this.bankForm1.controls.swiftCode.value === '' ? null : this.bankForm1.controls.swiftCode.value,
        // tslint:disable-next-line:max-line-length
        ifscCode: this.bankForm1.controls.ifscCode.value === undefined || this.bankForm1.controls.ifscCode.value === '' ? null : this.bankForm1.controls.ifscCode.value,
        // tslint:disable-next-line:max-line-length
        bankAccountNo: this.bankForm1.controls.accNo.value === undefined || this.bankForm1.controls.accNo.value === '' ? null : this.bankForm1.controls.accNo.value,
        // tslint:disable-next-line:max-line-length
        bankBeneficiaryName: this.bankForm1.controls.beneficiaryName.value === undefined || this.bankForm1.controls.beneficiaryName.value === '' ? null : this.bankForm1.controls.beneficiaryName.value,
        // tslint:disable-next-line:max-line-length
        bankAddress: this.bankForm1.controls.address.value === undefined || this.bankForm1.controls.address.value === '' ? null : this.bankForm1.controls.address.value,
        // tslint:disable-next-line:max-line-length
        bankLkCountry: this.bankForm1.controls.country.value === undefined || this.bankForm1.controls.country.value === '' ? null : this.bankForm1.controls.country.value.code,
        // tslint:disable-next-line:max-line-length
        bankLkState: this.bankForm1.controls.state.value === undefined || this.bankForm1.controls.state.value === '' ? null : this.bankForm1.controls.state.value.code,
        // tslint:disable-next-line:max-line-length
        bankCity: this.bankForm1.controls.city.value === undefined || this.bankForm1.controls.city.value === '' ? null : this.bankForm1.controls.city.value,
        bankDistrict: 'VIZAG',
        // tslint:disable-next-line:max-line-length
        bankZip: this.bankForm1.controls.zip.value === undefined || this.bankForm1.controls.zip.value === '' ? null : this.bankForm1.controls.zip.value,
        modifiedBy: '1',
      };
      this.disable = true;
      // tslint:disable-next-line: deprecation
      this.apisService.postApi(environment.saveBank, body).subscribe((res: any) => {
        if (res.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
          this.displayAddModel = false;
          this.disable = false;
          this.bankForm1.reset();
          this.gridTable();
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
          this.disable = false;
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
          this.disable = false;
        }
      });
    }
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridTable();
  }
  editRow(data) {
    this.displayAddModel1 = true;
    this.bankForm1.reset();
    this.id = data.id;
    this.editState = data;
    this.getState(data.country);
    this.bankForm1.controls.bankName.patchValue(data.bankName);
    this.bankForm1.controls.address.patchValue(data.bankAddress);
    this.bankForm1.controls.ifscCode.patchValue(data.ifscCode);
    this.bankForm1.controls.accNo.patchValue(data.bankAccountNo);
    this.bankForm1.controls.swiftCode.patchValue(data.swiftCode);
    this.bankForm1.controls.beneficiaryName.patchValue(data.beneficiaryName);
    this.bankForm1.controls.zip.patchValue(data.bankZip);
    this.bankForm1.controls.city.patchValue(data.bankCity);
    this.bankForm1.controls.country.patchValue(this.country.filter((x) => x.code === data.bankCountry)[0]);
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.states + data.bankCountry).subscribe((res: any) => {
      this.stateList = res.data;
      this.bankForm1.get('state').patchValue(this.stateList.filter((x) => x.code === data.bankState)[0]);
    });
  }
  updatebank() {
    if (this.bankForm1.invalid) {
      const controls = this.bankForm1.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      const body = {
        // tslint:disable-next-line:max-line-length
        bankName: this.bankForm1.controls.bankName.value === undefined || this.bankForm1.controls.bankName.value === '' ? null : this.bankForm1.controls.bankName.value,
        bankAbaRoutingNo: '9988',
        // tslint:disable-next-line:max-line-length
        swiftCode: this.bankForm1.controls.swiftCode.value === undefined || this.bankForm1.controls.swiftCode.value === '' ? null : this.bankForm1.controls.swiftCode.value,
        // tslint:disable-next-line:max-line-length
        ifscCode: this.bankForm1.controls.ifscCode.value === undefined || this.bankForm1.controls.ifscCode.value === '' ? null : this.bankForm1.controls.ifscCode.value,
        // tslint:disable-next-line:max-line-length
        bankAccountNo: this.bankForm1.controls.accNo.value === undefined || this.bankForm1.controls.accNo.value === '' ? null : this.bankForm1.controls.accNo.value,
        // tslint:disable-next-line:max-line-length
        bankBeneficiaryName: this.bankForm1.controls.beneficiaryName.value === undefined || this.bankForm1.controls.beneficiaryName.value === '' ? null : this.bankForm1.controls.beneficiaryName.value,
        // tslint:disable-next-line:max-line-length
        bankAddress: this.bankForm1.controls.address.value === undefined || this.bankForm1.controls.address.value === '' ? null : this.bankForm1.controls.address.value,
        // tslint:disable-next-line:max-line-length
        bankLkCountry: this.bankForm1.controls.country.value === undefined || this.bankForm1.controls.country.value === '' ? null : this.bankForm1.controls.country.value.code,
        // tslint:disable-next-line:max-line-length
        bankLkState: this.bankForm1.controls.state.value === undefined || this.bankForm1.controls.state.value === '' ? null : this.bankForm1.controls.state.value.code,
        // tslint:disable-next-line:max-line-length
        bankCity: this.bankForm1.controls.city.value === undefined || this.bankForm1.controls.city.value === '' ? null : this.bankForm1.controls.city.value,
        bankDistrict: 'VIZAG',
        // tslint:disable-next-line:max-line-length
        bankZip: this.bankForm1.controls.zip.value === undefined || this.bankForm1.controls.zip.value === '' ? null : this.bankForm1.controls.zip.value,
        modifiedBy: '1',
        id: this.id,
      };
      this.disable = true;
      // tslint:disable-next-line: deprecation
      this.apisService.putApi(environment.saveBank, body).subscribe((res: any) => {
        if (res.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
          this.displayAddModel1 = false;
          this.disable = false;
          this.gridTable();
          this.bankForm1.reset();
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
        }
      });
    }
  }
}
