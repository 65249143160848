<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
  <p-panel>
    <!-- Panel for Vendor Details form -->
    <p-header *ngIf=this.disableradio>
      <div *ngIf="((roleId === 1) || (roleId === 0))" class="pi pi-arrow-left panelBackRoute"
        [routerLink]="['/accounts']"></div>
    </p-header>
    <form [formGroup]="VendorDetailsForm">
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 aligncenter">
          <div class="p-grid p-fluid">
            <div class="p-col-4 p-md-4 p-sm-12" class="imageDiv">
              <label class="hoverable" for="fileInput">
                <img alt="" disable="true" class="img1"
                  [src]="url ? url : (this.accimage?this.accimage:'https://d2r8nxfsv3v6y3.cloudfront.net/images/Account.png')">
                <div class="middle">
                </div>
                <div class="hover-text">
                  Choose file
                  <h4 class="success-message" *ngIf="url"> Image added successfully </h4>
                </div>
                <div class="background"></div>
              </label>
              <br />
            </div>
          </div>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-9" style="
        position: relative;
        top: -8px;">
          <br><br><br>
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
              <label class="inputfields"> Account Name</label>
              <input autocomplete="off" id="input" type="text" pInputText placeholder="Account Name"
                formControlName="VendorName" disabled="true" maxlength="40">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
              <label class="inputfields">Account URL</label>
              <input autocomplete="off" id="input" type="text" pInputText placeholder="Website Address" disabled="true"
                formControlName="VendorUrl" maxlength="60">
            </div>

            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
              <label class="inputfields">Status<span class="mandatoryColor">*</span> </label>
              <p-dropdown [options]="status1" placeholder="Select Status" optionLabel="label"
                formControlName="VendorStatus" [showClear]="true"></p-dropdown>
              <span class="validationtest" *ngIf="this.VendorDetailsForm.get('VendorStatus').touched &&
                                 this.VendorDetailsForm.get('VendorStatus').invalid &&
                                  this.VendorDetailsForm.get('VendorStatus').errors.required">
                Please select status
              </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
              <label class="inputfields">Source<span class="mandatoryColor"></span> </label>
              <p-dropdown [options]="srcoptions" placeholder="Select Source" optionLabel="label"
                formControlName="source" [showClear]="true"></p-dropdown>
            </div>
          </div>

        </div>
      </div>
      <div>
        <h5 id="h3tag"> Address Details</h5>

        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
            <label class="inputfields">Address Line 1</label>
            <input autocomplete="off" id="input" type="text" pInputText placeholder="Address Line 1"
              formControlName="VendorAddress1" maxlength="51">
            <span class="validationtest" *ngIf="this.VendorDetailsForm.get('VendorAddress1').hasError('maxlength')">
              Please enter 50 alphabets only
            </span>
          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
            <label class="inputfields">Address Line 2</label>
            <input autocomplete="off" id="input" type="text" pInputText placeholder="Address Line 2"
              formControlName="VendorAddress2" maxlength="51">
            <span class="validationtest" *ngIf="this.VendorDetailsForm.get('VendorAddress2').hasError('maxlength')">
              Please enter 50 alphabets only
            </span>
          </div>

          <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
            <label class="inputfields">Mailbox</label>
            <input type="text" autocomplete="off" maxlength="11" pInputText placeholder="Mailbox"
              formControlName="mailbox">
            <span class="validationtest" *ngIf="this.VendorDetailsForm.get('mailbox').hasError('maxlength')">
              Please enter 10 numbers only
            </span>
            <span class="validationtest"
              *ngIf="this.VendorDetailsForm.get('mailbox').invalid && this.VendorDetailsForm.get('mailbox').errors.pattern">
              Please enter numbers only
            </span>
          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
            <label class="inputfields">Country<span class="mandatoryColor">*</span> </label>
            <p-dropdown [options]="countryOptions" placeholder="Select Country" optionLabel="label"
              formControlName="VendorCountry" [showClear]="true" (onChange)="stateDropdown($event.value.code)">
            </p-dropdown>
            <span class="validationtest"
              *ngIf="this.VendorDetailsForm.get('VendorCountry').touched && this.VendorDetailsForm.get('VendorCountry').invalid && this.VendorDetailsForm.get('VendorCountry').errors.required">
              Please select country
            </span>
          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
            <label class="inputfields">State</label>
            <p-dropdown [options]="stateoptions" placeholder="Select State" optionLabel="label"
              formControlName="VendorState" [showClear]="true"></p-dropdown>
          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
            <label class="inputfields">City</label>
            <input autocomplete="off" id="input" type="text" pInputText placeholder="City" formControlName="VendorVCity"
              maxlength="21">
            <span class="validationtest" *ngIf="this.VendorDetailsForm.get('VendorVCity').hasError('maxlength')">
              Please enter 20 alphabets only
            </span>
          </div>

          <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
            <label class="inputfields">Zip</label>
            <input autocomplete="off" id="input" pKeyFilter="num" pInputText placeholder="Zip"
              formControlName="VendorVZip" maxlength="11">
            <span class="validationtest" *ngIf="this.VendorDetailsForm.get('VendorVZip').hasError('maxlength')">
              Please enter 10 numbers only
            </span>
            <span class="validationtest"
            *ngIf="this.VendorDetailsForm.get('VendorVZip').invalid && this.VendorDetailsForm.get('VendorVZip').errors.pattern">
            Please enter numbers only
          </span>
          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
            <label class="inputfields">Phone</label>
            <div class="p-col-12 p-md-12 p-sm-12 ph-no">
              <div class="p-inputgroup" class="p-col-4 p-md-4 phone">
                <span class="p-inputgroup-addon">
                  <p-dropdown [options]="countrycodedropdown" class="phonecodestyle newcode"
                    (onChange)="countryids($event)" filterPlaceholder='Search Results' optionLabel="label"
                    formControlName="phoneDrp">
                    <ng-template let-items pTemplate="selectedItem">
                      <div class="flex-row">
                        <img class="lang-change-img" src={{items.flag}} alt="" />
                        <div class="lang-change-option-text selected-lang center">{{items.code}}
                        </div>
                      </div>
                    </ng-template>
                    <ng-template let-country pTemplate="item">
                      <div class="flex-row" id="dropdown1">
                        <img class="lang-change-img" src={{country.flag}} alt="" />
                        <div class="lang-change-option-text center">{{country.label}}
                          {{country.code}}</div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </span>
              </div>
              <div class="p-col-8 p-md-8 spinner">
                <span class="p-input-icon-right"><em *ngIf="phSpinnerHide"
                    class="pi pi-spin pi-spinner spinnerstyles"></em>
                  <em *ngIf="phCheckHide" class="pi pi-check-circle tickmarkstyle"></em>
                  <p-inputMask autocomplete="off" mask="(999) 999-9999" formControlName="Vendorphone"
                    placeholder="Phone Number" (onBlur)='checkPhone()'>
                  </p-inputMask>
                </span>
                <p class="validationtest">{{this.officenummsg}}</p>
              </div>
            </div>

          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
            <label class="inputfields">Fax</label>
            <input autocomplete="off" id="input" pKeyFilter="num" pInputText placeholder="Fax"
              formControlName="VendorFax" maxlength="16">
            <span class="validationtest" *ngIf="this.VendorDetailsForm.get('VendorFax').hasError('maxlength')">
              Please enter 15 numbers only
            </span>
            <span class="validationtest"
            *ngIf="this.VendorDetailsForm.get('VendorFax').invalid && this.VendorDetailsForm.get('VendorFax').errors.pattern">
            Please enter numbers only
          </span>
          </div>
        </div>
      </div>
      <h5 id="h3tag">Basic Information</h5>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
          <label class="inputfields">Industry</label>
          <p-dropdown [options]="industryOptions" placeholder="Industry" optionLabel="label"
            formControlName="VendorIndustry" [showClear]="true">
          </p-dropdown>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
          <label class="inputfields">Region</label>
          <p-dropdown [options]="regionOptions" placeholder="Select Region" optionLabel="label"
            formControlName='VendorRegion' [showClear]="true"></p-dropdown>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
          <label class="inputfields">No.Of Employees</label>
          <p-dropdown [options]="EmployeeData" optionValue="code" formControlName="VendorNoofEmployees"
            [showClear]="true" placeholder="Select No.Of Employees" optionLabel="label"></p-dropdown>
          <span class="validationtest"
            *ngIf="this.VendorDetailsForm.get('VendorNoofEmployees').touched && this.VendorDetailsForm.get('VendorNoofEmployees').invalid && this.VendorDetailsForm.get('VendorNoofEmployees').errors.required">
            Please enter no.of employees
          </span>
        </div>
        <div *ngIf="basedTaxId" class="p-col-12 p-md-12 p-sm-12 p-lg-3">
          <label class="inputfields">Tax Id</label>
          <input autocomplete="off" id="input" type="text" pInputText pKeyFilter="num" placeholder="Tax Id"
            maxlength="21" formControlName="VendorTaxid">
          <span class="validationtest"
            *ngIf="this.VendorDetailsForm.get('VendorTaxid').touched && this.VendorDetailsForm.get('VendorTaxid').invalid && this.VendorDetailsForm.get('VendorTaxid').errors.required">
            Please enter tax id
          </span>
          <span class="validationtest" *ngIf="this.VendorDetailsForm.get('VendorTaxid').hasError('maxlength')">
            Please enter 20 alphabets only
          </span>
          <span class="validationtest"
          *ngIf="this.VendorDetailsForm.get('VendorTaxid').invalid && this.VendorDetailsForm.get('VendorTaxid').errors.pattern">
          Please enter numbers only
        </span>
        </div>
        <div *ngIf="panNumber" class="p-col-12 p-md-12 p-sm-12 p-lg-3">
          <label class="inputfields">PAN Number</label>
          <input autocomplete="off" id="input" type="text" pInputText pKeyFilter="num" placeholder="PAN Number"
            maxlength="20" formControlName="VendorTaxid">
          <span class="validationtest"
            *ngIf="this.VendorDetailsForm.get('VendorTaxid').touched && this.VendorDetailsForm.get('VendorTaxid').invalid && this.VendorDetailsForm.get('VendorTaxid').errors.required">
            Please enter pan number
          </span>
        </div>

        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
          <label class="inputfields">Stock Symbol </label>
          <input autocomplete="off" id="input" type="text" pInputText placeholder="Stock Symbol" maxlength="11"
            formControlName="VendorStockSymbol">
          <span class="validationtest" *ngIf="this.VendorDetailsForm.get('VendorStockSymbol').hasError('maxlength')">
            Please enter 10 alphabets only
          </span>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
          <label class="inputfields">Revenue</label>
          <input autocomplete="off" id="input" pKeyFilter="num" pInputText placeholder="Revenue" maxlength="10"
            formControlName="VendorRevenue">
          <span class="validationtest"
            *ngIf="this.VendorDetailsForm.get('VendorRevenue').touched && this.VendorDetailsForm.get('VendorRevenue').invalid && this.VendorDetailsForm.get('VendorRevenue').errors.required">
            Please enter revenue
          </span>
          <span class="validationtest"
            *ngIf="this.VendorDetailsForm.get('VendorRevenue').invalid && this.VendorDetailsForm.get('VendorRevenue').errors.pattern">
            Please enter numbers only
          </span>

          <span class="validationtest" *ngIf="this.VendorDetailsForm.get('VendorRevenue').hasError('maxlength')">
            Please enter 9 alphabets only
          </span>
        </div>

        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
          <label class="inputfields"> Dun & BradStreet No. </label>
          <input autocomplete="off" id="input" type="text" pInputText placeholder="Dun & BradStreet No." maxlength="13"
            formControlName="VendorDunBradStreetNo">
          <span class="validationtest"
            *ngIf="this.VendorDetailsForm.get('VendorDunBradStreetNo').hasError('maxlength')">
            Please enter 12 alphabets only
          </span>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
          <label class="inputfields">Business Type</label>
          <p-dropdown [options]="businessType" placeholder="Select Business Type" optionLabel="label"
            formControlName="VendorBusinessType" [showClear]="true"></p-dropdown>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 invalidMandatory">
          <label>Employee approval required?</label>&nbsp;
          <p-radioButton name="Emp" value="Y" label="Yes" formControlName="Emp" inputId="Yes" [disabled]="true">
          </p-radioButton>
          <p-radioButton class="radiostyle" name="Emp" value="N" label="No" formControlName="Emp" inputId="No" [disabled]="true">
          </p-radioButton>
        </div>

        <div class="p-col-12 p-md-12 p-sm-12">
          <label class="inputfields">Notes</label>
          <textarea autocomplete="off" [rows]="8" [cols]="30" maxlength="201" placeholder="Notes " pInputTextarea
            formControlName="VendorNotes"></textarea>
          <span class="validationtest" *ngIf="this.VendorDetailsForm.get('VendorNotes').hasError('maxlength')">
            Please enter 200 alphabets only
          </span>
        </div>
      </div>
      <div class="btnsCSS1">
        <button pButton type="button" icon='pi pi-trash' class="addhBtn" label="Remove"
          *ngIf="removebtn" [disabled]='removebtnShow' (click)="deleteVendor()"></button>
        <button pButton type="button" icon='pi pi-save' class="addhBtn" label="Update"
        (click)="updateVendorDetails()"></button>
      </div><br><br>
    </form>
  </p-panel>
</p-card>
<p-dialog class="dialogBox" header="Remove Account" [(visible)]="displayActive" [style]="{width: '25%'}"
  [draggable]="false" [modal]="true" [resizable]="false">
  <span>
    <p style="margin-left: 1px;font-family: sans-serif;">Do you want to remove this account?<br> <span  style="
      color: #2bb9d5;position: relative;font-family: sans-serif;">{{actName}}</span></p>
  </span><br>
  <div class="btnsCSS1">
    <button pButton type="button" icon='pi pi-times' label="Cancel" class="addhBtn" (click)="cancel()"></button>
    <button pButton type="button" icon='pi pi-send' label="Confirm" class="addhBtn" (click)="saveUserDetails()" [disabled]="confirmbtn"></button>
  </div>
</p-dialog>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center laoderheight">
        <div class="laodersize">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
            class="loaderimg-height">
        </div>
      </div>
    </div>
  </div>
</ng-template>