import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { isEmpty } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-vendor-contact',
  templateUrl: './vendor-contact.component.html',
  styleUrls: ['./vendor-contact.component.scss'],
  providers: [DatePipe, MessageService],
})
export class VendorContactComponent implements OnInit {
  // tslint:disable-next-line:no-inferrable-types
  href = ' ';
  accTID: any;
  items: { label: string; icon: string; command: () => void; }[];
  first: any;
  pageCount: any;
  empflag: any;
  usid: any;
  contaccid: any;
  contflag: any;
  roleid: number;
  loginData: any;
  Accid: any;
  accId: any;
  actID: any;
  salesAccId: any;
  vendoraccname: any;
  contactnamedsb: boolean;
  accname: any;
  id: any;
  custName: boolean;
  from: string;
  usrroleid: any;
  accountactivityId: string;
  // tslint:disable-next-line: max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private router: Router, private messageService: MessageService, private route: ActivatedRoute, private breadcrumbService: BreadcrumbService) {
    this.accTID = JSON.parse(sessionStorage.getItem('acct_rowid'));
    this.salesAccId = JSON.parse(sessionStorage.getItem('salesAccId'));
    this.usid = JSON.parse(apisService.decodeData()).userId;
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.empflag = routeParams.params.empflag;
    this.actID = routeParams.params.actID;
    // this.accountactivityId = this.route.snapshot.paramMap.get('id');
    this.from = this.route.snapshot.paramMap.get('From');
    if (this.from === 'vendor') {
      this.vendoraccname = this.route.snapshot.paramMap.get('queryParams');
    }
    // vendorEdit
    else if (this.from === 'vendorEdit') {
      this.vendoraccname = this.route.snapshot.paramMap.get('queryParams');
    }
    else {
      this.vendoraccname = routeParams.params.vendoraccname;
    }

    this.accname = routeParams.params.accname;
    this.contflag = routeParams.params.contflag;
    this.contaccid = routeParams.params.contaccid;
    this.accountactivityId = this.route.snapshot.paramMap.get('id');
    this.loginData = JSON.parse(this.apisService.decodeData());
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
      this.roleid = this.loginData.roles[0].id;
    } else {
      this.roleid = this.usrroleid;
    }
    this.rowid = sessionStorage.getItem('acct_rowid');
    if (this.roleid === 14) {
      this.custName = true;
    } else {
      this.custName = false;
    }
    if (this.roleid === 14) {
      this.breadcrumbService.setItems([
        { label: 'Vendors', routerLink: '/vendor' },
        { label: 'Contacts', routerLink: '/vendor-contact/:id', },
      ]);
    }
    else {
      this.breadcrumbService.setItems([
        { label: 'Accounts', routerLink: '/accounts' },
        { label: 'Account Details', routerLink: ['/admin-menu/account-details', this.rowid] },
        { label: 'Contacts', routerLink: '#' },
      ]);
    }

  }
  ContactsForm: FormGroup;
  grid: any;
  statuTable = {};
  status: any[];
  accountid: any;
  editContactForm: FormGroup;
  userID: string;
  saveReg: any;
  displayActive = false;
  loginActiveData: string;
  email: any;
  registerdStatus = false;
  orgIdVal: any;
  iconToggle = [];
  loader: boolean;
  rowData: any;
  disable: boolean;
  totalrecords: any;
  actId: any;
  rolesData: any = [];
  isUploading = false;
  showUploadView = false;
  rows: any = 10;
  page: any = 1;
  // It is used for displaying table header data
  cols = [
    { field: 'name', header: 'Name', width: '8%' },
    { field: 'email', header: 'Email', width: '7%' },
    { field: 'roleList', header: 'Role ', width: '7%' },
    { field: 'officePhone', header: 'Phone', width: '5%' },
    { field: 'status', header: 'Status', width: '4%' },
    { field: '', header: 'Action', width: '4%' },
  ];
  email1: any;
  rowID: any;
  rolesname: any;
  rowid: string;
  rolename: any;
  role: any = [];
  table = true;
  records: boolean;
  accountId = this.route.snapshot.params.id;
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchData();
    }
  }
  // Drag Over Listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drag Leave Listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drop Listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.isUploading = true;
      this.onUpload(files);
    }
  }
  ngOnInit() {

    this.href = JSON.stringify(this.router.url);
    if (this.empflag === 'acctemp' || this.contflag === 'conflag' || this.empflag !== 'acctemp') {
      this.contactnamedsb = false;
    } else if (this.accname === 'accname') {
      this.contactnamedsb = false;
    } else {
      this.contactnamedsb = true;
    }
    const userdetails = JSON.parse(this.apisService.decodeData());
    this.accId = userdetails.accId;
    const rolevalue = sessionStorage.getItem('Act_type');
    const getroles = rolevalue === 'B' ? 'V' : rolevalue;
    this.apisService.getApi(environment.roleDD + getroles).subscribe((res: any) => {
      this.role = res.data;
    });
    this.ContactsForm = this.fb.group({
      FirstName: [''],
      LastName: [''],
      Emailid: [''],
      Phone: [''],
      Status: [''],
    });
    this.editContactForm = this.fb.group({
      NameControl: [''],
      addressControl: [''],
    });
    if (
      JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 2 ||
      JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 8
    ) {
      this.accountid = JSON.parse(sessionStorage.getItem('loginData')).orgId;
    } else {
      this.accountid = this.accountId;
    }
    this.getGirdData();
    this.statusDD();
  }
  // It is Used to upload files
  onUpload(files) {
    this.isUploading = true;
    const formData: FormData = new FormData();
    formData.append('file', files[0]);
    this.apisService.postApi(environment.fileuploadContacts, formData).subscribe((res: any) => {
      if (res.success === true) {
        this.messageService.add({
          key: 'br',
          severity: '',
          life: 3000,
          detail: 'Inserted Successfully.',
        });
        this.isUploading = false;
        this.showUploadView = false;
      } else {
        setTimeout(() => {
          this.isUploading = false;
        }, 2000);
        this.messageService.add({
          key: 'br',
          severity: '',
          life: 3000,
          detail: 'Something went wrong, please try again.',
        });
      }
    });
  }
  // It is used to copy an email from grid data to clipboard
  copyInputMessage(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
  // It is used to get griddata
  getGirdData() {
    this.orgIdVal = sessionStorage.getItem('orgId');
    this.loader = true;
    this.actId = JSON.parse(this.apisService.decodeData());
    this.rowid = sessionStorage.getItem('acct_rowid');
    const pageNo = this.page;
    const pageCount = this.rows;
    const orgId = pageNo + '/' + pageCount;
    const id = this.roleid === 1 ? this.rowid : this.actId.accId;
    const gridIDS = this.roleid === 14 ? this.salesAccId : id;
    this.apisService.getApi(environment.getTabledata + orgId + '/' + gridIDS).subscribe((res: any) => {
      this.grid = res.data;
      this.loader = false;
      this.totalrecords = res.count;
      if (this.totalrecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  getrole(data) {
    for (let i = 0; this.role.length < 0; i++) {
      for (let j = 0; data.length < 0; j++) {
        if (this.role[i] === data[j]) {
        }
      }
    }
  }
  // It is used to get status dropdown values
  statusDD() {
    this.apisService.getApi(environment.actstatus).subscribe((res: any) => {
      this.status = res.data;
      this.status.forEach((element) => {
        this.statuTable[element.code] = element.label;
      });
    });
  }
  // It is used  for search funtionality
  searchData() {
    const firstName = this.ContactsForm.controls.FirstName.value === null ? '' : this.ContactsForm.controls.FirstName.value;
    const lastName = this.ContactsForm.controls.LastName.value === null ? '' : this.ContactsForm.controls.LastName.value;
    const phone = this.ContactsForm.controls.Phone.value === null ? '' : this.ContactsForm.controls.Phone.value;
    const email = this.ContactsForm.controls.Emailid.value === null ? '' : this.ContactsForm.controls.Emailid.value;
    // tslint:disable-next-line:max-line-length
    const status = this.ContactsForm.controls.Status.value === null || this.ContactsForm.controls.Status.value === '' || this.ContactsForm.controls.Status.value === undefined ? '' : this.ContactsForm.controls.Status.value.code;
    this.loader = true;
    this.actId = JSON.parse(this.apisService.decodeData());
    this.rowid = sessionStorage.getItem('acct_rowid');
    const pageNo = this.page;
    const pageCount = this.rows;
    const orgId = pageNo + '/' + pageCount;
    const id = this.roleid === 1 ? this.rowid : this.actId.accId;
    const gridIDS = this.roleid === 14 ? this.salesAccId : id;
    const data = +gridIDS + '?fName=' + firstName + '&lName=' + lastName + '&email=' + email + '&phone=' + phone + '&status=' + status;
    this.apisService.getApi(environment.getTabledata + orgId + '/' + data).subscribe((res) => {
      this.grid = res.data;
      this.totalrecords = res.count;
      if (this.totalrecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.loader = false;
    });
  }
  // It isused to navigate to add contact screen to add a contact
  AddData() {
    if (this.roleid === 14) {
      this.router.navigate(['/add-contact'], {
        queryParams: {
          vendoraccname: this.vendoraccname,
          getaccdata: this.accountactivityId,
          flag: 'sales'
        }
      });
    }
    else {
      this.router.navigate(['/add-contact'], {
        queryParams: {
          getaccdata: this.accountactivityId,
        }
      });
    }
  }
  // It is used to edit the particular contact based oncontact id
  edit(data) {
    if (this.roleid === 14) {
      this.router.navigate(['/edit-contact', data.id], {
        queryParams: {
          vendoraccname: this.vendoraccname,
          empflag: 'acctemp',
          contflag: 'conflag',
          coneditid: data.id,
          flag: 'sales',
          gedata: this.accountactivityId
        }
      });
    }
    else {
      this.router.navigate(['/edit-contact', data.id], {
        queryParams: {
          vendoraccname: this.vendoraccname,
          empflag: 'acctemp',
          contflag: 'conflag',
          coneditid: data.id
        }
      });
    }
  }
  // It isused to show sweet alert if contact is already activated or not
  basedOnLoginStatus(data) {
    this.rowID = data.id;
    const data1 = data.status;
    this.rowData = data;
    this.displayActive = true;
    if (data.status === 'A') {
      this.rowData = data;
      this.displayActive = false;
      this.loginActiveData =
        'User contact is already activated please check email-';
      this.registerdStatus = false;
      this.email1 = data.email;
      Swal.fire({
        title: 'Success!',
        text: this.loginActiveData + this.email1,
        icon: 'success',
        confirmButtonColor: '#0D416B',
        iconColor: '#1dbb99',
        confirmButtonText: 'Ok',
      });
    }
    if (data.status === 'I') {
      this.loginActiveData = 'User contact is inactive';
      this.registerdStatus = false;
      this.email1 = data.email;
    } else if (data.status === 'R') {
      this.loginActiveData = 'Do you want to send login credentials to email?';
      this.registerdStatus = true;
      this.email1 = data.email;
    }
  }
  // It is used to navigate to email
  goToLink(url: string) {
    const addressLink = url.includes('http') ? url : `http://${url}`;
    window.open(addressLink, '_blank');
  }
  // It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.getGirdData();
  }
  // It is used to add a contact to a particular account id
  saveUserContacts() {
    this.userID = sessionStorage.getItem('userId');
    const contactId = this.rowID;
    this.disable = true;
    this.apisService.getApi(environment.loginSend + contactId).subscribe(
      (res) => {
        this.saveReg = res;
        this.disable = false;
        if (this.saveReg.hasOwnProperty('success') === true) {
          this.displayActive = false;
          this.getGirdData();
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.saveReg.message });
        } else if (this.saveReg.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.saveReg.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.saveReg.error });
        }
      },
      (err) => {
        this.disable = false;
      }
    );
  }
  // It is used for close the Contact Login Credentials dialog box
  cancel() {
    this.displayActive = false;
  }
  // It is used for the reset the form values
  resetData() {
    this.getGirdData();
    this.ContactsForm.reset();
  }
  // It is used to show the role in grid data by filtering
  roletable(data) {
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.role.length; i++) {
      // tslint:disable-next-line: prefer-for-of
      // tslint:disable-next-line:prefer-for-of
      if (data === null || data === undefined || data === '' || data === isEmpty()) { } else {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < data.length; j++) {
          if (this.role[i].name === data[j].name) {
            selectedPrePrimarySkills3.push(this.role[i].name);
          } else {
          }
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  emailToast() {
    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
  }
  // It is used to show action pop up
  toggle(event, data, rowData) {
    sessionStorage.setItem('editconId', data.id);

    this.rowData = rowData;
    this.items = [
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => { this.edit(data); },
      },
      { label: ' Activate', icon: 'pi pi-check-circle', command: () => { this.basedOnLoginStatus(data); } },
    ];
  }

}
