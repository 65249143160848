<p-card>
    <p-panel [toggleable]="false">
        <p-header> Tech Reviews</p-header>
        <form [formGroup]="RatingForm">
            <div class="p-grid p-fluid labelpos">
                <div class="p-col-12 p-md-3 p-sm-12 p-lg-8">
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-2">
                            <h6>Analytical</h6>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-4">
                            <p-progressBar [value]="this.analytical" [showValue]="false"> </p-progressBar>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-5">
                            <span *ngIf="percentage1">{{this.analytical}}%</span> <span *ngIf="iconn1"
                                class="pi pi-check-circle check-color"></span>
                        </div>
                    </div>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-2">
                            <h6>Architecture</h6>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-4">
                            <p-progressBar [value]=" this.architecture" [showValue]="false"> </p-progressBar>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-5">
                            <span *ngIf="percentage">{{this.architecture}}%</span>
                            <span *ngIf="iconn" class="pi pi-check-circle check-color"></span>
                        </div>
                    </div>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-2">
                            <h6>Business</h6>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-4">
                            <p-progressBar [value]=" this.business" [showValue]="false"> </p-progressBar>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-5">
                            <span *ngIf="percentage2">{{this.business}}%</span> <span *ngIf="iconn2"
                                class="pi pi-check-circle check-color"></span>
                        </div>
                    </div>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-2">
                            <h6>Coding</h6>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-4">
                            <p-progressBar [value]=" this.coding" [showValue]="false"> </p-progressBar>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-5">
                            <span *ngIf="percentage3">{{this.coding}}%</span> <span *ngIf="iconn3"
                                class="pi pi-check-circle check-color"></span>
                        </div>
                    </div>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-2">
                            <h6>Communication</h6>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-4">
                            <p-progressBar [value]=" this.communication" [showValue]="false"> </p-progressBar>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-5">
                            <span *ngIf="percentage4">{{this.communication}}%</span> <span *ngIf="iconn4"
                                class="pi pi-check-circle check-color"></span>
                        </div>
                    </div>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-2">
                            <h6>Documentation</h6>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-4">
                            <p-progressBar [value]=" this.documentation" [showValue]="false"> </p-progressBar>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-5">
                            <span *ngIf="percentage5">{{this.documentation}}%</span> <span *ngIf="iconn5"
                                class="pi pi-check-circle check-color"></span>
                        </div>
                    </div>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-2">
                            <h6>Ownership</h6>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-4">
                            <p-progressBar [value]=" this.ownership" [showValue]="false"> </p-progressBar>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-5">
                            <span *ngIf="percentage6">{{this.ownership}}%</span> <span *ngIf="iconn6"
                                class="pi pi-check-circle check-color"></span>
                        </div>
                    </div>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-2">
                            <h6>Position</h6>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-4">
                            <p-progressBar [value]=" this.position" [showValue]="false"> </p-progressBar>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-5">
                            <span *ngIf="percentage7">{{this.position}}%</span> <span *ngIf="iconn7"
                                class="pi pi-check-circle check-color"></span>
                        </div>
                    </div>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-2">
                            <h6>Team Management</h6>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-4">
                            <p-progressBar [value]=" this.teammanagement" [showValue]="false"> </p-progressBar>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-5">
                            <span *ngIf="percentage8">{{this.teammanagement}}%</span> <span *ngIf="iconn8"
                                class="pi pi-check-circle check-color"></span>
                        </div>
                    </div>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-2">
                            <h6>Testing</h6>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-4">
                            <p-progressBar [value]=" this.testing" [showValue]="false"> </p-progressBar>
                        </div>
                        <div class="p-col-12 p-md-3 p-sm-12 p-lg-5">
                            <span *ngIf="percentage9">{{this.testing}}%</span> <span *ngIf="iconn9"
                                class="pi pi-check-circle check-color"></span>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-3 p-sm-12 p-lg-3">
                    <br><br>
                    <div class="labelpos">
                        <p-card>
                            <br>
                            <div>
                                <div class="p-grid p-fluid">
                                    <div class="p-col-12 p-md-3 p-sm-12 p-lg-12">
                                        <h5 class="overall">Over All Ratings</h5>
                                    </div>
                                    <div class="p-col-12 p-md-3 p-sm-12 p-lg-12">
                                        <span> <strong class="overall">
                                                <h5>{{this.overallRating}}</h5>
                                            </strong></span>
                                    </div>
                                </div>
                                <br>
                                <div class="p-grid p-fluid">
                                    <div class="p-col-12 p-md-3 p-sm-12 p-lg-12 aligncenter">
                                            <div class="Stars" style="--rating: {{overallRating}} ;">
                                            </div>
                                    </div>
                                </div>
                                <br>
                            </div>
                        </p-card>
                    </div>
                </div>
            </div>
            <br>
        </form>
    </p-panel>
</p-card>
<br>
<p-card class="pgpos alig"> 
            <div *ngIf="table">
                <!--It is used to displaying review table data-->
                <p-table  [columns]="cols" [value]="reviewGrid" selectionMode="single" dataKey="edit" [rows]="10"
                    [responsive]="true" [rowsPerPageOptions]="[10,20,30]" sortMode="multiple">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th id="gridHeader" *ngFor="let col of columns; let i=index"
                                (click)="iconToggle[i] = !iconToggle[i]" [ngStyle]="{'width': col.width }"
                                [pSortableColumn]="col.field">
                                {{col.header}}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                        <tr>
                            <td class="textCenter"><span class="p-column-title"> Job Id</span>{{rowData.jobId}}</td>
                            <td><a class="pointer" (click)="goToLink(rowData.website)">{{rowData.accountName}}</a></td>
                            <td class="textCenter"><span class="p-column-title"> Reviewer
                                    Name</span>{{rowData.reviewerName}} </td>
                            <td class="textCenter"><span class="p-column-title"> Review
                                    Date</span>{{rowData.reviewDate}}</td>
                            <td class="textCenter"><span class="p-column-title"> Action</span><a>
                                    <em (click)="actionpopup.toggle($event); toggle($event,data, rowData)"
                                        class="pi pi-ellipsis-v pointer"></em></a></td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr *ngIf="reviewGrid?.length==0" class="aligncenter">
                            <td colspan="5" class="mandatoryColor">
                                No Records
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div class="p-grid p-fluid ppos">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                        <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                        <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                            (onPageChange)="paginate($event)"></p-paginator>
                    </div>
                </div>
            </div>
            <p-card *ngIf="records">
                <div class="norecordImgsize">
                    <div *ngIf="records" class="aligncenter">
                        <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                        <br><em>Sorry, we are not able to find any review details</em><br>
                    </div>
                </div>
            </p-card>
        </p-card>
                <!--It is used to displaying reviews pop up-->
                <p-dialog class="dialogBox" [(visible)]="showDialog" header="Ratings" [modal]="true"
                    [style]="{width: '60%','border-radius': '75px;'}" [baseZIndex]="1" [draggable]="false"
                    [resizable]="false">
                    <form [formGroup]="addtechreviewForm">
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <br>
                                <label class="inputfields"><strong>Reviewer Name : </strong> </label>
                                <strong><span class="panelLabel"> {{reviewNames}}</span></strong>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 pos">
                                <br>
                                       <label class="inputfields"><strong>Review Date  : </strong> </label>
                                        <strong><span class="panelLabel"> {{reviewDates}}</span></strong>
                            </div>
                        </div>
                        <br><br>
                        <div class="p-grid p-fluid p-justify-center">
                            <div class="p-col-12 p-md-2 p-sm-10 p-lg-2 analysis">
                                <label class="inputfields">Architecture </label>
                                <p-rating [cancel]="false" readonly="true" formControlName="architecture"></p-rating>
                            </div>
                            <div class="p-col-12 p-md-2 p-sm-10 p-lg-2 analysis">
                                <label class="inputfields">Coding </label>
                                <p-rating [cancel]="false" readonly="true" formControlName="coding"></p-rating>
                            </div>
                            <div class="p-col-12 p-md-2 p-sm-10 p-lg-2 analysis">
                                <label class="inputfields">Testing </label>
                                <p-rating [cancel]="false" readonly="true" formControlName="testing"></p-rating>
                            </div>
                            <div class="p-col-12 p-md-2 p-sm-10 p-lg-2 analysis">
                                <label class="inputfields">Business </label>
                                <p-rating [cancel]="false" readonly="true" formControlName="business"></p-rating>
                            </div>
                            <div class="p-col-12 p-md-2 p-sm-10 p-lg-2 analysis">
                                <label class="inputfields">Ownership </label>
                                <p-rating [cancel]="false" readonly="true" formControlName="ownershsip"></p-rating>
                            </div>
                        </div>
                        <br><br>
                        <div class="p-grid p-fluid p-justify-center">
                            <div class="p-col-12 p-md-2 p-sm-10 p-lg-2 analysis">
                                <label class="inputfields">Team Management </label>
                                <p-rating [cancel]="false" readonly="true" formControlName="management"></p-rating>
                            </div>
                            <div class="p-col-3 p-md-3 p-sm-12 p-lg-2 analysis">
                                <label class="inputfields">Analytical </label>
                                <p-rating [cancel]="false" readonly="true" formControlName="analytical"></p-rating>
                            </div>
                            <div class="p-col-3 p-md-3 p-sm-12 p-lg-2 analysis">
                                <label class="inputfields">Communication </label>
                                <p-rating [cancel]="false" readonly="true" formControlName="communication"></p-rating>
                            </div>
                            <div class="p-col-3 p-md-3 p-sm-12 p-lg-2 analysis">
                                <label class="inputfields">Documentation </label>
                                <p-rating [cancel]="false" readonly="true" formControlName="documentation"></p-rating>
                            </div>
                            <div class="p-col-3 p-md-3 p-sm-12 p-lg-2 analysis">
                                <label class="inputfields">Position </label>
                                <p-rating [cancel]="false" readonly="true" formControlName="position"></p-rating>
                            </div>
                        </div><br><br><br>
                        <div class="p-grid p-fluid">
                            <div class="p-col-3 p-md-3 p-sm-12 p-lg-4">
                            </div>
                            <div class="p-col-3 p-md-3 p-sm-12 p-lg-2 ">
                                <label class="inputfields headercolor">
                                    <h5><strong>Average Rating </strong></h5>
                                </label>
                            </div>
                            <div class="p-col-3 p-md-3 p-sm-12 p-lg-2 analysis avgrating">
                                <p-rating [cancel]="false" readonly="true" formControlName="average"></p-rating>
                            </div>
                        </div>
                        <div class="p-grid p-fluid">
                            <div class="p-col-3 p-md-3 p-sm-12 p-lg-12 analysis">
                                <label class="inputfields" id='csslabel'>Comments</label>
                                <textarea autocomplete="off" [rows]="2" [cols]="30" placeholder="Comments"
                                    readonly="true" pInputTextarea formControlName="requirementsComments"
                                    maxlength="300"></textarea>
                            </div>
                        </div>
                    </form>
                </p-dialog>
                <p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
