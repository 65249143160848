import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApisService } from './services/apis.service';
@Component({
  selector: 'app-menu',
  template: `
        <ul class="layout-menu menuhover">
            <li  app-menuitem *ngFor="let item of model; let i = index;"
                [item]="item" [index]="i" [visible]="true" [root]="true"></li>
        </ul> `
})
// .menuhover li:hover
export class AppMenuComponent implements OnInit {
  model: any[];
  roles: any;
  sidemenuid: any;
  roles1: any;
  accType: any;
  getaccid: any;
  constructor(private apiservices: ApisService, private router: Router) { }
  ngOnInit() {
    const acctdetails = JSON.parse(this.apiservices.decodeData());
    this.getaccid = acctdetails.accId;
    this.roles1 = acctdetails.roles[0].id;
    this.accType = acctdetails.accType;
    this.apiservices.rolebasedsidemenu.subscribe((res1: any) => {
      this.sidemenuid = res1.id;
      if (this.sidemenuid === undefined || this.sidemenuid === null) {
        this.roles = this.roles1;
      } else {
        this.roles = this.sidemenuid;
      }
      if (this.roles === 5) {
        this.router.navigate(['/project-requirements']);
      } else if (this.roles === 4) {
        // tslint:disable-next-line:no-unused-expression
        this.router.navigate(['/purchas-requirement']);
      }
      else if (this.roles === 3) {
        this.router.navigate(['/account-details']);
      }
      else if (this.roles === 9) {
        this.router.navigate(['/open-positions']);
      }
      else if (this.roles === 1) {
        this.router.navigate(['/accounts']);
      }
      else if (this.roles === 10) {
        this.router.navigate(['/tech-evaltion-tech-reviews']);
      }
      else if (this.roles === 11) {
        this.router.navigate(['/employee-requirements']);
      }
      else if (this.roles === 12) {
        this.router.navigate(['/tech-evalution']);
      }
      else if (this.roles === 6) {
        this.router.navigate(['/accountdetails']);
      }
      else if (this.roles === 8) {
        this.router.navigate(['/openpositions']);
      }
      else if (this.roles === 13) {
        this.router.navigate(['/salesOpenPositions']);
      }
      else if (this.roles === 14) {
        this.router.navigate(['/vendor']);
      }
      else if (this.roles === 15) {
        this.router.navigate(['/consultantopenposition']);
      }
      else {
        return true;
      }
      if (this.roles === 1) {
        const id = 1;
        this.model = [{
          items: [{
            label: 'Home', icon: '',
            items: [{ label: 'Preferences', icon: 'fa fa-cogs', routerLink: ['/my-preferences'] },
            // { label: 'Calendar', icon: 'fa fa-calendar-o', routerLink: ['/employer-calender'], },
            { label: 'Accounts', icon: 'fa fa-pencil-square-o', routerLink: ['/accounts'] },
            //   { label: 'Account Details', icon: 'fa fa-id-card-o', routerLink: ['/acc-adminmenu/acc-details', acctdetails.accId, {
            //     queryParams: 'AccAdmin'
            // }], }, command: () => { this.edit(data); }
            { label: 'Account Details', icon: 'fa fa-id-card-o', command: () => { this.routeto(); } },
            { label: 'Accounts Activity', icon: 'fa fa-history', routerLink: ['/accounts-activity'] },
            { label: 'Skills', icon: 'fa fa-certificate', routerLink: ['/skills'] },
            { label: 'Reset Password', icon: 'pi pi-key', routerLink: ['/reset-passwod'] },
            { label: 'Accounts Backup', icon: 'pi pi-cloud-upload', routerLink: ['/accountsBackup'] }
            ]
          },
            // { label: 'Reset Password', icon: 'fa fa-key', routerLink: ['/resetUserPassword'] }
          ],
        }];
      } else if (this.roles === 2) {
        this.model = [{
          items: [{
            label: 'Home', icon: '',
            // tslint:disable-next-line:max-line-length
            items: [{ label: 'Preferences', icon: 'fa fa-cogs', routerLink: ['/my-preferences'] },
            // { label: 'Calendar', icon: 'fa fa-calendar-o', routerLink: ['/employer-calender'], },
            { label: 'Team', icon: 'fa fa-users', routerLink: ['/team'] },
            { label: 'Tasks', icon: 'fa fa-tasks', routerLink: ['/task'] },
            { label: 'Groups', icon: 'fa fa-sitemap', routerLink: ['/groups'] },
            { label: 'Accounts', icon: 'fa fa-pencil-square-o', routerLink: ['/accounts'] }]
          },
            // { label: 'Reset Password', icon: 'fa fa-key', routerLink: ['/resetUserPassword'] }
          ],
        }];
      } else if (this.roles === 3) {// Customer Admin
        const id = 1;
        this.model = [{
          items: [{
            label: 'Home', icon: '',
            // tslint:disable-next-line: max-line-length
            items: [{ label: 'Preferences', icon: 'fa fa-cogs', routerLink: ['/my-preferences'] },
            // { label: 'Calendar', icon: 'fa fa-calendar-o', routerLink: ['/employer-calender'], },
            { label: 'Team', icon: 'fa fa-users', routerLink: ['/team'] },
            { label: 'Tasks', icon: 'fa fa-tasks', routerLink: ['/task'] },
            { label: 'Groups', icon: 'fa fa-sitemap', routerLink: ['/groups'] },
            { label: 'Account Details', icon: 'fa fa-id-card-o', routerLink: ['/account-details'] },
            ],
          },
            // { label: 'Reset Password', icon: 'fa fa-key', routerLink: ['/resetUserPassword'] }
          ],
        }];
      } else if (this.roles === 4) { // purchasing role
        this.model = [{
          items: [{
            label: 'Home', icon: '',
            items: [{ label: 'Preferences', icon: 'fa fa-cogs', routerLink: ['/my-preferences'] },
            // { label: 'Calendar', icon: 'fa fa-calendar-o', routerLink: ['/employer-calender'], },
            { label: 'Tasks', icon: 'fa fa-tasks', routerLink: ['/task'] },
            { label: 'Team', icon: 'fa fa-users', routerLink: ['/team'] },
            { label: 'Groups', icon: 'fa fa-sitemap', routerLink: ['/groups'] },
            { label: 'Departments', icon: 'fa fa-building-o', routerLink: ['/departments'] },
            { label: 'Job Categories', icon: 'fa fa-suitcase', routerLink: ['/jobcategories'] },
            { label: 'Job Titles', icon: 'fa fa-briefcase', routerLink: ['/jobtitles'] },
            { label: 'Requirement Preferences', icon: 'fa fa-cog', routerLink: ['/requirementpreference'] },
            { label: 'Locations', icon: 'fa fa-map-marker', routerLink: ['/location'] },
            { label: 'Tiers', icon: 'fa fa-align-justify', routerLink: ['/tiers'] },
            { label: 'Projects', icon: 'fa fa-product-hunt' },
            { label: 'Requirements', icon: 'fa fa-file-text', routerLink: ['/purchas-requirement'], },
            { label: 'Vendors', icon: 'fa fa-handshake-o', routerLink: ['/vendors'] }],
          },
            // {
            //   label: 'Dashboard', icon: '',
            //   items: []
            // },
          ]
        },
        ];
      } else if (this.roles === 5) {// project manger
        this.model = [{
          items: [{
            label: 'Home', icon: '',
            // tslint:disable-next-line:max-line-length
            items: [{ label: 'Preferences', icon: 'fa fa-cogs', routerLink: ['/my-preferences'] },
            // { label: 'Calendar', icon: 'fa fa-calendar-o', routerLink: ['/employer-calender'], },
            { label: 'Tasks', icon: 'fa fa-tasks', routerLink: ['/task'] },
            { label: 'Team', icon: 'fa fa-users', routerLink: ['/team'] },
            { label: ' Timesheets', icon: 'fa fa-clock-o', routerLink: ['/time-sheets'] },
            { label: 'Groups', icon: 'fa fa-sitemap', routerLink: ['/groups'] },
            { label: 'Vendors', icon: 'fa fa-handshake-o', routerLink: ['/projectvendor'] },
            { label: 'Requirements', icon: 'fa fa-file-text', routerLink: ['/project-requirements'] },
            // { label: 'Interviews', icon: 'fa fa-phone-square' },
            // {
            //   label: 'Contractors', icon: '', routerLink: ['/task'],
            //   items: [{ label: 'Received', icon: 'fa fa-check-square-o' },
            //   { label: 'Filtered', icon: 'fa fa-filter' }],
            // },
            { label: 'Selected Candidates', icon: 'fa fa-check-square', routerLink: '/selectedcandidates' },
            { label: 'Projects', icon: 'fa fa-product-hunt', routerLink: ['/projects'] },
            ],
          },
            // {
            //   label: 'Dashboard', icon: '', items: [
            //     // { label: 'Dashboard', icon: 'fa fa-tachometer', routerLink: ['/dashbord'], },
            //   ]
            // },
          ],
        },
        ];
      } else if (this.roles === 6) {// Vendor Admin
        const id = 1;
        this.model = [{
          items: [{
            label: 'Home', icon: '',
            items: [{ label: 'Preferences', icon: 'fa fa-cogs', routerLink: ['/my-preferences'] },
            // { label: 'Calendar', icon: 'fa fa-calendar-o', routerLink: ['/employer-calender'], },
            { label: 'Tasks', icon: 'fa fa-tasks', routerLink: ['/task'], },
            { label: 'Team', icon: 'fa fa-users', routerLink: ['/team'], },
            { label: 'Groups', icon: 'fa fa-sitemap', routerLink: ['/groups'] },
            { label: 'Bank Accounts', icon: 'fa fa-university', routerLink: ['/bank-accounts'] },
            { label: 'Account Details', icon: 'fa fa-id-card-o', routerLink: ['/accountdetails'], },
            ]
          },
            // { label: 'Reset Password', icon: 'fa fa-key', routerLink: ['/resetUserPassword'], }
          ],
        },
        ];
      } else if (this.roles === 7) {// vendor manager
        this.model = [{
          items: [{
            label: 'Home', icon: '',
            items: [{ label: 'Preferences', icon: 'fa fa-cogs', routerLink: ['/my-preferences'] },
            // { label: 'Calendar', icon: 'fa fa-calendar-o', routerLink: ['/employer-calender'], },
            { label: 'Tasks', icon: 'fa fa-tasks', routerLink: ['/task'], },
            { label: 'Team', icon: 'fa fa-users', routerLink: ['/team'] },
            { label: 'Groups', icon: 'fa fa-sitemap', routerLink: ['/groups'] },
            { label: 'Contacts', icon: 'fa fa-address-book', routerLink: [] },
            { label: 'Requirements', icon: 'fa fa-file-text', routerLink: ['/requirements'] },
            { label: 'Submitted Profiles', icon: 'fa fa-check-square-o', routerLink: ['/submitted-profile'] },
            { label: 'Employee', icon: 'fa fa-user-circle', routerLink: [''] },
            { label: 'W-2', icon: '' },
            { label: 'Subcontractors', icon: 'fa fa-handshake-o' },
            { label: 'Pool of Subcontractors', icon: 'fa fa-handshake-o', routerLink: [''] },
            { label: 'Customers', icon: 'fa fa-handshake-o', routerLink: ['/customers'] },
            { label: 'Customer Specific Rate Card', icon: 'fa fa-star-o', routerLink: [''] }],
          },
          {
            label: 'Dashboard', icon: '',
            items: [{ label: 'Dashboard', icon: 'fa fa-tachometer', routerLink: ['/vm-dashboard'] },
            ]
          }
          ],
        }];
      } else if (this.roles === 8 && this.accType === 'V') {// Recruiting Manager
        this.model = [{
          items: [{
            label: 'Home', icon: '',
            // tslint:disable-next-line:max-line-length
            items: [{ label: 'Preferences', icon: 'fa fa-cogs', routerLink: ['/my-preferences'] },
            // { label: 'Calendar', icon: 'fa fa-calendar-o', routerLink: ['/employer-calender'], },
            { label: 'Tasks', icon: 'fa fa-tasks', routerLink: ['/task'], },
            { label: 'Team', icon: 'fa fa-users', routerLink: ['/team'], },
            { label: 'Groups', icon: 'fa fa-sitemap', routerLink: ['/groups'] },
            // { label: 'Departments', icon: 'fa fa-building-o', routerLink: ['/departments'] },
            // { label: 'Job Categories', icon: 'fa fa-suitcase', routerLink: ['/jobcategories'] },
            // { label: 'Job Titles', icon: 'fa fa-briefcase', routerLink: ['/jobtitles'] },
            // { label: 'Requirement Preferences', icon: 'fa fa-cog', routerLink: ['/requirementpreference'] },
            // { label: 'Locations', icon: 'fa fa-map-marker', routerLink: ['/location'] },
            { label: 'Open Positions', icon: 'fa fa-sitemap', routerLink: ['/openpositions'], },
              // { label: 'Requirements', icon: 'fa fa-file-text', routerLink: ['/requirements'] },
              // {
              //   label: 'Vendors (Sub Contractors)', icon: '',
              //   items: [{ label: 'Sub Contractors', icon: 'fa fa-handshake-o', routerLink: ['/subcontactors'] },
              //   { label: 'Tiers', icon: 'fa fa-align-justify', routerLink: ['/tiers'], }],
              // }
            ],
          }],
        }];
      }
        else if (this.roles === 8 && this.accType === 'B') {// Recruiting Manager
          this.model = [{
            items: [{
              label: 'Home', icon: '',
              // tslint:disable-next-line:max-line-length
              items: [{ label: 'Preferences', icon: 'fa fa-cogs', routerLink: ['/my-preferences'] },
              // { label: 'Calendar', icon: 'fa fa-calendar-o', routerLink: ['/employer-calender'], },
              { label: 'Tasks', icon: 'fa fa-tasks', routerLink: ['/task'], },
              { label: 'Team', icon: 'fa fa-users', routerLink: ['/team'], },
              { label: 'Groups', icon: 'fa fa-sitemap', routerLink: ['/groups'] },
              { label: 'Departments', icon: 'fa fa-building-o', routerLink: ['/departments'] },
              { label: 'Job Categories', icon: 'fa fa-suitcase', routerLink: ['/jobcategories'] },
              { label: 'Job Titles', icon: 'fa fa-briefcase', routerLink: ['/jobtitles'] },
              { label: 'Requirement Preferences', icon: 'fa fa-cog', routerLink: ['/requirementpreference'] },
              { label: 'Locations', icon: 'fa fa-map-marker', routerLink: ['/location'] },
              { label: 'Open Positions', icon: 'fa fa-sitemap', routerLink: ['/openpositions'], },
              { label: 'Requirements', icon: 'fa fa-file-text', routerLink: ['/requirements'] },
              {
                label: 'Vendors (Subcontractors)', icon: '',
                items: [{ label: 'Subcontractors', icon: 'fa fa-handshake-o', routerLink: ['/subcontactors'] },
                { label: 'Tiers', icon: 'fa fa-align-justify', routerLink: ['/tiers'], }],
              },
              {
                label: 'Dashboard', icon: '',
                items: [{ label: 'Dashboard', icon: 'fa fa-tachometer', routerLink: ['/recuiter-dashboard'] },
                ]
              }
              ],
            }],
          }];
        }

        else if (this.roles === 9 && this.accType === 'B') { // Recruiter
          this.model = [{
            items: [{
              label: 'Home', icon: '',
              items: [{ label: 'Preferences', icon: 'fa fa-cogs', routerLink: ['/my-preferences'] },
              // { label: 'Calendar', icon: 'fa fa-calendar-o', routerLink: ['/employer-calender'], },
              { label: 'Tasks', icon: 'fa fa-tasks', routerLink: ['/task'] },
              { label: 'Team', icon: 'fa fa-users', routerLink: ['/team'] },
              { label: 'Groups', icon: 'fa fa-sitemap', routerLink: ['/groups'] },
              { label: 'Customers', icon: 'fa fa-handshake-o', routerLink: ['/customer'], },
              { label: 'Invoice', icon: 'fa fa-file-text-o', routerLink: ['#'] },
              { label: 'Candidates', icon: 'fa fa-user-plus', routerLink: ['/candidates'], },
              { label: 'Open Positions', icon: 'fa fa-sitemap', routerLink: ['/open-positions'], },
              { label: 'Requirements', icon: 'fa fa-file-text', routerLink: ['/rec-requirements'] },
              { label: 'Availability Pool', icon: 'fa fa-check-circle-o', routerLink: ['#'], },
              { label: 'Association Request', icon: 'fa fa-handshake-o', routerLink: '/associationrequest' },
              {
                label: 'Candidates', icon: '',
                items: [{ label: 'Submitted', icon: 'fa fa-check-square-o', routerLink: ['/rec-submitted'] },
                { label: 'Reviews', icon: 'fa fa-star-half-o', routerLink: ['/rec-reviews'] },
                { label: 'Interviews', icon: 'fa fa-phone-square', routerLink: ['/rec-interviews'] },
                { label: 'Selected', icon: 'fa fa-check-square', routerLink: ['/rec-selected'] },
                { label: 'OnProjects', icon: 'fa fa-product-hunt', routerLink: ['/rec-onProjects'] },
                { label: 'Timesheets', icon: 'fa fa-clock-o', routerLink: ['/time-sheets'] }],
              }],
            },
              // {
              //   label: 'Dashboard', icon: '',
              //   items: [{ label: 'Dashboard', icon: 'fa fa-tachometer', routerLink: ['/recuiter-dashboard'], }],
              // }
            ],
          }];
        }
        else if (this.roles === 9 && this.accType === 'V') { // Recruiter
          this.model = [{
            items: [{
              label: 'Home', icon: '',
              items: [{ label: 'Preferences', icon: 'fa fa-cogs', routerLink: ['/my-preferences'] },
              // { label: 'Calendar', icon: 'fa fa-calendar-o', routerLink: ['/employer-calender'], },
              { label: 'Tasks', icon: 'fa fa-tasks', routerLink: ['/task'] },
              { label: 'Team', icon: 'fa fa-users', routerLink: ['/team'] },
              { label: 'Groups', icon: 'fa fa-sitemap', routerLink: ['/groups'] },
              { label: 'Customers', icon: 'fa fa-handshake-o', routerLink: ['/customer'], },
              { label: 'Invoice', icon: 'fa fa-file-text-o', routerLink: ['#'] },
              { label: 'Candidates', icon: 'fa fa-user-plus', routerLink: ['/candidates'], },
              { label: 'Open Positions', icon: 'fa fa-sitemap', routerLink: ['/open-positions'], },
              // { label: 'Requirements', icon: 'fa fa-file-text', routerLink: ['/rec-requirements'] },
              { label: 'Availability Pool', icon: 'fa fa-check-circle-o', routerLink: ['#'], },
              { label: 'Association Request', icon: 'fa fa-handshake-o', routerLink: '/associationrequest' },
              {
                label: 'Candidates', icon: '',
                items: [{ label: 'Submitted', icon: 'fa fa-check-square-o', routerLink: ['/rec-submitted'] },
                { label: 'Reviews', icon: 'fa fa-star-half-o', routerLink: ['/rec-reviews'] },
                { label: 'Interviews', icon: 'fa fa-phone-square', routerLink: ['/rec-interviews'] },
                { label: 'Selected', icon: 'fa fa-check-square', routerLink: ['/rec-selected'] },
                { label: 'OnProjects', icon: 'fa fa-product-hunt', routerLink: ['/rec-onProjects'] },
                { label: 'Timesheets', icon: 'fa fa-clock-o', routerLink: ['/time-sheets'] }],
              }],
            },
              // {
              //   label: 'Dashboard', icon: '',
              //   items: [{ label: 'Dashboard', icon: 'fa fa-tachometer', routerLink: ['/recuiter-dashboard'], }],
              // }
            ],
          }];
        }
        else if (this.roles === 10) { // Tech Evaluation
          this.model = [{
            items: [{
              label: 'Home', icon: '',
              items: [{ label: 'Preferences', icon: 'fa fa-cogs', routerLink: ['/my-preferences'] },
              // { label: 'Calendar', icon: 'fa fa-calendar-o', routerLink: ['/employer-calender'], },
              { label: 'Tasks', icon: 'fa fa-tasks', routerLink: ['/task'], },
              { label: 'Team', icon: 'fa fa-users', routerLink: ['/team'], },
              { label: 'Groups', icon: 'fa fa-sitemap', routerLink: ['/groups'] },
              { label: 'Tech Reviews', icon: 'fa fa-star-half-o', routerLink: ['/tech-evaltion-tech-reviews'] }],
            }],
          }];
        } else if (this.roles === 11) { // Employee Role
          this.model = [{
            items: [{
              label: 'Home', icon: '',
              items: [{ label: 'Preferences', icon: 'fa fa-cogs', routerLink: ['/my-preferences'] },
              // { label: 'Calendar', icon: 'fa fa-calendar-o', routerLink: ['/employer-calender'], },
              { label: 'Profiles', icon: 'fa fa-user', routerLink: ['/myresume'] },
              { label: ' Timesheets', icon: 'fa fa-clock-o', routerLink: ['/time-sheets'] },
              { label: ' Leaves', icon: 'fa fa-umbrella' },
              { label: 'Tasks', icon: 'fa fa-tasks', routerLink: ['/task'] },
              { label: 'Tech Reviews', icon: 'fa fa-star-half-o', routerLink: ['/employee-reviews'], },
              { label: 'Skills', icon: 'fa fa-cubes', routerLink: ['/employeskills'] },
              { label: 'Academics', icon: 'fa fa-graduation-cap', routerLink: ['/employee-academic'] },
              { label: 'Certificates', icon: 'fa fa-certificate', routerLink: ['/emp-certificates'] },
              { label: 'Licences', icon: 'fa fa-id-card', routerLink: ['/emp-license'] },
              // { label: 'Projects', icon: 'fa fa-product-hunt', routerLink: ['/employee-projects'] },
              { label: 'Project History', icon: 'fa fa-product-hunt', routerLink: ['/project-history'] },
              { label: 'User History', icon: 'fa fa-clock-o', routerLink: ['/user-history'] },
              { label: 'Open Positions', icon: 'fa fa-sitemap', routerLink: ['/consultantopenposition'] },
              ],
            },
            {
              label: 'Network', icon: '',
              items: [{ label: 'Network', icon: 'fa fa-globe', routerLink: ['/network'], },
              { label: 'Recommendations', icon: 'fa fa-thumbs-up', routerLink: ['/employee-recommendations'] }]
            },
            {
              label: 'Recruitment', icon: '',
              items: [{ label: 'Recruiters', icon: 'fa fa-users', routerLink: ['/employee-recruiter'], },
              { label: 'Employers', icon: 'fa fa-user-circle', routerLink: ['/employer'] },
              { label: 'Interviews', icon: 'fa fa-phone-square', routerLink: ['/emp-interview'] },
              { label: 'Submission Requests', icon: 'fa fa-paper-plane', routerLink: ['/employee-requirements'] }],
            }],
          }];
        } else if (this.roles === 12) { // Project Tead Role
          this.model = [{
            items: [{
              label: 'Home', icon: '',
              // tslint:disable-next-line:max-line-length
              items: [{ label: 'Preferences', icon: 'fa fa-cogs', routerLink: ['/my-preferences'] },
              { label: 'Team', icon: 'fa fa-users', routerLink: ['/team'] },
              { label: 'Tasks', icon: 'fa fa-tasks', routerLink: ['/task'] },
              { label: 'Groups', icon: 'fa fa-sitemap', routerLink: ['/groups'] },
              { label: 'Tech Reviews', icon: 'fa fa-star-half-o', routerLink: ['/tech-evalution'] }],
            }],
          }];
        } else if (this.roles === 13) {// Sales
          this.model = [{
            items: [{
              label: 'Home', icon: '',
              // tslint:disable-next-line:max-line-length
              items: [{ label: 'Preferences', icon: 'fa fa-cogs', routerLink: ['/my-preferences'] },
              // { label: 'Calendar', icon: 'fa fa-calendar-o', routerLink: ['/employer-calender'], },
              { label: 'Open Positions', icon: 'fa fa-sitemap', routerLink: ['/salesOpenPositions'] },
              ]
            },
            ]
          },
          ];
        }
        else if (this.roles === 14) {// Sales
          this.model = [{
            items: [{
              label: 'Home', icon: '',
              items: [{ label: 'Preferences', icon: 'fa fa-cogs', routerLink: ['/my-preferences'] },
              { label: 'Team', icon: 'fa fa-users', routerLink: ['/team'] },
              { label: 'Tasks', icon: 'fa fa-tasks', routerLink: ['/task'] },
              { label: 'Groups', icon: 'fa fa-sitemap', routerLink: ['/groups'] },
              { label: 'Vendors', icon: 'fa fa-users', routerLink: ['/vendor'] },
              { label: 'Customers', icon: 'fa fa-handshake-o', routerLink: ['/customers'] }],
            }],
          }];
        }
        else if (this.roles === 15) {
          // consultant role
          this.model = [{
            items: [{
              label: 'Home', icon: '',
              items: [{ label: 'Preferences', icon: 'fa fa-cogs', routerLink: ['/my-preferences'] },
              // { label: 'Calendar', icon: 'fa fa-calendar-o', routerLink: ['/employer-calender'], },
              { label: 'Profiles', icon: 'fa fa-user', routerLink: ['/myresume'] },
              { label: 'Tech Reviews', icon: 'fa fa-star-half-o', routerLink: ['/employee-reviews'], },
              { label: 'Skills', icon: 'fa fa-cubes', routerLink: ['/employeskills'] },
              { label: 'Academics', icon: 'fa fa-graduation-cap', routerLink: ['/employee-academic'] },
              { label: 'Certificates', icon: 'fa fa-certificate', routerLink: ['/emp-certificates'] },
              { label: 'Licences', icon: 'fa fa-id-card', routerLink: ['/emp-license'] },
              { label: 'Open Positions', icon: 'fa fa-sitemap', routerLink: ['/consultantopenposition'] },
              { label: 'Applied Positions', icon: 'fa fa-check-circle', routerLink: '/appliedpositions' }
              ],
            },
            {
              label: 'Network', icon: '',
              items: [{ label: 'Network', icon: 'fa fa-globe', routerLink: ['/network'], },
              { label: 'Recommendations', icon: 'fa fa-thumbs-up', routerLink: ['/employee-recommendations'] }]
            },
            {
              label: 'Recruitment', icon: '',
              items: [{ label: 'Recruiters', icon: 'fa fa-users', routerLink: ['/employee-recruiter'], },
              { label: 'Employers', icon: 'fa fa-user-circle', routerLink: ['/employer'] },
              { label: 'Interviews', icon: 'fa fa-phone-square', routerLink: ['/emp-interview'] },
              { label: 'Submission Requests', icon: 'fa fa-paper-plane', routerLink: ['/employee-requirements'] }],
            }
            ],
          }
          ];
        }

      });

  }
  routeto() {
    this.router.navigate(['/acc-adminmenu/acctdetails', this.getaccid], {
      queryParams: {
        getaaccdataofacc: 'accountdata'
      }
    });
  }
}
