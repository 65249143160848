import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import { isEmpty } from 'rxjs/operators';
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import autoTable from 'jspdf-autotable';
@Component({
  selector: 'app-recruiting-manager-openposition-edit',
  templateUrl: './recruiting-manager-openposition-edit.component.html',
  styleUrls: ['./recruiting-manager-openposition-edit.component.scss'],
  providers: [MessageService, DatePipe],
})
export class RecruitingManagerOpenpositionEditComponent implements OnInit {
  editRequirementsForm: FormGroup;
  UserId: string;
  name1: any;
  reqId: any;
  titel: any;
  formRoute: any;
  gridStatus: any;
  reqData: any;
  reqPosi: any;
  reqName: any;
  jobCat: any;
  maxrate: any;
  headerName: string;
  rows: any = 10;
  page: any = 1;
  pageCount: any;
  submittedProfilesGridData: any;
  subProRecords: any;
  table: boolean;
  records: boolean;
  // tslint:disable-next-line:no-inferrable-types
  loader: boolean = false;
  array: any;
  specializationSkillsData: any = [];
  experienceData: any = [];
  expLabel: any = [];
  statusList: any = [];
  consStatusData: any;
  first: any;
  positionData: any;
  requiremntId: any;
  minMaxRate: any;
  departmentList: any;
  locationList1: any = [];
  locationList: any;
  hireTypeData: any;
  rowData: any;
  idToDelete: any;
  conId: any;
  consultantName: any;
  items: any = [];
  downloadOptions: any = [];
  jobTitleData: any;
  userDetails: any;
  userAccId: any;
  exprienceList: any;
  billingList: any;
  hireTypeList: any;
  display: boolean;
  reqSkillsValue: any;
  filedata: any;
  resumeRes: any;
  updateaction: boolean;
  updateactionBtn: boolean;
  userid: any;
  resumID: any;
  updateactionGrid: any;
  profileID: any;
  reqId1: any;
  candidateName: any;
  payRate: any;
  candidatename: any;
  userId: any;
  targetDate: string;
  isUploading: boolean;
  loaderForDownload: boolean;
  rowdataid: any;
  selectedResume: any = [];
  reqStatus: any;
  enddata: boolean;
  skills: any;
  preferredSkills: any = [];
  primarySkills: any = [];
  currencyValue: any;
  currencycode: any[];
  preferencList: any = [];
  currencysymbl: any;
  filedataa: any;
  skillsList: any = [];
  skillset1: any = {};
  skillsList12: any = [];
  selectedPrimaryskills: any = [];
  otherskills = false;
  patchlocationString: string;
  editLocationdata: any;
  rvId: any;
  isviewed: any;
  submittedProfileForm: FormGroup;
  show: boolean;
  PreferredSkills: any = [];
  skillset = {};
  reqStatusacc: string;
  rowDatagetres: any;
  displayModal: boolean;
  date: Date;
  histroy: any;
  reqList: any;
  // tslint:disable-next-line: max-line-length
  constructor(private datepipe: DatePipe, private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private apisService: ApisService, private breadcrumbService: BreadcrumbService, private messageService: MessageService) {
    this.reqData = JSON.parse(sessionStorage.getItem('reqData'));
    // console.log(" this.reqData", this.reqData);
    this.reqData.assignedToId === 0 ? this.show = false : this.show = true;
    this.reqId = this.reqData.reqId;
    this.reqPosi = this.reqData.noOfPositions;
    this.reqName = this.reqData.reqName;
    this.jobCat = this.reqData.jobCategory;
    this.maxrate = this.reqData.maxRate;
    this.breadcrumbService.setItems([
      { label: 'Open Positions', routerLink: '/openpositions' },
      { label: 'Open Position Edit', routerLink: '/openpositions-edit' },
    ]);
  }
  cols3 = [
    { dataKey: 'consultantName', header: 'Candidate Name' },
    { dataKey: 'submittedDate', header: 'Submitted Date' },
    { dataKey: 'skills', header: 'Skills' },
    { dataKey: 'exper', header: 'Experience' },
    { dataKey: 'status', header: 'Status' },
    { dataKey: 'billRate', header: 'Bill Rate' },
  ];
  cols11 = [
    { field: 'consultantName', header: 'Candidate Name', sno: 1 },
    { field: 'submittedDate', header: 'Submitted Date', sno: 2 },
    { field: 'requiredSkills', header: 'Skills', sno: 3 },
    { field: 'requiredSkills', header: 'Skills', sno: 4 },
    { field: 'experience', header: 'Experience', sno: 5 },
    { field: 'profileState', header: 'Status', sno: 6 },
    { field: 'billRate', header: 'Bill Rate', sno: 7 },
    { field: '', header: 'Action', sno: 8 },
  ];
  ngOnInit(): void {
    this.userDetails = JSON.parse(this.apisService.decodeData());
    this.preferencList = this.userDetails.preferencesList;
    if (
      this.preferencList === null ||
      this.preferencList === undefined ||
      this.preferencList.length === 0
    ) {
      this.currencyValue = 'USD';
    } else {
      this.currencycode = [];
      this.preferencList.forEach((element) => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }
    this.userAccId = this.userDetails.accId;
    this.UserId = sessionStorage.getItem('UserId');
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.reqStatusacc = this.route.snapshot.queryParamMap.get('gStatus');
    this.rvId = routeParams.params.rvId;
    this.isviewed = routeParams.params.viewed;
    if (Object.keys(routeParams.params).length !== 0) {
      this.name1 = routeParams.params.name;
      this.reqId = routeParams.params.reqId;
      this.titel = routeParams.params.title;
      this.formRoute = routeParams.params.from;
      this.gridStatus = routeParams.params.gStatus;
    } else {
      (this.name1 = this.reqData.name),
        (this.reqId = this.reqData.reqId),
        (this.titel = this.reqData.jobTitle),
        (this.formRoute = this.reqData.from),
        (this.gridStatus = this.reqData.reqStatus);
    }
    this.headerName = this.formRoute === 'open' ? 'Open Position' : 'Requirement';
    const nameLabel = this.formRoute === 'open' ? 'Open Positions' : 'Requirements';
    const routeUrl = this.formRoute === 'recruiter' ? '/recruiter-requirement' : '/requirements';
    this.targetDate = this.route.snapshot.queryParamMap.get('tdate');
    this.editRequirementsForm = this.fb.group({
      reqName: ['', Validators.required],
      requirementTitle: [''],
      requirementType: ['', Validators.required],
      requirementStartDate: ['', Validators.required],
      requirementEndDate: ['', Validators.required],
      requirementNoofPositions: ['', Validators.required],
      requirementReqExp: ['', Validators.required],
      requirementWorkCenterLocation: ['', Validators.required],
      requirementStatus: ['', Validators.required],
      requirementMinRate: [''],
      requirementMaxRate: [''],
      requirementDescription: ['', Validators.required],
      requirementResponsibilities: ['', Validators.required],
      requirementQualification: ['', Validators.required],
      requirementSkillSet: ['', Validators.required],
      preferredSkills: [''],
      requirementComments: [''],
      positionCategory: [''],
      billingtype: ['', Validators.required],
      hrsperWeek: [''],
      duration: [''],
      department: [],
      experience: [''],
      approval: [''],
      otherskills: ['']
    });
    this.submittedProfileForm = this.fb.group({
      firstName: ['', [Validators.pattern('^[a-zA-Z]*$')]],
      lastName: ['', [Validators.pattern('^[a-zA-Z]*$')]],
      vendorName: ['', Validators.pattern('^[a-zA-Z]*$')],
      emailId: [''],
      skillSet: [''],
      experience: [''],
      rejectReason: ['']
    });
    this.editRequirementsForm.disable();
    this.getConStatus();
    this.getDepartments();
    this.getPositionCatagory();
    this.getStatus();
    this.getBillingDropdown();
    this.getHireTypeData();
    this.getdSkillset();
    this.getWorkLocation();
    this.getExperienceDropDownData();
    this.editReqData();
    if (this.reqStatusacc === 'R') {
      this.reqSkillSet();
      this.search();
      this.getConStatus();
    } else {

    }
    if (this.isviewed === 'N') {
      this.openposviewedornot();
    } else {

    }
  }

  openposviewedornot() {
    const reqbody = {
      id: this.rvId
    };
    this.apisService.putApi(environment.openposviewedornot, reqbody).subscribe((res: any) => {
    });
  }
  backNavigate() {
    this.router.navigate(['/openpositions']);
  }
  submitProfileGrid() {
    this.loader = true;
    const row = this.rows;
    const page = this.page;
    // tslint:disable-next-line:max-line-length
    this.apisService.getApi(environment.avaliableSubmitedPro + page + '/' + row + '/' + this.reqId + '?consEmail=&fName=&lName=&exp=').subscribe((res: any) => {
      this.submittedProfilesGridData = res.data.records;
      this.subProRecords = res.data.count;
      this.loader = false;
      if (this.subProRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  getConStatus() {
    this.apisService.getApi(environment.lookupsData + 'RPLPS').subscribe((res) => {
      this.consStatusData = res.data;
      this.consStatusData.forEach((element) => {
        this.statusList[element.code] = element.label;
      });
    });
  }
  conStatusTable(data) {
    const conStatus = this.consStatusData.find((x) => x.code === data).label;
    return conStatus;
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.submitProfileGrid();
  }
  getdSkillset() {
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.skillsList = res.data;
      this.skillsList.forEach((element) => {
        this.skillset1[element.code] = element.label;
      });
    });
  }
  // reqSkillSet() {
  //   this.apisService.getApi(environment.skills).subscribe((res) => {
  //     this.specializationSkillsData = res.data;
  //   });
  // }
  reqSkills1(data) {
    this.display = true;
    this.reqSkillsValue = data;
  }
  getRolesBasedonCategory(event) {
    this.apisService.getApi(environment.jobTitle + this.userAccId + '/' + event).subscribe((res) => {
      this.jobTitleData = res.data;
    });
  }
  editReqData() {
    this.loader = true;
    this.apisService.getApi(environment.requirementEdit + '/' + this.reqId).subscribe((res) => {
      this.reqList = res.data;
      // console.log(" this.reqList", this.reqList);
      this.getPositionCategoryBindDataValues(this.reqList);
      this.getRolesBasedonCategory( this.reqList.positionCategory);
      this.typeData(this.reqList.hireType);
      this.editRequirementsForm.patchValue({
        reqName: this.reqList.name,
        positionCategory: this.reqList.positionCategory,
        requirementTitle: this.reqList.jobTitle.id,
        department: this.reqList.departmentId,
        requirementMinRate: this.reqList.minRate,
        requirementMaxRate: this.reqList.maxRate,
        requirementNoofPositions: this.reqList.noOfPositions,
        requirementType: this.reqList.hireType,
        requirementStartDate: this.reqList.plannedStartDate,
        requirementEndDate: this.reqList.plannedEndDate,
        billingtype: this.reqList.billingType,
        duration: this.reqList.duration,
        hrsperWeek: this.reqList.hrsPerWeek,
        requirementStatus: this.reqList.status,
        requirementDescription: this.reqList.description,
        requirementResponsibilities: this.reqList.responsibilities,
        requirementQualification: this.reqList.qualification,
        requirementComments: this.reqList.comments,
        approval: this.reqList.empApprovalReqFlag,
        otherskills: this.reqList.otherSkills,
      });
      this.skills = this.reqList.skills.toString(),
        this.preferredSkills = this.reqList.prefferedSkills.toString();
      const selectedPrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.skillsList.length; i++) {
        const array = this.reqList.prefferedSkills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.skillsList[i].code) === array[j]) {
            selectedPrimarySkills.push(this.skillsList[i]);
          }
        }
      }
      const preferredSkills = selectedPrimarySkills;
      preferredSkills.forEach((element) => {
        this.skillsList12.push(element.label);
      });
      const selectedPrePrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.skillsList.length; i++) {
        const array = this.reqList.skills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.skillsList[i].code) === array[j]) {
            selectedPrePrimarySkills.push(this.skillsList[i]);
          }
        }
      }
      const primarySkills = selectedPrePrimarySkills;
      primarySkills.forEach((element) => {
        this.primarySkills.push(element.label);
      });
      this.editRequirementsForm.controls.requirementSkillSet.patchValue(selectedPrePrimarySkills);
      this.editRequirementsForm.controls.preferredSkills.patchValue(preferredSkills);
      selectedPrePrimarySkills.forEach((element) => {
        this.selectedPrimaryskills.push(element.label);
      });
      if (this.reqList.otherSkills === null || this.reqList.otherSkills === '' || this.reqList.otherSkills === undefined) {
        this.otherskills = false;
      }
      else if (this.reqList.otherSkills !== null || this.reqList.otherSkills !== '' || this.reqList.otherSkills !== undefined) {
        this.otherskills = true;
      }
      else {
        return this.otherskills = false;
      }
      this.editRequirementsForm.controls.requirementWorkCenterLocation.patchValue(
        this.locationList1.filter(
          (x) => x.locId === this.reqList.location
        )[0]
      );
      this.editLocationdata = this.locationList1.filter(
        (x) => x.locId === this.reqList.location
      )[0];
      this.patchlocationString = this.editLocationdata?.name + '\n' + this.editLocationdata?.address1 + ',' + this.editLocationdata?.city + ',' + this.editLocationdata?.state + ',' + this.editLocationdata?.country;
      this.editRequirementsForm.controls.experience.patchValue(
        this.experienceData.filter(
          (x) => x.code === this.reqList.experience
        )[0]
      );
    });
    this.loader = false;
  }
  getExperienceDropDownData() {
    this.apisService.getApi(environment.lookupsData + 'RELYE').subscribe((res: any) => {
      this.experienceData = res.data;
      this.experienceData.forEach((element) => {
        this.expLabel[element.code] = [element.label];
      });
    });
  }
  getDepartments() {
    // this.reqData.createdByAccId
    // console.log("this.reqData.ownerId",this.reqData.ownerId);
    this.apisService.getApi(environment.departments + '/' + this.reqData.ownerId).subscribe((res) => {
      this.departmentList = res.data;
    });
  }
  getPositionCatagory() {
    // this.reqData.createdByAccId
    this.apisService.getApi(environment.positionCat + '/' + this.reqData.ownerId).subscribe((res) => {
      this.positionData = res.data;
    });
  }

  typeData(event) {
    if (event === 'C' || event === 'H') {
      this.enddata = true;
      this.editRequirementsForm.get('requirementEndDate').markAsTouched();
      this.editRequirementsForm.get('requirementEndDate').enable();
    } else {
      this.editRequirementsForm.get('requirementEndDate').disable();
      this.enddata = false;
    }
  }
  getWorkLocation() {
    // this.reqData.createdByAccId
    this.apisService.getApi(environment.location + '/' + this.reqData.ownerId).subscribe((res1) => {
      this.locationList1 = res1.data;
    });
  }
  getStatus() {
    this.apisService.getApi(environment.lookupsData + 'RELS').subscribe((res) => {
      this.statusList = res.data;
    });
  }
  getBillingDropdown() {
    this.apisService.getApi(environment.lookupsData + 'RELBT').subscribe((res) => {
      this.billingList = res.data;
    });
  }
  getHireTypeData() {
    this.apisService.getApi(environment.lookupsData + 'RELHT').subscribe((res) => {
      this.hireTypeList = res.data;
    });
  }
  getMinrate(event) {
    const availbleRate = this.jobTitleData.find((x) => x.id === event);
    this.editRequirementsForm.controls.requirementMinRate.patchValue(
      availbleRate.minrate
    );
    this.editRequirementsForm.controls.requirementMaxRate.patchValue(
      availbleRate.maxrate
    );
  }
  reqSkills(data) {
    this.display = true;
    this.reqSkillsValue = data;
  }
  downLoadsToggle() {
    this.downloadOptions = [
      { label: 'Download XLSX', icon: 'pi pi-download', command: (event, i) => { this.exportExcel(); }, },
      { label: 'Download PDF', icon: 'pi pi-download', command: (event, i) => { this.exportPdf(); }, },
    ];
  }
  toggle(menu, event, rowData) {
    this.rowDatagetres = rowData;
    this.consultantName = rowData.consultantName;
    this.items = [

      { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); } },
      { label: 'Profile Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); } },
    ];
  }
  deleteRow() {
    Swal.fire({
      text: 'Are you sure want to remove ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0D416B',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.apisService.deleteApi(environment.removeRequirement + this.idToDelete).subscribe((res: any) => {
          this.submitProfileGrid();
          Swal.fire({ text: res.message, icon: 'success', confirmButtonColor: '#0D416B', iconColor: '#1dbb99', confirmButtonText: 'Ok', });
        });
      }
    });
  }
  downloadFileapi() {
    this.isUploading = true;
    this.filedata = this.rowDatagetres.resumeId;
    this.apisService.getApi(environment.downloadedattachment + '/' + this.filedata).subscribe((res) => {
      this.resumeRes = res;
      setTimeout(() => {
        this.isUploading = false;
      }, 2000);
      window.location.assign(this.resumeRes.downloadUrl);
    });
  }
  updateAction() {
    this.updateaction = true;
    this.updateactionBtn = false;
    this.filedata = this.rowData;
    this.userid = this.rowData.userId;
    this.resumID = this.rowData.resumeId;
    this.apisService.getApi(environment.requireupdateaction + `/${this.userid}`).subscribe((res: any) => {
      this.updateactionGrid = res.data;
    });
  }
  selectedResumeCheck(data) {
    if (data.attachmentPath === this.rowData.attachmentPath) {
      this.selectedResume = [];
      this.selectedResume.push(data);
    } else {
      return;
    }
  }
  updatedownload(data) {
    this.filedataa = data.reqId;
    this.loaderForDownload = true;
    this.apisService.getApi(environment.downloadedattachment + '/' + this.filedata).subscribe((res) => {
      this.resumeRes = res;
      window.location.assign(this.resumeRes.downloadUrl);
      this.loaderForDownload = false;
    });
  }
  onSelect(event) {
    this.updateactionBtn = true;
    this.filedata = this.rowData;
    this.profileID = this.filedata.profileId;
    this.rowdataid = event.data.id;
  }
  onUnselect(event) {
    this.updateactionBtn = false;
    const val1 = event.data.id;
  }
  submitAction() {
    const data = {
      id: this.profileID,
      resumeId: this.rowdataid,
    };
    this.apisService.putApi(environment.updateaction, data).subscribe((res: any) => {
      if (res.hasOwnProperty('success') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        this.submitProfileGrid();
        this.updateaction = false;
      } else if (res.hasOwnProperty('success') === false) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
      } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
      }
    });
  }
  addReq() {
    this.filedata = this.rowData;
    this.profileID = this.filedata.profileId;
    const queryParams = {
      proId: this.profileID,
      reqId: this.requiremntId,
      consultantName: this.consultantName,
      jobTitle: this.reqName,
      conId: this.conId,
    };
    this.router.navigate(['/schedule-interview'], {
      queryParams: {
        proId: this.profileID,
        reqId: this.requiremntId,
        consultantName: this.consultantName,
        jobTitle: this.reqName,
        conId: this.conId,
      },
    });
  }
  ratings() {
    this.filedata = this.rowData;
    this.profileID = this.filedata.profileId;
    this.reqId1 = this.filedata.reqId;
    this.router.navigate(['/tech-reviews'], {
      queryParams: {
        proId: this.profileID,
        reqId: this.reqId1,
        conId: this.filedata.conId,
        reqname: this.reqName,
        vendorId: this.filedata.vendorId,
        userId: this.userId,
      },
    });
  }
  editSearchconsultant() {
    this.router.navigate([
      '/edit-consultant-search/open-postions-edit',
      this.userId,
    ]);
  }
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
  saveLocation(ev) {
    const data = ev.value;
    this.patchlocationString = data.name + '\n' + data.address1 + ',' + data.city + ',' + data.state + ',' + data.country;
  }
  setRequired() {
    return [Validators.required, Validators.maxLength(300)];
  }
  onBlur(data): void {
    if (data === '') {
      this.editRequirementsForm.controls.otherskills.setValidators(this.setRequired());
    }
  }
  search() {
    // tslint:disable-next-line:max-line-length
    const fName = this.submittedProfileForm.controls.firstName.value === null || undefined || '' ? '' : this.submittedProfileForm.controls.firstName.value;
    // tslint:disable-next-line:max-line-length
    const lName = this.submittedProfileForm.controls.lastName.value === null || undefined || '' ? '' : this.submittedProfileForm.controls.lastName.value;
    // tslint:disable-next-line:max-line-length
    const email = this.submittedProfileForm.controls.emailId.value === null || undefined || '' ? '' : this.submittedProfileForm.controls.emailId.value;
    // tslint:disable-next-line:max-line-length
    const exp = this.submittedProfileForm.controls.experience.value === null || this.submittedProfileForm.controls.experience.value === undefined || this.submittedProfileForm.controls.experience.value === '' ? '' : this.submittedProfileForm.controls.experience.value.code;
    this.loader = true;
    const pgFlag = this.page;
    const pgNo = this.rows;
    const data = pgFlag + '/' + pgNo + '/' + this.reqId + '?consEmail=' + email + '&fName=' + fName + '&lName=' + lName + '&exp=' + exp;
    this.apisService.getApi(environment.avaliableSubmitedPro + data).subscribe((res) => {
      this.submittedProfilesGridData = res.data.records;
      this.subProRecords = res.data.count;
      if (this.subProRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.loader = false;
    });
  }
  reqSkillSet() {
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.PreferredSkills = res.data;
      this.PreferredSkills.forEach((element) => {
        this.skillset[element.code] = element.label;
      });
    });
  }
  getSkillSetTable(data) {
    this.array = data;
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.PreferredSkills.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      if (data === null || data === undefined || data === '' || data === isEmpty()) { }
      else {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.array.length; j++) {
          if (Number(this.PreferredSkills[i].code) === this.array[j]) {
            selectedPrePrimarySkills3.push(
              this.PreferredSkills[i].label
            );
          } else {
          }
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  exportExcel() {
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    ws['!cols'] = [ {width : 10}, {width : 20}  , {width : 20} , {width : 10}, {width : 10}, {width : 10}, {width : 10}, {hidden : true} ];
    ws['!rows'] = [{hpt : 30}, {hpt : 30}, ];
    ws['!cols'][3] = { hidden: true };
    ws['!cols'][7] = { hidden: true };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ExcelSheet.xlsx');
  }
  exportPdf() {
    this.submittedProfilesGridData.forEach((element) => {
      element.status = this.statusList[element.profileState];
      element.skills = this.getSkillSetTable(element.requiredSkills);
      element.exper = this.expLabel[element.experience];
    });
    const doc = new jsPDF('p', 'pt');
    autoTable(doc, {
      headStyles: { fillColor: '#2bb9d5', cellPadding: 5   },
      margin: { left: 17},
      columns: this.cols3,
      body: this.submittedProfilesGridData,
      didDrawPage: (dataArg) => {
        doc.text('Submitted Candidates', dataArg.settings.margin.left, 30);
      }
    });
    doc.save('Submitted-Candidates.pdf');
  }
  profilehistroy() {
    this.displayModal = true;
    this.date = new Date();
    const actId = JSON.parse(this.apisService.decodeData());
    this.apisService.getApi(environment.getProfileActivity + this.rowDatagetres.profileId).subscribe((res: any) => {
      this.histroy = res.data;
      if (this.subProRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.loader = false;

    });

  }
  getPositionCategoryBindDataValues(data) {
    // console.log("data",data);
    const acctId = data.accId;
    const posnCat = data.category;
    this.requiremntId = data.reqId;
    this.apisService.getApi(environment.maxRate + this.reqData.ownerId + '/' + posnCat).subscribe((res) => {
      this.minMaxRate = res.data;
      this.editRequirementsForm.controls.requirementTitle.patchValue(
        this.minMaxRate.filter((x) => x.title === data.jobTitle.title)[0]
      );
    });
  }
}
