<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
  <form [formGroup]="consultantSearchForm">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-3 p-lg-3">
        <label class="inputfields">Name</label>
        <input id="input" type="text" size="30" pInputText placeholder="Name" autocomplete="off"
          (keyup.enter)="searchConsultant()" maxlength="50" formControlName="name">
      </div>
      <div class="p-field p-col-12 p-md-3 p-lg-3">
        <label class="inputfields">Email</label>
        <input id="input" type="text" size="30" pInputText placeholder="Email" autocomplete="off"
          (keyup.enter)="searchConsultant()" maxlength="50" formControlName="emailId">
      </div>
      <div class="p-field p-col-12 p-md-3 p-lg-3">
        <label class="inputfields">Status</label>
        <p-dropdown [options]="Status" optionLabel="label" (keyup.enter)="searchConsultant()" [showClear]="true"
          placeholder="Select Status" formControlName="status"></p-dropdown>
      </div>
      <div class="p-field p-col-12 p-md-3 p-lg-3">
        <label class="inputfields">Skills</label>
        <p-multiSelect (keyup.enter)="searchConsultant()" [options]="specializationSkills" optionValue="code"
          formControlName="specialization" optionLabel="label" placeholder="Select Skills">
        </p-multiSelect>
      </div>
      <div class="p-field p-col-12 p-md-3 p-lg-3">
        <label class="inputfields">Country</label>
        <p-dropdown [options]="country" placeholder="Select Country" optionLabel="label"
        [showClear]="true" (keyup.enter)="searchConsultant()" formControlName="country" (onChange)="getStateByUrl($event.value.code)">
        </p-dropdown>
      </div>
      <div class="p-field p-col-12 p-md-3 p-lg-3">
        <label class="inputfields">State</label>
        <p-dropdown [options]="state" placeholder="Select State" optionLabel="label" (keyup.enter)="searchConsultant()"
        [showClear]="true"  formControlName="state"></p-dropdown>
      </div>
      <div class="p-field p-col-12 p-md-3 p-lg-3">
        <label class="inputfields">Phone</label>
        <p-inputMask autocomplete="off" mask="(999) 999-9999" formControlName="phone" (keyup.enter)="searchConsultant()"
          placeholder="Phone">
        </p-inputMask>
      </div>
      <div class="p-field p-col-12 p-md-3 p-lg-3">
        <label class="inputfields">Experience </label>
        <p-dropdown [options]="experienceData" placeholder="Select Experience" optionLabel="label" optionValue="code"
        [showClear]="true"  (keyup.enter)="searchConsultant()" formControlName="experience">
        </p-dropdown>
      </div>
    </div>
    <!-- <div class="p-fluid p-formgrid p-grid">

        </div> -->
    <div class="btnsCSS1">
      <p-splitButton label="Import" [model]="items1" class="addhBtn "></p-splitButton>
      <button class="addhBtn" pButton type="button" icon='pi pi-plus' (click)="add()" label="Add"></button>
      <button class="addhBtn" pButton type="button" icon='pi pi-refresh' (click)="reset()" label="Reset"></button>
      <button class="addhBtn" pButton type="submit" icon='pi pi-search' (click)="searchConsultant()"
        label="Search"></button>
    </div><br><br>
    <div *ngIf="isUploading" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
      <p-progressBar mode="indeterminate"></p-progressBar>
    </div><br>
    <div *ngIf="showUploadView" class="dropzone p-col-12 p-md-12 p-sm-12 p-lg-3">
      <em class="pi pi-upload uploadIcon Iconsize" aria-hidden="true"></em>
      <input accept=".xlsm,.xlsx,application/msexcel" type="file" class="fileInput" #fileDropRef id="fileDropRef"
        multiple (change)="onUpload($event.target.files)" style="cursor: pointer;" />
      <h3>Drag and drop file here</h3>
      <h3 class="importstylin">or</h3>
      <label id="fileUpload" for="fileDropRef">Browse for files</label>
    </div>
  </form>
</p-card>
<br>
<p-dialog class="dialogBox" header="Activate User Contact" [(visible)]="displayActive" [style]="{width: '25%'}"
  [draggable]="false" [modal]="true" [resizable]="false">
  <p><strong>{{loginActiveData}}</strong></p>
  <!-- <a class="aligncenter pointer">({{email1}})</a> -->
  <br><br>
  <div *ngIf="registerdStatus" class="btnsCSS1">
    <button pButton type="button" icon='pi pi-times' label="Cancel" class="approvebtn" (click)="cancel()"></button>
    <button pButton type="button" icon='pi pi-send' label="Activate" (click)="saveUserContacts()" class='buttonsize'
      [disabled]="disable"></button>
  </div>
</p-dialog>
<p-dialog class="dialogBox" header="Send Candidate Credentials" [(visible)]="displaySend" [style]="{width: '25%'}"
  [draggable]="false" [modal]="true" [resizable]="false">
  <p><strong>{{loginActiveData}}</strong></p>
  <div class="p-grid p-fluid">
    <div class="p-col-12 p-md-12 p-sm-12 p-fluid aligncenter">
        <form [formGroup]="verifyForm">
            <span class="p-input-icon-right"><em *ngIf="altEmailSpinnerHide"
                class="pi pi-spin pi-spinner mailspin"></em>
            <em *ngIf="altEmailCheckHide" class="pi pi-check-circle mailcol"></em>
            </span>
            <div class="p-inputgroup">
              <!-- (blur)="emailverification()" -->
                <input id="input" type="text" autocomplete="off" pInputText placeholder="Email" 
                    formControlName="verifyEmail" maxlength="60" #userInput>

                <span class="p-inputgroup-addon"> <em class="pi pi-copy pointer"
                        (click)="emailToast()" (click)="copyInputMessage(userInput.value)"></em>
                </span>
            </div>
            <span class="validationtest"
                *ngIf="this.verifyForm.controls.verifyEmail.touched && this.verifyForm.controls.verifyEmail.invalid && this.verifyForm.controls.verifyEmail.errors.required">
                Please enter email id</span>
            <span class="validationtest"
                *ngIf="this.verifyForm.controls.verifyEmail.invalid && this.verifyForm.controls.verifyEmail.errors?.pattern">Please
                enter
                email id upto extension only</span>
            <span *ngIf="displayemail1" class="mandatoryColor">Email already exist</span>
        </form>
    </div>
</div>
  <br>
  <div *ngIf="sendCandidateCredentials" class="btnsCSS1">
    <button pButton type="button" icon='pi pi-times' label="Cancel" class="approvebtn" (click)="cancel()"></button>
    <button pButton type="button" icon='pi pi-send' label="Send"  class='buttonsize' (click)="sendCredentials()"
      [disabled]="btndisable"></button>
  </div>
</p-dialog>
<p-card class="pgpos">
  <div *ngIf="table">
    <p-table [value]="gridData" [columns]="cols" selectionMode="single" dataKey="edit" [responsive]="true"
      id="excel-table" sortMode="multiple" responsiveLayout="scroll">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter" [hidden]="col.sno == 5"
            [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
            <span>
              {{col.header}}
            </span>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-data>
        <tr>
          <td pTooltip="{{data.name}}" tooltipPosition="top" class="namedesc"><span
              class="ui-column-title"></span>{{data.name}}</td>
          <td class="namedesc pointer" pTooltip="{{data.email}}" tooltipPosition="top">
            <span> <a (click)="emailToast()" (click)="copyInputMessage(data.email)">{{data.email}}</a>
            </span>
          </td>
          <td><span class="ui-column-title">
            </span>{{this.expLabel[data.lkExperience]}}</td>
          <td class="pointer">
            <a (click)="op.toggle($event)" (click)="openSkillDailog(roletable(data.requiredSkills))"
              *ngFor="let data1 of roletable(data.requiredSkills); index as i;">
              <p-badge class="badgespace newsty" *ngIf='i<1' [value]="data1"></p-badge>
              <p-badge *ngIf='i===1' [value]="'+'+(data.requiredSkills.length-1)+' more'"></p-badge>
            </a>

          </td>
          <td class="pointer" [hidden]="true">
            <a (click)="op.toggle($event)" (click)="openSkillDailog(roletable(data.requiredSkills))"
            *ngFor="let data1 of roletable(data.requiredSkills); index as i;">
            <p-badge  [value]="data1+','"></p-badge>
          </a>

        </td>
          <td><span class="ui-column-title">
            </span>{{data.mobilePhone}}</td>
          <td><span class="ui-column-title"></span>
            <p-badge [value]="statusTable[data.lkStatus]"
              [ngClass]="data.lkStatus==='A'?'green': data.lkStatus === 'I'?'red': 'blue'">
            </p-badge>
          </td>
          <td class="pointer aligncenter"><span class="p-column-title"></span><a>
              <em (click)="editActionPopup.toggle($event); toggle($event,data, rowData)"
                class="pi pi-ellipsis-v leftalign"></em>
            </a> </td>
        </tr>
      </ng-template>
    </p-table>
    <ng-template pTemplate="emptymessage">
      <tr *ngIf="gridData?.length==0">
        <td colspan="7" class="mandatoryColor aligncenter">
          No Records
        </td>
      </tr>
    </ng-template>
    <div class="p-grid ui-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 totalrec">
        <h3 class="norecorddata ">Total records : {{totalRecords}}</h3>
        <p class="downpos">
          <span class="ui-column-title pointer" (click)="downloadActionPopup.toggle($event); downLoadsToggle()"
            (keyup)="exportExcel()">
            <em class="pi pi-download downloadspac"></em>
            <strong>Download</strong></span>
        </p>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
        <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
          (onPageChange)="paginate($event)" #p></p-paginator>
      </div>
    </div>


  </div>
  <p-card *ngIf="records">
    <div class="norecordImgsize">
      <div class="aligncenter">
        <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
        <br><em>Sorry, we are not able to find any candidates</em><br>
      </div>
    </div>
  </p-card>
</p-card>


<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center laoderheight ">
        <div class="laodersize">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
            class="loaderimg-height">
        </div>
      </div>
    </div>
  </div>
</ng-template>
<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '25%' }">
  <label class="inputfields">Skill Details</label>
  <ng-template pTemplate>
    <form [formGroup]="dialogForm">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12 bordersty">

          <p class="skpos">

            <p-badge class="badgespace badgesp" *ngFor="let data2 of skillData; index as i" [value]="data2"
              [ngClass]="i%2===0? 'newsty' : ''">
            </p-badge>


          </p>

        </div>
      </div>

    </form>
  </ng-template>
</p-overlayPanel>
<p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="downloadOptions"></p-menu>
<p-menu class="menuclass" #editActionPopup [popup]="true" [model]="items"></p-menu>