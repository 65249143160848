<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
    <p-panel>
        <p-header>
            <strong> {{subname}} </strong>
            <em class="pi pi-arrow-left panelBackRoute pointer" (click)="goto()"></em>
        </p-header>
        <p-tabView (onChange)="onTabChange($event)">
            <p-tabPanel header="Account Details">
                <form [formGroup]="accountDetailsForm">
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 alignitems">
                            <div class="p-grid p-fluid">
                                <div class="p-col-4 p-md-4 p-sm-12" class="imageDiv">
                                    <label class="hoverable" for="fileInput">
                                        <img alt="" class="img1"
                                            [src]="this.vlogo ? this.vlogo : 'https://d2r8nxfsv3v6y3.cloudfront.net/images/Account.png'">
                                        <div class="hover-text">
                                            Choose file
                                            <h4 class="success-message" *ngIf="url"> Image added successfully </h4>
                                        </div>
                                        <div class="background"></div>
                                    </label>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-9">
                            <div class="p-grid p-justify-end">
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-9">
                                    <div class="p-grid p-justify-end">

                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                    <label class="inputfields">Account Name</label>
                                    <input id="input" formControlName="accountName" autocomplete="off" type="text"
                                        size="30" pInputText placeholder="Account name" maxlength="60" disabled="true">
                                </div>
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                    <label class="inputfields">Corporate Website</label>
                                    <input disabled="true" id="input" formControlName="accountURL" autocomplete="off"
                                        type="text" size="30" pInputText placeholder="Corporate Website" maxlength="60">
                                </div>
                            </div><br>
                            <div class="p-grid  p-fluid">
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
                                    <label class="inputfields">Status<span class="mandatoryColor">*</span></label>
                                    <p-dropdown [disabled]="true" [options]="status" formControlName="status"
                                        [showClear]="true" optionValue="code" placeholder="Select Status"></p-dropdown>
                                    <div>
                                        <span class="validationtest"
                                            *ngIf="this.accountDetailsForm.get('status').touched && this.accountDetailsForm.get('status').invalid && this.accountDetailsForm.get('status').errors.required">
                                            Please select status
                                        </span>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
                                    <label class="inputfields">Home Number</label>
                                    <div class="p-col-12 p-md-12 p-sm-12 mobstyle">
                                        <div class="p-inputgroup" class="p-col-4 p-md-4 mobinput">
                                            <span class="p-inputgroup-addon my-component">
                                                <p-dropdown [options]="countrycodedropdown"
                                                    class="phonecodestyle newcode" (onChange)="countryids($event)"
                                                    [disabled]="true" filterPlaceholder='Search Results'
                                                    optionLabel="label" formControlName="phNumDrp">
                                                    <ng-template let-items pTemplate="selectedItem">
                                                        <div class="flex-row">
                                                            <img class="lang-change-img" src={{items.flag}} alt="" />
                                                            <div class="lang-change-option-text selected-lang center">
                                                                {{items.code}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template let-country pTemplate="item">
                                                        <div class="flex-row" id="dropdown1">
                                                            <img class="lang-change-img" src={{country.flag}} alt="" />
                                                            <div class="lang-change-option-text center">
                                                                {{country.label}}
                                                                {{country.code}}</div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </span>
                                        </div>
                                        <div class="p-col-8 p-md-8 phnostyle">
                                            <input disabled="true" type="text" formControlName="phone"
                                                mask="(999) 999-9999" autocomplete="off" pKeyFilter="num" size="30"
                                                pInputText placeholder="Phone">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 id="h3tag">Account Address</h5>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                            <label class="inputfields">Address Line 1</label>
                            <input disabled="true" autocomplete="off" formControlName="address1" id="input" type="text"
                                size="30" pInputText placeholder="Address 1" maxlength="100">
                            <div>
                                <span class="validationtest"
                                    *ngIf=" this.accountDetailsForm.get('address1').hasError('maxlength')">
                                    Please enter 100 alphabets only
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                            <label class="inputfields">Address Line 2</label>
                            <input disabled="true" autocomplete="off" formControlName="address2" id="input" type="text"
                                size="30" pInputText placeholder="Address 2" maxlength="100">
                            <div>
                                <span class="validationtest"
                                    *ngIf=" this.accountDetailsForm.get('address2').hasError('maxlength')">
                                    Please enter 100 alphabets only
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                            <label class="inputfields" id='csslabel'>Mailbox</label>
                            <input disabled="true" type="text" formControlName="mailbox" autocomplete="off"
                                maxlength="100" pInputText placeholder="Mailbox">
                            <div>
                                <span class="validationtest"
                                    *ngIf=" this.accountDetailsForm.get('mailbox').hasError('maxlength')">
                                    Please enter 100 alphabets only
                                </span>
                            </div>
                        </div>


                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields">Country</label>
                            <p-dropdown [disabled]="true" placeholder="Select Country" optionLabel="label"
                                [showClear]="true" [options]="country" formControlName="country"
                                (onChange)="getStateByUrl($event.value.code)" optionValue="code">
                            </p-dropdown>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields">State</label>
                            <p-dropdown [disabled]="true" [options]="stateoptions" placeholder="Select State"
                                [showClear]="true" optionLabel="label" optionValue="code" formControlName="state">
                            </p-dropdown>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields">City</label>
                            <input disabled="true" type="text" formControlName="city" autocomplete="off" maxlength="100"
                                pInputText placeholder="City">
                            <span class="validationtest"
                                *ngIf="this.accountDetailsForm.controls.city.invalid && this.accountDetailsForm.controls.city.errors?.pattern">Please
                                enter
                                alphabets only</span>
                            <div>
                                <span class="validationtest"
                                    *ngIf=" this.accountDetailsForm.get('city').hasError('maxlength')">
                                    Please enter 20 alphabets only
                                </span>
                            </div>

                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields">Zip</label>
                            <input disabled="true" type="text" formControlName="zip" autocomplete="off" pKeyFilter="num"
                                size="30" pInputText placeholder="Zip">
                            <div>
                                <span class="validationtest"
                                    *ngIf=" this.accountDetailsForm.get('zip').hasError('maxlength')">
                                    Please enter 6 alphabets only
                                </span>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields">Fax</label>
                            <input disabled="true" type="text" autocomplete="off" formControlName="fax" pKeyFilter="num"
                                size="30" pInputText placeholder="Fax">
                            <div>
                                <span class="validationtest"
                                    *ngIf=" this.accountDetailsForm.get('fax').hasError('maxlength')">
                                    Please enter 16 alphabets only
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-9"></div>
                    </div>
                    <h5 id="h3tag">Basic Information</h5>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields">Industry</label>
                            <p-dropdown [disabled]="true" [options]="industryOptions" optionLabel="label"
                                [showClear]="true" formControlName="industry" placeholder="Select Industry">
                            </p-dropdown>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields">Region</label>
                            <p-dropdown [disabled]="true" [options]="regionOptions" optionValue="code"
                                [showClear]="true" optionLabel="label" formControlName='region'
                                placeholder="Select Region">
                            </p-dropdown>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields">No.Of Employees</label>
                            <input disabled="true" type="text" autocomplete="off" formControlName="noOfEmployees"
                                pKeyFilter="No.Of Employees" size="30" pInputText placeholder="Fax">
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields">Tax ID</label>
                            <input disabled="true" type="text" formControlName="taxId" autocomplete="off"
                                pKeyFilter="Tax Id" size="30" pInputText placeholder="Tax Id">
                            <div>
                                <span class="validationtest"
                                    *ngIf=" this.accountDetailsForm.get('taxId').hasError('maxlength')">
                                    Please enter 20 alphabets only
                                </span>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields">Stock Symbol</label>
                            <input disabled="true" type="text" formControlName="stockSymbol" autocomplete="off"
                                maxlength="100" pInputText placeholder="Stock symbol">
                            <div>
                                <span class="validationtest"
                                    *ngIf=" this.accountDetailsForm.get('stockSymbol').hasError('maxlength')">
                                    Please enter 60 alphabets only
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields">Revenue</label>
                            <input disabled="true" type="text" formControlName="revenue" autocomplete="off"
                                pKeyFilter="Revenue" size="30" pInputText placeholder="Revenue">
                            <div>
                                <span class="validationtest"
                                    *ngIf="this.accountDetailsForm.get('revenue').hasError('maxlength')">
                                    Please enter maximum of 15 alphabets
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields">Dun & BradStreet No.</label>
                            <input disabled="true" type="text" formControlName="dunAndBradStreetNo" autocomplete="off"
                                maxlength="100" pInputText placeholder="Dun & BradStreet No.">
                            <div>
                                <span class="validationtest"
                                    *ngIf=" this.accountDetailsForm.get('dunAndBradStreetNo').hasError('maxlength')">
                                    Please enter 100 alphabets only
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3"></div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields">Business Type</label>
                            <p-dropdown [disabled]="true" [options]="businessType" optionLabel="label"
                                [showClear]="true" optionValue="code" formControlName="businessType"
                                placeholder=" Select Bsuiness Type">
                            </p-dropdown>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                            <label class="inputfields">Diversity</label>
                            <p-dropdown [disabled]="true" [options]="diversity" formControlName="diversity"
                                [showClear]="true" optionValue="code" placeholder="Select Diversity"></p-dropdown>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-6"></div>

                        <div class="p-col-12">
                            <label class="inputfields">Notes</label>
                            <textarea disabled="true" autocomplete="off" [rows]="3" [cols]="30" placeholder="Notes"
                                pInputTextarea formControlName="customerNotes" maxlength="300"></textarea>
                            <div>
                                <span class="validationtest"
                                    *ngIf="this.accountDetailsForm.get('customerNotes').touched && this.accountDetailsForm.get('customerNotes').hasError('maxlength')">
                                    Please enter maximum of 300 alphabets
                                </span>
                            </div>
                        </div>
                    </div>
                </form>

            </p-tabPanel>
            <p-tabPanel header="Banking Details" *ngIf="tabOption">
                <p-card  *ngIf="detailsLength != 0">
                    <form [formGroup]="bankdetailsForm">
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields" id='csslabel'>Bank Name</label>
                                <input type="text" autocomplete="off" maxlength="100" pInputText placeholder="Bank Name"
                                    formControlName="bankAccountName" [readonly]="true">
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields" id='csslabel'>Swift Code</label>
                                <input type="text" pInputText pKeyFilter="num" autocomplete="off" maxlength="100" pInputText
                                    placeholder="Swift Code" formControlName="bankAccountNumber" [readonly]="true">
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields" id='csslabel'>IFSC Code</label>
                                <input type="text" autocomplete="off" maxlength="100" pInputText placeholder="IFSC Code"
                                    formControlName="ifsccode" [readonly]="true">
                            </div>
                      
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <label class="inputfields" id='csslabel'>Beneficiary Account No</label>
                                <input type="text" autocomplete="off" maxlength="100" pInputText
                                    placeholder="Beneficiary Account No" formControlName="beneficiaryno" [readonly]="true">
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <label class="inputfields" id='csslabel'>Beneficiary Name</label>
                                <input type="text" pInputText pKeyFilter="num" autocomplete="off" maxlength="100" pInputText
                                    placeholder="Beneficiary Name" formControlName="beneficiaryname" [readonly]="true">
                            </div>
                      
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <label class="inputfields" id='csslabel'>Address</label>
                                <input type="text" autocomplete="off" maxlength="100" pInputText placeholder="Address"
                                    formControlName="address" [readonly]="true">
                            </div>
    
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                <label class="inputfields" id='csslabel'>City</label>
                                <input type="text" pInputText pKeyFilter="num" autocomplete="off" maxlength="100" pInputText
                                    placeholder="City" formControlName="city" [readonly]="true">
                            </div>
                    
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields" id='csslabel'>Country</label>
                                <p-dropdown [options]="country" placeholder="Select Country" optionLabel="label"
                                    [readonly]="true" (onChange)="getStateByUrl($event.value.code)" [showClear]="true"
                                    formControlName="country"></p-dropdown>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields" id='csslabel'>State</label>
                                <p-dropdown placeholder="Select State" [options]="stateoptions" [readonly]="true"
                                    optionLabel="label" [showClear]="true" formControlName="state"></p-dropdown>
                            </div>
    
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields" id='csslabel'>Zip</label>
                                <input type="text" pInputText pKeyFilter="num" autocomplete="off" maxlength="100"
                                    [readonly]="true" pInputText placeholder="Zip" formControlName="zip" [readonly]="true">
                            </div>
                        </div>
                    </form>
                </p-card>
                <p-card *ngIf="detailsLength === 0">
                    <div class="norecordImgsize">
                        <div class="aligncenter">
                            <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png"
                                class="norecordimg">
                            <br><em>Sorry, we are not able to find any banking details</em><br>
                        </div>
                    </div>
                </p-card>
            </p-tabPanel>
            <!-- <p-tabPanel *ngIf="tabOption" header="Job Categories">
                <div>
                    <p-card>
                        <form [formGroup]="titleForm">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                    <label class="inputfields">Job Category</label>
                                    <p-dropdown [options]="jobTitle" optionLabel="jobCategory"
                                        formControlName="jobTitle" placeholder="Select Job Category" [showClear]="true"
                                        optionValue="code" autocomplete="off">
                                    </p-dropdown>
                                </div>
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                    <label class="inputfields">Title</label>
                                    <input formControlName="title" id="input" type="text" pInputText placeholder="Title"
                                        autocomplete="off" maxlength="30">
                                </div>
                                <div class="p-col-3 p-md-3 p-sm-3 p-lg-3">
                                    <label class="inputfields">Min Rate</label><br>
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon">{{durationCal(0 | currency:
                                            currencyValue : true)}} </span>
                                        <input autocomplete="off" type="text" pInputText maxlength=5
                                            formControlName="min" placeholder="Min rate" pKeyFilter="num">
                                        <span class="p-inputgroup-addon">/Hrs</span>
                                    </div>
                                </div>
                                <div class="p-col-3 p-md-3 p-sm-3 p-lg-3">
                                    <label class="inputfields">Max Rate</label><br>
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon">{{durationCal(0 | currency:
                                            currencyValue : true)}} </span>
                                        <input autocomplete="off" type="text" pInputText maxlength=5
                                            formControlName="max" placeholder="Max rate" pKeyFilter="num">
                                        <span class="p-inputgroup-addon">/Hrs</span>
                                    </div>
                                </div>
                            </div><br><br>
                            <div class="btnsCSS1 btnalignments">
                                <button pButton type="button" icon='pi pi-refresh' label="Reset" class="addhBtn"
                                    (click)="rateCradReset()"></button>
                                <button pButton type="button" icon='pi pi-search' label="Search"
                                    class="addhBtn"></button>
                            </div><br>
                        </form>
                    </p-card>
                    <br>
                    <p-card class="pgpos">
                        <div *ngIf="table">
                            <p-table [columns]="cols" [value]="gridData" selectionMode="single" dataKey="edit"
                                [responsive]="true" sortMode="multiple">
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th id="gridHeader" *ngFor="let col of columns; let i = index"
                                            class="textCenter" [ngStyle]="{'width': col.width }"
                                            [pSortableColumn]="col.field">
                                            <span>
                                                {{col.header}}

                                            </span>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
                                    <tr>
                                        <td class="textCenter">{{+this.page === 1 ?
                                            rowIndex +1 :
                                            (+(this.page -1)*this.rows) + (rowIndex +1)}}</td>
                                        <td class="textCenter">{{data.title}}</td>
                                        <td class="textCenter">{{data.jobCategory}}</td>
                                        <td class="textCenter">
                                            {{data.minRate | currency: currencyValue : true}}
                                        </td>
                                        <td class="textCenter">
                                            {{data.maxRate | currency: currencyValue : true}}
                                        </td>

                                        <td class="textCenter">{{data.description}}
                                        </td>
                                        <td class="textCenter"><a>
                                                <em [ngClass]="(data.rateCardExistcount === 0)?'my-class2':'my-class'"
                                                    (click)="sweetAlertForTaxTerm(data)"
                                                    class="pi pi-arrow-circle-right"></em>
                                            </a></td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <div class="p-grid p-fluid">
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                                    <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
                                </div>
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                                    <p-paginator [rows]="10" [totalRecords]="this.totalRecords"
                                        [rowsPerPageOptions]="[10,20,30]" (onPageChange)="paginate($event)">
                                    </p-paginator>
                                </div>
                            </div>
                        </div>
                        <p-card *ngIf="records">
                            <div class="norecordImgsize">
                                <div *ngIf="records" class="aligncenter">
                                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png"
                                        class="norecordimg">
                                    <br><em>Sorry, we are not able to find any job categories</em><br>
                                </div>
                            </div>
                        </p-card>
                    </p-card>
                </div>
            </p-tabPanel> -->
            <!-- <p-tabPanel *ngIf="tabOption" header="Rate Card">
                <div>
                    <p-card>
                        <p-accordion>
                            <p-accordionTab header="Rate Card" [selected]="false">
                                <form [formGroup]="categoryForm">
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div class="p-col-3 p-md-3 p-sm-3">
                                            <label class="inputfields">Min Rate</label><br>
                                            <div class="p-inputgroup">
                                                <span class="p-inputgroup-addon">{{durationCal(0 | currency:
                                                    currencyValue : true)}} </span>
                                                <input formControlName="min" autocomplete="off" type="text" pInputText
                                                    maxlength=5 placeholder="Min rate" pKeyFilter="num">
                                                <span class="p-inputgroup-addon">/Hrs</span>
                                            </div>
                                        </div>
                                        <div class="p-col-3 p-md-3 p-sm-3">
                                            <label class="inputfields">Max Rate</label><br>
                                            <div class="p-inputgroup">
                                                <span class="p-inputgroup-addon">{{durationCal(0 | currency:
                                                    currencyValue : true)}} </span>
                                                <input formControlName="max" autocomplete="off" type="text" pInputText
                                                    maxlength=5 placeholder="Max rate" pKeyFilter="num">
                                                <span class="p-inputgroup-addon">/Hrs</span>
                                            </div>
                                        </div>
                                    </div><br>
                                    <div class="btnsCSS1 btnalignment">
                                        <button pButton type="button" icon='pi pi-refresh' label="Reset" class="addhBtn" 
                                            (click)="rateCradReset()"></button>
                                        <button pButton type="button" icon='pi pi-search' label="Search"
                                            class="addhBtn"></button>
                                    </div>
                                </form>
                            </p-accordionTab>
                        </p-accordion>
                    </p-card>
                    <br>
                    <p-card class="pgpos">
                        <div *ngIf="table1">
                            <p-table [columns]="cols1" [value]="rategridData" selectionMode="single" dataKey="edit"
                                [responsive]="true" sortMode="multiple">
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th id="gridHeader" *ngFor="let col of columns; let i = index"
                                            class="textCenter" [ngStyle]="{'width': col.width }"
                                            [pSortableColumn]="col.field">
                                            <span>
                                                {{col.header}}

                                            </span>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
                                    <tr>
                                        <td class="textCenter"><span class="ui-column-title"></span>{{+this.page === 1 ?
                                            rowIndex +1 :
                                            (+(this.page -1)*this.rows) + (rowIndex +1)}}</td>
                                        <td class="textCenter"><span class="ui-column-title"> </span>
                                            {{data.jobcategory}}</td>


                                        <td class="textCenter"><span class="ui-column-title">
                                            </span>{{data.jobTitle}}</td>

                                        <td class="textCenter"><span class="ui-column-title"> </span>
                                            {{data.minRate | currency: currencyValue : true}}
                                        </td>
                                        <td class="textCenter"><span class="ui-column-title"> </span>
                                            {{data.maxRate | currency: currencyValue : true}}
                                        </td>

                                        <td class="textCenter"><span class="ui-column-title"></span>{{data.comments}}
                                        </td>
                                        <td class="textCenter"><span class="ui-column-title"></span>{{data.status}}
                                        </td>

                                        <td class="aligncenter"><span class="ui-column-title pointer"></span><a>
                                                <em class="fa fa-arrow-circle-right" aria-hidden="true"
                                                    (click)="sweetAlertForTaxTerm(data)"></em>
                                            </a></td>

                                    </tr>
                                </ng-template>
                            </p-table>
                            <div class="p-grid p-fluid">
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                                    <h3 class="norecorddata">Total records : {{totalRecords1}}</h3>
                                </div>
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                                    <p-paginator [rows]="10" [totalRecords]="totalRecords1"
                                        [rowsPerPageOptions]="[10,20,30]" (onPageChange)="paginate($event)">
                                    </p-paginator>
                                </div>
                            </div>
                        </div>

                        <p-card *ngIf="records1">
                            <div class="norecordImgsize">
                                <div *ngIf="records1"class="aligncenter">
                                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                                    <br><em>Sorry, we are not able to find any rate cards</em><br>
                                </div>
                            </div>
                        </p-card>
                    </p-card>
                </div>
            </p-tabPanel> -->
        </p-tabView>
    </p-panel>
</p-card>
<form [formGroup]="titleActionForm">
    <p-dialog class="dialogBox" header="Submit Rate card to Vendor" [(visible)]="display1" [modal]="true"
        [style]="{width: '40%'}" [baseZIndex]="1" [draggable]="false" [resizable]="false">
        <div class="p-fluid p-formgrid p-grid">

            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Job Category</label>
                <input id="input" formControlName="actionjobTitle" type="text" disabled=true pInputText
                    placeholder="Job category" autocomplete="off" maxlength="30">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Title</label>
                <input id="input" formControlName="actionTitle" type="text" disabled=true pInputText
                    placeholder="Job title" autocomplete="off" maxlength="30">
            </div>
        </div><br>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 container">
                <label class="inputfields">Min Rate</label><br>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                    <input formControlName="actionMin" autocomplete="off" type="text" pInputText maxlength=5
                        placeholder="Min rate" pKeyFilter="num">
                    <span class="p-inputgroup-addon">/Hrs</span>
                </div>
                <span class="validationtest"
                    *ngIf="this.titleActionForm.get('actionMin').invalid && this.titleActionForm.get('actionMin').errors.pattern">
                    Please enter numbers only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 container">
                <label class="inputfields">Max Rate <span class="mandatoryColor">*</span></label><br>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                    <input autocomplete="off" type="text" pInputText maxlength=5 placeholder="Max rate"
                        formControlName="actionMax" pKeyFilter="num">
                    <span class="p-inputgroup-addon">/Hrs</span>
                </div>
                <span class="validationtest"
                *ngIf="this.titleActionForm.get('actionMax').touched && this.titleActionForm.get('actionMax').invalid && this.titleActionForm.get('actionMax').errors.required">
                Please enter max rate
            </span>
            </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Comments</label>
                <input id="input" formControlName="actionDes" type="text" pInputText placeholder="Comments"
                    autocomplete="off" maxlength="200">
            </div>
        </div>
        <br>
        <div class="btnsCSS1">
            <button pButton type="button" icon='pi pi-times' label="Cancel" (click)="actioCancel()"
                class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-save' label="Save" class="addhBtn"
                (click)="actionSave()"></button>
        </div><br><br>
    </p-dialog>
</form>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>