import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Paginator } from 'primeng/paginator';
import * as XLSX from 'xlsx';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import { MessageService } from 'primeng/api';
import { isEmpty } from 'rxjs/operators';
@Component({
  selector: 'app-open-positions',
  templateUrl: './open-positions.component.html',
  styleUrls: ['./open-positions.component.scss'],
  providers: [DatePipe, MessageService],
})
export class OpenPositionsComponent implements OnInit {
  openPositionSearchForm: FormGroup;
  recruiterForm: FormGroup;
  @ViewChild('p', { static: false }) paginator: Paginator;
  page: any = 1;
  rows: any = 10;
  cols3 = [
    { dataKey: 'reqId', header: 'Job Id' },
    { dataKey: 'accName', header: 'Customer Name' },
    { dataKey: 'reqName', header: 'Position Title' },
    { dataKey: 'skills', header: 'Skills' },
    { dataKey: 'postedDate', header: 'Posted Date' },
    { dataKey: 'plannedEndDate', header: 'Target Date' },
    { dataKey: 'status', header: 'Status' },
    { dataKey: 'submissions', header: 'Submissions' },
  ];
  cols = [
    { field: 'reqId', header: 'Job Id', sno: 1 },
    { field: 'accName', header: 'Customer Name', sno: 2 },
    { field: 'reqName', header: 'Position Title', sno: 3 },
    { field: 'requiredSkills', header: 'Skills', sno: 4 },
    { field: 'requiredSkills', header: 'Skills', sno: 5 },
    { field: 'postedDate', header: 'Posted Date', sno: 6 },
    { field: 'plannedEndDate', header: 'Target Date', sno: 7 },
    { field: 'reqStatus', header: 'Status', sno: 8 },
    { field: 'submissions', header: 'Submissions', sno: 9 },
    { field: 'Action', header: 'Action', sno: 10 },
  ];
  assignstatusDD = [
    { code: 1, label: 'Assigned' },
    { code: 0, label: 'Not assigned' },
  ];
  loading: boolean;
  acctDetails: any;
  roleId: any;
  array: any;
  specializationSkillsData: any = [];
  statusDD: any = [];
  tablestatusdata: any;
  display: boolean;
  reqSkillsValue: any;
  errThrow2: boolean;
  errMsg2: string;
  errThrow: boolean;
  errMsg: string;
  skillSet: any[];
  loader: boolean;
  requirementsStartDate: string;
  requirementsEndDate: string;
  requirementsData: any;
  totalRecords: any;
  requirementsGridData: any;
  dbQuery: any;
  table: boolean;
  records = false;
  name: string;
  gridStatus: any;
  reqID: any;
  header: string;
  first: any;
  assignedList: any = [];
  pageCount: any;
  getStatusData = {};
  // tslint:disable-next-line: max-line-length
  items: ({ label: string; icon: string; disable: boolean; command: (event: any, i: any) => void; } | { label: string; icon: string; command: (event: any, i: any) => void; disable?: undefined; })[];
  downloadOptions: { label: string; icon: string; command: (event: any, i: any) => void; }[];
  rowData: any;
  gridData: any;
  rowId: any;
  dialogForCandidates: boolean;
  recruiterFormdata: any;
  alertError: boolean;
  TaxTermDailog: boolean;
  enableAssignedField: boolean;
  userDropMenuid: any;
  lengthComment: boolean;
  commentsLength: string;
  lengthDescription: boolean;
  descriptionlength: string;
  exportActive: boolean;
  products: any;
  fileName: any;
  accountnamesres: any = [];
  usrroleid: any;
  acctype: any;
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchData();
    }
  }
  // tslint:disable-next-line: max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private datepipe: DatePipe, private breadcrumbService: BreadcrumbService, private route: Router, private messageService: MessageService) {
    this.breadcrumbService.setItems([
      { label: 'Open Positions', routerLink: '/open-positions' },
    ]);
  }
  ngOnInit(): void {
    this.openPositionSearchForm = this.fb.group({
      requirementsJobId: [''],
      requirementsCustomerName: [''],
      requirementsJobTitle: [''],
      requirementsStatus: [''],
      requirementsassignStatus: [''],
      requirementsStartDate: [''],
      requirementsEndDate: [''],
    });
    this.recruiterForm = this.fb.group({
      recruiterControl: [''],
    });
    this.acctDetails = JSON.parse(this.apisService.decodeData());
    this.acctype = this.acctDetails.accType;
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
      this.roleId = this.acctDetails.roles[0].id;
    } else {
      this.roleId = this.usrroleid;
    }
    this.reqSkillSet();
    this.tablegridData();
    this.getStatus();
    this.accountNames();
  }
  accountNames() {
    this.apisService.getApi(environment.lookupsData + 'vendor/customers').subscribe((res: any) => {
      this.accountnamesres = res.data;
    });
  }
  tablegridData() {
    const data = '/' + this.page + '/' + this.rows + '?' + 'accId=' + this.acctDetails.accId + '&' + 'primaryRole=' + this.roleId + '&isAssigned=' + 1;
    this.loading = true;
    this.apisService.getApi(environment.openPositions + data).subscribe((res) => {
      this.gridData = res.data.records;
      this.loading = false;
      this.totalRecords = res.data.count;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  reqSkillSet() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.specializationSkillsData = res.data;
    });
  }
  getSkillSetTable(data) {
    this.array = data;
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.specializationSkillsData.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      if (data === null || data === undefined || data === '' || data === isEmpty()) { }
      else {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.array.length; j++) {
          if (Number(this.specializationSkillsData[i].code) === this.array[j]) {
            selectedPrePrimarySkills3.push(
              this.specializationSkillsData[i].label
            );
          } else {
          }
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  getStatus() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.status).subscribe((res) => {
      this.statusDD = res.data;
      this.statusDD.forEach(element => {
        this.getStatusData[element.code] = element.label;
      });
    });
  }
  reset($event) {
    this.loading = true;
    this.openPositionSearchForm.reset();
    this.tablegridData();
  }
  reqSkills1(data) {
    this.display = true;
    this.reqSkillsValue = data;
  }
  searchData() {
    // tslint:disable-next-line: max-line-length
    if (this.openPositionSearchForm.controls.requirementsStartDate.value === '' || this.openPositionSearchForm.controls.requirementsStartDate.value === null || this.openPositionSearchForm.controls.requirementsStartDate.value === undefined) {
      this.requirementsStartDate = '';
    } else {
      const startdate = this.openPositionSearchForm.controls.requirementsStartDate.value;
      const reqstartDate = new Date(startdate);
      this.requirementsStartDate = this.datepipe.transform(reqstartDate, 'MM-dd-yyyy');
    }
    // tslint:disable-next-line: max-line-length
    if (this.openPositionSearchForm.controls.requirementsEndDate.value === '' || this.openPositionSearchForm.controls.requirementsEndDate.value === null || this.openPositionSearchForm.controls.requirementsEndDate.value === undefined) {
      this.requirementsEndDate = '';
    }
    else {
      const enddate = this.openPositionSearchForm.controls.requirementsEndDate.value;
      const reqendDate = new Date(enddate);
      this.requirementsEndDate = this.datepipe.transform(reqendDate, 'MM-dd-yyyy');
    }
    let statusCheck = '';
    if (!this.openPositionSearchForm.controls.requirementsStatus.value) {
      statusCheck = '';
    } else {
      statusCheck =
        this.openPositionSearchForm.controls.requirementsStatus.value.code;
    }
    const custNmae = this.openPositionSearchForm.controls.requirementsCustomerName.value;
    const pgFlag = this.page;
    const pgNo = this.rows;
    // tslint:disable-next-line: max-line-length
    const reqId = this.openPositionSearchForm.controls.requirementsJobId.value === null ? '' : this.openPositionSearchForm.controls.requirementsJobId.value;
    // tslint:disable-next-line: max-line-length
    const jobTitle = this.openPositionSearchForm.controls.requirementsJobTitle.value === null ? '' : this.openPositionSearchForm.controls.requirementsJobTitle.value;
    // tslint:disable-next-line:max-line-length
    const custName = this.openPositionSearchForm.controls.requirementsCustomerName.value === null || this.openPositionSearchForm.controls.requirementsCustomerName.value === undefined || this.openPositionSearchForm.controls.requirementsCustomerName.value === '' ? '' : this.openPositionSearchForm.controls.requirementsCustomerName.value.name;
    const createBy = '';
    this.loader = true;
    // tslint:disable-next-line: max-line-length
    const assingId = this.openPositionSearchForm.controls.requirementsassignStatus.value === null ? 1 : this.openPositionSearchForm.controls.requirementsassignStatus.value === '' ? 1 : this.openPositionSearchForm.controls.requirementsassignStatus.value.code;
    // tslint:disable-next-line: max-line-length
    const data = '/' + pgFlag + '/' + pgNo + '?reqId=' + reqId + '&reqName=' + jobTitle + '&reqStatus=' + '' + '&startDate=' + this.requirementsStartDate + '&endDate=' + this.requirementsEndDate + '&createdBy=' + createBy + '&customerName=' + custName + '&primaryRole=' + this.roleId + '&isAssigned=' + assingId + '&recruiterId=' + this.acctDetails.userId;
    this.loading = true;
    this.apisService.getApi(environment.requirementGrid + data).subscribe((res) => {
      this.loading = false;
      this.gridData = res.data.records;
      this.totalRecords = res.data.count;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  // open positions edit
  requirementRoute(data) {
    this.name = 'requirementsSearch';
    this.gridStatus = data.reqStatus;
    this.reqID = data.reqId;
    // tslint:disable-next-line:no-string-literal
    data['from'] = 'open';
    // tslint:disable-next-line:no-string-literal
    data['name'] = 'requirementsSearch';
    sessionStorage.setItem('reqData', JSON.stringify(data));
    this.route.navigate(['/open-position-edit'], {
      queryParams: {
        submittedreqId: this.reqID,
        gStatus: this.gridStatus,
        name: this.name,
        reqId: data.reqId,
        tdate: data.plannedEndDate,
        from: 'open',
        rvId: data.rvId,
        viewed: data.isViewed,
        createdByAccId: data.createdByAccId
      },
    });
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.searchData();
  }
  toggle1(menu, event, rowData) {
    this.rowData = rowData;
    if (this.acctype === 'V') {
      this.items = [
        // tslint:disable-next-line:no-shadowed-variable
        // { label: 'Copy', icon: 'pi pi-fw pi-clone', disable: true, command: (event, i) => { this.addVendor(); } },
        // tslint:disable-next-line:no-shadowed-variable
        { label: 'Assign', icon: 'fa fa-check-circle-o', command: (event, i) => { this.sweetAlertForTaxTerm(); } },
      ];
    } else {
      this.items = [
        // tslint:disable-next-line:no-shadowed-variable
        { label: 'Copy', icon: 'pi pi-fw pi-clone', disable: true, command: (event, i) => { this.addVendor(); } },
        // tslint:disable-next-line:no-shadowed-variable
        { label: 'Assign', icon: 'fa fa-check-circle-o', command: (event, i) => { this.sweetAlertForTaxTerm(); } },
      ];
    }

  }
  downLoadsToggle() {
    this.downloadOptions = [
      { label: 'Download XLSX', icon: 'pi pi-download', command: (event, i) => { this.exportExcel(); } },
      { label: 'Download PDF', icon: 'pi pi-download', command: (event, i) => { this.exportPdf(); } },
    ];
  }
  addVendor() {
    const data = this.rowData;
    sessionStorage.setItem('reqData', JSON.stringify(this.rowData));
    const addroute = true;
    this.route.navigate(['/addvendorrequirement'], {
      queryParams: {
        requirementId: true,
        reqId: data.reqId,
        from: 'open',
      },
    });
  }
  getAssignedList(event, type) {
    type === 'menu' ? (this.dialogForCandidates = true) : (this.dialogForCandidates = false);
    this.recruiterForm.reset();
    if (event.value.code === 1) {
      this.enableAssignedField = true;
      this.apisService.getApi(environment.updateStatus + this.acctDetails.accId).subscribe((res) => {
        this.assignedList = res.data;
      });
    }
  }
  sweetAlertForTaxTerm() {
    const data = this.rowData;
    this.rowId = this.rowData.reqId;
    if (this.dialogForCandidates === true) {
      this.recruiterFormdata = this.recruiterForm.controls.recruiterControl.value;
      if (this.recruiterFormdata === undefined || this.recruiterFormdata === null || this.recruiterFormdata === '') {
        this.alertError = true;
        setTimeout(() => {
          this.alertError = false;
        }, 3000);
        return;
      }
    }
    if (data.consName !== null) {
      // tslint:disable-next-line: max-line-length
      Swal.fire({ text: 'Requirement already assigned', icon: 'success', confirmButtonColor: '#0D416B', iconColor: '#1dbb99', confirmButtonText: 'Ok', reverseButtons: false });
    } else {
      this.rowId = data.reqId;
      this.TaxTermDailog = false;
      // tslint:disable-next-line: max-line-length
      Swal.fire({
        text: 'Are you sure want to assign?', icon: 'warning', showCancelButton: true, confirmButtonColor: '#0D416B', cancelButtonColor: '#D0D0D0', confirmButtonText: 'Yes', cancelButtonText: 'No', reverseButtons: false,
      }).then((result) => {
        if (result.value) {
          return this.upadteStatus();
        }
      });
    }
  }
  sweetAlertForTaxTerm1(data) {
    this.rowId = this.rowData.reqId;
    this.userDropMenuid = data.rowId;
    this.TaxTermDailog = false;
    // tslint:disable-next-line: max-line-length
    Swal.fire({
      text: 'Are you sure want to assign?', icon: 'warning', showCancelButton: true, confirmButtonColor: '#0D416B', cancelButtonColor: '#D0D0D0', confirmButtonText: 'Yes', cancelButtonText: 'No', reverseButtons: false,
    }).then((result) => {
      if (result.value) {
        return this.upadteStatus();
      }
    });
  }
  upadteStatus() {
    const idValue =
      this.apisService.getApi(environment.rmUpdateStatus + this.rowId + '/' + this.acctDetails.userId).subscribe((res) => {
        if (res.hasOwnProperty('success') === true) {
          this.tablegridData();
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
          this.gridData();
          this.dialogForCandidates = false;
        } else if (res.hasOwnProperty('success') === false) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
          this.dialogForCandidates = false;
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
          this.dialogForCandidates = false;
        }
      });
  }
  exportExcel() {
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    // tslint:disable-next-line:max-line-length
    ws['!cols'] = [ {width : 10}, {width : 20}  , {width : 30} , {width : 30}, {width : 20}, {width : 20}, {width : 20}, {width : 20}, {width : 20}, {hidden : true} ];
    ws['!rows'] = [{hpt : 30}, {hpt : 30}, ];
    ws['!cols'][3] = { hidden: true };
    ws['!cols'][9] = { hidden: true  };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ExcelSheet.xlsx' );
  }

  exportPdf() {
    this.gridData.forEach((element) => {
      element.status = this.getStatusData[element.reqStatus];
      element.skills = this.getSkillSetTable(element.requiredSkills);
    });
    const doc = new jsPDF('p', 'pt');
    autoTable(doc, {
      headStyles: { fillColor: '#2bb9d5', cellPadding: 5   },
      margin: { left: 17},
      columns: this.cols3,
      body: this.gridData,
      didDrawPage: (dataArg) => {
        doc.text('Open Positions', dataArg.settings.margin.left, 30);
      }
    });
    doc.save('Open Positions.pdf');
  }
}
