import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, } from '@angular/core';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { ActivatedRoute, Router, } from '@angular/router';
import { MessageService } from 'primeng/api';
import { isEmpty } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-recruting-manager-requirements',
  templateUrl: './recruting-manager-requirements.component.html',
  styleUrls: ['./recruting-manager-requirements.component.scss'],
  providers: [MessageService, DatePipe],
})
export class RecrutingManagerRequirementsComponent implements OnInit {
  rowData: any;
  items: { label: string; icon: string; command: () => void; }[];
  filedata: any;
  ownerID: any;
  disable: boolean;
  btnDisable: boolean;
  displayModal: boolean;
  date: Date;
  histroy: any;
  histroy1: any;
  usrroleid: any;
  // tslint:disable-next-line: max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private messageService: MessageService, private datepipe: DatePipe, private route: Router, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Requirements', routerLink: '/requirements' },
    ]);
  }
  requirementsForm: FormGroup;
  approveOrRejectForm: FormGroup;
  iconToggle: any = [];
  rows: any = 10;
  page: any = 1;
  loader: boolean;
  griddata: any;
  griddCount: any;
  primaryRole: any;
  acctDetails: any;
  roleId: any;
  statusDD: any[];
  statuTable = {};
  specializationSkillsData: any = [];
  array: any;
  rejectForComments: boolean;
  approveOrRejectResponse: any;
  approveOrReject: boolean;
  display: boolean;
  reqSkillsValue: any[];
  name: string;
  gridStatus: any;
  reqID: any;
  header: string;
  pageCount: any;
  first: any;
  records: boolean;
  table: boolean;
  errThrow2: boolean;
  errMsg2: string;
  errThrow: boolean;
  errMsg: string;
  requirementsStartDate: string;
  skillSet: any[];
  requirementsEndDate: string;
  dbQuery = '';
  requirementsGridData: any;
  cols = [
    { field: 'reqId', header: 'Job Id', width: '7%' },
    { field: 'reqName', header: 'Position Title', width: '9%' },
    { field: 'noOfPositions', header: 'Positions', width: '5%' },
    { field: 'requiredSkills', header: 'Skills', width: '11%' },
    { field: 'postedDate', header: 'Posted Date', width: '10%' },
    { field: 'reqStatus', header: 'Status', width: '7%' },
    { field: 'createdByName', header: 'Created By', width: '10%' },
    { field: '', header: 'Action', width: '5%' }, // role id = 13
  ];
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchData();
    }
  }
  ngOnInit() {
    this.primaryRole = JSON.parse(
      sessionStorage.getItem('loginData')
    ).primaryrole;
    this.acctDetails = JSON.parse(this.apisService.decodeData());
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
      this.roleId = this.acctDetails.roles[0].id;
    } else {
      this.roleId = this.usrroleid;
    }
    this.requirementsForm = this.fb.group({
      requirementsJobId: [''],
      requirementsJobTitle: [''],
      requirementsStatus: [''],
      requirementsStartDate: [''],
      requirementsEndDate: [''],
    });
    this.approveOrRejectForm = this.fb.group({
      approveOrReject: [''],
      comments: ['', Validators.required],
    });
    this.getStatus();
    this.getGridData();
    this.reqSkillSet();
  }
  toggle(event, data, rowData) {
    this.rowData = data;
    if (this.rowData.reqStatus === 'P') {
      this.items = [
        { label: 'Approve/Reject', icon: 'pi pi-arrow-circle-right', command: () => { this.approveOrRejectRequirement(); }, },
        { label: 'Requirement Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); }, },
      ];
    } else if (this.rowData.reqStatus === 'C') {
      this.items = [
        { label: 'Requirement Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); }, },
      ];
    } else if (this.rowData.reqStatus === 'R') {
      this.items = [
        {
          label: 'Subcontractors', icon: 'fa fa-users', command: () => {
            this.route.navigate(['/subcontractor-details'],
            {
              queryParams: {
                subcontractor: 'subcontractor',
                reqId: this.rowData.reqId,
                role: 'vendorManager'
              }
            });
          }
        },
        { label: 'Requirement Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); }, },
      ];
    } else if (this.rowData.reqStatus === 'J') {
      this.items = [
        { label: 'Requirement Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); }, },
      ];
    } else {
      this.items = [
        { label: 'Requirement Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); }, },
      ];
    }

  }
  downloadFileapi() {
    this.approveOrReject = true;
  }
  getStatus() {
    this.apisService.getApi(environment.status).subscribe((res) => {
      this.statusDD = res.data;
      this.statusDD.forEach((element) => {
        this.statuTable[element.code] = element.label;
      });
    });
  }
  getToolTipContent(tootipdata) {
    return tootipdata;
  }
  getGridData() {
    this.loader = true;
    const data = '?' + 'primaryRole=' + this.roleId;
    const page = this.page;
    const rows = this.rows;
    this.loader = true;
    this.apisService.getApi(environment.vedorReq + page + '/' + rows + data).subscribe((res) => {
      this.griddata = res.data.records;
      this.griddCount = res.data.count;
      if (this.griddCount !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.loader = false;
    });
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.searchData();
    this.getGridData();
  }
  reqSkillSet() {
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.specializationSkillsData = res.data;
    });
  }
  getSkillSetTable(data) {
    this.array = data;
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.specializationSkillsData.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      if (data === null || data === undefined || data === '' || data === isEmpty()) { } else {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.array.length; j++) {
          if (Number(this.specializationSkillsData[i].code) === this.array[j]) {
            selectedPrePrimarySkills3.push(
              this.specializationSkillsData[i].label
            );
          } else {
          }
        }
      }
    }
    return selectedPrePrimarySkills3;
  } // It is used for the displaying the particular row of the skillset data shows in the dialogbox based on the grid
  reqSkills1(data) {
    this.display = true;
    this.reqSkillsValue = data;
  }
  // It is used for Approve/Reject Requirement Details dialog box, if you select reject then comments field while be enable
  rejectRequirement() {
    this.rejectForComments = true;
    this.approveOrRejectForm.get('comments').enable();
  }
  // It is used for Approve/Reject Requirement Details dialog box, if you select approve then comments field while be disable
  approveRequirement() {
    this.approveOrRejectForm.get('comments').reset();
    this.rejectForComments = false;
    this.approveOrRejectForm.get('comments').disable();
  }
  submitForApproveOrReject() {
    if (this.approveOrRejectForm.invalid) {
      this.approveOrRejectForm.get('comments').markAsTouched();
    } else {
      this.approveOrRejectYes();
    }
  }
  approveOrRejectRequirement() {
    this.rejectForComments = false;
    this.filedata = this.rowData;
    if (this.filedata.reqStatus === 'P') {
      this.approveOrReject = true;
      this.approveOrRejectForm.get('comments').disable();
      this.approveOrRejectForm.get('approveOrReject').patchValue('Approved');
    }
    else {
      Swal.fire({
        text: 'Request already sent',
        icon: 'warning',
        confirmButtonColor: '#0D416B',
        confirmButtonText: 'Ok'
      });
    }
  }
  cancel() {
    this.approveOrReject = false;
    this.rejectForComments = false;
    this.approveOrRejectForm.reset();
  }
  approveOrRejectYes() {
    const submitReq = {
      id: this.filedata.reqId,
      comment: this.approveOrRejectForm.get('comments').value === null ? '' : this.approveOrRejectForm.get('comments').value,
      reqStatus: this.approveOrRejectForm.controls.approveOrReject.value === 'Approved' ? 'A' : 'J',
    };
    this.disable = true;
    this.apisService.putApi(environment.reqApprove, submitReq).subscribe((res) => {
      this.approveOrRejectResponse = res;
      if (this.approveOrRejectResponse.hasOwnProperty('success') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.approveOrRejectResponse.message });
        this.disable = false;
        this.approveOrReject = false;
        this.getGridData();
      } else if (
        this.approveOrRejectResponse.hasOwnProperty('success') === false
      ) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.approveOrRejectResponse.message });
        this.disable = false;
        this.approveOrReject = false;
      } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.approveOrRejectResponse.error });
        this.disable = false;
        this.approveOrReject = false;
      }
    });
  }
  requirementRoute(data) {
    this.name = 'requirementsSearch';
    this.gridStatus = data.reqStatus;
    this.reqID = data.reqId;
    sessionStorage.setItem('reqData', JSON.stringify(data));
    this.route.navigate(['/requirementedit'], {
      queryParams: {
        ownerId: data.ownerId,
        submittedreqId: this.reqID,
        gStatus: this.gridStatus,
        name: this.name,
        reqId: data.reqId,
        parentreqId: data.parentReqId,
        createdbyaccId: data.createdByAccId,
        from:
          this.header === 'Open Positions'
            ? 'open'
            : (this.header = 'recruiter-req' ? 'recruiter' : 'req'),
      },
    });
  }
  // It is used for end date validation
  endDateValidation() {
    if (new Date(this.requirementsForm.get('requirementsStartDate').value).getTime() <
      new Date(this.requirementsForm.get('requirementsEndDate').value).getTime()) {
    } else {
      this.requirementsForm.get('requirementsEndDate').reset();
      const sowStartDate = this.requirementsForm.get('requirementsStartDate').value;
    }
  }
  search() {
    const fromdate = this.requirementsForm.controls.requirementsStartDate.value;
    const todate = this.requirementsForm.controls.requirementsEndDate.value;
    if (
      (fromdate === '' || fromdate === null || fromdate === undefined) &&
      (todate === '' || todate === null || todate === undefined)
    ) {
      this.searchData();
    } else if (
      !(fromdate === '' || fromdate === null || fromdate === undefined)
    ) {
      if (todate === '' || todate === null || todate === undefined) {
        this.errThrow2 = true;
        this.errMsg2 = 'Please select end date';
        setTimeout(() => {
          this.errMsg2 = '';
        }, 5000);
      } else {
        this.searchData();
      }
    } else if (!(todate === '' || todate === null || todate === undefined)) {
      if (fromdate === '' || fromdate === null || fromdate === undefined) {
        this.errThrow = true;
        this.errMsg = 'Please select start date';
        setTimeout(() => {
          this.errMsg = '';
        }, 5000);
      } else {
        this.searchData();
      }
    } else {
      this.searchData();
    }
  }
  // It is used for the searching the the data based on the griddata
  searchData() {
    // It is used for the converting date format
    if (
      this.requirementsForm.controls.requirementsStartDate.value === '' ||
      this.requirementsForm.controls.requirementsStartDate.value === null ||
      this.requirementsForm.controls.requirementsStartDate.value === undefined
    ) {
      this.requirementsStartDate = '';
    } else {
      const startdate =
        this.requirementsForm.controls.requirementsStartDate.value;
      const reqstartDate = new Date(startdate);
      this.requirementsStartDate = this.datepipe.transform(
        reqstartDate,
        'MM-dd-yyyy'
      );
    }
    if (
      this.requirementsForm.controls.requirementsEndDate.value === '' ||
      this.requirementsForm.controls.requirementsEndDate.value === null ||
      this.requirementsForm.controls.requirementsEndDate.value === undefined
    ) {
      this.requirementsEndDate = '';
    } else {
      const enddate = this.requirementsForm.controls.requirementsEndDate.value;
      const reqendDate = new Date(enddate);
      this.requirementsEndDate = this.datepipe.transform(
        reqendDate,
        'MM-dd-yyyy'
      );
    }
    let statusCheck = '';
    if (!this.requirementsForm.controls.requirementsStatus.value) {
      statusCheck = '';
    } else {
      statusCheck =
        this.requirementsForm.controls.requirementsStatus.value.code;
    }

    const pgFlag = this.page;
    const pgNo = this.rows;
    // tslint:disable-next-line:max-line-length
    const reqId = this.requirementsForm.controls.requirementsJobId.value === null ? '' : this.requirementsForm.controls.requirementsJobId.value;
    // tslint:disable-next-line:max-line-length
    const jobTitle = this.requirementsForm.controls.requirementsJobTitle.value === '' || this.requirementsForm.controls.requirementsJobTitle.value === null || this.requirementsForm.controls.requirementsJobTitle.value === undefined ? '' : this.requirementsForm.controls.requirementsJobTitle.value;
    const createBy = '';
    // tslint:disable-next-line:max-line-length
    const data = pgFlag + '/' + pgNo + '?primaryRole=' + this.roleId + '&reqId=' + reqId + '&createdBy=' + createBy + '&reqName=' + jobTitle + '&startDate=' + this.requirementsStartDate + '&endDate=' + this.requirementsEndDate + '&reqStatus=' + statusCheck;
    this.loader = true;
    this.apisService.getApi(environment.requirmentRecruiter + data).subscribe((res) => {
      this.griddata = res.data.records;
      this.griddCount = res.data.count;
      this.loader = false;
      this.requirementsGridData = this.griddata.loginUserRequirementsList;
      this.dbQuery = this.griddata.reqDownloadQueryString;
      if (this.griddCount !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  reset() {
    this.requirementsForm.reset();
    this.getGridData();
  }
  profilehistroy() {
    this.displayModal = true;
    this.date = new Date();
    const actId = JSON.parse(this.apisService.decodeData());
    this.apisService.getApi(environment.getreqActivity + this.rowData.reqId).subscribe((res: any) => {
      this.histroy = res.data;
      this.histroy1 = this.rowData.reqId;
    });
  }
}
