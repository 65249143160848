import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { isEmpty } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss'],
  providers: [DatePipe, MessageService],
})
export class AddCustomerComponent implements OnInit {
  // tslint:disable-next-line:no-inferrable-types
  table: boolean = true;
  table1: boolean;
  accountDetailsForm: FormGroup;
  bankdetailsForm: FormGroup;
  teamsForm: FormGroup;
  taskViewForm: FormGroup;
  records: boolean;
  countryOptions: any = [];
  stateoptions: any = [];
  accountDetails: any;
  status: any;
  stateOptions: any;
  rateCard: boolean;
  teamInfo: boolean;
  businessType: any;
  regionOptions: any;
  industryOptions: any;
  loader: boolean;
  acctName: any;
  addID: any;
  acctType: any;
  accIDD: any;
  page: any = 1;
  rows: any = 10;
  custID: any;
  rateGridData: any;
  totalRecords: any;
  statuTable: any = {};
  first: any;
  pageCount: any;
  customerId: any;
  searchData: any;
  orgIdValue: any;
  custName: any;
  userIdValue: any;
  acctDetails: any;
  teamData: any;
  totalrecords: any;
  disableButtons: boolean;
  addDataResponse: any;
  rateId: any;
  displayModal: boolean;
  customerID: any;
  errValidation: any;
  dynamicHeaderNameChange: any;
  updatePosititionCategory: boolean;
  addPosititionCategory: boolean;
  displayAddButton: boolean;
  displayUpdateButton: boolean;
  disable: boolean;
  addRateCardForm: any;
  updateResponse: any;
  actId: any;
  currencysymbl: any;
  currencycode: any[];
  preferencList: any = [];
  currencyValue: any;
  standardmarkup: FormGroup;
  maxsubmissions: any;
  markup: any;
  countrycodedropdown: any = [];
  bankdetails: any;
  acctid: any;
  percentage: any;
  grid: any;
  role: any;
  detailsLength: any;
  // tslint:disable-next-line:max-line-length
  constructor(private route: ActivatedRoute, private router: Router, private messageService: MessageService, private formBuilder: FormBuilder, private breadcrumbService: BreadcrumbService, private apisService: ApisService) {
    this.breadcrumbService.setItems([
      { label: 'Customers', routerLink: '/customer' },
      { label: 'Customer Details', routerLink: '/customerdetails' },
    ]);
  }
  cols1 = [
    { field: 'name', header: 'Name', width: '8%' },
    { field: 'email', header: 'Email', width: '7%' },
    { field: 'roleList', header: 'Role ', width: '7%' },
    { field: 'officePhone', header: 'Phone', width: '5%' },
    { field: 'status', header: 'Status', width: '4%' },
  ];
  // Table header
  cols = [
    { field: 'jobcategory', header: 'Position Category', width: '10%' },
    { field: 'JobTitle', header: 'Job Title', width: '10%' },
    { field: 'minRate', header: 'Min Rate', width: '10%' },
    { field: 'maxRate', header: 'Max Rate ', width: '10%' },
    { field: 'comments', header: 'Comments', width: '10%' },
    { field: 'status', header: 'Status', width: '10%' },
    { field: 'action', header: 'Action', width: '5%' },
  ];
  ngOnInit(): void {
    this.actId = JSON.parse(this.apisService.decodeData());
    this.acctid = this.actId.accId;
    this.preferencList = this.actId.preferencesList;
    // tslint:disable-next-line:align
    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }
    this.accountDetailsForm = this.formBuilder.group({
      accountName: [''],
      accountURL: [''],
      address1: ['', Validators.required],
      address2: [''],
      mailbox: [''],
      country: [''],
      state: [''],
      city: [''],
      zip: [''],
      phone: [''],
      phDrp: [''],
      fax: [''],
    });
    this.bankdetailsForm = this.formBuilder.group({
      bankAccountName: [''],
      bankAccountNumber: [''],
      ifsccode: [''],
      beneficiaryno: [''],
      beneficiaryname: [''],
      address: [''],
      city: [''],
      country: [''],
      state: [''],
      zip: [''],
    });
    this.teamsForm = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      emailid: [''],
      phone: [''],
      status: [''],
    });
    this.addRateCardForm = this.formBuilder.group({
      jobcategory: [''],
      jobTitle: [''],
      minRate: ['', Validators.required],
      maxRate: ['', Validators.required],
      comments: [''],
    });
    this.taskViewForm = this.formBuilder.group({
      rateCardJobTitle: [''],
      rateCardJobCategory: [''],
      minRate: [''],
      maxRate: [''],
    });
    this.standardmarkup = this.formBuilder.group({
      markup: ['', [Validators.maxLength(3), Validators.pattern('^[0-9.]+$')]],
      submissions: ['']
    });

    const routeParams: any = this.route.snapshot.queryParamMap;
    this.customerID = this.route.snapshot.queryParamMap.get('customerID');
    this.custName = this.route.snapshot.queryParamMap.get('custName');
    this.countryDropdown();
    this.statusDD();
    this.getdrop();
    this.accountDetailsBindData();
    // this.getTeamData();
    // this.getdrop();
    // this.getGirdData();
  }
  resetteaminfo() {
    this.teamsForm.reset();
    this.getGirdData();

  }
  getroles(){
    const rolevalue = sessionStorage.getItem('Act_type');
    const getroles = rolevalue === 'B' ? 'V' : rolevalue;
    this.apisService.getApi(environment.roleDD + getroles).subscribe((res: any) => {
      this.role = res.data;
    });
  }
  getGirdData() {
    const firstName = this.teamsForm.controls.firstName.value === null ? '' : this.teamsForm.controls.firstName.value;
    const lastName = this.teamsForm.controls.lastName.value === null ? '' : this.teamsForm.controls.lastName.value;
    const phone = this.teamsForm.controls.phone.value === null ? '' : this.teamsForm.controls.phone.value;
    const email = this.teamsForm.controls.emailid.value === null ? '' : this.teamsForm.controls.emailid.value;
    // tslint:disable-next-line:max-line-length
    const status = this.teamsForm.controls.status.value === null || this.teamsForm.controls.status.value === '' || this.teamsForm.controls.status.value === undefined ? '' : this.teamsForm.controls.status.value.code;
    // tslint:disable-next-line:max-line-length
    const data = +this.customerID + '?fName=' + firstName + '&lName=' + lastName + '&email=' + email + '&phone=' + phone + '&status=' + status;
    const pageNo = this.page;
    const pageCount = this.rows;
    const orgId = pageNo + '/' + pageCount;
    this.loader = true;
    this.apisService.getApi(environment.getTabledata + orgId + '/' + data).subscribe((res: any) => {
      this.grid = res.data;
      this.loader = false;
      this.totalrecords = res.count;
      if (this.totalrecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  getrole(data) {
    for (let i = 0; this.role.length < 0; i++) {
      for (let j = 0; data.length < 0; j++) {
        if (this.role[i] === data[j]) {
        }
      }
    }
  }
  emailToast() {
    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
  }
  copyInputMessage(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
  roletable(data) {
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.role.length; i++) {
      // tslint:disable-next-line: prefer-for-of
      // tslint:disable-next-line:prefer-for-of
      if (data === null || data === undefined || data === '' || data === isEmpty()) { } else {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < data.length; j++) {
          if (this.role[i].name === data[j].name) {
            selectedPrePrimarySkills3.push(this.role[i].name);
          } else {
          }
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  // Country code onchange method for further enhancement
  countryids(event) {
  }
  // country codes dropdown along with flags
  getdrop() {
    this.countrycodedropdown = this.apisService.getdropdwon();
  }
  // Back routing to previous screen
  goto() {
    this.router.navigate(['/customer']);
  }
  // reset method
  reset() {
    this.taskViewForm.reset();
    this.records = false;
  }
  // coutry dropdown method
  countryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'ADLC').subscribe((res) => {
      this.countryOptions = res.data;
    });
  }
  // state dropdown method based on country codes
  stateDropdown(code) {
    if (code === null || code === '' || code === undefined) {
      this.stateoptions = [];
    }
    else {
      // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.state + '/' + code).subscribe((res) => {
        this.stateoptions = res.data;
        this.accountDetailsForm.get('state').patchValue(this.stateoptions.filter((x) => x.code === this.accountDetails[0].state)[0]);
      });
    }
  }
  // state dropdown method based on country codes
  getStateByUrl(code) {
    if (code === null || code === '' || code === undefined) {
      this.stateOptions = [];
    }
    else { // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.state + '/' + code).subscribe((res) => {
        this.stateOptions = res.data;
        this.bankdetailsForm.get('state').patchValue(this.stateOptions.filter((x) => x.code === this.bankdetails[0].bankLkState)[0]);
      });
    }
  }
  // status dropdown
  statusDD() {
    this.apisService.getApi(environment.lookupsData + 'ULS').subscribe((res: any) => {
      this.status = res.data;
      this.status.forEach((element) => {
        this.statuTable[element.code] = element.label;
      });
    });
  }
  // Tab change megthod to call API whenever they are required
  onTabChange(event) {
    if (event.index === 0) {
      this.accountDetailsBindData();
      this.countryDropdown();
      this.getdrop();
    }
    else if (event.index === 1) {
      this.bankingdetails();
    }
    else if (event.index === 2) {
      this.getGirdData();
      this.getroles();
    }
    else if (event.index === 3) {
      this.patchmarkup();
    }
    else {
      // this.rateCardGridData();
    }
  }
  // Bank details API
  bankingdetails() {
    this.loader = true;
    this.apisService.getApi(environment.accountbankdetails + '/' + this.customerID + '/' + this.acctid).subscribe((res: any) => {
      this.bankdetails = res.data;
      this.detailsLength = res.data.length;
      this.loader = false;
      this.bankdetailsForm.get('country').patchValue(this.countryOptions.filter((x) => x.code === this.bankdetails[0].bankLkCountry)[0]);
      this.getStateByUrl(this.bankdetails[0].bankLkCountry);
      this.bankdetailsForm.get('bankAccountName').patchValue(this.bankdetails[0].accName);
      this.bankdetailsForm.get('bankAccountNumber').patchValue(this.bankdetails[0].swiftCode);
      this.bankdetailsForm.get('ifsccode').patchValue(this.bankdetails[0].ifscCode);
      this.bankdetailsForm.get('beneficiaryno').patchValue(this.bankdetails[0].bankAccountNo);
      this.bankdetailsForm.get('beneficiaryname').patchValue(this.bankdetails[0].bankBeneficiaryName);
      this.bankdetailsForm.get('address').patchValue(this.bankdetails[0].bankAddress);
      this.bankdetailsForm.get('city').patchValue(this.bankdetails[0].bankCity);
      this.bankdetailsForm.get('zip').patchValue(this.bankdetails[0].bankZip);
    });
  }
  // Method to bind customer account details
  accountDetailsBindData() {
    this.loader = true;
    this.apisService.getApi(environment.Accountget + this.customerID).subscribe((res: any) => {
      this.accountDetails = res.data;
      this.acctName = res.data[0].accName;
      this.addID = res.data[0].addId;
      this.acctType = res.data[0].accType;
      sessionStorage.setItem('Act_type', this.acctType);
      this.accIDD = res.data[0].accId;
      this.loader = false;
      const mailID = this.accountDetails[0].mailExtention;
      sessionStorage.setItem('mailExtn', JSON.stringify(mailID));
      this.accountDetailsForm.get('country').patchValue(this.countryOptions.filter((x) => x.code === this.accountDetails[0].country)[0]);
      this.stateDropdown(this.accountDetails[0].country);
      this.accountDetailsForm.get('accountName').patchValue(this.accountDetails[0].accName);
      this.accountDetailsForm.get('accountURL').patchValue(this.accountDetails[0].website);
      this.accountDetailsForm.get('address1').patchValue(this.accountDetails[0].address1);
      this.accountDetailsForm.get('address2').patchValue(this.accountDetails[0].address2);
      this.accountDetailsForm.get('mailbox').patchValue(this.accountDetails[0].mailbox);
      this.accountDetailsForm.get('city').patchValue(this.accountDetails[0].city);
      this.accountDetailsForm.get('zip').patchValue(this.accountDetails[0].zip);
      this.accountDetailsForm.get('phone').patchValue(this.accountDetails[0].phone);
      this.accountDetailsForm.get('fax').patchValue(this.accountDetails[0].fax);
      // tslint:disable-next-line:max-line-length
      this.accountDetailsForm.controls.phDrp.patchValue(this.countrycodedropdown.filter((x) => x.id === this.accountDetails[0].mobileCcode)[0]);
    });
  }
  // method to get rate card grid
  rateCardGridData() {
    this.loader = true;
    const pageNo = this.page;
    const pageCount = this.rows;
    const data = pageNo + '/' + pageCount;
    const actId = JSON.parse(this.apisService.decodeData());
    this.apisService.getApi(environment.ratecards + this.customerID + '/' + actId.accId).subscribe((res) => {
      this.rateGridData = res.data;
      this.totalRecords = this.rateGridData.length;
      this.loader = false;
    });
    this.accstatusDD();
  }
  // Edit method on rate card tab
  editData(data) {
    this.errValidation = '';
    this.displayModal = true;
    this.dynamicHeaderNameChange = 'Update Position Category';
    this.addPosititionCategory = false;
    this.updatePosititionCategory = true;
    this.displayAddButton = false;
    this.displayUpdateButton = true;
    this.disable = true;
    this.rateId = data.id;
    this.addRateCardForm.get('jobcategory').patchValue(data.jobcategory);
    this.addRateCardForm.get('jobTitle').patchValue(data.jobTitle);
    this.addRateCardForm.get('minRate').patchValue(data.minRate);
    this.addRateCardForm.get('maxRate').patchValue(data.maxRate);
    this.addRateCardForm.get('comments').patchValue(data.comments);
  }
  // Update method for ratecard
  updateRateCardData() {
    this.disable = true;
    const updateDataReq = {
      description: this.addRateCardForm.get('comments').value,
      id: this.rateId,
      jobCategory: this.addRateCardForm.get('jobcategory').value,
      jobTitle: this.addRateCardForm.get('jobTitle').value,
      maxRate: this.addRateCardForm.get('maxRate').value,
      minRate: this.addRateCardForm.get('minRate').value,
      comments: this.addRateCardForm.get('comments').value,
    };
    if (this.addRateCardForm.invalid) {
      const controls = this.addRateCardForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      this.disableButtons = true;
      this.apisService.putApi(environment.positionUpdate, updateDataReq).subscribe((res) => {
        this.updateResponse = res;
        this.disableButtons = false;
        if (this.updateResponse.hasOwnProperty('success') === true) {
          this.displayModal = false;
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateResponse.message });
          // this.rateCardGridData();
        } else if (this.updateResponse.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateResponse.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateResponse.error });
        }
      });
    }
  }
  // team tab view status dropdown
  accstatusDD() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.actstatus).subscribe((res: any) => {
      this.status = res.data;
    });
  }
  // pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.getGirdData();
    this.searchRateCardData();
  }
  // Rate card search method
  searchRateCardData() {
    const actId = JSON.parse(this.apisService.decodeData());
    this.loader = true;
    const minRate = this.taskViewForm.get('minRate').value === null ? '' : this.taskViewForm.get('minRate').value;
    const maxRate = this.taskViewForm.get('maxRate').value === null ? '' : this.taskViewForm.get('maxRate').value;
    const jobTitle = this.taskViewForm.get('rateCardJobTitle').value === null ? '' : this.taskViewForm.get('rateCardJobTitle').value;
    // tslint:disable-next-line:max-line-length
    const jobCategory = this.taskViewForm.get('rateCardJobCategory').value === null ? '' : this.taskViewForm.get('rateCardJobCategory').value;
    const data = this.customerID + '/' + actId.accId + '?minRate=' + minRate + '&maxRate=' + maxRate + '&jobTitle=' + jobTitle + '&jobCategory=' + jobCategory;
    this.apisService.getApi(environment.ratecards + data).subscribe((res) => {
      this.searchData = res.data;
      this.loader = false;
      this.rateGridData = this.searchData;
      this.totalRecords = this.rateGridData.length;
      this.taskViewForm.reset();
    });
  }
  // Team tab view grid method
  getTeamData() {
    const actId = JSON.parse(this.apisService.decodeData());
    this.loader = true;
    this.apisService.getApi(environment.getTeamsData + 1 + '/' + 10 + '/' + actId.accId).subscribe((res: any) => {
      this.teamData = res.data;
      this.totalrecords = res.data.length;
      this.loader = false;
      this.totalrecords = this.teamData.length;
      if (this.totalrecords !== 0) {
        this.records = false;
      } else {
        this.records = true;
      }
    });
  }
  // Method to add rate card
  addRateCardData() {
    if (this.taskViewForm.invalid) {
      const controls = this.taskViewForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      const addDataReq = {
        description: this.taskViewForm.get('comments').value,
        id: this.rateId,
        jobCategory: this.taskViewForm.get('jobcategory').value,
        jobTitle: this.taskViewForm.get('jobTitle').value,
        maxRate: this.taskViewForm.get('maxRate').value,
        minRate: this.taskViewForm.get('minRate').value,
        comments: this.taskViewForm.get('comments').value,
      };
      this.disableButtons = true;
      this.apisService.postApi(environment.positionUpdate, addDataReq).subscribe((res) => {
        this.addDataResponse = res;
        this.disableButtons = false;
        if (this.addDataResponse.hasOwnProperty('success') === true) {
          this.displayModal = false;
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.addDataResponse.message });
          // this.rateCardGridData();
        } else if (this.addDataResponse.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.addDataResponse.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.addDataResponse.error });
        }
      });
    }
  }
  // Method to show currency per hour based on country
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
  // method to update markup percentage in util tab view
  updateStandardmarkup() {
    this.disable = true;
    this.loader = true;
    // tslint:disable-next-line: radix
    this.percentage = parseInt(this.standardmarkup.get('markup').value);
    const reqbody = {
      accId: this.accIDD,
      venId: this.actId.accId,
      percentage: this.percentage
    };
    // tslint:disable-next-line: deprecation
    this.apisService.putApi(environment.markup, reqbody).subscribe((res) => {
      this.standardmarkup.reset();
      this.loader = false;
      if (res.hasOwnProperty('success') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        this.patchmarkup();
      } else if (res.hasOwnProperty('failed') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
      } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
      }
      this.disable = false;
    });
  }
  // Method to patch markup
  patchmarkup() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.patchmarkup + this.accIDD + '/' + this.actId.accId).subscribe((res: any) => {
      this.maxsubmissions = res.data[0].maxSubmission;
      this.markup = res.data[0].markupPercentage;
      this.standardmarkup.get('markup').patchValue(this.markup);
      this.standardmarkup.get('submissions').patchValue(this.maxsubmissions);
    });
  }
}
