import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-reg-error',
  templateUrl: './reg-error.component.html',
  styleUrls: ['./reg-error.component.scss'],
  providers: [DatePipe, MessageService],
})
export class RegErrorComponent implements OnInit {

  mail = 'sb@miraclesoft.com';
  name: any;
  name2: any;
  mailid: any;
  name1: any;
  constructor(private router: Router, private route: ActivatedRoute) {
    this.name = this.route.snapshot.paramMap.get('id');
    this.name2 =   this.name ;
  }
  ngOnInit() {
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.name1 = routeParams.params.reqname;
    this.mailid = routeParams.params.mailid;
  }
  goto() {
    this.router.navigate(['/login']);
  }
}
