import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { isEmpty } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-customer-contacts',
  templateUrl: './customer-contacts.component.html',
  styleUrls: ['./customer-contacts.component.scss'],
  providers: [DatePipe, MessageService],
})
export class CustomerContactsComponent implements OnInit {
  items: MenuItem[];
  dropDownValue = [];
  accTID: any;
  first: any;
  loginData: any;
  roleId: any;
  accid: any;
  salesAccId: any;
  salescustAccId: any;
  salescustaccname: any;
  custName: boolean;
  from: string;
  usrroleid: any;
  accountactivityId: string;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private router: Router, private messageService: MessageService, private route: ActivatedRoute, private breadcrumbService: BreadcrumbService) {
    this.accTID = JSON.parse(sessionStorage.getItem('acct_rowid'));
    this.salesAccId = JSON.parse(sessionStorage.getItem('salesAccId'));
    this.loginData = JSON.parse(this.apisService.decodeData());
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.accountactivityId = this.route.snapshot.paramMap.get('id');
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === ''){
      this.roleId = this.loginData.roles[0].id;
    }else{
      this.roleId = this.usrroleid;
    }
    if (this.roleId === 14) {
      this.custName = true;
    }else{
      this.custName = false;
    }
    if (this.roleId === 14) {
      this.breadcrumbService.setItems([
        { label: 'Customers', routerLink: '/customers' },
        { label: 'Contacts', routerLink: '/contacts' },
      ]);
    }
    else if (this.roleId === 3) {
      this.breadcrumbService.setItems([
        { label: 'Account Details', routerLink: '/account-details' },
        { label: 'Contacts', routerLink: '/contacts' },
      ]);
    }
    else {
      this.breadcrumbService.setItems([
        { label: 'Account Details', routerLink: '/accountdetails' },
        { label: 'Contacts', routerLink: '/contacts' },
      ]);
    }
  }
  ContactsForm: FormGroup;
  grid: any;
  statuTable = {};
  status: any = [];
  accountid: any;
  userID: string;
  saveReg: any;
  displayActive = false;
  loginActiveData: string;
  email: any;
  registerdStatus = false;
  orgIdVal: any;
  iconToggle = [];
  loader: boolean;
  rowData: any;
  disable: boolean;
  totalrecords: any;
  isUploading = false;
  showUploadView = false;
  rows: any = 10;
  page: any = 1;
  pageCount: any;
  // It is used for displaying table header data
  cols = [
    { field: 'name', header: 'Account Name', width: '10%' },
    { field: 'email', header: 'Email', width: '10%' },
    { field: 'roleList', header: 'Role ', width: '8%' },
    { field: 'officePhone', header: ' Office Phone', width: '5%' },
    { field: 'status', header: 'Status', width: '4%' },
    { field: '', header: 'Action', width: '2%' },
  ];
  email1: any;
  rowID: any;
  rowid: string;
  role: any = [];
  table = true;
  records: boolean;
  accountId = this.route.snapshot.params.id;
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchData();
    }
  }
  // Drag Over Listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drag Leave Listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drop Listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.isUploading = true;
      this.onUpload(files);
    }
  }
  ngOnInit() {
    this.salescustAccId = JSON.parse(sessionStorage.getItem('salescustAccId'));
    this.accountId = JSON.parse(this.apisService.decodeData());
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.accid = routeParams.params.accid;
    this.from = this.route.snapshot.paramMap.get('From');
    if ( this.from === 'customer'){
      this.salescustaccname = this.route.snapshot.paramMap.get('queryParams');
    }
    else if (this.from === 'customerEdit'){
      this.salescustaccname = this.route.snapshot.paramMap.get('queryParams');
    }
    else{
      this.salescustaccname = routeParams.params.salescustomeraacname;
    }
    this.dropDownValue = [
      { label: 'Account Details', routerLink: ['/accountdetails', this.accountId] },
      { label: 'Contacts', routerLink: ['/contacts', this.accountId.accId] },
      { label: 'Attachments', routerLink: ['/attachments', this.accountId.accId] },
      { label: 'Locations', routerLink: ['/locations', this.accountId.accId] },
    ];
    const rolevalue = sessionStorage.getItem('Act_type');
    const roleId = this.accountId.roles[0].id === 14 ? 'C' : rolevalue;
    const getroles = rolevalue === 'B' ? 'V' : roleId;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.roleDD + getroles).subscribe((res: any) => {
      this.role = res.data;
    });
    this.ContactsForm = this.fb.group({
      FirstName: [''],
      LastName: [''],
      Emailid: [''],
      Phone: [''],
      Status: [''],
    });
    // tslint:disable-next-line:max-line-length
    if (JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 2 || JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 8) {
      this.accountid = JSON.parse(sessionStorage.getItem('loginData')).orgId;
    } else {
      this.accountid = this.accountId;
    }
    this.getGirdData();
    this.statusDD();
  }
  copyInputMessage(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
  // It is Used to upload files
  onUpload(files) {
    this.isUploading = true;
    const formData: FormData = new FormData();
    formData.append('file', files[0]);
    // tslint:disable-next-line: deprecation
    this.apisService.postApi(environment.fileuploadContacts, formData).subscribe((res: any) => {
      if (res.success === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Inserted Successfully.' });
        this.isUploading = false;
        this.showUploadView = false;
      } else {
        setTimeout(() => {
          this.isUploading = false;
        }, 2000);
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Something went wrong, please try again.', });
      }
    });
  }
  // It is used for the displaying the GridData
  getGirdData() {
    this.orgIdVal = sessionStorage.getItem('orgId');
    this.loader = true;
    this.rowid = sessionStorage.getItem('acct_rowid');
    const id = this.roleId === 1 ? this.rowid : this.accountId.accId;
    const rowID = this.roleId === 14 ? this.salescustAccId : id;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.getTabledata + this.page + '/' + this.rows + '/' + rowID).subscribe((res: any) => {
      this.grid = res.data;
      this.loader = false;
      this.totalrecords = res.count;
      if (this.totalrecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  getrole(data) {
    for (let i = 0; this.role.length < 0; i++) {
      for (let j = 0; data.length < 0; j++) {
        if (this.role[i] === data[j]) {
        }
      }
    }
  }
  statusDD() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.actstatus).subscribe((res: any) => {
      this.status = res.data;
      this.status.forEach((element) => {
        this.statuTable[element.code] = element.label;
      });
    });
  }
  // It is used for the searching the the data based on the griddata
  searchData() {
    const firstName = this.ContactsForm.controls.FirstName.value === null ? '' : this.ContactsForm.controls.FirstName.value;
    const lastName = this.ContactsForm.controls.LastName.value === null ? '' : this.ContactsForm.controls.LastName.value;
    const phone = this.ContactsForm.controls.Phone.value === null ? '' : this.ContactsForm.controls.Phone.value;
    const email = this.ContactsForm.controls.Emailid.value === null ? '' : this.ContactsForm.controls.Emailid.value;
    // tslint:disable-next-line:max-line-length
    const status = this.ContactsForm.controls.Status.value === null || this.ContactsForm.controls.Status.value === '' || this.ContactsForm.controls.Status.value === undefined ? '' : this.ContactsForm.controls.Status.value.code;
    this.loader = true;
    this.rowid = sessionStorage.getItem('acct_rowid');
    const id = this.roleId === 1 ? this.rowid : this.accountId.accId;
    const rowID = this.roleId === 14 ? this.salescustAccId : id;
    // tslint:disable-next-line: max-line-length
    const data = this.page + '/' + this.rows + '/' + rowID + '?fName=' + firstName + '&lName=' + lastName + '&email=' + email + '&phone=' + phone + '&status=' + status;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.getTabledata + data).subscribe((res) => {
      this.grid = res.data;
      this.totalrecords = res.count;
      if (this.totalrecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.loader = false;
    });
  }
  // It is used for navigate the add contact
  AddData() {
    this.router.navigate(['/contact-add'], {
      queryParams: {
        salescustomeraacname: this.salescustaccname
      }
    });
  }
  // It is used for navigate the edit contact
  edit(data) {
    this.router.navigate(['/contact-edit', data.id], { queryParams: {
      salescustomeraacname: this.salescustaccname,
      getdata: this.accountactivityId
    }});
  } basedOnLoginStatus(data) {
    this.rowID = data.id;
    this.rowData = data;
    this.displayActive = true;
    if (data.status === 'A') {
      this.displayActive = false;
      this.loginActiveData = 'User contact is already activated please check email-';
      this.registerdStatus = false;
      this.email1 = data.email;
      Swal.fire({
        title: 'Success!',
        text: this.loginActiveData + this.email1,
        icon: 'success',
        confirmButtonColor: '#0D416B',
        iconColor: '#1dbb99',
        confirmButtonText: 'Ok',
      });
    } else if (data.status === 'I') {
      this.loginActiveData = 'User contact is inactive';
      this.registerdStatus = false;
      this.email1 = data.email;
    } else if (data.status === 'R') {
      this.loginActiveData = 'Do you want to send login credentials to email?';
      this.registerdStatus = true;
      this.email1 = data.email;
    }
  }
  saveUserContacts() {
    this.userID = sessionStorage.getItem('userId');
    const contactId = this.rowID;
    this.disable = true;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.loginSend + contactId).subscribe((res) => {
      this.saveReg = res;
      this.disable = false;
      if (this.saveReg.hasOwnProperty('success') === true) {
        this.displayActive = false;
        this.getGirdData();
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.saveReg.message, });
      } else if (this.saveReg.hasOwnProperty('failed') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.saveReg.failed, });
      } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.saveReg.error });
      }
    }, (err) => {
      this.disable = false;
    }
    );
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.getGirdData();
  }
  // It is used for close the Contact Login Credentials dialog box
  cancel() {
    this.displayActive = false;
  }
  // It is used for the reset the form values
  resetData() {
    this.getGirdData();
    this.ContactsForm.reset();
  }
  roletable(data) {
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.role.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      if (data === null || data === undefined || data === '' || data === isEmpty()) { } else {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < data.length; j++) {
          if (this.role[i].name === data[j].name) {
            selectedPrePrimarySkills3.push(this.role[i].name);
          } else {
          }
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  toggle(event, data, rowData) {
    this.rowData = rowData;
    this.items = [
      { label: 'Edit', icon: 'pi pi-pencil', command: () => { this.edit(data); }, },
      { label: 'Activate', icon: 'pi pi-check-circle', command: () => { this.basedOnLoginStatus(data); } },
    ];
  }
  emailToast() {
    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
  }
  goToLink(url: string) {
    const addressLink = url.includes('http') ? url : `http://${url}`;
    window.open(addressLink, '_blank');
  }
}
