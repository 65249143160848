<p-toast position="bottom-right" key="br"></p-toast>
<nav class="navbar navbar-expand-lg navbar-dark sticky-top nav">
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
        <a class="navbar-brand">
            <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Servicesbay Logo SVG.svg" class="innerimg">
        </a>
        <button class="pi pi-arrow-left pointer addhBtn" id="backToStyle" pButton type="button" style="width: auto;
      top: 14px;" label="&nbsp;Back to Login" (click)="goto()"></button>
    </div>
</nav><br>
<div *ngIf="display">
    <div class="p-grid">
        <p-card class="hello">
            <div class="p-grid p-justify-center">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <div><br>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                                <h4><label class="inputfields">Hi &nbsp;<strong><a>Admin</a></strong></label>
                                </h4>
                                <h5><strong>Do you want to Remove this Account Permanently?</strong></h5>
                            </div>
                        </div><br>
                        <div class="p-grid p-fluid">
                            <div class="p-col-5 p-lg-5">
                                <label class="inputfields">Account Name</label>
                            </div>
                            <div class="p-col-7 p-lg-7">
                                <strong>{{userAccName}}</strong>
                            </div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-5 p-lg-5">
                                <label class="inputfields">Account Type</label>
                            </div>
                            <div class="p-col-7 p-lg-7">
                                <strong>{{lkUserType}}</strong>
                            </div>
                        </div>

                        <div class="p-grid ">
                            <div class="p-col-5 p-lg-6 p-fluid">
                                <label class="inputfields verifypswd">Email</label>
                            </div>
                            <div class="p-col-5 p-lg-6 verifypaswd descrip" tooltipPosition="right"
                                pTooltip="{{userEmail}}">
                                <strong>{{userEmail}}</strong>
                            </div>
                        </div><br>
                        <div class="p-grid p-justify-center">
                            <div class="p-col-5 p-fluid"></div>
                            <button pButton type="button" class="btnsty" label="Remove Account"
                                (click)="removeAccount()" icon="pi pi-trash" [disabled]='removebtn'></button>
                        </div>
                    </div>
                </div>
            </div>
        </p-card>
    </div>
</div>
<div *ngIf="!display">
    <div class="p-grid1">
        <p-card class="hello1">
            <br><br>
            <h3 class="aligncenter">
                <label>Token expired !</label><br><br>
                <em class="pi pi-times-circle fntsize"></em>
                <br>
            </h3>
            <br>
            <div class="p-grid p-justify-center">
                <span class="pointer" (click)="goto()">Please generate new token.&nbsp;<a>Login Here</a></span>
            </div>
        </p-card>
    </div>
</div>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>