import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApisService } from 'src/app/services/apis.service';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { isEmpty } from 'rxjs/operators';


@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
  providers: [DatePipe, MessageService],
})
export class TeamComponent implements OnInit {
  teamsForm: FormGroup;
  cols = [
    { field: 'name', header: 'Name', width: '20%' },
    { field: 'email', header: 'Email', width: '20%' },
    { field: 'roleList', header: 'Role ', width: '20%' },
    { field: 'officePhone', header: 'Phone', width: '20%' },
    { field: 'status', header: 'Status', width: '10%' },
  ];
  status: any = [
    { label: 'Active', value: 'Active' },
    { label: 'In Active', value: 'In Active' },
  ];
  statuTable = {};
  acctDetails: any;
  grid: any;
  totalRecords: number;
  records: boolean;
  loader: boolean;
  accId: any;
  page: any = 1;
  rows: any = 10;
  first: any;
  pageCount: any;
  items: any[];
  totalrecords: any;
  table: boolean;
  rowid: string;
  btndisable: boolean;
  roles: any = [];
  rolesData = {};
  role: any;
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.getTeamData();
    }
  }
  // tslint:disable-next-line: max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private messageService: MessageService, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Team', routerLink: '/Team' },
    ]);
  }
  ngOnInit(): void {
    const userdetails = JSON.parse(this.apisService.decodeData());
    const rolevalue = userdetails.accType;
    const getroles = rolevalue === 'B' ? 'V' : rolevalue;
    this.apisService.getApi(environment.roleDD + getroles).subscribe((res: any) => {
      this.role = res.data;
    });
    this.accId = userdetails.accId;
    this.teamsForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      emailid: [''],
      status: [''],
      phone: [''],
    });
    this.statusDD();
    this.getTeamData();

  }
  statusDD() {
    this.apisService.getApi(environment.lookupsData + 'ULS').subscribe((res: any) => {
      this.status = res.data;
      this.status.forEach((element) => {
        this.statuTable[element.code] = element.label;
      });
    });
  }
  // It is used to show action pop up
  toggle(event, data, rowData) {
    this.items = [

    ];
  }
  emailToast() {
    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
  }
  getrole(data) {
    for (let i = 0; this.role.length < 0; i++) {
      for (let j = 0; data.length < 0; j++) {
        if (this.role[i] === data[j]) {
        }
      }
    }
  }
  getTeamData() {
    const pageNo = this.page;
    const pageCount = this.rows;
    const orgId = pageNo + '/' + pageCount + '/' + this.accId;
    const firstName = this.teamsForm.controls.firstName.value === null ? '' : this.teamsForm.controls.firstName.value;
    const lastName = this.teamsForm.controls.lastName.value === null ? '' : this.teamsForm.controls.lastName.value;
    const phone = this.teamsForm.controls.phone.value === null ? '' : this.teamsForm.controls.phone.value;
    const email = this.teamsForm.controls.emailid.value === null ? '' : this.teamsForm.controls.emailid.value;
    // tslint:disable-next-line:max-line-length
    const status = this.teamsForm.controls.status.value === null || this.teamsForm.controls.status.value === '' || this.teamsForm.controls.status.value === undefined ? '' : this.teamsForm.controls.status.value.code;
    const data = orgId + '?fName=' + firstName + '&lName=' + lastName + '&email=' + email + '&phone=' + phone + '&status=' + status;
    this.loader = true;
    this.apisService.getApi(environment.getTeamsData + data).subscribe((res: any) => {
      this.grid = res.data;
      this.loader = false;
      this.totalRecords = res.count;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      }
      else {
        this.table = false;
        this.records = true;
      }
    });
  }
  roletable(data) {
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.role.length; i++) {
      // tslint:disable-next-line: prefer-for-of
      // tslint:disable-next-line:prefer-for-of
      if (data === null || data === undefined || data === '' || data === isEmpty()) { } else {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < data.length; j++) {
          if (this.role[i].name === data[j].name) {
            selectedPrePrimarySkills3.push(this.role[i].name);
          } else {
          }
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.getTeamData();
  }
  reset() {
    this.teamsForm.reset();
    this.records = false;
    this.getTeamData();
  }
  // It is used to copy an email from grid data to clipboard
  copyInputMessage(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
}
