import { DatePipe } from '@angular/common';
import { HostListener } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-vendor-locations',
  templateUrl: './vendor-locations.component.html',
  styleUrls: ['./vendor-locations.component.scss'],
  providers: [DatePipe, MessageService],
})
export class VendorLocationsComponent implements OnInit {
  accTID: any;
  roles: any;
  uploadedFiles: any[] = [];
  showUploadView = false;
  isUploading = false;
  vendorLocationForm: FormGroup;
  displayModal: boolean;
  vendorLocationPopUpForm: FormGroup;
  getData: any;
  type: any;
  country: any;
  grid: any;
  gridTableData: any;
  editlocation: boolean;
  vendorEditLocationPopUpForm: FormGroup;
  editData: any;
  accountid: any;
  iconToggle = [];
  hide: boolean;
  loader: boolean;
  locationdata: any;
  locationDatadisplay: boolean;
  type2: any[];
  statuTable = {};
  contryTable = {};
  totalrecords: any;
  actId: any;
  locarowId: any;
  stateData: any[];
  rowid: string;
  rowID: any;
  totalRecords: number;
  records = true;
  table: boolean;
  stateData1: any;
  totalRecords1: any;
  fileOver: boolean;
  status2 = [
    { code: 'A', label: 'Active' },
    { code: 'I', label: 'Inactive' },
  ];
  cols = [
    { field: 'name', header: 'Location Name', width: '5%' },
    { field: 'address1', header: 'Address', width: '6%' },
    { field: 'city', header: 'City', width: '5%' },
    { field: 'stateName', header: 'State ', width: '4%' },
    { field: 'country', header: 'Country', width: '4%' },
    { field: 'status', header: 'Status', width: '3%' },
    { field: '', header: 'Action', width: '3%' },
  ];
  items: { label: string; icon: string; command: () => void; }[];
  rowData: any;
  status1: any[];
  x: any;
  usid: any;
  empflag: any;
  qId: any;
  countrycodedropdown: any = [];
  rows: any = 10;
  page: any = 1;
  first: any;
  pageCount: any;
  displayNumber: boolean;
  phSpinnerHide: boolean;
  mobinum: any;
  mobinummsg: string;
  phCheckHide: boolean;
  editphSpinnerHide: boolean;
  mobinum1: any;
  mobinummsg1: string;
  editphCheckHide: boolean;
  items1: { label: string; icon: string; command: () => void; }[];
  downloadData: any;
  locid: any;
  usrroleid: any;
  // tslint:disable-next-line: max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private messageService: MessageService, private breadcrumbService: BreadcrumbService, private route: ActivatedRoute
  ) {
    this.usid = JSON.parse(apisService.decodeData()).userId;
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.empflag = routeParams.params.empflag;
    this.accTID = JSON.parse(sessionStorage.getItem('acct_rowid'));
    this.actId = JSON.parse(this.apisService.decodeData());
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === ''){
      this.roles = this.actId.roles[0].id;
    }else{
      this.roles = this.usrroleid;
    }
    if (
      JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 2 ||
      JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 8 ||
      JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 13
    ) {
      this.accountid = JSON.parse(sessionStorage.getItem('loginData')).orgId;
    } else if (this.roles === 4) {
      this.accountid = Number(sessionStorage.getItem('account_id'));
      this.actId = JSON.parse(this.apisService.decodeData());
      this.breadcrumbService.setItems([{ label: 'Locations', routerLink: '#' }, ]);
    } else if (this.roles === 1) {
      this.breadcrumbService.setItems([
        { label: 'Accounts', routerLink: '/accounts' },
        { label: 'Account Details', routerLink: ['/admin-menu/account-details', this.accTID] },
        { label: 'Locations', routerLink: '#' },
      ]);
    }
    else {
      if (
        JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 2 ||
        JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 8 ||
        JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 13
      ) {
        this.accountid = JSON.parse(sessionStorage.getItem('loginData')).orgId;
      } else if (this.roles === 4) {
        this.accountid = Number(sessionStorage.getItem('account_id'));
        this.actId = JSON.parse(this.apisService.decodeData());
        this.breadcrumbService.setItems([
          {
            label: 'Locations',
            routerLink: '#'
          },
        ]);
      } else if (this.roles === 1) {
        this.breadcrumbService.setItems([
          { label: 'Account Details', routerLink: ['/admin-menu/account-details', this.accTID] },

          {
            label: 'Location',
            routerLink: '#'
          },
        ]);
      }
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.Searchlocation();
    }
  }
  ngOnInit() {
    this.items1 = [
      {
        label: 'Download Sample', icon: 'pi pi-download', command: () => {
          this.downloadFileapi();
        }
      },
      {
        label: 'Import Data', icon: 'pi pi-chevron-down', command: () => {
          this.toggleUploadView();

        }
      }];
    this.actId = JSON.parse(this.apisService.decodeData());
    this.rowid = sessionStorage.getItem('acct_rowid');
    this.rowID = this.actId.roles[0].id === 1 ? this.rowid : this.actId.accId;
    this.vendorLocationForm = this.fb.group({
      Name: [''],
      City: [''],
      Phone: [''],
      Country: [''],
      State: [''],
      Status: [''],
    });
    this.vendorLocationPopUpForm = this.fb.group({
      NameControl: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(20)], ],
      addressControl: ['', [Validators.required, Validators.maxLength(50)]],
      addressFormControl: ['', Validators.maxLength(50)],
      Country: ['', Validators.required],
      State: ['', Validators.required],
      cityForm: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z0-9-()+]*$'), Validators.maxLength(20)], ],
      Phone: [''],
      zipForm: ['', [Validators.maxLength(10),Validators.pattern('^[0-9-]*$')]],
      faxForm: [''],
      mailbox1: ['', Validators.maxLength(10)],
      phoneDrp: ['']
    });
    this.vendorEditLocationPopUpForm = this.fb.group({
      NameControl: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(20)]],
      addressControl: ['', Validators.maxLength(50)],
      addressFormControl: ['', Validators.maxLength(50)],
      mailbox2: ['', Validators.maxLength(10)],
      Country: ['', Validators.required],
      State: ['', Validators.required],
      cityForm: ['', [Validators.required, Validators.maxLength(20)]],
      Phone: [''],
      zipForm: ['', [Validators.maxLength(10),Validators.pattern('^[0-9-]*$')]],
      faxForm: [''],
      StatusForm: [''],
      editPhoneDrp: ['']
    });
    this.getCountryDropdown();
    this.getstatusDD();
    this.Searchlocation();
    this.getdrop();
  }
  // It is used to get country dropdown values
  getCountryDropdown() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.preCountryDropdown).subscribe((res) => {
      this.country = res.data;
      this.country.forEach((element) => {
        this.contryTable[element.code] = element.label;
      });
    });
  }
  // It is used to get states dropdown values based on country codes
  getStateByUrl(code) {
    if (code === null || code === '' || code === undefined) {
      this.stateData = [];
    }
    else {   // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.states + code).subscribe((res) => {
        this.stateData = res.data;
        this.vendorEditLocationPopUpForm.get('State').patchValue(this.stateData.filter((x) => x.code === this.editData.state)[0]);
      });
    }
  }
  // It is used to get phone number country codes
  getdrop() {
    this.countrycodedropdown = this.apisService.getdropdwon();
  }
  // It used to get status dropwdown values
  getstatusDD() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'ULS').subscribe((res: any) => {
      this.type2 = res.data;
      this.type2.forEach((element) => {
        this.statuTable[element.code] = element.label;
      });
    });
  }
  // It is used to upload a file
  onUpload(files) {
    this.isUploading = true;
    const formData: FormData = new FormData();
    formData.append('file', files[0]);
    this.apisService.postApi(environment.fileuploadWorkLocation, formData).subscribe((res: any) => {
      if (res.success === true) {
        this.messageService.add({
          key: 'br',
          severity: '',
          life: 4000,
          detail: 'Inserted Successfully.',
        });
        this.Searchlocation();
        this.isUploading = false;
        this.showUploadView = false;
      } else {
        setTimeout(() => {
          this.isUploading = false;
        }, 2000);
        this.messageService.add({
          key: 'br',
          severity: '',
          life: 4000,
          detail: res.message,
        });
        this.Searchlocation();
      }
    });
  }
  toggleUploadView() {
    this.showUploadView = !this.showUploadView;
  }
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drag Leave Listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drop Listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.isUploading = true;
      this.onUpload(files);
    }
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.Searchlocation();
  }
  // It is used for search functionality
  Searchlocation() {
    const locationName = this.vendorLocationForm.controls.Name.value === null ? '' : this.vendorLocationForm.controls.Name.value;
    const city = this.vendorLocationForm.controls.City.value === null ? '' : this.vendorLocationForm.controls.City.value;
    // tslint:disable-next-line:max-line-length
    const country = this.vendorLocationForm.controls.Country.value === ('' || null) ? '' : this.vendorLocationForm.controls.Country.value.code === undefined ? '' : this.vendorLocationForm.controls.Country.value.code;
    // tslint:disable-next-line:max-line-length
    const status = this.vendorLocationForm.controls.Status.value === null || this.vendorLocationForm.controls.Status.value === '' || this.vendorLocationForm.controls.Status.value.code === undefined ? '' : this.vendorLocationForm.controls.Status.value.code;
    // tslint:disable-next-line:max-line-length
    const state = this.vendorLocationForm.controls.State.value === '' || this.vendorLocationForm.controls.State.value === null || this.vendorLocationForm.controls.State.value.code === undefined ? '' : this.vendorLocationForm.controls.State.value.code;
    this.loader = true;
    // tslint:disable-next-line:max-line-length
    const searchdata = this.page + '/' + this.rows + '/' + this.rowID + '?name=' + locationName + '&city=' + city + '&country=' + country + '&state=' + state + '&status=' + status;
    this.loader = true;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.getLocations + searchdata).subscribe((res) => {
      this.loader = false;
      this.gridTableData = res.data;
      this.gridTableData.forEach((element) => {
        const countryID = element.country;
        // tslint:disable-next-line:no-shadowed-variable
        this.apisService.getApi(environment.states + countryID).subscribe((res) => {
          this.stateData1 = res.data;
          element.stateName = this.stateData1.filter(
            (x) => x.code === element.state
          )[0].label;
        });
      });
      this.totalRecords = res.count;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
      this.vendorLocationForm.controls.State.value.code = '';
    });
  }
  // It is used to add a location
  vendorLocSave() {
    this.hide = true;
    if (this.vendorLocationPopUpForm.invalid) {
      const controls = this.vendorLocationPopUpForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    } else {
      const data = {
        locationFlag: 'Add',
        accId: this.rowID,
        address1: this.vendorLocationPopUpForm.controls.addressControl.value,
        address2: this.vendorLocationPopUpForm.controls.addressFormControl.value,
        city: this.vendorLocationPopUpForm.controls.cityForm.value,
        // tslint:disable-next-line:max-line-length
        country: this.vendorLocationPopUpForm.controls.Country.value === null || this.vendorLocationPopUpForm.controls.Country.value === undefined || this.vendorLocationPopUpForm.controls.Country.value.code === '' ? 'USA' : this.vendorLocationPopUpForm.controls.Country.value.code,
        district: '',
        mailBox: this.vendorLocationPopUpForm.controls.mailbox1.value,
        name: this.vendorLocationPopUpForm.controls.NameControl.value,
        objectType: 'A',
        // tslint:disable-next-line:max-line-length
        state: this.vendorLocationPopUpForm.controls.State.value === null || this.vendorLocationPopUpForm.controls.State.value === undefined || this.vendorLocationPopUpForm.controls.State.value.code === '' ? '' : this.vendorLocationPopUpForm.controls.State.value.code,
        zip: this.vendorLocationPopUpForm.controls.zipForm.value,
        phone: this.vendorLocationPopUpForm.controls.Phone.value,
        // tslint:disable-next-line:max-line-length
        mccode: this.vendorLocationPopUpForm.controls.phoneDrp.value === null || this.vendorLocationPopUpForm.controls.phoneDrp.value === '' || this.vendorLocationPopUpForm.controls.phoneDrp.value === undefined || this.vendorLocationPopUpForm.controls.phoneDrp.value === '' ? '' : this.vendorLocationPopUpForm.controls.phoneDrp.value.id.toString()
      };
      // tslint:disable-next-line: deprecation
      this.apisService.postApi(environment.locationEdit, data).subscribe((res) => {
        this.getData = res;
        this.hide = false;
        this.displayModal = false;
        this.Searchlocation();
        if (this.getData.hasOwnProperty('success') === true) {
          this.messageService.add({  key: 'br', severity: '', life: 4000,  detail: this.getData.message  });
          this.Searchlocation();
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({  key: 'br',  severity: '', life: 4000, detail: this.getData.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 4000, detail: this.getData.error });
        }
      });
    }
  }
  // It is used to get location details and to bind
  edit(data) {
    this.locid = data.locId;
    this.status1 = data.status === 'R' ? this.type2 : this.status2;
    this.locarowId = data.locId;
    this.editData = data;
    this.editlocation = true;
    this.vendorEditLocationPopUpForm.reset();
    this.vendorEditLocationPopUpForm.controls.mailbox2.patchValue(data.mailbox);
    this.vendorEditLocationPopUpForm.controls.NameControl.patchValue(data.name);
    this.vendorEditLocationPopUpForm.controls.addressControl.patchValue(data.address1);
    this.vendorEditLocationPopUpForm.controls.addressFormControl.patchValue(data.address2);
    this.vendorEditLocationPopUpForm.controls.cityForm.patchValue(data.city);
    this.vendorEditLocationPopUpForm.controls.Phone.patchValue(data.phone);
    this.vendorEditLocationPopUpForm.controls.zipForm.patchValue(data.zip);
    this.vendorEditLocationPopUpForm.controls.faxForm.patchValue(data.accFax);
    this.vendorEditLocationPopUpForm.controls.StatusForm.patchValue(this.status1.filter((x) => x.code === data.status)[0]);
    this.vendorEditLocationPopUpForm.controls.Country.patchValue(this.country.filter((x) => x.code === data.country)[0]);
    this.getStateByUrl(data.country);
    this.vendorEditLocationPopUpForm.controls.editPhoneDrp.patchValue(this.countrycodedropdown.filter((x) => x.id === data.mobileCcode)[0]);

  }
  // It is used to update location
  vendorLocUpdate() {
    this.hide = true;
    if (this.vendorEditLocationPopUpForm.invalid) {
      const controls = this.vendorEditLocationPopUpForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    } else {
      const data = {
        locationFlag: 'Edit',
        id: this.locarowId,
        accId: this.actId.accId,
        address1: this.vendorEditLocationPopUpForm.controls.addressControl.value,
        address2: this.vendorEditLocationPopUpForm.controls.addressFormControl.value,
        city: this.vendorEditLocationPopUpForm.controls.cityForm.value,
        country: this.vendorEditLocationPopUpForm.controls.Country.value.code,
        district: '',
        mailBox: this.vendorEditLocationPopUpForm.controls.mailbox2.value,
        name: this.vendorEditLocationPopUpForm.controls.NameControl.value,
        objectType: 'A',
        state: this.vendorEditLocationPopUpForm.controls.State.value.code,
        zip: this.vendorEditLocationPopUpForm.controls.zipForm.value,
        phone: this.vendorEditLocationPopUpForm.controls.Phone.value,
        status: this.vendorEditLocationPopUpForm.controls.StatusForm.value.code,
        // tslint:disable-next-line:max-line-length
        mccode: this.vendorEditLocationPopUpForm.controls.editPhoneDrp.value === null || this.vendorEditLocationPopUpForm.controls.editPhoneDrp.value === '' || this.vendorEditLocationPopUpForm.controls.editPhoneDrp.value === undefined || this.vendorEditLocationPopUpForm.controls.editPhoneDrp.value === '' ? '' : this.vendorEditLocationPopUpForm.controls.editPhoneDrp.value.id

      };
      // tslint:disable-next-line: deprecation
      this.apisService.postApi(environment.locationEdit, data).subscribe((res: any) => {
        this.hide = false;
        this.getData = res;
        if (this.getData.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 4000, detail: this.getData.message  });
          this.Searchlocation();
          this.editlocation = false;
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({  key: 'br', severity: '', life: 4000, detail: this.getData.failed  });
        } else {
          this.messageService.add({  key: 'br', severity: '', life: 4000, detail: this.getData.error  });
        }
      });
    }
  }
  // It is used to show add location pop up
  addlocation() {
    this.displayModal = true;
    this.vendorLocationPopUpForm.reset();
    this.vendorLocationPopUpForm.controls.Country.patchValue(this.country.filter((x) => x.code === 'USA')[0]);
    this.getStateByUrl('USA');
    this.vendorLocationPopUpForm.controls.phoneDrp.patchValue({
      code: '+1',
      flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png',
      id: '2',
      label: 'UNITED STATES',
    });
  }
  // It is used to reset add location pop up
  vendorLocReset() {
    this.vendorLocationPopUpForm.reset();
    this.vendorLocationPopUpForm.controls.Country.patchValue(this.country.filter((x) => x.code === 'USA')[0]);
    this.getStateByUrl('USA');
    this.vendorLocationPopUpForm.controls.phoneDrp.patchValue({
      code: '+1',
      flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png',
      id: '2',
      label: 'UNITED STATES',
    });
  }
  // used to reset the form controls and grid data
  resetData() {
    this.vendorLocationForm.reset();
    this.Searchlocation();
  }
  // It is used to for location name validation
  locatioVerification() {
    this.locationDatadisplay = false;
    const location = this.vendorLocationPopUpForm.controls.NameControl.value;
    const data = this.accountid + '/' + location;
  }
  // It is used to show action pop up
  toggle(event, data, rowData) {
    this.rowData = rowData;
    this.items = [
      { label: 'Edit', icon: 'pi pi-pencil', command: () => { this.edit(data); }, },
      { label: 'View on map', icon: 'pi pi-map-marker', command: () => { this.goToLink(data); } },
    ];
  }
  // It is used to navigate to google maps
  goToLink(url) {
    const webdata = 'https://www.google.com/maps/search/' + url.name + ',' + url.city;
    const addressLink = `${webdata}`;
    window.open(addressLink, '_blank');
  }
  getPhon() {
    this.displayNumber = false;
    const num = this.vendorLocationPopUpForm.controls.Phone.value;
    if (num === '' || num === null || num === undefined || num === '(___) ___-____') {
    }
    else {
      this.phSpinnerHide = true;
      // tslint:disable-next-line: deprecation
      // tslint:disable-next-line:max-line-length
      this.apisService.getApi(environment.numberValidation + this.usid + '/' + true + '?' + 'phoneNumber=' + num).subscribe(res => {
        this.mobinum = res;
        this.mobinum = res.success;
        if (this.mobinum === false) {
          this.phSpinnerHide = false;
          this.mobinummsg = 'Phone number already exist';
          setTimeout(() => {
            this.mobinummsg = '';
          }, 5000);
          this.vendorLocationPopUpForm.get('Phone').reset();
        }
        else {
          this.phSpinnerHide = false;
          this.phCheckHide = true;
          setTimeout(() => {
            this.phCheckHide = false;
          }, 3000);
        }
      });
    }
  }


  geteditPhon() {
    this.displayNumber = false;
    const num = this.vendorEditLocationPopUpForm.controls.Phone.value;
    if (num === '' || num === null || num === undefined || num === '(___) ___-____') {
    }
    else {
      this.editphSpinnerHide = true;
      this.hide = true;
      // tslint:disable-next-line:max-line-length
      this.apisService.getApi(environment.locationphone + this.locid + '/' + '?phoneNumber=' + num).subscribe(res => {
        this.hide = false;
        this.mobinum1 = res;
        this.mobinum1 = res.success;
        if (this.mobinum1 === false) {
          this.editphSpinnerHide = false;
          this.mobinummsg1 = 'Phone number already exist';
          setTimeout(() => {
            this.mobinummsg1 = '';
          }, 3000);
          this.vendorEditLocationPopUpForm.get('Phone').reset();
        }
        else {
          this.editphSpinnerHide = false;
          this.editphCheckHide = true;
          setTimeout(() => {
            this.editphCheckHide = false;
          }, 3000);
        }
      });
    }
  }
  downloadFileapi() {
    this.apisService.getApi(environment.downloadres + 5).subscribe((res: any) => {
      this.downloadData = res;
      window.location.assign(this.downloadData.downloadUrl);
    });
  }
}
