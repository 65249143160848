<p-card>
    <p-toolbar>
        <b>Available Skills</b>
    </p-toolbar>
    <br>
    <div class="p-grid">
        <div class="p-col droptarget" *ngFor="let data of skillsdata; index as i">
            <button pButton style="white-space: nowrap;border-color:none" type="button" label="{{data.label}}"
                class="p-button-raised" [ngStyle]="{'background-color':i%2===0?'#0D416B;':'#2bb9d5'}"
                optionLabel="label"></button>
        </div>
    </div>
</p-card><br>
<p-card>
    <p-toolbar>
        <b>Other Skills</b>
    </p-toolbar>
    <br>
    <div class="p-grid" *ngIf="table">
        <div class="p-col droptarget" *ngFor="let data of otherskills;let i = index"
            [ngClass]="{'active': clickedIndex == i || hoveredIndex === i}" (click)="clickedIndex = i"
            (mouseenter)="hoveredIndex = i" (mouseleave)="hoveredIndex = null">
            <!-- <input [value]="data" pInputText
                style="white-space: nowrap;border-color: #FF8C00;color: white;text-align: center;"
                [ngStyle]="{'background-color':i%2===0?'#FF8C00':'#FF8C00'}" size="{{data.length}}"> -->
                <button pButton style="white-space: nowrap;border-color:none" type="button" label="{{data}}"
                class="p-button-raised"  style="white-space: nowrap;border-color: #FF8C00;color: white;text-align: center;"   [ngStyle]="{'background-color':i%2===0?'#FF8C00':'#FF8C00'}"
                optionLabel="label"></button>
            <span *ngIf="clickedIndex == i || hoveredIndex === i">
                <em class="pi pi-plus-circle pointer" (click)="addSkills(data)"></em>
            </span>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png"
                    class="norecordimg">
                <br><em>Sorry, we are not able to find any other skills</em><br>
            </div>
        </div>
    </p-card>

</p-card>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>