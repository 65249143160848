import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-servicesbayattachments',
  templateUrl: './servicesbayattachments.component.html',
  styleUrls: ['./servicesbayattachments.component.scss'],
  providers: [DatePipe, MessageService],
})
export class ServicesbayattachmentsComponent implements OnInit {
  AttachmentsForm: FormGroup;
  vendorAttachmentsDailogForm: FormGroup;
  vendorAttachmentsEditDailogForm: FormGroup;
  GridData: any;
  attachmentsGriddata: any;
  attachmentSearchData: any;
  validFromDate: any;
  validToDate: string;
  attachmentSearchDataResponse: any;
  display: boolean;
  AttachmentsAdd: any;
  attachmentValidity: string;
  attachuserId: any;
  orgId: any;
  displayDailog: boolean;
  attachmentsUpdateDailog: any;
  attachId: any;
  iconToggle = [];
  minDate: Date;
  namedisplay: boolean;
  displaydoctype: boolean;
  validdisplay: boolean;
  validvalid: boolean;
  valid: boolean;
  displayvalid: boolean;
  fileResponse: string;
  base64textString: string;
  bas2: string;
  files1: any;
  errorMsg: string;
  file2: any;
  updateData: any;
  base64Response: any;
  getpath: boolean;
  hide: boolean;
  loader: boolean;
  startCurDate: Date;
  totalrecords: any;
  uploadfileData: any;
  fileHolder: any;
  downloadFiledata: any;
  actId: any;
  rows: any = 10;
  page: any = 1;
  docTable = {};
  documenttype: any[];
  records: boolean;
  table = true;
  accTID: any;
  rowData: any;
  items: { label: string; icon: string; command: () => void; }[];
  isUploading1: boolean;
  filedata: any;
  resumeRes: any;
  downloadData: any;
  rowId: any;
  fileName: any;
  fileType: any;
  name: any;
  fileRes: any;
  fileData: any;
  filePath: any;
  fileSize: any;
  getData: any;
  attachmentId: any;
  first: any;
  pageCount: any;
  usid: any;
  empflag: any;
  gridid: any;
  organisationId: any;
  show: boolean;
  hide1: boolean;
  show1: boolean;
  editattachvalid: any;
  editdata: boolean;
  roles: any;
  filesdatass: boolean;
  crossicon: boolean;
  classinput: boolean;
  validityshow: boolean;
  accID: any;
  usrroleid: any;
  getdata: any;
  accountactivityId: string;
  // tslint:disable-next-line: max-line-length
  constructor(private fb: FormBuilder, private sanitizer: DomSanitizer, private apisService: ApisService, private datepipe: DatePipe, private messageService: MessageService, private breadcrumbService: BreadcrumbService, private route: ActivatedRoute) {
    this.usid = JSON.parse(apisService.decodeData()).userId;
    this.accID = JSON.parse(apisService.decodeData()).accId;
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.empflag = routeParams.params.empflag;
    this.getdata = routeParams.params.getdata;
    this.accountactivityId = this.route.snapshot.paramMap.get('id');
    this.actId = JSON.parse(this.apisService.decodeData());
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
      this.roles = this.actId.roles[0].id;
    } else {
      this.roles = this.usrroleid;
    }
    if (this.getdata === 'AccAct') {
      this.breadcrumbService.setItems([
        { label: 'Accounts Activity', routerLink: '/accounts-activity' },
        {
          label: 'Account Details', routerLink: ['/acc-adminmenu/acc-details', this.accountactivityId, {
            getdata: 'AccAct'
          }]
        },
        // {
        //   label: 'Contacts', routerLink: ['/acc-adminmenu/acc-contact/', this.accountactivityId, {
        //     getdata: 'AccAct'
        //   }],
        // },
        { label: 'Attachments', routerLink: '#' },
      ]);
    } else {
      this.breadcrumbService.setItems([
        { label: 'Account Details', routerLink: ['/acc-adminmenu/acc-details', this.roles] },
        { label: 'Attachments', routerLink: '#' },
      ]);
    }

  }
  cols = [
    { field: 'title', header: 'Name', width: '9%' },
    { field: 'attachmentObjectType', header: 'Document Type', width: '7%' },
    { field: 'attachmentUploadedDate', header: 'Uploaded Date', width: '7%' },
    { field: 'attachmentExpiry', header: 'Date Of Expiry', width: '7%' },
    { field: 'attachmentUploadedBy', header: 'Uploaded By', width: '7%' },
    { field: 'action', header: 'Action', width: '3%' },
  ];
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.DataSearch();
    }
  }
  ngOnInit() {
    this.crossicon = true;
    this.AttachmentsForm = this.fb.group({
      Name: [''],
      ValidFromDate: [''],
      VAlidToDate: [''],
      DocumentType: [''],
    });
    this.vendorAttachmentsDailogForm = this.fb.group({
      attachmentNameDailog: ['', [Validators.required, Validators.maxLength(40), Validators.pattern('^[\\sa-zA-z]*$')],],
      attachmentDocTypeDailog: ['', Validators.required],
      attachmentValidityDailog: [''],
      attachmentCommentsDailog: ['', Validators.maxLength(300)],
      uploadAttachment: ['', Validators.required],
      dateCheck: [''],
      organisationText: ['']
    });
    this.vendorAttachmentsEditDailogForm = this.fb.group({
      attachmentEditNameDailog: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(40)]],
      attachmentEditValidityDailog: ['', null],
      attachmentEditCommentsDailog: ['', Validators.maxLength(300)],
      dateCheck: [''],
      organisationText: ['']
    });
    this.documentDropD();
    this.gridData();
    const today = new Date();
    this.startCurDate = today;
  }
  documentDropD() {
    this.apisService.getApi(environment.lookupsData + 'ATFA').subscribe((res: any) => {
      this.documenttype = res.data;
      this.documenttype.forEach((element) => {
        this.docTable[element.code] = element.label;
      });
    });
  }
  gridData() {
    this.loader = true;
    this.rowId = this.route.snapshot.paramMap.get('id');
    this.actId = JSON.parse(this.apisService.decodeData());
    const getattchments = this.getdata === 'AccAct' ? this.accountactivityId : this.actId;
    const pageNo = this.page;
    const pageCount = this.rows;
    const actid = '/' + pageNo + '/' + pageCount + '/' + this.accountactivityId;
    this.apisService.getApi(environment.getAttachment + actid).subscribe((res: any) => {
      this.GridData = res.data.total;
      if (this.GridData !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.loader = false;
      this.attachmentsGriddata = res.data.records;
      this.totalrecords = this.GridData;
    });
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridData();
  }
  endDateValidation() {
    if (
      new Date(this.AttachmentsForm.get('ValidFromDate').value).getTime() <
      new Date(this.AttachmentsForm.get('VAlidToDate').value).getTime()
    ) {
    } else {
      this.AttachmentsForm.get('VAlidToDate').reset();
      const startDate = this.AttachmentsForm.get('ValidFromDate').value;
    }
  }
  DataSearch() {
    this.loader = true;
    // tslint:disable-next-line:max-line-length
    const attachmenttitle = this.AttachmentsForm.controls.Name.value === undefined || this.AttachmentsForm.controls.Name.value === '' ? '' : this.AttachmentsForm.controls.Name.value === null ? '' : this.AttachmentsForm.controls.Name.value;
    // tslint:disable-next-line:max-line-length
    const attachementtype = this.AttachmentsForm.controls.DocumentType.value === undefined || this.AttachmentsForm.controls.DocumentType.value === '' ? '' : this.AttachmentsForm.controls.DocumentType.value === null ? ' ' : this.AttachmentsForm.controls.DocumentType.value.code;
    // tslint:disable-next-line:max-line-length
    const attachmentexpirydate = this.AttachmentsForm.controls.VAlidToDate.value === undefined || this.AttachmentsForm.controls.VAlidToDate.value === null ? '' : this.AttachmentsForm.controls.VAlidToDate.value === '' ? '' : this.datepipe.transform(this.AttachmentsForm.controls.VAlidToDate.value, 'MM-dd-yyyy');
    // tslint:disable-next-line:max-line-length
    const attachemnetvaliddate = this.AttachmentsForm.controls.ValidFromDate.value === undefined || this.AttachmentsForm.controls.ValidFromDate.value === '' ? '' : this.AttachmentsForm.controls.ValidFromDate.value === null ? '' : this.datepipe.transform(this.AttachmentsForm.controls.ValidFromDate.value, 'MM-dd-yyyy');
    const getattchments = this.getdata === 'AccAct' ? this.accountactivityId : this.rowId;
    // tslint:disable-next-line:max-line-length
    const data = this.page + '/' + this.rows + '/' + this.accountactivityId + '?attachmentName=' + attachmenttitle + '&validFrom=' + attachemnetvaliddate + '&documentType=' + attachementtype + '&validTo=' + attachmentexpirydate;
    this.apisService.getApi(environment.getAttachment + '/' + data).subscribe((res: any) => {
      this.loader = false;
      this.attachmentsGriddata = res.data.records;
      this.totalrecords = res.data.total;
      this.loader = false;
      if (this.totalrecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  selectFile(evt) {
    const formData: FormData = new FormData();
    if (evt.target.files.length > 0) {
      this.file2 = evt.target.files[0];
      this.vendorAttachmentsDailogForm.get('uploadAttachment').setValue(this.file2.name);
    }
    formData.append('file', this.file2.name);
  }
  showDailog() {
    this.filesdatass = false;
    this.vendorAttachmentsDailogForm.reset();
    this.show = false;
    this.validityshow = true;
    this.display = true;
  }
  SaveDailog() {
    if (this.vendorAttachmentsDailogForm.invalid) {
      const controls = this.vendorAttachmentsDailogForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      this.attachuserId = sessionStorage.getItem('userId');
      // tslint:disable-next-line:max-line-length
      if (this.vendorAttachmentsDailogForm.controls.attachmentValidityDailog.value === null || this.vendorAttachmentsDailogForm.controls.attachmentValidityDailog.value === undefined) {
        this.attachmentValidity = '';
      } else {
        const attValidity = this.vendorAttachmentsDailogForm.controls.attachmentValidityDailog.value;
        const attachmentValidityDate = new Date(Date.parse(attValidity));
        this.attachmentValidity = this.datepipe.transform(attachmentValidityDate, 'MM-dd-yyyy');
      }
      this.organisationId = JSON.parse(sessionStorage.getItem('loginData')).orgId;
      const gridaccId = this.empflag === 'acctemp' ? this.organisationId : this.usid;
      const addatt = this.getdata === 'AccAct' ? this.accountactivityId : gridaccId;
      const data = {
        orgId: addatt,
        // tslint:disable-next-line:max-line-length
        vendorDocs: this.vendorAttachmentsDailogForm.controls.attachmentDocTypeDailog.value === (undefined || null || '') ? -1 : this.vendorAttachmentsDailogForm.controls.attachmentDocTypeDailog.value.value,
        fileFileName: this.file2 === undefined || '' || null ? null : this.file2,
        validity: this.attachmentValidity === '' || null ? '' : this.attachmentValidity === undefined ? '' : this.attachmentValidity,
        userId: this.attachuserId,
        attachmentTitle: this.vendorAttachmentsDailogForm.controls.attachmentNameDailog.value,
        vendorComments: this.vendorAttachmentsDailogForm.controls.attachmentCommentsDailog.value,
        filePath: this.uploadfileData.data,
      };
    }
  }
  disableAddCheckboxValue(data) {
    if (data.checked === true) {
      // this.vendorAttachmentsDailogForm.controls.attachmentValidityDailog.disable();
      this.show = true;
      this.validityshow = false;
    } else {
      this.show = false;
      // this.vendorAttachmentsDailogForm.controls.attachmentValidityDailog.enable();
      this.validityshow = true;
    }
  }
  disableEditCheckboxValue(data) {
    this.editdata = data.checked;
    if (data.checked === true) {
      this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.disable();
      this.show1 = true;
    } else {
      this.show1 = false;
      this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.enable();
    }
  }
  showDailogUpdate(data) {
    this.updateData = data;
    this.attachmentId = data.attachmentId;
    this.vendorAttachmentsEditDailogForm.controls.attachmentEditNameDailog.patchValue(this.updateData.title);
    this.vendorAttachmentsEditDailogForm.controls.attachmentEditCommentsDailog.patchValue(this.updateData.comments);
    if (data.attachmentExpiry === '' || data.attachmentExpiry === '1999-01-01T00:00:00.000+0000') {
      this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.disable();
      this.vendorAttachmentsEditDailogForm.controls.dateCheck.patchValue(true);
      this.show1 = true;
    } else {
      this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.enable();
      this.vendorAttachmentsEditDailogForm.controls.dateCheck.patchValue(false);
      this.show1 = false;
      this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.enable();
    }
    if (data.attachmentExpiry === '' || data.attachmentExpiry === '1999-01-01T00:00:00.000+0000') {
      this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.patchValue('');
    } else {
      this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.patchValue(new Date(this.updateData.attachmentExpiry));
    }
  }
  updataeDailog() {
    this.getpath = true;
    // const updateattch = this.getdata === 'AccAct' ? this.accountactivityId : this.attachmentId;
    if (this.vendorAttachmentsEditDailogForm.invalid) {
      const controls = this.vendorAttachmentsEditDailogForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      if (this.editdata === true) {
        // tslint:disable-next-line:max-line-length
        this.editattachvalid = this.vendorAttachmentsEditDailogForm.controls.organisationText.value === null || this.vendorAttachmentsEditDailogForm.controls.organisationText.value === undefined || this.vendorAttachmentsEditDailogForm.controls.organisationText.value === '' ? '01-01-1999' : this.vendorAttachmentsEditDailogForm.controls.organisationText.value;
      } else {
        // tslint:disable-next-line:max-line-length
        this.editattachvalid = this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.value === null || this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.value === undefined || this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.value === '' ? '' : this.datepipe.transform(this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.value, 'MM-dd-yyyy');
      }
      const updateadd = this.editattachvalid;
      const attachmenttitle = this.vendorAttachmentsEditDailogForm.controls.attachmentEditNameDailog.value;
      const attachmentComment = this.vendorAttachmentsEditDailogForm.controls.attachmentEditCommentsDailog.value;
      const data1 = {
        id: this.attachmentId,
        fileName: attachmenttitle,
        expiryDate: updateadd,
        comments: attachmentComment
      };
      this.hide = true;
      this.apisService.putApi(environment.updateAttachment, data1).subscribe((res: any) => {
        this.hide = false;
        this.getData = res;
        this.displayDailog = false;
        if (this.getData.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message });
          this.gridData();
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error });
        }
      });
    }
  }
  resetData() {
    this.AttachmentsForm.reset();
    this.gridData();
  }
  resetDailog() {
    this.filesdatass = false;
    this.vendorAttachmentsDailogForm.reset();
  }
  downloadFileapi() {
    this.isUploading1 = true;
    this.filedata = this.rowData.attachmentId;
    this.apisService.getApi(environment.downloadedattachment + '/' + this.filedata).subscribe((res: any) => {
      this.downloadData = res;
      this.isUploading1 = false;
      window.location.assign(this.downloadData.downloadUrl);
    });
  }
  toggle(event, data) {
    this.rowData = data;
    this.items = [
      { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => { this.displayDailog = true; this.showDailogUpdate(data); } },
      { label: 'Download', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); } },
    ];
  }
  onFileChange(event) {
    this.fileName = event.target.files[0];
    this.name = this.fileName.name;
    if (this.fileName.name.length > 25) {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'File name should be 25 alphabets.' });
      this.cancelfiles();
    }
    if (this.fileName.size > 10485760) {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please upload below 10MB.' });
      this.cancelfiles();
    }
    else {
      this.fileType = this.fileName.name.split('.').pop();
      const formData: FormData = new FormData();
      formData.append('file', this.fileName);
      this.filedata = formData;
      // tslint:disable-next-line:no-conditional-assignment
      if (this.filedata = formData) {
        this.filesdatass = true;
      } else {
        this.filesdatass = false;
      }
    }
  }
  createQP() {
    if (this.vendorAttachmentsDailogForm.invalid) {
      const controls = this.vendorAttachmentsDailogForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      this.hide1 = true;
      this.apisService.postApi(environment.profileUpload + this.rowId + '/' + 'A', this.filedata).subscribe((res: any) => {
        this.fileRes = res;
        this.filedata = res.data;
        this.filePath = res.data.path;
        this.fileSize = res.data.fileSize;
        this.addAttachement();
      });
    }
  }
  addAttachement() {
    this.hide1 = true;
    const addattch = this.getdata === 'AccAct' ? this.accountactivityId : this.rowId;
    const attachdata = {
      objectId: addattch,
      verifiedDate: '01-01-2021',
      objectType: 'A',
      // tslint:disable-next-line:max-line-length
      title: this.vendorAttachmentsDailogForm.controls.attachmentNameDailog.value === undefined ? ' ' : this.vendorAttachmentsDailogForm.controls.attachmentNameDailog.value === null ? '' : this.vendorAttachmentsDailogForm.controls.attachmentNameDailog.value,
      attPath: this.filePath,
      fileName: this.fileName.name,
      size: this.fileSize,
      extension: this.fileType,
      // tslint:disable-next-line:max-line-length
      comments: this.vendorAttachmentsDailogForm.controls.attachmentCommentsDailog.value === undefined ? ' ' : this.vendorAttachmentsDailogForm.controls.attachmentCommentsDailog.value === null ? '' : this.vendorAttachmentsDailogForm.controls.attachmentCommentsDailog.value,
      verifiedBy: '1',
      // tslint:disable-next-line:max-line-length
      attType: this.vendorAttachmentsDailogForm.controls.attachmentDocTypeDailog.value === undefined ? ' ' : this.vendorAttachmentsDailogForm.controls.attachmentDocTypeDailog.value === null ? '' : this.vendorAttachmentsDailogForm.controls.attachmentDocTypeDailog.value.code,
      expiryDate: this.datepipe.transform(this.vendorAttachmentsDailogForm.controls.attachmentValidityDailog.value, 'MM-dd-yyyy'),
    };
    this.apisService.postApi(environment.addProfile, attachdata).subscribe((res: any) => {
      this.getData = res;
      this.display = false;
      if (this.getData.hasOwnProperty('success') === true) {
        this.hide1 = false;
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message });
        this.gridData();
      } else if (this.getData.hasOwnProperty('failed') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.failed });
      } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error });
      }
    });
  }
  cancelfiles() {
    this.vendorAttachmentsDailogForm.controls.uploadAttachment.reset();
    this.filesdatass = false;
    this.crossicon = false;
    this.classinput = true;
  }
}
