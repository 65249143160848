import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApisService } from './apis.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  loginRoleId: any;
  ip: any;
  userId: any;
  myip: string;
  constructor(public router: Router, private apiservices: ApisService, private http: HttpClient, ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const logInDetails = JSON.parse(this.apiservices.decodeData());
    const { detect } = require('detect-browser');
    const browser = detect();
    this.ip = logInDetails.ipAddress;
    this.userId = logInDetails.userId;
    const body = {
      usrId: this.userId,
      browser: browser.name,
      ipAddress: this.ip
    };
    // tslint:disable-next-line: deprecation
    this.apiservices.postApi(environment.verifyLogin, body).subscribe(res => {
      sessionStorage.setItem('autoID', res.autoIncrementedId);
    });
    this.loginRoleId = logInDetails.roles[0].id;
    if (this.loginRoleId === 5) {
      this.router.navigate(['/project-requirements']);
    } else if (this.loginRoleId === 4) {
      // tslint:disable-next-line:no-unused-expression
      this.router.navigate(['/purchas-requirement']);
    }
    else if (this.loginRoleId === 3) {
      this.router.navigate(['/account-details']);
    }
    else if (this.loginRoleId === 9) {
      this.router.navigate(['/open-positions']);
    }
    else if (this.loginRoleId === 1) {
      this.router.navigate(['/accounts']);
    }
    else if (this.loginRoleId === 10) {
      this.router.navigate(['/tech-evaltion-tech-reviews']);
    }
    else if (this.loginRoleId === 11) {
      this.router.navigate(['/employee-requirements']);
    }
    else if (this.loginRoleId === 12) {
      this.router.navigate(['/tech-evalution']);
    }
    else if (this.loginRoleId === 6) {
      this.router.navigate(['/accountdetails']);
    }
    else if (this.loginRoleId === 8) {
      this.router.navigate(['/openpositions']);
    }
    else if (this.loginRoleId === 13) {
      this.router.navigate(['/salesOpenPositions']);
    }
    else if (this.loginRoleId === 14) {
      this.router.navigate(['/vendor']);
    }
    else if (this.loginRoleId === 15) {
      this.router.navigate(['/consultantopenposition']);
    }
    else {
      return true;
    }
    return false;
  }
}
