import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
  providers: [DatePipe, MessageService],
})
export class GroupsComponent implements OnInit {
  cols = [
    { field: 'name', header: 'Group Name', width: '25%' },
    { field: 'description', header: 'Member Count', width: '20%' },
    { field: 'memberCount', header: 'Description', width: '20%' },
    { field: 'lkStatus', header: 'Status', width: '20%' },
    { field: '', header: 'Action', width: '10%' }
  ];
  displaydata1: boolean;
  groupform: any;
  display: boolean;
  loading = false;
  gridData: any;
  totalrecords: any;
  rowData: any;
  items: { label: string; icon: string; command: () => void; }[];
  addgroupform: FormGroup;
  editgroupform: FormGroup;
  accId: any;
  disable: boolean;
  display1: boolean;
  addres: any;
  updateres: any;
  status = [
    { name: 'Active', id: 'A' },
    { name: 'Inactive', id: 'I' },
  ];
  statuslist = {};
  userId: any;
  rowid: any;
  table: boolean;
  records: boolean;
  rows: any = 10;
  page: any = 1;
  first: any;
  pageCount: any;
  RoleId: any;
  flag: string;
  adminrole: boolean;
  nonadminrole: boolean;
  usrroleid: any;
  // tslint:disable-next-line:max-line-length
  constructor(private apisService: ApisService, private fb: FormBuilder, private breadcrumbService: BreadcrumbService, private messageService: MessageService, private router: Router) {
    this.breadcrumbService.setItems([
      { label: 'Groups', routerLink: '/groups' },
    ]);
  }
  ngOnInit(): void {
    const userdetails = JSON.parse(this.apisService.decodeData());
    this.accId = userdetails.accId;
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === ''){
      this.RoleId = userdetails.roles[0].id;
    }else{
      this.RoleId = this.usrroleid;
    }
    this.userId = userdetails.userId;
    if (this.RoleId === 3 || this.RoleId === 6 || this.RoleId === 1 || this.RoleId === 14) {
      this.flag = 'A';
      this.adminrole = true;
      this.nonadminrole = false;
    }
    else {
      this.flag = 'B';
      this.adminrole = false;
      this.nonadminrole = true;
    }
    this.groupform = this.fb.group({
      name: ['', Validators.required],
    });
    this.addgroupform = this.fb.group({
      addgroupname: ['', [ Validators.required, Validators.maxLength(25)]],
      description: ['', Validators.maxLength(100)],
    });
    this.editgroupform = this.fb.group({
      editgroupname: ['', [Validators.required, Validators.maxLength(25)]],
      edescription: ['', Validators.maxLength(100)],
      Status: ['', Validators.required]
    });
    this.searchData();
    this.gridstatus();
  }
  gridstatus() {
    // tslint:disable-next-line:no-unused-expression
    this.status.forEach(element => {
      this.statuslist[element.id] = [element.name];
    });
  }
  // this method is used for enter-search
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchData();
    }
  }
  // this method is used for search data
  searchData() {
    this.loading = true;
    // tslint:disable-next-line: max-line-length
    const firstName = this.groupform.controls.name.value === null || this.groupform.controls.name.value === undefined || this.groupform.controls.name.value === '' ? '' : this.groupform.controls.name.value;
    // tslint:disable-next-line: max-line-length
    this.apisService.getApi(environment.groupdata + this.page + '/' + this.rows + '/' + this.flag + '/' + this.userId + '?groupName=' + firstName).subscribe((res) => {
      this.gridData = res.data.records;
      this.loading = true;
      this.totalrecords = res.count;
      if (this.totalrecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.loading = false;
    });
  }
  // It is used to display add pop-up
  addData() {
    this.addgroupform.reset();
    this.display = true;
  }
  editData(data) {
    this.rowid = data.id;
    this.editgroupform.reset();
    this.display1 = true;
    this.editgroupform.controls.editgroupname.patchValue(data.name);
    this.editgroupform.controls.edescription.patchValue(data.description);
    this.editgroupform.controls.Status.patchValue(this.status.filter((x) => x.id === data.lkStatus)[0]);
  }
  // It is used for addgroup
  addgroup() {
    if (this.addgroupform.invalid) {
      const controls = this.addgroupform.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      this.disable = true;
      this.loading = true;
      const data = {
        name: this.addgroupform.controls.addgroupname.value,
        description: this.addgroupform.controls.description.value,
        ownerId: this.userId,
        ownerOrgId: this.accId,
      };
      this.apisService.postApi(environment.groupadd, data).subscribe((res: any) => {
        this.addres = res;
        if (this.addres.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.addres.message });
        } else if (this.addres.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.addres.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.addres.error });
        }
        this.disable = false;
        this.display = false;
        this.loading = false;
        this.searchData();
      });
    }
  }
  // it is used for update pop-up
  updategroup() {
    if (this.editgroupform.invalid) {
      const controls = this.editgroupform.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      this.disable = true;
      const data1 = {
        id: this.rowid,
        name: this.editgroupform.controls.editgroupname.value,
        description: this.editgroupform.controls.edescription.value,
        ownerId: this.accId,
        ownerOrgId: this.userId,
        lkStatus: this.editgroupform.controls.Status.value.id
      };
      this.apisService.putApi(environment.groupadd, data1).subscribe((res: any) => {
        this.updateres = res;
        if (this.updateres.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateres.message });
        } else if (this.updateres.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateres.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.updateres.error });
        }
        this.disable = false;
        this.display1 = false;
        this.searchData();
      });
    }
  }
  // it is used for reset grid
  reset() {
    this.groupform.reset();
    this.searchData();
  }
  nextPage(data) {
    this.router.navigate(['/members'], { queryParams: { gridId: data.id, groupname: data.name } });
  }
  // It is used to show action pop up
  toggle(event, data, rowData) {
    this.rowData = rowData;
    if (this.flag === 'A') {
      this.items = [{ label: 'Group Members', icon: 'pi pi-users', command: () => { this.nextPage(data); } },
      { label: 'Edit', icon: 'pi pi-pencil', command: () => { this.editData(data); } },
      ];
    }
    else {
      this.items = [
        { label: 'Group Members', icon: 'pi pi-users', command: () => { this.nextPage(data); } },
      ];
    }
  }
  // It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.searchData();
  }
}
