<div class="layout-wrapper" (click)="onLayoutClick()"
     [ngClass]="{'layout-static': isStatic(),
                'layout-overlay': isOverlay(),
                'layout-overlay-active': overlayMenuActive,
                'layout-horizontal': isHorizontal(),
                'layout-slim': isSlim(),
                'layout-static-inactive': staticMenuDesktopInactive,
                'layout-mobile-active': staticMenuMobileActive,
                'layout-menu-dark':app.darkMenu,
                'layout-menu-light':!app.darkMenu, 'p-input-filled': app.inputStyle === 'filled', 'p-ripple-disabled': !app.ripple}">
    <app-topbar></app-topbar>
    <div class="layout-menu-container" (click)="onMenuClick($event)">
        <div class="layout-menu-logo">
            <a>
                <img alt=""  id="layout-menu-logo"src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Servicesbay Logo SVG.svg" style="width: 215px;" library="" />
            </a>
        </div>
        <div class="layout-menu-wrapper">
            <div class="menu-scroll-content">
                <app-inline-profile *ngIf="app.profileMode=='inline'&&!isHorizontal()"></app-inline-profile>
                <app-menu></app-menu>
            </div>
        </div>
    </div>
    <div class="layout-main">
        <app-breadcrumb></app-breadcrumb>
        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
    <app-config></app-config>
    <div class="layout-mask"></div>
</div>
