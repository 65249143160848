import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-consultant-open-position-edit',
  templateUrl: './consultant-open-position-edit.component.html',
  styleUrls: ['./consultant-open-position-edit.component.scss'],
  providers: [DatePipe, MessageService],
})
export class ConsultantOpenPositionEditComponent implements OnInit {
  openpositondata: string;
  positiontitle: any;
  reqId: any;
  openPositionEditForm: FormGroup;
  currencysymbl: any;
  acctdetails: any;
  preferencList: any;
  currencycode: any[];
  currencyValue: string;
  openpositiondata: any;
  OwnerId: any;
  positionData: any = [];
  jobTitleId: any;
  minMaxRate: any;
  locationList: any = [];
  getexperienceOptions: any = [];
  expLabel: any = [];
  hireTypeData: any = [];
  billingData: any = [];
  skillsList: any = [];
  skillset1: any = [];
  selectedPrimaryskills: any = [];
  skillsList12: any = [];
  primarySkills: any = [];
  display: boolean;
  updateprofileForm: FormGroup;
  userId: any;
  loader: boolean;
  resumegrid: any;
  filedataa: any;
  loaderForDownload: boolean;
  resumeRes: any;
  resumeId: any;
  accId: any;
  venid: any;
  conCountid: any;
  btndisable: boolean;
  btndis: boolean;
  selectedRowForResume: any;
  updateactionBtn: boolean;
  tableRowID: any;
  bllrategroup: FormGroup;
  displaybill: boolean;
  selectevent: any;
  restitle: any;
  prname: any;
  popuptitle: any;
  radiobtn: boolean;
  otherskills = false;
  editLocationdata: any;
  patchlocationString: string;
  // tslint:disable-next-line: max-line-length
  constructor(private breadcrumservice: BreadcrumbService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private messageService: MessageService, private apiservice: ApisService) {
    this.breadcrumservice.setItems([
      { label: 'Open Positions', routerLink: '/consultantopenposition' },
      { label: 'Open Position Edit', routerLink: '/consultantopenpositionedit' }
    ]);
  }
  // It is used for displaying table header data
  updateactioncols = [
    { field: 'name', header: 'Title', width: '4%' },
    { field: 'title', header: 'Name', width: '4%' },
    { field: '', header: 'Download', width: '1%' },
    { field: '', header: '', width: '1%' },
  ];
  ngOnInit(): void {
    this.acctdetails = JSON.parse(this.apiservice.decodeData());
    this.preferencList = this.acctdetails.preferencesList;
    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.positiontitle = routeParams.params.positontitle;
    this.reqId = routeParams.params.jobid;
    this.OwnerId = routeParams.params.ownerId;
    this.accId = routeParams.params.accid;
    this.venid = routeParams.params.vendid;
    this.conCountid = routeParams.params.conid;
    this.btndisable = this.conCountid >= 1 ? false : true;
    this.updateprofileForm = this.fb.group({
      tableapproval: ['']
    });
    this.openPositionEditForm = this.fb.group({
      positionTitle: [''],
      positionCategory: [''],
      jobRole: [''],
      workLocation: [''],
      requiredExperience: [''],
      hireType: [''],
      plannedStartDate: [''],
      status: [''],
      billingtype: [''],
      duration: [''],
      hrsperWeek: [''],
      skillSet: [''],
      jobDescription: [''],
      responsibilities: [''],
      qualification: [''],
      comments: [''],
      preferredSkills: [''],
      otherskills: ['']
    });
    this.bllrategroup = this.fb.group({
      billRateControl: ['', [Validators.required]]
    });
    this.openPositionEditForm.disable();
    this.getWorkLocation();
    this.getexperience();
    this.getHireTypeData();
    this.getBillingDropdown();
    this.getdSkillset();
    this.openpositionEditdata();
  }
  // It is used to navigate consultant open position screen
  backNavigate() {
    this.router.navigate(['/consultantopenposition']);
  }
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
  // It is used to show profile submit pop up
  backtopreviouspopup() {
    this.displaybill = false;
    this.bllrategroup.reset();
    this.display = true;
  }
  // It is used for displaying job role dropdown values
  getPositionCategoryBindDataValues(data) {
    const acctId = this.OwnerId;
    const posnCat = data.category;
    this.apiservice.getApi(environment.maxRate + acctId + '/' + posnCat).subscribe((res) => {
      this.minMaxRate = res.data;
      this.openPositionEditForm.controls.jobRole.patchValue(
        this.minMaxRate.filter((x) => x.title === data.jobTitle.title)[0]
      );
    });
  }
  // It is used for displaying worklocation dropdown values
  getWorkLocation() {
    this.apiservice.getApi(environment.location + '/' + this.OwnerId).subscribe((res) => {
      this.locationList = res.data;
    });
  }
  // It is used for displaying experience dropdown values
  getexperience() {
    this.apiservice.getApi(environment.lookupsData + 'RELYE').subscribe((res: any) => {
      this.getexperienceOptions = res.data;
      this.getexperienceOptions.forEach((element) => {
        this.expLabel[element.label] = [element.code];
      });
    });
  }
  // It is used for displaying hiretype dropdown values
  getHireTypeData() {
    this.apiservice.getApi(environment.hireType).subscribe((res) => {
      this.hireTypeData = res.data;
    });
  }
  // It is used for displaying billingtype dropdown values
  getBillingDropdown() {
    this.apiservice.getApi(environment.billingType).subscribe((res) => {
      this.billingData = res.data;
    });
  }
  // It is used for displaying skills dropdown values
  getdSkillset() {
    this.apiservice.getApi(environment.skills).subscribe((res) => {
      this.skillsList = res.data;
      this.skillsList.forEach((element) => {
        this.skillset1[element.code] = element.label;
      });
    });
  }
  // It is used to bind the table data
  openpositionEditdata() {
    this.apiservice.getApi(environment.requirementEdit + '/' + this.reqId).subscribe((res: any) => {
      this.openpositiondata = res.data;
      this.apiservice.getApi(environment.positionCat + '/' + this.OwnerId).subscribe((result) => {
        this.positionData = result.data;
        this.openPositionEditForm.controls.positionCategory.patchValue(
          this.positionData.filter((x) => x.id === this.openpositiondata.category)[0]
        );
      });
      this.jobTitleId = this.openpositiondata.jobTitle.id;
      this.getPositionCategoryBindDataValues(this.openpositiondata);
      this.openPositionEditForm.patchValue({
        positionTitle: this.openpositiondata.name,
        plannedStartDate: this.openpositiondata.plannedStartDate,
        hrsperWeek: this.openpositiondata.hrsPerWeek,
        duration: this.openpositiondata.duration,
        jobDescription: this.openpositiondata.description,
        responsibilities: this.openpositiondata.responsibilities,
        qualification: this.openpositiondata.qualification,
        comments: this.openpositiondata.comments,
        // otherskills: this.openpositiondata.otherSkills
      });
      // tslint:disable-next-line:max-line-length
      this.openPositionEditForm.controls.requiredExperience.patchValue(this.getexperienceOptions.filter((x) => x.code === this.openpositiondata.experience)[0]);
      // tslint:disable-next-line: max-line-length
      this.openPositionEditForm.controls.workLocation.patchValue(this.locationList.filter((x) => x.locId === this.openpositiondata.location)[0]);
      this.editLocationdata = this.locationList.filter((x) => x.locId === this.openpositiondata.location)[0];
      this.patchlocationString = this.editLocationdata?.name + '\n' + this.editLocationdata?.address1 + ',' + this.editLocationdata?.city + ',' + this.editLocationdata?.state + ',' + this.editLocationdata?.country;
      this.openPositionEditForm.controls.hireType.patchValue(this.hireTypeData.filter((x) => x.code === this.openpositiondata.hireType)[0]);
      // tslint:disable-next-line: max-line-length
      this.openPositionEditForm.controls.billingtype.patchValue(this.billingData.filter((x) => x.code === this.openpositiondata.billingType)[0]);
      const selectedPrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.skillsList.length; i++) {
        const array = this.openpositiondata.prefferedSkills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.skillsList[i].code) === array[j]) {
            selectedPrimarySkills.push(this.skillsList[i]);
          }
        }
      }
      const preferredSkills = selectedPrimarySkills;
      preferredSkills.forEach((element) => {
        this.skillsList12.push(element.label);
      });
      const selectedPrePrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.skillsList.length; i++) {
        const array = this.openpositiondata.skills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.skillsList[i].code) === array[j]) {
            selectedPrePrimarySkills.push(this.skillsList[i]);
          }
        }
      }
      const primarySkills = selectedPrePrimarySkills;
      primarySkills.forEach((element) => {
        this.primarySkills.push(element.label);
      });
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < selectedPrePrimarySkills.length; i++)
      {
          if (selectedPrePrimarySkills[i].code === '25')
          {
            this.otherskills = true;
            this.openPositionEditForm.controls.otherskills.patchValue(this.openpositiondata.otherSkills);
          }
      }
      this.openPositionEditForm.controls.skillSet.patchValue(selectedPrePrimarySkills);
      this.openPositionEditForm.controls.preferredSkills.patchValue(preferredSkills);
      selectedPrePrimarySkills.forEach((element) => { this.selectedPrimaryskills.push(element.label); });
    });
  }
  onSelect(event) {
    this.selectevent = event;
    this.radiobtn = false;
    this.resumeId = event.data.id;
  }
  onUnselect(event) {
    this.radiobtn = true;
  }
  // It is used to show select profile submit table data
  grid() {
    this.updateprofileForm.reset();
    const acctdetails = JSON.parse(this.apiservice.decodeData());
    this.userId = acctdetails.userId;
    this.apiservice.getApi(environment.attachmentActive + 1 + '/' + 10 + '/' + this.userId).subscribe((res: any) => {
      this.resumegrid = res.data.records;
      this.resumegrid.forEach((ele) => {
        if (ele.id === res.data.records[0].id) {
          this.tableRowID = res.data.records[0].id;
          this.selectedRowForResume = ele;
          this.updateactionBtn = true;
        }
      });
    });
  }
  // It is used to download attachment
  updatedownload(data) {
    this.filedataa = data.id;
    this.loaderForDownload = true;
    this.apiservice.getApi(environment.downloadedattachment + '/' + this.filedataa).subscribe((res) => {
      this.resumeRes = res;
      window.location.assign(this.resumeRes.downloadUrl);
      this.loaderForDownload = false;
    });
  }
  submittedProfileForm() {
    this.updateprofileForm.reset();
    this.bllrategroup.reset();
    this.display = true;
    this.radiobtn = false;
    this.grid();
  }
  updateresume() {
    this.updateprofileForm.reset();
    this.restitle = this.selectevent !== undefined ? this.selectevent.data.title : this.resumegrid[0].title;
    this.prname = this.selectevent !== undefined ? this.selectevent.data.fileName : this.resumegrid[0].fileName;
    this.display = false;
    this.bllrategroup.reset();
    this.displaybill = true;
  }
  // it is used to submit profile
  update() {
    if (this.bllrategroup.invalid) {
      const controls = this.bllrategroup.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      const ID = this.resumeId === undefined || null || '' ? this.tableRowID : this.resumeId;
      const data = {
        accId: this.accId,
        reqId: this.reqId,
        venId: this.venid,
        resumeId: ID,
        // tslint:disable-next-line:whitespace
        // tslint:disable-next-line:max-line-length
        billRate: this.bllrategroup.controls.billRateControl.value === null || this.bllrategroup.controls.billRateControl.value === undefined || this.bllrategroup.controls.billRateControl.value === '' ? '' : this.bllrategroup.controls.billRateControl.value,
        payRate: 0,
        status: 'Z'
      };
      this.display = false;
      this.loader = true;
      this.btndis = true;
      this.apiservice.postApi(environment.consultantsubmitreq, data).subscribe((res: any) => {
        this.loader = false;
        if (res.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
          setTimeout(() => {
            this.router.navigate(['/consultantopenposition']);
          }, 1000);
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
        }
      });
    }
  }
  saveLocation(ev) {
    const data = ev.value;
    this.patchlocationString = data.name  + '\n' + data.address1 + ',' + data.city + ',' + data.state + ',' + data.country;
   }
}
