<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
    <p-panel class="posskill" [toggleable]="false">
        <p-header>
            <strong>
                <label class="inputfields"> Primary Skills </label>
                <em class="pi pi-plus pskill" (click)="showDialog('P')"></em>
                <em class="pi pi-pencil pskill-p" (click)="showEditDialog()"></em>
            </strong>
        </p-header>
        <div class="p-grid ui-fluid">
            <div class="p-col" *ngFor="let item of pskillGrid index as i">

                <button pButton type="button" style="white-space: nowrap;" (click)="endorse(item)"
                    label="{{item.skillName}}" class="p-button-raised"
                    [ngStyle]="{'background-color':i%2===0?'#2bb9d5':'#0D416B'}"></button>
            </div>
        </div>
        <!--It is used to displaying add primary skill pop up-->
        <p-dialog class="dialogBox" header="Add Primary Skills" [(visible)]="display" appendTo="body"
            [contentStyle]="{'overflow': 'visible'}" [draggable]="false" [modal]="true" [resizable]="false"
            [style]="{width: '25%' }" [baseZIndex]="1">
            <form [formGroup]="AddSkillForm">
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 ">
                        <p-multiSelect [options]="skillList" formControlName="skill" optionLabel="label"
                            placeholder=" Select Primary Skills ">
                        </p-multiSelect>
                        <span class="validationtest"
                            *ngIf="this.AddSkillForm.get('skill').touched && this.AddSkillForm.get('skill').invalid && this.AddSkillForm.get('skill').errors.required">
                            Please select primary skills
                        </span><br>
                    </div>
                </div>
                <div class="btnsCSS1 aligncenter">
                    <button class="addhBtn" pButton type="submit" icon='pi pi-save' label="Save" [disabled]='disable'
                        (click)="addSkillData()"></button>
                </div>
            </form>
        </p-dialog>
        <!--It is used to edit primary skills pop up-->
        <p-dialog class="dialogBox" header="Edit primary skills" [(visible)]="displayEditPopup" [style]="{width: '25%'}"
            [draggable]="false" [modal]="true" [resizable]="false">
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-fluid ">
                    <p-table [value]="pskillGrid">
                        <ng-template pTemplate="body" let-data>
                            <tr>
                                <td>{{data.skillName}}</td>
                                <td style="text-align: end;color:rgb(43, 185, 213)"><em
                                        class="pi pi-ellipsis-v pointerpointer"
                                        (click)="actionpopup.toggle($event); toggle($event, data, 'primary')"></em></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </p-dialog>
    </p-panel><br>
    <p-panel class="posskill" [toggleable]="false">
        <p-header>
            <strong>
                <label class="inputfields">Secondary Skills </label>
                <em class="pi pi-plus pskill" (click)="showDialog1('S')"></em>
                <em class="pi pi-pencil pskill-p" (click)="showEditDialog2('a')"></em>
            </strong>
        </p-header>
        <div class="p-grid ui-fluid">
            <div class="p-col" *ngFor="let item of secskillGrid index as i">

                <button pButton type="button" style="white-space: nowrap;" (click)="endorse(item)"
                    label="{{item.skillName}}" class="p-button-raised"
                    [ngStyle]="{'background-color':i%2===0?'#2bb9d5':'#0D416B'}"></button>
            </div>
        </div>
        <!--It is used to display add secondary skills pop up-->
        <p-dialog class="dialogBox" header="Add Secondary Skills" [(visible)]="display1" [draggable]="false"
            [contentStyle]="{'overflow': 'visible'}" [modal]="true" [resizable]="false" [style]="{width: '25%' }"
            [baseZIndex]="1">
            <form [formGroup]="AddSecSkillForm">
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 ">
                        <p-multiSelect [options]="skillList1" formControlName="skill" optionLabel="label"
                            placeholder=" Select Secondary Skills ">
                        </p-multiSelect>
                        <span class="validationtest"
                            *ngIf="this.AddSecSkillForm.get('skill').touched && this.AddSecSkillForm.get('skill').invalid && this.AddSecSkillForm.get('skill').errors.required">
                            Please select secondary skills
                        </span>
                        <br>
                    </div>
                </div>
            </form>
            <div class="btnsCSS1 aligncenter">
                <button class="addhBtn" pButton type="submit" icon='pi pi-save' label="Save" [disabled]='disable'
                    (click)="addSkillData1()"></button>
            </div>
        </p-dialog>
        <!--It is used to display edit secondary skill pop up-->
        <p-dialog class="dialogBox" header="Edit secondary skills" [(visible)]="displayEditPopup2"
            [style]="{width: '25%'}" [draggable]="false" [modal]="true" [resizable]="false">
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-fluid ">
                    <p-table [value]="secskillGrid">
                        <ng-template pTemplate="body" let-data>
                            <tr>
                                <td>{{data.skillName}}</td>
                                <td style="text-align: end;color:rgb(43, 185, 213)"><em class="pi pi-ellipsis-v pointer"
                                        (click)="actionpopup.toggle($event); toggle($event, data, 'secondary')"></em>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </p-dialog>
    </p-panel>
</p-card>
<br>
<p-card *ngIf="endorseCardDisplay">
    <p-panel [toggleable]="false">
        <p-header>
            <strong>
                <label class="inputfields">Endorsed Skill - {{endorseSkillName}} </label>
                <em class="pi pi-times-circle endorsedskill" (click)="closePanel()"></em>
            </strong>
        </p-header>
        <div *ngIf="table">
            <p-table [value]="skillsEndorsed" selectionMode="single" dataKey="edit" [paginator]="false" [rows]="15"
                [responsive]="true" [rowsPerPageOptions]="[10,20,30]" sortMode="multiple">
                <ng-template pTemplate="header">
                    <tr>
                        <th id="gridHeader">Name</th>
                        <th id="gridHeader">Organization</th>
                        <th id="gridHeader">Rating</th>
                        <th id="gridHeader">Comment</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data>
                    <tr>
                        <td>{{data.reviewerName}}</td>
                        <td>{{data.organization}}</td>
                        <td>
                            <p-rating [ngModel]="data.lkRating" [readonly]="true" [cancel]="false"></p-rating>
                        </td>
                        <td>{{data.context}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <p-card *ngIf="records">
            <div class="norecordImgsize">
                <div *ngIf="records" class="aligncenter">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                    <br><em>Sorry, we are not able to find any endorsement</em><br>
                </div>
            </div>
        </p-card>
    </p-panel>
</p-card>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>