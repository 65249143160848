import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss'],
  providers: [DatePipe, MessageService],
})
export class NetworkComponent implements OnInit {
  endorsementForm: FormGroup;
  endorsementPrimaryForm: FormGroup;
  totalRecords: any;
  scopesTypes: any = [];
  loader: boolean;
  selectedInviationType: any;
  updatedRecordId: any;
  updateStatus: any;
  connectionUpdateId: any;
  invitationsData: any;
  searchNetoworkTerm: any;
  requestId: any;
  ignoreId: any;
  usrId: any;
  patnerId: any;
  connectionSelected: any;
  displayConnectionConfirmation = false;
  show = false;
  partnerId: any;
  networkData1: any;
  branchName: any;
  typeTable: any;
  primaryTable: any = {};
  skillLabel: any;
  primarySkills: any;
  skillsList: any = [];
  display1 = false;
  display2 = false;
  endorsementList: any;
  endorsementTable = {};
  connectionList: any[];
  value3: any;
  hide: boolean;
  getData: any;
  user: any;
  rowId: any;
  skillscode: any;
  skillId: any;
  userID: any;
  userid: any;
  actID: any;
  secondaryskill: any = [];
  secondaryskillData: any = [];
  primaryskillId: any;
  secondaryskillId: any;
  skillTitle: any;
  searchTerm: any;
  loginData: any;
  status: string;
  rows: any = 10;
  page: any = 1;
  networkData: any;
  connectionItems: any = [];
  connectionsItems: any = [];
  display = false;
  showInfo = false;
  confirmingRequest = false;
  confirmingIgnore = false;
  selectedCategoryList = 'Invitations';
  connectionTypes: any = [];
  iconToggle: {};
  items: any = [];
  cols: { field: string; header: string; width: string; }[];
  count: any;
  networksearch: FormGroup;
  connectionForm: FormGroup;
  partnerID: any;
  userId: any;
  Pid: any;
  Requestresponse: any;
  Reqreject: any;
  partner: any;
  invitation: any;
  acadamics: any;
  license: any;
  certification: any;
  primaryskil: any;
  secondaryskil: any;
  ignorereq: any;
  disable: boolean;
  rejectReq: any;
  endorsedSkills: any;
  endorsedprimaryskill: any = [];
  normalprimaryskill: any = [];
  endorsedsecondaryskill: any = [];
  normalsecondaryskill: any = [];
  skillofendorsed: any;
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
  }
  bottomReached(): boolean {
    return (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
  }
  // tslint:disable-next-line:member-ordering
  connectionOpenList: any = [
    { name: 'Giridhar Pachipulusu', designation: 'Software Engineer', organization: 'Miracle Software Systems', img: '', },
    { name: 'Nawazur Raheman', designation: 'System Engineer', organization: 'Miracle Software Systems', img: '', },
    { name: 'Nawazur Raheman', designation: 'System Engineer', organization: 'Miracle Software Systems', img: '', },
    { name: 'Nawazur Raheman', designation: 'System Engineer', organization: 'Miracle Software Systems', img: '', },
    { name: 'Nawazur Raheman', designation: 'System Engineer', organization: 'Miracle Software Systems', img: '', },
    { name: 'Nawazur Raheman', designation: 'System Engineer', organization: 'Miracle Software Systems', img: '', },
    { name: 'Nawazur Raheman', designation: 'System Engineer', organization: 'Miracle Software Systems', img: '', },
    { name: 'Nawazur Raheman', designation: 'System Engineer', organization: 'Miracle Software Systems', img: '', },
    { name: 'Nawazur Raheman', designation: 'System Engineer', organization: 'Miracle Software Systems', img: '', },
    { name: 'Nawazur Raheman', designation: 'System Engineer', organization: 'Miracle Software Systems', img: '', },
  ];
  // tslint:disable-next-line:member-ordering
  relationshipType: any = { code: 'P', id: 'RLRT', label: 'Personal' };
  // tslint:disable-next-line:member-ordering
  scopesType: any = { code: 'P', id: 'RLSC', label: 'Public' };
  // tslint:disable-next-line:max-line-length
  constructor(private breadCrumbService: BreadcrumbService, private fb: FormBuilder, private messageService: MessageService, private apisService: ApisService) {
    this.breadCrumbService.setItems([{ label: 'Network', routerLink: ['/network'] }]);
  }
  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name', width: '7.5%' },
      { field: 'company', header: 'Company', width: '5.5%' },
      { field: 'designation', header: 'Designation', width: '5.5%' }
    ];
    this.networksearch = this.fb.group({
      invisearch: [''],
      connectionsearch: ['']
    });
    this.endorsementForm = this.fb.group({
      endorsebtn: ['', Validators.required],
      comment: ['', Validators.maxLength(30)],
    });
    this.endorsementPrimaryForm = this.fb.group({
      endorsebtn: ['', Validators.required],
      comment: [''],
    });
    this.status = 'A';
    this.loginData = JSON.parse(this.apisService.decodeData());
    this.userId = this.loginData.userId;
    this.getNetworkData();
    this.items = [
      {
        label: 'Invitations', icon: 'pi pi-fw pi-user-plus', active: true, command: () => {
          this.selectedCategoryList = 'Invitations';
          this.status = 'R';
          this.networkData = [];
          this.totalRecords = 0;
          this.getNetworkData();
          this.networksearch.reset();
        },
      },
      {
        label: 'Connections',
        icon: 'pi pi-fw pi-users',
        command: () => {
          this.relationshipType = {
            code: 'P', id: 'RLRT', label: 'Personal'
          };
          this.scopesType = {
            code: 'P',
            id: 'RLSC',
            label: 'Public'
          };
          this.selectedCategoryList = 'Connections';
          this.scopeTypes();
          this.networkData = [];
          this.totalRecords = 0;
          this.status = 'A';
          this.show = false;
          this.networkTypes();
          this.getConnectionsData();
          this.networksearch.reset();
        },
      },
      {
        label: 'Network',
        icon: 'pi pi-fw pi-search',
        command: () => {
          this.networkData = [];
          this.totalRecords = 0;
          this.selectedCategoryList = 'Network';
          this.status = 'N';
          this.getNetworkList('no search');
          this.networksearch.reset();
        },
      },
      {
        label: 'My Followers',
        icon: 'pi pi-fw pi-user',
        command: () => {
          this.selectedCategoryList = 'My Followers';
          this.networksearch.reset();
        },
      },
      {
        label: 'Manage',
        icon: 'pi pi-fw pi-cog',
        command: () => {
          this.networkData = [];
          this.totalRecords = 0;
          this.selectedCategoryList = 'Manage';
          this.getConnectionRequestData();
          this.networksearch.reset();

        },
      },
    ];
    this.connectionList = [
      { name: 'Low', value: 1 },
      { name: 'Medium', value: 3 },
      { name: 'High', value: 5 }
    ];
  }
  selectedItem(eve) {
    this.selectedInviationType = eve;
    this.connectionTypes.forEach(x => {
      if (x.code === this.selectedInviationType) {
        this.connectionSelected = x.label;
      }
    });
    this.displayConnectionConfirmation = true;
  }
  // It is used to get the network dropdown
  networkTypes() {
    this.apisService.getApi(environment.lookupsData + 'RLRT').subscribe((res: any) => {
      this.connectionTypes = res.data;
      this.connectionItems = [];
      res.data.forEach(x => {
        const dataItem = {
          label: x.label,
          command: () => {
            this.selectedItem(x.code);
          },
        };
        this.connectionItems.push(dataItem);
      });
    });
  }
  // It is used to get the scopes dropdown
  scopeTypes() {
    this.apisService.getApi(environment.lookupsData + 'RLSC').subscribe((res: any) => {
      this.scopesTypes = res.data;
    });
  }
  // It is used to get the grid data
  getNetworkData() {
    this.loader = true;
    const params = this.page + '/' + this.rows + '/' + this.loginData.userId;
    this.apisService.getApi(environment.networkData + params).subscribe((res: any) => {
      this.loader = false;
      this.networkData = res.data.records;
      this.totalRecords = res.data.count;
      this.Pid = res.data.records[0]?.partnerId;
      this.networkTypes();
    });
  }
  // It is used to get the data with status requested
  getConnectionRequestData() {
    this.loader = true;
    const params = this.page + '/' + this.rows + '/' + this.loginData.userId;
    this.apisService.getApi(environment.connectionRequestData + params).subscribe((res: any) => {
      this.loader = false;
      this.networkData = res.data.records;
      this.totalRecords = res.data.count;
    });
  }

  // It is used to get the connections data
  getConnectionsData() {
    this.loader = true;
    const relationShipType = this.relationshipType ? this.relationshipType.code : '';
    const scope = this.scopesType ? this.scopesType.code : '';
    // tslint:disable-next-line:max-line-length
    const params = this.page + '/' + this.rows + '/' + this.loginData.userId + '?status=' + this.status + '&relationship=' + relationShipType + '&scope=' + scope;
    this.apisService.getApi(environment.connectionsData + params).subscribe((res: any) => {
      this.count = res.data.count;
      if (this.count !== 0) {
        this.partnerId = res.data.records[0].partnerId;
        sessionStorage.setItem('partnerId', this.partnerId);
      }
      this.loader = false;
      this.networkData = res.data.records;
      this.totalRecords = res.data.count;
    });
  }
  // It is used to menu showing menu options
  toggle(event, data) {
    this.updatedRecordId = data.id;
    this.usrId = data.usrId;
    this.partnerID = data.partnerId;
    this.patnerId = this.partnerId;
    this.connectionsItems = [
      { label: 'Remove', icon: ' pi pi-times', command: () => { this.confirm(data); }, },
      // tslint:disable-next-line: max-line-length
      { label: 'Show Details', icon: 'pi pi-user', command: () => { this.getConnectionsData(); this.reqSkillSet(); this.endorsementSet(); this.showDetails(data); } },
      { label: 'Communicate', icon: 'pi pi-send', command: () => { } },
    ];
  }
  showDetails(data) {
    this.show = true;
    this.patnerId = sessionStorage.getItem('partnerId');
    this.apisService.getApi(environment.connectionShowDetails + this.patnerId).subscribe((res: any) => {
      this.networkData1 = [res];
      // this.showskills(this.networkData1)
      this.actID = res.data.accountId;
      this.userid = res.data.userId;
      this.primaryskil = res.primarySkills;
      this.secondaryskil = res.secondarySkills;
      this.endorsedSkills = res.endorsedSkills;
      const product = 1;
      // Only change code below this line
      this.endorsedsecondaryskill = [];
      this.normalprimaryskill = [];
      const endorsedprimaryskill = [];
      const normalprimaryskill = [];

      for (let i = 0; i < this.primaryskil.length; i++)
      {

                  const primary = this.endorsedSkills.includes(this.primaryskil[i]);
                  if (primary === true)
                  {
                    endorsedprimaryskill.push(this.primaryskil[i]);
                    this.endorsedprimaryskill = [...new Set(endorsedprimaryskill)];

                  }
                  else{
                    normalprimaryskill.push(this.primaryskil[i]);
                    this.normalprimaryskill = [...new Set(normalprimaryskill)];
                  }
                }
      const endorsedsecondaryskill = [];
      const normalsecondaryskill = [];
      this.endorsedsecondaryskill = [];
      this.normalsecondaryskill = [];

      for (let i = 0; i < this.secondaryskil.length; i++){

                   const secondary = this.endorsedSkills.includes(this.secondaryskil[i]);
                   if (secondary === true)
                  {
                    endorsedsecondaryskill.push(this.secondaryskil[i]);
                    this.endorsedsecondaryskill = [...new Set(endorsedsecondaryskill)];

                  }
                  else{
                    normalsecondaryskill.push(this.secondaryskil[i]);
                    this.normalsecondaryskill = [...new Set(normalsecondaryskill)];
                  }
      }
 });
  }
  homeroute() {
    this.show = false;
  }
  showDialog1(skillId) {
    this.skillTitle = this.primaryTable[skillId];
    this.primaryskillId = skillId;
    this.skillsList.forEach((element) => {
      this.primaryskillId = element.label;
    });
    this.endorsementPrimaryForm.reset();
    this.display1 = true;
  }
  showDialog2(skillId) {
    this.skillId = skillId;
    this.skillTitle = this.primaryTable[skillId];
    this.skillsList.forEach((element) => {
      this.secondaryskillId = element.label;
    });
    this.endorsementForm.reset();
    this.apisService.getApi(environment.skillsEndorsement + this.userid + '/' + this.skillId).subscribe((res) =>
    {
      this.skillofendorsed = res?.data[0];
      this.skillofendorsed ? this.disable = true : this.disable = false;
      const skillvalue = parseInt(this.skillofendorsed?.lkRating);
      this.endorsementForm.controls.endorsebtn.patchValue(this.connectionList.filter(x => x.value === skillvalue)[0]);
      this.endorsementForm.controls.comment.patchValue(this.skillofendorsed?.context);
    });
    this.display2 = true;
  }
  reqSkillSet() {
    this.apisService.getApi(environment.lookupsData + 'RELRS').subscribe((res) => {
      this.skillsList = res.data;
      this.skillsList.forEach((element) => {
        this.primaryTable[element.code] = element.label;
        this.skillscode = element.label;
      });
    });
  }
  endorsementSet() {
    this.apisService.getApi(environment.lookupsData + 'ELRT').subscribe((res) => {
      this.endorsementList = res.data;
      this.endorsementList.forEach((element) => {
        this.endorsementTable[element.code] = element.label;
      });
    });
  }
  // It is used for calculating the invitation time
  durationCal(days, createdDate) {
    if (days === undefined && createdDate === undefined) {
      return 'Connected hour(s) NA';
    }
    if (days === 0) {
      const timeStart = new Date().getHours();
      // tslint:disable-next-line:prefer-const
      let timeEnd = new Date(createdDate).getHours();
      let hours = timeStart - timeEnd;
      if (hours < 0) {
        hours = 24 + hours;
      }
      if (this.selectedCategoryList === 'Connections') {
        return 'Connected ' + hours + ' hour(s) ago';
      }
      else {
        return hours + ' hour(s) ago';
      }
    } else {
      if (this.selectedCategoryList === 'Connections') {
        return 'Connected ' + days + ' day(s) ago';
      }
      else {
        return days + ' day(s) ago';
      }
    }
  }
  // It is used for calculating the invitation time
  lastConversationCal(days, createdDate) {
    if (days === undefined && createdDate === undefined) {
      return 'Last conversation hour(s) NA';
    }
    if (days === 0) {
      // tslint:disable-next-line:prefer-const
      let timeStart = new Date().getHours();
      // tslint:disable-next-line:prefer-const
      let timeEnd = new Date(createdDate).getHours();
      let hours = timeStart - timeEnd;
      if (hours < 0) {
        hours = 24 + hours;
      }
      return 'Last conversation ' + hours + ' hour(s) ago';
    } else {
      return 'Last conversation ' + days + ' day(s) ago';
    }
  }
  updateInvitation(type) {
    const reqBody = {
      lkStatus: this.updateStatus,
      lkRelationshipType: this.selectedInviationType,
      id: this.updatedRecordId,
      usrId: this.userId,
      partnerId: this.Pid,
    };
    if (type !== 'Accept') {
      reqBody.lkRelationshipType = 'P';
      reqBody.lkStatus = 'I',
        reqBody.id = type;
      this.disable = true;
    }
    else {
      reqBody.lkStatus = 'A';
    }
    this.loader = true;
    this.apisService.putApi(environment.updateNetwork, reqBody).subscribe((res: any) => {
      this.Reqreject = res;
      if (this.Reqreject.hasOwnProperty('success') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.Reqreject.message, });
      }
      else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.Reqreject.message, });
      }
      this.disable = false;
      this.showInfo = false;
      this.loader = false;
      if (this.status === 'N') {
        this.getNetworkList('no search');
      }
      else {
        this.getNetworkData();
      }
    });
  }
  updateInvitation1(type) {
    const reqBody = {
      lkStatus: this.updateStatus,
      lkRelationshipType: this.selectedInviationType,
      id: this.updatedRecordId,
      usrId: this.userId,
      partnerId: this.Pid,
    };
    this.loader = true;
    this.apisService.putApi(environment.updateNetwork, reqBody).subscribe((res: any) => {
      this.rejectReq = res;
      if (this.rejectReq.hasOwnProperty('success') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.rejectReq.message, });
      }
      else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.rejectReq.message, });
      }
      this.loader = false;
    });
    this.getConnectionsData();
  }
  // It is used to show confirmation for the connection update
  showConnectionsConfirmation(action) {
    if (action === 'no') {
      this.displayConnectionConfirmation = false;
      return;
    }
    else {
      this.displayConnectionConfirmation = false;
      this.updateInvitation('Accept');
      return;
    }
  }
  convertImg(imgPath) {
    const data = {
      attPath: imgPath
    };
    this.apisService.postApi(environment.downloadAttach, data).subscribe((res: any) => {
      return res.downloadUrl;
    });
  }
  confirm(data) {
    this.display = true;
    this.updateStatus = data.lkRelationshiType;
    this.connectionUpdateId = data.id;
  }
  confirmRequest(data) {
    this.requestId = data.id;
    this.confirmingRequest = true;
  }
  saveNetwork(action) {
    if (action === 'no') {
      this.confirmingRequest = false;
      return;
    }
    else {
      const reqBody = {
        usrId: this.loginData.userId,
        partnerId: this.requestId,
        lkRelationshipType: '',
        lkScope: '',
        comments: '',
        officialEmail: '',
        personalEmail: '',
        officialPhone: '',
        mobile: '',
        lkCommPreference: ''
      };
      this.apisService.postApi(environment.saveNetwork, reqBody).subscribe((res: any) => {
        this.Requestresponse = res;
        if (this.Requestresponse.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.Requestresponse.message, });
        }
        else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.Requestresponse.message, });
        }
      });
      this.getNetworkList('no search');
      this.confirmingRequest = false;
    }
  }
  showConfirmation(action) {
    if (action === 'no') {
      this.display = false;
      return;
    }
    else {
      this.updateInvitation1(this.connectionUpdateId);
      this.display = false;
    }
  }
  getNetworkList(type) {
    if (type === 'search' && this.searchNetoworkTerm.length >= 3) {
      this.page = 1;
      this.rows = 10;
      const searchTerm = this.searchNetoworkTerm ? this.searchNetoworkTerm : '';
      const params = this.page + '/' + this.rows + '/' + '?name=' + searchTerm;
      this.loader = true;
      this.apisService.getApi(environment.networkList + params).subscribe((res: any) => {
        this.loader = false;
        this.networkData = res.data.records;
        this.totalRecords = res.data.count;
      });
    }
    else if (type === 'no search') {
      const searchTerm = this.searchNetoworkTerm ? this.searchNetoworkTerm : '';
      const params = this.page + '/' + this.rows + '/' + '?name=' + searchTerm;
      this.loader = true;
      this.apisService.getApi(environment.networkList + params).subscribe((res: any) => {
        this.loader = false;
        this.networkData = res.data.records;
        this.totalRecords = res.data.count;
      });
    }
  }
  confirmIgnore(data) {
    this.confirmingIgnore = true;
    this.updateStatus = data.lkRelationshiType;
    this.ignoreId = data.id;
    this.usrId = data.usrId;
    this.patnerId = data.partnerId;
  }
  ignoreInvitation(action) {
    if (action === 'no') {
      this.confirmingIgnore = false;
      return;
    }
    else {
      this.updateInvitation(this.ignoreId);
      this.confirmingIgnore = false;
      return;
    }
  }
  paginate(event, tab) {
    let i = 1;
    // this.page = event.first + 1;
    this.rows = event.rows;
    this.page = event.page + i++;
    if (tab === 'network') {
      this.getNetworkList('no search');
    }
    else if (tab === 'invitations') {
      this.getNetworkData();
    }
  }
  invitationInfo(data) {
    this.loader = true;
    this.partner = data.partnerId;
    this.reqSkillSet();
    this.apisService.getApi(environment.connectionShowDetails + this.partner).subscribe((res: any) => {
      this.invitation = res;
      this.acadamics = res.academics;
      this.license = res.license;
      this.certification = res.certification;
      this.primaryskil = res.primarySkills;
      this.secondaryskil = res.secondarySkills;
      this.loader = false;
    });
    this.invitationsData = data;
    this.showInfo = true;
  }
  closeInfo() {
    this.showInfo = false;
  }
  saveEndorserment() {
    this.hide = true;
    if (this.endorsementForm.invalid) {
      const controls = this.endorsementForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    } else {
      this.disable = true;
      const actId = JSON.parse(this.apisService.decodeData());
      this.user = actId.userId;
      const data = {
        usrId: this.userid,
        usrAccId: this.actID,
        reviewerId: this.loginData.userId,
        reviewerAccId: actId.accId,
        skillId: this.skillId,
        lkRating: this.endorsementForm.controls.endorsebtn.value.value,
        context: this.endorsementForm.controls.comment.value,
      };
      this.apisService.postApi(environment.skillEndorsement, data).subscribe((res: any) => {
        this.hide = false;
        this.getData = res;
        this.disable = false;
        this.loader = false;
        if (this.getData.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message });
          this.display2 = false;
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message });
          this.loader = false;
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error });
          this.loader = false;
        }
        this.endorsementForm.reset();
        this.showDetails(data);
      });
    }
  }
  savePrimaryEndorserment() {
    this.hide = true;
    if (this.endorsementPrimaryForm.invalid) {
      const controls = this.endorsementPrimaryForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    } else {
    }
  }
  ignorerequest(data) {
    this.loader = true;
    this.apisService.deleteApi(environment.Ignore + data.id + '/' + data.autoId).subscribe((res: any) => {
      this.ignorereq = res;
      if (this.ignorereq.hasOwnProperty('success') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.ignorereq.message, });
        this.loader = false;
      }
      else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.ignorereq.message, });
        this.loader = false;
      }
      this.getConnectionRequestData();
    });
  }

}
