import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-vendor-details',
  templateUrl: './vendor-details.component.html',
  styleUrls: ['./vendor-details.component.scss'],
  providers: [DatePipe, MessageService],
})
export class VendorDetailsComponent implements OnInit {
  display: boolean;
  VendorDetailsForm: FormGroup;
  countryOptions: any;
  stateoptions: any;
  industryOptions: any = [];
  regionOptions: any = [];
  accountId: any;
  vendorDetails: any;
  specializationSkills: any;
  orgIdValue: any;
  userIdValue: any;
  specializationSkillsValues: any;
  staffingModeValues: any;
  specializationSkillsMultipleIds: any;
  staffingModeMultipleValues: any;
  VendorSpecializationMultipleValues: any;
  status: any[];
  EmployeeData: any;
  x: any;
  // It is used for displaying status
  status2 = [
    { code: 'A', id: 'ULS', label: 'Active' },
    { code: 'I', id: 'ULS', label: 'Inactive' },
  ];
  // It is used for the displaying the diversity Dropdown Values
  diversity = [
    { label: 'None', value: 'None' },
    { label: 'Women\'s Business Enterprise (WBE)', value: 'Women\'s Business Enterprise (WBE)', },
    { label: 'Minority Business Enterprise (MBE)', value: 'Minority Business Enterprise (MBE)', },
    { label: 'Other', value: 'Other' },
  ];
  // It is used for the displaying the ownerShip Dropdown Values
  ownerShip = [
    { label: 'Private', value: 'Private' },
    { label: 'Public', value: 'Public' },
  ];
  // It is used for the displaying the staffingmode Dropdown Values
  staffingmode = [
    { label: 'Contract', value: 'Contract' },
    { label: 'Permanent', value: 'Permanent' },
    { label: 'SOW', value: 'SOW' },
  ];
  // It is used for the displaying the Specialization Dropdown Values
  vendorSpecialization = [
    { label: 'IT', value: 'IT' },
    { label: 'Engineering', value: 'Engineering' },
    { label: 'Operations', value: 'Operations' },
    { label: 'Sales', value: 'Sales' },
  ];
  url: any;
  imagePath: any;
  pathExtension: any;
  fileName: any;
  imageSuccess: boolean;
  vendorDetailsLogoResponse: any;
  vendorSpecilationValue: any;
  roleId: any;
  loader: boolean;
  countrybasedRegion: boolean;
  basedTaxId: boolean;
  panNumber: boolean;
  businessType: any = [];
  accTID: any;
  addID: any;
  accID: any;
  acctType: any;
  tireId: any;
  tireName: any;
  actName: any;
  noofEmployees: any;
  radiovalue: void;
  disable: boolean;
  status1: any[];
  empflag: any;
  accountid: any;
  gridaccId: any;
  disableradio: boolean;
  srcoptions: any = [];
  imagedisbale: boolean;
  accimage: any;
  acctype: any;
  countrycodedropdown: any = [];
  phSpinnerHide: boolean;
  mobileNum: any;
  officenummsg: string;
  phCheckHide: boolean;
  displayNumber: boolean;
  imgid: any;
  usrroleid: any;
  displayActive: boolean;
  confirmationData: any;
  removebtn: boolean;
  confirmbtn: boolean;
  removebtnShow: boolean;
  constructor(
    private fb: FormBuilder,
    private apisService: ApisService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    private routes: Router
  ) {
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.empflag = routeParams.params.empflag;
    const acctdetails = JSON.parse(this.apisService.decodeData());
    this.accTID = JSON.parse(sessionStorage.getItem('acct_rowid'));
    this.breadcrumbService.setItems([
      { label: 'Accounts', routerLink: '/accounts' },
      { label: 'Account Details', routerLink: '/accounts-vendor-menu/vendor-details' + this.accTID, },
    ]);

    this.countryDropdown();
    this.industryDropdown();
    this.regionDropdown();
    this.specialization();
    this.businesTypeDD();
    this.employeeDD();
    this.validateradio();
    this.accountId = this.route.snapshot.paramMap.get('id');
    sessionStorage.setItem('qid', this.accountId);
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
      this.roleId = JSON.parse(apisService.decodeData()).roles[0].id;
    } else {
      this.roleId = this.usrroleid;
    }
    this.accountid = JSON.parse(apisService.decodeData()).userId;
    this.orgIdValue = JSON.parse(sessionStorage.getItem('orgId'));
    this.userIdValue = JSON.parse(sessionStorage.getItem('userId'));
    this.accTID = JSON.parse(sessionStorage.getItem('acct_rowid'));
    this.acctype = sessionStorage.getItem('acctpe');
  }
  ngOnInit() {
    this.imagedisbale = this.empflag === 'acctemp' ? this.imagedisbale = true : this.imagedisbale = false;
    this.VendorDetailsForm = this.fb.group({
      VendorName: [''],
      VendorUrl: [''],
      VendorStatus: ['', Validators.required],
      VendormailExtension: [''],
      VendorAddress1: ['', Validators.maxLength(50)],
      VendorAddress2: ['', Validators.maxLength(50)],
      VendorVCity: ['', Validators.maxLength(20)],
      VendorVZip: ['', [Validators.maxLength(10),Validators.pattern('^[0-9-]*$')]],
      VendorCountry: ['', Validators.required],
      VendorState: [''],
      Vendorphone: [''],
      VendorFax: ['', [Validators.maxLength(15),Validators.pattern('^[0-9-]*$')]],
      VendorIndustry: [''],
      VendorRegion: [''],
      VendorNoofEmployees: [''],
      VendorTaxid: ['', [Validators.maxLength(20),Validators.pattern('^[0-9-]*$')]],
      VendorStockSymbol: ['', Validators.maxLength(10)],
      VendorRevenue: ['', [Validators.maxLength(9), Validators.pattern('^[0-9.]*$')]],
      VendorDunBradStreetNo: ['', Validators.maxLength(12)],
      VendorBusinessType: [''],
      VendorDiversity: [''],
      VendorNotes: ['', Validators.maxLength(200)],
      VendorAccountNo: [''],
      mailbox: ['', [Validators.maxLength(10)]],
      Emp: [''],
      source: [''],
      phoneDrp: ['']
    });
    if (this.roleId === 8) {
      this.VendorDetailsForm.get('VendorStatus').disable();
    } else {
      this.VendorDetailsForm.get('VendorStatus').enable();
    }
    this.statusDD();
    this.sourceDropdwon();
    this.vendorDetailsBasedOnAccountId();
    this.getcountrydrop();
  }
  // Onchange event for phone number codes for future reference
  countryids(event) {
  }
  // It used to get phone number codes dropdown
  getcountrydrop() {
    this.countrycodedropdown = this.apisService.getdropdwon();
  }
  // It is used to show number of employees dropdown
  employeeDD() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'EMP').subscribe((res: any) => {
      this.EmployeeData = res.data;
    });
  }
  // It is used for the displaying the country Dropdown Values
  countryDropdown() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'ADLC').subscribe((res) => {
      this.countryOptions = res.data;
    });
  }
  // It is used to show source dropdown values
  sourceDropdwon() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'SOF').subscribe((res) => {
      this.srcoptions = res.data;
    });
  }
  // It is used for the displaying the state Dropdown based on country Values
  stateDropdown(code) {
    if (code === 'USA') {
      this.basedTaxId = true;
      this.panNumber = false;
    } else {
      this.VendorDetailsForm.controls.VendorRegion.reset();
      this.basedTaxId = false;
      this.panNumber = true;
    }
    if (code === null || code === '' || code === undefined) {
      this.stateoptions = [];
    }
    else {  // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.state + '/' + code).subscribe((res) => {
        this.stateoptions = res.data;
        this.VendorDetailsForm.get('VendorState').patchValue(this.stateoptions.filter((x) => x.code === this.vendorDetails[0].state)[0]);
      });
    }

  }
  // It is used for the displaying the industry Dropdown Values
  industryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'ALIT').subscribe((res: any) => {
      this.industryOptions = res.data;
    });
  }
  // It is used for the displaying the industry Dropdown Values
  regionDropdown() {
    this.apisService.getApi(environment.lookupsData + 'ALR').subscribe((res) => {
      this.regionOptions = res.data;
    });
  }
  // It is used for the displaying the specialization Dropdown Values
  specialization() {
    this.apisService.getApi(environment.lookupsData + 'RELRS').subscribe((res) => {
      this.specializationSkills = res.data;
    });
  }
  // It is used for the displaying the Business Type Dropdown Values
  businesTypeDD() {
    this.apisService.getApi(environment.lookupsData + 'ALBE').subscribe((res: any) => {
      this.businessType = res.data;
    });
  }
  // It is used for the displaying the  Status Dropdown Values
  statusDD() {
    this.apisService.getApi(environment.lookupsData + 'ULS').subscribe((res: any) => {
      this.status = res.data;
    });
  }
  // It is used to show get account details and to bind it
  vendorDetailsBasedOnAccountId() {
    this.loader = true;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.Accountget + this.accTID).subscribe((res: any) => {
      this.vendorDetails = res.data;
      this.status1 = this.vendorDetails[0].status === 'R' ? this.status : this.status2;
      this.addID = res.data[0].addId;
      this.accID = res.data[0].accId;
      this.imgid = res.data[0].logoDocId;
      this.acctType = res.data[0].accType;
      this.accimage = this.vendorDetails[0].logo;
      sessionStorage.setItem('Act_type', this.acctType);
      this.loader = false;
      if (this.vendorDetails[0].noOfEmployees != null && this.vendorDetails[0].noOfEmployees !== ' ') {
        this.noofEmployees = this.vendorDetails[0].noOfEmployees.toString();
      } else {
        this.noofEmployees = this.vendorDetails[0].noOfEmployees;
      }

      if (this.vendorDetails[0].status === 'R') {
        this.removebtn = true;
      } else {
        this.removebtn = false;
      }

      this.actName = this.vendorDetails[0].accName;
      this.stateDropdown(this.vendorDetails[0].country);
      this.VendorDetailsForm.get('VendorName').patchValue(this.vendorDetails[0].accName);
      this.VendorDetailsForm.get('VendorUrl').patchValue(this.vendorDetails[0].website);
      this.VendorDetailsForm.get('mailbox').patchValue(this.vendorDetails[0].mailbox);
      this.VendorDetailsForm.get('VendorStatus').patchValue(this.status.filter((x) => x.code === this.vendorDetails[0].status)[0]);
      this.VendorDetailsForm.get('source').patchValue(this.srcoptions.filter((x) => x.code === this.vendorDetails[0]?.source)[0]);
      this.VendorDetailsForm.get('VendormailExtension').patchValue(this.vendorDetails[0].emailExt);
      this.VendorDetailsForm.get('VendorAddress1').patchValue(this.vendorDetails[0].address1);
      this.VendorDetailsForm.get('VendorAddress2').patchValue(this.vendorDetails[0].address2);
      this.VendorDetailsForm.get('VendorVCity').patchValue(this.vendorDetails[0].city);
      this.VendorDetailsForm.get('VendorVZip').patchValue(this.vendorDetails[0].zip);
      // tslint:disable-next-line:max-line-length
      this.VendorDetailsForm.get('VendorCountry').patchValue(this.countryOptions.filter((x) => x.code === this.vendorDetails[0].country)[0]);
      this.stateDropdown(this.vendorDetails[0].country);
      this.VendorDetailsForm.get('Vendorphone').patchValue(this.vendorDetails[0].phone);
      this.VendorDetailsForm.get('VendorFax').patchValue(this.vendorDetails[0].fax);
      this.VendorDetailsForm.get('VendorIndustry').patchValue(
        this.industryOptions.filter((x) => x.code === this.vendorDetails[0].lkIndustryType)[0]);
      this.VendorDetailsForm.get('VendorRegion').patchValue(
        this.regionOptions.filter((x) => x.code === this.vendorDetails[0].region)[0]);
      this.VendorDetailsForm.get('VendorNoofEmployees').patchValue(this.noofEmployees);
      this.radiovalue = this.VendorDetailsForm.get('Emp').patchValue(this.vendorDetails[0].empApprovalReqFlag);
      if (this.radiovalue === this.VendorDetailsForm.controls.Emp.value) {
        this.VendorDetailsForm.controls.Emp.value(true);
      }
      this.VendorDetailsForm.get('VendorTaxid').patchValue(this.vendorDetails[0].taxId);
      this.VendorDetailsForm.get('VendorStockSymbol').patchValue(this.vendorDetails[0].stockSymbol);
      this.VendorDetailsForm.get('VendorRevenue').patchValue(this.vendorDetails[0].revenue);
      this.VendorDetailsForm.get('VendorDunBradStreetNo').patchValue(this.vendorDetails[0].dunsNo);
      this.VendorDetailsForm.get('VendorBusinessType').patchValue(
        this.businessType.filter((x) => x.value === this.vendorDetails[0].businessType)[0]);
      this.VendorDetailsForm.get('VendorDiversity').patchValue(
        this.diversity.filter((x) => x.value === this.vendorDetails[0].diversity)[0]);
      this.VendorDetailsForm.get('VendorNotes').patchValue(this.vendorDetails[0].description);

      // tslint:disable-next-line:max-line-length
      this.VendorDetailsForm.controls.phoneDrp.value === null || this.VendorDetailsForm.controls.phoneDrp.value === '' ? this.VendorDetailsForm.controls.phoneDrp.patchValue({
        code: '+1',
        flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png',
        id: '2',
        label: 'UNITED STATES'
        // tslint:disable-next-line:max-line-length
      }) : this.VendorDetailsForm.get('phoneDrp').patchValue(this.countrycodedropdown.filter((x) => x.id === this.vendorDetails[0].mobileCcode)[0]);
    });
  }

  changePhoto() {
    this.display = true;
  }

  // It is used for the delete the Vendor or Customer Accounts 
  deleteVendor() {
    this.displayActive = true;
  }

  cancel() {
    this.displayActive = false;
  }

  saveUserDetails() {
    this.confirmbtn = true;
    this.loader = true;
    this.apisService.getApi(environment.deleteConfirmation + this.accTID).subscribe((res: any) => {
      this.loader = false;
      this.confirmationData = res.data;
      if (res.success === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message
      });        
        this.removebtnShow = true;
        setTimeout(()=>{this.routes.navigate(['/accounts']);}, 2000)
      } else if (res.hasOwnProperty('failed') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
      } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
      }
      this.displayActive = false;
      this.confirmbtn = false;
    });
  }

  // It is used for the updating the update Vendor Details and keep the validations
  updateVendorDetails() {
    if (this.acctype === 'V' && this.VendorDetailsForm.get('VendorStatus').value.code === 'I') {
      this.inactivestatus();
    }
    if (this.VendorDetailsForm.invalid) {
      const controls = this.VendorDetailsForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
        }
      }
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      const reqbody = {
        addId: this.addID,
        accId: this.accID,
        address1: this.VendorDetailsForm.get('VendorAddress1').value,
        address2: this.VendorDetailsForm.get('VendorAddress2').value,
        // tslint:disable-next-line:max-line-length
        businessType: this.VendorDetailsForm.get('VendorBusinessType').value === '' || this.VendorDetailsForm.get('VendorBusinessType').value === null || this.VendorDetailsForm.get('VendorBusinessType').value === undefined ? 'A' : this.VendorDetailsForm.get('VendorBusinessType').value.code,
        city: this.VendorDetailsForm.get('VendorVCity').value,
        // tslint:disable-next-line:max-line-length
        country: this.VendorDetailsForm.get('VendorCountry').value === '' || this.VendorDetailsForm.get('VendorCountry').value === undefined || this.VendorDetailsForm.get('VendorCountry').value === null ? 0 : this.VendorDetailsForm.get('VendorCountry').value.code,
        description: this.VendorDetailsForm.get('VendorNotes').value,
        dunNo: this.VendorDetailsForm.get('VendorDunBradStreetNo').value,
        fax: this.VendorDetailsForm.get('VendorFax').value,
        // tslint:disable-next-line:max-line-length
        industry: this.VendorDetailsForm.get('VendorIndustry').value === '' || this.VendorDetailsForm.get('VendorIndustry').value === undefined || this.VendorDetailsForm.get('VendorIndustry').value === null ? 0 : this.VendorDetailsForm.get('VendorIndustry').value.code,
        mailBox: this.VendorDetailsForm.get('mailbox').value,
        // tslint:disable-next-line:max-line-length
        noOfEmployee: this.VendorDetailsForm.get('VendorNoofEmployees').value === '' || this.VendorDetailsForm.get('VendorNoofEmployees').value === undefined || this.VendorDetailsForm.get('VendorNoofEmployees').value === null ? 0 : this.VendorDetailsForm.get('VendorNoofEmployees').value,
        phone: this.VendorDetailsForm.get('Vendorphone').value,
        // tslint:disable-next-line:max-line-length
        region: this.VendorDetailsForm.get('VendorRegion').value === '' || this.VendorDetailsForm.get('VendorRegion').value === undefined || this.VendorDetailsForm.get('VendorRegion').value === null ? 'A' : this.VendorDetailsForm.get('VendorRegion').value.code,
        // tslint:disable-next-line:max-line-length
        revenu: this.VendorDetailsForm.get('VendorRevenue').value === '' || this.VendorDetailsForm.get('VendorRevenue').value === undefined || this.VendorDetailsForm.get('VendorRevenue').value === null ? null : this.VendorDetailsForm.get('VendorRevenue').value,
        // tslint:disable-next-line:max-line-length
        state: this.VendorDetailsForm.get('VendorState').value === '' || this.VendorDetailsForm.get('VendorState').value === undefined || this.VendorDetailsForm.get('VendorState').value === null ? 0 : this.VendorDetailsForm.get('VendorState').value.code,
        stockSymbol: this.VendorDetailsForm.get('VendorStockSymbol').value,
        taxId: this.VendorDetailsForm.get('VendorTaxid').value,
        zip: this.VendorDetailsForm.get('VendorVZip').value,
        logoDocId: this.imgid,
        // tslint:disable-next-line:max-line-length
        status: this.VendorDetailsForm.get('VendorStatus').value === '' || this.VendorDetailsForm.get('VendorStatus').value === undefined || this.VendorDetailsForm.get('VendorStatus').value === null ? 'A' : this.VendorDetailsForm.get('VendorStatus').value.code,
        // tslint:disable-next-line:max-line-length
        source: this.VendorDetailsForm.get('source').value === '' || this.VendorDetailsForm.get('source').value === undefined || this.VendorDetailsForm.get('source').value === null ? '' : this.VendorDetailsForm.get('source').value.code,
        empApprovalReqFlag: this.VendorDetailsForm.get('Emp').value,
        mccode: this.VendorDetailsForm.controls.phoneDrp.value === null || this.VendorDetailsForm.controls.phoneDrp.value === undefined || '' ? '' : this.VendorDetailsForm.controls.phoneDrp.value.id,
      };
      this.loader = true;

      // tslint:disable-next-line: deprecation
      this.apisService.putApi(environment.Accountget, reqbody).subscribe((res: any) => {
        this.loader = false;
        if (res.hasOwnProperty('success') === true) {
          this.vendorDetailsBasedOnAccountId();
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
        }
      });
    }
  }
  // It is used for select the image on Vendor Details
  onSelectFile(event) {
    const chooseImgPath = event.target.files[0].name;
    this.pathExtension = chooseImgPath.substring(chooseImgPath.indexOf('.') + 1);
    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    this.fileName = file.name;
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    const reader = e.target;
    this.url = reader.result;
    this.imageSuccess = true;
  }
  // It is used for upload the image on Vendor Details
  uploadAccountDetailsLogoImage() {
    const data = this.url;
    const imageIURL = data.substring(data.indexOf(',') + 1);
    const body = {
      fileExtension: this.pathExtension,
      accountId: this.accountId,
      base64: imageIURL,
    };
  }
  validateradio() {

  }

  inactivestatus() {
    this.apisService.getApi(environment.accinactive + this.accountId).subscribe((res: any) => {
    });
  }
  checkPhone() {
    this.displayNumber = false;
    const num = this.VendorDetailsForm.controls.Vendorphone.value;
    if (num === '' || num === null || num === undefined || num === '(___) ___-____') {
    }
    else {
      this.phSpinnerHide = true;
      this.apisService.getApi(environment.phonerequired + num).subscribe(res => {
        this.mobileNum = res;
        this.mobileNum = res.data.isNumberExisted;
        if (this.mobileNum === true) {
          this.phSpinnerHide = false;
          this.officenummsg = 'Phone number already exist';
          setTimeout(() => {
            this.officenummsg = '';
          }, 5000);
          this.VendorDetailsForm.get('Vendorphone').reset();
        }
        else {
          this.phSpinnerHide = false;
          this.phCheckHide = true;
          setTimeout(() => {
            this.phCheckHide = false;
          }, 3000);
        }
      });
    }
  }
}
