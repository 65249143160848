import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-authenticate-password',
  templateUrl: './authenticate-password.component.html',
  styleUrls: ['./authenticate-password.component.scss'],
  providers: [DatePipe, MessageService],
})
export class AuthenticatePasswordComponent implements OnInit {
  name: any;
  display = true;
  names: any;
  emailId: any;
  resetUsrPwdForm: FormGroup;
  forgotpwdForm: FormGroup;
  hide = true;
  hideIcon = true;
  showIcon: boolean;
  hideNewPWDIcon = true;
  hideCfmPWDIcon = true;
  showNewPWDIcon: boolean;
  hide1 = true;
  showCfmPWDIcon: boolean;
  hide2 = true;
  text: string;
  capitalLetterRegEx: string;
  numberRegEx: string;
  smallLetterRegEx: string;
  disable: boolean;
  files: any;
  success: string;
  loader: boolean;
  successScreen: boolean;
  displayForgotDialog: boolean;
  resultData: any;
  resetEmail: any;
  // tslint:disable-next-line: max-line-length
  constructor(private route: ActivatedRoute, private messageService: MessageService, private fb: FormBuilder, private apisService: ApisService, private routes: Router) { }
  ngOnInit(): void {
    this.resetUsrPwdForm = this.fb.group({
      password: ['', [Validators.required, Validators.maxLength(20), Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&+-])[A-Za-z\d@$!%*#?&+-]{8,}$/),]],
      conformPassword: ['', [Validators.required, Validators.maxLength(20), Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&+-])[A-Za-z\d@$!%*#?&+-]{8,}$/),]],
    });
    // tslint:disable-next-line: deprecation
    this.route.queryParams.subscribe(params => {
      this.name = params.sessionId;
    });
    this.forgotpwdForm = this.fb.group({
      emailId: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.com')]]
    });
    this.verify();
  }
  verify() {
    // tslint:disable-next-line: deprecation
    // this.apisService.getApi(`https://uat-portal-api.servicesbay.com/v1/token-expiry?token=${this.name}`).subscribe((res) => { // UAT URL
      this.apisService.getApi(`https://portal-api.servicesbay.com/v1/token-expiry?token=${this.name}`).subscribe((res) => {  // PROD URL
      this.display = res.success;
      setTimeout(() => {
      }, 1000);
      this.names = res.name;
      this.emailId = res.email;
    });
  }
  goto() {
    this.routes.navigate(['/login']);
  }
  myFunction(data) {
    if (data === 1) {
      this.hide = !this.hide;
      this.hideIcon = false;
      this.showIcon = true;
    }
    if (data === 2) {
      this.hide1 = !this.hide1;
      this.hideNewPWDIcon = false;
      this.showNewPWDIcon = true;
    }
    if (data === 3) {
      this.hide2 = !this.hide2;
      this.hideCfmPWDIcon = false;
      this.showCfmPWDIcon = true;
    }
  }
  myFunction1(data) {
    if (data === 1) {
      this.hide = !this.hide;
      this.hideIcon = true;
      this.showIcon = false;
    }
    if (data === 2) {
      this.hide1 = !this.hide1;
      this.hideNewPWDIcon = true;
      this.showNewPWDIcon = false;
    }
    if (data === 3) {
      this.hide2 = !this.hide2;
      this.hideCfmPWDIcon = true;
      this.showCfmPWDIcon = false;
    }
  }
  onSearchChange(searchValue: string): void {
    this.text = searchValue;
    this.capitalLetterRegEx = '.*[A-Z]+.*';
    this.smallLetterRegEx = '.*[a-z]+.*';
    this.numberRegEx = '.*[0-9]+.*';
    if (this.text.search(this.capitalLetterRegEx) === -1) {
      this.resetUsrPwdForm.controls.upper.patchValue(false);
    } else {
      this.resetUsrPwdForm.controls.upper.patchValue(true);
    }
    if (this.text.search(this.smallLetterRegEx) === -1) {
      this.resetUsrPwdForm.controls.lower.patchValue(false);
    } else {
      this.resetUsrPwdForm.controls.lower.patchValue(true);
    }
    if (this.text.search(this.numberRegEx) === -1) {
      this.resetUsrPwdForm.controls.number.patchValue(false);
    } else {
      this.resetUsrPwdForm.controls.number.patchValue(true);
    }
    if (this.text.length < 8) {
      this.resetUsrPwdForm.controls.eight.patchValue(false);
    } else {
      this.resetUsrPwdForm.controls.eight.patchValue(true);
    }
  }
  resetPasswords() {
    const password = this.resetUsrPwdForm.controls.password.value;
    const conformPassword = this.resetUsrPwdForm.controls.conformPassword.value;
    if (this.resetUsrPwdForm.invalid) {
      const controls = this.resetUsrPwdForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      if (password === '' || conformPassword === '') {
        if (password === '') {
          this.disable = false;
        } else {
          this.disable = false;
        }
      }
      else {
        if (password === conformPassword) {
          const data = {
            email: this.emailId,
            password: conformPassword,
          };
          this.disable = true;
          // tslint:disable-next-line: deprecation
          this.apisService.postApi(environment.forgetPassword, data).subscribe((res: any) => {
            this.files = res;
            // tslint:disable-next-line: no-conditional-assignment
            if (this.files.success === true) {
              this.messageService.add({ key: 'br', severity: '', life: 4000, detail: this.files.message });
              this.disable = false;
              this.successScreen = true;
            }
            this.goto();
          });
        } else {
          // tslint:disable-next-line:max-line-length
          this.messageService.add({ key: 'br', severity: '', life: 4000, detail: 'New Password and confirm new password should be same.', });
          this.disable = false;
        }
      }
    }
  }
  forgotPassword() {
    this.displayForgotDialog = true;
  }
  password() {
    this.loader = true;
    const EmailId = this.forgotpwdForm.controls.emailId.value === null ? '' : this.forgotpwdForm.controls.emailId.value;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.resetpassword + EmailId).subscribe((res) => {
      this.resultData = res;
      this.resetEmail = res.data;
      if (this.resultData.success === true) {
        Swal.fire({
          // tslint:disable-next-line:max-line-length
          text: 'We sent you link to reset your password.', icon: 'success', confirmButtonColor: '#0D416B', iconColor: '#1dbb99', confirmButtonText: 'Ok', reverseButtons: false,
        }).then((result) => {
          if (result.value) {
            this.displayForgotDialog = false;
            return this.goto();
          }
        });
        this.loader = false;
      } else {
        Swal.fire({
          text: 'User not exist', icon: 'error', confirmButtonColor: '#0D416B', confirmButtonText: 'Ok', reverseButtons: false
        }).then((result): void => {
          if (result.value) {
            this.displayForgotDialog = false;
            this.goto();
          }
        });
        this.loader = false;
      }
    });
  }
}
