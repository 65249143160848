import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-subcontractor',
  templateUrl: './subcontractor.component.html',
  styleUrls: ['./subcontractor.component.scss'],
  providers: [DatePipe, MessageService],
})
export class SubcontractorComponent implements OnInit {
  first: any;
  rows: any = 10;
  pageCount: any;
  page: any = 1;
  totalrecods: any;
  statusDD: any;
  records: boolean;
  loading: boolean;
  subContractortableData: any;
  subContractorGridData: any;
  table: boolean;
  vendorAssociatedCustomertableData: any;
  data: string;
  country: any;
  state: any;
  statusLabel = {};
  industry: any;
  industryLabel = {};
  vendorAssociatedStaffingMode: any[];
  vendorAssociatedCustomerForm: any;
  // tslint:disable-next-line: max-line-length
  items: ({ label: string; icon: string; disable: boolean; command: (event: any, i: any) => void; } | { label: string; icon: string; command: (event: any, i: any) => void; disable?: undefined; })[];

  // tslint:disable-next-line: max-line-length
  constructor(private fb: FormBuilder, private router: Router, private apisService: ApisService, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Subcontractors', routerLink: '/subcontactors' },
    ]);
  }
  cols = [
    { field: 'name', header: 'Sub Contrator Name' },
    { field: 'webSite', header: 'Corporate Website' },
    { field: 'phone', header: 'Phone Number' },
    { field: 'status', header: 'Status' },
    { field: 'industry', header: 'Industry' },
    { field: 'tierName', header: 'Tiers' },
    { field: 'createdDate', header: 'Date Added' },
    { field: 'action', header: 'Action' },
  ];

  ngOnInit(): void {
    this.vendorAssociatedCustomerForm = this.fb.group({
      vendorName: [''],
      vendorUrl: [''],
      vendorStatus: [''],
      vendorIndustry: [''],
      vendorCountry: [''],
      vendorState: [''],
      vendorStaffingMode: [''],
    });
    this.gridData();
    this.getCountryDropdown();
    this.getStatusData();
    this.getIndustryDropdown();
  }
  gridData() {
    const rows = this.rows;
    const page = this.page;
    const data = page + '/' + rows;
    this.loading = true;
    this.apisService.getApi(environment.vendorAccsocitegrid + data).subscribe((res: any) => {
      this.vendorAssociatedCustomertableData = res.data;
      this.loading = false;
      this.totalrecods = res.total;
      if (this.totalrecods === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.searchData();
  }
  reset() {
    this.vendorAssociatedCustomerForm.reset();
    this.records = false;

    this.gridData();
  }
  toggle(event, data, rowData) {
    this.items = [
      {
        label: 'Details',
        icon: 'pi pi-info-circle',
        disable: true,
        // tslint:disable-next-line:no-shadowed-variable
        command: (event, i) => {
          this.editSubContractor(data);
        },
      }
    ];
  }

  editSubContractor(data) {
    sessionStorage.setItem('subContractorName', data.name);
    sessionStorage.setItem('subContractorId', data.id);
    this.router.navigate(['/edit-subcontractor', data.id], {
      queryParams: {
        type: 3,
        sName : data.name
      },
    });
  }

  getCountryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'adlc').subscribe((res) => {
      this.country = res.data;
    });
  }
  getStateByUrl(country) {
    this.vendorAssociatedCustomerForm.controls.vendorState.setValue('');
    if (country === null || '' || undefined) { }
    else {
      const states = country;
      this.apisService.getApi(environment.states + states.code).subscribe((res) => {
        this.state = res.data;
      });
    }

  }
  getStatusData() {
    this.apisService.getApi(environment.lookupsData + 'RLS').subscribe((res) => {
        this.statusDD = res.data;
        this.statusDD.forEach((element) => {
          this.statusLabel[element.code] = element.label;
        });
      });
  }
  getIndustryDropdown() {
    this.apisService
      .getApi(environment.lookupsData + 'ALIT')
      .subscribe((res) => {
        this.industry = res.data;
        this.industry.forEach((element) => {
          this.industryLabel[element.code] = element.label;
        });
      });
  }
  searchData() {
    const associatedStaffingMode =
      this.vendorAssociatedCustomerForm.controls.vendorStaffingMode.value;
    this.vendorAssociatedStaffingMode = [];
    if (this.vendorAssociatedCustomerForm.controls.vendorStaffingMode.value) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < associatedStaffingMode.length; i++) {
        this.vendorAssociatedStaffingMode.push(associatedStaffingMode[i].value);
      }
    }
    const staffingMode =
      this.vendorAssociatedCustomerForm.controls.vendorStaffingMode.value;
    this.loading = true;
    const pgNo = this.page;
    const recordPage = this.rows;
    const accountName =
      this.vendorAssociatedCustomerForm.controls.vendorName.value === null
        ? ''
        : this.vendorAssociatedCustomerForm.controls.vendorName.value;
    const accountUrl =
      this.vendorAssociatedCustomerForm.controls.vendorUrl.value === null
        ? ''
        : this.vendorAssociatedCustomerForm.controls.vendorUrl.value;
    const accCountry =
      this.vendorAssociatedCustomerForm.controls.vendorCountry.value === null ||
        ''
        ? ''
        : this.vendorAssociatedCustomerForm.controls.vendorCountry.value
          .code === undefined
          ? ''
          : this.vendorAssociatedCustomerForm.controls.vendorCountry.value.code;
    const accState =
      this.vendorAssociatedCustomerForm.controls.vendorState.value === null ||
        ''
        ? ''
        : this.vendorAssociatedCustomerForm.controls.vendorState.value.code ===
          undefined
          ? ''
          : this.vendorAssociatedCustomerForm.controls.vendorState.value.code;
    const accountIndustry =
      this.vendorAssociatedCustomerForm.controls.vendorIndustry.value ===
        null || ''
        ? ''
        : this.vendorAssociatedCustomerForm.controls.vendorIndustry.value
          .code === undefined
          ? ''
          : this.vendorAssociatedCustomerForm.controls.vendorIndustry.value.code;
    const associatedSearch =
      pgNo +
      '/' +
      recordPage +
      '?name=' +
      accountName +
      '&country=' +
      accCountry +
      '&state=' +
      accState +
      '&url=' +
      accountUrl +
      '&industry=' +
      accountIndustry;
    this.apisService.getApi(environment.vendorAccsocitegrid + associatedSearch).subscribe((res) => {
      this.vendorAssociatedCustomertableData = res.data;
      this.totalrecods = res.total;
      if (this.totalrecods === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
      this.loading = false;
      this.vendorAssociatedCustomertableData = res.data;
      this.totalrecods = res.total;
    });
  }
  goToLink(url: string) {
    const addressLink = url.includes('http') ? url : `http://${url}`;
    window.open(addressLink, '_blank');
  }
}
