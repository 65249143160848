<p-toast position="bottom-right" key="br"></p-toast>
<div class="html">
  <div class="tablescroll">
    <div class="login-body1"
      [ngStyle]="{'background-image': getUrl(), 'background-repeat': 'no-repeat','background-attachment': 'fixed', 'background-size': 'cover', 'height' : '723px', 'overflow-y': 'hidden' }">
      <div class="login-panel1"></div>
      <div class="login-content1">
        <img src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Servicesbay Logo SVG.svg" style="width:235px"
          alt="" />
        <div *ngIf="loginpage">
          <div *ngIf=!verification>
            <form [formGroup]="loginForm">
              <h4>
                <span style="color:#727272;"> Welcome to </span><strong>ServicesBay Portal</strong>
              </h4>
              <div class="login-input-wrapper1">
                <input type="text" formControlName="email" autocomplete="off" placeholder="Email" maxlength=50
                  class=" p-inputtext  p-widget"><span class="pi pi-envelope"
                  style="position:absolute; right:8px;top:8px;"></span>
                <span class="validationtest" *ngIf="
                        loginForm.get('email').touched &&
                        loginForm.get('email').errors?.required && loginForm.get('email').invalid
                      ">Email is required
                </span>
              </div>
              <div class="login-input-wrapper1">
                <div class=" p-inputgroup">
                  <input type="password" formControlName="password" autocomplete="off" placeholder="Password"
                    maxlength=20 class=" p-inputtext  p-widget" [type]="hide ? 'password' : 'text'">
                  <span style="position:absolute; right:8px;top:8px;"><em class="pi pi-eye-slash showEye"
                      style="font-size: 18px; color: black;" (click)="myFunction()" *ngIf="hideIcon"></em>
                    <em class="pi pi-eye showEye" style="font-size:  18px; color: black;" (click)="myFunction1()"
                      *ngIf="showIcon"></em></span>
                  <div>
                  </div>
                </div>
                <div>
                  <span class="validationtest" *ngIf="
                          loginForm.get('password').touched &&
                          loginForm.get('password').errors?.required && loginForm.get('password').invalid">
                    Password is required
                  </span>
                </div>
              </div>
              <div class="p-grid  p-fluid">
                <div class="p-col-3 p-md-3 p-sm-12">
                  <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                      <button class='addhBtn' pButton type="submit" [disabled]="button" label=" Sign In"
                        (keyup.enter)="login()" (click)="login()" style="text-align: justify;margin-left: 1px;width: 150px;">
                        <span *ngIf="show1"> <em class="pi pi-lock"
                            style="font-size:13px;margin-left:25px;margin-right: 10px;"></em>
                        </span>
                        <span *ngIf="show"> <em class="fa fa-spinner fa-spin"
                            style="font-size:15px;margin-left:25px;margin-right: 10px;"></em>
                        </span>
                      </button>
                      <div style="margin-left: 196px;
                      margin-top: -25px;
                      position: relative;
                      left: -4px;">
                        <a style=" cursor: pointer;" (click)="forgotPassword()">Forgot password?</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br>
              Don't have an account?<a style="margin-top: 5px; cursor: pointer; margin-left: 0px;"
                (click)="registration()">
                Register here</a>
            </form>
          </div>
          <div *ngIf="verification">
            <form [formGroup]="verificationForm">
              <span>
                <h4 *ngIf="stepverify" style="margin-top: -9px;margin-left: 3px;font-family: sans-serif;">2-Step Verification</h4>
              </span>
              <h5 *ngIf="stepverify" style="margin-left: 3px;font-family: sans-serif;">Hi</h5>
              <h5 *ngIf="stepverify" style="margin-top: 10px;
              color: #2bb9d5;
              margin-left: 3px;
              position: relative;
              left: 21px;
    top: -34px;font-family: sans-serif;">
                {{this.userName}}</h5>
              <br>
              <div class="p-col-12 p-md-4" *ngIf="selectModestep">
                <p style="margin-top: -29px;margin-left: -5px;font-family: sans-serif;">Please select verification mode 
                </p>
                <div class="p-field-radiobutton" style="margin-top: -21px; margin-left: -5px;">
                  <p-radioButton name="email" (onClick)="selectMode('email')" value="email" formControlName="email"
                    inputId="city1"></p-radioButton>
                  <label for="city1" style="font-family: sans-serif;">Email</label>&nbsp;&nbsp;&nbsp;
                  <p-radioButton class="radioSMS" [disabled]="true"  name="email" value="sms" (onClick)="selectMode('sms')"
                    formControlName="email" inputId="city2"></p-radioButton>
                  <label for="city2"style="font-family: sans-serif;">SMS</label>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 ">
                  <button [disabled]="proccedbtn" style="margin-left: -11px;
                  margin-top: 1px;
                  width: 152px;" pButton type="submit"
                    label="Proceed" (click)="Procced()">
                    <span *ngIf="proccedshow1"> <em class="fa fa-arrow-circle-right"
                        style=" position: relative; left: 19px; top: 1px;"></em>&nbsp;&nbsp;&nbsp;
                    </span>
                    <span *ngIf="proccedshow"> <em class="fa fa-spinner fa-spin"
                      style="position: relative; left: 19px; top: 1px;"></em>&nbsp;&nbsp;&nbsp;
                  </span>
                  </button>
                </div>
              </div>
              <div *ngIf="passCodes" style="margin-top: -127px;">
                <span>
        <h4 style="margin-top: 52px;margin-left: 49px;font-family: sans-serif;">{{ this.verificationName}} Verification</h4>
                </span>
                <img alt="" *ngIf="emailimg"
                  src="https://d2r8nxfsv3v6y3.cloudfront.net/images/miracleemail.svg" class="emailverify">
                <img alt="" *ngIf="smsimg" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/sms.svg"
                  class="emailverify">
                <span>
                  <p style="margin-top: -117px; margin-left: 1px;font-family: sans-serif;">A verification code has been sent to <br> <span  style="
                    color: #2bb9d5;position: relative;font-family: sans-serif;">{{this.loginId}}</span></p>
                </span>
                <div class="p-inputgroup" style="margin-top: -20px;display: flex;width: fit-content;">
                  <span class="p-inputgroup-addon">SB -</span>
                  <input type="text" formControlName="Passcode" autocomplete="off" placeholder="Passcode" maxlength=10
                    pInputText>
                </div>
                <span class="validationtest"
                  *ngIf="verificationForm.get('Passcode').touched && verificationForm.get('Passcode').invalid && verificationForm.get('Passcode').errors.required">
                  Please enter Passcode</span>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 verifyBtn">
                  <button style="margin-left: -6px;
                    " [disabled]="verifybtn" pButton type="submit" label="Verify" (keyup.enter)="verify()"
                    (click)="verify()">
                    <span *ngIf="verifyshow1"> <em class="fa fa-check-circle"
                        style="position: relative;
                        left: 13px;
                        top: 0px;"></em>&nbsp;&nbsp;&nbsp;
                    </span>
                    <span *ngIf="verifyshow"> <em class="fa fa-spinner fa-spin"
                        style="position: relative;
                        left: 13px;
                        top: 0px;"></em>&nbsp;&nbsp;&nbsp;
                    </span>
                  </button>
                  <div class="resendPcode">
                    <em class="pi pi-refresh" aria-hidden="true" (click)="resend()"></em>&nbsp;
                    <a (click)="resend()">Resend Passcode</a>
                  </div>
                </div>
                <div class="backBtn">
                  <em class="pi pi-arrow-left arrowleft"  aria-hidden="true" (click)="backtologin()"></em>
                  <span (click)="backtologin()">Back to Login</span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <form [formGroup]="forgotpwdForm">
          <div *ngIf="forgotpasswordpage">
            <span> <em class="fa fa-lock lockverfy" style="margin-left: -81px; top: 1px;"></em>
            </span>
            <h4>
              <span style="color:#727272;"> </span><strong style="position: relative; left: 46px;
              font-family: sans-serif;">Forgot Your
                Password?</strong>
            </h4>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3"  style="font-family: sans-serif;">
              Confirm your email and we'll send the instructions.
              <br><br>
              <div class="p-grid-12"></div>
              <div class="p-grid p-fluid p-justify-center">
                <div class="p-col-12 p-md-12 p-sm-12 p-fluid">
                  <input style="width: 310px;" autocomplete="off" type="text" pInputText size="40" placeholder="Email"
                    formControlName="emailId" />
                  <div>
                    <span class="validationtest"
                      *ngIf="forgotpwdForm.controls.emailId.touched && forgotpwdForm.controls.emailId.invalid && forgotpwdForm.controls.emailId.errors.required">Email
                      is required</span> <span class="validationtest"
                      *ngIf="forgotpwdForm.controls.emailId.touched && forgotpwdForm.controls.emailId.invalid && forgotpwdForm.controls.emailId.errors.pattern">Email
                      is not valid</span>
                  </div>
                </div>
              </div>
              <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <button style="margin-left: -6px;" class='addhBtn' pButton type="submit" label="Submit" (keyup.enter)="resetPassword()"
                  (click)="resetPassword()" [disabled]="disabledd">
                  <span *ngIf="show1"> <em class="fa fa-check-circle"
                      style="position: relative; left: 16px; top: 0px;margin-right: 10px;"></em>
                  </span>
                  <span *ngIf="show12"> <em class="fa fa-spinner fa-spin"
                      style="position: relative; left: 18px;top: 0px;margin-right: 10px;"></em>
                  </span>
                </button>
                <div style="margin-top: -19px; margin-left: 208px;">
                  <a style="margin-left: -18px;
                  position: relative;
                  left: 3px;
                  top: -4px;cursor: pointer;" class="pi pi-arrow-left" (click)="backlogin()">Back to Login</a>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="success1">
            <span style="color:#727272;"> </span><strong>
            </strong><br>
            <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/download.png"
              style="width: 60px;margin-bottom:10px;margin-left:83px;"><br>
            {{this.resultData.message}}
            <div style="margin-top: -21px; margin-left: 208px;"><br><br>
              <a style=" cursor: pointer;margin-left: -149px;margin-right:-84px;" class="pi pi-arrow-left"
                (click)="backlogin()">&nbsp;Back to Login</a>
            </div>
          </div>
        </form>
      </div>
      <p-dialog class="dialogBox" header="Registration" [(visible)]="display" [modal]="true" [style]="{width: '45%'}"
        [draggable]="false" [resizable]="false">
        <br>
        <div class="p-grid  p-fluid" style="background-color: #f8f9fafa;">
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-4" style="text-align: center;cursor: pointer;">
            <p-card (click)="navigateToCustomerRegisterForm()">
              <a style="cursor:pointer">
                <img alt="" style="height: 80px;"
                  src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Customer.png">
                <h5>Customer</h5>
              </a>
              <p> Register as Customer</p>
            </p-card>
          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-4" style="text-align: center;cursor: pointer;">
            <p-card (click)="navigateToRegisterForm()">
              <a style="cursor:pointer">
                <img alt="" style="height: 80px;"
                  src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Vendor.png">
                <h5>Vendor</h5>
              </a>
              <p>Register as Vendor</p>
            </p-card>
          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-4" style="text-align: center;cursor: pointer;">
            <p-card (click)="navigateToconsultantRegisterForm()">
              <a style="cursor: pointer;" (click)="navigateToconsultantRegisterForm()">
                <img alt="" style="height: 80px;"
                  src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Consultant.png">
                <h5>Consultant</h5>
              </a>
              <p>Register as Consultant</p>
            </p-card>
          </div>
        </div>
      </p-dialog>
    </div>
  </div>
  <div class="layout-footer" style="position: fixed; bottom: 0;width: 100%;">
    <div class="p-grid ui-fluid">
      <div class="p-col-12 p-md-6" style="margin-top: 0%;">
        <p class="text" style="margin-left: 25px;">Copyright © {{currenYearFormat}} ServicesBay. All rights
          reserved.</p>
      </div>
      <div class="p-col-12 p-md-6 footer-icons">
        <a href="#">
          <em class="fa fa-facebook-square" style="color: #3b5998;" aria-hidden="true"></em>
        </a>
        <a href="#">
          <em class="fa fa-twitter-square" aria-hidden="true" style="color: #1DA1F2;"></em>
        </a>
        <a href="#">
          <em class="fa fa-linkedin-square" aria-hidden="true" style="color: #0077b5;"></em>
        </a>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center laoderheight">
        <div class="laodersize">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
            class="loaderimg-height">
        </div>
      </div>
    </div>
  </div>
</ng-template>