<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
    <form [formGroup]="accountsActivityForm">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Name</label>
                <p-dropdown [options]="accountnames" placeholder="Select Account Name" optionLabel="employerName"
                (keyup.enter)="getaccountsActivity()" formControlName="AccountName" [filter]="true" [showClear]="true"></p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 "></div>
        </div>
        <div class="btnsCSS1 buttonalignement">
            <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-search' label="Search" (click)="getaccountsActivity()" class="addhBtn"></button>
        </div><br>
    </form>
</p-card>
<br>
<p-card class="pgpos">
    <div *ngIf="table">
        <p-table [columns]="cols" [value]="accountsactivitdata" selectionMode="single" dataKey="edit" [rows]="10"
            [responsive]="true" sortMode="multiple">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i=index"
                        (click)="iconToggle[i] = !iconToggle[i]" [ngStyle]="{'width': col.width }"
                        [pSortableColumn]="col.field">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
                <tr>
                    <td class="textCenter skill-desc" tooltipPosition="top" pTooltip="{{data.accountName}}"><span
                            class="ui-column-title"></span>
                        {{data.accountName}}</td>
                    <td class="textCenter"><span class="ui-column-title"></span>{{
                        data.activity}}</td>
                    <td class="textCenter"><span class="ui-column-title"></span>{{
                        data.activityBy}}</td>
                    <td class='textCenter'><span class="ui-column-title">
                        </span>{{data.activityDate}}</td>
                    <td class="textCenter actionclr"><span class="ui-column-title"></span><em type='button'
                            (click)="actionpopup.toggle($event); toggle($event, data)"
                            class="pi pi-ellipsis-v pointer"></em>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr *ngIf="attachmentsGriddata?.length==0">
                    <td colspan="6" class="mandatoryColor" aria-colcount="aligncenter">
                        No Records
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalrecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator [rows]="10" [totalRecords]="totalrecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any accounts activity</em><br>
            </div>
        </div>
    </p-card>
</p-card>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center laoderheight">
        <div class="laodersize">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
            class="loaderimg-height">
        </div>
      </div>
    </div>
  </div>
</ng-template>