<p-card>
            <form [formGroup]="vendorAssociatedCustomerForm">
                <div class="p-fluid p-grid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Vendor Name</label>
                        <input (keyup.enter)="searchData()" id="input" type="text" pInputText placeholder="Name" 
                            formControlName="vendorName" maxlength="50" autocomplete="off">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Corporate Website</label>
                        <input (keyup.enter)="searchData()" id="input" type="text" pInputText 
                            placeholder="Corporate Website" formControlName="vendorUrl" maxlength="60" 
                            autocomplete="off">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Industry</label>
                        <p-dropdown (keyup.enter)="searchData()" placeholder="Select Industry" optionLabel="label" 
                        [showClear]="true"   [options]="industry" formControlName="vendorIndustry" ></p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Status</label>
                        <p-dropdown (keyup.enter)="searchData()" optionLabel="label" formControlName="vendorStatus" 
                        [showClear]="true"   [options]="statusDD" placeholder="Select Status" ></p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Country</label>
                        <p-dropdown (keyup.enter)="searchData()" placeholder="Select Country" optionLabel="label"
                        [showClear]="true"  [options]="country" formControlName="vendorCountry" 
                            (onChange)="getStateByUrl($event.value.code)">
                        </p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">State</label>
                        <p-dropdown (keyup.enter)="searchData()" [options]="state" placeholder="Select State" 
                        [showClear]="true" optionLabel="label" formControlName="vendorState"></p-dropdown>
                    </div>
                </div>

                <div class="btnsCSS1 buttonalignment">
                    <button pButton type="button" icon='pi pi-plus' label="Add"
                        [routerLink]="['/available-vendors']" class="addhBtn"></button>
                    <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" 
                        class="addhBtn"></button>
                    <button pButton type="button" icon='pi pi-search' label="Search" (click)="searchData()" 
                        class="addhBtn"></button>
                </div>
            </form></p-card><br>
<p-card class="pgpos">
    <div *ngIf="table">
        <p-table [value]="vendorAssociatedCustomertableData" [columns]="cols" selectionMode="single" dataKey="edit"
            [responsive]="true" sortMode="multiple">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                        <span>
                            {{col.header}}

                        </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
                <tr>
                    <td class="textCenter"><span class="p-column-title">
                            Vendor Name</span>
                        {{data.name}}
                    </td>

                    <td class="textCenter"><span class="p-column-title">
                            Corporate Website</span>
                        <a (click)="goToLink(data.webSite)" class="pointer">{{data.webSite}}</a>
                    </td>
                    <td class="textCenter"><span class="p-column-title">
                            Phone Number</span>{{data.phone}}</td>

                    <td><span class="ui-column-title"></span>
                        <p-badge [value]="getStatusTable[data.status]" [ngClass]="data.status ==='A'?'green': 'red'">
                        </p-badge>
                    </td>
                    <td class="textCenter"><span class="p-column-title">
                            Industry</span>{{getTableIndustry[data.industry]}}</td>
                    <td><span class="p-column-title">
                            Tiers</span>{{data.tierName}}</td>
                    <td class="textCenter"><span class="p-column-title">
                            Date Added</span> {{data.createdDate}}</td>
                    <td><span class="p-column-title">
                            Action</span><a>
                            <em (click)="editActionPopup.toggle($event); toggle($event,data, rowData)"
                                class="pi pi-ellipsis-v pointer"></em>
                        </a> </td>
                </tr>
            </ng-template>

        </p-table>
        <div class="p-grid ui-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalrecods}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator [rows]="10" [totalRecords]="totalrecods" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
        <ng-template pTemplate="emptymessage">
            <tr *ngIf="vendorAssociatedCustomertableData?.length===0 || vendorAssociatedCustomertableData === undefined"
                class="aligncenter">

                <td colspan="6" class="mandatoryColor">
                    No Records
                </td>
            </tr>
        </ng-template>
    </div>

    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any vendors</em><br>
            </div>
        </div>
    </p-card>
</p-card>
<div *ngIf="!loading; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-menu class="menuclass" #editActionPopup [popup]="true" [model]="items"></p-menu>