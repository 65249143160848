<p-card>
    <p-panel header="My Preferences" [toggleable]="false">
        <form [formGroup]="userPrefernces">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 position">
                <label><strong>Preferred Country :</strong></label>
                <label  class="panelLabel space">{{this.coutryvalue[countryValue]}}</label>
            </div>
            <br>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <label><strong>Preferred State :</strong></label>&nbsp;&nbsp;
                    <label class="panelLabel">{{this.statuTable.toString()}}</label>
                </div>
            </div>
            <br>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <label><strong>Preferred Currency :</strong></label>
                    <label class="panelLabel space">{{this.item[preferences]}}</label>
                </div>
            </div><br>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <label><strong>Preferred Notifications :</strong></label>
                    <label 
                        class="panelLabel space">{{this.notification1[notificationvalue]}}</label>
                </div>
            </div>
        </form>
    </p-panel>
</p-card>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight " >
                <div class="laodersize" >
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif" class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>