<p-card>
    <form [formGroup]="selectedForm">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Candidate Name</label>
                <input id="input" (keyup.enter)="gridTable()" pInputText placeholder="Candidate Name" autocomplete="off"
                    formControlName="selectCandidateName" maxlength="30">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Status</label>
                <p-dropdown (keyup.enter)="gridTable()" [options]="newstatus" formControlName="selectStatus"
                    placeholder="Select Status" optionLabel="label" [showClear]="true"></p-dropdown>
            </div>
        </div>
        <br>
        <div class="btnsCSS1 buttonalignement">
            <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="resetSelectedForm()"
                class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-search' label="Search" (click)="gridTable()"
                class="addhBtn"></button>
        </div><br>
    </form>
</p-card>
<br>
<p-card class="pgpos">
    <div *ngIf="table">
        <p-table [value]="gridData" [columns]="cols" selectionMode="single" dataKey="edit" [responsive]="true"
            sortMode="multiple" [rows]="10">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                        <span>
                            {{col.header}}

                        </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
                <tr>
                    <td class="skill-desc" pTooltip="{{data.name}}" tooltipPosition="top"><span class="p-column-title">
                        </span>{{data.vendorName}}</td>
                    <td class="textCenter"><span class="p-column-title">
                        </span>{{data.consultantName}}</td>
                    <td class="textCenter " style="cursor: pointer">
                        <a (click)="op.toggle($event)" (click)="reqSkillsPopUp(getSkillSetTable(data.skills))"
                            *ngFor="let data1 of getSkillSetTable(data.skills); index as i;">
                            <p-badge class="badgespace newsty" *ngIf='i<1' [value]="data1"></p-badge>
                            <p-badge *ngIf='i===1' [value]="'+'+(data.skills.length-1)+' more'"></p-badge>
                        </a>
                    </td>
                    <td class="pointer" [hidden]="true" style="cursor: pointer">
                        <a (click)="op.toggle($event)" (click)="reqSkillsPopUp(getSkillSetTable(data.skills))"
                            *ngFor="let data1 of getSkillSetTable(data.skills); index as i;">
                            <p-badge [value]="data1+','"></p-badge>
                        </a>

                    </td>
                    <td>
                        {{data.billRate | currency: currencyValue : true}}
                    </td>
                    <td><span class="ui-column-title"></span>
                       {{statuslist[data.profileState]}}
                    </td>
                    <td class="textCenter"><span class="p-column-title">
                        </span><a (click)="actionpopup.toggle($event); toggle(items, $event, data)"
                            class="pi pi-ellipsis-v pointer"></a>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10 ">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any resources</em><br>
            </div>
        </div>
    </p-card>
</p-card>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '25%' }">
    <label class="inputfields">Skill Details</label>
    <ng-template pTemplate>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12 bordersty">
                <p class="skpos">
                    <p-badge class="badgespace badgesp" *ngFor="let data2 of reqSkillsValue; index as i" [value]="data2"
                        [ngClass]="i%2===0? 'newsty' : ''">
                    </p-badge>
                </p>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>
<p-dialog class="dialogBox" [(visible)]="displayModal" [draggable]="false" [modal]="true"
    [style]="{width: '30%',height:'60%'}">
    <p-header>Project Activity<br>
        <span class="Activity">Candidate Name : <a>{{this.candidatename}}</a></span>
    </p-header>
    <p class="Activitycolor">Please find the below information</p>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-sm-12">
            <p-timeline [value]="histroy" align="alternate">
                <ng-template pTemplate="opposite" let-event><span class="Activitycolor">{{event.startDate |
                        date:'MM-dd-yyyy'}}</span>
                </ng-template>
                <ng-template pTemplate="content" let-event>
                    <p-card>
                        <p class="aligncenter">
                            <span class="Activitycolor">{{event.projectName}}</span>
                            <br>
                            Start Date {{event.startDate | date:'MM-dd-yyyy'}}<br>
                            End Date {{event.endDate | date:'MM-dd-yyyy'}}
                        </p>
                    </p-card>
                    <br>
                </ng-template>
            </p-timeline>
        </div>
    </div>
</p-dialog>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>