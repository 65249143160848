<p-card>
    <p-accordion>
        <p-accordionTab header="Submitted Profiles" [selected]="false">
            <form [formGroup]="submittedListForm">
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Consultant Email</label><br>
                        <input id="input" type="text" pInputText placeholder="Email" autocomplete="off"
                            (keyup.enter)="search()" formControlName="customerEmail" maxlength="30">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">First Name</label><br>
                        <input id="input" type="text" pInputText placeholder="First Name" autocomplete="off"
                            (keyup.enter)="search()" formControlName="firstName" maxlength="30">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Last Name</label><br>
                        <input id="input" type="text" pInputText placeholder="Last Name" autocomplete="off"
                            (keyup.enter)="search()" formControlName="lastName" maxlength="30">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">from Date</label><br>
                        <p-calendar [showIcon]="true" inputId="icon" formControlName="startDate"
                            placeholder="MM-DD-YYYY" (keyup.enter)="search()" dateFormat="mm-dd-yy"></p-calendar>
                    </div>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">To date</label><br>
                        <p-calendar id="popup" dateFormat="mm-dd-yy" (keyup.enter)="search()" formControlName="todate"
                            placeholder="MM-DD-YYYY" [showIcon]="true" [showButtonBar]="true">
                        </p-calendar>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Status</label><br>
                        <p-dropdown placeholder="Select Status" [options]="workStatusList" (keyup.enter)="search()"
                        [showClear]="true"   optionLabel="label" formControlName="status"></p-dropdown>
                    </div>
                </div>
                <div class="btnsCSS1">
                    <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()"
                        class="addhBtn"></button>
                    <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn"
                        (click)="search()"></button>
                </div>
                <br><br>
            </form>
        </p-accordionTab>
    </p-accordion>
</p-card>
<br>
<p-card class="pgpos">
    <div *ngIf="table">
        <p-table [columns]="cols" [value]="tableData" scrollDirection="both" selectionMode="single" [scrollable]="true"
            dataKey="edit" sortMode="multiple">
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngFor="let col of cols" class="tableColWdith">
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        [ngStyle]="{'width': col.width}" [pSortableColumn]="col.field">
                        <span> {{col.header}}</span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-getData>
                <tr style="width: 100%;">
                    <td class="textCenter">{{getData.reqId}}</td>
                    <td class="textCenter">{{getData.customerName}}</td>
                    <td class="textCenter"><a class="pointer">{{getData.consultantName}}</a> </td>
                    <td class="textCenter">{{getData.submittedDate}}</td>
                    <td class="textCenter">{{getData.email}}</td>
                    <td class="textCenter">{{getData.office_phone}}</td>
                    <td class="textCenter">{{getWorkStatusTable(getData.workStatus)}}</td>
                    <td class="textCenter">{{getConsStatus(getData.consStatus)}}</td>
                    <td class="textCenter"></td>
                    <td class="textCenter">{{getData.maxRate || "----"}}</td>
                    <td class="textCenter"><a>
                            <em (click)="actionpopup.toggle($event); toggle(items, $event, data)"
                                class="pi pi-ellipsis-v pointer"></em> </a></td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 norec">
                <h3 class="norecorddata">Total records : {{this.totalRecords1}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-8 pagi">
                <p-paginator [rows]="10" [totalRecords]="totalRecords1" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any submitted details</em><br>
            </div>
        </div>
    </p-card>
</p-card>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight " >
                <div class="laodersize" >
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif" class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>