<div class="scroll">
  <p-toast position="bottom-right" key="br"></p-toast>
  <nav class="navbar navbar-expand-lg navbar-dark sticky-top nav">
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
      <a class="navbar-brand">
        <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Servicesbay Logo SVG.svg" class="innerimg">
      </a>

      <button class="pi pi-arrow-left pointer addhBtn" id="backToStyle" pButton type="button"
        style="width:auto;top: 14px;" label="Back to Registration" (click)="goto()"></button>
    </div>
  </nav>
  <br>
  <form [formGroup]="registrationForm">
    <div class="p-grid p-justify-center">

      <div class="p-col-8 p-md-8 p-sm-12">
        <p-card class="cardCss">
          <p-panel [toggleable]="false" [style]="{'margin-bottom':'20px'}">
            <p-header>
              <div>
                <label> {{vendorName === 'Company' ? 'Customer' : vendorName}} Registration</label>
              </div>
            </p-header>
            <h5 class="panelLabel h3tag">{{vendorName === 'Company' ? 'Customer' : vendorName}} Information</h5>
            <div class="p-grid p-fluid p-justify-center">
              <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">Company<label class="mandatoryColor">*</label></label>
                <span class="p-input-icon-right"><em *ngIf="spinnerHide"
                    class="pi pi-spin pi-spinner spinnerstyles"></em>
                  <em *ngIf="checkHide" class="pi pi-check-circle tickmarkstyle"></em>
                  <input autocomplete="off" id="input" (blur)="customerName()" type="text" pInputText
                    placeholder="Company" maxlength="61" formControlName="customerName"></span>
                <span class="validationtest"
                  *ngIf="registrationForm.get('customerName').touched && registrationForm.get('customerName').invalid && registrationForm.get('customerName').errors.required">
                  Please enter {{vendorName | lowercase}} name</span>
                  <span class="validationtest"
                  *ngIf="this.registrationForm.controls.customerName.invalid && this.registrationForm.controls.customerName.errors.pattern">Please
                  enter
                  alphabets only</span>
                <div>
                  <span class="validationtest" *ngIf="this.registrationForm.get('customerName').hasError('maxlength')">
                    Please enter 60 alphabets only
                  </span>
                </div>
                <p class="mandatoryColor">{{consultantNames}}</p>
              </div>
              <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 ">
                <label class="inputfields">Corporate Website<label class="mandatoryColor">*</label></label>
                <input autocomplete="off" id="input" type="text" pInputText placeholder="Corporate Website"
                  maxlength="61" formControlName="websiteURL" (blur)="urlvalidation()">
                <span class="validationtest"
                  *ngIf="registrationForm.get('websiteURL').touched && registrationForm.get('websiteURL').invalid && registrationForm.get('websiteURL').errors.required">
                  Please enter corporate website</span>
                <div>
                  <span class="validationtest" *ngIf="this.registrationForm.get('websiteURL').hasError('maxlength')">
                    Please enter 60 alphabets only
                  </span>
                </div>
              </div>
              <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">No.Of Employees</label>
                <p-dropdown [options]="EmployeeData" formControlName="NumOfEmployees" [showClear]="true"
                  placeholder="Select No.Of Employees" optionLabel="label"></p-dropdown>

              </div>
            </div>
            <h5 class="panelLabel h3tag">{{vendorName === 'Company' ? 'Customer' : vendorName}} Admin Details</h5>
            <div class="p-grid p-fluid ">
              <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">First Name<label class="mandatoryColor">*</label></label>
                <input autocomplete="off" id="input" type="text" pInputText placeholder="First Name" maxlength="26"
                  formControlName="firstName">
                <span class="validationtest"
                  *ngIf="registrationForm.get('firstName').touched && registrationForm.get('firstName').invalid && registrationForm.get('firstName').errors.required">
                  Please enter first name</span>
                <span class="validationtest" *ngIf="this.registrationForm.get('firstName').hasError('maxlength')">
                  Please enter 25 alphabets only
                </span>
                <span class="validationtest"
                *ngIf="this.registrationForm.controls.firstName.invalid && this.registrationForm.controls.firstName.errors.pattern">Please
                enter
                alphabets only</span>
              </div>
              <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">Middle Name </label>
                <input autocomplete="off" id="input" type="text" pInputText placeholder="Middle Name" maxlength="16"
                  formControlName="middleName">
                <span class="validationtest" *ngIf="this.registrationForm.get('middleName').hasError('maxlength')">
                  Please enter 15 alphabets only
                </span>
              </div>
              <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <label class="inputfields">Last Name<label class="mandatoryColor">*</label></label>
                <input autocomplete="off" id="input" type="text" pInputText placeholder="Last Name" maxlength="26"
                  formControlName="lastName">
                <span class="validationtest"
                  *ngIf="registrationForm.get('lastName').touched && registrationForm.get('lastName').invalid && registrationForm.get('lastName').errors.required">
                  Please enter last name</span>
                <span class="validationtest" *ngIf="this.registrationForm.get('lastName').hasError('maxlength')">
                  Please enter 25 alphabets only
                </span>
                <span class="validationtest"
                *ngIf="this.registrationForm.controls.lastName.invalid && this.registrationForm.controls.lastName.errors.pattern">Please
                enter
                alphabets only</span>
              </div>

              <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 invalidMandatory">
                <label class="inputfields">Office Phone<label class="mandatoryColor">*</label></label>
                <div class="p-col-12 p-md-12 p-sm-12 phone">
                  <div class="p-inputgroup" class="p-col-4 p-md-4 ph-no">
                    <span class="p-inputgroup-addon my-component">
                      <p-dropdown [options]="countrycodedropdown" class="phonecodestyle newcode"
                        (onChange)="countryids($event)" filterPlaceholder='Search Results' optionLabel="label"
                        formControlName="mobileDrp">
                        <ng-template let-items pTemplate="selectedItem">
                          <div class="flex-row">
                            <img class="lang-change-img" src={{items.flag}} alt="" />
                            <div class="lang-change-option-text selected-lang center">{{items.code}}
                            </div>
                          </div>
                        </ng-template>
                        <ng-template let-country pTemplate="item">
                          <div class="flex-row" id="dropdown1">
                            <img class="lang-change-img" src={{country.flag}} alt="" />
                            <div class="lang-change-option-text center">{{country.label}}
                              {{country.code}}</div>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </span>
                  </div>
                  <div class="spinner">
                    <span class="p-input-icon-right"><em *ngIf="phSpinnerHide"
                        class="pi pi-spin pi-spinner spinnerstyles"></em>
                      <em *ngIf="phCheckHide" class="pi pi-check-circle tickmarkstyle"></em>
                      <p-inputMask autocomplete="off" mask="(999) 999-9999" formControlName="officePhone"
                        (onBlur)='getPhon()' placeholder="Office Phone">
                      </p-inputMask>
                    </span>
                  </div>
                </div>
                <span class="validationtest"
                  *ngIf="registrationForm.get('officePhone').touched && registrationForm.get('officePhone').invalid && registrationForm.get('officePhone').errors.required">
                  Please enter office phone</span>
                <span class="validationtest" *ngIf="this.registrationForm.get('officePhone').hasError('maxlength')">
                  Please enter 15 alphabets only
                </span>
                <p class="validationtest">{{this.officenummsg}}</p>
              </div>
              <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 invalidMandatory">
                <label class="inputfields">Office Email<label class="mandatoryColor">*</label></label>
                <div class="p-inputgroup">
                  <span class="p-input-icon-right"><em *ngIf="mailSpinnerHide"
                      class="pi pi-spin pi-spinner spinnerstyles"></em>
                    <em *ngIf="mailCheckHide" class="pi pi-check-circle tickmarkstyle"></em>
                    <input class="inpt" autocomplete="off" id="input" type="text" maxlength="61" pInputText
                      placeholder="Office Email" formControlName="officeEmail" (blur)="officeEmailExtensionCheck()"
                      #userInput>
                  </span>
                </div>
                <span class="validationtest"
                  *ngIf="registrationForm.get('officeEmail').touched && registrationForm.get('officeEmail').invalid && registrationForm.get('officeEmail').errors.required">
                  Please enter office email</span>

                <span class="validationtest"
                  *ngIf="registrationForm.controls.officeEmail.invalid && registrationForm.controls.officeEmail.errors.pattern">
                  Must be valid corp email
                </span>
                <span class="validationtest" *ngIf="this.registrationForm.get('officeEmail').hasError('maxlength')">
                  Please enter 60 alphabets only
                </span>
                <p class="validationtest">{{ofcMailValidation}}</p>
              </div>
              <div class="p-col-12 p-md-12 p-sm-12 p-lg-4  invalidMandatory">
                <label class="inputfields">Gender</label>
                <p-dropdown [options]="gender1" [showClear]="true" formControlName="gender" placeholder="Select Gender"
                  optionLabel="name">
                </p-dropdown>
              </div>

              <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Address Line 1</label>
                <input autocomplete="off" id="input" type="text" pInputText placeholder="Address Line 1" maxlength="51"
                  formControlName="address1">
                <span class="validationtest" *ngIf="this.registrationForm.get('address1').hasError('maxlength')">
                  Please enter 50 alphabets only
                </span>
              </div>
              <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Address Line 2</label>
                <input autocomplete="off" id="input" type="text" pInputText placeholder="Address Line 2" maxlength="51"
                  formControlName="address2">
                <span class="validationtest" *ngIf="this.registrationForm.get('address2').hasError('maxlength')">
                  Please enter 50 alphabets only
                </span>
              </div>

              <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Mailbox</label>
                <input type="text" autocomplete="off" maxlength="11" pInputText placeholder="Mailbox"
                  formControlName="mailbox">
                <span class="validationtest" *ngIf="this.registrationForm.get('mailbox').hasError('maxlength')">
                  Please enter 10 alphabets only
                </span>
              </div>
              <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                <label class="inputfields">Country<label class="mandatoryColor">*</label></label>
                <p-dropdown class="countryDD" [options]="countryData" formControlName="country"
                  placeholder="Select Country" [showClear]="true" [filter]='true' optionLabel="label"
                  (onChange)='getState($event.value.code)'>
                </p-dropdown>
                <span class="validationtest"
                  *ngIf="registrationForm.get('country').touched && registrationForm.get('country').invalid && registrationForm.get('country').errors.required">
                  Please select country</span>
              </div>
              <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                <label class="inputfields">State</label>
                <p-dropdown class="countryDD" [options]="stateDataArray" formControlName="state"
                  placeholder="Select State" [filter]='true' [showClear]="true" optionLabel="label"></p-dropdown>
              </div>
              <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">City</label>
                <input autocomplete="off" id="input" type="text" pInputText placeholder="City" maxlength="21"
                  formControlName="city">
                <span class="validationtest" *ngIf="this.registrationForm.get('city').hasError('maxlength')">
                  Please enter 20 alphabets only
                </span>
              </div>

              <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Zip</label>
                <input autocomplete="off" id="input" type="text" pInputText placeholder="Zip" formControlName="zipcode"
                  maxlength="11">
                <span class="validationtest" *ngIf="this.registrationForm.get('zipcode').hasError('maxlength')">
                  Please enter 10 alphabets only
                </span>
              </div>
            </div>
            <div class="btnsCSS1 alignbtn">
              <button pButton type="button" icon='pi pi-refresh' label="Reset" class="addhBtn"
                (click)="reset()"></button>
              <button pButton [disabled]="disable" type="button" icon='pi pi-save' label="Register" class="addhBtn"
                (click)="register()"></button>
            </div>
          </p-panel>
        </p-card>
      </div>
    </div>
  </form>

  <p-dialog class="dialogBox" [(visible)]="displayBasic" [style]="{width: '30vw'}" [draggable]="false" [modal]="true"
    [resizable]="false" [closable]="false">

    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/miracleemail.svg" class="verify">
    <h5 class="h5msg">Please check your email and click on verify</h5>

  </p-dialog>
  <div *ngIf="!loader; else showLoader"></div>
  <ng-template #showLoader>
    <div class="loading-bar">
      <div>
        <div class="image-holder" class="col-sm-12 text-center laoderheight">
          <div class="laodersize">
            <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
              class="loaderimg-height">
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>