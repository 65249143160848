import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-consultant-open-positions',
  templateUrl: './consultant-open-positions.component.html',
  styleUrls: ['./consultant-open-positions.component.scss'],
  providers: [DatePipe, MessageService],
})
export class ConsultantOpenPositionsComponent implements OnInit {
  statusDD: any = [];
  getStatusData = {};
  downloadOptions: { label: string; icon: string; command: (event: any, i: any) => void; }[];
  // tslint:disable-next-line: max-line-length
  constructor(private route: Router, private datepipe: DatePipe, private apiservice: ApisService, private breadcrumbservice: BreadcrumbService, private fb: FormBuilder) {
    this.breadcrumbservice.setItems([
      { label: 'Open Positions', routerLink: '/consultantopenposition' }]);
  }
  consultantOpenPositionForm: FormGroup;
  griddata: any;
  items: any[];
  specializationSkillsData: any = [];
  array: any;
  display: boolean;
  reqSkillsValue: any;
  totalRecords: any;
  first: any;
  page: any = 1;
  rows: any = 10;
  pageCount: any;
  table: boolean;
  records: boolean;
  loader: boolean;
  displayModal: boolean;
  histroy: any;
  accountnamesres: any = [];
  reqProfileId: any;
  rowData: any;
  jobId: any;
  // It is used for displaying table header data
  cols3 = [
    { dataKey: 'jobId', header: 'Job Id' },
    { dataKey: 'customerName', header: 'Company' },
    { dataKey: 'jobTitle', header: 'Position Title' },
    { dataKey: 'skills', header: 'Skills' },
    { dataKey: 'postedDate', header: 'Posted Date' },
    { dataKey: 'status', header: 'Status' },
  ];
  cols = [
    { field: 'jobId', header: 'Job Id', sno: 1 },
    { field: 'customerName', header: 'Company', sno: 2 },
    { field: 'jobTitle', header: 'Position Title', sno: 3 },
    { field: 'requiredSkills', header: 'Skills', sno: 4 },
    { field: 'requiredSkills', header: 'Skills', sno: 5 },
    { field: 'postedDate', header: 'Posted Date', sno: 6 },
    { field: 'status', header: 'Status', sno: 7 },
    { field: 'Action', header: 'Action', sno: 8 },
  ];
  // Window keydown listener
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.consultantOpenPositionGrid();
    }
  }
  ngOnInit(): void {
    this.consultantOpenPositionForm = this.fb.group({
      jobId: [''],
      companyName: [''],
      jobTitle: [''],
      postedDate: []
    });
    this.consultantOpenPositionGrid();
    this.reqSkillSet();
    this.accountNames();
    this.apiservice.getApi(environment.status).subscribe((res) => {
      this.statusDD = res.data;
      this.statusDD.forEach(element => {
        this.getStatusData[element.code] = element.label;
      });
    });
  }
  // It is used to reset the search fields
  reset() {
    this.consultantOpenPositionForm.reset();
    this.consultantOpenPositionGrid();
  }
  // It is used for displaying company dropdown values
  accountNames() {
    this.apiservice.getApi(environment.accountnamesdropdown).subscribe((res: any) => {
      this.accountnamesres = res.data;
    });
  }
  // it is used to show table data and search table data
  consultantOpenPositionGrid() {
    // tslint:disable-next-line: max-line-length
    const jobid = this.consultantOpenPositionForm.controls.jobId.value === null || this.consultantOpenPositionForm.controls.jobId.value === undefined || this.consultantOpenPositionForm.controls.jobId.value === '' ? '' : this.consultantOpenPositionForm.controls.jobId.value;
    // tslint:disable-next-line: max-line-length
    const compName = this.consultantOpenPositionForm.controls.companyName.value === null || this.consultantOpenPositionForm.controls.companyName.value === undefined || this.consultantOpenPositionForm.controls.companyName.value === '' ? '' : this.consultantOpenPositionForm.controls.companyName.value.employerName;
    // tslint:disable-next-line: max-line-length
    const jobtitle = this.consultantOpenPositionForm.controls.jobTitle.value === null || this.consultantOpenPositionForm.controls.jobTitle.value === undefined || this.consultantOpenPositionForm.controls.jobTitle.value === '' ? '' : this.consultantOpenPositionForm.controls.jobTitle.value;
    // tslint:disable-next-line: max-line-length
    const postDate = this.consultantOpenPositionForm.controls.postedDate.value === null || this.consultantOpenPositionForm.controls.postedDate.value === undefined || this.consultantOpenPositionForm.controls.postedDate.value === '' ? '' : this.datepipe.transform(this.consultantOpenPositionForm.controls.postedDate.value, 'MM-dd-yyyy');
    const data = '?jobId=' + jobid + '&companyName=' + compName + '&jobTitle=' + jobtitle + '&postedDate=' + postDate;
    this.loader = true;
    this.apiservice.getApi(environment.consultantOpenPos + this.page + '/' + this.rows + data).subscribe((res: any) => {
      this.loader = false;
      this.griddata = res.data;
      this.totalRecords = res.count;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  // It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.consultantOpenPositionGrid();
  }
  // It is used to show action pop up
  toggle1(menu, event, rowData) {
    this.rowData = rowData;
    this.jobId = rowData.jobId;
    this.reqProfileId = rowData.reqProfileId;
    this.items = [
    ];
  }
  reqSkills1(data) {
    this.display = true;
    this.reqSkillsValue = data;
  }
  // It is used for displaying skill dropdown values
  reqSkillSet() {
    // tslint:disable-next-line: deprecation
    this.apiservice.getApi(environment.skills).subscribe((res) => {
      this.specializationSkillsData = res.data;
    });
  }
  getSkillSetTable(data) {
    this.array = data;
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.specializationSkillsData.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < this.array.length; j++) {
        if (Number(this.specializationSkillsData[i].code) === this.array[j]) {
          selectedPrePrimarySkills3.push(
            this.specializationSkillsData[i].label
          );
        } else {
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  // It is used to show download action data
  downLoadsToggle() {
    this.downloadOptions = [
      { label: 'Download XLSX', icon: 'pi pi-download', command: (event, i) => { this.exportExcel(); } },
      { label: 'Download PDF', icon: 'pi pi-download', command: (event, i) => { this.exportPdf(); } },
    ];
  }
  exportExcel() {
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    // tslint:disable-next-line:max-line-length
    ws['!cols'] = [{ width: 10 }, { width: 10 }, { width: 30 }, { width: 10 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 10 }, { width: 20 }, { hidden: true }];
    ws['!rows'] = [{ hpt: 30 }, { hpt: 30 },];
    ws['!cols'][3] = { hidden: true };
    ws['!cols'][7] = { hidden: true };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ExcelSheet.xlsx');
  }

  exportPdf() {
    // tslint:disable-next-line: no-shadowed-variable
    this.griddata.forEach((element) => {
      element.skills = this.getSkillSetTable(element.requiredSkills);
    });
    const doc = new jsPDF('p', 'pt');
    autoTable(doc, {
      headStyles: { fillColor: '#2bb9d5', cellPadding: 5 },
      margin: { left: 17 },
      columns: this.cols3,
      body: this.griddata,
      didDrawPage: (dataArg) => {
        doc.text('open Positions', dataArg.settings.margin.left, 30);
      }
    });
    doc.save('open Positions.pdf');
  }
  // It is used to navigate consultant open position edit
  consultantopenpositionedit(data) {
    this.route.navigate(['/consultantopenpositionedit'], {
      queryParams: {
        jobid: data.jobId,
        positontitle: data.jobTitle,
        ownerId: data.ownerId,
        accid: data.accId,
        vendid: data.venId,
        conid: data.conCount
      }
    });
  }
  // It is used to display activity
  profilehistroy() {
    this.displayModal = true;
    if (this.reqProfileId === null || this.reqProfileId === undefined) {
    } else {
      this.apiservice.getApi(environment.getConsultantProfileActivity + '/' + this.reqProfileId).subscribe((res: any) => {
        this.histroy = res.data;
      });
    }
  }
}
