import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-availablesubcontractor',
  templateUrl: './availablesubcontractor.component.html',
  styleUrls: ['./availablesubcontractor.component.scss'],
  providers: [DatePipe, MessageService],
})
export class AvailablesubcontractorComponent implements OnInit {

  records: boolean;
  subContractorForm: any;
  rows: any = 10;
  page: any = 1;
  loading: boolean;
  vendorAssociatedCustomertableData: any;
  totalrecods: any;
  table: boolean;

  first: any;
  pageCount: any;
  vendorSearchTableData: any;
  totalRecords: any;
  availableForm: any;
  vendorAddForm: FormGroup;
  vendorId: any;
  errormgs: boolean;
  accId: string;
  vendorTierList: any;
  availableSubcontractorForm: FormGroup;
  country: any;
  state: any;
  industry: any = [];
  industryTable = {};
  display: boolean;
  rowData: any;
  items: any = [];
  data: any;
  roles: any;
  accountId: any;
  status1 = [
    { name: 'Active', value: 'A' },
    { name: 'Inactive', value: 'I' },
  ];
  accountDetails: any;
  saveData: any;
  tableStatus: any;
  disabled: boolean;

  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private messageService: MessageService, private router: Router, private apisService: ApisService, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Subcontactors', routerLink: '/subcontactors' },
      { label: 'Available Subcontractors', routerLink: '/avaialablesubcontractor' },
    ]);
  }
  cols = [
    { field: 'name', header: 'Sub Contrator Name' },
    { field: 'webSite', header: 'Corporate Website' },
    { field: 'phone', header: 'Phone Number' },
    { field: 'industry', header: 'Industry' },
    { field: 'Action', header: 'Action' },
  ];

  ngOnInit(): void {
    const acctdetails = JSON.parse(this.apisService.decodeData());
    this.accountId = acctdetails.accId;
    this.roles = acctdetails.roles[0].id;
    this.availableSubcontractorForm = this.fb.group({
      subcontratorName: [''],
      subcontratorUrl: [''],
      country: [''],
      state: [''],
      industry: [''],
      vendorState: ['']
    });
    this.vendorAddForm = this.fb.group({
      subContractorName1: [''],
      vendorAddTier: ['', Validators.required],
      status: [''],
      days: [''],
      discount: [''],
      maxSubmission: ['', [Validators.required, Validators.pattern('^(0|[1-9][0-9]*)$'), Validators.maxLength(4)]]
    });
    this.searchData();
    this.gridData();
    this.getCountryDropdown();
    this.getIndustryDropDown();
    this.getAddVendorNames();
  }
  gridData() {
    this.loading = true;
    const pageNo = this.page;
    const pageCount = this.rows;
    const data = pageNo + '/' + pageCount;
    this.apisService
      .getApi(environment.activeVendorGrid + data)
      // tslint:disable-next-line: deprecation
      .subscribe((res: any) => {
        this.vendorSearchTableData = res.data;
        this.totalRecords = res.total;
        this.loading = false;
        if (this.totalRecords !== 0) {
          this.table = true;
          this.records = false;
        } else {
          this.table = false;
          this.records = true;
        }
      });

  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridData();
  }
  getVendorTierExist(data) {
    this.errormgs = false;
    if (data.value.status === 'A') {
      this.vendorAddForm.controls.discount.patchValue(
        data.value.discount
      );
      this.vendorAddForm.controls.days.patchValue(
        data.value.daysDelay);
      this.vendorAddForm.controls.status.patchValue(this.status1.filter(x => x.value === data.value.status)[0]);
    } else {
      this.vendorAddForm.controls.vendorAddTier.reset();
      this.vendorAddForm.controls.discount.reset();
      this.vendorAddForm.controls.days.reset();
      this.vendorAddForm.controls.status.reset();
      setTimeout(() => {
        this.errormgs = true;
      }, 500);

    }
  }
  reset() {
    this.availableSubcontractorForm.reset();
    this.records = false;
    this.gridData();

  }

  getIndustryDropDown() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'ALIT').subscribe(res => {
      this.industry = res.data;
      this.industry.forEach((element) => {
        this.industryTable[element.code] = element.label;
      });

    });
  }
  getTableIndustry(data) {
    const industry =
      data === null || data === undefined
        ? ''
        : this.industry.find((x) => x.code === data);
    this.tableStatus = industry.label;
    return this.tableStatus;

  }
  getCountryDropdown() {

    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'adlc').subscribe(res => {
      this.country = res.data;
    });
  }
  getStateByUrl(country) {
    this.availableSubcontractorForm.controls.vendorState.setValue('');
    if (country === null || '' || undefined) { }
    else {  // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.states + country).subscribe((res) => {
        this.state = res.data;
      });
    }

  }
  searchData() {
    this.loading = true;
    const pgNo = this.page;
    const recordPage = this.rows;
    // tslint:disable-next-line:max-line-length
    const accountName = this.availableSubcontractorForm.controls.subcontratorName.value === null ? '' : this.availableSubcontractorForm.controls.subcontratorName.value;
    // tslint:disable-next-line:max-line-length
    const accountUrl = this.availableSubcontractorForm.controls.subcontratorUrl.value === null ? '' : this.availableSubcontractorForm.controls.subcontratorUrl.value;
    // tslint:disable-next-line:max-line-length
    const accCountry = this.availableSubcontractorForm.controls.country.value === null || '' ? '' : this.availableSubcontractorForm.controls.country.value.code === undefined ? '' : this.availableSubcontractorForm.controls.country.value.code;
    // tslint:disable-next-line:max-line-length
    const accState = this.availableSubcontractorForm.controls.vendorState.value === null || '' ? '' : this.availableSubcontractorForm.controls.vendorState.value.code === undefined ? '' : this.availableSubcontractorForm.controls.vendorState.value.code;
    // tslint:disable-next-line:max-line-length
    const accountIndustry = this.availableSubcontractorForm.controls.industry.value === null || '' ? '' : this.availableSubcontractorForm.controls.industry.value === undefined ? '' : this.availableSubcontractorForm.controls.industry.value;
    // tslint:disable-next-line:max-line-length
    const associatedSearch = pgNo + '/' + recordPage + '?name=' + accountName + '&country=' + accCountry + '&state=' + accState + '&url=' + accountUrl + '&industry=' + accountIndustry;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.activeVendorGrid + associatedSearch).subscribe(res => {
      this.vendorSearchTableData = res.data;
      this.totalRecords = res.total;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
      this.loading = false;

      this.vendorSearchTableData = res.data;
      this.totalRecords = res.total;

    });

  }
  addSave() {
    if (this.vendorAddForm.invalid) {
      const controls = this.vendorAddForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      const data = {
        tierId: this.vendorAddForm.controls.vendorAddTier.value.id,
        venId: this.vendorId,
        discount: this.vendorAddForm.controls.discount.value,
        daysDelay: this.vendorAddForm.controls.days.value,
        maxSubmission: this.vendorAddForm.controls.maxSubmission.value
      };
      this.disabled = true;
      this.loading = true;
      this.apisService.postApi(environment.addVendorTier, data).subscribe((res: any) => {
        this.saveData = res;
        this.display = false;
        this.loading = false;
        this.gridData();
        this.vendorAddForm.reset();
        if (this.saveData.success === true) {
          this.loading = false;
          this.messageService.add({ key: 'br', severity: '', life: 3000,  detail: this.saveData.message });
          this.disabled = false;
          this.display = false;
          this.gridData();
        } else if (this.saveData.success === false) {
          this.loading = false;
          this.display = true;
          this.messageService.add({  key: 'br', severity: '', life: 3000, detail: this.saveData.message});
        } else {
          this.loading = false;
          this.disabled = false;
          this.display = true;
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.saveData.error });
        }
        this.loading = false;
        this.disabled = false;
        this.gridData();
      });
    }
  }
  associateAlert() {
    Swal.fire({
      icon: 'warning',
      text: 'You can\'t associate yourself',
      confirmButtonColor: '#0D416B',
      confirmButtonText: 'Ok',
    });
  }
  getAddVendorNames() {
    const pageNo = this.page;
    const pageCount = this.rows;
    const datapage = pageNo + '/' + pageCount;
    this.accountDetails = JSON.parse(this.apisService.decodeData());
    this.apisService.getApi(environment.vendorTier + datapage + '/'+ this.accountDetails.accId).subscribe((res) => {
      this.vendorTierList = res.data;
    });
  }
  // It is used for showing the alert message for do want sure to Associate or not
  addDailong(data) {
    this.data = this.rowData;
    this.vendorId = this.data.id;
    this.vendorAddForm.reset();
    this.display = true;
    this.vendorAddForm.controls.subContractorName1.patchValue(this.data.name);
  }
  toggle(data, event, rowData) {
    this.rowData = rowData;
    if (this.rowData.id === this.accountId) {
    } else {
      this.items = [
        {
          label: 'Associate',
          icon: 'fa fa-handshake-o',
          // tslint:disable-next-line:no-shadowed-variable
          command: (event, i) => {
            this.addDailong(data);
          },
        },
        {
          label: 'Details',
          icon: 'pi pi-info-circle',

          // tslint:disable-next-line:no-shadowed-variable
          command: (event, i) => {
            this.vendorDetails(rowData);
          },
        },
      ];
    }
  }

  vendorDetails(data) {
    sessionStorage.setItem('subContractorId', data.id);
    this.router.navigate(['/edit-subcontractor', data.id], {
      queryParams: {
        type: 2,
        sName: data.name
      },
    });

  }
  goToLink(url: string) {
    const addressLink = url.includes('http') ? url : `http://${url}`;
    window.open(addressLink, '_blank');
  }
}
