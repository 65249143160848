import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { tick } from '@angular/core/testing';
import { ActivatedRoute, Router } from '@angular/router';
import { Stomp } from '@stomp/stompjs';
import { LOADIPHLPAPI } from 'dns';
import { MessageService } from 'primeng/api';
import * as SockJS from 'sockjs-client';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
  providers: [DatePipe, MessageService],
})
export class VerifyEmailComponent implements OnInit {
  name: any;
  socket: WebSocket;
  stompClient: any;
  message1: any = [];
  subId: any;
  resMsg: any;
  tokenExp: boolean;
  success: boolean;
  loader = true;
  cards = false;
  constructor( private route: ActivatedRoute, private apisService: ApisService, private router: Router, ) { }
  ngOnInit(): void {
      this.route.queryParams.subscribe(params => {
      this.name = params.sessionId;
    });
      this.verifyemail();
  }
  verifyemail(){
    const tokenId = atob(this.name.split('.')[1]);
    this.subId = JSON.parse(tokenId).sub;
    this.sendMessage();
}
sendMessage(){
  this.socket = new SockJS(environment.socketendpoint);
  this.stompClient = Stomp.over(this.socket);
  this.stompClient.connect({},
  fra => {
    this.loader = true;
    // tslint:disable-next-line: no-shadowed-variable
    this.stompClient.subscribe('/topic/verify-email/' + this.subId, tick => {
  this.cards = true;
  this.resMsg = JSON.parse(tick.body);
  this.loader = false;
  if (this.resMsg.success === false){
      this.loader = false;
      this.tokenExp = true;
      this.success = false;
    }
    else{
      this.loader = false;
      this.success = true;
      this.tokenExp = false;
      this.stompClient.disconnect(); // disconnected connection
    }
  this.message1.push(tick.body);
    });
    this.stompClient.send('/servicesBay/verify-email/' + this.subId + '/' + this.name, {});
});
}
goto() {
  this.router.navigate(['/login']);
}
}
