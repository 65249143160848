import { Component, OnDestroy } from '@angular/core';
import { BreadcrumbService } from './app.breadcrumb.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { ApisService } from './services/apis.service';
import { RouterTestingModule } from '@angular/router/testing';
import { Router } from '@angular/router';
@Component({
    selector: 'app-breadcrumb',
    templateUrl: './app.breadcrumb.component.html'
})
export class AppBreadcrumbComponent implements OnDestroy {
    subscription: Subscription;
    items: MenuItem[];
    loginRoleId: any;
    usrroleid: any;
    constructor(public breadcrumbService: BreadcrumbService, private apiservice: ApisService, private router: Router) {
        // tslint:disable-next-line: deprecation
        this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
        });
        this.apiservice.rolebasedsidemenu.subscribe((res1: any) => {
            this.usrroleid = res1.id;
        });
    }
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    routing() {
        const logInDetails = this.apiservice.decodeData();
        this.loginRoleId = JSON.parse(logInDetails).roles[0].id;
        if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
            if (this.loginRoleId === 5) {
                this.router.navigate(['/project-requirements']);
            } else if (this.loginRoleId === 4) {
                // tslint:disable-next-line:no-unused-expression
                this.router.navigate(['/purchas-requirement']);
            }
            else if (this.loginRoleId === 3) {
                this.router.navigate(['/account-details']);
            }
            else if (this.loginRoleId === 9) {
                this.router.navigate(['/open-positions']);
            }
            else if (this.loginRoleId === 1) {
                this.router.navigate(['/accounts']);
            }
            else if (this.loginRoleId === 10) {
                this.router.navigate(['/tech-evaltion-tech-reviews']);
            }
            else if (this.loginRoleId === 11) {
                this.router.navigate(['/employee-requirements']);
            }
            else if (this.loginRoleId === 12) {
                this.router.navigate(['/tech-evalution']);
            }
            else if (this.loginRoleId === 6) {
                this.router.navigate(['/accountdetails']);
            }
            else if (this.loginRoleId === 8) {
                this.router.navigate(['/openpositions']);
            }
            else if (this.loginRoleId === 13) {
                this.router.navigate(['/salesOpenPositions']);
            }
            else if (this.loginRoleId === 14) {
                this.router.navigate(['/vendor']);
            }
            else if (this.loginRoleId === 15) {
                this.router.navigate(['/consultantopenposition']);
            }
            else {
                return true;
            }
        } else {
            if (this.usrroleid === 5) {
                this.router.navigate(['/project-requirements']);
            } else if (this.usrroleid === 4) {
                // tslint:disable-next-line:no-unused-expression
                this.router.navigate(['/purchas-requirement']);
            }
            else if (this.usrroleid === 3) {
                this.router.navigate(['/account-details']);
            }
            else if (this.usrroleid === 9) {
                this.router.navigate(['/open-positions']);
            }
            else if (this.usrroleid === 1) {
                this.router.navigate(['/accounts']);
            }
            else if (this.usrroleid === 10) {
                this.router.navigate(['/tech-evaltion-tech-reviews']);
            }
            else if (this.usrroleid === 11) {
                this.router.navigate(['/employee-requirements']);
            }
            else if (this.usrroleid === 12) {
                this.router.navigate(['/tech-evalution']);
            }
            else if (this.usrroleid === 6) {
                this.router.navigate(['/accountdetails']);
            }
            else if (this.usrroleid === 8) {
                this.router.navigate(['/openpositions']);
            }
            else if (this.usrroleid === 13) {
                this.router.navigate(['/salesOpenPositions']);
            }
            else if (this.usrroleid === 14) {
                this.router.navigate(['/vendor']);
            }
            else if (this.usrroleid === 15) {
                this.router.navigate(['/consultantopenposition']);
            }
            else {
                return true;
            }
        }
        return false;

    }
}


