<div *ngIf="this.roleId != 14">
  <p-splitButton icon="pi pi pi-bars" [model]="dropDownValue"></p-splitButton>
</div>
<div  *ngIf="custName">
	<div class="account"><strong>Customer : <span class="accname">{{this.salescustaccname}}</span></strong></div><br>
</div>
<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
  <form [formGroup]="ContactsForm">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields"> First Name</label>
        <input id="input" type="text" pInputText placeholder="First Name" autocomplete="off" formControlName="FirstName"
          maxlength="25">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Last Name</label>
        <input id="input" type="text" pInputText placeholder="Last Name" autocomplete="off" formControlName="LastName"
          maxlength="25">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Email</label>
        <input id="input" type="text" pInputText placeholder="Email" autocomplete="off" formControlName="Emailid"
          maxlength="60">
        <div>
        </div>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Phone</label>
        <p-inputMask autocomplete="off" mask="(999) 999-9999" formControlName="Phone" placeholder="Phone">
        </p-inputMask>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Status</label>
        <p-dropdown [options]="status" placeholder="Select Status" optionLabel="label" [showClear]="true" formControlName="Status">
        </p-dropdown>
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
      <div class="btnsCSS1 btnsalign">
        <button pButton type="button" icon='pi pi-plus' label="Add" (click)="AddData()" class="addhBtn"></button>
        <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="resetData()" class="addhBtn"></button>
        <button pButton type="button" icon='pi pi-search' label="Search" (click)="searchData()"
          class="addhBtn"></button>
      </div>
    </div>
    <div *ngIf="isUploading" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
      <p-progressBar mode="indeterminate"></p-progressBar>
    </div>
    <div *ngIf="showUploadView" class="dropzone p-col-12 p-md-12 p-sm-12 p-lg-3">
      <em class="pi pi-upload uploadIcon Iconsize" aria-hidden="true"></em>
      <input accept=".xlsm,.xlsx,application/msexcel" type="file" class="fileInput" #fileDropRef id="fileDropRef"
        multiple (change)="onUpload($event.target.files)" style="cursor: pointer;"/>
      <h3>Drag and drop file here</h3>
      <h3 class="importstylin">or</h3>
      <label id="fileUpload" for="fileDropRef">Browse for files</label>
    </div>
  </form>
</p-card><br>
<p-card class="pgpos">
  <div *ngIf="table">
    <!--It is used for displaying the Contacts table-->
    <p-table class="tablescroll" [columns]="cols" [value]="grid" selectionMode="single" dataKey="edit" [rows]="10"
      [responsive]="true" sortMode="multiple">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th id="gridHeader" *ngFor="let col of columns; let i=index" (click)="iconToggle[i] = !iconToggle[i]"
            [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td class="textCenter skill-desc" tooltipPosition="top" pTooltip="{{data.name}}"><span class="p-column-title">Account Name</span>{{data.name}}</td>
          <td class="textCenter skill-desc" tooltipPosition="top" pTooltip="{{data.email}}"><span class="p-column-title">Email</span><a class="pointer" (click)="emailToast()"
              (click)="copyInputMessage(data.email)">{{data.email}}</a></td>
          <td class="textCenter skill-desc" tooltipPosition="top" pTooltip="{{roletable(data.roleList)}}"><span class="p-column-title">Role</span>{{ roletable(data.roleList)}}</td>
          <td class="textCenter"><span class="p-column-title">Phone</span>{{data.officePhone}}</td>
          <td>
            <p-badge [value]="statuTable[data.status]"
              [ngClass]="data.status==='A'?'green': data.status === 'I'?'red': 'blue'">
            </p-badge>
          </td>
          <td class="textCenter"><span class="p-column-title">Action</span><a> <em
                (click)="downloadActionPopup.toggle($event); toggle($event,data, rowData)"
                class="pi pi-ellipsis-v pointer"></em> </a> </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr *ngIf="grid?.length==0" class="aligncenter">
          <td colspan="6" class="mandatoryColor"> No Records </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
        <h3 class="norecorddata">Total records : {{this.totalrecords}}</h3>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-10 ">
        <p-paginator [rows]="10" [totalRecords]="totalrecords" [rowsPerPageOptions]="[10,20,30]"
          (onPageChange)="paginate($event)"></p-paginator>
      </div>
    </div>
  </div>
  <p-card *ngIf="records">
    <div class="norecordImgsize">
      <div *ngIf="records" class="aligncenter">
        <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
        <br><em>Sorry, we are not able to find any contacts</em><br>
      </div>
    </div>
  </p-card>
  <!---Dialog box  for Contact Login Credentials---->
  <p-dialog class="dialogBox" header="Contact Login Credentials" [(visible)]="displayActive" [style]="{width: '25%'}"
    [draggable]="false" [modal]="true" [resizable]="false">
    <p><strong>{{loginActiveData}}</strong></p>
    <a class="aligncenter pointer">({{email1}})</a>
    <br><br>
    <div *ngIf="registerdStatus" class="btnsCSS1">
      <button pButton type="button" icon='pi pi-times' label="Cancel" (click)="cancel()" class="right"></button>
      <button pButton type="button" icon='pi pi-send' label="Send" (click)="saveUserContacts()" class="buttonwidth"
        [disabled]="disable"></button>
    </div>
  </p-dialog>
</p-card>
<div *ngIf="!loader; else showLoader"></div>
<p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="items"></p-menu>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center laoderheight">
        <div class="laodersize">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
            class="loaderimg-height">
        </div>
      </div>
    </div>
  </div>
</ng-template>