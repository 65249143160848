import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApisService } from 'src/app/services/apis.service';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-vendor-asst',
  templateUrl: './vendor-asst.component.html',
  styleUrls: ['./vendor-asst.component.scss'],
  providers: [DatePipe, MessageService],
})
export class VendorAsstComponent implements OnInit {

  vendorAssociatedCustomerForm: FormGroup;
  cols = [
    { field: 'name', header: 'Vendor Name', width: '9%' },
    { field: 'webSite', header: 'Corporate Website', width: '9%' },
    { field: 'phone', header: 'Phone Number', width: '7%' },
    { field: 'status', header: 'Status', width: '5%' },
    { field: 'industry', header: 'Industry', width: '7%' },
    { field: 'tierName', header: 'Tiers', width: '5%' },
    { field: 'createdDate', header: 'Date Added', width: '7%' },
    { field: '', header: 'Action', width: '3%' },
  ];
  rows = 10;
  page = 1;
  loading: boolean;
  vendorAssociatedCustomertableData: any;
  totalrecods: any;
  table: boolean;
  accId: any;
  first: 1;
  pageCount: any;
  country: any;
  state: any;
  statusDD: any;
  statusLabel: {};
  industry: any;
  industryLabel: {};
  records: boolean;
  getStatusTable = {};
  getTableIndustry = {};
  vendorAssociatedStaffingMode: any[];
  rowData: any;
  items: { label: string; icon: string; command: () => void; }[];
  resetbutton: boolean;

  // tslint:disable-next-line:max-line-length
  constructor(private route: ActivatedRoute, private breadcrumbService: BreadcrumbService, private fb: FormBuilder, private router: Router, private apisService: ApisService) {
    this.breadcrumbService.setItems([
      { label: 'Vendors', routerLink: '/VendorAssociatedCustomer' }
    ]);
  }
  ngOnInit(): void {
    const userdetails = JSON.parse(this.apisService.decodeData());
    this.accId = userdetails.accId;
    this.vendorAssociatedCustomerForm = this.fb.group({
      vendorName: [''],
      vendorUrl: [''],
      vendorStatus: [''],
      vendorIndustry: [''],
      vendorCountry: [''],
      vendorState: [''],
      vendorStaffingMode: ['']
    });
    this.gridData();
    this.getCountryDropdown();
    this.getStatusData();
    this.getIndustryDropdown();
  }

  getCountryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'adlc').subscribe(res => {
      this.country = res.data;
    });
  }

  getStateByUrl(country) {
    this.vendorAssociatedCustomerForm.controls.vendorState.setValue('');
    if (country === null || undefined || ''){}
    else{
      this.apisService.getApi(environment.states + country).subscribe(res => {
        this.state = res.data;
      });
    }
  }
  getIndustryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'ALIT').subscribe(res => {
      this.industry = res.data;
      this.industry.forEach(element => {
        this.getTableIndustry[element.code] = element.label;
      });

    });
  }
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchData();
    }
  }

  goToLink(url: string) {
    const addressLink = url.includes('http') ? url : `http://${url}`;
    window.open(addressLink, '_blank');
  }
  getStatusData() {
    this.apisService.getApi(environment.lookupsData + 'RLS').subscribe(res => {
      this.statusDD = res.data;
      this.statusDD.forEach(element => {
        this.getStatusTable[element.code] = element.label;
      });
    }
    );
  }
  gridData() {
    const rows = this.rows;
    const page = this.page;
    const data = page + '/' + rows;
    this.loading = true;
    this.apisService.getApi(environment.vendorAccsocitegrid + data).subscribe((res: any) => {
      this.vendorAssociatedCustomertableData = res.data;
      this.loading = false;
      this.totalrecods = res.total;
      if (this.totalrecods === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridData();

  }

  searchData() {
    const associatedStaffingMode = this.vendorAssociatedCustomerForm.controls.vendorStaffingMode.value;
    this.vendorAssociatedStaffingMode = [];
    if (this.vendorAssociatedCustomerForm.controls.vendorStaffingMode.value) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < associatedStaffingMode.length; i++) {
        this.vendorAssociatedStaffingMode.push(associatedStaffingMode[i].value);
      }
    }
    const staffingMode = this.vendorAssociatedCustomerForm.controls.vendorStaffingMode.value;
    this.loading = true;
    const pgNo = this.page;
    const recordPage = this.rows;
    // tslint:disable-next-line: max-line-length
    const accountName = this.vendorAssociatedCustomerForm.controls.vendorName.value === null ? '' : this.vendorAssociatedCustomerForm.controls.vendorName.value === undefined ? '' : this.vendorAssociatedCustomerForm.controls.vendorName.value === '' ? '' : this.vendorAssociatedCustomerForm.controls.vendorName.value;
    // tslint:disable-next-line: max-line-length
    const accountUrl = this.vendorAssociatedCustomerForm.controls.vendorUrl.value === null ? '' : this.vendorAssociatedCustomerForm.controls.vendorUrl.value
    // tslint:disable-next-line: max-line-length
    === undefined ? '' : this.vendorAssociatedCustomerForm.controls.vendorUrl.value === '' ? '' : this.vendorAssociatedCustomerForm.controls.vendorUrl.value;
    // tslint:disable-next-line: max-line-length
    const accCountry = this.vendorAssociatedCustomerForm.controls.vendorCountry.value === null || '' ? '' : this.vendorAssociatedCustomerForm.controls.vendorCountry.value.code === undefined ? '' : this.vendorAssociatedCustomerForm.controls.vendorCountry.value.code;
    // tslint:disable-next-line: max-line-length
    const accState = this.vendorAssociatedCustomerForm.controls.vendorState.value === null || '' ? '' : this.vendorAssociatedCustomerForm.controls.vendorState.value.code === undefined ? '' : this.vendorAssociatedCustomerForm.controls.vendorState.value.code;
    // tslint:disable-next-line: max-line-length
    const accountIndustry = this.vendorAssociatedCustomerForm.controls.vendorIndustry.value === null || '' ? '' : this.vendorAssociatedCustomerForm.controls.vendorIndustry.value.code === undefined ? '' : this.vendorAssociatedCustomerForm.controls.vendorIndustry.value.code;
    // tslint:disable-next-line:max-line-length
    const status = this.vendorAssociatedCustomerForm.controls.vendorStatus.value === null || this.vendorAssociatedCustomerForm.controls.vendorStatus.value === undefined || this.vendorAssociatedCustomerForm.controls.vendorStatus.value === '' ? '' : this.vendorAssociatedCustomerForm.controls.vendorStatus.value.code;
    const associatedSearch =
      pgNo + '/' + recordPage + '?name=' + accountName + '&country=' + accCountry + '&state=' + accState + '&url=' + accountUrl + '&industry=' + accountIndustry + '&status=' + status;
    this.apisService.getApi(environment.vendorAccsocitegrid + associatedSearch).subscribe(res => {
      this.vendorAssociatedCustomertableData = res.data;
      this.totalrecods = res.total;
      if (this.totalrecods === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
      this.loading = false;
    });

  }
  goToaccounts(data) {
    sessionStorage.setItem('vendorname', data.name);
    sessionStorage.setItem('vendorId', data.id);
    this.router.navigate(['/accdetails', data.id], {
      queryParams: {
        type: 3,
      },
    });
  }
  reset() {
    this.vendorAssociatedCustomerForm.reset();
    this.records = false;
    this.gridData();
  }

  toggle(event, data, rowData) {
    this.rowData = rowData;
    this.items = [
      {
        label: 'Details',
        icon: 'pi pi-eye showEye',
        command: () => {
          this.goToaccounts(data);
        },
      },
    ];
  }
}
