import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rec-interviews',
  templateUrl: './rec-interviews.component.html',
  styleUrls: ['./rec-interviews.component.scss'],
  providers: [DatePipe, MessageService],
})
export class RecInterviewsComponent implements OnInit {

  submittedProfileForm: FormGroup;
  rows: any = 10;
  page: any = 1;
  pageCount: any;
  exprienceList: any = [];
  expLabel = {};
  items: any = [];
  cols11 = [
    { field: 'reqId', header: 'Job Id' },
    { field: 'consultantName', header: 'Candidate Name' },
    { field: 'submittedDate', header: 'Submitted Date' },
    { field: 'requiredSkills', header: 'Skills' },
    { field: 'experience', header: 'Experience' },
    { field: 'payRate', header: 'Pay Rate' },
    { field: 'billRate', header: 'Bill Rate' },
    { field: '', header: 'Action' },
  ];
  first: any;
  gridData: any;
  acctdetails: any;
  preferencList: any = [];
  currencyValue: string;
  currencycode: any;
  billingList: any = [];
  loader: boolean;
  statusList: any = [];
  statusData = {};
  subProRecords: any;
  PreferredSkills: any = [];
  skillset = {};
  display: boolean;
  reqSkillsValue: any;
  table: boolean;
  records: boolean;
  array: any = [];
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.tableGet();
    }
  }
  constructor(
    private datepipe: DatePipe,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apisService: ApisService,
    private breadcrumbService: BreadcrumbService,
  ) {
    this.breadcrumbService.setItems([
      { label: 'Candidates Interviews', routerLink: '/rec-interviews' },

    ]);

  }

  ngOnInit(): void {
    this.acctdetails = JSON.parse(this.apisService.decodeData());
    this.preferencList = this.acctdetails.preferencesList;
    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }

    this.submittedProfileForm = this.fb.group({
      firstName: ['', [Validators.pattern('^[a-zA-Z]*$')]],
      lastName: ['', [Validators.pattern('^[a-zA-Z]*$')]],
      vendorName: ['', Validators.pattern('^[a-zA-Z]*$')],
      emailId: ['', [Validators.pattern('^[a-zA-Z]*$')]],
      skillSet: [''],
      experience: [''],
      jobId: ['']
    });
    this.getexperience();
    this.getdSkillset();
    this.getStatus();
    this.tableGet();
  }
  getexperience() {
    this.apisService.getApi(environment.lookupsData  + 'RELYE').subscribe((res: any) => {
      this.exprienceList = res.data;
      this.exprienceList.forEach(element => {
        this.expLabel[element.code] = [element.label];
      });
    });
  }
  getdSkillset() {
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.PreferredSkills = res.data;
      this.PreferredSkills.forEach((element) => {
        this.skillset[element.code] = element.label;
      });
    });
  }
  getSkillSetTable(data) {
    this.array = data;
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.PreferredSkills.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < this.array.length; j++) {
        if (Number(this.PreferredSkills[i].code) === this.array[j]) {
          selectedPrePrimarySkills3.push(
            this.PreferredSkills[i].label
          );
        } else {
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  getStatus() {
    this.apisService.getApi(environment.lookupsData + 'RELS').subscribe((res) => {
      this.statusList = res.data;
    });
  }
  tableGet() {
    // tslint:disable-next-line:max-line-length
    const jobID = this.submittedProfileForm.controls.jobId.value === null || undefined || '' ? '' : this.submittedProfileForm.controls.jobId.value;

    // tslint:disable-next-line:max-line-length
    const fName = this.submittedProfileForm.controls.firstName.value === null || undefined || '' ? '' : this.submittedProfileForm.controls.firstName.value;
    // tslint:disable-next-line:max-line-length
    const lName = this.submittedProfileForm.controls.lastName.value === null || undefined || '' ? '' : this.submittedProfileForm.controls.lastName.value;
    // tslint:disable-next-line:max-line-length
    const email = this.submittedProfileForm.controls.emailId.value === null || undefined || '' ? '' : this.submittedProfileForm.controls.emailId.value;
    // tslint:disable-next-line:max-line-length
    const exp = this.submittedProfileForm.controls.experience.value === null || this.submittedProfileForm.controls.experience.value === undefined || this.submittedProfileForm.controls.experience.value === '' ? '' : this.submittedProfileForm.controls.experience.value.code;
    const row = this.rows;
    const page = this.page;
    this.loader = true;
    this.apisService.getApi(environment.recsubmitGet + page + '/' + row + '?reqId=' + jobID + '&status=F,I' + '&consEmail=' + email + '&fName=' + fName + '&lName=' + lName + '&exp=' + exp).subscribe(res => {
      this.loader = false;
      this.gridData = res.data.records;
      this.gridData.forEach((element) => {
        element.skills = this.getSkillSetTable(element.requiredSkills);
      });
      this.subProRecords = res.data.count;
      if (this.subProRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.loader = false;
    });
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.tableGet();
  }
  reqSkills(data) {
    this.display = true;
    this.reqSkillsValue = data;
  }
  toggle(event) {
  }
  reset() {
    this.loader = true;
    this.submittedProfileForm.reset();
    this.tableGet();
  }
}
