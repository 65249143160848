<p-toast position="bottom-right" key="br"></p-toast>
<p-card><br>
    <div class="p-grid ">
        <form [formGroup]="resetPasswordForm">
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-8 pswd">
                    <p-panel [toggleable]="false" [style]="{'margin-bottom':'20px'}">
                        <ng-template pTemplate="header">
                            <label class="inputfields"><strong>Reset password</strong></label><span
                                (click)="instructions()"><em pTooltip="Instructions" tooltipPosition="right"
                                    class="pi pi-exclamation-circle Ttipos" style="cursor: pointer;"></em></span>
                        </ng-template>
                        <div class="pass">
                            <br>
                            <div class="p-grid p-fluid">
                                <div class="p-col-3 p-lg-3 p-fluid">
                                    <label class="inputfields">Email<span class="mandatoryColor">*</span></label>
                                </div>
                                <div class="p-col-3 p-lg-3 p-fluid">

                                </div>
                                <div class="p-col-6 p-lg-6 p-fluid">
                                    <div class=" p-inputgroup">
                                        <input type="text" formControlName="Email" autocomplete="off" placeholder="Email" maxlength=61
                  class=" p-inputtext  p-widget"><span class="pi pi-envelope"
                  style="position:absolute; right:8px;top: 31px;"></span>  
                                    </div>
                                    <span class="validationtest"
                                        *ngIf="this.resetPasswordForm.controls.Email.touched && this.resetPasswordForm.controls.Email.invalid && this.resetPasswordForm.controls.Email.errors.required">
                                        Please enter email</span>
                                    <span class="validationtest"
                                        *ngIf="this.resetPasswordForm.get('Email').hasError('maxlength')">
                                        Please enter 60 alphabets only
                                    </span>
                                    <span class="validationtest"
                                        *ngIf="resetPasswordForm.controls.Email.invalid && resetPasswordForm.controls.Email.errors.pattern">
                                        Must be valid corp email
                                    </span>
                                </div>
                            </div>
                            <div class="p-grid ">
                                <div class="p-col-5 p-lg-6 p-fluid">
                                    <label class="inputfields">New Password<label
                                            class="mandatoryColor">*</label></label>
                                </div>
                                <div class="p-col-7 p-lg-6 p-fluid">
                                    <div class=" p-inputgroup">
                                        <input type="text" formControlName="password" autocomplete="off" placeholder="Password"
                                        maxlength=21 class=" p-inputtext  p-widget" >
                                      <!-- <span class="hello"><em class="pi pi-eye-slash showEye"
                                          style="font-size: 18px; color: black;" (click)="myFunction()" *ngIf="hideIcon"></em>
                                        <em class="pi pi-eye showEye" style="font-size:  18px; color: black;" (click)="myFunction1()"
                                          *ngIf="showIcon"></em></span> -->
                                        <br><br>
                                    </div>
                                    <span class="validationtest"
                                        *ngIf="this.resetPasswordForm.get('password').hasError('maxlength')">
                                        Please enter 20 alphabets only
                                    </span>
                                </div>
                            </div>
                            <br>
                            <div class="p-grid">
                                <!-- <div class="p-col-8 p-lg-8 p-fluid"></div> -->
                                <button pButton type="button" class="btn" label="Reset Password" [disabled]='disable'
                                    icon="pi pi-key" (click)="resetPassword()"></button>
                            </div>
                        </div>
                    </p-panel>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                    <div class="p-grid siz">
                        <div class="p-col-12 p-fluid sic">
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</p-card>
<p-dialog header="Password Setup" [(visible)]="instruction" [modal]="true" [style]="{width: '30%' }" [baseZIndex]="1"
    [draggable]="false" [resizable]="false">
    <div class="p-grid  p-fluid">
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 tool">
            <label>At least 8 alphabets but not more than 20 alphabets</label><br><br>
            <label>At least one uppercase letter</label><br><br>
            <label>At least one or more lowercase letters</label><br><br>
            <label>At least one number</label><br><br>
            <label>At least one special character from !@#%^*=-+;.|:[]</label>
        </div>
    </div>
</p-dialog>