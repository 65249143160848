import { CommonModule, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GMapModule } from 'primeng/gmap';
// PrimeNG Components for demos
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KnobModule } from 'primeng/knob';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { AppRoutingModule } from './app-routing.module';
import { AppBreadcrumbComponent } from './app.breadcrumb.component';
// Application services
import { BreadcrumbService } from './app.breadcrumb.service';
// Demo pages
import { AppCodeModule } from './app.code.component';
import { BnNgIdleService } from 'bn-ng-idle';
// Application Components
import { AppComponent } from './app.component';
import { AppConfigComponent } from './app.config.component';
import { AppFooterComponent } from './app.footer.component';
import { AppMainComponent } from './app.main.component';
import { AppMenuComponent } from './app.menu.component';
import { MenuService } from './app.menu.service';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppProfileComponent } from './app.profile.component';
import { AppTopBarComponent } from './app.topbar.component';
import { CustomerAccountDetailsComponent } from './components/customer-admin/customer-account-details/customer-account-details.component';
import { CustomerAddContactsComponent } from './components/customer-admin/customer-add-contacts/customer-add-contacts.component';
import { CustomerAttachmentsComponent } from './components/customer-admin/customer-attachments/customer-attachments.component';
import { CustomerContactsComponent } from './components/customer-admin/customer-contacts/customer-contacts.component';
import { CustomerEditContactsComponent } from './components/customer-admin/customer-edit-contacts/customer-edit-contacts.component';
import { CustomerLocationsComponent } from './components/customer-admin/customer-locations/customer-locations.component';
import { AcademicComponent } from './components/employee/academic/academic.component';
import { CertificationsComponent } from './components/employee/certifications/certifications.component';
// tslint:disable-next-line: max-line-length
import { ConsultantResumeComponentComponent } from './components/employee/consultant-resume-component/consultant-resume-component.component';
import { EmployeeInterviewComponent } from './components/employee/employee-interview/employee-interview.component';
import { EmployeeSkillsComponent } from './components/employee/employee-skills/employee-skills.component';
import { EmployeereviewsComponent } from './components/employee/employeereviews/employeereviews.component';
import { LicenseComponent } from './components/employee/license/license.component';
import { NetworkComponent } from './components/employee/network/network.component';
import { PreferencesComponent } from './components/employee/preferences/preferences.component';
import { RecruiterComponent } from './components/employee/recruiter/recruiter.component';
import { SubmissionrequestComponent } from './components/employee/submissionrequest/submissionrequest.component';
import { LogincComponent } from './components/loginc/loginc.component';
import { AddTechreviewsComponent } from './components/project-lead/add-techreviews/add-techreviews.component';
import { TechEvalutionComponent } from './components/project-lead/tech-evalution/tech-evalution.component';
import { AddRequirementsComponent } from './components/projectManager/add-requirements/add-requirements.component';
import { CopyRequirementComponent } from './components/projectManager/copy-requirement/copy-requirement.component';
import { EditRequirementsComponent } from './components/projectManager/edit-requirements/edit-requirements.component';
import { ProjectRequirementsComponent } from './components/projectManager/project-requirements/project-requirements.component';
import { ScheduleInterviewComponent } from './components/projectManager/schedule-interview/schedule-interview.component';
import { SubmitedProfilesComponent } from './components/projectManager/submited-profiles/submited-profiles.component';
import { TechReviewsComponent } from './components/projectManager/tech-reviews/tech-reviews.component';
import { DepartmentsComponent } from './components/purchasing/departments/departments.component';
import { JobCategoryComponent } from './components/purchasing/job-category/job-category.component';
import { JobTitleComponent } from './components/purchasing/job-title/job-title.component';
import { PurchasRequirementComponent } from './components/purchasing/purchas-requirement/purchas-requirement.component';
import { TaskComponent } from './components/purchasing/task/task.component';
import { TeamComponent } from './components/purchasing/team/team.component';
import { TiersComponent } from './components/purchasing/tiers/tiers.component';
import { VendorAccountComponent } from './components/purchasing/vendor-account/vendor-account.component';
import { VendorAsstComponent } from './components/purchasing/vendor-asst/vendor-asst.component';
import { VendorDefinationComponent } from './components/purchasing/vendor-defination/vendor-defination.component';
import { VendorSearchComponent } from './components/purchasing/vendor-search/vendor-search.component';
import { WorkLocationComponent } from './components/purchasing/work-location/work-location.component';
import { AddConsultantComponent } from './components/recruiter/add-consultant/add-consultant.component';
import { AddCustomerComponent } from './components/recruiter/add-customer/add-customer.component';
import { AddvendorrequirementComponent } from './components/recruiter/addvendorrequirement/addvendorrequirement.component';
import { AvailableopenpositionsComponent } from './components/recruiter/availableopenpositions/availableopenpositions.component';
import { ConsultantSearchComponent } from './components/recruiter/consultant-search/consultant-search.component';
import { CustomerComponent } from './components/recruiter/customer/customer.component';
import { EditConsultantSearchComponent } from './components/recruiter/edit-consultant-search/edit-consultant-search.component';
import { OpenPositionsEditComponent } from './components/recruiter/open-positions-edit/open-positions-edit.component';
import { OpenPositionsComponent } from './components/recruiter/open-positions/open-positions.component';
import { VendorRequirementEditComponent } from './components/recruiter/vendor-requirement-edit/vendor-requirement-edit.component';
import { VendorRequirementComponent } from './components/recruiter/vendor-requirement/vendor-requirement.component';
import { VendorTierDefinitionComponent } from './components/recruiter/vendor-tier-definition/vendor-tier-definition.component';
import { AvailablesubcontractorComponent } from './components/recruiter/vendor/availablesubcontractor/availablesubcontractor.component';
import { SubcontractorComponent } from './components/recruiter/vendor/subcontractor/subcontractor.component';
import { VendotTierComponent } from './components/recruiter/vendot-tier/vendot-tier.component';
import { ChangepasswordComponent } from './components/registrationpages/changepassword/changepassword.component';
import { ConsultantVendorregistrationComponent } from './components/registrationpages/consultant-vendorregistration/consultant-vendorregistration.component';
import { CustomerregistrationComponent } from './components/registrationpages/customerregistration/customerregistration.component';
import { ProfileComponent } from './components/registrationpages/profile/profile.component';
import { ResetuserpasswordComponent } from './components/registrationpages/resetuserpassword/resetuserpassword.component';
import { UserpreferenceComponent } from './components/registrationpages/userpreference/userpreference.component';
import { AccountSearchComponent } from './components/servicesbay-admin/account-search/account-search.component';
import { AddContactComponent } from './components/servicesbay-admin/add-contact/add-contact.component';
import { AdminMenuComponent } from './components/servicesbay-admin/admin-menu/admin-menu.component';
import { EditContactComponent } from './components/servicesbay-admin/edit-contact/edit-contact.component';
import { VendorAttachmentComponent } from './components/servicesbay-admin/vendor-attachment/vendor-attachment.component';
import { VendorContactComponent } from './components/servicesbay-admin/vendor-contact/vendor-contact.component';
import { VendorDetailsComponent } from './components/servicesbay-admin/vendor-details/vendor-details.component';
import { VendorLocationsComponent } from './components/servicesbay-admin/vendor-locations/vendor-locations.component';
import { TechEvalutionAddTechreviewsComponent } from './components/tech-evalution/tech-evalution-add-techreviews/tech-evalution-add-techreviews.component';
import { TechEvalutionTechreviewsComponent } from './components/tech-evalution/tech-evalution-techreviews/tech-evalution-techreviews.component';
import { VendorAccountDetailsComponent } from './components/vendor-admin/vendor-account-details/vendor-account-details.component';
import { EmployerComponent } from './components/employee/employer/employer.component';
import { RecruitingManagerOpenpositionComponent } from './components/recruitingManager/recruiting-manager-openposition/recruiting-manager-openposition.component';
import { RecruitingManagerOpenpositionEditComponent } from './components/recruitingManager/recruiting-manager-openposition-edit/recruiting-manager-openposition-edit.component';
import { RecrutingManagerRequirementsComponent } from './components/recruitingManager/recruting-manager-requirements/recruting-manager-requirements.component';
import { RecrutingManagerRequirementsEditComponent } from './components/recruitingManager/recruting-manager-requirements-edit/recruting-manager-requirements-edit.component';
import { EmployeeRecommendationsComponent } from './components/employee/employee-recommendations/employee-recommendations.component';
import { PurchaRequirementeditComponent } from './components/purchasing/purcha-requirementedit/purcha-requirementedit.component';
import { RecuiterDashboardComponent } from './components/recruiter/recuiter-dashboard/recuiter-dashboard.component';
import { RequirementPreferenceComponent } from './components/purchasing/requirement-preference/requirement-preference.component';
import { EmployerCalenderComponent } from './components/employee/employer-calender/employer-calender.component';
import { EditSubcontractorComponent } from './components/recruiter/edit-subcontractor/edit-subcontractor.component';
import { RegErrorComponent } from './components/recruiter/reg-error/reg-error.component';
import { ProjectvendorComponent } from './projectvendor/projectvendor.component';
import { SalesOpenPositionsComponent } from './components/sales/sales-open-positions/sales-open-positions.component';
import { SalesopenpositionEditComponent } from './components/sales/sales-open-positions/salesopenposition-edit/salesopenposition-edit.component';
import { AuthenticatePasswordComponent } from './components/authenticate-password/authenticate-password.component';
import { SalesInfoComponent } from './components/projectManager/sales-info/sales-info.component';
import { RecSubmittedComponent } from './components/recruiter/rec-submitted/rec-submitted.component';
import { RecReviewsComponent } from './components/recruiter/rec-reviews/rec-reviews.component';
import { RecInterviewsComponent } from './components/recruiter/rec-interviews/rec-interviews.component';
import { RecSelectedComponent } from './components/recruiter/rec-selected/rec-selected.component';
import { VendorBankComponent } from './components/vendor-admin/vendor-bank/vendor-bank.component';
import { SalesCustomerComponent } from './components/salesRole/sales-customer/sales-customer.component';
import { SalesVendorComponent } from './components/salesRole/sales-vendor/sales-vendor.component';
import { SalesInformationComponent } from './components/salesRole/sales-information/sales-information.component';
import { VerifyEmailComponent } from './components/registrationpages/verify-email/verify-email.component';
import { SharecalenderComponent } from './components/registrationpages/sharecalender/sharecalender.component';
import { CalendereventsComponent } from './components/registrationpages/calenderevents/calenderevents.component';
import { SkillsComponent } from './components/servicesbay-admin/skills/skills.component';
import { AccountdetailsComponent } from './components/servicesbayadmin-accountdetails/accountdetails/accountdetails.component';
import { ServicesbaycontactsComponent } from './components/servicesbayadmin-accountdetails/servicesbaycontacts/servicesbaycontacts.component';
import { ServicesbayaddcontactsComponent } from './components/servicesbayadmin-accountdetails/servicesbayaddcontacts/servicesbayaddcontacts.component';
import { ServicesbayeditcontactsComponent } from './components/servicesbayadmin-accountdetails/servicesbayeditcontacts/servicesbayeditcontacts.component';
import { ServicesbayadminmenuComponent } from './components/servicesbayadmin-accountdetails/servicesbayadminmenu/servicesbayadminmenu.component';
import { ServicesbaylocationsComponent } from './components/servicesbayadmin-accountdetails/servicesbaylocations/servicesbaylocations.component';
import { ServicesbayattachmentsComponent } from './components/servicesbayadmin-accountdetails/servicesbayattachments/servicesbayattachments.component';
// tslint:disable-next-line:max-line-length
import { ConsultantOpenPositionsComponent } from './components/consultantRole/consultant-open-positions/consultant-open-positions.component';
import { ConsultantOpenPositionEditComponent } from './components/consultantRole/consultant-open-position-edit/consultant-open-position-edit.component';
import { AssociationRequestComponent } from './components/recruiter/association-request/association-request.component';
import { AppliedpositionsComponent } from './components/consultantRole/appliedpositions/appliedpositions.component';
import { HttpInterceptorInterceptor } from './services/http-interceptor.interceptor';
import { AddcustomertobankComponent } from './components/vendor-admin/addcustomertobank/addcustomertobank.component';
import { AccountregistrationpageComponent } from './components/registrationpages/accountregistrationpage/accountregistrationpage.component';
import { GroupsComponent } from './components/customer-admin/groups/groups.component';
import { MembersComponent } from './components/customer-admin/members/members.component';
import { ProjectsComponent } from './components/projectManager/projects/projects.component';
import { TimeSheetsComponent } from './components/employee/time-sheets/time-sheets.component';
import { AddTimesheetComponent } from './components/employee/add-timesheet/add-timesheet.component';
import { ProjectListComponent } from './components/employee/project-list/project-list.component';
import { ResourceComponent } from './components/projectManager/resource/resource.component';
import { UserHistoryComponent } from './components/employee/user-history/user-history.component';
import { SelectCandidatesComponent } from './components/projectManager/select-candidates/select-candidates.component';
import { AccountsactivityComponent } from './components/servicesbay-admin/accountsactivity/accountsactivity.component';
import { VendorSubcontractorComponent } from './components/projectManager/vendor-subcontractor/vendor-subcontractor.component';
import { ResetPasswodComponent } from './components/servicesbay-admin/reset-passwod/reset-passwod.component';
import { DeleteAccountComponent } from './components/delete-account/delete-account.component';
import { AccountsBackupComponent } from './components/servicesbay-admin/accounts-backup/accounts-backup.component';
@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    GMapModule,
    CommonModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AccordionModule,
    AutoCompleteModule,
    AvatarModule,
    AvatarGroupModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    CascadeSelectModule,
    ChartModule,
    CheckboxModule,
    ChipModule,
    ChipsModule,
    CodeHighlighterModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    ColorPickerModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DividerModule,
    DropdownModule,
    FieldsetModule,
    FileUploadModule,
    FullCalendarModule,
    GalleriaModule,
    InplaceModule,
    InputNumberModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    KnobModule,
    LightboxModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    RippleModule,
    ScrollPanelModule,
    ScrollTopModule,
    SelectButtonModule,
    SidebarModule,
    SkeletonModule,
    SlideMenuModule,
    SliderModule,
    SplitButtonModule,
    SplitterModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    TagModule,
    TerminalModule,
    TimelineModule,
    TieredMenuModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeTableModule,
    VirtualScrollerModule,
    AppCodeModule,
    FormsModule,
  ],
  declarations: [
    AppComponent,
    AppMainComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppProfileComponent,
    AppTopBarComponent,
    AppFooterComponent,
    AppConfigComponent,
    AppBreadcrumbComponent,
    LogincComponent,
    AddRequirementsComponent,
    EditRequirementsComponent,
    TeamComponent,
    DepartmentsComponent,
    JobTitleComponent,
    JobCategoryComponent,
    WorkLocationComponent,
    ProjectRequirementsComponent,
    VendorAsstComponent,
    VendorSearchComponent,
    VendorAccountComponent,
    TiersComponent,
    VendorDefinationComponent,
    PurchasRequirementComponent,
    CopyRequirementComponent,
    SubmitedProfilesComponent,
    TaskComponent,
    CustomerComponent,
    ConsultantSearchComponent,
    EditConsultantSearchComponent,
    AddConsultantComponent,
    OpenPositionsComponent,
    OpenPositionsEditComponent,
    SubcontractorComponent,
    AvailablesubcontractorComponent,
    ProfileComponent,
    ResetuserpasswordComponent,
    CustomerregistrationComponent,
    ConsultantVendorregistrationComponent,
    ChangepasswordComponent,
    AvailableopenpositionsComponent,
    AddvendorrequirementComponent,
    ConsultantResumeComponentComponent,
    EmployeereviewsComponent,
    EmployeeInterviewComponent,
    EmployeeSkillsComponent,
    SubmissionrequestComponent,
    CertificationsComponent,
    AcademicComponent,
    RecruiterComponent,
    PreferencesComponent,
    LicenseComponent,
    UserpreferenceComponent,
    TechEvalutionComponent,
    AddTechreviewsComponent,
    NetworkComponent,
    AccountSearchComponent,
    CustomerAccountDetailsComponent,
    VendorTierDefinitionComponent,
    VendotTierComponent,
    VendorRequirementComponent,
    VendorRequirementEditComponent,
    ScheduleInterviewComponent,
    AddContactComponent,
    AdminMenuComponent,
    EditContactComponent,
    VendorDetailsComponent,
    VendorContactComponent,
    VendorAttachmentComponent,
    VendorLocationsComponent,
    AddCustomerComponent,
    TechReviewsComponent,
    CustomerAddContactsComponent,
    CustomerAttachmentsComponent,
    CustomerContactsComponent,
    CustomerLocationsComponent,
    TechEvalutionTechreviewsComponent,
    TechEvalutionAddTechreviewsComponent,
    CustomerEditContactsComponent,
    VendorAccountDetailsComponent,
    EmployerComponent,
    RecruitingManagerOpenpositionComponent,
    RecruitingManagerOpenpositionEditComponent,
    RecrutingManagerRequirementsComponent,
    RecrutingManagerRequirementsEditComponent,
    EmployeeRecommendationsComponent,
    PurchaRequirementeditComponent,
    RecuiterDashboardComponent,
    RequirementPreferenceComponent,
    EmployerCalenderComponent,
    EditSubcontractorComponent,
    RegErrorComponent,
    ProjectvendorComponent,
    SalesOpenPositionsComponent,
    SalesopenpositionEditComponent,
    AuthenticatePasswordComponent,
    SalesInfoComponent,
    RecSubmittedComponent,
    RecReviewsComponent,
    RecInterviewsComponent,
    RecSelectedComponent,
    VendorBankComponent,
    SalesCustomerComponent,
    SalesVendorComponent,
    SalesInformationComponent,
    VerifyEmailComponent,
    SharecalenderComponent,
    CalendereventsComponent,
    SkillsComponent,
    AccountdetailsComponent,
    ServicesbaycontactsComponent,
    ServicesbayaddcontactsComponent,
    ServicesbayeditcontactsComponent,
    ServicesbayadminmenuComponent,
    ServicesbaylocationsComponent,
    ServicesbayattachmentsComponent,
    ConsultantOpenPositionsComponent,
    ConsultantOpenPositionEditComponent,
    AssociationRequestComponent,
    AppliedpositionsComponent,
    AddcustomertobankComponent,
    AccountregistrationpageComponent,
    GroupsComponent,
    MembersComponent,
    ProjectsComponent,
    TimeSheetsComponent,
    AddTimesheetComponent,
    ProjectListComponent,
    ResourceComponent,
    UserHistoryComponent,
    SelectCandidatesComponent,
    AccountsactivityComponent,
    VendorSubcontractorComponent,
    ResetPasswodComponent,
    DeleteAccountComponent,
    AccountsBackupComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
     MenuService, BreadcrumbService, BnNgIdleService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
