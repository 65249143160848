<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
    <form [formGroup]="consultantOpenPositionForm">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Job Id</label>
                <input (keyup.enter)="consultantOpenPositionGrid()" autocomplete="off" id="input" type="text" pInputText
                    placeholder="Job Id" maxlength="11" formControlName="jobId">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Company</label>
                <p-dropdown (keyup.enter)="consultantOpenPositionGrid()" placeholder="Select Company" [showClear]="true"
                    formControlName="companyName" filter="true" [options]="accountnamesres" optionLabel="employerName">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Position Title</label>
                <input autocomplete="off" maxlength="31" (keyup.enter)="consultantOpenPositionGrid()" id="input"
                    type="text" pInputText placeholder="Position Title" formControlName="jobTitle">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Posted Date</label>
                <p-calendar (keyup.enter)="consultantOpenPositionGrid()" id="popup" dateFormat="mm-dd-yy"
                    formControlName="postedDate" placeholder="MM-DD-YYYY" [showIcon]="true" [showButtonBar]="true">
                </p-calendar>
            </div>
        </div><br>
        <div class="btnsCSS1">
            <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" class="addhBtn"></button>
            <button pButton type="submit" icon='pi pi-search' label="Search" (click)="consultantOpenPositionGrid()"
                class="addhBtn"></button>
        </div>
        <br><br>
    </form>
</p-card>
<br>
<p-card class="pgpos">
    <div *ngIf="table">
        <p-table [columns]="cols" [value]="griddata" selectionMode="single" dataKey="edit" [responsive]="true"
            id="excel-table" sortMode="multiple" [autoLayout]="true">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field" [hidden]="col.sno == 5">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
                <tr class="pointer">
                    <td class="textCenter" (click)="consultantopenpositionedit(data)">{{data.jobId}}</td>
                    <td class="textCenter" (click)="consultantopenpositionedit(data)" pTooltip="{{data.customerName}}"
                        tooltipPosition="top">{{data.customerName}} </td>
                    <td class="textCenter" (click)="consultantopenpositionedit(data)" pTooltip="{{data.jobTitle}}"
                        tooltipPosition="top">{{data.jobTitle}}</td>
                    <td class="textCenter skillDesc pointer">
                        <a (click)="op.toggle($event)" (click)="reqSkills1(getSkillSetTable(data.requiredSkills))"
                            *ngFor="let data1 of getSkillSetTable(data.requiredSkills); index as i;">
                            <p-badge class="badgespace newsty" *ngIf='i<1' [value]="data1"></p-badge>
                            <p-badge *ngIf='i===1' [value]="'+'+(data.requiredSkills.length-1)+' more'"></p-badge>
                        </a>
                    </td>
                    <td class="pointer" [hidden]="true">
                        <a (click)="op.toggle($event)" (click)="reqSkills1(getSkillSetTable(data.requiredSkills))"
                            *ngFor="let data1 of getSkillSetTable(data.requiredSkills); index as i;">
                            <p-badge [value]="data1+','"></p-badge>
                        </a>

                    </td>
                    <td class="textCenter" (click)="consultantopenpositionedit(data)">{{data.postedDate |
                        date:'MM-dd-yyyy' }}</td>
                    <td class="textCenter" (click)="consultantopenpositionedit(data)">
                        <p-badge [value]="data.status"
                            [ngClass]="data.status==='Applied'?'green': data.status === 'Open'?'blue':data.status === 'Closed'?'red':''">
                        </p-badge>
                    </td>
                    <td class="textCenter"><a>
                            <em (click)="actionpopup.toggle($event); toggle1(items, $event, data)"
                                class="pi pi-ellipsis-v"></em>
                        </a></td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid ui-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 totalrec">
                <h3 class="norecorddata ">Total records : {{totalRecords}}</h3>
                <p class="downpos">
                    <span class="ui-column-title pointer"
                        (click)="downloadActionPopup.toggle($event); downLoadsToggle()" (keyup)="exportExcel()">
                        <em class="pi pi-download downloadspac"></em>
                        <strong>Download</strong></span>
                </p>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)" #p></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any open positions</em><br>
            </div>
        </div>
    </p-card>
    <p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="downloadOptions"></p-menu>
</p-card>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '25%' }">
    <label class="inputfields">Skill Details</label>
    <ng-template pTemplate>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12 bordersty">
                <p class="skpos">
                    <p-badge class="badgespace badgesp" *ngFor="let data2 of reqSkillsValue; index as i" [value]="data2"
                        [ngClass]="i%2===0? 'newsty' : ''">
                    </p-badge>
                </p>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-dialog class="dialogBox" [(visible)]="displayModal" [draggable]="false" [modal]="true"
    [style]="{width: '30%',height:'60%'}">
    <p-header>Activity
        <span class="activitypos">Job Id : <a>{{this.jobId}}</a></span>
    </p-header>
    <p class="activitycolor">Please find the below information</p>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-sm-12">
            <p-timeline [value]="histroy" align="alternate">
                <ng-template pTemplate="content" let-event>
                    <p-card>
                        <p class="aligncenter"> {{event.activity}}
                            <br>
                            <span class="activitycolor">By {{event.activityBy}}</span>
                        </p>
                    </p-card>
                    <br>
                </ng-template>
                <ng-template pTemplate="opposite" let-event><span class="activitycolor">{{event.activityDate |
                        date:'MM-dd-yyyy'}}</span>
                </ng-template>
            </p-timeline>
        </div>
    </div>
</p-dialog>