import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { MessageService } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { isEmpty } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-open-positions-edit',
  templateUrl: './open-positions-edit.component.html',
  styleUrls: ['./open-positions-edit.component.scss'],
  providers: [MessageService, DatePipe],
})
export class OpenPositionsEditComponent implements OnInit {
  submittedProfileForm: FormGroup;
  editRequirementsForm: FormGroup;
  updateprofileForm: FormGroup;
  UserId: string;
  name1: any;
  reqId: any;
  titel: any;
  formRoute: any;
  gridStatus: any;
  reqData: any;
  reqPosi: any;
  reqName: any;
  jobCat: any;
  maxrate: any;
  headerName: string;
  rows: any = 10;
  page: any = 1;
  pageCount: any;
  minimumDate = new Date();
  submitApproval: boolean;
  dateErr: boolean;
  @ViewChild('p', { static: false }) paginator: Paginator;
  updateactioncols = [
    { field: 'name', header: 'Title' },
    { field: 'title', header: 'Name' },
    { field: '', header: 'Download' },
    { field: '', header: '' },
  ];
  cols3 = [
    { dataKey: 'consultantName', header: 'Candidate Name' },
    { dataKey: 'submittedDate', header: 'Submitted Date' },
    { dataKey: 'skills', header: 'Skills' },
    { dataKey: 'exper', header: 'Experience' },
    { dataKey: 'status', header: 'Status' },
    { dataKey: 'billRate', header: 'Bill Rate' },
  ];
  cols11 = [
    { field: 'consultantName', header: 'Candidate Name', sno: 1 },
    { field: 'submittedDate', header: 'Submitted Date', sno: 2 },
    { field: 'requiredSkills', header: 'Skills', sno: 3 },
    { field: 'requiredSkills', header: 'Skills', sno: 4 },
    { field: 'experience', header: 'Experience', sno: 5 },
    { field: 'profileState', header: 'Status', sno: 6 },
    { field: 'billRate', header: 'Bill Rate', sno: 7 },
    { field: '', header: 'Action', sno: 8 },
  ];
  payRatesTable = [
    { key: 'Pay Rate +  25%', value: 25 },
    { key: 'Pay Rate +  30%', value: 30 },
    { key: 'Pay Rate +  35%', value: 35 },
    { key: 'Pay Rate +  40%', value: 40 },
    { key: 'Pay Rate +  45%', value: 45 },
    { key: 'Pay Rate +  50%', value: 50 },
  ];
  submittedProfilesGridData: any;
  subProRecords: any;
  table: boolean;
  records: boolean;
  loader = false;
  array: any;
  PreferredSkills: any = [];
  experienceData: any;
  expLabel: any = [];
  statusList: any = [];
  statusdata = {};
  first: any;
  positionData: any;
  requiremntId: any;
  minMaxRate: any;
  departmentList: any = [];
  postCat: any;
  jobTitleId: any;
  locationList1: any = [];
  acctdetails: any;
  locationList: any;
  getexperienceOptions: any;
  statusDD: any;
  billingData: any;
  hireTypeData: any;
  preferredSkills: any = [];
  skillset = {};
  rowData: any;
  idToDelete: any;
  conId: any;
  consultantName: any;
  items: any = [];
  downloadOptions: any = [];
  jobTitleData: any;
  userAccId: any;
  exprienceList: any;
  billingList: any;
  hireTypeList: any;
  display: boolean;
  reqSkillsValue: any;
  filedata: any;
  resumeRes: any;
  updateaction: boolean;
  updateactionBtn: boolean;
  userid: any;
  resumID: any;
  updateactionGrid: any;
  profileID: any;
  reqId1: any;
  consultantRequirementDisplay: boolean;
  billdata: any;
  updateBilForm: any;
  candidateName: any;
  payRate: any;
  markUpData: any;
  errorMsg: boolean;
  payrates: any;
  alertError: boolean;
  approvalRequest: boolean;
  candidatename: any;
  userId: any;
  targetDate: string;
  maximumDate: Date;
  isUploading: boolean;
  loaderForDownload: boolean;
  rowdataid: any;
  iconToggle = {};
  selectedResume: any = [];
  display1: boolean;
  availCounsultRes: any;
  popup: boolean;
  reqCount: any;
  reqStatus: any;
  rejectSubmission: boolean;
  enddata: boolean;
  selectedPrimaryskills: any = [];
  tablerowID: any;
  selectedValue: boolean;
  selectedRowForResume: any;
  currencycode: any[];
  preferencList: any = [];
  currencyValue: any;
  currencysymbl: any;
  assignedDate: string;
  histroy: any;
  date: Date;
  displayModal = false;
  endDisplay: boolean;
  statusList1: any = [];
  disable: boolean;
  consultantNames: any;
  recCandidates: boolean;
  show: boolean;
  reqid: any;
  filedataa: any;
  rejectReasonData: any = [];
  candidaterejectpopup: boolean;
  lkRejectedType: any;
  profileId: any;
  parentprofileid: any;
  pprofileid: any;
  presumeid: any;
  minDate: Date;
  startCurDate: Date;
  candidateRejectFrom: FormGroup;
  otherskills: boolean;
  patchlocationString: string;
  editLocationdata: any;
  otherskilldata: boolean;
  rvId: any;
  isviewed: any;
  reqData1: any;
  createdByAccId: any;
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.search();
    }
  }
  // tslint:disable-next-line: max-line-length
  constructor(private datepipe: DatePipe, private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private apisService: ApisService, private breadcrumbService: BreadcrumbService, private messageService: MessageService) {
    this.reqData = JSON.parse(sessionStorage.getItem('reqData'));
    this.reqData.assignedToId === 0 ? this.show = false : this.show = true;
    this.reqPosi = this.reqData.noOfPositions;
    this.reqName = this.reqData.reqName;
    this.jobCat = this.reqData.jobCategory;
    this.maxrate = this.reqData.maxRate;
    this.breadcrumbService.setItems([
      { label: 'Open Positions', routerLink: '/open-positions' },
      { label: 'Open Position Edit', routerLink: '/open-position-edit' },
    ]);
  }
  ngOnInit(): void {
    this.minDate = new Date();

    this.minDate.setMonth(new Date().getMonth());
    this.minDate.setFullYear(new Date().getFullYear());
    this.startCurDate = this.minDate;
    this.acctdetails = JSON.parse(this.apisService.decodeData());
    this.preferencList = this.acctdetails.preferencesList;
    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }
    this.userAccId = this.acctdetails.accId;
    this.UserId = sessionStorage.getItem('UserId');
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.rvId = routeParams.params.rvId;
    this.isviewed = routeParams.params.viewed;
    if (Object.keys(routeParams.params).length !== 0) {
      this.name1 = routeParams.params.name;
      this.reqId = routeParams.params.reqId;
      this.titel = routeParams.params.title;
      this.formRoute = routeParams.params.from;
      this.gridStatus = routeParams.params.gStatus;
      this.createdByAccId = routeParams.params.createdByAccId;
    } else {
      (this.name1 = this.reqData.name),
        (this.reqId = this.reqData.reqId),
        (this.titel = this.reqData.jobTitle),
        (this.formRoute = this.reqData.from),
        (this.gridStatus = this.reqData.reqStatus);
      this.editReqData();
    }
    this.headerName = this.formRoute === 'open' ? 'Open Position' : 'Requirement';
    const nameLabel = this.formRoute === 'open' ? 'Open Positions' : 'Requirements';
    const routeUrl = this.formRoute === 'recruiter' ? '/recruiter-requirement' : '/requirements';
    this.updateBilForm = this.fb.group({
      billRateControl: ['', [Validators.required, Validators.min(1), Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
      requestDate: [''],
      rejComment: ['', Validators.required],
    });
    this.submittedProfileForm = this.fb.group({
      firstName: ['', [Validators.pattern('^[a-zA-Z]*$')]],
      lastName: ['', [Validators.pattern('^[a-zA-Z]*$')]],
      vendorName: ['', Validators.pattern('^[a-zA-Z]*$')],
      emailId: [''],
      skillSet: [''],
      experience: [''],
      rejectReason: ['']
    });
    this.updateprofileForm = this.fb.group({
      tableapproval: ['']
    });
    this.candidateRejectFrom = this.fb.group({
      candidaeRejectReason: ['']
    });
    this.targetDate = this.route.snapshot.queryParamMap.get('tdate');
    this.editRequirementsForm = this.fb.group({
      requirementSkillSet: ['', Validators.required],
      reqName: ['', Validators.required],
      requirementTitle: [''],
      positionCategory: [''],
      department: [''],
      requirementMinRate: [''],
      requirementMaxRate: [''],
      requirementNoofPositions: ['', Validators.required],
      requirementWorkCenterLocation: ['', Validators.required],
      requirementReqExp: ['', Validators.required],
      requirementType: ['', Validators.required],
      requirementStartDate: ['', Validators.required],
      requirementEndDate: ['', Validators.required],
      requirementStatus: ['', Validators.required],
      billingtype: ['', Validators.required],
      duration: [''],
      hrsperWeek: [''],
      requirementPreferredSkillSet: [''],
      requirementDescription: ['', Validators.required],
      requirementResponsibilities: ['', Validators.required],
      requirementQualification: ['', Validators.required],
      requirementComments: [''],
      approval: [''],
      otherskills: ['']
    });
    if (this.isviewed === 'N') {
      this.openposviewedornot();
    } else {

    }
    this.editRequirementsForm.disable();
    // this.submitProfileGrid();
    this.getExperienceDropDownData();
    this.getConStatus();
    this.getDepartments();
    this.getPositionCatagory();
    this.getWorkLocation();
    this.getexperience();
    this.getStatus();
    this.getBillingDropdown();
    this.getHireTypeData();
    this.getdSkillset();
    this.editReqData();
  }
  backNavigate() {
    this.router.navigate(['/open-positions']);
  }
  
  onTabOpen(data){
    var index = data.index;
    if(index===2) {
      this.submitProfileGrid();
    } else if(index === 1) {
      this.availableCandidates();
    }
     else {}
  }
  onTabClose(data){
  }
  availableCandidates(){
    this.apisService.availableRefresh.next(true);
  }
  submitProfileGrid() {
    this.loader = true;
    const row = this.rows;
    const page = this.page;
    // tslint:disable-next-line: max-line-length
    this.apisService.getApi(environment.avaliableSubmitedPro + page + '/' + row + '/' + this.reqId + '?consEmail=&fName=&lName=&exp=').subscribe((res: any) => {
      this.submittedProfilesGridData = res.data.records;
      this.subProRecords = res.data.count;
      this.submittedProfilesGridData.forEach(element => {
        this.consultantName = element.consultantName;
      });
      this.loader = false;
      if (this.subProRecords !== 0) {
        this.table = true;
        this.records = false;
        setTimeout(() => {
          this.records = false;
        }, 2000);
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  getExperienceDropDownData() {
    this.apisService.getApi(environment.lookupsData + 'RELYE').subscribe((res: any) => {
      this.experienceData = res.data;
      this.experienceData.forEach((element) => {
        this.expLabel[element.code] = [element.label];
      });
    });
  }
  getConStatus() {
    this.apisService.getApi(environment.lookupsData + 'RPLPS').subscribe((res) => {
      this.statusList1 = res.data;
      this.statusList1.forEach((element) => {
        this.statusdata[element.code] = element.label;
      });
    });
  }
  getDepartments() {
    // this.reqData.createdByAccId
    this.apisService.getApi(environment.departments + '/' + this.reqData.ownerId).subscribe((res) => {
      this.departmentList = res.data;
    });
  }
  getPositionCatagory() {
    // this.reqData.createdByAccId
    this.apisService.getApi(environment.positionCat + '/' + this.reqData.ownerId).subscribe((res) => {
      this.positionData = res.data;
    });
  }
  getWorkLocation() {
    // this.reqData.createdByAccId
    this.apisService.getApi(environment.location + '/' + this.reqData.ownerId).subscribe((res) => {
      this.locationList = res.data;
    });
  }
  getexperience() {
    this.apisService.getApi(environment.lookupsData + 'RELYE').subscribe((res: any) => {
      this.exprienceList = res.data;
    });
  }
  getStatus() {
    this.apisService.getApi(environment.lookupsData + 'RELS').subscribe((res) => {
      this.statusList = res.data;
    });
  }
  getBillingDropdown() {
    this.apisService.getApi(environment.lookupsData + 'RELBT').subscribe((res) => {
      this.billingList = res.data;
    });
  }
  getHireTypeData() {
    this.apisService.getApi(environment.lookupsData + 'RELHT').subscribe((res) => {
      this.hireTypeList = res.data;
    });
  }
  getdSkillset() {
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.PreferredSkills = res.data;
      this.PreferredSkills.forEach((element) => {
        this.skillset[element.code] = element.label;
      });
    });
  }
  editReqData() {
    this.apisService.getApi(environment.requirementEdit + '/' + this.reqId).subscribe((res) => {
      this.reqData1 = res.data;
      // console.log(" this.reqData1 ", this.reqData1 );
      this.getWorkLocation();
      this.getRolesBasedonCategory(this.reqData1.positionCategory);
      this.getPositionCategoryBindDataValues(this.reqData1);
      this.typeData(this.reqData1.hireType);
      this.editRequirementsForm.patchValue({
        reqName: this.reqData1.name,
        positionCategory: this.reqData1.positionCategory,
        requirementTitle: this.reqData1.jobTitle.id,
        department: this.reqData1.departmentId,
        requirementMinRate: this.reqData1.minRate,
        requirementMaxRate: this.reqData1.maxRate,
        requirementNoofPositions: this.reqData1.noOfPositions,
        requirementReqExp: this.reqData1.experience,
        requirementType: this.reqData1.hireType,
        requirementStartDate: this.reqData1.plannedStartDate,
        requirementEndDate: this.reqData1.plannedEndDate,
        billingtype: this.reqData1.billingType,
        duration: this.reqData1.duration,
        hrsperWeek: this.reqData1.hrsPerWeek,
        requirementStatus: this.statusList.filter(x => x.code === this.reqData1.status)[0],
        // requirementSkillSet: this.reqData1.prefferedSkills.toString(),
        // requirementPreferredSkillSet: this.reqData1.skills.toString(),
        requirementDescription: this.reqData1.description,
        requirementResponsibilities: this.reqData1.responsibilities,
        requirementQualification: this.reqData1.qualification,
        requirementComments: this.reqData1.comments,
        approval: this.reqData1.empApprovalReqFlag,
        otherskills: this.reqData1.otherSkills,

      });
      const selectedPrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.PreferredSkills.length; i++) {
        const array = this.reqData1.prefferedSkills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.PreferredSkills[i].code) === array[j]) {
            selectedPrimarySkills.push(this.PreferredSkills[i]);
          }
        }
      }
      this.editRequirementsForm.controls.requirementPreferredSkillSet.patchValue(selectedPrimarySkills);
      const preferredSkills = this.editRequirementsForm.controls.requirementPreferredSkillSet.value;
      preferredSkills.forEach((element) => {
        this.preferredSkills.push(element.label);
      });
      const selectedPrePrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.PreferredSkills.length; i++) {
        const array = this.reqData1.skills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.PreferredSkills[i].code) === array[j]) {
            selectedPrePrimarySkills.push(this.PreferredSkills[i]);
          }
        }
      }
      this.editRequirementsForm.controls.requirementSkillSet.patchValue(selectedPrePrimarySkills);
      selectedPrePrimarySkills.forEach((element) => {
        this.selectedPrimaryskills.push(element.label);
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < selectedPrePrimarySkills.length; i++) {
          if (selectedPrePrimarySkills[i].code === '25') {
            this.otherskilldata = true;
            this.editRequirementsForm.controls.otherskills.patchValue(this.reqData1.otherSkills);

          }
        }

      });
      this.editRequirementsForm.controls.requirementWorkCenterLocation.patchValue(
        this.locationList.filter(
          (x) => x.locId === this.reqData1.location
        )[0]
      );
      this.editLocationdata = this.locationList.filter(
        (x) => x.locId === this.reqData1.location
      )[0];
      // tslint:disable-next-line: max-line-length
      this.patchlocationString = this.editLocationdata.name + '\n' + this.editLocationdata.address1 + ',' + this.editLocationdata.city + ',' + this.editLocationdata.state + ',' +
       this.editLocationdata.country;
    });

    // this.apisService.getApi(environment.location + '/' + this.reqData.createdByAccId).subscribe((res1) => {
    //   this.locationList1 = res1.data;
    //   this.editRequirementsForm.controls.requirementWorkCenterLocation.patchValue(
    //     this.locationList1.filter(
    //       (x) => x.locId === this.reqData1.location
    //     )[0]
    //   );
    //   this.editLocationdata = this.locationList1.filter(
    //     (x) => x.locId === this.reqData1.location
    //   )[0]
    // tslint:disable-next-line: max-line-length
    //   this.patchlocationString = this.editLocationdata.name + '\n' + this.editLocationdata.address1 + ',' + this.editLocationdata.city + ',' + this.editLocationdata.state + ',' + this.editLocationdata.country
    // });
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.submitProfileGrid();
  }
  search() {
    // tslint:disable-next-line:max-line-length
    const fName = this.submittedProfileForm.controls.firstName.value === null || undefined || '' ? '' : this.submittedProfileForm.controls.firstName.value;
    // tslint:disable-next-line:max-line-length
    const lName = this.submittedProfileForm.controls.lastName.value === null || undefined || '' ? '' : this.submittedProfileForm.controls.lastName.value;
    // tslint:disable-next-line:max-line-length
    const email = this.submittedProfileForm.controls.emailId.value === null || undefined || '' ? '' : this.submittedProfileForm.controls.emailId.value;
    // tslint:disable-next-line:max-line-length
    const exp = this.submittedProfileForm.controls.experience.value === null || this.submittedProfileForm.controls.experience.value === undefined || this.submittedProfileForm.controls.experience.value === '' ? '' : this.submittedProfileForm.controls.experience.value.code;
    this.loader = true;
    const pgFlag = this.page;
    const pgNo = this.rows;
    const data = pgFlag + '/' + pgNo + '/' + this.reqId + '?consEmail=' + email + '&fName=' + fName + '&lName=' + lName + '&exp=' + exp;
    this.apisService.getApi(environment.avaliableSubmitedPro + data).subscribe((res) => {
      this.submittedProfilesGridData = res.data.records;
      this.subProRecords = res.data.count;
      if (this.subProRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.loader = false;
    });
  }
  getSkillSetTable(data) {
    this.array = data;
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.PreferredSkills.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      if (data === null || data === undefined || data === '' || data === isEmpty()) { }
      else {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.array.length; j++) {
          if (Number(this.PreferredSkills[i].code) === this.array[j]) {
            selectedPrePrimarySkills3.push(
              this.PreferredSkills[i].label
            );
          } else {
          }
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  getRolesBasedonCategory(event) {
    this.apisService.getApi(environment.jobTitle + this.userAccId + '/' + event).subscribe((res) => {
      this.jobTitleData = res.data;
    });
  }
  emailToast() {
    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
  }
  // It is used to copy an email from grid data to clipboard
  copyInputMessage(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
  getPositionCategoryBindDataValues(data) {
    // const acctId = data.accId;
    const posnCat = data.category;
    this.requiremntId = data.reqId;
    this.apisService.getApi(environment.maxRate + this.reqData.ownerId + '/' + posnCat).subscribe((res) => {
      this.minMaxRate = res.data;
      // console.log("  this.minMaxRate",  this.minMaxRate);
      this.editRequirementsForm.controls.requirementTitle.patchValue(
        this.minMaxRate.filter(x => x.title === data.jobTitle.title)[0]
      );
    });
  }
  typeData(event) {
    if (event === 'C' || event === 'H') {
      this.endDisplay = true;
      this.editRequirementsForm.get('requirementEndDate').enable();
    } else {
      this.endDisplay = false;
      this.editRequirementsForm.get('requirementEndDate').disable();
    }
  }
  getMinrate(event) {
    const availbleRate = this.jobTitleData.find(x => x.id === event);
    this.editRequirementsForm.controls.requirementMinRate.patchValue(availbleRate.minrate);
    this.editRequirementsForm.controls.requirementMaxRate.patchValue(availbleRate.maxrate);
  }
  toggle(menu, event, rowData) {
    // console.log("rowData",rowData);
    this.rowData = rowData;
    this.profileId = rowData.profileId;
    this.idToDelete = rowData.profileId;
    this.conId = rowData.conId;
    this.userId = rowData.userId;
    this.consultantName = rowData.consultantName;
    this.lkRejectedType = rowData.lkRejectedType;
    this.tablerowID = rowData.resumeId;
    this.parentprofileid = rowData.parentReqProfileId;
    if (this.rowData.profileState === 'A' && this.rowData.empApprovalReqFlag === 'N') {
      this.items = [
        // tslint:disable-next-line:no-shadowed-variable
        { label: 'Remove', icon: 'pi pi-fw pi-trash', command: (event, i) => { this.deleteRow(); } },
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); } },
        { label: 'Update Profile', icon: 'pi pi-fw pi-refresh', command: () => { this.updateAction(); }, },
        { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); }, },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
        { label: 'Candidate Details', icon: 'pi pi-fw pi-user', command: () => { this.editSearchconsultant(); }, },
        { label: 'Update Bill Rate', icon: 'pi pi-fw pi-money-bill', command: () => { this.updateBillrate(); }, },
        { label: 'Submit Candidate', icon: 'pi pi-fw pi-check-circle', command: () => { this.submitCust(); }, },
        { label: 'Profile Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); } },
      ];
    }
    else if (this.rowData.profileState === 'A' && this.rowData.empApprovalReqFlag === 'Y') {
      this.items = [
        // tslint:disable-next-line:no-shadowed-variable
        { label: 'Remove', icon: 'pi pi-fw pi-trash', command: (event, i) => { this.deleteRow(); }, },
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        { label: 'Update Profile', icon: 'pi pi-fw pi-refresh', command: () => { this.updateAction(); }, },
        { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); } },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
        { label: 'Candidate Details', icon: 'pi pi-fw pi-user', command: () => { this.editSearchconsultant(); }, },
        { label: 'Update Bill Rate', icon: 'pi pi-fw pi-money-bill', command: () => { this.updateBillrate(); } },
        { label: 'Submit Candidate', icon: 'pi pi-fw pi-check-circle', command: () => { this.submitCust(); } },
        { label: 'Request Approval', icon: 'pi pi-fw pi-external-link', command: () => { this.requestApproval(); } },
        { label: 'Profile Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); } },
      ];
    }
    else if (this.rowData.profileState === 'C') {
      this.items = [
        // tslint:disable-next-line:no-shadowed-variable
        { label: 'Proceed', icon: 'pi pi-arrow-circle-right', command: (event, i) => { } },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); } },
        { label: 'Profile Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); } },
      ];
    }
    // tslint:disable-next-line:max-line-length
    else if (this.rowData.profileState === 'T' && this.rowData.empApprovalReqFlag === 'N' || this.rowData.profileState === 'L' && this.rowData.empApprovalReqFlag === 'N' || this.rowData.profileState === 'F' && this.rowData.empApprovalReqFlag === 'N') {
      this.items = [
        // tslint:disable-next-line:no-shadowed-variable
        { label: 'Remove', icon: 'pi pi-fw pi-trash', command: (event, i) => { this.deleteRow(); }, },
        { label: 'Reject', icon: 'pi pi-fw pi-times-circle' },
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); } },
        { label: 'Update Profile', icon: 'pi pi-fw pi-refresh', command: () => { this.updateAction(); } },
        { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); } },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); } },
        { label: 'Candidate Details', icon: 'pi pi-fw pi-user', command: () => { this.editSearchconsultant(); }, },
        { label: 'Update Bill Rate', icon: 'pi pi-fw pi-money-bill', command: () => { this.updateBillrate(); }, },
        { label: 'Submit Candidate', icon: 'pi pi-fw pi-check-circle', command: () => { this.submitCust(); }, },
        { label: 'Profile Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); } },
      ];
    }
    // tslint:disable-next-line:max-line-length
    else if (this.rowData.profileState === 'T' && this.rowData.empApprovalReqFlag === 'Y' || this.rowData.profileState === 'F' && this.rowData.empApprovalReqFlag === 'Y') {
      this.items = [
        // tslint:disable-next-line:no-shadowed-variable
        { label: 'Remove', icon: 'pi pi-fw pi-trash', command: (event, i) => { this.deleteRow(); } },
        { label: 'Reject', icon: 'pi pi-fw pi-times-circle' },
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); } },
        { label: 'Update Profile', icon: 'pi pi-fw pi-refresh', command: () => { this.updateAction(); } },
        { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); } },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); } },
        { label: 'Candidate Details', icon: 'pi pi-fw pi-user', command: () => { this.editSearchconsultant(); } },
        { label: 'Update Bill Rate', icon: 'pi pi-fw pi-money-bill', command: () => { this.updateBillrate(); }, },
        { label: 'Submit Candidate', icon: 'pi pi-fw pi-check-circle', command: () => { this.submitCust(); }, },
        { label: 'Request Approval', icon: 'pi pi-fw pi-external-link', command: () => { this.requestApproval(); }, },
        { label: 'Profile Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); }, },
      ];
    }
    else if (this.rowData.profileState === 'S' || this.rowData.profileState === 'I') {
      this.items = [
        { label: 'Reject', icon: 'pi pi-fw pi-times-circle' },
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); } },
        { label: 'Schedule Interview ', icon: 'pi pi-fw pi-users', command: () => { this.addReq(); } },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
        { label: 'Profile Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); }, },
      ];
    }
    else if (this.rowData.profileState === 'U') {
      this.items = [
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
        { label: 'Profile Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); }, },
      ];
    }
    else if (this.rowData.profileState === 'R') {
      this.items = [
        { label: 'Reject Reason', icon: 'pi pi-fw pi-times-circle', command: () => { this.rejectReson(); }, },
        { label: 'Show Reviews', icon: 'pi pi-fw pi-star-o', command: () => { this.ratings(); }, },
        { label: 'Profile Activity', icon: 'pi pi-fw pi-clock', command: () => { this.profilehistroy(); }, },
      ];
    }
    else {
      this.items = [];
    }
  }
  downLoadsToggle() {
    this.downloadOptions = [
      { label: 'Download XLSX', icon: 'pi pi-download', command: (event, i) => { this.exportExcel(); }, },
      { label: 'Download PDF', icon: 'pi pi-download', command: (event, i) => { this.exportPdf(); } },
    ];
  }
  reqSkills(data) {
    this.display = true;
    this.reqSkillsValue = data;
  }
  reset($event) {
    this.loader = true;
    this.submittedProfileForm.reset();
    this.loader = false;
    this.submitProfileGrid();

  }
  deleteRow() {
    Swal.fire({
      text: 'Are you sure want to remove ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0D416B',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        if (this.parentprofileid != null && this.parentprofileid !== 0) {
          // tslint:disable-next-line:max-line-length
          this.apisService.deleteApi(environment.removecandidate + '/' + this.idToDelete + '/' + this.parentprofileid).subscribe((res: any) => {
            this.submitProfileGrid();
            // tslint:disable-next-line: max-line-length
            Swal.fire({ text: res.message, icon: 'success', confirmButtonColor: '#0D416B', iconColor: '#1dbb99', confirmButtonText: 'Ok' });
          });
        } else {
          this.apisService.deleteApi(environment.removeRequirement + this.idToDelete).subscribe((res: any) => {
            this.submitProfileGrid();
            // tslint:disable-next-line: max-line-length
            Swal.fire({ text: res.message, icon: 'success', confirmButtonColor: '#0D416B', iconColor: '#1dbb99', confirmButtonText: 'Ok' });
          });
        }
      }
    });
  }
  downloadFileapi() {
    this.filedata = this.rowData;
    this.reqid = this.filedata.resumeId;
    this.isUploading = true;
    this.apisService.getApi(environment.downloadedattachment + '/' + this.reqid).subscribe((res) => {
      this.resumeRes = res;
      setTimeout(() => {
        this.isUploading = false;
      }, 2000);
      window.location.assign(this.resumeRes.downloadUrl);
    });
  }
  updateAction() {
    this.updateprofileForm.reset();
    const row = this.rows;
    const page = this.page;
    this.updateaction = true;
    this.updateactionBtn = false;
    this.filedata = this.rowData;
    this.pprofileid = this.filedata.profileId;
    this.presumeid = this.filedata.resumeId;
    this.userid = this.rowData.userId;
    this.resumID = this.rowData.resumeId;
    this.apisService.getApi(environment.requireupdateaction + '/' + page + '/' + row + `/${this.userid}`).subscribe((res: any) => {
      this.updateactionGrid = res.data.records;
      res.data.forEach((ele) => {
        if (ele.id === this.resumID) {
          this.selectedRowForResume = ele;
          this.updateactionBtn = true;
        }
      });
    });
    const radioid = this.resumID === this.tablerowID;
    this.selectedValue = radioid;
  }
  selectedResumeCheck(data) {
    if (data.attachmentPath === this.rowData.attachmentPath) {
      this.selectedResume = [];
      this.selectedResume.push(data);
    }
    else {
      return;
    }
  }
  updatedownload(data) {
    this.filedataa = data.id;
    this.loaderForDownload = true;
    this.apisService.getApi(environment.downloadedattachment + '/' + this.filedataa).subscribe((res) => {
      this.resumeRes = res;
      window.location.assign(this.resumeRes.downloadUrl);
      this.loaderForDownload = false;
    });
  }
  onSelect(event) {
    // Radio button select event
    this.updateactionBtn = true;
    this.filedata = this.rowData;
    this.profileID = this.filedata.profileId;
    this.rowdataid = event.data.id;
  }
  submitCust() {
    this.filedata = this.rowData;
    this.reqCount = this.rowData.requestCount;
    this.reqStatus = this.rowData.submissionRequestStatus;
    this.userId = this.filedata.userId;
    if (this.filedata.empApprovalReqFlag === 'N' || this.reqCount !== 0 && this.reqStatus === 'A') {
      const subID = this.userAccId + '/' + this.reqData1.accId + '/' + this.reqData.reqId + '/' + this.userId + '/' + this.profileId;
      Swal.fire({
        // tslint:disable-next-line:max-line-length
        text: 'Are you sure want to submit candidate to customer?', icon: 'warning', showCancelButton: true, confirmButtonColor: '#0D416B', cancelButtonColor: '#D0D0D0', confirmButtonText: 'Yes', cancelButtonText: 'No', reverseButtons: false,
      }).then((result) => {
        if (result.value) {
          this.apisService.getApi(environment.requirementSubmit + subID).subscribe((res: any) => {
            if (res.success === true) {
              // tslint:disable-next-line: max-line-length
              Swal.fire({ text: 'Candidate submitted to customer', icon: 'success', confirmButtonColor: '#0D416B', iconColor: '#1dbb99', confirmButtonText: 'Ok', reverseButtons: false });
              this.submitProfileGrid();
            }
            else if (res.hasOwnProperty('success') === false) {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
            } else {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
            }
          });
        }
      });
    }
    else if (this.reqCount !== 0 && this.filedata.empApprovalReqFlag === 'Y') {
      Swal.fire({
        text: 'Candidate approval pending for submission',
        icon: 'warning', confirmButtonColor: '#0D416B',
        confirmButtonText: 'Ok'
      });
    }
    else if (this.reqCount === 0 && this.reqStatus === '') {
      Swal.fire({
        text: 'Candidate approval request pending',
        icon: 'warning',
        confirmButtonColor: '#0D416B',
        confirmButtonText: 'Ok'
      });
    }
    else if (this.reqCount !== 0 && this.reqStatus === 'R') {
      this.rejectSubmission = true;
      this.updateBilForm.get('rejComment').patchValue(this.rowData.comments);

    }

  }


  saveRequest() {
    this.billdata = this.rowData;
    const dateValue = this.updateBilForm.controls.requestDate.value;
    if (dateValue === '' || dateValue === null || dateValue === undefined) {
      this.dateErr = true;
      setTimeout(() => {
        this.dateErr = false;
      }, 2000);
    } else {
      const data = {
        objectId: this.billdata.profileId,
        assignedToId: this.billdata.userId,
        targetDate:
          this.datepipe.transform(dateValue, 'yyyy-MM-dd') + ' 00:00:00',
      };
      this.submitApproval = true;
      this.loader = true;
      this.apisService
        .postApi(environment.requestApproval, data)
        .subscribe((res: any) => {
          this.submitApproval = false;
          this.approvalRequest = false;
          this.loader = false;
          if (res.success === true) {
            Swal.fire({
              text: res.message,
              icon: 'success',
              confirmButtonColor: '#0D416B',
              iconColor: '#1dbb99',
              confirmButtonText: 'Ok',
              reverseButtons: false,
            });
            this.submitProfileGrid();
          } else {
            Swal.fire({
              text: res.message,
              icon: 'warning',
              confirmButtonColor: '#0D416B',
              confirmButtonText: 'Ok',
              reverseButtons: false,
            });
          }
        });
    }
    this.submitProfileGrid();
  }


  onUnselect(event) {
    // Radio button unselect event
    this.updateactionBtn = false;
    const val1 = event.data.id;
  }

  submitAction() {
    // Update button method in update profile
    const data = {
      id: this.profileID === undefined || this.profileID === null || this.profileID === '' ? this.pprofileid : this.profileID,
      resumeId: this.rowdataid === undefined || this.rowdataid === null || this.rowdataid === '' ? this.presumeid : this.rowdataid
    };
    this.disable = true;
    this.apisService
      .putApi(environment.updateaction, data)
      .subscribe((res: any) => {
        if (res.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
          this.submitProfileGrid();
          this.disable = false;
          this.updateaction = false;
        } else if (res.hasOwnProperty('success') === false) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
          this.disable = false;
          this.updateaction = false;
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
          this.disable = false;
          this.updateaction = false;
        }
      });
  }

  addReq() {
    // console.log("this.rowData.createdByAccId",this.rowData.createdByAccId);
    this.filedata = this.rowData;
    this.profileID = this.filedata.profileId;
    const queryParams = {
      proId: this.profileID,
      reqId: this.requiremntId,
      consultantName: this.consultantName,
      jobTitle: this.reqName,
      conId: this.userId,
      createdbyaccID: this.rowData.createdByAccId,
    };
    this.router.navigate(['/schedule-interview'], {
      queryParams: {
        proId: this.profileID,
        reqId: this.requiremntId,
        consultantName: this.consultantName,
        jobTitle: this.reqName,
        conId: this.userId,
        from: 'Oedit',
        createdbyaccID: this.rowData.createdByAccId
      },
    });
    sessionStorage.setItem('openpositiondata', JSON.stringify(queryParams));
  }
  ratings() {
    this.filedata = this.rowData;
    this.profileID = this.filedata.profileId;
    this.reqId1 = this.filedata.reqId;
    this.router.navigate(['/tech-reviews'], {
      queryParams: {
        proId: this.profileID,
        reqId: this.reqId1,
        conId: this.filedata.conId,
        reqname: this.reqName,
        vendorId: this.filedata.vendorId,
        userId: this.userId,
        type: 'open'
      },
    });
  }

  editSearchconsultant() {
    this.router.navigate(['/editcandidate', this.userId], {
      queryParams: {
        type: 'open'
      },
    }
    );
  }
  updateBillrate() {
    this.consultantRequirementDisplay = true;
    this.billdata = this.rowData;
    const createdAccId = JSON.parse(
      sessionStorage.getItem('reqData')
    ).createdByAccId;
    const userData = JSON.parse(this.apisService.decodeData()).accId;

    this.updateBilForm.controls.billRateControl.reset();
    this.consultantRequirementDisplay = true;
    this.candidateName = this.billdata.consultantName;
    this.payRate = this.billdata.payRate;
    this.apisService.getApi(environment.vendorMarkUp + createdAccId + '/' + userData).subscribe((x) => {
      this.markUpData = x.data[0].markupPercentage;
      this.updateBilForm.controls.billRateControl.patchValue(this.billdata.billRate);
      if (this.updateBilForm.controls.billRateControl.value > this.maxrate) {
        this.errorMsg = true;
        setTimeout(() => {
          this.errorMsg = false;
          this.updateBilForm.controls.billRateControl.reset();
        }, 3000);
      }
    });
    const data = '?billRate=' + this.billdata.billRate + '&profileId=' + this.billdata.userId;
  }
  billRate() {
    this.alertError = false;
    if (this.updateBilForm.controls.billRateControl.value > this.maxrate) {
      this.updateBilForm.controls.billRateControl.reset();
      this.errorMsg = true;
      setTimeout(() => {
        this.errorMsg = false;
      }, 3000);
    } else if (
      this.updateBilForm.controls.billRateControl.value === '' ||
      this.updateBilForm.controls.billRateControl.value === null ||
      this.updateBilForm.controls.billRateControl.value === undefined
    ) {
      this.alertError = true;
      setTimeout(() => {
        this.alertError = false;
      }, 3000);
    } else {
      this.errorMsg = false;
    }
  }

  // It is used for the adding the Security Deposit Details
  submitConsultant() {
    if (this.updateBilForm.controls.billRateControl.value > this.maxrate) {
      this.updateBilForm.controls.billRateControl.reset();
      this.errorMsg = true;
      setTimeout(() => {
        this.errorMsg = false;
      }, 3000);
    } else if (this.updateBilForm.controls.billRateControl.invalid) {
      const controls = this.updateBilForm.controls.billRateControl.value;
      const controls1 = this.updateBilForm.controls.billRateControl;

      // tslint:disable-next-line:radix
      if (parseInt(controls) <= 0) {
        this.alertError = true;
        this.updateBilForm.controls.billRateControl.reset();
        setTimeout(() => {
          this.alertError = false;
        }, 3000);
      }
      else {
        controls1.markAsTouched();
      }
    } else {
      this.alertError = false;
      this.disable = true;
      const data = '?billRate=' + this.updateBilForm.controls.billRateControl.value + '&profileId=' + this.billdata.profileId;
      this.loader = true;
      this.apisService.getApi(environment.updateBillRate + data).subscribe((res: any) => {
        this.display1 = false;
        this.loader = false;
        this.availCounsultRes = res;
        this.disable = false;
        if (this.availCounsultRes.hasOwnProperty('success') === true) {
          Swal.fire({
            text: this.availCounsultRes.message,
            icon: 'success',
            confirmButtonColor: '#0D416B',
            iconColor: '#1dbb99',
            confirmButtonText: 'Ok',
            reverseButtons: false,
          });
          this.popup = false;
          this.consultantRequirementDisplay = false;
          this.submitProfileGrid();
          this.disable = false;
        } else if (
          this.availCounsultRes.hasOwnProperty('failed') === true
        ) {
          Swal.fire({
            text: this.availCounsultRes.failed,
            icon: 'warning',
            confirmButtonColor: '#0D416B',
            confirmButtonText: 'Ok',
            reverseButtons: false,
          });
        } else {
          Swal.fire({
            text: this.availCounsultRes.error,
            icon: 'error',
            confirmButtonColor: '#0D416B',
            confirmButtonText: 'Ok',
            reverseButtons: false,
          });
        }
      });
    }
  }


  payrate(data) {
    this.payrates = data;
    if (this.payrates <= this.maxrate) {
      this.updateBilForm.controls.billRateControl.patchValue(this.payrates);
      this.alertError = false;
    } else if (this.payrates >= this.maxrate) {
      this.updateBilForm.controls.billRateControl.patchValue(this.payrates);
      this.alertError = true;
      setTimeout(() => {
        this.alertError = false;
        this.updateBilForm.controls.billRateControl.reset();
      }, 3000);
    }
  }

  requestApproval() {
    this.approvalRequest = false;
    this.updateBilForm.controls.requestDate.reset();
    this.reqCount = this.rowData.requestCount;
    this.reqStatus = this.rowData.submissionRequestStatus;
    if (this.reqCount === 0) {
      this.approvalRequest = true;
      this.updateBilForm.controls.requestDate.reset();
      this.filedata = this.rowData;
      this.candidatename = this.filedata.consultantName;
      this.userId = this.filedata.userId;
      const dateVal = new Date(this.targetDate);
      this.maximumDate = dateVal;
    }
    else {
      Swal.fire({
        text: 'Request already sent',
        icon: 'warning',
        confirmButtonColor: '#0D416B',
        confirmButtonText: 'Ok'
      });
    }
  }
  add() {
    // this.router.navigate(['/availablecandidates']);
    this.router.navigate(['/addcandidate'], {
      queryParams: {
        flag: 'O'
      }
    });
  }
  profilehistroy() {
    this.displayModal = true;
    this.date = new Date();
    const actId = JSON.parse(this.apisService.decodeData());
    this.apisService.getApi(environment.getProfileActivity + this.rowData.profileId).subscribe((res: any) => {
      this.histroy = res.data;
      if (this.subProRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.loader = false;

    });

  }
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }

  exportExcel() {
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    ws['!cols'] = [ {width : 10}, {width : 20}  , {width : 20} , {width : 10}, {width : 10}, {width : 10}, {width : 10}, {hidden : true} ];
    ws['!rows'] = [{hpt : 30}, {hpt : 30}, ];
    ws['!cols'][2] = { hidden: true };
    ws['!cols'][7] = { hidden: true };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ExcelSheet.xlsx');
  }
  exportPdf() {
    this.submittedProfilesGridData.forEach((element) => {
      element.status = this.statusdata[element.profileState];
      element.skills = this.getSkillSetTable(element.requiredSkills);
      element.exper = this.expLabel[element.experience];
    });
    const doc = new jsPDF('p', 'pt');
    autoTable(doc, {
      headStyles: { fillColor: '#2bb9d5', cellPadding: 5   },
      margin: { left: 17},
      columns: this.cols3,
      body: this.submittedProfilesGridData,
      didDrawPage: (dataArg) => {
        doc.text('Submitted Candidates', dataArg.settings.margin.left, 30);
      }
    });
    doc.save('Submitted-Candidates.pdf');
  }
  rejectReson() {
    this.candidaterejectpopup = true;
    this.apisService.getApi(environment.lookupsData + 'RPLR').subscribe((res) => {
      this.rejectReasonData = res.data;
      this.candidateRejectFrom.controls.candidaeRejectReason.patchValue(this.rejectReasonData.filter(
        (x) => x.code === this.lkRejectedType)[0]);
    });
  }
  skillsetOthers(data, event) {
    if (event.length === 0) {
      this.selectedPrimaryskills = [];
    }

    this.editRequirementsForm.controls.otherskills.reset();
    this.editRequirementsForm.controls.otherskills.clearValidators();
    // this.otherskilldata = false;
    // if (data)
    // {
    //   this.otherskilldata = false;
    // }
    if (data[0] === '25') {
      this.editRequirementsForm.controls.otherskills.setValidators([Validators.required, Validators.maxLength(300)]);
      this.otherskills = true;
    }
    else {
      this.editRequirementsForm.controls.otherskills.clearValidators();
      this.otherskills = false;
    }
  }
  saveLocation(ev) {
    const data = ev.value;
    this.patchlocationString = data.name + '\n' + data.address1 + ',' + data.city + ',' + data.state + ',' + data.country;
  }
  openposviewedornot() {
    const reqbody = {
      id: this.rvId
    };
    this.apisService.putApi(environment.openposviewedornot, reqbody).subscribe((res: any) => {
    });
  }
  setRequired() {
    return [Validators.required, Validators.maxLength(300)];
  }
  onBlur(data): void {
    if (data === '') {
      this.editRequirementsForm.controls.otherskills.setValidators(this.setRequired());
    }
  }
  resetskills(data, event) {
    if (event.length === 0) {
      this.preferredSkills = [];
    }

  }
}
