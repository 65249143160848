<p-toast position="bottom-right" key="br"></p-toast>
<form [formGroup]="AddreqTechReviews">
    <p-card>
        <p-panel> <p-header>
            <label>
                <strong>Tech Review Details</strong><strong class="positionheader">Job Id
                    : </strong><strong>
                    <span class="panelLabel"> {{this.reqId}}</span></strong>
                <strong class="positionheader">Position Title : </strong>
                <strong><span class="panelLabel">{{jobTitle}}</span></strong><strong
                    class="positionheader">Candidate
                    Name : </strong>
                <strong><span class="panelLabel">{{consultantName}}</span></strong>
            </label>
            <em class="pi pi-arrow-left panelBackRoute" (click)="backToTechReview()"></em>
        </p-header> 
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-3 p-sm-12 invalidMandatory">
                        <label class="inputfields">Interview Mode<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="interviewMode" placeholder="Select Interview Mode"
                            (onChange)="interviewCheck($event.value)" optionLabel="label" [showClear]="true"
                            formControlName="interviewModeControl"></p-dropdown>
                        <span class="colorRed"
                            *ngIf="AddreqTechReviews.get('interviewModeControl').touched && !AddreqTechReviews.get('interviewModeControl').valid">
                            Please select interview mode
                        </span>
                    </div>
                    <div id="autoCompleteReviewId" class="p-col-12 p-md-3 p-sm-12 invalidMandatory">
                        <label class="inputfields">Reviewer<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="reviewData" placeholder="Select Reviewer" optionLabel="name"
                            [showClear]="true" formControlName="reviewer"></p-dropdown>
                        <div *ngIf="AddreqTechReviews.controls.reviewer.errors?.required && AddreqTechReviews.controls.reviewer.touched && AddreqTechReviews.controls.reviewer.invalid"
                            class="colorRed">Please select reviewer</div>
                    </div>
                    <div *ngIf="!skillsFlag" class="p-col-12 p-md-3 p-sm-12 invalidMandatory">
                        <label class="inputfields">Interview Date<span class="mandatoryColor">*</span></label>
                        <p-calendar placeholder="MM-DD-YYYY" formControlName="interviewDate" [stepMinute]="15"
                            placeholder="MM-DD-YYYY HH:MM" [showTime]="true" inputId="time" [showButtonBar]="true"
                            [minDate]="startCurDate" [maxDate]="maxDate"></p-calendar>
                        <div *ngIf="AddreqTechReviews.controls.interviewDate.touched && AddreqTechReviews.get('interviewDate').errors?.required &&AddreqTechReviews.controls.interviewDate.invalid"
                            class="colorRed">Please select interview date</div>
                    </div>
                    <div *ngIf="!skillsFlag" class="p-col-12 p-md-3 p-sm-12">
                        <label class="inputfields">Time Zone<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="zone" formControlName="zone" placeholder="Select Time Zone"
                            optionLabel="label" autocomplete="off" [showClear]="true"></p-dropdown>
                            <span class="colorRed"
                            *ngIf="AddreqTechReviews.get('zone').touched && !AddreqTechReviews.get('zone').valid">
                            Please select time zone
                        </span>
                    </div>
                    <div *ngIf="skillsFlag" class="p-col-12 p-md-3 p-sm-12">
                        <label class="inputfields">Severity</label>
                        <p-dropdown [options]="severity" formControlName="severity" optionLabel="label"
                            placeholder="Select Severity" [showClear]="true" (onChange)="severityChange()">
                        </p-dropdown>
                    </div>
                    <div *ngIf="skillsFlag" id="reviewHoursId" class="p-col-12 p-md-3 p-sm-12 invalidMandatory">
                        <div class="p-grid p-fluid">
                            <div class="p-col-6 p-md-6 p-sm-12">
                                <label class="inputfields">HH<span class="mandatoryColor">*</span></label>
                                <p-dropdown [options]="HH" formControlName="HH" optionLabel="label" [showClear]="true"
                                    [virtualScroll]="true" placeholder="Select HH"></p-dropdown>
                                <div *ngIf="interviewModeForm && submitted && AddreqTechReviews.controls.HH.errors">
                                    <div *ngIf="AddreqTechReviews.controls.HH.errors.required" class="colorRed">Please
                                        select hour
                                    </div>
                                </div>
                                <div *ngIf="hhValid" class="colorRed">Please select hour</div>
                            </div>
                            <div class="p-col-6 p-md-6 p-sm-12">
                                <label class="inputfields">MM<span class="mandatoryColor">*</span></label>
                                <p-dropdown [options]="MM" formControlName="MM" optionLabel="label" [showClear]="true"
                                    [virtualScroll]="true" placeholder="Select MM"></p-dropdown>
                                <div *ngIf="interviewModeForm && submitted && AddreqTechReviews.controls.MM.errors">
                                    <div *ngIf="AddreqTechReviews.controls.MM.errors.required" class="colorRed">Please
                                        select Minutes
                                    </div>
                                </div>
                                <div *ngIf="hhValid" class="colorRed"> Please select Minutes </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="skillsFlag" class="p-grid p-fluid">
                    <div class="p-col-12 p-md-3 p-sm-12">
                        <label class="inputfields">Total Questions<span class="mandatoryColor">*</span></label>
                        <input id="HHid" type="text" pInputText formControlName="totalQuestions" disabled="true"
                            maxlength="11">
                        <div *ngIf="interviewModeForm && submitted && AddreqTechReviews.controls.totalQuestions.errors">
                            <div *ngIf="AddreqTechReviews.controls.totalQuestions.errors.required" class="colorRed">
                                Total Questions field required</div>
                        </div>
                        <div *ngIf="totQuest">
                            <div class="mandatoryColor">Please enter avaible questions</div>
                        </div>
                    </div>
                </div>
                <div class="p-grid p-fluid">
                    <div *ngIf="skillsFlag; else thenHeaders" class="p-col-12 p-md-12 p-sm-12">
                        <label class="inputfields">Skill Set<span class="mandatoryColor">*</span></label>
                        <div class="p-col-12 p-md-12 p-sm-12"
                            [ngClass]="(interviewModeForm && submitted && skillBlocks.length === 0) ? 'borderColorRed' : 'borderColorGrey'">
                            <div *ngIf="skillBlocks.length > 0" class="ui-g">
                                <div *ngFor="let sk of skillBlocks" class="skillButton">
                                    <em class="pi pi-times ft12 cursorPointer" type="button" (click)="selectedSkill(sk.skillName, 'skills', 'skillBlocks'); totalCalculation();
 getQuestionsData(sk.skillName, 'deleted');"></em>
                                    <span class="ml5">{{sk.skillName}}</span>
                                </div>
                            </div>
                            <div id="autoCompleteId" class="p-md-4">
                                <p-autoComplete #skillFilter inputId="SkillFilter" placeholder="Type to search skills"
                                    [suggestions]="filteredSkills" formControlName="skillSetControls"
                                    (onSelect)="selectedSkill($event, 'skillBlocks', 'skills'); "
                                    (completeMethod)="filterSkills($event)" [size]="30" [minLength]="0"
                                    [dropdown]="true" [emptyMessage]="'no results found'">
                                </p-autoComplete>
                            </div>
                        </div>
                        <div *ngIf="errSkills">
                            <span class="mandatoryColor"> You can add upto 9 skills only. </span>
                        </div>
                    </div>
                    <div *ngIf="skillsFlag && interviewModeForm && submitted && skillBlocks.length === 0"
                        class="p-col-12 p-md-12 p-sm-12">
                        <div *ngIf="skillBlocks.length === 0" class="colorRed">Skill Set field required</div>
                    </div>
                    <div *ngIf="skillBlocks.length > 0" class="p-col-12 p-md-12 p-sm-12 p-grid p-fluid">
                        <div *ngFor="let sk of skillBlocks; let i = index" class="p-col-12 p-md-3 p-sm-12">
                            <label class="inputfields"><span
                                    class="colorBlue">{{sk.skillName}}</span><br><span>Available Questions
                                    ({{sk.value}})</span></label>
                            <input autocomplete="off" type="text" pInputText [value]="0"
                                formControlName="questionsAvailable" (blur)="skillTotalEvent($event, sk, i)"
                                maxlength="300" pKeyFilter="int" placeholder="0">
                        </div>
                    </div>
                    <ng-template #thenHeaders>
                        <div *ngIf="faceFlag" class="p-col-3 p-md-3 p-sm-3">
                            <label class="inputfields">Location<span class="mandatoryColor">*</span></label>
                            <p-dropdown [options]="locationList" formControlName="location"
                                placeholder="Select Location" optionLabel="name" autocomplete="off" [showClear]="true">
                            </p-dropdown>
                            <div *ngIf="faceFlag && AddreqTechReviews.controls.location.errors?.required && AddreqTechReviews.controls.location.invalid && AddreqTechReviews.controls.location.touched"
                                class="colorRed">Please select location</div>
                        </div>
                        <ng-template #otherHeaders>
                        </ng-template>
                    </ng-template>
                    <div *ngIf="skillsFlag" class="p-col-12 p-md-12 p-sm-12">
                        <label class="inputfields">Comments</label>
                        <textarea autocomplete="off" [rows]="2" [cols]="30" pInputTextarea formControlName="comments"
                            placeholder="Comments" maxlength="300"></textarea>
                    </div>
                </div>
                <br>
                <div class="btnsCSS1">
                    <button pButton type="submit" icon='pi pi-angle-double-right' label="Schedule" class="addhBtn"
                        (click)="Addschedule()"></button>
                </div><br><br>
            </p-panel>
    </p-card>
    <br>
    <p-card class="pgpos">
        <div *ngIf="totalRecords !== 0">
            <p-table [columns]="cols" [value]="reqTechReviewsGrid1" selectionMode="single" dataKey="edit" [rows]="10"
                [autoLayout]="true" [responsive]="true" [rowsPerPageOptions]="[10,20,30]" sortMode="multiple">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th id="gridHeader" *ngFor="let col of columns; let i=index" class="textCenter"
                            (click)="iconToggle[i] = !iconToggle[i]" [ngStyle]="{'width': col.width }"
                            [pSortableColumn]="col.field">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-columns="columns" let-rowIndex="rowIndex">
                    <tr>
                        <td class="textCenter cursorPointer" (click)="location($event, data)"
                            [ngStyle]="{'color':data.activityAttributes==='f' ? '#2bb9d5' : ' ' }">
                            {{typeTable[data.activityAttributes]}}
                        </td>
                        <td class='textCenter'>{{data.assignedDate}}</td>
                        <td class='textCenter ' pTooltip="{{data.reviewedBy}}" tooltipPosition="top">{{data.reviewedBy}}
                        </td>
                        <td class="textCenter cursorPointer" #actualTarget pTooltip="{{data.reviewerName}}"
                            tooltipPosition="top"><a (click)="op.show(data,actualTarget)"
                                (click)="reviewDetails(data)">{{data.reviewerName}}</a></td>
                        <td class='textCenter' (click)="data.lkActivityStatus==='R'? showstatus(data):' '"
                            [ngStyle]="{'color':data.lkActivityStatus==='R' ? 'red' : ' ' }"><span
                                class="ui-column-title"></span>
                            <p-badge [value]="statusConversion[data.lkActivityStatus]"
                                [ngClass]="data.lkActivityStatus ==='A'?'green': data.lkActivityStatus ==='C'?'blue': 'red'">
                            </p-badge>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="p-grid ui-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                    <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-10 ">
                    <p-paginator [rows]="10" (onPageChange)="paginate($event)" [rowsPerPageOptions]="[10,20,30]">
                    </p-paginator>
                </div>
            </div>
        </div>
        <p-card *ngIf="totalRecords === 0">
            <div class="norecordImgsize">
                <div class="aligncenter">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                    <br><em>Sorry, we are not able to find any scheduled interviews</em><br>
                </div>
            </div>
        </p-card>
    </p-card>
</form>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center  laoderheight">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-overlayPanel #op [showCloseIcon]="true" [dismissable]="true">
    <label class="inputfields">Reviewer</label>
    <ng-template pTemplate>
        <p-table [columns]="cols" [value]="entries" [autoLayout]="true" selectionMode="single"
            [(selection)]="selectedProduct" [paginator]="false" [rows]="5">
            <ng-template pTemplate="header">
                <tr>
                    <th id="gridHeader">Mail Id</th>
                    <th id="gridHeader">Mobile Number</th>
                    <th id="gridHeader">Office Number</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
                <tr>
                    <td class="pointer" (click)="emailToast()" (click)="copyInputMessage(data.reviewerEmail)" pTooltip="{{data.reviewerEmail}}" tooltipPosition="top"><a>{{data.reviewerEmail}}</a></td>
                    <td>{{data.reviewerMobilePhone}}</td>
                    <td>{{data.reviewerOfficePhone}}</td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</p-overlayPanel>
<p-dialog header="Rejection Reason" class="dialogBox" [(visible)]="displayreason" [style]="{width: '25vw'}"
    [draggable]="false" [modal]="true" [resizable]="true">
    <textarea autocomplete="off" pInputTextarea rows="3" cols="5" [style]="{width: '100%'}" readonly="true"
        [value]='rejreason'></textarea>
</p-dialog>
<p-dialog class="dialogBox" [(visible)]="showDialog1" [modal]="true" [style]="{width: '35%'}" [baseZIndex]="1"
    [draggable]="false" [resizable]="false">
    <p-header>Interview Location
        <a> <span class="map" (click)="goToLink($event)">View on map</span>
            <span class="pi pi-map-marker"></span>
        </a>
    </p-header>
    <div>
        <div class="p-grid p-fluid">
            <br>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Location Name : <strong class="loc-color">{{
                        this.locationname}}</strong></label>
            </div>
        </div>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields ">Address Line 1 : <strong class="loc-color"> {{
                        this.addressline1}}</strong></label>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Address Line 2 : <strong class="loc-color"> {{ this.adddressline2}} </strong>
                </label>
            </div>
        </div>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Country : <strong class="loc-color">{{this.country}}</strong> </label>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">State : <strong class="loc-color">{{this.state}}</strong> </label>
            </div>
        </div>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">City : <strong class="loc-color">{{ this.city}}</strong> </label>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Zip : <strong class="loc-color"> {{ this.zip}}</strong></label>
            </div>
        </div>
    </div>
</p-dialog>