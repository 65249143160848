import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss'],
  providers: [DatePipe, MessageService],

})
export class AddContactComponent implements OnInit {
  AddContactsForm: FormGroup;
  drop: any;
  state: any;
  hide: boolean;
  getData: any;
  displayModal: boolean;
  workLocation: any;
  accountid: string;
  userId: any;
  emailidd: any;
  loader = false;
  id: any;
  emailId: any;
  displayemail: boolean;
  roleData: any[];
  orgIdValue: any;
  // It is used for the displaying the  gender Dropdown Values
  genderDrop = [
    { id: 'M', name: 'Male' },
    { id: 'F', name: 'Female' },
  ];
  actId: any;
  mailId: string;
  mailextension: any;
  mailExtensionValue: string;
  rowid: string;
  rowID: any;
  rolesData: any;
  roles: any = [];
  officenum: any;
  displayNumber: boolean;
  officenummsg: string;
  usid: any;
  empflag: any;
  acctid: any;
  contaccid: any;
  conaccid: any;
  roleid: number;
  loginData: any;
  accID: any;
  phSpinnerHide: boolean;
  phCheckHide: boolean;
  phCheckHide1: boolean;
  phSpinnerHide1: boolean;
  displayNumber1: boolean;
  mobnummsg: string;
  mailSpinnerHide: boolean;
  ofcEmailExten: any;
  ofcMailValidation: string;
  mailCheckHide: boolean;
  acctype: string;
  salesacctype: string;
  salesrowid: string;
  oCountryCodeDropdown: any = [];
  mCountryCodeDropdown: any = [];
  items: any[];
  vendoraccname: any;
  usrroleid: any;
  getaccdata: any;
  salesflag: any;
  constructor(
    private fb: FormBuilder,
    private apisService: ApisService,
    private messageService: MessageService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute

  ) {
    this.usid = JSON.parse(apisService.decodeData()).userId;
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.vendoraccname = routeParams.params.vendoraccname;
    this.accID = routeParams.params.accID;
    this.empflag = routeParams.params.empflag;
    this.conaccid = routeParams.params.conaccid;
    this.contaccid = routeParams.params.conaccid;
    this.getaccdata = routeParams.params.getaccdata;
    this.salesflag = routeParams.params.flag;
    this.loginData = JSON.parse(this.apisService.decodeData());
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
      this.roleid = this.loginData.roles[0].id;
    } else {
      this.roleid = this.usrroleid;
    }
    // used to show breadcrumbs based on role ids
    this.rowid = sessionStorage.getItem('acct_rowid');
    this.salesrowid = sessionStorage.getItem('salesAccid');
    if (this.roleid === 14) {
      this.breadcrumbService.setItems([
        { label: 'Vendors', routerLink: '/vendor' },
        {
          label: 'Contacts', routerLink: ['/vendor-contact/', this.salesrowid, {
            queryParams:
              this.vendoraccname, From: 'vendor'
          }]
        },
        { label: 'Contact Add', routerLink: '#' },
      ]);
    }
    else if (this.empflag === 'acctemp') {
      this.breadcrumbService.setItems([
        { label: 'Accounts', routerLink: '/accounts' },
        { label: 'Account Details', routerLink: ['/admin-menu/acc-details', this.rowid] },
        { label: 'Contacts', routerLink: ['/admin-menu/account-contacts', this.rowid] },
        { label: 'Contact Add', routerLink: '#' },
      ]);
    }
    else if (this.roleid === 1) {
      this.breadcrumbService.setItems([
        { label: 'Accounts', routerLink: '/accounts' },
        { label: 'Account Details', routerLink: ['/admin-menu/account-details', this.rowid] },
        { label: 'Contacts', routerLink: ['/admin-menu/account-contacts', this.rowid] },
        { label: 'Contact Add', routerLink: '#' },
      ]);
    }
    else {
      this.breadcrumbService.setItems([
        { label: 'Account Details', routerLink: ['/admin-menu/account-details', this.rowid] },
        { label: 'Contacts', routerLink: ['/admin-menu/vendor-contact', this.rowid], },
        { label: 'Contact Add', routerLink: '#' },
      ]);
    }
    if (
      JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 0 ||
      JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 1
    ) {
      this.accountid = sessionStorage.getItem('account_id');
    } else {
      this.accountid = JSON.parse(sessionStorage.getItem('loginData')).orgId;
    }
  }
  ngOnInit() {

    this.actId = JSON.parse(this.apisService.decodeData());
    this.acctid = JSON.parse(this.apisService.decodeData()).userId;
    this.acctype = sessionStorage.getItem('acctpe');
    this.salesacctype = sessionStorage.getItem('salesacctype');
    this.rowID = this.actId.roles[0].id === 1 ? this.rowid : this.actId.accId;
    this.AddContactsForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(25), Validators.pattern('^[\\sa-zA-z]*$')],],
      middleName: ['', [Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(15)]],
      lastName: ['', [Validators.required, Validators.maxLength(25), Validators.pattern('^[\\sa-zA-z]*$')],],
      Email: ['', [Validators.required, Validators.maxLength(60), Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),],],
      gender: [{ id: 'M', name: 'Male' }],
      Title: ['', [Validators.required, Validators.maxLength(25), Validators.pattern('^[\\sa-zA-z]*$')]],
      offphone: ['', Validators.required],
      Mobilephone: [''],
      worklocation: [''],
      addressLine1: ['', Validators.maxLength(50)],
      oMobileDrp: [''],
      mMobileDrp: [''],
      addressLine2: ['', Validators.maxLength(50)],
      mailboxNumber: ['', [ Validators.maxLength(10)]],
      city: ['', [Validators.pattern('^[\\sa-zA-z0-9]*$'), Validators.maxLength(20)]],
      country: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', [Validators.pattern('^[0-9-]+$'),Validators.maxLength(10)]],
      role: ['', Validators.required],
    });
    this.AddContactsForm.controls.oMobileDrp.patchValue({
      code: '+1',
      flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png',
      id: '2',
      label: 'UNITED STATES',
    });
    this.AddContactsForm.controls.mMobileDrp.patchValue({
      code: '+1',
      flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png',
      id: '2',
      label: 'UNITED STATES',
    });
    this.getlocation();
    this.getRoleDD();
    this.getdrop();
    this.getCountrydrop();
    this.orgIdValue = JSON.parse(sessionStorage.getItem('loginData')).orgId;
    this.userId = JSON.parse(sessionStorage.getItem('loginData')).userId;
    this.AddContactsForm.controls.gender.patchValue({ id: 'M', name: 'Male' });
  }
  emailToast() {
    if (this.AddContactsForm.controls.Email.invalid && this.AddContactsForm.controls.Email.errors.pattern) {
    }
    else if (this.AddContactsForm.controls.Email.value === '') {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please enter email.' });
    }
    else {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
    }
  }
  // It is used for the displaying the country Dropdown Values
  getRoleDD() {
    const rolevalue = sessionStorage.getItem('Act_type');
    const getroles = rolevalue === 'B' ? 'V' : rolevalue;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.roleDD + getroles).subscribe((res: any) => {
      this.roleData = res.data;
    });
  }
  // onchange method for phone number code dropdown for future enhancement if needed
  countryids(event) {
  }
  // dropdown for phone number codes
  getdrop() {
    this.oCountryCodeDropdown = this.apisService.getdropdwon();
    this.mCountryCodeDropdown = this.apisService.getdropdwon();
  }
  // country drop down
  getCountrydrop() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.preCountryDropdown).subscribe((res) => {
      this.drop = res.data;
      this.AddContactsForm.controls.country.patchValue(this.drop.filter((x) => x.code === 'USA')[0]);
      this.getState('USA');
    });
  }
  // used to copy email field to clipboard
  copyInputMessage(data) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (data));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
  // used to show toggle if email copied
  toggle(event, data, rowData) {
    this.items = [

    ];
  }
  // It is used for the displaying the get location Dropdown Values
  getlocation() {
    this.actId = JSON.parse(this.apisService.decodeData());
    this.rowid = sessionStorage.getItem('acct_rowid');
    this.id = this.actId.accId;
    const id = this.actId.roles[0].id === 1 ? this.rowid : this.actId.accId;
    const locadid = id === null ? this.getaccdata : id;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.location + '/' + locadid).subscribe((res: any) => {
      this.workLocation = res.data;
      this.workLocation.every(element => {
        this.AddContactsForm.controls.worklocation.patchValue(element);
        return false;
      });
    });
  }
  // It is used for the displaying the states Dropdown Values based on country
  getState(code) {
    if (code === null || code === '' || code === undefined) {
      this.state = [];
    }
    else {  // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.state + '/' + code).subscribe((res) => {
        this.state = res.data;
      });
    }

  }
  // method to add contact based on that particular id
  contactAdd() {
    this.hide = true;
    if (this.AddContactsForm.invalid) {
      const controls = this.AddContactsForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    } else {
      const roleValue = this.AddContactsForm.controls.role.value;
      this.roles = roleValue.map((x) => x.id);
      const addacctype = this.roleid === 14 ? this.salesacctype : this.acctype;
      const addId = this.roleid === 14 ? this.salesrowid : this.rowID;
      const data = {
        accId: addId,
        address1: this.AddContactsForm.controls.addressLine1.value,
        address2: this.AddContactsForm.controls.addressLine2.value,
        city: this.AddContactsForm.controls.city.value,
        // tslint:disable-next-line:max-line-length
        country: this.AddContactsForm.controls.country.value === undefined || this.AddContactsForm.controls.country.value === null || this.AddContactsForm.controls.country.value === '' ? -1 : this.AddContactsForm.controls.country.value.code,
        email: this.AddContactsForm.controls.Email.value,
        fname: this.AddContactsForm.controls.firstName.value,
        gender: this.AddContactsForm.controls.gender.value.id || '',
        lname: this.AddContactsForm.controls.lastName.value,
        mailBox: this.AddContactsForm.controls.mailboxNumber.value,
        mname: this.AddContactsForm.controls.middleName.value,
        officePhone: this.AddContactsForm.controls.offphone.value,
        phone: this.AddContactsForm.controls.Mobilephone.value,
        // tslint:disable-next-line: max-line-length
        state: this.AddContactsForm.controls.state.value === undefined || this.AddContactsForm.controls.state.value === null || this.AddContactsForm.controls.state.value === '' ? -1 : this.AddContactsForm.controls.state.value.code,
        title: this.AddContactsForm.controls.Title.value,
        workLocation: this.AddContactsForm.controls.worklocation.value.locId || '',
        zip: this.AddContactsForm.controls.zip.value,
        roleList: this.roles,
        typeOfUser: addacctype,
        occode: this.AddContactsForm.controls.oMobileDrp.value === null || this.AddContactsForm.controls.oMobileDrp.value === undefined || '' ? '' : this.AddContactsForm.controls.oMobileDrp.value.id,
        mccode: this.AddContactsForm.controls.mMobileDrp.value === null || this.AddContactsForm.controls.oMobileDrp.value === undefined || '' ? '' : this.AddContactsForm.controls.mMobileDrp.value.id,
      };
      this.loader = true;
      // tslint:disable-next-line: deprecation
      this.apisService.postApi(environment.addContact, data).subscribe((res: any) => {
        this.getData = res;
        this.displayModal = false;
        if (this.getData.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message });
          if (this.roleid === 14) {
            setTimeout(() => {
              this.loader = false;
              this.AddContactsForm.reset();
              this.router.navigate(['/vendor-contact/', this.salesrowid], {
                queryParams: {
                  vendoraccname: this.vendoraccname
                }
              });
            }, 2000);
          } else {
            setTimeout(() => {
              this.loader = false;
              this.AddContactsForm.reset();
              this.router.navigate(['/admin-menu/account-contacts', this.rowid]
              );
            }, 2000);
          }
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error });
        }
      });
    }
  }
  // It is used for the reset the form values
  reset() {
    this.AddContactsForm.reset();
    this.AddContactsForm.controls.gender.patchValue({ id: 'M', name: 'Male' });
    this.AddContactsForm.controls.country.patchValue(this.drop.filter((x) => x.code === 'USA')[0]);
    this.getState('USA');
    this.AddContactsForm.controls.oMobileDrp.patchValue({
      code: '+1',
      flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png',
      id: '2',
      label: 'UNITED STATES',
    });
    this.AddContactsForm.controls.mMobileDrp.patchValue({
      code: '+1',
      flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png',
      id: '2',
      label: 'UNITED STATES',
    });
    this.workLocation.every(element => {
      this.AddContactsForm.controls.worklocation.patchValue(element);
      return false;
    });
  }
  // It is used for navigate the account contacts page
  homeroute() {

    const routeParams: any = this.route.snapshot.queryParamMap;
    this.conaccid = routeParams.params.conaccid;
    if (this.roleid === 14 || this.salesflag === 'sales') {
      this.router.navigate(['/vendor-contact/', this.salesrowid], {
        queryParams: {
          vendoraccname: this.vendoraccname,
          flag: 'sales'
        }
      }
      );
    }
    else {
      if (
        JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 2 ||
        JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 8 ||
        JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 13
      ) {
        this.id = JSON.parse(sessionStorage.getItem('loginData')).orgId;
      } else {
        this.id = sessionStorage.getItem('account_id');
        this.router.navigate(['/admin-menu/account-contacts', this.rowid], {
          queryParams: {
            contflag: 'conflag',
          }
        });
      }
      // this.router.navigate(['/admin-menu/account-contacts', this.rowid]);
    }
    // if (this.roleid === 14) {
    //   this.router.navigate(['/vendor-contact/', this.salesrowid], {
    //     queryParams: {
    //       vendoraccname: this.vendoraccname
    //     }
    //   }
    //   );
    // } else if (this.salesflag === 'sales') {
    //   this.router.navigate(['/vendor-contact/', this.salesrowid], {
    //     queryParams: {
    //       vendoraccname: this.vendoraccname,
    //       flag: 'sales'
    //     }
    //   }
    //   );
    // }
    // else {
    //   this.router.navigate(['/admin-menu/account-contacts', this.rowid]);
    // }

    // this.router.navigate(['/admin-menu/account-contacts', this.rowid]);
  }
  // used to check a office phone number exists or not
  getPhon() {
    this.displayNumber = false;
    const num = this.AddContactsForm.controls.offphone.value;
    if (num === '' || num === null || num === undefined || num === '(___) ___-____') {
    }
    else {
      this.phSpinnerHide = true;
      // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.phonerequired + num).subscribe(res => {
        this.officenum = res;
        this.officenum = res.data.isNumberExisted;
        if (this.officenum === true) {
          this.phSpinnerHide = false;
          this.officenummsg = 'Office number already exist';
          setTimeout(() => {
            this.officenummsg = '';
          }, 5000);
          this.AddContactsForm.get('offphone').reset();
        }
        else {
          this.phSpinnerHide = false;
          this.phCheckHide = true;
          setTimeout(() => {
            this.phCheckHide = false;
          }, 3000);
        }
      });
    }
  }
  // used to check mobile phone number exista or not
  getMobPhon() {
    this.displayNumber1 = false;
    const num = this.AddContactsForm.controls.Mobilephone.value;
    if (num === '' || num === null || num === undefined || num === '(___) ___-____') {
    }
    else {
      this.phSpinnerHide1 = true;
      // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.phonerequired + num).subscribe(res => {
        this.officenum = res;
        this.officenum = res.data.isNumberExisted;
        if (this.officenum === true) {
          this.phSpinnerHide1 = false;
          this.mobnummsg = 'Mobile number already exist';
          setTimeout(() => {
            this.mobnummsg = '';
          }, 5000);
          this.AddContactsForm.get('Mobilephone').reset();
        }
        else {
          this.phSpinnerHide1 = false;
          this.phCheckHide1 = true;
          setTimeout(() => {
            this.phCheckHide1 = false;
          }, 3000);
        }
      });
    }
  }
  // used to check whether an email already exist or not
  emailverification() {
    this.displayemail = false;
    const email = this.AddContactsForm.controls.Email.value;
    if (email === null || email === '' || email === undefined) {
    } else {
      if (this.AddContactsForm.controls.Email.invalid && this.AddContactsForm.controls.Email.errors.pattern) {
      }
      else {
        this.mailSpinnerHide = true;
        // tslint:disable-next-line: deprecation
        this.apisService.getApi(environment.emailVerification + email).subscribe((res) => {
          this.emailId = res.data.isEmailExisted;
          if (this.emailId === true) {
            this.mailSpinnerHide = false;
            this.emailId = 'Email already exist';
            this.mailExtensionValue = '';
            setTimeout(() => {
              this.emailId = '';
            }, 3000);
            this.AddContactsForm.controls.Email.reset();
          } else {
            this.mailSpinnerHide = false;
            this.mailCheckHide = true;
            this.emailId = '';
            setTimeout(() => {
              this.mailCheckHide = false;
            }, 3000);
          }
        });
      }
    }
  }
}
