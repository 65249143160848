<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
        <form [formGroup]="employerScreen">
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                        <label class="inputfields"> Employer Name</label>
                        <p-dropdown [options]="employerNameList" formControlName="employerName"
                            optionLabel="employerName" [filter]="true" [showClear]="true" placeholder="Employer Name">
                        </p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                        <label class="inputfields"> Designation</label>
                        <input id="input" type="text" pInputText placeholder="Designation" size="30" autocomplete="off"
                            formControlName="designation">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Start Date</label>
                        <p-calendar appendTo="body" [inputStyle]="{'width':'95%'}" id="popup" dateFormat="yy-mm-dd"
                            formControlName="startdate" placeholder="MM-DD-YYYY" [showIcon]="true"
                            [showButtonBar]="true">
                        </p-calendar>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">End Date</label>
                        <p-calendar appendTo="body" [inputStyle]="{'width':'95%'}" id="popup" dateFormat="yy-mm-dd"
                            formControlName="enddate" placeholder="MM-DD-YYYY" [showIcon]="true" [showButtonBar]="true">
                        </p-calendar>
                    </div>
                </div><br>
                <div class="btnsCSS1 btnAlg">
                    <button pButton type="button" icon='pi pi-plus' label="Add" class="addhBtn"
                        (click)="add()"></button>
                    <button pButton type="button" icon='pi pi-refresh' label="Reset" class="addhBtn"
                        (click)="reset()"></button>
                    <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn"
                        (click)="search()"></button>
                </div><br><br>
        </form>
</p-card>
<br>
<p-card class="pgpos">
    <div *ngIf="table">
        <p-table [columns]="cols" [value]="gridData" [rows]="10" selectionMode="single" dataKey="edit"
            [responsive]="true" sortMode="multiple" [autoLayout]="true">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        (click)="iconToggle[i] = !iconToggle[i]" [ngStyle]="{'width': col.width }"
                        [pSortableColumn]="col.field">
                        <span>{{col.header}} </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
                <tr>
                    <td class="textCenter"><span class="p-column-title"> Employer Name</span>{{data.employerName}}</td>
                    <td class="textCenter"> <span class="p-column-title">Designation</span>{{data.title}}</td>
                    <td class="textCenter"><span class="p-column-title"> Start Date</span>{{data.startDate |date:'MM-dd-yyyy'}}</td>
                    <td class="textCenter"><span class="p-column-title"> End Date</span>
                        {{data.endDate === "1970-01-05"||data.endDate === ''||data.endDate === null?"--":data.endDate | date:'MM-dd-yyyy'}}
                    </td>
                    <td class="textCenter"><span class="p-column-title"> Action</span><span
                            class="ui-column-title"></span><a>
                            <em (click)="actionpopup.toggle($event); toggle($event, data)"
                                class="pi pi-ellipsis-v pointer"></em>
                        </a></td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" (onPageChange)="paginate($event)"
                    [rowsPerPageOptions]="[10,20,30]"></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any employers</em><br>
            </div>
        </div>
    </p-card>
</p-card>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-dialog [contentStyle]="{'overflow': 'visible'}" class="dialogBox" header="Add Employer " [(visible)]="displayModal"
    [modal]="true" [style]="{width: '30%'}" [draggable]="false" [resizable]="false">
    <form [formGroup]="addEmployerScreen">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Title<span class="mandatoryColor">*</span></label>
                <input id="input" type="text" pInputText placeholder="Title" autocomplete="off"
                   maxlength="16" formControlName="addTitle">
                <div>
                    <span class="validationtest"
                        *ngIf="this.addEmployerScreen.get('addTitle').touched && this.addEmployerScreen.get('addTitle').invalid && this.addEmployerScreen.get('addTitle').errors.required">
                        Please enter title
                    </span>
                </div>
                <div>
                    <span class="validationtest"
                        *ngIf="this.addEmployerScreen.get('addTitle').invalid && this.addEmployerScreen.get('addTitle').errors?.pattern">
                        Please enter alphabets only</span>
                </div>
                <div>
                    <span class="validationtest" *ngIf="this.addEmployerScreen.get('addTitle').hasError('maxlength')">
                        Please enter 15 alphabets only
                    </span>
                </div>
            </div>
      
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Organization Name<span class="mandatoryColor">*</span> </label>
                <p-dropdown [options]="employerNameList" (onChange)="getworkLocByUrl($event.value.id)"
                    formControlName="organisationName" optionLabel="employerName" [filter]="true" [showClear]="true"
                    placeholder="Select Organization Name">
                </p-dropdown>
                <span class="validationtest"
                    *ngIf="this.addEmployerScreen.get('organisationName').touched && this.addEmployerScreen.get('organisationName').invalid && this.addEmployerScreen.get('organisationName').errors.required">
                    Please select organization name
                </span>
            </div>
      
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Project </label>
                <input id="input" type="text" pInputText disabled=true placeholder="Project" autocomplete="off"
                    formControlName="project" maxlength="30">
            </div>
      
            <div class="p-col-12 p-md-12 p-sm-12  p-fluid ">
                <p-checkbox [formControl]="addEmployerScreen.controls['dateCheck']"
                    (onChange)="disableAddCheckboxValue($event)" label=" Working Currently">
                </p-checkbox>
            </div>
      
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">From Date<span class="mandatoryColor">*</span></label>
                <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="fromDate" placeholder="MM-DD-YYYY"
                    [showIcon]="true" [showButtonBar]="true"></p-calendar>
                <span class="validationtest"
                    *ngIf="this.addEmployerScreen.get('fromDate').touched && this.addEmployerScreen.get('fromDate').invalid && this.addEmployerScreen.get('fromDate').errors.required">
                    Please enter from date
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-6 p-lg-6" *ngIf="!show">
                <label class="inputfields">To Date</label>
                <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="toDate" placeholder="MM-DD-YYYY"
                    [showIcon]="true" [showButtonBar]="true"></p-calendar>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6" *ngIf="show">
                <label class="inputfields">To Date</label>
                <input id="input" type="text" pInputText placeholder="Working Currently" disabled="true"
                    (onChange)="disableCheckboxValue1($event)" autocomplete="off" formControlName="organisationText"
                    maxlength="30">
            </div>
      
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Work Location </label>
                <p-dropdown [options]="workLoc" formControlName="workLocName" optionLabel="name" [showClear]="true"
                    placeholder="Select Work Location">
                    <ng-template let-data pTemplate="item">
                        <div pTooltip="{{data.name + '\n' + data.address1+',' + data.city + ',' + data.state + ',' + data.country}}"
                            tooltipPosition="right">
                            {{ data.name }}
                        </div>
                    </ng-template> 
                </p-dropdown>
            </div>

            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Comments</label>
                <textarea id="textarea" type="text" pInputText placeholder="Comments" autocomplete="off"
                    formControlName="comment" maxlength="201"></textarea>
                    <span class="validationtest" *ngIf="this.addEmployerScreen.get('comment').hasError('maxlength')">
                        Please enter 200 alphabets only
                    </span>
            </div>
        </div>
        <br>
    </form>
    <div class="btnsCSS1 rightalign"> <button pButton type="button" icon='pi pi-save' label="Save" class="addhBtn"
            (click)="addemp()" width="100%" [disabled]="disable"></button>
    </div>
</p-dialog>
<p-dialog [contentStyle]="{'overflow': 'visible'}" class="dialogBox" header="Edit Employer " [(visible)]="editModal"
    [modal]="true" [style]="{width: '30%'}" [draggable]="false" [resizable]="false">
    <form [formGroup]="editEmployerScreen">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Title<span class="mandatoryColor">*</span></label>
                <input id="input" type="text" pInputText placeholder="Title" autocomplete="off"
                    formControlName="addTitle" maxlength="16">
                <div>
                    <span class="validationtest"
                        *ngIf="this.editEmployerScreen.get('addTitle').touched && this.editEmployerScreen.get('addTitle').invalid && this.editEmployerScreen.get('addTitle').errors.required">
                        Please enter title
                    </span>
                </div>
                <div>
                    <span class="validationtest"
                        *ngIf="this.editEmployerScreen.get('addTitle').invalid && this.editEmployerScreen.get('addTitle').errors?.pattern">
                        Please enter alphabets only</span>
                </div>
                <div>
                    <span class="validationtest" *ngIf="this.editEmployerScreen.get('addTitle').hasError('maxlength')">
                        Please enter 15 alphabets only
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Organization Name<span class="mandatoryColor">*</span> </label>
                <p-dropdown [options]="employerNameList" (onChange)="getworkLocByUrl($event.value.id)"
                    formControlName="organisationName" optionLabel="employerName" [filter]="true" [showClear]="true"
                    placeholder="Select Organization Name">
                </p-dropdown>
                <span class="validationtest"
                    *ngIf="this.editEmployerScreen.get('organisationName').touched && this.editEmployerScreen.get('organisationName').invalid && this.editEmployerScreen.get('organisationName').errors.required">
                    Please select organization name
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Project </label>
                <input id="input" type="text" pInputText disabled=true placeholder="Project" autocomplete="off"
                    formControlName="project" maxlength="30">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12  p-fluid ">
                <p-checkbox [formControl]="editEmployerScreen.controls['dateCheck']" binary="true"
                    (click)="dateChecker()" (onChange)="disableAddCheckboxValue($event)" label=" Working Currently">
                </p-checkbox>
            </div>
            <div class="p-col-12 p-md-12 p-sm-6 p-lg-6">
                <label class="inputfields">From Date<span class="mandatoryColor">*</span></label>
                <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="fromDate" placeholder="MM-DD-YYYY"
                    [showIcon]="true" [showButtonBar]="true"></p-calendar>
                <span class="validationtest"
                    *ngIf="this.editEmployerScreen.get('fromDate').touched && this.editEmployerScreen.get('fromDate').invalid && this.editEmployerScreen.get('fromDate').errors.required">
                    Please enter from date
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-6 p-lg-6" *ngIf="!show">
                <label class="inputfields">To Date</label>
                <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="toDate" placeholder="MM-DD-YYYY"
                    [showIcon]="true" [showButtonBar]="true"></p-calendar>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6" *ngIf="show">
                <label class="inputfields">To Date</label>
                <input id="input" type="text" pInputText placeholder="Working Currently" disabled="true"
                    (onChange)="disableCheckboxValue1($event)" autocomplete="off" formControlName="organisationText"
                    maxlength="30">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12" pTooltip="{{this.patchlocationString}}" tooltipPosition="top">
                <label class="inputfields">Work Location </label>
                <p-dropdown [options]="workLoc" formControlName="workLocName" optionLabel="name" [showClear]="true"
                    placeholder="Select Work Location" appendTo="body" (onChange)="saveLocation($event)">
                    <ng-template let-data pTemplate="item">
                        <div pTooltip="{{data.name + '\n' + data.address1+',' + data.city + ',' + data.state + ',' + data.country}}"
                            tooltipPosition="right">
                            {{ data.name }}
                        </div>
                    </ng-template>
               
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Comments</label>
                <textarea id="textarea" type="text" pInputText placeholder="Comments" autocomplete="off"
                    formControlName="comment" maxlength="201"></textarea>
                    <span class="validationtest" *ngIf="this.editEmployerScreen.get('comment').hasError('maxlength')">
                        Please enter 200 alphabets only
                    </span>
            </div>
        </div>
        <br>
    </form>
    <div class="btnsCSS1 rightalign">
        <button pButton type="button" icon='pi pi-save' label="Update" class="addhBtn" (click)="editemp(data)"
            [disabled]="disable"></button>
    </div>
</p-dialog>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>