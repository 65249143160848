<p-toast position="bottom-right" key="br"></p-toast>

<form [formGroup]="tierdefinitionform">
    <p-card class="pgpos">
        <div class="btnsCSS1 addBtn">
            <button pButton type="button" icon='pi pi-plus' label="Add" class="addhBtn"
                (click)="addData('null', 'null')" [disabled]='buttonHandle'></button>
        </div><br><br>
             <!--It is used for displaying the tier table-->
        <p-table [responsive]="true" [columns]="cols" [value]="gridData" selectionMode="single" dataKey="edit"
            sortMode="multiple">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
                <tr>
                    <td class="textCenter"><span class="p-column-title">
                            Tier</span>{{data.tierName}}</td>
                    <td class="textCenter"><span class="p-column-title">
                            Discount(%)</span>{{data.discount}}</td>
                    <td class="textCenter"><span class="p-column-title">
                            Days Delay</span>{{data.daysDelay}}</td>
                    <td><span class="ui-column-title"></span>
                        <p-badge [value]="getStatusTable[data.status]" [ngClass]="data.status ==='A'?'green': 'red'">
                        </p-badge>
                    </td>

                    <td class="textCenter"><span class="p-column-title">
                            Vendor Count</span>{{data.vendorCount}}</td>
                    <td class="textCenter"><span class="p-column-title">
                            Action</span>
                        <a class="pi pi-ellipsis-v pointer"
                            (click)="downloadActionPopup.toggle($event); toggle($event,'data', data)"></a>
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <div class="p-grid ui-fluid">

            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalrecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10 ">
                <p-paginator [rows]="10" [totalRecords]="totalrecords" [rowsPerPageOptions]="[10,20,30]"
                (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </p-card>

</form>
<div *ngIf="!loading; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="items"></p-menu>

<!-- It is used for edit tier pop up -->
<p-dialog class="dialogBox" header="Edit Tier" [(visible)]="display" [modal]="true" [baseZIndex]="1" [draggable]="false" [style]="{width: '20%'}"
    [resizable]="false">
    <form [formGroup]='vendorEditForm'>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12  p-lg-12">
                <label class="inputfields">Type Of Tier<span class="mandatoryColor">*</span></label>
                <input autocomplete="off" id="input" type="text" size="30" pInputText formControlName="vendorName1"
                    placeholder="Type Of Tier" disabled="true">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12  p-lg-12">
                <label class="inputfields">Status</label>
                <p-dropdown [options]="status1" placeholder="Select Status" formControlName="status" optionLabel="name"
                [showClear]="true">
                </p-dropdown>
            </div>
      
            <div class="p-col-12 p-md-12 p-sm-12  p-lg-12">
                <label class="inputfields">Days Delay</label>
                <input autocomplete="off" id="input" type="text" size="30" pInputText formControlName="days"
                   maxlength="3" placeholder="No.Of Days">
                   <span class="validationtest" *ngIf="this.vendorEditForm.get('days').hasError('maxlength')">
                    Please enter 2 numbers only
                </span>
            </div>
     
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Discount(%)</label>
                <input autocomplete="off" id="input" type="text" size="30" pInputText formControlName="discount"
                    placeholder="Discount">
            </div>
        </div><br>
        <div class="btnsCSS1">
            <button pButton type="button" icon='pi pi-save' class="addhBtn" label="Update" [disabled]="disable"
                (click)="vendorUpdate()"></button>
        </div>
        <br><br>
    </form>
</p-dialog>