import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss'],
  providers: [DatePipe, MessageService],
})
export class SkillsComponent implements OnInit {
  skillsdata: any = [];
  skills = {};
  array: any = [];
  skillsName: any;
  boxes: any[];
  targetProducts: any[];
  otherskills: any = [];
  skillset1: any;
  skillset: any;
  skillget = {};
  skillsdatas: any = [];
  uniqueskills: any = [];
  draresponse: any = [];
  dragged: any;
  loader: boolean;
  @Output() refreshList = new EventEmitter();
  totalRecords: any;
  table: boolean;
  records: boolean;
  // tslint:disable-next-line: max-line-length
  constructor(private breadcrumbService: BreadcrumbService, private router: Router, private apisService: ApisService, private primengConfig: PrimeNGConfig) {
    this.breadcrumbService.setItems([
      { label: 'Skills', routerLink: '/skills' },
    ]);
    this.availableskills();
    this.getskills();
  }
  ngOnInit(): void {
    this.targetProducts = [];
    this.primengConfig.ripple = true;
  }
  availableskills() {
    this.loader = true;
    this.apisService.getApi(environment.skills).subscribe((res: any) => {
      this.skillsdata = res.data;
      this.loader = false;
      this.skillsdata.forEach((element) => {
        this.skillget[element.code] = element.label;
      });
    });
  }
  getskills() {
    this.loader = true;
    this.apisService.getApi(environment.skillsnotExisted).subscribe((res: any) => {
      this.otherskills = res.data;
      this.totalRecords = res.data.length;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.loader = false;
    });
  }
  addSkills(data) {
    Swal.fire({
      text: 'Are you sure want to add skills ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0D416B',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        const body = {
          skill: data
        };
        this.loader = true;
        this.apisService.postApi(environment.notExistedSkillAdded, body).subscribe((res: any) => {
          this.loader = false;
          this.draresponse = res;
          if (this.draresponse.success === true) {
            this.availableskills();
            this.getskills();
          }
        });
      }
    });
  }
}
