<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
    <p-panel header="Contact Add">
        <p-header>
            <div class="pi pi-arrow-left panelBackRoute" (click)="homeroute()"> </div>
        </p-header>
        <form [formGroup]="AddContactsForm">
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields"> First Name<span class="mandatoryColor">*</span></label>
                    <input autocomplete="off" id="input" type="text" pInputText placeholder="First Name"
                        formControlName="firstName" maxlength="26">
                    <div>
                        <span class="validationtest"
                            *ngIf="this.AddContactsForm.controls.firstName.touched && this.AddContactsForm.controls.firstName.invalid && this.AddContactsForm.controls.firstName.errors.required">
                            Please enter first name
                        </span>
                        <span class="validationtest"
                            *ngIf="this.AddContactsForm.controls.firstName.invalid && this.AddContactsForm.controls.firstName.errors.pattern">Please
                            enter
                            alphabets only</span>
                    </div>
                    <div>
                        <span class="validationtest"
                            *ngIf=" this.AddContactsForm.get('firstName').hasError('maxlength')">
                            Please enter 25 alphabets only
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">Middle Name</label>
                    <input autocomplete="off" id="input" type="text" pInputText placeholder="Middle Name"
                        formControlName="middleName" maxlength="16">
                    <span class="validationtest"
                        *ngIf="this.AddContactsForm.controls.middleName.invalid && this.AddContactsForm.controls.middleName.errors.pattern">Please
                        enter
                        alphabets only</span>
                    <div>
                        <span class="validationtest"
                            *ngIf=" this.AddContactsForm.get('middleName').hasError('maxlength')">
                            Please enter 15 alphabets only
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields"> Last Name<span class="mandatoryColor">*</span></label>
                    <input autocomplete="off" id="input" type="text" pInputText placeholder="Last Name"
                        formControlName="lastName" maxlength="26">
                    <div>
                        <span class="validationtest"
                            *ngIf="this.AddContactsForm.controls.lastName.touched && this.AddContactsForm.controls.lastName.invalid && this.AddContactsForm.controls.lastName.errors.required">
                            Please enter last name
                        </span>
                        <span class="validationtest"
                            *ngIf="this.AddContactsForm.controls.lastName.invalid && this.AddContactsForm.controls.lastName.errors.pattern">Please
                            enter
                            alphabets only</span>
                    </div>
                    <div>
                        <span class="validationtest"
                            *ngIf=" this.AddContactsForm.get('lastName').hasError('maxlength')"> Please enter 25
                            alphabets only
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">Gender</label>
                    <p-dropdown [options]="genderDrop" formControlName="gender" placeholder="Select Gender"
                        optionLabel="name" [showClear]="true">
                    </p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">Title<span class="mandatoryColor">*</span></label>
                    <input autocomplete="off" id="input" type="text" pInputText placeholder="Title"
                        formControlName="Title" maxlength="26">
                    <div>
                        <span class="validationtest"
                            *ngIf="this.AddContactsForm.controls.Title.touched && this.AddContactsForm.controls.Title.invalid && this.AddContactsForm.controls.Title.errors.required">
                            Please enter title</span>
                        <span class="validationtest"
                            *ngIf="this.AddContactsForm.controls.Title.invalid && this.AddContactsForm.controls.Title.errors?.pattern">Please
                            enter
                            alphabets only</span>
                        <span class="validationtest" *ngIf="this.AddContactsForm.get('Title').hasError('maxlength')">
                            Please enter 25 alphabets only
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 invalidMandatory">
                    <label class="inputfields">Email<span class="mandatoryColor">*</span></label>
                    <span class="p-input-icon-right"><em *ngIf="mailSpinnerHide"
                            class="pi pi-spin pi-spinner mail"></em>
                        <em *ngIf="mailCheckHide" class="pi pi-check-circle mail"></em> </span>
                    <div class="p-inputgroup">
                        <input autocomplete="off" id="input" type="text" (blur)="emailverification()" pInputText
                            placeholder="Email" formControlName="Email" maxlength="61" #mail>
                        <span class="p-inputgroup-addon"> <em class="pi pi-copy pointer" (click)="emailToast()"
                                (click)="copyInputMessage(mail.value)"></em>
                        </span>
                    </div>
                    <span *ngIf="displayemail" class="mandatoryColor">Email already exist</span>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.AddContactsForm.controls.Email.touched && this.AddContactsForm.controls.Email.invalid && this.AddContactsForm.controls.Email.errors.required">
                            Please enter email</span>
                        <span class="validationtest" *ngIf="this.AddContactsForm.get('Email').hasError('maxlength')">
                            Please enter 60 alphabets only
                        </span>
                        <span class="validationtest"
                            *ngIf="AddContactsForm.controls.Email.invalid && AddContactsForm.controls.Email.errors.pattern">
                            Must be valid corp email
                        </span>
                        <p class="validationtest">{{this.emailId}}</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 invalidMandatory">
                    <label class="inputfields"> Office Phone<span class="mandatoryColor">*</span></label>
                    <div class="p-col-12 p-md-12 p-sm-12 phone">
                        <div class="p-inputgroup" class="p-col-4 p-md-4 ph-no">
                            <span class="p-inputgroup-addon">
                                <p-dropdown [options]="mCountryCodeDropdown" class="phonecodestyle newcode"
                                    (onChange)="countryids($event)" filterPlaceholder='Search Results'
                                    optionLabel="label" formControlName="oMobileDrp">
                                    <ng-template let-items pTemplate="selectedItem">
                                        <div class="flex-row">
                                            <img class="lang-change-img" src={{items.flag}} alt="" />
                                            <div class="lang-change-option-text selected-lang center">{{items.code}}
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template let-country pTemplate="item">
                                        <div class="flex-row" id="dropdown1">
                                            <img class="lang-change-img" src={{country.flag}} alt="" />
                                            <div class="lang-change-option-text center">{{country.label}}
                                                {{country.code}}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </span>
                        </div>
                        <div class="p-col-8 p-md-8 ccode">
                            <span class="p-input-icon-right"><em *ngIf="phSpinnerHide"
                                    class="pi pi-spin pi-spinner spinnerstyles"></em>
                                <em *ngIf="phCheckHide" class="pi pi-check-circle tickmarkstyle"></em>
                                <p-inputMask class="inputmaskwidth" id="grpText" autocomplete="off" (onBlur)="getPhon()"
                                    mask="(999) 999-9999" placeholder="Office Phone" formControlName="offphone">
                                </p-inputMask>
                            </span>
                        </div>
                    </div>
                    <span class="validationtest"
                        *ngIf="this.AddContactsForm.controls.offphone.touched && this.AddContactsForm.controls.offphone.invalid && this.AddContactsForm.controls.offphone.errors.required">
                        Please enter office number</span>
                    <p class="validationtest">{{this.officenummsg}}</p>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 invalidMandatory">
                    <label class="inputfields"> Mobile Phone</label>
                    <div class="p-col-12 p-md-12 p-sm-12 phone">
                        <div class="p-inputgroup" class="p-col-4 p-md-4 ph-no">
                            <span class="p-inputgroup-addon">
                                <p-dropdown [options]="mCountryCodeDropdown" class="phonecodestyle newcode"
                                    (onChange)="countryids($event)" filterPlaceholder='Search Results'
                                    optionLabel="label" formControlName="mMobileDrp">
                                    <ng-template let-items pTemplate="selectedItem">
                                        <div class="flex-row">
                                            <img class="lang-change-img" src={{items.flag}} alt="" />
                                            <div class="lang-change-option-text selected-lang center">{{items.code}}
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template let-country pTemplate="item">
                                        <div class="flex-row" id="dropdown1">
                                            <img class="lang-change-img" src={{country.flag}} alt="" />
                                            <div class="lang-change-option-text center">{{country.label}}
                                                {{country.code}}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </span>
                        </div>
                        <div class="p-col-8 p-md-8 ccode">
                            <span class="p-input-icon-right"><em *ngIf="phSpinnerHide1"
                                    class="pi pi-spin pi-spinner spinnerstyles"></em>
                                <em *ngIf="phCheckHide1" class="pi pi-check-circle tickmarkstyle"></em>
                                <p-inputMask class="inputmaskwidth" id="grpText" autocomplete="off"
                                    (onBlur)="getMobilePhon()" mask="(999) 999-9999" placeholder="Mobile Phone"
                                    formControlName="Mobilephone">
                                </p-inputMask>
                            </span>
                        </div>
                    </div>
                    <p class="validationtest">{{this.mobilenummsg}}</p>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">Work Location</label>
                    <p-dropdown [options]="workLocation" placeholder="Select Work Location" optionLabel="name"
                        formControlName="worklocation" [showClear]="true">
                        <ng-template let-data pTemplate="item">
                            <div pTooltip="{{data.name + '\n' + data.address1+',' + data.city + ',' + data.state + ',' + data.country}}"
                                tooltipPosition="right">
                                {{ data.name }}
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">Role<span class="mandatoryColor">*</span></label>
                    <p-multiSelect [options]="roleData" [showToggleAll]="false" formControlName="role"
                        placeholder="Select Role" [panelStyle]="{minWidth:'12em'}" optionLabel="name" [filter]='false'>
                    </p-multiSelect>
                    <span class="validationtest"
                        *ngIf="AddContactsForm.get('role').touched && AddContactsForm.get('role').invalid && AddContactsForm.get('role').errors.required">
                        Please select role
                    </span>
                </div>
            </div>
            <h5 class="h3tag">Contact Address</h5>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Address Line 1</label>
                    <input autocomplete="off" id="input" type="text" pInputText placeholder="Address Line 1"
                        formControlName="addressLine1" maxlength="51">
                    <div>
                        <span class="validationtest"
                            *ngIf=" this.AddContactsForm.get('addressLine1').hasError('maxlength')">
                            Please enter 50 alphabets only
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Address Line 2</label>
                    <input autocomplete="off" id="input" type="text" pInputText placeholder="Address Line 2"
                        formControlName="addressLine2" maxlength="51">
                    <div>
                        <span class="validationtest"
                            *ngIf=" this.AddContactsForm.get('addressLine2').hasError('maxlength')">
                            Please enter 50 alphabets only
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                    <label class="inputfields">Mailbox</label>
                    <input autocomplete="off" id="input" type="text" pInputText placeholder="Mailbox" maxLength="11"
                        formControlName="mailboxNumber">
                    <div class="mandatoryColor">
                        <span class="validationtest"
                            *ngIf="this.AddContactsForm.get('mailboxNumber').invalid && this.AddContactsForm.get('mailboxNumber').errors.pattern">
                            Please enter numbers only
                        </span>
                    </div>
                    <div>
                        <span class="validationtest"
                            *ngIf=" this.AddContactsForm.get('mailboxNumber').hasError('maxlength')">
                            Please enter 10 alphabets only
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                    <label class="inputfields">Country<span class="mandatoryColor">*</span></label>
                    <p-dropdown [options]="drop" formControlName="country" placeholder="Select Country"
                        optionLabel="label" [filter]='true' [showClear]="true" (onChange)="getState($event.value.code)">
                    </p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.AddContactsForm.controls.country.touched && this.AddContactsForm.controls.country.invalid && this.AddContactsForm.controls.country.errors.required">
                            Please select country</span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">State<span class="mandatoryColor">*</span></label>
                    <p-dropdown [options]="state" [filter]='true' formControlName="state" placeholder="Select State"
                        optionLabel="label" [showClear]="true"></p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.AddContactsForm.controls.state.touched && this.AddContactsForm.controls.state.invalid && this.AddContactsForm.controls.state.errors.required">
                            Please select state</span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">City</label>
                    <input autocomplete="off" id="input" type="text" pInputText placeholder="City"
                        formControlName="city" maxlength="21">
                    <span class="validationtest"
                        *ngIf="this.AddContactsForm.controls.city.invalid && this.AddContactsForm.controls.city.errors?.pattern">Please
                        enter
                        alphabets only</span>
                    <div>
                        <span class="validationtest" *ngIf=" this.AddContactsForm.get('city').hasError('maxlength')">
                            Please enter 20 alphabets only
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                    <label class="inputfields">Zip</label>
                    <input autocomplete="off" id="input" type="text" pInputText placeholder="Zip" pKeyFilter="num"
                        formControlName="zip" maxlength="11">
                    <div>
                        <span class="validationtest" *ngIf=" this.AddContactsForm.get('zip').hasError('maxlength')">
                            Please enter 10 alphabets only
                        </span>
                        <span class="validationtest"
                            *ngIf="this.AddContactsForm.get('zip').invalid && this.AddContactsForm.get('zip').errors.pattern">
                            Please enter numbers only
                        </span>
                    </div>
                </div>
            </div>
            <br>
            <div class="btnsCSS1">
                <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()"
                    class="addhBtn"></button>
                <button pButton type="submit" [disabled]="disablebtn" icon='pi pi-save' label="Save"
                    (click)="contactAdd()" class="addhBtn"></button>
            </div><br><br>
        </form>
    </p-panel>
</p-card>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>