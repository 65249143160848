import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-employee-skills',
  templateUrl: './employee-skills.component.html',
  styleUrls: ['./employee-skills.component.scss'],
  providers: [DatePipe, MessageService],
})
export class EmployeeSkillsComponent implements OnInit {
  RatingForm: FormGroup;
  loader: boolean;
  pskillGrid: any;
  secskillGrid: any;
  endorseSkills: any;
  skillTypes: any;
  display: boolean;
  AddSkillForm: any;
  skillList: any;
  skillList1: any = [];
  skilldata = {};
  user: any;
  hide: boolean;
  display1: boolean;
  displayEditPopup: boolean;
  items: {
    label: string;
    icon: string; command: () => void;
  }[];
  endorseCardDisplay: boolean;
  endorseSkillName: any;
  skillsEndorsed: any[];
  AddSecSkillForm: any;
  displayEditPopup2: boolean;
  ratings: any;
  ratingLabel: string;
  skillsEndorsementList: any;
  skillID: any;
  starRating: any;
  rating: any;
  records: boolean;
  table: boolean;
  totalRecords: any;
  primaryID: any;
  secondaryID: any;
  primaryId: string;
  secondaryId: string;
  skillsData: any;
  skillName: any;
  disable = false;
  // tslint:disable-next-line:max-line-length
  constructor(private apisService: ApisService, private fb: FormBuilder, private messageService: MessageService, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Skills', routerLink: '/employe-skills' },
    ]);
  }
  ngOnInit(): void {
    this.AddSecSkillForm = this.fb.group({
      skill: ['', Validators.required],
    });
    this.AddSkillForm = this.fb.group({
      skill: ['', Validators.required],
    });
    this.gridData();
    this.getSkillList();
    this.getSkillsRatings();
  }
  // It is used to display the skills  data
  gridData() {
    this.loader = true;
    const actId = JSON.parse(this.apisService.decodeData());
    this.apisService.getApi(environment.getSkillGrid + actId.userId).subscribe((res: any) => {
      this.pskillGrid = res.data.P;
      this.secskillGrid = res.data.S;
      this.endorseSkills = res.data.E;
      this.loader = false;
      this.pskillGrid.forEach(element => {
        this.skilldata[element.skillName] = element.skillId;
        this.skillID = element.skillId;
      });
    });
  }
  // It is used for displaying add primary skills pop up
  showDialog(type) {
    this.AddSkillForm.reset();
    this.skillTypes = type;
    this.getSkillList();
    this.display = true;
  }
  // It is used display skill list
  getSkillList() {
    const actId = JSON.parse(this.apisService.decodeData());
    this.apisService.getApi(environment.getSkillList + actId.userId).subscribe((res: any) => {
      this.skillList = res.data;
      this.skillList1 = res.data;
    });
  }
  // It is used to add primary skills
  addSkillData() {
    this.hide = true;
    if (this.AddSkillForm.invalid) {
      const controls = this.AddSkillForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    } else {
      this.disable = true;
      const actId = JSON.parse(this.apisService.decodeData());
      this.user = actId.userId;
      const skillset = [];
      this.AddSkillForm.controls.skill.value.forEach((element) => {
        skillset.push(element.code);
      });
      const data = {
        usrId: this.user,
        skillId: skillset.toString(),
        skillType: this.skillTypes,
      };
      this.loader = true;
      this.apisService.postApi(environment.getAddSkill, data).subscribe((res: any) => {
        this.loader = false;
        this.skillList = res;
        this.disable = false;
        this.display1 = false;
        this.display = false;
        this.gridData();
        if (this.skillList.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.skillList.message });
          this.loader = false;
          setTimeout(() => {
            this.loader = false;
          }, 1500);
        } else if (this.skillList.hasOwnProperty('success') === false) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.skillList.message });
          this.loader = false;
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.skillList.error });
          this.loader = false;
        }
        this.loader = true;
      });
    }
  }
  // It is used to add secondary skills
  addSkillData1() {
    this.hide = true;
    if (this.AddSecSkillForm.invalid) {
      const controls = this.AddSecSkillForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    } else {
      this.disable = true;
      const actId = JSON.parse(this.apisService.decodeData());
      this.user = actId.userId;
      const skillset = [];
      this.AddSecSkillForm.controls.skill.value.forEach((element) => {
        skillset.push(element.code);
      });
      const data = {
        usrId: this.user,
        skillId: skillset.toString(),
        skillType: this.skillTypes,
      };
      this.loader = true;
      this.apisService.postApi(environment.getAddSkill, data).subscribe((res: any) => {
        this.loader = false;
        this.skillList1 = res;
        this.disable = false;
        this.display1 = false;
        this.display = false;
        this.gridData();
        if (this.skillList1.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.skillList1.message });
          this.loader = false;
          setTimeout(() => {
            this.loader = false;
          }, 1500);
        } else if (this.skillList1.hasOwnProperty('success') === false) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.skillList1.message });
          this.loader = false;
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.skillList1.error });
          this.loader = false;
        }
        this.loader = true;
      });
    }
  }
  showEditDialog() {
    this.displayEditPopup = true;
  }
  // It is used to display action pop up
  toggle(event, data, type) {
    this.skillsData = data;
    this.skillName = data.skillName;
    if (type === 'primary') {
      this.items = [{ label: 'Make Secondary', icon: 'pi pi-angle-double-down', command: () => { this.deletePrimaryRow(data); }, }];
    } else {
      this.items = [{ label: 'Make Primary', icon: 'pi pi-angle-double-up', command: () => { this.deleteSecondaryRow(data); }, }];
    }
  }
  // it is used to show skill name list
  endorse(data) {
    this.loader = true;
    this.endorseCardDisplay = true;
    const actId = JSON.parse(this.apisService.decodeData());
    this.apisService.getApi(environment.skillsEndorsement + actId.userId + '/' + data.skillId).subscribe((res: any) => {
      this.skillsEndorsed = res.data;
      this.table = true;
      this.totalRecords = this.skillsEndorsed.length;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
      this.loader = false;
    });
    this.endorseSkillName = data.skillName;
  }
  deletePrimaryRow(data) {
    this.displayEditPopup = false;
    Swal.fire({
      text: 'Do you want to make ' + data.skillName + ' secondary skill?', icon: 'warning', showCancelButton: true, confirmButtonColor: '#0D416B', confirmButtonText: 'Yes', cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        return this.changePrimaryType(data);
      }
    });
  }
  deleteSecondaryRow(data) {
    this.displayEditPopup2 = false;
    Swal.fire({
      text: 'Do you want to make ' + data.skillName + ' primary skill?', icon: 'warning', showCancelButton: true, confirmButtonColor: '#0D416B', confirmButtonText: 'Yes', cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        return this.changeSecondaryType(data);
      }
    });
  }
  changePrimaryType(data) {
    this.loader = true;
    this.primaryId = sessionStorage.getItem('primaryID');
    const reqbody = {
      id : data.id,
      skillsId: data.skillId,
      skillType : 'S'
    };
    this.apisService.putApi(environment.changeSkill, reqbody).subscribe((res: any) => {
      this.skillsEndorsed = res.data;
      if (res.success === true) {
        this.gridData();
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Changed Successfully.' });
        this.displayEditPopup = false;
        this.gridData();
      } else {
        setTimeout(() => {
          this.displayEditPopup = false;
        }, 2000);
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
      }
      this.loader = false;
    });
  }
  // It is used to change primary skill to secondary skills
  changeSecondaryType(data) {
    this.loader = true;
    this.secondaryId = sessionStorage.getItem('secondaryID');
    const reqbody = {
      id : data.id,
      skillsId: data.skillId,
      skillType : 'P'
    };
    this.apisService.putApi(environment.changeSkill , reqbody).subscribe((res: any) => {
      this.skillsEndorsed = res.data;
      this.loader = false;
      if (res.success === true) {
        this.gridData();
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Changed Successfully.' });
        this.displayEditPopup2 = false;
        this.gridData();
      } else {
        setTimeout(() => {
          this.displayEditPopup = false;
        }, 2000);
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
      }
    });
  }
  // It is used to display secondary skill pop up
  showDialog1(type) {
    this.AddSecSkillForm.reset();
    this.skillTypes = type;
    this.getSkillList();
    this.display1 = true;
  }
  showEditDialog2(data) {
    this.displayEditPopup2 = true;
  }
  closePanel() {
    this.endorseCardDisplay = false;
  }
  // It is used to display skill rating
  getSkillsRatings() {
    this.apisService.getApi(environment.getRatings).subscribe((res: any) => {
      this.ratings = res;
    });
  }
}
