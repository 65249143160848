import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-loginc',
  templateUrl: './loginc.component.html',
  styleUrls: ['./loginc.component.scss'],
  providers: [DatePipe, MessageService],
})
export class LogincComponent implements OnInit {
  loginForm: FormGroup;
  loginRes: any;
  check: any;
  statusCheck: any;
  display = false;
  loader: boolean;
  displayForgotDialog: boolean;
  forgotpwdForm: FormGroup;
  verificationForm: FormGroup;
  hide = true;
  showIcon: boolean;
  hideIcon = true;
  primaryrole: any;
  show = false;
  show1 = true;
  currenYearFormat: number = new Date().getFullYear();
  resetEmail: any;
  resultData: any;
  isUploading = false;
  isSameSystem: any;
  verification = false;
  emailverfy: any;
  browsName: any;
  autoId: any;
  datatoken: any;
  tokenId: any;
  userid: any;
  actId: any;
  autoIds: any;
  resendres: boolean;
  name: any;
  button: boolean;
  forgotpasswordpage: boolean;
  loginpage = true;
  obj: any;
  passCodes = false;
  selectModestep = true;
  autoID: any;
  verifyshow1 = true;
  verifyshow: boolean;
  show12: boolean;
  disabledd: boolean;
  success1: boolean;
  emailVaue: any;
  proccedshow1 = true;
  proccedshow: boolean;
  proccedbtn = true;
  proccedRes: any;
  verifybtn: boolean;
  forgot2: boolean;
  smsimg: boolean;
  emailimg: boolean;
  stepverify = true;
  userName: any;
  verificationName: string;
  mblNumber: any;
  loginId: any;
  myFunction() {
    this.hide = !this.hide;
    this.hideIcon = false;
    this.showIcon = true;
  }
  myFunction1() {
    this.hide = !this.hide;
    this.hideIcon = true;
    this.showIcon = false;
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    // tslint:disable-next-line: no-conditional-assignment
    if (this.passCodes = true) {
      this.verification = false;
      this.backtologin();
      window.location.reload();
    }
    else {
      this.verification = true;
    }
  }
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private router: Router, private apisService: ApisService, private messageService: MessageService) { }
  ngOnInit() {
    const { detect } = require('detect-browser');
    const browser = detect();
    // handle the case where we don't detect the browser
    if (browser) {
      this.browsName = browser.name;
    }
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.verificationForm = this.fb.group({
      Passcode: ['', [Validators.required]],
      mail: [''],
      email: [''],
    });
    this.forgotpwdForm = this.fb.group({
      emailId: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.com')]]
    });
  }
  getUrl() {
    return 'url(\'https://d2r8nxfsv3v6y3.cloudfront.net/images/loginpage.jpg\')';
  }
  login() {
    if (this.loginForm.invalid) {
      const controls = this.loginForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      this.isUploading = true;
      this.show1 = false;
      this.show = true;
      if (!this.loginForm.controls.email.value) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please Enter UserName.' });
      } else {
        this.statusCheck = this.loginForm.controls.email.value;
      }
      if (!this.loginForm.controls.password.value) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please Enter Password.' });
        this.show1 = true;
        this.show = false;
      } else {
        this.check = '';
        this.check = this.loginForm.controls.password.value;
      }
      const data1 = {
        userId: this.apisService.encryptdata(this.loginForm.controls.email.value),
        password: this.apisService.encryptdata(this.loginForm.controls.password.value),
        browser: this.browsName
      };
      this.emailverfy = data1.userId;
      this.verificationForm.controls.mail.patchValue(this.emailverfy);
      this.button = true;
      // tslint:disable-next-line: deprecation
      this.apisService.postApi(environment.logInData, data1).subscribe(res => {
        this.loginRes = res;
        this.button = false;
        this.show1 = true;
        this.show = false;
        if (this.loginRes.success === false && this.loginRes.isResent !== false || this.loginRes.message === 'User is inactive!') {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.loginRes.message });
          this.button = false;
          this.show1 = true;
          this.show = false;
        }
        else if (this.loginRes.success === false && this.loginRes.isResent !== false || this.loginRes.message === 'Invalid email or password!') {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.loginRes.message });
          this.button = false;
          this.show1 = true;
          this.show = false;
        }
        this.datatoken = this.loginRes.data.token;
        this.tokenId = atob(this.datatoken.split('.')[1]);
        this.loader = false;
        this.isSameSystem = res.isSameSystem;
        if (this.isSameSystem === this.loginRes.success) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.loginRes.message });
          sessionStorage.setItem('loginData', JSON.stringify(this.loginRes));
          sessionStorage.setItem('usrImagePath', this.loginRes.usrImagePath);
          sessionStorage.setItem('loginId', 'true');
          this.router.navigateByUrl('root');
          this.apisService.landingPage();
          this.isUploading = false;
          this.button = false;
        }
        else if (this.loginRes.success === false) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.loginRes.message });
          this.isUploading = false;
          this.button = false;
        }
        else if (this.isSameSystem === false && this.loginRes.success === true && this.loginRes.isResent === false) {
          this.show = false;
          this.show1 = true;
          this.verification = true;
          this.selectModestep = true;
          this.stepverify = true;
          this.passCodes = false;
          this.isUploading = false;
          this.button = false;
        } else if (this.loginRes.success === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.loginRes.message });
          this.isUploading = false;
          this.button = false;
        }
        else {
          setTimeout(() => {
            this.show = false;
          }, 2000);
          this.isUploading = false;
          this.button = false;
        }
        this.userName = JSON.parse(this.tokenId).name;
        this.loginId = JSON.parse(this.tokenId).loginId;
      });
      setTimeout(() => {
      }, 2000);
      this.getUrl();
    }
  }
  registration() {
   this.router.navigate(['/register']);
  }
  navigateToRegisterForm() {
    this.router.navigate(['/registration'], {
      queryParams: {
        vendorRegistrationForm: 'V'
      }
    });
  }
  navigateToCustomerRegisterForm() {
    this.router.navigate(['/registration']);
  }
  navigateToconsultantRegisterForm() {
    this.router.navigate(['/consultantRegistration']);
  }
  forgotPassword() {
    this.forgotpasswordpage = true;
    this.loginpage = false;
    this.forgotpwdForm.get('emailId').reset();
    this.success1 = false;
    this.forgot2 = false;
  }
  backlogin() {
    this.forgotpasswordpage = false;
    this.loginpage = true;
    this.forgotpwdForm.reset();
    this.success1 = false;
    this.forgot2 = false;
  }
  resetPassword() {
    if (this.forgotpwdForm.invalid) {
      const controls = this.forgotpwdForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      this.show1 = false;
      this.show12 = true;
      this.disabledd = true;
      const EmailId =
        this.forgotpwdForm.controls.emailId.value === null ? '' : this.forgotpwdForm.controls.emailId.value;
      // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.resetpassword + EmailId).subscribe((res) => {
        this.resultData = res;
        this.disabledd = false;
        this.show1 = true;
        this.show12 = false;
        if (this.resultData.success === true) {
          this.forgotpasswordpage = false;
          this.success1 = true;
          this.forgot2 = false;
        }
        else {
          this.messageService.add({  key: 'br',  severity: '', life: 3000, detail:  this.resultData.message });
          this.forgot2 = true;
          this.forgotpwdForm.reset();
          this.success1 = false;
        }
      });
      const reqData = {
        emailId: this.forgotpwdForm.get('emailId').value
      };
    }
  }
  selectMode(data) {
    this.emailVaue = data;
    this.proccedbtn = false;
  }
  Procced() {
    this.obj = JSON.parse(this.tokenId);
    this.proccedshow1 = false;
    this.proccedshow = true;
    this.proccedbtn = true;
    if (this.emailVaue === 'sms') {
      this.apisService.getApi(environment.smsUser + this.obj.loginId).subscribe(res1 => {
        this.mblNumber = res1.phone;
        // tslint:disable-next-line: max-line-length
        if (res1.success === true) {
          // tslint:disable-next-line: max-line-length
          this.apisService.getApi(environment.selectmode + this.emailVaue + '/' + res1.phone + '/' + this.browsName + '/' + this.obj.userId).subscribe(res => {
            this.autoID = res.autoId;
            this.proccedRes = res.success;
            this.proccedshow1 = true;
            this.proccedshow = false;
            if (res.success === true) {
              this.passCodes = false;
              this.selectModestep = false;
              this.stepverify = false;
            }
            this.passCodes = true;
            this.stepverify = false;
            if (this.emailVaue === 'email') {
              this.emailimg = true;
              this.verificationName = 'Email';
              this.smsimg = false;
            }
            else {
              this.emailimg = false;
              this.verificationName = 'SMS';
              this.smsimg = true;
            }
          });
        }
        else if (res1.success === false) {
          this.messageService.add({  key: 'br',  severity: '', life: 3000, detail: res1.message });
        }
      });
    }
    else {
      // tslint:disable-next-line:max-line-length
      this.apisService.getApi(environment.selectmode + this.emailVaue + '/' + this.obj.loginId + '/' + this.browsName + '/' + this.obj.userId).subscribe(res => {
        this.autoID = res.autoId;
        this.proccedRes = res.success;
        this.proccedshow1 = true;
        this.proccedshow = false;
        if (res.success === true) {
          this.passCodes = false;
          this.selectModestep = false;
          this.stepverify = false;
        }
        this.passCodes = true;
        this.stepverify = false;
        if (this.emailVaue === 'email') {
          this.emailimg = true;
          this.verificationName = 'Email';
          this.smsimg = false;
        }
        else {
          this.emailimg = false;
          this.verificationName = 'SMS';
          this.smsimg = true;
        }
      });
    }
  }
  resend() {
    this.obj = JSON.parse(this.tokenId);
    this.loader = true;
    // tslint:disable-next-line: max-line-length
    if (this.emailVaue === 'sms') {
      // tslint:disable-next-line: max-line-length
      this.apisService.getApi(environment.resendit + this.obj.userId + '/' + this.autoID + '/sms/' + this.mblNumber + '?browser=' + this.browsName).subscribe(res => {
        this.autoIds = res.autoId;
        this.loader = false;
        if (res.success === true && res.isResent === true) {
          this.verificationForm.controls.Passcode.reset();
        }
        else {
          this.messageService.add({ severity: 'success', detail: this.loginRes.message });
          sessionStorage.setItem('loginData', JSON.stringify(this.loginRes));
          sessionStorage.setItem('usrImagePath', this.loginRes.usrImagePath);
          sessionStorage.setItem('loginId', 'true');
          this.apisService.landingPage();
          this.isUploading = false;
        }
        if (res.isResent === true) {
          this.resendres = res.isResent;
          this.autoIds = res.autoId;
        }
      });
    }
    else{
    // tslint:disable-next-line: max-line-length
    this.apisService.getApi(environment.resendit + this.obj.userId + '/' + this.autoID + '/email/' + this.obj.loginId + '?browser=' + this.browsName).subscribe(res => {
      this.autoIds = res.autoId;
      this.loader = false;
      if (res.success === true && res.isResent === true) {
        this.verificationForm.controls.Passcode.reset();
      }
      else {
        this.messageService.add({ severity: 'success', detail: this.loginRes.message });
        sessionStorage.setItem('loginData', JSON.stringify(this.loginRes));
        sessionStorage.setItem('usrImagePath', this.loginRes.usrImagePath);
        sessionStorage.setItem('loginId', 'true');
        this.apisService.landingPage();
        this.isUploading = false;
      }
      if (res.isResent === true) {
        this.resendres = res.isResent;
        this.autoIds = res.autoId;
      }
    });
  }
  }
  verify() {
    if (this.verificationForm.invalid) {
      const controls = this.verificationForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    } else {
      this.verifyshow1 = false;
      this.verifyshow = true;
      this.hide = true;
      this.verifybtn = true;
      const verifyCode = this.verificationForm.controls.Passcode.value;
      const id = this.resendres === true ? this.autoIds : this.autoID;
      const ID = this.proccedRes === true ? this.autoID : id;
      this.apisService.getApi(environment.verify + ID + '/' + verifyCode).subscribe(res => {
        this.loader = false;
        if (res.success === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
          sessionStorage.setItem('loginData', JSON.stringify(this.loginRes));
          sessionStorage.setItem('usrImagePath', this.loginRes.usrImagePath);
          sessionStorage.setItem('loginId', 'true');
          this.apisService.landingPage();
          this.isUploading = false;
        }
        else if (res.success === false) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
          this.verificationForm.reset();
          this.verifybtn = false;
          this.verifyshow1 = true;
          this.verifyshow = false;
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
        }
        this.verificationForm.reset();
        this.verifyshow1 = true;
        this.verifyshow = false;
        this.verifybtn = false;
      });
    }
  }
  backtologin() {
    const id = this.resendres === true ? this.autoIds : this.autoID;
    if (id === undefined || id === null || id === '')
    {}
    else{
      this.apisService.deleteApi(environment.backlogin + id).subscribe(res => {
        this.loader = false;
        if (res.success === true) {
          this.verification = false;
          this.verificationForm.reset();
          this.loginForm.reset();
          this.router.navigate(['/login']);
        }
        else {
          this.verification = true;
          this.verificationForm.reset();
          this.loginForm.reset();
          this.router.navigate(['/login']);
        }
      });
    }
  }
}
