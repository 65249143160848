import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmpty } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-recruting-manager-requirements-edit',
  templateUrl: './recruting-manager-requirements-edit.component.html',
  styleUrls: ['./recruting-manager-requirements-edit.component.scss'],
  providers: [MessageService, DatePipe],
})
export class RecrutingManagerRequirementsEditComponent implements OnInit {
  editRequirementsForm: any;
  UserId: string;
  name1: any;
  reqId: any;
  titel: any;
  formRoute: any;
  gridStatus: any;
  reqData: any;
  reqPosi: any;
  reqName: any;
  jobCat: any;
  maxrate: any;
  headerName: string;
  rows: any = 10;
  page: any = 1;
  requirement: any;
  pageCount: any;
  minimumDate = new Date();
  submitApproval: boolean;
  dateErr: boolean;
  submittedProfilesGridData: any;
  subProRecords: any;
  table: boolean;
  records: boolean;
  // tslint:disable-next-line:no-inferrable-types
  loader: boolean = false;
  array: any;
  specializationSkillsData: any;
  experienceData: any;
  expLabel: any = [];
  statusList = {};
  consStatusData: any;
  first: any;
  positionData: any = [];
  requiremntId: any;
  minMaxRate: any = [];
  departmentList: any = [];
  postCat: any;
  jobTitleId: any;
  locationList1: any = [];
  acctdetails: any;
  locationList: any = [];
  getexperienceOptions: any;
  billingData: any;
  hireTypeData: any;
  rowData: any;
  idToDelete: any;
  conId: any;
  consultantName: any;
  items: any = [];
  downloadOptions: any = [];
  userDetails: any;
  userAccId: any;
  exprienceList: any = [];
  billingList: any;
  hireTypeList: any;
  display: boolean;
  reqSkillsValue: any;
  filedata: any;
  resumeRes: any;
  updateaction: boolean;
  updateactionBtn: boolean;
  userid: any;
  resumID: any;
  updateactionGrid: any;
  profileID: any;
  reqId1: any;
  consultantRequirementDisplay: boolean;
  billdata: any;
  updateBilForm: any;
  candidateName: any;
  payRate: any;
  markUpData: any;
  errorMsg: boolean;
  payrates: any;
  alertError: boolean;
  approvalRequest: boolean;
  candidatename: any;
  userId: any;
  targetDate: string;
  maximumDate: Date;
  isUploading: boolean;
  loaderForDownload: boolean;
  rowdataid: any;
  iconToggle = {};
  selectedResume: any = [];
  display1: boolean;
  availCounsultRes: any;
  popup: boolean;
  reqCount: any;
  reqStatus: any;
  rejectSubmission: boolean;
  enddata: boolean;
  updatebutton = true;
  preferredskill: any;
  preferredSet: any;
  skillTable = {};
  ownerID: any;
  submittedreqId: any;
  jobTitleData: any = [];
  primarySkills: any = [];
  currencyValue: any;
  currencycode: any[];
  preferencList: any = [];
  currencysymbl: any;
  skillsList: any = [];
  skillset1: any = {};
  skillsList12: any = [];
  selectedPrimaryskills: any = [];
  parentreqId: any;
  createdbyaccid: any;
  otherskills: boolean;
  patchlocationString: string;
  editLocationdata: any;
  reqStatusacc: string;
  cols11: { field: string; header: string; sno: number; }[];
  submittedProfileForm: FormGroup;
  acctDetails: any;
  usrroleid: any;
  roleId: any;
  recived: boolean;
  cols3: { dataKey: string; header: string; }[];
  isUploading1: boolean;
  rowDatagetres: any;

  // tslint:disable-next-line: max-line-length
  constructor(private datepipe: DatePipe, private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private apisService: ApisService, private breadcrumbService: BreadcrumbService, private messageService: MessageService,
  ) {
    this.cols11 = [
      { field: 'vendorName', header: 'Subcontractor Name', sno: 1 },
      { field: 'consultantName', header: 'Consultant Name', sno: 2 },
      { field: 'submittedDate', header: 'Received Date', sno: 3 },
      { field: 'skills', header: 'Skills', sno: 4 },
      { field: 'skills', header: 'Skills', sno: 5 },
      { field: 'profileState', header: 'Status', sno: 6 },
      { field: 'billRate', header: 'Bill Rate', sno: 7 },
      { field: 'Action', header: 'Action', sno: 8 },
    ];
    this.cols3 = [
      { dataKey: 'vendorName', header: 'Subcontractor Name' },
      { dataKey: 'consultantName', header: 'Consultant Name' },
      { dataKey: 'submittedDate', header: 'Received Date' },
      { dataKey: 'skills', header: 'Skills' },
      { dataKey: 'status', header: 'Status' },
      { dataKey: 'billRate', header: 'Bill Rate' },
    ];
    this.reqData = JSON.parse(sessionStorage.getItem('reqData'));
    this.reqId = this.reqData.reqId;
    this.reqPosi = this.reqData.noOfPositions;
    this.reqName = this.reqData.reqName;
    this.jobCat = this.reqData.jobCategory;
    this.maxrate = this.reqData.maxRate;
    this.ownerID = this.reqData.ownerId;
    this.breadcrumbService.setItems([
      { label: 'Requirements', routerLink: '/requirements' },
      { label: 'Requirement Edit', routerLink: '/requirementedit' },
    ]);
  }
  ngOnInit(): void {
    this.submittedProfileForm = this.fb.group({
      vendorName: ['', Validators.pattern('^[a-zA-Z]*$')],
      firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z]*$')]],
      lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z]*$')]],
      ssnNumber: [''],
      skillSet: [''],
      emailId: ['', [Validators.required, Validators.pattern('^[a-zA-Z]*$')]],
      csrStatus: [''],
      skillDetails: [''],
      comments1: ['', Validators.required],
      status: [''],
    });
    this.userDetails = JSON.parse(this.apisService.decodeData());
    this.acctDetails = JSON.parse(this.apisService.decodeData());
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
      this.roleId = this.acctDetails.roles[0].id;
    } else {
      this.roleId = this.usrroleid;
    }
    this.userAccId = this.userDetails.accId;
    this.UserId = sessionStorage.getItem('UserId');
    const routeParams: any = this.route.snapshot.queryParamMap;
    if (Object.keys(routeParams.params).length !== 0) {
      this.name1 = routeParams.params.name;
      this.reqId = routeParams.params.reqId;
      this.titel = routeParams.params.title;
      this.formRoute = routeParams.params.from;
      this.gridStatus = routeParams.params.gStatus;
      this.ownerID = routeParams.params.ownerId;
      this.submittedreqId = routeParams.params.submittedreqId;
      this.parentreqId = routeParams.params.parentreqId;
      this.createdbyaccid = routeParams.params.createdbyaccId;
    } else {
      (this.name1 = this.reqData.name),
        (this.reqId = this.reqData.reqId),
        (this.titel = this.reqData.jobTitle),
        (this.formRoute = this.reqData.from),
        (this.gridStatus = this.reqData.reqStatus);
      (this.ownerID = routeParams.params.ownerId);
    }
    this.headerName = this.formRoute === 'open' ? 'Open Position' : 'Requirement';
    const nameLabel = this.formRoute === 'open' ? 'Open Positions' : 'Requirements';
    const routeUrl = this.formRoute === 'recruiter' ? '/recruiter-requirement' : '/requirements';
    this.targetDate = this.route.snapshot.queryParamMap.get('tdate');
    this.reqStatusacc = this.route.snapshot.queryParamMap.get('gStatus');
    this.reqStatus = this.route.snapshot.queryParamMap.get('reqStatus');
    if (this.reqStatus === 'C') {
      this.updatebutton = true;
    }
    else {
      this.updatebutton = false;
    }
    this.editRequirementsForm = this.fb.group({
      reqName: ['', Validators.required],
      jobRole: [''],
      requirementType: ['', Validators.required],
      requirementStartDate: ['', Validators.required],
      requirementEndDate: ['', Validators.required],
      requirementNoofPositions: ['', Validators.required],
      requirementReqExp: ['', Validators.required],
      requirementWorkCenterLocation: ['', Validators.required],
      requirementStatus: ['', Validators.required],
      requirementMinRate: [''],
      requirementMaxRate: [''],
      requirementCategory: [''],
      requirementDescription: ['', Validators.required],
      requirementResponsibilities: ['', Validators.required],
      requirementQualification: ['', Validators.required],
      requirementSkillSet: ['', Validators.required],
      preferredSkills: ['', [Validators.required]],
      requirementComments: [''],
      positionCategory: [''],
      billingtype: ['', Validators.required],
      hrsperWeek: [''],
      duration: [''],
      department: [],
      experience: [''],
      approval: [''],
      otherskills: ['']
    });
    this.acctdetails = JSON.parse(this.apisService.decodeData()); // this.acctdetails.accId
    this.preferencList = this.acctdetails.preferencesList;
    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }
    this.editRequirementsForm.disable();
    this.getConStatus();
    this.getexperience();
    this.getDepartments();
    this.getPositionCatagory();
    this.getWorkLocation();
    this.getStatus();
    this.getBillingDropdown();
    this.getHireTypeData();
    this.reqSkillSet();
    this.getdSkillset();
    this.editReqData();
    if (this.reqStatusacc === 'R') {
      this.recived = true;
      this.gridTable();
      this.getConStatus();
    }
    else {
      this.recived = false;
    }
  }
  backNavigate() {
    this.router.navigate(['/requirements']);
  }
  getConStatus() {
    this.apisService
      .getApi(environment.lookupsData + 'RPLPS')
      .subscribe((res) => {
        this.consStatusData = res.data;
        this.consStatusData.forEach((element) => {
          this.statusList[element.code] = element.label;
        });
      });
  }
  getexperience() {
    this.apisService.getApi(environment.lookupsData + 'RELYE').subscribe((res: any) => {
      this.exprienceList = res.data;
      // console.log(" this.exprienceList ", this.exprienceList );
    });
  }
  getDepartments() {
    const getdropdown = this.parentreqId === '0' ? this.createdbyaccid : this.ownerID;
    this.apisService.getApi(environment.departments + '/' + getdropdown).subscribe((res) => {
      this.departmentList = res.data;
      this.editRequirementsForm.controls.department.patchValue(
        this.departmentList.filter(
          (x) => x.id === this.reqData.departmentId
        )[0]
      );
    });
  }
  getPositionCatagory() {
    const getdropdown = this.parentreqId === '0' ? this.createdbyaccid : this.ownerID;
    this.apisService.getApi(environment.positionCat + '/' + getdropdown).subscribe((res) => {
      this.positionData = res.data;
      // console.log(" this.positionData", this.positionData);
      this.positionData.forEach((element) => {
        this.skillTable[element.code] = element.label;
      });
    });
  }
  getWorkLocation() {
    const getdropdown = this.parentreqId === '0' ? this.createdbyaccid : this.ownerID;
    this.apisService.getApi(environment.location + '/' + getdropdown).subscribe((res) => {
      this.locationList = res.data;
      // console.log(" this.locationList", this.locationList);
    });
  }
  getStatus() {
    this.apisService.getApi(environment.lookupsData + 'RELS').subscribe((res) => {
      this.statusList = res.data;
    });
  }
  getBillingDropdown() {
    this.apisService.getApi(environment.lookupsData + 'RELBT').subscribe((res) => {
      this.billingList = res.data;
    });
  }
  getHireTypeData() {
    this.apisService.getApi(environment.lookupsData + 'RELHT').subscribe((res) => {
      this.hireTypeList = res.data;
      // console.log("  this.hireTypeList ",  this.hireTypeList );
    });
  }
  reqSkillSet() {
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.specializationSkillsData = res.data;
    });
  }
  getdSkillset() {
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.skillsList = res.data;
      this.skillsList.forEach((element) => {
        this.skillset1[element.code] = element.label;
      });
    });
  }
  editReqData() {
    this.loader = true;
    this.apisService.getApi(environment.requirementEdit + '/' + this.submittedreqId).subscribe((res) => {
      this.reqData = res.data;
      // console.log("this.reqData",this.reqData);
      this.getPositionCategoryBindDataValues(this.reqData);
      this.typeData(this.reqData.hireType);
      this.editRequirementsForm.patchValue({
        reqName: this.reqData.name,
        requirementMinRate: this.reqData.minRate,
        requirementMaxRate: this.reqData.maxRate,
        requirementNoofPositions: this.reqData.noOfPositions,
        requirementWorkCenterLocation: this.reqData.location,
        requirementType: this.reqData.hireType,
        requirementStartDate: this.reqData.plannedStartDate,
        requirementEndDate: this.reqData.plannedEndDate,
        billingtype: this.reqData.billingType,
        duration: this.reqData.duration,
        hrsperWeek: this.reqData.hrsPerWeek,
        requirementStatus: this.reqData.status,
        requirementDescription: this.reqData.description,
        requirementResponsibilities: this.reqData.responsibilities,
        requirementQualification: this.reqData.qualification,
        requirementComments: this.reqData.comments,
        approval: this.reqData.empApprovalReqFlag,
        // otherskills: this.reqData.otherSkills
      });
      const selectedPrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.skillsList.length; i++) {
        const array = this.reqData.prefferedSkills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.skillsList[i].code) === array[j]) {
            selectedPrimarySkills.push(this.skillsList[i]);
          }
        }
      }
      const preferredSkills = selectedPrimarySkills;
      preferredSkills.forEach((element) => {
        this.skillsList12.push(element.label);
      });
      const selectedPrePrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.skillsList.length; i++) {
        const array = this.reqData.skills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.skillsList[i].code) === array[j]) {
            selectedPrePrimarySkills.push(this.skillsList[i]);
          }
        }
      }
      const primarySkills = selectedPrePrimarySkills;
      primarySkills.forEach((element) => {
        this.primarySkills.push(element.label);
      });

      this.editRequirementsForm.controls.requirementSkillSet.patchValue(selectedPrePrimarySkills);
      this.editRequirementsForm.controls.preferredSkills.patchValue(preferredSkills);
      selectedPrePrimarySkills.forEach((element) => {
        this.selectedPrimaryskills.push(element.label);
      });
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < selectedPrePrimarySkills.length; i++) {
        if (selectedPrePrimarySkills[i].code === '25') {
          this.otherskills = true;
          this.editRequirementsForm.controls.otherskills.patchValue(this.reqData.otherSkills);

        }
        else {
          this.onBlur('');
        }
      }

      this.editRequirementsForm.controls.experience.patchValue(this.exprienceList.filter((x) => x.code === this.reqData.experience)[0]);
      // tslint:disable-next-line: max-line-length
      this.editRequirementsForm.controls.requirementWorkCenterLocation.patchValue(this.locationList.filter((x) => x.locId === this.reqData.location)[0]);

      this.editLocationdata = this.locationList.filter((x) => x.locId === this.reqData.location)[0];
      this.patchlocationString = this.editLocationdata?.name + '\n' + this.editLocationdata?.address1
        + ',' + this.editLocationdata?.city + ',' + this.editLocationdata?.state + ',' + this.editLocationdata?.country;
      // tslint:disable-next-line: max-line-length
      this.editRequirementsForm.controls.department.patchValue(this.departmentList.filter((x) => x.id === this.reqData.departmentId)[0]);
      // tslint:disable-next-line: max-line-length
      this.editRequirementsForm.controls.positionCategory.patchValue(this.positionData.filter((x) => x.id === this.reqData.category)[0]);

    });
    this.loader = false;
  }
  submitProfileGrid() {
    this.loader = true;
    const row = this.rows;
    const page = this.page;
    // tslint:disable-next-line:max-line-length
    this.apisService.getApi(environment.avaliableSubmitedPro + page + '/' + row + '/' + this.reqId + '?consEmail=&fName=&lName=&exp=').subscribe((res: any) => {
      this.submittedProfilesGridData = res.data.records;
      this.subProRecords = res.data.count;
      this.loader = false;
      if (this.subProRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  conStatusTable(data) {
    const conStatus = this.consStatusData.find((x) => x.code === data).label;
    return conStatus;
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.submitProfileGrid();
  }


  getToolTipContent(tootipdata) {
    return tootipdata;
  }

  getPositionCategoryBindDataValues(data) {
    const acctId = data.accId;
    const posnCat = data.category;
    this.requiremntId = data.reqId;
    const getdropdown = this.parentreqId === '0' ? this.createdbyaccid : this.ownerID;
    this.apisService.getApi(environment.maxRate + getdropdown + '/' + posnCat).subscribe((res) => {
      this.minMaxRate = res.data;
      this.editRequirementsForm.controls.jobRole.patchValue(
        this.minMaxRate.filter(x => x.title === data.jobTitle.title)[0]
      );
    });
  }
  typeData(event) {
    if (event === 'C' || event === 'H') {
      this.enddata = true;
      this.editRequirementsForm.get('requirementEndDate').markAsTouched();
      this.editRequirementsForm.get('requirementEndDate').enable();
    } else {
      this.editRequirementsForm.get('requirementEndDate').disable();
      this.enddata = false;
    }
  }

  getMinrate(event) {
    const availbleRate = this.jobTitleData.find(x => x.id === event);
    this.editRequirementsForm.controls.minRate.patchValue(availbleRate.minrate);
    this.editRequirementsForm.controls.maxRate.patchValue(availbleRate.maxrate);
  }
  reqSkills(data) {
    this.display = true;
    this.reqSkillsValue = data;
  }

  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
  saveLocation(ev) {
    const data = ev.value;
    this.patchlocationString = data.name + '\n' + data.address1 + ',' + data.city + ',' + data.state + ',' + data.country;
  }
  setRequired() {
    return [Validators.required, Validators.maxLength(300)];
  }
  onBlur(data): void {
    if (data === '') {
      this.editRequirementsForm.controls.otherskills.setValidators(this.setRequired());
    }
  }
  reset() {
    this.submittedProfileForm.reset();
    this.gridTable();
  }
  gridTable() {
    this.loader = true;
    const searchstatus =
      // tslint:disable-next-line:max-line-length
      this.submittedProfileForm.controls.status.value === null || this.submittedProfileForm.controls.status.value === '' ? '' : this.submittedProfileForm.controls.status.value === undefined ? '' : this.submittedProfileForm.controls.status.value.code;
    // tslint:disable-next-line:max-line-length
    const searchEmail = this.submittedProfileForm.controls.emailId.value === null || this.submittedProfileForm.controls.emailId.value === '' ? '' : this.submittedProfileForm.controls.emailId.value === undefined ? '' : this.submittedProfileForm.controls.emailId.value;
    const firstName = this.submittedProfileForm.controls.firstName.value === null || this.submittedProfileForm.controls.firstName.value === '' ? '' : this.submittedProfileForm.controls.firstName.value === undefined ? '' : this.submittedProfileForm.controls.firstName.value;
    // tslint:disable-next-line:max-line-length
    const lastname = this.submittedProfileForm.controls.lastName.value === null || this.submittedProfileForm.controls.lastName.value === '' ? '' : this.submittedProfileForm.controls.lastName.value === undefined ? '' : this.submittedProfileForm.controls.lastName.value;
    const search1 = this.page + '/' + this.rows + '/' + this.roleId + '?reqId=' + this.submittedreqId;
    const email = '&consEmail=' + searchEmail;
    const status = '&status=' + searchstatus;
    const fname = '&fName=' + firstName;
    const lname = '&lName=' + lastname;
    this.apisService.getApi(
      environment.vendorreceivedprofile + search1 + email + fname + lname + status)
      .subscribe((res: any) => {
        this.submittedProfilesGridData = res.data.records;
        this.loader = false;
        this.subProRecords = res.data.count;
        if (this.subProRecords !== 0) {
          this.table = true;
          this.records = false;
        } else {
          this.table = false;
          this.records = true;
        }
      });
  }
  getSkillSetTable(data) {
    this.array = data;
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.specializationSkillsData.length; i++) {
      // tslint:disable-next-line: prefer-for-of
      // tslint:disable-next-line:prefer-for-of
      if (data === null || data === undefined || data === '' || data === isEmpty()) { } else {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.array.length; j++) {
          if (Number(this.specializationSkillsData[i].code) === this.array[j]) {
            selectedPrePrimarySkills3.push(
              this.specializationSkillsData[i].label
            );
          } else {
          }
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  reqSkills1(data) {
    this.display = true;
    this.reqSkillsValue = data;
  }
  downLoadsToggle() {
    this.downloadOptions = [
      { label: 'Download XLSX', icon: 'pi pi-download', command: (event, i) => { this.exportExcel(); }, },
      { label: 'Download PDF', icon: 'pi pi-download', command: (event, i) => { this.exportPdf(); }, },
    ];
  }
  exportExcel() {
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    ws['!cols'] = [ {width : 10}, {width : 20}  , {width : 20} , {width : 10}, {width : 10}, {width : 10}, {width : 10}, {hidden : true} ];
    ws['!rows'] = [{hpt : 30}, {hpt : 30}, ];
    ws['!cols'][3] = { hidden: true };
    ws['!cols'][7] = { hidden: true };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ExcelSheet.xlsx');
  }
  exportPdf() {
    this.submittedProfilesGridData.forEach((element) => {
      element.status = this.statusList[element.profileState];
      element.skills = this.getSkillSetTable(element.skills);
    });
    const doc = new jsPDF('p', 'pt');
    autoTable(doc, {
      headStyles: { fillColor: '#2bb9d5', cellPadding: 5   },
      margin: { left: 17},
      columns: this.cols3,
      body: this.submittedProfilesGridData,
      didDrawPage: (dataArg) => {
        doc.text('Received Candidates', dataArg.settings.margin.left, 30);
      }
    });
    doc.save('Received-Candidates.pdf');
  }
  toggle(menu, event, rowData) {
    this.rowDatagetres = rowData;
    this.items = [
      { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
    ];
  }
  downloadFileapi() {
    this.filedata = this.rowDatagetres.resumeId;
    this.isUploading1 = true;
    this.apisService.getApi(environment.downloadedattachment + '/' + this.filedata).subscribe((res) => {
      this.resumeRes = res;
      this.isUploading1 = false;
      window.location.assign(this.resumeRes.downloadUrl);
    });
  }
}
