<p-toast position="bottom-right" key="br" ></p-toast>
<p-card>
    <form [formGroup]="requirementsForm">
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Job Id</label>
                        <input id="input" type="text" maxlength="5" pKeyFilter="int" formControlName="requirementsJobId"
                            autocomplete="off" pInputText placeholder="Job Id">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Position Title</label>
                        <input id="input" type="text" maxlength=30 formControlName="requirementsJobTitle" pInputText
                            placeholder="Position Title" autocomplete="off">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Status</label>
                        <p-dropdown [options]="statusDD" formControlName="requirementsStatus"
                            placeholder="Select Status" optionLabel="label" [showClear]="true">
                        </p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Start Date </label>
                        <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="requirementsStartDate"
                            placeholder="MM-DD-YYYY" [showIcon]="true" [showButtonBar]="true">
                        </p-calendar>
                        <div *ngIf="errThrow">
                            <span class="mandatoryColor">{{errMsg}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">End Date </label>
                        <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="requirementsEndDate"
                            placeholder="MM-DD-YYYY" [showIcon]="true" [showButtonBar]="true"></p-calendar>
                        <div *ngIf="errThrow2">
                            <span class="mandatoryColor">{{errMsg2}}</span>
                        </div>
                    </div>
                </div>
                <div class="btnsCSS1 btnalignment">
                    <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" 
                        class="addhBtn"></button>
                    <button pButton type="submit" icon='pi pi-search' label="Search" (click)="searchData()"
                        class="addhBtn"></button>
                </div>
    </form>
</p-card><br>
<p-card class="pgpos">
    <div *ngIf="table">
        <p-table [columns]="cols" [value]="griddata" [autoLayout]="true" selectionMode="single" dataKey="edit"
        [responsive]="true" sortMode="multiple" [rowsPerPageOptions]="[10,20,30]" sortMode="multiple">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th id="gridHeader" *ngFor="let col of columns let i=index" [ngStyle]="{'width': col.width }"
                (click)="iconToggle[i] = !iconToggle[i]" [pSortableColumn]="col.field">
                    <span> {{col.header}} </span>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
            <tr class="pointer">
                    <td class="textCenter" (click)="requirementRoute(data)">{{data.reqId}}</td>
                    <td  class="textCenter skillDesc" (click)="requirementRoute(data)"
                    [pTooltip]="getToolTipContent(data.reqName)" tooltipPosition="top"
                        >{{data.reqName}}</td>
                        
                   
                    <td class="textCenter" (click)="requirementRoute(data)">{{data.noOfPositions}}</td>
                    <td class="pointer">
                       <a (click)="op.toggle($event)" (click)="reqSkills1(getSkillSetTable(data.requiredSkills))"
                *ngFor="let data1 of getSkillSetTable(data.requiredSkills); index as i;">
                <p-badge class="badgespace newsty"  *ngIf='i<1' [value]="data1"></p-badge>
                <p-badge  *ngIf='i===1' [value]="'+'+(data.requiredSkills.length-1)+' more'"></p-badge>
              </a>
                    </td>
                    <td class="textCenter" (click)="requirementRoute(data)">{{data.postedDate}}</td>
                    <td class="textCenter" (click)="requirementRoute(data)"><span class="ui-column-title"></span>
                        <p-badge [value]="statuTable[data.reqStatus]" [ngClass]="data.reqStatus ==='C'?'blue': data.reqStatus === 'R' ?'green':data.reqStatus === 'J'? 'red':data.reqStatus === 'P'?'evalution':'green'">
                        </p-badge>
                    </td>
                    <td class="textCenter skillDesc" (click)="requirementRoute(data)"
                   
                    [pTooltip]="getToolTipContent(data.createdByName)" tooltipPosition="top">{{data.createdByName}}</td>
                    <td class="textCenter"><a>
                            <em
                                (click)="downloadActionPopup.toggle($event); toggle($event,data, rowData)"
                                class="pi pi-ellipsis-v"></em>
                        </a></td>
                </tr>
            </ng-template>
        </p-table>

        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
              
                <h3 class="norecorddata">Total records : {{this.griddCount}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator [rows]="10" [totalRecords]="griddCount" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>

    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any requirements</em><br>
            </div>
    </div>
    </p-card>
    <p-dialog class="dialogBox" header="Approve/Reject Requirement" [(visible)]="approveOrReject" [draggable]="false"
        [modal]="true" [resizable]="false" [style]="{width: '25%' }" [baseZIndex]="1">
        <form [formGroup]="approveOrRejectForm">

            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <p>Do you want to approve the requirement?</p>
                </div>
            </div>
            <div class="p-grid p-fluid ">
                <div class="p-col-6 p-md-6 p-sm-12">
                    <p-radioButton name="approveOrReject" value="Approved" label="Yes" formControlName="approveOrReject" class="downloadspac"
                        inputId="Yes" (click)="approveRequirement()"></p-radioButton>
                    <p-radioButton name="approveOrReject" value="Rejected" label="No" formControlName="approveOrReject"
                        inputId="No" (click)="rejectRequirement()"></p-radioButton>
                </div>
            </div><br>
            <div class="p-grid p-fluid ">
                <div class="p-col-8 p-md-12 p-sm-12" *ngIf="rejectForComments">
                    <label class="inputfields">Reason for rejection<span class="mandatoryColor">*</span></label>
                    <textarea autocomplete="off" pInputTextarea [rows]="2" [cols]="30" placeholder="Comments"
                        formControlName="comments" maxlength=300></textarea>
                    <span class="validationtest" *ngIf="approveOrRejectForm.get('comments').invalid && approveOrRejectForm.get('comments').touched &&
    this.approveOrRejectForm.get('comments').errors.required">Please enter reason for rejection</span>
                </div>
            </div>
            <div class="btnsCSS1">
                <button pButton type="button" icon='pi pi-times' label="Cancel" class="approvebtn"
                    (click)="cancel()"></button>

                <button pButton type="button" [disabled]="disable" icon='pi pi-check-circle' label="Submit"  class="approvebtn"
                    (click)="submitForApproveOrReject()"></button><br>
            </div>
        </form>
    </p-dialog>
    <p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="items"></p-menu>
</p-card>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight " >
                <div class="laodersize" >
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif" class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>

<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '25%' }">
    <label class="inputfields">Skill Details</label>
    <ng-template pTemplate>

        <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12 bordersty">
      
              <p class="skpos">
            
                <p-badge class="badgespace badgesp" *ngFor="let data2 of reqSkillsValue; index as i" [value]="data2" [ngClass]="i%2===0? 'newsty' : ''">
                </p-badge>
               
             
              </p>
      
            </div>
          </div>

    </ng-template>
</p-overlayPanel>
<p-dialog class="dialogBox" [(visible)]="displayModal"  [draggable]="false" [modal]="true" 
[style]="{width: '30%',height:'60%'}">
<p-header>Requirement Activity 
  <span class="Activity">Job Id : <a>{{this.histroy1}}</a></span>
</p-header>
<div class="Activitycolor"> Please find the below information</div><br>
<div class="p-grid p-fluid">
    <div class="p-col-12 p-md-12 p-sm-12">
     <p-timeline [value]="histroy" align="alternate">
        <ng-template pTemplate="content" let-event>
            <p-card >
                <p class="aligncenter"> {{event.activity}}
                    <br>
                    <span class="Activitycolor">By {{event.activityBy}}</span>
                </p>
            </p-card>
            <br>
        </ng-template>
        
        <ng-template pTemplate="opposite" let-event><span class="Activitycolor">{{event.activityDate | date:'MM-dd-yyyy'}}</span>

        </ng-template>
    </p-timeline>
</div>
</div>
</p-dialog>