<div class="layout-profile" [ngClass]="{'layout-profile-active':appMain.inlineMenuActive}">
    <a (click)="onClick($event)" class="layout-profile-button">
        <img   src="https://d2r8nxfsv3v6y3.cloudfront.net/images/female.png" alt="" />
        <div class="layout-profile-userinfo">
            <span class="layout-profile-name">Arlene Welch</span>
            <span class="layout-profile-role">Design Ops</span>
        </div>
        <em class="layout-profile-icon pi pi-angle-down"></em>
    </a>
    <ul class="layout-profile-menu"
        [@menu]="appMain.isSlim() ? appMain.inlineMenuActive ? 'visible' : 'hidden': appMain.inlineMenuActive ? 'visibleAnimated':'hiddenAnimated'">
        <li role="menuitem">
            <a [attr.tabindex]="!appMain.inlineMenuActive  ? '-1' : null">
                <em class="pi pi-user"></em>
                <span>Profile</span>
            </a>
        </li>
        <li role="menuitem">
            <a [attr.tabindex]="!appMain.inlineMenuActive  ? '-1' : null">
                <em class="pi pi-cog"></em>
                <span>Settings</span>
            </a>
        </li>
        <li role="menuitem">
            <a [attr.tabindex]="!appMain.inlineMenuActive  ? '-1' : null">
                <em class="pi pi-envelope"></em>
                <span>Messages</span>
            </a>
        </li>
        <li role="menuitem">
            <a [attr.tabindex]="!appMain.inlineMenuActive  ? '-1' : null">
                <em class="pi pi-bell"></em>
                <span>Notifications</span>
            </a>
        </li>
    </ul>
</div>