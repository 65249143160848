<!-- Toast Message for Displaying error & Success -->
<p-toast position="bottom-right" key="br" ></p-toast>
<form [formGroup]="vendorTiersForm">
  <p-card>
    <p-panel [toggleable]="false">
      <p-header>
        <label>
          {{tireName}} vendors
        </label>
        <em class="pi pi-arrow-left panelBackRoute" 
          (click)="goto()"></em>
      </p-header>
      <div *ngIf="table">
        <!--It is used for displaying the Vendor Tier  table-->
        <p-table [columns]="tableHeader" [value]="vendorSaveData" selectionMode="single" dataKey="edit"
          [responsive]="true" sortMode="multiple" [autoLayout]="true" [scrollable]="true">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th id="gridHeader" *ngFor="let col of columns; let i=index" (click)="iconToggle[i] = !iconToggle[i]"
                [pSortableColumn]="col.field">
                {{col.header}}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-data>
            <tr>
              <td class="textCenter"><span class="ui-column-title"></span><a (click)="edit(data)"
                  style="cursor: pointer;" class="pi pi-pencil"></a>
              </td>
              <td class="textCenter" pTooltip="{{data.vName}}" tooltipPosition="top"><span
                  class="ui-column-title"></span>{{data.vName}}</td>
              <td class="textCenter" pTooltip="{{data.tierName}}" tooltipPosition="top"><span
                  class="ui-column-title"></span>{{data.tierName}}</td>
              <td class="textCenter"><span class="ui-column-title"></span>{{data.discount}}</td>
              <td class="textCenter"><span class="ui-column-title"></span>{{data.daysDelay}}</td>
              <td class="textCenter"><span class="ui-column-title"></span>
                {{getStatusTable(data.status)}}
              </td>

            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr *ngIf="vendorSaveData?.length==0" style="text-align: center;">
              <td colspan="6" style="font-size:16px;" class="mandatoryColor">
                No Records
              </td>
            </tr>
          </ng-template>

        </p-table>
        <div class="p-grid ui-fluid">
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
            <h3 class="norecorddata">Total records : {{this.totalrecords}}</h3>
          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-4" style="margin-left: -7%;">
            <p-paginator [rows]="10" [totalRecords]="totalrecords" [rowsPerPageOptions]="[10,20,30]"></p-paginator>
          </div>
        </div>
      </div>
      <p-card *ngIf="records">
        <div style="height: 360px;">
          <div *ngIf="records" style="text-align: center;">
            <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
            <br><em>Sorry, we are not able to find any vendors</em><br>
          </div>
        </div>
      </p-card>
    </p-panel>
    <!--In this dialogbox is used to add the Vendor Tiers---->
    <p-dialog class="dialogBox" header="Move Associate Vendor To Active Vendor" [(visible)]="display" [modal]="true"
      [style]="{width: '40%'}" [baseZIndex]="1" [draggable]="false" [resizable]="false">
      <form [formGroup]='vendorAddForm'>
        <div class="p-grid ui-fluid">
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">

            <label class="inputfields"><span class="mandatoryColor">*</span></label>
            <p-multiSelect [options]="vendorNamelist" [showToggleAll]="false" formControlName="vendorName1"
              placeholder="Type To search Staffing Mode" [panelStyle]="{minWidth:'12em'}" optionLabel="name"
              [filter]='true'></p-multiSelect>
            <span class="mandatoryColor"
              *ngIf="vendorAddForm.controls.vendorName1.touched && vendorAddForm.controls.vendorName1.invalid && vendorAddForm.controls.vendorName1.errors.required">
              Please select vendor name</span>

          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
            <label class="inputfields">Type Of Tier<span class="mandatoryColor">*</span></label>

            <p-dropdown [options]="vendorTierList" placeholder="Select Type Of Tier" (onChange)='getVendorTierExist($event)'
              formControlName='vendorAddTier' optionLabel="tierName" [showClear]="true">
            </p-dropdown>
            <span *ngIf='display1' class="mandatoryColor">This tier not added</span>
            <span class="mandatoryColor"
              *ngIf="vendorAddForm.controls.vendorAddTier.touched && vendorAddForm.controls.vendorAddTier.invalid && vendorAddForm.controls.vendorAddTier.errors.required">
              Please select type of tier</span>
          </div>

        </div>
        <div class="p-grid ui-fluid">
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
            <label class="inputfields">Status</label>
            <p-dropdown [options]="status1" formControlName="status" placeholder="Select Status" optionLabel="name"
              [showClear]="true"></p-dropdown>
          </div>
              <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Days Delay</label>
                <input autocomplete="off" id="input" type="text" size="30" maxlength="5" pInputText
                  placeholder="No.Of Days" disabled="true" formControlName="days">
              </div>
        </div>
        <div class="p-grid ui-fluid">
              <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Discount(%)</label>
                <input autocomplete="off" id="input" type="text" size="30" pInputText maxlength="9"
                  placeholder="Discount(%)" disabled="true" formControlName="discount">
              </div>
            </div>
        <div class="btnsCSS1">
          <button pButton type="button" icon='pi pi-save' class="addhBtn" [disabled]='display1' label="Save"
            (click)="addSave()"></button>
        </div><br><br>
      </form>
    </p-dialog>
    <!--In this dialogbox is used to update the Vendor Tiers---->
    <p-dialog class="dialogBox" header="Update Vendor Tiers" [(visible)]="display2" [modal]="true"
      [style]="{width: '40%'}" [baseZIndex]="1" [draggable]="false" [resizable]="false">
      <form [formGroup]='vendorEditForm'>
        <p class="mandatoryColor">{{this.message}}</p>
        <div class="p-grid ui-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
            <label class="inputfields">Vendor Name</label>
            <input id="input" type="text" pInputText placeholder="Enter First Name" maxlength="40" disabled="true"
              autocomplete="off" formControlName="vendorEditName" readonly>
          </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
            <label class="inputfields">Type Of Tier </label>
            <p-dropdown [options]="gridData" placeholder="Select Type Of Tier" (onChange)='getVendorTier($event.value)'
              formControlName='vendorEditTier' optionLabel="tierName" [showClear]="true">
            </p-dropdown>
            <span *ngIf='tierdata' class="mandatoryColor">This tier Inactive</span>
          </div>
        </div>
        <div class="p-grid ui-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
            <label class="inputfields">Status<span class="mandatoryColor">*</span></label>
            <p-dropdown [options]="status1" formControlName="vendorEditsatutsTier" placeholder="Select Status"
              optionLabel="name" [showClear]="true">
            </p-dropdown>
            <span class="mandatoryColor"
              *ngIf="vendorEditForm.controls.vendorEditsatutsTier.touched && vendorEditForm.controls.vendorEditsatutsTier.invalid && vendorEditForm.controls.vendorEditsatutsTier.errors.required">
              Please select status</span>
          </div>
            <div class="p-grid ui-fluid">
              <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Days Delay</label>
                <input autocomplete="off" id="input" type="text" disabled="true" size="30" maxlength="3" pInputText
                  placeholder="No.Of Days" readonly formControlName="editDays">
              </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Discount(%)</label>
                <input autocomplete="off" id="input" type="text" size="30" maxlength="3" pInputText disabled="true"
                  placeholder="Discount(%)" readonly formControlName="editDiscount">
              </div>
            </div>
        </div>
        <div class="btnsCSS1">
          <button pButton type="button" class="addhBtn" [disabled]="buttonDisable" icon='pi pi-save' label="Update"
            (click)="Update()"></button>
        </div><br><br>
      </form>
    </p-dialog>
  </p-card>
</form>

<div *ngIf="!loading; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center" style="height:520px">
        <div style="position: absolute;left:50%;top:50%;transform:translate(-50%,-50%)">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif" style="height:8em">
        </div>
      </div>
    </div>
  </div>
</ng-template>