<p-toast position="bottom-right" key="br" ></p-toast>
<form [formGroup]="tierdefinitionform">
  <!-- Panel for Tiering Definition form -->
  <p-panel header="Tiering Definition " [toggleable]="false" [style]="{'margin-bottom':'20px'}">
    <div class="p-grid ui-fluid">
      <div class="p-col-8 p-md-8 p-sm-12"></div>
      <div class="p-col-4 p-md-4 p-sm-12">
        <button pButton type="button" icon='pi pi-plus' style="width: 120px;float: right;" label="Add"
          (click)="addData('null', 'null')" [disabled]='buttonHandle'></button>
      </div>
      <!---Dialog box  for Add or Update Tier Definition---->
      <p-dialog class="dialogBox" header="{{tierHeader}}" [(visible)]="display" [modal]="true" [style]="{width: '30%'}"
        [baseZIndex]="1" [draggable]="false" [resizable]="false">
        <p-toast></p-toast>
        <div class="p-grid ui-fluid p-justify-center">
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 invalidMandatory">
            <label class="inputfields">Tiers<span class="mandatoryColor">*</span></label>
            <p-dropdown [options]="tier" formControlName="tiers" placeholder="Select Tiers" optionLabel="vendorType"
              (onChange)="tierExistorNot($event)" [showClear]="true"></p-dropdown>
            <span *ngIf='displaydata1' class='mandatoryColor'> This tier already added</span>
            <div>
              <span class="mandatoryColor"
                *ngIf="tierdefinitionform.controls.tiers.touched && tierdefinitionform.controls.tiers.invalid && tierdefinitionform.controls.tiers.errors.required">
                Please select tiers</span>
            </div>
          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <label class="inputfields">Discount(%)<span class="mandatoryColor">*</span></label>
            <input autocomplete="off" type="text" pInputText pKeyFilter="num" maxlength='9' formControlName="discount"
              placeholder="Discount">
            <div>
              <span class="mandatoryColor"
                *ngIf="tierdefinitionform.controls.discount.touched && tierdefinitionform.controls.discount.invalid && tierdefinitionform.controls.discount.errors.required">
                Please enter discount</span>
            </div>
          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <label class="inputfields">Days Delay<span class="mandatoryColor">*</span></label>
            <input autocomplete="off" type="text" pInputText pKeyFilter="num" maxlength='9' formControlName="days"
              placeholder="No.Of Days">
            <div>
              <span class="mandatoryColor"
                *ngIf="tierdefinitionform.controls.days.touched && tierdefinitionform.controls.days.invalid && tierdefinitionform.controls.days.errors.required">
                Please enter days delay</span>
            </div>
          </div>

        </div>
        <div class="p-grid ui-fluid p-justify-center" *ngIf="showStatusDropDown">
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <label class="inputfields">Status<span class="mandatoryColor">*</span></label>
            <p-dropdown [options]="stausData" formControlName="status" placeholder="Select Status" optionLabel="label"
              [showClear]="true"></p-dropdown>
          </div>
        </div><br>
        <div class="btnsCSS1" *ngIf="addBtn">
          <button pButton type="button" icon='pi pi-plus' [disabled]='displaydata1' class="addhBtn" label="Add" style="margin-right: 6px;"
            (click)="addTiersData()"></button>
          <button pButton type="button" icon='pi pi-refresh' [disabled]='displaydata1' class="addhBtn" label="Reset"
            (click)="addTiersDataReset()"></button><br>
        </div>
        <div class="btnsCSS1" *ngIf="updateBtn">
          <button pButton type="button" icon='pi pi-save' class="addhBtn" label="Update" [disabled]="disable"
            (click)="updateTiersData()"></button>
        </div><br><br>
      </p-dialog>
    </div>
    <!--It is used for displaying the Tiering Definition table-->
    <p-table [columns]="cols" [value]="gridData" [autoLayout]="true" [scrollable]="true" selectionMode="single"
      dataKey="edit" [responsive]="true" sortMode="multiple">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th id="gridHeader" *ngFor="let col of columns; let i=index" (click)="iconToggle[i] = !iconToggle[i]"
            [pSortableColumn]="col.field">
            {{col.header}}
           </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>

          <td class="textCenter"><span class="ui-column-title">Tier</span>{{data.tierName}}</td>
          <td class="textCenter"><span class="ui-column-title">Discount(%)</span>{{data.discount}}</td>
          <td class="textCenter"><span class="ui-column-title">Days Delay</span>{{data.daysDelay}}</td>
          <td class="textCenter"><span class="ui=column-title"></span>
            {{getStatusTable(data.status)}}
          </td>
          <td class="textCenter"><span class="ui-column-title">Vendor Count</span>{{data.vendorCount}}</td>
          <td class="textCenter"><span class="ui-column-title">Edit</span>
            <a style="cursor: pointer;" class="pi pi-plus" (click)="addRoute(data)"></a>

          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr *ngIf="cols?.length==0" style="text-align: center;">
          <td colspan="3">
            No Records
          </td>
        </tr>
      </ng-template>
    </p-table>


    <br>
  </p-panel>
</form>

<div *ngIf="!loading; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center" style="height:520px">
        <div style="position: absolute;left:50%;top:50%;transform:translate(-50%,-50%)">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif" style="height:8em">
        </div>
      </div>
    </div>
  </div>
</ng-template>