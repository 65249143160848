import { Component, OnInit, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-purchas-requirement',
  templateUrl: './purchas-requirement.component.html',
  styleUrls: ['./purchas-requirement.component.scss'],
  providers: [DatePipe, MessageService]
})
export class PurchasRequirementComponent implements OnInit {

  iconToggle: any = [];
  // It is used for displaying table header data
  cols3 = [
    { dataKey: 'reqId', header: 'Job Id"' },
    { dataKey: 'reqName', header: 'Job Title' },
    { dataKey: 'createdByName', header: 'Created By' },
    { dataKey: 'noOfPositions', header: 'Positions' },
    { dataKey: 'skills', header: 'Skills' },
    { dataKey: 'postedDate', header: 'Posted Date' },
    { dataKey: 'status', header: 'Status' },
  ];
  // It is used for displaying table header data
  cols = [
    { field: 'reqId', header: 'Job Id', width: '7%', sno: 1 },
    { field: 'reqName', header: 'Position Title', width: '9%', sno: 2 },
    { field: 'noOfPositions', header: 'Positions', width: '5%', sno: 3 },
    { field: 'requiredSkills', header: 'Skills', width: '11%', sno: 4 },
    { field: 'requiredSkills', header: 'Skills', width: '11%', sno: 5 },
    { field: 'postedDate', header: 'Posted Date', width: '10%', sno: 6 },
    { field: 'reqStatus', header: 'Status', width: '7%', sno: 7 },
    { field: 'createdByName', header: 'Created By', width: '10%', sno: 8 },
    { field: '', header: 'Action', width: '5%', sno: 9 }
  ];
  requirementsForm: FormGroup;
  approveOrRejectForm: FormGroup;
  dialogForm: FormGroup;
  statusList: any;
  userDetails: any;
  userAccId: any;
  first: any;
  rows: any = 10;
  page: any = 1;
  pageCount: any;
  userRoleId: any;
  listOfRequirements: any;
  totalRecords: any;
  skillsList: any = [];
  tableStatus: any;
  display: boolean;
  reqStartDate: string;
  reqEndDate: string;
  items: ({
    label: string; icon: string;
    command: (event: any, i: any) => void;
  } | {
    label: string; icon: string;
    command?: undefined;
  })[];
  reqIdData: any;
  securityReleaseResponse: any;
  loader: boolean;
  approveOrRejectResponse: any;
  approveOrReject: boolean;
  rejectForComments = false;
  rowData: any;
  reqcount: any;
  table: boolean;
  records: boolean;
  displayModal: boolean;
  date: Date;
  histroy: any;
  downloadOptions: { label: string; icon: string; command: (event: any, i: any) => void; }[];
  disable: boolean;
  jobID: any;
  histroy1: any;
  specializationSkillsData: any = [];
  array: any = [];
  reqSkillsValue: any;
  getStatusData: any = {};
  accountnamesres: any = [];
  resetbtn: boolean;
  usrroleid: any;
  // tslint:disable-next-line:max-line-length
  constructor(private breadcrumbService: BreadcrumbService, private messageService: MessageService, private apiService: ApisService, private datepipe: DatePipe, private router: Router, private fb: FormBuilder) {
    this.breadcrumbService.setItems([
      { label: 'Requirements', routerLink: '/requiremenent' },
    ]);
  }
  // Window keydown listener
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.search();
    }
  }
  ngOnInit(): void {

    this.requirementsForm = this.fb.group({
      jobId: [''],
      jobTitle: [''],
      status: [''],
      customerName: [''],
      startDate: [''],
      endDate: ['']
    });
    this.approveOrRejectForm = this.fb.group({
      approveOrReject: [''],
      comments: ['', Validators.required],
    });
    this.dialogForm = this.fb.group({
      comments: ['']
    });
    this.userDetails = JSON.parse(this.apiService.decodeData());
    this.userAccId = this.userDetails.accId;
    this.apiService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
      this.userRoleId = this.userDetails.roles[0].id;
    } else {
      this.userRoleId = this.usrroleid;
    }

    // this.accountNames();
    this.getStatus();
    this.reqSkillSet();
    this.getRequirementsList();
  }
  // It is used for displaying customer name dropdown values
  accountNames() {
    this.apiService.getApi(environment.accountnamesdropdown).subscribe((res: any) => {
      this.accountnamesres = res.data;
    });
  }
  // It is used for displaying status dropdown values
  getStatus() {
    this.apiService.getApi(environment.lookupsData + 'RELS').subscribe((res) => {
      this.statusList = res.data;
      this.statusList.forEach(element => {
        this.getStatusData[element.code] = element.label;
      });
    });
  }
  // It is used for displaying skills dropdown values
  reqSkillSet() {
    this.apiService.getApi(environment.skills).subscribe((res) => {
      this.specializationSkillsData = res.data;
    });
  }
  // It is used to show requirement table data
  getRequirementsList() {
    this.loader = true;
    const pgNo = this.page;
    const recordPage = this.rows;
    const statusVal = this.requirementsForm.controls.status.value;
    const data = '/' + pgNo + '/' + recordPage + '?' + 'accId=' + this.userAccId + '&' + 'primaryRole=' + this.userRoleId + '&isAssigned=' + '';
    this.apiService.getApi(environment.requirementGrid + data).subscribe((res) => {
      this.loader = false;
      this.listOfRequirements = res.data.records;
      this.listOfRequirements.forEach(element => {
        this.jobID = element.reqId;
      });
      this.reqcount = res.data.count;
      this.loader = false;
      this.totalRecords = this.reqcount;
      if (this.reqcount !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  // It is used to search table data
  search() {
    this.loader = true;
    if (
      this.requirementsForm.controls.startDate.value === '' ||
      this.requirementsForm.controls.startDate.value === null ||
      this.requirementsForm.controls.startDate.value === undefined
    ) {
      this.reqStartDate = '';
    } else {
      const startdate =
        this.requirementsForm.controls.startDate.value;
      const reqstartDate = new Date(startdate);
      this.reqStartDate = this.datepipe.transform(
        reqstartDate,
        'MM-dd-yyyy'
      );
    }
    if (
      this.requirementsForm.controls.endDate.value === '' ||
      this.requirementsForm.controls.endDate.value === null ||
      this.requirementsForm.controls.endDate.value === undefined
    ) {
      this.reqEndDate = '';
    } else {
      const endDate =
        this.requirementsForm.controls.endDate.value;
      const reqendtDate = new Date(endDate);
      this.reqEndDate = this.datepipe.transform(
        reqendtDate,
        'MM-dd-yyyy'
      );
    }
    const jobId = this.requirementsForm.controls.jobId.value === null ? '' : this.requirementsForm.controls.jobId.value;
    const jobTitle = this.requirementsForm.controls.jobTitle.value === null ? '' : this.requirementsForm.controls.jobTitle.value;
    // tslint:disable-next-line:max-line-length
    const jodStatus = this.requirementsForm.controls.status.value === null ? '' : this.requirementsForm.controls.status.value === '' ? '' : this.requirementsForm.controls.status.value;
    // tslint:disable-next-line:max-line-length
    const customerName = this.requirementsForm.controls.customerName.value === null || this.requirementsForm.controls.customerName.value === undefined || this.requirementsForm.controls.customerName.value === '' ? '' : this.requirementsForm.controls.customerName.value.employerName;
    const pgNo = this.page;
    const recordPage = this.rows;
    const createBy = 0;
    const data = '/' + pgNo + '/' + recordPage + '?accId=' + this.userAccId + '&reqId=' + jobId + '&createdBy=' + createBy + '&customerName=' + customerName + '&reqName=' + jobTitle + '&startDate=' + this.reqStartDate + '&endDate=' + this.reqEndDate + '&reqStatus=' + jodStatus + '&primaryRole=' + this.userRoleId + '&isAssigned=' + '' + '&recruiterId=' + '';
    this.apiService.getApi(environment.requirementGrid + data).subscribe((res) => {
      this.listOfRequirements = res.data.records;
      this.reqcount = res.data.count;
      this.loader = false;
      if (this.reqcount !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  // It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.getRequirementsList();
  }
  // It is used for displaying skills in table
  getSkillSetTable(data) {
    this.array = data;
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.specializationSkillsData.length; i++) {
      // tslint:disable-next-line: prefer-for-of
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < this.array.length; j++) {
        if (Number(this.specializationSkillsData[i].code) === this.array[j]) {
          selectedPrePrimarySkills3.push(
            this.specializationSkillsData[i].label
          );
        } else {
        }
      }
    }
    return selectedPrePrimarySkills3;
  }

  // It is used to show action pop up
  toggle1(menu, event, rowData) {
    this.rowData = rowData;
    if (this.rowData.reqStatus === 'A') {
      this.items = [
        {
          label: 'Requirement Activity',
          icon: 'pi pi-fw pi-clock',
          command: () => {
            this.profilehistroy();
          },
        },
      ];
    } else if (this.rowData.reqStatus === 'P') {
      this.items = [
        {
          label: 'Approve/Reject',
          icon: 'pi pi-arrow-circle-right',
          // tslint:disable-next-line:no-shadowed-variable
          command: (event, i) => {
            this.approveOrRejectRequirement('');
          },
        },
        {
          label: 'Requirement Activity',
          icon: 'pi pi-fw pi-clock',
          command: () => {
            this.profilehistroy();
          },
        },
      ];
    } else if (this.rowData.reqStatus === 'C') {
      this.items = [
        {
          label: 'Requirement Activity',
          icon: 'pi pi-fw pi-clock',
          command: () => {
            this.profilehistroy();
          },
        },
      ];
    }else if (this.rowData.reqStatus === 'R'){
      this.items = [
        {
          // tslint:disable-next-line: no-shadowed-variable
          label: 'Vendors', icon: 'fa fa-users', command: (event, i) => {
            this.router.navigate(['/vendor-details'], {
              queryParams: {
                vendor: 'vendor',
                reqId: this.rowData.reqId,
                role: 'purchasManager'
              }
            });
          }
        },
        {
          label: 'Requirement Activity',
          icon: 'pi pi-fw pi-clock',
          command: () => {
            this.profilehistroy();
          },
        },
      ];
    }
    else {
      this.items = [
        {
          label: 'Requirement Activity',
          icon: 'pi pi-fw pi-clock',
          command: () => {
            this.profilehistroy();
          },
        },
      ];
    }

  }
  // it is used to display download action pop up
  downLoadsToggle() {
    this.downloadOptions = [
      {
        label: 'Download XLSX',
        icon: 'pi pi-download',
        command: (event, i) => {
          this.exportExcel();
        },
      },
      {
        label: 'Download PDF',
        icon: 'pi pi-download',
        command: (event, i) => {
          this.exportPdf();
        },
      },
    ];
  }
  // It used to reset search fileds
  reset() {
    this.requirementsForm.reset();
    this.getRequirementsList();

  }
  backNavigate() { }
  getStatusTable(data) {
    const item = data === null || data === undefined ? '' : this.statusList.find((element) => element.code === data);
    this.tableStatus = item.label;
    return this.tableStatus;
  }
  reqSkills1(data) {
    this.display = true;
    this.reqSkillsValue = data;
  }
  // It is used to display approve/reject requirement popup
  approveOrRejectRequirement(data) {
    if (this.rowData.reqStatus === 'P') {
      this.rejectForComments = false;
      this.approveOrRejectForm.reset();
      this.approveOrReject = true;
      this.approveOrRejectForm.get('comments').disable();
      this.approveOrRejectForm.get('approveOrReject').patchValue('Approved');
    }
  }
  // It used to display reject text filed
  rejectRequirement() {
    this.rejectForComments = true;
    this.approveOrRejectForm.get('comments').reset();
    this.approveOrRejectForm.get('comments').enable();
  }
  // It is used to display its approve requirements
  approveRequirement() {
    this.rejectForComments = false;
    this.approveOrRejectForm.get('comments').reset();
    this.approveOrRejectForm.get('comments').disable();
  }
  submitForApproveOrReject() {
    if (this.approveOrRejectForm.invalid) {
      this.approveOrRejectForm.get('comments').markAsTouched();
    } else {
      this.approveOrRejectYes();
    }
  }
  // It is used to approved requirement
  approveOrRejectYes() {
    this.disable = true;
    const submitReq = {
      id: this.rowData.reqId,
      comment: this.approveOrRejectForm.get('comments').value === null ? '' : this.approveOrRejectForm.get('comments').value,
      reqStatus: this.approveOrRejectForm.controls.approveOrReject.value === 'Approved' ? 'A' : 'J',
    };
    this.apiService.putApi(environment.reqApprove, submitReq).subscribe((res) => {
      this.disable = false;
      if (res.hasOwnProperty('success') === true) {
        this.approveOrReject = false;
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        this.getRequirementsList();
      } else if (
        res.hasOwnProperty('success') === false
      ) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        this.disable = false;
      } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
        this.disable = false;
      }
    });
  }
  // It is used to navigate to requirement edit screen
  reqEditRoute(data) {
    this.router.navigate(['/purcha-requirementedit'], {
      queryParams: {
        submittedreqId: data.reqId,
        gStatus: data.reqStatus,
        name: data.reqName,
        reqId: data.reqId,
        maxRate: data.maxRate
      }
    });
  }
  // It is used to navigate add requirement screen
  add() {
    this.router.navigate(['/add-requiremenent']);
  }
  // It is used navigate to project edit requirement
  add1() {
    this.router.navigate(['/project-edit-requirement']);
  }
  // It is used to display requirement activities
  profilehistroy() {
    this.displayModal = true;
    this.date = new Date();
    const actId = JSON.parse(this.apiService.decodeData());
    this.apiService.getApi(environment.getreqActivity + this.rowData.reqId).subscribe((res: any) => {
      this.histroy1 = this.rowData.reqId;
      this.histroy = res.data;
      if (this.reqcount !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  // It is used to download table data in excel format
  exportExcel() {
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    // tslint:disable-next-line:max-line-length
    ws['!cols'] = [{ width: 10 }, { width: 30 }, { width: 10 }, { width: 30 }, { width: 30 }, { width: 20 }, { width: 10 }, { width: 20 }, { hidden: true }];
    ws['!rows'] = [{ hpt: 30 }, { hpt: 30 }];
    ws['!cols'][3] = { hidden: true };
    ws['!cols'][9] = { hidden: true };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ExcelSheet.xlsx');
  }
  // It is used to download table data in pdf format
  exportPdf() {
    this.listOfRequirements.forEach((element) => {
      element.status = this.getStatusData[element.reqStatus];
      element.skills = this.getSkillSetTable(element.requiredSkills);
    });
    const doc = new jsPDF('p', 'pt');
    autoTable(doc, {
      headStyles: { fillColor: '#2bb9d5', cellPadding: 5 , cellWidth: 85 },
      margin: { left: 3},
      columns: this.cols3,
      body: this.listOfRequirements,
      didDrawPage: (dataArg) => {
        doc.text('Requirements', dataArg.settings.margin.left, 30);
      }
    });
    doc.save('Requirements.pdf');
  }
  // It is used to close approve/reject pop up
  cancle() {
    this.approveOrReject = false;
    this.rejectForComments = false;
    this.approveOrRejectForm.reset();

  }
}
