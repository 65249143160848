import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApisService {
  username = new BehaviorSubject('');
  currentUsername = this.username.asObservable();
  username1 = new BehaviorSubject('');
  currentUsername1 = this.username.asObservable();
  username2 = new BehaviorSubject('');
  currentUsername2 = this.username.asObservable();
  rolebasedsidemenu = new BehaviorSubject('');
  rolebased = this.username.asObservable();
  accActivity = new BehaviorSubject('');
  accountactivity = this.username.asObservable();

  accActivitycondition = new BehaviorSubject('');
  accountactivitycondition = this.username.asObservable();
  accActivitycon = new BehaviorSubject('');
  accountaccActivitycon = this.username.asObservable();
  availableRefresh = new BehaviorSubject<boolean>(false);
  secretKey = 'ZS745#7JiI1234@8'; // Prod
  // secretKey = 'ZS#87HH@4917@789'; // UAT
  ip: any;
  userId: any;
  loginRoleId: any;
  myip: string;
  constructor(private http: HttpClient, private router: Router) {}
  getApi(endpoint): Observable<any> {
    return this.http.get(endpoint).pipe(
      map((uresponse: Response) => {
        return uresponse;
      }), catchError(this.handleError<any>('Login')));
  }
  deleteApi(endpoint): Observable<any> {
    return this.http.delete(endpoint).pipe(
      map((uresponse: Response) => {
        return uresponse;
      }), catchError(this.handleError<any>('Login')));
  }
  getdropdwon() {
    return [
      { label: 'INDIA', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/indian-flag-500x500.jpg', code: '+91', id: '1' },
      { label: 'UNITED STATES', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png', code: '+1', id: '2' },
      { label: 'CANADA', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/canada.jpg', code: '+1', id: '3' },
      { label: 'UNITED KINGDOM', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/uk.png', code: '+44', id: '4' },
      { label: 'AUSTRALIA', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/australia.png', code: '+61', id: '5' },
      { label: 'BRAZIL', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/brazil.png', code: '+55', id: '6' },
      { label: 'CHINA', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/china.jpg', code: '+86', id: '7' },
      { label: 'SINGAPORE', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/singapore.jpg', code: '+65', id: '8' },
      { label: 'JAPAN', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/japan.png', code: '+81', id: '9' },
      { label: 'SWITZERLAND', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/switzerland.jpg', code: '+41', id: '10' },
      { label: 'MEXICO', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/mexico.png', code: '+52', id: '11' },
      { label: 'ITALY', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/italy.png', code: '+39', id: '12' },
      { label: 'ISRAEL', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/israel.png', code: '+972', id: '13' },
      { label: 'GERMANY', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/germany.png', code: '+49', id: '14' },
      { label: 'FRANCE', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/france.jpg', code: '+33', id: '15' },
      { label: 'SAUDI ARABIA', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/saudiarabia.jpg', code: '+966', id: '16' }
    ];
  }
  postApi(endpoint, data): Observable<any> {
    return this.http.post<any>(endpoint, data).pipe(
      map((uresponse: Response) => {
        return uresponse;
      }), catchError(this.handleError<any>('Login'))
    );
  }
  putApi(endpoint, data): Observable<any> {
    return this.http.put(endpoint, data).pipe(
      map((uresponse: Response) => {
        return uresponse;
      }), catchError(this.handleError<any>('Login'))
    );
  }
  decodeData() {
    if (JSON.parse(sessionStorage.getItem('loginData')) === null) {
      this.router.navigate(['/login']);
    }
    const tokenString = JSON.parse(sessionStorage.getItem('loginData')).data
      .token;
    if (tokenString) {
      const tokenArray = tokenString.split('.');
      if (tokenArray.length) {
        const dataObj = atob(tokenArray[1]);
        return dataObj;
      }
    }
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      if (error.error.status === '401' && error.error.message === 'Unauthorized') {
        this.router.navigate(['/']);
      }
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  encryptdata(value: string) {
    const key = CryptoJS.enc.Utf8.parse(this.secretKey);
    // tslint:disable-next-line:prefer-const
    let ciphertext = CryptoJS.AES.encrypt(value, key, { iv: key }).toString();
    return ciphertext;
    // return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
  }
  landingPage() {
    const logInDetails = JSON.parse(this.decodeData());
    const { detect } = require('detect-browser');
    const browser = detect();
    this.ip = logInDetails.ipAddress;
    this.userId = logInDetails.userId;
    // handle the case where we don't detect the browser
    if (browser) {
    }
    const body = {
      usrId: this.userId,
      browser: browser.name,
      ipAddress: this.ip
    };
    // tslint:disable-next-line: deprecation
    this.postApi(environment.verifyLogin, body).subscribe(res => {
      sessionStorage.setItem('autoID', res.autoIncrementedId);
    });
    this.loginRoleId = logInDetails.roles[0].id;
    if (this.loginRoleId === 5) {
      this.router.navigate(['/project-requirements']);
    } else if (this.loginRoleId === 4) {
      // tslint:disable-next-line:no-unused-expression
      this.router.navigate(['/purchas-requirement']);
    }
    else if (this.loginRoleId === 3) {
      this.router.navigate(['/account-details']);
    }
    else if (this.loginRoleId === 9) {
      this.router.navigate(['/open-positions']);
    }
    else if (this.loginRoleId === 1) {
      this.router.navigate(['/accounts']);
    }
    else if (this.loginRoleId === 10) {
      this.router.navigate(['/tech-evaltion-tech-reviews']);
    }
    else if (this.loginRoleId === 11) {
      this.router.navigate(['/employee-requirements']);
    }
    else if (this.loginRoleId === 12) {
      this.router.navigate(['/tech-evalution']);
    }
    else if (this.loginRoleId === 6) {
      this.router.navigate(['/accountdetails']);
    }
    else if (this.loginRoleId === 8) {
      this.router.navigate(['/openpositions']);
    }
    else if (this.loginRoleId === 13) {
      this.router.navigate(['/salesOpenPositions']);
    }
    else if (this.loginRoleId === 14) {
      this.router.navigate(['/vendor']);
    }
    else if (this.loginRoleId === 15) {
      this.router.navigate(['/consultantopenposition']);
    }
    else {
      return true;
    }
    return false;

  }
}
