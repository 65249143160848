import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { MessageService } from 'primeng/api';
import { isEmpty } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-consultant-search',
  templateUrl: './consultant-search.component.html',
  styleUrls: ['./consultant-search.component.scss'],
  providers: [DatePipe, MessageService],
})
export class ConsultantSearchComponent implements OnInit {
  expLabel: any = [];
  cols3 = [
    { dataKey: 'name', header: 'Name', width: '10%' },
    { dataKey: 'email', header: 'Email' , width: '10%'},
    { dataKey: 'exper', header: 'Experience', width: '10%' },
    { dataKey: 'skills', header: 'Skill Set', width: '10%' },
    { dataKey: 'mobilePhone', header: 'Phone No', width: '10%' },
    { dataKey: 'status', header: 'Status' , width: '10%'},
  ];
  cols = [
    { field: 'name', header: 'Name', width: '10%', sno: 1 },
    { field: 'email', header: 'Email', width: '10%', sno: 2 },
    { field: 'lkExperience', header: 'Experience', width: '5%', sno: 3 },
    { field: 'requiredSkills', header: 'Skill Set', width: '7%', sno: 4 },
    { field: 'requiredSkills', header: 'Skill Set', width: '7%', sno: 5 },
    { field: 'mobilePhone', header: 'Phone No', width: '5%', sno: 6 },
    { field: 'lkStatus', header: 'Status', width: '5%', sno: 7 },
    { field: ' ', header: 'Action', width: '3%', sno: 8 }
  ];

  consultantSearchForm: FormGroup;
  country: any;
  state: any;
  experienceData: any;
  specializationSkills: any = [];
  loader: boolean;
  orgIdValue: any;
  userIdValue: any;
  actId: any;
  gridData: any;
  user: any;
  totalRecords: any;
  pdf: boolean;
  disable: boolean;
  page: any = 1;
  rows: any = 10;
  skillsCategory: any;
  statusTable = {};
  skillSet: any;
  table = true;
  loading: boolean;
  first: any;
  pageCount: any;
  openSkillModel: boolean;
  skillData: any;
  dialogForm: FormGroup;
  statuTable: any;
  experienceTable = {};
  downloadOptions: { label: string; icon: string; command: (event: any, i: any) => void; }[];
  rowData: any;
  items: { label: string; icon: string; command: () => void; }[];
  records: boolean;
  displayActive: boolean;
  displaySend: boolean;
  Status: any = [];
  rowID: any;
  loginActiveData: string;
  email1: any;
  registerdStatus: boolean;
  sendCandidateCredentials: boolean;
  saveReg: any;
  userID: string;
  items1: { label: string; icon: string; command: () => void; }[];
  isUploading: boolean;
  showUploadView: boolean;
  fileOver: boolean;
  downloadData: any;
  verifyForm: FormGroup;
  displayemail1: boolean;
  altEmailSpinnerHide: boolean;
  emailIdData: any;
  altEmailCheckHide: boolean;
  candidateRowID: any;
  btndisable: boolean = false;
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchConsultant();
    }
  }
  // tslint:disable-next-line: max-line-length
  constructor(private breadcrumbService: BreadcrumbService, private messageService: MessageService, private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private apisService: ApisService,
  ) {
    this.breadcrumbService.setItems([
      { label: 'Candidates', routerLink: '/consultantSearch' }
    ]);
    this.items1 = [
      {label: 'Download Sample', icon: 'pi pi-download', command: () => { this.downloadFileapi();
      }},
      {label: 'Import Data', icon: 'pi pi-chevron-down', command: () => {
        this.toggleUploadView();
      }
    }];



  }
  ngOnInit() {
    this.consultantSearchForm = this.fb.group({
      name: [''],
      emailId: [''],
      status: [''],
      country: [''],
      state: [''],
      city: [''],
      phone: [''],
      experience: [''],
      specialization: ['']

    });
    this.dialogForm = this.fb.group({
      comments: ['']
    });
    this.verifyForm = this.fb.group({
      verifyEmail: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
    })
    this.getStatus();
    this.getCountryDropdown();
    this.getExperienceDropDownData();
    this.getSpecialization();
    this.getConsultatSearchGrid();
  }
  // It is used to copy email to clipboard
copyInputMessage(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
toggleemail(event) {
  }
// Country dropdown
getCountryDropdown() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'adlc').subscribe(res => {
      this.country = res.data;
    });
  }

// States dropdown
getStateByUrl(country) {
  this.consultantSearchForm.controls.state.setValue('');
    // tslint:disable-next-line:align
    if (country === null || '' || undefined) { }
    else {
      // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.states + country).subscribe(res => {
        this.state = res.data;
      });
    }
  }
  // It is used to show experience dropdown values
  getExperienceDropDownData() {
    this.apisService.getApi(environment.lookupsData + 'RELYE').subscribe((res: any) => {
        this.experienceData = res.data;
        this.experienceData.forEach((element) => {
          this.expLabel[element.code] = [element.label];
        });
      });
  }
  // specialization dropdown
  getSpecialization() {
    this.apisService.getApi(environment.lookupsData + 'RELRS').subscribe((res) => {
        this.specializationSkills = res.data;
      });
  }
  // grid data method
  getConsultatSearchGrid() {
    this.loader = true;
    this.orgIdValue = JSON.parse(sessionStorage.getItem('loginData')).orgId;
    this.userIdValue = JSON.parse(sessionStorage.getItem('loginData')).userId;
    this.actId = JSON.parse(this.apisService.decodeData());
    const pageNo = this.page;
    const pageCount = this.rows;
    const orgId = '/' + pageNo + '/' + pageCount + '/' + this.actId.accId;
    this.loading = true;
    this.apisService.getApi(environment.consultantTable + orgId).subscribe((res: any) => {
        this.loading = false;
        this.loader = false;
        this.gridData = res.data.records;
        sessionStorage.setItem('UserId', this.user);
        sessionStorage.setItem('UserId', this.user);
        this.totalRecords = res.data.count;
        if (this.totalRecords === 0) {
          this.records = true;
          this.table = false;
        } else {
          this.records = false;
          this.table = true;
        }
      });
  }
  // search method
  searchConsultant() {
    this.loader = true;
    const skillSetFormData = this.consultantSearchForm.controls.specialization.value;
    this.skillSet = [];
    if (this.consultantSearchForm.controls.specialization.value === null) {
    } else {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < skillSetFormData.length; i++) {
        this.skillSet.push(skillSetFormData[i].skillName);
      }
    }
    // tslint:disable-next-line:max-line-length
    const consultName =  this.consultantSearchForm.controls.name.value === null || '' || undefined  ? '' : this.consultantSearchForm.controls.name.value;
    // tslint:disable-next-line:max-line-length
    const yearExperience =  this.consultantSearchForm.controls.experience.value === ('' || null)  ? ''  : this.consultantSearchForm.controls.experience.value === undefined  ? ''  : this.consultantSearchForm.controls.experience.value;
    // tslint:disable-next-line:max-line-length
    const consultEmail =  this.consultantSearchForm.controls.emailId.value === ('' || null)  ? ''  : this.consultantSearchForm.controls.emailId.value;
    // tslint:disable-next-line:max-line-length
    const consultPhno = this.consultantSearchForm.controls.phone.value === ('' || null)  ? ''  : this.consultantSearchForm.controls.phone.value;
    // tslint:disable-next-line:max-line-length
    const consultStatus =  this.consultantSearchForm.controls.status.value === ('' || null)  ? ''  : this.consultantSearchForm.controls.status.value.code === undefined  ? ''  : this.consultantSearchForm.controls.status.value.code;
    // tslint:disable-next-line:max-line-length
    const skillValues =  this.consultantSearchForm.controls.specialization.value === null  ? ''  : this.consultantSearchForm.controls.specialization.value;
    // tslint:disable-next-line:max-line-length
    const consultCountry =  this.consultantSearchForm.controls.country.value === ('' || null)  ? ''   : this.consultantSearchForm.controls.country.value.code === undefined  ? ''  : this.consultantSearchForm.controls.country.value.code;
    // tslint:disable-next-line:max-line-length
    const consultState = this.consultantSearchForm.controls.state.value === ('' || null)   ? ''  : this.consultantSearchForm.controls.state.value.code === undefined  ? ''  : this.consultantSearchForm.controls.state.value.code;
    const pageNo = this.page;
    const pageCount = this.rows;
    // tslint:disable-next-line:max-line-length
    const orgId = '/' +  pageNo +  '/' +  pageCount +  '/' +  this.actId.accId +  '?' +  'name=' +  consultName +  '&email=' + consultEmail +  '&status=' + consultStatus +  '&country=' + consultCountry +  '&state=' +  consultState + '&phone=' +  consultPhno + '&experiance=' +  yearExperience +  '&skills=' + skillValues;
    this.apisService.getApi(environment.consultantTable + orgId).subscribe((res: any) => {
        this.loader = false;
        this.gridData = res.data.records;
        this.totalRecords = res.data.count;
        if (this.totalRecords === 0) {
          this.records = true;
          this.table = false;
        } else {
          this.records = false;
          this.table = true;
        }
      });
  }
  reset() {
    this.consultantSearchForm.reset();
    this.getConsultatSearchGrid();
  }

  // server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.getConsultatSearchGrid();

  }
  downloadPDFFile() {
    this.loader = true;
  }
  // It is used to show skill sin table data
  roletable(data) {
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.specializationSkills.length; i++) {
      if (data === null || data === undefined || data === '' || data === isEmpty()) { } else {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < data.length; j++) {
          if (Number(this.specializationSkills[i].code) === data[j]) {
            selectedPrePrimarySkills3.push(this.specializationSkills[i].label);
          } else {
          }
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  // Method to open skills dialoog box
  openSkillDailog(data) {
    this.skillData = data;
  }
  // It is used to show status sdropdown values
  getStatus() {
    this.apisService.getApi(environment.lookupsData + 'RELRS').subscribe((res) => {
        this.skillsCategory = res.data;
        const data = { skillName: 'Others', id: '' };
        this.skillsCategory.push(data);
      });
    this.apisService.getApi(environment.actstatus).subscribe((res) => {
        this.Status = res.data;
        this.Status.forEach((element) => {
          this.statusTable[element.code] = element.label;
        });
      });
  }
  // It is used to redirect to add candidate screen
  add() {
    this.router.navigate(['/addcandidate']);
  }
  // It is used to show download option toggle
  downLoadsToggle() {
    this.downloadOptions = [
      {
        label: 'Download XLSX',
        icon: 'pi pi-download',
        command: (event, i) => {
          this.exportExcel();
        },
      },
      {
        label: 'Download PDF',
        icon: 'pi pi-download',
        command: (event, i) => {
          this.exportPdf();
        },
      },
    ];
  }
  emailToast(){
    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
  }
  toggle(event, data, rowData) {
    sessionStorage.setItem('editconIdttt', data.userId);
    this.rowData = rowData;
    this.items = [
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => {
          this.router.navigate(['/editcandidate', data.userId]);
        },
      },
      {
        label: 'Activate',
        icon: 'pi pi-check-circle',
        command: () => {
          this.activateaccount(data);
        },
      },
      {
        label: 'Send Credentials',
        icon: 'pi pi-send',
        command: () => {
          this.sendCandidateDetails(data);
        },
      },
    ];
  }
  goToLink(url: string) {
    const addressLink = url.includes('http') ? url : `http://${url}`;
    window.open(addressLink, '_blank');
  }
  // It is used to download gridata in excel format
  exportExcel() {
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    ws['!cols'] = [ {width : 10}, {width : 20}  , {width : 20} , {width : 10}, {width : 10}, {width : 10}, {width : 10}, {hidden : true} ];
    ws['!rows'] = [{hpt : 30}, {hpt : 30}, ];
    ws['!cols'][3] = {hidden: true};
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ExcelSheet.xlsx');
  }
  // It is used to download grid data as pdf format
  exportPdf() {
    this.gridData.forEach((element) => {
      element.status = this.statusTable[element.lkStatus];
      element.skills = this.roletable(element.requiredSkills);
      element.exper = this.expLabel[element.lkExperience];
    });
    const doc = new jsPDF('p', 'pt');
    autoTable(doc, {
      headStyles: { fillColor: '#2bb9d5', cellPadding: 5   },
      margin: { left: 17},
      columns: this.cols3,
      body: this.gridData,
      didDrawPage: (dataArg) => {
        doc.text('Candidates', dataArg.settings.margin.left, 30);
      }
    });
    doc.save('Candidates.pdf');
  }
  // Method to close send account credentials
  cancel() {
    this.displayActive = false;
    this.displaySend = false;
    this.verifyForm.controls.verifyEmail.reset();
  }
  // Method to show are whether you want to activate account
  activateaccount(data) {
    this.rowID = data.userId;
    const data1 = data.status;
    this.rowData = data;
    this.displayActive = true;
    if (data.lkStatus === 'A') {
      this.displayActive = false;
      this.loginActiveData = 'User contact is already activated ';
      // please check email-';
      this.registerdStatus = false;
      this.email1 = "";
      Swal.fire({
        title: 'Success!',
        text: this.loginActiveData + this.email1,
        icon: 'success',
        confirmButtonColor: '#0D416B',
        iconColor: '#1dbb99',
        confirmButtonText: 'Ok',
      });
    } else if (data.lkStatus === 'I') {
      this.loginActiveData = 'User contact is inactive';
      this.registerdStatus = false;
      this.email1 = data.email;
    } else if (data.lkStatus === 'R') {
      this.loginActiveData = 'Do you want to Activate the User contact?';
      this.registerdStatus = true;
      this.email1 = data.name;
    }
  }
  // toggle option for send credential of candidate
  sendCandidateDetails(data) {
    this.verifyForm.controls.verifyEmail.reset();
    this.candidateRowID = data;
    this.displaySend = true;
    if(data.flag === 'Y') {
      this.displaySend = false;
      this.loginActiveData = 'Candidate Credentials have already been sent ';
      this.sendCandidateCredentials = true;
      Swal.fire({
        title: 'Success!',
        text: this.loginActiveData,
        icon: 'success',
        confirmButtonColor: '#0D416B',
        iconColor: '#1dbb99',
        confirmButtonText: 'Ok',
      });
    } else if(data.flag === 'N') {
      if (data.lkStatus === 'A' || data.lkStatus === 'R') {
        this.loginActiveData = 'Do you want to Send Candidate Credentials?';
        this.verifyForm.patchValue({
          verifyEmail: data.email
        });
        this.emailverification();
        this.sendCandidateCredentials = true;
      } else if (data.lkStatus === 'I') {
        this.loginActiveData = 'User contact is inactive';
        this.sendCandidateCredentials = false;
      }
    }
  }
  // email verification for existed or not
  emailverification() {
    this.displayemail1 = false;
    const email = this.verifyForm.controls.verifyEmail.value;
    if (email === '' || email === null || email === undefined) {
    } else {
      if (this.verifyForm.controls.verifyEmail.invalid && this.verifyForm.controls.verifyEmail.errors.pattern) {
      }
      else {
        this.altEmailSpinnerHide = true;
        const emailCheck = this.candidateRowID.userId + "/" + email;
        this.apisService.getApi(environment.existEmailValidation + emailCheck).subscribe((res) => {
          this.emailIdData = res;
          if (this.emailIdData.isEmailExisted === true) {
            this.altEmailSpinnerHide = false;
            this.displayemail1 = true;
            setTimeout(() => {
              this.displayemail1 = false;
            }, 2000);
            this.verifyForm.controls.verifyEmail.reset();
          } else {
            this.altEmailSpinnerHide = false;
            this.altEmailCheckHide = true;
            setTimeout(() => {
              this.altEmailCheckHide = false;
            }, 3000);
          }
        });
      }
    }
  }
  // Method to send account credentials
  saveUserContacts() {
    this.userID = sessionStorage.getItem('userId');
    const contactId = this.rowID;
    this.disable = true;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.loginSendCandidate + contactId).subscribe((res) => {
      this.saveReg = res;
      this.disable = false;
      if (this.saveReg.hasOwnProperty('success') === true) {
        this.displayActive = false;
        this.getConsultatSearchGrid();
        this.messageService.add({
          key: 'br',
          severity: '',
          life: 3000,
          detail: this.saveReg.message,
        });
      } else if (this.saveReg.hasOwnProperty('failed') === true) {
        this.messageService.add({
          key: 'br',
          severity: '',
          life: 3000,
          detail: this.saveReg.failed,
        });
      } else {
        this.messageService.add({
          key: 'br',
          severity: '',
          life: 3000,
          detail: this.saveReg.error,
        });
      }
    }, (err) => {
      this.disable = false;
    }
    );
  }
  // send credential of candidate
  sendCredentials() {
    this.btndisable = true;
    const email = this.verifyForm.controls.verifyEmail.value;
    if (email === '' || email === null || email === undefined) {
      this.btndisable = false;
    } else {
      if (this.verifyForm.controls.verifyEmail.invalid && this.verifyForm.controls.verifyEmail.errors.pattern) {
      this.btndisable = false;
      }
      else {
        this.altEmailSpinnerHide = true;
        const emailCheck = this.candidateRowID.userId + "/" + email;
        this.apisService.getApi(environment.existEmailValidation + emailCheck).subscribe((res) => {
          this.emailIdData = res;
          if (this.emailIdData.isEmailExisted === true) {
            this.btndisable = false;
            this.altEmailSpinnerHide = false;
            this.displayemail1 = true;
            setTimeout(() => {
              this.displayemail1 = false;
            }, 2000);
            this.verifyForm.controls.verifyEmail.reset();
          } else {
            this.altEmailSpinnerHide = false;
            this.altEmailCheckHide = true;
            setTimeout(() => {
              this.altEmailCheckHide = false;
            }, 2000);            
            this.apisService.getApi(
              environment.loginSendCandidate + this.candidateRowID.userId + "?email=" + 
              this.verifyForm.controls.verifyEmail.value + "&flag=Y").subscribe((res: any) => {
                if (res.hasOwnProperty('success') === true) {
                  this.getConsultatSearchGrid();
                  this.messageService.add({
                    key: 'br',
                    severity: '',
                    life: 3000,
                    detail: res.message,
                  });
                } else if (res.hasOwnProperty('failed') === true) {
                  this.messageService.add({
                    key: 'br',
                    severity: '',
                    life: 3000,
                    detail: res.failed,
                  });
                } else {
                  this.messageService.add({
                    key: 'br',
                    severity: '',
                    life: 3000,
                    detail: res.error,
                  });
                }
                this.btndisable = false;
                this.cancel();
            });
          }
        });
      }
    }












    // this.apisService.getApi(
    //   environment.loginSendCandidate + this.candidateRowID.userId + "?email=" + 
    //   this.verifyForm.controls.verifyEmail.value + "&flag=Y").subscribe((res: any) => {
    //     if (res.hasOwnProperty('success') === true) {
    //       this.getConsultatSearchGrid();
    //       this.messageService.add({
    //         key: 'br',
    //         severity: '',
    //         life: 3000,
    //         detail: res.message,
    //       });
    //     } else if (res.hasOwnProperty('failed') === true) {
    //       this.messageService.add({
    //         key: 'br',
    //         severity: '',
    //         life: 3000,
    //         detail: res.failed,
    //       });
    //     } else {
    //       this.messageService.add({
    //         key: 'br',
    //         severity: '',
    //         life: 3000,
    //         detail: res.error,
    //       });
    //     }
    //     this.cancel();
    // });
  }
   // It is Used to upload files
   onUpload(files) {
    this.isUploading = true;
    const formData: FormData = new FormData();
    formData.append('file', files[0]);
    this.apisService.postApi(environment.importCandidate, formData).subscribe((res: any) => {
        if (res.success === true) {
          this.getConsultatSearchGrid();
          this.messageService.add({  key: 'br', severity: '', life: 3000, detail: res.message, });
          this.isUploading = false;
          this.showUploadView = false;
        } else {
          setTimeout(() => {
            this.isUploading = false;
          }, 2000);
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
          this.getConsultatSearchGrid();
        }
      });
  }



   // Drop Listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.isUploading = true;
      this.onUpload(files);
    }
  }
  downloadFileapi() {
    this.apisService.getApi(environment.downloadres + 6).subscribe((res: any) => {
      this.downloadData = res;
      window.location.assign(this.downloadData.downloadUrl);
    });
  }
  toggleUploadView() {
    this.showUploadView = !this.showUploadView;
  }
}
