<p-toast position="bottom-right" key="br" ></p-toast>
<form [formGroup]="resetUserPassword">
  <p-panel header="Reset User Password" [toggleable]="false" [style]="{'margin-bottom':'20px'}">
    <div class="p-grid  p-justify-center">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6  p-fluid">
        <label class="inputfields">Email<label class="mandatoryColor">*</label></label>
        <input autocomplete="off" type="text" pInputText placeholder="Email" formControlName="email" maxlength="60">
        <span class="validationtest"
          *ngIf="resetUserPassword.controls.email.touched && resetUserPassword.controls.email.invalid && resetUserPassword.controls.email.errors.required">
          Please enter email
        </span>
        <span class="mandatoryColor" *ngIf='resetUserPassword.controls.email.invalid && resetUserPassword.controls.email.touched &&
                       resetUserPassword.controls.email.errors.pattern'>Please enter valid email</span>
      </div>
    </div>
    <div class="p-grid  p-justify-center">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6  p-fluid">
        <label class="inputfields">New Password<label class="mandatoryColor">*</label></label>
        <input autocomplete="off" type="password" pInputText placeholder="Password" formControlName="password"
          maxlength="20">
        <span class="validationtest"
          *ngIf="resetUserPassword.controls.password.touched && resetUserPassword.controls.password.invalid && resetUserPassword.controls.password.errors.required">
          Please enter new password
        </span>
      </div>
    </div>
    <div class="p-grid  p-justify-center">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6  p-fluid">
        <label class="inputfields">Confirm Password<label class="mandatoryColor">*</label></label>
        <input autocomplete="off" type="password" pInputText placeholder="Confirm Password"
          formControlName="conformPassword" maxlength="20">
        <span class="validationtest"
          *ngIf="resetUserPassword.controls.conformPassword.touched && resetUserPassword.controls.conformPassword.invalid && resetUserPassword.controls.conformPassword.errors.required">
          Please enter confirm password
        </span>
        <div class="btnsCSS1">
          <br>
          <button pButton type="button" icon='pi pi-save' label="Submit" (click)="resetPassword()" class="addhBtn"
            ></button>
          <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" 
         class="addhBtn"></button>
        </div>
      </div>
    </div>
     </p-panel>
</form>
<ng-template #showLoader>
  <div clss=".loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center laoderheight">
        <div class="laodersize" >
            <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif" class="loaderimg-height" >
        </div>
      </div>
    </div>
  </div>
</ng-template>
