import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-sales-open-positions',
  templateUrl: './sales-open-positions.component.html',
  styleUrls: ['./sales-open-positions.component.scss'],
  providers: [DatePipe, MessageService],

})
export class SalesOpenPositionsComponent implements OnInit {
  openPositionSearchForm: FormGroup;
  recruiterForm: FormGroup;
  cols = [
    { field: 'reqId', header: 'Job Id', sno: 1 },
    { field: 'accName', header: 'Customer Name',  sno: 2},
    { field: 'reqName', header: 'Position Title',  sno: 3},
    { field: 'requiredSkills', header: 'Skills',  sno: 4},
    { field: 'requiredSkills', header: 'Skills',  sno: 5},
    { field: 'postedDate', header: 'Posted Date', sno: 6},
    { field: 'plannedEndDate', header: 'Target Date',  sno: 7},
    { field: 'reqStatus', header: 'Status', sno: 8 },
    { field: 'submissions', header: 'Submissions', width: '5px' , sno: 9},
  ];
  cols3 = [
    { dataKey: 'reqId', header: 'Job Id' },
    { dataKey: 'accName', header: 'Customer Name' },
    { dataKey: 'reqName', header: 'Position Title' },
    { dataKey: 'skills', header: 'Skills' },
    { dataKey: 'postedDate', header: 'Posted Date' },
    { dataKey: 'plannedEndDate', header: 'Target Date' },
    { dataKey: 'status', header: 'Status' },
    { dataKey: 'submissions', header: 'Submissions' },
  ];
  loading: boolean;
  acctDetails: any;
  rows: any = 10;
  page: any = 1;
  roleId: any;
  gridData: any;
  totalRecords: any;
  records = false;
  table: boolean;
  requirementsStartDate: string;
  requirementsEndDate: string;
  loader: boolean;
  requirementsData: any;
  first: any;
  pageCount: any;
  display: boolean;
  reqSkillsValue: any;
  array: any;
  specializationSkillsData: any = [];
  statusDD: any = [];
  getStatusData = {};
  downloadOptions: { label: string; icon: string; command: (event: any, i: any) => void; }[];
  rowData: any;
  // tslint:disable-next-line: max-line-length
  items: ({ label: string; icon: string; disable: boolean; command: (event: any, i: any) => void; } | { label: string; icon: string; command: (event: any, i: any) => void; disable?: undefined; })[];
  dialogForCandidates: boolean;
  assignedList: any = [];
  enableAssignedField: boolean;
  rowId: any;
  recruiterFormdata: any;
  alertError: boolean;
  userDropMenuid: any;
  TaxTermDailog: boolean;
  reqID: any;
  gridStatus: any;
  name: string;
  usrroleid: any;
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchData();
    }
  }
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private datepipe: DatePipe, private breadcrumbService: BreadcrumbService, private messageService: MessageService, private route: Router) {
    this.breadcrumbService.setItems([
      { label: 'Open Positions', routerLink: '/salesOpenPositions' },
    ]);
  }
  ngOnInit(): void {
    this.openPositionSearchForm = this.fb.group({
      requirementsJobId: [''],
      requirementsCustomerName: [''],
      requirementsJobTitle: [''],
      requirementsStatus: [''],
      requirementsassignStatus: [''],
      requirementsStartDate: [''],
      requirementsEndDate: [''],
    });
    this.recruiterForm = this.fb.group({
      recruiterControl: [''],
    });
    this.acctDetails = JSON.parse(this.apisService.decodeData());
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === ''){
      this.roleId = this.acctDetails.roles[0].id;
    }else{
      this.roleId = this.usrroleid;
    }
    this.tablegridData();
    this.reqSkillSet();
    this.getStatus();
  }
  tablegridData() {
    const data = '/' + this.page + '/' + this.rows + '?' + 'accId=' + this.acctDetails.accId + '&' + 'primaryRole=' + this.roleId + '&isAssigned=' + 1;
    this.loading = true;
    this.apisService.getApi(environment.openPositions + data).subscribe((res) => {
      this.gridData = res.data.records;
      this.loading = false;
      this.totalRecords = res.data.count;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  reset($event) {
    this.loading = true;
    this.openPositionSearchForm.reset();
    this.tablegridData();
  }
  searchData() {
    if (
      this.openPositionSearchForm.controls.requirementsStartDate.value === '' ||
      this.openPositionSearchForm.controls.requirementsStartDate.value === null ||
      this.openPositionSearchForm.controls.requirementsStartDate.value === undefined
    ) {
      this.requirementsStartDate = '';
    } else {
      const startdate =
        this.openPositionSearchForm.controls.requirementsStartDate.value;
      const reqstartDate = new Date(startdate);
      this.requirementsStartDate = this.datepipe.transform(
        reqstartDate,
        'MM-dd-yyyy'
      );
    }
    if (
      this.openPositionSearchForm.controls.requirementsEndDate.value === '' ||
      this.openPositionSearchForm.controls.requirementsEndDate.value === null ||
      this.openPositionSearchForm.controls.requirementsEndDate.value === undefined
    ) {
      this.requirementsEndDate = '';
    } else {
      const enddate = this.openPositionSearchForm.controls.requirementsEndDate.value;
      const reqendDate = new Date(enddate);
      this.requirementsEndDate = this.datepipe.transform(
        reqendDate,
        'MM-dd-yyyy'
      );
    }
    let statusCheck = '';
    if (!this.openPositionSearchForm.controls.requirementsStatus.value) {
      statusCheck = '';
    } else {
      statusCheck =
        this.openPositionSearchForm.controls.requirementsStatus.value.code;
    }
    const custNmae =
      this.openPositionSearchForm.controls.requirementsCustomerName.value;
    const pgFlag = this.page;
    const pgNo = this.rows;
    // tslint:disable-next-line:max-line-length
    const reqId = this.openPositionSearchForm.controls.requirementsJobId.value === null ? '' : this.openPositionSearchForm.controls.requirementsJobId.value;
    // tslint:disable-next-line:max-line-length
    const jobTitle = this.openPositionSearchForm.controls.requirementsJobTitle.value === null ? '' : this.openPositionSearchForm.controls.requirementsJobTitle.value;
    // tslint:disable-next-line:max-line-length
    const custName = this.openPositionSearchForm.controls.requirementsCustomerName.value === null ? '' : this.openPositionSearchForm.controls.requirementsCustomerName.value;
    const createBy = '';
    this.loader = true;
    // tslint:disable-next-line:max-line-length
    const assingId = this.openPositionSearchForm.controls.requirementsassignStatus.value === null ? 1 : this.openPositionSearchForm.controls.requirementsassignStatus.value === '' ? 1 : this.openPositionSearchForm.controls.requirementsassignStatus.value.code;
    // tslint:disable-next-line:max-line-length
    const data = '/' + pgFlag + '/' + pgNo + '?reqId=' + reqId + '&reqName=' + jobTitle + '&reqStatus=' + statusCheck + '&startDate=' + this.requirementsStartDate + '&endDate=' + this.requirementsEndDate + '&createdBy=' + createBy + '&customerName=' + custName + '&primaryRole=' + this.roleId + '&isAssigned=' + assingId + '&recruiterId=' + this.acctDetails.userId;
    this.loading = true;
    this.apisService.getApi(environment.requirementGrid + data).subscribe((res) => {
      this.loading = false;
      this.gridData = res.data.records;
      this.totalRecords = res.data.count;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.searchData();
  }
  reqSkills1(data) {
    this.display = true;
    this.reqSkillsValue = data;
  }
  getStatus() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.status).subscribe((res) => {
      this.statusDD = res.data;
      this.statusDD.forEach(element => {
        this.getStatusData[element.code] = element.label;
      });
    });
  }
  getSkillSetTable(data) {
    this.array = data;
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.specializationSkillsData.length; i++) {
      // tslint:disable-next-line: prefer-for-of
      for (let j = 0; j < this.array.length; j++) {
        if (Number(this.specializationSkillsData[i].code) === this.array[j]) {
          selectedPrePrimarySkills3.push(
            this.specializationSkillsData[i].label
          );
        } else {
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  reqSkillSet() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.specializationSkillsData = res.data;
    });
  }
  toggle1(menu, event, rowData) {
    this.rowData = rowData;
    this.items = [
      {
        label: 'Copy',
        icon: 'pi pi-fw pi-clone',
        disable: true,
        // tslint:disable-next-line:no-shadowed-variable
        command: (event, i) => {
          this.addVendor();
        },
      },
      {
        label: 'Assign',
        icon: 'fa fa-check-circle-o',
        // tslint:disable-next-line:no-shadowed-variable
        command: (event, i) => {
          this.sweetAlertForTaxTerm();
        },
      },
    ];
  }
  downLoadsToggle() {
    this.downloadOptions = [
      {
        label: 'Download XLSX',
        icon: 'pi pi-download',
        command: (event, i) => {
          this.exportExcel();
        },
      },
      {
        label: 'Download PDF',
        icon: 'pi pi-download',
        command: (event, i) => {
          this.exportPdf();
        },
      },
    ];
  }
  addVendor() {
    const data = this.rowData;
    sessionStorage.setItem('reqData', JSON.stringify(this.rowData));
    const addroute = true;
  }
  getAssignedList(event, type) {
    type === 'menu'
      ? (this.dialogForCandidates = true)
      : (this.dialogForCandidates = false);
    this.recruiterForm.reset();
    if (event.value.code === 1) {
      this.enableAssignedField = true;
      // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.updateStatus + this.acctDetails.accId).subscribe((res) => {
        this.assignedList = res.data;
      });
    }
  }

  sweetAlertForTaxTerm() {
    const data = this.rowData;
    this.rowId = this.rowData.reqId;
    if (this.dialogForCandidates === true) {
      this.recruiterFormdata =
        this.recruiterForm.controls.recruiterControl.value;
      if (
        this.recruiterFormdata === undefined ||
        this.recruiterFormdata === null ||
        this.recruiterFormdata === ''
      ) {
        this.alertError = true;
        setTimeout(() => {
          this.alertError = false;
        }, 3000);

        return;
      }
    }
    if (data.consName !== null ) {
      Swal.fire({
        text: 'Requirement already assigned',
        icon: 'success',
        confirmButtonColor: '#0D416B',
        iconColor: '#1dbb99',
        confirmButtonText: 'Ok',
        reverseButtons: false,
      });
    } else {
      this.rowId = data.reqId;
      this.TaxTermDailog = false;
      Swal.fire({
        text: 'Are you sure want to assign?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0D416B',
        cancelButtonColor: '#D0D0D0',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: false,
      }).then((result) => {
        if (result.value) {
          return this.upadteStatus();
        }
      });
    }
  }
  sweetAlertForTaxTerm1(data) {
    this.rowId = this.rowData.reqId;
    this.userDropMenuid = data.rowId;
    this.TaxTermDailog = false;
    Swal.fire({
      text: 'Are you sure want to assign?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0D416B',
      cancelButtonColor: '#D0D0D0',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: false,
    }).then((result) => {
      if (result.value) {
        return this.upadteStatus();
      }
    });
  }
  upadteStatus() {
    const idValue =
      this.apisService.getApi(environment.rmUpdateStatus + this.rowId + '/' + this.acctDetails.userId).subscribe((res) => {
        this.dialogForCandidates = false;
        if (res.hasOwnProperty('success') === true) {
          this.dialogForCandidates = false;
          this.gridData();
          this.messageService.add({ key: 'br',  severity: '', life: 3000, detail: res.message });
        } else if (res.hasOwnProperty('success') === false) {
          this.dialogForCandidates = false;
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message});
        } else {
          this.dialogForCandidates = false;
          this.messageService.add({

            key: 'br',
            severity: '',
            life: 3000,
            detail: res.error,
          });
        }
      });
  }

  exportExcel() {
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols3'] = [];
    // tslint:disable-next-line:max-line-length
    ws['!cols'] = [ {width : 10}, {width : 10}  , {width : 30} , {width : 10}, {width : 20}, {width : 20}, {width : 20}, {width : 10}, {width : 20}, {hidden : true} ];
    ws['!rows'] = [{hpt : 30}, {hpt : 30}, ];
    ws['!cols'][3] = {hidden: true};
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ExcelSheet.xlsx');
  }
  exportPdf() {
    // tslint:disable-next-line:no-shadowed-variable
    this.gridData.forEach((element) => {
      element.status = this.getStatusData[element.reqStatus];
      element.skills = this.getSkillSetTable(element.requiredSkills);
    });
    const doc = new jsPDF('p', 'pt');
    const element = document.getElementById('my-table');
    autoTable(doc, {
      headStyles: { fillColor: '#2bb9d5', cellPadding: 5 , cellWidth: 75 },
      margin: { left: 3},
      columns: this.cols3,
      body: this.gridData,
      didDrawPage: (dataArg) => {
        doc.text('Open Positions', dataArg.settings.margin.left, 30);
      }
    });
    doc.save('Open Positions.pdf');
  }
  requirementRoute(data) {
    this.name = 'requirementsSearch';
    this.gridStatus = data.reqStatus;
    this.reqID = data.reqId;
    // tslint:disable-next-line:no-string-literal
    data['from'] = 'open';
    // tslint:disable-next-line:no-string-literal
    data['name'] = 'requirementsSearch';
    sessionStorage.setItem('dataiq', JSON.stringify(data));
    this.route.navigate(['/salesOpenPositions-edit'], {
      queryParams: {
        submittedreqId: this.reqID,
        gStatus: this.gridStatus,
        name: this.name,
        reqId: data.reqId,
        tdate: data.plannedEndDate,
        from: 'open',
      },
    });
  }
}
