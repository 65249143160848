import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { MenuItem, MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-vendor-account-details',
  templateUrl: './vendor-account-details.component.html',
  styleUrls: ['./vendor-account-details.component.scss'],
  providers: [DatePipe, MessageService],
})
export class VendorAccountDetailsComponent implements OnInit {
  items: MenuItem[];
  dropDownValue = [];
  accountDetailsForm: FormGroup;
  accountDetails: any;
  customerId: string;
  staffingModeValues: any;
  url: any;
  imagePath: any;
  imageSuccess: boolean;
  fileName: any;
  pathExtension: any;
  accountId: any;
  roleId: any;
  loader: boolean;
  backroutingName: any;
  url1: boolean;
  usrGrpId: any;
  label: string;
  businessType: any = [];
  specializationSkillsMultipleIds: any = [];
  staffingModeMultipleValues: any[];
  status: any = [];
  countryOptions: any = [];
  stateoptions: any = [];
  industryOptions: any = [];
  regionOptions: any = [];
  specializationSkills: any = [];
  // It is used for the displaying the diversity Dropdown Values
  diversity = [
    { label: 'None', value: 'None' },
    { label: 'Women\'s Business Enterprise (WBE)', value: 'Women\'s Business Enterprise (WBE)' },
    { label: 'Minority Business Enterprise (MBE)', value: 'Minority Business Enterprise (MBE)', },
    { label: 'Other', value: 'Other' },
  ];
  addID: any;
  accIDD: any;
  acctType: any;
  EmployeeData: any = [];
  empnum: boolean;
  radiovalue: void;
  imageevent: any;
  name: any;
  fileType: any;
  filedata: FormData;
  profileImage: any;
  getData: any;
  actId: any;
  iamgedownloadurl: any;
  delflag: any;
  countrycodedropdown: any = [];
  editphCheckHide: boolean;
  editphSpinnerHide: boolean;
  mobinummsg1: string;
  mobinum: boolean;
  displayNumber: boolean;
  usrroleid: any;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private apisService: ApisService, private messageService: MessageService, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Account Details', routerLink: '/accountdetails' }
    ]);
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === ''){
      this.roleId = JSON.parse(apisService.decodeData()).roles[0].id;
    }else{
      this.roleId = this.usrroleid;
    }
    this.usrGrpId = JSON.parse(sessionStorage.getItem('loginData')).usrgrpid;
    this.customerId = this.route.snapshot.paramMap.get('id');
    this.actId = JSON.parse(this.apisService.decodeData());
    this.countryDropdown();
    this.industryDropdown();
    this.regionDropdown();
    this.businesTypeDD();
    this.statusDD();
    this.accountDetailsBindData();
    this.employeeDD();
  }
  ngOnInit() {
    this.accountId = JSON.parse(this.apisService.decodeData());
    this.dropDownValue = [
      { label: 'Account Details', routerLink: ['/accountdetails', this.accountId] },
      { label: 'Contacts', routerLink: ['/contacts', this.accountId.accId] },
      { label: 'Attachments', routerLink: ['/attachments', this.accountId.accId] },
      { label: 'Locations', routerLink: ['/locations', this.accountId.accId] },
    ];
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.backroutingName = sessionStorage.getItem('accountSearch-name');
    this.accountDetailsForm = this.fb.group({
      accountName: [''],
      accountURL: [''],
      status: ['', Validators.required],
      address1: ['', [Validators.maxLength(50)]],
      address2: ['', [Validators.maxLength(50)]],
      city: ['', [Validators.pattern('^[\\sa-zA-z0-9]*$'), Validators.maxLength(20)]],
      zip: ['', [Validators.maxLength(10), Validators.pattern('^[0-9-]*$')]],
      country: [''],
      state: [''],
      phone: [''],
      fax: ['', [Validators.maxLength(15), Validators.pattern('^[0-9-]*$')]],
      industry: [''],
      phNumDrp: [''],
      region: [''],
      noOfEmployees: [''],
      taxId: ['', [Validators.maxLength(20), Validators.pattern('^[0-9-]*$')]],
      stockSymbol: ['', [Validators.maxLength(10)]],
      revenue: ['', [Validators.maxLength(9)]],
      dunAndBradStreetNo: ['', [Validators.maxLength(12)]],
      businessType: [''],
      diversity: [''],
      customerNotes: ['', [Validators.maxLength(200)]],
      mailbox: ['', [Validators.maxLength(10)]],
      Emp: ['']
    });
    if (this.roleId === 13 || (this.roleId === 2 && this.usrGrpId === 0)) {
      this.accountDetailsForm.get('status').disable();
    }
    if (this.roleId === 2 && this.usrGrpId === 0) {
      this.label = 'Save';
    } else {
      this.label = 'Update';
    }
    this.getdrop();
  }
  countryids(event) {
  }
  getdrop() {
    this.countrycodedropdown = this.apisService.getdropdwon();
  }
  // It is used for the displaying the country Dropdown Values
  countryDropdown() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'ADLC').subscribe((res) => {
      this.countryOptions = res.data;
    });
  }
  // It is used for the displaying the status based on country Dropdown Values
  getStateByUrl(code) {
    if (code === null || code === '' || code === undefined) {
      this.stateoptions = [];
    }
    else {   // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.state + '/' + code).subscribe((res) => {
        this.stateoptions = res.data;
        this.accountDetailsForm.get('state').patchValue(this.stateoptions.filter((x) => x.code === this.accountDetails[0].state)[0]
        );
      });
    }
  }
  // It is used for the displaying the industry DropdownValues
  industryDropdown() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'ALIT').subscribe((res: any) => {
      this.industryOptions = res.data;
    });
  }
  // It is used for the displaying the region Dropdown Values
  regionDropdown() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'ALR').subscribe((res) => {
      this.regionOptions = res.data;
    });
  }
  businesTypeDD() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'ALBE').subscribe((res: any) => {
      this.businessType = res.data;
    });
  }
  statusDD() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'ULS').subscribe((res: any) => {
      this.status = res.data;
    });
  }
  // It is used for get data form Api and binding it
  accountDetailsBindData() {
    this.loader = true;
    const actId = JSON.parse(this.apisService.decodeData());
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.Accountget + actId.accId).subscribe((res: any) => {
      this.accountDetails = res.data;
      this.addID = res.data[0].addId;
      this.acctType = res.data[0].accType;
      sessionStorage.setItem('Act_type', this.acctType);
      this.accIDD = res.data[0].accId;
      if (this.accountDetails[0].noOfEmployees === null || this.accountDetails[0].noOfEmployees === undefined) {
        this.empnum = this.accountDetails[0].noOfEmployees;
      } else {
        this.empnum = this.accountDetails[0].noOfEmployees.toString();
      }
      this.loader = false;
      this.iamgedownloadurl = this.accountDetails[0].logo;
      this.delflag = this.accountDetails[0].dFlag;
      const mailID = this.accountDetails[0].mailExtention;
      sessionStorage.setItem('mailExtn', JSON.stringify(mailID));
      this.accountDetailsForm.get('country').patchValue(this.countryOptions.filter((x) => x.code === this.accountDetails[0].country)[0]);
      this.getStateByUrl(this.accountDetails[0].country);
      this.accountDetailsForm.get('status').patchValue(this.status.filter((x) => x.code === this.accountDetails[0].status)[0]);
      this.accountDetailsForm.get('accountName').patchValue(this.accountDetails[0].accName);
      this.accountDetailsForm.get('accountURL').patchValue(this.accountDetails[0].website);
      this.accountDetailsForm.get('address1').patchValue(this.accountDetails[0].address1);
      this.accountDetailsForm.get('address2').patchValue(this.accountDetails[0].address2);
      this.accountDetailsForm.get('mailbox').patchValue(this.accountDetails[0].mailbox);
      this.accountDetailsForm.get('city').patchValue(this.accountDetails[0].city);
      this.accountDetailsForm.get('zip').patchValue(this.accountDetails[0].zip);
      this.accountDetailsForm.get('phone').patchValue(this.accountDetails[0].phone);
      this.accountDetailsForm.get('fax').patchValue(this.accountDetails[0].fax);
      // tslint:disable-next-line: max-line-length
      this.accountDetailsForm.get('industry').patchValue(this.industryOptions.filter((x) => x.code === this.accountDetails[0].lkIndustryType)[0]);
      this.accountDetailsForm.get('region').patchValue(this.regionOptions.filter((x) => x.code === this.accountDetails[0].region)[0]);
      this.accountDetailsForm.get('noOfEmployees').patchValue(this.empnum);
      this.accountDetailsForm.get('taxId').patchValue(this.accountDetails[0].taxId);
      this.accountDetailsForm.get('stockSymbol').patchValue(this.accountDetails[0].stockSymbol);
      this.accountDetailsForm.get('revenue').patchValue(this.accountDetails[0].revenue);
      this.accountDetailsForm.get('dunAndBradStreetNo').patchValue(this.accountDetails[0].dunsNo);
      this.accountDetailsForm.get('customerNotes').patchValue(this.accountDetails[0].description);
      // tslint:disable-next-line: max-line-length
      this.accountDetailsForm.get('businessType').patchValue(this.businessType.filter((x) => x.code === this.accountDetails[0].lkBusinessEntity)[0]);
      this.radiovalue = this.accountDetailsForm.get('Emp').patchValue(this.accountDetails[0].empApprovalReqFlag);
      if (this.radiovalue === this.accountDetailsForm.controls.Emp.value) {
        this.accountDetailsForm.controls.Emp.value(true);
      }
      // tslint:disable-next-line:max-line-length
      this.accountDetailsForm.get('phNumDrp').patchValue((this.countrycodedropdown.filter((x) => x.id === this.accountDetails[0].mobileCcode)[0]));
      this.accountDetailsForm.get('diversity').patchValue(this.diversity.filter((x) => x.value === this.accountDetails[0].diversity)[0]);
    });
  }
  deleteAttachment() {
    const delimageid = '?attachmentId=' + this.accountDetails[0].logoDocId;
    // tslint:disable-next-line: deprecation
    this.loader = true;
    this.apisService.deleteApi(environment.deleteimage + delimageid).subscribe((res: any) => {
      this.loader = false;
      this.profileImageUploadData();
    });
  }
  updateAccountDetails() {
    if (this.delflag > 0 && this.imageevent !== undefined) {
      this.deleteAttachment();
    }
    // tslint:disable-next-line:max-line-length
    if (this.accountDetails[0].logoDocId === null || this.accountDetails[0].logoDocId === 0 || this.accountDetails[0].logoDocId > 0 || this.delflag === 0) {
      // tslint:disable-next-line:max-line-length
      if (this.accountDetails[0].logoDocId === 0 && this.imageevent !== undefined && this.delflag === 0 || this.accountDetails[0].logoDocId >= 0 && this.imageevent !== undefined && this.delflag === 0) {
        this.profileImageUploadData();
      }
      // tslint:disable-next-line:max-line-length
      else if (this.accountDetails[0].logoDocId === 0 || this.accountDetails[0].logoDocId === null || this.accountDetails[0].logoDocId > 0 && this.imageevent === undefined) {
        this.specializationSkillsMultipleIds = [];
        const updateAccountData = {
          addId: this.addID,
          accId: this.accIDD,
          address1: this.accountDetailsForm.get('address1').value,
          address2: this.accountDetailsForm.get('address2').value,
          // tslint:disable-next-line: max-line-length
          businessType: this.accountDetailsForm.get('businessType').value === '' || this.accountDetailsForm.get('businessType').value === null || this.accountDetailsForm.get('businessType').value === undefined ? 'A' : this.accountDetailsForm.get('businessType').value.code,
          city: this.accountDetailsForm.get('city').value,
          // tslint:disable-next-line: max-line-length
          country: this.accountDetailsForm.get('country').value === '' || this.accountDetailsForm.get('country').value === undefined || this.accountDetailsForm.get('country').value === null ? 0 : this.accountDetailsForm.get('country').value.code,
          description: this.accountDetailsForm.get('customerNotes').value,
          dunNo: this.accountDetailsForm.get('dunAndBradStreetNo').value,
          fax: this.accountDetailsForm.get('fax').value,
          // tslint:disable-next-line: max-line-length
          industry: this.accountDetailsForm.get('industry').value === '' || this.accountDetailsForm.get('industry').value === undefined || this.accountDetailsForm.get('industry').value === null ? 0 : this.accountDetailsForm.get('industry').value.code,
          mailBox: this.accountDetailsForm.get('mailbox').value,
          noOfEmployee: this.accountDetailsForm.get('noOfEmployees').value,
          phone: this.accountDetailsForm.get('phone').value,
          // tslint:disable-next-line: max-line-length
          region: this.accountDetailsForm.get('region').value === '' || this.accountDetailsForm.get('region').value === undefined || this.accountDetailsForm.get('region').value === null ? 'A' : this.accountDetailsForm.get('region').value.code,
          revenu: this.accountDetailsForm.get('revenue').value,
          // tslint:disable-next-line: max-line-length
          state: this.accountDetailsForm.get('state').value === '' || this.accountDetailsForm.get('state').value === undefined || this.accountDetailsForm.get('state').value === null ? 0 : this.accountDetailsForm.get('state').value.code,
          stockSymbol: this.accountDetailsForm.get('stockSymbol').value,
          taxId: this.accountDetailsForm.get('taxId').value,
          zip: this.accountDetailsForm.get('zip').value,
          // tslint:disable-next-line: max-line-length
          status: this.accountDetailsForm.get('status').value === '' || this.accountDetailsForm.get('status').value === undefined || this.accountDetailsForm.get('status').value === null ? 'A' : this.accountDetailsForm.get('status').value.code,
          empApprovalReqFlag: this.accountDetailsForm.get('Emp').value,
          diversity: this.accountDetailsForm.get('diversity').value,
          logoDocId: this.accountDetails[0].logoDocId,
          // tslint:disable-next-line:max-line-length
          mccode: this.accountDetailsForm.controls.phNumDrp.value === null || this.accountDetailsForm.controls.phNumDrp.value === '' || this.accountDetailsForm.controls.phNumDrp.value.id === undefined ? '' : this.accountDetailsForm.controls.phNumDrp.value.id.toString(),
        };
        this.loader = true;
        // tslint:disable-next-line: deprecation
        this.apisService.putApi(environment.Accountget, updateAccountData).subscribe((res: any) => {
          this.loader = false;
          this.accountDetailsBindData();
          if (res.hasOwnProperty('success') === true) {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
          } else if (res.hasOwnProperty('failed') === true) {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
          } else {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
          }
        });
      }
    }
  }
  uploadAccountDetailsLogoImage() {
    const data = this.url;
    const imageIURL = data.substring(data.indexOf(',') + 1);
    const body = {
      fileExtension: this.pathExtension,
      accountId: this.accountId,
      base64: imageIURL,
    };
  }
  public delete() {
    this.url = null;
    this.url1 = false;
    this.imageSuccess = false;
  }
  employeeDD() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'EMP').subscribe((res: any) => {
      this.EmployeeData = res.data;
    });
  }
  onSelectFile(event) {
    this.imageevent = event;
    this.fileName = event.target.files[0];
    this.name = this.fileName.name;
    this.fileType = this.fileName.name.split('.').pop();
    const formData: FormData = new FormData();
    formData.append('file', this.fileName);
    this.filedata = formData;
    const chooseImgPath = event.target.files[0].name;
    this.pathExtension = chooseImgPath.substring(chooseImgPath.indexOf('.') + 1);
    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    const reader = e.target;
    this.url = reader.result;
    this.imageSuccess = true;
  }
  profileImageUploadData() {
    this.loader = true;
    this.apisService.postApi(environment.profileImageUpload + 'A/' + this.actId.accId, this.filedata).subscribe((res: any) => {
      this.loader = false;
      this.profileImage = res.data;
      if (res.success === true) {
        this.addAttachement();
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
      }
      else if (res.success === false) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
      } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
      }
    });
  }
  addAttachement() {
    const actId = JSON.parse(this.apisService.decodeData());
    if (this.accountDetailsForm.invalid) {
      const controls = this.accountDetailsForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      const addData = {
        objectId: actId.accId,
        objectType: 'A',
        title: '',
        attPath: this.profileImage,
        fileName: '',
        size: '',
        extension: '',
        comments: '',
        verifiedBy: 0,
        attType: 'A'
      };
      // tslint:disable-next-line: deprecation
      this.apisService.postApi(environment.addProfile, addData).subscribe((res: any) => {
        this.getData = res;
        if (this.getData.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
          this.specializationSkillsMultipleIds = [];
          const updateAccountData = {
            addId: this.addID,
            accId: this.accIDD,
            address1: this.accountDetailsForm.get('address1').value,
            address2: this.accountDetailsForm.get('address2').value,
            // tslint:disable-next-line: max-line-length
            businessType: this.accountDetailsForm.get('businessType').value === '' || this.accountDetailsForm.get('businessType').value === null || this.accountDetailsForm.get('businessType').value === undefined ? 'A' : this.accountDetailsForm.get('businessType').value.code,
            city: this.accountDetailsForm.get('city').value,
            // tslint:disable-next-line: max-line-length
            country: this.accountDetailsForm.get('country').value === '' || this.accountDetailsForm.get('country').value === undefined || this.accountDetailsForm.get('country').value === null ? 0 : this.accountDetailsForm.get('country').value.code,
            description: this.accountDetailsForm.get('customerNotes').value,
            dunNo: this.accountDetailsForm.get('dunAndBradStreetNo').value,
            fax: this.accountDetailsForm.get('fax').value,
            // tslint:disable-next-line: max-line-length
            industry: this.accountDetailsForm.get('industry').value === '' || this.accountDetailsForm.get('industry').value === undefined || this.accountDetailsForm.get('industry').value === null ? 0 : this.accountDetailsForm.get('industry').value.code,
            mailBox: this.accountDetailsForm.get('mailbox').value,
            noOfEmployee: this.accountDetailsForm.get('noOfEmployees').value,
            phone: this.accountDetailsForm.get('phone').value,
            // tslint:disable-next-line: max-line-length
            region: this.accountDetailsForm.get('region').value === '' || this.accountDetailsForm.get('region').value === undefined || this.accountDetailsForm.get('region').value === null ? 'A' : this.accountDetailsForm.get('region').value.code,
            revenu: this.accountDetailsForm.get('revenue').value,
            // tslint:disable-next-line: max-line-length
            state: this.accountDetailsForm.get('state').value === '' || this.accountDetailsForm.get('state').value === undefined || this.accountDetailsForm.get('state').value === null ? 0 : this.accountDetailsForm.get('state').value.code,
            stockSymbol: this.accountDetailsForm.get('stockSymbol').value,
            taxId: this.accountDetailsForm.get('taxId').value,
            zip: this.accountDetailsForm.get('zip').value,
            // tslint:disable-next-line: max-line-length
            status: this.accountDetailsForm.get('status').value === '' || this.accountDetailsForm.get('status').value === undefined || this.accountDetailsForm.get('status').value === null ? 'A' : this.accountDetailsForm.get('status').value.code,
            empApprovalReqFlag: this.accountDetailsForm.get('Emp').value,
            diversity: this.accountDetailsForm.get('diversity').value,
            logoDocId: this.getData.id,
            // tslint:disable-next-line:max-line-length
            mccode: this.accountDetailsForm.controls.phNumDrp.value === null || '' || this.accountDetailsForm.controls.phNumDrp.value.id === undefined ? '' : this.accountDetailsForm.controls.phNumDrp.value.id.toString(),
          };
          this.loader = true;
          // tslint:disable-next-line: deprecation
          // tslint:disable-next-line:no-shadowed-variable
          this.apisService.putApi(environment.Accountget, updateAccountData).subscribe((res: any) => {
            this.loader = false;
            this.accountDetailsBindData();
            this.onSelectFile(undefined);
            if (res.hasOwnProperty('success') === true) {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
            } else if (res.hasOwnProperty('failed') === true) {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
            } else {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
            }
          });
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error });
        }
      });
    }
  }
  getPhon() {
    this.displayNumber = false;
    const num = this.accountDetailsForm.controls.phone.value;
    if (num === '' || num === null || num === undefined || num === '(___) ___-____') {
    }
    else {
      // tslint:disable-next-line:max-line-length
      this.editphSpinnerHide = true;
      // tslint:disable-next-line:max-line-length
      this.apisService.getApi(environment.numberValidation + this.accountId.userId + '/' + true + '?' + 'phoneNumber=' + num).subscribe(res => {
        this.mobinum = res;
        this.mobinum = res.success;
        if (this.mobinum === false) {
          this.editphSpinnerHide = false;
          this.mobinummsg1 = 'Phone number already exist';
          setTimeout(() => {
            this.mobinummsg1 = '';
          }, 5000);
          this.accountDetailsForm.get('phone').reset();
        }
        else {
          this.editphSpinnerHide = false;
          this.editphCheckHide = true;
          setTimeout(() => {
            this.editphCheckHide = false;
          }, 3000);
        }
      });
    }
  }
}
