import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { isEmpty } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-sales-customer',
  templateUrl: './sales-customer.component.html',
  styleUrls: ['./sales-customer.component.scss'],
  providers: [DatePipe, MessageService],
})
export class SalesCustomerComponent implements OnInit {
  regionOptions: any = [];
  countryOptions: any = [];
  stateoptions: any = [];
  getregionTable = {};
  // tslint:disable-next-line:no-inferrable-types
  displayModal: boolean = false;
  registrationForm: FormGroup;
  regRes: any;
  name: any;
  accountExistCheck: string;
  errMsg: string;
  ofcEmailExten: any;
  ofcMailValidation: string;
  vendorName: string;
  display: any;
  EmployeeData: any;
  type: any;
  typeTable: any;
  displayNumber: boolean;
  officenum: any;
  officenummsg: string;
  sourcedata: any;
  accountId: any;
  Accid: any;
  countryData: any;
  stateDataArray: any;
  rowstatus: any;
  accountName: any;
  salesuserId: any;
  spinnerHide: boolean;
  checkHide: boolean;
  phCheckHide: boolean;
  phSpinnerHide: boolean;
  mailSpinnerHide: boolean;
  mailCheckHide: boolean;
  website: any;
  countrycodedropdown: any = [];
  // tslint:disable-next-line:max-line-length
  constructor(private route: ActivatedRoute, private router: Router, private messageService: MessageService, private formBuilder: FormBuilder, private breadcrumbService: BreadcrumbService, private apisService: ApisService) {
    this.breadcrumbService.setItems([
      { label: 'Customers', routerLink: '/customers' }
    ]);
  }
  customerScreen: FormGroup;
  loader: boolean;
  customerGrid: any;
  customerId: any;
  AcctRowID: any;
  totalRecords: any;
  accId: any;
  rows: any = 10;
  page: any = 1;
  cols = [
    { field: 'id', header: '#', width: '3%' },
    { field: 'accName', header: 'Compnany Name', width: '10%' },
    { field: 'website', header: 'Corporate Website', width: '15%' },
    { field: 'country', header: 'Country', width: '10%' },
    { field: 'lkRegion', header: 'Region', width: '10%' },
    { field: 'action', header: 'Action', width: '3%' },
  ];
  gender1 = [
    { name: 'Male', code: 'M' },
    { name: 'Female', code: 'F' },
  ];
  table = true;
  records: boolean;
  pageCount: any;
  first: any;
  userDetails: any;
  userAccId: any;
  customerName: any;
  disable = false;
  contryTable = {};
  items: ({
    label: string; icon: string;
    // tslint:disable-next-line:no-shadowed-variable
    command: (event: any, i: any) => void;
  } | {
    label: string; icon: string;
    // tslint:disable-next-line:no-shadowed-variable
    command?: undefined;
  })[];
  industryOptions: any = [];
  getIndustryTable = {};
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchData();
    }
  }
  ngOnInit(): void {
    this.accountId = JSON.parse(this.apisService.decodeData());
    this.salesuserId = this.accountId.userId;
    // tslint:disable-next-line:align
    this.customerScreen = this.formBuilder.group({
      customerName: [''],
      customerIndustry: [''],
      region: [''],
      country: [''],
      state: ['']
    });
    this.registrationForm = this.formBuilder.group({
      customerName: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z0-9]*$'), Validators.maxLength(60)]],
      websiteURL: ['', [Validators.required, Validators.maxLength(60)]],
      NumOfEmployees: [''],
      firstName: ['', [Validators.required, Validators.maxLength(25)]],
      middleName: ['', [Validators.maxLength(15)]],
      lastName: ['', [Validators.required, Validators.maxLength(25)]],
      officePhone: ['', [Validators.required, Validators.maxLength(15)]],
      mobileDrp: [''],
      // tslint:disable-next-line:max-line-length
      officeEmail: ['', [ Validators.required,  Validators.pattern('^[[a-zA-Z0-9.+-_]+@[a-zA-Z0-9.+-_]+.[a-zA-Z]{2,5}]*$'), Validators.maxLength(60)], ],
      address1: ['', [Validators.maxLength(50)]],
      address2: ['', [Validators.maxLength(50)]],
      city: ['', [Validators.maxLength(20)]],
      rcountry: ['', Validators.required],
      rstate: [''],
      gender: [''],
      mailbox: ['', [Validators.maxLength(10)]],
      zipcode: ['', [Validators.maxLength(10),Validators.pattern('^[0-9-]*$')]],
      source: ['', Validators.required]
    });
    this.userDetails = JSON.parse(this.apisService.decodeData());
    this.userAccId = this.userDetails.accId;
    this.regionDropdown();
    this.searchData();
    this.countryDropdown();
    this.getdrop();
  }
  countryids(event) {
  }
  getdrop() {
    this.countrycodedropdown = this.apisService.getdropdwon();
  }
  sourceDD() {
    this.apisService.getApi(environment.lookupsData + 'SOF').subscribe((res: any) => {
      this.sourcedata = res.data;
    });
  }
  industryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'ALIT').subscribe((res: any) => {
      this.industryOptions = res.data;
      this.industryOptions.forEach(element => {
        this.getIndustryTable[element.code] = element.label;
      });
    });
  }
  regionDropdown() {
    this.apisService.getApi(environment.lookupsData + 'ALR').subscribe((res) => {
        this.regionOptions = res.data;
        this.regionOptions.forEach(element => {
          this.getregionTable[element.code] = element.label;
        });
      });
  }
  copyInputMessage(data) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (data));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
  countryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'ADLC').subscribe((res) => {
        this.countryOptions = res.data;
        this.registrationForm.controls.rcountry.patchValue(this.countryOptions.filter((x) => x.code === 'USA')[0]);
        this.countryOptions.forEach((element) => {
          this.contryTable[element.code] = element.label;
        });
      });
  }
  getStateByUrl(code) {
    this.customerScreen.controls.state.setValue('');
    if (code === null || code === '' || code === undefined) {
      this.stateoptions = [];
    }
    else {
      this.apisService.getApi(environment.state + '/' + code).subscribe((res) => {
        this.stateoptions = res.data;
      });
    }
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.searchData();
  }
  emailToast(){
    if (this.registrationForm.controls.officeEmail.invalid && this.registrationForm.controls.officeEmail.errors.pattern) {
    }
    // tslint:disable-next-line: max-line-length
    else if (this.registrationForm.controls.officeEmail.value === '' || this.registrationForm.controls.officeEmail.value === null || this.registrationForm.controls.officeEmail.value === undefined) {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please enter email.' });
    }
    else {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
    }
  }
  toggle(menu, event, rowData) {
    sessionStorage.setItem('salescustaccid', rowData.accId);
    sessionStorage.setItem('Act_type', rowData.lkAccountType);
    sessionStorage.setItem('SAct_type', rowData.lkAccountType);
    this.items = [
      {
        label: 'Contacts',
        icon: 'pi pi-user',
        // tslint:disable-next-line:no-shadowed-variable
        command: (event, i) => {
          this.contacts(rowData);
        },
      },
    ];
  }
  searchData() {
    // tslint:disable-next-line:max-line-length
    const accountCountry = this.customerScreen.controls.country.value === null || this.customerScreen.controls.country.value === undefined || this.customerScreen.controls.country.value === '' ? '' : this.customerScreen.controls.country.value.code;
    // tslint:disable-next-line:max-line-length
    const accountState = this.customerScreen.controls.state.value === '' || this.customerScreen.controls.state.value === null || this.customerScreen.controls.state.value === undefined ? '' : this.customerScreen.controls.state.value.code;
    // tslint:disable-next-line:max-line-length
    const region = this.customerScreen.controls.region.value === null || this.customerScreen.controls.region.value === undefined || this.customerScreen.controls.region.value === '' ? '' : this.customerScreen.controls.region.value.code;
    // tslint:disable-next-line:semicolon
    const data = this.page + '/' + this.rows + '/' + this.salesuserId + '?country=' + accountCountry + '&state=' + accountState + '&accType=' + 'C' + '&region=' + region
    this.loader = true;
    this.apisService.getApi(environment.salescontacts + data).subscribe((res: any) => {
      this.loader = false;
      this.customerGrid = res.data.records;
      this.totalRecords = res.data.count;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  reset() {
    this.customerScreen.reset();
    this.searchData();
  }
  goToLink(url: string) {
    const addressLink = url.includes('http') ? url : `http://${url}`;
    window.open(addressLink, '_blank');
  }
  addlocation() {
    this.displayModal = true;
    this.registrationForm.reset();
    this.registrationForm.controls.mobileDrp.patchValue({
      code: '+1',
      flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png',
      id: '2',
      label: 'UNITED STATES',
    });
    this.getStateByUrl('USA');
    this.registrationForm.controls.gender.patchValue({
      name: 'Male',
      code: 'M',
    });
    this.employeeDD();
    this.sourceDD();
  }
  contacts(rowData) {
    this.Accid = rowData.accId;
    this.router.navigate(['/contacts/', this.Accid], {
      queryParams: {
        salescustomeraacname: rowData.accName
      }
    });
    sessionStorage.setItem('salescustAccId', this.Accid);
  }
  register() {
    if (this.registrationForm.invalid) {
      const controls = this.registrationForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      this.disable = true;
      this.loader = true;
      const body = {
        accId: 0,
        accName: this.registrationForm.controls.customerName.value,
        accType: 'C',
        address1: this.registrationForm.controls.address1.value,
        address2: this.registrationForm.controls.address2.value,
        city: this.registrationForm.controls.city.value,
        country: this.registrationForm.controls.rcountry.value.code,
        firstName: this.registrationForm.controls.firstName.value,
        gender: this.registrationForm.controls.gender.value.code,
        lastName: this.registrationForm.controls.lastName.value,
        mailBox: this.registrationForm.controls.mailbox.value,
        middleName: this.registrationForm.controls.middleName.value,
        // tslint:disable-next-line:max-line-length
        noOfEmployees: this.registrationForm.controls.NumOfEmployees.value === null || this.registrationForm.controls.NumOfEmployees.value === undefined || this.registrationForm.controls.NumOfEmployees.value === '' ? '' : this.registrationForm.controls.NumOfEmployees.value.code,
        officeMail: this.registrationForm.controls.officeEmail.value,
        officePhone: this.registrationForm.controls.officePhone.value,
        // tslint:disable-next-line:max-line-length
        state: this.registrationForm.controls.rstate.value === null || this.registrationForm.controls.rstate.value === undefined || this.registrationForm.controls.rstate.value === '' ? '' : this.registrationForm.controls.rstate.value.code,
        webSite: this.registrationForm.controls.websiteURL.value,
        zip: this.registrationForm.controls.zipcode.value,
        source: this.registrationForm.controls.source.value.code,
        // tslint:disable-next-line:max-line-length
        occode: this.registrationForm.controls.mobileDrp.value === null || '' || this.registrationForm.controls.mobileDrp.value.id === undefined ? '' : this.registrationForm.controls.mobileDrp.value.id.toString(),
      };
      this.apisService.postApi(environment.acctreg, body).subscribe((res) => {
        this.regRes = res;
        this.disable = false;
        this.loader = false;
        this.displayModal = false;
        if (this.regRes.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.regRes.message});
          this.reset();
        } else if (this.regRes.hasOwnProperty('success') === false) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.regRes.message});
        } else {
          this.messageService.add({key: 'br', severity: '', life: 3000, detail: this.regRes.error });
        }
      });
    }
  }
  reset1() {
    this.loader = true;
    this.registrationForm.reset();
    this.registrationForm.controls.rcountry.patchValue({ code: 'USA', id: 'ADLC', label: 'UNITED STATES' });
    this.registrationForm.controls.mobileDrp.patchValue({
      code: '+1',
      flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png',
      id: '2',
      label: 'UNITED STATES',
    });
    this.getStateByUrl('USA');
    this.registrationForm.controls.gender.patchValue({   name: 'Male', code: 'M' });
    this.accountExistCheck = '';
    this.errMsg = '';
    this.loader = false;
  }
  officeEmailExtensionCheck() {
    const email = this.registrationForm.controls.officeEmail.value;
    if (email === null || email === '' || email === undefined) {
    } else {
      if (this.registrationForm.controls.officeEmail.invalid && this.registrationForm.controls.officeEmail.errors.pattern) {
      }
      else {
        this.mailSpinnerHide = true;
        this.apisService.getApi(environment.emailVaildation + email).subscribe((res) => {
            this.ofcEmailExten = res;
            if (this.ofcEmailExten.validation) {
              this.mailSpinnerHide = false;
              this.ofcMailValidation = 'Email already exist';
              this.registrationForm.get('officeEmail').reset();
              setTimeout(() => {
                this.ofcMailValidation = '';
              }, 3000);
            } else {
              this.mailSpinnerHide = false;
              this.mailCheckHide = true;
              setTimeout(() => {
                this.mailCheckHide = false;
              }, 3000);
              this.ofcMailValidation = '';
            }
          });
      }
    }
  }
  getTypeDropDown() {
    this.apisService.getApi(environment.lookupsData + 'ALAT').subscribe((res) => {
        this.type = res.data;
        this.type.forEach((element) => {
          this.typeTable[element.code] = element.label;
        });
      });
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.display = routeParams.params.vendorRegistrationForm;
    if (this.display === 'V') {
      this.vendorName = 'Vendor';
    } else {
      this.vendorName = 'Company';
    }
    this.countryDropdown();
    this.employeeDD();
  }
  employeeDD() {
    this.apisService.getApi(environment.lookupsData + 'EMP').subscribe((res: any) => {
        this.EmployeeData = res.data;
      });
  }
  getPhon() {
    this.displayNumber = false;
    const num = this.registrationForm.controls.officePhone.value;
    if (num === '' || num === null || num === undefined || num === '(___) ___-____') {
    }
    else {
      this.phSpinnerHide = true;
      this.apisService.getApi(environment.phonerequired + num).subscribe(res => {
        this.officenum = res;
        this.officenum = res.data.isNumberExisted;
        if (this.officenum === true) {
          this.phSpinnerHide = false;
          this.officenummsg = 'office number already exist';
          setTimeout(() => {
            this.officenummsg = '';
          }, 5000);
          this.registrationForm.get('officePhone').reset();
        }
        else {
          this.phSpinnerHide = false;
          this.phCheckHide = true;
          setTimeout(() => {
            this.phCheckHide = false;
          }, 3000);
        }
      });
    }
  }
  checkButton(data) {
    this.rowstatus = data.lkStatus;
  }
  officeCheck() {
    const customerName = this.registrationForm.controls.customerName.value;
    if (customerName) {
      this.spinnerHide = true;
      this.apisService.getApi(environment.NameValidation + '?' + 'accName=' + customerName).subscribe((res) => {
          this.accountName = res;
          if (this.accountName.validation) {
            this.spinnerHide = false;
            this.messageService.add({  key: 'br', severity: '', life: 3000,  detail: 'Account name already existed.' });
            this.registrationForm.get('customerName').reset();
          }
          else {
            this.spinnerHide = false;
            this.checkHide = true;
            setTimeout(() => {
              this.checkHide = false;
            }, 3000);
          }
        });
    }
  }
  urlvalidation() {
    this.website = this.registrationForm.controls.websiteURL.value;
    if (true) {
      this.website = this.website.replace('http://www.', '');
      this.website = this.website.replace('http://', '');
      this.website = this.website.replace('http:', '');
    }
    if (true) {
      this.website = this.website.replace('https://www.', '');
      this.website = this.website.replace('https://', '');
      this.website = this.website.replace('https:', '');
    }
    if (true) {
      this.website = this.website.replace('www.', '');
    }
    this.registrationForm.controls.websiteURL.patchValue(this.website);
  }

}
