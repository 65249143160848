<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
    <p-panel>
        <p-header> <strong>Requirement Copy</strong>
            <em class="pi pi-arrow-left panelBackRoute" (click)="goto()"></em>
        </p-header>
        <form [formGroup]="copyRequirementsForm">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                    <label class="inputfields">Position Title<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" maxlength="51" pKeyFilter="int" autocomplete="off" pInputText
                        formControlName="positionTitle" placeholder="Enter Title(Ex : Project Developer)">
                    <div>
                        <span class="validationtest"
                            *ngIf="this.copyRequirementsForm.get('positionTitle').touched && this.copyRequirementsForm.get('positionTitle').invalid && this.copyRequirementsForm.get('positionTitle').errors.required">
                            Please enter position title
                        </span>
                        <span class="validationtest"
                            *ngIf="this.copyRequirementsForm.get('positionTitle').invalid && this.copyRequirementsForm.get('positionTitle').errors.pattern">
                            Please enter alphabets only
                        </span>
                    </div>
                    <div>
                        <span class="validationtest"
                            *ngIf=" this.copyRequirementsForm.get('positionTitle').hasError('maxlength')"> Please enter
                            50
                            alphabets only </span>
                    </div>
                </div>
                <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                    <label class="inputfields">Position Category<span class="mandatoryColor">*</span></label>
                    <p-dropdown [options]="positionData" placeholder="Select Position Category " optionValue="id"
                        [showClear]="true" (onChange)="getRolesBasedonCategory($event.value)" optionLabel="jobCategory"
                        formControlName="positionCategory"></p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.copyRequirementsForm.get('positionCategory').touched && this.copyRequirementsForm.get('positionCategory').invalid && this.copyRequirementsForm.get('positionCategory').errors.required">
                            Please select position category
                        </span>
                    </div>
                </div>
                <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                    <label class="inputfields">Job Role<span class="mandatoryColor">*</span> </label>
                    <p-dropdown [options]="jobTitleData" placeholder="Select Job Role " optionValue="id"
                        [showClear]="true" (onChange)="getMinrate($event.value)" optionLabel="title"
                        formControlName="jobRole"></p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.copyRequirementsForm.get('jobRole').touched && this.copyRequirementsForm.get('jobRole').invalid && this.copyRequirementsForm.get('jobRole').errors.required">
                            Please select Job Role
                        </span>
                    </div>
                </div>
                <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                    <label class="inputfields">Department<span class="mandatoryColor">*</span></label>
                    <p-dropdown [options]="departmentList" placeholder="Select Department" [showClear]="true"
                        optionLabel="name" formControlName="department"></p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.copyRequirementsForm.get('department').touched && this.copyRequirementsForm.get('department').invalid && this.copyRequirementsForm.get('department').errors.required">
                            Please select department
                        </span>
                    </div>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class=" p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                    <label class="inputfields">Min Rate </label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                        <input type="text" pInputText placeholder="Min Rate" disabled="true" formControlName="minRate">
                        <span class="p-inputgroup-addon">/Hr</span>
                    </div>
                </div>
                <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                    <label class="inputfields">Max Rate <span class="mandatoryColor">*</span></label>
                    <div class="p-inputgroup"><span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue
                            : true)}} </span>
                        <input type="text" pInputText placeholder="Max Rate" formControlName="maxRate">
                        <span class="p-inputgroup-addon">/Hr</span>
                    </div>

                    <span class="validationtest"
                    *ngIf="this.copyRequirementsForm.get('maxRate').touched && this.copyRequirementsForm.get('maxRate').invalid && this.copyRequirementsForm.get('maxRate').errors.required">
                    Please enter max rate
                </span>
                    <span class="validationtest"
                        *ngIf="this.copyRequirementsForm.get('maxRate').invalid && this.copyRequirementsForm.get('maxRate').errors.pattern">
                        Please enter numbers only
                    </span>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                    <label class="inputfields">Number of Positions<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" maxlength="12" pKeyFilter="int" autocomplete="off" pInputText
                        optionValue="code" formControlName="positions" placeholder="Enter Number of Positions ">
                    <div>
                        <span class="validationtest"
                            *ngIf="this.copyRequirementsForm.get('positions').touched && this.copyRequirementsForm.get('positions').invalid && this.copyRequirementsForm.get('positions').errors.required">
                            Please enter number of Positions
                        </span>
                        <span class="validationtest"
                            *ngIf=" this.copyRequirementsForm.get('positions').hasError('maxlength')">
                            Please enter 11 alphabets only
                        </span>
                        <span class="validationtest"
                        *ngIf="this.copyRequirementsForm.get('positions').invalid && this.copyRequirementsForm.get('positions').errors.pattern">
                        Please enter numbers only
                    </span>
                    </div>
                </div>
                <!-- <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                    <label class="inputfields">Work Location<span class="mandatoryColor">*</span></label>
                    <p-dropdown [options]="locationList" placeholder="Select Work Location"  [showClear]="true"
                        optionLabel="name" optionValue="locId" formControlName="workLocation"></p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.copyRequirementsForm.get('workLocation').touched && this.copyRequirementsForm.get('workLocation').invalid && this.copyRequirementsForm.get('workLocation').errors.required">
                            Please select Work Location
                        </span>
                    </div>
                </div> -->
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory" pTooltip="{{this.patchlocationString}}"
                    tooltipPosition="top">
                    <label class="inputfields">Work Location<span class="mandatoryColor">*</span></label>
                    <p-dropdown [options]="locationList" placeholder="Select Work Location" optionLabel="name"
                        [showClear]="true" formControlName="workLocation" appendTo="body"
                        (onChange)="saveLocation($event)">
                        <ng-template let-data pTemplate="item">
                            <div pTooltip="{{data.name + '\n' + data.address1+',' + data.city + ',' + data.state + ',' + data.country}}"
                                tooltipPosition="right">
                                {{ data.name }}
                            </div>
                        </ng-template>

                    </p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.copyRequirementsForm.controls.workLocation.touched && this.copyRequirementsForm.controls.workLocation.invalid && this.copyRequirementsForm.controls.workLocation.errors.required">
                            Please select work location
                        </span>
                    </div>
                </div>
                <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                    <label class="inputfields">Required Experience<span class="mandatoryColor">*</span></label>
                    <p-dropdown [options]="exprienceList" placeholder="Select Required Experience" [showClear]="true"
                        optionLabel="label" optionValue="code" formControlName="experience"></p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.copyRequirementsForm.get('experience').touched && this.copyRequirementsForm.get('experience').invalid && this.copyRequirementsForm.get('experience').errors.required">
                            Please select experience
                        </span>
                    </div>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                    <label class="inputfields">Hire Type<span class="mandatoryColor">*</span></label>
                    <p-dropdown [options]="hireTypeList" (onChange)="typeData($event.value)" [showClear]="true"
                        placeholder="Select Hire Type" optionLabel="label" optionValue="code"
                        formControlName="hireType"></p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.copyRequirementsForm.get('hireType').touched && this.copyRequirementsForm.get('hireType').invalid && this.copyRequirementsForm.get('hireType').errors.required">
                            Please select hire Type
                        </span>
                    </div>
                </div>
                <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                    <label class="inputfields">Planned Start Date<span class="mandatoryColor">*</span></label>
                    <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="startDate" placeholder="MM-DD-YYYY"
                        [showIcon]="true" [showButtonBar]="true">
                    </p-calendar>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.copyRequirementsForm.get('startDate').touched && this.copyRequirementsForm.get('startDate').invalid && this.copyRequirementsForm.get('startDate').errors.required">
                            Please select start date
                        </span>
                    </div>
                </div>
                <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3" *ngIf=endDisplay>
                    <label class="inputfields">Planned End Date</label>
                    <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="endDate" placeholder="MM-DD-YYYY"
                        [showIcon]="true" [showButtonBar]="true">
                    </p-calendar>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <label>Employee approval required? </label>&nbsp;
                    <!-- <p-radioButton name="approval" value="Y" label="Yes" formControlName="approval" inputId="Yes">
                    </p-radioButton>
                    <p-radioButton name="approval" value="N" label="No" formControlName="approval" inputId="No"
                        class="radiospac"> -->

                    <p-radioButton name="approval" value="Y" label="Yes" formControlName="approval" inputId="Yes" [disabled]="true">
                    </p-radioButton>
                    <p-radioButton name="approval" value="N" label="No" formControlName="approval" inputId="No" [disabled]="true"
                        class="radiospac">
                    </p-radioButton>
                </div>
            </div><br>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                    <label class="inputfields">Billing Type<span class="mandatoryColor">*</span></label>
                    <p-dropdown [options]="billingList" placeholder="Select Billing Type" [showClear]="true"
                        optionLabel="label" optionValue="code" formControlName="billingType"></p-dropdown>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.copyRequirementsForm.get('billingType').touched && this.copyRequirementsForm.get('billingType').invalid && this.copyRequirementsForm.get('billingType').errors.required">
                            Please select billing type
                        </span>
                    </div>
                </div>
                <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                    <label class="inputfields">Duration</label>
                    <div class="p-inputgroup">
                        <!-- <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span> -->
                        <input type="text" pInputText placeholder="Duration" formControlName="duration"
                            (blur)="getduration()">
                        <span class="p-inputgroup-addon">Months</span>
                    </div>
                    <span class="validationtest" *ngIf="this.shwmsg">{{this.showmsg}}</span>
                    <span class="validationtest"
                    *ngIf="this.copyRequirementsForm.get('duration').invalid && this.copyRequirementsForm.get('duration').errors.pattern">
                    Please enter numbers only
                </span>
                </div>
                <div class="p-field p-col-3 p-md-3 p-sm-12 p-lg-3">
                    <label class="inputfields">Per Week</label>
                    <div class="p-inputgroup">
                        <!-- <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}}</span> -->
                        <input type="text" pInputText placeholder="Per Week" formControlName="perWeek" maxlength="12">
                        <span class="p-inputgroup-addon">Hrs</span>
                    </div>
                    <div>
                        <span class="validationtest"
                            *ngIf=" this.copyRequirementsForm.get('perWeek').hasError('maxlength')">
                            Please enter 11 alphabets only
                        </span>
                        <span class="validationtest"
                            *ngIf="this.copyRequirementsForm.get('perWeek').invalid && this.copyRequirementsForm.get('perWeek').errors.pattern">
                            Please enter numbers only
                        </span>
                    </div>
                </div>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory skills">
                    <label class="inputfields">Skill Set<span class="mandatoryColor">*</span></label>
                    <p-multiSelect [options]="PreferredSkills" optionLabel="label" [virtualScroll]="true"
                        [itemSize]="34" formControlName="requirementSkillSet" 
                        (onChange)="skillsetOthers($event.value)">
                    </p-multiSelect>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.copyRequirementsForm.controls.requirementSkillSet.touched && this.copyRequirementsForm.controls.requirementSkillSet.invalid && this.copyRequirementsForm.controls.requirementSkillSet.errors.required">
                            Please select skill set
                        </span>
                    </div>
                    <div class="skillstyle">
                        <label><strong>Skill Set: </strong></label>
                        <span>{{selectedPrimaryskills.toString()}}</span>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory skills">
                    <label class="inputfields">Preferred Skill Set<span class="mandatoryColor">*</span></label>
                    <p-multiSelect [options]="PreferredSkills" optionLabel="label" [virtualScroll]="true"
                    (onChange)="resetskills($event,$event.value)" [itemSize]="34" formControlName="requirementPreferredSkillSet">
                    </p-multiSelect>
                    <div class="skillstyle">
                        <label><strong>Preferred Skill Set: </strong></label>
                        <span >{{preferredSkills.toString()}}</span>
                    </div><br>
                </div>
            </div>
            <div class="p-fluid  p-grid" *ngIf="otherskills">
                <div class="p-field p-col-12 p-md-6 p-sm-12 p-lg-6">
                    <label class="inputfields">Others Skills<span class="mandatoryColor">*</span></label>
                    <textarea rows="5" maxlength="301" cols="30" (blur)=onBlur($event.target.value)
                        placeholder="Enter Others Skills (Ex: Angular, Java)" formControlName="otherskills"
                        pInputTextarea></textarea>
                    <span class="validationtest" *ngIf="this.copyRequirementsForm.get('otherskills').touched &&
                             this.copyRequirementsForm.get('otherskills').invalid &&
                              this.copyRequirementsForm.get('otherskills').errors.required">
                        Please enter others skills
                    </span>
                    <span class="validationtest"
                        *ngIf=" this.copyRequirementsForm.get('otherskills').hasError('maxlength')"> Please enter
                        300
                        alphabets only </span>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-6 p-md-6 p-sm-12 p-lg-6">
                    <label class="inputfields">Job Description<span class="mandatoryColor">*</span></label>
                    <textarea rows="5" cols="30" maxlength="2001" placeholder="Enter Description"
                        formControlName="description" pInputTextarea autoResize="false"></textarea>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.copyRequirementsForm.get('description').touched && this.copyRequirementsForm.get('description').invalid && this.copyRequirementsForm.get('description').errors.required">
                            Please enter job description
                        </span>
                    </div>
                    <div>
                        <span class="validationtest"
                            *ngIf=" this.copyRequirementsForm.get('description').hasError('maxlength')">
                            Please enter 2000 alphabets only
                        </span>
                    </div>
                </div>

                <div class="p-field p-col-6 p-md-6 p-sm-12 p-lg-6">
                    <label class="inputfields">Responsibilities<span class="mandatoryColor">*</span></label>
                    <textarea rows="5" cols="30" maxlength="2001" placeholder="Enter Responsibilities"
                        formControlName="responsibilities" pInputTextarea autoResize="false"></textarea>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.copyRequirementsForm.get('responsibilities').touched && this.copyRequirementsForm.get('responsibilities').invalid && this.copyRequirementsForm.get('responsibilities').errors.required">
                            Please enter responsibilities
                        </span>
                    </div>
                    <div>
                        <span class="validationtest"
                            *ngIf=" this.copyRequirementsForm.get('responsibilities').hasError('maxlength')">
                            Please enter 2000 alphabets only
                        </span>
                    </div>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-6 p-md-6 p-sm-12 p-lg-6">
                    <label class="inputfields">Qualification<span class="mandatoryColor">*</span></label>
                    <textarea rows="5" cols="30" maxlength="501" placeholder="Enter Qualification"
                        formControlName="qualification" pInputTextarea autoResize="false"></textarea>
                    <div>
                        <span class="validationtest"
                            *ngIf="this.copyRequirementsForm.get('qualification').touched && this.copyRequirementsForm.get('qualification').invalid && this.copyRequirementsForm.get('qualification').errors.required">
                            Please enter qualification
                        </span>
                    </div>
                    <div>
                        <span class="validationtest"
                            *ngIf=" this.copyRequirementsForm.get('qualification').hasError('maxlength')">
                            Please enter 500 alphabets only
                        </span>
                    </div>
                </div>
                <div class="p-field p-col-6 p-md-6 p-sm-12 p-lg-6">
                    <label class="inputfields"> Comments</label>
                    <textarea rows="5" maxlength="501" cols="30" placeholder="Enter Comments" formControlName="comments"
                        pInputTextarea autoResize="false"></textarea>
                    <div>
                        <span class="validationtest"
                            *ngIf=" this.copyRequirementsForm.get('comments').hasError('maxlength')">
                            Please enter 500 alphabets only
                        </span>
                    </div>
                </div>
            </div>
        </form>
        <div class="btnsCSS1">
            <button pButton class="addhBtn" type="button" icon='pi pi-refresh' (click)="resetreq()" label="Reset"></button>
            <button pButton class="addhBtn" type="button" icon='pi pi-save' (click)="copyRequirement()"
                [disabled]="disable" label="Save"></button>
        </div>
        <br><br>
    </p-panel>
</p-card>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight">
                <div class="laodersize">
                    <img alt="babylon-layout" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>