import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ApisService } from 'src/app/services/apis.service';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
@Component({
    selector: 'app-vendor-defination',
    templateUrl: './vendor-defination.component.html',
    styleUrls: ['./vendor-defination.component.scss'],
    providers: [DatePipe, MessageService],
})
export class VendorDefinationComponent implements OnInit {
    displaydata1: boolean;
    tierId: any;
    showStatusDropDown: boolean;
    tierdefinitionform: any;
    tierHeader: string;
    tier: any;
    stausData: any = [];
    display: boolean;
    addBtn: boolean;
    updateBtn: boolean;
    buttonHandle = false;
    loading = false;
    accDetails: any;
    gridData: any;
    norecorddata: any;
    totalrecords: any;
    rowData: any;
    getStatusTable = {};
    items: { label: string; icon: string; command: () => void; }[];
    vendorEditForm: FormGroup;
    vendorId: any;
    vendorTierList: any;
    accId: any;
    status1: { name: string; value: string; }[];
    errormgs: boolean;
    vendorrowId: any;
    loader: boolean;
    disable: boolean;
    editlocation: boolean;
    accType: any;
    cols: { field: string; header: string; width: string; }[];
    pageCount: any;
    page: any = 1;
    rows: any = 10;
    first: any;

    // tslint:disable-next-line:max-line-length
    constructor(private apisService: ApisService, private fb: FormBuilder,  private breadcrumbService: BreadcrumbService, private messageService: MessageService, private router: Router) {
        this.breadcrumbService.setItems([
            { label: 'Tiers', routerLink: '/VendorDefinition' },
        ]);
    }

    ngOnInit(): void {
        const userdetails = JSON.parse(this.apisService.decodeData());
        this.accId = userdetails.accId;
        this.accType = userdetails.accType;
          // It is used for the displaying the table header details
        if (this.accType === 'V') {
            this.cols = [
                { field: 'tierName', header: 'Tier', width: '2%' },
                { field: 'discount', header: 'Discount(%)', width: '2%' },
                { field: 'daysDelay', header: 'Days Delay', width: '2%' },
                { field: 'status', header: 'Status', width: '2%' },
                { field: 'vendorCount', header: 'Subcontractor', width: '2%' },
                { field: '', header: 'Action', width: '1%' },
            ];
        }
        else {
            this.cols = [
                { field: 'tierName', header: 'Tier', width: '2%' },
                { field: 'discount', header: 'Discount(%)', width: '2%' },
                { field: 'daysDelay', header: 'Days Delay', width: '2%' },
                { field: 'status', header: 'Status', width: '2%' },
                { field: 'vendorCount', header: 'Vendor Count', width: '2%' },
                { field: '', header: 'Action', width: '1%' },
            ];
        }
        // It is used for displaying status dropdown values
        this.status1 = [
            { name: 'Active', value: 'A' },
            { name: 'Inactive', value: 'I' }
        ];
        this.tierdefinitionform = this.fb.group({
            status: ['', Validators.required],
            discount: ['', Validators.required],
            days: ['', [Validators.required]],
            tiers: ['', Validators.required],
        });
        this.vendorEditForm = this.fb.group({
            vendorName1: ['', Validators.required],
            vendorAddTier: ['', Validators.required],
            status: [''],
            days: ['', [Validators.maxLength(2), Validators.pattern('[0-9]*')]],
            discount: [''],
            maxSubmission: ['', Validators.required]
        });
        this.getTiergridData();
        this.apisService.getApi(environment.lookupsData + 'RLS').subscribe((res) => {
            this.stausData = res.data;
            this.stausData.forEach(element => {
                this.getStatusTable[element.code] = element.label;
            });
        });

        this.getAddVendorNames();
    }
    // It is used to bind the table data
    addData(data, value) {
        if (value === 'editValue') {
            this.displaydata1 = false;
            this.tierId = data.id;
            this.showStatusDropDown = true;
            this.tierHeader = 'Update Tier Definition';
            this.tierdefinitionform.controls.tiers.patchValue(this.tier.filter((x) => x.vendorType === data.tierName)[0]);
            this.tierdefinitionform.controls.discount.patchValue(data.discount);
            this.tierdefinitionform.controls.days.patchValue(data.daysDelay);
            this.tierdefinitionform.controls.status.patchValue(this.stausData.filter((x) => x.code === data.status)[0]);
            this.display = true;
            this.addBtn = false;
            this.updateBtn = true;
            this.tierdefinitionform.controls.tiers.disable();
        } else {
            this.tierdefinitionform.reset();
            this.displaydata1 = false;
            this.display = true;
            this.updateBtn = false;
            this.showStatusDropDown = false;
            this.tierHeader = 'Add Tier Definition';
            this.tierdefinitionform.reset();
            this.addBtn = true;
            this.tierdefinitionform.controls.tiers.enable();
        }
    }
    // It is used for displaying vendor tiers names
    getAddVendorNames() {
        const pgNo = this.page;
        const recordPage = this.rows;
        const pagecount = pgNo + '/' + recordPage;
        this.apisService.getApi(environment.vendorTier + pagecount + '/' + this.accId).subscribe(res => {
            this.vendorTierList = res.data;
        });
    }
    // It is used to display vendor tiers exist or not
    getVendorTierExist(data) {
        this.errormgs = false;
        if (data.value.status === 'A') {
            this.vendorEditForm.controls.discount.patchValue(
                data.value.discount
            );
            this.vendorEditForm.controls.days.patchValue(
                data.value.daysDelay);
            this.vendorEditForm.controls.status.patchValue(this.status1.filter(x => x.value === data.value.status)[0]);
        } else {
            this.vendorEditForm.controls.vendorAddTier.reset();
            this.vendorEditForm.controls.discount.reset();
            this.vendorEditForm.controls.days.reset();
            this.vendorEditForm.controls.status.reset();
            setTimeout(() => {
                this.errormgs = true;
            }, 500);

        }
    }
    // It is used to show table data
    getTiergridData() {
        this.loading = true;
        const pgNo = this.page;
        const recordPage = this.rows;
        const pagecount = pgNo + '/' + recordPage;
        this.accDetails = JSON.parse(this.apisService.decodeData());
        this.apisService.getApi(environment.tiers + '/' + pagecount + '/' + this.accDetails.accId).subscribe((res) => {
            this.gridData = res.data;
            this.totalrecords = this.gridData.length;
            if (this.gridData.length >= 5) {
                this.buttonHandle = true;
            } else {
                this.buttonHandle = false;
            }
            this.loading = false;
        });
    }

    // It is used to navigate add vendor tiers screen
    addRoute(rowData) {
        this.router.navigate(['/verdor-tiers'], {
            queryParams: {
                t1: rowData.id,
                tN: rowData.tierName,
            },
        });
    }
    // It is used to bind the table data
    addDailong(data) {
        this.vendorId = data.id;
        this.vendorEditForm.reset();
        this.display = true;
        this.vendorEditForm.controls.vendorName1.patchValue(data.tierName);
        this.vendorEditForm.controls.discount.patchValue(data.discount);
        this.vendorEditForm.controls.days.patchValue(data.daysDelay);
        this.vendorEditForm.controls.status.patchValue(this.status1.filter(x => x.value === data.status)[0]);
    }

    // It is used to show action pop up
    toggle(event, data, rowData) {
        this.rowData = rowData;
        if (this.accType === 'C') {
            this.items = [
                {
                    label: 'Vendors',
                    icon: 'pi pi-arrow-circle-right',
                    command: () => {
                        this.addRoute(rowData);
                    }
                },
                {
                    label: 'Edit',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.addDailong(rowData);
                    }
                }
            ];
        }
        else {
            this.items = [
                {
                    label: 'Subcontractor',
                    icon: 'pi pi-arrow-circle-right',
                    command: () => {
                        this.addRoute(rowData);
                    }
                },
                {
                    label: 'Edit',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.addDailong(rowData);
                    }
                }
            ];
        }
    }
    paginate(event) {
        let i = 1;
        this.first = event.first;
        this.rows = event.rows;
        this.page = event.page + i++;
        this.pageCount = event.pageCount;
        this.getTiergridData();
    }
    // It is used to update vendor tiers
    vendorUpdate() {
        this.vendorrowId = this.rowData.id;
        const reqBody = {
            id: this.vendorrowId,
            // tslint:disable-next-line:max-line-length
            discount: this.vendorEditForm.controls.discount.value === null || this.vendorEditForm.controls.discount.value === undefined || this.vendorEditForm.controls.discount.value === '' ? '' : this.vendorEditForm.controls.discount.value,
            // tslint:disable-next-line:max-line-length
            daysDelay: this.vendorEditForm.controls.days.value === null || this.vendorEditForm.controls.days.value === undefined || this.vendorEditForm.controls.days.value === '' ? '' : this.vendorEditForm.controls.days.value,
            // tslint:disable-next-line:max-line-length
            status: this.vendorEditForm.controls.status.value === null || this.vendorEditForm.controls.status.value === undefined || this.vendorEditForm.controls.status.value === '' ? '' : this.vendorEditForm.controls.status.value.value
        };
        this.disable = true;
        this.apisService.putApi(environment.tierUpdate, reqBody).subscribe(res => {
            if (res.hasOwnProperty('success') === true) {
                this.messageService.add({
                    key: 'br',
                    severity: '',
                    life: 3000,
                    detail: res.message,
                });
                this.editlocation = false;
                this.getTiergridData();
                this.display = false;
                this.disable = false;
            } else if (res.hasOwnProperty('failed') === true) {
                this.messageService.add({
                    key: 'br',
                    severity: '',
                    life: 3000,
                    detail: res.failed,
                });
                this.disable = false;
                this.editlocation = false;
            } else {
                this.messageService.add({
                    key: 'br',
                    severity: '',
                    life: 3000,
                    detail: res.error,
                });
                this.disable = false;
                this.editlocation = false;
            }
        });

    }

}
