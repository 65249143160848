<div class="route-bar">
    <div class="route-bar-breadcrumb">
        <ul style=" position: relative;left: -32px;top: -10px;height: 1px;">
        <li> <a style="cursor: pointer;" (click)="routing()"> Home </a> </li>
        <li>/</li>
        <ng-template ngFor let-item let-last="last" [ngForOf]="items">
            <li>
                <a [ngStyle]="{'color':last === true ? '#2bb9d5' : '#727272' }" [routerLink]="item.routerLink" *ngIf="item.routerLink">{{item.label}}</a>
                <ng-container *ngIf="!item.routerLink">{{item.label}}</ng-container>
            </li>
            <li *ngIf="!last">/</li>
        </ng-template>
    </ul>
    </div>
</div>