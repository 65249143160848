<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
  <p-panel header="Contact Edit">
    <p-header>
      <div class="pi pi-arrow-left panelBackRoute" (click)="homeroute()"> </div>
    </p-header>
    <form [formGroup]="EditContactsForm">
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 aligncenter">
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 " class="imageDiv">
              <label class="hoverable" for="fileInput">
                <img alt="" class="img1" [src]="this.imagelogo ? this.imagelogo : this.ProfileImg">
                <div class="background"></div>
              </label>
              <br />
            </div>
          </div>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-9">
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
              <label class="inputfields"> First Name<span class="mandatoryColor">*</span></label>
              <input id="input" type="text" pKeyFilter="alpha" autocomplete="off" pInputText placeholder="First Name"
                formControlName="FirstName" maxlength="26">
              <span class="validationtest"
                *ngIf="EditContactsForm.get('FirstName').touched && EditContactsForm.get('FirstName').invalid && EditContactsForm.get('FirstName').errors.required">
                Please enter first name
              </span>
              <span class="validationtest"
                *ngIf="this.EditContactsForm.controls.FirstName.invalid && this.EditContactsForm.controls.FirstName.errors?.pattern">Please
                enter
                alphabets only</span>
              <div>
                <span class="validationtest" *ngIf=" this.EditContactsForm.get('FirstName').hasError('maxlength')">
                  Please enter 25 alphabets only
                </span>
              </div>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
              <label class="inputfields">Middle Name</label>
              <input id="input" type="text" autocomplete="off" pInputText placeholder="Middle Name" pKeyFilter="alpha"
                formControlName="MidleName" maxlength="16">
              <span class="validationtest"
                *ngIf="this.EditContactsForm.controls.MidleName.invalid && this.EditContactsForm.controls.MidleName.errors?.pattern">Please
                enter
                alphabets only</span>
              <div>
                <span class="validationtest" *ngIf=" this.EditContactsForm.get('MidleName').hasError('maxlength')">
                  Please enter 15 alphabets only
                </span>
              </div>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
              <label class="inputfields">Last Name<span class="mandatoryColor">*</span></label>
              <input id="input" type="text" pInputText placeholder="Last Name" autocomplete="off"
                formControlName="LastName" pKeyFilter="alpha" maxlength="26">
              <span class="validationtest"
                *ngIf="EditContactsForm.get('LastName').touched && EditContactsForm.get('LastName').invalid && EditContactsForm.get('LastName').errors.required">
                Please enter last name
              </span>
              <span class="validationtest"
                *ngIf="this.EditContactsForm.controls.LastName.invalid && this.EditContactsForm.controls.LastName.errors?.pattern">Please
                enter
                alphabets only</span>
              <div>
                <span class="validationtest" *ngIf=" this.EditContactsForm.get('LastName').hasError('maxlength')">
                  Please enter 25 alphabets only
                </span>
              </div>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
              <label class="inputfields">Email<span class="mandatoryColor">*</span></label>
              <div class="p-inputgroup">
                <input id="input" type="text" autocomplete="off" pInputText placeholder="Email" formControlName="Email"
                  disabled="true" maxlength="61" #userInputs>
                <span class="p-inputgroup-addon"> <em class="pi pi-copy pointer" (click)="emailToast()"
                    (click)="copyInputMessage(userInputs.value)"></em>
                </span>
              </div>
              <span class="validationtest"
                *ngIf="this.EditContactsForm.controls.Email.touched && this.EditContactsForm.controls.Email.invalid && this.EditContactsForm.controls.Email.errors.required">
                Please enter email</span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
              <label class="inputfields">Gender</label>
              <p-dropdown [options]="genderDrop" placeholder="Select Gender" optionLabel="name" formControlName="Gender"
                [showClear]="true"></p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
              <label class="inputfields">Title<span class="mandatoryColor">*</span></label>
              <input id="input" type="text" autocomplete="off" pInputText placeholder="Title" formControlName="Title"
                maxlength="26">
              <span class="validationtest"
                *ngIf="EditContactsForm.get('Title').touched && EditContactsForm.get('Title').invalid && EditContactsForm.get('Title').errors.required">
                Please enter title
              </span>
              <span class="validationtest"
                *ngIf="this.EditContactsForm.controls.Title.invalid && this.EditContactsForm.controls.Title.errors?.pattern">Please
                enter
                alphabets only</span>
              <div>
                <span class="validationtest" *ngIf=" this.EditContactsForm.get('Title').hasError('maxlength')">
                  Please enter 25 alphabets only
                </span>
              </div>
            </div>
            <!-- <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
              <label class="inputfields">Work Location</label>
              <p-dropdown [options]="workLoc" optionLabel="name" placeholder="Select Work Location"
                formControlName="worklocation" [showClear]="true">
              </p-dropdown>
            </div> -->
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 invalidMandatory" pTooltip="{{this.patchlocationString}}"
              tooltipPosition="top">
              <label class="inputfields">Work Location</label>
              <p-dropdown [options]="workLoc" placeholder="Select Work Location" optionLabel="name" [showClear]="true"
                formControlName="worklocation" appendTo="body" (onChange)="saveLocation($event)">
                <ng-template let-data pTemplate="item">
                  <div
                    pTooltip="{{data.name + '\n' + data.address1+',' + data.city + ',' + data.state + ',' + data.country}}"
                    tooltipPosition="right">
                    {{ data.name }}
                  </div>
                </ng-template>

              </p-dropdown>

            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
              <label class="inputfields">Status</label>
              <p-dropdown [options]="status1" placeholder="Select Status" optionLabel="label" formControlName="Status"
                [disabled]="statusDisable" [showClear]="true"></p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
              <label class="inputfields">Role</label>
              <p-multiSelect [options]="role" [showToggleAll]="false" formControlName="Role" placeholder="Select Role"
                [panelStyle]="{minWidth:'12em'}" optionLabel="name" [filter]='false'>
              </p-multiSelect>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 invalidMandatory">
              <label class="inputfields">Office Phone<span class="mandatoryColor">*</span></label>
              <div class="p-col-12 p-md-12 p-sm-12 phone">
                <div class="p-inputgroup" class="p-col-4 p-md-4 ph-no">
                  <span class="p-inputgroup-addon addon">
                    <p-dropdown [options]="countrycodedropdown" class="phonecodestyle newcode"
                      (onChange)="countryids($event)" filterPlaceholder='Search Results' optionLabel="label"
                      formControlName="ofcPhDrp">
                      <ng-template let-items pTemplate="selectedItem">
                        <div class="flex-row">
                          <img class="lang-change-img" src={{items.flag}} alt="" />
                          <div class="lang-change-option-text selected-lang center">{{items.code}}
                          </div>
                        </div>
                      </ng-template>
                      <ng-template let-country pTemplate="item">
                        <div class="flex-row" id="dropdown1">
                          <img class="lang-change-img" src={{country.flag}} alt="" />
                          <div class="lang-change-option-text center">{{country.label}}
                            {{country.code}}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </span>
                </div>
                <div class="p-col-8 p-md-8 ccode">
                  <span class="p-input-icon-right"><em *ngIf="phSpinnerHide"
                      class="pi pi-spin pi-spinner spinnerstyles"></em>
                    <em *ngIf="phCheckHide" class="pi pi-check-circle tickmarkstyle"></em>
                    <p-inputMask autocomplete="off" (onBlur)="getPhon()" mask="(999) 999-9999"
                      formControlName="OfficePh" placeholder="Office Number">
                    </p-inputMask>
                  </span>
                </div>
              </div>
              <span class="validationtest"
                *ngIf="EditContactsForm.get('OfficePh').touched && EditContactsForm.get('OfficePh').invalid && EditContactsForm.get('OfficePh').errors.required">
                Please enter office phone
              </span>
              <p class="mandatoryColor">{{this.officenummsg}}</p>
              <span *ngIf="displayNumber" class="mandatoryColor">office number already exist</span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 invalidMandatory">
              <label class="inputfields">Mobile Number</label>
              <div class="p-col-12 p-md-12 p-sm-12 phone">
                <div class="p-inputgroup" class="p-col-4 p-md-4 ph-no">
                  <span class="p-inputgroup-addon addon">
                    <p-dropdown [options]="countrycodedropdown" class="phonecodestyle newcode"
                      (onChange)="countryids($event)" filterPlaceholder='Search Results' optionLabel="label"
                      formControlName="mobileNumDrp">
                      <ng-template let-items pTemplate="selectedItem">
                        <div class="flex-row">
                          <img class="lang-change-img" src={{items.flag}} alt="" />
                          <div class="lang-change-option-text selected-lang center">{{items.code}}
                          </div>
                        </div>
                      </ng-template>
                      <ng-template let-country pTemplate="item">
                        <div class="flex-row" id="dropdown1">
                          <img class="lang-change-img" src={{country.flag}} alt="" />
                          <div class="lang-change-option-text center">{{country.label}}
                            {{country.code}}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </span>
                </div>
                <div class="p-col-8 p-md-8 ccode">
                  <span class="p-input-icon-right"><em *ngIf="phSpinnerHide1"
                      class="pi pi-spin pi-spinner spinnerstyles"></em>
                    <em *ngIf="phCheckHide1" class="pi pi-check-circle tickmarkstyle"></em>
                    <p-inputMask autocomplete="off" (onBlur)="getmobi()" mask="(999) 999-9999"
                      formControlName="Mobphone" placeholder="Mobile Number">
                    </p-inputMask>
                  </span>
                </div>
              </div>
              <span *ngIf="displayNumber1" class="mandatoryColor">Mobile number already exist</span>
              <p class="mandatoryColor">{{this.mobinummsg}}</p>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
              <label class="inputfields">Identity Number</label>
              <input id="input" type="text" autocomplete="off" pInputText placeholder="Identity Number" pKeyFilter="num"
                formControlName="SSNnum" maxlength="26">
              <div>
                <span class="validationtest" *ngIf=" this.EditContactsForm.get('SSNnum').hasError('maxlength')">
                  Please enter 25 alphabets only
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h5 id="h3tag">Contact Address</h5>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
          <label class="inputfields">Address Line 1</label>
          <input id="input" type="text" autocomplete="off" pInputText placeholder="Address 1"
            formControlName="addressLine1" maxlength="51">
          <div>
            <span class="validationtest" *ngIf=" this.EditContactsForm.get('addressLine1').hasError('maxlength')">
              Please enter 50 alphabets only
            </span>
          </div>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
          <label class="inputfields">Address Line 2</label>
          <input id="input" type="text" autocomplete="off" pInputText placeholder="Address 2"
            formControlName="addressLine2" maxlength="51">
          <div>
            <span class="validationtest" *ngIf=" this.EditContactsForm.get('addressLine2').hasError('maxlength')">
              Please enter 50 alphabets only
            </span>
          </div>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
          <label class="inputfields">Mailbox</label>
          <input id="input" type="text" autocomplete="off" pInputText placeholder="Mailbox" maxLength="11"
            formControlName="mailboxNumber">
          <div>
            <span class="validationtest" *ngIf=" this.EditContactsForm.get('mailboxNumber').hasError('maxlength')">
              Please enter 10 alphabets only
            </span>
          </div>
          <div class="mandatoryColor">

            <span class="validationtest"
              *ngIf="this.EditContactsForm.get('mailboxNumber').invalid && this.EditContactsForm.get('mailboxNumber').errors.pattern">
              Please enter numbers only
            </span>
          </div>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
          <label class="inputfields">Country</label>
          <p-dropdown [options]="country" formControlName="country" placeholder="Select Country" optionLabel="label"
            [showClear]="true" [filter]='true' (onChange)="getStateByUrl($event.value.code)"></p-dropdown>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
          <label class="inputfields">State</label>
          <p-dropdown [options]="stateData" [filter]='true' formControlName="state" placeholder="Select State"
            optionLabel="label" [showClear]="true"></p-dropdown>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
          <label class="inputfields">City</label>
          <input autocomplete="off" id="input" type="text" pInputText placeholder="City" formControlName="city"
            maxlength="21">
          <span class="validationtest"
            *ngIf="this.EditContactsForm.controls.city.invalid && this.EditContactsForm.controls.city.errors?.pattern">Please
            enter
            alphabets only</span>
          <div>
            <span class="validationtest" *ngIf=" this.EditContactsForm.get('city').hasError('maxlength')">
              Please enter 20 alphabets only
            </span>
          </div>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
          <label class="inputfields">Zip</label>
          <input id="input" type="text" autocomplete="off" pInputText placeholder="Zip" pKeyFilter="num"
            formControlName="zip" maxlength="11">
          <div>
            <span class="validationtest" *ngIf=" this.EditContactsForm.get('zip').hasError('maxlength')">
              Please enter 10 alphabets only
            </span>

            <span class="validationtest"
              *ngIf="this.EditContactsForm.get('zip').invalid && this.EditContactsForm.get('zip').errors.pattern">
              Please enter numbers only
            </span>
          </div>
        </div>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-10 p-md-10 p-sm-12"></div>
      </div>
      <div class="btnsCSS1">
        <button pButton type="button" icon='pi pi-save' [disabled]="btndsb" class="addhBtn" label="Update"
          (click)="profileUpdate()"></button>
      </div><br><br>
    </form>
  </p-panel>
</p-card>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center laoderheight">
        <div class="laodersize">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
            class="loaderimg-height">
        </div>
      </div>
    </div>
  </div>
</ng-template>
<p-overlayPanel #op1 class="over">
  <h6>Copied</h6>
</p-overlayPanel>