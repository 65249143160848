import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
  providers: [MessageService],
})
export class DeleteAccountComponent implements OnInit {
  name: any;
  display = true;
  names: any;
  emailId: any;
  userEmail: any;
  userAccName: any;
  lkUserType: any;
  loader: boolean;
  files: any;
  userAccId: any;
  removebtn: boolean;
  constructor(private route: ActivatedRoute, private messageService: MessageService, private apisService: ApisService, private routes: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.name = params.sessionId;
    });
    this.verify();
  }
  verify() {
    this.loader = true;
    // tslint:disable-next-line: deprecation
    // this.apisService.getApi(`http://172.17.10.73:9090/ngServicesBay/token-expiry-delete?token=${this.name}`).subscribe((res) => { // Dev URL
      // this.apisService.getApi(`https://uat-portal-api.servicesbay.com/v1/token-expiry-delete?token=${this.name}`).subscribe((res) => { // UAT URL
      this.apisService.getApi(`https://portal-api.servicesbay.com/v1/token-expiry-delete?token=${this.name}`).subscribe((res) => {  // PROD URL
      this.loader = false;
      this.display = res.success;
      this.names = res.name;
      this.emailId = res.email;
      this.userEmail = res.userEmail;
      this.userAccName = res.userAccName;
      // this.lkUserType = res.lkUserType;
      this.lkUserType = res.lkUserType === 'C' ? "Customer" : "Vendor";
      this.userAccId = res.accId;
    });
  }
  removeAccount() {
    this.loader = true;
    this.removebtn = true;
    this.apisService.deleteApi(environment.removeUserAccount + this.userAccId).subscribe((res: any) => {
      this.loader = false;
      this.files = res;
      // tslint:disable-next-line: no-conditional-assignment
      if (res.success === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
      }
      this.removebtn = false;
      setTimeout(() => { this.routes.navigate(['/login']); }, 2000)
    });
  }
  goto() {
    this.routes.navigate(['/login']);
  }
}
