<div class="account"><strong>Requirement Id : <span class="accname">{{this.reqId}}</span></strong></div><br>
<p-card>
    <form [formGroup]="vendorsForm">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-3">
                <label class="inputfields">{{this.searchName}} Name</label>
                <p-dropdown [options]="nameList" placeholder="Select Name" [filter]="true" [showClear]="true"
                    optionLabel="name" optionValue="id" (keyup.enter)="getTableData()" formControlName="name">
                </p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-3"></div>
            <div class="p-field p-col-12 p-md-3"></div>
            <div class="p-field p-col-12 p-md-3">
                <div class="btnsCSS1 btnalign">
                    <button pButton class="addhBtn" type="button" icon='pi pi-refresh' (click)="reset()"
                        label="Reset"></button>
                    <button pButton class="addhBtn" type="button" icon='pi pi-search' (click)="getTableData()"
                        label="Search"></button>
                </div>
            </div>
        </div>
    </form>
</p-card>
<br>
<p-card class="pgpos">
    <div *ngIf="table">
        <p-table [columns]="cols" [value]="customerGrid" [rows]="10" selectionMode="single" dataKey="edit"
            [autoLayout]="true" [responsive]="true" sortMode="multiple">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        (click)="iconToggle[i] = !iconToggle[i]" [ngStyle]="{'width': col.width }"
                        [pSortableColumn]="col.field" [autoLayout]="true">
                        <span>
                            {{col.header}}
                        </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
                <tr>
                    <td>
                        {{data.name}}
                    </td>
                    <td>{{data.releasedDate}}</td>
                    <td class="textCenter"><span class="ui-column-title"></span>
                        <p-badge [value]="data.isViewed === 'N' ? 'No' : 'Yes'"
                            [ngClass]="data.isViewed ==='N'?'red':'green'">
                        </p-badge>
                    </td>
                    <td> <a class="pi pi-ellipsis-v pointer"></a></td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid ui-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any {{this.searchName}} list</em><br>
            </div>
        </div>
    </p-card>
    <div *ngIf="!loader; else showLoader"></div>
    <ng-template #showLoader>
        <div class="loading-bar">
            <div>
                <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                    <div class="laodersize">
                        <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                            class="loaderimg-height">
                    </div>
                </div>
            </div>
        </div>
    </ng-template>