<form [formGroup]="recruiterFormGroup">
    <p-toast position="bottom-right" key="br"></p-toast>
    <p-card>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Company</label>
                <input id="input" type="text" pInputText placeholder="Company" autocomplete="off" maxlength="30"
                    formControlName="reqNameForm">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Corporate Website</label>
                <input id="input" type="text" pInputText placeholder="Corporate Website" autocomplete="off"
                    maxlength="30" formControlName="urlForm">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Phone Number</label>
                <p-inputMask autocomplete="off" id="input" mask="(999) 999-9999" size="30" formControlName="phForm"
                    placeholder="Phone Number">
                </p-inputMask>
            </div>
        </div>
        <div class="btnsCSS1 btnsalign">
            <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn"
                (click)="search()"></button>
        </div>
    </p-card>
    <br>
    <p-card class="pgpos" *ngIf='this.roleId!=15'>
        <div *ngIf="table">
            <p-table [columns]="cols" [value]="gridRes" class="tablescroll" [columns]="cols" dataKey="edit"
                selectionMode="single" [responsive]="true" sortMode="multiple" [autoLayout]="true" >
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th id="gridHeader" *ngFor="let col of columns let i=index"
                            (click)="iconToggle[i] = !iconToggle[i]" [ngStyle]="{'width': col.width }"
                            [pSortableColumn]="col.field">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-columns="columns" let-rowIndex="rowIndex">
                    <tr>
                        <td class="textCenter pointer" pTooltip="{{data.recruiterName}}"> <span class="p-column-title">
                                Recruiter Name</span>{{data.recruiterName}}</td>
                        <td class="textCenter"><span class="p-column-title">
                                Company</span>{{data.name}}</td>
                        <td class="textCenter"><span class="p-column-title">
                                Phone Number</span>{{data.phone}}</td>
                        <td class="textCenter pointer" (click)="emailToast()"
                            (click)="copyInputMessage(data.email)"><span class="p-column-title">
                                Email</span><a>{{data.email}}</a></td>
                        <td>
                            <p-badge
                                [value]="data.lkStatus === 'A'?'Active':data.lkStatus === 'I'?'InActive':data.lkStatus"
                                [ngClass]="data.lkStatus ==='A'?'green': data.lkStatus === 'I'?'red': 'blue'">
                            </p-badge>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr *ngIf="accounSearchGridData?.length==0" class="aligncenter">
                        <td colspan="5" class="mandatoryColor">
                            No Records
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                    <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                    <p-paginator [rows]="10" [totalRecords]="recordslnt" [rowsPerPageOptions]="[10,20,30]"
                        (onPageChange)="paginate($event)"></p-paginator>
                </div>
            </div>
        </div>
        <p-card *ngIf="records">
            <div class="norecordImgsize">
                <div *ngIf="records" class="aligncenter">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                    <br><em>Sorry, we are not able to find any recruiters</em><br>
                </div>
            </div>
        </p-card>
    </p-card>
    <p-card class="pgpos" *ngIf='this.roleId===15'>
        <div *ngIf="conTable">
            <p-table [columns]="cols1" [value]="ConGridRes" class="tablescroll" dataKey="edit" selectionMode="single"
                [responsive]="true" sortMode="multiple" [autoLayout]="true">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th id="gridHeader" *ngFor="let col of columns let i=index"
                            (click)="iconToggle[i] = !iconToggle[i]" [ngStyle]="{'width': col.width }"
                            [pSortableColumn]="col.field">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                    <ng-template pTemplate="body" let-data let-columns="columns">
                        <tr>
                           
                            <td class="textCenter"><span class="p-column-title">
                                    Company</span>{{data.companyName}}</td>
                     
                 
                            <td (click)=" data.lkStatus === 'R' ?  rejectToggle( $event,data): '' " class="textCenter"><span
                                    class="p-column-title">
                                </span>
                                <p-badge [value]="gettatusData[data.lkStatus]"
                                    [ngClass]="data.lkStatus ==='A'?'green':data.lkStatus === 'R'?'red': 'blue'">
                                </p-badge>
                            </td>
                            <td class="textCenter pointer" >
                                <span class="p-column-title">
                                    createdDate</span>{{data.createdDate}}</td>
                            <td class="textCenter"><a>
                                    <em (click)="actionpopup.toggle($event);  toggle( $event,data)"
                                        class="pi pi-ellipsis-v pointer"></em>
                                </a></td>
                        </tr>
                    </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr *ngIf="accounSearchGridData?.length==0" class="aligncenter">
                        <td colspan="5" class="mandatoryColor">
                            No Records
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                    <h3 class="norecorddata">Total records : {{this.conTotalRecords}}</h3>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-10 pagi">
                    <p-paginator [rows]="10" [totalRecords]="conTotalRecords" [rowsPerPageOptions]="[10,20,30]"
                        (onPageChange)="paginate($event)"></p-paginator>
                </div>
            </div>
        </div>
        <p-card *ngIf="conRecords">
            <div class="norecordImgsize">
                <div *ngIf="conRecords" class="aligncenter">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                    <br><em>Sorry, we are not able to find any recruiters</em><br>
                </div>
            </div>
        </p-card>
        <p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
    </p-card>
    <p-dialog class="dialogBox" [contentStyle]="{'overflow': 'visible'}" header=" Reject Reason"
        [(visible)]="candidaterejectpopup" [style]="{width: '20%'}" [baseZIndex]="1" [draggable]="false" [modal]="true"
        [resizable]="true">
        <div class="p-fluid p-formgrid p-grid">
            <input id="input" type="text" pInputText placeholder="Reject Reason" autocomplete="off" maxlength="30"
                formControlName="rejectReason">
        </div>
    </p-dialog>
</form>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>