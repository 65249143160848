import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-customer-edit-contacts',
  templateUrl: './customer-edit-contacts.component.html',
  styleUrls: ['./customer-edit-contacts.component.scss'],
  providers: [DatePipe, MessageService],
})
export class CustomerEditContactsComponent implements OnInit {
  genderDrop = [// It is used for the displaying the gender Dropdown Values
    { id: 'M', name: 'Male' },
    { id: 'F', name: 'Female' },
  ];
  status2 = [
    { code: 'A', id: 'ULS', label: 'Active' },
    { code: 'I', id: 'ULS', label: 'Inactive' },
  ];
  EditContactsForm: FormGroup;
  location = [{ acc_add_id: -1, location_name: 'Select Work Location' }];
  country: any = [];
  stateData: any = [];
  rolesData: any = [];
  accountType: string;
  status: any = [];
  data: any;
  role: any = [];
  id: any;
  fileName: any;
  url: any;
  imageSuccess: boolean;
  accountTypeValue: any;
  accountid: any;
  loader: boolean;
  url1: any;
  workLoc: any = [];
  actId: any;
  accID: any;
  addID: any;
  userID: any;
  statusDisable = false;
  rowid: string;
  displayNumber: boolean;
  officenum: any;
  officenummsg: string;
  loginData: any;
  roleid: any;
  salescustAccId: any;
  phSpinnerHide: boolean;
  phCheckHide: boolean;
  ProfileImg: string;
  phCheckHide1: boolean;
  phSpinnerHide1: boolean;
  mobinummsg: string;
  mobinum: boolean;
  userDetails: any;
  x: any;
  status1: any = [];
  displayNumber1: boolean;
  countrycodedropdown: any = [];
  items: any[];
  btndsb: boolean;
  backid: any;
  imagelogo: any;
  salescustaccname: any;
  usrroleid: any;
  patchlocationString: string;
  editLocationdata: any;
  accountactivityId: any;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private messageService: MessageService, private router: ActivatedRoute, private route: Router, private breadcrumbService: BreadcrumbService) {
    this.loginData = JSON.parse(this.apisService.decodeData());
    this.userDetails = JSON.parse(this.apisService.decodeData());
    this.backid = this.userDetails.accId;
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
      this.roleid = this.loginData.roles[0].id;
    } else {
      this.roleid = this.usrroleid;
    }
    const routeParams: any = this.router.snapshot.queryParamMap;
    this.accountactivityId = routeParams.params.getdata;
    this.salescustaccname = routeParams.params.salescustomeraacname;

    this.salescustAccId = JSON.parse(sessionStorage.getItem('salescustAccId'));
    if (this.roleid === 14) {
      this.breadcrumbService.setItems([
        { label: 'Customers', routerLink: '/customers' },
        {
          label: 'Contacts', routerLink: ['/contacts', this.accountactivityId, {
            queryParams:
              this.salescustaccname, From: 'customerEdit'
          }]
        },
        { label: 'Contact Edit', routerLink: '/contact-edit' },
      ]);
    }
    else if (this.roleid === 3) {
      this.breadcrumbService.setItems([
        { label: 'Account Details', routerLink: '/account-details' },
        { label: 'Contacts', routerLink: ['/contacts', this.backid] },
        { label: 'Contact Edit', routerLink: '/contact-edit' }
      ]);
    }
    else {
      this.breadcrumbService.setItems([
        { label: 'Account Details', routerLink: '/accountdetails' },
        { label: 'Contacts', routerLink: ['/contacts', this.backid] },
        { label: 'Contact Edit', routerLink: '/contact-edit' }
      ]);
    }
    if (JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 8) {
      this.accountTypeValue = JSON.parse(sessionStorage.getItem('loginData')).primaryrole;
    } else {
      sessionStorage.getItem('account_Type');
      this.accountTypeValue = sessionStorage.getItem('account_Type');
    }
    // tslint:disable-next-line:max-line-length
    if (JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 0 || JSON.parse(sessionStorage.getItem('loginData')).primaryrole === 1) {
      this.accountid = Number(sessionStorage.getItem('account_id'));
    } else {
      this.accountid = JSON.parse(sessionStorage.getItem('loginData')).orgId;
    }
  }
  ngOnInit() {
    this.actId = JSON.parse(this.apisService.decodeData());
    this.EditContactsForm = this.fb.group({
      FirstName: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(25)]],
      MidleName: ['', [Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(15)]],
      LastName: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(25)]],
      Email: ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      Gender: [''],
      Title: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(25)]],
      OfficePh: ['', Validators.required],
      Mobphone: [''],
      worklocation: [''],
      ofcPhDrp: [''],
      mobileNumDrp: [''],
      SSNnum: ['', [Validators.maxLength(25)]],
      Role: [''],
      Status: [''],
      addressLine1: ['', [Validators.maxLength(50)]],
      addressLine2: ['', [Validators.maxLength(50)]],
      mailboxNumber: ['', [Validators.maxLength(10)]],
      city: ['', [Validators.pattern('^[\\sa-zA-z0-9]*$'), Validators.maxLength(20)]],
      country: [''],
      state: [''],
      zip: ['', [Validators.pattern('^[0-9-]+$'),Validators.maxLength(10)]],
    });
    this.statusDD();
    this.getRoleDD();
    this.getCountryDropD();
    // this.getlocation();
    this.edit();
    this.getdrop();
  }
  countryids(event) {
  }
  getdrop() {
    this.countrycodedropdown = this.apisService.getdropdwon();
  }
  statusDD() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'ULS').subscribe((res: any) => {
      this.status = res.data;
    });
  }
  getRoleDD() {
    const rolevalue = sessionStorage.getItem('Act_type');
    const getroles = rolevalue === 'B' ? 'V' : rolevalue;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.roleDD + getroles).subscribe((res: any) => {
      this.role = res.data;
      this.EditContactsForm.controls.Role.patchValue(this.role.filter((x) => x.id === this.data?.roles[0]));
    });
  }
  // It is used for the displaying the country Dropdown Values
  getCountryDropD() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.preCountryDropdown).subscribe((res) => {
      this.country = res.data;
    });
  }
  copyInputMessage(data) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (data));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
  // It is used for the displaying the StateB Dropdown based on country Values
  getStateByUrl(code) {
    if (code === null || code === '' || code === undefined) {
      this.stateData = [];
    }
    else {
      // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.state + '/' + code).subscribe((res) => {
        this.stateData = res.data;
        this.EditContactsForm.controls.state.patchValue(
          this.stateData.filter((x) => x.code === this.data.state)[0]
        );
      });
    }
  }
  // It is used for the displaying the location Dropdown Values
  getlocation() {
    this.rowid = sessionStorage.getItem('acct_rowid');
    this.id = '/' + this.actId.accId;
    const id = this.actId.roles[0].id === 1 ? this.rowid : this.actId.accId;
    const getloc = this.roleid === 14 ? this.accountactivityId : id;

    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.locationEdit + '/' + getloc).subscribe((res: any) => {
      this.workLoc = res.data;
      this.EditContactsForm.controls.worklocation.patchValue(this.workLoc.filter((x) => x.locId === this.data.workLocationId)[0]);
      this.editLocationdata = this.workLoc.filter((x) => x.locId === this.data.workLocationId)[0];
      this.patchlocationString = this.editLocationdata?.name + '\n' + this.editLocationdata?.address1 + ','
        + this.editLocationdata?.city + ',' + this.editLocationdata?.state + ',' + this.editLocationdata?.country
    });
  }
  emailToast() {
    if (this.EditContactsForm.controls.Email.invalid && this.EditContactsForm.controls.Email.errors.pattern) {
    }
    // tslint:disable-next-line: max-line-length
    else if (this.EditContactsForm.controls.Email.value === '' || this.EditContactsForm.controls.Email.value === null || this.EditContactsForm.controls.Email.value === undefined) {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please enter email.' });
    }
    else {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
    }
  }
  // It is used for the get the data and binding it
  edit() {
    this.statusDisable = false;
    const contactI = Number(this.router.snapshot.paramMap.get('id'));
    this.loader = true;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.editContactpatch + contactI).subscribe((res: any) => {
      this.data = res.data;
      this.statusDisable = this.data.status === 'R' ? true : false;
      this.status1 = this.data.status === 'R' ? this.status : this.status2;
      this.accID = res.data.accId;
      this.addID = res.data.addId;
      this.userID = res.data.userId;
      this.getStateByUrl(this.data.country);
      this.imagelogo = this.data.logo;
      if (this.data.gender === 'M') {
        this.ProfileImg = 'https://d2r8nxfsv3v6y3.cloudfront.net/images/male.png';
      }
      else {
        this.ProfileImg = 'https://d2r8nxfsv3v6y3.cloudfront.net/images/female.png';
      }
      const Rolelist = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.role?.length; i++) {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.data.roleList?.length; j++) {
          if (this.role[i].id === this.data.roleList[j].id) {
            Rolelist.push(this.role[i]);
          } else {
          }
        }
      }
      this.loader = false;
      this.getlocation();
      this.EditContactsForm.controls.country.patchValue(this.country.filter((x) => x.code === this.data.country)[0]);
      this.EditContactsForm.controls.Gender.patchValue(this.genderDrop.filter((x) => x.id === this.data.gender)[0]);
      this.EditContactsForm.controls.Role.patchValue(Rolelist);
      this.EditContactsForm.controls.FirstName.patchValue(this.data.firstName);
      this.EditContactsForm.controls.MidleName.patchValue(this.data.middleName);
      this.EditContactsForm.controls.LastName.patchValue(this.data.lastName);
      this.EditContactsForm.controls.Email.patchValue(this.data.email);
      this.EditContactsForm.controls.Title.patchValue(this.data.designation);
      this.EditContactsForm.controls.OfficePh.patchValue(this.data.officePhone);
      this.EditContactsForm.controls.Mobphone.patchValue(this.data.mobilePhone);
      this.EditContactsForm.controls.addressLine1.patchValue(this.data.address1);
      this.EditContactsForm.controls.addressLine2.patchValue(this.data.address2);
      this.EditContactsForm.controls.mailboxNumber.patchValue(this.data.mailbox);
      this.EditContactsForm.controls.city.patchValue(this.data.city);
      this.EditContactsForm.controls.zip.patchValue(this.data.zip);
      this.EditContactsForm.controls.Status.patchValue(this.status.filter((x) => x.code === this.data.status)[0]);
      this.EditContactsForm.controls.SSNnum.patchValue(this.data.ssnNumber);
      this.EditContactsForm.controls.Gender.patchValue(this.genderDrop.filter((x) => x.id === this.data.gender)[0]);
      this.EditContactsForm.controls.country.patchValue(this.country.filter((x) => x.code === this.data.country)[0]);
      this.EditContactsForm.controls.mobileNumDrp.patchValue(this.countrycodedropdown.filter((x) => x.id === this.data.mobileCcode)[0]);
      this.EditContactsForm.controls.ofcPhDrp.patchValue(this.countrycodedropdown.filter((x) => x.id === this.data.officeCcode)[0]);
    });
  }
  // It is used for the updating the Edit Contact Details and keep the validations
  profileUpdate() {
    this.loader = true;
    if (this.EditContactsForm.invalid) {
      const controls = this.EditContactsForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
        this.loader = false;
      });
    } else {
      const roleValue = this.EditContactsForm.controls.Role.value;
      this.rolesData = roleValue.map((x) => x.id);
      const data1 = {
        accId: this.accID,
        addId: this.addID,
        address1: this.EditContactsForm.controls.addressLine1.value,
        address2: this.EditContactsForm.controls.addressLine2.value,
        city: this.EditContactsForm.controls.city.value,
        // tslint:disable-next-line:max-line-length
        country: this.EditContactsForm.controls.country.value === null || this.EditContactsForm.controls.country.value ==='' || this.EditContactsForm.controls.country.value?.code === undefined ? 'USA' : this.EditContactsForm.controls.country.value?.code,
        email: this.EditContactsForm.controls.Email.value,
        fname: this.EditContactsForm.controls.FirstName.value,
        gender: this.EditContactsForm.controls.Gender.value === null || this.EditContactsForm.controls.Gender.value ==='' || this.EditContactsForm.controls.Gender.value.id === undefined ? 'M' : this.EditContactsForm.controls.Gender.value?.id,
        id: this.userID,
        lname: this.EditContactsForm.controls.LastName.value,
        mailBox: this.EditContactsForm.controls.mailboxNumber.value,
        mname: this.EditContactsForm.controls.MidleName.value,
        officePhone: this.EditContactsForm.controls.OfficePh.value,
        phone: this.EditContactsForm.controls.Mobphone.value,
        roleList: this.rolesData,
        ssnNumber: this.EditContactsForm.controls.SSNnum.value,
        // tslint:disable-next-line:max-line-length
        status: this.EditContactsForm.controls.Status.value === null || this.EditContactsForm.controls.Status.value === '' || this.EditContactsForm.controls.Status.value?.code === undefined ? 'A' : this.EditContactsForm.controls.Status.value?.code,
        // tslint:disable-next-line:max-line-length
        state: this.EditContactsForm.controls.state.value === null || this.EditContactsForm.controls.state.value === '' || this.EditContactsForm.controls.state.value?.code === undefined ? 'A' : this.EditContactsForm.controls.state.value?.code,
        title: this.EditContactsForm.controls.Title.value,
        // tslint:disable-next-line:max-line-length
        workLocation: this.EditContactsForm.controls.worklocation.value === null ||this.EditContactsForm.controls.worklocation.value === '' || this.EditContactsForm.controls.worklocation.value?.locId === undefined ? '' : this.EditContactsForm.controls.worklocation.value?.locId,
        zip: this.EditContactsForm.controls.zip.value,
        // tslint:disable-next-line:max-line-length
        mccode: this.EditContactsForm.controls.mobileNumDrp.value === null || this.EditContactsForm.controls.mobileNumDrp.value ==='' || this.EditContactsForm.controls.mobileNumDrp.value?.id === undefined ? '' : this.EditContactsForm.controls.mobileNumDrp.value?.id,
        // tslint:disable-next-line:max-line-length
        occode: this.EditContactsForm.controls.ofcPhDrp.value === null || this.EditContactsForm.controls.ofcPhDrp.value ==='' || this.EditContactsForm.controls.ofcPhDrp.value?.id === undefined ? '' : this.EditContactsForm.controls.ofcPhDrp.value.id,
      };
      this.btndsb = true;
      // tslint:disable-next-line: deprecation
      this.apisService.putApi(environment.addContact, data1).subscribe((res) => {
        this.loader = false;
        if (res.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
          setTimeout(() => {
          
            if (this.roleid === 14) { 
              this.route.navigate(['/contacts', this.accountactivityId], {
                queryParams: {
                  salescustomeraacname: this.salescustaccname
                }
              });
            } else {
              this.route.navigate(['/contacts', this.actId.accId], {
                queryParams: {
                  salescustomeraacname: this.salescustaccname
                }
              });
            }
          }, 2000);
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed, });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
        }
      });
    }
  }
  homeroute() {
    this.route.navigate(['/contacts', this.actId.accId], {
      queryParams: {
        salescustomeraacname: this.salescustaccname
      }
    });
  }
  getPhon() {
    this.displayNumber = false;
    const num = this.EditContactsForm.controls.OfficePh.value;
    if (num === '' || num === null || num === undefined || num === '(___) ___-____') {
    }
    else {
      this.phSpinnerHide = true;
      // tslint:disable-next-line: deprecation
      // tslint:disable-next-line:max-line-length
      this.apisService.getApi(environment.numberValidation + this.userDetails.userId + '/' + true + '?' + 'phoneNumber=' + num).subscribe(res => {
        this.officenum = res;
        this.officenum = res.success;
        if (this.officenum === false) {
          this.phSpinnerHide = false;
          this.officenummsg = 'office number already exist';
          setTimeout(() => {
            this.officenummsg = '';
          }, 5000);
          this.EditContactsForm.get('OfficePh').reset();
        }
        else {
          this.phSpinnerHide = false;
          this.phCheckHide = true;
          setTimeout(() => {
            this.phCheckHide = false;
          }, 3000);
        }
      });
    }
  }
  getmobi() {
    this.displayNumber1 = false;
    const mob = this.EditContactsForm.controls.Mobphone.value;
    if (mob === '' || mob === null || mob === undefined || mob === '(___) ___-____') {
    }
    else {
      this.phSpinnerHide1 = true;
      // tslint:disable-next-line: deprecation
      // tslint:disable-next-line: max-line-length
      this.apisService.getApi(environment.numberValidation + this.userDetails.userId + '/' + false + '?' + 'phoneNumber=' + mob).subscribe(res => {
        this.mobinum = res;
        this.mobinum = res.success;
        if (this.mobinum === false) {
          this.phSpinnerHide1 = false;
          this.mobinummsg = 'mobile number already exist';
          setTimeout(() => {
            this.mobinummsg = '';
          }, 5000);
          this.EditContactsForm.get('Mobphone').reset();
        }
        else {
          this.phSpinnerHide1 = false;
          this.phCheckHide1 = true;
          setTimeout(() => {
            this.phCheckHide1 = false;
          }, 3000);
        }
      });
    }
  }
  saveLocation(ev) {
    const data = ev.value
    this.patchlocationString = data.name + '\n' + data.address1 + ',' + data.city + ',' + data.state + ',' + data.country
  }
}
