<p-toast position="bottom-right" key="br"></p-toast>
<p-panel [toggleable]="false">
    <p-header>
        <label>
            <strong>Tech Reviews</strong> <strong class="positionheader">Job Id : </strong><span
                class="panelLabel">{{jobId}}</span><strong class="positionheader">Position
                Title : </strong> <span class="panelLabel">{{reqName}}</span>
        </label>
        <em class="pi pi-arrow-left panelBackRoute" (click)="goto()"></em>
    </p-header>
    <form [formGroup]="addtechreviewForm">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Candidate Name </label>
                <input autocomplete="off" type="text" pInputText formControlName="candidatetName" maxlength=40
                    disabled="true" placeholder="Candidate Name">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Reviewer Name </label>
                <input autocomplete="off" type="text" disabled=true pInputText formControlName="reviewName" maxlength=40
                    placeholder="Reviewer Name">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Review Status </label>
                <p-dropdown [options]="reviewerStatus1" optionLabel="label" placeholder="Reviewer Status"
                [showClear]="true"  formControlName="reviewStatus"></p-dropdown>
            </div>
        </div>
        <h5 id="h3tag">Ratings</h5>
        <div class="p-grid p-fluid">
            <div class="p-col-3 p-md-3 p-sm-12 p-lg-3 name">
                <label class="inputfields">Architecture </label>
                <p-rating *ngIf="hide" [cancel]="false" formControlName="architecture"></p-rating>
                <p-rating *ngIf="!hide" disabled=true [cancel]="false" formControlName="architecture"></p-rating>
            </div>
            <div class="p-col-3 p-md-3 p-sm-12 p-lg-3 name">
                <label class="inputfields">Coding </label>
                <p-rating *ngIf="hide" [cancel]="false" formControlName="coding"></p-rating>
                <p-rating *ngIf="!hide" disabled=true [cancel]="false" formControlName="coding"></p-rating>
            </div>
            <div class="p-col-3 p-md-3 p-sm-12 p-lg-3 name ">
                <label class="inputfields">Testing </label>
                <p-rating *ngIf="hide" [cancel]="false" formControlName="testing"></p-rating>
                <p-rating *ngIf="!hide" disabled=true [cancel]="false" formControlName="testing"></p-rating>
            </div>
            <div class="p-col-3 p-md-3 p-sm-12 p-lg-3 name">
                <label class="inputfields">Business </label>
                <p-rating *ngIf="hide" [cancel]="false" formControlName="business"></p-rating>
                <p-rating *ngIf="!hide" disabled=true [cancel]="false" formControlName="business"></p-rating>
            </div>
       
            <div class="p-col-3 p-md-3 p-sm-12 p-lg-3 name">
                <label class="inputfields">Ownership </label>
                <p-rating *ngIf="hide" [cancel]="false" formControlName="ownershsip"></p-rating>
                <p-rating *ngIf="!hide" disabled=true [cancel]="false" formControlName="ownershsip"></p-rating>
            </div>
            <div class="p-col-3 p-md-3 p-sm-12 p-lg-3 name">
                <label class="inputfields">Team Management </label>
                <p-rating *ngIf="hide" [cancel]="false" formControlName="management"></p-rating>
                <p-rating *ngIf="!hide" disabled=true [cancel]="false" formControlName="management"></p-rating>
            </div>
            <div class="p-col-3 p-md-3 p-sm-12 p-lg-3 name">
                <label class="inputfields">Analytical </label>
                <p-rating *ngIf="hide" [cancel]="false" formControlName="analytical"></p-rating>
                <p-rating *ngIf="!hide" disabled=true [cancel]="false" formControlName="analytical"></p-rating>
            </div>
            <div class="p-col-3 p-md-3 p-sm-12 p-lg-3 name">
                <label class="inputfields">Communication </label>
                <p-rating *ngIf="hide" [cancel]="false" formControlName="communication"></p-rating>
                <p-rating *ngIf="!hide" disabled=true [cancel]="false" formControlName="communication"></p-rating>
            </div>
       
            <div class="p-col-3 p-md-3 p-sm-12 p-lg-3 name">
                <label class="inputfields">Documentation </label>
                <p-rating *ngIf="hide" [cancel]="false" formControlName="documentation"></p-rating>
                <p-rating *ngIf="!hide" disabled=true [cancel]="false" formControlName="documentation"></p-rating>
            </div>
            <div class="p-col-3 p-md-3 p-sm-12 p-lg-3 name">
                <label class="inputfields">Position </label>
                <p-rating *ngIf="hide" [cancel]="false" formControlName="position"></p-rating>
                <p-rating *ngIf="!hide" disabled=true [cancel]="false" formControlName="position"></p-rating>
            </div>
            <div class="p-col-3 p-md-3 p-sm-12 p-lg-6 name"></div>
            <div class="p-col-3 p-md-3 p-sm-12 p-lg-3 name">
                <label class="inputfields ratingcol">Average Rating </label>
                <p-rating [cancel]="false" formControlName="average" disabled=true></p-rating>
            </div>
            <div class="p-col-12 ">
                <label class="inputfields" id='csslabel'>Comments</label>
                <textarea *ngIf="hide" autocomplete="off" formControlName="requirementsComments" placeholder="Comments"
                    pInputTextarea rows="2" cols="30" maxlength=300></textarea>

                <textarea *ngIf="!hide" disabled=true autocomplete="off" formControlName="requirementsComments"
                    placeholder="Comments" pInputTextarea rows="2" cols="30" maxlength=300></textarea>
            </div>
        </div>
        <div class="btnsCSS1" *ngIf="hide">
            <button *ngIf="this.roleId == 12 || this.roleId == 10" [disabled]="disable" pButton type="submit"
                icon='pi pi-save' label="Save" (click)="addReqData()" class="addhBtn"></button>
        </div><br><br>
    </form>
</p-panel>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>