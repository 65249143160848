import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-passwod',
  templateUrl: './reset-passwod.component.html',
  styleUrls: ['./reset-passwod.component.scss'],
  providers: [MessageService],
})
export class ResetPasswodComponent implements OnInit {
  resetPasswordForm: any;
  text: string;
  capitalLetterRegEx: string;
  smallLetterRegEx: string;
  numberRegEx: string;
  instruction = false;
  // hide = true;
  // hideIcon = true;
  showIcon: boolean;
  hide1: boolean;
  hide2: boolean;
  hideCfmPWDIcon: boolean;
  showCfmPWDIcon: boolean;
  disable: boolean;
  files: any;
  success: string;
  router: any;
  // myFunction() {
  //   this.hide = !this.hide;
  //   this.hideIcon = false;
  //   this.showIcon = true;
  // }
  // myFunction1() {
  //   this.hide = !this.hide;
  //   this.hideIcon = true;
  //   this.showIcon = false;
  // }
  constructor(private breadcrumbService: BreadcrumbService, private fb: FormBuilder,
              private apisService: ApisService, private messageService: MessageService ) {
    this.breadcrumbService.setItems([
      { label: 'Reset Password', routerLink: '/reset-passwod' },
    ]);
  }

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group({
      Email: ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/), Validators.maxLength(60)]],
      password: ['', [Validators.required, Validators.maxLength(20)]],
    });
  }

  resetPassword() {
    if (this.resetPasswordForm.invalid) {
      const controls = this.resetPasswordForm.controls;
      for (const name in controls) { if (controls[name].invalid) { } }
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      this.disable = true;
      const email =
        this.resetPasswordForm.controls.Email.value;
      const password = this.resetPasswordForm.controls.password.value;
      const data = {
        // tslint:disable-next-line:object-literal-shorthand
        email: email,
        // tslint:disable-next-line:object-literal-shorthand
        password: password,
      };
      this.disable = true;
      this.apisService.postApi(environment.updateMail, data).subscribe((res: any) => {
        this.files = res;
        // tslint:disable-next-line:no-conditional-assignment
        if (this.success = this.files.success ? 'success' : 'error') {
          this.resetPasswordForm.reset();
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.files.message, });
          this.disable = false;
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 3000);
        }
        else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail:  this.files.message, });
          this.disable = false;
        }
      });
    }
  }
  onSearchChange(searchValue: string): void {
    this.text = searchValue;
    this.capitalLetterRegEx = '.*[A-Z]+.*';
    this.smallLetterRegEx = '.*[a-z]+.*';
    this.numberRegEx = '.*[0-9]+.*';
    if (this.text.search(this.capitalLetterRegEx) === -1) {
      this.resetPasswordForm.controls.upper.patchValue(false);
    } else {
      this.resetPasswordForm.controls.upper.patchValue(true);
    }
    if (this.text.search(this.smallLetterRegEx) === -1) {
      this.resetPasswordForm.controls.lower.patchValue(false);
    } else {
      this.resetPasswordForm.controls.lower.patchValue(true);
    }
    if (this.text.search(this.numberRegEx) === -1) {
      this.resetPasswordForm.controls.number.patchValue(false);
    } else {
      this.resetPasswordForm.controls.number.patchValue(true);
    }
    if (this.text.length < 8) {
      this.resetPasswordForm.controls.eight.patchValue(false);
    } else {
      this.resetPasswordForm.controls.eight.patchValue(true);
    }
  }

  instructions() {
    this.instruction = true;
  }
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.resetPassword();
    }
  }
}
