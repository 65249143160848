import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Stomp } from '@stomp/stompjs';
import { MessageService } from 'primeng/api';
import * as SockJS from 'sockjs-client';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-customerregistration',
  templateUrl: './customerregistration.component.html',
  styleUrls: ['./customerregistration.component.scss'],
  providers: [DatePipe, MessageService],
})
export class CustomerregistrationComponent implements OnInit {
  consultantRegForm: FormGroup;
  gender = [
    { name: 'Male', code: 'M' },
    { name: 'Female', code: 'F' }
  ];
  country: any;
  fileName: any;
  base64File: any;
  fileErrorText: string;
  file: string | ArrayBuffer;
  loader: boolean;
  displayNumber: boolean;
  phSpinnerHide: boolean;
  mobileNum: any;
  officenummsg: string;
  phCheckHide: boolean;
  mailSpinnerHide: boolean;
  emailExten: any;
  ofcMailValidation: string;
  mailCheckHide: boolean;
  displayemail: boolean;
  socket: WebSocket;
  stompClient: any;
  message1: any = [];
  emailres: any;
  displayBasic: boolean;
  disable: boolean;
  successMsg: any;
  exprienceList: any = [];
  filedata: FormData;
  UserID: any;
  name: any;
  qualificationList: any = [];
  countrycodedropdown: any = [];
  classinput: boolean;
  filesdatass: boolean;
  names: any;
  classinput1: boolean;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private route: Router, private messageService: MessageService, ) { }
  assessmentType: any;
  ngOnInit(): void {
    this.classinput = true;
    this.consultantRegForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'),  Validators.maxLength(25)]],
      lastName: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'),  Validators.maxLength(25)]],
      emailId: ['', [Validators.required,
        Validators.pattern('^[[a-zA-Z0-9.+-_]+@[a-zA-Z0-9.+-_]+.[a-zA-Z]{2,5}]*$'), Validators.maxLength(60)]],
      mobileNumber: ['', [Validators.required, Validators.maxLength(15)]],
      experience: [''],
      qualification: [''],
      uploadResume: [''],
      country: ['', Validators.required],
      fileData: [''],
      gender: ['', Validators.required],
      mobileDrp: [''],
    });
    this.consultantRegForm.controls.mobileDrp.patchValue({
      code: '+1',
      flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png',
      id: '2',
      label: 'UNITED STATES',
    });
    this.getCountryDropdown();
    this.getExperience();
    this.qualificationDD();
    this.getdrop();
    this.consultantRegForm.controls.gender.patchValue(this.gender.filter((x) => x.code === 'M')[0]);
  }
  countryids(event) {
    if (event.value.label === 'INDIA') {
      this.consultantRegForm.controls.country.patchValue(this.country.filter((x) => x.code === 'IND')[0]);
    } else if (event.value.label === 'UNITED STATES') {
      this.consultantRegForm.controls.country.patchValue(this.country.filter((x) => x.code === 'USA')[0]);
    } else if (event.value.label === 'CANADA') {
      this.consultantRegForm.controls.country.patchValue(this.country.filter((x) => x.code === 'CAN')[0]);
    } else if (event.value.label === 'UNITED KINGDOM') {
      this.consultantRegForm.controls.country.patchValue(this.country.filter((x) => x.code === 'UK')[0]);
    } else if (event.value.label === 'AUSTRALIA') {
      this.consultantRegForm.controls.country.patchValue(this.country.filter((x) => x.code === 'AUS')[0]);
    } else if (event.value.label === 'BRAZIL') {
      this.consultantRegForm.controls.country.patchValue(this.country.filter((x) => x.code === 'BRA')[0]);
    } else if (event.value.label === 'CHINA') {
      this.consultantRegForm.controls.country.patchValue(this.country.filter((x) => x.code === 'CHN')[0]);
    } else if (event.value.label === 'SINGAPORE') {
      this.consultantRegForm.controls.country.patchValue(this.country.filter((x) => x.code === 'SGP')[0]);
    } else if (event.value.label === 'JAPAN') {
      this.consultantRegForm.controls.country.patchValue(this.country.filter((x) => x.code === 'JPN')[0]);
    }else if (event.value.label === 'SWITZERLAND') {
      this.consultantRegForm.controls.country.patchValue(this.country.filter((x) => x.code === 'SWS')[0]);
    } else if (event.value.label === 'MEXICO') {
      this.consultantRegForm.controls.country.patchValue(this.country.filter((x) => x.code === 'MEX')[0]);
    }else if (event.value.label === 'ITALY') {
      this.consultantRegForm.controls.country.patchValue(this.country.filter((x) => x.code === 'ITL')[0]);
    }else if (event.value.label === 'ISRAEL') {
      this.consultantRegForm.controls.country.patchValue(this.country.filter((x) => x.code === 'ISR')[0]);
    }else if (event.value.label === 'GERMANY') {
      this.consultantRegForm.controls.country.patchValue(this.country.filter((x) => x.code === 'GER')[0]);
    }else if (event.value.label === 'FRANCE') {
      this.consultantRegForm.controls.country.patchValue(this.country.filter((x) => x.code === 'FRA')[0]);
    }else if (event.value.label === 'SAUDI ARABIA') {
      this.consultantRegForm.controls.country.patchValue(this.country.filter((x) => x.code === 'SAU')[0]);
    }
  }
  getdrop() {
    this.countrycodedropdown = this.apisService.getdropdwon();
  }
  getCountryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'adlc').subscribe(res => {
      this.country = res.data;
      this.consultantRegForm.controls.country.patchValue(this.country.filter((x) => x.code === 'USA')[0]);
    });
  }
  getExperience() {
    this.apisService.getApi(environment.lookupsData + 'RELYE').subscribe((res: any) => {
      this.exprienceList = res.data;
    });
  }
  qualificationDD() {
    this.apisService.getApi(environment.lookupsData + 'QFC').subscribe(res => {
      this.qualificationList = res.data;
    });
  }
  // It is used for the save the Consultant Registration details and keep the validations
  checkPhone() {
    this.displayNumber = false;
    const num = this.consultantRegForm.controls.mobileNumber.value;
    if (num) {
      this.phSpinnerHide = true;
      this.apisService.getApi(environment.phonerequired + num).subscribe(res => {
        this.mobileNum = res;
        this.mobileNum = res.data.isNumberExisted;
        if (this.mobileNum === true) {
          this.phSpinnerHide = false;
          this.officenummsg = 'Mobile number already exist';
          setTimeout(() => {
            this.officenummsg = '';
          }, 5000);
          this.consultantRegForm.get('mobileNumber').reset();
        }
        else {
          this.phSpinnerHide = false;
          this.phCheckHide = true;
          setTimeout(() => {
            this.phCheckHide = false;
          }, 3000);
        }
      });
    }
  }
  checkEmail() {
    this.displayemail = false;
    const email = this.consultantRegForm.controls.emailId.value;
    if (email === null || email === '' || email === undefined) {
    } else {
      if (this.consultantRegForm.controls.emailId.invalid && this.consultantRegForm.controls.emailId.errors.pattern) {
      }
      else {
        this.mailSpinnerHide = true;
        this.loader = true;
        this.apisService.getApi(environment.emailVaildation + email).subscribe((res) => {
          this.emailExten = res;
          this.loader = false;
          if (this.emailExten.validation) {
            this.mailSpinnerHide = false;
            this.ofcMailValidation = 'Email already exist';
            setTimeout(() => {
              this.ofcMailValidation = '';
            }, 5000);
            this.consultantRegForm.get('emailId').reset();
          }
          else {
            this.mailSpinnerHide = false;
            this.mailCheckHide = true;
            this.sendMessage();
            setTimeout(() => {
              this.mailCheckHide = false;
            }, 3000);
          }
        });
      }
    }
  }
  sendMessage() {
    this.loader = true;
    const email = this.consultantRegForm.controls.emailId.value;
    this.socket = new SockJS(environment.socketendpoint);
    this.stompClient = Stomp.over(this.socket);
    this.stompClient.connect({},
      fra => {
        this.stompClient.subscribe('/topic/verify-email/' + email, tick => {

          this.message1 = [];
          this.message1.push(tick.body);
          this.emailres = JSON.parse(this.message1[0]);
          if (this.emailres.success === false) {
            this.loader = false;
            this.displayBasic = true;
            this.disable = true;
          }
          else if (this.emailres.success === true) {
            this.successMsg = JSON.parse(tick.body);
            this.displayBasic = false;
            this.disable = false;
            this.stompClient.disconnect(); // Disconnected socket connection
            this.consultantRegForm.controls.emailId.disable();
          }
        });
        this.stompClient.send('/servicesBay/verify-email/' + email + '/noToken', {});
      });
  }
  handleFileInput(event) {
    this.fileName = event.target.files[0];
    if (this.fileName.name.length > 25){
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'File name should be 25 alphabets.' });
      this.cancelfiles();
    }
    if (this.fileName.size > 10485760){
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please upload below 10MB.' });
      this.cancelfiles();
     }
   else{
    this.names = this.fileName.name;
    const formData: FormData = new FormData();
    formData.append('file', this.fileName);
    this.filedata = formData;
    // tslint:disable-next-line: no-conditional-assignment
    if (this.filedata = formData) {
      this.filesdatass = true;
    } else {
      this.filesdatass = false;
    }
   }
  }
  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.file = myReader.result;
    };
    myReader.readAsDataURL(file);
  }
  register() {
    if (this.consultantRegForm.invalid) {
      const controls = this.consultantRegForm.controls;
      Object.keys(controls).forEach(key => {
        controls[key].markAsTouched();
      });
    } else {
      const data = {
        firstname: this.consultantRegForm.controls.firstName.value,
        lastname: this.consultantRegForm.controls.lastName.value,
        mobilePhone: this.consultantRegForm.controls.mobileNumber.value,
        email: this.consultantRegForm.controls.emailId.value,
        gender: this.consultantRegForm.controls.gender.value.code,
        // tslint:disable-next-line:max-line-length
        education: this.consultantRegForm.controls.qualification.value === null || '' || this.consultantRegForm.controls.qualification.value.code === undefined ? '' : this.consultantRegForm.controls.qualification.value.code,
        experiance: this.consultantRegForm.controls.experience.value.code,
        livingCountry: this.consultantRegForm.controls.country.value.code,
        // tslint:disable-next-line:max-line-length
        mobileCode: this.consultantRegForm.controls.mobileDrp.value === null || '' || this.consultantRegForm.controls.mobileDrp.value.id === undefined ? '' : this.consultantRegForm.controls.mobileDrp.value.id.toString(),
      };
      this.loader = true;
      this.disable = true;
      this.apisService.postApi(environment.consultant, data).subscribe((res) => {
        this.UserID = res.userId;
        const userid = '/' + this.UserID + '/R';
        const file = this.consultantRegForm.controls.fileData.value;
        if (file === '' || null) {
        } else {
          this.apisService.postApi(environment.attachment + userid, this.filedata).subscribe((res1: any) => {
          });
        }
        this.loader = false;
        if (res.success === true) {
          this.messageService.add({key: 'br', severity: '', life: 3000,  detail: res.message });
          this.route.navigate(['/reg_success'], {
            queryParams: {
              reqname: res.success.orgName,
              mailid: res.success.from,
            },
          });
          this.consultantRegForm.reset();
        } else if (res.success === false) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error, });
        }
      });
    }
  }
  goto() {
    this.route.navigate(['/register']);
  }
  cancelfiles() {
    this.consultantRegForm.controls.fileData.reset();
    this.filesdatass = false;
    this.classinput = false;
    this.classinput1 = true;
  }
  resetRegForm() {
    this.filesdatass = false;
    this.consultantRegForm.reset();
  }
}
