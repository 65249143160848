<p-card>
    <form [formGroup]="projectForm">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Project Name</label>
                <input id="input" pInputText placeholder="Project Name" autocomplete="off" formControlName="projectname"
                    maxlength="30" (keyup.enter)="gridTable()">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Status</label>
                <p-dropdown [options]="status" formControlName="searchstatus" placeholder="Select Status"
                    optionLabel="label" [showClear]="true" (keyup.enter)="gridTable()"></p-dropdown>
            </div>
        </div>
        <div class="btnsCSS1 buttonalignement">
            <button pButton type="button" icon='pi pi-plus' label="Add" (click)="addproject()" class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn"
                (click)="gridTable()"></button>
        </div>
    </form>
</p-card><br>
<p-card class="pgpos">
    <div *ngIf="table">
        <p-table [columns]="cols" [value]="gridData" selectionMode="single" dataKey="edit" [responsive]="true"
            sortMode="single">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                        <span>
                            {{col.header}}
                        </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
                    <tr>
                        <td class="textCenter skill-desc" pTooltip="{{data.projectName}}" tooltipPosition="top"><span class="p-column-title"></span>{{data.projectName}}</td>
                        <td class="textCenter"><span class="p-column-title"></span>{{projectypelist[data.lkProjectType]}}
                        </td>
                        <td class="textCenter"><span class="p-column-title"></span>{{data.actualStartDate === null || data.actualStartDate ===''?'--':data.actualStartDate | date:'MM-dd-yyyy'}}</td>
                        <td class="textCenter"><span class="p-column-title"></span>{{data.actualEndDate===null || data.actualEndDate===''? '--':data.actualEndDate| date:'MM-dd-yyyy'}}</td>
                        <td class="textCenter"><span class="p-column-title"></span>
                            <p-badge [value]="statuslist[data.lkStatus]"
                            [ngClass]="data.lkStatus ==='A'?'green':data.lkStatus==='I'?'red':data.lkStatus==='C'?'evalution':'blue' ">
                        </p-badge>
                        </td>
                        <td class="textCenter"><span class="p-column-title"></span>{{data.noOfResources}}</td>
                        <td class="textCenter"><span class="p-column-title">Action</span><a class="pi pi-ellipsis-v pointer"
                                (click)="actionpopup.toggle($event); toggle(items, $event, data)"></a></td>
                    </tr>
            </ng-template>
        </p-table>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any projects</em><br>
            </div>
        </div>
    </p-card>
</p-card>
<form [formGroup]="projectAddForm">
    <p-dialog class="dialogBox" header="Add Project " [(visible)]="displayModal" [modal]="true" [baseZIndex]="1"
        [style]="{width: '35%'}" [draggable]="false" [resizable]="false">
        <div class="p-fluid p-grid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Project Name<span class="mandatoryColor">*</span></label>
                <input id="input" type="text" pInputText placeholder="Project Name" autocomplete="off"
                    formControlName="addProject" maxlength="51">
                <span class="validationtest"
                    *ngIf="this.projectAddForm.get('addProject').touched && this.projectAddForm.get('addProject').invalid && this.projectAddForm.get('addProject').errors.required">
                    Please enter project name
                </span>
                <span class="validationtest" *ngIf="this.projectAddForm.get('addProject').hasError('maxlength')">
                    Please enter 50 alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Estimated Start Date</label><span class="mandatoryColor">*</span>
                <p-calendar  dateFormat="mm-dd-yy" formControlName="startdate" placeholder="MM-DD-YYYY"
                    [showIcon]="true" [showButtonBar]="true"  [minDate]="startCurDate" (onSelect)="onselectDate('startdate')">
                </p-calendar>
                <span class="validationtest"
                    *ngIf="this.projectAddForm.get('startdate').touched && this.projectAddForm.get('startdate').invalid && this.projectAddForm.get('startdate').errors.required">
                    Please select estimated start date
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Estimated End Date</label><span class="mandatoryColor">*</span>
                <p-calendar  dateFormat="mm-dd-yy" formControlName="enddate" placeholder="MM-DD-YYYY"
                    [showIcon]="true" [showButtonBar]="true" 
                    [minDate]="minDate" (onSelect)="onselectDate('enddate')">
                </p-calendar>
                <span class="validationtest"
                *ngIf="this.projectAddForm.get('enddate').touched && this.projectAddForm.get('enddate').invalid && this.projectAddForm.get('enddate').errors.required">
                Please select estimated end date
            </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Project Type</label><span class="mandatoryColor">*</span>
                <p-dropdown formControlName="projecttype" placeholder="Select Project Type" [options]="projecttype"
                    optionLabel="label" [showClear]="true"></p-dropdown>
                <span class="validationtest"
                    *ngIf="this.projectAddForm.get('projecttype').touched && this.projectAddForm.get('projecttype').invalid && this.projectAddForm.get('projecttype').errors.required">
                    Please select project type
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Project Hours</label>
                <input id="input" type="text" pInputText placeholder="Project Hours" autocomplete="off"
                    formControlName="projecthrs" maxlength="9">
                <span class="validationtest"
                    *ngIf="this.projectAddForm.controls.projecthrs.invalid && this.projectAddForm.controls.projecthrs.errors?.pattern">Enter
                    numbers only</span>
                <span class="validationtest" *ngIf="this.projectAddForm.get('projecthrs').hasError('maxlength')">
                    Please enter 8 alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Project Cost</label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                <input id="input" type="text" pInputText placeholder="Project Cost" autocomplete="off"
                    formControlName="cost" maxlength="11"></div>
                <span class="validationtest"
                    *ngIf="this.projectAddForm.controls.cost.invalid && this.projectAddForm.controls.cost.errors?.pattern">Enter
                    numbers only</span>
                <span class="validationtest" *ngIf="this.projectAddForm.get('cost').hasError('maxlength')">
                    Please enter 10 alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Project Manager</label>
                <input id="input" type="text" pInputText placeholder="Project Manager" autocomplete="off"
                    disabled="true" formControlName="projectmanager">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Description</label><br>
                <textarea type="text" pInputText placeholder="Description" formControlName="addDes"
                    autoResize="autoResize" rows="5" maxlength="256"></textarea>
                <span class="validationtest" *ngIf="this.projectAddForm.get('addDes').hasError('maxlength')">
                    Please enter 255 alphabets only
                </span>
            </div>
        </div>
        <br>
        <div class="btnsCSS1">
            <button pButton type="button" icon='pi pi-save' label="Save" class="addhBtn" (click)="saveproject()"
                [disabled]="disable"></button>
        </div><br><br>
    </p-dialog>
</form>
<form [formGroup]="projectEditForm">
    <p-dialog class="dialogBox" header="Edit Project " [(visible)]="displayModal1" [modal]="true" [baseZIndex]="1"
    [style]="{width: '35%'}" [draggable]="false" [resizable]="false"  [contentStyle]="{'overflow': 'visible'}" >
        <div class="p-fluid p-grid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Project Name<span class="mandatoryColor">*</span></label>
                <input id="input" type="text" pInputText placeholder="Project Name" autocomplete="off"
                    formControlName="editProject" maxlength="51">
                <span class="validationtest"
                    *ngIf="this.projectEditForm.get('editProject').touched && this.projectEditForm.get('editProject').invalid && this.projectEditForm.get('editProject').errors.required">
                    Please enter project name
                </span>
                <span class="validationtest" *ngIf="this.projectEditForm.get('editProject').hasError('maxlength')">
                    Please enter 50 alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Estimated Start Date</label><span class="mandatoryColor">*</span>
                <p-calendar  dateFormat="mm-dd-yy" formControlName="estimatedstartdate"
                    placeholder="MM-DD-YYYY" [showIcon]="true" [showButtonBar]="true" [minDate]="startCurDate" 
                     (onSelect)="selectDate('estimatedstartdate');actualselectDate('actualstartdate')">
                </p-calendar>
                <span class="validationtest"
                    *ngIf="this.projectEditForm.get('estimatedstartdate').touched && this.projectEditForm.get('estimatedstartdate').invalid && this.projectEditForm.get('estimatedstartdate').errors.required">
                    Please select estimated start date
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Estimated End Date</label><span class="mandatoryColor">*</span>
                <p-calendar  dateFormat="mm-dd-yy" formControlName="estimatedenddate" placeholder="MM-DD-YYYY" 
                    [showIcon]="true" [showButtonBar]="true"  [minDate]="minDate" (onSelect)="selectDate('estimatedenddate')">
                </p-calendar>
                <span class="validationtest"
                    *ngIf="this.projectEditForm.get('estimatedenddate').touched && this.projectEditForm.get('estimatedenddate').invalid && this.projectEditForm.get('estimatedenddate').errors.required">
                    Please select estimated end date
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Actual Start Date</label><span class="mandatoryColor">*</span>
                <p-calendar  dateFormat="mm-dd-yy" formControlName="actualstartdate" placeholder="MM-DD-YYYY" 
                    [showIcon]="true" [showButtonBar]="true" [minDate]="minDate" (onSelect)="actualselectDate('actualstartdate');enddatefun('actualstartdate')">
                </p-calendar>
                <span class="validationtest"
                *ngIf="this.projectEditForm.get('actualstartdate').touched && this.projectEditForm.get('actualstartdate').invalid && this.projectEditForm.get('actualstartdate').errors.required">
                Please select actual start date
            </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Actual End Date</label><span *ngIf="endDate" class="mandatoryColor">*</span>
                <p-calendar  dateFormat="mm-dd-yy" formControlName="actualenddate" placeholder="MM-DD-YYYY"
                    [showIcon]="true" [showButtonBar]="true" [minDate]="minDate1" (onSelect)="enddatefun('actualenddate')">
                </p-calendar>
                <span class="validationtest"
                *ngIf="endDateMsg">
                Please select actual end date
            </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Project Type</label><span class="mandatoryColor">*</span>
                <p-dropdown formControlName="editprojecttype" placeholder="Select Project Type" [options]="projecttype"
                    optionLabel="label" [showClear]="true"></p-dropdown>
                <span class="validationtest"
                    *ngIf="this.projectEditForm.get('editprojecttype').touched && this.projectEditForm.get('editprojecttype').invalid && this.projectEditForm.get('editprojecttype').errors.required">
                    Please select project type
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Status</label>
                <p-dropdown [options]="status1" formControlName="editstatus" placeholder="Select Status" (onChange)="statusData()" 
                optionLabel="label" [showClear]="true"></p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Project Hours</label>
                <input id="input" type="text" pInputText placeholder="Project Hours" autocomplete="off"
                    formControlName="editprojecthrs" maxlength="9">
                <span class="validationtest"
                    *ngIf="this.projectEditForm.controls.editprojecthrs.invalid && this.projectEditForm.controls.editprojecthrs.errors?.pattern">Enter
                    numbers only</span>
                <span class="validationtest" *ngIf="this.projectEditForm.get('editprojecthrs').hasError('maxlength')">
                    Please enter 8 alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Project Cost</label>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                <input id="input" type="text" pInputText placeholder="Project Cost" autocomplete="off"
                    formControlName="editprojectcost" maxlength="11">
                    </div>
                <span class="validationtest"
                    *ngIf="this.projectEditForm.controls.editprojectcost.invalid && this.projectEditForm.controls.editprojectcost.errors?.pattern">Enter
                    numbers only</span>
                <span class="validationtest" *ngIf="this.projectEditForm.get('editprojectcost').hasError('maxlength')">
                    Please enter 10 alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Project Manager</label>
                <input id="input" type="text" pInputText placeholder="Project Manager" autocomplete="off"
                    disabled="true" formControlName="editprojectmanager">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">No.Of Resources</label>
                <input id="input" type="text" pInputText placeholder="No.Of Resources" autocomplete="off"
                    formControlName="noofresource" disabled="true">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Description</label><br>
                <textarea type="text" pInputText placeholder="Description" formControlName="editDes"
                    autoResize="autoResize" rows="5" maxlength="256"></textarea>
                <span class="validationtest" *ngIf="this.projectEditForm.get('editDes').hasError('maxlength')">
                    Please enter 255 alphabets only
                </span>
            </div>
        </div>
        <br>
        <div class="btnsCSS1" *ngIf="savebutton">
            <button pButton type="button" icon='pi pi-save' label="Update" class="addhBtn" (click)="updateproject()"
                [disabled]="updatedisable"></button>
        </div><br><br>
    </p-dialog>
</form>
<p-toast position="bottom-right" key="br"></p-toast>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>