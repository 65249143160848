<p-dialog [(visible)]="displaypopup" [contentStyle]="{'overflow': 'visible'}" header=" " [modal]="true"
    [style]="{width: '28%'}" [closable]="false">
    <form [formGroup]="AssociateForm">
        <div *ngIf='dialogstatus===1'>
            <h5 class="aligncenter">Welcome {{accname}}!</h5>
            <p class="aligncenter"> We would like to Asscociate to a company</p>
            <div class="aligncenter">
                <button pButton type="button" label="Yes" (click)="yesDialog()" class="btnspace"></button>
                <button pButton type="button" label="No" (click)="noDialog()"></button>
            </div>
        </div>
        <div *ngIf='dialogstatus===2'>
            <div class="aligncenter">
                <em class="fa fa-thumbs-o-up fa-5x" aria-hidden="true"></em>
            </div>
            <h5 class="aligncenter">Thank you</h5>
            <div class="aligncenter">
                <button pButton type="button" label="Ok" (click)="closeDialog()"></button>
            </div>
        </div>
        <div *ngIf='dialogstatus===3'>
            <h5 class="aligncenter">Welcome {{accname}}!</h5>
            <p class="aligncenter"> We would like to Asscociate to a company</p>
            <div class="aligncenter">
                <p-dropdown [options]='company' [filter]="true" formControlName="companyname" optionLabel='name'
                [showClear]="true" placeholder="Select CompanyName"></p-dropdown>
                <div>
                    <span class="validationtest"
                        *ngIf="AssociateForm.get('companyname').touched && AssociateForm.get('companyname').invalid && AssociateForm.get('companyname').errors.required">
                        Please select company</span>
                </div>
            </div><br>
            <div class="aligncenter"> <button pButton type="button" class="btnspace" icon='fa fa-handshake-o'
                    [disabled]="disable" (click)="associate()" label="Associate"></button>
                <button pButton type="button" icon="pi pi-arrow-circle-left" label="Back"
                    (click)="backDialog()"></button>
            </div>
        </div>
    </form>
</p-dialog>
<p-tabView *ngIf='this.roleId===15' (onChange)="handleChange($event)">
    <p-tabPanel header="Profile">
        <form [formGroup]="ConsultantprofileForm">
            <p-card>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 aligncenter">
                        <div class="p-grid p-fluid">
                            <div class="p-col-4 p-md-4 p-sm-12" class="imageDiv">
                                <div class="p-col-4 p-md-4 p-sm-12" class="imageDiv">
                                    <label class="hoverable" for="fileInput">
                                        <img alt="" class="img1"
                                            [src]="url ? url : (this.Ciamgedownloadurl?this.Ciamgedownloadurl:this.Cprofilegender)">
                                        <div class="middle">
                                            <div class="text"><em class="fa fa-camera fa-2x"></em></div>
                                        </div>
                                        <div class="hover-text">
                                            Choose file
                                            <h4 class="success-message" *ngIf="url"> Image added successfully </h4>
                                        </div>
                                        <div class="background"></div>
                                    </label>
                                    <br />
                                    <input autocomplete="off" id="fileInput" type='file' accept=".png, .jpeg, .jpg"
                                        (change)="onSelectFile($event)">
                                </div>
                            </div>
                            <p-dialog class="dialogBox" [(visible)]="displayModal2" [modal]="true"
                                [style]="{width: '28%'}" [baseZIndex]="1" [draggable]="false" [resizable]="false">
                                <p-header>
                                    <span><em class="pi pi-arrow-left backarrow" (click)="back()"></em></span>
                                </p-header>
                                <div>
                                    <label class="hoverable" for="fileInput1">
                                        <img alt="" [src]='Cimage' class="img2" (mouseover)="showImg=true"
                                            (mouseout)="showImg=false"
                                            (click)="(ConsultantProfileData?.imageId !== null) ? profileDetails1():''">
                                        <div class="hover-text">
                                            Choose file
                                            <label class="success-message" *ngIf="url"> Image added successfully
                                            </label>
                                        </div>
                                    </label>
                                    <input id="fileInput1" type='file' accept=".png, .jpeg, .jpg"
                                        (change)="onSelectFile($event)">
                                    <h3 class="aligncenter"><strong>Choose a Photo</strong></h3>
                                </div>
                                <br>
                                <div class="btnsCSS aligncenter">
                                    <button pButton type="file" label="Yes" class="hover"
                                        (click)="onSelectFile($event)">
                                    </button>
                                    <button pButton type="file" label="No" class="hover" (click)="back1()">
                                    </button>
                                </div>
                            </p-dialog>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-9">
                        <div class="p-grid p-justify-end ippos">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-9">
                                <div class="p-grid p-justify-end">
                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields">First Name<label class="mandatoryColor">*</label></label>
                                <input autocomplete="off" id="input" type="text" pInputText placeholder="First Name"
                                    maxlength="26" formControlName="firstName">
                                <span class="validationtest"
                                    *ngIf="ConsultantprofileForm.get('firstName').touched && ConsultantprofileForm.get('firstName').invalid && ConsultantprofileForm.get('firstName').errors.required">
                                    Please enter first name </span>
                                    <span class="validationtest"
            *ngIf="this.ConsultantprofileForm.controls.firstName.invalid && this.ConsultantprofileForm.controls.firstName.errors?.pattern">Please enter
            alphabets only</span>
                                <span class="validationtest"
                                    *ngIf="this.ConsultantprofileForm.get('firstName').hasError('maxlength')">
                                    Please enter 25 alphabets only
                                </span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields">Middle Name</label>
                                <input autocomplete="off" id="input" type="text" pInputText placeholder="Middle Name"
                                    maxlength="16" formControlName="middileName">
                                <span class="validationtest"
                                    *ngIf="this.ConsultantprofileForm.get('middileName').hasError('maxlength')">
                                    Please enter 15 alphabets only
                                </span>
                                <span class="validationtest"
                                *ngIf="this.ConsultantprofileForm.controls.middileName.invalid && this.ConsultantprofileForm.controls.middileName.errors?.pattern">Please enter
                                alphabets only</span>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields">Last Name<label class="mandatoryColor">*</label></label>
                                <input autocomplete="off" id="input" type="text" pInputText placeholder="Last Name"
                                    maxlength="26" formControlName="lastName">
                                <span class="validationtest"
                                    *ngIf="ConsultantprofileForm.get('lastName').touched && ConsultantprofileForm.get('lastName').invalid && ConsultantprofileForm.get('lastName').errors.required">
                                    Please enter last name </span>
                                <span class="validationtest"
                                    *ngIf="this.ConsultantprofileForm.get('lastName').hasError('maxlength')">
                                    Please enter 25 alphabets only
                                </span>
                                <span class="validationtest"
                                *ngIf="this.ConsultantprofileForm.controls.lastName.invalid && this.ConsultantprofileForm.controls.lastName.errors?.pattern">Please enter
                                alphabets only</span>
                            </div>
                        </div><br>
                        <div class="p-grid p-justify-end ippos">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields">Email</label>

                                <div class="p-inputgroup">
                                    <input autocomplete="off" id="input" type="text" pInputText placeholder="Email"
                                        maxlength="60" formControlName="emailId" disabled="true" #userInput>

                                    <span class="p-inputgroup-addon"> <em class="pi pi-copy pointer"
                                            (click)="emailToast()" (click)="copyInputMessage(userInput.value)"></em>
                                    </span>

                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields">Gender</label>
                                <p-dropdown [options]="gender" formControlName="gender" optionValue="code"
                                    [showClear]="true" placeholder="Select Gender" optionLabel="name"></p-dropdown>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields">Title<label class="mandatoryColor">*</label></label>
                                <input autocomplete="off" id="input" type="text" pInputText placeholder="Title"
                                    maxlength="26" formControlName="title">
                                <span class="validationtest"
                                    *ngIf="ConsultantprofileForm.get('title').touched && ConsultantprofileForm.get('title').invalid && ConsultantprofileForm.get('title').errors.required">
                                    Please enter title </span>
                                    <span class="validationtest"
                                    *ngIf="this.ConsultantprofileForm.controls.title.invalid && this.ConsultantprofileForm.controls.title.errors?.pattern">Please enter
                                    alphabets only</span>
                                <span class="validationtest"
                                    *ngIf="this.ConsultantprofileForm.get('title').hasError('maxlength')">
                                    Please enter 25 alphabets only
                                </span>
                            </div>
                        </div><br>
                        <div class="p-grid p-justify-end ippos">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields">Office Phone<label class="mandatoryColor">*</label></label>
                                <div class="p-col-12 p-md-12 p-sm-12 phone">
                                    <div class="p-inputgroup" class="p-col-4 p-md-4 ph-no">
                                        <span class="p-inputgroup-addon">
                                            <p-dropdown [options]="oCountryCodeDropdown" class="phonecodestyle"
                                                (onChange)="countryids($event)" filterPlaceholder='Search Results'
                                                optionLabel="label" formControlName="oMobileDrp">
                                                <ng-template let-items pTemplate="selectedItem">
                                                    <div class="flex-row">
                                                        <img class="lang-change-img" src={{items.flag}} alt="" />
                                                        <div class="lang-change-option-text selected-lang center">
                                                            {{items.code}}
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template let-country pTemplate="item">
                                                    <div class="flex-row" id="dropdown1">
                                                        <img class="lang-change-img" src={{country.flag}} alt="" />
                                                        <div class="lang-change-option-text center">{{country.label}}
                                                            {{country.code}}</div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </span>
                                    </div>
                                    <div class="p-col-8 p-md-8 spinner">
                                        <span class="p-input-icon-right"><em *ngIf="CphSpinnerHide"
                                                class="pi pi-spin pi-spinner spinnerstyles"></em>
                                            <em *ngIf="CphCheckHide" class="pi pi-check-circle tickmarkstyle"></em>
                                            <p-inputMask autocomplete="off" (onBlur)="CgetPhon()" mask="(999) 999-9999"
                                                formControlName="officePhone" placeholder="Office Phone">
                                            </p-inputMask>
                                        </span>
                                    </div>
                                </div>
                                <span class="validationtest"
                                    *ngIf="ConsultantprofileForm.get('officePhone').touched && ConsultantprofileForm.get('officePhone').invalid && ConsultantprofileForm.get('officePhone').errors.required">
                                    Please enter office phone number </span>
                                <p class="mandatoryColor">{{this.Cofficenummsg}}</p>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                                <label class="inputfields">Mobile Number</label>
                                <div class="p-col-12 p-md-12 p-sm-12 phone">
                                    <div class="p-inputgroup" class="p-col-4 p-md-4 ph-no">
                                        <span class="p-inputgroup-addon">
                                            <p-dropdown [options]="oCountryCodeDropdown" class="phonecodestyle"
                                                (onChange)="countryids($event)" filterPlaceholder='Search Results'
                                                optionLabel="label" formControlName="mMobileDrp">
                                                <ng-template let-items pTemplate="selectedItem">
                                                    <div class="flex-row">
                                                        <img class="lang-change-img" src={{items.flag}} alt="" />
                                                        <div class="lang-change-option-text selected-lang center">
                                                            {{items.code}}
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template let-country pTemplate="item">
                                                    <div class="flex-row" id="dropdown1">
                                                        <img class="lang-change-img" src={{country.flag}} alt="" />
                                                        <div class="lang-change-option-text center">{{country.label}}
                                                            {{country.code}}</div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </span>
                                    </div>
                                    <div class="p-col-8 p-md-8 spinner">
                                        <span class="p-input-icon-right"><em *ngIf="CphSpinnerHide1"
                                                class="pi pi-spin pi-spinner spinnerstyles"></em>
                                            <em *ngIf="CphCheckHide1" class="pi pi-check-circle tickmarkstyle"></em>
                                            <p-inputMask autocomplete="off" mask="(999) 999-9999"
                                                formControlName="mobileNumber" (onBlur)="Cgetmobi()"
                                                placeholder="Mobile Number">
                                            </p-inputMask>
                                        </span>
                                    </div>
                                </div>
                                <p class="mandatoryColor">{{this.mobinummsg}}</p>
                            </div>
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4"  pTooltip="{{this.patchlocationString1}}"  tooltipPosition="top">
                                <label class="inputfields">Work Location</label>
                                <p-dropdown [options]="workLocations" formControlName="workLocation"  optionLabel="name"
                                    [showClear]="true" placeholder="Select Work Location" optionLabel="name" >
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 id="h3tag">Contact Address</h5>
                <div class="p-grid p-fluid ippos">
                    <div class="p-col-12 p-lg-6 peraddress">
                        <div id="cssdiv">Permanent Address <span></span></div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-12  ">
                            <label class="inputfields">Address Line 1 </label>
                            <input type="text" autocomplete="off" pInputText placeholder="Address Line 1" maxlength="51"
                                (click)="disableCheckboxValue()" formControlName="permanentAddressLine1">
                            <span class="validationtest"
                                *ngIf="this.ConsultantprofileForm.get('permanentAddressLine1').hasError('maxlength')">
                                Please enter 50 alphabets only
                            </span>
                        </div>
                        <div class="p-col-12">
                            <div class="p-grid p-fluid ippos">
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-7">
                                    <label class="inputfields">Address Line 2</label>
                                    <input type="text" autocomplete="off" pInputText placeholder="Address Line 2"
                                        maxlength="51" (click)="disableCheckboxValue()"
                                        formControlName="permanentAddressLine2">
                                    <span class="validationtest"
                                        *ngIf="this.ConsultantprofileForm.get('permanentAddressLine2').hasError('maxlength')">
                                        Please enter 50 alphabets only
                                    </span>
                                </div>
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-5">
                                    <label class="inputfields">Mailbox</label>
                                    <input type="text" autocomplete="off" maxlength="11" pInputText
                                        placeholder="Mailbox" formControlName="mailbox1">
                                    <span class="validationtest"
                                        *ngIf="this.ConsultantprofileForm.get('mailbox1').hasError('maxlength')">
                                        Please enter 10 alphabets only
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12">
                            <div class="p-grid p-fluid ippos">
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
                                    <label class="inputfields">Country </label>
                                    <p-dropdown [options]="countryData" placeholder="Country" [showClear]="true"
                                        (onChange)="getStateByUrl($event.value.code)" (click)="disableCheckboxValue()"
                                        optionLabel="label" formControlName="permanentCountry">
                                    </p-dropdown>
                                    <div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
                                    <label class="inputfields">State </label>
                                    <p-dropdown [options]="StateData" placeholder="State" [showClear]="true"
                                        (click)="disableCheckboxValue()" optionLabel="label"
                                        formControlName="permanentState">
                                    </p-dropdown>
                                    <div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
                                    <label class="inputfields">City </label>
                                    <input type="text" autocomplete="off" pInputText placeholder="City" maxlength="21"
                                        (click)="disableCheckboxValue()" formControlName="permanentCity">
                                    <span class="validationtest"
                                        *ngIf="this.ConsultantprofileForm.get('permanentCity').hasError('maxlength')">
                                        Please enter 20 alphabets only
                                    </span>
                                    <div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
                                    <label class="inputfields">Zip</label>
                                    <input type="text" autocomplete="off" pInputText pKeyFilter="num" placeholder="Zip"
                                        maxlength="11" (click)="disableCheckboxValue()" formControlName="permanentZip">
                                    <span class="validationtest"
                                        *ngIf="this.ConsultantprofileForm.get('permanentZip').hasError('maxlength')">
                                        Please enter 10 alphabets only
                                    </span>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-lg-6 peraddress">
                        <div id="cssdiv"><span class="maddres">Mailing Address</span>
                            <p-checkbox name="mailingAdressCheckbox" [value]="true" (onChange)="disableMailAd($event)"
                                label="Same as Permanent Address" formControlName="mailingAdressCheckbox">
                            </p-checkbox>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-12  ">
                            <label class="inputfields">Address Line 1 </label>
                            <input type="text" autocomplete="off" maxlength="51" pInputText placeholder="Address Line 1"
                                formControlName="mailingAddressLine1">
                            <span class="validationtest"
                                *ngIf="this.ConsultantprofileForm.get('mailingAddressLine1').hasError('maxlength')">
                                Please enter 50 alphabets only
                            </span>
                        </div>
                        <div class="p-col-12">
                            <div class="p-grid p-fluid ippos">
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-7">
                                    <label class="inputfields">Address Line 2</label>
                                    <input type="text" autocomplete="off" maxlength="51" pInputText
                                        placeholder="Address Line 2" formControlName="mailingAddressLine2">
                                    <span class="validationtest"
                                        *ngIf="this.ConsultantprofileForm.get('mailingAddressLine2').hasError('maxlength')">
                                        Please enter 50 alphabets only
                                    </span>
                                </div>
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-5">
                                    <label class="inputfields">Mailbox</label>
                                    <input type="text" autocomplete="off" maxlength="11" pInputText
                                        placeholder="Mailbox" formControlName="mailbox2">
                                    <span class="validationtest"
                                        *ngIf="this.ConsultantprofileForm.get('mailbox2').hasError('maxlength')">
                                        Please enter 10 alphabets only
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12">
                            <div class="p-grid p-fluid invalidMandatory ippos">
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
                                    <label class="inputfields">Country</label>
                                    <p-dropdown [options]="countryData" placeholder="Country" [showClear]="true"
                                        formControlName="mailingCountry" (onChange)="getStateByUrl1($event.value.code)"
                                        optionLabel="label">
                                    </p-dropdown>
                                    <div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
                                    <label class="inputfields">State </label>
                                    <p-dropdown [options]="StateData1" placeholder="State" [showClear]="true"
                                        formControlName="mailingState" optionLabel="label">
                                    </p-dropdown>
                                    <div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                    <label class="inputfields">City </label>
                                    <input type="text" autocomplete="off" maxlength="21" pInputText placeholder="City"
                                        formControlName="mailingCity">
                                    <span class="validationtest"
                                        *ngIf="this.ConsultantprofileForm.get('mailingCity').hasError('maxlength')">
                                        Please enter 20 alphabets only
                                    </span>
                                    <div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                                    <label class="inputfields">Zip </label>
                                    <input type="text" autocomplete="off" pInputText pKeyFilter="num" placeholder="Zip"
                                        formControlName="mailingZip" maxlength="11">
                                    <span class="validationtest"
                                        *ngIf="this.ConsultantprofileForm.get('mailingZip').hasError('maxlength')">
                                        Please enter 10 alphabets only
                                    </span>
                                    <div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <br> -->
                <div class="btnsCSS1">
                    <button pButton type="submit" icon='pi pi-save' label="Update" (click)="ConsultantprofileUpdate()"
                        class="addhBtn"></button>
                </div><br><br>
            </p-card>
        </form>
    </p-tabPanel>
    <p-tabPanel header="Other Details">
        <form [formGroup]="addConsultantForm">
            <p-card>
                <div class="p-grid p-fluid ippos">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                        <label class="inputfields">Experience </label>
                        <p-dropdown placeholder="Select Experience" optionLabel="label" [options]="experienceData"
                            [showClear]="true" formControlName="experience">
                        </p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Current Rate</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                            <input id="input" autocomplete="off" type="text" pInputText placeholder="Current Rate"
                                formControlName="currentRate">
                            <span class="p-inputgroup-addon">/Hrs</span>
                        </div>
                        <span class="validationtest"
                            *ngIf="this.addConsultantForm.controls.currentRate.invalid && this.addConsultantForm.controls.currentRate.errors?.pattern">Please enter
                            numbers only</span>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Current CTC </label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                            <input id="input" autocomplete="off" type="text" pInputText placeholder="Current CTC"
                                formControlName="currentCTC">
                            <span class="p-inputgroup-addon">/Yr</span>
                        </div>
                        <span class="validationtest"
                            *ngIf="this.addConsultantForm.controls.currentCTC.invalid && this.addConsultantForm.controls.currentCTC.errors?.pattern">Please enter
                            numbers only</span>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3"></div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Expected MinRate </label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                            <input id="input" autocomplete="off" type="text" pInputText placeholder="Expected MinRate"
                                formControlName="expMinRate">
                            <span class="p-inputgroup-addon">/Hrs</span>
                        </div>
                        <span class="validationtest"
                            *ngIf="this.addConsultantForm.controls.expMinRate.invalid && this.addConsultantForm.controls.expMinRate.errors?.pattern">Please enter
                            numbers only</span>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Expected MaxRate </label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                            <input id="input" autocomplete="off" type="text" pInputText placeholder="Expected MaxRate"
                                formControlName="expMaxRate">
                            <span class="p-inputgroup-addon">/Hrs</span>
                        </div>
                        <span class="validationtest"
                            *ngIf="this.addConsultantForm.controls.expMaxRate.invalid && this.addConsultantForm.controls.expMaxRate.errors?.pattern">Please enter
                            numbers only</span>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Expected MinCTC </label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                            <input id="input" autocomplete="off" type="text" pInputText placeholder="Expected MinCTC"
                                formControlName="expMinCTC">
                            <span class="p-inputgroup-addon">/Yr</span>
                        </div>
                        <span class="validationtest"
                            *ngIf="this.addConsultantForm.controls.expMinCTC.invalid && this.addConsultantForm.controls.expMinCTC.errors?.pattern">Please enter
                            numbers only</span>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Expected MaxCTC </label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                            <input id="input" autocomplete="off" type="text" pInputText placeholder="Expected MaxCTC"
                                formControlName="expMaxCTC">
                            <span class="p-inputgroup-addon">/Yr</span>
                        </div>
                        <span class="validationtest"
                            *ngIf="this.addConsultantForm.controls.expMaxCTC.invalid && this.addConsultantForm.controls.expMaxCTC.errors?.pattern">Please enter
                            numbers only</span>
                    </div>
             
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                        <div>
                            <label class="inputfields">Education</label>
                            <textarea autocomplete="off" placeholder="Education" pInputTextarea rows="2" cols="30"
                                formControlName="education" maxlength="11"></textarea>
                            <span class="validationtest"
                                *ngIf="this.addConsultantForm.get('education').hasError('maxlength')">
                                Please enter 10 alphabets only
                            </span>
                        </div>
                    </div>
              
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Working Country<label class="mandatoryColor">*</label></label>
                        <p-dropdown placeholder="Select Working Country" [options]="lvcountry" optionLabel="label"
                            [showClear]="true" formControlName="workingCountry">
                        </p-dropdown>
                        <span class="validationtest"
                            *ngIf="addConsultantForm.get('workingCountry').touched && addConsultantForm.get('workingCountry').invalid && addConsultantForm.get('workingCountry').errors.required">
                            Please select working country</span>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Currency<label class="mandatoryColor">*</label></label>
                        <p-dropdown [options]="currencyData" placeholder="Select Currency" optionLabel="label"
                            [showClear]="true" formControlName="currency">
                        </p-dropdown>
                        <span class="validationtest"
                            *ngIf="addConsultantForm.get('currency').touched && addConsultantForm.get('currency').invalid && addConsultantForm.get('currency').errors.required">
                            Please select currency</span>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Relocation</label>
                        <p-dropdown [options]="Relocation" placeholder="Select Relocation" optionLabel="name"
                            [showClear]="true" formControlName="relocation">
                        </p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3"></div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Living Country</label>
                        <p-dropdown optionLabel="label" [options]="lvcountry" formControlName="livingCountry"
                            [showClear]="true" placeholder="Select Living Country">
                        </p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Consultant Work Status<label class="mandatoryColor">*</label></label>
                        <p-dropdown placeholder="Consultant Work Status" maxlength="201" [options]="consultant"
                            optionLabel="label" [showClear]="true" formControlName="consultantworkstatus">
                        </p-dropdown>
                        <span class="validationtest"
                            *ngIf="addConsultantForm.get('consultantworkstatus').touched && addConsultantForm.get('consultantworkstatus').invalid && addConsultantForm.get('consultantworkstatus').errors.required">
                            Please select consultant work status</span>
                       
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6"></div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                        <label class="inputfields">Consultant Work Status Comments</label>
                        <textarea autocomplete="off" maxlength="201" placeholder="Consultant Work Status Comments"
                            pInputTextarea formControlName="consultantworkstatusComments" rows="8" cols="30"></textarea>
                            <span class="validationtest"
                            *ngIf="this.addConsultantForm.get('consultantworkstatusComments').hasError('maxlength')">
                            Please enter 200 alphabets only
                        </span>
                   
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                        <label class="inputfields">Comments</label>
                        <textarea autocomplete="off" maxlength="201" placeholder="Comments" pInputTextarea formControlName="comments"
                            rows="8" cols="30"></textarea>
                            <span class="validationtest"
                            *ngIf="this.addConsultantForm.get('comments').hasError('maxlength')">
                            Please enter 200 alphabets only
                        </span>
                    </div>
                </div>
                <div class="btnsCSS1">
                    <button class="addhBtn" pButton type="button" icon='pi pi-save' label="Update"
                        (click)="updateConsultantProfile()"></button>
                </div>
                <br>
                <br>
            </p-card>
        </form>
    </p-tabPanel>
</p-tabView>
<form [formGroup]="profileForm" *ngIf='this.roleId!=15'>
    <p-card>
        <p-panel header="Profile" [toggleable]="false" [style]="{'margin-bottom':'20px'}">
            <div class="p-fluid p-grid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 aligncenter">
                    <div class="p-grid p-fluid">
                        <div class="p-col-4 p-md-4 p-sm-12" class="imageDiv">
                            <div class="p-col-4 p-md-4 p-sm-12" class="imageDiv">
                                <label class="hoverable" for="fileInput">
                                    <img alt="" class="img1"
                                        [src]="url ? url : (this.iamgedownloadurl?this.iamgedownloadurl:this.profilegender)">
                                    <div class="middle">
                                        <div class="text"><em class="fa fa-camera fa-2x"></em></div>
                                    </div>
                                    <div class="hover-text">
                                        Choose file
                                        <h4 class="success-message" *ngIf="url"> Image added successfully </h4>
                                    </div>
                                    <div class="background"></div>
                                </label>
                                <br />
                                <input autocomplete="off" id="fileInput" type='file' accept=".png, .jpeg, .jpg"
                                    (change)="onSelectFile($event)">
                            </div>
                        </div>
                        <p-dialog class="dialogBox" [(visible)]="displayModal2" [modal]="true" [style]="{width: '28%'}"
                            [baseZIndex]="1" [draggable]="false" [resizable]="false">
                            <p-header>
                                <span><em class="pi pi-arrow-left backarrow" (click)="back()"></em></span>
                            </p-header>
                            <div>
                                <label class="hoverable" for="fileInput1">
                                    <img alt="" [src]='image' class="img2" (mouseover)="showImg=true"
                                        (mouseout)="showImg=false"
                                        (click)="(profileData?.imageId !== null) ? profileDetails1():''">
                                    <div class="hover-text">
                                        Choose file
                                        <label class="success-message" *ngIf="url"> Image added successfully </label>
                                    </div>
                                </label>
                                <input id="fileInput1" type='file' accept=".png, .jpeg, .jpg"
                                    (change)="onSelectFile($event)">
                                <h3 class="aligncenter"><strong>Choose a Photo</strong></h3>
                            </div>
                            <br>
                            <div class="btnsCSS aligncenter">
                                <button pButton type="file" label="Yes" class="hover" (click)="onSelectFile($event)">
                                </button>
                                <button pButton type="file" label="No" class="hover" (click)="back1()">
                                </button>
                            </div>
                        </p-dialog>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-9">
                    <div class="p-grid p-justify-end ippos">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-9">
                            <div class="p-grid p-justify-end">
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                            <label class="inputfields">First Name<label class="mandatoryColor">*</label></label>
                            <input autocomplete="off" id="input" type="text" pInputText placeholder="First Name"
                                maxlength="26" formControlName="firstName">
                            <span class="validationtest"
                                *ngIf="profileForm.get('firstName').touched && profileForm.get('firstName').invalid && profileForm.get('firstName').errors.required">
                                Please enter first name </span>
                            <span class="validationtest"
                                *ngIf="this.profileForm.get('firstName').hasError('maxlength')">
                                Please enter 25 alphabets only
                            </span>
                            
<span class="validationtest"
*ngIf="this.profileForm.controls.firstName.invalid && this.profileForm.controls.firstName.errors?.pattern">Please enter
alphabets only</span>

                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                            <label class="inputfields">Middle Name</label>
                            <input autocomplete="off" id="input" type="text" pInputText placeholder="Middle Name"
                                maxlength="16" formControlName="middileName">
                            <span class="validationtest"
                                *ngIf="this.profileForm.get('middileName').hasError('maxlength')">
                                Please enter 15 alphabets only
                            </span>
                            <span class="validationtest"
*ngIf="this.profileForm.controls.middileName.invalid && this.profileForm.controls.middileName.errors?.pattern">Please enter
alphabets only</span>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                            <label class="inputfields">Last Name<label class="mandatoryColor">*</label></label>
                            <input autocomplete="off" id="input" type="text" pInputText placeholder="Last Name"
                                maxlength="26" formControlName="lastName">
                            <span class="validationtest"
                                *ngIf="profileForm.get('lastName').touched && profileForm.get('lastName').invalid && profileForm.get('lastName').errors.required">
                                Please enter last name </span>
                            <span class="validationtest" *ngIf="this.profileForm.get('lastName').hasError('maxlength')">
                                Please enter 25 alphabets only
                            </span>
                            <span class="validationtest"
*ngIf="this.profileForm.controls.lastName.invalid && this.profileForm.controls.lastName.errors?.pattern">Please enter
alphabets only</span>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                            <label class="inputfields">Email</label>
                            <div class="p-inputgroup">
                                <input autocomplete="off" id="input" type="text" pInputText placeholder="Email"
                                    maxlength="60" formControlName="emailId" disabled="true" #userInput>

                                <span class="p-inputgroup-addon"> <em class="pi pi-copy pointer" (click)="emailToast()"
                                        (click)="copyInputMessage(userInput.value)"></em>
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                            <label class="inputfields">Gender</label>
                            <p-dropdown [options]="gender" formControlName="gender" optionValue="code"
                                [showClear]="true" placeholder="Select Gender" optionLabel="name"></p-dropdown>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                            <label class="inputfields">Title<label class="mandatoryColor">*</label></label>
                            <input autocomplete="off" id="input" type="text" pInputText placeholder="Title"
                                maxlength="26" formControlName="title">
                            <span class="validationtest"
                                *ngIf="profileForm.get('title').touched && profileForm.get('title').invalid && profileForm.get('title').errors.required">
                                Please enter title </span>
                            <span class="validationtest" *ngIf="this.profileForm.get('title').hasError('maxlength')">
                                Please enter 25 alphabets only
                            </span>
                            <span class="validationtest"
            *ngIf="this.profileForm.controls.title.invalid && this.profileForm.controls.title.errors?.pattern">Please enter
            alphabets only</span>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                            <label class="inputfields">Office Phone<label class="mandatoryColor">*</label></label>
                            <div class="p-col-12 p-md-12 p-sm-12 phone">
                                <div class="p-inputgroup" class="p-col-4 p-md-4 ph-no">
                                    <span class="p-inputgroup-addon">
                                        <p-dropdown [options]="oCountryCodeDropdown" class="phonecodestyle newcode"
                                            (onChange)="countryids($event)" filterPlaceholder='Search Results'
                                            optionLabel="label" formControlName="ConoffMobileDrp">
                                            <ng-template let-items pTemplate="selectedItem">
                                                <div class="flex-row">
                                                    <img class="lang-change-img" src={{items.flag}} alt="" />
                                                    <div class="lang-change-option-text selected-lang center">
                                                        {{items.code}}
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template let-country pTemplate="item">
                                                <div class="flex-row" id="dropdown1">
                                                    <img class="lang-change-img" src={{country.flag}} alt="" />
                                                    <div class="lang-change-option-text center">{{country.label}}
                                                        {{country.code}}</div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </span>
                                </div>
                                <div class="p-col-8 p-md-8 spinner">
                                    <span class="p-input-icon-right"><em *ngIf="phSpinnerHide"
                                            class="pi pi-spin pi-spinner spinnerstyles"></em>
                                        <em *ngIf="phCheckHide" class="pi pi-check-circle tickmarkstyle"></em>
                                        <p-inputMask autocomplete="off" (onBlur)="getPhon()" mask="(999) 999-9999"
                                            formControlName="officePhone" placeholder="Office Phone">
                                        </p-inputMask>
                                    </span>
                                </div>
                            </div>
                            <span class="validationtest"
                                *ngIf="profileForm.get('officePhone').touched && profileForm.get('officePhone').invalid && profileForm.get('officePhone').errors.required">
                                Please enter office phone number </span>
                            <p class="mandatoryColor">{{this.officenummsg}}</p>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                            <label class="inputfields">Mobile Number</label>
                            <div class="p-col-12 p-md-12 p-sm-12 phone">
                                <div class="p-inputgroup" class="p-col-4 p-md-4 ph-no">
                                    <span class="p-inputgroup-addon">
                                        <p-dropdown [options]="oCountryCodeDropdown" class="phonecodestyle newcode"
                                            (onChange)="countryids($event)" filterPlaceholder='Search Results'
                                            optionLabel="label" formControlName="conMobileDrp">
                                            <ng-template let-items pTemplate="selectedItem">
                                                <div class="flex-row">
                                                    <img class="lang-change-img" src={{items.flag}} alt="" />
                                                    <div class="lang-change-option-text selected-lang center">
                                                        {{items.code}}
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template let-country pTemplate="item">
                                                <div class="flex-row" id="dropdown1">
                                                    <img class="lang-change-img" src={{country.flag}} alt="" />
                                                    <div class="lang-change-option-text center">{{country.label}}
                                                        {{country.code}}</div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </span>
                                </div>
                                <div class="p-col-8 p-md-8 spinner">
                                    <span class="p-input-icon-right"><em *ngIf="phSpinnerHide1"
                                            class="pi pi-spin pi-spinner spinnerstyles"></em>
                                        <em *ngIf="phCheckHide1" class="pi pi-check-circle tickmarkstyle"></em>
                                        <p-inputMask autocomplete="off" mask="(999) 999-9999"
                                            formControlName="mobileNumber" (onBlur)="getmobi()"
                                            placeholder="Mobile Number">
                                        </p-inputMask>
                                    </span>
                                </div>
                            </div>
                            <p class="mandatoryColor">{{this.mobinummsg}}</p>
                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4"  pTooltip="{{this.patchlocationString}}"  tooltipPosition="top">
                            <label class="inputfields">Work Location</label>
                            <p-dropdown [options]="workLocations" [disabled]="true" formControlName="workLocation"
                                [showClear]="true" placeholder="Select Work Location" optionLabel="name"></p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <h5 id="h3tag">Contact Address</h5>
            <div class="p-fluid p-formgrid p-grid ippos">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Address Line 1</label>
                    <input autocomplete="off" id="input" type="text" pInputText placeholder="Address Line 1"
                        maxlength="51" formControlName="addressLine1">
                    <span class="validationtest" *ngIf="this.profileForm.get('addressLine1').hasError('maxlength')">
                        Please enter 50 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Address Line 2</label>
                    <input autocomplete="off" id="input" type="text" pInputText placeholder="Address Line 2"
                        maxlength="51" formControlName="addressLine2">
                    <span class="validationtest" *ngIf="this.profileForm.get('addressLine2').hasError('maxlength')">
                        Please enter 50 alphabets only
                    </span>
                </div>
            </div><br>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                    <label class="inputfields">Mailbox Number</label>
                    <input autocomplete="off" id="input" maxlength="11" type="text" pInputText
                        placeholder="Mailbox Number" formControlName="mailboxNumber">
                    <span class="validationtest" *ngIf="this.profileForm.get('mailboxNumber').hasError('maxlength')">
                        Please enter 10 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                    <label class="inputfields">Country</label>
                    <p-dropdown [options]="countryData" formControlName="country" placeholder="Select Country"
                        [showClear]="true" optionLabel="label" (onChange)="getStateByUrl($event.value.code)">
                    </p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                    <label class="inputfields">State</label>
                    <p-dropdown [options]="StateData" formControlName="state" placeholder="Select State"
                        [showClear]="true" optionLabel="label"></p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">City</label>
                    <input autocomplete="off" id="input" type="text" pInputText placeholder="City" maxlength="21"
                        formControlName="city">
                    <span class="validationtest" *ngIf="this.profileForm.get('city').hasError('maxlength')">
                        Please enter 20 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Zip</label>
                    <input autocomplete="off" id="input" type="text" pInputText placeholder="Zip" pKeyFilter="num"
                        maxlength="11" formControlName="zip">
                    <span class="validationtest" *ngIf="this.profileForm.get('zip').hasError('maxlength')">
                        Please enter 10 alphabets only
                    </span>
                </div>
            </div><br>
            <div class="btnsCSS1">
                    <button pButton [disabled]="disable" type="button" icon='pi pi-save' label="Update" class="addhBtn"
                    (click)="profileUpdate()"></button>
            </div><br><br>
        </p-panel>
    </p-card>
</form>
<p-toast position="bottom-right" key="br"></p-toast>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>