<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
    <p-panel>
        <p-header>
            <strong>
                <label> Sales Info : <span style="color: #0D416B;">{{custName}}</span></label><span *ngIf="contactnamedsb"><label class="accordiontab">Account : </label><label class="labelname">{{this.salesuser }}</label></span>
            </strong>
        </p-header>
  
         <form [formGroup]="salesinfoForm">
                <div class="p-fluid p-grid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                        <label class="inputfields">Name</label>
                        <input id="input" autocomplete="off" pKeyFilter="num" size="30" formControlName="name"
                            (keyup.enter)="gridData()" pInputText placeholder="Name" maxlength="11">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                        <label class="inputfields">Email</label>
                        <input id="input" type="text" pInputText placeholder="Email" (keyup.enter)="gridData()"
                            formControlName="mail" maxlength="60" autocomplete="off">
                    </div>
                </div>
                <div class="btnsCSS1 btnalign">
                    <button pButton type="button" icon='pi pi-plus' label="Add" (click)="addSaleInfo()" 
                        class="addhBtn"></button>
                    <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()"
                        class="addhBtn"></button>
                    <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn"
                        (click)="gridData()"></button>
                </div>
            </form>
        </p-panel>
</p-card>
<br>
<p-card class="pgpos">
    <div *ngIf="table">
        <p-table [value]="gridres" [columns]="cols" selectionMode="single" dataKey="edit" [responsive]="true"
            sortMode="multiple">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                        <span>
                            {{col.header}}
                        </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
                <tr>
                    <td class="textCenter"><span class="p-column-title">
                            Name</span>
                        {{data.name}}
                    </td>
                    <td class="textCenter"><span class="p-column-title">Email</span><a class="pointer"
                        (click)="emailToast()"  (click)="copyInputMessage(data.email)">{{data.email}}</a></td>

                    <td class="textCenter"><span class="p-column-title">
                            Phone Number</span>{{data.officePhone}}</td>
                  
                            <td>
                                <p-badge [value]="statuTable[data.status]"
                                    [ngClass]="data.status==='A'?'green': data.status === 'I'?'red': 'blue'">
                                </p-badge>
                            </td>
                    <td class="textCenter"><span class="p-column-title">Action</span><a><em
                                (click)="actionpopup.toggle($event); toggle($event,data)" class="pi pi-ellipsis-v pointer"></em>
                        </a></td>
                </tr>
            </ng-template>
          
        </p-table>
        <div class="p-grid ui-fluid">
			<div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
				<h3 class="norecorddata">Total records : {{this.totalrecods}}</h3>
			</div>
			<div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
				<p-paginator [rows]="10" [totalRecords]="totalrecods" [rowsPerPageOptions]="[10,20,30]"
					></p-paginator>
			</div>
		</div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any sales info</em><br>
            </div>
        </div>
    </p-card>
</p-card>


<form [formGroup]="saleAddForm">
    <p-dialog header="Add Sale Info" [(visible)]="displayModal" [modal]="true" [draggable]="false" [resizable]="false"
        [style]="{width: '20%'}" [contentStyle]="{'overflow': 'visible'}">

        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Name<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="saleName" placeholder="Select Name" optionLabel="name" filter="true"
                    autocomplete="off" formControlName="addnNameForm" [showClear]="true">
                </p-dropdown>
                <div>
                    <span class="validationtest"
                        *ngIf="this.saleAddForm.get('addnNameForm').touched && this.saleAddForm.get('addnNameForm').invalid && this.saleAddForm.get('addnNameForm').errors.required">
                        Please select name
                    </span>
                </div>
            </div>
        </div>
        <br>
        <div class="alignitem">
            <button pButton type="button" icon='pi pi-save' label="Assign"  class="addhBtn "
                (click)="addSaledata()" [disabled]="subbtn"></button>
        </div>
    </p-dialog>
</form>
<form [formGroup]="saleEditForm">
    <p-dialog header="Edit Sale Info" [(visible)]="displayModal1" [modal]="true" [draggable]="false"
        [resizable]="false">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Name</label>
                <input id="input" autocomplete="off" pKeyFilter="num" size="30" formControlName="editNameForm"
                    pInputText placeholder="Name" maxlength="11" disabled="true">
            </div>
        </div>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Email</label>
                <input id="input" autocomplete="off" pKeyFilter="num" size="30" formControlName="editEmailForm"
                    pInputText placeholder="Email" maxlength="11" disabled="true">
            </div>
        </div>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Phone Number</label>
                <p-inputMask autocomplete="off" id="input" mask="(999) 999-9999" size="30"
                    formControlName="editPhoneForm" placeholder="Phone" disabled="true"></p-inputMask>
            </div>
        </div>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Status</label>
                <p-dropdown [options]="statusData" placeholder="Select Status" optionLabel="label" autocomplete="off"
                    formControlName="editStatusForm" [showClear]="true">
                </p-dropdown>
            </div>
        </div>
        <br>
        <div class="btnsCSS1">
            <button pButton type="button" icon='pi pi-save' label="Update" class="addhBtn" 
                (click)="updatesales()" [disabled]="subbtn"></button>
        </div>
    </p-dialog>
</form>
<div *ngIf="!loading; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight">
                <div class="laodersize" >
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif" class="loaderimg-height" >
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
<!-- <p-overlayPanel #op1 class="over" [style]="{background: 'black',color: 'white',height: '33px'}">
    <h6>Copied</h6>
</p-overlayPanel> -->