import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import { isEmpty } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
@Component({
  selector: 'app-purcha-requirementedit',
  templateUrl: './purcha-requirementedit.component.html',
  styleUrls: ['./purcha-requirementedit.component.scss'],
  providers: [MessageService, DatePipe],
})
export class PurchaRequirementeditComponent implements OnInit {
  editRequirementsForm: FormGroup;
  UserId: string;
  name1: any;
  reqId: any;
  titel: any;
  formRoute: any;
  gridStatus: any;
  reqData: any;
  reqPosi: any;
  reqName: any;
  jobCat: any;
  maxrate: any;
  headerName: string;
  rows: any = 10;
  page: any = 1; requirement;
  pageCount: any;
  minimumDate = new Date();
  submitApproval: boolean;
  dateErr: boolean;
  submittedProfilesGridData: any;
  subProRecords: any;
  table: boolean;
  records: boolean;
  loader: boolean;
  array: any;
  specializationSkillsData: any = [];
  experienceData: any;
  expLabel: any = [];
  statusList: any = [];
  consStatusData: any;
  first: any;
  positionData: any;
  requiremntId: any;
  minMaxRate: any;
  departmentList: any;
  postCat: any;
  jobTitleId: any;
  locationList1: any = [];
  acctdetails: any;
  locationList: any = [];
  getexperienceOptions: any;
  selectedPrimarySkills: any[];
  statusDD: any;
  billingData: any;
  hireTypeData: any;
  PreferredSkills: any = [];
  skillset = {};
  rowData: any;
  idToDelete: any;
  conId: any;
  consultantName: any;
  items: any = [];
  downloadOptions: any = [];
  jobTitleData: any;
  userAccId: any;
  exprienceList: any;
  billingList: any;
  hireTypeList: any;
  display: boolean;
  reqSkillsValue: any;
  filedata: any;
  resumeRes: any;
  updateaction: boolean;
  updateactionBtn: boolean;
  userid: any;
  resumID: any;
  updateactionGrid: any;
  profileID: any;
  reqId1: any;
  consultantRequirementDisplay: boolean;
  billdata: any;
  updateBilForm: any;
  candidateName: any;
  payRate: any;
  markUpData: any;
  errorMsg: boolean;
  payrates: any;
  alertError: boolean;
  approvalRequest: boolean;
  candidatename: any;
  userId: any;
  targetDate: string;
  maximumDate: Date;
  isUploading: boolean;
  loaderForDownload: boolean;
  rowdataid: any;
  iconToggle = {};
  selectedResume: any = [];
  display1: boolean;
  availCounsultRes: any;
  popup: boolean;
  reqCount: any;
  reqStatus: any;
  rejectSubmission: boolean;
  enddata: boolean;
  maxRate: any;
  currencycode: any[];
  preferencList: any = [];
  currencyValue: any;
  currencysymbl: any;
  preferredSkills: any = [];
  primarySkills: any = [];
  requirementPreferredSkillSet: any = [];
  skills1: any[];
  reqData1: any;
  skillsList = [];
  skillset1: any = {};
  skillsList12: any = [];
  selectedPrimaryskills: any = [];
  patchskillsdata: any;
  otherskills: boolean;
  patchlocationString: string;
  editLocationdata: any;
  receivedCandidates: FormGroup;
  cols = [
    { field: 'vendorName', header: 'Vendor Name', sno: 1 },
    { field: 'consultantName', header: 'Candidate Name', sno: 2 },
    { field: 'submittedDate', header: 'Received Date', sno: 3 },
    { field: 'skills', header: ' Skills', sno: 4 },
    { field: 'skills', header: ' Skills', sno: 5 },
    { field: 'profileState', header: 'Status', sno: 6 },
    { field: 'payRate', header: 'Bill Rate', sno: 7 },
    { field: '', header: 'Action', sno: 8 }
  ];
  cols3 = [
    { dataKey: 'vendorName', header: 'Vendor Name' },
    { dataKey: 'consultantName', header: 'Candidate Name' },
    { dataKey: 'submittedDate', header: 'Received Date' },
    { dataKey: 'skills', header: 'Skills' },
    { dataKey: 'status', header: 'Status' },
    { dataKey: 'billRate', header: 'Bill Rate' },
  ];
  gridData: any;
  statusList11: any = [];
  userDetails: any;
  usrroleid: any;
  roleId: any;
  recCandidates: boolean;
  reqid: any;
  isUploading1: boolean;
  rowDatagetres: any;
  constructor(
    private datepipe: DatePipe,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apisService: ApisService,
    private breadcrumbService: BreadcrumbService,
    private messageService: MessageService,
  ) {
    this.breadcrumbService.setItems([
      { label: 'Requirements', routerLink: '/purchas-requirement' },
      { label: 'Requirement Edit', routerLink: '/requirementedit' },
    ]);
    this.getdSkillset();
  }
  ngOnInit(): void {
    this.acctdetails = JSON.parse(this.apisService.decodeData());
    this.preferencList = this.acctdetails.preferencesList;
    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }
    this.userDetails = JSON.parse(this.apisService.decodeData());
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
      this.roleId = this.userDetails.roles[0].id;
    } else {
      this.roleId = this.usrroleid;
    }
    this.userAccId = this.acctdetails.accId;
    this.UserId = sessionStorage.getItem('UserId');
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.name1 = routeParams.params.name;
    this.reqId = routeParams.params.reqId;
    this.maxRate = routeParams.params.maxRate;
    this.gridStatus = routeParams.params.gStatus;
    this.editRequirementsForm = this.fb.group({
      reqName: ['', Validators.required],
      requirementTitle: [''],
      requirementType: ['', Validators.required],
      requirementStartDate: ['', Validators.required],
      requirementEndDate: ['', Validators.required],
      requirementNoofPositions: ['', Validators.required],
      requirementReqExp: ['', Validators.required],
      requirementWorkCenterLocation: ['', Validators.required],
      requirementStatus: ['', Validators.required],
      requirementMinRate: [''],
      requirementMaxRate: [''],
      requirementCategory: [''],
      requirementDescription: ['', Validators.required],
      requirementResponsibilities: ['', Validators.required],
      requirementQualification: ['', Validators.required],
      requirementsSkillSet: ['', Validators.required],
      preferredSkills: ['', [Validators.required]],
      requirementComments: [''],
      positionCategory: [''],
      billingtype: ['', Validators.required],
      hrsperWeek: [''],
      duration: [''],
      department: [''],
      experience: [''],
      approval: [''],
      otherskills: ['']
    });
    this.receivedCandidates = this.fb.group({
      fristName: [''],
      lastName: [''],
      email: [''],
      skillSet: [''],
      status: [''],
      onboardDate: [''],
      onboardDate1: ['', [Validators.required]],
      comments: [''],
      reject: [''],
      rejectReason: ['']
    });
    this.editRequirementsForm.disable();
    this.loader = true;
    // this.submitProfileGrid();
    this.reqSkillSet();
    this.getExperienceDropDownData();
    this.getConStatus();
    this.getStatus();
    this.getWorkLocation();
    this.getexperience();
    this.getBillingDropdown();
    this.getHireTypeData();
    this.getDepartments();
    this.getPositionCatagory();
    this.editReqData();
    if (this.gridStatus === 'R') {
      this.recCandidates = true;
    }
    else {
      this.recCandidates = false;
    }
    this.gridTable();
  }
  // it is used to back navigate to purchasing requirement screen
  backNavigate() {
    this.router.navigate(['/purchas-requirement']);
  }
  // It is used for displaying experience dropdown values
  getExperienceDropDownData() {
    this.apisService.getApi(environment.lookupsData + 'RELYE').subscribe((res: any) => {
      this.experienceData = res.data;
      this.experienceData.forEach((element) => {
        this.expLabel[element.code] = [element.label];
      });
    });
  }
  // It is used for displaying status dropdown values
  getConStatus() {
    this.apisService.getApi(environment.lookupsData + 'RPLPS').subscribe((res) => {
      this.consStatusData = res.data;
      this.consStatusData.forEach((element) => {
        this.statusList[element.code] = element.label;
      });
      this.consStatusData.forEach((element) => {
        this.statusList11[element.code] = element.label;
      });
    });
  }
  reset() {
    this.receivedCandidates.reset();
    this.gridTable();
  }
  // It is used for displaying status dropdown values
  getStatus() {
    this.apisService.getApi(environment.lookupsData + 'RELS').subscribe((res) => {
      this.statusList = res.data;
    });
  }
  // It is used for displaying location dropdown values
  getWorkLocation() {
    this.apisService.getApi(environment.location + '/' + this.userAccId).subscribe((res) => {
      this.locationList = res.data;
    });
  }
  //// It is used for displaying experience dropdown values
  getexperience() {
    this.apisService.getApi(environment.lookupsData + 'RELYE').subscribe((res: any) => {
      this.exprienceList = res.data;
    });
  }
  // It is used for displaying billing type dropdown values
  getBillingDropdown() {
    this.apisService.getApi(environment.lookupsData + 'RELBT').subscribe((res) => {
      this.billingList = res.data;
    });
  }
  // It is used for displaying hiretype dropdown values
  getHireTypeData() {
    this.apisService.getApi(environment.lookupsData + 'RELHT').subscribe((res) => {
      this.hireTypeList = res.data;
    });
  }
  // It is used to show table data
  submitProfileGrid() {
    const row = this.rows;
    const page = this.page;
    this.apisService
      .getApi(environment.avaliableSubmitedPro + page + '/' + row + '/' + this.reqId + '?consEmail=&fName=&lName=&exp=')
      .subscribe((res: any) => {
        this.submittedProfilesGridData = res.data.records;
        this.subProRecords = res.data.count;
        if (this.subProRecords !== 0) {
          this.table = true;
          this.records = false;
        } else {
          this.table = false;
          this.records = true;
        }
      });
  }
  // It is used for displaying job category dropdown values
  getRolesBasedonCategory(event) {
    this.apisService.getApi(environment.jobTitle + this.userAccId + '/' + event)
      .subscribe((res) => {
        this.jobTitleData = res.data;
      });
  }
  conStatusTable(data) {
    const conStatus = this.consStatusData.find((x) => x.code === data).label;
    return conStatus;
  }
  // it is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.submitProfileGrid();
  }
  getdSkillset() {
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.skillsList = res.data;
      this.patchskillsdata = res.data;
      this.skillsList.forEach((element) => {
        this.skillset1[element.code] = element.label;
      });
    });
  }


  reqSkillSet() {
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.specializationSkillsData = res.data;
    });
  }


  getSkillSetTable(data) {
    this.array = data;
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.specializationSkillsData.length; i++) {
      // tslint:disable-next-line: prefer-for-of
      // tslint:disable-next-line:prefer-for-of
      if (data === null || data === undefined || data === '' || data === isEmpty()) { } else {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.array.length; j++) {
          if (Number(this.specializationSkillsData[i].code) === this.array[j]) {
            selectedPrePrimarySkills3.push(
              this.specializationSkillsData[i].label
            );
          } else {
          }
        }
      }
    }
    return selectedPrePrimarySkills3;
  }
  // It is used for displaying department dropdown values
  getDepartments() {
    this.apisService.getApi(environment.departments + '/' + this.userAccId).subscribe((res) => {
      this.departmentList = res.data;
    });
  }
  // It is used for displaying position category dropdown values
  getPositionCatagory() {
    this.apisService.getApi(environment.positionCat + '/' + this.userAccId).subscribe((res) => {
      this.positionData = res.data;
    });
  }
  // It is used for bind requirementr table data
  editReqData() {
    this.apisService.getApi(environment.requirementEdit + '/' + this.reqId).subscribe((res) => {
      this.reqData = res.data;
      this.getRolesBasedonCategory(this.reqData.positionCategory);
      this.getPositionCategoryBindDataValues(this.reqData);
      this.typeData(this.reqData.hireType);
      this.editRequirementsForm.patchValue({
        reqName: this.reqData.name,
        positionCategory: this.reqData.positionCategory,
        requirementTitle: this.reqData.jobTitle.id,
        department: this.reqData.departmentId,
        requirementMinRate: this.reqData.minRate,
        requirementMaxRate: this.reqData.maxRate,
        requirementNoofPositions: this.reqData.noOfPositions,
        requirementWorkCenterLocation: this.reqData.location,
        requirementReqExp: this.reqData.experience,
        requirementType: this.reqData.hireType,
        requirementStartDate: this.reqData.plannedStartDate,
        requirementEndDate: this.reqData.plannedEndDate,
        billingtype: this.reqData.billingType,
        experience: this.reqData.experience,
        duration: this.reqData.duration,
        hrsperWeek: this.reqData.hrsPerWeek,
        requirementStatus: this.reqData.status,
        requirementDescription: this.reqData.description,
        requirementResponsibilities: this.reqData.responsibilities,
        requirementQualification: this.reqData.qualification,
        requirementComments: this.reqData.comments,
        approval: this.reqData.empApprovalReqFlag,
        // otherskills: this.reqData.otherSkills
      });

      const selectedPrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.patchskillsdata?.length; i++) {
        const array = this.reqData.prefferedSkills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.patchskillsdata[i].code) === array[j]) {
            selectedPrimarySkills.push(this.patchskillsdata[i]);
          }
        }
      }
      const preferredSkills = selectedPrimarySkills;
      preferredSkills.forEach((element) => {
        this.skillsList12.push(element.label);
      });
      const selectedPrePrimarySkills = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.patchskillsdata?.length; i++) {
        const array = this.reqData.skills;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < array.length; j++) {
          if (Number(this.patchskillsdata[i].code) === array[j]) {
            selectedPrePrimarySkills.push(this.patchskillsdata[i]);
          }
        }
      }
      const primarySkills = selectedPrePrimarySkills;
      primarySkills.forEach((element) => {
        this.primarySkills.push(element.label);
      });

      this.editRequirementsForm.controls.requirementsSkillSet.patchValue(selectedPrePrimarySkills);
      this.editRequirementsForm.controls.preferredSkills.patchValue(preferredSkills);
      selectedPrePrimarySkills.forEach((element) => {
        this.selectedPrimaryskills.push(element.label);
      });
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < selectedPrePrimarySkills.length; i++) {
        if (selectedPrePrimarySkills[i].code === '25') {
          this.otherskills = true;
          this.editRequirementsForm.controls.otherskills.patchValue(this.reqData.otherSkills);

        }
      }


      // tslint:disable-next-line:max-line-length
      this.editRequirementsForm.controls.requirementWorkCenterLocation.patchValue(this.locationList.filter((x) => x.locId === this.reqData.location)[0]);
      this.editLocationdata = this.locationList.filter((x) => x.locId === this.reqData.location)[0];
      this.patchlocationString = this.editLocationdata?.name + '\n' + this.editLocationdata?.address1
        + ',' + this.editLocationdata?.city + ',' + this.editLocationdata?.state + ','
        + this.editLocationdata?.country;
      setTimeout(() => {
        this.loader = false;
      }, 1500);
      this.skills();
    });
  }
  // It is used to show skills
  skills() {
    this.skills1 = [];
    this.reqData.skills.forEach(element1 => {
      this.skillsList.forEach(element => {
        if (element.code === element1.toString()) {
          this.skills1.push(element.label);
        }
      });

    });
  }
  // It is used bind position category dropdown values
  getPositionCategoryBindDataValues(data) {
    const acctId = data.accId;
    const posnCat = data.category;
    this.requiremntId = data.reqId;
    this.apisService.getApi(environment.maxRate + acctId + '/' + posnCat).subscribe((res) => {
      this.minMaxRate = res.data;
      this.editRequirementsForm.controls.requirementTitle.patchValue(
        this.minMaxRate.filter(x => x.title === data.jobTitle.title)[0]
      );
    });
  }
  // It is used to show hiretype enbale or disable
  typeData(event) {
    if (event === 'C' || event === 'H') {
      this.enddata = true;
      this.editRequirementsForm.get('requirementEndDate').markAsTouched();
      this.editRequirementsForm.get('requirementEndDate').enable();
    } else {
      this.editRequirementsForm.get('requirementEndDate').disable();
      this.enddata = false;
    }
  }
  getMinrate(event) {
    const availbleRate = this.jobTitleData.find(x => x.id === event);
    this.editRequirementsForm.controls.requirementMinRate.patchValue(availbleRate.minrate);
    this.editRequirementsForm.controls.requirementMaxRate.patchValue(availbleRate.maxrate);
  }
  gridTable() {
    this.loader = true;
    // tslint:disable-next-line:max-line-length
    const searchstatus = this.receivedCandidates.controls.status.value === null || this.receivedCandidates.controls.status.value === '' ? '' : this.receivedCandidates.controls.status.value === undefined ? '' : this.receivedCandidates.controls.status.value.code;
    // tslint:disable-next-line:max-line-length
    const searchEmail = this.receivedCandidates.controls.email.value === null || this.receivedCandidates.controls.email.value === '' ? '' : this.receivedCandidates.controls.email.value === undefined ? '' : this.receivedCandidates.controls.email.value;
    const firstName = this.receivedCandidates.controls.fristName.value === null || this.receivedCandidates.controls.fristName.value === '' ? '' : this.receivedCandidates.controls.fristName.value === undefined ? '' : this.receivedCandidates.controls.fristName.value;
    // tslint:disable-next-line:max-line-length
    const lastname = this.receivedCandidates.controls.lastName.value === null || this.receivedCandidates.controls.lastName.value === '' ? '' : this.receivedCandidates.controls.lastName.value === undefined ? '' : this.receivedCandidates.controls.lastName.value;
    const search1 = this.page + '/' + this.rows + '/' + this.roleId + '?reqId=' + this.reqId;
    const email = '&consEmail=' + searchEmail;
    const status = '&status=' + searchstatus;
    const fname = '&fName=' + firstName;
    const lname = '&lName=' + lastname;
    this.apisService.getApi(environment.getReceivedTable + search1 + email + fname + lname + status).subscribe((res: any) => {
      this.submittedProfilesGridData = res.data.records;
      this.loader = false;
      this.subProRecords = res.data.count;
      if (this.subProRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  downLoadsToggle() {
    this.downloadOptions = [
      { label: 'Download XLSX', icon: 'pi pi-download', command: (event, i) => { this.exportExcel(); } },
      { label: 'Download PDF', icon: 'pi pi-download', command: (event, i) => { this.exportPdf(); } },
    ];
  }
  exportExcel() {
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    ws['!cols'] = [ {width : 10}, {width : 20}  , {width : 20} , {width : 10}, {width : 10}, {width : 10}, {width : 10}, {hidden : true} ];
    ws['!rows'] = [{hpt : 30}, {hpt : 30}, ];
    ws['!cols'][3] = { hidden: true };
    ws['!cols'][7] = { hidden: true };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ExcelSheet.xlsx');
  }
  exportPdf() {
    this.submittedProfilesGridData.forEach((element) => {
      element.status = this.statusList11[element.profileState];
      element.skills = this.getSkillSetTable(element.skills);
    });
    const doc = new jsPDF('p', 'pt');
    autoTable(doc, {
      headStyles: { fillColor: '#2bb9d5', cellPadding: 5   },
      margin: { left: 17},
      columns: this.cols3,
      body: this.submittedProfilesGridData,
      didDrawPage: (dataArg) => {
        doc.text('Requirement Edit', dataArg.settings.margin.left, 30);
      }
    });
    doc.save('Requirement Edit.pdf');
  }
  reqSkills1(data) {
    this.display = true;
    this.reqSkillsValue = data;
  }
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
  saveLocation(ev) {
    const data = ev.value;
    this.patchlocationString = data.name + '\n' + data.address1 + ',' + data.city + ',' + data.state + ',' + data.country;
  }
  toggle(menu, event, rowData) {
    this.rowDatagetres = rowData;
    this.items = [
      { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); } },
    ];
  }
  downloadFileapi() {
    this.filedata = this.rowDatagetres;
    this.reqid = this.filedata.resumeId;
    this.isUploading1 = true;
    this.apisService.getApi(environment.downloadedattachment + '/' + this.reqid).subscribe((res) => {
      this.resumeRes = res;
      this.isUploading1 = false;
      window.location.assign(this.resumeRes.downloadUrl);
    });
  }
}
