import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
  providers: [MessageService, DatePipe]
})
export class TaskComponent implements OnInit {
  titleForm: FormGroup;
  cols = [
    { field: 'title', header: 'Title', width: '10%' },
    { field: 'assignedByName', header: 'Assigned By', width: '10%' },
    { field: 'assignedToName', header: 'Assigned To', width: '10%' },
    { field: 'assignedDate', header: 'Assigned Date', width: '10%' },
    { field: 'targetDate', header: 'Target Date', width: '10%' },
    { field: 'status', header: 'Status', width: '10%' },
    { field: '', header: 'Action', width: '3%' },
  ];
  editstatus = [
    { code: 'A', id: 'TTS', label: 'Assigned' },
    { code: 'I', id: 'TTS', label: 'Inprogress' },
    { code: 'P', id: 'TTS', label: 'Completed' },
    { code: 'O', id: 'TTS', label: 'Postponed' },
    { code: 'C', id: 'TTS', label: 'Created' }
  ];
  taskCategory = [
    { code: 'B', label: 'Business' },
    { code: 'P', label: 'Personal' }
  ];
  groupOrIndividual = [
    { code: 'M', label: 'Member' },
    { code: 'G', label: 'Group' }
  ];
  titleAddForm: FormGroup;
  titleUpdateForm: FormGroup;
  displayModal: boolean;
  loader: boolean;
  remainderData = [];
  gridData: any;
  table: boolean;
  totalRecords: any;
  records: boolean;
  accId: any;
  taskState: any;
  employeeList: any = [];
  empList = {};
  displayModal1: boolean;
  departSearch: any;
  name: any;
  page: any = 1;
  rows: any = 10;
  first: any;
  pageCount: any;
  items: { label: string; icon: string; command: () => void; }[];
  loginusid: any;
  taskid: any;
  fileName: any;
  filedata: FormData;
  flagvalue: string;
  attname: any;
  fileType: any;
  bnt: any;
  btndsb: boolean;
  UserID: any;
  userid: string;
  taskstatus: any = [];
  statustable = {};
  assignid: any;
  assignto: any = [];
  checkboxdsb: boolean;
  isUploading1: boolean;
  dwnid: any;
  // updtbtndsb: boolean;
  updatebtn: boolean;
  edittaskstatus: any = [];
  tasksource: any = [];
  tasktype: any = [];
  targetTime = [{ label: '8 AM', code: '8:00 am' }, { label: '9 AM', code: '9:00 am' }];
  recuringData = [{ label: 'Yes', code: 'Y' }, { label: 'No', code: 'N' }];
  groups = [{ label: 'All', code: 'A' }, { label: 'Group', code: 'G' }, { label: 'Members', code: 'M' }];
  groupsName = [];
  // tslint:disable-next-line:no-inferrable-types
  groupOrIndiv: boolean = true;
  taskcategoryvalue: any;
  taskData: any;
  assignId: number;
  GrpId: number;
  GrpIDD: any;
  assignIds: any;
  groupID: any;
  ClosedDate: boolean;
  headername: string;
  durationData: any = [];
  taskValue: any;
  updateBtn: boolean;
  recuringDuration: any = [];
  recuringDD: boolean;
  filesdatass: boolean;
  classinput: boolean;
  taskfiles: boolean;
  status: any;
  addstatus: boolean;
  minDate: Date;
  startCurDate: Date;
  // Window keydown listener
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.gridTable();
    }
  }
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private datePipe: DatePipe, private breadcrumbService: BreadcrumbService, private messageService: MessageService) {
    this.breadcrumbService.setItems([
      { label: 'Tasks', routerLink: '/task' },
    ]);
  }
  ngOnInit() {
    this.minDate = new Date();
    this.minDate.setMonth(new Date().getMonth());
    this.minDate.setFullYear(new Date().getFullYear());
    this.startCurDate = this.minDate;
    this.classinput = true;
    const userdetails = JSON.parse(this.apisService.decodeData());
    this.accId = userdetails.accId;
    this.loginusid = userdetails.userId;
    this.titleForm = this.fb.group(
      {
        title: [''],
        assignedTo: [''],
        assignedDate: [''],
        groups: [''],
        groupsname: [''],
        searchDuration: ['']
      });
    this.titleAddForm = this.fb.group(
      {
        addTitle: ['', [Validators.required, Validators.maxLength(30)]],
        assignedBy: ['', [Validators.required]],
        tasksource: ['', [Validators.required]],
        taskCategory: ['', [Validators.required]],
        groupOrIndividual: [''],
        taskType: ['', [Validators.required]],
        assignedDate: [''],
        targetDate: [''],
        description: ['', [Validators.maxLength(200)]],
        addResumeForm: [''],
        assignStatus: ['', [Validators.required]],
        bnotification: [''],
        remainder: [''],
        duration: ['', [Validators.required]],
        addTargetTime: [''],
        recuringTask: [''],
        recuringduration: ['']
      });
    this.titleUpdateForm = this.fb.group(
      {
        editTitle: ['', [Validators.required, Validators.maxLength(30)]],
        editassignedBy: ['', [Validators.required]],
        edittasksource: ['', [Validators.required]],
        edittaskCategory: ['', [Validators.required]],
        editgroupOrIndividual: [''],
        edittaskType: ['', [Validators.required]],
        editassignedDate: [''],
        edittargetDate: [''],
        editdescription: ['', [Validators.maxLength(200)]],
        editResumeForm: [''],
        editassignStatus: ['', [Validators.required]],
        editremainder: [''],
        editduration: ['', [Validators.required]],
        editclosedDate: [''],
        editTargetTime: [''],
        editbnotification: [''],
        editrecuringTask: [''],
        editrecuringDuration: ['']
      }
    );
    this.taskStatus();
    this.gridTable();
    this.remainderDD();
    this.groupNameDD();
    this.assignedto();
    this.durationDD();
    this.recuringDurationDD();
  }
  assignedto() {
    this.apisService.getApi(environment.assignedtodropdown + this.accId).subscribe((res) => {
      this.employeeList = res.data;
      this.employeeList.forEach((element) => {
        this.empList[element.id] = element.name;
      });
    });
  }
  groupNameDD() {
    this.apisService.getApi(environment.groupormembernames + this.loginusid + '/B').subscribe((res) => {
      this.groupsName = res.data;
    });
  }
  durationDD() {
    this.apisService.getApi(environment.lookupsData + 'TLD').subscribe((res) => {
      this.durationData = res.data;
    });
  }
  recuringDurationDD() {
    this.apisService.getApi(environment.lookupsData + 'RMT').subscribe((res) => {
      this.recuringDuration = res.data;
    });
  }
  taskStatus() {
    // tslint:disable-next-line: deprecation groupormembernames durationData recuringDuration
    this.apisService.getApi(environment.lookupsData + 'TTS').subscribe((res: any) => {
      this.taskstatus = res.data;
      this.taskstatus.forEach(element => {
        this.statustable[element.code] = element.label;
      });
    });
    if (this.assignid === this.loginusid) {
      this.assignto = this.taskstatus;
    } else {
      this.taskstatus.forEach(element => {
        if (element.code !== 'I' && element.code !== 'P' && element.code !== 'O') {
          this.assignto.push(element);
        }
      });
      this.titleAddForm.controls.assignStatus.patchValue(
        this.taskstatus.filter((x) => x.code === 'C')[0]
      );
    }
  }
  taskcategory(event) {
    this.taskcategoryvalue = event.value.code;
    this.groupOrIndiv = event.value.code === 'B' ? true : false;
    if (this.taskcategoryvalue === 'P') {
      this.apisService.getApi(environment.assignedtodropdown + this.accId).subscribe((res) => {
        this.employeeList = res.data;
        this.employeeList.forEach((element) => {
          this.empList[element.id] = element.name;
        });
      });
    }
  }
  assigntod(event) {
    if (event.value.code === 'G') {
      this.apisService.getApi(environment.groupormembernames + this.loginusid + '/A').subscribe((res) => {
        this.employeeList = res.data;
        this.employeeList.forEach((element) => {
          this.empList[element.id] = element.name;
        });
      });
      this.assignId = 0;
      // tslint:disable-next-line: max-line-length
      this.GrpIDD = this.titleAddForm.controls.assignedBy.value === null || this.titleAddForm.controls.assignedBy.value === '' || this.titleAddForm.controls.assignedBy.value === undefined || this.titleAddForm.controls.assignedBy.value.id;
    }
    else {
      this.apisService.getApi(environment.assignedtodropdown + this.accId).subscribe((res) => {
        this.employeeList = res.data;
        this.employeeList.forEach((element) => {
          this.empList[element.id] = element.name;
        });
      });
      this.GrpIDD = 0;
      // tslint:disable-next-line: max-line-length
      this.assignIds = this.titleAddForm.controls.assignedBy.value === null || this.titleAddForm.controls.assignedBy.value === '' || this.titleAddForm.controls.assignedBy.value === undefined || this.titleAddForm.controls.assignedBy.value.id;
    }
  }
  toggle(event, data) {
    this.status = data.status;
    this.dwnid = data.id;
    if (data.assignedtoid === this.loginusid && data.status === 'C' && data.attId > 0) {
      this.items = [
        { label: 'Assign', icon: 'pi pi-pencil', command: () => { this.assignTask(data); } },
        { label: 'Copy', icon: 'pi pi-copy', command: () => { this.addTask('C'); } },
        { label: 'Notify', icon: 'pi pi-bell', command: () => { this.notify(data); } },
        { label: 'Show Attachment', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(data); }, },
      ];
    }else if (data.assignedtoid === this.loginusid && data.status === 'C' && data.attId === 0) {
      this.items = [
        { label: 'Assign', icon: 'pi pi-pencil', command: () => { this.assignTask(data); } },
        { label: 'Copy', icon: 'pi pi-copy', command: () => { this.addTask('C'); } },
        { label: 'Notify', icon: 'pi pi-bell', command: () => { this.notify(data); } },
        // { label: 'Show Attachment', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(data); }, },
      ];
    }
    // tslint:disable-next-line: max-line-length
    else if (data.assignedtoid !== this.loginusid && data.status === 'A' && data.attId > 0 || data.assignedtoid !== this.loginusid && data.status === 'P' && data.attId > 0) {
      this.items = [{ label: 'Copy', icon: 'pi pi-copy', command: () => { this.addTask('C'); } },
      { label: 'Show Attachment', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(data); }, },
      ];
    }

    else if (data.assignedtoid === this.loginusid && data.status === 'A' && data.attId > 0) {
      this.items = [
        { label: 'Copy', icon: 'pi pi-copy', command: () => { this.addTask('C'); } },
        { label: 'Notify', icon: 'pi pi-bell', command: () => { this.notify(data); } },
        { label: 'Close', icon: 'pi pi-times-circle', command: () => { this.closeTask(data); } },
        { label: 'Show Attachment', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(data); }, },
      ];
    }
    else if (data.assignedtoid === this.loginusid && data.status === 'A' && data.attId === 0) {
      this.items = [
        { label: 'Copy', icon: 'pi pi-copy', command: () => { this.addTask('C'); } },
      ];
    }
    // tslint:disable-next-line: max-line-length
    else if (((data.assignedtoid === this.loginusid && data.status === 'A') && data.attId !== 0) || ((data.assignedtoid === this.loginusid && data.status === 'A') && data.attId === undefined) || ((data.assignedtoid === this.loginusid && data.status === 'A') && data.attId === null)) {
      this.items = [
        { label: 'Copy', icon: 'pi pi-copy', command: () => { this.addTask('C'); } },
        { label: 'Notify', icon: 'pi pi-bell', command: () => { this.notify(data); } },
        { label: 'Close', icon: 'pi pi-times-circle', command: () => { this.closeTask(data); } },
      ];
    }
    else if (data.assignedtoid === this.loginusid && data.status === 'P') {
      this.items = [
        { label: 'Copy', icon: 'pi pi-copy', command: () => { this.addTask('C'); } },
        { label: 'Show Attachment', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(data); }, },
      ];
    }
    else if ((data.assignedtoid !== this.loginusid) && (data.status === 'C' && data.attId > 0)) {
      this.items = [
        { label: 'Assign', icon: 'pi pi-pencil', command: () => { this.assignTask(data); } },
        { label: 'Copy', icon: 'pi pi-copy', command: () => { this.addTask('C'); } },
        { label: 'Notify', icon: 'pi pi-bell', command: () => { this.notify(data); } },
        { label: 'Show Attachment', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(data); }, },
      ];
    }
    else if ((data.assignedtoid !== this.loginusid) && (data.status === 'C' && data.attId === 0)) {
      this.items = [
        { label: 'Assign', icon: 'pi pi-pencil', command: () => { this.assignTask(data); } },
        { label: 'Copy', icon: 'pi pi-copy', command: () => { this.addTask('C'); } },
        { label: 'Notify', icon: 'pi pi-bell', command: () => { this.notify(data); } },
        // { label: 'Show Attachment', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(data); }, },
      ];
    }
    // tslint:disable-next-line: max-line-length
    else if (((data.assignedtoid !== this.loginusid) && (data.status === 'C' && data.attId !== 0)) || (data.assignedtoid !== this.loginusid && data.status === 'C') && data.attId === undefined || data.assignedtoid !== this.loginusid && data.status === 'C' && data.attId === null) {
      this.items = [
        { label: 'Assign', icon: 'pi pi-pencil', command: () => { this.assignTask(data); } },
        { label: 'Copy', icon: 'pi pi-copy', command: () => { this.addTask('C'); } },
        { label: 'Notify', icon: 'pi pi-bell', command: () => { this.notify(data); } },
        { label: 'Show Attachment', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(data); }, },
      ];
    }
    // tslint:disable-next-line: max-line-length
    else if (data.assignedtoid !== this.loginusid && data.status === 'A' && data.attId !== 0 || data.assignedtoid !== this.loginusid && data.status === 'A' && data.attId === undefined || data.assignedtoid !== this.loginusid && data.status === 'A' && data.attId === null || data.assignedtoid !== this.loginusid) {
      this.items = [{ label: 'Copy', icon: 'pi pi-copy', command: () => { this.addTask('C'); } },
      ];
    }
    // tslint:disable-next-line: max-line-length
    else if (data.assignedtoid !== this.loginusid && data.status === 'P' && data.attId !== 0 || data.assignedtoid !== this.loginusid && data.status === 'P' && data.attId === undefined || data.assignedtoid !== this.loginusid && data.status === 'P' && data.attId === null) {
      this.items = [{ label: 'Copy', icon: 'pi pi-copy', command: () => { this.addTask('C'); } },
      ];
    }
    // tslint:disable-next-line: max-line-length
    else if (data.assignedtoid === this.loginusid && data.status === 'C' && data.attId !== 0 || data.attId === undefined || data.attId === null) {
      // tslint:disable-next-line: max-line-length
      this.items = [
        { label: 'Assign', icon: 'pi pi-pencil', command: () => { this.assignTask(data); } },
        { label: 'Copy', icon: 'pi pi-copy', command: () => { this.addTask('C'); } },
        { label: 'Notify', icon: 'pi pi-bell', command: () => { this.notify(data); } },
      ];
    }
    else {
      this.items = [];
    }
  }
  downloadFileapi(data) {
    this.isUploading1 = true;
    this.apisService.getApi(environment.taskDownload + data.attId).subscribe((res) => {
      this.isUploading1 = false;
      window.location.assign(res.downloadUrl);
    });
  }
  remainderDD() {
    this.apisService.getApi(environment.lookupsData + 'RMT').subscribe((res) => {
      this.remainderData = res.data;
    });
  }
  gridTable() {
    this.loader = true;
    const pageNo = this.page;
    const pageCount = this.rows;
    const orgId = pageNo + '/' + pageCount;
    // tslint:disable-next-line:max-line-length
    const assignedto = this.titleForm.controls.assignedTo.value === null || this.titleForm.controls.assignedTo.value === undefined || this.titleForm.controls.assignedTo.value === '' ? '' : this.titleForm.controls.assignedTo.value.id;
    // tslint:disable-next-line:max-line-length
    const tasktitle = this.titleForm.controls.title.value === null || this.titleForm.controls.title.value === undefined || this.titleForm.controls.title.value === '' ? '' : this.titleForm.controls.title.value;
    // tslint:disable-next-line:max-line-length
    const assigneddat = this.titleForm.controls.assignedDate.value === null || this.titleForm.controls.assignedDate.value === undefined || this.titleForm.controls.assignedDate.value === '' ? '' : this.datePipe.transform(
      this.titleForm.controls.assignedDate.value,
      'MM-dd-yyyy'
    );
    // tslint:disable-next-line: max-line-length
    const groupID = this.titleForm.controls.groupsname.value === null || this.titleForm.controls.groupsname.value === '' || this.titleForm.controls.groupsname.value === undefined ? '' : this.titleForm.controls.groupsname.value.id;
    // tslint:disable-next-line: max-line-length
    const Duration = this.titleForm.controls.searchDuration.value === null || this.titleForm.controls.searchDuration.value === '' || this.titleForm.controls.searchDuration.value === undefined ? '' : this.titleForm.controls.searchDuration.value.code;
    const searchdata = '?title=' + tasktitle + '&assignTo=' + assignedto + '&groupId=' + groupID + '&duration=' + Duration + '&assignedDate=' + assigneddat;
    this.apisService.getApi(environment.gettask + orgId + '/' + this.loginusid + '/' + searchdata).subscribe((res: any) => {
      this.gridData = res.data;
      this.loader = false;
      this.totalRecords = res.count;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  // It is used  for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridTable();
  }
  recurring(data) {
    if (data.value.code === 'N') {
      this.recuringDD = false;
    }
    else {
      this.recuringDD = true;
    }
  }
  addTask(data) {
    this.filesdatass = false;
    this.filedata = null || '' || undefined;
    this.titleAddForm.reset();
    this.taskValue = data;
    if (data === 'A') {
      this.headername = 'Add Task';
      this.addstatus = true;
      this.displayModal = true;
      this.groupOrIndiv = true;
      // tslint:disable-next-line: no-shadowed-variable  this.recuringDD=false
      const today = new Date();
      // tslint:disable-next-line: no-shadowed-variable
      const tomorrow = new Date(today.setDate(today.getDate() + 1));
      this.titleAddForm.reset();
      this.titleAddForm.get('assignedDate').patchValue(new Date());
      this.titleAddForm.get('targetDate').patchValue(tomorrow);
      this.titleAddForm.controls.taskCategory.patchValue({ code: 'B', label: 'Business' });
      this.titleAddForm.controls.groupOrIndividual.patchValue({ code: 'M', label: 'Member' });
      this.apisService.getApi(environment.assignedtodropdown + this.accId).subscribe((res) => {
        this.employeeList = res.data;
      });
      this.titleAddForm.controls.recuringTask.patchValue({ label: 'No', code: 'N' });
      this.recuringDD = false;
      this.titleAddForm.controls.duration.patchValue({ code: '1', id: 'TLD', label: 'Today' });
      this.titleAddForm.controls.remainder.patchValue(this.remainderData.filter((x) => x.code === 'D')[0]);
      this.titleAddForm.controls.assignStatus.patchValue(this.taskstatus.filter((x) => x.code === 'C')[0]);
      this.apisService.getApi(environment.lookupsData + 'TLTS').subscribe((res: any) => { this.tasksource = res.data; });
      this.apisService.getApi(environment.lookupsData + 'TLTT').subscribe((res: any) => {
        this.tasktype = res.data;
        this.titleAddForm.controls.taskType.patchValue(this.tasktype.filter((x) => x.code === 'W')[0]);
      });
    }
    else {
      this.headername = 'Copy Task';
      this.addstatus = false;
      this.displayModal = true;
      this.groupOrIndiv = true;
      this.titleAddForm.reset();
      this.apisService.getApi(environment.edittask + this.dwnid).subscribe((res: any) => {
        this.taskData = res.data.records[0];
        this.titleAddForm.reset();
        this.titleAddForm.controls.addTitle.patchValue(this.taskData.title);
        this.titleAddForm.controls.description.patchValue(this.taskData.description);
        this.titleAddForm.controls.taskCategory.patchValue(this.taskCategory.filter((x) => x.code === this.taskData.lkCategory)[0]);
        this.titleAddForm.controls.assignStatus.patchValue(this.taskstatus.filter((x) => x.code === this.taskData.taskState)[0]);
        // tslint:disable-next-line: max-line-length
        this.titleAddForm.controls.targetDate.patchValue(this.taskData.targetDate);
        this.titleAddForm.controls.recuringTask.patchValue(this.recuringData.filter((x) => x.code === this.taskData.recurringTask)[0]);
        // tslint:disable-next-line: max-line-length
        this.titleAddForm.controls.remainder.patchValue(this.remainderData.filter((x) => x.code === this.taskData.reminderType)[0]);
        this.titleAddForm.controls.duration.patchValue(this.durationData.filter((x) => x.code === this.taskData.duration)[0]);
        // tslint:disable-next-line: max-line-length
        this.titleAddForm.controls.groupOrIndividual.patchValue(this.groupOrIndividual.filter((x) => x.code === this.taskData.type)[0]);
        // tslint:disable-next-line:no-shadowed-variable
        this.apisService.getApi(environment.lookupsData + 'TLTS').subscribe((res: any) => {
          this.tasksource = res.data;
          this.titleAddForm.controls.tasksource.patchValue(this.tasksource.filter((x) => x.code === this.taskData.taskSource)[0]);
        });
        // tslint:disable-next-line: no-shadowed-variable
        this.apisService.getApi(environment.lookupsData + 'TLTT').subscribe((res: any) => {
          this.tasktype = res.data;
          this.titleAddForm.controls.taskType.patchValue(this.tasktype.filter((x) => x.code === this.taskData.taskType)[0]);
        });
        if (this.taskData.type === 'G') {
          // tslint:disable-next-line:no-shadowed-variable
          this.apisService.getApi(environment.groupormembernames + this.loginusid + '/A').subscribe((res) => {
            this.employeeList = res.data;
            this.titleAddForm.controls.assignedBy.patchValue(this.employeeList.filter((x) => x.id === this.taskData.groupId)[0]);
          });
        }
        else {
          // tslint:disable-next-line:no-shadowed-variable
          this.apisService.getApi(environment.assignedtodropdown + this.accId).subscribe((res) => {
            this.employeeList = res.data;
            this.titleAddForm.controls.assignedBy.patchValue(this.employeeList.filter((x) => x.id === this.taskData.assignedTo)[0]);
          });
        }
      });
    }
  }
  reset() {
    this.titleForm.reset();
    this.records = false;
    this.gridTable();
  }
  updateData(event, data) {
    this.titleUpdateForm.reset();
    if (data.status === 'P') {
      this.updateBtn = false;
    }
    else {
      this.updateBtn = true;
    }
    if (data.assignedtoid !== this.loginusid) {
      this.ClosedDate = false;
    }
    else {
      this.ClosedDate = true;
    }
    this.displayModal1 = true;
    this.taskid = data.id;
    this.apisService.getApi(environment.edittask + this.taskid).subscribe((res: any) => {
      this.taskData = res.data.records[0];
      // tslint:disable-next-line: no-shadowed-variable
      this.apisService.getApi(environment.lookupsData + 'TLTS').subscribe((res: any) => {
        this.tasksource = res.data;
        this.titleUpdateForm.controls.edittasksource.patchValue(this.tasksource.filter((x) => x.code === this.taskData.taskSource)[0]);
      });
      // tslint:disable-next-line: no-shadowed-variable
      this.apisService.getApi(environment.lookupsData + 'TLTT').subscribe((res: any) => {
        this.tasktype = res.data;
        this.titleUpdateForm.controls.edittaskType.patchValue(this.tasktype.filter((x) => x.code === this.taskData.taskType)[0]);
      });
      this.taskStatus();
      if (this.taskData.taskState === 'A') {
        this.edittaskstatus = this.editstatus;
        this.titleUpdateForm.controls.editTitle.disable();
        this.titleUpdateForm.controls.editassignedDate.disable();
        this.titleUpdateForm.controls.edittaskCategory.disable();
        this.titleUpdateForm.controls.edittasksource.disable();
        this.titleUpdateForm.controls.edittaskType.disable();
        this.titleUpdateForm.controls.editgroupOrIndividual.disable();
        this.titleUpdateForm.controls.editassignedBy.disable();
      } else if (this.taskData.taskState === 'P') {
        this.titleUpdateForm.disable();
        this.edittaskstatus = this.taskstatus;
      }
      else {
        this.titleUpdateForm.enable();
        this.edittaskstatus = this.taskstatus;
      }
      this.taskData.taskState === 'P' ? this.updatebtn = false : this.updatebtn = true;
      const assigneddate = this.datePipe.transform(
        this.taskData.assignedDateTime,
        'MM-dd-yyyy'
      );
      if (this.taskData.taskState !== 'P') {
        this.ClosedDate = false;
      } else {
        this.ClosedDate = true;
      }
      this.titleUpdateForm.controls.editTitle.patchValue(this.taskData.title);
      this.titleUpdateForm.controls.editdescription.patchValue(this.taskData.description);
      this.titleUpdateForm.controls.edittaskCategory.patchValue(this.taskCategory.filter((x) => x.code === this.taskData.lkCategory)[0]);
      // tslint:disable-next-line: max-line-length
      if (this.loginusid !== this.taskData.assignedTo && this.taskData.assignedBy !== this.taskData.assignedTo && this.taskData.taskState !== 'C') {
        this.updateBtn = false;
        this.titleUpdateForm.controls.editassignStatus.disable();
        this.titleUpdateForm.controls.editassignStatus.patchValue(this.editstatus.filter((x) => x.code === this.taskData.taskState)[0]);
      }
      else if (this.loginusid === this.taskData.assignedTo && this.taskData.assignedBy !== this.taskData.assignedTo) {
        this.updateBtn = true;
        this.titleUpdateForm.controls.editassignStatus.enable();
        this.editstatus.splice(this.editstatus.length - 1, 1);
      }

      else if (this.taskData.assignedBy === this.taskData.assignedTo && this.taskData.taskState === 'A') {
        this.updateBtn = true;
        this.titleUpdateForm.controls.editassignStatus.enable();
        this.titleUpdateForm.controls.editassignStatus.patchValue(this.editstatus.filter((x) => x.code === this.taskData.taskState)[0]);
        this.editstatus.splice(this.editstatus.length - 1, 1);
      }
      else if (this.taskData.assignedBy !== this.taskData.assignedTo && this.taskData.taskState === 'A') {
        this.titleUpdateForm.controls.editassignStatus.patchValue(this.editstatus.filter((x) => x.code === this.taskData.taskState)[0]);
        this.editstatus.splice(this.editstatus.length - 1, 1);
      }
      this.titleUpdateForm.controls.editassignStatus.patchValue(this.editstatus.filter((x) => x.code === this.taskData.taskState)[0]);
      this.titleUpdateForm.controls.editassignedDate.patchValue(this.taskData.assignedDate);
      this.titleUpdateForm.controls.edittargetDate.patchValue(this.taskData.targetDate);
      // tslint:disable-next-line: max-line-length
      this.titleUpdateForm.controls.editrecuringTask.patchValue(this.recuringData.filter((x) => x.code === this.taskData.recurringTask)[0]);
      this.titleUpdateForm.controls.editremainder.patchValue(this.remainderData.filter((x) => x.code === this.taskData.reminderType)[0]);
      this.titleUpdateForm.controls.editduration.patchValue(this.durationData.filter((x) => x.code === this.taskData.duration)[0]);
      // tslint:disable-next-line: max-line-length
      this.titleUpdateForm.controls.editgroupOrIndividual.patchValue(this.groupOrIndividual.filter((x) => x.code === this.taskData.type)[0]);
      if (this.taskData.type === 'G') {
        // tslint:disable-next-line: no-shadowed-variable
        this.apisService.getApi(environment.groupormembernames + this.loginusid + '/A').subscribe((res) => {
          this.employeeList = res.data;
          this.titleUpdateForm.controls.editassignedBy.patchValue(this.employeeList.filter((x) => x.id === this.taskData.groupId)[0]);
        });
      }
      else {
        // tslint:disable-next-line: no-shadowed-variable
        this.apisService.getApi(environment.assignedtodropdown + this.accId).subscribe((res) => {
          this.employeeList = res.data;
          this.titleUpdateForm.controls.editassignedBy.patchValue(this.employeeList.filter((x) => x.id === this.taskData.assignedTo)[0]);
        });
      }
    });

  }
  addCancel() {
    this.displayModal = false;
    this.titleAddForm.reset();
    this.displayModal1 = false;
    this.titleUpdateForm.reset();
  }
  editCancel() {
    this.displayModal1 = false;
    this.titleUpdateForm.reset();
  }
  handleFileInput(event) {
    this.fileName = event.target.files[0];
    this.attname = this.fileName.name;
    if (this.fileName.name.length > 25) {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'File name should be 25 alphabets.' });
      this.cancelfiles();
    }
    if (this.fileName.size > 10485760) {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please upload below 10MB.' });
      this.cancelfiles();
    }
    else {
      this.fileType = this.fileName.name.split('.').pop();
      const formData: FormData = new FormData();
      formData.append('file', this.fileName);
      this.filedata = formData;
      // tslint:disable-next-line:no-conditional-assignment
      if (this.filedata = formData) {
        this.filesdatass = true;
      } else {
        this.filesdatass = false;
      }
    }
  }
  SaveDailog1() {
    if (this.titleAddForm.invalid) {
      const controls = this.titleAddForm.controls;
      Object.keys(controls).forEach(key => {
        controls[key].markAsTouched();
      });
    } else {
      const data = {
        // tslint:disable-next-line: max-line-length
        title: this.titleAddForm.controls.addTitle.value === null || this.titleAddForm.controls.addTitle.value === undefined || this.titleAddForm.controls.addTitle.value === '' ? '' : this.titleAddForm.controls.addTitle.value,
        // tslint:disable-next-line: max-line-length
        description: this.titleAddForm.controls.description.value === null || this.titleAddForm.controls.description.value === '' || this.titleAddForm.controls.description.value === undefined ? '' : this.titleAddForm.controls.description.value,
        objectId: 0,
        lkObjectType: 'A',
        // tslint:disable-next-line:max-line-length
        category: this.titleAddForm.controls.taskCategory.value === null || this.titleAddForm.controls.taskCategory.value === undefined || this.titleAddForm.controls.taskCategory.value === '' ? 'B' : this.titleAddForm.controls.taskCategory.value.code,
        // tslint:disable-next-line:max-line-length
        type: this.titleAddForm.controls.groupOrIndividual.value === null || this.titleAddForm.controls.groupOrIndividual.value === undefined || this.titleAddForm.controls.groupOrIndividual.value === '' ? '' : this.titleAddForm.controls.groupOrIndividual.value.code,
        // tslint:disable-next-line:max-line-length
        groupId: this.titleAddForm.controls.groupOrIndividual.value.code === 'G' ? this.titleAddForm.controls.assignedBy.value.id : '' || this.titleAddForm.controls.groupOrIndividual.value.code === 'M' ? 0 : this.titleAddForm.controls.assignedBy.value.id,
        // tslint:disable-next-line: max-line-length
        taskSource: this.titleAddForm.controls.tasksource.value === null || this.titleAddForm.controls.tasksource.value === '' || this.titleAddForm.controls.tasksource.value === undefined ? '' : this.titleAddForm.controls.tasksource.value.code,
        // tslint:disable-next-line: max-line-length
        taskType: this.titleAddForm.controls.taskType.value === null || this.titleAddForm.controls.taskType.value === '' || this.titleAddForm.controls.taskType.value === undefined ? '' : this.titleAddForm.controls.taskType.value.code,
        // tslint:disable-next-line: max-line-length
        taskState: this.titleAddForm.controls.assignStatus.value === null || this.titleAddForm.controls.assignStatus.value === undefined || this.titleAddForm.controls.assignStatus.value === '' ? '' : this.titleAddForm.controls.assignStatus.value.code,
        // tslint:disable-next-line: max-line-length
        reminderType: this.titleAddForm.controls.remainder.value === null || this.titleAddForm.controls.remainder.value === undefined || this.titleAddForm.controls.remainder.value === '' || this.titleAddForm.controls.remainder.value.code,
        // tslint:disable-next-line: max-line-length
        parentTaskId: this.taskValue === 'C' ? this.dwnid : 0,
        // tslint:disable-next-line: max-line-length
        targetDate: this.titleAddForm.controls.targetDate.value === null || this.titleAddForm.controls.targetDate.value === undefined || this.titleAddForm.controls.targetDate.value === '' ? null : this.datePipe.transform(this.titleAddForm.controls.targetDate.value, 'yyyy-MM-dd'),
        // tslint:disable-next-line: max-line-length
        assignedDate: this.titleAddForm.controls.assignedDate.value === null || this.titleAddForm.controls.assignedDate.value === undefined || this.titleAddForm.controls.assignedDate.value === '' ? null : this.datePipe.transform(this.titleAddForm.controls.assignedDate.value, 'yyyy-MM-dd'),
        startedDate: null,
        // tslint:disable-next-line:max-line-length
        duration: this.titleAddForm.controls.duration.value === null || this.titleAddForm.controls.duration.value === undefined || this.titleAddForm.controls.duration.value === '' ? '' : this.titleAddForm.controls.duration.value.code,
        assignedById: this.loginusid,
        // tslint:disable-next-line: max-line-length
        assignedToId: this.titleAddForm.controls.groupOrIndividual.value.code === 'G' ? 0 : '' || this.titleAddForm.controls.groupOrIndividual.value.code === 'M' ? this.titleAddForm.controls.assignedBy.value.id : this.titleAddForm.controls.assignedBy.value === null || this.titleAddForm.controls.assignedBy.value === undefined || this.titleAddForm.controls.assignedBy.value === '' ? '' : this.titleAddForm.controls.assignedBy.value.id,
        // tslint:disable-next-line: max-line-length
        targetTime: this.titleAddForm.controls.addTargetTime.value === null || this.titleAddForm.controls.addTargetTime.value === '' || this.titleAddForm.controls.addTargetTime.value === undefined ? '' : this.titleAddForm.controls.addTargetTime.value.code,
        // tslint:disable-next-line: max-line-length
        recurringTask: this.titleAddForm.controls.recuringTask.value === null || this.titleAddForm.controls.recuringTask.value === '' || this.titleAddForm.controls.recuringTask.value === undefined ? 'N' : this.titleAddForm.controls.recuringTask.value.code,
        // tslint:disable-next-line: max-line-length
        lkRecurringDuration: this.titleAddForm.controls.recuringduration.value === null || this.titleAddForm.controls.recuringduration.value === '' || this.titleAddForm.controls.recuringduration.value === undefined ? '' : this.titleAddForm.controls.recuringduration.value.code
      };
      this.loader = true;
      this.btndsb = true;
      this.apisService.postApi(environment.addTask, data).subscribe((res: any) => {
        if (res.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
        }
        this.displayModal = false;
        this.loader = false;
        this.titleAddForm.reset();
        this.btndsb = false;
        this.UserID = res.autoId;
        this.userid = this.UserID + '/T';
        if (this.filedata === null || this.filedata === undefined) {
        }
        else {
          this.apisService.postApi(environment.profileUpload + this.UserID + '/T', this.filedata).subscribe((res1: any) => {
            const reqbody = {
              taskId: this.UserID,
              attachmentPath: res1.data.path,
              attachmentName: this.attname,
              documentType: 'A',
              bucket: 's3',
            };
            this.apisService.postApi(environment.saveattachment, reqbody).subscribe((res2: any) => {
              if (res2.hasOwnProperty('success') === true) {
                this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res2.message });
              } else if (res2.hasOwnProperty('failed') === true) {
                this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res2.failed });
              } else {
                this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res2.error });
              }
              this.titleAddForm.reset();
              this.gridTable();

            });
          });
        }
        this.titleAddForm.reset();
        this.gridTable();

      });
    }
  }
  assigntomethod(event) {
    this.assignto = [];
    this.assignid = event.value.id;
    this.taskStatus();
    this.statusmethod('C');
  }
  statusmethod(event) {
    if (event.value.code === 'P') {
      this.ClosedDate = true;
      this.titleUpdateForm.controls.editclosedDate.enable();
    } else {
      this.ClosedDate = false;
    }
    // tslint:disable-next-line:max-line-length edittargetDate
    event === 'C' || event.value.code === 'C' || event.value.code === 'I' || event.value.code === 'P' || event.value.code === 'O' ? this.checkboxdsb = false : this.checkboxdsb = true;
  }
  update() {
    const reqbody = {
      id: this.taskid,
      objectId: 0,
      lkObjectType: 'B',
      // tslint:disable-next-line: max-line-length
      title: this.titleUpdateForm.controls.editTitle.value === null || this.titleUpdateForm.controls.editTitle.value === '' || this.titleUpdateForm.controls.editTitle.value === undefined ? '' : this.titleUpdateForm.controls.editTitle.value,
      description: this.titleUpdateForm.controls.editdescription.value === null || this.titleUpdateForm.controls.editdescription.value === '' || this.titleUpdateForm.controls.editdescription.value === undefined ? '' : this.titleUpdateForm.controls.editdescription.value,
      lkActivityType: 'B',
      // tslint:disable-next-line: max-line-length
      category: this.titleUpdateForm.controls.edittaskCategory.value === null || this.titleUpdateForm.controls.edittaskCategory.value === '' || this.titleUpdateForm.controls.edittaskCategory.value === undefined ? '' : this.titleUpdateForm.controls.edittaskCategory.value.code,
      // tslint:disable-next-line: max-line-length
      type: this.titleUpdateForm.controls.editgroupOrIndividual.value === null || this.titleUpdateForm.controls.editgroupOrIndividual.value === '' || this.titleUpdateForm.controls.editgroupOrIndividual.value === undefined ? '' : this.titleUpdateForm.controls.editgroupOrIndividual.value.code,
      // tslint:disable-next-line: max-line-length
      groupId: this.titleUpdateForm.controls.editgroupOrIndividual.value.code === 'G' ? this.titleUpdateForm.controls.editassignedBy.value.id : '' || this.titleUpdateForm.controls.editgroupOrIndividual.value.code === 'M' ? 0 : this.titleUpdateForm.controls.editassignedBy.value.id,
      // tslint:disable-next-line: max-line-length
      taskSource: this.titleUpdateForm.controls.edittasksource.value === null || this.titleUpdateForm.controls.edittasksource.value === '' || this.titleUpdateForm.controls.edittasksource.value === undefined ? '' : this.titleUpdateForm.controls.edittasksource.value.code,
      // tslint:disable-next-line: max-line-length
      taskType: this.titleUpdateForm.controls.edittaskType.value === null || this.titleUpdateForm.controls.edittaskType.value === '' || this.titleUpdateForm.controls.edittaskType.value === undefined ? '' : this.titleUpdateForm.controls.edittaskType.value.code,
      taskState: this.titleUpdateForm.controls.editassignStatus.value === null || this.titleUpdateForm.controls.editassignStatus.value === '' || this.titleUpdateForm.controls.editassignStatus.value === undefined ? '' : this.titleUpdateForm.controls.editassignStatus.value.code,
      // tslint:disable-next-line: max-line-length
      reminderType: this.titleUpdateForm.controls.editremainder.value === null || this.titleUpdateForm.controls.editremainder.value === '' || this.titleUpdateForm.controls.editremainder.value === undefined ? '' : this.titleUpdateForm.controls.editremainder.value.code,
      // tslint:disable-next-line: max-line-length
      // notification: this.titleUpdateForm.controls.editbnotification.value === null || this.titleUpdateForm.controls.editbnotification.value === '' || this.titleUpdateForm.controls.editbnotification.value === undefined ? '' : this.titleUpdateForm.controls.editbnotification.value,
      parentTaskId: 0,
      // tslint:disable-next-line: max-line-length
      targetDate: this.titleUpdateForm.controls.edittargetDate.value === null || this.titleUpdateForm.controls.edittargetDate.value === undefined || this.titleUpdateForm.controls.edittargetDate.value === '' ? null : this.datePipe.transform(this.titleUpdateForm.controls.edittargetDate.value, 'yyyy-MM-dd'),
      // tslint:disable-next-line: max-line-length
      assignedDate: this.titleUpdateForm.controls.editassignedDate.value === null || this.titleUpdateForm.controls.editassignedDate.value === undefined || this.titleUpdateForm.controls.editassignedDate.value === '' ? null : this.datePipe.transform(this.titleUpdateForm.controls.editassignedDate.value, 'yyyy-MM-dd'),

      startedDate: '2021-12-01',
      // tslint:disable-next-line: max-line-length
      closedDate: this.titleUpdateForm.controls.editclosedDate.value === null || this.titleUpdateForm.controls.editclosedDate.value === undefined || this.titleUpdateForm.controls.editclosedDate.value === '' ? null : this.datePipe.transform(this.titleUpdateForm.controls.editclosedDate.value, 'yyyy-MM-dd'),

      // tslint:disable-next-line: max-line-length
      duration: this.titleUpdateForm.controls.editduration.value === null || this.titleUpdateForm.controls.editduration.value === '' || this.titleUpdateForm.controls.editduration.value === undefined ? '' : this.titleUpdateForm.controls.editduration.value.code,

      // assignedById: this.loginusid,
      // tslint:disable-next-line: max-line-length
      assignedToId: this.titleUpdateForm.controls.editgroupOrIndividual.value.code === 'G' ? 0 : '' || this.titleUpdateForm.controls.editgroupOrIndividual.value.code === 'M' ? this.titleUpdateForm.controls.editassignedBy.value.id : this.titleUpdateForm.controls.editassignedBy.value === null || this.titleAddForm.controls.editassignedBy.value === undefined || this.titleAddForm.controls.editassignedBy.value === '' ? '' : this.titleAddForm.controls.editassignedBy.value.id,
      // tslint:disable-next-line: max-line-length
      recurringTask: this.titleUpdateForm.controls.editrecuringTask.value === null || this.titleUpdateForm.controls.editrecuringTask.value === '' || this.titleUpdateForm.controls.editrecuringTask.value === undefined ? 'N' : this.titleUpdateForm.controls.editrecuringTask.value.code,
      // tslint:disable-next-line: max-line-length
      lkRecurringDuration: this.titleUpdateForm.controls.editrecuringDuration.value === null || this.titleUpdateForm.controls.editrecuringDuration.value === '' || this.titleUpdateForm.controls.editrecuringDuration.value === undefined ? '' : this.titleUpdateForm.controls.editrecuringDuration.value.code
    };
    this.btndsb = true;
    this.loader = true;
    this.apisService.putApi(environment.updatetask, reqbody).subscribe((res: any) => {
      this.displayModal1 = false;
      this.loader = false;
      this.btndsb = false;
      if (res.success === true) {
        this.gridTable();
        this.messageService.add({
          key: 'br',
          severity: '',
          life: 3000,
          detail: res.message,
        });
      } else {
        this.messageService.add({
          key: 'br',
          severity: '',
          life: 3000,
          detail: res.message,
        });
        this.gridTable();
      }
    });
  }
  assignTask(data) {
    Swal.fire({
      // tslint:disable-next-line: max-line-length
      text: 'Do you want to assign task', icon: 'warning', showCancelButton: true, confirmButtonColor: '#0D416B', confirmButtonText: 'Yes', cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        return this.assign(data);
      }
    });
  }
  assign(data) {
    const body = {
      flag: 'A',
      taskState: 'A',
      id: data.id
    };
    this.apisService.putApi(environment.assignTask, body).subscribe((res) => {
      this.gridTable();
      this.messageService.add({
        key: 'br',
        severity: '',
        life: 3000,
        detail: 'Task Assigned successfully.',
      });
    });
    // assignTask
  }
  closeTask(data) {
    Swal.fire({
      // tslint:disable-next-line: max-line-length
      text: 'Do you want to close task', icon: 'warning', showCancelButton: true, confirmButtonColor: '#0D416B', confirmButtonText: 'Yes', cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        return this.close(data);
      }
    });
  }
  close(data) {
    const body = {
      flag: 'C',
      taskState: 'P',
      id: data.id
    };
    this.apisService.putApi(environment.assignTask, body).subscribe((res) => {
      this.gridTable();
      this.messageService.add({
        key: 'br',
        severity: '',
        life: 3000,
        detail: 'Task Closed successfully.',
      });
    });
  }
  notify(data) {
    Swal.fire({
      // tslint:disable-next-line: max-line-length
      text: 'Do you want to send notification', icon: 'warning', showCancelButton: true, confirmButtonColor: '#0D416B', confirmButtonText: 'Yes', cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        return this.notification(data);
      }
    });
  }
  notification(data) {
    const body = {
      notification: 1,
      assignedToId: data.assignedtoid,
      groupId: data.groupId,
      id: data.id,
      targetDate: data.targetDate,
      title: data.title
    };
    this.apisService.putApi(environment.taskNotify, body).subscribe((res) => {
      this.messageService.add({
        key: 'br',
        severity: '',
        life: 3000,
        detail: 'Task Notification send successfully.',
      });
    });
    this.gridTable();
  }
  cancelfiles() {
    this.titleAddForm.controls.addResumeForm.reset();
    this.filesdatass = false;
    this.classinput = false;
    this.taskfiles = true;
  }
}
