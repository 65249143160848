<p-toast position="bottom-right" key="br"></p-toast>
<form [formGroup]="recommendationForm">
    <p-card>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Status</label>
                <p-dropdown [options]="status" [showClear]="true" optionLabel="name" size="30"
                    (onChange)="dropdownevent($event.value.code)" formControlName="status" placeholder="Select Status">
                </p-dropdown>
            </div>
        </div>
        <div class="btnsCSS1 alignbutton">
            <button pButton type="button" icon='pi pi-plus' label="Request" class="addhBtn" (click)="pop()"></button>
            <button pButton type="button" icon='pi pi-refresh' label="Reset" class="addhBtn" (click)="reset()"></button>
            <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn"
                (click)="search()"></button>
        </div>
    </p-card>
</form>
<br>
<p-card class="pgpos">
    <div *ngIf="table">
        <p-table [columns]="cols" [value]="gridRes" selectionMode="single" dataKey="edit" [rows]="10"
            [responsive]="true" [value]="gridData" [rowsPerPageOptions]="[10,20,30]" sortMode="multiple">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i=index" [ngStyle]="{'width': col.width }"
                        [pSortableColumn]="col.field">
                        <span>{{col.header}} </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
                <tr>
                    <td><span class="p-column-title">Organization Name</span>{{data.name}} </td>
                    <td><span class="p-column-title"> Recommended By</span> {{data.organization }} </td>
                    <td (click)="emailToast()" (click)="copyInputMessage(data.email)"><span class="p-column-title">
                            Email</span> {{data.recommendation}} </td>
                    <td><span class="p-column-title"> Recommendated Date</span>{{data.recDate}} </td>
                    <td><span class="p-column-title"> Action</span><a>
                            <em (click)="actionpopup.toggle($event); toggle($event, data)"
                                class="pi pi-ellipsis-v pointer"></em>
                        </a></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr *ngIf="gridRes?.length==0" class="aligncenter">
                    <td colspan="3" class="mandatoryColor"> No Records
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalRecords}} </h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png"
                    class="norecordimg">
                <br><em>Sorry, we are not able to find any recommendations</em><br>
            </div>
        </div>
    </p-card>
</p-card>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-dialog class="dialogBox" header="Request " [(visible)]="displayModal" [style]="{width: '28%'}" [draggable]="false"
    [modal]="true" [resizable]="false">
    <form [formGroup]="request">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Name<span class="mandatoryColor">*</span></label>
                <p-dropdown [filter]="true" optionLabel="details" [options]="jobStatus" [showClear]="true"
                    placeholder="Select Name" formControlName="name">
                </p-dropdown>
                <span class="validationtest"
                    *ngIf="this.request.get('name').touched && this.request.get('name').invalid && this.request.get('name').errors.required">
                    Please select name
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Description</label>
                <textarea id="textarea" type="text" pInputText placeholder="Description" autocomplete="off"
                    formControlName="description" maxlength="201"></textarea>
                    <span class="validationtest" *ngIf="this.request.get('description').hasError('maxlength')">
                        Please enter 200 alphabets only
                    </span>
            </div>
        </div>
    </form><br>
    <div class="btnsCSS2 rightalign">
        <button pButton type="button" [disabled]="hide" icon='pi pi-plus' label="Send Request" (click)="save()"
            class="addhBtn " [style]="{width:'100%'}"></button>
    </div>
</p-dialog>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
<p-dialog class="dialogBox" header="Show Request " [(visible)]="editModal" [style]="{width: '27%'}" [draggable]="false"
    [modal]="true" [resizable]="false">
    <form [formGroup]="editrequest">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Name<span class="mandatoryColor">*</span></label>
                <p-dropdown [filter]="true" optionLabel="details" [options]="jobStatus" [showClear]="true"
                    formControlName="name" placeholder="Select Name">
                </p-dropdown>
                <span class="validationtest"
                    *ngIf="this.editrequest.get('name').touched && this.editrequest.get('name').invalid && this.editrequest.get('name').errors.required">
                    Please select name
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Description</label>
                <textarea id="textarea" type="text" pInputText placeholder="Description" autocomplete="off"
                    formControlName="description" maxlength="201"></textarea>
                    <span class="validationtest" *ngIf="this.editrequest.get('description').hasError('maxlength')">
                        Please enter 200 alphabets only
                    </span>
            </div>
        </div>
    </form>
</p-dialog>