<div>
        <div *ngIf="!this.showdialog">
                <!-- Header will be shown when key is add -->
                <div *ngIf="addTimesheet">
                        <p-card class="background pgpos">
                                <div class="p-grid p-fluid ">
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 ">
                                                <img alt="" class="image" [src]="this.profileimage">
                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 line">
                                                <h4 style="position: relative; left: -2px; top: 14px;">
                                                        <strong>Month</strong>
                                                </h4><br>
                                                <span style="position: relative; left: -2px; top: -12px;">{{this.startdate|
                                                        date:'MMMM'
                                                        }}</span>
                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 line">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 14px;">
                                                                <strong>Week</strong>
                                                        </h4><br>
                                                        <span style="position: relative; left: -2px;  top: -12px;">Week
                                                                of
                                                                {{this.startdate|
                                                                date:'d'}}</span>
                                                </div>
                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 ">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 14px;">
                                                                <strong>Created
                                                                        By</strong></h4>
                                                        <br>
                                                        <span
                                                                style="position: relative; left: -2px; top: -12px;">{{this.accusername}}</span>
                                                </div>
                                        </div>
                                </div>
                        </p-card>
                </div>
                <!-- Header will be shown when key id D -->
                <div *ngIf="editTimesheet">
                        <p-card class="background pgpos">
                                <div class="p-grid p-fluid ">
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 ">
                                                <img alt="" class="image" [src]="this.profileimage">
                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 line">
                                                <h4 style="position: relative; left: -2px; top: 14px;">
                                                        <strong>Month</strong>
                                                </h4><br>
                                                <span style="position: relative; left: -2px; top: -12px;">{{this.startdate|
                                                        date:'MMMM'
                                                        }}</span>
                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 line">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 14px;">
                                                                <strong>Week</strong>
                                                        </h4><br>
                                                        <span style="position: relative; left: -2px;  top: -12px;">Week
                                                                of
                                                                {{this.startdate|
                                                                date:'d'}}</span>
                                                </div>

                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 ">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 14px;">
                                                                <strong>Submitted
                                                                        Date</strong></h4>
                                                        <br>
                                                        <span
                                                                style="position: relative; left: -2px; top: -12px;">{{this.patchSubmittedDate}}</span>
                                                </div>
                                        </div>
                                </div>
                        </p-card>
                </div>
                <!-- Header will be shown when key is S -->
                <div *ngIf="submittedform">
                        <p-card class="background pgpos">
                                <div class="p-grid p-fluid ">
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 ">
                                                <img alt="" class="image" [src]="this.profileimage">
                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 line">
                                                <h4 style="position: relative; left: -2px; top: 14px;">
                                                        <strong>Month</strong>
                                                </h4><br>
                                                <span style="position: relative; left: -2px; top: -12px;">{{this.startdate|
                                                        date:'MMMM'
                                                        }}</span>
                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 line">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 14px;">
                                                                <strong>Week</strong>
                                                        </h4><br>
                                                        <span style="position: relative; left: -2px;  top: -12px;">Week of
                                                                {{this.startdate|
                                                                date:'d'}}</span>
                                                </div>

                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 ">
                                                <div class="linemoveleft">
                                                        <h4><strong>Submitted Date</strong></h4>
                                                        <span class="headerDate">{{this.submittedDate}}</span>
                                                </div>
                                        </div>
                                </div>
                        </p-card>
                </div>
                <!-- Header will be shown when key is A -->
                <div *ngIf="approveTimesheet">
                        <p-card class="background pgpos">
                                <div class="p-grid p-fluid ">
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 ">
                                                <img alt="" class="image" [src]="this.profileimage">
                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 line">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 4px;"><strong>Submitted
                                                                By</strong></h4>
                                                <span
                                                        style="position: relative; left: -2px; top: -4px;">{{this.submittedbyname}}</span>
                                                </div>
                                                
                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 line">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 14px;">
                                                                <strong>Month</strong>
                                                        </h4><br>
                                                        <span style="position: relative; left: -2px; top: -12px;">{{this.startdate|
                                                                date:'MMMM'}}</span>
                                                </div>

                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 line">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 14px;">
                                                                <strong>Week</strong>
                                                        </h4><br>
                                                        <span style="position: relative; left: -2px;  top: -12px;">Week of
                                                                {{this.startdate| date:'d'}}</span>
                                                </div>

                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 line">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 6px;"><strong>Submitted
                                                                Date</strong></h4>
                                                <span
                                                        style="position: relative; left: -2px;  top: -4px;">{{this.submittedDate}}</span>
                                                </div>
                                               
                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 14px;">
                                                                <strong>Total Hours</strong>
                                                        </h4><br>
                                                        <span style="position: relative; left: -2px;  top: -12px;">{{this.hierarchytotalhours}}
                                                                (Hrs)</span>
                                                </div>

                                        </div>

                                </div>

                        </p-card>
                </div>
                <!-- header will be shown in project manager role when key is R and need to approve -->
                <div *ngIf="proejctmanagerreject">
                        <p-card class="background pgpos">
                                <div class="p-grid p-fluid ">
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 ">
                                                <img alt="" class="image" [src]="this.profileimage">
                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 line">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 4px;"><strong>Submitted
                                                                By</strong></h4>
                                                <span
                                                        style="position: relative; left: -2px; top: -4px;">{{this.submittedbyname}}</span>
                                                </div>

                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 line">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 14px;">
                                                                <strong>Month</strong>
                                                        </h4><br>
                                                        <span style="position: relative; left: -2px; top: -12px;">{{this.startdate|
                                                                date:'MMMM'}}</span>
                                                </div>

                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 line">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 14px;">
                                                                <strong>Week</strong>
                                                        </h4><br>
                                                        <span style="position: relative; left: -2px;  top: -12px;">Week of
                                                                {{this.startdate| date:'d'}}</span>       
                                                </div>

                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 line">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 6px;"><strong>Submitted
                                                                Date</strong></h4>
                                                <span
                                                        style="position: relative; left: -2px;  top: -4px;">{{this.submittedDate}}</span>
                                                </div>
                                                
                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 14px;">
                                                                <strong>Total Hours</strong>
                                                        </h4><br>
                                                        <span style="position: relative; left: -2px;  top: -12px;">{{this.hierarchytotalhours}}
                                                                (Hrs)</span>
                                                </div>
                                        </div>
                                </div>
                        </p-card>
                </div>
                <div *ngIf="pmrejected">
                        <p-card class="background pgpos">
                                <div class="p-grid p-fluid ">
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 ">
                                                <img alt="" class="image" [src]="this.profileimage">
                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 line">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 4px;"><strong>Submitted
                                                                By</strong></h4>
                                                <span
                                                        style="position: relative; left: -2px; top: -4px;">{{this.submittedbyname}}</span> 
                                                </div>

                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 line">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 14px;">
                                                                <strong>Month</strong>
                                                        </h4><br>
                                                        <span style="position: relative; left: -2px; top: -12px;">{{this.startdate|
                                                                date:'MMMM'}}</span>
                                                </div>

                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 line">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 14px;">
                                                                <strong>Week</strong>
                                                        </h4><br>
                                                        <span style="position: relative; left: -2px;  top: -12px;">Week of
                                                                {{this.startdate| date:'d'}}</span>
                                                </div>

                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 line">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 6px;"><strong>Submitted
                                                                Date</strong></h4>
                                                <span
                                                        style="position: relative; left: -2px;  top: -4px;">{{this.submittedDate}}</span>
                                                </div>
                                                
                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 14px;">
                                                                <strong>Total Hours</strong>
                                                        </h4><br>
                                                        <span style="position: relative; left: -2px;  top: -12px;">{{this.hierarchytotalhours}}
                                                                (Hrs)</span>
                                                </div>
                                        </div>
                                </div>
                        </p-card>
                </div>
                <!-- header will be shown in employee role when key is R -->
                <div *ngIf="rejectedTimeSheet">
                        <p-card class="background pgpos">
                                <div class="p-grid p-fluid ">
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 ">
                                                <img alt="" class="image" [src]="this.profileimage">
                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 line">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 14px;">
                                                                <strong>Month</strong>
                                                        </h4><br>
                                                        <span style="position: relative; left: -2px; top: -12px;">{{this.startdate|
                                                                date:'MMMM'
                                                                }}</span>
                                                </div>

                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1 line">
                                                <div style="    position: relative;
                                                left: 12px;">
                                                        <h4 style="position: relative; left: -2px; top: 14px;">
                                                                <strong>Week</strong>
                                                        </h4><br>
                                                        <span style="position: relative; left: -2px;  top: -12px;">Week
                                                                of
                                                                {{this.startdate|
                                                                date:'d'}}</span>
                                                </div>
                                        </div>
                                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 ">
                                                <div class="linemoveleft">
                                                        <h4 style="position: relative; left: -2px; top: 14px;"><strong>Created
                                                                By</strong></h4>
                                                <br>
                                                <span
                                                        style="position: relative; left: -2px; top: -12px;">{{this.accusername}}</span>
                                                </div>
                                                
                                        </div>
                                </div>
                        </p-card>
                </div>
                <br>
                <p-card>
                        <button style=" background-color: rgb(43, 185, 213);
                        border: none;" pButton type="button" icon="pi pi-arrow-left" (click)="goBack()"></button>
                        <div class="btnsCSS1" *ngIf="!addTimesheet">
                                <button style=" background-color: rgb(43, 185, 213);
                                border: none;" tooltipPosition="top" pTooltip="View timesheet history" pButton
                                        type="button" icon="pi pi-fw pi-clock" class="addhBtn"
                                        (click)="viewHistory()"></button>
                                <button *ngIf="this.roles !== 9"  style=" background-color: rgb(43, 185, 213);
                                border: none;" tooltipPosition="top" (click)="previousTimesheet('1')"
                                        pTooltip="View previous timesheet" pButton [disabled]="previousbtn"
                                        type="button" icon="pi pi-chevron-left" class="addhBtn"></button>
                                <button *ngIf="this.roles !== 9" style=" background-color: rgb(43, 185, 213);
                                border: none;" tooltipPosition="top" pTooltip="View next  timesheet" pButton
                                        [disabled]="nextbtn" (click)="nextTimesheet('2')" type="button"
                                        icon="pi pi-chevron-right" class="addhBtn"></button>
                        </div>
                        <br><br>
                        <form [formGroup]="projectsdata">
                                <div *ngIf="isUploading1" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                                        <p-progressBar mode="indeterminate"></p-progressBar>
                                </div>
                                <!-- <p-table [columns]="cols" [value]="projects" dataKey="edit" [autoLayout]="true"
                             selectionMode="single" [responsive]="true"> -->
                                <p-table [columns]="cols" [value]="this.projects" [autoLayout]="true"
                                        selectionMode="single" [responsive]="true">

                                        <ng-template pTemplate="header" let-columns>

                                                <tr>
                                                        <th><br /><label
                                                                        style="color:rgb(43, 185, 213)">Category</label>
                                                        </th>
                                                        <th style="padding: 1rem 2rem;">
                                                                {{this.week1 }}<br /><label
                                                                        style="font-size: 10px; padding-left: 11px;">
                                                                </label><label class="headerstyle">Sunday</label>
                                                        </th>
                                                        <th style="padding: 1rem 2rem;">
                                                                {{this.week2 }}<br /><label
                                                                        style="font-size: 10px; padding-left: 11px;">
                                                                </label><label class="headerstyle">Monday</label>
                                                        </th>
                                                        <th style="padding: 1rem 2rem;">
                                                                {{this.week3}}<br /><label
                                                                        style="font-size: 10px; padding-left: 6px;">
                                                                </label><label class="headerstyle">Tuesday</label>
                                                        </th>
                                                        <th style="padding: 1rem 2rem;">
                                                                {{this.week4 }}<br /><label
                                                                        style="font-size: 10px; padding-left: -1px;">
                                                                </label><label class="headerstyle">Wednesday</label>
                                                        </th>
                                                        <th style="padding: 1rem 2rem;">
                                                                {{this.week5 }}<br /><label
                                                                        style="font-size: 10px; padding-left: 4px;">
                                                                </label><label class="headerstyle">Thursday</label>
                                                        </th>
                                                        <th style="padding: 1rem 2rem;">
                                                                {{this.week6 }}<br /><label
                                                                        >
                                                                </label><label style="padding-left: 17px;" class="headerstyle">Friday</label>
                                                        </th>
                                                        <th style="padding: 1rem 2rem;">
                                                                {{this.week7}}<br /><label
                                                                        style="font-size: 10px; padding-left: 11px;">
                                                                </label><label style="padding-left: 0px;" class="headerstyle">Saturday</label>
                                                        </th>
                                                </tr>
                                        </ng-template>

                                        <ng-template pTemplate="body" let-data>

                                                <tr *ngIf="this.project1">
                                                        <td tooltipPosition="top" pTooltip="{{project1}}">{{project1}}
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p1sun" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p1mon" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p1tues" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p1wed" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p1thurs" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p1fri" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p1sat" />
                                                        </td>
                                                </tr>
                                                <tr *ngIf="this.project2">
                                                        <td tooltipPosition="top" pTooltip="{{project2}}">{{project2}}
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p2sun" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p2mon" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p2tues" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p2wed" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p2thurs" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p2fri" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p2sat" />
                                                        </td>
                                                </tr>
                                                <tr *ngIf="this.project3">
                                                        <td tooltipPosition="top" pTooltip="{{project3}}">{{project3}}
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p3sun" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p3mon" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p3tues" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p3wed" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p3thurs" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p3fri" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p3sat" />
                                                        </td>
                                                </tr>
                                                <tr *ngIf="this.project4">
                                                        <td tooltipPosition="top" pTooltip="{{project4}}">{{project4}}
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p4sun" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p4mon" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p4tues" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p4wed" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p4thurs" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p4fri" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p4sat" />
                                                        </td>
                                                </tr>
                                                <tr *ngIf="this.project5">
                                                        <td tooltipPosition="top" pTooltip="{{project5}}">{{project5}}
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p5sun" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p5mon" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p5tues" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p5wed" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p5thurs" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p5fri" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="p5sat" />
                                                        </td>
                                                </tr>
                                                <tr>
                                                        <td tooltipPosition="top" pTooltip="Internal Hours">Internal
                                                                Hours</td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="isun" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="imon" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="itues" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="iwed" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="ithurs" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="ifri" />
                                                        </td>
                                                        <td><input type="number" class="iptext"
                                                                        (input)="calculateTotalHours()" placeholder="0"
                                                                        pInputText formControlName="isat" />
                                                        </td>
                                                </tr>

                                                <tr>
                                                        <td>Total Hours</td>
                                                        <td><input type="number" disabled="true" class="iptext"
                                                                        pInputText formControlName="tsun"
                                                                        placeholder="0" /></td>
                                                        <td><input type="number" disabled="true" class="iptext"
                                                                        pInputText formControlName="tmon"
                                                                        placeholder="0" /></td>
                                                        <td><input type="number" disabled="true" class="iptext"
                                                                        pInputText formControlName="ttues"
                                                                        placeholder="0" /></td>
                                                        <td><input type="number" disabled="true" class="iptext"
                                                                        pInputText formControlName="twed"
                                                                        placeholder="0" /></td>
                                                        <td><input type="number" disabled="true" class="iptext"
                                                                        pInputText formControlName="tthurs"
                                                                        placeholder="0" /></td>
                                                        <td><input type="number" disabled="true" class="iptext"
                                                                        pInputText formControlName="tfri"
                                                                        placeholder="0" /></td>
                                                        <td><input type="number" disabled="true" class="iptext"
                                                                        pInputText formControlName="tsat"
                                                                        placeholder="0" /></td>
                                                </tr>

                                        </ng-template>

                                </p-table>
                        </form>
                        <br>
                        <div *ngIf="addTimesheet">
                                <form [formGroup]="timesheetNote">
                                        <textarea autocomplete="off" pInputTextarea rows="3" placeholder="Notes"
                                                class="size" maxlength=251 formControlName="notes"
                                                autoResize="false"></textarea>

                                        <span class="validationtest"
                                                *ngIf="this.timesheetNote.get('notes').hasError('maxlength')">
                                                Please enter 250 alphabets only
                                        </span>


                                        <br>
                                        <br>
                                        <input *ngIf="classinput" type="file" id="attachment" maxFileSize="1000000"
                                                accept=".doc,.docx,application/msword,application/pdf"
                                                (change)="handleFileInput($event)" formControlName='addResumeForm'>
                                        <input *ngIf="classinput1" type="file" id="attachment"
                                                accept=".doc,.docx,application/msword,application/pdf"
                                                (change)="handleFileInput($event)" formControlName='addResumeForm'>
                                        <em *ngIf="filesdatass" class="pi pi-times" style="cursor: pointer;"
                                                (click)="cancelfiles()"></em>
                                        <div class="validationtest"
                                                *ngIf="this.timesheetNote.get('addResumeForm').touched && this.timesheetNote.get('addResumeForm').invalid && this.timesheetNote.get('addResumeForm').errors.required">
                                                Please upload attachment
                                        </div>
                                        <!-- </div> -->
                                        <div class="btnsCSS1">
                                                <button pButton type="button" icon="pi pi-copy" label="Save as Draft"
                                                        class="addhBtn" style="width: auto"
                                                        (click)="saveAsDraft('D')"></button>
                                                <button pButton type="button" label="Submit" icon='pi pi-save'
                                                [disabled]="savenbtn"    class="addhBtn" (click)="submitTimesheet('S')"></button>
                                        </div>
                                </form>
                        </div>
                        <div *ngIf="editTimesheet">
                                <div [formGroup]="timesheetNote">
                                        <textarea autocomplete="off" pInputTextarea rows="3" placeholder="Notes"
                                                class="size" maxlength=251 formControlName="notes"
                                                autoResize="false"></textarea>
                                        <span class="validationtest"
                                                *ngIf="this.timesheetNote.get('notes').hasError('maxlength')">
                                                Please enter 250 alphabets only
                                        </span>
                                </div>

                                <br>
                                <br>

                                <input maxFileSize="10485760" type="file" id="attachment"
                                        accept=".doc,.docx,application/msword,application/pdf" class="pointer">
                                <div class="btnsCSS1">
                                        <button pButton disabled="true" type="button" icon="pi pi-copy"
                                                label="Save as Draft" class="addhBtn" style="width: auto"></button>
                                        <button pButton type="button" label="Submit" icon='pi pi-save' class="addhBtn"
                                        [disabled]="savenbtn"  (click)="callPopUpTimesheet()"></button>
                                </div>
                        </div>
                        <div *ngIf="rejectedTimeSheet">
                                <form [formGroup]="timesheetNote">
                                        <textarea autocomplete="off" pInputTextarea rows="3" placeholder="Notes"
                                                class="size" maxlength=251 formControlName="notes"
                                                autoResize="false"></textarea>

                                        <span class="validationtest"
                                                *ngIf="this.timesheetNote.get('notes').hasError('maxlength')">
                                                Please enter 250 alphabets only
                                        </span>
                                        <textarea disabled="true" autocomplete="off" pInputTextarea rows="3"
                                        placeholder="Reject Reason" class="size" maxlength=300
                                        formControlName="rejectReason" autoResize="false"></textarea><br><br>
                                        <!-- <h5></h5> -->
                                        <p class="downpos" *ngIf="this.dwnshow"
                                                style="    position: relative; bottom: -29px; left: -17px;">
                                                <span class="ui-column-title pointer" (click)="downloadFileapi()"><em
                                                                (click)="downloadFileapi()"
                                                                style="left: -12px;bottom: -11px;"
                                                                class="pi pi-download download-pos"></em><strong
                                                                style="position: relative; left: 3px;bottom: -11px;">Download</strong></span>
                                        </p>
                                        <input *ngIf="classinput" [ngClass]="this.downloadflag == '0' ? 'withoutattachment' : 'withattachment'" type="file" id="attachment" maxFileSize="1000000"
                                                accept=".doc,.docx,application/msword,application/pdf"
                                                (change)="handleFileInput($event)" formControlName='addResumeForm'>
                                        <!-- <input *ngIf="classinput" type="file" id="attachment" maxFileSize="1000000"
                                                accept=".doc,.docx,application/msword,application/pdf"
                                                (change)="handleFileInput($event)" formControlName='addResumeForm'> -->
                                        <input *ngIf="classinput1" type="file" id="attachment"
                                                accept=".doc,.docx,application/msword,application/pdf"
                                                (change)="handleFileInput($event)" formControlName='addResumeForm'>
                                        <em *ngIf="filesdatass" class="pi pi-times" style="cursor: pointer;"
                                                (click)="cancelfiles()"></em>
                                        <div class="validationtest"
                                                *ngIf="this.timesheetNote.get('addResumeForm').touched && this.timesheetNote.get('addResumeForm').invalid && this.timesheetNote.get('addResumeForm').errors.required">
                                                Please upload attachment
                                        </div>
                                        <!-- </div> -->
                                        <div class="btnsCSS1">
                                                <button pButton type="button" disabled="true" icon="pi pi-copy"
                                                        label="Save as Draft" class="addhBtn"
                                                        style="width: auto"></button>
                                                <button pButton type="button" label="Submit" icon='pi pi-save'
                                                [disabled]="savenbtn"  class="addhBtn" (click)="callPopUpTimesheet()"></button>
                                        </div>
                                </form>
                        </div>
                        <div *ngIf="submittedform">
                                <div [formGroup]="timesheetNote">
                                        <textarea autocomplete="off" disabled="true" pInputTextarea rows="3"
                                                placeholder="Notes" class="size" maxlength=300 formControlName="notes"
                                                autoResize="false"></textarea><br>
                                </div>
                                <p class="downpos" *ngIf="this.dwnshow" style="    position: relative;
                                bottom: 0px; right: 17px;">
                                        <span class="ui-column-title pointer" (click)="downloadFileapi()"><em
                                                        (click)="downloadFileapi()" style="left: 6px;bottom: -11px;"
                                                        class="pi pi-download download-pos"></em><strong
                                                        style="position: relative; left: 6px;bottom: -11px;">Download</strong></span>
                                </p>
                        </div>
                        <div *ngIf="approveTimesheet">

                                <div [formGroup]="timesheetNote">
                                        <textarea autocomplete="off" disabled="true" pInputTextarea rows="3" placeholder="Notes"
                                                class="size" maxlength=300 formControlName="notes"
                                                autoResize="false"></textarea><br>
                                </div><br><br>
                                <p class="downpos" *ngIf="this.dwnshow">
                                        <span class="ui-column-title pointer" (click)="downloadFileapi()"><em
                                                        (click)="downloadFileapi()" style="left: 6px;bottom: -11px;"
                                                        class="pi pi-download download-pos"></em><strong
                                                        style="position: relative; left: 6px;bottom: -11px;">Download</strong></span>
                                </p>
                                <div class="btnsCSS1" style="    position: relative;
                                bottom: 30px;">
                                        <button pButton type="button" icon="pi pi-times" label="Reject" class="addhBtn"
                                                style="width: auto" (click)="reject()"></button>
                                        <button pButton type="button" label="Approve" icon='pi pi-check' class="addhBtn"
                                                (click)="approvetimesheet()"></button>
                                </div>
                        </div>
                        <div *ngIf="pmrejected">
                                <br><br>
                                <div [formGroup]="timesheetNote">
                                        <textarea autocomplete="off" pInputTextarea rows="3" placeholder="Notes"
                                                class="size" maxlength=300 formControlName="notes"
                                                autoResize="false"></textarea><br>
                                </div>
                                <p class="downpos" *ngIf="this.dwnshow">
                                        <span class="ui-column-title pointer" (click)="downloadFileapi()"><em
                                                        (click)="downloadFileapi()"
                                                        class="pi pi-download download-position"></em><strong
                                                        style="position: relative; left: -11px;bottom: -27px;">Download</strong></span>
                                </p>
                                <br>
                        </div>
                        <div *ngIf="proejctmanagerreject">
                                <div [formGroup]="timesheetNote">
                                        <textarea autocomplete="off" pInputTextarea rows="3" placeholder="Notes"
                                        disabled="true" class="size" maxlength=300 formControlName="notes"
                                                autoResize="false"></textarea>
                                        <textarea disabled="true" autocomplete="off" pInputTextarea rows="3"
                                                placeholder="Reject Reason" class="size" maxlength=300
                                                formControlName="rejectReason" autoResize="false"></textarea><br>
                                </div>
                                <p class="downpos" *ngIf="this.dwnshow">
                                        <span class="ui-column-title pointer" (click)="downloadFileapi()"><em
                                                        (click)="downloadFileapi()"
                                                        class="pi pi-download download-position"></em><strong
                                                        style="position: relative; left: -11px;bottom: -27px;">Download</strong></span>
                                </p>
                                <br>
                        </div>
                </p-card>
        </div>
</div>

<div *ngIf="this.showdialog">
        <p-dialog style="text-align: center;" class="box" header=" " [closeOnEscape]="false" [closeIcon]="false"
                [(visible)]="display" [modal]="true" [contentStyle]="{'overflow': 'visible'}"
                [style]="{width: '30%','height':'20%'}">

                <h4>The TimeSheet already filled for the week</h4>
                <h5>(Starting Date: {{firstday | date:'MM-dd-yyyy'}} And End Date : {{lastday | date:'MM-dd-yyyy'}})
                </h5>
                <button pButton type="button" icon="pi pi-arrow-left" label="Go Back" (click)="goBack()"></button>


        </p-dialog>
</div>
<p-dialog style="text-align: center;" class="box" header=" " [(visible)]="updatedisplay" [modal]="true"
        [contentStyle]="{'overflow': 'visible'}" [style]="{width: '35%'}">
        <div class="p-grid p-fluid userenter">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                        <span style="align-items: center; padding-left: 1%;"><i class="fa fa-keyboard-o fa-5x"></i>
                                <p>User Entered {{this.totalhours}}</p>
                        </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">

                </div>
        </div>
        <div class="p-grid p-fluid textuser">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                        <h5 style="text-align: center;">Your are about to submit the timesheet for {{this.week1 |
                                date:'MMMM'}}
                                and week of
                                {{this.week1 | date:'d'
                                }}</h5>
                        <p style="text-align: center;">** Once you submitted you will not be able to make changes **</p>
                </div>
        </div>
        <div class="btnsCSS1">
                <button pButton class="addhBtn" type="button" icon='pi pi-times' label="Cancel"
                        (click)="cancelPopUp()"></button>
                <button pButton class="addhBtn" type="button" icon='pi pi-save' label="Submit"
                        (click)="updateTimesheet()"></button>
        </div>
</p-dialog>





<p-dialog class="dialogBox" [(visible)]="displayModal" header="" [draggable]="false" [modal]="true"
        [style]="{width: '30%',height:'60%'}">
        <p-header>Timesheet History<br>
                <!-- <span class="Activity">Candidate Name : <a>{{this.consultantName}}</a></span> -->
        </p-header>
        <p style="color: #8b91a0;">Please find the below information</p>
        <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12">
                        <p-timeline [value]="histroy" align="alternate">
                                <ng-template pTemplate="content" let-event>
                                        <p-card>
                                                <p style="text-align: center;"> {{event.activity}}
                                                        <br>
                                                        <span style="color: #8b91a0;">By {{event.activityBy}}</span>
                                                </p>
                                        </p-card>
                                        <br>
                                </ng-template>

                                <ng-template pTemplate="opposite" let-event><span
                                                style="color: #8b91a0;">{{event.activityDate |
                                                date:'MM-dd-yyyy'}}</span>

                                </ng-template>
                        </p-timeline>
                </div>
        </div>
</p-dialog>

















<p-dialog [showHeader]="false" [(visible)]="approveRejectPopup" [resizable]="false" [modal]="true" [responsive]="true"
        [style]="{width: '450px', minWidth: '150px'}" [minY]="70">
        <h5 style="margin-top: 20px">Are you sure you want to reject?</h5>


        <form [formGroup]="rejectResonForm">
                <div class="p-grid-12"></div>
                <div class="p-col-12 p-md-12 p-sm-12 p-fluid">
                        <label class="inputfields">Reason For Rejection<span class="mandatoryColor">*</span></label>

                        <textarea autocomplete="off" pInputTextarea rows="3" cols="40"
                                placeholder="Please mention the reason for rejecting the timesheet"
                                formControlName="rejectreason" maxlength="251"></textarea>

                        <span class="validationtest"
                                *ngIf="this.rejectResonForm.get('rejectreason').touched && this.rejectResonForm.get('rejectreason').invalid && this.rejectResonForm.get('rejectreason').errors.required">
                                Please enter reason
                        </span>

                        <span class="validationtest"
                                *ngIf="this.rejectResonForm.get('rejectreason').hasError('maxlength')">
                                Please enter 250 alphabets only
                        </span>
                </div>
        </form>
        <p-footer>
                <button type="button" pButton icon="pi pi-times" (click)="close()" label="Close"
                        class="ui-button-secondary"></button>
                <button type="button" pButton icon="pi pi-check" (click)="rejectReason()" label="Reject"></button>
        </p-footer>
</p-dialog>
<p-toast position="bottom-right" key="br"></p-toast>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
        <div class="loading-bar">
                <div>
                        <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                                <div class="laodersize">
                                        <img alt=""
                                                src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                                                class="loaderimg-height">
                                </div>
                        </div>
                </div>
        </div>
</ng-template>