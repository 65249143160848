<p-toast position="bottom-right" key="br"></p-toast>
<p-card><br>
    <div class="p-grid ">
        <form [formGroup]="csradminresetUsrPwd">
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-8 pswd">
                    <p-panel [toggleable]="false" [style]="{'margin-bottom':'20px'}">
                        <ng-template pTemplate="header">
                            <label class="inputfields"><strong>Change password</strong></label><span (click)="instructions()"><em
                                    pTooltip="Instructions" tooltipPosition="right" 
                                    class="pi pi-exclamation-circle Ttipos" style="cursor: pointer;"></em></span>
                        </ng-template>
                        <div class="pass">
                            <br>
                            <div class="p-grid p-fluid">
                                <div class="p-col-3 p-lg-3 p-fluid">
                                    <label class="inputfields">Email</label>
                                </div>
                                <div class="p-col-3 p-lg-3 p-fluid">

                                </div>
                                <div class="p-col-9 p-lg-6 p-fluid">
                                    <label >{{userName}}</label>
                                    <em class=" pi pi-envelope enev"></em>
                                </div>
                            </div>
                            <div class="p-grid ">
                                <div class="p-col-5 p-lg-6 p-fluid">
                                    <label class="inputfields">Old Password<label class="mandatoryColor">*</label></label>
                                </div>
                                <div class="p-col-7 p-lg-6 p-fluid">
                                    <div class=" p-inputgroup">
                                        <input autocomplete="off" type="password"  pInputText placeholder=""
                                            class=" p-inputtext p-widget" [type]="hide ? 'password' : 'text'"
                                            formControlName="currentPassword">
                                        <span class="hello">
                                            <em class="pi pi-eye-slash showEye eye" 
                                                (click)="myFunction(1)" *ngIf="hideIcon"></em>
                                            <em class="pi pi-eye showEye eye"
                                                (click)="myFunction1(1)" *ngIf="showIcon"></em></span>
                                        <br><br>
                                    </div>
                                    <span class="validationtest"
                                        *ngIf="csradminresetUsrPwd.controls.currentPassword.touched && csradminresetUsrPwd.controls.currentPassword.invalid && csradminresetUsrPwd.controls.currentPassword.errors.required">
                                        Please enter current password
                                    </span>
                                </div>
                            </div>
                            <div class="p-grid ">
                                <div class="p-col-5 p-lg-6 p-fluid">
                                    <label class="inputfields">New Password<label
                                            class="mandatoryColor">*</label></label>
                                </div>
                                <div class="p-col-7 p-lg-6 p-fluid">
                                    <div class=" p-inputgroup">
                                        <input pPassword autocomplete="off" type="password" autocomplete="off"
                                            maxlength=21 pInputText placeholder="" class=" p-inputtext p-widget"
                                            [type]="hide1 ? 'password' : 'text'"
                                            (input)="onSearchChange($event.target.value)" formControlName="password">

                                        <span class="hello"><em class="pi pi-eye-slash showEye eye"
                                               (click)="myFunction(2)"
                                                *ngIf="hideNewPWDIcon"></em>
                                            <em class="pi pi-eye showEye eye"
                                                (click)="myFunction1(2)" *ngIf="showNewPWDIcon"></em></span>
                                        <br><br>
                                    </div>
                                    <span class="validationtest newLine"
                                        *ngIf="csradminresetUsrPwd.controls.password.touched && csradminresetUsrPwd.controls.password.invalid && csradminresetUsrPwd.controls.password.errors.required">
                                        Please enter new password
                                    </span>
                                    <span class="validationtest"
                                        *ngIf="this.csradminresetUsrPwd.get('password').hasError('maxlength')">
                                        Please enter 20 alphabets only
                                    </span>
                                </div>
                            </div>
                            <div class="p-grid ">
                                <div class="p-col-5 p-lg-6 p-fluid">
                                    <label class="inputfields">Confirm New Password<label
                                            class="mandatoryColor">*</label></label>
                                </div>
                                <div class="p-col-7 p-lg-6 p-fluid">
                                    <div class=" p-inputgroup">
                                        <input pPassword autocomplete="off" type="password" autocomplete="off"
                                            maxlength=21 pInputText placeholder="" class=" p-inputtext p-widget"
                                            [type]="hide2? 'password' : 'text'" formControlName="conformPassword">
                                        <span class="hello"><em class="pi pi-eye-slash showEye eye"
                                                (click)="myFunction(3)"
                                                *ngIf="hideCfmPWDIcon"></em>
                                            <em class="pi pi-eye showEye eye"
                                                (click)="myFunction1(3)" *ngIf="showCfmPWDIcon"></em>
                                        </span><br><br>
                                    </div>
                                    <span class="validationtest"
                                        *ngIf="csradminresetUsrPwd.controls.conformPassword.touched && csradminresetUsrPwd.controls.conformPassword.invalid && csradminresetUsrPwd.controls.conformPassword.errors.required">
                                        Please enter confirm password
                                    </span>
                                </div>
                            </div>
                            <br>
                            <div class="p-grid">
                                <div class="p-col-5 p-fluid"></div>
                                <button pButton type="button" class="btn"
                                    label="Change Password" [disabled]='disable' icon="pi pi-key"
                                    (click)="resetPassword()"></button>
                            </div>
                        </div>
                    </p-panel>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                    <div class="p-grid siz">
                        <div class="p-col-12 p-fluid sic">
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</p-card>
<p-dialog header="Password Setup" [(visible)]="instruction" [modal]="true" [style]="{width: '30%' }" [baseZIndex]="1"
    [draggable]="false" [resizable]="false">
    <div class="p-grid  p-fluid">
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 tool">
            <label>At least 8 alphabets but not more than 20 alphabets</label><br><br>
            <label>At least one uppercase letter</label><br><br>
            <label>At least one or more lowercase letters</label><br><br>
            <label>At least one number</label><br><br>
            <label>At least one special character from !@#%^*=-+;.|:[]</label>
        </div>
    </div>
</p-dialog>