<p-card>

    <p-panel header="My Preferences" [toggleable]="false" [style]="{'margin-bottom':'25px'}">
        <form [formGroup]="userPrefernces">

            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <label class="inputfields">Preferences</label>
                    <p-dropdown [options]="preferenceValues" placeholder="Select Preferences" optionLabel="name"
                        [showClear]="true" formControlName="preference" (onChange)="getpreference($event)">
                    </p-dropdown>
                </div>

                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3" *ngIf="locations">
                    <label class="inputfields">Preferred Country </label>
                    <p-dropdown [options]="preCountry" placeholder="Select Preferred Country" optionLabel="label"
                        [showClear]="true" formControlName="precountry" (onChange)="getStateByUrl($event.value.code)">
                    </p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3" *ngIf="locations">
                    <label class="inputfields">Preferred State </label>
                    <p-multiSelect [options]="prestate" [showToggleAll]="false" formControlName="prestate"
                        placeholder="Select Preferred State" [panelStyle]="{minWidth:'12em'}" optionLabel="label"
                        [filter]='true'>
                    </p-multiSelect>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3" *ngIf="currency">
                    <label class="inputfields">Preferred Currency </label>
                    <p-dropdown [options]="preCurrency" placeholder="Select Preferred Currency" optionLabel="label"
                        [showClear]="true" formControlName="precurrency">
                    </p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3" *ngIf="notification">
                    <label class="inputfields">Preferred Notifications </label>
                    <p-dropdown [options]="preNotifications" placeholder="Select Preferred Notifications"
                        optionLabel="label" [showClear]="true" formControlName="prenotifications">
                    </p-dropdown>
                </div>
            </div>

            <h5></h5>
            <div class="p-grid p-fluid" *ngIf='button'>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                    <button pButton type="button" style="float: right;" icon='pi pi-save' label="Save" class="addhBtn" [disabled]="disable"
                        (click)="preferenceSave()"></button><br><br>
                </div>
            </div>
        </form>
    </p-panel>