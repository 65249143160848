<form [formGroup]="techReviewsListForm">
  <p-card>
    <p-panel>
      <p-header>
        <strong>Tech Reviews </strong><strong class="positionheader">Job Id : </strong> <span
          class="panelLabel">{{requId}}</span>
        <strong class="positionheader">Position Title
          : </strong><span class="panelLabel"> {{reqname}}</span>
        <em class="pi pi-arrow-left panelBackRoute" (click)="backNavigate()">
        </em>
      </p-header>
        <div class="p-grid  p-fluid">
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
            <label class="inputfields">Interview Type</label>
            <p-dropdown [options]="interviewMode" placeholder="Select Interview Type" optionLabel="label"
              formControlName="interviewType" [showClear]="true"></p-dropdown>
          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
            <label class="inputfields">From <label class="mandatoryColor"></label></label>
            <p-calendar id="popup" dateFormat="mm-dd-yy" placeholder="MM-DD-YYYY" [showIcon]="true"
              [showButtonBar]="true" formControlName="fromDate" (onSelect)='endDateValidation()'>
            </p-calendar>
            <p class="mandatoryColor">{{toErrorMessage}}</p>
          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
            <label class="inputfields">To <label class="mandatoryColor"></label></label>
            <p-calendar id="popup" dateFormat="mm-dd-yy" placeholder="MM-DD-YYYY" [showIcon]="true" [minDate]='minDate'
              [showButtonBar]="true" formControlName="toDate">
            </p-calendar>
            <p class="mandatoryColor">{{fromErrorMessage}}</p>
          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
            <label class="inputfields">Status</label>
            <p-dropdown [options]="statusData" placeholder="Select Status" optionLabel="label" formControlName="status"
              [showClear]="true"></p-dropdown>
          </div>
          <div class="p-col-12 p-md-12 p-sm-12 p-lg-3" *ngIf="(roleId === 1) || (roleId === 11)">
            <label class="inputfields">Interview Notification Status</label>
            <p-dropdown [options]="interviewNotifyStatus" placeholder="Select Interview Notification Status"
              optionLabel="name" formControlName="interviewNotificationStatus" [showClear]="true"></p-dropdown>
          </div>
        </div>
        <br>
        <div class="btnsCSS1">
          <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" class="addhBtn"></button>
          <button pButton type="button" icon='pi pi-search' label="Search" (click)="searchTechReviewData()"
            class="addhBtn"></button>
        </div><br><br>
      </p-panel>
  </p-card><br>
  <p-card class="pgpos">
    <div *ngIf="table">
      <div *ngIf="isUploading1" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <p-progressBar mode="indeterminate"></p-progressBar>
      </div>
      <p-table [columns]="cols"  [value]="techReviewData" selectionMode="single" [autoLayout]="true"
      dataKey="edit" [responsive]="true" sortMode="multiple">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th id="gridHeader" *ngFor="let col of columns; let i = index" (click)="iconToggle[i] = !iconToggle[i]"
              [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
              {{col.header}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-techData>
          <tr>
            <td class="textCenter" pTooltip="{{techData.name}}" tooltipPosition="top"><span
                class="ui-column-title"></span> {{techData.name}} </td>
                <td class="textCenter pointer" pTooltip="{{techData.email}}" tooltipPosition="top"
                (click)="navigateTo(techData.id)" (click)="emailToast()"  (click)="copyInputMessage(techData.email)" >
                <span class="ui-column-title"></span><a>{{techData.email}}</a></td>
            <td class="textCenter"><span class="ui-column-title"></span>{{techData.reviewedBy}}</td>
            <td class="textCenter pointer" #actualTarget pTooltip="{{techData.reviewerName}}" tooltipPosition="top"><a
                (click)="op.show(techData,actualTarget)" (click)="reviewDetails(techData)">{{techData.reviewerName}}</a>
            </td>
            <td class="textCenter"><span class="ui-column-title">
              </span>{{getInterView(techData.interviewType)}}</td>
            <td class="textCenter"> <span class="ui-column-title"></span>{{techData.interviewDate}} </td>
            <td *ngIf="(roleId === 1) ||(roleId === 3)||(roleId === 13)"><span class="ui-column-title pointer"></span>
              <a (click)="forwardedByDetails(techData)">{{techData.fbyName}}</a>
            </td>
            <td *ngIf="(roleId === 1) || (roleId === 11)"> {{techData.cname}} </td>
            <td *ngIf="roleId === 1 "> {{techData.vname}}</td>
            <td><span class="ui-column-title"></span>
              <p-badge [value]=" getstatusTable(techData.reviewState)"
                [ngClass]="techData.reviewState ==='A'?'green': 'evalution'">
              </p-badge>
            </td>
            <td class="textCenter"><span class="ui-column-title"></span><a>
                <em (click)="actionpopup.toggle($event); toggle(items, $event, techData)"
                  class="pi pi-ellipsis-v pointer"></em>
              </a></td>
              <td *ngIf="roleId === 1" class="textCenter"><span class="ui-column-title"></span>
                <a (click)="intStausCsr(techData)"
                  [ngClass]="techData.interviewNotificationStatus === 'CSRPending'?'my-class1':'my-class3'">
                  {{techData.interviewNotificationStatus}}</a>
              </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr *ngIf="techReviewGridData?.length==0">
            <td *ngIf='!(roleId === 1 || roleId === 16 || roleId === 15)' colspan="11"
              class="mandatoryColor aligncenter">
              No Records
            </td>
            <td *ngIf='roleId === 1' colspan="13" class="mandatoryColor aligncenter">
              No Records
            </td>
            <td *ngIf='roleId === 16 || roleId === 15' colspan="10" class="mandatoryColor aligncenter">
              No Records
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="p-grid ui-fluid">
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
          <h3 class="norecorddata">Total records : {{this.totalRecords1}}</h3>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
          <p-paginator [rows]="10" [totalRecords]="totalRecords1" [rowsPerPageOptions]="[10,20,30]"
            (onPageChange)="paginate($event)"></p-paginator>
        </div>
      </div>
    </div>
    <p-card *ngIf="records">
      <div class="norecordImgsize">
        <div *ngIf="records" class="aligncenter">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png"
            class="norecordimg">
          <br><em>Sorry, we are not able to find any tech reviews</em><br>
        </div>
      </div>
    </p-card>
  </p-card>
</form>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center laoderheight ">
        <div class="laodersize">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
            class="loaderimg-height">
        </div>
      </div>
    </div>
  </div>
</ng-template>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
<!---Dialog box  for Tech Review Process---->
<form [formGroup]="popTechReviewsForm">
  <p-dialog header=" Tech Review Process " class="dialogBox" [(visible)]="display123" [modal]="true"
    [style]="{width: '60%'}" [baseZIndex]="1" [draggable]="false" [resizable]="false">
    <div *ngIf="consultantData" class="mandatoryColor"> Consultant has to Accept the interview.</div>
    <div class="modalStyle">
      <h2 class="headercolor">Consultant Details</h2>
      <div class="p-grid ui-fluid">
        <div class="p-col-12 p-md-4 p-sm-12">
          <label class="inputfields">Name</label>
          <input autocomplete="off" type="text" size="30" pInputText placeholder="Name" readonly maxlength="30"
            formControlName="name">
        </div>
        <div class="p-col-12 p-md-4 p-sm-12">
          <label class="inputfields">Title</label>
          <input autocomplete="off" type="text" size="30" pInputText placeholder="Title" readonly maxlength="30"
            formControlName="title">
        </div>
        <div class="p-col-12 p-md-4 p-sm-12">
          <label class="inputfields">Email</label>
          <input autocomplete="off" type="text" size="30" pInputText placeholder="Email" readonly maxlength="60"
            formControlName="email">
        </div>
      </div>
      <div class="p-grid ui-fluid">
        <div class="p-col-12 p-md-4 p-sm-12">
          <label class="inputfields">Mobile</label>
          <input autocomplete="off" type="text" size="30" pInputText placeholder="Mobile" readonly
            formControlName="mobile">
        </div>
        <div class="p-col-12 p-md-4 p-sm-12">
          <label class="inputfields">Scheduled Date</label>
          <input autocomplete="off" type="text" size="30" pInputText placeholder="Scheduled Date" readonly
            formControlName="date">
        </div>
        <div class="p-col-12 p-md-4 p-sm-12">
          <label class="inputfields">Status</label>
          <p-dropdown [options]="status" formControlName="status1" placeholder="Select Status" optionLabel="name"
            [showClear]="true">
          </p-dropdown>
        </div>
      </div>
      <div class="p-grid ui-fluid">
        <div class="p-col-12 p-md-12 p-sm-12" *ngIf="skillsDetails">
          <label class="inputfields">Skill Details </label>
          <textarea autocomplete="off" placeholder="Skill Details" readonly pInputTextarea
            formControlName="skillDetails1" [rows]="2" [cols]="30"></textarea>
        </div>
      </div>
      <h2 class="headercolor" *ngIf="reviews">Rating Details(**Rating between 1(min) to 10(max))</h2>
      <div class="p-grid ui-fluid" *ngIf="skills">
        <div class="p-col-12 p-md-4 p-sm-12">
          <label class="inputfields">Technical Skills<label class="mandatoryColor">*</label></label>
          <input autocomplete="off" type="text" pKeyFilter="num" size="2" maxlength="3" pInputText
            placeholder="Technical Skills" formControlName="techniicalSkills">
          <span class="validationtest"
            *ngIf="savebutton && this.popTechReviewsForm.get('techniicalSkills').touched && this.popTechReviewsForm.get('techniicalSkills').invalid && this.popTechReviewsForm.get('techniicalSkills').errors.required">
            Please enter technical skills
          </span>
        </div>
        <div class="p-col-12 p-md-4 p-sm-12">
          <label class="inputfields">Domain Skills <label class="mandatoryColor"> *</label></label>
          <input autocomplete="off" type="text" pKeyFilter="num" size="2" maxlength="3" pInputText
            placeholder="Domain Skills" formControlName="domainSkills">
          <span class="validationtest"
            *ngIf="savebutton && this.popTechReviewsForm.get('domainSkills').touched && this.popTechReviewsForm.get('domainSkills').invalid && this.popTechReviewsForm.get('domainSkills').errors.required">
            Please enter domain skills
          </span>
        </div>
        <div class="p-col-12 p-md-4 p-sm-12">
          <label class="inputfields">Communication Skills<label class="mandatoryColor">*</label></label>
          <input autocomplete="off" type="text" size="2" pKeyFilter="num" maxlength="3" pInputText
            placeholder="Communication Skills" formControlName="communicationSkills">
          <span class="validationtest"
            *ngIf="savebutton && this.popTechReviewsForm.get('communicationSkills').touched && this.popTechReviewsForm.get('communicationSkills').invalid && this.popTechReviewsForm.get('communicationSkills').errors.required">
            Please enter communication skills
          </span>
        </div>
      </div>
      <div class="p-grid ui-fluid">
        <div class="p-col-12 p-md-12 p-sm-12" *ngIf="location">
          <label class="inputfields">Location</label>
          <textarea autocomplete="off" placeholder="Location" readonly pInputTextarea formControlName="location"
            [rows]="2" [cols]="30"></textarea>
        </div>
      </div>
      <div class="p-grid ui-fluid">
        <div class="p-col-12 p-md-12 p-sm-12" *ngIf="notes">
          <label class="inputfields">Notes</label>
          <textarea autocomplete="off" placeholder="Note" readonly pInputTextarea formControlName="notes" [rows]="2"
            [cols]="30"></textarea>
        </div>
      </div>
      <h1></h1>
      <div class="p-grid ui-fluid">
        <div class="p-col-12 p-md-12 p-sm-12">
          <label class="inputfields">Comments<label class="mandatoryColor">*</label></label>
          <textarea autocomplete="off" placeholder="Comments" pInputTextarea formControlName="comments" [rows]="2"
            [cols]="30"></textarea>
          <span class="validationtest"
            *ngIf="(rejectbutton || savebutton) && this.popTechReviewsForm.get('comments').touched && this.popTechReviewsForm.get('comments').invalid && this.popTechReviewsForm.get('comments').errors.required">
            Please enter comments
          </span>
        </div>
      </div>
      <div *ngIf="onlineExam">
        <h2>Online Exam Details:</h2>
        <div class="p-grid ui-fluid">
          <div class="p-col-12 p-md-4 p-sm-12">
            <label class="inputfields">Exam Performed On</label>
            <input autocomplete="off" type="text" size="30" maxlength="8" readonly pInputText
              placeholder="Exam Performed On " formControlName="examPerformance">
          </div>
          <div class="p-col-12 p-md-4 p-sm-12">
            <label class="inputfields">Exam Result</label>
            <div *ngFor="let item of skilsdata | keyvalue">
              {{item.key}}: <a [ngStyle]="{'color':item.value===0?'red':'green'}">{{item.value }}%</a>
            </div>
          </div>
          <div class="p-col-12 p-md-4 p-sm-12">
            <label class="inputfields">Average Result </label>
            <div>total Result : <a class="mandatoryColor">{{this.rowdata.totalResult}}</a></div>
          </div>
        </div>
      </div>
    </div>
    <div class="btnsCSS" *ngIf="savebutton">
      <button pButton type="submit" icon='pi pi-save' label="Save" (click)="addData()" class="sub-btn"></button>
    </div><br><br>
    <div class="p-grid ui-fluid" *ngIf="rejectbutton">
      <div class="p-col-2 p-md-2 p-sm-12">
        <button pButton type="button" icon='pi pi-times' label="Rejected" (click)='rejectbutton1()'
          class="rej-btn"></button>
      </div>
      <div class="p-col-8 p-md-8 p-sm-12"></div>
      <div class="p-col-2 p-md-2 p-sm-12">
        <button pButton type="button" icon='pi pi-check' label="ShortListed" value="ShortListed" (click)='shortListed()'
          class="buttonWidth rej-btn"></button>
      </div>
    </div>
  </p-dialog>
  <!---Dialog box  for Interview Notification Status---->
  <p-dialog class="dialogBox" header="Interview Notification Status" [(visible)]="displayConsultantDailog"
    [style]="{width: '30vw'}" [draggable]="false" [modal]="true" [resizable]="false">
    <label>Are you attending the Interview?</label>
    <br><br>
    <div class="p-grid ui-fluid">
      <div class="p-col-12">
        <p-radioButton name="tecStatus" value="Accepted" label="Yes" formControlName="tecStatus" class="radio-spac"
          (onClick)='select($event)' inputId="Yes"></p-radioButton>
        <p-radioButton name="tecStatus" value="Rejected" label="No" formControlName="tecStatus"
          (onClick)='select($event)' inputId="No"></p-radioButton>
      </div>
    </div>
    <br>
    <div class="p-grid ui-fluid">
      <div class="p-col-12" *ngIf='commentData'>
        <textarea autocomplete="off" pInputTextarea [rows]="2" [cols]="30" placeholder="Comments"
          formControlName="commentsConsulForm"></textarea>
        <span class="validationtest"
          *ngIf="this.techReviewsListForm.get('commentsConsulForm').touched && this.techReviewsListForm.get('commentsConsulForm').invalid && this.techReviewsListForm.get('commentsConsulForm').errors.required">
          Please enter comments
        </span>
      </div>
    </div>
    <div class="btnsCSS">
      <button pButton type="button" icon='pi pi-check-circle' label="Submit" class="sub-btn"
        (click)="interviewStatusConsul()"></button><br><br>
    </div>
  </p-dialog>
  <!---Dialog box  for Forwarded By Details---->
  <p-dialog class="dialogBox" header="Forwarded By Details" [(visible)]="forwardedByDetailsDisplay" [modal]="true"
    [style]="{width: '30%'}" [baseZIndex]="1" [draggable]="false" [resizable]="false">
    <div class="p-grid ui-fluid">
      <div class="p-col-1 p-md-1 p-sm-12"></div>
      <div class="p-col-10 p-md-10 p-sm-12">
        <label class="inputfields">Name</label>
        <input autocomplete="off" type="text" readonly pInputText [value]="forwardedByName">
      </div>
      <div class="p-col-1 p-md-1 p-sm-12"></div>
    </div>
    <div class="p-grid ui-fluid">
      <div class="p-col-1 p-md-1 p-sm-12"></div>
      <div class="p-col-10 p-md-10 p-sm-12">
        <label class="inputfields">Email</label>
        <input autocomplete="off" type="text" maxlength="60" readonly pInputText [value]="emailId">
      </div>
      <div class="p-col-1 p-md-1 p-sm-12"></div>
    </div>
    <div class="p-grid ui-fluid">
      <div class="p-col-1 p-md-1 p-sm-12"></div>
      <div class="p-col-10 p-md-10 p-sm-12">
        <label class="inputfields">Phone</label>
        <input autocomplete="off" type="text" maxlength="20" readonly pInputText [value]="phoneNumber">
      </div>
      <div class="p-col-1 p-md-1 p-sm-12"></div>
    </div>
  </p-dialog>
  <p-dialog class="dialogBox" [(visible)]="showDialog" header="Ratings" [modal]="true"
    [style]="{width: '60%','border-radius': '75px;'}" [baseZIndex]="1" [draggable]="false" [resizable]="false">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
        <label class="inputfields"><strong>Reviewer Name : </strong> </label> <strong><span class="panelLabel">
            {{reviewerName}}</span></strong>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
        <span class="rev-date"><strong>Review Date : </strong> </span><strong><span
            class="panelLabel">{{reviewDate}}</span></strong>
      </div>
    </div>
    <br>
    <div class="p-grid p-fluid p-justify-center">
      <div class="p-col-12 p-md-2 p-sm-10 p-lg-2">
        <label class="inputfields">Architecture </label>
        <p-rating [cancel]="false" formControlName="architecture" readonly="true"></p-rating>
      </div>
      <div class="p-col-12 p-md-2 p-sm-10 p-lg-2">
        <label class="inputfields">Coding </label>
        <p-rating [cancel]="false" formControlName="coding" readonly="true"></p-rating>
      </div>
      <div class="p-col-12 p-md-2 p-sm-10 p-lg-2">
        <label class="inputfields">Testing </label>
        <p-rating [cancel]="false" formControlName="testing" readonly="true"></p-rating>
      </div>
      <div class="p-col-12 p-md-2 p-sm-10 p-lg-2">
        <label class="inputfields">Business </label>
        <p-rating [cancel]="false" formControlName="business" readonly="true"></p-rating>
      </div>
      <div class="p-col-12 p-md-2 p-sm-10 p-lg-2">
        <label class="inputfields">Ownership </label>
        <p-rating [cancel]="false" formControlName="ownershsip" readonly="true"></p-rating>
      </div>
    </div><br>
    <div class="p-grid p-fluid p-justify-center">
      <div class="p-col-12 p-md-2 p-sm-10 p-lg-2">
        <label class="inputfields">Team Management </label>
        <p-rating [cancel]="false" formControlName="management" readonly="true"></p-rating>
      </div>
      <div class="p-col-3 p-md-3 p-sm-12 p-lg-2">
        <label class="inputfields">Analytical </label>
        <p-rating [cancel]="false" formControlName="analytical" readonly="true"></p-rating>
      </div>
      <div class="p-col-3 p-md-3 p-sm-12 p-lg-2">
        <label class="inputfields">Communication </label>
        <p-rating [cancel]="false" formControlName="communication" readonly="true"></p-rating>
      </div>
      <div class="p-col-3 p-md-3 p-sm-12 p-lg-2">
        <label class="inputfields">Documentation </label>
        <p-rating [cancel]="false" formControlName="documentation" readonly="true"></p-rating>
      </div>
      <div class="p-col-3 p-md-3 p-sm-12 p-lg-2">
        <label class="inputfields">Position </label>
        <p-rating [cancel]="false" formControlName="position" readonly="true"></p-rating>
      </div>
    </div><br><br>
    <div class="p-grid p-fluid">
      <div class="p-col-3 p-md-3 p-sm-12 p-lg-4">
      </div>
      <div class="p-col-3 p-md-3 p-sm-12 p-lg-2">
        <label class="inputfields headercolor">
          <h5><strong>Average Rating </strong></h5>
        </label>
      </div>
      <div class="p-col-3 p-md-3 p-sm-12 p-lg-2">
        <p-rating [cancel]="false" formControlName="average" readonly="true"></p-rating>
      </div>
    </div>
    <div class="p-grid p-fluid">
      <div class="p-col-3 p-md-3 p-sm-12 p-lg-12">
        <label class="inputfields" id='csslabel'>Comments</label>
        <textarea autocomplete="off" [rows]="2" [cols]="30" placeholder="Comments" pInputTextarea
          formControlName="requirementsComments" maxlength="300"></textarea>
      </div>
    </div>
  </p-dialog>
  <p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '450px'}" [dismissable]="true">
    <label class="inputfields">Reviewer</label>
    <ng-template pTemplate>
      <p-table [columns]="cols" [value]="entries" selectionMode="single" [(selection)]="selectedProduct" [autoLayout]="true"
        [paginator]="false" [rows]="5">
        <ng-template pTemplate="header">
          <tr>
            <th id="gridHeader">Mail Id</th>
            <th id="gridHeader">Mobile Number</th>
            <th id="gridHeader">Office Number</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
          <tr>
            <td>{{data.reviewerEmail}}</td>
            <td>{{data.reviewerMobilePhone}}</td>
            <td>{{data.reviewerOfficePhone}}</td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-overlayPanel>
</form>
<p-toast position="bottom-right" key="br"></p-toast>