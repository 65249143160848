import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-tech-evalution-techreviews',
  templateUrl: './tech-evalution-techreviews.component.html',
  styleUrls: ['./tech-evalution-techreviews.component.scss'],
  providers: [MessageService, DatePipe],
})
export class TechEvalutionTechreviewsComponent implements OnInit {
  reviewSearchForm: FormGroup;
  interviewTypeList: any = [];
  statusList: any = [];
  interviewTypeLabel = {};
  gridData: any = [];
  rows: any = 10;
  page: any = 1;
  pageCount: any;
  reviewData = {};
  statusTable = {};
  items: any = [];
  cols = [
    { field: 'jobTitle', header: 'Job Title', width: '12%' },
    { field: 'username', header: 'Candidate Name', width: '12%' },
    { field: 'attributes', header: 'Interview Mode', width: '10%' },
    { field: 'interviewDate', header: 'Interview Date & Time', width: '10%' },
    { field: 'assignDate', header: 'Assign Date', width: '7%' },
    { field: 'reviewDate', header: 'Review Date', width: '7%' },
    { field: 'status', header: 'Status', width: '8%' },
    { field: 'actions', header: 'Action', width: '5%' }
  ];
  first: any;
  userData: any;
  totalRecords: number;
  loader: boolean;
  table: boolean;
  records: boolean;
  rowData: any;
  resumeRes: any;
  isUploading = false;
  assignDate: any;
  reviewdate: any;
  dataaa: any;
  parentReqId: any;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private datepipe: DatePipe, private apisService: ApisService, private router: Router, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Tech Reviews', routerLink: '/tech-evaltion-tech-reviews' },
    ]);
  }
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchData();
    }
  }
  ngOnInit() {
    this.userData = JSON.parse(this.apisService.decodeData());
    this.reviewSearchForm = this.fb.group({
      name: [''],
      startDate: [''],
      endDate: [''],
      interviewType: [''],
      status: ['']
    });
    this.getInterviewType();
    this.gridTable();
  }
  getInterviewType() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.getInterviewType).subscribe((res: any) => {
      this.interviewTypeList = res.data;
      this.interviewTypeList.forEach((element: { code: string | number; label: any; }) => {
        this.interviewTypeLabel[element.code] = element.label;
      });
      this.getInterviewStatus();
    });
  }
  getInterviewStatus() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.interviewStatus).subscribe((res: any) => {
      this.statusList = res.data;
      this.statusList.forEach((element: { code: string | number; label: any; }) => {
        this.statusTable[element.code] = element.label;
      });
    });
  }
  gridTable() {
    this.loader = true;
    const data = this.page + '/' + this.rows + '/' + this.userData.userId;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.techReviews + data).subscribe((res: any) => {
      this.gridData = res.data;
      this.totalRecords = res.count;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.loader = false;
    });
  }
  paginate(event: { first: any; rows: any; page: number; pageCount: any; }) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.searchData();
  }
  searchData() {
    this.loader = true;
    // tslint:disable-next-line: max-line-length
    const cndName = this.reviewSearchForm.controls.name.value === undefined ? '' : this.reviewSearchForm.controls.name.value === null ? '' : this.reviewSearchForm.controls.name.value === '' ? '' : this.reviewSearchForm.controls.name.value;
    // tslint:disable-next-line:max-line-length
    const inretviewMode = this.reviewSearchForm.controls.interviewType.value === undefined ? '' : this.reviewSearchForm.controls.interviewType.value === null ? '' : this.reviewSearchForm.controls.interviewType.value === '' ? '' : this.reviewSearchForm.controls.interviewType.value.code;
    const assignDate = this.reviewSearchForm.controls.startDate.value;
    const asgDate = assignDate === undefined || assignDate === null || assignDate === '' ? '' : this.datepipe.transform(assignDate, 'MM-dd-yyyy');
    const reviewdate = this.reviewSearchForm.controls.endDate.value;
    const revDate = reviewdate === undefined || reviewdate === '' || reviewdate === null ? '' : this.datepipe.transform(reviewdate, 'MM-dd-yyyy');
    // tslint:disable-next-line:max-line-length
    const status = this.reviewSearchForm.controls.status.value === undefined ? '' : this.reviewSearchForm.controls.status.value === null ? '' : this.reviewSearchForm.controls.status.value === '' ? '' : this.reviewSearchForm.controls.status.value.code;
    const data = this.page + '/' + this.rows + '/' + this.userData.userId;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.techReviews + data + '?candidateName=' + cndName + '&interviewMode=' + inretviewMode + '&assignedDate=' + asgDate + '&reviewsDate=' + revDate + '&status=' + status).subscribe((res: any) => {
      this.gridData = res.data;
      this.totalRecords = res.count;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.loader = false;
    });
  }
  toggle(menu: any, event: any, rowData: any) {
    this.parentReqId = rowData.parentReqId;
    this.rowData = rowData;
    if (this.rowData.status === 'E') {
      this.items = [
        // tslint:disable-next-line: no-shadowed-variable
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        { label: 'Show Review', icon: 'pi pi-fw pi-star-o', command: (event: any, i: any) => { this.ratings(); }, }
      ];
    } else if (this.rowData.status === 'A') {
      this.items = [
        { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: () => { this.downloadFileapi(); }, },
        // tslint:disable-next-line:no-shadowed-variable
        { label: 'Review', icon: 'pi pi-fw pi-star-o', command: (event: any, i: any) => { this.ratings(); }, },
      ];
    }
  }
  ratings() {
    const data = this.rowData.id;
    this.router.navigate(['/add-tech-review', this.rowData.id], {
      queryParams: {
        rowId: data,
        jobId: this.rowData.requirementId,
        positionTitle: this.rowData.positionName,
        porfileid: this.rowData.reqProfileId,
        parentReqId: this.parentReqId,
        createdbyaccId: this.rowData.createdByAccId
      },
    });
  }


  downloadFileapi() {
    this.dataaa = this.rowData.resumeId;
    this.isUploading = true;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.downloadedattachment + '/' + this.dataaa).subscribe((res) => {
      this.resumeRes = res;
      this.isUploading = false;
      window.location.assign(this.resumeRes.downloadUrl);
    });
  }
  reset() {
    this.reviewSearchForm.reset();
    this.gridTable();
  }
}
