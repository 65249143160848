import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { DatePipe } from '@angular/common';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  providers: [DatePipe, MessageService],
})
export class CustomerComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  constructor(private router: Router, private formBuilder: FormBuilder, private breadcrumbService: BreadcrumbService, private apisService: ApisService) {
    this.breadcrumbService.setItems([
      { label: 'Customers', routerLink: '/customer' }
    ]);
  }
  customerScreen: FormGroup ;
  loader: boolean;
  customerGrid: any;
  customerId: any;
  AcctRowID: any;
  totalRecords: any;
  accId: any;
  rows: any = 10;
  page: any = 1;
  cols = [
    { field: 'id', header: '#' },
    { field: 'cusName', header: 'Customer Name' },
    { field: 'website', header: 'Corporate Website' },
    { field: 'lkIndustryType', header: 'Industry'},
    { field: 'action', header: 'Action' },
  ];
  table = true;
  records: boolean;
  pageCount: any;
  first: any;
  userDetails: any;
  userAccId: any;
  customerName: any;
  items: ({
    label: string; icon: string;
    // tslint:disable-next-line:no-shadowed-variable
    command: (event: any, i: any) => void;
  } | {
    label: string; icon: string;
    // tslint:disable-next-line:no-shadowed-variable
    command?: undefined;
  })[];
  industryOptions: any = [];
  getIndustryTable = {};
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchData();
    }
  }
  ngOnInit(): void {
    this.customerScreen = this.formBuilder.group({
      customerName: [''],
      customerIndustry: [''],

    });
    this.userDetails = JSON.parse(this.apisService.decodeData());
    this.userAccId = this.userDetails.accId;
    this.gridData();
    this.industryDropdown();
}
// It is used to get industry dropdown values
industryDropdown() {
  // tslint:disable-next-line: deprecation
  this.apisService.getApi(environment.lookupsData + 'ALIT').subscribe((res: any) => {
    this.industryOptions = res.data;
    this.industryOptions.forEach(element => {
      this.getIndustryTable[element.code] = element.label;
    });
  });
}
// It is used to get customer grid data
gridData() {
  this.loader = true;
  const pageNo = this.page;
  const pageCount = this.rows;
  const data = pageNo + '/' + pageCount;
  const actId = JSON.parse(this.apisService.decodeData());
  this.apisService.getApi(environment.getCustomerList + data + '/' + this.userAccId).subscribe((res: any) => {
      this.customerGrid = res.data.records;
      this.AcctRowID = this.customerGrid.accId;
      this.totalRecords = res.data.count;
      this.loader = false;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
}
// pagination
paginate(event) {
  let i = 1;
  this.first = event.first;
  this.rows = event.rows;
  this.page = event.page + i++;
  this.pageCount = event.pageCount;
  this.gridData();
}

toggle(menu, event, rowData) {
  this.items = [
      {
        label: 'Details',
        icon: 'pi pi-info-circle',

        // tslint:disable-next-line:no-shadowed-variable
        command: (event, i) => {
          this.goto(rowData);
        },
      },
    ];

    }
// Method to search for a particular record in customer table
searchData() {
  this.loader = true;
  const actId = JSON.parse(this.apisService.decodeData());
  const customerName =  this.customerScreen.controls.customerName.value === null  ? ''  : this.customerScreen.controls.customerName.value;
  const industry = this.customerScreen.controls.customerIndustry.value === null ||  this.customerScreen.controls.customerIndustry.value === '' ||  this.customerScreen.controls.customerIndustry.value === undefined  ? ''  : this.customerScreen.controls.customerIndustry.value;
  const data =  this.page + '/' +  this.rows + '/' +  actId.accId + '?cusName=' +  customerName + '&industry=' +  industry;
  this.apisService.getApi(environment.getCustomerList + data).subscribe((res) => {
      this.loader = false;
      this.customerGrid = res.data.records;
      this.totalRecords =res.data.count;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
}
// It is used ot navigate to customer details screen
goto(data) {
  this.router.navigate(['/customerdetails'], {
  queryParams: {
    customerID: data.accId,
    custName: data.cusName
  }
});
}
reset(){
  this.customerScreen.reset();
  this.gridData();
}
goToLink(url: string) {
  const addressLink = url.includes('http') ? url : `http://${url}`;
  window.open(addressLink, '_blank');
}
}
