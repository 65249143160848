import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-vendor-tier-definition',
  templateUrl: './vendor-tier-definition.component.html',
  styleUrls: ['./vendor-tier-definition.component.scss'],
  providers: [DatePipe, MessageService],
})
export class VendorTierDefinitionComponent implements OnInit {
  tierdefinitionform: FormGroup;
  // It is used for the displaying the table header details
  cols = [
    { field: 'tierName', header: 'Tier', width: '2%' },
    { field: 'discount', header: 'Discount(%)', width: '2%' },
    { field: 'daysDelay', header: 'Days Delay', width: '2%' },
    { field: 'status', header: 'Status', width: '2%' },
    { field: 'vendorCount', header: 'Vendor Count', width: '3%' },
    { field: '', header: 'Action', width: '1%' },
  ];
  customerId: any;
  custmrId: any;
  grid: any;
  tierDefinition: any;
  display: any;
  status1: any;
  tier: any;
  tiersTypes: any;
  tierExit: any;
  Tierupdate: any;
  userId: any;
  status2: { name: string; value: string }[];
  showStatusDropDown = false;
  addBtn: boolean;
  updateBtn: boolean;
  tierHeader: string;
  display1: boolean;
  display3 = true;
  data1: boolean;
  displaydata1: boolean;
  display2: boolean;
  handleTierDrop: any;
  iconToggle = [];
  disable = false;
  loading = false;
  buttonHandle = false;
  accId: any;
  accDetails: any;
  gridData: any;
  tierId: any;
  stausData: any;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private apisService: ApisService, private messageService: MessageService, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Tiering Definition', routerLink: '/VendorDefinition' },
    ]);
    this.customerId = Number(this.route.snapshot.paramMap.get('id'));
    sessionStorage.setItem('cumId', this.customerId);
  }
  ngOnInit() {
    this.tierdefinitionform = this.fb.group({
      status: ['', Validators.required],
      discount: ['', Validators.required],
      days: ['', Validators.required],
      tiers: ['', Validators.required],
    });
    this.customerId = JSON.parse(sessionStorage.getItem('loginData')).orgId;
    this.userId = JSON.parse(sessionStorage.getItem('loginData')).userId;
    this.getTiergridData();
    this.apisService.getApi(environment.tiersLookup).subscribe((res) => {
      this.tier = res.data;
    });
    this.apisService.getApi(environment.lookupsData + 'RLS').subscribe((res) => {
      this.stausData = res.data;
    });
  }
  // It is used for the displaying the Grid Data
  getTiergridData() {
    this.loading = true;
    this.accDetails = JSON.parse(this.apisService.decodeData());
    this.apisService.getApi(environment.tiers + '/' + this.accDetails.accId).subscribe((res) => {
      this.gridData = res.data;
      if (this.gridData.length >= 5) {
        this.buttonHandle = true;
      } else {
        this.buttonHandle = false;
      }
      this.loading = false;
    });
  }
  getStatusTable(data) {
    const status = data === null ? '' : this.stausData.find((element) => element.code === data).label;
    return status;
  }
  // In this method is used from open the both add and update dialog boxs
  addData(data, value) {
    if (value === 'editValue') {
      this.displaydata1 = false;
      this.tierId = data.id;
      this.showStatusDropDown = true;
      this.tierHeader = 'Update Tier Definition';
      this.tierdefinitionform.controls.tiers.patchValue(this.tier.filter((x) => x.vendorType === data.tierName)[0]);
      this.tierdefinitionform.controls.discount.patchValue(data.discount);
      this.tierdefinitionform.controls.days.patchValue(data.daysDelay);
      this.tierdefinitionform.controls.status.patchValue(this.stausData.filter((x) => x.code === data.status)[0]);
      this.display = true;
      this.addBtn = false;
      this.updateBtn = true;
      this.tierdefinitionform.controls.tiers.disable();
    } else {
      this.tierdefinitionform.reset();
      this.displaydata1 = false;
      this.display = true;
      this.updateBtn = false;
      this.showStatusDropDown = false;
      this.tierHeader = 'Add Tier Definition';
      this.tierdefinitionform.reset();
      this.addBtn = true;
      this.tierdefinitionform.controls.tiers.enable();
    }
  }
  // It is used for the adding the Add Tier Definition, keep the validations
  addTiersData() {
    this.tierdefinitionform.controls.status.disable();
    if (this.tierdefinitionform.invalid) {
      this.tierdefinitionform.controls.tiers.markAsTouched();
      this.tierdefinitionform.controls.days.markAsTouched();
      this.tierdefinitionform.controls.discount.markAsTouched();
    } else {
      const parms = {
        accId: this.accDetails.accId,
        name: this.tierdefinitionform.controls.tiers.value.vendorType,
        discount: Number(this.tierdefinitionform.controls.discount.value),
        daysDelay: Number(this.tierdefinitionform.controls.days.value),
      };
      this.disable = true;
      this.apisService.postApi(environment.addTiers, parms).subscribe((res) => {
        this.Tierupdate = res;
        this.disable = false;
        this.display = false;
        this.getTiergridData();
      });
    }
  }
  // It is used for based on the selected tier get the discount ,no.of.days delay and also binding that data
  tierExistorNot(event) {
    this.display1 = false;
    this.apisService.getApi(environment.checkTiers + event.value.vendorType).subscribe((res) => {
      this.tierExit = res.data;
      if (this.tierExit.isTierExisted === true) {
        this.displaydata1 = true;
        setTimeout(() => {
          this.displaydata1 = false;
        }, 200);
        this.tierdefinitionform.controls.tiers.reset();
        this.tierdefinitionform.controls.days.reset();
        this.tierdefinitionform.controls.discount.reset();
      } else {
        this.displaydata1 = false;
        this.tierdefinitionform.get('days').patchValue(event.value.days_delay);
        this.tierdefinitionform.get('discount').patchValue(event.value.discount);
      }
    });
  }
  // It is used for updating the Update Vendor Tiers,keep the validations
  updateTiersData() {
    this.tierdefinitionform.controls.status.enable();
    if (this.tierdefinitionform.invalid) {
      this.tierdefinitionform.controls.tiers.markAsTouched();
      this.tierdefinitionform.controls.days.markAsTouched();
      this.tierdefinitionform.controls.discount.markAsTouched();
      this.tierdefinitionform.controls.status.markAsTouched();
    } else {
      const data = {
        accId: this.accDetails.accId,
        name: this.tierdefinitionform.controls.tiers.value.vendorType,
        status: this.tierdefinitionform.controls.status.value.code,
        discount: Number(this.tierdefinitionform.controls.discount.value),
        daysDelay: Number(this.tierdefinitionform.controls.days.value),
      };
      this.disable = true;
      this.apisService.putApi(environment.tierUpdate + '/' + this.tierId, data).subscribe((res) => {
        this.Tierupdate = res;
        this.disable = false;
        if (this.Tierupdate.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.Tierupdate.message, });
          this.display = false;
          this.getTiergridData();
        } else if (this.Tierupdate.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.Tierupdate.failed, });
          this.display = true;
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.Tierupdate.error, });
          this.display = true;
        }
      });
    }
  }
  // It is used for the reset the form values
  addTiersDataReset() {
    this.tierdefinitionform.reset();
  }
}
