<!-- top bar -->
<div class="scroll">
  <nav class="navbar navbar-expand-lg navbar-dark sticky-top nav bg">
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
      <a class="navbar-brand"><img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Servicesbay Logo SVG.svg"
          class="innerimg"></a>
      <button class="pi pi-arrow-left pointer addhbacktn" id="backToStyle" pButton type="button"
        style="width:auto;" label="Back to Login" (click)="goto()"></button>
    </div>
  </nav>
  <br>
  <!-- customer -->
  <div class="p-grid p-justify-center cardstyle" style="position: relative; top: 160px;">
    <div class="p-col-8 p-md-8 p-sm-12 ">
      <div class="p-grid  p-fluid">
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4" style="text-align: center;">
          <div class="cardstyle">
            <p-card>
              <a><img alt="" style="height: 100px;" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Customer.svg"></a>
              <div style="position: relative;top: 2px;">
                <h4>Customer</h4>
                <p>A company which is having requirements and needs employees to the full fill their requirements.
                </p>
                <button pButton type="button" style="width: auto; margin-bottom:8px;cursor: pointer;"
                  label="Register as Customer" (click)="navigateToCustomerRegisterForm()"></button>
              </div>
            </p-card>
          </div>
        </div>
        <!-- Vensor -->
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4" style="text-align: center;">
          <div class="cardstyle">
            <p-card>
              <a><img alt="" style="height: 100px;" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Vendor.svg"></a>
              <div style="position: relative;top: 2px;">
                <h4>Vendor</h4>
                <p>A company that can provide employees to the customer company who is having requirements.
                </p>
                <button pButton type="button" style="width: auto; margin-bottom:8px;cursor: pointer;"
                  label="Register as Vendor" (click)="navigateToRegisterForm()"></button>
              </div>
            </p-card>
          </div>
        </div>
        <!-- consultant -->
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-4" style="text-align: center;">
          <div class="cardstyle">
            <p-card>
              <a><img alt="" style="height: 100px;" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/consultancy.svg"></a>
              <div style="position: relative;top: 2px;">
                <h4>Consultant</h4>
                <p>A person who is seeking a job. </p><br><br><br>
                <button pButton type="button" style="    width: auto;
                position: relative;
                top: -7px;cursor: pointer;"
                  label="Register as Consultant" (click)="navigateToconsultantRegisterForm()"></button>
              </div>
            </p-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>