import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-add-consultant',
  templateUrl: './add-consultant.component.html',
  styleUrls: ['./add-consultant.component.scss'],
  providers: [MessageService, DatePipe],
})
export class AddConsultantComponent implements OnInit {
  addConsultantForm: FormGroup;
  hideIcon = true;
  showIcon: boolean;
  fileName: any;
  filedata: any = {};
  // Drop down for availability
  availability = [
    { name: 'Yes', code: 'Y' },
    { name: 'No', code: 'N' },
  ];
  hide = true;
  disabled = true;
  disableMailAddress = false;
  showIcon1: boolean;
  displayemail: boolean;
  orgIdValue: string;
  emailId: any;
  // Gender Drop down
  gender1 = [
    { name: 'Male', code: 'M' },
    { name: 'Female', code: 'F' },
  ];
  // Relocation drop down
  Relocation = [
    { name: 'Yes', code: 'Y' },
    { name: 'No', code: 'N' },
  ];
  stateOptions: any;
  livingCountry: any[];
  stateOptions1: any;
  filteredData: any;
  filterId: any[];
  primField = false;
  secondaryFilteredData: any;
  secondarySkillId: any[];
  secondaryField = false;
  relocCountry = false;
  experienceData: any;
  dateOfBirthValidation: string;
  currencyData: any[];
  consultant: any;
  skillsCategory: any;
  dobDate: string;
  actId: any;
  actID: any;
  refferedDate1: string;
  availDate1: string;
  loader: boolean;
  addConsultantUrlData: any;
  UserID: any;
  acctdetails: any;
  currencycode: any[];
  preferencList: any = [];
  currencyValue: any;
  currencysymbl: any;
  dobDate1: string;
  phSpinnerHide: boolean;
  phCheckHide: boolean;
  displayNumber: boolean;
  officenum: any;
  officenummsg: string;
  phSpinnerHide1: boolean;
  mobnummsg: string;
  phCheckHide1: boolean;
  displayemail1: boolean;
  altEmailSpinnerHide: boolean;
  altEmailId: any;
  altEmailCheckHide: boolean;
  countrycodedropdown: any = [];
  phSpinnerHide2: boolean;
  phCheckHide2: boolean;
  items: any[];
  btndisable: boolean;
  filesdatass: boolean;
  attachmentfile: boolean;
  profileupload: boolean;
  name: any;
  registerdStatus: boolean;
  loginActiveData: String;
  email1: any;
  displayActive: boolean;
  disable: boolean;
  saveReg: any;
  vendorEmail: any;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private breadcrumbService: BreadcrumbService, private router: Router, private apisService: ApisService, private datepipe: DatePipe, private messageService: MessageService) {
    this.breadcrumbService.setItems([
      { label: 'Candidates', routerLink: '/candidates' },
      { label: 'Candidate Add', routerLink: '/add-consultant' },
    ]);
  }
  ngOnInit(): void {
    this.attachmentfile = true;
    this.acctdetails = JSON.parse(this.apisService.decodeData());
    this.vendorEmail = this.acctdetails.loginId;
    this.preferencList = this.acctdetails.preferencesList;
    // tslint:disable-next-line:align
    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }
    this.addConsultantForm = this.fb.group({
      resume: [''],
      firstName: ['', [Validators.required, Validators.maxLength(25), Validators.pattern('^[\\sa-zA-z]*$')]],
      middleName: ['', [Validators.maxLength(15), Validators.pattern('^[\\sa-zA-z]*$')]],
      lastName: ['', [Validators.required, Validators.maxLength(25), Validators.pattern('^[\\sa-zA-z]*$')]],
      dateOfBirth: [''],
      mobileNo: [''],
      homePhone: [''],
      emailId: ['', [Validators.maxLength(60), Validators.pattern('^[[a-zA-Z0-9.+-_]+@[a-zA-Z0-9.+-_]+.[a-zA-Z]{2,5}]*$'),]],
      alternateEmailId: ['', [Validators.maxLength(60), Validators.pattern('^[[a-zA-Z0-9.+-_]+@[a-zA-Z0-9.+-_]+.[a-zA-Z]{2,5}]*$'),]],
      gender: [{ name: 'Male', code: 'M' }],
      availability: [{ name: 'No', code: 'N' }],
      availableDate: [''],
      ssnNo: ['', Validators.maxLength(25)],
      linkedIn: ['', Validators.maxLength(120)],
      facebook: ['', Validators.maxLength(120)],
      twitter: ['', Validators.maxLength(120)],
      permanentAddressLine1: ['', [Validators.maxLength(50)]],
      permanentAddressLine2: ['', Validators.maxLength(50)],
      mailbox1: ['', [Validators.pattern('^[0-9]*$'), Validators.maxLength(10)]],
      permanentCountry: [''],
      permanentState: [''],
      permanentCity: ['', Validators.maxLength(20)],
      permanentZip: ['', [Validators.pattern('^[0-9]*$'), Validators.maxLength(10)]],
      mailingAdressCheckbox: [''],
      mailingAddressLine1: ['', Validators.maxLength(50)],
      mailingAddressline2: ['', Validators.maxLength(50)],
      mailbox2: ['', [Validators.pattern('^[0-9]*$'), Validators.maxLength(10)]],
      mailingCountry: [''],
      mailingState: [''],
      mailingCity: ['', Validators.maxLength(20)],
      mailingZip: ['', [Validators.pattern('^[0-9]*$'), Validators.maxLength(10)]],
      // tslint:disable-next-line:no-trailing-whitespace
      primarySkills: ['', Validators.required],
      secondarySkills: ['', Validators.required],
      jobTitle: ['', [Validators.required, Validators.maxLength(30), Validators.pattern('^[\\sa-zA-z]*$')]],
      experience: [''],
      mobileDrp: [''],
      homeNumDrp: [''],
      currentRate: ['', Validators.pattern('^[0-9]*$')],
      currentCTC: ['', Validators.pattern('^[0-9]*$')],
      expMinRate: ['', Validators.pattern('^[0-9]*$')],
      expMaxRate: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      expMinCTC: ['', Validators.pattern('^[0-9]*$')],
      expMaxCTC: ['', Validators.pattern('^[0-9]*$')],
      education: ['', Validators.maxLength(10)],
      referredBy: ['', Validators.maxLength(30)],
      refDate: [''],
      workingCountry: ['', [Validators.required]],
      currency: ['', [Validators.required]],
      relocation: [''],
      livingCountry: [''],
      consultantworkstatus: ['', Validators.required],
      consultantworkstatusComments: ['', Validators.maxLength(500)],
      comments: ['', Validators.maxLength(500)],
    });
    this.CountryDropdown();
    this.getExperienceDropDownData();
    this.currencyDropdown();
    this.ConsultantDropdown();
    this.getSkillsCategory();
    this.avilableDateModification('No');
    this.getdrop();
    // this.addConsultantForm.patchValue({
    //   alternateEmailId: this.vendorEmail
    // });
  }
  // Country code onchange method for further enhancement
  countryids(event) {
  }
  // country code dropdown along with flags
  getdrop() {
    this.countrycodedropdown = this.apisService.getdropdwon();
    // tslint:disable-next-line:max-line-length
    this.addConsultantForm.controls.mobileDrp.patchValue({ code: '+1', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png', id: '2', label: 'UNITED STATES' });
    // tslint:disable-next-line:max-line-length
    this.addConsultantForm.controls.homeNumDrp.patchValue({ code: '+1', flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png', id: '2', label: 'UNITED STATES' });
  }
  // Method to copy email to clipboard
  copyInputMessage(data) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (data));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
  // Method to get formdata of a file on uploading
  handleFileInput(event) {
    this.fileName = event.target.files[0];
    this.name = this.fileName.name;
    if (this.fileName.name.length > 25) {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'File name should be 25 alphabets.' });
      this.cancelfiles();
    }
    if (this.fileName.size > 10485760) {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please upload below 10MB.' });
      this.cancelfiles();
    }
    else {
      const formData: FormData = new FormData();
      formData.append('file', this.fileName);
      this.filedata = formData;
      // tslint:disable-next-line: no-conditional-assignment
      if (this.filedata = formData) {
        this.filesdatass = true;
      } else {
        this.filesdatass = false;
      }
    }
  }
  // Method to hide SSN number
  myFunction() {
    this.hide = !this.hide;
    this.hideIcon = false;
    this.showIcon1 = true;
  }
  // Method to show SSN number
  myFunction1() {
    this.hide = !this.hide;
    this.hideIcon = true;
    this.showIcon1 = false;
  }
  // Method to check whether an email already exists or not
  emailverification() {
    // this.displayemail = false;
    // const email = this.addConsultantForm.controls.emailId.value;
    // const data = email + '/' + this.orgIdValue;
    // if (email === '' || email === null || email === undefined) {
    // } else {
    //   if (this.addConsultantForm.controls.emailId.invalid && this.addConsultantForm.controls.emailId.errors.pattern) {
    //   }
    //   else {
    //     this.phSpinnerHide2 = true;
    //     this.apisService.getApi(environment.emailVaildation + email).subscribe((res) => {
    //       this.emailId = res;
    //       if (this.emailId.validation === true) {
    //         this.phSpinnerHide2 = false;
    //         this.displayemail = true;
    //         setTimeout(() => {
    //           this.displayemail = false;
    //         }, 1000);
    //         this.addConsultantForm.controls.emailId.reset();
    //       } else {
    //         this.phSpinnerHide2 = false;
    //         this.phCheckHide2 = true;
    //         setTimeout(() => {
    //           this.phCheckHide2 = false;
    //         }, 3000);
    //       }
    //     });
    //   }
    // }
  }
  // To change availoable date
  avilableDateModification(event) {
    if (event === 'No') {
      this.addConsultantForm.controls.availableDate.reset();
      this.addConsultantForm.controls.availableDate.disable();
      this.showIcon = false;
    } else {
      this.addConsultantForm.controls.availableDate.enable();
      this.showIcon = true;
    }
  }
  // Country dropdown
  CountryDropdown() {  
    this.apisService.getApi(environment.lookupsData + 'ADLC').subscribe((res) => {
      this.livingCountry = res.data;
      this.addConsultantForm.controls.permanentCountry.patchValue(this.livingCountry.filter((x) => x.code === 'USA')[0]);
      this.getStateByUrl(this.addConsultantForm.controls.permanentCountry.value.code);
      this.addConsultantForm.controls.mailingCountry.patchValue(this.livingCountry.filter((x) => x.code === 'USA')[0]);
      this.getStateByUrl1(this.addConsultantForm.controls.mailingCountry.value.code);
    });
  }
  // Experience dropdown
  getExperienceDropDownData() {
    this.loader = true;
    this.apisService.getApi(environment.lookupsData + 'RELYE').subscribe((res: any) => {
      this.experienceData = res.data;
      this.loader = false;
    });
  }
  // Consultant work status dropdown
  ConsultantDropdown() {
    this.apisService.getApi(environment.lookupsData + 'UPDVS').subscribe((res) => {
      this.consultant = res.data;
    });
  }
  // Skills dropdown method
  getSkillsCategory() {
    this.apisService.getApi(environment.lookupsData + 'RELRS').subscribe((res) => {
      this.skillsCategory = res.data;
      // const data = { skillName: 'Others', id: '' };
      // this.skillsCategory.push(data);
    });
  }
  // currency dropdown
  currencyDropdown() {
    this.apisService.getApi(environment.lookupsData + 'UPDC').subscribe((res: any) => {
      this.currencyData = res.data;
    });
  }
  // relocation dropdown
  relocDropDown(event) {
    if (event.value.code === 'Yes') {
      this.relocCountry = true;
    } else {
      this.relocCountry = false;
    }
  }
  // states dropdown
  getStateByUrl1(code) {
    if (code === null || code === '' || code === undefined) {
      this.stateOptions1 = [];
    }
    else {
      this.apisService.getApi(environment.state + '/' + code).subscribe((res) => {
        this.stateOptions1 = res.data;
      });
    }
  }
  // method to enable checkbox and mailing address
  disableCheckboxValue() {
    this.addConsultantForm.controls.mailingAdressCheckbox.setValue(false);
    this.addConsultantForm.controls.mailingAddressLine1.enable();
    this.addConsultantForm.controls.mailingAddressline2.enable();
    this.addConsultantForm.controls.mailingCity.enable();
    this.addConsultantForm.controls.mailingCountry.enable();
    this.addConsultantForm.controls.mailingState.enable();
    this.addConsultantForm.controls.mailingZip.enable();
  }
  // method to enable checkbox and to patch to permanent address data to mailing address
  disableMailAd(event) {
    this.getStateByUrl1(this.addConsultantForm.controls.permanentCountry.value.code);
    this.addConsultantForm.controls.mailingAddressLine1.setValue(this.addConsultantForm.controls.permanentAddressLine1.value);
    this.addConsultantForm.controls.mailingAddressline2.setValue(this.addConsultantForm.controls.permanentAddressLine2.value);
    this.addConsultantForm.controls.mailingCity.setValue(this.addConsultantForm.controls.permanentCity.value);
    this.addConsultantForm.controls.mailingCountry.patchValue(this.addConsultantForm.controls.permanentCountry.value);
    this.addConsultantForm.controls.mailingState.setValue(this.addConsultantForm.controls.permanentState.value);
    this.addConsultantForm.controls.mailingZip.setValue(this.addConsultantForm.controls.permanentZip.value);
    this.addConsultantForm.controls.mailbox2.setValue(this.addConsultantForm.controls.mailbox1.value);
    // tslint:disable-next-line:align
    if (event.checked === true) {
      this.addConsultantForm.controls.mailingAddressLine1.disable();
      this.addConsultantForm.controls.mailingAddressline2.disable();
      this.addConsultantForm.controls.mailingCity.disable();
      this.addConsultantForm.controls.mailingCountry.disable();
      this.addConsultantForm.controls.mailingState.disable();
      this.addConsultantForm.controls.mailingZip.disable();
      this.addConsultantForm.controls.mailbox2.disable();
    } else {
      this.addConsultantForm.controls.mailingAddressLine1.enable();
      this.addConsultantForm.controls.mailingAddressline2.enable();
      this.addConsultantForm.controls.mailingCity.enable();
      this.addConsultantForm.controls.mailingCountry.enable();
      this.addConsultantForm.controls.mailingState.enable();
      this.addConsultantForm.controls.mailingZip.enable();
      this.addConsultantForm.controls.mailbox2.enable();
    }
  }
  // primary skills onchange method
  primarySkillData() {
    const skill = this.addConsultantForm.get('primarySkills').value;
    this.filteredData = skill.map((x) => x.skillName);
    this.filterId = skill.map((x) => x.code).map(Number);
    if (this.filteredData.includes('Others')) {
      this.primField = true;
    } else {
      this.primField = false;
    }
  }
  // Secondary skills onchange method
  secondarySkillData() {
    const skills = this.addConsultantForm.get('secondarySkills').value;
    this.secondaryFilteredData = skills.map((x) => x.skillName);
    this.secondarySkillId = skills.map((x) => x.code).map(Number);
    if (this.secondaryFilteredData.includes('Others')) {
      this.secondaryField = true;
    } else {
      this.secondaryField = false;
    }
  }
  // dropdownto get states based on country
  getStateByUrl(code) {
    if (code === null || code === '' || code === undefined) {
      this.stateOptions = [];
    }
    else {
      this.apisService.getApi(environment.state + '/' + code).subscribe((res) => {
        this.stateOptions = res.data;
      });
    }
  }
  toggle(event, data, rowData) {
    this.items = [];
  }
  emailToast() {
    if (this.addConsultantForm.controls.emailId.invalid && this.addConsultantForm.controls.emailId.errors.pattern) {
    }
    else if (this.addConsultantForm.controls.emailId.value === '') {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please enter email.' });
    }
    else {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
    }

  }
  altEmailToast() {

    if (this.addConsultantForm.controls.alternateEmailId.invalid && this.addConsultantForm.controls.alternateEmailId.errors.pattern) {
    }
    else if (this.addConsultantForm.controls.alternateEmailId.value === '') {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please enter alternate email.' });
    }
    else {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
    }
  }
  // Method to validate age >18 or not
  getAge(event) {
    const today = new Date();
    const birthDate = new Date(this.addConsultantForm.get('dateOfBirth').value);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      isNaN(age--);
    }
    if (age < 18) {
      this.addConsultantForm.get('dateOfBirth').reset();
      this.dateOfBirthValidation = 'Age should be atleast 18';
    } else {
      this.dateOfBirthValidation = '';
    }
  }
  // To reset consultant form
  reset() {
    this.addConsultantForm.reset();
    // this.addConsultantForm.patchValue({
    //   alternateEmailId: this.vendorEmail
    // });
    this.addConsultantForm.controls.gender.patchValue(this.gender1.filter((x) => x.code === 'M')[0]);
    this.addConsultantForm.controls.availability.patchValue(this.Relocation.filter((x) => x.code === 'N')[0]);
    this.addConsultantForm.controls.permanentCountry.patchValue(this.livingCountry.filter((x) => x.code === 'USA')[0]);
    this.addConsultantForm.controls.mailingCountry.patchValue(this.livingCountry.filter((x) => x.code === 'USA')[0]);
    // availability: [{ name: 'No', code: 'N' }],







    // this.addConsultantForm.reset();
  }
  // Method to add consultant data using post API
  addConsultantData() {
    this.disabled = true;
    this.actId = JSON.parse(this.apisService.decodeData());
    if (this.fileName === null || this.fileName === undefined || this.fileName === '') {
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please upload resume.' });
      return;
    }
    this.actID = this.actId.accId;
    if (this.addConsultantForm.invalid) {
      const controls = this.addConsultantForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      // tslint:disable-next-line:max-line-length
      // if (this.addConsultantForm.controls.dateOfBirth.value === '' || this.addConsultantForm.controls.dateOfBirth.value === null || this.addConsultantForm.controls.dateOfBirth.value === undefined) {
      //   this.dobDate = '';
      // } else {
      //   const dob = this.addConsultantForm.controls.dateOfBirth.value;
      //   const dobDate1 = new Date(Date.parse(dob));
      //   this.dobDate1 = this.datepipe.transform(this.addConsultantForm.controls.dateOfBirth.value, 'MM-dd-yyyy');
      // }
      // tslint:disable-next-line:max-line-length
      if (this.addConsultantForm.controls.refDate.value === '' || this.addConsultantForm.controls.refDate.value === null || this.addConsultantForm.controls.refDate.value === undefined) {
        this.dobDate = '';
      } else {
        const refferedDate = this.addConsultantForm.controls.refDate.value;
        const dobDate1 = new Date(Date.parse(refferedDate));
        this.refferedDate1 = this.datepipe.transform(dobDate1, 'MM-dd-yyyy');
      }
      // tslint:disable-next-line:max-line-length
      if (this.addConsultantForm.controls.availableDate.value === '' || this.addConsultantForm.controls.availableDate.value === null || this.addConsultantForm.controls.availableDate.value === undefined) {
        this.dobDate = '';
      } else {
        const avaliDate = this.addConsultantForm.controls.availableDate.value;
        const avail = new Date(Date.parse(avaliDate));
        this.availDate1 = this.datepipe.transform(avail, 'MM-dd-yyyy');
      }
      this.saveUserContacts();
      // this.displayActive = true;
      // this.registerdStatus = true;
      // this.loginActiveData = 'Do you want to send login credentials to email?';
      // this.email1 = this.addConsultantForm.controls.emailId.value;


    }
  }

  // Save Candidate contact details
  saveUserContacts() {
    this.loader = true;
    const data = {
      accId: this.actID,
      altEmail: this.addConsultantForm.controls.alternateEmailId.value,
      // tslint:disable-next-line:max-line-length
      occode: this.addConsultantForm.controls.homeNumDrp.value === undefined || this.addConsultantForm.controls.homeNumDrp.value === null || this.addConsultantForm.controls.homeNumDrp.value === '' ? '' : this.addConsultantForm.controls.homeNumDrp.value.id,
      // tslint:disable-next-line:max-line-length
      mccode: this.addConsultantForm.controls.mobileDrp.value === undefined || this.addConsultantForm.controls.mobileDrp.value === null || this.addConsultantForm.controls.mobileDrp.value === '' ? '' : this.addConsultantForm.controls.mobileDrp.value.id,
      // tslint:disable-next-line:max-line-length
      availability: this.addConsultantForm.controls.availability.value.name === undefined || this.addConsultantForm.controls.availability.value.name === '' ? '' : this.addConsultantForm.controls.availability.value.name === 'Yes' ? 'Y' : 'N',
      availableDate: this.availDate1 === undefined || this.availDate1 === '' ? '' : this.availDate1,
      comment: this.addConsultantForm.controls.comments.value === '' ? null : this.addConsultantForm.controls.comments.value,
      currCtc: this.addConsultantForm.controls.currentCTC.value === '' ? 0 : this.addConsultantForm.controls.currentCTC.value,
      currRate: this.addConsultantForm.controls.currentRate.value === '' ? 0 : this.addConsultantForm.controls.currentRate.value,
      dob: this.addConsultantForm.controls.dateOfBirth.value === null || this.addConsultantForm.controls.dateOfBirth.value === undefined || this.addConsultantForm.controls.dateOfBirth.value === '' ? '' : this.datepipe.transform(this.addConsultantForm.controls.dateOfBirth.value, 'MM-dd-yyyy'),
      education: this.addConsultantForm.controls.education.value === '' ? null : this.addConsultantForm.controls.education.value,
      email: this.addConsultantForm.controls.emailId.value,
      expMaxCtc: this.addConsultantForm.controls.expMaxCTC.value === '' ? 0 : this.addConsultantForm.controls.expMaxCTC.value,
      expMaxRate: this.addConsultantForm.controls.expMaxRate.value === '' ? 0 : this.addConsultantForm.controls.expMaxRate.value,
      expMinCtc: this.addConsultantForm.controls.expMinCTC.value === '' ? 0 : this.addConsultantForm.controls.expMinCTC.value,
      expMinRate: this.addConsultantForm.controls.expMinRate.value === '' ? 0 : this.addConsultantForm.controls.expMinRate.value,
      // tslint:disable-next-line:max-line-length
      experiance: this.addConsultantForm.controls.experience.value.code === undefined ? 0 : this.addConsultantForm.controls.experience.value.code,
      // tslint:disable-next-line:max-line-length
      facebook: this.addConsultantForm.controls.facebook.value === '' ? null : 'https://www.facebook.com/in/' + this.addConsultantForm.controls.facebook.value,
      fname: this.addConsultantForm.controls.firstName.value,
      // tslint:disable-next-line:max-line-length
      gender: this.addConsultantForm.controls.gender.value.code === undefined || this.addConsultantForm.controls.gender.value.code === '' ? '' : this.addConsultantForm.controls.gender.value.code,
      homePhone: this.addConsultantForm.controls.homePhone.value === '' ? null : this.addConsultantForm.controls.homePhone.value,
      jobTitle: this.addConsultantForm.controls.jobTitle.value === '' ? null : this.addConsultantForm.controls.jobTitle.value,
      // tslint:disable-next-line:max-line-length
      linkedIn: this.addConsultantForm.controls.linkedIn.value === '' ? null : 'https://www.linkedin.com/in/' + this.addConsultantForm.controls.linkedIn.value,
      // tslint:disable-next-line:max-line-length
      livingCountry: this.addConsultantForm.controls.livingCountry.value === undefined || this.addConsultantForm.controls.livingCountry.value === null || this.addConsultantForm.controls.livingCountry.value === '' ? '' : this.addConsultantForm.controls.livingCountry.value.code,
      // tslint:disable-next-line:max-line-length
      lkCurrency: this.addConsultantForm.controls.currency.value.code === undefined ? '' : this.addConsultantForm.controls.currency.value.code,
      // tslint:disable-next-line:max-line-length
      lkRelocation: this.addConsultantForm.controls.relocation.value.code === undefined || this.addConsultantForm.controls.relocation.value.code === '' ? '' : this.addConsultantForm.controls.relocation.value.code,
      // tslint:disable-next-line:max-line-length
      lkVisaComment: this.addConsultantForm.controls.consultantworkstatusComments.value === undefined || this.addConsultantForm.controls.consultantworkstatusComments.value === null || this.addConsultantForm.controls.consultantworkstatusComments.value === '' ? '' : this.addConsultantForm.controls.consultantworkstatusComments.value,
      // tslint:disable-next-line:max-line-length
      lkVisaStatus: this.addConsultantForm.controls.consultantworkstatus.value === undefined || this.addConsultantForm.controls.consultantworkstatus.value === '' ? 0 : this.addConsultantForm.controls.consultantworkstatus.value.code,
      lname: this.addConsultantForm.controls.lastName.value,
      maddType: '',
      // tslint:disable-next-line:max-line-length
      maddress1: this.addConsultantForm.controls.mailingAddressLine1.value === '' ? null : this.addConsultantForm.controls.mailingAddressLine1.value,
      // tslint:disable-next-line:max-line-length
      maddress2: this.addConsultantForm.controls.mailingAddressline2.value === '' ? null : this.addConsultantForm.controls.mailingAddressline2.value,
      mcity: this.addConsultantForm.controls.mailingCity.value === '' ? null : this.addConsultantForm.controls.mailingCity.value,
      // tslint:disable-next-line:max-line-length
      mcountry: this.addConsultantForm.controls.mailingCountry.value === undefined || this.addConsultantForm.controls.mailingCountry.value === null || this.addConsultantForm.controls.mailingCountry.value === '' ? 0 : this.addConsultantForm.controls.mailingCountry.value.code,
      mdistrict: '',
      mmailBox: this.addConsultantForm.controls.mailbox2.value,
      mname: this.addConsultantForm.controls.middleName.value,
      mobilePhone: this.addConsultantForm.controls.mobileNo.value,
      mphone: '',
      // tslint:disable-next-line:max-line-length
      mstate: this.addConsultantForm.controls.mailingState.value.code === undefined ? '' : this.addConsultantForm.controls.mailingState.value.code,
      mzip: this.addConsultantForm.controls.mailingZip.value === '' ? null : this.addConsultantForm.controls.mailingZip.value,
      paddType: '',
      // tslint:disable-next-line:max-line-length
      paddress1: this.addConsultantForm.controls.permanentAddressLine1.value === '' ? null : this.addConsultantForm.controls.permanentAddressLine1.value,
      // tslint:disable-next-line:max-line-length
      paddress2: this.addConsultantForm.controls.permanentAddressLine2.value === '' ? null : this.addConsultantForm.controls.permanentAddressLine2.value,
      pcity: this.addConsultantForm.controls.permanentCity.value === '' ? null : this.addConsultantForm.controls.permanentCity.value,
      // tslint:disable-next-line:max-line-length
      pcountry: this.addConsultantForm.controls.permanentCountry.value === undefined || this.addConsultantForm.controls.permanentCountry.value === '' ? '' : this.addConsultantForm.controls.permanentCountry.value.code,
      pdistrict: '',
      pmailBox: this.addConsultantForm.controls.mailbox1.value,
      pphone: '',
      // tslint:disable-next-line:max-line-length
      pstate: this.addConsultantForm.controls.permanentState.value.code === undefined || this.addConsultantForm.controls.permanentState.value.code === '' ? null : this.addConsultantForm.controls.permanentState.value.code,
      pzip: this.addConsultantForm.controls.permanentZip.value === '' ? null : this.addConsultantForm.controls.permanentZip.value,
      referedBy: this.addConsultantForm.controls.referredBy.value,
      referedDate: this.refferedDate1 === undefined || this.refferedDate1 === '' ? '' : this.refferedDate1,
      ssnNumber: this.addConsultantForm.controls.ssnNo.value === '' ? null : this.addConsultantForm.controls.ssnNo.value,
      // tslint:disable-next-line:max-line-length
      twitter: this.addConsultantForm.controls.twitter.value === '' ? null : 'https://www.twitter.com/in/' + this.addConsultantForm.controls.twitter.value,
      // tslint:disable-next-line:max-line-length
      wokringCountry: this.addConsultantForm.controls.workingCountry.value.code === undefined || this.addConsultantForm.controls.workingCountry.value.code === '' ? '' : this.addConsultantForm.controls.workingCountry.value.code,
      workLocationId: 0,
      hireType: '',
      startDate: '',
      endDate: '',
      primarySkills: this.filterId === undefined ? 0 : this.filterId,
      secondarySkills: this.secondarySkillId === undefined ? 0 : this.secondarySkillId,
      // tslint:disable-next-line:max-line-length
      relocation: this.addConsultantForm.controls.relocation.value.code === undefined || this.addConsultantForm.controls.relocation.value.code === '' ? '' : this.addConsultantForm.controls.relocation.value.code,
    };
    this.btndisable = true;
    this.apisService.postApi(environment.addConsultant, data).subscribe((res) => {
      this.addConsultantUrlData = res;
      this.UserID = this.addConsultantUrlData.userId;
      const userid = '/' + this.UserID + '/R';
      this.apisService.postApi(environment.attachment + userid, this.filedata).subscribe((res1: any) => {
      });
      if (this.addConsultantUrlData.hasOwnProperty('success') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.addConsultantUrlData.message });   
        const contactId = this.UserID;
        this.disable = true;
        // tslint:disable-next-line: deprecation
        // this.apisService.getApi(environment.loginSendCandidate + contactId).subscribe((res) => {
//           this.saveReg = res;
//           this.disable = false;
//           // this.loader = false;
//           if (this.saveReg.hasOwnProperty('success') === true) {
//             this.displayActive = false;
        setTimeout(() => {
          this.router.navigate(['/candidates']);
        }, 2000);

        //     this.addConsultantForm.reset();
        //     this.messageService.add({
        //       key: 'br',
        //       severity: '',
        //       life: 3000,
        //       detail: this.saveReg.message,
        //     });
        //   } else if (this.saveReg.hasOwnProperty('failed') === true) {
        //     this.messageService.add({
        //       key: 'br',
        //       severity: '',
        //       life: 3000,
        //       detail: this.saveReg.failed,
        //     });
        //   } else {
        //     this.messageService.add({
        //       key: 'br',
        //       severity: '',
        //       life: 3000,
        //       detail: this.saveReg.error,
        //     });
        //   }
        // }, (err) => {
        //   this.disable = false;
        // }
        // );
      } else if (this.addConsultantUrlData.hasOwnProperty('success') === false) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.addConsultantUrlData.failed });
      } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.addConsultantUrlData.error });
      }
      this.loader = false;
    });
  }

  // backrouting to previous screen
  goto() {
    this.router.navigate(['/candidates']);
  }
  // Method to calculate duration per hr
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
  // method to check whether a mobile number already exist or not in DB
  getPhon() {
    this.displayNumber = false;
    const num = this.addConsultantForm.controls.mobileNo.value;
    if (num === '' || num === null || num === undefined || num === '(___) ___-____') {
    }
    else {
      this.phSpinnerHide = true;
      this.apisService.getApi(environment.phonerequired + num).subscribe(res => {
        this.officenum = res;
        this.officenum = res.data.isNumberExisted;
        if (this.officenum === true) {
          this.phSpinnerHide = false;
          this.officenummsg = 'mobile number already exist';
          setTimeout(() => {
            this.officenummsg = '';
          }, 5000);
          this.addConsultantForm.get('mobileNo').reset();
        }
        else {
          this.phSpinnerHide = false;
          this.phCheckHide = true;
          setTimeout(() => {
            this.phCheckHide = false;
          }, 3000);
        }
      });
    }
  }
  // method to check whether a Home number already exist in DB or not
  getMobPhon() {
    const num = this.addConsultantForm.controls.homePhone.value;
    if (num === '' || num === null || num === undefined || num === '(___) ___-____') {
    }
    else {
      this.phSpinnerHide1 = true;
      // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.phonerequired + num).subscribe(res => {
        this.officenum = res;
        this.officenum = res.data.isNumberExisted;
        if (this.officenum === true) {
          this.phSpinnerHide1 = false;
          this.mobnummsg = 'home number already exist';
          setTimeout(() => {
            this.mobnummsg = '';
          }, 5000);
          this.addConsultantForm.get('homePhone').reset();
        }
        else {
          this.phSpinnerHide1 = false;
          this.phCheckHide1 = true;
          setTimeout(() => {
            this.phCheckHide1 = false;
          }, 3000);
        }
      });
    }
  }
  // Method to check whether an email already exist in DB
  AlternateEmail() {
    // this.displayemail1 = false;
    // const email = this.addConsultantForm.controls.alternateEmailId.value;
    // if (email === null || email === undefined || email === '') {
    // } else {
    //   if (this.addConsultantForm.controls.alternateEmailId.invalid && this.addConsultantForm.controls.alternateEmailId.errors.pattern) {
    //   }
    //   else {
    //     this.altEmailSpinnerHide = true;
    //     this.apisService.getApi(environment.emailVaildation + email).subscribe((res) => {
    //       this.altEmailId = res;
    //       if (this.altEmailId.validation === true) {
    //         this.altEmailSpinnerHide = false;
    //         this.displayemail1 = true;
    //         setTimeout(() => {
    //           this.displayemail1 = false;
    //         }, 1000);
    //         this.addConsultantForm.controls.alternateEmailId.reset();
    //       } else {
    //         this.altEmailSpinnerHide = false;
    //         this.altEmailCheckHide = true;
    //         setTimeout(() => {
    //           this.altEmailCheckHide = false;
    //         }, 3000);
    //       }
    //     });
    //   }
    // }
  }
  cancelfiles() {
    this.addConsultantForm.controls.resume.reset();
    this.filesdatass = false;
    this.attachmentfile = false;
    this.profileupload = true;
  }
}

