import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-customer-attachments',
  templateUrl: './customer-attachments.component.html',
  styleUrls: ['./customer-attachments.component.scss'],
  providers: [DatePipe, MessageService],
})
export class CustomerAttachmentsComponent implements OnInit {
  items: MenuItem[];
  dropDownValue = [];
  AttachmentsForm: FormGroup;
  vendorAttachmentsDailogForm: FormGroup;
  vendorAttachmentsEditDailogForm: FormGroup;
  attachmentsGriddata: any;
  display: boolean;
  attachmentValidity: string;
  attachuserId: any;
  displayDailog: boolean;
  minDate: Date;
  valid: boolean;
  displayvalid: boolean;
  errorMsg: string;
  file2: any;
  updateData: any;
  hide: boolean;
  loader: boolean;
  disable = false;
  startCurDate: Date;
  totalrecords: any;
  uploadfileData: any;
  rows: any = 10;
  page: any = 1;
  docTable = {};
  documenttype: any[];
  records: boolean;
  table = true;
  accountId: any;
  rowData: any;
  filedata: any;
  resumeRes: any;
  isUploading1: boolean;
  fileName: any;
  name: any;
  fileType: any;
  fileRes: any;
  filePath: any;
  fileSize: any;
  getData: any;
  roleId: number;
  show: boolean;
  editdata: any;
  show1: boolean;
  validitydate: boolean;
  attachmentId: any;
  editattachvalid: any;
  item: { label: string; icon: string; command: (event: any, i: any) => void; }[];
  first: any;
  pageCount: any;
  filesdatass: boolean;
  classinput: boolean;
  classinput1: boolean;
  usrroleid: any;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private datepipe: DatePipe, private messageService: MessageService, private breadcrumbService: BreadcrumbService) {
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === ''){
      this.roleId = JSON.parse(this.apisService.decodeData()).roles[0].id;
    }else{
      this.roleId = this.usrroleid;
    }
    if (this.roleId === 3) {
      this.breadcrumbService.setItems([
        { label: 'Account Details', routerLink: '/account-details' },
        { label: 'Attachment', routerLink: '/attachments/:id' }
      ]);
    }
    else {
      this.breadcrumbService.setItems([
        { label: 'Account Details', routerLink: '/accountdetails' },
        { label: 'Attachment', routerLink: '/attachments/:id' }
      ]);
    }
  }
  // It is used for the displaying the table header details
  cols = [
    { field: 'title', header: 'Name', width: '9%' },
    { field: 'lkAttachmentType', header: 'Document Type', width: '5%' },
    { field: 'attachmentUploadedDate', header: 'Uploaded Date', width: '5%' },
    { field: 'attachmentExpiry', header: 'Date Of Expiry', width: '5%' },
    { field: 'attachmentUploadedBy', header: 'Uploaded By', width: '7%' },
    { field: 'Download', header: 'Action', width: '4%' },
  ];
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.DataSearch();
    }
  }
  ngOnInit() {
    this.classinput = true;
    this.accountId = JSON.parse(this.apisService.decodeData());
    this.dropDownValue = [
      { label: 'Account Details', routerLink: ['/account-details', this.accountId] },
      { label: 'Contacts', routerLink: ['/contacts', this.accountId.accId] },
      { label: 'Attachments', routerLink: ['/attachments', this.accountId.accId] },
      { label: 'Locations', routerLink: ['/locations', this.accountId.accId] },
    ];
    this.AttachmentsForm = this.fb.group({
      Name: [''],
      ValidFromDate: [''],
      VAlidToDate: [''],
      DocumentType: [''],
    });
    this.vendorAttachmentsDailogForm = this.fb.group({
      attachmentNameDailog: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(40)]],
      attachmentDocTypeDailog: ['', Validators.required],
      attachmentValidityDailog: [''],
      attachmentCommentsDailog: ['', [Validators.maxLength(300)]],
      uploadAttachment: ['', Validators.required],
      dateCheck: [''],
      organisationText: ['']
    });
    this.vendorAttachmentsEditDailogForm = this.fb.group({
      attachmentEditNameDailog: ['', [Validators.maxLength(40), Validators.pattern('^[\\sa-zA-z]*$')]],
      attachmentEditValidityDailog: [''],
      attachmentEditCommentsDailog: ['', [Validators.maxLength(300)]],
      dateCheck: [''],
      organisationText: ['']
    });
    this.documentDropD();
    this.gridData();
    this.startCurDate = this.minDate;
  }
  documentDropD() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'ATFA').subscribe((res: any) => {
      this.documenttype = res.data;
      this.documenttype.forEach((element) => {
        this.docTable[element.code] = element.label;
      });
    });
  }
  // It is used for the displaying the GridData
  gridData() {
    this.loader = true;
    const pageNo = this.page;
    const pageCount = this.rows;
    const orgId = '/' + pageNo + '/' + pageCount;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.getAttachment + orgId + '/' + this.accountId.accId).subscribe((res: any) => {
      this.attachmentsGriddata = res.data.records;
      this.totalrecords = res.data.total;
      if (this.totalrecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.loader = false;
    });
  }
  endDateValidation() {
    // tslint:disable-next-line:max-line-length
    if (new Date(this.AttachmentsForm.get('ValidFromDate').value).getTime() < new Date(this.AttachmentsForm.get('VAlidToDate').value).getTime()) {
    } else {
      this.AttachmentsForm.get('VAlidToDate').reset();
    }
  }
  DataSearch() {
    this.loader = true;
    // tslint:disable-next-line:max-line-length
    const attachmenttitle = this.AttachmentsForm.controls.Name.value === null || undefined || '' ? '' : this.AttachmentsForm.controls.Name.value;
    // tslint:disable-next-line:max-line-length
    const attachementtype = this.AttachmentsForm.controls.DocumentType.value === null || this.AttachmentsForm.controls.DocumentType.value === '' || this.AttachmentsForm.controls.DocumentType.value === undefined ? '' : this.AttachmentsForm.controls.DocumentType.value.code;
    // tslint:disable-next-line:max-line-length
    const attachmentexpirydate = this.AttachmentsForm.controls.VAlidToDate.value === null || this.AttachmentsForm.controls.VAlidToDate.value === undefined || this.AttachmentsForm.controls.VAlidToDate.value === '' ? '' : this.datepipe.transform(this.AttachmentsForm.controls.VAlidToDate.value, 'MM-dd-yyyy');
    // tslint:disable-next-line:max-line-length
    const attachemnetvaliddate = this.AttachmentsForm.controls.ValidFromDate.value === null || this.AttachmentsForm.controls.ValidFromDate.value === undefined || this.AttachmentsForm.controls.ValidFromDate.value === '' ? '' : this.datepipe.transform(this.AttachmentsForm.controls.ValidFromDate.value, 'MM-dd-yyyy');
    // tslint:disable-next-line:max-line-length
    const data = this.page + '/' + this.rows + '/' + this.accountId.accId + '?attachmentName=' + attachmenttitle + '&validFrom=' + attachemnetvaliddate + '&validTo=' + attachmentexpirydate + '&documentType=' + attachementtype;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.getAttachment + '/' + data).subscribe((res: any) => {
      this.loader = false;
      this.attachmentsGriddata = res.data.records;
      this.totalrecords = res.data.total;
      this.loader = false;
      if (this.totalrecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  // It is used for select the file on vendor attachment details
  selectFile(evt) {
    const formData: FormData = new FormData();
    if (evt.target.files.length > 0) {
      this.file2 = evt.target.files[0];
      this.vendorAttachmentsDailogForm.get('uploadAttachment').setValue(this.file2.name);
    }
    formData.append('file', this.file2.name);
  }
  onFileChange(event) {
    this.fileName = event.target.files[0];
    this.name = this.fileName.name;
    if (this.fileName.name.length > 25){
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'File name should be 25 alphabets.' });
      this.cancelfiles();
    }
    if (this.fileName.size > 10485760){
      this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Please upload below 10MB.' });
      this.cancelfiles();
     }
   else{
    this.fileType = this.fileName.name.split('.').pop();
    const formData: FormData = new FormData();
    formData.append('file', this.fileName);
    this.filedata = formData;
    // tslint:disable-next-line: no-conditional-assignment
    if (this.filedata = formData) {
      this.filesdatass = true;
    } else {
      this.filesdatass = false;
    }
   }
  }
  // It is used for open the Add Attachment dialog box
  showDailog() {
    this.vendorAttachmentsDailogForm.reset();
    this.display = true;
    this.validitydate = true;
    this.show = false;
    this.filesdatass = false;
  }
  SaveDailog() {
    if (this.vendorAttachmentsDailogForm.invalid) {
      const controls = this.vendorAttachmentsDailogForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      this.attachuserId = sessionStorage.getItem('userId');
      // tslint:disable-next-line:max-line-length
      if (this.vendorAttachmentsDailogForm.controls.attachmentValidityDailog.value === null || this.vendorAttachmentsDailogForm.controls.attachmentValidityDailog.value === undefined) {
        this.attachmentValidity = '';
      } else {
        const attValidity = this.vendorAttachmentsDailogForm.controls.attachmentValidityDailog.value;
        const attachmentValidityDate = new Date(Date.parse(attValidity));
        this.attachmentValidity = this.datepipe.transform(attachmentValidityDate, 'MM-dd-yyyy');
      }
    }
  }
  showDailogUpdate(data) {
    this.updateData = data;
    this.attachmentId = data.attachmentId;
    this.vendorAttachmentsEditDailogForm.controls.attachmentEditNameDailog.patchValue(this.updateData.title);
    this.vendorAttachmentsEditDailogForm.controls.attachmentEditCommentsDailog.patchValue(this.updateData.comments);
    if (data.attachmentExpiry === '' || data.attachmentExpiry === '1992-01-01T00:00:00.000+0000') {
      this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.disable();
      this.vendorAttachmentsEditDailogForm.controls.dateCheck.patchValue(true);
      this.show1 = true;
    } else {
      this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.enable();
      this.vendorAttachmentsEditDailogForm.controls.dateCheck.patchValue(false);
      this.show1 = false;
      this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.enable();
    }
    if (data.attachmentExpiry === '' || data.attachmentExpiry === '1992-01-01T00:00:00.000+0000') {
      this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.patchValue('');
    } else {
      this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.patchValue(new Date(this.updateData.attachmentExpiry));
    }
  }
  updataeDailog() {
    this.attachuserId = JSON.parse(this.apisService.decodeData());
    this.hide = true;
    if (this.vendorAttachmentsEditDailogForm.invalid) {
      const controls = this.vendorAttachmentsEditDailogForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
        this.hide = false;
      });
    } else {
      if (this.vendorAttachmentsEditDailogForm.controls.dateCheck.value === true) {
        // tslint:disable-next-line:max-line-length
        this.editattachvalid = this.vendorAttachmentsEditDailogForm.controls.organisationText.value === null ||
          this.vendorAttachmentsEditDailogForm.controls.organisationText.value === undefined ||
          // tslint:disable-next-line:max-line-length
          this.vendorAttachmentsEditDailogForm.controls.organisationText.value === '' ? '01-01-1992' : this.vendorAttachmentsEditDailogForm.controls.organisationText.value;
      } else {
        // tslint:disable-next-line:max-line-length
        this.editattachvalid = this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.value === null || this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.value === undefined || this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.value === '' ? '' : this.datepipe.transform(this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.value, 'MM-dd-yyyy');
      }
      const updateadd = this.editattachvalid;
      const attachmenttitle = this.vendorAttachmentsEditDailogForm.controls.attachmentEditNameDailog.value;
      const attachmentComment = this.vendorAttachmentsEditDailogForm.controls.attachmentEditCommentsDailog.value;
      const data1 = {
        id: this.attachmentId,
        fileName: attachmenttitle,
        expiryDate: updateadd,
        comments: attachmentComment
      };
      // tslint:disable-next-line: deprecation
      this.apisService.putApi(environment.updateAttachment, data1).subscribe((res: any) => {
        this.hide = false;
        this.getData = res;
        this.loader = false;
        if (this.getData.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message, });
          this.displayDailog = false;
          this.gridData();
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message, });
          this.loader = false;
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error, });
          this.loader = false;
        }
        this.loader = true;
      });
    }
  }
  resetData() {
    this.AttachmentsForm.reset();
    this.gridData();
  }
  resetDailog() {
    this.vendorAttachmentsDailogForm.reset();
    this.filesdatass = false;
  }
  rset() {
    this.vendorAttachmentsEditDailogForm.reset();
  }
  downloadFileapi(data) {
    this.isUploading1 = true;
    this.filedata = data.attachmentId;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.downloadedattachment + '/' + this.filedata).subscribe((res) => {
      this.resumeRes = res;
      this.isUploading1 = false;
      window.location.assign(this.resumeRes.downloadUrl);
    });
  }
  toggle1(data) {
    this.rowData = data;
    this.item = [
      // tslint:disable-next-line:max-line-length
      { label: 'Edit', icon: 'pi pi-pencil', command: (event, i) => { this.displayDailog = true; this.showDailogUpdate(data); }, },
      { label: 'Download', icon: 'pi pi-download', command: () => { this.downloadFileapi(data); }, },
    ];
  }
  createQP() {
    if (this.vendorAttachmentsDailogForm.invalid) {
      const controls = this.vendorAttachmentsDailogForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      this.disable = true;
      // tslint:disable-next-line: deprecation
      this.apisService.postApi(environment.profileUpload + this.accountId.accId + '/' + 'A', this.filedata).subscribe((res: any) => {
        this.fileRes = res;
        this.filedata = res.data;
        this.filePath = res.data.path;
        this.fileSize = res.data.fileSize;
        this.addAttachement();
        if (this.fileRes.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.fileRes.message });
          this.gridData();
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.fileRes.error });
        }
      });
    }
  }
  addAttachement() {
    const attachdata = {
      objectId: this.accountId.accId,
      verifiedDate: '01-01-2021',
      objectType: 'A',
      // tslint:disable-next-line:max-line-length
      title: this.vendorAttachmentsDailogForm.controls.attachmentNameDailog.value === null || this.vendorAttachmentsDailogForm.controls.attachmentNameDailog.value === undefined || this.vendorAttachmentsDailogForm.controls.attachmentNameDailog.value === '' ? '' : this.vendorAttachmentsDailogForm.controls.attachmentNameDailog.value,
      attPath: this.filePath,
      fileName: this.fileName.name,
      size: this.fileSize,
      extension: this.fileType,
      // tslint:disable-next-line:max-line-length
      comments: this.vendorAttachmentsDailogForm.controls.attachmentCommentsDailog.value === null || undefined || '' ? ' ' : this.vendorAttachmentsDailogForm.controls.attachmentCommentsDailog.value,
      verifiedBy: '1',
      // tslint:disable-next-line:max-line-length
      attType: this.vendorAttachmentsDailogForm.controls.attachmentDocTypeDailog.value === null || '' || this.vendorAttachmentsDailogForm.controls.attachmentDocTypeDailog.value.code === undefined ? ' ' : this.vendorAttachmentsDailogForm.controls.attachmentDocTypeDailog.value.code,
      // tslint:disable-next-line: max-line-length
      expiryDate: this.vendorAttachmentsDailogForm.controls.attachmentValidityDailog.value === null || undefined || '' ? '01-01-1992' : this.datepipe.transform(this.vendorAttachmentsDailogForm.controls.attachmentValidityDailog.value, 'MM-dd-yyyy'),
    };
    // tslint:disable-next-line: deprecation
    this.apisService.postApi(environment.addProfile, attachdata).subscribe((res: any) => {
      this.getData = res;
      this.disable = false;
      this.display = false;
      if (this.getData.hasOwnProperty('success') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message });
        this.gridData();
      } else if (this.getData.hasOwnProperty('failed') === true) {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.failed });
      } else {
        this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error });
      }
    });
  }
  disableAddCheckboxValue(data) {
    if (data.checked === true) {
      this.validitydate = false;
      this.show = true;
    } else {
      this.show = false;
      this.validitydate = true;
    }
  }
  disableEditCheckboxValue(data) {
    this.editdata = data.checked;
    if (data.checked === true) {
      this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.disable();
      this.show1 = true;
    } else {
      this.show1 = false;
      this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.enable();
      this.vendorAttachmentsEditDailogForm.controls.attachmentEditValidityDailog.patchValue(new Date(this.updateData.attachmentExpiry));
    }
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridData();
  }
  cancelfiles() {
    this.vendorAttachmentsDailogForm.controls.uploadAttachment.reset();
    this.filesdatass = false;
    this.classinput = false;
    this.classinput1 = true;
  }
}
