<p-tabView>  
    <p-tabPanel header="Availability">  
        <p-card header="Set your availability">
            <div class="alignment">
                <p-selectButton [options]="stateOptions" (onChange)="selectoption()" [(ngModel)]="value1"
                    optionLabel="label" optionValue="value"></p-selectButton>
            </div>
            <br>
            <div *ngIf="calenderview"  [style]="{width: '50%'}">
                <p-panel header="Set your weekly hours">
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                            <p-checkbox [(ngModel)]="sunoption" binary="true" inputId="binary" (click)="checkclaender()"></p-checkbox>

                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                            <strong>
                                <h6>{{this.value}}</h6>
                            </strong>
                        </div>
                    </div>
                    <div *ngIf="sunoption" class="styling">
                        <form [formGroup]="calederform">
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <p-dropdown [options]="Time" [showClear]="true" formControlName="fromdates" optionLabel="name" (onChange)="updateapi()"></p-dropdown>
                            </div>
                            -
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <p-dropdown [options]="Time" [showClear]="true" optionLabel="name" formControlName="todates" (onChange)="updateapi()"></p-dropdown>
                            </div>  
                        </div>
                    </form>
                    </div>
                    <div *ngIf="!sunoption" class="unavilable">
                        Unavailable
                    </div>
                    <hr>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                            <p-checkbox [(ngModel)]="monoption" binary="true" (click)="checkclaender()" inputId="binary"></p-checkbox>

                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                            <strong>
                                <h6>{{this.monday}}</h6>
                            </strong>
                        </div>
                    </div>
                    <div *ngIf="monoption" class="nextday">
                        <form [formGroup]="calederform1">
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <p-dropdown [showClear]="true" [options]="Time" optionLabel="name" formControlName="monday" (onChange)="updatemonday()"></p-dropdown>
                            </div>
                         -
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <p-dropdown [showClear]="true" [options]="Time" optionLabel="name" formControlName="mondays" (onChange)="updatemonday()"></p-dropdown>
                            </div>
                        </div>
                    </form>
                    </div>
                    <div *ngIf="!monoption" class="unavilable">

                        Unavailable
                    </div>
                    <hr>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                            <p-checkbox [(ngModel)]="tueoption" binary="true" (click)="checkclaender()" inputId="binary"></p-checkbox>

                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                            <strong>
                                <h6>{{this.tuesday}}</h6>
                            </strong>

                        </div>
                    </div>
                    <div *ngIf="tueoption"  class="nextday">
                        <form [formGroup]="calederform2">
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <p-dropdown [showClear]="true" [options]="Time" optionLabel="name" formControlName="tuesday" (onChange)="updatetuesday()"></p-dropdown>
                            </div>
                            -
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <p-dropdown [showClear]="true" [options]="Time" optionLabel="name" formControlName="tuesdays" (onChange)="updatetuesday()"></p-dropdown>
                            </div>
                           
                        </div>
                    </form>
                    </div>

                    <div *ngIf="!tueoption"  class="unavilable">

                        Unavailable
                    </div>
                    <hr>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                            <p-checkbox [(ngModel)]="wedoption" binary="true" (click)="checkclaender()" inputId="binary"></p-checkbox>

                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                            <strong>
                                <h6>{{this.wednesday}}</h6>
                            </strong>

                        </div>
                    </div>
                    <div *ngIf="wedoption"  class="nextday">
                        <form [formGroup]="calederform3">
                        <div class="p-grid p-fluid">
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <p-dropdown [showClear]="true" [options]="Time" optionLabel="name" formControlName="wednesday" (onChange)="updatewednesday()"></p-dropdown>
                            </div>
                            -
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <p-dropdown [showClear]="true" [options]="Time" optionLabel="name" formControlName="wednesdays" (onChange)="updatewednesday()"></p-dropdown>
                            </div>
                           
                        </div>
                        </form>

                    </div>

                    <div *ngIf="!wedoption"  class="nextday">

                        Unavailable
                    </div>
                    <hr>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                            <p-checkbox [(ngModel)]="thuoption" binary="true" (click)="checkclaender()" inputId="binary"></p-checkbox>

                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                            <strong>
                                <h6>{{this.thursday}}</h6>
                            </strong>

                        </div>
                    </div>
                    <div *ngIf="thuoption" class="nextday">
                        <form [formGroup]="calederform4">
                        <div class="p-grid p-fluid">


                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <p-dropdown [showClear]="true" [options]="Time" formControlName="thursday" optionLabel="name" (onChange)="updatethurday()"></p-dropdown>
                            </div>
                            -
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <p-dropdown [showClear]="true" [options]="Time" optionLabel="name" formControlName="thursdays" (onChange)="updatethurday()"></p-dropdown>
                            </div>
                           
                        </div>
                        </form>
                    </div>

                    <div *ngIf="!thuoption" class="unavilable">

                        Unavailable
                    </div>
                    <hr>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                            <p-checkbox [(ngModel)]="frioption" binary="true" (click)="checkclaender()" inputId="binary"></p-checkbox>

                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                            <strong>
                                <h6>{{this.friday}}</h6>
                            </strong>

                        </div>
                    </div>
                    <div *ngIf="frioption"  class="nextday">
                        <form [formGroup]="calederform5">
                        <div class="p-grid p-fluid">


                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <p-dropdown [showClear]="true" [options]="Time" optionLabel="name" formControlName="friday" (onChange)="updatefriday()"></p-dropdown>
                            </div>
                            -
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <p-dropdown [showClear]="true" [options]="Time" optionLabel="name" formControlName="fridays" (onChange)="updatefriday()"></p-dropdown>
                            </div>
                           
                        </div>
                        </form>
                    </div>

                    <div *ngIf="!frioption" class="unavilable">

                        Unavailable
                    </div>
                    <hr>
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                            <p-checkbox [(ngModel)]="satoption" binary="true" inputId="binary" (click)="checkclaender()"></p-checkbox>

                        </div>
                        <div class="p-col-12 p-md-12 p-sm-12 p-lg-1">
                            <strong>
                                <h6>{{this.saturday}}</h6>
                            </strong>

                        </div>
                    </div>
                    <div *ngIf="satoption"  class="nextday">
                        <form [formGroup]="calederform6">
                        <div class="p-grid p-fluid">


                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <p-dropdown [showClear]="true" [options]="Time" optionLabel="name" formControlName="saturday" (onChange)="updatesaturday()"></p-dropdown>
                            </div>
                            -
                            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                                <p-dropdown [showClear]="true" [options]="Time" optionLabel="name"  formControlName="saturdays" (onChange)="updatesaturday()"></p-dropdown>
                            </div>
                           
                        </div>
                        </form>
                    </div>

                    <div *ngIf="!satoption" class="unavilable">

                        Unavailable
                    </div>

                </p-panel>
               
            </div>
        
     <div *ngIf="!calenderview">
        <p-fullCalendar class="calStyle
        " [events]="events" [options]="options"></p-fullCalendar>
        <span class="pointer" pTooltip="{{this.events}}" tooltipPosition="top"></span>
    </div>

    </p-card>

     </p-tabPanel>
     
   
    <p-tabPanel header="Event Type">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                <h6>Mail:-<a>mr.saikumar16@gmail.com</a></h6>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2">
                <button pButton type="button" label="Create Event" class="p-button-outlined" (click)="showDialog()"
                    class="createbutton"></button>
            </div>
        </div>
        <hr class="header">
        <p-dialog class="dialogBox" header="Select Event Type" [(visible)]="display" [modal]="true"
            [style]="{width: '20%'}">
            <br>
            <form [formGroup]="Createform">
                <label class="pi pi-user users"> One-on-One </label> 
                <p-radioButton value="single" formControlName="eventtype" class="radiobutton"></p-radioButton>
                <label class="pi pi-users users"> Group</label>
                <p-radioButton value="multiple" formControlName="eventtype"></p-radioButton>
                <br>
                <div>
                    <span class="validationtest validate"
                        *ngIf="this.Createform.get('eventtype').touched && this.Createform.get('eventtype').invalid && this.Createform.get('eventtype').errors.required">
                        Please select Event type
                    </span>
                </div>
                <br>
            </form>
            <div class="proceedbutton">
                <button pButton type="button" label="Proceed" (click)="navigate()"
                    class="alignmentss"></button>
            </div>
        </p-dialog>

        <div [formGroup]="orderForm">
            <div class="p-grid p-fluid">
                <div formArrayName="items" *ngFor="let item of orderForm.get('items')['controls']; let i = index;">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <p-card [style]="{'height': '300px','width':'300px'}" [formGroupName]="i">
                            <div class='head' [ngStyle]="{'background-color':eventcolor}"></div>
                            <form>
                                Card {{i}}
                            </form>
                        
                        </p-card>
                        <br>
                    </div>
                </div>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Shedule Event">
        <form [formGroup]="sheduleform">
            <p-card>
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Events</label>
                        <p-dropdown optionLabel="label" [options]="event" autocomplete="off"  [showClear]="true"
                            (onChange)="typeofevent($event.value.code)" placeholder="Select Event Type"
                            formControlName="event">
                        </p-dropdown>
                    </div>

                    <div *ngIf="showcalender" class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">From date <label class="mandatoryColor"></label></label>
                        <p-calendar id="popup" placeholder="MM-DD-YYYY" dateFormat="mm-dd-yy" [showIcon]="true"
                            [showButtonBar]="true" formControlName="fromdate">
                        </p-calendar>
                    </div>
                    <div *ngIf="showcalender" class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">To date <label class="mandatoryColor"></label></label>
                        <p-calendar id="popup" placeholder="MM-DD-YYYY" dateFormat="mm-dd-yy" [showIcon]="true"
                            [showButtonBar]="true" formControlName="todate">
                        </p-calendar>
                    </div>


                </div>

                <div class="btnsCSS1 buttonalign">

                    <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn"></button>
                </div>
                <h1></h1>
            </p-card>
            <br>
            <div *ngIf="table">
                <!--It is used for displaying the Locations table-->
                <p-table [columns]="cols" selectionMode="single" dataKey="edit" [responsive]="true" sortMode="multiple"
                    [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,20,30]">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th id="gridHeader" *ngFor="let col of columns let i=index"
                                (click)="iconToggle[i] = !iconToggle[i]" [ngStyle]="{'width': col.width }"
                                [pSortableColumn]="col.field">
                                {{col.header}}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="paginatorleft">
                        <h3 class="norecorddata">Total records : </h3>
                    </ng-template>
                    <ng-template pTemplate="paginatorright">
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr *ngIf="gridTableData?.length==0" class="image">
                            <td colspan="6" class="mandatoryColor"> No Records</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </form>
        <p-card *ngIf="records">
            <div class="norecordImgsize">
                <div class="aligncenter">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                    <br><em>Sorry, we are not able to find any events</em><br>
                </div>
            </div>
        </p-card>
    </p-tabPanel>
</p-tabView>
<div *ngIf="!loading; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center laoderheight ">
        <div class="laodersize" >
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif" class="loaderimg-height" >
        </div>
      </div>
    </div>
  </div>
</ng-template>