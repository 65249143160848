import { Component } from '@angular/core';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { Router } from '@angular/router';
import { ApisService } from './services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {
  loginRoleName: any;
  fullName: any;
  acctname: any;
  male: boolean;
  female: boolean;
  topbarimage: any;
  autoId: string;
  response: any;
  topimageid: any;
  subscription: any;
  profileimage: string;
  iomgid: string;
  getimgid: any;
  usrlgindata: any;
  usrroles: any = [];
  usrlgindatarole: any;
  usrrolenmaes: any = [];
  showaccname: any;
  selectedRole: any;
  selectedrole: any;
  constructor(public app: AppComponent, public router: Router, public appMain: AppMainComponent, private apiService: ApisService) {
    const userDetails = JSON.parse(this.apiService.decodeData());
    this.usrlgindata = userDetails.roles;
    this.usrlgindatarole = userDetails.name;
    this.acctname = userDetails.accName;
    this.usrlgindata.forEach((element: any) => {
      this.usrroles.push(element);
    });
    // this.selectedRole = this.usrroles[0].id;
    // this.rolechange('');
    const dataaa = sessionStorage.getItem('loginRole');
    const dataaa1 = JSON.parse(dataaa);
    if (dataaa1) {
      this.selectedRole = dataaa1.id;
      this.rolechange(dataaa1);
    }
    else {
      this.selectedRole = this.usrroles[0].id;
      this.rolechange('');
    }
    this.iomgid = sessionStorage.getItem('profileImgId');
    this.topimageid = userDetails.imageId;
    this.fullName = userDetails.name;
    sessionStorage.setItem('genderValue', JSON.stringify(userDetails.gender));
    sessionStorage.setItem('topimageid', JSON.stringify(userDetails.imageId));
    this.topbarimgae();
    if (this.topbarimage === '' || this.topbarimage === null || this.topbarimage === undefined) {
      if (userDetails.gender === 'M') {
        this.profileimage = 'https://d2r8nxfsv3v6y3.cloudfront.net/images/male.png';
      }
      else {
        this.profileimage = 'https://d2r8nxfsv3v6y3.cloudfront.net/images/female.png';
      }
    }
  }
  logout() {
    const dataa = sessionStorage.getItem('autoID');
    const reqbody = {
    id: dataa
    };
    // tslint:disable-next-line: deprecation
    this.apiService.putApi(environment.updateaccesslog, reqbody).subscribe((res) => {
    this.response = res;
    });
    sessionStorage.clear();
    
    this.apiService.username.subscribe((res)=>{
    if(res === "" || res === null || res === undefined) {
    } else {
    this.apiService.username.next('');    
    }
    });
    this.apiService.username1.subscribe((res)=>{
    if(res === "" || res === null || res === undefined) {
    } else {
    this.apiService.username1.next('');
    }
    });
    this.router.navigateByUrl('login');
    }
  ViewProfile() {
    this.router.navigateByUrl('/profile');
  }
  changePassword() {
    this.router.navigateByUrl('/changepassword');
  }
  userPrefernces() {
    this.router.navigateByUrl('/userpreference');
  }
  sharecalender() {
    this.router.navigateByUrl('/sharedcalender');
  }
  topbarimgae() {
    this.apiService.username1.subscribe((res: any) => {
      this.getimgid = res;
      const imgid = res === '' ? this.topimageid : this.getimgid;
      if (this.topimageid === 0 && res === null && this.topimageid === 0 && res === '') {
      } else if (imgid === undefined || imgid === null || imgid === '' || imgid === 0) {
      } else {
        this.apiService.getApi(environment.downloadres + imgid).subscribe((result) => {
          this.topbarimage = result.downloadUrl;
        });
      }
    });
    this.apiService.username.subscribe((res1: any) => {
      if (res1 === '') {
      } else {
        this.apiService.getApi(environment.downloadres + res1).subscribe((res: any) => {
          this.topbarimage = res.downloadUrl;
        });
      }
    });
  }

  rolechange(data) {
    this.showaccname = data?.name;
    this.apiService.rolebasedsidemenu.next(data);
    if (this.showaccname === undefined || this.showaccname === null || this.showaccname === '') {
      const userDetails = JSON.parse(this.apiService.decodeData());
      this.loginRoleName = userDetails.roles[0].name;
    } else {
      this.loginRoleName = this.showaccname;
    }
  }
  getrole(data)
  {
    this.selectedrole = data;
    sessionStorage.setItem('loginRole',  JSON.stringify(this.selectedrole));
  }
}
