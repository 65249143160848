import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss'],
  providers: [DatePipe, MessageService],
})
export class ChangepasswordComponent implements OnInit {
  csradminresetUsrPwd: FormGroup;
  loginUserId: any;
  checkPasswordRes: any;
  loginId: any;
  passwordUpdateRes: any;
  userName: any;
  hide = true;
  hideIcon = true;
  showIcon: boolean;
  hideNewPWDIcon = true;
  hideCfmPWDIcon = true;
  showNewPWDIcon: boolean;
  hide1 = true;
  showCfmPWDIcon: boolean;
  hide2 = true;
  value4: string;
  text: string;
  capitalLetterRegEx: string;
  capitalresult: any;
  specialresult: any;
  specialCharacterRegEx: string;
  numberRegEx: string;
  numberresult: any;
  smallLetterRegEx: string;
  smallresult: any;
  disable: boolean;
  files: any;
  success: string;
  // tslint:disable-next-line:no-inferrable-types
  instruction: boolean = false;
  constructor(
    private fb: FormBuilder,
    private apisService: ApisService,
    private messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
  ) {
    this.breadcrumbService.setItems([
      { label: 'Change Password', routerLink: '/change-password' },
    ]);
  }
  ngOnInit() {
    this.loginUserId = JSON.parse(sessionStorage.getItem('loginData')).userId;
    this.loginId = JSON.parse(sessionStorage.getItem('loginData')).loginId;
    this.csradminresetUsrPwd = this.fb.group({
      password: ['', [Validators.required, Validators.maxLength(20), Validators.pattern( /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&+-])[A-Za-z\d@$!%*#?&+-]{8,}$/ ), ]],
      currentPassword: ['', [Validators.required]],
      conformPassword: ['', [Validators.required, Validators.maxLength(20), Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&+-])[A-Za-z\d@$!%*#?&+-]{8,}$/ ), ]],
       });
    const acctdetails = JSON.parse(this.apisService.decodeData());
    this.userName = acctdetails.loginId;
  }
  myFunction(data) {
    if (data === 1) {
      this.hide = !this.hide;
      this.hideIcon = false;
      this.showIcon = true;
    }
    if (data === 2) {
      this.hide1 = !this.hide1;
      this.hideNewPWDIcon = false;
      this.showNewPWDIcon = true;
    }
    if (data === 3) {
      this.hide2 = !this.hide2;
      this.hideCfmPWDIcon = false;
      this.showCfmPWDIcon = true;
    }
  }
  myFunction1(data) {
    if (data === 1) {
      this.hide = !this.hide;
      this.hideIcon = true;
      this.showIcon = false;
    }
    if (data === 2) {
      this.hide1 = !this.hide1;
      this.hideNewPWDIcon = true;
      this.showNewPWDIcon = false;
    }
    if (data === 3) {
      this.hide2 = !this.hide2;
      this.hideCfmPWDIcon = true;
      this.showCfmPWDIcon = false;
    }
  }
  onSearchChange(searchValue: string): void {
    this.text = searchValue;
    this.capitalLetterRegEx = '.*[A-Z]+.*';
    this.smallLetterRegEx = '.*[a-z]+.*';
    this.numberRegEx = '.*[0-9]+.*';
    if (this.text.search(this.capitalLetterRegEx) === -1) {
      this.csradminresetUsrPwd.controls.upper.patchValue(false);
    } else {
      this.csradminresetUsrPwd.controls.upper.patchValue(true);
    }
    if (this.text.search(this.smallLetterRegEx) === -1) {
      this.csradminresetUsrPwd.controls.lower.patchValue(false);
    } else {
      this.csradminresetUsrPwd.controls.lower.patchValue(true);
    }
    if (this.text.search(this.numberRegEx) === -1) {
      this.csradminresetUsrPwd.controls.number.patchValue(false);
    } else {
      this.csradminresetUsrPwd.controls.number.patchValue(true);
    }
    if (this.text.length < 8) {
      this.csradminresetUsrPwd.controls.eight.patchValue(false);
    } else {
      this.csradminresetUsrPwd.controls.eight.patchValue(true);
    }
  }
  resetPassword() {
    if (this.csradminresetUsrPwd.invalid) {
      const controls = this.csradminresetUsrPwd.controls;
      for (const name in controls) { if (controls[name].invalid) { } }
      Object.keys(controls).forEach((key) => {
      controls[key].markAsTouched();
      });
      }
      else{
        this.disable = true;
        const currentPassword =
          this.csradminresetUsrPwd.controls.currentPassword.value;
        const password = this.csradminresetUsrPwd.controls.password.value;
        const conformPassword =
          this.csradminresetUsrPwd.controls.conformPassword.value;
        if (currentPassword === '' || password === '' || conformPassword === '') {
          if (currentPassword === '' || password === '') {
            if (currentPassword === '') {
              this.messageService.add({
                key: 'br',
                severity: '',
                life: 3000,
                detail: 'Please Enter Current Password.',
              });
              this.disable = false;
            } else {
              this.messageService.add({
                key: 'br',
                severity: '',
                life: 3000,
                detail: 'Please Enter New Password.',
              });
              this.disable = false;
            }
          } else {
            this.messageService.add({
              severity: 'error',
              detail: 'Please Enter Confirm Password.',
            });
            this.disable = false;
          }
        } else {
          if (password === conformPassword) {
            const data = {
              email: this.userName,
              newPassword: password,
              password: currentPassword,
            };
            this.disable = true;
            this.apisService.postApi(environment.changepassword, data).subscribe((res: any) => {
              this.files = res;
              // tslint:disable-next-line:no-conditional-assignment
              if (this.success = this.files.success ? 'success' : 'error') {
                this.messageService.add({  key: 'br', severity: '', life: 3000, detail: this.files.message,  });
                this.disable = false;
                setTimeout(() => {
                  this.router.navigate(['/login']);
                }, 3000);
              }
            });

          } else {
            // tslint:disable-next-line:max-line-length
            this.messageService.add({ key: 'br',  severity: '',  life: 3000, detail: 'New Password and confirm new password should be same.',  });
            this.disable = false;
          }
        }
      }
  }
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.resetPassword();
    }
  }
  instructions() {
    this.instruction = true;
  }
}
