<p-card>
    <form [formGroup]="vendorAssociatedCustomerForm">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Subcontractor Name</label>
                <input (keyup.enter)="searchData()" id="input" type="text" pInputText placeholder="Subcontractor Name"
                    formControlName="vendorName" maxlength="50" autocomplete="off">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Corporate Website</label>
                <input (keyup.enter)="searchData()" id="input" type="text" pInputText placeholder="Corporate Website"
                    formControlName="vendorUrl" maxlength="60" autocomplete="off">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Country</label>
                <p-dropdown (keyup.enter)="searchData()" placeholder="Select Country" [options]="country" [showClear]="true"
                    optionLabel="label" formControlName="vendorCountry" (onChange)="getStateByUrl($event.value)">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">State</label>
                <p-dropdown (keyup.enter)="searchData()" placeholder="Select State" [options]="state" [showClear]="true"
                    optionLabel="label" formControlName="vendorState">
                </p-dropdown>
            </div>
        </div>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Industry</label>
                <p-dropdown (keyup.enter)="searchData()" placeholder="Select Industry" [options]="industry"
                [showClear]="true" optionLabel="label" formControlName="vendorIndustry"></p-dropdown>
            </div>
        </div>
        <div class="btnsCSS1 btnalignment">
            <button pButton type="button" icon='pi pi-plus' label="Add" class="addhBtn"
                [routerLink]="['/avaialablesubcontractor']"></button>
            <button pButton type="button" icon='pi pi-refresh' label="Reset" class="addhBtn" (click)="reset()"></button>
            <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn"
                (click)="searchData()"></button>
        </div>
    </form>
</p-card><br>
<p-card class="pgpos">
    <div *ngIf="table">
        <p-table [value]="vendorAssociatedCustomertableData" [columns]="cols" [autoLayout]="true" selectionMode="single"
            dataKey="edit" [responsive]="true" sortMode="multiple" [rows]="10">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                        <span>
                            {{col.header}}
                        </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
                <tr>
                    <td (click)="updateData(data)" pTooltip="{{data.name}}" tooltipPosition="top"><span
                            class="ui-column-title"></span>
                        {{data.name}}</td>
                    <td class="textCenter" pTooltip="{{data.webSite}}" tooltipPosition="top"><span
                            class="ui-column-title pointer"></span><a
                            (click)="goToLink(data.webSite)">{{data.webSite}}</a></td>
                    <td class="textCenter"><span class="ui-column-title"></span>{{data.phone}}</td>
                    <td><span class="ui-column-title"></span>
                        <p-badge [value]="statusLabel[data.status]" [ngClass]="data.status ==='A'?'green': 'red'">
                        </p-badge>
                    </td>
                    <td>
                        {{industryLabel[data.industry]}}
                    </td>
                    <td>
                        {{data.tierName}}
                    </td>
                    <td>
                        {{data.createdDate}}
                    </td>
                    <td><span class="p-column-title aligncenter"></span><a>
                            <em (click)="actionpopup.toggle($event); toggle($event,data, rowData)"
                                class="pi pi-ellipsis-v pointer align-left"></em>
                        </a> </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalrecods}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    <p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png"
                    class="norecordimg">
                <br><em>Sorry, we are not able to find any subcontractors</em><br>
            </div>
        </div>
    </p-card>
</p-card>

<div *ngIf="!loading; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>