<p-card>
  <form [formGroup]="requirementsForm">
    <div class="p-fluid p-grid">
      <div class="p-col-12 p-md-3">
        <label class="inputfields">Job Id</label>
        <p-inputNumber mode="decimal" formControlName="jobId" inputId="withoutgrouping" [useGrouping]="false"
          pKeyFilter="int" placeholder="Job Id" maxlength="11">
        </p-inputNumber>
      </div>
      <div class=" p-col-12 p-md-3">
        <label class="inputfields">Position Title</label>
        <input id="input" type="text" maxlength="30" autocomplete="off" pInputText formControlName="jobTitle"
          (keyup.enter)="search()" placeholder="Position Title">
      </div>
      <div class="p-col-12 p-md-3">
        <label class="inputfields">Status</label>
        <p-dropdown [options]="statusList" placeholder="Select Status" optionValue="code" [showClear]="true"
          optionLabel="label" (keyup.enter)="search()" formControlName="status"></p-dropdown>
      </div>
      <!-- <div class=" p-col-12 p-md-3">
        <label class="inputfields">Customer Name</label>
        <p-dropdown placeholder="Select Customer Name" [showClear]="true" (keyup.enter)="search()"
          formControlName="customerName" filter="true" [options]="accountnamesres" optionLabel="employerName">
        </p-dropdown>
      </div> -->
      <div class="p-col-12 p-md-3">
        <label class="inputfields">Start Date</label>
        <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="startDate" placeholder="MM-DD-YYYY"
          [showIcon]="true" (keyup.enter)="search()" [showButtonBar]="true">
        </p-calendar>
      </div>
      <div class="p-col-12 p-md-3">
        <label class="inputfields">End Date</label>
        <p-calendar id="popup" dateFormat="mm-dd-yy" formControlName="endDate" placeholder="MM-DD-YYYY"
          [showIcon]="true" (keyup.enter)="search()" [showButtonBar]="true">
        </p-calendar>
      </div>
    </div>
    <div class="btnsCSS1 alignbutton">
      <button pButton class="addhBtn" type="button" icon='pi pi-refresh' (click)="reset()" label="Reset"></button>
      <button pButton class="addhBtn" type="button" icon='pi pi-search' (click)="search()" label="Search"></button>
    </div>
  </form>
</p-card>
<br>

<p-card class="pgpos">
  <div *ngIf="table">
    <!--It is used for displaying the requirement table-->
    <p-table [columns]="cols" [value]="listOfRequirements" selectionMode="single" dataKey="edit" [responsive]="true"
      id='excel-table' sortMode="multiple">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter" [hidden]="col.sno == 5"
            [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
            <span>
              {{col.header}}
            </span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td class="textCenter pointer" (click)="reqEditRoute(data)"><span class="p-column-title">
            </span>{{data.reqId}}</td>
          <td class="textCenter pointer" (click)="reqEditRoute(data)"><span class="p-column-title">
            </span>{{data.reqName}}</td>
          <td class="textCenter pointer" (click)="reqEditRoute(data)"><span class="p-column-title">
            </span>
            {{data.noOfPositions}}
          </td>
          <td class="textCenter pointer">
            <a (click)="op.toggle($event)" (click)="reqSkills1(getSkillSetTable(data.requiredSkills))"
              *ngFor="let data1 of getSkillSetTable(data.requiredSkills); index as i;">
              <p-badge class="badgespace newsty" *ngIf='i<1' [value]="data1"></p-badge>
              <p-badge *ngIf='i===1' [value]="'+'+(data.requiredSkills.length-1)+' more'"></p-badge>
            </a>
          </td>
          <td class="pointer" [hidden]="true">
            <a (click)="op.toggle($event)" (click)="reqSkills1(getSkillSetTable(data.requiredSkills))"
              *ngFor="let data1 of getSkillSetTable(data.requiredSkills); index as i;">
              <p-badge [value]="data1+','"></p-badge>
            </a>

          </td>
          <td class="textCenter pointer" (click)="reqEditRoute(data)"><span class="p-column-title">
            </span>
            {{data.postedDate }}</td>
          <td><span class="ui-column-title" (click)="reqEditRoute(data)"></span>
            <p-badge [value]="getStatusTable(data.reqStatus)"
              [ngClass]="data.reqStatus ==='C'?'blue': data.reqStatus === 'R' ?'green':data.reqStatus === 'J'? 'red':data.reqStatus === 'P'?'evalution':'green'">
            </p-badge>
          </td>
          <td class="textCenter namedesc" (click)="reqEditRoute(data)" pTooltip="{{data.createdByName}}"
          tooltipPosition="top"><span class="p-column-title">
          </span>{{data.createdByName}}</td>
          <td class="textCenter pointer"><span class="p-column-title">
            </span><a>
              <em (click)="actionpopup.toggle($event); toggle1(items, $event, data)" class="pi pi-ellipsis-v"></em>
            </a></td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-grid ui-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 totalrec">
        <h3 class="norecorddata ">Total records : {{this.reqcount}}</h3>
        <p class="downpos">
          <span class="ui-column-title pointer" (click)="downloadActionPopup.toggle($event); downLoadsToggle()"
            (keyup)="exportExcel()">
            <em class="pi pi-download downloadspace"></em>
            <strong>Download</strong></span>
        </p>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
        <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
          (onPageChange)="paginate($event)" #p></p-paginator>
      </div>
    </div>
  </div>
  <p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="downloadOptions"></p-menu>
  <p-card *ngIf="records">
    <div class="norecordImgsize">
      <div *ngIf="records" class="aligncenter">
        <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
        <br><em>Sorry, we are not able to find any requirements</em><br>
      </div>
    </div>
  </p-card>
</p-card>
<!-- It is used for approve/reject pop up -->
<p-dialog class="dialogBox" header="Approve/Reject Requirement" [(visible)]="approveOrReject" [draggable]="false"
  [modal]="true" [resizable]="false" [style]="{width: '25%' }" [baseZIndex]="1">
  <form [formGroup]="approveOrRejectForm">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <p>Do you want to Approve the requirement?</p>
      </div>
    </div>
    <div class="p-grid p-fluid ">
      <div class="p-col-6 p-md-6 p-sm-12">
        <p-radioButton name="approveOrReject" value="Approved" label="Yes" formControlName="approveOrReject"
          inputId="Yes" (click)="approveRequirement()"></p-radioButton>
        <p-radioButton name="approveOrReject" value="Rejected" label="No" formControlName="approveOrReject" inputId="No"
          class="radiospace" (click)="rejectRequirement()"></p-radioButton>
      </div>
    </div><br>
    <div class="p-grid p-fluid ">
      <div class="p-col-8 p-md-12 p-sm-12" *ngIf="rejectForComments">
        <label class="first">Reason for Rejection<span class="mandatoryColor">*</span></label>
        <textarea autocomplete="off" pInputTextarea [rows]="2" [cols]="30" placeholder="Comments"
          formControlName="comments" maxlength=100></textarea>
        <span class="validationtest" *ngIf="approveOrRejectForm.get('comments').invalid && approveOrRejectForm.get('comments').touched &&
    this.approveOrRejectForm.get('comments').errors.required">Please enter reason for rejection</span>
      </div>
    </div>
    <div class="btnsCSS1 buttonalign">
      <button pButton type="button" icon='pi pi-times' label="Cancel" class="addhBtn" (click)="cancle()"></button>
      <button pButton type="button" icon='pi pi-check-circle' label="Submit" class="addhBtn" [disabled]="disable"
        (click)="submitForApproveOrReject()"></button><br>
    </div>
  </form>
</p-dialog>
<!-- It is used for requirement activity pop up -->
<p-dialog class="dialogBox" [(visible)]="displayModal" [draggable]="false" [modal]="true"
  [style]="{width: '30%',height:'60%'}">
  <p-header>Requirement Activity
    <span class="reqActivity">Job Id : <a>{{this.histroy1}}</a></span>
  </p-header>
  <p class="reqActivitycolor"> Please find the below information</p>
  <div class="p-grid p-fluid">
    <div class="p-col-12 p-md-12 p-sm-12">
      <p-timeline [value]="histroy" align="alternate">
        <ng-template pTemplate="content" let-event>
          <p-card>
            <p class="aligncenter"> {{event.activity}}
              <br>
              <span class="reqActivitycolor">By {{event.activityBy}}</span>
            </p>
          </p-card>
          <br>
        </ng-template>

        <ng-template pTemplate="opposite" let-event><span class="reqActivitycolor">{{event.activityDate |
            date:'MM-dd-yyyy'}}</span>

        </ng-template>
      </p-timeline>
    </div>
  </div>
</p-dialog>

<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>

<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center laoderheight">
        <div class="laodersize">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
            class="loaderimg-height">
        </div>
      </div>
    </div>
  </div>
</ng-template>
<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '25%' }">
  <label class="inputfields">Skill Details</label>
  <ng-template pTemplate>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-12 bordersty">

        <p class="skpos">

          <p-badge class="badgespace badgesp" *ngFor="let data2 of reqSkillsValue; index as i" [value]="data2"
            [ngClass]="i%2===0? 'newsty' : ''">
          </p-badge>


        </p>

      </div>
    </div>

  </ng-template>
</p-overlayPanel>
<p-toast position="bottom-right" key="br"></p-toast>