import { DatePipe } from '@angular/common';
import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-calenderevents',
    templateUrl: './calenderevents.component.html',
    styleUrls: ['./calenderevents.component.scss'],
    providers: [DatePipe, MessageService],
})
export class CalendereventsComponent implements OnInit {
    location = [
        { name: 'Zoom', code: 'zm' },
        { name: 'Gmeet', code: 'gm' }
    ];
    duration = [
        { name: '15 min', code: 'zm' },
        { name: '30 min', code: 'gm' },
        { name: '45 min', code: 'gm' },
        { name: '60 min', code: 'gm' },

    ];
    shedule = [
        { name: 'hours', code: 'zm' },
        { name: 'mins', code: 'gm' },
        { name: 'days', code: 'gm' },
    ];
    eventsForm: FormGroup;
    detailspanel = true;
    availabilitypanel = false;
    event2 = false;
    availabilityForm: FormGroup;
    event3: boolean;
    booking: boolean;
    selctedItem: string;
    bookingForm: FormGroup;
    radiooption: boolean;
    checking: boolean;
    city: string;
    zone: any;
    violet: boolean;
    blue: boolean;
    green: boolean;
    yellow: boolean;
    red: boolean;
    pink: boolean;
    minDate: Date;
    eventcolor: any;
    // tslint:disable-next-line:max-line-length
    constructor(private fb: FormBuilder, private route: ActivatedRoute, private apiService: ApisService, private breadcrumbService: BreadcrumbService, private router: Router, private apisService: ApisService, private messageService: MessageService) {
        this.breadcrumbService.setItems([
            { label: 'Sharedcalender', routerLink: '/sharedcalender' },
            { label: 'Events', routerLink: '/calendar-events' },
        ]);
    }

    ngOnInit(): void {
        this.eventsForm = this.fb.group({
            eventname: ['', [Validators.required]],
            location: ['', [Validators.required]],
            description: [''],
            link: ['', [Validators.required]],
        });
        this.availabilityForm = this.fb.group({
            duration: [''],
            maxevents: [''],
            bevent: [''],
            aevent: [''],
        });
        this.bookingForm = this.fb.group({
            days: [''],
            availabledays: ['Available days'],
            fromdate: [''],
            todate: [''],
            Emp: [''],
            Emp1: [''],
            Emp2: [''],
            starttime: [''],
            conditions: [''],
            shedule: [''],
            zone: [''],
            typeofInvite: ['']
        });

    }
    getZoneList() {
        this.apiService.getApi(environment.getZoneType).subscribe((res: any) => {
            this.zone = res.data;
        });
    }

    eventdetailsfn() {
        if (this.eventsForm.invalid) {
            const controls = this.eventsForm.controls;
            Object.keys(controls).forEach((key) => {
                controls[key].markAsTouched();
            });
        }
        else {
            this.detailspanel = false;
            this.event2 = true;
            this.availabilitypanel = true;
        }
    }
    datavalidation() {
        this.minDate = this.bookingForm.controls.fromdate.value;
    }
    eventavailability() {
        this.availabilitypanel = false;
        this.event3 = true;
        this.booking = true;
        this.getZoneList();
    }
    selectedoption(data) {
        data === 'B' ? this.radiooption = true : this.radiooption = false;
    }
    eventColor(color) {
        this.eventcolor = color;
        if (color === 'violet') {
            this.violet = true;
            this.blue = false;
            this.green = false;
            this.yellow = false;
            this.red = false;
            this.pink = false;
        }
        else if (color === 'blue') {
            this.blue = true;
            this.violet = false;
            this.green = false;
            this.yellow = false;
            this.red = false;
            this.pink = false;
        }
        else if (color === 'green') {
            this.green = true;
            this.blue = false;
            this.violet = false;
            this.yellow = false;
            this.red = false;
            this.pink = false;
        }
        else if (color === 'yellow') {
            this.yellow = true;
            this.green = false;
            this.blue = false;
            this.violet = false;
            this.red = false;
            this.pink = false;
        } else if (color === 'red') {
            this.red = true;
            this.green = false;
            this.blue = false;
            this.violet = false;
            this.yellow = false;
            this.pink = false;
        }
        else {
            this.pink = true;
            this.red = false;
            this.green = false;
            this.blue = false;
            this.violet = false;
            this.yellow = false;

        }
    }
    bookingexp() {
        this.router.navigate(['/sharedcalender'], {
            queryParams: {
                card: 'Y',
                color: this.eventcolor
            }
        });
    }


}
