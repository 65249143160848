import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-schedule-interview',
  templateUrl: './schedule-interview.component.html',
  styleUrls: ['./schedule-interview.component.scss'],
  providers: [DatePipe, MessageService],
})
export class ScheduleInterviewComponent implements OnInit {
  AddreqTechReviews: FormGroup;
  display = false;
  submitAddRes: any;
  skillName: any;
  skillSetControls: FormControl;
  dataArray: any = [];
  loader = true;
  date7: Date;
  questionRes: any;
  onlineQuestionSSkils: any;
  errSkills: boolean;
  hhValid: boolean;
  totQuest: boolean;
  skillBlocksIdValues: any;
  iconToggle = [];
  reqTechReviewsGrid: any;
  fordId: any;
  searchVal: boolean;
  totalRecords: any = 0;
  getData: any;
  AcctRowID: any;
  first: any;
  subGridData: any;
  typeTable = {};
  interviewMode: any = [];
  reviewerList: any = [];
  zone: any = [];
  locationList: any = [];
  rows: any = 10;
  page: any = 1;
  reqId: any;
  profd1: any;
  sataus: any;
  reviewData: any;
  reviewroleID: any;
  date: Date;
  displayreason = false;
  rejreason: any;
  conId: any;
  reqTechReviewsGrid1: any;
  displayActive: boolean;
  proID: string;
  requId: string;
  table: boolean;
  records: boolean;
  consultantName: string;
  jobTitle: string;
  statusConversion: any = {};
  interviewSataus: any;
  dateSelected: any;
  roleId: any;
  userId: any;
  showReview: boolean;
  conid: any;
  reviewerData: any = [];
  reviewerEmail: any;
  reviewerData1: any;
  entries: any = [];
  from: string;
  reqEdit: any;
  type: string;
  requestData: any;
  startCurDate: Date;
  addressline1: any;
  locationid: any;
  interviewtype: any;
  showDialog1: boolean;
  locationname: any;
  locdata: any;
  adddressline2: any;
  city: any;
  zip: any;
  country: any;
  livingCountry: any = [];
  stateOptions: any = [];
  state: any;
  parentReqId: any;
  usrroleid: any;
  createdByAccId: string;
  approveOrRejectResponse: any;
  // tslint:disable-next-line:max-line-length
  constructor(private http: HttpClient, private route: ActivatedRoute, private fb: FormBuilder, private breadcrumbService: BreadcrumbService, private apiService: ApisService, private router: Router, private datePipe: DatePipe, private messageService: MessageService) { }
  cols = [
    { field: 'reviewType', header: 'Interview Mode' },
    { field: 'scheduledDate', header: 'Date & Time' },
    { field: 'scheduledDate', header: 'Reviewed By' },
    { field: 'forwardedToName', header: 'Reviewer' },
    { field: 'status', header: 'Status' },
  ];
  @ViewChild('techReviewerFilter', { static: false })
  private techReviewerFilter;
  @HostListener('window:keydown', ['$event'])
  severity = [
    { label: 'Low', value: 'L' },
    { label: 'Medium', value: 'M' },
    { label: 'High', value: 'H' },
  ];
  HH: any = [];
  MM: any = [];
  hours: any = [];
  minutes: any = [];
  skillsFlag = false;
  faceFlag = false;
  skills: any = [];
  reviewers: any = [];
  filteredSkills: any[];
  filteredReviewers: any[];
  private skillFilter: ElementRef;
  skillBlocks: any = [];
  interviewModeForm: any;
  skillTotal: any = {};
  submitted = false;
  minDate: Date;
  minAlertDate: Date;
  maxAlertDate: Date;
  reqName: any;
  candidateName: any;
  pageCount: any;
  array: any = [];
  @ViewChild('reviewerFilter', { static: false }) private reviewerFilter;
  @ViewChild('skillFilter', { static: false }) set content(content) {
    if (content) {
      this.skillFilter = content;
    }
  }
  ngOnInit() {
    this.AddreqTechReviews = this.fb.group({
      interviewModeControl: ['', Validators.required],
      reviewer: ['', Validators.required],
      interviewDate: ['', Validators.required],
      zone: ['', Validators.required],
      location: ['', Validators.required],
      alertCheckbox: [''],
      alertDate: ['', Validators.required],
      hours: [{ value: '00', label: '00' }],
      minutes: [{ value: '00', label: '00' }],
      alertMessage: [''],
      severity: [{ label: 'Medium', value: 'M' }],
      HH: [{ label: '00', value: '00' }, Validators.required],
      MM: [{ label: '00', value: '00' }, Validators.required],
      totalQuestions: ['', Validators.required],
      comments: [''],
      skillset: [''],
      notes: [''],
      skillSetControls: this.skillSetControls,
      questionsAvailable: [''],
    });
    this.apiService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
      this.roleId = JSON.parse(this.apiService.decodeData()).roles[0].id;
    } else {
      this.roleId = this.usrroleid;
    }
    this.reqEdit = JSON.parse(sessionStorage.getItem('reqEdit'));
    this.requestData = JSON.parse(sessionStorage.getItem('requestData'));
    this.from = this.route.snapshot.queryParamMap.get('from');
    this.type = this.route.snapshot.queryParamMap.get('type');
    if (this.roleId === 5) {
      this.breadcrumbService.setItems([
        { label: 'Requirements', routerLink: '/project-requirements' },
        { label: 'Requirement Edit', routerLink: '/project-edit-requirement' },
        { label: 'Schedule Interview', routerLink: '/schedule-interview' },
      ]);
    }
    else if (this.roleId === 9) {
      if (this.from === 'Oedit') {
        this.breadcrumbService.setItems([
          { label: 'Open Positions', routerLink: '/open-positions' },
          { label: 'Open Position Edit', routerLink: '/open-position-edit' },
          { label: 'Schedule Interview', routerLink: '/schedule-interview' },
        ]);
      }
      else if (this.from === 'reqEdit') {
        this.breadcrumbService.setItems([
          { label: 'Requirements', routerLink: '/rec-requirements' },
          { label: 'Requirement Edit', routerLink: '/rec-requirement-edit' },
          { label: 'Schedule Interview', routerLink: '/schedule-interview' },
        ]);
      }
    }
    this.proID = this.route.snapshot.queryParamMap.get('proId');
    this.requId = this.route.snapshot.queryParamMap.get('requirementData');
    this.consultantName =
      this.route.snapshot.queryParamMap.get('consultantName');
    this.jobTitle = this.route.snapshot.queryParamMap.get('jobTitle');
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.reqId = routeParams.params.reqId;
    this.profd1 = routeParams.params.proId;
    this.conId = routeParams.params.conId;
    this.userId = routeParams.params.userId;
    this.createdByAccId = this.route.snapshot.queryParamMap.get('createdbyaccID')
    this.parentReqId = routeParams.params.parentreqId;
    for (let i = 0; i < 24; i++) {
      this.HH.push({ label: ('0' + i).slice(-2), value: ('0' + i).slice(-2) });
    }
    for (let j = 0; j < 12; j++) {
      this.MM.push({
        label: ('0' + j * 5).slice(-2),
        value: ('0' + j * 5).slice(-2),
      });
    }
    const data = {
      empName: '',
      orgId: sessionStorage.getItem('orgId'),
    };
    this.loader = true;
    this.AddreqTechReviews.controls.alertDate.disable();
    this.minDate = new Date();
    this.minDate.setMonth(new Date().getMonth());
    this.minDate.setFullYear(new Date().getFullYear());
    this.AddreqTechReviews.controls.zone.patchValue(this.zone[0]);
    this.subGridData = JSON.parse(sessionStorage.getItem('subGridData'));
    this.getInterviewStatus();
    this.getInterviewList();
    this.getZoneList();
    this.getLocationList();
    this.gridData();
    this.getstatues();
    this.getReviwerData();
    this.CountryDropdown();
    this.startCurDate = this.minDate;
  }
  selectedInterviewDate(event) {
    this.hours = [];
    this.minutes = [];
    this.minAlertDate = this.minDate;
    this.maxAlertDate = new Date(event);
    this.maxAlertDate.setMonth(new Date(event).getMonth());
    this.maxAlertDate.setFullYear(new Date(event).getFullYear());
    const hoursValue = new Date(this.maxAlertDate).getHours();
    const minutesValue = new Date(this.maxAlertDate).getMinutes();
    for (let i = 0; i <= hoursValue; i++) {
      this.hours.push({
        label: ('0' + i).slice(-2),
        value: ('0' + i).slice(-2),
      });
    }
    for (let i = 0; i <= minutesValue; i++) {
      this.minutes.push({
        label: ('0' + i).slice(-2),
        value: ('0' + i).slice(-2),
      });
    }
    this.AddreqTechReviews.controls.alertDate.enable();
    this.AddreqTechReviews.controls.alertDate.setValue(this.maxAlertDate);
    const maxHour = ('0' + new Date(this.maxAlertDate).getHours()).slice(-2);
    const maxMin = ('0' + new Date(this.maxAlertDate).getMinutes()).slice(-2);
    this.AddreqTechReviews.controls.hours.setValue({
      label: maxHour,
      value: maxHour,
    });
    this.AddreqTechReviews.controls.minutes.setValue({
      label: maxMin,
      value: maxMin,
    });
  }
  CountryDropdown() {
    this.apiService.getApi(environment.lookupsData + 'ADLC').subscribe((res) => {
      this.livingCountry = res.data;
    });
  }
  location(event, data) {
    this.locationid = data.locationId;
    this.interviewtype = data.activityAttributes;
    if (this.interviewtype === 'f') {
      this.showDialog1 = true;
      this.apiService.getApi(environment.getlocation + this.locationid).subscribe((res) => {
        this.locdata = res.data[0];
        this.locationname = this.locdata.name;
        this.addressline1 = this.locdata.address1;
        this.adddressline2 = this.locdata.address2;
        this.city = this.locdata.city;
        this.zip = this.locdata.zip;
        this.country = this.livingCountry.filter(x => x.code === this.locdata.country)[0].label;

        this.apiService.getApi(environment.state + '/' + this.livingCountry.filter(x => x.code === this.locdata.country)[0].code)
          .subscribe((result) => {
            this.stateOptions = result.data;
            this.state = this.stateOptions.filter(x => x.code === this.locdata.state)[0].label;
          });
      });
    }
  }
  goToLink(url) {
    const webdata = 'https://www.google.com/maps/search/' + this.locationname + ',' + this.city;
    const addressLink = `${webdata}`;
    window.open(addressLink, '_blank');
  }
  skillTotalEvent(event, obj, index) {
    // tslint:disable-next-line:max-line-length
    this.skillBlocks[index].weight = this.skillTotal[obj.skillName] = event.target.value > obj.value ? this.AddreqTechReviews.controls.questionsAvailable.patchValue(0) : parseInt(event.target.value);
    this.totalCalculation();
  }
  totalCalculation() {
    const totVal = Object.values(this.skillTotal).reduce(
      (a: any, b: any) => a + b,
      0
    );
    if (totVal) {
      this.AddreqTechReviews.controls.totalQuestions.setValue(totVal);
    } else {
      this.AddreqTechReviews.controls.totalQuestions.setValue(0);
    }
  }
  filterReviewers(event) {
    this.reviewerFilter.inputEL.nativeElement.focus();
    this.filteredReviewers = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.reviewers.length; i++) {
      const reviewer = this.reviewers[i].fullName;
      // tslint:disable-next-line: triple-equals
      if (reviewer.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredReviewers.push(reviewer);
      }
    }
  }
  getStatusType(data) {
    const statusgrid = this.sataus.find((x) => x.code === data).label;
    return statusgrid;
  }
  filterSkills(event) {
    // tslint:disable-next-line: no-string-literal
    this.skillFilter['inputEL'].nativeElement.focus();
    this.filteredSkills = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.skills.length; i++) {
      const skill = this.skills[i].skillName;
      // tslint:disable-next-line: triple-equals
      if (skill.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredSkills.push(skill);
      }
    }
    this.filteredSkills.sort();
  }
  severityChange() {
    this.AddreqTechReviews.controls.skillSetControls.patchValue('');
    this.AddreqTechReviews.controls.totalQuestions.reset();
    this.AddreqTechReviews.controls.questionsAvailable.reset();
    this.getQuestionsData(this.skillName, 'add');
  }
  interviewCheck(header) {
    this.interviewModeForm = header;
    const formValue = this.AddreqTechReviews.controls.interviewModeControl.value;
    this.AddreqTechReviews.reset();
    this.AddreqTechReviews.controls.interviewModeControl.patchValue(formValue);
    this.hours = [];
    this.minutes = [];
    this.display = false;
    this.submitted = false;
    // tslint:disable-next-line: forin
    for (const obj in this.skillTotal) {
      this.skillTotal[obj] = 0;
    }
    this.skillBlocks = [];
    this.skillsFlag = header.label === 'Online' || header.label === 'Psychometric';
    this.faceFlag = header.label === 'Face to Face';
    if (this.skillsFlag) {
      this.minAlertDate = new Date();
      this.minAlertDate.setMonth(new Date().getMonth());
      this.minAlertDate.setFullYear(new Date().getFullYear());
      this.maxAlertDate = new Date();
      this.maxAlertDate.setDate(this.maxAlertDate.getDate() + 1);
      this.maxAlertDate.setMonth(new Date(this.maxAlertDate).getMonth());
      this.maxAlertDate.setFullYear(new Date(this.maxAlertDate).getFullYear());
      const hoursValue = new Date(this.maxAlertDate).getHours();
      const minutesValue = new Date(this.maxAlertDate).getMinutes();
      for (let i = 0; i <= hoursValue; i++) {
        this.hours.push({
          label: ('0' + i).slice(-2),
          value: ('0' + i).slice(-2),
        });
      }
      for (let i = 0; i <= minutesValue; i++) {
        this.minutes.push({
          label: ('0' + i).slice(-2),
          value: ('0' + i).slice(-2),
        });
      }
      this.AddreqTechReviews.controls.alertDate.setValue(this.maxAlertDate);
      const maxHour = ('0' + new Date(this.maxAlertDate).getHours()).slice(-2);
      const maxMin = ('0' + new Date(this.maxAlertDate).getMinutes()).slice(-2);
      this.AddreqTechReviews.controls.hours.setValue({ label: maxHour, value: maxHour });
      this.AddreqTechReviews.controls.minutes.setValue({ label: maxMin, value: maxMin });
      this.AddreqTechReviews.controls.severity.setValue({ label: 'Medium', value: 'M' });
      this.AddreqTechReviews.controls.MM.setValue({ label: '00', value: '00' });
      this.AddreqTechReviews.controls.HH.setValue({ label: '00', value: '00' });
      this.AddreqTechReviews.controls.HH.enable();
      this.AddreqTechReviews.controls.MM.enable();
      this.AddreqTechReviews.controls.totalQuestions.enable();
      this.AddreqTechReviews.controls.alertDate.enable();
      this.AddreqTechReviews.controls.location.disable();
      this.AddreqTechReviews.controls.interviewDate.disable();
    } else {
      this.AddreqTechReviews.controls.interviewDate.enable();
      this.faceFlag ? this.AddreqTechReviews.controls.location.enable() : this.AddreqTechReviews.controls.location.disable();
      this.AddreqTechReviews.controls.interviewDate.enable();
      this.AddreqTechReviews.controls.HH.disable();
      this.AddreqTechReviews.controls.MM.disable();
      this.AddreqTechReviews.controls.totalQuestions.disable();
      this.AddreqTechReviews.controls.alertDate.disable();
    }
    if (header.label === 'Psychometric') {
    } else if (header.label === 'Online') {
      this.skills = JSON.parse(sessionStorage.getItem('loginData')).skillsmap;
      this.onlineQuestionSSkils = JSON.parse(
        sessionStorage.getItem('loginData')
      ).skillsmap;
    }
  }
  selectedSkill(value, deleted, added) {
    for (let i = 0; i < this[added].length; i++) {
      if (this[added][i].skillName === value) {
        this.skillTotal[value] = 0;
        this[deleted].push(...this[added].splice(i, 1));
        if (this.skillBlocks.length > 10) {
          this.errSkills = true;
          this.skillBlocks.splice(this.skillBlocks.length - 1, 1);
          setTimeout(() => {
            this.errSkills = false;
          }, 5000);
        }
        break;
      }
    }
    // tslint:disable-next-line: no-string-literal
    this.skillFilter['inputEL'].nativeElement.value = '';
    this.getQuestionsData(value, 'select skill called');
  }
  getQuestionsData(value, opeartion) {
    this.skillName = value;
    this.dataArray = [];
    if (opeartion === 'deleted') {
      const obj = this.onlineQuestionSSkils.filter(
        (x) => x.skillName === value
      )[0];
      const index = this.array.indexOf(obj.id);
      if (index > -1) {
        this.array.splice(index, 1);
        this.skillBlocksIdValues = this.array;
      }
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.skillBlocks.length; i++) {
      if (this.skillBlocks[i].skillName === value) {
        this.array.push(this.skillBlocks[i].id);
        this.skillBlocksIdValues = this.array;
        this.skillBlocks[i].id = [];
        const data = {
          skillCategoryArray: this.array.toString(), // this.skillBlocks[i].id
          techReviewSeverity: this.AddreqTechReviews.controls.severity.value.value,
          techExamType: this.interviewModeForm.label === 'Online' ? 'T' : 'S',
          orgId: sessionStorage.getItem('orgId'),
        };
        this.loader = true;
        this.questionRes = {};
        break;
      }
    }
  }
  dailogBoxValue() {
    this.display = this.AddreqTechReviews.controls.alertCheckbox.value[0];
  }
  backToTechReview() {
    if (this.roleId === 5) {
      this.router.navigate(['/project-edit-requirement']);
    }
    else if (this.roleId === 9) {
      if (this.from === 'Oedit') {
        this.router.navigate(['/open-position-edit']);
      }
      else {
        this.router.navigate(['/rec-requirement-edit']);
      }
    }
  }
  getInterviewList() {
    this.apiService.getApi(environment.getInterviewType).subscribe((res: any) => {
      this.interviewMode = res.data;
      this.interviewMode.forEach((element) => {
        this.typeTable[element.code] = element.label;
      });
    });
  }
  getZoneList() {
    this.apiService.getApi(environment.getZoneType).subscribe((res: any) => {
      this.zone = res.data;
    });
  }
  getLocationList() {
    const actId = JSON.parse(this.apiService.decodeData());
    this.apiService.getApi(environment.getLocationList + actId.accId).subscribe((res: any) => {
      this.locationList = res.data;
    });
  }
  getstatues() {
    this.apiService.getApi(environment.lookupsData + 'RPLPS').subscribe((res) => {
      this.sataus = res.data;
    });
  }
  getInterviewStatus() {
    this.apiService.getApi(environment.lookupsData + 'ATESL').subscribe((res: any) => {
      this.interviewSataus = res.data;
      this.interviewSataus.forEach((x) => {
        this.statusConversion[x.code] = x.label;
      });
    });
  }
  getReviwerData() {
    const actId = JSON.parse(this.apiService.decodeData());
    const aType = actId.accType === 'C' ? 12 : 10;
    this.apiService.getApi(environment.reviewer + actId.accId + '/' + aType).subscribe((res) => {
      this.reviewData = res.data;
      this.reviewroleID = this.reviewData[0].roleId;
    });
  }
  getRevType(data) {
    const revtype = this.interviewMode.find((x) => x.code === data).label;
    return revtype;
  }
  gridData() {
    this.loader = true;
    const pgNo = this.page;
    const recordPage = this.rows;
    this.apiService.getApi(environment.getInterviewGrid + pgNo + '/' + recordPage + '/' + this.proID).subscribe((res: any) => {
      this.reqTechReviewsGrid1 = res.data;
      this.loader = false;
      this.totalRecords = res.count;
      if (this.totalRecords !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  emailToast() {
    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
  }
  // It is used to copy an email from grid data to clipboard
  copyInputMessage(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridData();
  }
  toggle(data) { }
  reviewDetails(data) {
    this.showReview = true;
    this.reviewerData1 = data;
    this.entries = [];
    this.entries.push(this.reviewerData1);
    this.reviewerEmail = data.reviewerEmail;
  }
  Addschedule() {
    this.loader = true;
    const actId = JSON.parse(this.apiService.decodeData());
    this.conid = actId.id === 5 ? this.userId : this.conId;
    if (this.AddreqTechReviews.invalid) {
      // tslint:disable-next-line:forin
      for (const i in this.AddreqTechReviews.controls) {
        this.AddreqTechReviews.controls[i].markAsTouched();
        this.AddreqTechReviews.controls[i].updateValueAndValidity();
      }
    } else {
      this.dateSelected = this.AddreqTechReviews.controls.interviewDate.value;
      const reqBody = {
        parentReqId: this.parentReqId === undefined ? 0 : this.parentReqId,
        objectId: this.proID,
        reqId: +this.reqId,
        lkActivityType: actId.accType === 'V' || actId.accType === 'B' ? 8 : actId.accType === 'C' ? 5 : '',
        assignedTo: this.AddreqTechReviews.controls.reviewer.value ? this.AddreqTechReviews.controls.reviewer.value.id : '',
        conId: this.conid,
        accId: actId.accId,
        reviewType: (this.from === 'Oedit' && actId.accType === 'V' || this.from === 'Oedit' && actId.accType === 'B') ? 'V' : this.from === 'reqEdit' ? 'C' : actId.accType,
        // actId.accType === 'B' ? 'V' : actId.accType,
        interviewTime: this.datePipe.transform(this.dateSelected, 'yyyy-MM-dd h:mm:ss'),
        // tslint:disable-next-line:max-line-length
        activityAttribute: this.AddreqTechReviews.controls.interviewModeControl.value ? this.AddreqTechReviews.controls.interviewModeControl.value.code : 0,
        zone: this.AddreqTechReviews.controls.zone.value ? this.AddreqTechReviews.controls.zone.value.code : '',
        // tslint:disable-next-line:max-line-length
        locationId: this.AddreqTechReviews.controls.interviewModeControl.value.code === 'f' ? this.AddreqTechReviews.controls.location.value.locId : 0,
        createdByAccId: this.createdByAccId
      };
      this.apiService.postApi(environment.scheduleData, reqBody).subscribe((res: any) => {
        this.getData = res;
        this.loader = false;
        if (this.getData.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message });
          this.AddreqTechReviews.reset();
          this.gridData();
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error });
        }
      });
    }
  }
  showstatus(data) {
    this.displayreason = true;
    this.rejreason = data.comments;
  }
}
