import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss'],
  providers: [MessageService, DatePipe]
})
export class ResourceComponent implements OnInit {
  cols = [
    { field: 'name', header: 'Resource Name', width: '10%' },
    { field: 'startDate', header: 'Start Date', width: '10%' },
    { field: 'endDate', header: 'End Date', width: '10%' },
    { field: 'lkUtilization', header: 'Utilization', width: '10%' },
    { field: 'lkStatus', header: 'Status ', width: '12%' },
    { field: '', header: 'Action', width: '5%' },
  ];
  minDate: Date;
  projectName: any;
  resourcesForm: FormGroup;
  status: any = [];
  totalRecords: any;
  statuslist = {};
  records: boolean;
  rows: any = 10;
  page: any = 1;
  loader: boolean;
  gridData: any;
  table: boolean;
  projectId: any;
  displayAddResource: boolean;
  addResourceForm: FormGroup;
  editResourceForm: FormGroup;
  displayEditResource: boolean;
  items: { label: string; icon: string; command: () => void; }[];
  btnhide: boolean;
  editProjectId: any;
  first: any;
  pageCount: any;
  actId: any;
  accId: any;
  selectedbycustomer: any = [];
  queryprojectName: any;
  utilozationmsg: string;
  currentdate: string | number | Date;
  startCurDate: Date;
  startDate: any;
  editstartCurDate: void;
  statusdata: string;
  buttondisable: boolean;
  endStaus: boolean;
  endStauserr: boolean;
  editData: any;
  msg: string;
  actualProjectEndDate: any;
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.gridTable();
    }
  }
  // tslint:disable-next-line:max-line-length
  constructor(private breadcrumbService: BreadcrumbService, private route: ActivatedRoute, private fb: FormBuilder, private apisService: ApisService, private messageService: MessageService, private datePipe: DatePipe) {
    this.breadcrumbService.setItems([
      { label: 'Projects', routerLink: '/projects' },
      { label: 'Resources', routerLink: '/resource' },
    ]);
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.queryprojectName = routeParams.params.projectname;
    this.projectId = routeParams.params.id;
    this.statusdata = routeParams.params.lkStatus;
    this.actualProjectEndDate = routeParams.params.actualEndDate;
  }
  ngOnInit(): void {
    this.currentdate = new Date();
    this.resourcesForm = this.fb.group({
      startDate: [''],
      endDate: [''],
      status: ['']
    });
    this.addResourceForm = this.fb.group({
      addResourceName: ['', [Validators.required]],
      addResourceStatus: ['', [Validators.required]],
      addResourceUtilization: ['', [Validators.required, Validators.maxLength(3), Validators.pattern('^[0-9]+$')]],
      addResourceStartDate: ['', [Validators.required]],
      addResourceEndDate: ['']
    });
    this.editResourceForm = this.fb.group({
      editResourceName: [''],
      editResourceStatus: ['', [Validators.required]],
      editResourceUtilization: ['', [Validators.required, Validators.maxLength(3), Validators.pattern('^[0-9]+$')]],
      editResourceStartDate: ['', [Validators.required]],
      editResourceEndDate: [''],
      skills: ['', [Validators.maxLength(25)]],
      comments: ['', [Validators.maxLength(250)]]
    });
    this.startCurDate = this.currentdate;
    this.minDate = this.addResourceForm.controls.addResourceStartDate.value;
    this.actId = JSON.parse(this.apisService.decodeData());
    this.accId = this.actId.accId;
    if (this.statusdata === 'A') {
      this.buttondisable = false;
    } else {
      this.buttondisable = true;
    }
    this.gridstatus();
    this.gridTable();
  }
  gridstatus() {
    this.apisService.getApi(environment.lookupsData + 'RLS').subscribe((res: any) => {
      this.status = res.data;
      this.status.forEach(element => {
        this.statuslist[element.code] = [element.label];
      });
    }
    );
  }
  getselectedbycustomer() {
    // tslint:disable-next-line:max-line-length
    this.apisService.getApi(environment.lookupsData + 'candidate/not-in-project/' + this.accId + '/' + this.projectId).subscribe((res: any) => {
      this.selectedbycustomer = res.data;
    }
    );
  }
  resetResources() {
    this.resourcesForm.reset();
    this.gridTable();
  }
  gridTable() {
    this.loader = true;
    const pgNo = this.page;
    const recordPage = this.rows;
    // tslint:disable-next-line:max-line-length
    const status = this.resourcesForm.controls.status.value === null || this.resourcesForm.controls.status.value === undefined || this.resourcesForm.controls.status.value === '' ? '' : this.resourcesForm.controls.status.value.code;
    // tslint:disable-next-line:max-line-length
    const sdate = this.resourcesForm.controls.startDate.value === null || this.resourcesForm.controls.startDate.value === undefined || this.resourcesForm.controls.startDate.value === '' ? '' : this.datePipe.transform(this.resourcesForm.controls.startDate.value, 'MM-dd-yyyy');
    // tslint:disable-next-line:max-line-length
    const edate = this.resourcesForm.controls.endDate.value === null || this.resourcesForm.controls.endDate.value === undefined || this.resourcesForm.controls.endDate.value === '' ? '' : this.datePipe.transform(this.resourcesForm.controls.endDate.value, 'MM-dd-yyyy');
    this.apisService.getApi(environment.projectResources + pgNo + '/' + recordPage + '/' + this.projectId + '?status=' + status + '&startDate=' + sdate + '&endDate=' + edate).subscribe((res: any) => {
      this.loader = false;
      this.gridData = res.data;
      this.totalRecords = res.Count;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
      this.loader = false;
    });
  }
  toggle(event, data, rowData) {
    this.projectName = rowData.projectName;
    this.items = [{
      label: 'Edit', icon: 'pi pi-pencil', command: () => { this.editResource(rowData); }
    },
    ];
  }
  addResource() {
    this.addResourceForm.reset();
    this.getselectedbycustomer();
    this.addResourceForm.controls.addResourceStatus.patchValue(this.status.filter((x) => x.code === 'A')[0]);
    this.addResourceForm.controls.addResourceUtilization.patchValue(100);
    this.addResourceForm.controls.addResourceStartDate.patchValue(this.datePipe.transform(this.currentdate, 'MM-dd-yyyy'));
    this.displayAddResource = true;
  }
  editResource(rowData) {
    this.editResourceForm.reset();
    this.endStauserr = false;
    this.getselectedbycustomer();
    this.editData = rowData;
    this.editProjectId = rowData.id;
    this.displayEditResource = true;
    this.editResourceForm.patchValue({
      editResourceName: rowData.name,
      editResourceStatus: rowData.lkStatus,
      editResourceUtilization: rowData.lkUtilization,
      editResourceStartDate : this.datePipe.transform(rowData.startDate, 'MM-dd-yyyy'),
      editResourceEndDate : this.datePipe.transform(rowData.endDate, 'MM-dd-yyyy'),
      skills: rowData.skills,
      comments : rowData.comments
    });
    this.startDate = new Date(rowData.startCurDate);
    this.startDate = new Date(rowData.endDate);
    if (rowData.lkStatus === 'I') {
      this.endStaus = true;
    } else{
      this.endStaus = false;
    }
  }
  closeEdit() {
    this.displayEditResource = false;
  }
  resetaddResources() {
    this.addResourceForm.reset();
    this.addResourceForm.controls.addResourceStatus.patchValue(this.status.filter((x) => x.code === 'A')[0]);
    this.addResourceForm.controls.addResourceUtilization.patchValue(100);
    this.addResourceForm.controls.addResourceStartDate.patchValue(this.datePipe.transform(this.currentdate, 'MM-dd-yyyy'));
  }
  saveRsource() {
    if (this.addResourceForm.invalid) {
      const controls = this.addResourceForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    }
    else {
      const reqbody = {
        projectId: this.projectId,
        // tslint:disable-next-line:max-line-length
        profileId: this.addResourceForm.controls.addResourceName.value === null || this.addResourceForm.controls.addResourceName.value === undefined || this.addResourceForm.controls.addResourceName.value === '' ? '' : this.addResourceForm.controls.addResourceName.value.profileId,
        // tslint:disable-next-line:max-line-length
        usrId: this.addResourceForm.controls.addResourceName.value === null || this.addResourceForm.controls.addResourceName.value === undefined || this.addResourceForm.controls.addResourceName.value === '' ? '' : this.addResourceForm.controls.addResourceName.value.id,
        // tslint:disable-next-line:max-line-length
        lkStatus: this.addResourceForm.controls.addResourceStatus.value === null || this.addResourceForm.controls.addResourceStatus.value === undefined || this.addResourceForm.controls.addResourceStatus.value === '' ? '' : this.addResourceForm.controls.addResourceStatus.value.code,
        // tslint:disable-next-line:max-line-length
        lkUtilization: this.addResourceForm.controls.addResourceUtilization.value === null || this.addResourceForm.controls.addResourceUtilization.value === undefined || this.addResourceForm.controls.addResourceUtilization.value === '' ? '' : this.addResourceForm.controls.addResourceUtilization.value,
        // tslint:disable-next-line:max-line-length
        startDate: this.addResourceForm.controls.addResourceStartDate.value === null || this.addResourceForm.controls.addResourceStartDate.value === undefined || this.addResourceForm.controls.addResourceStartDate.value === '' ? '' : this.datePipe.transform(this.addResourceForm.controls.addResourceStartDate.value, 'yyyy-MM-dd'),
        // tslint:disable-next-line:max-line-length
        endDate: this.addResourceForm.controls.addResourceEndDate.value === null || this.addResourceForm.controls.addResourceEndDate.value === undefined || this.addResourceForm.controls.addResourceEndDate.value === '' ? '0000-00-00' : this.datePipe.transform(this.addResourceForm.controls.addResourceEndDate.value, 'yyyy-MM-dd')
      };
      this.btnhide = true;
      this.loader = true;
      this.apisService.postApi(environment.addProjectResources, reqbody).subscribe((res) => {
        this.btnhide = false;
        this.loader = false;
        if (res.hasOwnProperty('success') === true) {
          this.displayAddResource = false;
          this.gridTable();
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
        }
      });
    }
  }
  statusData() {
   this.endStauserr = false;
   if (this.editResourceForm.value.editResourceStatus === 'I') {
      this.endStaus = true;
      // this.editResourceForm.value.editResourceEndDate.patchValue(this.datePipe.transform(this.currentdate, 'MM-dd-yyyy'));
      this.editResourceForm.controls.editResourceEndDate.patchValue(this.datePipe.transform(this.currentdate, 'MM-dd-yyyy'));
      this.startDate = new Date(this.currentdate);
    } else{
      this.endStaus = false;
      this.editResourceForm.controls.editResourceEndDate.reset();
      this.editResourceForm.controls.editResourceEndDate.patchValue(this.datePipe.transform(this.editData.endDate, 'MM-dd-yyyy'));
      this.startDate = new Date(this.editData.endDate);
    }
  }
  updateResource() {
    if (this.editResourceForm.value.editResourceStatus === 'I' && this.editResourceForm.value.editResourceEndDate !== null) {
      this.endStauserr = false;
      if (this.editResourceForm.invalid) {
        const controls = this.editResourceForm.controls;
        Object.keys(controls).forEach((key) => {
          controls[key].markAsTouched();
        });
      }
      else {
        const reqbody = {
          // tslint:disable-next-line:max-line-length
          lkUtilization: this.editResourceForm.controls.editResourceUtilization.value === null || this.editResourceForm.controls.editResourceUtilization.value === undefined || this.editResourceForm.controls.editResourceUtilization.value === '' ? '' : this.editResourceForm.controls.editResourceUtilization.value,
          // tslint:disable-next-line:max-line-length
          lkStatus: this.editResourceForm.value.editResourceStatus === null || this.editResourceForm.value.editResourceStatus === undefined || this.editResourceForm.value.editResourceStatus === '' ? '' : this.editResourceForm.value.editResourceStatus,
          // tslint:disable-next-line:max-line-length
          endDate: this.editResourceForm.controls.editResourceEndDate.value === null || this.editResourceForm.controls.editResourceEndDate.value === undefined || this.editResourceForm.controls.editResourceEndDate.value === '' ? '0000-00-00' : this.datePipe.transform(this.editResourceForm.controls.editResourceEndDate.value, 'yyyy-MM-dd'),
          id: this.editProjectId,
          // tslint:disable-next-line: max-line-length
          skills: this.editResourceForm.controls.skills.value === null || this.editResourceForm.controls.skills.value === '' || this.editResourceForm.controls.skills.value === undefined ? '' : this.editResourceForm.controls.skills.value,
          // tslint:disable-next-line: max-line-length
          comments: this.editResourceForm.controls.comments.value === null || this.editResourceForm.controls.comments.value === '' || this.editResourceForm.controls.comments.value === undefined ? '' : this.editResourceForm.controls.comments.value,
        };
        this.btnhide = true;
        this.loader = true;
        this.apisService.putApi(environment.updateProjetResource, reqbody).subscribe((res): any => {
          this.btnhide = false;
          this.loader = false;
          if (res.hasOwnProperty('success') === true) {
            this.displayEditResource = false;
            this.gridTable();
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
          } else if (res.hasOwnProperty('failed') === true) {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
          } else {
            this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
          }
        });
      }
    } else if (this.editResourceForm.value.editResourceStatus === 'A' ) {
      this.endStauserr = false;
      const reqbody = {
        // tslint:disable-next-line:max-line-length
        lkUtilization: this.editResourceForm.controls.editResourceUtilization.value === null || this.editResourceForm.controls.editResourceUtilization.value === undefined || this.editResourceForm.controls.editResourceUtilization.value === '' ? '' : this.editResourceForm.controls.editResourceUtilization.value,
        // tslint:disable-next-line:max-line-length
        lkStatus: this.editResourceForm.value.editResourceStatus === null || this.editResourceForm.value.editResourceStatus === undefined || this.editResourceForm.value.editResourceStatus === '' ? '' : this.editResourceForm.value.editResourceStatus,
        // tslint:disable-next-line:max-line-length
        endDate: this.editResourceForm.controls.editResourceEndDate.value === null || this.editResourceForm.controls.editResourceEndDate.value === undefined || this.editResourceForm.controls.editResourceEndDate.value === '' ? '0000-00-00' : this.datePipe.transform(this.editResourceForm.controls.editResourceEndDate.value, 'yyyy-MM-dd'),
        id: this.editProjectId,
        // tslint:disable-next-line: max-line-length
        skills: this.editResourceForm.controls.skills.value === null || this.editResourceForm.controls.skills.value === '' || this.editResourceForm.controls.skills.value === undefined ? '' : this.editResourceForm.controls.skills.value,
        // tslint:disable-next-line: max-line-length
        comments: this.editResourceForm.controls.comments.value === null || this.editResourceForm.controls.comments.value === '' || this.editResourceForm.controls.comments.value === undefined ? '' : this.editResourceForm.controls.comments.value,
      };
      this.btnhide = true;
      this.loader = true;
      this.apisService.putApi(environment.updateProjetResource, reqbody).subscribe((res): any => {
        this.btnhide = false;
        this.loader = false;
        if (res.hasOwnProperty('success') === true) {
          this.displayEditResource = false;
          this.gridTable();
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message, });
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
        }
      });
    }
    else{
      this.endStauserr = true;
    }
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridTable();
  }
  getUtilization() {
    // tslint:disable-next-line:no-unused-expression
    this.addResourceForm.controls.addResourceUtilization.value;
    if (this.addResourceForm.controls.addResourceUtilization.value > 100) {
      this.utilozationmsg = 'Utilization should be less than or equal to 100';
      setTimeout(() => {
        this.utilozationmsg = '';
        this.addResourceForm.controls.addResourceUtilization.reset();
      }, 3000);
    }
    // tslint:disable-next-line:no-unused-expression
    this.editResourceForm.controls.editResourceUtilization.value;
    if (this.editResourceForm.controls.editResourceUtilization.value > 100) {
      this.utilozationmsg = 'Utilization should be less than or equal to 100';
      setTimeout(() => {
        this.utilozationmsg = '';
        this.editResourceForm.controls.editResourceUtilization.reset();
      }, 3000);
    }
  }
  endDateValidation() {
    if (new Date(this.addResourceForm.get('addResourceStartDate').value).getTime() <
      new Date(this.addResourceForm.get('addResourceEndDate').value).getTime()) {
      this.startCurDate = this.addResourceForm.get(
        'addResourceStartDate'
      ).value;
    } else {
      this.addResourceForm.get('addResourceEndDate').reset();
      this.startCurDate = this.addResourceForm.get(
        'addResourceStartDate'
      ).value;
    }
  }
  editendDateValidation() {
    this.editResourceForm.get('editResourceEndDate').reset();
    if (new Date(this.editResourceForm.get('editResourceStartDate').value).getTime() <
      new Date(this.editResourceForm.get('editResourceEndDate').value).getTime()) {
      this.startDate = this.editResourceForm.get('editResourceStartDate').value;
    } else {
      this.editResourceForm.get('editResourceEndDate').reset();
      this.startDate = this.editResourceForm.get(
        'editResourceStartDate'
      ).value;
    }
  }
  projectEndDateValidation() {
    if (new Date(this.editResourceForm.get('editResourceEndDate').value).getTime() > new Date(this.actualProjectEndDate).getTime()) {
      this.editResourceForm.get('editResourceEndDate').reset();
      this.msg = 'Resource end date should be less than project end date';
      setTimeout(() => {
        this.msg = '';
      }, 3000);
    }
  }
}
