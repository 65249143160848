import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-accountsactivity',
  templateUrl: './accountsactivity.component.html',
  styleUrls: ['./accountsactivity.component.scss'],
  providers: [MessageService, DatePipe]
})
export class AccountsactivityComponent implements OnInit {

  accountsActivityForm: FormGroup;
  loader: boolean;
  totalrecords: any;
  records: boolean;
  table: boolean;
  accountsactivitdata: any;
  accountnames: any = [];
  rows: any = 10;
  page: any = 1;
  first: any;
  pageCount: any;
  items: { label: string; icon: string; command: () => void; }[];
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private apiservice: ApisService, private breadcrumbService: BreadcrumbService, private route: Router) {
    this.breadcrumbService.setItems([
      { label: 'Accounts Activity', routerLink: '/accounts-activity' },
    ]);


  }
  cols = [
    { field: 'accountName', header: 'Name', width: '9%' },
    { field: 'activity', header: 'Activity', width: '7%' },
    { field: 'activityBy', header: 'Activity By', width: '7%' },
    { field: 'activityDate', header: 'Activity Date', width: '7%' },
    { field: 'action', header: 'Action', width: '3%' },
  ];
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.getaccountsActivity();
    }
  }
  ngOnInit(): void {
    this.accountsActivityForm = this.fb.group({
      AccountName: ['']
    });
    this.getAccounts();
    this.getaccountsActivity();
  }
  getaccountsActivity() {

    this.loader = true;
    // tslint:disable-next-line:max-line-length
    const accName = this.accountsActivityForm.controls.AccountName.value === null || this.accountsActivityForm.controls.AccountName.value === undefined || this.accountsActivityForm.controls.AccountName.value === '' ? '' : this.accountsActivityForm.controls.AccountName.value.id;
    // tslint:disable-next-line:max-line-length
    this.apiservice.getApi(environment.accountsActivity + this.page + '/' + this.rows + '/' + 'A' + '?accId=' + accName).subscribe((res: any) => {
      this.loader = false;
      this.accountsactivitdata = res.data.records;
      this.totalrecords = res.data.count;
      if (this.totalrecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  getAccounts() {
    this.apiservice.getApi(environment.lookupsData + 'account-names').subscribe((res) => {
      this.accountnames = res.data;
    });
  }
  // It is used to show action menu
  toggle(event, data) {
    this.items = [
      { label: 'Show Details', icon: 'fa fa-file-text', command: () => { this.routetodetails(data); }, },
    ];
  }
  // It is used to for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.getaccountsActivity();
  }
  reset() {
    this.accountsActivityForm.reset();
    this.getaccountsActivity();
  }
  routetodetails(data) {
    this.route.navigate(['/acc-adminmenu/acctdetails', data.accId], {
      queryParams: {
        getaccounts: data.accId,
        flag: 'AccActivity'
      }
    });
    this.apiservice.accActivity.next(data);
  }

}
