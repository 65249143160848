<p-card>
            <p-panel>  <p-header>
                <strong >Bank Name : </strong><strong class="headstyle">{{this.bankNameee}}</strong>
                <em class="pi pi-arrow-left panelBackRoute" (click)="backNavigate()"></em>
            </p-header>
          
            <form [formGroup]="bankCustSearch">
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Customer Name</label>
                        <input id="input" type="text" pInputText placeholder="Customer Name" autocomplete="off"
                            formControlName="custname" maxlength="30">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Country</label>
                        <p-dropdown [options]="countryDrp" placeholder="Select Country" optionLabel="label"
                            autocomplete="off" formControlName="country" [showClear]="true">
                        </p-dropdown>
                    </div>
                </div>
                <div class="btnsCSS1 btnalign">
                    <button pButton type="button" icon='pi pi-plus' label="Add" (click)="add()"
                       class="addhBtn"></button>
                    <button pButton type="button" icon='pi pi-refresh' label="Reset" class="addhBtn" (click)="reset()"
                       ></button>
                    <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn" (click)="gridData1()"></button>
                </div>
            </form>
        </p-panel>
</p-card><br>
<p-card class="pgpos">
    <div *ngIf="table">
    <p-table [columns]="cols" [value]="gridData" [autoLayout]="true" selectionMode="single" dataKey="edit"
    [responsive]="true" sortMode="multiple" [rowsPerPageOptions]="[10,20,30]" sortMode="multiple">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th id="gridHeader" *ngFor="let col of columns let i=index" [ngStyle]="{'width': col.width }"
                [pSortableColumn]="col.field">
                <span> {{col.header}} </span>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
        <tr>
            <td class="textCenter">{{data.name}}</td>
            <td class="textCenter">{{ctryLabel[data.lkCountry]}}</td>
            <td class="textCenter">{{regionLabel[data.lkRegion]}}</td>
            <td>
                <p-badge [value]="statusLabel[data.lkStatus]"
                    [ngClass]="data.lkStatus==='A'?'green': data.lkStatus === 'I'?'red': 'blue'">
                </p-badge>
            </td>

            <td class="textCenter action" ><span
                    class="p-column-title">Action</span>
                <a><em type='button' class="pi pi-ellipsis-v pointer acznsize" ></em></a>
            </td>
        </tr>
    </ng-template>
</p-table>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
            <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
        </div>
        <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
            <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
            (onPageChange)="paginate($event)"></p-paginator>
        </div>
    </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any bank customer details</em><br>
            </div>
        </div>
    </p-card>
    <form [formGroup]="custAddForm">
        <p-dialog class="dialogBox" header="Add Bank Details To Customer " [(visible)]="displayModal" [modal]="true"
            [style]="{width: '25%'}" [baseZIndex]="1" [draggable]="false" [resizable]="false"
            [contentStyle]="{'overflow': 'visible'}">
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <label class="inputfields">Bank Name</label>
                    <input id="input" type="text" pInputText placeholder="Bank Name" autocomplete="off" disabled="true" 
                    formControlName="banknameForm" maxlength="15">
                </div>
          
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <label class="inputfields">Customer Name<span class="mandatoryColor">*</span></label>
                    <p-dropdown [options]="custnameDrp" placeholder="Select Customer Name" optionLabel="customerName" autocomplete="off"
                        formControlName="custNameForm" [showClear]="true">
                    </p-dropdown>
                    <span class="validationtest"
                    *ngIf="custAddForm.get('custNameForm').touched && custAddForm.get('custNameForm').invalid && custAddForm.get('custNameForm').errors.required">
                    Please select customer name </span>
                </div>
            </div>
            <br>
            <div class="btnsCSS1">
                <button pButton type="button" icon='pi pi-times' label="Cancel" (click)="cancel()"
                    class="addhBtn"></button>&nbsp;
                <button pButton type="button" icon='pi pi-save' label="Save"  [disabled]="disable" (click)="saveData()"
                    class="addhBtn"></button>&nbsp;
            </div><br><br>
        </p-dialog>
    </form>
    <div *ngIf="!loader; else showLoader"></div>
    <ng-template #showLoader>
        <div class="loading-bar">
            <div>
                <div class="image-holder" class="col-sm-12 text-center laoderheight">
                    <div class="laodersize" >
                        <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif" class="loaderimg-height" >
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <p-toast position="bottom-right" key="br"></p-toast>