<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
    <p-accordion>
        <p-accordionTab [selected]="true">
            <p-header>
                <label> Add Vendor Requirement </label>
                <em class="pi pi-arrow-left backRoute" (click)="backNavigate()"></em>
            </p-header>
            <form [formGroup]="editRequirementsForm">
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Position Title<span class="mandatoryColor">*</span></label>
                        <div class="p-inputgroup">
                            <input autocomplete="off" type="text" pInputText placeholder="Position Title" maxlength="51"
                                formControlName="name">
                        </div>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.name.touched && this.editRequirementsForm.controls.name.invalid && this.editRequirementsForm.controls.name.errors.required">
                                please enter name
                            </span>
                            <span class="validationtest"
                                *ngIf=" this.editRequirementsForm.get('name').hasError('maxlength')">
                                Please enter 50 alphabets only
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                        <label class="inputfields">Position Category</label>
                        <p-dropdown readonly="true" [options]="positionData" optionLabel="jobCategory"
                            [showClear]="true" formControlName="positionCategory"
                            placeholder="Select Position Category">
                        </p-dropdown>
                        <div>
                            <span
                                *ngIf="this.editRequirementsForm.controls.positionCategory.touched && this.editRequirementsForm.controls.positionCategory.invalid && this.editRequirementsForm.controls.positionCategory.errors.required">
                                Please select position category
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                        <label class="inputfields">Job Role</label>
                        <p-dropdown readonly="true" optionLabel="title" [options]="minMaxRate" [showClear]="true"
                            (onChange)="getMinrate($event.value)" formControlName="requirementTitle"
                            placeholder="Select Job Role">
                        </p-dropdown>
                    </div>
                    <div class="p-col-3 p-md-3 p-sm-12">
                        <label class="inputfields">Department </label>
                        <p-dropdown readonly="true" [options]="departmentList" optionLabel="name" [showClear]="true"
                            formControlName="department" placeholder="Select Department"></p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                        <label class="inputfields">Min Rate</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                            <input autocomplete="off" type="text" readonly="true" pInputText placeholder="Min Rate"
                                readonly="true" placeholder="Min Rate" maxlength="9"
                                formControlName="requirementMinRate">
                            <span class="p-inputgroup-addon">/Hr</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                        <label class="inputfields">Max Rate <span class="mandatoryColor">*</span></label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span>
                            <input autocomplete="off" type="text" pInputText maxlength="9"
                                formControlName="requirementMaxRate" placeholder="Max Rate" pKeyFilter="num">
                            <span class="p-inputgroup-addon">/Hr</span>
                        </div>
                        <span class="validationtest"
                        *ngIf="this.editRequirementsForm.get('requirementMaxRate').touched && this.editRequirementsForm.get('requirementMaxRate').invalid && this.editRequirementsForm.get('requirementMaxRate').errors.required">
                        Please enter max rate
                    </span>
                        <span class="validationtest"
                            *ngIf="this.editRequirementsForm.get('requirementMaxRate').invalid && this.editRequirementsForm.get('requirementMaxRate').errors.pattern">
                            Please enter numbers only
                        </span>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 "></div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                        <label class="inputfields">Number of Positions<span class="mandatoryColor">*</span></label>
                        <input autocomplete="off" id="input" readonly="true" type="text" pInputText
                            placeholder="Number of Positions" maxlength="10" formControlName="requirementNoofPositions"
                            maxlength=4 pKeyFilter="num">
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.requirementNoofPositions.touched && this.editRequirementsForm.controls.requirementNoofPositions.invalid && this.editRequirementsForm.controls.requirementNoofPositions.errors.required">
                                Please enter number of positions
                            </span>
                        </div>
                    </div>
                    <!-- <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                        <label class="inputfields">Work Location<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="locationList" placeholder="Select Work Location" optionLabel="name"
                            [showClear]="true" readonly="true" formControlName="requirementWorkCenterLocation">
                        </p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.requirementWorkCenterLocation.touched && this.editRequirementsForm.controls.requirementWorkCenterLocation.invalid && this.editRequirementsForm.controls.requirementWorkCenterLocation.errors.required">
                                Please select work location
                            </span>
                        </div>
                    </div> -->
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory"
                        pTooltip="{{this.patchlocationString}}" tooltipPosition="top">
                        <label class="inputfields">Work Location<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="locationList" placeholder="Select Work Location" optionLabel="name"
                            [showClear]="true" formControlName="requirementWorkCenterLocation" appendTo="body"
                            (onChange)="saveLocation($event)">
                            <ng-template let-data pTemplate="item">
                                <div pTooltip="{{data.name + '\n' + data.address1+',' + data.city + ',' + data.state + ',' + data.country}}"
                                    tooltipPosition="right">
                                    {{ data.name }}
                                </div>
                            </ng-template>

                        </p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.requirementWorkCenterLocation.touched && this.editRequirementsForm.controls.requirementWorkCenterLocation.invalid && this.editRequirementsForm.controls.requirementWorkCenterLocation.errors.required">
                                Please select work location
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                        <label class="inputfields">Required Experience<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="getexperienceOptions" readonly="true" optionLabel="label"
                            [showClear]="true" placeholder="Select Required Experience "
                            formControlName="requirementReqExp">
                        </p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.requirementReqExp.touched && this.editRequirementsForm.controls.requirementReqExp.invalid && this.editRequirementsForm.controls.requirementReqExp.errors.required">
                                Please select required experience
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 "></div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                        <label class="inputfields">Hire Type<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="hireTypeData" readonly="true" placeholder="select Hire Type"
                            [showClear]="true" optionLabel="label" formControlName='requirementType'>
                        </p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.requirementType.touched && this.editRequirementsForm.controls.requirementType.invalid && this.editRequirementsForm.controls.requirementType.errors?.required">
                                Please select type
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory">
                        <label class="inputfields">Planned Start Date<span class="mandatoryColor">*</span></label>
                        <p-calendar id="popup" placeholder="MM-DD-YYYY" readonly="true" dateFormat="mm-dd-yy"
                            [showIcon]="true" [showButtonBar]="true" formControlName="requirementStartDate">
                        </p-calendar>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('requirementStartDate').touched && this.editRequirementsForm.get('requirementStartDate').invalid && this.editRequirementsForm.get('requirementStartDate').errors.required">
                                Please select planned start date
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 invalidMandatory" *ngIf='enddata'>
                        <label class="inputfields">Planned End Date<span class="mandatoryColor">*</span></label>
                        <p-calendar id="popup" placeholder="MM-DD-YYYY" readonly="true" dateFormat="mm-dd-yy"
                            [showIcon]="true" [showButtonBar]="true" formControlName="requirementEndDate">
                        </p-calendar>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('requirementEndDate').touched && this.editRequirementsForm.get('requirementEndDate').invalid && this.editRequirementsForm.get('requirementEndDate').errors.required">
                                Please select planned end date
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6" *ngIf='!enddata'></div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3" *ngIf='enddata'></div>
                    <div class="p-col-3 p-md-3 p-sm-12 invalidMandatory container">
                        <label class="inputfields">Billing Type<span class="mandatoryColor">*</span></label>
                        <p-dropdown [options]="billingData" formControlName="billingtype" optionLabel="label"
                            [showClear]="true" readonly="true" placeholder="Select Billing Type"></p-dropdown>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.get('billingtype').touched && this.editRequirementsForm.get('billingtype').invalid && this.editRequirementsForm.get('requirementsExp').errors.required">
                                Please Select Billing Type
                            </span>
                        </div>
                    </div>
                    <div class="p-col-3 p-md-3 p-sm-12 container">
                        <label class="inputfields">Duration</label>
                        <div class="p-inputgroup">
                            <!-- <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span> -->
                            <input id="input" type="text" autocomplete="off" readonly="true" formControlName="duration"
                                pInputText placeholder="Enter Duration" maxlength="30">
                            <span class="p-inputgroup-addon">Months</span>
                        </div>
                    </div>
                    <div class="p-col-3 p-md-3 p-sm-12 container">
                        <label class="inputfields">Per Week</label>
                        <div class="p-inputgroup">
                            <!-- <span class="p-inputgroup-addon">{{durationCal(0 | currency: currencyValue : true)}} </span> -->
                            <input id="input" type="text" placeholder="Enter Per Week" readonly="true"
                                autocomplete="off" formControlName="hrsperWeek" pInputText maxlength="30">
                            <span class="p-inputgroup-addon">Hrs</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 "></div>
                    <h1></h1>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 invalidMandatory">
                        <label>Employee approval required? </label>
                        <p-radioButton name="approval" value="Y" label="Yes" formControlName="approval" disabled="true"
                            class="reqApp" inputId="Yes"></p-radioButton>
                        <p-radioButton name="approval" value="N" label="No" formControlName="approval" disabled="true"
                            class="reqApp" inputId="No"></p-radioButton>
                    </div>

                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory skills">
                        <label class="inputfields">Skill Set<span class="mandatoryColor">*</span></label>
                        <p-multiSelect maxSelectedLabels="15" optionLabel="label" [options]="PreferredSkills"
                            [virtualScroll]="true" [itemSize]="34" placeholder="Select Skill Set"
                            formControlName="requirementSkillSet" (onChange)="skillsetOthers($event.value)">
                        </p-multiSelect>
                        <div class="skillstyle">
                            <label><strong>Skill Set: </strong></label>
                            <span >{{requirementSkillSet.toString()}}</span> 
                        </div>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.requirementSkillSet.touched && this.editRequirementsForm.controls.requirementSkillSet.invalid && this.editRequirementsForm.controls.requirementSkillSet.errors.required">
                                Please select skill set
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory skills">
                        <label class="inputfields">Preferred Skill Set</label>
                        <p-multiSelect maxSelectedLabels="15" [options]="PreferredSkills" optionLabel="label"
                            [virtualScroll]="true" [itemSize]="34" placeholder="Select Preferred Skill Set"
                            formControlName="requirementPreferredSkillSet">
                        </p-multiSelect>
                        <div class="skillstyle">
                            <label><strong>Preferred Skill Set: </strong></label>
                            <span >{{requirementPreferredSkillSet.toString()}}</span>
                            
                        </div>
                    </div>
                </div>
                <br>
                <div class="p-fluid p-grid" *ngIf="otherskilldata">
                    <div class="p-col-12 p-md-6 p-sm-12 p-lg-6">
                        <label class="inputfields">Other Skills<span class="mandatoryColor">*</span></label>
                        <textarea rows="5" maxlength="301" cols="30"
                            placeholder="Enter Other Skills (Ex: Angular, Java)" formControlName="otherskills"
                            (blur)=onBlur($event.target.value) pInputTextarea></textarea>
                        <span class="validationtest" *ngIf="this.editRequirementsForm.get('otherskills').touched &&
                             this.editRequirementsForm.get('otherskills').invalid &&
                              this.editRequirementsForm.get('otherskills').errors.required">
                            Please enter other skills
                        </span>
                        <span class="validationtest"
                            *ngIf=" this.editRequirementsForm.get('otherskills').hasError('maxlength')"> Please enter
                            300
                            alphabets only </span>
                    </div>
                    <div class="p-field p-col-12 p-md-6 p-sm-12 p-lg-6"></div>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                        <label class="inputfields">Responsibilities<span class="mandatoryColor">*</span></label>
                        <textarea autocomplete="off" pInputTextarea readonly="true" rows="8" cols="30"
                            placeholder="Responsibilities" formControlName="requirementResponsibilities"></textarea>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.requirementResponsibilities.touched && this.editRequirementsForm.controls.requirementResponsibilities.invalid && this.editRequirementsForm.controls.requirementResponsibilities.errors.required">
                                Please enter responsibilities
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                        <label class="inputfields">Description<span class="mandatoryColor">*</span></label>
                        <textarea autocomplete="off" pInputTextarea rows="8" cols="30" placeholder="Job Description"
                            formControlName="requirementDescription" readonly="true"></textarea>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.requirementDescription.touched && this.editRequirementsForm.controls.requirementDescription.invalid && this.editRequirementsForm.controls.requirementDescription.errors.required">
                                Please enter description
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                        <label class="inputfields">Qualifications<span class="mandatoryColor">*</span></label>
                        <textarea autocomplete="off" pInputTextarea readonly="true" rows="8" cols="30"
                            placeholder="Qualifications" formControlName="requirementQualification"></textarea>
                        <div>
                            <span class="validationtest"
                                *ngIf="this.editRequirementsForm.controls.requirementQualification.touched && this.editRequirementsForm.controls.requirementQualification.invalid && this.editRequirementsForm.controls.requirementQualification.errors.required">
                                Please enter qualifications
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                        <label class="inputfields">Comments</label>
                        <textarea pInputTextarea maxlength="501" rows="8" cols="30" placeholder="Comments"
                            formControlName="requirementComments"></textarea>
                        <span class="validationtest"
                            *ngIf=" this.editRequirementsForm.get('requirementComments').hasError('maxlength')">
                            Please enter 500 alphabets only
                        </span>
                    </div>
                </div>
                <br>
                <div class="btnsCSS1">
                    <button class="addhBtn" pButton type="submit" icon='pi pi-save' label="Create"
                        [disabled]="disbalebtn" (click)="requirementEditUpdate()"></button>
                </div><br><br>
            </form>
        </p-accordionTab>
    </p-accordion>
</p-card>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>