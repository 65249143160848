import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Stomp } from '@stomp/stompjs';
import { MessageService } from 'primeng/api';
import * as SockJS from 'sockjs-client';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-consultant-vendorregistration',
  templateUrl: './consultant-vendorregistration.component.html',
  styleUrls: ['./consultant-vendorregistration.component.scss'],
  providers: [DatePipe, MessageService],
})
export class ConsultantVendorregistrationComponent implements OnInit {
  registrationForm: FormGroup;
  EmployeeData: any;
  gender1 = [
    { name: 'Male', code: 'M' },
    { name: 'Female', code: 'F' },
  ];
  ofcEmailExten: any;
  ofcMailValidation: string;
  stateDataArray: any;
  countryData: any;
  loader = false;
  regRes: any;
  display: string;
  name: any;
  accountExistCheck: string;
  errMsg: string;
  vendorName: string;
  displayNumber: boolean;
  officenum: any;
  officenummsg: string;
  consultantNames: string;
  spinnerHide = false;
  // tslint:disable-next-line:no-inferrable-types
  checkHide: boolean = false;
  phSpinnerHide = false;
  phCheckHide = false;
  mailSpinnerHide = false;
  mailCheckHide = false;
  socket: WebSocket;
  stompClient: any;
  message1: any = [];
  emailres: any;
  displayBasic: boolean;
  disable: boolean;
  success: any;
  successMsg: any;
  website: any;
  countrycodedropdown: any = [];
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private router: Router, private route: ActivatedRoute, private messageService: MessageService) { }
  ngOnInit(): void {
    this.registrationForm = this.fb.group({
      customerName: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z0-9]*$'), Validators.maxLength(60)]],
      websiteURL: ['', [Validators.required, Validators.maxLength(60)]],
      NumOfEmployees: [''],
      firstName: ['', [Validators.required,Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(25)]],
      middleName: ['', [Validators.maxLength(15)]],
      lastName: ['', [Validators.required,Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(25)]],
      officePhone: ['', [Validators.required, Validators.maxLength(15)]],
      // tslint:disable-next-line:max-line-length
      officeEmail: ['', [Validators.required, Validators.pattern('^[[a-zA-Z0-9.+-_]+@[a-zA-Z0-9.+-_]+.[a-zA-Z]{2,5}]*$'), Validators.maxLength(60)], ],
      mobileDrp: [''],
      address1: ['', [Validators.maxLength(50)]],
      address2: ['', [Validators.maxLength(50)]],
      city: ['', [Validators.maxLength(20)]],
      country: ['', Validators.required],
      state: [''],
      gender: [''],
      mailbox: ['', Validators.maxLength(10)],
      zipcode: ['', Validators.maxLength(10)]
    });
    this.registrationForm.controls.mobileDrp.patchValue({
      code: '+1',
      flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png',
      id: '2',
      label: 'UNITED STATES',
    });
    this.registrationForm.controls.gender.patchValue({
      name: 'Male',
      code: 'M',
    });
    const routeParams: any = this.route.snapshot.queryParamMap;
    this.display = routeParams.params.vendorRegistrationForm;
    this.success = routeParams.params.success;
    if (this.display === 'V') {
      this.vendorName = 'Vendor';
    } else {
      this.vendorName = 'Company';
    }
    this.countryList();
    this.getdrop();
    this.employeeDD();
  }
  countryids(event) {
    if (event.value.label === 'INDIA') {
      this.registrationForm.controls.country.patchValue(this.countryData.filter((x) => x.code === 'IND')[0]);
    } else if (event.value.label === 'UNITED STATES') {
      this.registrationForm.controls.country.patchValue(this.countryData.filter((x) => x.code === 'USA')[0]);
    } else if (event.value.label === 'CANADA') {
      this.registrationForm.controls.country.patchValue(this.countryData.filter((x) => x.code === 'CAN')[0]);
    } else if (event.value.label === 'UNITED KINGDOM') {
      this.registrationForm.controls.country.patchValue(this.countryData.filter((x) => x.code === 'UK')[0]);
    } else if (event.value.label === 'AUSTRALIA') {
      this.registrationForm.controls.country.patchValue(this.countryData.filter((x) => x.code === 'AUS')[0]);
    } else if (event.value.label === 'BRAZIL') {
      this.registrationForm.controls.country.patchValue(this.countryData.filter((x) => x.code === 'BRA')[0]);
    } else if (event.value.label === 'CHINA') {
      this.registrationForm.controls.country.patchValue(this.countryData.filter((x) => x.code === 'CHN')[0]);
    } else if (event.value.label === 'SINGAPORE') {
      this.registrationForm.controls.country.patchValue(this.countryData.filter((x) => x.code === 'SGP')[0]);
    } else if (event.value.label === 'JAPAN') {
      this.registrationForm.controls.country.patchValue(this.countryData.filter((x) => x.code === 'JPN')[0]);
    }else if (event.value.label === 'SWITZERLAND') {
      this.registrationForm.controls.country.patchValue(this.countryData.filter((x) => x.code === 'SWS')[0]);
    } else if (event.value.label === 'MEXICO') {
      this.registrationForm.controls.country.patchValue(this.countryData.filter((x) => x.code === 'MEX')[0]);
    }else if (event.value.label === 'ITALY') {
      this.registrationForm.controls.country.patchValue(this.countryData.filter((x) => x.code === 'ITL')[0]);
    }else if (event.value.label === 'ISRAEL') {
      this.registrationForm.controls.country.patchValue(this.countryData.filter((x) => x.code === 'ISR')[0]);
    }else if (event.value.label === 'GERMANY') {
      this.registrationForm.controls.country.patchValue(this.countryData.filter((x) => x.code === 'GER')[0]);
    }else if (event.value.label === 'FRANCE') {
      this.registrationForm.controls.country.patchValue(this.countryData.filter((x) => x.code === 'FRA')[0]);
    }else if (event.value.label === 'SAUDI ARABIA') {
      this.registrationForm.controls.country.patchValue(this.countryData.filter((x) => x.code === 'SAU')[0]);
    }
  }
  getdrop() {
    this.countrycodedropdown = this.apisService.getdropdwon();
  }
  copyInputMessage(data) {
    data.select();
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
    data.setSelectionRange(0, 0);
  }
  employeeDD() {
    this.apisService.getApi(environment.lookupsData + 'EMP').subscribe((res: any) => {
      this.EmployeeData = res.data;
    });
  }
  getState(data) {
    if (data === null || '' || undefined) { }
    else {
      this.apisService.getApi(environment.states + data).subscribe((res) => {
        this.stateDataArray = res.data;
      });
    }
  }

  officeEmailExtensionCheck() {
    const email = this.registrationForm.controls.officeEmail.value;
    if (email === null || email === '' || email === undefined) {
    } else {
      if (this.registrationForm.controls.officeEmail.invalid && this.registrationForm.controls.officeEmail.errors.pattern) {
      }
      else {
        this.mailSpinnerHide = true;
        this.loader = true;
        this.apisService.getApi(environment.emailVaildation + email).subscribe((res) => {
          this.loader = false;
          this.ofcEmailExten = res;
          if (this.ofcEmailExten.validation) {
            this.mailSpinnerHide = false;
            this.ofcMailValidation = 'Email already exist.';
            this.registrationForm.get('officeEmail').reset();
            setTimeout(() => {
              this.ofcMailValidation = '';
            }, 3000);
          }
          else {
            this.mailSpinnerHide = false;
            this.mailCheckHide = true;
            this.sendMessage();
            setTimeout(() => {
              this.mailCheckHide = false;
            }, 3000);
            this.ofcMailValidation = '';
          }
        });
      }
    }
  }
  sendMessage() {
    this.loader = true;
    const email = this.registrationForm.controls.officeEmail.value;
    this.socket = new SockJS(environment.socketendpoint);
    this.stompClient = Stomp.over(this.socket);
    this.stompClient.connect({},
      fra => {
        this.stompClient.subscribe('/topic/verify-email/' + email, tick => {
          this.message1 = [];
          this.message1.push(tick.body);
          this.emailres = JSON.parse(this.message1[0]);
          if (this.emailres.success === false) {
            this.loader = false;
            this.displayBasic = true;
            this.disable = true;
          }
          else if (this.emailres.success === true) {
            this.successMsg = JSON.parse(tick.body);
            this.displayBasic = false;
            this.disable = false;
            this.stompClient.disconnect(); // Disconnected socket connection
            this.registrationForm.controls.officeEmail.disable();
          }
        });
        this.stompClient.send('/servicesBay/verify-email/' + email + '/noToken', {});
      });
  }
  countryList() {
    this.apisService.getApi(environment.lookupsData + 'adlc').subscribe((res) => {
      this.countryData = res.data;
      this.registrationForm.controls.country.patchValue(this.countryData.filter((x) => x.code === 'USA')[0]);
    });
    this.getState('USA');
  }
  reset() {
    this.loader = true;
    this.registrationForm.reset();
    this.registrationForm.controls.country.patchValue({
      code: 'USA',
      id: 'ADLC',
      label: 'UNITED STATES',
    });
    this.getState('USA');
    this.registrationForm.controls.mobileDrp.patchValue({
      code: '+1',
      flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png',
      id: '2',
      label: 'UNITED STATES',
    });
    this.registrationForm.controls.gender.patchValue({
      name: 'Male',
      code: 'M',
    });
    this.registrationForm.controls.officeEmail.enable();
    this.loader = false;
    this.accountExistCheck = '';
    this.errMsg = '';
  }
  register() {
    if (this.registrationForm.invalid) {
      const controls = this.registrationForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      const body = {
        accId: 0,
        accName: this.registrationForm.controls.customerName.value,
        accType: this.display || 'C',
        address1: this.registrationForm.controls.address1.value,
        address2: this.registrationForm.controls.address2.value,
        city: this.registrationForm.controls.city.value,
        country: this.registrationForm.controls.country.value.code,
        firstName: this.registrationForm.controls.firstName.value,
        gender: this.registrationForm.controls.gender.value.code,
        lastName: this.registrationForm.controls.lastName.value,
        mailBox: this.registrationForm.controls.mailbox.value,
        middleName: this.registrationForm.controls.middleName.value,
        noOfEmployees: this.registrationForm.controls.NumOfEmployees.value.code,
        officeMail: this.registrationForm.controls.officeEmail.value,
        officePhone: this.registrationForm.controls.officePhone.value,
        state: this.registrationForm.controls.state.value.code,
        webSite: this.registrationForm.controls.websiteURL.value,
        zip: this.registrationForm.controls.zipcode.value,
        // tslint:disable-next-line:max-line-length
        occode: this.registrationForm.controls.mobileDrp.value === null || this.registrationForm.controls.mobileDrp.value === '' || this.registrationForm.controls.mobileDrp.value.id === undefined ? '' : this.registrationForm.controls.mobileDrp.value.id.toString(),
        source: 'O'
      };
      this.disable = true;
      this.loader = true;
      this.apisService.postApi(environment.acctreg, body).subscribe((res) => {
        // tslint:disable-next-line:no-unused-expression
        this.regRes = res;
        this.loader = false;
        if (this.regRes.success === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.regRes.message });
          this.router.navigate(['/reg_success'], {
            queryParams: {
              reqname: this.regRes.success.orgName,
              mailid: this.regRes.success.from,
            },
          });
          this.registrationForm.reset();
          this.accountExistCheck = '';
          this.errMsg = '';
        } else if (this.regRes.success === false) {
          this.messageService.add({ key: 'br', severity: '',  life: 3000, detail: this.regRes.message });
        } else {
          this.messageService.add({  key: 'br',  severity: '',  life: 3000, detail: this.regRes.error });
        }
      });
    }
  }
  goto() {
    this.router.navigate(['/register']);
  }
  getPhon() {
    this.displayNumber = false;
    const num = this.registrationForm.controls.officePhone.value;
    if (num) {
      this.phSpinnerHide = true;
      this.apisService.getApi(environment.phonerequired + num).subscribe(res => {
        this.officenum = res;
        this.officenum = res.data.isNumberExisted;
        if (this.officenum === true) {
          this.phSpinnerHide = false;
          this.officenummsg = 'office number already exist.';
          setTimeout(() => {
            this.officenummsg = '';
          }, 5000);
          this.registrationForm.get('officePhone').reset();
        }
        else {
          this.phSpinnerHide = false;
          this.phCheckHide = true;
          setTimeout(() => {
            this.phCheckHide = false;
          }, 3000);
        }
      });
    }
  }
  customerName() {
    const name = this.registrationForm.controls.customerName.value;
    if (name) {
      this.spinnerHide = true;
      this.apisService.getApi(environment.NameValidation + '?accName=' + name).subscribe(res => {
        if (res.validation) {
          this.spinnerHide = false;
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
          this.registrationForm.get('customerName').reset();
          setTimeout(() => {
            this.consultantNames = '';
          }, 3000);

        } else {
          this.spinnerHide = false;
          this.checkHide = true;

          setTimeout(() => {
            this.checkHide = false;
          }, 3000);
          this.consultantNames = '';
        }
      });
    }
  }
  urlvalidation() {
    this.website = this.registrationForm.controls.websiteURL.value;
    if (true) {
      this.website = this.website.replace('http://www.', '');
      this.website = this.website.replace('http://', '');
      this.website = this.website.replace('http:', '');
    }
    if (true) {
      this.website = this.website.replace('https://www.', '');
      this.website = this.website.replace('https://', '');
      this.website = this.website.replace('https:', '');
    }
    if (true) {
      this.website = this.website.replace('www.', '');
    }
    this.registrationForm.controls.websiteURL.patchValue(this.website);
  }
}
