<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
    <form [formGroup]="appliedpositions">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Job Id</label>
                <input autocomplete="off" id="input" type="text" pInputText placeholder="Job Id"
                    formControlName="jobId">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Company</label>
                <p-dropdown placeholder="Select Company" [showClear]="true" formControlName="companyName" filter="true"
                    [options]="accountnamesres" optionLabel="employerName">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Position Title</label>
                <input autocomplete="off" id="input" type="text" pInputText placeholder="Position Title"
                    formControlName="jobTitle">
            </div>
        </div>
        <div class="btnsCSS1 alignbtn">
            <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" class="addhBtn"></button>
            <button pButton type="submit" icon='pi pi-search' label="Search" (click)="appliedopenpositiongrid()"
                class="addhBtn"></button>
        </div>
    </form>
</p-card>
<br>
<p-card class="pgpos">
    <div *ngIf="table">
        <p-table [columns]="cols" [value]="griddata" selectionMode="single" dataKey="edit" [responsive]="true"
            id="excel-table" sortMode="multiple">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
                <tr>
                    <td class="textCenter">{{data.jobId}}</td>
                    <td class="textCenter" pTooltip="{{data.customerName}}" tooltipPosition="top">{{data.customerName}}
                    </td>
                    <td class="textCenter" pTooltip="{{data.jobTitle}}" tooltipPosition="top">{{data.jobTitle}}</td>
                    <td class="textCenter" pTooltip="{{statusdata[data.profileState]}}" tooltipPosition="top">
                        {{statusdata[data.profileState]}}</td>
                    <td class="textCenter"><a>
                            <em (click)="actionpopup.toggle($event); toggle1($event, data)"
                                class="pi pi-ellipsis-v"></em>
                        </a></td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid ui-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-4 totalrec1">
                <h3 class="norecorddata ">Total records : {{totalRecords}}</h3>
                <p class="downpos1 pointer">
                    <span class="ui-column-title" (click)="downloadActionPopup.toggle($event); downLoadsToggle()"
                        (keyup)="exportExcel()">
                        <em class="pi pi-download downloadspace"></em>
                        <strong>Download</strong></span>
                </p>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)" #p></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any applied positions</em><br>
            </div>
        </div>
    </p-card>
    <p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="downloadOptions"></p-menu>
</p-card>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-dialog class="dialogBox" [(visible)]="displayModal" [draggable]="false" [modal]="true"
    [style]="{width: '30%',height:'60%'}">
    <p-header>Profile Activity
        <span class="Activity">Job Id : <a>{{this.jobid}}</a></span>
    </p-header>
    <p class="Activitycolor">Please find the below information</p>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-sm-12">
            <p-timeline [value]="histroy" align="alternate">
                <ng-template pTemplate="content" let-event>
                    <p-card>
                        <p class="aligncenter"> {{event.activity}}
                            <br>
                            <span class="Activitycolor">By {{event.activityBy}}</span>
                        </p>
                    </p-card>
                    <br>
                </ng-template>
                <ng-template pTemplate="opposite" let-event><span class="Activitycolor">{{event.activityDate |
                        date:'MM-dd-yyyy'}}</span>
                </ng-template>
            </p-timeline>
        </div>
    </div>
</p-dialog>