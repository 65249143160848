<p-toast position="bottom-right" key="br"></p-toast>
<form [formGroup]="interviewsForm">
    <p-card>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Status</label>
                <p-dropdown [options]="status" formControlName="status" [showClear]="true" placeholder="Select Status" optionLabel="label">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields">Interview Mode</label>
                <p-dropdown [options]="interviewMode" formControlName="interviewMode" [showClear]="true"
                    placeholder="Select Interview Mode" optionLabel="label"></p-dropdown>
            </div>
        </div>
        <div class="btnsCSS1 alignbtn">
            <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" class="addhBtn"></button>
            <button pButton type="button" icon='pi pi-search' label="Search" (click)="searchData()"
                class="addhBtn"></button>
        </div>
    </p-card><br>
    <p-card class="pgpos">
        <div *ngIf="isUploading1" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
            <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
        <div *ngIf="table">
            <p-table [columns]="cols" [value]="interviewGrid" selectionMode="single" dataKey="edit" [rows]="10"
                [responsive]="true" [rowsPerPageOptions]="[10,20,30]" sortMode="multiple" [autoLayout]="true">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th id="gridHeader" *ngFor="let col of columns; let i=index"
                            (click)="iconToggle[i] = !iconToggle[i]" [ngStyle]="{'width': col.width }"
                            [pSortableColumn]="col.field">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-columns="columns" let-rowIndex="rowIndex">
                    <tr>
                        <td class="textCenter"><span class="p-column-title">Job Id</span>{{data.reqId}}</td>
                        <td class="textCenter" pTooltip="{{data.reviewerName}}" tooltipPosition="top"><span
                                class="p-column-title">Reviewer Name</span>
                            <p class="nameDesc" [pTooltip]="(data.recruiterName)" tooltipPosition="top">
                                {{data.reviewerName }}</p>
                        </td>
                        <td class="textCenter"><span class="p-column-title">Reviewed By</span>
                            <p class="nameDesc" [pTooltip]="(data.recruiterBY)" tooltipPosition="top">{{data.reviewedBy }}</p>
                        </td>
                        <td class="textCenter"><span class="p-column-title">Interview Mode</span>{{interview[data.activityAttributes]}}</td>
                        <td class='textCenter'><span class="p-column-title">Date & Time</span>{{data.interviewDate}}
                        </td>
                        <td>
                            <p-badge [value]="statusConversion[data.lkActivityStatus]"
                                [ngClass]="data.lkActivityStatus ==='A'?'green': data.lkActivityStatus === 'R'?'red': 'blue'">
                            </p-badge>
                        </td>
                        <td class="textCenter"><span class="p-column-title">Action</span><a>
                                <em (click)="actionpopup.toggle($event); toggle($event, data)"
                                    class="pi pi-ellipsis-v pointer"></em></a>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr *ngIf="interviewGrid?.length==0" class="aligncenter">
                        <td colspan="6" class="mandatoryColor"> No Records </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                    <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-10 ">
                    <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                        (onPageChange)="paginate($event)"></p-paginator>
                </div>
            </div>
        </div>
        <p-card *ngIf="records">
            <div class="norecordImgsize">
                <div *ngIf="records" class="aligncenter">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png"
                        class="norecordimg">
                    <br><em>Sorry, we are not able to find any interview details</em><br>
                </div>
            </div>
        </p-card>
    </p-card>
</form>
<p-dialog [(visible)]="approveOrReject" header="Interview Approval" [resizable]="false" [modal]="true"
    [responsive]="true" [style]="{width: '25%', minWidth: '180px'}" [minY]="70">
    <form [formGroup]="approveOrRejectForm">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <p>Do you want to approve the interview ?</p>
            </div>
            <div class="p-col-6 p-md-6 p-sm-12">
                <p-radioButton name="approveOrReject" value="Approved" label="Yes" formControlName="approveOrReject"
                class="radiospace" inputId="opt1" (click)="approveRequirement()">
            </p-radioButton>
            <p-radioButton name="approveOrReject" value="Rejected" label="No" formControlName="approveOrReject"
                inputId="opt2" (click)="rejectRequirement()">
            </p-radioButton>
            </div>
        </div>
        <div class="p-grid p-fluid ">
            <div class="p-col-8 p-md-12 p-sm-12" *ngIf="rejectForComments">
                <label class="first">Reason for Rejection<span class="mandatoryColor">*</span></label>
                <textarea autocomplete="off" pInputTextarea [rows]="2" [cols]="30" placeholder="Comments"
                    formControlName="comments" maxlength=201></textarea>
                <span class="validationtest" *ngIf="approveOrRejectForm.get('comments').invalid && approveOrRejectForm.get('comments').touched &&
          this.approveOrRejectForm.get('comments').errors.required">Please enter reason for rejection</span>
          <span class="validationtest" *ngIf="this.approveOrRejectForm.get('comments').hasError('maxlength')">
            Please enter 200 alphabets only
        </span>
            </div>
        </div><br>
        <div class="btnsCSS1">
            <button pButton type="button" icon='pi pi-times' label="Cancel" class="rejnbtn"
                (click)="approveOrReject = false"></button>
            <button pButton type="button" icon='pi pi-check-circle' label="Submit" class="rejnbtn" [disabled]="disable"
                (click)="submitForApproveOrReject()"></button><br>
        </div>
    </form>
</p-dialog>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-dialog class="dialogBox" [(visible)]="showDialog1" [modal]="true" [style]="{width: '40%'}" [baseZIndex]="1"
    [draggable]="false" [resizable]="false">
    <p-header>Interview Location
        <a> <span class="map" pTooltip="Navigate to google map" tooltipPosition="top" (click)="goToLink($event)">View on
                map</span>
            <span class="pi pi-map-marker"></span>
        </a>
    </p-header>
    <div>
        <div class="p-grid p-fluid">
            <br>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Location Name : <strong class="loc-color">{{ this.locationname}}</strong></label>
            </div>
        </div>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Address Line 1 : <strong class="loc-color"> {{ this.addressline1}}</strong></label>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Address Line 2 : <strong class="loc-color"> {{ this.adddressline2}} </strong>
                </label>
            </div>
        </div>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Country : <strong class="loc-color">{{this.country}}</strong> </label>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">State : <strong class="loc-color">{{this.state}}</strong> </label>
            </div>
        </div>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">City : <strong class="loc-color">{{ this.city}}</strong> </label>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Zip : <strong class="loc-color"> {{ this.zip}}</strong></label>
            </div>
        </div>
        <br>
        <h2></h2>
    </div>
</p-dialog>
<p-menu class="menuclass" #actionpopup [popup]="true" [model]="items"></p-menu>
<p-dialog class="dialogBox" [(visible)]="displayModal" [draggable]="false" [modal]="true"
    [style]="{width: '30%',height:'60%'}">
    <p-header>Interview Activity
        <span class="Activity">Job Id :<a class="job-sty">{{this.empId}}</a></span>
    </p-header>
    <div class="Activity-color">Please find the below information</div><br>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-sm-12">
            <p-timeline [value]="histroy" align="alternate">
                <ng-template pTemplate="content" let-event>
                    <p-card>
                        <p class="aligncenter"> {{event.activity}}
                            <br>
                            <span class="Activity-color">By {{event.activityBy}}</span>
                        </p>
                    </p-card>
                    <br>
                </ng-template>
                <ng-template pTemplate="opposite" let-event><span class="Activity-color">{{event.activityDate |
                        date:'MM-dd-yyyy'}}</span>
                </ng-template>
            </p-timeline>
        </div>
    </div>
</p-dialog>