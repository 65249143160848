<p-card>
  <form [formGroup]="teamsForm">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields"> First Name</label>
        <input id="input" type="text" pInputText placeholder="First Name" autocomplete="off" formControlName="firstName"
          maxlength="30">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Last Name</label>
        <input id="input" type="text" pInputText placeholder="Last Name" autocomplete="off" formControlName="lastName"
          maxlength="30">
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Email</label>
        <input id="input" type="text" pInputText placeholder="Email" autocomplete="off" formControlName="emailid"
          maxlength="60">
        <div>
        </div>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Phone</label>
        <p-inputMask autocomplete="off" mask="(999) 999-9999" formControlName="phone" placeholder="Phone">
        </p-inputMask>
      </div>
    </div>
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
        <label class="inputfields">Status</label>
        <p-dropdown [options]="status" [showClear]="true" placeholder="Select Status" optionLabel="label"
          formControlName="status">
        </p-dropdown>
      </div>

    </div>

    <div class="btnsCSS1" style="margin-top: -37px;">
      <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" style="margin-right: 6px;"
        class="addhBtn"></button>
      <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn" (click)="getTeamData()"></button>

    </div>
  </form>
</p-card>
<br>
<p-card class="pgpos">
  <div *ngIf="table">

    <p-table [value]="grid" [columns]="cols" selectionMode="single" dataKey="edit" [responsive]="true"
      sortMode="multiple">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
            [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
            <span>
              {{col.header}}

            </span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td class="textCenter skill-desc" tooltipPosition="top" pTooltip="{{data.name}}"><span class="p-column-title">
              Name</span>{{data.name}}</td>

          <td class="textCenter skill-desc" tooltipPosition="top" pTooltip="{{data.email}}" (click)="emailToast()"
            (click)="copyInputMessage(data.email)"><span class="p-column-title">
              Email</span><a style="cursor: pointer;">{{data.email}}</a></td>

          <td class="textCenter skill-desc" tooltipPosition="top" pTooltip="{{roletable(data.roleList)}}"><span
              class="p-column-title">
              Role</span>{{roletable(data.roleList)}}</td>

          <td class="textCenter"><span class="p-column-title">
              Phone</span>{{data.officePhone}}</td>

          <td class="textCenter">
            <p-badge [value]="statuTable[data.status]"
              [ngClass]="data.status ==='A'?'green': data.status === 'I'?'red': 'blue'">
            </p-badge>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-grid ui-fluid ppos">
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
        <h3 class="norecorddata" *ngIf="totalRecords !== 0">Total records : {{this.totalRecords}}</h3>
      </div>
      <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
        <p-paginator (onPageChange)="paginate($event)" [rows]="10" [totalRecords]="totalRecords"
          [rowsPerPageOptions]="[10,20,30]"></p-paginator>
      </div>
    </div>
  </div>
  <p-card *ngIf="records">
    <div style="height: 360px;">
      <div *ngIf="records" style="text-align: center;">
        <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
        <br><em>Sorry, we are not able to find any teams</em><br>
      </div>
    </div>
  </p-card>
</p-card>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center" style="height:520px">
        <div style="position: absolute;left:50%;top:50%;transform:translate(-50%,-50%)">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif" style="height:8em">
        </div>
      </div>
    </div>
  </div>
</ng-template>
<p-toast position="bottom-right" key="br"></p-toast>