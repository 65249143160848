import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { ApisService } from '../services/apis.service';
@Component({
  selector: 'app-projectvendor',
  templateUrl: './projectvendor.component.html',
  styleUrls: ['./projectvendor.component.scss'],
  providers: [DatePipe, MessageService],
})
export class ProjectvendorComponent implements OnInit {
  vendorForm: FormGroup;
  cols = [
    { field: 'name', header: 'Vendor Name', width: '10%' },
    { field: 'webSite', header: 'Corporate Website', width: '10%' },
    { field: 'phone', header: 'Phone Number', width: '10%' },
    { field: 'status', header: 'Status', width: '10%' },
    { field: 'industry', header: 'Industry', width: '10%' },
    { field: '', header: 'Action', width: '5%' },
  ];
  accId: any;
  page: any = 1;
  rows: any = 10;
  loading: boolean;
  totalrecods: any;
  records: boolean;
  table: boolean;
  first: any;
  pageCount: any;
  vendorAssociatedStaffingMode: any;
  vendorAssociatedCustomertableData: any;
  statusDD: any;
  getStatusTable = {};
  industry: any;
  getTableIndustry = {};
  state: any;
  country: any;
  statusLabel: any;
  rowData: any;
  items: { label: string; icon: string; command: () => void; }[];
  name: any;
  // tslint:disable-next-line:max-line-length
  constructor(private route: ActivatedRoute, private breadcrumbService: BreadcrumbService, private fb: FormBuilder, private router: Router, private apisService: ApisService) {
    this.breadcrumbService.setItems([
      { label: 'Vendors', routerLink: '/projectvendor' }
    ]);
  }
  ngOnInit(): void {
    const userdetails = JSON.parse(this.apisService.decodeData());
    this.accId = userdetails.accId;
    this.vendorForm = this.fb.group({
      vendorName: [''],
      vendorUrl: [''],
      vendorStatus: [''],
      vendorIndustry: [''],
      vendorCountry: [''],
      vendorState: [''],
      vendorStaffingMode: ['']
    });
    this.searchData();
    this.getCountryDropdown();
    this.getStatusData();
    this.getIndustryDropdown();
  }
  // gridData() {
  //   const rows = this.rows;
  //   const page = this.page;
  //   const data = page + '/' + rows;
  //   this.loading = true;
  //   // tslint:disable-next-line: deprecation
  //   this.apisService.getApi(environment.vendorAccsocitegrid + data).subscribe((res: any) => {
  //     this.vendorAssociatedCustomertableData = res.data;
  //     this.loading = false;
  //     this.totalrecods = res.total;
  //     if (this.totalrecods === 0) {
  //       this.records = true;
  //       this.table = false;
  //     } else {
  //       this.records = false;
  //       this.table = true;
  //     }
  //   });
  // }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.searchData();
  }
  searchData() {
    const associatedStaffingMode = this.vendorForm.controls.vendorStaffingMode.value;
    this.vendorAssociatedStaffingMode = [];
    if (this.vendorForm.controls.vendorStaffingMode.value) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < associatedStaffingMode.length; i++) {
        this.vendorAssociatedStaffingMode.push(associatedStaffingMode[i].value);
      }
    }
    const staffingMode = this.vendorForm.controls.vendorStaffingMode.value;
    this.loading = true;
    const pgNo = this.page;
    const recordPage = this.rows;
    const accountName = this.vendorForm.controls.vendorName.value === null ? '' : this.vendorForm.controls.vendorName.value === undefined ? '' : this.vendorForm.controls.vendorName.value === '' ? '' : this.vendorForm.controls.vendorName.value;
    // tslint:disable-next-line: max-line-length
    const accountUrl = this.vendorForm.controls.vendorUrl.value === null ? '' : this.vendorForm.controls.vendorUrl.value === undefined ? '' : this.vendorForm.controls.vendorUrl.value === '' ? '' : this.vendorForm.controls.vendorUrl.value;
    // tslint:disable-next-line: max-line-length
    const accCountry = this.vendorForm.controls.vendorCountry.value === null || '' ? '' : this.vendorForm.controls.vendorCountry.value.code === undefined ? '' : this.vendorForm.controls.vendorCountry.value.code;
    // tslint:disable-next-line: max-line-length
    const accState = this.vendorForm.controls.vendorState.value === null || this.vendorForm.controls.vendorState.value === '' || this.vendorForm.controls.vendorState.value.code === undefined ? '' : this.vendorForm.controls.vendorState.value.code;
    // tslint:disable-next-line: max-line-length
    const accountIndustry = this.vendorForm.controls.vendorIndustry.value === null || '' ? '' : this.vendorForm.controls.vendorIndustry.value.code === undefined ? '' : this.vendorForm.controls.vendorIndustry.value.code;
    // tslint:disable-next-line: max-line-length
    const status = this.vendorForm.controls.vendorStatus.value === null || this.vendorForm.controls.vendorStatus.value === undefined || this.vendorForm.controls.vendorStatus.value === '' ? '' : this.vendorForm.controls.vendorStatus.value.code;
    // tslint:disable-next-line: max-line-length
    const associatedSearch = pgNo + '/' + recordPage + '?name=' + accountName + '&country=' + accCountry + '&state=' + accState + '&url=' + accountUrl + '&industry=' + accountIndustry + '&status=' + status;
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.vendorAccsocitegrid + associatedSearch).subscribe(res => {
      this.vendorAssociatedCustomertableData = res.data;
      this.totalrecods = res.total;
      // this.vendorForm.controls.vendorState.value?.code === '';
      // console.log('this.vendorForm.controls.vendorState.value?.code',this.vendorForm.controls.vendorState.value?.code)
      if (this.totalrecods === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
      // this.vendorForm.controls.vendorState.value.code = undefined;
      this.loading = false;
    });
  }
  reset() {
    this.vendorForm.reset();
    this.records = false;
    this.searchData();
    this.getCountryDropdown();
    this.getStatusData();
    this.getIndustryDropdown();
  }
  getCountryDropdown() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'adlc').subscribe(res => {
      this.country = res.data;
    });
  }
  getStateByUrl(country) {
    this.vendorForm.controls.vendorState.setValue('');
    this.apisService.getApi(environment.states + country).subscribe(res => {
      this.state = res.data;
    });
  }
  getIndustryDropdown() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'ALIT').subscribe(res => {
      this.industry = res.data;
      this.industry.forEach(element => {
        this.getTableIndustry[element.code] = element.label;
      });
    });
  }
  goToLink(url: string) {
    const addressLink = url.includes('http') ? url : `http://${url}`;
    window.open(addressLink, '_blank');
  }
  getStatusData() {
    // tslint:disable-next-line: deprecation
    this.apisService.getApi(environment.lookupsData + 'RLS').subscribe(res => {
      this.statusDD = res.data;
      this.statusDD.forEach(element => {
        this.getStatusTable[element.code] = element.label;
      });
    }
    );
  }
  toggle(event, data) {
    this.rowData = data;
    sessionStorage.setItem('id', this.rowData.id);
    this.items = [
      { label: 'Sales Info', icon: 'pi pi-info-circle', command: () => { this.salesscreen(); }, },
    ];
  }
  salesscreen() {
    this.name = this.rowData.name;
    this.router.navigate(['/salesinfo'], {
      queryParams: {
        name: this.name,
      }
    }
    );
  }
}
