import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { DatePipe } from '@angular/common';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-employer-calender',
  templateUrl: './employer-calender.component.html',
  styleUrls: ['./employer-calender.component.scss'],
  providers: [DatePipe, MessageService],
})
export class EmployerCalenderComponent implements OnInit {
  currentDate: Date;
  // tslint:disable-next-line:max-line-length
  options: { plugins: import('@fullcalendar/core/plugin-system').PluginDef[]; defaultDate: Date; header: { left: string; center: string; right: string; }; editable: boolean; };
  acctdetails: any;
  events: any[];
  data: any;
  display: boolean;
  toolData: any;
  constructor(private apisService: ApisService, private breadcrumbService: BreadcrumbService, ) {
    this.breadcrumbService.setItems([
      { label: 'Calendar', routerLink: '/employee-calender' },
    ]);
   }
  ngOnInit() {
    this.acctdetails = JSON.parse(this.apisService.decodeData());
    this.currentDate = new Date();
    this.options = {
      plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin ],
      defaultDate: this.currentDate,
      header: {
          left: 'prev,next',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      editable: true
  };
    this.calender();
  }
 calender(){
  this.apisService.getApi(environment.calender + this.acctdetails.userId).subscribe((res: any) => {
    this.data = res.data.records;
    this.events = this.data;
  });
 }
}
