<p-card>
            <form [formGroup]="bankSearch">
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Name</label>
                        <input id="input" type="text" pInputText placeholder="Name" autocomplete="off"
                            formControlName="bankName1" maxlength="30">
                    </div>
                    <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                        <label class="inputfields">Beneficiary Name</label>
                        <input id="input" type="text" pInputText placeholder="Beneficiary Name" autocomplete="off"
                            formControlName="beneficiaryName" maxlength="60">
                    </div>
                </div>
                <div class="btnsCSS1 btnalign">
                    <button pButton type="button" icon='pi pi-plus' label="Add" (click)="addNewBank()" 
                        class="addhBtn"></button>
                    <button pButton type="button" icon='pi pi-refresh' label="Reset" class="addhBtn" 
                        (click)="reset()"></button>
                    <button pButton type="button" icon='pi pi-search' label="Search" class="addhBtn"
                        (click)="gridTable()"></button>
                </div>
            </form>
</p-card><br>
<p-card class="pgpos">
    <div *ngIf="table">
        <p-table [columns]="cols" [value]="gridData" [autoLayout]="true" selectionMode="single" dataKey="edit"
            [responsive]="true" sortMode="multiple" [rowsPerPageOptions]="[10,20,30]" sortMode="multiple">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns let i=index" [ngStyle]="{'width': col.width }"
                        [pSortableColumn]="col.field">
                        <span> {{col.header}} </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowIndex="rowIndex" let-data>
                <tr>
                    <td> {{data.bankName}} </td>
                    <td> {{data.beneficiaryName}} </td>
                    <td> {{data.swiftCode}} </td>
                    <td> {{data.createdBy}} </td>
                    <td> {{data.customerCount}} </td>
                    <td class="textCenter"><span class="p-column-title">Action</span><a><em
                                (click)="downloadActionPopup.toggle($event);toggle($event,data)"
                                class="pi pi-ellipsis-v pointer"></em> </a></td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any bank details</em><br>
            </div>
        </div>
    </p-card>
    <p-dialog class="dialogBox" header="{{headerTitle}} Bank Details" [(visible)]="displayAddModel" [modal]="true"
        [contentStyle]="{'overflow': 'visible'}" [style]="{width: '45%'}" [draggable]="false">
        <form [formGroup]="bankForm1">
            <div class="p-grid p-fluid ippos">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">Bank Name<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText placeholder="Name" autocomplete="off"
                        formControlName="bankName" maxlength="51">
                    <span class="validationtest"
                        *ngIf="bankForm1.get('bankName').touched && bankForm1.get('bankName').invalid && bankForm1.get('bankName').errors.required">
                        Please enter bank name </span>
                    <span class="validationtest" *ngIf="this.bankForm1.get('bankName').hasError('maxlength')">
                        Please enter 50 alphabets only
                    </span>
                    <span class="validationtest"
                        *ngIf="this.bankForm1.controls.bankName.invalid && this.bankForm1.controls.bankName.errors.pattern">Please enter
                        alphabets only</span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">Swift Code<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText placeholder="Swift Code" autocomplete="off"
                        formControlName="swiftCode" maxlength="15">
                    <span class="validationtest"
                        *ngIf="bankForm1.get('swiftCode').touched && bankForm1.get('swiftCode').invalid && bankForm1.get('swiftCode').errors.required">
                        Please enter swift code </span>
                    <span class="validationtest" *ngIf="this.bankForm1.get('swiftCode').hasError('maxlength')">
                        Please enter 14 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">IFSC Code<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText placeholder="IFSC Code" autocomplete="off"
                        formControlName="ifscCode" maxlength="15">
                    <span class="validationtest"
                        *ngIf="bankForm1.get('ifscCode').touched && bankForm1.get('ifscCode').invalid && bankForm1.get('ifscCode').errors.required">
                        Please enter IFSC code </span>
                    <span class="validationtest" *ngIf="this.bankForm1.get('ifscCode').hasError('maxlength')">
                        Please enter 14 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Beneficiary Account No.<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText pKeyFilter="int" placeholder="Beneficiary Account No."
                        autocomplete="off" formControlName="accNo" maxlength="26">
                    <span class="validationtest"
                        *ngIf="bankForm1.get('accNo').touched && bankForm1.get('accNo').invalid && bankForm1.get('accNo').errors.required">
                        Please enter account number </span>
                    <span class="validationtest" *ngIf="this.bankForm1.get('accNo').hasError('maxlength')">
                        Please enter 25 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Beneficiary Name<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText placeholder="Beneficiary Name" autocomplete="off"
                        formControlName="beneficiaryName" maxlength="51">
                    <span class="validationtest"
                        *ngIf="bankForm1.get('beneficiaryName').touched && bankForm1.get('beneficiaryName').invalid && bankForm1.get('beneficiaryName').errors.required">
                        Please enter beneficiary name </span>
                    <span class="validationtest" *ngIf="this.bankForm1.get('beneficiaryName').hasError('maxlength')">
                        Please enter 50 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Address<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText placeholder="Address" autocomplete="off"
                        formControlName="address" maxlength="111">
                    <span class="validationtest"
                        *ngIf="bankForm1.get('address').touched && bankForm1.get('address').invalid && bankForm1.get('address').errors.required">
                        Please enter address </span>
                    <span class="validationtest" *ngIf="this.bankForm1.get('address').hasError('maxlength')">
                        Please enter 110 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">City<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText placeholder="City" autocomplete="off"
                        formControlName="city" maxlength="21">
                    <span class="validationtest"
                        *ngIf="bankForm1.get('city').touched && bankForm1.get('city').invalid && bankForm1.get('city').errors.required">
                        Please enter city </span>
                    <span class="validationtest" *ngIf="this.bankForm1.get('city').hasError('maxlength')">
                        Please enter 20 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">Country<span class="mandatoryColor">*</span></label>
                    <p-dropdown [options]="country" placeholder="Select Country" optionLabel="label"
                        (onChange)="getState($event.value.code)" autocomplete="off" formControlName="country"
                        [showClear]="true">
                    </p-dropdown>
                    <span class="validationtest"
                        *ngIf="bankForm1.get('country').touched && bankForm1.get('country').invalid && bankForm1.get('country').errors.required">
                        Please select country </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">State<span class="mandatoryColor">*</span></label>
                    <p-dropdown [options]="stateList1" placeholder="Select State" optionLabel="label" autocomplete="off"
                        formControlName="state" [showClear]="true">
                    </p-dropdown>
                    <span class="validationtest"
                        *ngIf="bankForm1.get('state').touched && bankForm1.get('state').invalid && bankForm1.get('state').errors.required">
                        Please select state </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">Zip<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText pKeyFilter="int" placeholder="Zip" autocomplete="off"
                        formControlName="zip" maxlength="11">
                    <span class="validationtest"
                        *ngIf="bankForm1.get('zip').touched && bankForm1.get('zip').invalid && bankForm1.get('zip').errors.required">
                        Please enter zip </span>
                    <span class="validationtest" *ngIf="this.bankForm1.get('zip').hasError('maxlength')">
                        Please enter 10 alphabets only
                    </span>
                </div>
            </div><br>
            <div class="btnsCSS1">
                <button pButton type="button" icon='pi pi-times' label="Cancel" class="addhBtn"
                    (click)="cancelAddBank()"></button>
                <button pButton type="button" icon='pi pi-save' label="Save" class="addhBtn" (click)="addBank()"
                    [disabled]="disable"></button>
            </div>
        </form>
    </p-dialog>
    <p-dialog class="dialogBox" header="Edit Bank Details" [(visible)]="displayAddModel1" [modal]="true"
        [contentStyle]="{'overflow': 'visible'}" [style]="{width: '45%'}" [draggable]="false">
        <form [formGroup]="bankForm1">
            <div class="p-grid p-fluid ippos">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">Bank Name<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText placeholder="Name" autocomplete="off"
                        formControlName="bankName" maxlength="51">
                    <span class="validationtest"
                        *ngIf="bankForm1.get('bankName').touched && bankForm1.get('bankName').invalid && bankForm1.get('bankName').errors.required">
                        Please enter bank name </span>
                        <span class="validationtest"
            *ngIf="this.bankForm1.controls.bankName.invalid && this.bankForm1.controls.bankName.errors?.pattern">Please enter
            alphabets only</span>
                    <span class="validationtest" *ngIf="this.bankForm1.get('bankName').hasError('maxlength')">
                        Please enter 50 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">Swift Code<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText placeholder="Swift Code" autocomplete="off"
                        formControlName="swiftCode" maxlength="15">
                    <span class="validationtest"
                        *ngIf="bankForm1.get('swiftCode').touched && bankForm1.get('swiftCode').invalid && bankForm1.get('swiftCode').errors.required">
                        Please enter swift code </span>
                    <span class="validationtest" *ngIf="this.bankForm1.get('swiftCode').hasError('maxlength')">
                        Please enter 14 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">IFSC Code<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText placeholder="IFSC Code" autocomplete="off"
                        formControlName="ifscCode" maxlength="15">
                    <span class="validationtest"
                        *ngIf="bankForm1.get('ifscCode').touched && bankForm1.get('ifscCode').invalid && bankForm1.get('ifscCode').errors.required">
                        Please enter IFSC code </span>
                    <span class="validationtest" *ngIf="this.bankForm1.get('ifscCode').hasError('maxlength')">
                        Please enter 14 alphabets only
                    </span>

                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Beneficiary Account No.<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText pKeyFilter="int" placeholder="Beneficiary Account No."
                        autocomplete="off" formControlName="accNo" maxlength="26">
                    <span class="validationtest"
                        *ngIf="bankForm1.get('accNo').touched && bankForm1.get('accNo').invalid && bankForm1.get('accNo').errors.required">
                        Please enter account number </span>
                    <span class="validationtest" *ngIf="this.bankForm1.get('accNo').hasError('maxlength')">
                        Please enter 25 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Beneficiary Name<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText placeholder="Beneficiary Name" autocomplete="off"
                        formControlName="beneficiaryName" maxlength="51">
                    <span class="validationtest"
                        *ngIf="bankForm1.get('beneficiaryName').touched && bankForm1.get('beneficiaryName').invalid && bankForm1.get('beneficiaryName').errors.required">
                        Please enter beneficiary name </span>
                    <span class="validationtest" *ngIf="this.bankForm1.get('beneficiaryName').hasError('maxlength')">
                        Please enter 50 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">Address<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText placeholder="Address" autocomplete="off"
                        formControlName="address" maxlength="111">
                    <span class="validationtest"
                        *ngIf="bankForm1.get('address').touched && bankForm1.get('address').invalid && bankForm1.get('address').errors.required">
                        Please enter address </span>
                    <span class="validationtest" *ngIf="this.bankForm1.get('address').hasError('maxlength')">
                        Please enter 110 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                    <label class="inputfields">City<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText placeholder="City" autocomplete="off"
                        formControlName="city" maxlength="21">
                    <span class="validationtest"
                        *ngIf="bankForm1.get('city').touched && bankForm1.get('city').invalid && bankForm1.get('city').errors.required">
                        Please enter city </span>
                    <span class="validationtest" *ngIf="this.bankForm1.get('city').hasError('maxlength')">
                        Please enter 20 alphabets only
                    </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">Country<span class="mandatoryColor">*</span></label>
                    <p-dropdown [options]="country" placeholder="Select Country" optionLabel="label"
                        (onChange)="getState($event.value.code)" autocomplete="off" formControlName="country"
                        [showClear]="true">
                    </p-dropdown>
                    <span class="validationtest"
                        *ngIf="bankForm1.get('country').touched && bankForm1.get('country').invalid && bankForm1.get('country').errors.required">
                        Please select country </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">State<span class="mandatoryColor">*</span></label>
                    <p-dropdown [options]="stateList" placeholder="Select State" optionLabel="label" autocomplete="off"
                        formControlName="state" [showClear]="true">
                    </p-dropdown>
                    <span class="validationtest"
                        *ngIf="bankForm1.get('state').touched && bankForm1.get('state').invalid && bankForm1.get('state').errors.required">
                        Please select state </span>
                </div>
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-4">
                    <label class="inputfields">Zip<span class="mandatoryColor">*</span></label>
                    <input id="input" type="text" pInputText pKeyFilter="int" placeholder="Zip" autocomplete="off"
                        formControlName="zip" maxlength="11">
                    <span class="validationtest"
                        *ngIf="bankForm1.get('zip').touched && bankForm1.get('zip').invalid && bankForm1.get('zip').errors.required">
                        Please enter zip </span>
                    <span class="validationtest" *ngIf="this.bankForm1.get('zip').hasError('maxlength')">
                        Please enter 10 alphabets only
                    </span>
                </div>
            </div>
            <br>
            <div class="btnsCSS1">
                <button pButton type="button" icon='pi pi-times' label="Cancel" class="addhBtn"
                    (click)="canceleditbank()"></button>
                <button pButton type="button" icon='pi pi-save' label="Update" class="addhBtn" (click)="updatebank()"
                    [disabled]="disable"></button>
            </div>
        </form>
    </p-dialog>
    <div *ngIf="!loader; else showLoader"></div>
    <ng-template #showLoader>
        <div class="loading-bar">
            <div>
                <div class="image-holder" class="col-sm-12 text-center laoderheight">
                    <div class="laodersize" >
                        <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif" class="loaderimg-height" >
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="items"></p-menu>
    <p-toast position="bottom-right" key="br"></p-toast>