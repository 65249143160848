import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-recuiter-dashboard',
  templateUrl: './recuiter-dashboard.component.html',
  styleUrls: ['./recuiter-dashboard.component.scss'],
  providers: [DatePipe, MessageService],
})
export class RecuiterDashboardComponent implements OnInit {
  barData: any;
  barData1: any;
  data: any;
  data1: any;
  mnthNo: number;
  piRes: any;
  dashboardForm: FormGroup;
  dateTime = new Date();
  totalPiechartValue: any;
  percentArray: any[];
  dataKeys: string[];
  dataValues: any[];
  from: any;
  to: any;
  frmDate: string;
  toDate: string;
  reqBarChart: any;
  reyKeys: any[];
  nameData: any = [];
  openPosition: any = [];
  selectedCandites: any = [];
  submittedResume: any = [];
  reyValue: any[];
  nameIndices: any;
  dataRes: { labels: any[]; datasets: any[]; };
  fileService: any;
  downloadOptions: { label: string; icon: string; command: (event: any, i: any) => void; }[];
  options: { title: { display: boolean; text: string; fontSize: number; }; legend: { position: string; }; };
  public chartOptions = {
    scales: {
      yAxes: [
        {
          display: true,
          scaleLabel: {
            show: false,
          },
          ticks: {
            beginAtZero: true,
            max: 40,
            min: 0,
            stepSize: 5,
          }
        }
      ],
    },
    title: {
      display: true,
      text: 'Top 5 Recruiters',
      fontSize: 16
    },
    legend: {
      position: 'bottom',
      usePointStyle: true

    },
  };
  // tslint:disable-next-line:max-line-length
  constructor(private apisService: ApisService, private breadcrumbService: BreadcrumbService, private fb: FormBuilder, private datepipe: DatePipe) {
    this.breadcrumbService.setItems([
      { label: 'Dashboard', routerLink: '/recdashbord' },
    ]);
  }

  ngOnInit() {
    this.dashboardForm = this.fb.group({
      fromDateForm: [''],
      toDateForm: ['']
    });
    this.dashboardForm.get('fromDateForm').setValue(new Date());
    this.dashboardForm.get('toDateForm').setValue(new Date());
    const date = new Date();
    // this.barChart1();
    // this.pieChart();
    // this.pieChartRes();
    this.barChartres();
  }

  // pieChart() {
  // this.data = {
  // labels: ['A', 'B', 'C'],
  // datasets: [
  // {
  // data: [300, 50, 100],
  // backgroundColor: [
  // '#FF6384',
  // '#36A2EB',
  // '#FFCE56'
  // ],
  // hoverBackgroundColor: [
  // '#FF6384',
  // '#36A2EB',
  // '#FFCE56'
  // ]
  // }]
  // };
  // }

  pieChart1() {
    this.data1 = {
      labels: this.dataKeys,
      datasets: [
        {
          data: this.dataValues,
          backgroundColor: [
            '#63dbb1',
            '#36A2EB',
            '#ff7b9a',
            '#bfffff',
            '#ff0f0f',
          ],
          hoverBackgroundColor: [
            '#63dbb1',
            '#36A2EB',
            '#ff7b9a',
            '#bfffff',
            '#ff0f0f',
          ]
        }]
    };
  }

  pieChartRes() {
    this.from = this.dashboardForm.controls.fromDateForm.value === undefined ? '' :
      this.dashboardForm.controls.fromDateForm.value
        === null ? '' : this.dashboardForm.controls.fromDateForm.value;

    this.to = this.dashboardForm.controls.toDateForm.value === undefined ? '' :
      this.dashboardForm.controls.toDateForm.value === null ? '' :
        this.dashboardForm.controls.toDateForm.value;

    this.frmDate = this.datepipe.transform(this.from, 'yyyy-MM-dd');
    this.toDate = this.datepipe.transform(this.to, 'yyyy-MM-dd');
    this.apisService.getApi(environment.pieChart + '?fromDate=' + this.frmDate + '&toDate=' + this.toDate).subscribe((res: any) => {
      this.piRes = res.data;
      this.dataKeys = Object.keys(res.data[0]);
      this.dataValues = Object.values(res.data[0]);
      this.pieChart1();
    });
  }

  barChart() {
    this.barData = {
      labels: this.nameData,

      datasets: [

        {
          label: 'Open Position',
          backgroundColor: 'rgb(0, 170, 231) ',
          // data: this.openPosition,
          data: [10, 20, 10, 20, 40, 20, 50, 10],
          hoverBackgroundColor: 'rgb(70, 197, 255)',
          pointStyle: 'circle',
          // fill: false,
        },
        {
          label: 'Submitted Profiles',
          backgroundColor: 'rgb(243, 89, 88)',
          data: [10, 20, 30, 20, 30, 20, 50, 10],
          hoverBackgroundColor: '#F57A79',
          // data: this.submittedResume,
          pointStyle: 'circle',
        },
        {
          label: 'Selected Candites',
          backgroundColor: '#1dbb99',
          data: [10, 20, 10, 30, 40, 50, 50, 10],
          hoverBackgroundColor: '#60cfb7',
          // data: this.selectedCandites,
          pointStyle: 'circle',
        },

      ]


    };
    // this.options = {

    // title: {
    // display: true,
    // text: 'Top 5 Recruiters',
    // fontSize: 16
    // },
    // legend: {
    // position: 'bottom'
    // },
    // };
  }

  barChartres() {
    // tslint:disable-next-line: max-line-length
    const fromDates = this.dashboardForm.controls.fromDateForm.value === null || this.dashboardForm.controls.fromDateForm.value === undefined || this.dashboardForm.controls.fromDateForm.value === '' ? '' : this.dashboardForm.controls.fromDateForm.value;
    // tslint:disable-next-line: max-line-length
    const toDates = this.dashboardForm.controls.toDateForm.value === null || this.dashboardForm.controls.toDateForm.value === undefined || this.dashboardForm.controls.toDateForm.value === '' ? '' : this.dashboardForm.controls.toDateForm.value;
    const fromDate = this.datepipe.transform(fromDates, 'yyyy-MM-dd');
    const toDate = this.datepipe.transform(toDates, 'yyyy-MM-dd');
    this.apisService.getApi(environment.barChart + '?fromDate=' + fromDate + '&toDate=' + toDate).subscribe((res: any) => {
      this.reqBarChart = res.data;
      res.data.forEach(element => {
        this.nameData.push(element.name);
        this.openPosition.push(element.openPosition);
        this.selectedCandites.push(element.selectedCandites);
        this.submittedResume.push(element.submittedResume);
      });
      this.barChart();
    });
  }
  // barChart1() {
  // this.barData1 = {
  // labels: ['GreenDots', 'WhiteStar', 'InfoGram', 'PrimeHeal', 'CrestMark'],
  // datasets: [
  // {
  // label: 'Open Positions',
  // backgroundColor: '#63dbb1',
  // data: [65, 59, 80, 81, 56, 55, 40]
  // },
  // {
  // label: 'Submitted Resumes',
  // backgroundColor: '#36A2EB',
  // data: [28, 48, 40, 19, 86, 27, 90]
  // },
  // {
  // label: 'Selected Candidates',
  // backgroundColor: '#ff7b9a',
  // data: [65, 59, 80, 81, 56, 55, 40]
  // },

  // ]
  // };
  // }


}
