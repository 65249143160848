import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApisService } from 'src/app/services/apis.service';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-work-location',
  templateUrl: './work-location.component.html',
  styleUrls: ['./work-location.component.scss'],
  providers: [DatePipe, MessageService],
})
export class WorkLocationComponent implements OnInit {
  workLocationForm: FormGroup;
  addBtnForm: FormGroup;
  vendorEditLocation: FormGroup;
  showUploadView: boolean;
  displayModal: boolean;
    // It is used for displaying table header data
  cols = [
    { field: 'name', header: 'Location Name', width: '5%' },
    { field: 'address1', header: 'Address', width: '6%' },
    { field: 'city', header: 'City', width: '5%' },
    { field: 'state', header: 'State ', width: '4%' },
    { field: 'country', header: 'Country', width: '4%' },
    { field: 'status', header: 'Status', width: '3%' },
    { field: '', header: 'Action', width: '3%' },
  ];

  loader: boolean;
  actId: any;
  rowid: string;
  gridTableData: any;
  totalRecords: any;
  table: boolean;
  records: boolean;
  stateData1: any;
  accId: any;
  gridData: any;
  country: any;
  contryTable = {};
  stateData: any;
  editData: any;
  state: any[];
  type2: any;
  statuTable = {};
  isUploading: boolean;
  locarowId: any;
  editlocation: boolean;
  hide: boolean;
  getData: any;
  grid: any;
  rowID: any;
  rowData: any;
  items: { label: string; icon: string; command: () => void; }[];
  stateTable = {};
  label: any;
  cs = {};
  tableStatus: any;
  disable: boolean;
  fileOver: boolean;
  items1: { label: string; icon: string; command: () => void; }[];
  countrycodedropdown: any = [];
  phCheckHide: boolean;
  phSpinnerHide: boolean;
  officenummsg: string;
  mobileNum: boolean;
  displayNumber: boolean;
  rows: any = 10;
  page: any = 1;
  first: any;
  pageCount: any;
  disableresetbtn: boolean;
  downloadData: any;
  mobileNumber: any;
  // tslint:disable-next-line:max-line-length
  constructor(private breadcrumbService: BreadcrumbService, private router: Router, private messageService: MessageService, private formBuilder: FormBuilder, private apisService: ApisService) {
    this.breadcrumbService.setItems([
      { label: 'Locations', routerLink: '/location' },
    ]);
    this.items1 = [
      {label: 'Download Sample', icon: 'pi pi-download', command: () => { this.downloadFileapi();
      }},

      {
        label: 'Import Data', icon: 'pi pi-chevron-down', command: () => {
          this.toggleUploadView();
        }
      }];
  }
  ngOnInit(): void {

    this.workLocationForm = this.formBuilder.group({
      name: [''],
      phone: [''],
      status: [''],
      country: [''],
      state: [''],
      city: ['']
    });
    this.addBtnForm = this.formBuilder.group({
      addName: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(20)]],
      addAddr1: ['', [Validators.required, Validators.maxLength(50)]],
      addAddr2: ['', Validators.maxLength(50)],
      addMailbox: ['', [Validators.maxLength(10)]],
      addCountry: ['', [Validators.required]],
      addState: ['', [Validators.required]],
      addCity: ['', [Validators.required, Validators.maxLength(20)]],
      addZip: ['', [Validators.maxLength(10), Validators.pattern('^[0-9-]*$')]],
      addPhone: [''],
      phoneDrp: ['']
    });
    this.vendorEditLocation = this.formBuilder.group({
      nameControl: ['', [Validators.required, Validators.pattern('^[\\sa-zA-z]*$'), Validators.maxLength(20)]],
      statusForm: ['', [Validators.required]],
      addressControl: ['', [Validators.required, Validators.maxLength(50)]],
      addressFormControl: ['', Validators.maxLength(50)],
      mailbox2: ['', [Validators.maxLength(10)]],
      country: ['', [Validators.required]],
      state: ['', [Validators.required]],
      cityForm: ['', [Validators.required, Validators.maxLength(20)]],
      zipForm: ['', [Validators.maxLength(10),Validators.pattern('^[0-9-]*$')]],
      phone: [''],
      editPhoneDrp: ['']
    });
    const userdetails = JSON.parse(this.apisService.decodeData());
    this.accId = userdetails.accId;
    this.gridTable();
    this.getCountryDropdown();
    this.getstatusDD();
    this.getdrop();
  }
// It is used for displaying country dropdown value
  getCountryDropdown() {
    this.apisService.getApi(environment.lookupsData + 'ADLC').subscribe((res) => {
      this.country = res.data;
      this.country.forEach((element) => {
        this.contryTable[element.code] = element.label;
      });
    });
  }
 // It is used for displaying state dropdown value
  getStateByUrl(country) {
    this.workLocationForm.controls.state.setValue('');
    if (country === null || '' || undefined) { }
    else {
      // tslint:disable-next-line: deprecation
      this.apisService.getApi(environment.states + country).subscribe(res => {
        this.state = res.data;
        this.vendorEditLocation.get('state').patchValue(this.state.filter((x) => x.code === this.editData.state)[0]);
        this.state.forEach((element: { code: string | number; label: any; }) => {
          this.stateTable[element.code] = element.label;
        });
      });
    }
  }
  getdrop() {
    this.countrycodedropdown = this.apisService.getdropdwon();
  }
// It is used for displaying status dropdown values
  getstatusDD() {
    this.apisService.getApi(environment.lookupsData + 'RLS').subscribe((res: any) => {
      this.type2 = res.data;
      this.type2.forEach((element: { code: string | number; label: any; }) => {
        this.statuTable[element.code] = element.label;
      });
    });
  }
  toggleUploadView() {
    this.showUploadView = !this.showUploadView;
  }
  // It is used to show add lcation pop up
  addlocation() {
    this.displayModal = true;
    this.addBtnForm.reset();
    this.addBtnForm.controls.addCountry.patchValue(this.country.filter((x) => x.code === 'USA')[0]);
    this.getStateByUrl('USA');
    this.addBtnForm.controls.phoneDrp.patchValue({
      code: '+1',
      flag: 'https://d2r8nxfsv3v6y3.cloudfront.net/images/us.png',
      id: '2',
      label: 'UNITED STATES',
    });
  }
  // It is used to show the table data
  gridTable() {
    this.loader = true;
    this.apisService.getApi(environment.getLocations + this.page + '/' + this.rows + '/' + this.accId).subscribe((res: any) => {
        this.gridData = res.data;
        this.loader = false;
        this.table = true;
        this.totalRecords = res.count;
        if (this.totalRecords === 0) {
          this.records = true;
          this.table = false;
        } else {
          this.records = false;
          this.table = true;
        }
        this.gridData.forEach((element) => {
          const countryID = element.country;
          // tslint:disable-next-line:no-shadowed-variable
          this.apisService.getApi(environment.states + countryID).subscribe((res) => {
            this.stateData1 = res.data;
            element.stateName = this.stateData1.filter(
              (x) => x.code === element.state
            )[0].label;
          });
        });
        this.loader = false;
      });
  }
// Drag over listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drag leave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.isUploading = true;
      this.onUpload(files);
    }
  }
  // It is used to upload files
  onUpload(files) {
    this.isUploading = true;
    const formData: FormData = new FormData();
    formData.append('file', files[0]);
    this.apisService.postApi(environment.fileuploadWorkLocation, formData).subscribe((res: any) => {
      if (res.success === true) {
        this.gridTable();
        this.messageService.add({
          key: 'br',
          severity: '',
          life: 3000,
          detail: res.message,
        });
        this.isUploading = false;
        this.showUploadView = false;
      } else {
        setTimeout(() => {
          this.isUploading = false;
        }, 2000);
        this.messageService.add({
          key: 'br',
          severity: '',
          life: 3000,
          detail: res.message,
        });
        this.gridTable();
      }
    });
  }
// It is used to bind the table data
  edit(data) {
    this.locarowId = data.locId;
    this.editData = data;
    this.editlocation = true;
    this.vendorEditLocation.controls.mailbox2.patchValue(data.mailbox);
    this.vendorEditLocation.controls.nameControl.patchValue(data.name);
    this.vendorEditLocation.controls.addressControl.patchValue(data.address1);
    this.vendorEditLocation.controls.addressFormControl.patchValue(data.address2);
    this.vendorEditLocation.controls.cityForm.patchValue(data.city);
    this.vendorEditLocation.controls.phone.patchValue(data.phone);
    this.vendorEditLocation.controls.zipForm.patchValue(data.zip);
    this.vendorEditLocation.controls.statusForm.patchValue(data.status);
    this.vendorEditLocation.controls.country.patchValue(this.country.filter((x) => x.code === data.country)[0]);
    this.getStateByUrl(data.country);
    this.vendorEditLocation.controls.editPhoneDrp.patchValue(this.countrycodedropdown.filter((x) => x.id === data.mobileCcode)[0]);
  }
  // It is used to reset search fileds
  reset() {
    this.loader = true;
    this.workLocationForm.reset();
    this.records = false;
    this.gridTable();

  }
// It is used to close add location pop up
  addCancel() {
    this.displayModal = false;
  }
  // It is used to update location
  vendorLocUpdate() {
    this.hide = true;
    if (this.vendorEditLocation.invalid) {
      const controls = this.vendorEditLocation.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
      this.hide = false;
    } else {
      const data = {
        locationFlag: 'Edit',
        id: this.locarowId,
        accId: this.accId,
        address1:
          this.vendorEditLocation.controls.addressControl.value,
        address2:
          this.vendorEditLocation.controls.addressFormControl.value,
        city: this.vendorEditLocation.controls.cityForm.value,
        country: this.vendorEditLocation.controls.country.value.code,
        district: '',
        mailBox: this.vendorEditLocation.controls.mailbox2.value,
        name: this.vendorEditLocation.controls.nameControl.value,
        objectType: 'A',
        state: this.vendorEditLocation.controls.state.value.code,
        zip: this.vendorEditLocation.controls.zipForm.value,
        phone: this.vendorEditLocation.controls.phone.value,
        status: this.vendorEditLocation.controls.statusForm.value,
        // tslint:disable-next-line:max-line-length
        mccode: this.vendorEditLocation.controls.editPhoneDrp.value === null || this.vendorEditLocation.controls.editPhoneDrp.value === undefined || this.vendorEditLocation.controls.editPhoneDrp.value === '' ? '' : this.vendorEditLocation.controls.editPhoneDrp.value.id

      };
      this.disable = true;
      this.apisService.postApi(environment.locationEdit, data).subscribe((res: any) => {
        this.getData = res;
        if (this.getData.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message });
          this.disable = false;
          this.editlocation = false;
          this.gridTable();
          this.Searchlocation();
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.failed });
          this.disable = false;
          this.editlocation = false;
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.error  });
          this.disable = false;
          this.editlocation = false;
        }
      });
    }
  }
  // It is used to add location
  vendorLocSave() {
    this.hide = true;
    if (this.addBtnForm.invalid) {
      const controls = this.addBtnForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      const data = {
        locationFlag: 'Add',
        accId: this.accId,
        objectType: 'A',
        address1: this.addBtnForm.controls.addAddr1.value,
        address2: this.addBtnForm.controls.addAddr2.value,
        city: this.addBtnForm.controls.addCity.value,
        country: this.addBtnForm.controls.addCountry.value.code,
        mailBox: this.addBtnForm.controls.addMailbox.value,
        name: this.addBtnForm.controls.addName.value,
        state: this.addBtnForm.controls.addState.value.code,
        zip: this.addBtnForm.controls.addZip.value,
        phone: this.addBtnForm.controls.addPhone.value,
        // tslint:disable-next-line:max-line-length
        mccode: this.addBtnForm.controls.phoneDrp.value === null || this.addBtnForm.controls.phoneDrp.value === undefined || this.addBtnForm.controls.phoneDrp.value === '' ? '' : this.addBtnForm.controls.phoneDrp.value.id

      };
      this.disable = true;
      this.apisService.postApi(environment.locationEdit, data).subscribe((res) => {
        this.getData = res;
        this.hide = false;
        this.displayModal = false;
        if (this.getData.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.getData.message });
          this.disable = false;
          this.gridTable();
        } else if (this.getData.hasOwnProperty('failed') === true) {
          this.messageService.add({  key: 'br', severity: '',  life: 3000, detail: this.getData.failed });
          this.disable = false;
        } else {
          this.messageService.add({  key: 'br', severity: '',  life: 3000,  detail: this.getData.error  });
          this.disable = false;
        }
      });
    }
  }
  // It is used for phone number existed or not
  checkPhone() {
    this.displayNumber = false;
    const num = this.addBtnForm.controls.addPhone.value;
    if (num) {
      this.phSpinnerHide = true;
      this.apisService.getApi(environment.phonerequired + num).subscribe(res => {
        this.mobileNum = res;
        this.mobileNum = res.data.isNumberExisted;
        if (this.mobileNum === true) {
          this.phSpinnerHide = false;
          this.officenummsg = 'Phone number already exist';
          setTimeout(() => {
            this.officenummsg = '';
          }, 5000);
          this.addBtnForm.get('addPhone').reset();
        }
        else {
          this.phSpinnerHide = false;
          this.phCheckHide = true;
          setTimeout(() => {
            this.phCheckHide = false;
          }, 3000);
        }
      });
    }
  }

  checkPhoneData() {
    this.displayNumber = false;
    const num = this.vendorEditLocation.controls.phone.value;
    if (num) {
      this.phSpinnerHide = true;
      this.apisService.getApi(environment.locationphone + this.locarowId + '/' + '?phoneNumber=' + num).subscribe(res => {
        this.mobileNumber = res;
        this.mobileNumber = res.success;
        if (this.mobileNumber === false) {
          this.phSpinnerHide = false;
          this.officenummsg = 'Phone number already exist';
          setTimeout(() => {
            this.officenummsg = '';
          }, 5000);
          this.vendorEditLocation.get('phone').reset();
        }
        else {
          this.phSpinnerHide = false;
          this.phCheckHide = true;
          setTimeout(() => {
            this.phCheckHide = false;
          }, 3000);
        }
      });
    }
  }

  // It is used for server side pagination
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.Searchlocation();
  }
  // It is used to search locations
  Searchlocation() {
    const locationName =
      this.workLocationForm.controls.name.value === null
        ? ''
        : this.workLocationForm.controls.name.value;
    const city =
      this.workLocationForm.controls.city.value === null
        ? ''
        : this.workLocationForm.controls.city.value;
    const phone =
      this.workLocationForm.controls.phone.value === null
        ? ''
        : this.workLocationForm.controls.phone.value;
    const country =
      this.workLocationForm.controls.country.value === ('' || null)
        ? ''
        : this.workLocationForm.controls.country.value === undefined
          ? ''
          : this.workLocationForm.controls.country.value;
    const status =
      this.workLocationForm.controls.status.value === null ||
        this.workLocationForm.controls.status.value === '' ||
        this.workLocationForm.controls.status.value === undefined
        ? ''
        : this.workLocationForm.controls.status.value;
    const state =
      this.workLocationForm.controls.state.value === '' ||
        this.workLocationForm.controls.state.value === null ||
        this.workLocationForm.controls.state.value === undefined
        ? ''
        : this.workLocationForm.controls.state.value;
    this.loader = true;
    const searchdata =
      // tslint:disable-next-line: max-line-length
      this.page + '/' + this.rows + '/' + this.accId + '?name=' + locationName + '&city=' + city + '&phone=' + phone + '&country=' + country + '&state=' + state + '&status=' + status;
    this.loader = true;
    this.apisService.getApi(environment.getLocations + searchdata).subscribe((res) => {
      this.grid = res;
      this.loader = false;
      this.gridTableData = this.grid.data;
      this.totalRecords = res.count;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
      this.loader = false;
      this.gridData = res.data;
      this.totalRecords = res.count;
      this.gridData.forEach((element) => {
        const countryID = element.country;
        // tslint:disable-next-line:no-shadowed-variable
        this.apisService.getApi(environment.states + countryID).subscribe((res) => {
          this.stateData1 = res.data;
          element.stateName = this.stateData1.filter(
            (x) => x.code === element.state
          )[0].label;
        });
      });
    });
  }
  // It is used to show action pop up
  toggle(event, data, rowData) {
    this.rowData = rowData;
    this.items = [
      {
        label: ' Edit',
        icon: 'pi pi-pencil',
        command: () => {
          this.edit(this.rowData);
        }
      },
    ];
  }
  getState(country, state) {
    this.loader = true;
    this.loader = false;
    return this.stateTable[state];
  }
  downloadFileapi() {
    this.apisService.getApi(environment.downloadres + 5).subscribe((res: any) => {
      this.downloadData = res;
      window.location.assign(this.downloadData.downloadUrl);
    });
  }
}