<p-toast position="bottom-right" key="br"></p-toast>
<p-card>
    <form [formGroup]="workLocationForm">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                <label class="inputfields"> Name</label>
                <input id="input" type="text" pInputText placeholder="Name" size="30" formControlName="name"
                    (keyup.enter)="Searchlocation()" autocomplete="off" maxlength="60">
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                <label class="inputfields"> Phone</label>
                <p-inputMask mask="(999) 999-9999" formControlName="phone" placeholder="Phone"
                    (keyup.enter)="Searchlocation()">
                </p-inputMask>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                <label class="inputfields"> Status</label>
                <p-dropdown [options]="type2" optionValue="code" formControlName="status" [showClear]="true"
                    placeholder="Select Status" optionLabel="label" (keyup.enter)="Searchlocation()" size="30"
                    formControlName="status"></p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                <label class="inputfields"> Country</label>
                <p-dropdown [options]="country" optionValue="code" (onChange)="getStateByUrl($event.value)"
                    [showClear]="true" (keyup.enter)="Searchlocation()" placeholder="Select Country" optionLabel="label"
                    size="30" formControlName="country"></p-dropdown>
            </div>

            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                <label class="inputfields"> State</label>
                <p-dropdown [options]="state" optionValue="code" placeholder="Select State" [showClear]="true"
                    (keyup.enter)="Searchlocation()" optionLabel="label" size="30" formControlName="state">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                <label class="inputfields"> City</label>
                <input id="input" type="text" pInputText placeholder="City" size="30" (keyup.enter)="Searchlocation()"
                    autocomplete="off" formControlName="city" maxlength="20" autocomplete="off">
            </div>
        </div>
    </form>

    <div class="btnsCSS1 buttonalignment">

        <p-splitButton label="Import" [model]="items1" class="addhBtn"></p-splitButton>
        <button pButton type="button" icon='pi pi-plus' label="Add" (click)="addlocation()" class="addhBtn"></button>
        <button pButton type="button" icon='pi pi-refresh' label="Reset" (click)="reset()" class="addhBtn"></button>
        <button pButton type="button" icon='pi pi-search' label="Search" (click)="Searchlocation()"
            class="addhBtn"></button>
    </div>

    <div *ngIf="isUploading" class="p-col-12 p-md-12 p-sm-12 p-lg-12">
        <br>
        <p-progressBar mode="indeterminate"></p-progressBar>
    </div>
    <br>
    <div *ngIf="showUploadView" class="dropzone p-col-12 p-md-12 p-sm-12 p-lg-3">
        <em class="pi pi-upload Iconsize" aria-hidden="true"></em>
        <input accept=".xlsm,.xlsx,application/msexcel" type="file" class="fileInput" #fileDropRef id="fileDropRef"
            multiple (change)="onUpload($event.target.files)" style="cursor: pointer;" />
        <h3>Drag and drop file here</h3>
        <h3 class="importstylin">or</h3>
        <label id="fileUpload" for="fileDropRef">Browse for files</label>
    </div>
</p-card>
<br>
<p-card class="pgpos">
    <div *ngIf="table">
        <!--It is used for displaying the work locations table-->
        <p-table [value]="this.gridData" [columns]="cols" selectionMode="single" dataKey="edit" [responsive]="true"
            sortMode="multiple">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="gridHeader" *ngFor="let col of columns; let i = index" class="textCenter"
                        [ngStyle]="{'width': col.width }" [pSortableColumn]="col.field">
                        <span>
                            {{col.header}}

                        </span>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-data>
                <tr>
                    <td class="textCenter"><span class="p-column-title">
                            Location Name</span>{{data.name}}
                    </td>
                    <td class="textCenter addresDesc" pTooltip="{{data.address1}}" tooltipPosition="top"><span
                            class="p-column-title">
                            Address</span>{{data.address1}}</td>
                    <td class="textCenter"><span class="p-column-title">
                            City</span>{{data.city}}</td>
                    <td class="textCenter"><span class="p-column-title">
                            State</span>{{data.stateName}}</td>
                    <td class="textCenter"><span class="p-column-title">
                            Country</span>{{contryTable[data.country]}}</td>
                    <td><span class="ui-column-title"></span>
                        <p-badge [value]="statuTable[data.status]" [ngClass]="data.status ==='A'?'green': 'red'">
                        </p-badge>
                    </td>
                    <td class="textCenter"><span class="p-column-title">
                            Action</span><a (click)="downloadActionPopup.toggle($event); toggle($event,'data', data)"
                            class="pi pi-ellipsis-v pointer"></a>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-2 totalrec">
                <h3 class="norecorddata">Total records : {{this.totalRecords}}</h3>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-10">
                <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    <p-card *ngIf="records">
        <div class="norecordImgsize">
            <div *ngIf="records" class="aligncenter">
                <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/no-results.png" class="norecordimg">
                <br><em>Sorry, we are not able to find any locations</em><br>
            </div>
        </div>
    </p-card>
</p-card>
<!-- It is used for add work location pop up -->
<p-dialog [contentStyle]="{'overflow': 'visible'}" class="dialogBox" header="Add Location" [(visible)]="displayModal"
    [style]="{width: '30%'}" [baseZIndex]="1" [modal]="true" [draggable]="false" [resizable]="false">
    <form [formGroup]="addBtnForm">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 ">
                <label class="inputfields">Name<span class="mandatoryColor">*</span></label>
                <input id="input" type="text" maxlength="21" pInputText placeholder="Name" formControlName="addName"
                    autocomplete="off">
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="addBtnForm.get('addName').touched && addBtnForm.get('addName').invalid && this.addBtnForm.get('addName').errors.required">
                    Please enter name
                </span>
                <span class="validationtest" *ngIf="this.addBtnForm.get('addName').hasError('maxlength')">
                    Please enter 20 alphabets only
                </span>
                <span class="validationtest"
                    *ngIf="this.addBtnForm.get('addName').invalid && this.addBtnForm.get('addName').errors.pattern">
                    Please enter alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12 ">
                <label class="inputfields"> Address Line 1<span class="mandatoryColor">*</span></label>
                <input id="input" type="text" pInputText placeholder="Address Line 1" maxlength="51"
                    formControlName="addAddr1" autocomplete="off">
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="addBtnForm.get('addAddr1').touched && addBtnForm.get('addAddr1').invalid && this.addBtnForm.get('addAddr1').errors.required">
                    Please enter address line 1
                </span>
                <span class="validationtest" *ngIf="this.addBtnForm.get('addAddr1').hasError('maxlength')">
                    Please enter 50 alphabets only
                </span>
            </div>

            <div class="p-col-12 p-md-12 p-sm-12 p-lg-9 ">
                <label class="inputfields"> Address Line 2</label>
                <input id="input" type="text" pInputText placeholder="Address Line 2" maxlength="51"
                    formControlName="addAddr2" autocomplete="off">
                <span class="validationtest" *ngIf="this.addBtnForm.get('addAddr2').hasError('maxlength')">
                    Please enter 50 alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3 ">
                <label class="inputfields"> Mailbox </label>

                <input id="input" type="text" pInputText placeholder="Mailbox" maxlength="11"
                    formControlName="addMailbox" autocomplete="off">
                <span class="validationtest" *ngIf="this.addBtnForm.get('addMailbox').hasError('maxlength')">
                    Please enter 10 alphabets only
                </span>
                <span class="validationtest"
                    *ngIf="this.addBtnForm.get('addMailbox').invalid && this.addBtnForm.get('addMailbox').errors.pattern">
                    Please enter numbers only
                </span>
            </div>

            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields"> Country<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="country" optionalValue="code" (onChange)="getStateByUrl($event.value.code)"
                    [showClear]="true" placeholder="Select Country" optionLabel="label" size="30"
                    formControlName="addCountry">
                </p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="addBtnForm.get('addCountry').touched && addBtnForm.get('addCountry').invalid && this.addBtnForm.get('addCountry').errors.required">
                    Please select country
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields"> State<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="state" optionalValue="code" placeholder="Select State" optionLabel="label"
                    [showClear]="true" size="30" formControlName="addState"></p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="addBtnForm.get('addState').touched && addBtnForm.get('addState').invalid && this.addBtnForm.get('addState').errors.required">
                    Please select state
                </span>
            </div>

            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields"> City<span class="mandatoryColor">*</span> </label>
                <input id="input" type="text" pInputText placeholder=" City" maxlength="21" formControlName="addCity"
                    autocomplete="off">
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="addBtnForm.get('addCity').touched && addBtnForm.get('addCity').invalid && this.addBtnForm.get('addCity').errors.required">
                    Please enter city
                </span>
                <span class="validationtest" *ngIf="this.addBtnForm.get('addCity').hasError('maxlength')">
                    Please enter 20 alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 ">
                <label class="inputfields"> Zip </label>
                <input id="input" type="text" pInputText placeholder=" Zip" maxlength="11" formControlName="addZip"
                    autocomplete="off">
                <span class="validationtest" *ngIf="this.addBtnForm.get('addZip').hasError('maxlength')">
                    Please enter 10 alphabets only
                </span>
                <span class="validationtest"
                    *ngIf="this.addBtnForm.get('addZip').invalid && this.addBtnForm.get('addZip').errors.pattern">
                    Please enter numbers only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
                <label class="inputfields">Phone</label>
                <div class="p-col-12 p-md-12 p-sm-12" style="display: flex;padding: 0">
                    <div class="p-inputgroup" class="p-col-4 p-md-4" style="padding: 0; width: initial;">
                        <span class="p-inputgroup-addon addon">
                            <p-dropdown [options]="countrycodedropdown" class="phonecodestyle"
                                filterPlaceholder='Search Results' optionLabel="label" formControlName="phoneDrp">
                                <ng-template let-items pTemplate="selectedItem">
                                    <div class="flex-row">
                                        <img class="lang-change-img" src={{items.flag}} alt="" />
                                        <div class="lang-change-option-text selected-lang center">{{items.code}}
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template let-country pTemplate="item">
                                    <div class="flex-row" id="dropdown1">
                                        <img class="lang-change-img" src={{country.flag}} alt="" />
                                        <div class="lang-change-option-text center">{{country.label}}
                                            {{country.code}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </span>
                    </div>
                    <div class="p-col-8 p-md-8" style=" padding:0;width: calc(100% - 100px);">
                        <span class="p-input-icon-right"><em *ngIf="phSpinnerHide"
                                class="pi pi-spin pi-spinner spinnerstyles"></em>
                            <em *ngIf="phCheckHide" class="pi pi-check-circle tickmarkstyle"></em>
                            <p-inputMask autocomplete="off" mask="(999) 999-9999" formControlName="addPhone"
                                (onBlur)='checkPhone()' placeholder="Mobile Number">
                            </p-inputMask>
                        </span>
                        <p class="mandatoryColor">{{this.officenummsg}}</p>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <br>
    <div class="btnsCSS1">
        <button pButton type="button" icon='pi pi-times' label="Cancel" (click)="addCancel()" class="addhBtn"></button>
        <button pButton type="button" icon='pi pi-save' label="Save" (click)="vendorLocSave()" class="addhBtn"
            [disabled]="disable"></button>
    </div><br><br>
</p-dialog>
<!-- It is used for edit work location pop up -->
<p-dialog [contentStyle]="{'overflow': 'visible'}" class="dialogBox" header="Edit Location" [(visible)]="editlocation"
    [style]="{width: '30%'}" [baseZIndex]="1" [modal]="true" [draggable]="false" [resizable]="false">
    <form [formGroup]="vendorEditLocation">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Name<span class="mandatoryColor">*</span></label>
                <input id="input" autocomplete="off" type="text" pInputText placeholder="Name"
                    formControlName="nameControl" maxlength="21">
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="vendorEditLocation.get('nameControl').touched && vendorEditLocation.get('nameControl').invalid && this.vendorEditLocation.get('nameControl').errors.required">
                    Please enter name
                </span>
                <span class="validationtest" *ngIf="this.vendorEditLocation.get('nameControl').hasError('maxlength')">
                    Please enter 20 alphabets only
                </span>
                <span class="validationtest"
                    *ngIf="this.vendorEditLocation.get('nameControl').invalid && this.vendorEditLocation.get('nameControl').errors.pattern">
                    Please enter alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Status<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="type2" placeholder="Select status" optionLabel="label" optionValue="code"
                    [showClear]="true" formControlName="statusForm"></p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="vendorEditLocation.get('statusForm').touched && vendorEditLocation.get('statusForm').invalid && this.vendorEditLocation.get('statusForm').errors.required">
                    Please select status
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                <label class="inputfields">Address Line 1<span class="mandatoryColor">*</span></label>
                <input id="input" autocomplete="off" type="text" pInputText placeholder="Address Line 1"
                    formControlName="addressControl" maxlength="51">
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="vendorEditLocation.get('addressControl').touched && vendorEditLocation.get('addressControl').invalid && this.vendorEditLocation.get('addressControl').errors.required">
                    Please enter address line 1
                </span>
                <span class="validationtest"
                    *ngIf="this.vendorEditLocation.get('addressControl').hasError('maxlength')">
                    Please enter 50 alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-9">
                <label class="inputfields">Address Line 2</label>
                <input id="input" type="text" autocomplete="off" pInputText placeholder="Address Line 2"
                    formControlName="addressFormControl" maxlength="51">
                <span class="validationtest"
                    *ngIf="this.vendorEditLocation.get('addressFormControl').hasError('maxlength')">
                    Please enter 50 alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-3">
                <label class="inputfields" id='csslabel'>Mailbox</label>
                <input type="text" autocomplete="off" maxlength="11" pInputText placeholder="Mailbox"
                    formControlName="mailbox2">
                <span class="validationtest" *ngIf="this.vendorEditLocation.get('mailbox2').hasError('maxlength')">
                    Please enter 10 alphabets only
                </span>
                <span class="validationtest"
                    *ngIf="this.vendorEditLocation.get('mailbox2').invalid && this.vendorEditLocation.get('mailbox2').errors.pattern">
                    Please enter numbers only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
                <label class="inputfields">Country<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="country" placeholder="Select Country" optionLabel="label" [showClear]="true"
                    formControlName="country" (onChange)="getStateByUrl($event.value.code)">
                </p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="vendorEditLocation.get('country').touched && vendorEditLocation.get('country').invalid && this.vendorEditLocation.get('country').errors.required">
                    Please select country
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6 invalidMandatory">
                <label class="inputfields">State<span class="mandatoryColor">*</span></label>
                <p-dropdown [options]="state" placeholder="State" optionLabel="label" formControlName="state"
                    [showClear]="true">
                </p-dropdown>
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="vendorEditLocation.get('state').touched && vendorEditLocation.get('state').invalid && this.vendorEditLocation.get('state').errors.required">
                    Please select state
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">City<span class="mandatoryColor">*</span></label>
                <input id="input" type="text" pInputText placeholder="City" formControlName="cityForm" maxlength="21"
                    autocomplete="off">
                <span class="validationtest" class="mandatoryColor"
                    *ngIf="vendorEditLocation.get('cityForm').touched && vendorEditLocation.get('cityForm').invalid && this.vendorEditLocation.get('cityForm').errors.required">
                    Please enter city
                </span>
                <span class="validationtest" *ngIf="this.vendorEditLocation.get('cityForm').hasError('maxlength')">
                    Please enter 20 alphabets only
                </span>
            </div>
            <div class="p-col-12 p-md-12 p-sm-12 p-lg-6">
                <label class="inputfields">Zip</label>
                <input id="input" type="text" pInputText pKeyFilter="num" maxlength="11" pInputText placeholder="Zip"
                    formControlName="zipForm">
                <span class="validationtest" *ngIf="this.vendorEditLocation.get('zipForm').hasError('maxlength')">
                    Please enter 10 alphabets only
                </span>
                <span class="validationtest"
                    *ngIf="this.vendorEditLocation.get('zipForm').invalid && this.vendorEditLocation.get('zipForm').errors.pattern">
                    Please enter numbers only
                </span>
            </div>

            <div class="p-col-12 p-md-12 p-sm-12 p-lg-8">
                <label class="inputfields">Phone</label>
                <div class="p-col-12 p-md-12 p-sm-12" style="display: flex;padding: 0">
                    <div class="p-inputgroup" class="p-col-4 p-md-4" style="padding: 0; width: initial;">
                        <span class="p-inputgroup-addon addon">
                            <p-dropdown [options]="countrycodedropdown" class="phonecodestyle"
                                filterPlaceholder='Search Results' optionLabel="label" formControlName="editPhoneDrp">
                                <ng-template let-items pTemplate="selectedItem">
                                    <div class="flex-row">
                                        <img class="lang-change-img" src={{items.flag}} alt="" />
                                        <div class="lang-change-option-text selected-lang center">{{items.code}}
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template let-country pTemplate="item">
                                    <div class="flex-row" id="dropdown1">
                                        <img class="lang-change-img" src={{country.flag}} alt="" />
                                        <div class="lang-change-option-text center">{{country.label}}
                                            {{country.code}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </span>
                    </div>
                    <div class="p-col-8 p-md-8" style=" padding:0;width: calc(100% - 100px);">
                        <span class="p-input-icon-right"><em *ngIf="phSpinnerHide"
                                class="pi pi-spin pi-spinner spinnerstyles"></em>
                            <em *ngIf="phCheckHide" class="pi pi-check-circle tickmarkstyle"></em>
                            <p-inputMask autocomplete="off" mask="(999) 999-9999" formControlName="phone"
                                placeholder="Phone" (onBlur)='checkPhoneData()'>
                            </p-inputMask>
                        </span>
                        <p class="mandatoryColor">{{this.officenummsg}}</p>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="btnsCSS1 buttonaligned">
            <button pButton type="button" icon='pi pi-save' label="Update" class="addhBtn" [disabled]="disable"
                (click)=vendorLocUpdate()></button>
        </div><br><br>
    </form>
</p-dialog>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
    <div class="loading-bar">
        <div>
            <div class="image-holder" class="col-sm-12 text-center laoderheight ">
                <div class="laodersize">
                    <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
                        class="loaderimg-height">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<p-menu class="menuclass" #downloadActionPopup [popup]="true" [model]="items"></p-menu>