<nav class="navbar navbar-expand-lg navbar-dark sticky-top nav">
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
        <a class="navbar-brand">
            <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Servicesbay Logo SVG.svg"
                class="innerimg">
        </a>
        <button class="pi pi-arrow-left pointer addhBtn" id="backToStyle" pButton type="button" style="width: auto;
        top: 14px;" label="Back
          to Login" (click)="goto()"></button>
    </div>
</nav><br>
<div *ngIf="cards">
    <div class="p-grid">
        <p-card class="hello">
            <div class="p-grid p-justify-center">
                <div class="p-col-12 p-md-12 p-sm-12 p-lg-12">
                    <div>
                        <br>
                        <div class="p-grid">
                            <div *ngIf="success">
                                <img alt=""
                                    src="https://d2r8nxfsv3v6y3.cloudfront.net/images/download.png" class="verifyimg">
                                <div class="p-col-12 p-lg-12 p-fluid">
                                    <h4 class="textpos">Email Verified Successfully
                                        <strong class="strng"></strong>
                                    </h4>
                                </div>
                            </div>
                            <div *ngIf="tokenExp">
                                <img alt=""
                                    src="https://d2r8nxfsv3v6y3.cloudfront.net/images/delete-icon-close-symbol-vector-no-sign-cancel-symbol-wrong-reject-illustration-delete-icon-close-symbol-vector-no-sign-cancel-1480.jpg"
                                    style="width: 75px;
                                    margin-left: 64px;">
                                <div class="p-col-12 p-lg-12 p-fluid">
                                    <h4 class="textpos1">Token Expired
                                        <strong class="strng"></strong>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </p-card>
    </div>
</div>
<div *ngIf="!loader; else showLoader"></div>
<ng-template #showLoader>
  <div class="loading-bar">
    <div>
      <div class="image-holder" class="col-sm-12 text-center laoderheight">
        <div class="laodersize">
          <img alt="" src="https://d2r8nxfsv3v6y3.cloudfront.net/images/Services-Bay-Loader.gif"
            class="loaderimg-height">
        </div>
      </div>
    </div>
  </div>
</ng-template>