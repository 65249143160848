import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { MessageService } from 'primeng/api';
import { isEmpty } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-vendor-requirement',
  templateUrl: './vendor-requirement.component.html',
  styleUrls: ['./vendor-requirement.component.scss'],
  providers: [DatePipe, MessageService],
})
export class VendorRequirementComponent implements OnInit {
  requirementsForm: FormGroup;
  cols3 = [
    { dataKey: 'parentReqId', header: 'Parent Job Id' },
    { dataKey: 'reqId', header: 'Job Id' },
    { dataKey: 'reqName', header: 'Position Title' },
    { dataKey: 'noOfPositions', header: 'Positions' },
    { dataKey: 'skills', header: 'Skills' },
    { dataKey: 'postedDate', header: 'Posted Date' },
    // { dataKey: 'plannedEndDate', header: 'Target Date' },
    { dataKey: 'status', header: 'Status' },
    { dataKey: 'submissions', header: 'Submissions' },
  ];
  cols = [
    { field: 'postedDate', header: 'Parent Job Id', sno: 1 },
    { field: 'reqId', header: 'Job Id', sno: 2 },
    { field: 'accName', header: 'Position Title', sno: 3 },
    { field: 'resource', header: 'Positions', sno: 4 },
    { field: 'requiredSkills', header: 'Skills', sno: 5 },
    { field: 'requiredSkills', header: 'Skills', sno: 6 },
    { field: 'postedDate', header: 'Posted Date', sno: 7 },
    { field: 'reqStatus', header: 'Status', sno: 8 },
    { field: 'submissions', header: 'Submissions', sno: 9 },
    { field: '', header: 'Action', sno: 10 }, // role id != 11
  ];
  iconToggle: any = [];
  griddata: any;
  first: any;
  rows: any = 10;
  page: any = 1;
  pageCount: any;
  specializationSkillsData: any = [];
  array: any = [];
  statusDD: any;
  tablestatusdata: any;
  display: boolean;
  reqSkillsValue: any = [];
  skillSet: any[];
  requirementsStartDate: string;
  requirementsEndDate: string;
  griddCount: any;
  downloadOptions: {
    label: string;
    icon: string;
    command: (event: any, i: any) => void;
  }[];
  table = true;
  records: boolean;
  acctdetails: any;
  loader: boolean;
  // tslint:disable-next-line:no-inferrable-types
  displayreason: boolean = false;
  reason: any;
  statusRes: any;
  reqIdData: any;
  securityReleaseResponse: any;
  dbQuery = '';
  totalRecords: boolean;
  primaryRole: number;
  data1: { orgId: number };
  orgIdValue: any;
  userdata: number;
  roleId: any;
  requirementsData: any;
  reqcount: any;
  requirementsGridData: any;
  name: string;
  gridStatus: any;
  reqID: any;
  header: string;
  userGroupId: any;
  assignedList: any;
  rowData: any;
  items: any = [];
  releaseDialog: boolean;
  releaseForm: FormGroup;
  vendorNames: any = [];
  actId: any;
  getStatusData = {};
  displayModal: boolean;
  date: Date;
  histroy: any;
  histroy1: any;
  usrroleid: any;
  constructor(
    private fb: FormBuilder,
    private route: Router,
    private apisService: ApisService,
    private datepipe: DatePipe,
    private messageService: MessageService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.breadcrumbService.setItems([
      { label: 'Requirements', routerLink: '/rec-requirements' },
    ]);
  }

  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchData1();
    }
  }
  ngOnInit() {
    this.requirementsForm = this.fb.group({
      requirementsJobId: [''],
      requirementsJobTitle: [''],
      requirementsReqCategory: [''],
      requirementsStatus: [''],
      requirementsStartDate: [''],
      requirementsEndDate: [''],
      requirementsSpecialization: [''],
      requirementsCustomerName: [''],
      requirementsassignStatus: [''],
      requirementsassignCandidates: [''],
    });


    this.releaseForm = this.fb.group({
      vendorNameControl: [''],
      checkvalue: ['']
    });
    this.acctdetails = JSON.parse(this.apisService.decodeData());
    this.apisService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === '') {
      this.roleId = this.acctdetails.roles[0].id;
    } else {
      this.roleId = this.usrroleid;
    }
    this.getGridData();
    this.reqSkillSet();
    this.getStatus();
  }

  getGridData() {
    const data = '?' + 'primaryRole=' + this.roleId;
    const page = this.page;
    const rows = this.rows;

    this.loader = true;
    this.apisService.getApi(environment.vedorReq + page + '/' + rows + data).subscribe((res) => {
      this.griddata = res.data.records;
      this.griddCount = res.data.count;
      if (this.griddCount !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
      this.loader = false;
    });
  }

  reqSkillSet() {
    this.apisService.getApi(environment.skills).subscribe((res) => {
      this.specializationSkillsData = res.data;
    });
  }
  getStatus() {
    this.apisService.getApi(environment.status).subscribe((res) => {
      this.statusDD = res.data;
      this.statusDD.forEach(element => {
        this.getStatusData[element.code] = element.label;
      });
    });
  }
  getSkillSetTable(data) {
    this.array = data;
    const selectedPrePrimarySkills3 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.specializationSkillsData.length; i++) {
      // tslint:disable-next-line: prefer-for-of
      // tslint:disable-next-line:prefer-for-of
      if (data === null || data === undefined || data === '' || data === isEmpty()) { } else {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.array.length; j++) {
          if (Number(this.specializationSkillsData[i].code) === this.array[j]) {
            selectedPrePrimarySkills3.push(
              this.specializationSkillsData[i].label
            );
          } else {
          }
        }
      }
    }
    return selectedPrePrimarySkills3;
  }

  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.searchData1();
  }
  requirementRoute(data) {
    sessionStorage.setItem('requestData', JSON.stringify(data));
    this.route.navigate(['/rec-requirement-edit'], {
      queryParams: {
        ownerId: data.ownerId,
        name: this.name,
        reqId: data.reqId,
        tdate: data.plannedEndDate,
        reqStatus: data.reqStatus,
        maxRate: data.maxRate,
        reqName: data.reqName,
        parentreqId: data.parentReqId,
        createdbyaccID: data.createdByAccId,
        from: 'reqEdit',
      },
    });
  }
  toggle(event, data) {
    this.rowData = data;
    if (this.roleId === 9 && this.rowData.reqStatus === 'A') {
      this.items = [
        {
          label: 'Release',
          icon: 'pi pi-arrow-circle-right',
          command: () => {
            this.showReleaseDialog();
          },
        },
        {
          label: 'Requirement Activity',
          icon: 'pi pi-fw pi-clock',
          command: () => {
            this.profilehistroy();
          },
        },
        //   { label: 'Sub Contractor', icon: 'fa fa-users', command: (event, i) => { this.route.navigate(['/subcontractor-details'],
        //   {
        //     queryParams:{
        //       subcontractor:'subcontractor',
        //       reqId:this.rowData.reqId
        //     }
        //   }) }
        // },
      ];
    }
    else if (this.rowData.reqStatus === 'J') {
      this.items = [
        {
          label: 'Rejection Reason',
          icon: 'pi pi-comments',
          // tslint:disable-next-line:no-shadowed-variable
          command: (event, i) => {
            this.showReasonDialog(this.rowData);

          },
        },
        {
          label: 'Requirement Activity',
          icon: 'pi pi-fw pi-clock',
          command: () => {
            this.profilehistroy();
          },
        },
        //   { label: 'Sub Contractor', icon: 'fa fa-users', command: (event, i) => { this.route.navigate(['/subcontractor-details'],
        //   {
        //     queryParams:{
        //       subcontractor:'subcontractor',
        //       reqId:this.rowData.reqId
        //     }
        //   })
        // } },
      ];
    } else if (this.rowData.reqStatus === 'R' && this.rowData.releaseToPublic === 'Y') {
      this.items = [

        {
          label: 'Public Requirement',
          icon: 'pi pi-fw pi-users',
          command: () => {
            this.publicRequirement();
          },
        },
        {
          // tslint:disable-next-line:no-shadowed-variable
          label: 'Subcontractor', icon: 'fa fa-users', command: (event, i) => {
            this.route.navigate(['/subcontractor-details'],
              {
                queryParams: {
                  subcontractor: 'subcontractor',
                  reqId: this.rowData.reqId,
                  role: 'HCM'
                }
              });
          }
        },
        {
          label: 'Requirement Activity',
          icon: 'pi pi-fw pi-clock',
          command: () => {
            this.profilehistroy();
          },
        }, ];
    }
    else if (this.rowData.reqStatus === 'R') {
      this.items = [
        {
          // tslint:disable-next-line:no-shadowed-variable
          label: 'Subcontractor', icon: 'fa fa-users', command: (event, i) => {
            this.route.navigate(['/subcontractor-details'],
              {
                queryParams: {
                  subcontractor: 'subcontractor',
                  reqId: this.rowData.reqId,
                  role: 'HCM'
                }
              });
          }
        },
        {
          label: 'Requirement Activity',
          icon: 'pi pi-fw pi-clock',
          command: () => {
            this.profilehistroy();
          },
        }, ];
    }
    else {
      this.items = [

        {
          label: 'Requirement Activity',
          icon: 'pi pi-fw pi-clock',
          command: () => {
            this.profilehistroy();
          },
        },
        //   { label: 'Sub Contractor', icon: 'fa fa-users', command: (event, i) => { this.route.navigate(['/subcontractor-details'],
        //   {
        //     queryParams:{
        //       subcontractor:'subcontractor',
        //       reqId:this.rowData.reqId
        //     }
        //   }) }
        //  },
      ];
    }
  }
  publicRequirement() {
    Swal.fire({
      text: 'Requirement release to public',
      icon: 'success',
      confirmButtonColor: '#0D416B',
      iconColor: '#1dbb99',
      confirmButtonText: 'Ok',
      reverseButtons: false,
    });
  }
  reqSkills1(data) {
    this.display = true;
    this.reqSkillsValue = data;
  }
  searchData1() {
    this.loader = true;
    const actId = JSON.parse(this.apisService.decodeData());
    const pgFlag = this.page;
    const pgNo = this.rows;
    let statusCheck = '';
    if (!this.requirementsForm.controls.requirementsStatus.value) {
      statusCheck = '';
    } else {
      statusCheck =
        this.requirementsForm.controls.requirementsStatus.value.code;
    }
    const reqId =
      this.requirementsForm.controls.requirementsJobId.value === null
        ? ''
        : this.requirementsForm.controls.requirementsJobId.value;

    const jobTitle =
      this.requirementsForm.controls.requirementsJobTitle.value === null
        ? ''
        : this.requirementsForm.controls.requirementsJobTitle.value;

    const reqStatus =
      this.requirementsForm.controls.requirementsStatus.value === null
        ? ''
        : this.requirementsForm.controls.requirementsStatus.value;

    if (
      this.requirementsForm.controls.requirementsStartDate.value === '' ||
      this.requirementsForm.controls.requirementsStartDate.value === null ||
      this.requirementsForm.controls.requirementsStartDate.value === undefined
    ) {
      this.requirementsStartDate = '';
    } else {
      const startdate =
        this.requirementsForm.controls.requirementsStartDate.value;
      const reqstartDate = new Date(startdate);
      this.requirementsStartDate = this.datepipe.transform(
        reqstartDate,
        'MM-dd-yyyy'
      );
    }
    if (
      this.requirementsForm.controls.requirementsEndDate.value === '' ||
      this.requirementsForm.controls.requirementsEndDate.value === null ||
      this.requirementsForm.controls.requirementsEndDate.value === undefined
    ) {
      this.requirementsEndDate = '';
    } else {
      const enddate = this.requirementsForm.controls.requirementsEndDate.value;
      const reqendDate = new Date(enddate);
      this.requirementsEndDate = this.datepipe.transform(
        reqendDate,
        'MM-dd-yyyy'
      );
    }
    const data =
      pgFlag +
      '/' +
      pgNo +
      '?reqId=' +
      reqId +
      '&reqName=' +
      jobTitle +
      '&reqStatus=' +
      statusCheck +
      '&startDate=' +
      this.requirementsStartDate +
      '&endDate=' +
      this.requirementsEndDate +
      '&primaryRole=' +
      this.roleId;
    this.apisService.getApi(environment.vedorReq + data).subscribe((res) => {
      this.loader = false;
      this.griddata = res.data.records;
      this.griddCount = res.data.count;
      if (this.griddCount !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  showReleaseDialog() {
    this.releaseForm.reset();
    this.apisService.getApi(environment.getVendorNames + '?accId=' + this.rowData.createdByAccId).subscribe((res: any) => {
      this.vendorNames = res.data;
    });
    this.releaseDialog = true;
  }
  releasedDialogBox() {
    const data = this.rowData;
    this.reqIdData = data.reqId;
    if (data.reqStatus === 'A') {
      this.releaseDialog = false;
      this.depositPolicy();
    }
  }
  depositPolicy() {
    Swal.fire({
      text: 'Do you want to release requirement?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0D416B',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',

    }).then((result) => {
      if (result.value) {
        return this.releasedYes();
      }
      this.releaseDialog = false;
    });
  }
  releasedYes() {
    // tslint:disable-next-line:max-line-length
    this.actId = this.releaseForm.controls.vendorNameControl.value === null || this.releaseForm.controls.vendorNameControl.value === undefined || this.releaseForm.controls.vendorNameControl.value === '' ? 0 : this.releaseForm.controls.vendorNameControl.value.accId;

    const checkValue = this.releaseForm.controls.checkvalue.value === null || undefined || '' ? 'N' : 'Y';

    this.apisService
      .getApi(environment.getRealeseReq + this.reqIdData + '?venId=' + this.actId + '&flag=' + checkValue)
      .subscribe((res: any) => {
        if (res.hasOwnProperty('success') === true) {
          Swal.fire({
            text: res.message,
            icon: 'success',
            confirmButtonColor: '#0D416B',
            iconColor: '#1dbb99',
            confirmButtonText: 'Ok',
            reverseButtons: false,
          });
          this.releaseDialog = false;
          this.getGridData();
        } else if (
          res.hasOwnProperty('success') === false
        ) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
        }
      });
    this.getGridData();
  }
  reset() {
    this.requirementsForm.reset();
    this.getGridData();
  }
  downLoadsToggle() {
    this.downloadOptions = [
      { label: 'Download XLSX', icon: 'pi pi-download', command: (event, i) => { this.exportExcel(); } },
      { label: 'Download PDF', icon: 'pi pi-download', command: (event, i) => { this.exportPdf(); } },
    ];
  }
  exportExcel() {
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    // tslint:disable-next-line:max-line-length
    ws['!cols'] = [ {width : 10}, {width : 10}  , {width : 30} , {width : 10}, {width : 20}, {width : 20}, {width : 20}, {width : 10}, {width : 20}, {hidden : true} ];
    ws['!rows'] = [{hpt : 30}, {hpt : 30}, ];
    ws['!cols'][4] = { hidden: true };
    ws['!cols'][9] = { hidden: true };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ExcelSheet.xlsx');
  }
  exportPdf() {
    this.griddata.forEach((element) => {
      element.status = this.getStatusData[element.reqStatus];
      element.skills = this.getSkillSetTable(element.requiredSkills);
    });
    const doc = new jsPDF('p', 'pt');
    autoTable(doc, {
      headStyles: { fillColor: '#2bb9d5', cellPadding: 5   },
      margin: { left: 17},
      columns: this.cols3,
      body: this.griddata,
      didDrawPage: (dataArg) => {
        doc.text('Requirements', dataArg.settings.margin.left, 30);
      }
    });
    doc.save('Requirements.pdf');
  }
  profilehistroy() {
    this.displayModal = true;
    this.date = new Date();
    const actId = JSON.parse(this.apisService.decodeData());
    this.apisService.getApi(environment.getreqActivity + this.rowData.reqId).subscribe((res: any) => {
      this.histroy = res.data;
      this.histroy1 = this.rowData.reqId;
    });
  }

  showReasonDialog(rowData) {
    this.reason = rowData.approverComments;
    this.displayreason = true;
  }
  routeToRequirements() {
    this.route.navigate(['/add-requiremenent'], {
      queryParams: {
        Hcm: 'HCM'
      }
    });
  }
}
