<div class="layout-footer">
    <div class="p-grid ui-fluid">
      <div class="p-col-12 p-md-6" style="margin-top: 0%;">
        <p style="margin-left: 11px;" class="text">Copyright © {{currenYearFormat}} ServicesBay. All rights reserved.</p>
      </div>
      <div class="p-col-12 p-md-6 footer-icons">
        <a href="#">
          <em class="fa fa-facebook-square" style="color: #3b5998;" aria-hidden="true"></em>
        </a>
        <a href="#">
          <em class="fa fa-twitter-square" aria-hidden="true" style="color: #1DA1F2;"></em>
        </a>
        <a href="#">
          <em class="fa fa-linkedin-square" aria-hidden="true" style="color: #0077b5;"></em>
        </a>
      </div>
    </div>
  </div>
  