import { DatePipe } from '@angular/common';
import { HostListener } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-tech-reviews',
  templateUrl: './tech-reviews.component.html',
  styleUrls: ['./tech-reviews.component.scss'],
  providers: [DatePipe, MessageService],
})
export class TechReviewsComponent implements OnInit {
  techReviewsListForm: FormGroup;
  popTechReviewsForm: FormGroup;
  loginRole: any;
  techReviewGridData: any;
  userIdValue: any;
  orgIdValue: any;
  searchData1: any;
  fromDate: any;
  endDate: any;
  resume: any;
  attachementId: any;
  filename = 'resume.doc';
  techReviewData: any;
  loader = false;
  searchListData: any;
  iconToggle = [];
  // It is used for the displaying the table header details
  cols = [
    { field: 'Name', header: 'Name'},
    { field: 'email1', header: 'Email'},
    { field: 'reviewedBy', header: 'Reviewed By'},
    { field: 'phone1', header: 'Reviewer'},
    { field: 'review_type', header: 'Interview Type'},
    { field: 'scheduled_date', header: 'Date & Time'},
    { field: 'status', header: 'Status'},
    { field: '', header: 'Action'},
  ];
  roleId: any;
  display1: boolean;
  downloadOption = false;
  display12: boolean;
  display3: boolean;
  display4: any;
  online: boolean;
  disabled1: any;
  interStatus: any;
  rowData: any;
  rowdata: any;
  popData: any;
  revType: any;
  consuId: any;
  forwadId: any;
  usrId: any;
  reqId: any;
  saveData: any;
  interStatusCsr: any;
  rowData1: any;
  displayConsultantDailog: boolean;
  commentData: boolean;
  interStatusConsul: any;
  totalRecords: any;
  first: any;
  rows: any = 10;
  page: any = 1;
  pageCount: any;
  gridRowData: any;
  display123: boolean;
  consultantData: boolean;
  savebutton: boolean;
  reviews: boolean;
  skills: boolean;
  skillsDetails: boolean;
  notes: boolean;
  location: boolean;
  onlineExam: boolean;
  // tslint:disable-next-line:variable-name
  schedul_Date: any;
  csrRowData: any;
  rejectData: any;
  rejectbutton: boolean;
  skilsdata: any;
  result: any;
  userId: any;
  reqId1: any;
  venId: any;
  // tslint:disable-next-line:variable-name
  req_id: any;
  // tslint:disable-next-line:variable-name
  user_id1: any;
  csrId: any;
  techViews: any;
  minDate: Date;
  name: any;
  items: any = [];
  loginData: any;
  interviewMode: any;
  totalRecords1: number;
  table = true;
  records: boolean;
  statusData: any;
  statusDD: any;
  proID: string;
  requId: string;
  actId: any;
  useId: string;
  resumeRes: any;
  reqname: string;
  vendorId: string;
  queryParam: string;
  conId: string;
  type: string;
  showDialog: boolean;
  isUploading1 = false;
  reviewdata: any;
  reviewerName: any;
  reviewDate: any;
  reviewerData1: any;
  entries: any[];
  from: string;
  filedata: any;
  vendorID: string;
  techflag: string;
  usrroleid: any;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private messageService: MessageService, private apiService: ApisService, private datepipe: DatePipe, private router: Router, private route: ActivatedRoute, private breadcrumbService: BreadcrumbService) {
  }
  forwardedByDetailsDisplay: boolean;
  forwardedByName: any;
  emailId: any;
  phoneNumber: any;
  // It is used for the displaying the  interview type Dropdown Values
  interviewtype = [
    { name: 'All', code: '-1' },
    { name: 'Face to Face', code: 'Face to Face' },
    { name: 'Telephone', code: 'Telephonic' },
    { name: 'Skype', code: 'Skype' },
    { name: 'Written', code: 'Written' },
    { name: 'HR', code: 'hr' },
    { name: 'Online', code: 'Online' },
    { name: 'Psychometric', code: 'Psychometric' },
    { name: 'WebEx', ode: 'WebEx' },
  ];
  // It is used for the displaying the  Status Dropdown Values
  status = [
    { name: 'All', code: '-1' },
    { name: 'Processing', code: 'Processing' },
    { name: 'ShortListed', code: 'ShortListed' },
    { name: 'Rejected', code: 'Rejected' },
    { name: 'Selected', code: 'Selected' },
    { name: 'Accepted', code: 'Accepted' },
  ];
  // It is used for the displaying the interview Notifcation  Status Dropdown Values
  interviewNotifyStatus = [
    { name: 'All', code: '-1' },
    { name: 'CSR Pending', code: 'CSRPending' },
    { name: 'Consultant Pending', code: 'ConPending' },
    { name: 'Vendor Pending', code: 'VenPending' },
    { name: 'Accepted', code: 'Accepted' },
    { name: 'Rejected', code: 'Rejected' },
  ];
  display: boolean;
  fromErrorMessage: string;
  toErrorMessage: string;
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.searchTechReviewData();
    }
  }
  ngOnInit() {
    this.proID = this.route.snapshot.queryParamMap.get('proId');
    this.requId = this.route.snapshot.queryParamMap.get('reqId');
    this.reqname = this.route.snapshot.queryParamMap.get('reqname');
    this.techflag = this.route.snapshot.queryParamMap.get('techflag');
    this.vendorId = this.route.snapshot.queryParamMap.get('vendorId');
    this.vendorID = this.route.snapshot.queryParamMap.get('vendorID');
    this.userId = this.route.snapshot.queryParamMap.get('userId');
    this.conId = this.route.snapshot.queryParamMap.get('conId');
    this.type = this.route.snapshot.queryParamMap.get('type');
    this.techReviewsListForm = this.fb.group({
      interviewType: [''],
      status: [''],
      fromDate: [''],
      toDate: [''],
      interviewNotificationStatus: [''],
    });
    this.popTechReviewsForm = this.fb.group({
      name: [''],
      title: [''],
      email: [''],
      mobile: [''],
      date: [''],
      status1: [''],
      skillDetails1: [''],
      location: [''],
      techniicalSkills: ['', Validators.required],
      domainSkills: ['', Validators.required],
      communicationSkills: ['', Validators.required],
      comments: ['', Validators.required],
      examPerformance: [''],
      skillname: [''],
      notes: [''],
      examavg: [''],
      tecStatus: [''],
      commentsConsulForm: ['', Validators.required],
      requirementsComments: [''],
      average: [''],
      position: [''],
      documentation: [''],
      communication: [''],
      analytical: [''],
      management: [''],
      ownershsip: [''],
      business: [''],
      testing: [''],
      coding: [''],
      architecture: ['']
    });
    this.loginData = JSON.parse(this.apiService.decodeData());
    this.apiService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === ''){
      this.roleId = this.loginData.roles[0].id;
    }else{
      this.roleId = this.usrroleid;
    }
    this.from = this.route.snapshot.queryParamMap.get('from');
    if (this.roleId === 5) {
      this.breadcrumbService.setItems([
        { label: 'Requirements', routerLink: '/project-requirements' },
        { label: 'Requirement Edit', routerLink: '/project-edit-requirement' },
        { label: 'Tech Reviews', routerLink: '/tech-reviews' },
      ]);
    }
    else if (this.roleId === 9) {
      if (this.type === 'open') {
        this.breadcrumbService.setItems([
          { label: 'Open Positions', routerLink: '/open-positions' },
          { label: 'Open Position Edit', routerLink: '/open-position-edit' },
          { label: 'Tech Reviews', routerLink: '/tech-reviews' },
        ]);
      }
      else {
        this.breadcrumbService.setItems([
          { label: 'Requirements', routerLink: '/rec-requirements' },
          { label: 'Requirement Edit', routerLink: '/rec-requirement-edit' },
          { label: 'Tech Reviews', routerLink: '/tech-reviews' },
        ]);
      }
    }
    this.getInterviewList();
    this.getStatus();
    this.actId = JSON.parse(this.apiService.decodeData());
    this.apiService.rolebasedsidemenu.subscribe((res1: any) => {
      this.usrroleid = res1.id;
    });
    if (this.usrroleid === undefined || this.usrroleid === null || this.usrroleid === ''){
      this.roleId = this.actId.roles[0].id;
    }else{
      this.roleId = this.usrroleid;
    }
    if (this.roleId === 9) {
      this.gridTable();
    } else {
      this.gridTable();
      this.getInterviewList();
      this.getStatus();
    }
  }
  gridTable() {
    this.loader = true;
    const searchstatus = this.techReviewsListForm.controls.status.value === null || this.techReviewsListForm.controls.status.value === '' ? '' : this.techReviewsListForm.controls.status.value === undefined ? '' : this.techReviewsListForm.controls.status.value.id;
    // tslint:disable-next-line:max-line-length
    const searchinterviewType = this.techReviewsListForm.controls.interviewType.value === null || this.techReviewsListForm.controls.interviewType.value === '' ? '' : this.techReviewsListForm.controls.interviewType.value === undefined ? '' : this.techReviewsListForm.controls.interviewType.value.id;
    const startDate = this.techReviewsListForm.controls.fromDate.value === null || this.techReviewsListForm.controls.fromDate.value === '' ? '' : this.techReviewsListForm.controls.fromDate.value === undefined ? '' : this.techReviewsListForm.controls.fromDate.value;
    // tslint:disable-next-line:max-line-length
    const endDate = this.techReviewsListForm.controls.toDate.value === null || this.techReviewsListForm.controls.toDate.value === '' ? '' : this.techReviewsListForm.controls.toDate.value === undefined ? '' : this.techReviewsListForm.controls.toDate.value;
    const rows = this.rows;
    const page = this.page;
    // tslint:disable-next-line:max-line-length
    const ID = this.loginData.id === 5 ? this.loginData.accId : this.vendorId ||  this.loginData.id === 9 ? this.vendorId : this.loginData.accId;
    const gridid = this.techflag === 'T' ? this.vendorID : ID;
    // tslint:disable-next-line:max-line-length
    const id = this.loginData.id === 9 && this.type === 'open' ? this.conId : this.userId || this.loginData.id === 5 ? this.userId : this.conId;
    this.queryParam = page + '/' + rows + '/' + id + '?reqId=' + this.requId + '&vendorId=' + gridid;
    const interview = '&intreviewType=' + searchinterviewType;
    const status = '&status=' + searchstatus;
    const date1 = '&startDate=' + startDate;
    const date2 = '&endDate=' + endDate;
    this.apiService.getApi(environment.getReviewTable + this.queryParam + interview + status + date1 + date2).subscribe((res: any) => {
      this.techReviewData = res.data;
      this.loader = false;
      this.totalRecords1 = res.count;
      if (this.totalRecords1 !== 0) {
        this.table = true;
        this.records = false;
      } else {
        this.table = false;
        this.records = true;
      }
    });
  }
  add(data) {
    const rowId = data.id;
    this.router.navigate(['/addTechreviews'], {
      queryParams: {
        rowId: data.id,
        jobId: data.jobId,
      },
    });
  }
  getStatus() {
    this.apiService.getApi(environment.lookupsData + 'ERRS').subscribe((res) => {
      this.statusData = res.data;
    });
  }
  getstatusTable(data) {
    const item = data === null || undefined ? '' : this.statusData.find((element) => element.code === data).label;
    return item;
  }
  // It is used for server side pagenation
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridTable();
  }
  getInterviewList() {
    this.apiService.getApi(environment.getInterviewType).subscribe((res: any) => {
      this.interviewMode = res.data;
    });
  }
  // It is used for the displaying the GridData
  toggle(menu, event, rowData) {
    this.rowData = rowData;
    this.items = [
      // tslint:disable-next-line: no-shadowed-variable
      { label: 'Show Profile', icon: 'pi pi-fw pi-download', command: (event, i) => { this.downloadFileapi(rowData); } },
      { label: 'Show Ratings', icon: 'pi pi-fw pi-star-o', command: (event1, i) => { this.goto(rowData); } },
    ];
  }
  emailToast(){
    this.messageService.add({ key: 'br', severity: '', life: 3000, detail: 'Email copied to clipboard.' });
  }
  getInterView(data) {
    if (data === '') {
      const invty = '--';
      return invty;
    } else {
      const invty = this.interviewMode.find((x) => x.code === data).label;
      return invty;
    }
  }
  getInterDate(data) {
    const intdt = (this.fromDate = this.datepipe.transform(data, 'MM-dd-yyyy & HH:MM:SS'));
    return intdt;
  }
  // It is used for the searching the the data based on the griddata
  searchTechReviewData() {
    // tslint:disable-next-line:max-line-length
    if (this.techReviewsListForm.get('fromDate').value === undefined || this.techReviewsListForm.get('fromDate').value === '' || this.techReviewsListForm.get('fromDate').value === null
    ) {
      this.fromDate = '';
    } else {
      const date = this.techReviewsListForm.get('fromDate').value;
      const fromDate = new Date(Date.parse(date));
      this.fromDate = this.datepipe.transform(fromDate, 'MM-dd-yyyy');
    }
    // tslint:disable-next-line:max-line-length
    if (this.techReviewsListForm.get('toDate').value === undefined || this.techReviewsListForm.get('toDate').value === '' || this.techReviewsListForm.get('toDate').value === null) {
      this.endDate = '';
    } else {
      const date = this.techReviewsListForm.get('toDate').value;
      const toDate = new Date(Date.parse(date));
      this.endDate = this.datepipe.transform(toDate, 'MM-dd-yyyy');
    }
    if (this.fromDate !== '' && this.endDate === '') {
      this.fromErrorMessage = 'Please Select To Date';
      this.toErrorMessage = '';
    } else if (this.fromDate === '' && this.endDate !== '') {
      this.toErrorMessage = 'Please Select From Date';
      this.fromErrorMessage = '';
    } else {
      this.fromErrorMessage = '';
      this.toErrorMessage = '';
      const techStatus = this.techReviewsListForm.get('status').value;
      const intType = this.techReviewsListForm.get('interviewType').value;
      const intnotificationData = this.techReviewsListForm.get('interviewNotificationStatus').value;
      const techReviewStatus = techStatus === '' || techStatus === null || techStatus === undefined ? '' : techStatus.code;
      const reviewStartDate = this.fromDate;
      const reviewEndDate = this.endDate;
      const interviewType = intType === '' || intType === null || intType === undefined ? '' : intType.code;
      this.loader = true;
      const page = this.page;
      const row = this.rows;
      // tslint:disable-next-line:max-line-length
      const ID = this.loginData.id === 5 ? this.loginData.accId : this.vendorId ||  this.loginData.id === 9 ? this.vendorId : this.loginData.accId;
      const gridid = this.techflag === 'T' ? this.vendorID : ID;
      // const ID = this.loginData.id === 5 ? this.loginData.accId : this.vendorID || this.loginData.id === 9 ? this.vendorID : this.loginData.accId;
      // tslint:disable-next-line:max-line-length
      // const gridid = this.techflag !== 'T' ? this.vendorId : ID;
      // tslint:disable-next-line:max-line-length
      const id = this.loginData.id === 9 && this.type === 'open' ? this.conId : this.userId || this.loginData.id === 5 ? this.userId : this.conId;
      const payload = page + '/' + row + '/' + id + '?reqId=' + this.requId + '&vendorId=' + gridid + '&intreviewType=' + interviewType + '&status=' + techReviewStatus + '&startDate=' + reviewStartDate + '&endDate=' + reviewEndDate;
      this.apiService.getApi(environment.getReviewTable + payload).subscribe((res: any) => {
        this.techReviewData = res.data;
        this.totalRecords1 = res.count;
        this.loader = false;
        if (this.totalRecords1 === 0) {
          this.records = true;
          this.table = false;
        } else {
          this.records = false;
          this.table = true;
        }
      });
    }
  }
  // It is used for open the Tech Review Process dialog box based on grid data status and also based on roles hiding feilds
  showDailog(techData) {
    this.revType = techData.review_type;
    this.consuId = techData.id;
    this.forwadId = techData.forwarded_by;
    this.usrId = techData.usr_id;
    this.reqId = techData.req_id;
    this.router.navigate(['/consultantDetails_BackGroundCheck']);
    if (
      (this.roleId === 1 || this.roleId === 11 || this.roleId === 3) &&
      (techData.review_type === 'Telephonic' ||
        techData.review_type === 'Telephone' ||
        techData.review_type === 'Skype' ||
        techData.review_type === 'WebEx' ||
        techData.review_type === 'hr' ||
        techData.review_type === 'Written') &&
      (techData.status === 'Selected' || techData.status === 'ShortListed') &&
      (techData.interviewNotificationStatus === 'VenPending' ||
        techData.interviewNotificationStatus === 'Accepted')
    ) {
      this.consultantData = false;
      this.savebutton = false;
      this.reviews = true;
      this.skills = true;
      this.skillsDetails = true;
      this.notes = true;
      this.location = false;
      this.onlineExam = false;
      this.popTechReviewsForm.controls.notes.disable();
      this.popTechReviewsForm.controls.status1.enable();
      this.popTechReviewsForm.controls.techniicalSkills.disable();
      this.popTechReviewsForm.controls.communicationSkills.disable();
      this.popTechReviewsForm.controls.domainSkills.disable();
      this.popTechReviewsForm.controls.comments.disable();
      // tslint:disable-next-line: max-line-length
    } else if (
      (this.roleId === 1 || this.roleId === 11) &&
      (techData.review_type === 'Telephonic' ||
        techData.review_type === 'Telephone' ||
        techData.review_type === 'Skype' ||
        techData.review_type === 'WebEx' ||
        techData.review_type === 'hr' ||
        techData.review_type === 'Written') &&
      techData.status === 'Processing' &&
      (techData.interviewNotificationStatus === 'Accepted' ||
        techData.interviewNotificationStatus === 'Accept')
    ) {
      this.consultantData = false;
      this.savebutton = false;
      this.reviews = true;
      this.skills = true;
      this.skillsDetails = true;
      this.notes = true;
      this.location = false;
      this.onlineExam = false;
      this.popTechReviewsForm.controls.notes.disable();
      this.popTechReviewsForm.controls.status1.enable();
      this.popTechReviewsForm.controls.techniicalSkills.enable();
      this.popTechReviewsForm.controls.communicationSkills.enable();
      this.popTechReviewsForm.controls.domainSkills.enable();
      this.popTechReviewsForm.controls.comments.enable();
    } else if (
      (this.roleId === 1 || this.roleId === 11 || this.roleId === 3) &&
      techData.review_type === 'Face to Face' &&
      (techData.status === 'Selected' || techData.status === 'ShortListed') &&
      techData.interviewNotificationStatus === 'Accepted'
    ) {
      this.consultantData = false;
      this.savebutton = false;
      this.reviews = true;
      this.skills = true;
      this.skillsDetails = true;
      this.notes = false;
      this.location = true;
      this.onlineExam = false;
      this.popTechReviewsForm.controls.location.disable();
      this.popTechReviewsForm.controls.status1.enable();
      this.popTechReviewsForm.controls.techniicalSkills.disable();
      this.popTechReviewsForm.controls.communicationSkills.disable();
      this.popTechReviewsForm.controls.domainSkills.disable();
      this.popTechReviewsForm.controls.comments.disable();
    } else if (
      (this.roleId === 1 || this.roleId === 11) &&
      techData.review_type === 'Face to Face' &&
      techData.status === 'Processing' &&
      techData.interviewNotificationStatus === 'Accepted'
    ) {
      this.consultantData = false;
      this.savebutton = false;
      this.reviews = true;
      this.skills = true;
      this.skillsDetails = true;
      this.notes = false;
      this.location = true;
      this.onlineExam = false;
      this.popTechReviewsForm.controls.location.disable();
      this.popTechReviewsForm.controls.status1.enable();
      this.popTechReviewsForm.controls.techniicalSkills.enable();
      this.popTechReviewsForm.controls.communicationSkills.enable();
      this.popTechReviewsForm.controls.domainSkills.enable();
      this.popTechReviewsForm.controls.comments.enable();
    } else if (
      this.roleId === 11 &&
      (techData.review_type === 'Telephonic' ||
        techData.review_type === 'Telephone' ||
        techData.review_type === 'Skype' ||
        techData.review_type === 'WebEx' ||
        techData.review_type === 'hr' ||
        techData.review_type === 'Written') &&
      (techData.status === 'Processing' || techData.status === 'Accepted') &&
      (techData.interviewNotificationStatus === 'VenPending' ||
        techData.interviewNotificationStatus === 'Accepted')
    ) {
      this.consultantData = false;
      this.savebutton = false;
      this.reviews = true;
      this.skills = true;
      this.skillsDetails = true;
      this.notes = true;
      this.location = false;
      this.onlineExam = false;
      this.popTechReviewsForm.controls.notes.disable();
      this.popTechReviewsForm.controls.status1.enable();
      this.popTechReviewsForm.controls.techniicalSkills.enable();
      this.popTechReviewsForm.controls.communicationSkills.enable();
      this.popTechReviewsForm.controls.domainSkills.enable();
      this.popTechReviewsForm.controls.comments.enable();
    } else if (
      this.roleId === 11 &&
      (techData.review_type === 'Telephonic' ||
        techData.review_type === 'Telephone' ||
        techData.review_type === 'Skype' ||
        techData.review_type === 'WebEx' ||
        techData.review_type === 'hr' ||
        techData.review_type === 'Written') &&
      (techData.status === 'Processing' || techData.status === 'Selected') &&
      (techData.interviewNotificationStatus === 'CSRPending' ||
        techData.interviewNotificationStatus === 'conPending' ||
        techData.interviewNotificationStatus === 'ConPending')
    ) {
      this.consultantData = true;
      this.savebutton = false;
      this.reviews = true;
      this.skills = true;
      this.skillsDetails = true;
      this.notes = true;
      this.location = false;
      this.onlineExam = false;
      this.popTechReviewsForm.controls.notes.disable();
      this.popTechReviewsForm.controls.status1.enable();
      this.popTechReviewsForm.controls.techniicalSkills.enable();
      this.popTechReviewsForm.controls.communicationSkills.enable();
      this.popTechReviewsForm.controls.domainSkills.enable();
      this.popTechReviewsForm.controls.comments.enable();
    } else if (
      this.roleId === 11 &&
      techData.review_type === 'Face to Face' &&
      techData.status === 'Processing' &&
      (techData.interviewNotificationStatus === 'CSRPending' ||
        techData.interviewNotificationStatus === 'conPending' ||
        techData.interviewNotificationStatus === 'ConPending')
    ) {
      this.consultantData = true;
      this.savebutton = false;
      this.reviews = true;
      this.skills = true;
      this.skillsDetails = true;
      this.notes = false;
      this.location = true;
      this.onlineExam = false;
      this.popTechReviewsForm.controls.location.disable();
      this.popTechReviewsForm.controls.status1.enable();
      this.popTechReviewsForm.controls.techniicalSkills.enable();
      this.popTechReviewsForm.controls.communicationSkills.enable();
      this.popTechReviewsForm.controls.domainSkills.enable();
      this.popTechReviewsForm.controls.comments.enable();
    } else if (
      this.roleId === 11 &&
      techData.review_type === 'Face to Face' &&
      techData.status === 'Processing' &&
      techData.interviewNotificationStatus === 'VenPending'
    ) {
      this.consultantData = false;
      this.savebutton = false;
      this.reviews = true;
      this.skills = true;
      this.skillsDetails = true;
      this.notes = false;
      this.location = true;
      this.onlineExam = false;
      this.popTechReviewsForm.controls.location.disable();
      this.popTechReviewsForm.controls.status1.enable();
      this.popTechReviewsForm.controls.techniicalSkills.enable();
      this.popTechReviewsForm.controls.communicationSkills.enable();
      this.popTechReviewsForm.controls.domainSkills.enable();
      this.popTechReviewsForm.controls.comments.enable();
    } else if (
      (this.roleId === 1 || this.roleId === 3 || this.roleId === 13) &&
      (techData.review_type === 'Telephonic' ||
        techData.review_type === 'Telephone' ||
        techData.review_type === 'Skype' ||
        techData.review_type === 'WebEx' ||
        techData.review_type === 'hr' ||
        techData.review_type === 'Written') &&
      techData.status === 'Processing' &&
      (techData.interviewNotificationStatus === 'CSRPending' ||
        techData.interviewNotificationStatus === 'VenPending' ||
        techData.interviewNotificationStatus === 'conPending' ||
        techData.interviewNotificationStatus === 'ConPending')
    ) {
      this.consultantData = true;
      this.savebutton = false;
      this.reviews = true;
      this.skills = true;
      this.skillsDetails = true;
      this.notes = true;
      this.location = false;
      this.popTechReviewsForm.controls.notes.disable();
      this.popTechReviewsForm.controls.status1.enable();
      this.popTechReviewsForm.controls.techniicalSkills.enable();
      this.popTechReviewsForm.controls.communicationSkills.enable();
      this.popTechReviewsForm.controls.domainSkills.enable();
      this.popTechReviewsForm.controls.comments.enable();
    } else if (
      (this.roleId === 1 || this.roleId === 3) &&
      techData.review_type === 'Face to Face' &&
      techData.status === 'Processing' &&
      (techData.interviewNotificationStatus === 'CSRPending' ||
        techData.interviewNotificationStatus === 'VenPending' ||
        techData.interviewNotificationStatus === 'conPending' ||
        techData.interviewNotificationStatus === 'ConPending')
    ) {
      this.consultantData = true;
      this.reviews = true;
      this.skills = true;
      this.skillsDetails = true;
      this.notes = false;
      this.location = true;
      this.popTechReviewsForm.controls.location.disable();
      this.popTechReviewsForm.controls.status1.enable();
      this.popTechReviewsForm.controls.techniicalSkills.enable();
      this.popTechReviewsForm.controls.communicationSkills.enable();
      this.popTechReviewsForm.controls.domainSkills.enable();
      this.popTechReviewsForm.controls.comments.enable();
    } else if (
      (this.roleId === 3 || this.roleId === 13) &&
      (techData.review_type === 'Telephonic' ||
        techData.review_type === 'Telephone' ||
        techData.review_type === 'Skype' ||
        techData.review_type === 'WebEx' ||
        techData.review_type === 'hr' ||
        techData.review_type === 'Written') &&
      techData.status === 'Processing' &&
      techData.interviewNotificationStatus === 'Accepted'
    ) {
      this.consultantData = false;
      this.savebutton = true;
      this.reviews = true;
      this.skills = true;
      this.skillsDetails = true;
      this.notes = true;
      this.location = false;
      this.popTechReviewsForm.controls.notes.disable();
      this.popTechReviewsForm.controls.status1.enable();
      this.popTechReviewsForm.controls.techniicalSkills.enable();
      this.popTechReviewsForm.controls.communicationSkills.enable();
      this.popTechReviewsForm.controls.domainSkills.enable();
      this.popTechReviewsForm.controls.comments.enable();
    } else if (
      (this.roleId === 3 || this.roleId === 13) &&
      techData.review_type === 'Face to Face' &&
      techData.status === 'Processing' &&
      techData.interviewNotificationStatus === 'Accepted'
    ) {
      this.consultantData = false;
      this.savebutton = true;
      this.reviews = true;
      this.skills = true;
      this.skillsDetails = true;
      this.location = true;
      this.notes = false;
      this.popTechReviewsForm.controls.location.disable();
      this.popTechReviewsForm.controls.status1.enable();
      this.popTechReviewsForm.controls.techniicalSkills.enable();
      this.popTechReviewsForm.controls.communicationSkills.enable();
      this.popTechReviewsForm.controls.domainSkills.enable();
      this.popTechReviewsForm.controls.comments.enable();
    } else if (
      (this.roleId === 1 ||
        this.roleId === 11 ||
        this.roleId === 3 ||
        this.roleId === 13) &&
      techData.review_type === 'Online' &&
      techData.status === 'Processing' &&
      (techData.interviewNotificationStatus === 'CSRPending' ||
        techData.interviewNotificationStatus === 'VenPending' ||
        techData.interviewNotificationStatus === 'conPending' ||
        techData.interviewNotificationStatus === 'ConPending')
    ) {
      this.consultantData = true;
      this.notes = false;
      this.skills = false;
      this.savebutton = false;
      this.skillsDetails = false;
      this.savebutton = false;
      this.location = false;
      this.reviews = false;
      this.onlineExam = false;
      this.rejectbutton = false;
      this.popTechReviewsForm.controls.status1.disable();
      this.popTechReviewsForm.controls.comments.enable();
    } else if (
      (this.roleId === 1 || this.roleId === 11) &&
      techData.review_type === 'Online' &&
      techData.status === 'Processing' &&
      techData.interviewNotificationStatus === 'Accepted'
    ) {
      this.consultantData = false;
      this.notes = false;
      this.skills = false;
      this.skillsDetails = false;
      this.savebutton = false;
      this.location = false;
      this.reviews = false;
      this.onlineExam = false;
      this.rejectbutton = false;
      this.popTechReviewsForm.controls.status1.disable();
      this.popTechReviewsForm.controls.comments.enable();
    } else if (
      (this.roleId === 1 ||
        this.roleId === 11 ||
        this.roleId === 3 ||
        this.roleId === 13) &&
      techData.review_type === 'Online' &&
      (techData.status === 'Selected' || techData.status === 'ShortListed') &&
      (techData.interviewNotificationStatus === 'Accepted' ||
        techData.interviewNotificationStatus === 'Rejected')
    ) {
      this.consultantData = false;
      this.notes = false;
      this.skills = false;
      this.savebutton = false;
      this.skillsDetails = false;
      this.savebutton = false;
      this.location = false;
      this.reviews = false;
      this.onlineExam = true;
      this.rejectbutton = false;
      this.popTechReviewsForm.controls.status1.disable();
      this.popTechReviewsForm.controls.comments.enable();
    } else if (
      (this.roleId === 3 || this.roleId === 13) &&
      techData.review_type === 'Online' &&
      techData.status === 'Processing' &&
      techData.interviewNotificationStatus === 'Accepted'
    ) {
      this.consultantData = false;
      this.notes = false;
      this.skills = false;
      this.savebutton = true;
      this.skillsDetails = false;
      this.savebutton = false;
      this.location = false;
      this.reviews = false;
      this.onlineExam = false;
      this.rejectbutton = false;
      this.popTechReviewsForm.controls.status1.disable();
      this.popTechReviewsForm.controls.comments.enable();
    } else if (
      (this.roleId === 1 ||
        this.roleId === 11 ||
        this.roleId === 3 ||
        this.roleId === 13) &&
      techData.review_type === 'Online' &&
      techData.status === 'Exam completed' &&
      (techData.interviewNotificationStatus === 'CSRPending' ||
        techData.interviewNotificationStatus === 'VenPending' ||
        techData.interviewNotificationStatus === 'conPending' ||
        techData.interviewNotificationStatus === 'ConPending')
    ) {
      this.consultantData = true;
      this.notes = false;
      this.skills = false;
      this.savebutton = false;
      this.skillsDetails = false;
      this.savebutton = false;
      this.location = false;
      this.reviews = false;
      this.onlineExam = true;
      this.rejectbutton = true;
      this.popTechReviewsForm.controls.status1.disable();
      this.popTechReviewsForm.controls.comments.enable();
    } else if (
      (this.roleId === 1 ||
        this.roleId === 11 ||
        this.roleId === 3 ||
        this.roleId === 13) &&
      techData.review_type === 'Online' &&
      techData.status === 'ShortListed' &&
      (techData.interviewNotificationStatus === 'CSRPending' ||
        techData.interviewNotificationStatus === 'VenPending' ||
        techData.interviewNotificationStatus === 'conPending' ||
        techData.interviewNotificationStatus === 'ConPending')
    ) {
      this.consultantData = true;
      this.notes = false;
      this.skills = false;
      this.savebutton = false;
      this.skillsDetails = false;
      this.savebutton = false;
      this.location = false;
      this.reviews = false;
      this.onlineExam = true;
      this.rejectbutton = false;
      this.popTechReviewsForm.controls.status1.disable();
      this.popTechReviewsForm.controls.comments.enable();
    } else {
    }
    const data = {
      reviewType: this.revType,
      consultantId: this.usrId,
      consultantTechId: this.consuId,
      reqId: this.reqId,
    };
  }
  downloadFileapi(data) {
    this.filedata = data.resumeId;
    this.isUploading1 = true;
    this.apiService.getApi(environment.downloadedattachment + '/' + this.filedata).subscribe((res) => {
      this.resumeRes = res;
      this.isUploading1 = false;
      window.location.assign(this.resumeRes.downloadUrl);
    });
  }
  goto(data) {
    this.showDialog = true;
    this.apiService.getApi(environment.getReviewType + data.id).subscribe(res => {
      this.reviewdata = res.data;
      this.reviewerName = data.reviewerName;
      this.reviewDate = this.reviewdata[0].reviewDate;
      this.popTechReviewsForm.controls.architecture.patchValue(this.reviewdata[0].architecture);
      this.popTechReviewsForm.controls.coding.patchValue(this.reviewdata[0].coding);
      this.popTechReviewsForm.controls.testing.patchValue(this.reviewdata[0].testing);
      this.popTechReviewsForm.controls.business.patchValue(this.reviewdata[0].business);
      this.popTechReviewsForm.controls.ownershsip.patchValue(this.reviewdata[0].ownership);
      this.popTechReviewsForm.controls.management.patchValue(this.reviewdata[0].teammanagement);
      this.popTechReviewsForm.controls.analytical.patchValue(this.reviewdata[0].analytical);
      this.popTechReviewsForm.controls.communication.patchValue(this.reviewdata[0].communication);
      this.popTechReviewsForm.controls.documentation.patchValue(this.reviewdata[0].documentation);
      this.popTechReviewsForm.controls.position.patchValue(this.reviewdata[0].POSITION);
      this.popTechReviewsForm.controls.average.patchValue(this.reviewdata[0].averageRating);
      this.popTechReviewsForm.controls.requirementsComments.patchValue(this.reviewdata[0].comments);
    });
  }
  backNavigate() {
    if (this.roleId === 5) {
      this.router.navigate(['/project-edit-requirement']);
    }
    else if (this.roleId === 9 && this.type === 'open') {
      this.router.navigate(['/open-position-edit']);
    }
    else {
      this.router.navigate(['/rec-requirement-edit']);
    }
  }
  rejectbutton1() {
    if (this.popTechReviewsForm.invalid) {
      const controls = this.popTechReviewsForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      const data = {
        userId: this.forwadId,
        consultantId: this.usrId,
        reqId: this.reqId,
        consultantTechId: this.consuId,
        consultantComments: this.popTechReviewsForm.controls.comments.value,
        examStatus: 'Rejected',
      };
    }
  }
  // It is used for the downloading the particular row resume based on the grid
  resumeDownload(techData) {
    if (techData.req_status === 'Rejected') {
      this.downloadOption = false;
    } else {
      this.downloadOption = true;
    }
    const resumeReqBody = {
      consultantBackgroundFlag: '',
      acc_attachment_id: techData.acc_attachment_id,
      consId: JSON.parse(this.userIdValue),
    };
  }
  // It is used for convert the  downloaded  resume into base64
  downloadFile1(filepath, fileName) {
    this.loader = true;
  }
  // It is used for after conveting the base64 file check the extenstion and download it
  showPdf(base64string, fileName1) {
    const extension = fileName1.substring(fileName1.indexOf('.') + 1);
    if (extension === 'doc') {
      this.loader = false;
      const linkSource = 'data:application/msword;base64,' + base64string;
      const downloadLink = document.createElement('a');
      const fileName = fileName1;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } else if (extension === 'pdf') {
      this.loader = false;
      const linkSource = 'data:application/pdf;base64,' + base64string;
      const downloadLink = document.createElement('a');
      const fileName = fileName1;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } else if (extension === 'docx') {
      this.loader = false;
      const linkSource =
        'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' +
        base64string;
      const downloadLink = document.createElement('a');
      const fileName = fileName1;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } else if (extension === 'odt') {
      this.loader = false;
      const linkSource =
        'data:application/vnd.oasis.opendocument.text;base64,' + base64string;
      const downloadLink = document.createElement('a');
      const fileName = fileName1;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }
  // It is used for submited the online exam status
  shortListed() {
    if (this.popTechReviewsForm.invalid) {
      const controls = this.popTechReviewsForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      const data = {
        userId: this.forwadId,
        consultantId: this.usrId,
        reqId: this.reqId,
        consultantTechId: this.consuId,
        consultantComments: this.popTechReviewsForm.controls.comments.value,
        examStatus: 'ShortListed',
      };
    }
  }
  // It is used for navigate the consultant details back ground check page ,and also passing the data through query parms
  routeProfile(techData) {
    const id = JSON.parse(sessionStorage.getItem('loginData')).userId;
    this.techViews = 'techreviewsdetails';
    this.router.navigate(['/consultantDetails_BackGroundCheck'], {
      queryParams: {
        consultant_id: techData.usr_id,
        name: this.techViews,
        reqId: techData.req_id,
      },
    });
  }
  // It is used for the reset the form values
  reset() {
    this.techReviewsListForm.reset();
    this.gridTable();
  }
  // It is used for the saveing the Tech Review Process  Details and keep the validations
  addData() {
    const commskills = this.popTechReviewsForm.controls.communicationSkills.value;
    const techSkills = this.popTechReviewsForm.controls.techniicalSkills.value;
    const domainSkill = this.popTechReviewsForm.controls.domainSkills.value;
    const rating = Number(
      Number(commskills) + Number(techSkills) + Number(domainSkill)
    );
    const rating1 = (rating / 3).toFixed(2);
    if (this.popTechReviewsForm.invalid) {
      const controls = this.popTechReviewsForm.controls;
      Object.keys(controls).forEach((key) => {
        controls[key].markAsTouched();
      });
    } else {
      const data = {
        techSkill: this.popTechReviewsForm.controls.techniicalSkills.value.toString(),
        domainSkill: this.popTechReviewsForm.controls.communicationSkills.value.toString(),
        comSkill: this.popTechReviewsForm.controls.communicationSkills.value.toString(),
        rating: '6',
        consultantComments: this.popTechReviewsForm.controls.comments.value,
        finalTechReviewStatus: this.popTechReviewsForm.controls.status1.value.code,
        interviewType: this.revType,
        techTitle: this.popTechReviewsForm.controls.title.value,
        userId: this.forwadId,
        consultantId: this.usrId,
        reqId: this.reqId,
        consultantTechId: this.consuId,
      };
    }
  }
  // It is used for showing the alert message to  want forward notification to  Vendor
  intStausCsr(data) {
    this.csrId = data.id;
    this.userId = data.usr_id;
    this.reqId1 = data.req_id;
    if (data.interviewNotificationStatus === 'CSRPending') {
      this.csrRowData = data;
      // tslint:disable-next-line:max-line-length
      Swal.fire({
        title: 'Do you want forward notification to  Vendor?', text: '', icon: 'warning', showCancelButton: true, confirmButtonColor: '#0D416B', confirmButtonText: 'Yes', cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
         return this.sweetAlertIntStatusCsr();
        }
      });
    }
  }
  reviewDetails(data) {
    this.reviewerData1 = data;
    this.entries = [];
    this.entries.push(this.reviewerData1);
  }
  // It is used for showing the alert message to  want forward notification to  Consultant
  intStausVen(data) {
    this.venId = data.id;
    this.req_id = data.req_id;
    this.user_id1 = data.usr_id;
    if (data.interviewNotificationStatus === 'VenPending') {
      this.rowData1 = data;
      Swal.fire({
        title: ' Do you want forward notification to Consultant ? ',
        // tslint:disable-next-line: quotemark
        text: "", // 'You will not be able to recover the Certification details!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0D416B',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
        return this.sweetAlertIntStatusVen();
        }
      });
    }
  }
  // It is used for forward notification to  Vendor
  sweetAlertIntStatusCsr() {
    const data = {
      consultantTechId: this.csrId,
      reqId: this.reqId1,
      consultantId: this.userId,
      userId: JSON.parse(sessionStorage.getItem('loginData')).userId,
    };
  }
  // It is used for forward notification to  Consultant
  sweetAlertIntStatusVen() {
    const data = {
      userId: JSON.parse(sessionStorage.getItem('loginData')).userId,
      consultantTechId: this.venId,
    };
  }
  // It is used for open the Interview Notification Statu dialog box,  based on interview notification status in consultant role
  intStatusDialogBoxConsul(techData) {
    if (techData.interviewNotificationStatus === 'ConPending') {
      this.displayConsultantDailog = true;
      this.gridRowData = techData;
      this.commentData = false;
      this.techReviewsListForm.get('commentsConsulForm').reset();
      this.techReviewsListForm.controls.tecStatus.patchValue('Accepted');
    } else {
      this.displayConsultantDailog = false;
    }
  }
  // In Interview Notification Status dialog box,based on the status comments feild will be enable and disable
  select(data) {
    this.commentData = this.techReviewsListForm.controls.tecStatus.value === 'Accepted' ? false : true;
  }
  // It is used for the Submit the Interview Notification Status Details and keep the validations
  interviewStatusConsul() {
    if (this.techReviewsListForm.controls.tecStatus.value === 'Accepted') {
      this.techReviewsListForm.controls.commentsConsulForm.disable();
    } else {
      this.techReviewsListForm.controls.commentsConsulForm.enable();
    }
    if (this.techReviewsListForm.invalid) {
      this.techReviewsListForm.controls.commentsConsulForm.markAsTouched();
    } else {
      const data = {
        consultantTechId: this.gridRowData.id,
        // tslint:disable-next-line:max-line-length
        status: this.techReviewsListForm.controls.tecStatus.value === null || this.techReviewsListForm.controls.tecStatus.value === undefined ? '' : this.techReviewsListForm.get('tecStatus').value,
        comment: this.techReviewsListForm.controls.commentsConsulForm.value,
        userId: JSON.parse(sessionStorage.getItem('loginData')).userId,
      };
    }
  }
  // It is used for open the Forwarded By Details dialog box, get paticular consultant details like name ,phone number, and email id
  forwardedByDetails(data) {
    this.forwardedByDetailsDisplay = true;
    this.forwardedByName = data.fbyName;
  }
  // It is used for routing requirements edit or requirement details based on role id
  navigateTo(data) {
    this.name = 'techReview_req';
    sessionStorage.setItem('id', data);
    if (this.roleId === 3 || this.roleId === 1 || this.roleId === 13) {
      this.router.navigate(['/requirements-edit'], {
        queryParams: { name: this.name },
      });
    } else {
      this.router.navigate(['/requirementDetails', data], {
        queryParams: {
          name1: this.name,
        },
      });
    }
  }
  // It is used for end date validation
  endDateValidation() {
    if (
      new Date(this.techReviewsListForm.get('fromDate').value).getTime() <
      new Date(this.techReviewsListForm.get('toDate').value).getTime()
    ) {
    } else {
      this.techReviewsListForm.get('toDate').reset();
      const startDate = this.techReviewsListForm.get('fromDate').value;
    }
  }
  copyInputMessage(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
}
