import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  providers: [DatePipe, MessageService]
})
export class ProjectsComponent implements OnInit {
  projectForm: FormGroup;
  projectAddForm: FormGroup;
  displayModal: boolean;
  actId: any;
  name: any;
  disable: boolean;
  pageCount: any;
  page: any = 1;
  rows: any = 10;
  first: any;
  accountid: any;
  gridData: any;
  totalRecords: number;
  table: boolean;
  records: boolean;
  statuslist = {};
  loader: boolean;
  status: any = [];
  projecttype: any = [];
  projectypelist = {};
  startCurDate: Date;
  minDate: Date;
  date5: Date;
  projectEditForm: FormGroup;
  data: any;
  items: { label: string; icon: string; command: () => void; }[];
  displayModal1: boolean;
  editdata: any;
  id: any;
  resdata: any;
  updatedisable: boolean;
  projectName: any;
  status1: any[];
  status2 = [
    { code: 'A', id: 'PLS', label: 'Active' },
    { code: 'I', id: 'PLS', label: 'Inactive' },
    { code: 'D', id: 'PLS', label: 'Completed' },
  ];
  savestatus: any;
  savebutton: boolean;
  date1: any;
  enddate: Date;
  maxDate: Date;
  maxdate: Date;
  message: string;
  details: string;
  actualdate: any;
  remainingDays: number;
  duration: number;
  messagedetails: string;
  currencysymbl: any;
  preferencList: any;
  currencycode: any[];
  currencyValue: string;
  minDate1: any;
  statusdata: any;
  endDate: boolean;
  endDateMsg: boolean;
  actualEndDate: any;
  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private apisService: ApisService, private router: Router, private breadcrumbService: BreadcrumbService, private messageService: MessageService, private datePipe: DatePipe) {
    this.breadcrumbService.setItems([{ label: 'Projects', routerLink: '/onproject' }]);
  }
  // It is used for displaying table header data
  cols = [
    { field: 'projectname', header: 'Project Name', width: '12%' },
    { field: 'projecttype', header: 'Project Type', width: '12%' },
    { field: 'startdate', header: 'Actual Start Date', width: '10%' },
    { field: 'enddate', header: 'Actual End Date', width: '10%' },
    { field: 'status', header: 'Status', width: '10%' },
    { field: 'noofresourse', header: 'No.Of Resources', width: '12%' },
    { field: 'action', header: 'Action', width: '7%' }
  ];
  // It is used for displaying status dropdown values
  ngOnInit(): void {
    this.minDate = new Date();
    this.startCurDate = this.minDate;
    this.minDate.setMonth(new Date().getMonth());
    this.minDate.setFullYear(new Date().getFullYear());
    this.actId = JSON.parse(this.apisService.decodeData());
    this.actId = JSON.parse(this.apisService.decodeData());
    this.preferencList = this.actId.preferencesList;
    // tslint:disable-next-line:align
    if (this.preferencList === null || this.preferencList === undefined || this.preferencList.length === 0) {
      this.currencyValue = 'USD';
    }
    else {
      this.currencycode = [];
      this.preferencList.forEach(element => {
        this.currencycode[element.key] = [element.value];
      });
      // tslint:disable-next-line:no-string-literal
      this.currencyValue = this.currencycode['currency'].toString();
    }
    this.accountid = this.actId.accId;
    this.name = this.actId.name;
    this.projectForm = this.fb.group({
      projectname: [''],
      searchstatus: [''],
    });
    this.projectAddForm = this.fb.group({
      addProject: ['', [Validators.required, Validators.maxLength(50)]],
      addDes: ['', Validators.maxLength(255)],
      startdate: ['', Validators.required],
      enddate: ['', Validators.required],
      projecttype: ['', Validators.required],
      totalresource: [''],
      projecthrs: ['', [Validators.maxLength(8), Validators.pattern(/^-?(0|[1-9]\d*)?$/), ]],
      cost: ['', [Validators.maxLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/), ]],
      projectmanager: [''],
    });
    this.projectEditForm = this.fb.group({
      editProject: ['', [Validators.required, Validators.maxLength(50)]],
      editstatus: [''],
      estimatedstartdate: ['', Validators.required],
      estimatedenddate: ['', Validators.required],
      actualstartdate: ['', Validators.required],
      actualenddate: [''],
      editprojecttype: ['', Validators.required],
      editprojecthrs: ['', [Validators.maxLength(8), Validators.pattern(/^-?(0|[1-9]\d*)?$/), ]],
      editprojectcost: ['', [Validators.maxLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/), ]],
      editprojectmanager: [''],
      noofresource: [''],
      editDes: ['', Validators.maxLength(255)],
    });
    this.gridTable();
    this.gridstatus();
    this.projectType();
  }
  // It is used to display add pop up
  addproject() {
    this.displayModal = true;
    this.projectAddForm.reset();
    const firstDate: any = new Date();
    this.minDate = firstDate;
    this.projectAddForm.controls.projectmanager.patchValue(this.name);
    this.projectAddForm.controls.projecthrs.patchValue('1056');
    this.projectAddForm.controls.projecttype.patchValue({ code: 'D',  id: 'PLPT', label: 'Development' });
  }
  // It is used to reset the search field
  reset() {
    this.loader = true;
    this.projectForm.reset();
    this.gridTable();
  }
  saveproject() {
    this.disable = false;
    if (this.projectAddForm.invalid) {
      const controls = this.projectAddForm.controls;
      Object.keys(controls).forEach(key => {
        controls[key].markAsTouched();
      });
    } else {
      const projectdata = {
        // tslint:disable-next-line: max-line-length
        projectName: this.projectAddForm.controls.addProject.value === null || this.projectAddForm.controls.addProject.value === '' || this.projectAddForm.controls.addProject.value === undefined ? '' : this.projectAddForm.controls.addProject.value,
        // tslint:disable-next-line: max-line-length
        description: this.projectAddForm.controls.addDes.value === null || this.projectAddForm.controls.addDes.value === undefined || this.projectAddForm.controls.addDes.value === '' ? '' : this.projectAddForm.controls.addDes.value,
        // tslint:disable-next-line: max-line-length
        estimatedStartDate: this.projectAddForm.controls.startdate.value === null || this.projectAddForm.controls.startdate.value === undefined || this.projectAddForm.controls.startdate.value === '' ? '' : this.datePipe.transform(this.projectAddForm.controls.startdate.value, 'yyyy-MM-dd'),
        // tslint:disable-next-line: max-line-length
        estimatedEndDate: this.projectAddForm.controls.enddate.value === null || this.projectAddForm.controls.enddate.value === undefined || this.projectAddForm.controls.enddate.value === '' ? '' : this.datePipe.transform(this.projectAddForm.controls.enddate.value, 'yyyy-MM-dd'),
        // tslint:disable-next-line: max-line-length
        lkProjectType: this.projectAddForm.controls.projecttype.value === null || this.projectAddForm.controls.projecttype.value === undefined || this.projectAddForm.controls.projecttype.value === '' ? '' : this.projectAddForm.controls.projecttype.value.code,
        // tslint:disable-next-line: radix
        // tslint:disable-next-line: max-line-length
        projectCost: parseInt(this.projectAddForm.controls.cost.value === null || undefined || '' ? 0 : this.projectAddForm.controls.cost.value),
        // tslint:disable-next-line: radix
        // tslint:disable-next-line: max-line-length
        projectHrs: parseInt(this.projectAddForm.controls.projecthrs.value === null || undefined || '' ? 0 : this.projectAddForm.controls.projecthrs.value),
      };
      this.disable = true;
      this.apisService.postApi(environment.project, projectdata).subscribe((res: any) => {
        if (res.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.message });
          this.disable = false;
          this.gridTable();
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.failed });
          this.disable = false;
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: res.error });
          this.disable = false;
        }
        this.displayModal = false;
      });
    }
  }
  paginate(event) {
    let i = 1;
    this.first = event.first;
    this.rows = event.rows;
    this.page = event.page + i++;
    this.pageCount = event.pageCount;
    this.gridTable();
  }
  gridstatus() {
    this.apisService.getApi(environment.statuslookup).subscribe((res: any) => {
      this.status = res.data;
      this.status.forEach(element => {
        this.statuslist[element.code] = [element.label];
      });
    }
    );
  }
  projectType() {
    this.apisService.getApi(environment.projecttype).subscribe((res: any) => {
      this.projecttype = res.data;
      this.projecttype.forEach(element => {
        this.projectypelist[element.code] = [element.label];
      });
    });
  }
  gridTable() {
    this.loader = true;
    const pgNo = this.page;
    const recordPage = this.rows;
    const pagenbr = pgNo + '/' + recordPage;
    // tslint:disable-next-line: max-line-length
    const name = this.projectForm.controls.projectname.value === null || this.projectForm.controls.projectname.value === '' || this.projectForm.controls.projectname.value === undefined ? '' :
      this.projectForm.controls.projectname.value;
    // tslint:disable-next-line: max-line-length
    const status = this.projectForm.controls.searchstatus.value === null || this.projectForm.controls.searchstatus.value === '' || this.projectForm.controls.searchstatus.value === undefined ? '' : this.projectForm.controls.searchstatus.value.code;
    const searcdata = '?name=' + name + '&status=' + status;
    this.apisService.getApi(environment.projects + pagenbr + '/' + this.accountid + searcdata).subscribe((res: any) => {
      this.gridData = res.data;
      this.loader = false;
      this.totalRecords = res.count;
      if (this.totalRecords === 0) {
        this.records = true;
        this.table = false;
      } else {
        this.records = false;
        this.table = true;
      }
    });
  }
  toggle(event, data, rowData) {
    this.data = rowData;
    this.actualEndDate = rowData.actualEndDate;
    this.id = rowData.id;
    this.projectName = rowData.projectName;
    this.statusdata = rowData.lkStatus;
    this.items = [{ label: ' Edit', icon: 'pi pi-pencil', command: () => { this.updateData(this.data); } },
    { label: 'Resources', icon: 'pi pi-users', command: () => { this.routeToResources(); } }
    ];
  }
  routeToResources() {
    this.router.navigate(['/resource'], {
      queryParams: {
        projectname: this.projectName,
        id: this.id,
        lkStatus: this.statusdata,
        actualEndDate: this.actualEndDate
      }
    });
  }
  statusData() {
    this.endDateMsg = false;
    if (this.projectEditForm.value.editstatus.label === 'Completed') {
      this.projectEditForm.controls.actualenddate.setValidators([Validators.required]);
      this.endDate = true;
      this.projectEditForm.controls.actualenddate.enable();
    }
    else {
      this.endDate = false;
      this.projectEditForm.controls.actualenddate.clearValidators();
      this.projectEditForm.controls.actualenddate.reset();
      this.projectEditForm.controls.actualenddate.disable();
    }
  }
  updateData(data) {
    this.displayModal1 = true;
    this.endDateMsg = false;
    this.savestatus = data.lkStatus;
    if (this.savestatus === 'D') {
      this.savebutton = false;
      this.endDate = true;
      this.projectEditForm.controls.actualenddate.enable();
    }
    else {
      this.savebutton = true;
      this.endDate = false;
      this.projectEditForm.controls.actualenddate.disable();
    }
    this.projectEditForm.reset();
    const firstDate: any = new Date();
    this.minDate = firstDate;
    const firstDate1: any = new Date();
    this.minDate1 = firstDate1;
    this.minDate1 = firstDate;
    this.id = data.id;
    this.apisService.getApi(environment.project + '/' + this.id).subscribe((res: any) => {
      this.editdata = res.data;
      this.status1 = this.editdata[0].lkStatus === 'C' ? this.status : this.status2;
      this.projectEditForm.controls.editProject.patchValue(this.editdata[0].projectName);
      // tslint:disable-next-line: max-line-length
      this.projectEditForm.controls.estimatedstartdate.patchValue(this.datePipe.transform(this.editdata[0].estimatedStartDate, 'MM-dd-yyyy'));
      this.projectEditForm.controls.estimatedenddate.patchValue(this.datePipe.transform(this.editdata[0].estimatedEndDate, 'MM-dd-yyyy'));
      this.projectEditForm.controls.actualstartdate.patchValue(this.datePipe.transform(this.editdata[0].actualStartDate, 'MM-dd-yyyy'));
      this.projectEditForm.controls.actualenddate.patchValue(this.datePipe.transform(this.editdata[0].actualEndDate, 'MM-dd-yyyy'));
      this.projectEditForm.controls.editprojecthrs.patchValue(this.editdata[0].projectHrs);
      this.projectEditForm.controls.editprojectcost.patchValue(this.editdata[0].projectCost);
      this.projectEditForm.controls.editprojectmanager.patchValue(this.name);
      this.projectEditForm.controls.noofresource.patchValue(this.editdata[0].totalResources);
      this.projectEditForm.controls.editDes.patchValue(this.editdata[0].description);
      this.projectEditForm.controls.editstatus.patchValue(this.status.filter((x) => x.code === this.editdata[0].lkStatus)[0]);
      // tslint:disable-next-line: max-line-length
      this.projectEditForm.controls.editprojecttype.patchValue(this.projecttype.filter((x) => x.code === this.editdata[0].lkProjectType)[0]);
      this.minDate = new Date(this.editdata[0].estimatedStartDate);
      this.minDate1 = new Date(this.editdata[0].actualStartDate);
    });
  }
  updateproject() {
   // tslint:disable-next-line: max-line-length
   if ((this.projectEditForm.value.editstatus.label === 'Completed' && this.projectEditForm.value.actualenddate === null) || (this.projectEditForm.value.editstatus.label === 'Completed' && this.projectEditForm.value.actualenddate !== null)) {
      this.endDate = true;
      this.endDateMsg = true;
      this.projectEditForm.controls.actualenddate.setValidators([Validators.required]);
      if ( this.projectEditForm.value.actualenddate === null){
        this.projectEditForm.controls.actualenddate.markAsTouched();
      } else {
        if (this.projectEditForm.invalid) {
          const controls = this.projectEditForm.controls;
          Object.keys(controls).forEach(key => {
            controls[key].markAsTouched();
          });
        }
        else {
          const projectsdata = {
            id: this.id,
            // tslint:disable-next-line: max-line-length
            projectName: this.projectEditForm.controls.editProject.value === null || this.projectEditForm.controls.editProject.value === '' || this.projectEditForm.controls.editProject.value === undefined ? '' : this.projectEditForm.controls.editProject.value,
            description: this.projectEditForm.controls.editDes.value,
            lkProjectType: this.projectEditForm.controls.editprojecttype.value.code,
            // tslint:disable-next-line: radix
            // tslint:disable-next-line: max-line-length
            projectCost: parseInt(this.projectEditForm.controls.editprojectcost.value === null || undefined || '' ? 0 : this.projectEditForm.controls.editprojectcost.value),
            // tslint:disable-next-line: max-line-length
            estimatedStartDate: this.projectEditForm.controls.estimatedstartdate.value === null || this.projectEditForm.controls.estimatedstartdate.value === undefined || this.projectEditForm.controls.estimatedstartdate.value === '' ? null : this.datePipe.transform(this.projectEditForm.controls.estimatedstartdate.value, 'yyyy-MM-dd'),
            // tslint:disable-next-line: max-line-length
            estimatedEndDate: this.projectEditForm.controls.estimatedenddate.value === null || this.projectEditForm.controls.estimatedenddate.value === undefined || this.projectEditForm.controls.estimatedenddate.value === '' ? '' : this.datePipe.transform(this.projectEditForm.controls.estimatedenddate.value, 'yyyy-MM-dd'),
            // tslint:disable-next-line: max-line-length
            actualStartDate: this.projectEditForm.controls.actualstartdate.value === null || this.projectEditForm.controls.actualstartdate.value === undefined || this.projectEditForm.controls.actualstartdate.value === '' ? '' : this.datePipe.transform(this.projectEditForm.controls.actualstartdate.value, 'yyyy-MM-dd'),
            // tslint:disable-next-line: max-line-length
            actualEndDate: this.projectEditForm.controls.actualenddate.value === null || this.projectEditForm.controls.actualenddate.value === undefined || this.projectEditForm.controls.actualenddate.value === '' ? '0000-00-00' : this.datePipe.transform(this.projectEditForm.controls.actualenddate.value, 'yyyy-MM-dd'),
            lkStatus: this.projectEditForm.controls.editstatus.value.code,
            // tslint:disable-next-line: radix
            // tslint:disable-next-line: max-line-length
            projectHrs: parseInt(this.projectEditForm.controls.editprojecthrs.value === null || undefined || '' ? 0 : this.projectEditForm.controls.editprojecthrs.value),
          };
          this.updatedisable = true;
          this.apisService.putApi(environment.updateproject, projectsdata).subscribe((res: any) => {
            this.resdata = res;
            if (res.hasOwnProperty('success') === true) {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.resdata.message });
              this.loader = false;
              this.updatedisable = false;
              this.gridTable();
            } else if (res.hasOwnProperty('failed') === true) {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.resdata.failed });
            } else {
              this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.resdata.error });
            }
            this.displayModal1 = false;
          });
        }
      }
    }
    // tslint:disable-next-line: max-line-length
    else if ((this.projectEditForm.value.editstatus.label === 'Inactive' && this.projectEditForm.value.actualenddate === null) || (this.projectEditForm.value.editstatus.label === 'Inactive' && this.projectEditForm.value.actualenddate !== null)) {
      if (this.projectEditForm.invalid) {
        const controls = this.projectEditForm.controls;
        Object.keys(controls).forEach(key => {
          controls[key].markAsTouched();
        });
      }
      else {
      const projectsdata = {
        id: this.id,
        // tslint:disable-next-line: max-line-length
        projectName: this.projectEditForm.controls.editProject.value === null || this.projectEditForm.controls.editProject.value === '' || this.projectEditForm.controls.editProject.value === undefined ? '' : this.projectEditForm.controls.editProject.value,
        description: this.projectEditForm.controls.editDes.value,
        lkProjectType: this.projectEditForm.controls.editprojecttype.value.code,
        // tslint:disable-next-line: radix
        // tslint:disable-next-line: max-line-length
        projectCost: parseInt(this.projectEditForm.controls.editprojectcost.value === null || undefined || '' ? 0 : this.projectEditForm.controls.editprojectcost.value),
        // tslint:disable-next-line: max-line-length
        estimatedStartDate: this.projectEditForm.controls.estimatedstartdate.value === null || this.projectEditForm.controls.estimatedstartdate.value === undefined || this.projectEditForm.controls.estimatedstartdate.value === '' ? null : this.datePipe.transform(this.projectEditForm.controls.estimatedstartdate.value, 'yyyy-MM-dd'),
        // tslint:disable-next-line: max-line-length
        estimatedEndDate: this.projectEditForm.controls.estimatedenddate.value === null || this.projectEditForm.controls.estimatedenddate.value === undefined || this.projectEditForm.controls.estimatedenddate.value === '' ? '' : this.datePipe.transform(this.projectEditForm.controls.estimatedenddate.value, 'yyyy-MM-dd'),
        // tslint:disable-next-line: max-line-length
        actualStartDate: this.projectEditForm.controls.actualstartdate.value === null || this.projectEditForm.controls.actualstartdate.value === undefined || this.projectEditForm.controls.actualstartdate.value === '' ? '' : this.datePipe.transform(this.projectEditForm.controls.actualstartdate.value, 'yyyy-MM-dd'),
        // tslint:disable-next-line: max-line-length
        actualEndDate: this.projectEditForm.controls.actualenddate.value === null || this.projectEditForm.controls.actualenddate.value === undefined || this.projectEditForm.controls.actualenddate.value === '' ? '0000-00-00' : this.datePipe.transform(this.projectEditForm.controls.actualenddate.value, 'yyyy-MM-dd'),
        lkStatus: this.projectEditForm.controls.editstatus.value.code,
        // tslint:disable-next-line: radix
        // tslint:disable-next-line: max-line-length
        projectHrs: parseInt(this.projectEditForm.controls.editprojecthrs.value === null || undefined || '' ? 0 : this.projectEditForm.controls.editprojecthrs.value),
      };
      this.updatedisable = true;
      this.apisService.putApi(environment.updateproject, projectsdata).subscribe((res: any) => {
        this.resdata = res;
        if (res.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.resdata.message });
          this.loader = false;
          this.updatedisable = false;
          this.gridTable();
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.resdata.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.resdata.error });
        }
        this.displayModal1 = false;
      });
    }
    }
    // tslint:disable-next-line: max-line-length
    else if ((this.projectEditForm.value.editstatus.label === 'Active' && this.projectEditForm.value.actualenddate === null) || (this.projectEditForm.value.editstatus.label === 'Active' && this.projectEditForm.value.actualenddate !== null)) {
        if (this.projectEditForm.invalid) {
          const controls = this.projectEditForm.controls;
          Object.keys(controls).forEach(key => {
            controls[key].markAsTouched();
          });
        }
      else{
      const projectsdata = {
        id: this.id,
        // tslint:disable-next-line: max-line-length
        projectName: this.projectEditForm.controls.editProject.value === null || this.projectEditForm.controls.editProject.value === '' || this.projectEditForm.controls.editProject.value === undefined ? '' : this.projectEditForm.controls.editProject.value,
        description: this.projectEditForm.controls.editDes.value,
        lkProjectType: this.projectEditForm.controls.editprojecttype.value.code,
        // tslint:disable-next-line: radix
        // tslint:disable-next-line: max-line-length
        projectCost: parseInt(this.projectEditForm.controls.editprojectcost.value === null || undefined || '' ? 0 : this.projectEditForm.controls.editprojectcost.value),
        // tslint:disable-next-line: max-line-length
        estimatedStartDate: this.projectEditForm.controls.estimatedstartdate.value === null || this.projectEditForm.controls.estimatedstartdate.value === undefined || this.projectEditForm.controls.estimatedstartdate.value === '' ? null : this.datePipe.transform(this.projectEditForm.controls.estimatedstartdate.value, 'yyyy-MM-dd'),
        // tslint:disable-next-line: max-line-length
        estimatedEndDate: this.projectEditForm.controls.estimatedenddate.value === null || this.projectEditForm.controls.estimatedenddate.value === undefined || this.projectEditForm.controls.estimatedenddate.value === '' ? '' : this.datePipe.transform(this.projectEditForm.controls.estimatedenddate.value, 'yyyy-MM-dd'),
        // tslint:disable-next-line: max-line-length
        actualStartDate: this.projectEditForm.controls.actualstartdate.value === null || this.projectEditForm.controls.actualstartdate.value === undefined || this.projectEditForm.controls.actualstartdate.value === '' ? '' : this.datePipe.transform(this.projectEditForm.controls.actualstartdate.value, 'yyyy-MM-dd'),
        // tslint:disable-next-line: max-line-length
        actualEndDate: this.projectEditForm.controls.actualenddate.value === null || this.projectEditForm.controls.actualenddate.value === undefined || this.projectEditForm.controls.actualenddate.value === '' ? '0000-00-00' : this.datePipe.transform(this.projectEditForm.controls.actualenddate.value, 'yyyy-MM-dd'),
        lkStatus: this.projectEditForm.controls.editstatus.value.code,
        // tslint:disable-next-line: radix
        // tslint:disable-next-line: max-line-length
        projectHrs: parseInt(this.projectEditForm.controls.editprojecthrs.value === null || undefined || '' ? 0 : this.projectEditForm.controls.editprojecthrs.value),
      };
      this.updatedisable = true;
      this.apisService.putApi(environment.updateproject, projectsdata).subscribe((res: any) => {
        this.resdata = res;
        if (res.hasOwnProperty('success') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.resdata.message });
          this.loader = false;
          this.updatedisable = false;
          this.gridTable();
        } else if (res.hasOwnProperty('failed') === true) {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.resdata.failed });
        } else {
          this.messageService.add({ key: 'br', severity: '', life: 3000, detail: this.resdata.error });
        }
        this.displayModal1 = false;
      });
    }
    }
    else {
      this.endDate = false;
    }
  }
  onselectDate(dateType?: any) {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate: any = new Date(this.projectAddForm.controls.startdate.value);
    const secondDate: any = this.projectAddForm.controls.enddate.value === null ? '' : new Date(this.projectAddForm.controls.enddate.value);
    const currentDate: any = new Date();
    this.remainingDays = Math.round(Math.abs((firstDate - secondDate) / oneDay)) + 1;
    // tslint:disable-next-line: max-line-length
    const diffDays: number = firstDate < new Date() ? Math.round(Math.abs((currentDate - secondDate) / oneDay)) : Math.round(Math.abs((firstDate - secondDate) / oneDay));
    this.minDate = firstDate;
    this.maxDate = secondDate;
    this.duration = secondDate < new Date() ? 0 : diffDays + 1;
    if (firstDate > secondDate) {
      this.projectAddForm.controls.enddate.reset();
    }
  }
  selectDate(dateType?: any) {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate: any = new Date(this.projectEditForm.controls.estimatedstartdate.value);
    // tslint:disable-next-line: max-line-length
    const secondDate: any = this.projectEditForm.controls.estimatedenddate.value === null ? '' : new Date(this.projectEditForm.controls.estimatedenddate.value);
    const currentDate: any = new Date();
    this.remainingDays = Math.round(Math.abs((firstDate - secondDate) / oneDay)) + 1;
    // tslint:disable-next-line: max-line-length
    const diffDays: number = firstDate < new Date() ? Math.round(Math.abs((currentDate - secondDate) / oneDay)) : Math.round(Math.abs((firstDate - secondDate) / oneDay));
    this.minDate = firstDate;
    this.maxDate = secondDate;
    this.duration = secondDate < new Date() ? 0 : diffDays + 1;
    if (firstDate > secondDate) {
      this.projectEditForm.controls.estimatedenddate.reset();
    } else {
    }
  }
  actualselectDate(dateType?: any) {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate1: any = new Date(this.projectEditForm.controls.estimatedstartdate.value);
    // tslint:disable-next-line: max-line-length
    const secondDate: any = this.projectEditForm.controls.actualstartdate.value === null ? '' : new Date(this.projectEditForm.controls.actualstartdate.value);
    const currentDate: any = new Date();
    this.remainingDays = Math.round(Math.abs((firstDate1 - secondDate) / oneDay)) + 1;
    // tslint:disable-next-line: max-line-length
    const diffDays: number = firstDate1 < new Date() ? Math.round(Math.abs((currentDate - secondDate) / oneDay)) : Math.round(Math.abs((firstDate1 - secondDate) / oneDay));
    this.minDate1 = firstDate1;
    this.maxDate = secondDate;
    this.duration = secondDate < new Date() ? 0 : diffDays + 1;
    if (firstDate1 > secondDate) {
      this.projectEditForm.controls.actualstartdate.reset();
      this.projectEditForm.controls.actualenddate.reset();
    }
  }
  enddatefun(dateType?: any) {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate: any = new Date(this.projectEditForm.controls.actualstartdate.value);
    // tslint:disable-next-line: max-line-length
    const secondDate: any = this.projectEditForm.controls.actualenddate.value === null ? '' : new Date(this.projectEditForm.controls.actualenddate.value);
    const currentDate: any = new Date();
    this.remainingDays = Math.round(Math.abs((firstDate - secondDate) / oneDay)) + 1;
    // tslint:disable-next-line: max-line-length
    const diffDays: number = firstDate < new Date() ? Math.round(Math.abs((currentDate - secondDate) / oneDay)) : Math.round(Math.abs((firstDate - secondDate) / oneDay));
    this.minDate1 = firstDate;
    this.maxDate = secondDate;
    this.duration = secondDate < new Date() ? 0 : diffDays + 1;
    if (firstDate > secondDate) {
      this.projectEditForm.controls.actualenddate.reset();
    }
    if(secondDate === null){
      this.endDateMsg = true;
    } else{
      this.endDateMsg = false;
    }
  }
  durationCal(event) {
    this.currencysymbl = event.slice(0, 1);
    return this.currencysymbl;
  }
}